import React, { useState, useEffect, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import { getAPIHostURL } from '../../ClientConfig';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { IDS_RegistEmailError, IDS_PrevBtn, IDS_NxtBtn, IDS_Search, IDS_TodaySearch, IDS_AUServerError} from '../../VcLanguage';
import { convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday} from '../../vtUtil';
import { FaSearch, FaEdit, FaIoMdPersonAdd, FaEye} from 'react-icons/fa';
import { IoMdAddCircleOutline} from 'react-icons/io';
import { AppRelevantDataContext } from '../../AppContext';
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import '../CSS/VcCustomer.css';
import '../CSS/ReactTable.css';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis",whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                     placeholder={"Search"}
                     style={{fontSize:"0.8rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

// Create a default prop getter
const defaultPropGetter = () => ({});

const ReactTable = ({ columns, data, passedStateVariable, getCellProps = defaultPropGetter }) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    );

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
          minWidth: 30,
          width: 450,
          Filter: DefaultColumnFilter,
          canFilter: true,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // set page size based on tables
            initialState: {pageSize: 10},
            filterTypes,
            // whichever function called from react table which is inside main function
            // should be passed to react table and added in useTable
            // onViewProductDetails,
            // onEditProductDetails
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    // if the state.goToPage1 is true and if new data is inserted
    // go to page 1
    React.useEffect(() =>{
        if(passedStateVariable == true){
            gotoPage(0)
        }
    },[passedStateVariable]);

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;No Product Details Found.</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                <div className='fa' >
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <FaArrowDownLong />
                                        : <FaArrowUpLong />
                                    : ''}
                                </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* {page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"   
                                    style={{
                                        cursor: '',
                                        background: '',
                                        color: 'black',
                                        alignItems: "center",
                                    }}  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style},
                                            getCellProps(cell),
                                        ]
                                    )} className="td" 
                                    // onClick = {() =>{ 
                                    //                 if(cell.column.id == 'view'){
                                    //                     onViewProductDetails(row, cell.column);
                                    //                 } else if(cell.column.id == 'edit'){
                                    //                     onEditProductDetails(row, cell.column);
                                    //                 }
                                    //             }}
                                    >{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || 
                        //     // when there is no data found 
                        //     <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex" ,justifyContent:"left"}}>{"No Product Details Found."}</span>
                        //         </td>
                        //     </tr> 
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>
            
            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div>
                    <div className="-pagination">
                        <div className="-previous">
                            <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                        </div>
                        <div className="-center">
                            <span className="-pageInfo">
                                Page {" "}    
                                <div className="-pageJump">
                                    <input 
                                        value={pageIndex + 1} 
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(page)
                                        }}
                                        aria-label="jump to page" type="number"
                                    />
                                </div>
                                of {" "}  
                                <span className="-totalPages">{pageOptions.length}</span>
                            </span>
                            <span className="select-wrap -pageSizeOptions">
                                <select aria-label="rows per page"
                                    style={{padding:"0.2rem"}}
                                    value={pageSize}
                                    onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}>
                                    {[5, 10, 20, 25, 100].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                        </option>
                                    ))}
                                </select>
                            </span>  
                        </div>
                        <div className="-next">
                            <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcProduct (props) {
    const searchStringStyle = {fontSize: "0.8rem", width:"85%"};
    const filterCaseInsensitive = (rows, id, filterValue) => {

        if(id != "lastModifiedTime") {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
                })
        } else {
            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]

                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                    else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            })
        }
    };

        // function will get called on cliking of eye icon, setting the flag formViewMode to viewMode to display form in readonly only.
    // fetching the value from react table selected Product details row and set it in the state.
    // setting modal value to true to display Form view only mode.
    const onViewProductDetails = (rowInfo, column) => {

        let modifiedState = state;
        modifiedState.formViewMode = "viewMode";
        modifiedState.ProductCode = rowInfo.original.ProductCode;
        modifiedState.selectedProdNameCode = rowInfo.original.selectedProdNameCode;
        modifiedState.selectedProductName = rowInfo.original.selectedProductName;
        modifiedState.selectedProductLine = rowInfo.original.selectedProductLine;
        modifiedState.ProdDescription = rowInfo.original.ProdDescription;
        modifiedState.SelectedSequenceID = rowInfo.original.SelectedSequenceID;
        modifiedState.PricePerUnit = rowInfo.original.PricePerUnit;
        modifiedState.BasicUnit = rowInfo.original.BasicUnit;
        modifiedState.TaxPrcntPerUnit = rowInfo.original.TaxPrcntPerUnit;
        modifiedState.QuantityInStock = rowInfo.original.QuantityInStock;
        modifiedState.SetByUser = rowInfo.original.SetByUser;
        modifiedState.ProdLastModifiedTime = rowInfo.original.ProdLastModifiedTime;

        modifiedState.modal = true;
        setState({...modifiedState});
    }

    // function will get called on clicking of edit icon, setting the flag formViewMode to editMode to display form in Editable screen.
    // fetching the value from react table to allow edting the exsiting details of selected product.
    // setting modal value to true to display Form in Edit mode.
    // also setting product original details(before any changes) to state to compaire it latter on with value of changed fields
    // to propmpt confirmation while saving edited fields.
    const onEditProductDetails = (rowInfo, column) => {

        let modifiedState = state;
        modifiedState.formViewMode = "editMode";

        modifiedState.ProductCode = rowInfo.original.ProductCode;
        modifiedState.selectedProdNameCode = rowInfo.original.selectedProdNameCode;
        modifiedState.selectedProductName = rowInfo.original.selectedProductName;
        modifiedState.selectedProductLine = rowInfo.original.selectedProductLine;
        modifiedState.ProdDescription = rowInfo.original.ProdDescription;
        modifiedState.SelectedSequenceID = rowInfo.original.SelectedSequenceID;
        modifiedState.PricePerUnit = rowInfo.original.PricePerUnit;
        modifiedState.BasicUnit = rowInfo.original.BasicUnit;
        modifiedState.TaxPrcntPerUnit = rowInfo.original.TaxPrcntPerUnit;
        modifiedState.QuantityInStock = rowInfo.original.QuantityInStock;
        modifiedState.SetByUser = rowInfo.original.SetByUser;
        modifiedState.lastModifiedTime = rowInfo.original.lastModifiedTime;

        modifiedState.originalProductCode = rowInfo.original.ProductCode;
        modifiedState.originalProductNameCode = rowInfo.original.selectedProdNameCode;
        modifiedState.originalProductName =rowInfo.original.selectedProductName;
        modifiedState.originalProductLine = rowInfo.original.selectedProductLine;
        modifiedState.originalProductDescription = rowInfo.original.ProdDescription;
        modifiedState.originalStateSeqId = rowInfo.original.SelectedSequenceID;
        modifiedState.originalPricePerUnitINR = rowInfo.original.PricePerUnit;
        modifiedState.originalBasicUnit = rowInfo.original.BasicUnit;
        modifiedState.originalTaxPrcntPerUnitINR = rowInfo.original.TaxPrcntPerUnit;
        modifiedState.originalQuantityInStock = rowInfo.original.QuantityInStock;
        modifiedState.originalSetByUser = rowInfo.original.SetByUser;

        modifiedState.modal = true;
        setState({...modifiedState});
    }

    const context = useContext(AppRelevantDataContext);
    const [state, setState] = useState({
        modal: false,
        backdrop: 'static',

        errors: {
            others: "",
            ProductLine: "",
            ProdNameCode: "",
            ProdName: "",
            ProdDescription: "",
            BasicUnit: "",
            PricePerUnit: "",
            TaxPrcntPerUnit: "",
            SelectedSequenceID: "",
        },
        ProductLineArr: [],
        ProductNameCodeAndProductNameArr: [],
        ProdNameCodeArr: [],
        ProdNameArr: [],
        BasicUnitArr: [],
        SequenceIDArr: [],
        ProdDescription: "",
        BasicUnit: "",
        PricePerUnit: "",
        TaxPrcntPerUnit: "",
        SelectedSequenceID: "GenericProductSeq",
        goToPage1: false,

        selectedProductLine: "",
        selectedProdNameCode: "",
        selectedProductName: "",
        LoggedInUserID: "",
        showModal: false,

        formViewMode: "",
        LastModifiedUtcDtTmOfReceivedProductData: "",
        data: [],

        ProductCode: "",
        QuantityInStock: "",
        SetByUser: "",
        ProdLastModifiedTime: "",

        originalProductCode: "",
        originalProductNameCode: "",
        originalProductName: "",
        originalProductLine: "",
        originalProductDescription: "",
        originalStateSeqId: "",
        originalPricePerUnitINR: "",
        originalBasicUnit: "",
        originalTaxPrcntPerUnitINR: "",
        originalQuantityInStock: "",
        originalSetByUser: "",
        noDataFound: "",
        showModal: false,
        columns: [
            {       
            Header:() => <div className="AlertLogTableHeader">View</div>,
                accessor: 'view',
                width: 80,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                disableFilters: true,
                filter: filterCaseInsensitive,
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">Edit</div>, 
                accessor: "edit",
                width: 80,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                disableFilters: true,
                filter: filterCaseInsensitive,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Product<br/>Code</div>,  
                accessor: 'ProductCode',
                width: 110,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Product<br/>Name Code</div>,
                accessor: 'selectedProdNameCode',
                width: 155,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Product<br/>Name</div>,
                accessor: 'selectedProductName',
                width: 210,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },    
            {       
                Header:() => <div className="AlertLogTableHeader">Product<br/>Description</div>,
                accessor: 'ProdDescription',
                width: 260,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Basic<br/>Unit</div>,  
                accessor: 'BasicUnit',
                width: 100,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Price Per<br/>Unit (INR)</div>,
                accessor: 'PricePerUnit',
                width: 120,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },   
            {       
                Header:() => <div className="AlertLogTableHeader">Tax Per<br/>Unit (%)</div>,
                accessor: 'TaxPrcntPerUnit',
                width: 120,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            // {       
            //     Header:() => <div className="AlertLogTableHeader">Quantity<br/>In Stock</div>,  
            //     accessor: 'QuantityInStock',
            //     filter: filterCaseInsensitive,
            //     width: 110,
            //     style:({
            //         textAlign:"center",
            //         paddingLeft: "1rem",
                        // textOverflow: "ellipsis", 
                        // overflow: "hidden",
                        // whiteSpace: "nowrap",
                        // height: "2.6rem"
            //     }),
            // },  
            // {       
            //     Header:() => <div className="AlertLogTableHeader">State<br/>Sequence</div>,  
            //     // accessor: 'StateSeqId',
            //     accessor: 'SelectedSequenceID',
            //     filter: filterCaseInsensitive,
            //     width: 175,
            //     style:({
            //         textAlign:"left",
            //         paddingLeft: "1rem",
                        // textOverflow: "ellipsis", 
                        // overflow: "hidden",
                        // whiteSpace: "nowrap",
                        // height: "2.6rem"
    
            //     }),
            // },
            { 
                Header:() => <div className="AlertLogTableHeader">Last<br/>Modified Time</div>, 
                accessor: 'lastModifiedTime',
                Cell: (props) => getCustomizedTodaysDate(props.value),
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width:190,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },                    
        ],
    });

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return t(IDS_TodaySearch) + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    const toggle = () => {
        let modifiedState = state;

        modifiedState.modal = !modifiedState.modal;
        modifiedState.ProductCode = "";
        modifiedState.selectedProdNameCode = "";
        modifiedState.selectedProductName = "";
        modifiedState.selectedProductLine = "";
        modifiedState.ProdDescription = "";
        modifiedState.SelectedSequenceID = "";
        modifiedState.PricePerUnit = "";
        modifiedState.BasicUnit = "";
        modifiedState.TaxPrcntPerUnit = "";
        modifiedState.QuantityInStock = "";
        modifiedState.SetByUser = "";
        modifiedState.formViewMode = "";

        modifiedState.errors.ProductCode = "";
        modifiedState.errors.selectedProdNameCode = "";
        modifiedState.errors.selectedProductName = "";
        modifiedState.errors.selectedProductLine = "";
        modifiedState.errors.ProdDescription = "";
        modifiedState.errors.SelectedSequenceID = "";
        modifiedState.errors.PricePerUnit = "";
        modifiedState.errors.BasicUnit = "";
        modifiedState.errors.TaxPrcntPerUnit = "";
        modifiedState.errors.QuantityInStock = "";
        modifiedState.errors.SetByUser = "";

        modifiedState.errors.ProductLine = "";
        modifiedState.errors.ProdNameCode = "";
        modifiedState.errors.ProdName = "";
        
        setState({...modifiedState});

    }

    useEffect(() =>{
        getLatestProductInfo();
    }, []);

    //when first time page will load we send LastModifiedUtcDtTmForInsertOrUpdateProdDetails empty, 
    //and retrieved all the Customer data from database and filled out in the Customer table.
    //In case of create new customer and edit existing customer details, we send latest LastModifiedUtcDtTmForInsertOrUpdateProdDetails
    //received from customer table, and axios will retrieved the Customer details next to LastModifiedUtcDtTmForInsertOrUpdateProdDetails.
    //we put latest retrived data on top of the table.
    const getLatestProductInfo = (inModifiedState = null) => {
        
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.goToPage1 = false;

        let LastModifiedUtcDtTmForInsertOrUpdateProdDetails;
        //In case of create new customer(flag formViewMode == InsertMode) and edit existing customer(flag formViewMode == "editMode") details, 
        //we send latest LastModifiedUtcDtTmForInsertOrUpdateProdDetails recvied from Customer Table
        //or send empty LastModifiedUtcDtTmForInsertOrUpdateProdDetails.
        if(modifiedState.formViewMode == "editMode" || modifiedState.formViewMode == "insertMode"){
            LastModifiedUtcDtTmForInsertOrUpdateProdDetails = modifiedState.LastModifiedUtcDtTmOfReceivedProductData;
        } else {
            LastModifiedUtcDtTmForInsertOrUpdateProdDetails = null;
        }

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        const jsonBody = {
            lastModifiedTime: LastModifiedUtcDtTmForInsertOrUpdateProdDetails, 
        };
        axios.post(`${getAPIHostURL()}/wclient/getLatestProductInfo`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {    

                if(response.data.retrievedProductTableDetails == null || response.data.retrievedProductTableDetails.length <= 0) {
                    // modifiedState.errors.others = "No Product Data Found.";
                } else {
                    let stateProductDetailsArr = [];
                    let stateProductLineDetailsArrForFechingData = []
                                            
                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing Customer Details data.
                    stateProductDetailsArr = [...modifiedState.data]

                    const receivedProductData = response.data.retrievedProductTableDetails;
                    let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
                    let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>
                    
                    for(let i = 0; i < receivedProductData.length; i++) {
                        const productDetails = receivedProductData[i];
                        let ProductlastModifiedLocaleDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(productDetails.ProdLastModifiedTime);
                        let singleProductDetails = {
                            view: viewEyeIcon,  
                            edit: editIcon,

                            ProductCode: productDetails.ProductCode == "null" ? "" : productDetails.ProductCode,
                            selectedProdNameCode: productDetails.ProductNameCode == "null" ? "" : productDetails.ProductNameCode,
                            selectedProductName: productDetails.ProductName == "null" ? "" : productDetails.ProductName,
                            selectedProductLine: productDetails.ProductName == "null" ? "" : productDetails.ProductLine,
                            ProdDescription: productDetails.ProductName == "null" ? "" : productDetails.ProductDescription,
                            SelectedSequenceID: productDetails.ProductName == "null" ? "" : productDetails.StateSeqId,
                            PricePerUnit: productDetails.ProductName == "null" ? "" : productDetails.PricePerUnitINR,
                            BasicUnit: productDetails.ProductName == "null" ? "" : productDetails.BasicUnit,
                            TaxPrcntPerUnit: productDetails.ProductName == "null" ? "" : productDetails.TaxPrcntPerUnit,
                            QuantityInStock: productDetails.ProductName == "null" ? "" : productDetails.QuantityInStock,
                            SetByUser: productDetails.ProductName == "null" ? "" : productDetails.SetByUser,
                            lastModifiedTime: ProductlastModifiedLocaleDateTime,
                            lastModifiedUtcDtTm : productDetails.LastModifiedUtcDtTm == "null" ? "" : productDetails.LastModifiedUtcDtTm,
                        };

                        //if form is open in insertMode(create new Product) then just add new retrieved data(new added Product details) at top of the Product table.
                        if(modifiedState.formViewMode == "insertMode"){
                            stateProductDetailsArr.unshift(singleProductDetails);
                            modifiedState.goToPage1 = true;
                        }
                        //if form is open in editMode(edit existing Product details) then compare all ProductCode in Product table
                        //with retrieved ProductCode after editing Product details. If both custId get match then remove the selected Product data
                        //from Product table, and add new retrived Product data at top of the Product table.
                        else if(modifiedState.formViewMode == "editMode"){
                            for(let j = 0; j <stateProductDetailsArr.length; j++){
                                if(stateProductDetailsArr[j].ProductCode == singleProductDetails.ProductCode){
                                    stateProductDetailsArr.splice(j, 1);
                                }
                            }
                            stateProductDetailsArr.unshift(singleProductDetails);
                            modifiedState.goToPage1 = false;
                        }    
                        //on first time page load its will retrieved all Product details and add it in Product table,
                        // in order of latest data on top of the Product table.
                        else { 
                            stateProductLineDetailsArrForFechingData.push(singleProductDetails);
                            modifiedState.goToPage1 = false;
                        }
                    } 

                    if(modifiedState.formViewMode == "insertMode"){
                        // stored latest received UTC Date Time of Product table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedProductData = stateProductDetailsArr[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateProductDetailsArr;
                    } else if(modifiedState.formViewMode == "editMode"){
                        // stored latest received UTC Date Time of Product table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedProductData = stateProductDetailsArr[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateProductDetailsArr;
                    } else {
                        // stored latest received UTC Date Time of Product table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedProductData = stateProductLineDetailsArrForFechingData[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateProductLineDetailsArrForFechingData;
                    }
                    
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        })
    }

// ========================================================================================================================
    // For retrieving some data from database which is needed for DropDowns and Datalists
    const GetDetailsForCreatingNewProduct = () => {

        let modifiedState = state; 
        modifiedState.formViewMode = "insertMode";

        // To avoid appending of array values.
        modifiedState.ProductLineArr = [];
        modifiedState.BasicUnitArr = [];
        modifiedState.SequenceIDArr = [];

        modifiedState.modal = true;
        // To get ProductLine, ProductNameCode, ProductName, BasicUnit and Sequence ID from database.
        axios.post(`${getAPIHostURL()}/wclient/GetDetailsForCreatingNewProduct`)
        .then(response => {
            if(response.data.code == "SUCCESS") {
                // IF response gets inadequate data.
                if(response.data.retrievedProductLine == null || response.data.retrievedProductLine.length <= 0 ||
                   response.data.retrievedBasicUnit == null || response.data.retrievedBasicUnit.length <= 0 ||
                   response.data.retrievedStateSeqId == null || response.data.retrievedStateSeqId.length <= 0 )
                   {
                    modifiedState.errors.others = "Product Details are not present on Server.";
                    
                } else {
                    for(let i=0; i< response.data.retrievedProductLine.length; i++) {
                        modifiedState.ProductLineArr.push(response.data.retrievedProductLine[i].ProductLine);
                    }

                    let retrievedProdNameCodeAndProdName = response.data.retrievedProductNameCodeAndProductName;

                    modifiedState.ProductNameCodeAndProductNameArr = ( retrievedProdNameCodeAndProdName != null && 
                                                                       retrievedProdNameCodeAndProdName.length > 0 ) ? 
                                                                       retrievedProdNameCodeAndProdName : [];

                    // modifiedState.ProductNameCodeAndProductNameArr = response.data.retrievedProductNameCodeAndProductName;

                    // for(let j=0; j< modifiedState.ProductNameCodeAndProductNameArr.length; j++) {
                    //     modifiedState.ProdNameCodeArr.push(modifiedState.ProductNameCodeAndProductNameArr[j].newProductNameCode);
                    //     modifiedState.ProdNameArr.push(modifiedState.ProductNameCodeAndProductNameArr[j].newProductName);
                    // }
                    // modifiedState.ProdNameCodeArr.push(response.data["retrievedProductNameCodeAndProductName"][])
                    for(let k=0; k< response.data.retrievedBasicUnit.length; k++) {
                        modifiedState.BasicUnitArr.push(response.data.retrievedBasicUnit[k].Unit);
                    }

                    modifiedState.SelectedSequenceID = "GenericProductSeq";
                    for(let l=0; l< response.data.retrievedStateSeqId.length; l++) {
                        modifiedState.SequenceIDArr.push(response.data.retrievedStateSeqId[l].StateSeqId);
                    }
                
                }

            } else {
                if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
        }  
        });
    }

    // For selecting values in datalist of "Select or Define Product Name Code and Product Name" as per selection of dropdown of "Select Product Line".
    const onChangeProductLineSelection = (e) => {

        state.errors.ProductLine = "";
        let modifiedState = state;
        modifiedState.selectedProductLine = e.target.value;

        // modifiedState.ProdNameCodeArr = "";
        modifiedState.ProdNameCodeArr = [];
        modifiedState.ProdNameArr = [];
        modifiedState.selectedProdNameCode = "";
        modifiedState.selectedProductName = "";
        modifiedState.ProdDescription = "";
        modifiedState.SelectedSequenceID = "";
        modifiedState.PricePerUnit = "";
        modifiedState.BasicUnit = "";
        modifiedState.TaxPrcntPerUnit = "";


        for(let i=0; i< modifiedState.ProductNameCodeAndProductNameArr.length; i++) {
            if(modifiedState.ProductNameCodeAndProductNameArr[i].ProductLine == modifiedState.selectedProductLine) {
                // Assigning values to ProductNameCode and ProductName droplist based on selection of ProductLine.
                modifiedState.ProdNameCodeArr.push(modifiedState.ProductNameCodeAndProductNameArr[i].newProductNameCode);
                modifiedState.ProdNameArr.push(modifiedState.ProductNameCodeAndProductNameArr[i].newProductName);
            }
        }
        setState({...modifiedState});
    }

    const onChangeProdNameCodeSelection = (e) => {

        let modifiedState = state;
        modifiedState.errors.ProdNameCode = "";
        modifiedState.ProdNameArr = [];
        modifiedState.selectedProdNameCode = "";
        modifiedState.selectedProductName = "";
        modifiedState.ProdDescription = "";
        modifiedState.SelectedSequenceID = "";
        modifiedState.PricePerUnit = "";
        modifiedState.BasicUnit = "";
        modifiedState.TaxPrcntPerUnit = "";

        modifiedState.selectedProdNameCode = e.target.value;

        for(let i=0; i< modifiedState.ProductNameCodeAndProductNameArr.length; i++) {
            if(modifiedState.ProductNameCodeAndProductNameArr[i].newProductNameCode == modifiedState.selectedProdNameCode) {
                // Assigning values to ProductName DataList based on selection of ProductNameCode.
                modifiedState.selectedProductName = modifiedState.ProductNameCodeAndProductNameArr[i].newProductName;
                modifiedState.errors.ProdName = "";
                // modifiedState.ProdNameArr.push(modifiedState.ProductNameCodeAndProductNameArr[i].newProductName);
            }
        }
        
        setState({...modifiedState});
    }

    const OnChangeProductNameSelection = (e) => {

        let modifiedState = state;
        modifiedState.errors.ProdName = "";

        // modifiedState.ProdName = e.target.value;
        modifiedState.selectedProductName = e.target.value;

        for(let i=0; i< modifiedState.ProductNameCodeAndProductNameArr.length; i++) {
            if(modifiedState.ProductNameCodeAndProductNameArr[i].newProductName == modifiedState.selectedProductName) {
                // Assigning values to ProductNameCode DataList selection based on selection of ProductName DataList.
                modifiedState.selectedProdNameCode = modifiedState.ProductNameCodeAndProductNameArr[i].newProductNameCode;
                modifiedState.errors.ProdNameCode = "";
            }
        }

        setState({...modifiedState});
    }

    // For entering detailed decription for newly created product.
    const onEnteredProdDescription = (e) => {

        let modifiedState = state;
        modifiedState.errors.ProdDescription = "";
        modifiedState.ProdDescription = e.target.value;
        setState({...modifiedState});
    }

    // For entering Basic Unit for the newly created product.
    const onChangeBasicUnitSelection = (e) => {

        let modifiedState = state;
        modifiedState.errors.BasicUnit = "";
        modifiedState.BasicUnit = e.target.value;
        setState({...modifiedState});
    }

    const onEnteredPricePerUnit = (e) => {
        
        e.target.value = e.target.value < 0 ? "" : e.target.value;
        let modifiedState = state;
        modifiedState.errors.PricePerUnit = "";
        modifiedState.PricePerUnit = e.target.value;
        setState({...modifiedState});
    }

    const onEnteredTaxPrcntPerUnit = (e) => {

        e.target.value = e.target.value < 0 ? "" : e.target.value;
        let modifiedState = state;
        modifiedState.errors.TaxPrcntPerUnit = "";
        modifiedState.TaxPrcntPerUnit = e.target.value;
        setState({...modifiedState});
    }

    const onChangeSeqIDSelection = (e) => {

        let modifiedState = state;
        modifiedState.errors.SelectedSequenceID = "";
        modifiedState.SelectedSequenceID = e.target.value;
        setState({...modifiedState});
    }

    const closeModal = () => {
        toggle();
        state.errors.ProductLine = "";
        state.errors.ProdNameCode = "";
        state.errors.ProdName = "";
        setState({...state});
    }

    // For saving all information to database.
    const onSubmit = (e) => {
        e.preventDefault();
        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let confirmationForProdFormEditFileds = "";
        let ProdCodeForEdit;

        // if flag formviewMode is set "editMode" (edit Product details) then check which fields are user has edited,
        // and ask a confirmation for that edited fields of Product form.
        // if there is no change if form return a function. 
        // aslo add Product code of selected Product which user want to edit.
        if(modifiedState.formViewMode == "editMode")
        {   
            ProdCodeForEdit = modifiedState.ProductCode;

            if(modifiedState.originalPricePerUnitINR != modifiedState.PricePerUnit){
                confirmationForProdFormEditFileds = "Price Per Unit,";
            }
            if(modifiedState.originalTaxPrcntPerUnitINR != modifiedState.TaxPrcntPerUnit){
                confirmationForProdFormEditFileds = confirmationForProdFormEditFileds + "Tax Per Unit,";
            }
            if(confirmationForProdFormEditFileds != null && confirmationForProdFormEditFileds.length > 0 ){
                if(!window.confirm(`Are you sure you want to change the ${confirmationForProdFormEditFileds} for this product ?`)){
                    return
                }
            } else {
                alert("There are no changes in Product Details")
                return
            }
            
        } 
        // if flag formViewMode is not set as EditMode(Edit existing Product Details) then ProductCode will send null.
        // in case of create new Product we send ProductCode =  Null
        else {
            ProdCodeForEdit = null;    
        }
        
        // Validation of all required fields.
        modifiedState.errors.ProductLine = (modifiedState.selectedProductLine == null || modifiedState.selectedProductLine == "" || modifiedState.selectedProductLine.length < 0)
            ? "Please Select ProductLine." 
            : "";

        modifiedState.errors.ProdNameCode = (modifiedState.selectedProdNameCode == null || modifiedState.selectedProdNameCode == "" || modifiedState.selectedProdNameCode.length < 0)
            ? "Please Select or Define Product Name Code." 
            : "";

        modifiedState.errors.ProdName = (modifiedState.selectedProductName == null || modifiedState.selectedProductName == "" || modifiedState.selectedProductName.length < 0)
            ? "Please Select or Define Product Name." 
            : "";

        modifiedState.errors.ProdDescription = (modifiedState.ProdDescription == null || modifiedState.ProdDescription == "" || modifiedState.ProdDescription.length < 0)
            ? "Please Enter Product Description." 
            : "";

        modifiedState.errors.BasicUnit = (modifiedState.BasicUnit == null || modifiedState.BasicUnit == "" || modifiedState.BasicUnit.length < 0)
            ? "Please Select or Define Basic Unit." 
            : "";

        modifiedState.errors.PricePerUnit = (modifiedState.PricePerUnit == null || modifiedState.PricePerUnit == "" || modifiedState.PricePerUnit.length < 0)
            ? "Please Enter Price Per Unit." 
            : "";

        modifiedState.errors.TaxPrcntPerUnit = (modifiedState.TaxPrcntPerUnit == null || modifiedState.TaxPrcntPerUnit == "" || modifiedState.TaxPrcntPerUnit.length < 0)
            ? "Please Enter Tax Per Unit." 
            : "";

        modifiedState.errors.SelectedSequenceID = (modifiedState.SelectedSequenceID == null || modifiedState.SelectedSequenceID == "" || modifiedState.SelectedSequenceID.length < 0)
            ? "Please Select SequenceID." 
            : "";

        // if any one of the required fields is not entered by user then return the function and show error message.
        if(modifiedState.errors.ProductLine.length > 0 || modifiedState.errors.ProdNameCode.length > 0 || 
           modifiedState.errors.ProdName.length > 0 || modifiedState.errors.ProdDescription.length > 0 || 
           modifiedState.errors.BasicUnit.length > 0 || modifiedState.errors.PricePerUnit.length > 0 || 
           modifiedState.errors.TaxPrcntPerUnit.length > 0 || modifiedState.errors.SelectedSequenceID.length > 0) {
            
            setState({...modifiedState});
            return
        } else {

                let ProdDescriptionToStore = modifiedState.ProdDescription.replace(/'/g, "''").replace(/\\/g, "\\\\");

                let JsonBody = {
                    ProdCode: ProdCodeForEdit,
                    ProductLineToSave: modifiedState.selectedProductLine,
                    ProductNameCodeToSave: modifiedState.selectedProdNameCode,
                    ProductNameToSave: modifiedState.selectedProductName,
                    ProductDescriptionToSave: trimStringAndRemoveTrailingComma(ProdDescriptionToStore),
                    BasicUnitToSave: modifiedState.BasicUnit,
                    PricePerUnitToSave: trimStringAndRemoveTrailingComma(modifiedState.PricePerUnit),
                    TaxPrcntPerUnitToSave: trimStringAndRemoveTrailingComma(modifiedState.TaxPrcntPerUnit),
                    SequenceIDToSave: modifiedState.SelectedSequenceID,
                    // UserID: modifiedState.UserID,
                    UserID: modifiedState.LoggedInUserID
                }

                // For saving/defining new Product's Information.
                axios.post(`${getAPIHostURL()}/wclient/insertOrUpdateProductInfo`, JsonBody)
                .then(response => {
                    if(response.data.code == "SUCCESS") {
                        if(modifiedState.formViewMode == "editMode") {
                            alert(`Successfully 'updated' Price and Tax for the selected Product.`);
                        } else{
                            alert(`New Product Created Successfully.`);
                        }
 
                        // closeModal();
                        modifiedState.modal = false;
                        getLatestProductInfo(modifiedState)
                        // props.toggle();

                        // Clear all fields in the form when user successfully defines new Firmware or Firmware Version.
                        modifiedState.errors.others = "";
                        modifiedState.selectedProductLine = "";
                        modifiedState.selectedProdNameCode = "";
                        modifiedState.selectedProductName = "";
                        modifiedState.ProdDescription = "";
                        modifiedState.BasicUnit = "";
                        modifiedState.PricePerUnit = "";
                        modifiedState.TaxPrcntPerUnit = "";
                        modifiedState.SelectedSequenceID = "";

                        // document.getElementById("defineNewFWForm").reset();

                    } else {
                        if (response.data.code == 'SQL_ERROR') {
                            console.log("SQL Error while Saving Product Info.")
                            // Tell the user that server is experiencing issues (dont tell about SQL Error). User should try again later.
                            modifiedState.errors.others = t(IDS_AUServerError); // TODO: Define generic language error for this
                        } else {
                            if (response.data.code == 'REQ_PARAMS_MISSING') {
                                console.log("Request Parameters missing while Saving Product Info.")
                                // Tell the user that server is experiencing issues (dont tell about SQL Error). User should try again later.
                                modifiedState.errors.others = t(IDS_AUServerError); // TODO: Define generic language error for this
                            } else {
                                console.log("Should not reach here.")
                                modifiedState.errors.others = t(IDS_AUServerError); // TODO: Define generic language error for this
                            }
                        }
                    }
                    setState({...modifiedState});
                })
                .catch(error => {
                    console.log(error);
                    if (axios.isCancel(error)) {
                        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                    } else {
                        modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    //   modifiedState.errors.others = t(IDS_RegistNetworkError);
                    setState({...modifiedState});
                } 
                });
        }
    }

    const {errors, successfulRegisterMsg}=state;
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    return (
        <div>
            <div className = "container col-lg-12" style = {{display: "flex", justifyContent: "space-between", padding: "0.2rem"}}>
                <div>
                    <button type = "button" 
                        className = "btn-md addCustBtn" 
                        style = {{background: "transparent", pointerEvents: "none", border: "none", color:"transparent"}} 
                    >
                        Add New Product
                    </button>  
                    <IoMdAddCircleOutline className = "addProductIcon"
                        style = {{background: "transparent", pointerEvents: "none", border: "none", color:"transparent"}} />   
                </div>
                <div className = "customerTableHeading">
                    Add, View or Edit Product Details
                </div> 
                <div>
                    <button type = "button" 
                        className = "btn-md addCustBtn" 
                        onClick = {GetDetailsForCreatingNewProduct}
                        style = {{textTransform: 'capitalize'}}
                        >   
                        Add New Product
                    </button>
                    <IoMdAddCircleOutline className = "addProductIcon" onClick = {GetDetailsForCreatingNewProduct} title="Add New Product"/>    
                </div> 
            </div>
            {/* <div style={{borderStyle: "solid", borderWidth: "1px"}}> */}
            <div className="border border-3 ReactTableStyle">
                <ReactTable 
                    columns={state.columns} 
                    data={state.data}
                    passedStateVariable = {state.goToPage1}
                    getCellProps={(cellInfo) => {
                        let modifiedState = state;
                        return {
                            onClick: () => {
                                modifiedState.selectedRowIndex = cellInfo.row.index;
                                setState({...modifiedState});

                                if(cellInfo.column.id == "view") {
                                    onViewProductDetails(cellInfo.row, cellInfo.column); 
                                }  else if(cellInfo.column.id == "edit"){
                                    onEditProductDetails(cellInfo.row, cellInfo.column);
                                }             
                            }, 
                            style: {
                                cursor: '',
                                background: cellInfo.row.index === modifiedState.selectedRowIndex ? '#6c95ba' : '',
                                color: cellInfo.row.index === modifiedState.selectedRowIndex ? 'white' : 'black',
                                alignItems: "center",
                            }
                        }
                    }}
                    // onViewProductDetails = {onViewProductDetails}
                    // onEditProductDetails = {onEditProductDetails}
                />
            </div>

            <Modal size="lg" isOpen={state.modal} backdrop={state.backdrop}>
                <ModalHeader toggle={toggle} style={{textAlign: "center"}}>
                    {state.formViewMode == "viewMode" ? <span> Product Details View Only </span> : 
                    state.formViewMode == "editMode" ? <span> Edit Product Details </span> 
                    : <span>Add New Product </span>}
                </ModalHeader>
                <ModalBody>  
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="container col-lg-6 col-lg-offset-3
                                                    col-md-8 col-md-offset-2">
                                <div className="modal-body box">
                                    <form onSubmit={onSubmit}>
                                        <div>
                                            {(state.formViewMode == "viewMode" || state.formViewMode == "editMode") ?
                                                <div>
                                                    <label className="reg-form-label">Product Line : </label>
                                                    <input type='text' className="input-form" 
                                                                value= {state.selectedProductLine} 
                                                                onChange={onChangeProductLineSelection}
                                                                readOnly
                                                                style={{color: "#505050", backgroundColor: "#F0F0F0"}}       
                                                    />
                                                </div>
                                            :
                                                <div>
                                                    <label className="reg-form-label">Select Product Line : <span className="validationRequiredStarMark">*</span></label>
                                                    <select className="input-form" style={{height:"2.5rem"}}
                                                                                value={state.selectedProductLine}
                                                                                onChange={onChangeProductLineSelection}
                                                    >
                                                        <option value="" disabled select= "true">Select Product Line...</option>
                                                        {(state.ProductLineArr).map((singleProdLine,index) => <option key={index} value={singleProdLine}>{singleProdLine}</option>)}
                                                        
                                                    </select>
                                                    {errors.ProductLine.length > 0 && 
                                                                        <span  className='validationErrorLabel'>{errors.ProductLine}</span>} 
                                                </div>
                                            }   
                                        </div>

                                        <div>
                                            {(state.formViewMode == "viewMode" || state.formViewMode == "editMode") ?
                                                <div style={{marginTop: "0.5rem"}}>
                                                    <label className="reg-form-label">Product Name Code : </label>
                                                    <input type='text' className="input-form" 
                                                                value= {state.selectedProdNameCode} 
                                                                onChange={onChangeProdNameCodeSelection}
                                                                readOnly
                                                                style={{color: "#505050", backgroundColor: "#F0F0F0"}}
                                                    />
                                                </div>
                                            :         
                                                <div style={{marginTop: "0.5rem"}}>
                                                    <label className="reg-form-label">Select or Define Product Name Code : <span className="validationRequiredStarMark">*</span></label>
                                                    <div>
                                                        <input type="list" list="dataListForProdNameCode" className="input-form" 
                                                                                                    value={state.selectedProdNameCode} 
                                                                                                    onChange={onChangeProdNameCodeSelection}
                                                        />                                                  
                                                        <datalist id="dataListForProdNameCode">
                                                            {(state.ProdNameCodeArr).map((singleProdNameCode, index) =>
                                                                <option key={index} value={singleProdNameCode.displayValue}>{singleProdNameCode}</option>
                                                            )}
                                                        </datalist>
                                                        {errors.ProdNameCode.length > 0 && 
                                                                        <span  className='validationErrorLabel'>{errors.ProdNameCode}</span>} 
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            {(state.formViewMode == "viewMode" || state.formViewMode == "editMode") ?
                                                <div style={{marginTop: "0.5rem"}}>
                                                    <label className="reg-form-label">Product Name : </label>
                                                    <input type='text' className="input-form" 
                                                                value= {state.selectedProductName} 
                                                                onChange={OnChangeProductNameSelection}
                                                                readOnly
                                                                style={{color: "#505050", backgroundColor: "#F0F0F0"}}
                                                    />
                                                </div>
                                            :
                                                <div style={{marginTop: "0.5rem"}}>
                                                    <label className="reg-form-label">Select or Define Product Name : <span className="validationRequiredStarMark">*</span></label>
                                                    <div>
                                                        <input type="list" list="dataListForProdName" className="input-form"
                                                                                                    style={{marginTop: "0.5rem"}}
                                                                                                    value={state.selectedProductName} 
                                                                                                    onChange={OnChangeProductNameSelection}
                                                        />
                                                        <datalist id="dataListForProdName">
                                                            {(state.ProdNameArr).map((singleProdName, index) =>
                                                                <option key={index} value={singleProdName.displayValue}>{singleProdName}</option>
                                                            )}
                                                        </datalist>
                                                        {errors.ProdName.length > 0 && 
                                                                        <span  className='validationErrorLabel'>{errors.ProdName}</span>} 
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="inputgroupCustom" style={{marginTop: "0.5rem"}}>
                                            {(state.formViewMode == "viewMode" || state.formViewMode == "editMode") ?
                                                <div className="inputgroupCustom">
                                                    <label className="reg-form-label">Product Description : </label>   
                                                    <textarea className=" input-form" name="comments"   
                                                                            value={state.ProdDescription}
                                                                            onChange={onEnteredProdDescription} 
                                                                            readOnly
                                                                            style={{color: "#505050", backgroundColor: "#F0F0F0"}} 
                                                    /> 
                                                </div>
                                            : 
                                                <div>
                                                    <label className="reg-form-label">Enter Product Description : <span className="validationRequiredStarMark">*</span></label>
                                                    <textarea className=" input-form" name="comments"   
                                                                                    value={state.ProdDescription}
                                                                                    onChange={onEnteredProdDescription} 
                                                    />
                                                    {errors.ProdDescription.length > 0 && 
                                                                        <span  className='validationErrorLabel'>{errors.ProdDescription}</span>} 
                                                </div>
                                            }
                                        </div>

                                        <div style={{marginTop: "0.5rem"}}>
                                            {(state.formViewMode == "viewMode" || state.formViewMode == "editMode") ? 
                                                <div>
                                                    <label className="reg-form-label">Basic Unit : </label>
                                                    <input type="list" list="data" className="input-form" 
                                                            value={state.BasicUnit} onChange={onChangeBasicUnitSelection}
                                                            readOnly
                                                            style={{color: "#505050", backgroundColor: "#F0F0F0"}}
                                                    />   
                                                </div> 
                                            : 
                                                <div>
                                                    <label className="reg-form-label">Select or Define Basic Unit : <span className="validationRequiredStarMark">*</span></label>
                                                    <div>
                                                        <input type="list" list="data" className="input-form" 
                                                            value={state.BasicUnit} onChange={onChangeBasicUnitSelection}
                                                        />
                                                        <datalist id="data">
                                                            {state.BasicUnitArr.map((singleBasicUnit, index) =>
                                                                <option key={index} value={singleBasicUnit.displayValue}>{singleBasicUnit}</option>
                                                            )}
                                                        </datalist>
                                                        {errors.BasicUnit.length > 0 && 
                                                            <span  className='validationErrorLabel'>{errors.BasicUnit}</span>} 
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="inputgroupCustom" style={{marginTop:"0.5rem"}}>
                                            {state.formViewMode == "viewMode"
                                            ?   
                                                <div className="inputgroupCustom">
                                                    <label className="reg-form-label">Price Per Unit (INR): </label>
                                                    <input type='number' className="input-form" 
                                                    value= {state.PricePerUnit} onChange={onEnteredPricePerUnit}
                                                    readOnly
                                                    style={{color: "#505050", backgroundColor: "#F0F0F0"}}
                                                    />
                                                </div>
                                            : 
                                                <div>
                                                    <label className="reg-form-label">Enter Price Per Unit (INR): <span className="validationRequiredStarMark">*</span></label>
                                                    <input type='number' className="input-form" 
                                                                        value= {state.PricePerUnit} onChange={onEnteredPricePerUnit}
                                                    />  
                                                    {errors.PricePerUnit.length > 0 && 
                                                                        <span  className='validationErrorLabel'>{errors.PricePerUnit}</span>}
                                                </div>
                                            }
                                        </div>

                                        <div className="inputgroupCustom" style={{marginTop:"0.5rem"}}>
                                            {state.formViewMode == "viewMode"
                                            ? 
                                                <div className="inputgroupCustom">
                                                    <label className="reg-form-label">Tax Per Unit (%): </label>
                                                    <input type='number' className="input-form" 
                                                                    value= {state.TaxPrcntPerUnit} onChange={onEnteredTaxPrcntPerUnit}
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    style={state.formViewMode == "viewMode" ? 
                                                                            {color: "#505050", backgroundColor: "#F0F0F0"} :
                                                                            {color: "", backgroundColor: "", }}
                                                    />  
                                                </div>
                                            : 
                                                <div>
                                                    <label className="reg-form-label">Enter Tax Per Unit (%): <span className="validationRequiredStarMark">*</span></label>
                                                    <input type='number' className="input-form" 
                                                                    value= {state.TaxPrcntPerUnit} onChange={onEnteredTaxPrcntPerUnit}
                                                    />  
                                                    {errors.TaxPrcntPerUnit.length > 0 && 
                                                                        <span  className='validationErrorLabel'>{errors.TaxPrcntPerUnit}</span>} 
                                                </div>
                                            }                        
                                        </div>

                                        <div>
                                            {(state.formViewMode == "viewMode" || state.formViewMode == "editMode") ? ""
                                                // <div style={{marginTop: "0.5rem"}}>
                                                //     <label className="reg-form-label">State Sequence : </label>
                                                //     <input type='text' className="input-form" 
                                                //                 value= {state.SelectedSequenceID} 
                                                //                 onChange={onChangeSeqIDSelection}
                                                //                 readOnly
                                                //                 style={{color: "#505050", backgroundColor: "#F0F0F0"}}
                                                //     />
                                                // </div>
                                            :
                                                <div className="inputgroupCustom" style={{marginTop:"0.5rem"}}>
                                                    <label className="reg-form-label">Select State Sequence : <span className="validationRequiredStarMark">*</span></label>
                                                    <select  className="input-form" style={{height:"2.5rem"}}
                                                                                    value={state.SelectedSequenceID}
                                                                                    onChange={onChangeSeqIDSelection}
                                                    >
                                                        <option value="" disabled select= "true">Select State Sequence... </option>
                                                        {/* <option value=""> </option> */}
                                                        {(state.SequenceIDArr).map((singleSeqID,index) => <option key={index} value={singleSeqID}>{singleSeqID}</option>)}
                                                        
                                                    </select>
                                                    {errors.SelectedSequenceID.length > 0 && 
                                                                        <span  className='validationErrorLabel'>{errors.SelectedSequenceID}</span>} 
                                                </div>
                                            }
                                        </div>

                                        <div style={{marginTop: "1rem", display: "flex", justifyContent: "space-evenly"}}>
                                            <div>
                                                <button type="button" className="addCustSavebtn" 
                                                    onClick={closeModal} name="Back" 
                                                    style={{pointerEvents: "auto"}}
                                                > 
                                                Back</button>
                                            </div >
                                            <div style={{ display: `${state.formViewMode == "viewMode" ? "none" : "block"}` }}>
                                                <button type="submit" className="addCustSavebtn"  name="Save">
                                                Save</button>
                                            </div>
                                        </div>

                                        <div className = "buttonErrorMessage">
                                            {state.errors.others.length > 0 && 
                                                <p style={{color: 'var(--errorColor)', fontSize: '0.8rem'}} className='error'>{state.errors.others}</p>}
                                        </div> 
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
    
}

export default VcProduct;
