import React from 'react';

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
export const AppRelevantDataContext = React.createContext(
  {
    loggedInUserInfo: {
      userID: '',
      userFirstName: '',
      userLastName: '',
      userIsLoggedInAndRenderedTree: false
    }, 
    toSearchDeviceID : '',
    selectedNodeInfo: {
      nodeID: '',
      nodeTitle: '',
      isRoot: true,
      hasDevc: false,
      isDevc: false,
      deviceType: [],
      parentID: null,
      containsChildNode: false,
      SelectedNodeDeviceType: "",
      nodePath: '',
    },
    chartData: {
      chartParamType: '',
      chartPeriodToView:  '',
      chartGraphToView: '',
      chartStartDateTime: null,
      chartEndDateTime: null,
      isCustomizedDateTimeChecked: false,
    },
    selectedModelInfo: {
      modelName: '',
      modelID: '',
    },
    language: {
      languageToViewIn: '',
      // isReqProcessing: false,
    },    
    loggedInUserPrivilege: {
      Privilege: '',
    },
    devicesToTrack: {
      DevicesInfo: [],
      SelectedDeviceInfo: {},
      ownerOfTrackedDevices: "",
      hasAssociatedTreeNodeIds: false
    },
    navMenu: {
      menuSwitchBtn: "",
    },
    sessJankari:{ 
      sessChallan: '',
      sessChaabi: '',
      sessID: '',
    },
    fcmRegDetails: {
      fcmRegID: ''
    },
    quickTrackParamInfo: {
      quickTrackParam: ''
    },
    invokedLoginPgInfo: {
      loginPagePath: '',
      showNextPagePathAfterLogin: '',
    },

    DevicesTreeStructureSettings: {
      treeStructureSettingsEnabled: false,
      reRenderTreeOnAddEditRemove: false
    },
    
    
    onSelectedDevice: (nodeID, nodeTitle, isRoot, hasDevc, isDevc, deviceType, parentID, SelectedNodeDeviceType, nodePath) => { 
                        console.log( `Received selected Device Info - DevcID: ${nodeID}, 
                                      DevcTitle: ${nodeTitle},
                                      Has selected node contains Devices to show data: ${hasDevc},
                                      Is selected node Device: ${isDevc},
                                      Is Root: ${isRoot},
                                      Type of Devices associated with the selected Node: ${deviceType},
                                      ParentId of the selcted Node: ${parentID},
                                      Device type of the selcted Node: ${SelectedNodeDeviceType}.
                                      Path of Selected Node: ${nodePath}.
                                      Cannot process further as implementation should be in main App.` ) },
    onSelectedRoot: (nodeID, nodeTitle, isRoot, hasDevc, isDevc, deviceType, parentID) => { 
                        console.log( `Received selected Root Info - RootID: ${nodeID}, 
                                      RootTitle: ${nodeTitle},
                                      Is Root: ${isRoot},
                                      Has selected node contains Devices to show data: ${hasDevc},
                                      Is selected node Device: ${isDevc},
                                      Type of Devices associated with the selected Node: ${deviceType},
                                      ParentId of the selcted Node: ${parentID}. 
                                      Cannot process further as implementation should be in main App.` ) },

    onSelectedNodeContainsChildNode: (containsChildNode) => { 
                                      console.log( `Received selected Node Info - 
                                                    Contains child node: ${containsChildNode}.
                                                    Cannot process further as implementation should be in main App.` ) },                                    
    
    onAddUser: (nodeID, nodeTitle, parentID) => { 
                        console.log( `Received selected Root Info - RootID: ${nodeID}, 
                                      RootTitle: ${nodeTitle}
                                      ParentID of the selected NodeID: ${parentID}. 
                                      Cannot process further as implementation should be in main App.` ) },
    onRemoveUser: (nodeID, nodeTitle, parentID) => { 
                        console.log( `Received selected Root Info - RootID: ${nodeID},
                                      RootTitle: ${nodeTitle}
                                      ParentID of the selected NodeID: ${parentID}. 
                                      Cannot process further as implementation should be in main App.` ) },
    onGetRawData: (nodeID, nodeTitle, SelectedNodeDeviceType) => { console.log( `Received selected Root Info - RootID: ${nodeID}, RootTitle: ${nodeTitle}, SelectedDeviceType: ${SelectedNodeDeviceType}. Cannot process further as implementation should be in main App.` ) },
    onDeviceProfile: (nodeID, nodeTitle) => { console.log( `Received selected Root Info - RootID: ${nodeID}, RootTitle: ${nodeTitle}. Cannot process further as implementation should be in main App.` ) },
    onAddUserEmailID: (nodeID, nodeTitle) => { console.log( `Received selected Root Info - RootID: ${nodeID}, RootTitle: ${nodeTitle}. Cannot process further as implementation should be in main App.` ) },
    
    onChangeChartParamType: ( chartParamType) => { console.log( `Received Chart Parameter Type: ${chartParamType}` ) },
    onChangeChartPeriodToView: ( chartPeriodToView) => { console.log( `Received Chart Period: ${chartPeriodToView}` ) },
    onChangeChartPeriodToViewWRTDateTime: ( chartStartDateTime, chartEndDateTime ) => { console.log( `Received Chart Start Date Time: ${chartStartDateTime}, Received Chart End Date Time: ${chartEndDateTime}` ) },
    onChangeChartGraphView: ( chartGraphToView ) => { console.log( `Received Graph type: ${chartGraphToView}` ) },

    onChangeChartRelatedContext: ( chartParamType, chartPeriodToView, isCustomizedDateTimeChecked, chartStartDateTime, chartEndDateTime ) => { 
                                      console.log(`Received Chart Type: ${chartParamType}`, 
                                                  `Received Chart Period: ${chartPeriodToView}`,
                                                  `Received Boolean Customized DateTime Checked Checkbox value: ${isCustomizedDateTimeChecked}`, 
                                                  `Received Chart Start Date Time: ${chartStartDateTime}`, 
                                                  `Received Chart End Date Time: ${chartEndDateTime}`) 
                                },
    
    onChangeCustomizedDateTimeChecked: ( isCustomizedDateTimeChecked ) => { console.log( `Received Boolean Customized DateTime Checked Checkbox value: ${isCustomizedDateTimeChecked}` ) },

    onChangeloggedInUserInfo: ( UserFirstName, UserLastName ) => { console.log( `Received loggedin user firstName value: ${UserFirstName}, Received loggedin user lastName value: ${UserLastName}` ) },

    onChangeModelName: ( modelID, modelName) => { console.log( `Received ModelID: ${modelID}, ModelName: ${modelName}` ) },
    
    onChangeLanguageToViewIn: ( languageToViewIn) => { console.log( `Received Language to view in: ${languageToViewIn}` ) },

    onChangeLoggedInUserPvg: ( privilege ) => { console.log( `Received Logged in User Privilege: ${privilege}` ) },

    onChangeProcessingReq: ( isReqProcessing ) => { console.log( `Received Logged in User Privilege: ${isReqProcessing}` ) },

    onChangeShowNotification: (showNotification) => { console.log( `Received value to show notification: ${showNotification}` ) },

    onChangeTrackDeviceInfo: ( DevicesInfo, SelectedDeviceInfo, ownerOfTrackedDevices, hasAssociatedTreeNodeIds ) => { 
                              console.log( `Received DeviceID: ${DevicesInfo}`,
                                            `Received Selected Device Info: ${SelectedDeviceInfo}`,
                                            `Received ownerOfTrackedDevices: ${ownerOfTrackedDevices}`,
                                            `Has searched user associated tree node id: ${hasAssociatedTreeNodeIds}.`
                            ) },
    
    onChangeSwitchBtn: (menuSwitchBtn) =>{ console.log( `Received menuSwitchBtn Value: ${menuSwitchBtn }` )},

    onChangeFCMRegId: (fcmRegID) => {console.log( `Received fcmRegID Value: ${fcmRegID }` )},
    
    onChangeSessJankari: (inSessChalan, inSessChaabi, inSessID) => { console.log( `Received Logged in User Sess Chalan: ${inSessChalan}, Sess Chaabi: ${inSessChaabi} and SessID: ${inSessID}` ) },                                                                      
    
    onChangeQuickTrackParam: (inParam) => { console.log( `Received Default Param: ${inParam}` ) },   
    
    onTreeRenderedFirstTime: (inuserIsLoggedInAndRenderedTree) => { console.log( `Received boolean value indicating DeviceTree is rendered or not: ${inuserIsLoggedInAndRenderedTree}` ) },   
    
    onInvokedloginFromDiffrentPath: (loginPagePath, showNextPagePathAfterLogin) => { console.log( `Received Login Url Path: ${loginPagePath}, Show Next Page Path After Login: ${showNextPagePathAfterLogin}` ) },

    onEnabledTreeStructureSettings: (treeStructureSettingsEnabled, reRenderTreeOnAddEditRemove) =>{ console.log( `Received treeStructureSettingsEnabled Value: ${treeStructureSettingsEnabled }, Tree Refreshed: ${reRenderTreeOnAddEditRemove}` )},

    updateAppRelevantDataContextFromLocalStorage: () => { 
      console.log( `Received call for 'updateAppRelevantDataContextFromLocalStorage'. Cannot process further as implementation should be in main App.` );
      return false; // Return false to indicate that the value could not be retrieved. It should be retreived by the MainApp.
    }
  }
);
