import React, { useState, useEffect, useContext, useMemo } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import '../CSS/VcCustomer.css'
import '../CSS/VcTrackDevices.css'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday} from '../../vtUtil';
import { FaSearch, FaCircle}  from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import { AppRelevantDataContext } from '../../AppContext';
import {ACTIVE_STATUS_MINIMUM_TIME, CLIENT_TYPE, PROD_DEVC_OWNER_USER_ID, PVG_PRODUCTION_SUPERVISOR, PVG_PRODUCTION_WORKER} from '../../VcConstants';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

const defaultPropGetter = () => ({});

const ReactTable = ({ columns, data, getCellProps = defaultPropGetter, renderBasedOnTable}) => {

    const [selectedRowIndexForUpdate, setSelectedRowIndex] = useState(null);
    const [selectedRowIndexTrackDvcTblForUpdate, setSelectedRowIndexTrackDvcTbl] = useState(null);

    const filterTypes = React.useMemo(
        () => ({         
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const tableColumns = useMemo(() => columns, [columns]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        // dispatch,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns: tableColumns,
            data,
            defaultColumn,
            initialState: {
                pageSize: renderBasedOnTable == "SearchResultTable" || 
                            renderBasedOnTable == "TrackedDeviceDataTable" ? 5 : 1,
            },
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    )

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;No Data Found.</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()} style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trforHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead hidden = {renderBasedOnTable != "UserInfoTable" ? false : true}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"
                                    onClick = {(e, handleOriginal) => {
                                        if(renderBasedOnTable == "SearchResultTable") {
                                            setSelectedRowIndex(row.index);
                                            // dispatch({ type: "searchTable", payload: row.index });
                                        } else if(renderBasedOnTable == "TrackedDeviceDataTable"){
                                            setSelectedRowIndexTrackDvcTbl(row.index);
                                            // dispatch({ type: "trackTable", payload: row.index });
                                        }
                                        else {
                                            //nothing
                                        }
                                    }} 
                                    style = {
                                        (renderBasedOnTable == "SearchResultTable") ?  
                                        {
                                            cursor: '',
                                            background: row.index === selectedRowIndexForUpdate ? '#6c95ba' : '',
                                            color: row.index === selectedRowIndexForUpdate ? 'white' : 'black',
                                            alignItems: "center",
                                        }
                                        : (renderBasedOnTable == "TrackedDeviceDataTable") ?  
                                        {
                                            cursor: '',
                                            background: row.index === selectedRowIndexTrackDvcTblForUpdate ? '#6c95ba' : '',
                                            color: row.index === selectedRowIndexTrackDvcTblForUpdate ? 'white' : 'black',
                                            alignItems: "center",
                                        } 
                                        :
                                        {}
                                    }       
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {
                                            style: cell.column.style,
                                            },
                                            getCellProps(cell),
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || <tr style = {{backgroundColor: "white"}}><td><span  style={{paddingLeft:"1rem", color: "green", display: "flex", justifyContent:"left"}}>{"No Data Found."}</span></td></tr>
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>
            
            {renderBasedOnTable != "UserInfoTable" &&
                <div className='w-100 d-flex justify-content-end antdPagination'>
                    <div className='d-flex py-1 justify-content-end align-items-center'>
                        <Pagination showQuickJumper 
                            defaultCurrent={pageIndex + 1} 
                            total={pageOptions.length * 10}
                            // total={10 * 10} 
                            onChange={(page) => gotoPage(page - 1)} 
                            showSizeChanger={false}
                            current={pageIndex + 1} 
                        />

                        <Select
                            style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                            aria-label="rows per page"
                            className="paginationDropdown mySelector"
                            value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                            onChange={e => setPageSize(Number(e))} 
                            options={[
                                {
                                    label: "5 rows",
                                    value: 5
                                },
                                {
                                    label: "10 rows",
                                    value: 10
                                },
                                {
                                    label: "20 rows",
                                    value: 20
                                },
                                {
                                    label: "25 rows",
                                    value: 25
                                },
                                {
                                    label: "100 rows",
                                    value: 100
                                }
                            ]} // Assuming options is the array of options for the Select component
                        />
                    </div>
                </div>
            }

            {/* <div hidden = {renderBasedOnTable != "UserInfoTable" ? false : true} >
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcTrackDevices (props) {
    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {

        if(id != "lastDataReceivedAtDtTm") {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
                });
        } else {
            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]

                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                    else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        }
    };

    const [state, setState] = useState({
        invokedFrom: "",
        heloo: false,
        trackDeviceModal: false,
        backdrop: "static",
        ownersEmailID: "",
        firstName: "",
        lastName: "",
        mobileNo: "",
        strDeviceID: "",
        selectedOwnersEmailID: "",
        searchedBy: "searchedByUser",
        data: [],
        trackDeviceTableDataArr: [],
        selectedUserData: [],
        DevicesInfoArr: [],
        deviceOwnerContext: "",

        errors: {
            others: "",
            ownersEmailID: "",
            firstName: "",
            lastName: "",
            mobileNo: "",
            strDeviceID: "",
            requiredFiled: "",
        },
        hasAssociatedTreeNodeIds: false,

        // React table columns for displaying Search Result of Device Owner's Info.
        columns: [
            {   
                Header:() => <div className="AlertLogTableHeader">Action</div>, 
                accessor: "TrackDevice",
                width: 290,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
                disableFilters: true,
                Cell: ( props ) => {
                    return (
                    <button type = "button" className = "createOrderButton">
                        Track Devices / View User Tree
                    </button> 
                    );
                },
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Email ID</div>,  
                accessor: 'OwnersEmailID',
                filter: filterCaseInsensitive,
                width: 300,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"

                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Mobile No</div>,  
                accessor: 'MobileNo',
                filter: filterCaseInsensitive,
                width: 230,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 

            {       
                Header:() => <div className="AlertLogTableHeader">First Name</div>,  
                accessor: 'FirstName',
                width: 240,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Last Name</div>,  
                accessor: 'LastName',
                width: 230,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },    
        ],

        // React table columns for displaying Selected Owner's Info for tracking a devices.
        selectedUserColumns: [
            {       
                Header:() => <div className="AlertLogTableHeader">Tracked User Email ID</div>,  
                accessor: 'selctUsrEmailID',
                width: 353,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"

                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Mobile No</div>,  
                accessor: 'selctUsrMobNo',
                disableFilters: true,
                width: 250,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
    
                }),
            }, 

            {       
                Header:() => <div className="AlertLogTableHeader">First Name</div>,  
                accessor: 'selctUsrFirstName',
                disableFilters: true,
                width: 250,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
    
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Last Name</div>,  
                accessor: 'selctUsrLastName',
                disableFilters: true,
                width: 250,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },    
        ],

        // React table columns for displaying tracked devices details of selected owner's.
        trackDeviceColumns: [
            {   
                Header:() => <div className="AlertLogTableHeader">Action</div>, 
                accessor: "trackDevice",
                width: 115,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                disableFilters: true,
                Cell: ( rowInfo ) => {
                    return (
                        <button type = "button" className = "createOrderButton"
                            id= "openWindow" value="Open Window" onClick = {() => {window.open('/device/dashboard', 'newTabWindow')}} 
                        >
                            View Data
                        </button> 
                    );
                },
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Device ID</div>,  
                accessor: 'deviceID',
                filter: filterCaseInsensitive,
                width: 180,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Device Name</div>,  
                accessor: 'deviceName',
                filter: filterCaseInsensitive,
                width: 180,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 

            {       
                Header:() => <div className="AlertLogTableHeader">Owner Email ID</div>,  
                accessor: 'deviceOwnerID',
                width: 210,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            // It will display a Green dot if devices sent last data in interval of "ACTIVE_STATUS_MINIMUM_TIME"(Device is Active). 
            // It will display a Red dot if device did not sent last data in interval of "ACTIVE_STATUS_MINIMUM_TIME"(Device is InActive).
            {       
                Header:() => <div className="AlertLogTableHeader">Active <br/> Status</div>,  
                accessor: 'activeStatus',
                width: 115,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "center",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                disableFilters: true,
                Cell: ( rowInfo ) => {
                    return (
                        // In MySQL there is no explicit boolean variable so we are returning "true" or "false"
                        // as a string. so here we are explicitly comparing with double quoted boolean string.  
                        <FaCircle title = {rowInfo.cell.value == "true" ?  "Online" : "Offline"}
                            style = {{color: rowInfo.cell.value == "true" ? "#00ff00" : "red", 
                              fontSize:"1.1rem", border: "1px solid", borderRadius: "50%", borderColor: "darkgray"}}
                        />
                    );
                },
            },   

            { 
                Header:() => <div className="AlertLogTableHeader">Last Data <br/> Received At</div>, 
                Cell: props => getCustomizedTodaysDate(props.value),
                accessor: 'lastDataReceivedAtDtTm',
                filter: filterCaseInsensitive,
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 200,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"

                }),
            },  

            {       
                Header:() => <div className="AlertLogTableHeader">Current <br/> State</div>,  
                accessor: 'currentState',
                width: 115,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },  

            {       
                Header:() => <div className="AlertLogTableHeader">Hardware <br/> ID</div>,  
                accessor: 'hardwareID',
                width: 120,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Hardware <br/> Model</div>,  
                accessor: 'hardwareModal',
                width: 160,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Firmware <br/> ID</div>,  
                accessor: 'firmwareID',
                width: 120,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Firmware <br/> Name</div>,  
                accessor: 'firmwareName',
                width: 160,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Firmware <br/> Version</div>,  
                accessor: 'firmwareVersion',
                width: 120,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },
        ],

    });

    // Function will call on clicking of TrackDevice Button.
    // Fetching the value from react table selected User "OwnersEmailID" and set it in the state.
    // Calling function "getDetailedInformationForTrackingDevices" and sending the state to that function.
    // This fetched Emailid from ReactTable will use to call axios "getDetailedInformationForTrackingDevices"
    const onClickTrackDevices = (rowInfo, column) => {

        let modifiedState = state;

        modifiedState.selectedOwnersEmailID = rowInfo.original.OwnersEmailID;
        modifiedState.invokedFrom = "";

        let stateUserdata = [];
        modifiedState.selectedUserData = [];

        let singleDeviceOwnUserInfo ={
            selctUsrFirstName: rowInfo.original.FirstName == null ? "" : rowInfo.original.FirstName,
            selctUsrLastName: rowInfo.original.LastName == null ? "" : rowInfo.original.LastName,
            selctUsrEmailID: rowInfo.original.OwnersEmailID == null ? "" : rowInfo.original.OwnersEmailID,
            selctUsrMobNo: rowInfo.original.MobileNo == null ? "" : rowInfo.original.MobileNo,   
        };
        stateUserdata.push(singleDeviceOwnUserInfo);
        modifiedState.selectedUserData = stateUserdata;
        modifiedState.deviceOwnerContext = rowInfo.original.OwnersEmailID;

        getDetailedInformationForTrackingDevices(modifiedState)
    }

    useEffect(() =>{
        let appRelevantDataContextValue = context;

        appRelevantDataContextValue.onChangeTrackDeviceInfo([], {}, "");
        appRelevantDataContextValue.onSelectedDevice("", "", false);
    }, []);

    
    // it should take to the WebApp where we can see all the menus (Devices, Charts, Alerts, Settings etc
    const onClickViewData = (rowInfo, column) => {
        let modifiedState = state;

        let selectedDeviceInfo = {};
        // Fetching selected Row deviceID & deviceName to add in context to display a single device data page of selected device ID.
        let selectedDeviceID = rowInfo.original.deviceID;
        let selectedDeviceName = rowInfo.original.deviceName;
        let SelectedNodeDeviceType = rowInfo.original.SelectedNodeDeviceType;

        selectedDeviceInfo = {
            selectedDeviceID : selectedDeviceID,
            selectedDeviceName : selectedDeviceName
        }

        let appRelevantDataContextValue = context;
        // Save into the App Context any current DeviceID changes that might have happened on the UI
        appRelevantDataContextValue.onChangeTrackDeviceInfo(modifiedState.DevicesInfoArr, selectedDeviceInfo, modifiedState.selectedOwnersEmailID, modifiedState.hasAssociatedTreeNodeIds);
        appRelevantDataContextValue.onSelectedDevice(selectedDeviceID, selectedDeviceName, false, null, true, [], null, SelectedNodeDeviceType);

        // setState(modifiedState); Not doing setState Because of if we add any value in appRelevantDataContextValue then it will automaticaly setState.
    }

    // it should take to the WebApp where we can see all the menus (Devices, Charts, Alerts, Settings etc
    const onClickViewTreeData = () => {
        let modifiedState = state;

        let selectedDeviceInfo = {};

        let appRelevantDataContextValue = context;
        
        selectedDeviceInfo = {
            selectedDeviceID : "trackedDevicesID",
            selectedDeviceName : "Tracked Devices"
        }

        let selectedNodeID = "trackedDevicesID";
        let selectedNodeName = "Tracked Devices";
        appRelevantDataContextValue.onChangeTrackDeviceInfo(modifiedState.DevicesInfoArr, selectedDeviceInfo, modifiedState.selectedOwnersEmailID, modifiedState.hasAssociatedTreeNodeIds);
        appRelevantDataContextValue.onSelectedRoot(selectedNodeID, selectedNodeName, true);
        window.open('/device/dashboard', 'newTabWindow');
        // setState(modifiedState); Not doing setState Because of if we add any value in appRelevantDataContextValue then it will automaticaly setState.
    }

    // Radio Button to Switch between Search by User Info and search by Device ID.
    // on switch we also Empty the TextBoxes and Array.
    const onSearchByRadioBtnChange = (e) => {
        
        let modifiedState = state;

        modifiedState.errors.requiredFiled = "";
        modifiedState.firstName = "";
        modifiedState.lastName = "";
        modifiedState.ownersEmailID = "";
        modifiedState.mobileNo = "";
        modifiedState.strDeviceID = "";
        modifiedState.data = [];
        modifiedState.searchedBy = e.target.value;
        modifiedState.invokedFrom = "";

        setState({...modifiedState});  
    }

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return "Today " + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    const onClickTrackByDevcID = (event) => {

        let modifiedState = state;

        modifiedState.selectedOwnersEmailID = "";
        modifiedState.invokedFrom = "";

        event.preventDefault();
        getDetailedInformationForTrackingDevices(modifiedState)
    }

    const onClickTrackProdDevc = (event) => {

        let modifiedState = state;

        modifiedState.selectedOwnersEmailID = PROD_DEVC_OWNER_USER_ID;
        modifiedState.invokedFrom = "TrackProdDevc";

        getDetailedInformationForTrackingDevices(modifiedState)
    }

    const onClickTrackOnlineProdDevc = (event) => {

        let modifiedState = state;

        modifiedState.selectedOwnersEmailID = PROD_DEVC_OWNER_USER_ID;
        modifiedState.invokedFrom = "TrackOnlineProdDevc";

        getDetailedInformationForTrackingDevices(modifiedState)
    }

    const onClickTrackNotInstalledDevc = (event) => {

        let modifiedState = state;

        modifiedState.invokedFrom = "TrackNotInstalledDevc";

        getDetailedInformationForTrackingDevices(modifiedState);
    }

    const onClickTrackOfflineDevc = (event) => {

        let modifiedState = state;

        modifiedState.invokedFrom = "TrackOfflineDevc";

        getDetailedInformationForTrackingDevices(modifiedState);
    }
    

    // Function will get detaild information of Tracked Devices and store in the React Table.
    const getDetailedInformationForTrackingDevices = (inModifiedState = null) => {

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        };

        modifiedState.hasAssociatedTreeNodeIds = false;
        let DeviceIDArrToSent = [];

        let jsonBody = {}

        if( modifiedState.invokedFrom != null && modifiedState.invokedFrom.length > 0) {

            if(modifiedState.invokedFrom == "TrackProdDevc" || modifiedState.invokedFrom == "TrackOnlineProdDevc") {
                jsonBody = {
                    InvokedFrom: modifiedState.invokedFrom,
                    UserID: PROD_DEVC_OWNER_USER_ID,
                    ActiveStatusMinimumTime: ACTIVE_STATUS_MINIMUM_TIME // time in seconds - Minimum gap in log time 
                                                                        // after which device is considered inactive
                };

            } else if(modifiedState.invokedFrom == "TrackNotInstalledDevc" || modifiedState.invokedFrom == "TrackOfflineDevc") {
                jsonBody = {
                    InvokedFrom: modifiedState.invokedFrom,
                    ActiveStatusMinimumTime: ACTIVE_STATUS_MINIMUM_TIME // time in seconds - Minimum gap in log time 
                                                                        // after which device is considered inactive
                };

            } else {
                jsonBody = {
                    InvokedFrom: modifiedState.invokedFrom,
                    ActiveStatusMinimumTime: ACTIVE_STATUS_MINIMUM_TIME // time in seconds - Minimum gap in log time 
                                                                        // after which device is considered inactive
                };
            }
        } else if (modifiedState.searchedBy == "searchByDeviceID" && modifiedState.selectedOwnersEmailID != PROD_DEVC_OWNER_USER_ID) {

            // if any one of the required fields are not entered by user then return the function and show error message.
            // Getting the Tracked Devices Information on basis of device id entered by user.
            // Sending DeviceIDArr and ACTIVE_STATUS_MINIMUM_TIME in json body.

            if( (modifiedState.strDeviceID == null || modifiedState.strDeviceID == "" || modifiedState.strDeviceID.length <= 0)) {

                modifiedState.errors.requiredFiled = "Please enter Device ID - (required field)";
                setState({...modifiedState});  
                return

            } else {
                DeviceIDArrToSent.push(modifiedState.strDeviceID);
                modifiedState.selectedOwnersEmailID = "";
                jsonBody = {
                    DeviceIDArr: DeviceIDArrToSent,
                    ActiveStatusMinimumTime: ACTIVE_STATUS_MINIMUM_TIME  // time in seconds - Minimum gap in log time 
                                                                        // after which device is considered inactive

                };
            }
        
        } else {
            // if any one of the required fields are not entered by user then return the function and show error message.
            // Getting the Tracked Devices Information on basis of "selectedOwnersEmailID" Selected by user.
            // sending selectedOwnersEmailID and ACTIVE_STATUS_MINIMUM_TIME in json body.
            if( (modifiedState.selectedOwnersEmailID == null || modifiedState.selectedOwnersEmailID == "" || modifiedState.selectedOwnersEmailID.length <= 0)) {

                modifiedState.errors.requiredFiled = "Owners Email ID is Missing";
                setState({...modifiedState});  
                return

            } else {

                jsonBody = {
                    ClientType: CLIENT_TYPE,
                    UserID: modifiedState.selectedOwnersEmailID,
                    ActiveStatusMinimumTime: ACTIVE_STATUS_MINIMUM_TIME  // time in seconds - Minimum gap in log time 
                                                                        // after which device is considered inactive
                };
            }
        }

        axios.post(`${getAPIHostURL()}/wclient/getDetailedInformationForTrackingDevices`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if((response.data.detailedInfoForDeviceTracking == null || response.data.detailedInfoForDeviceTracking.length <= 0) &&
                    (response.data.hasAssociatedTreeNodeIds == null || response.data.hasAssociatedTreeNodeIds.length <= 0 || (response.data.hasAssociatedTreeNodeIds <= 0 || response.data.hasAssociatedTreeNodeIds > 0))
                ) {
                    if(modifiedState.invokedFrom == "TrackProdDevc" || modifiedState.invokedFrom == "TrackOnlineProdDevc") {
                        alert(`The specified User "[ ${modifiedState.selectedOwnersEmailID} ]" currently does not own any devices or does not have any live devices.`);
        
                    } else if(modifiedState.invokedFrom == "TrackNotInstalledDevc") {
                        alert(`There are no Devices present which is in Demo or Sold state.`);
    
                    } else if(modifiedState.invokedFrom == "TrackOfflineDevc") {
                        alert(`There are no offline Devices present on the field.`);

                    } else if(modifiedState.searchedBy == "searchedByUser") {
                        alert(`The specified User "[ ${modifiedState.selectedOwnersEmailID} ]" currently does not own any devices or does not have any live devices.`);
                    } else {
                        alert(`The specified Device "[ ${DeviceIDArrToSent} ]" is not yet registered under a DeviceOwner or is not live.`);
                    }

                } else {

                    modifiedState.trackDeviceModal = true;

                    let stateTrackDeviceTableDataArr = [];
                    modifiedState.trackDeviceTableDataArr = [];  
                    modifiedState.DevicesInfoArr = [];      

                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing product line Details data.
                    stateTrackDeviceTableDataArr = [...modifiedState.trackDeviceTableDataArr]
                                            
                    const infoForDeviceTrackingData = response.data.detailedInfoForDeviceTracking;

                    if(infoForDeviceTrackingData != null && infoForDeviceTrackingData.length > 0) {

                        modifiedState.deviceOwnerContext = infoForDeviceTrackingData[0].DevcOwnerEmailID;

                        for(let i = 0; i < infoForDeviceTrackingData.length; i++) {
                            let deviceTrackingDetails = infoForDeviceTrackingData[i];

                            let DeviceID = deviceTrackingDetails.DeviceID;
                            let DeviceName = deviceTrackingDetails.DeviceName;
                            let ModelID = deviceTrackingDetails.ModelID;
                            let SelectedNodeDeviceType = deviceTrackingDetails.SelectedNodeDeviceType;

                            modifiedState.DevicesInfoArr.push({DeviceID, DeviceName, ModelID, SelectedNodeDeviceType});

                            let DeviceTrackingDetailsLastDataReceivedAtDtTm = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(deviceTrackingDetails.LastDataReceivedAtDtTm);

                            let singleDeviceTrackingDetails = {
                                deviceID: deviceTrackingDetails.DeviceID == null ? "" : deviceTrackingDetails.DeviceID,
                                deviceName: deviceTrackingDetails.DeviceName == null ? "" : deviceTrackingDetails.DeviceName,
                                currentState: deviceTrackingDetails.DeviceCurrentState == null ? "" : deviceTrackingDetails.DeviceCurrentState,
                                activeStatus: deviceTrackingDetails.DeviceActiveStatus == null ? "" : deviceTrackingDetails.DeviceActiveStatus,

                                lastDataReceivedAtDtTm: DeviceTrackingDetailsLastDataReceivedAtDtTm,
                                hardwareID: deviceTrackingDetails.ModelID == null ? "" : deviceTrackingDetails.ModelID,
                                hardwareModal: deviceTrackingDetails.ModelName == null ? "" : deviceTrackingDetails.ModelName,
                                firmwareID: deviceTrackingDetails.SoftwareID == null ? "" : deviceTrackingDetails.SoftwareID,

                                firmwareName: deviceTrackingDetails.SoftwareName == null ? "" : deviceTrackingDetails.SoftwareName,
                                firmwareVersion: deviceTrackingDetails.Version == null ? "" : deviceTrackingDetails.Version,
                                SelectedNodeDeviceType: deviceTrackingDetails.SelectedNodeDeviceType == null ? "" : deviceTrackingDetails.SelectedNodeDeviceType,
                                deviceOwnerID: deviceTrackingDetails.DevcOwnerEmailID == null ? "" : deviceTrackingDetails.DevcOwnerEmailID
                            };
                            stateTrackDeviceTableDataArr.push(singleDeviceTrackingDetails);
                        } 
                        modifiedState.trackDeviceTableDataArr = stateTrackDeviceTableDataArr;
                    }                
                    
                    // Adding user details into selectedUserData when we track device by DeviceID
                    if(modifiedState.searchedBy == "searchByDeviceID"){
                        let stateUserdata = [];
                        modifiedState.selectedUserData = [];
                
                        let singleDeviceOwnUserInfo ={
                            selctUsrFirstName: infoForDeviceTrackingData[0].FirstName == null ? "" : infoForDeviceTrackingData[0].FirstName,
                            selctUsrLastName: infoForDeviceTrackingData[0].LastName == null ? "" : infoForDeviceTrackingData[0].LastName,
                            selctUsrEmailID: infoForDeviceTrackingData[0].DevcOwnerEmailID == null ? "" : infoForDeviceTrackingData[0].DevcOwnerEmailID,
                            selctUsrMobNo: infoForDeviceTrackingData[0].DevcOwnerPhoneNo == null ? "" : infoForDeviceTrackingData[0].DevcOwnerPhoneNo,   
                        };
                        stateUserdata.push(singleDeviceOwnUserInfo);
                        modifiedState.selectedUserData = stateUserdata;

                    }
                    
                    modifiedState.hasAssociatedTreeNodeIds = response.data.hasAssociatedTreeNodeIds > 0 ? true : false;
                
                }
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                } else if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});  
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});  
            }
        })
    }

    // Function will get searched user details on the basis of entered keywords(EmailID, PhoneNo, FirstName, & LastName).
    const searchUserInfoForTrackingDevices = (event) => {
    
        let modifiedState = state;
        event.preventDefault();
        modifiedState.data = [];
        modifiedState.invokedFrom = "";

        modifiedState.errors.requiredFiled = ((modifiedState.ownersEmailID == null || modifiedState.ownersEmailID == '' || modifiedState.ownersEmailID.length < 0) &&
                                              (modifiedState.firstName == null || modifiedState.firstName == '' || modifiedState.firstName.length < 0) &&
                                              (modifiedState.lastName == null || modifiedState.lastName == '' || modifiedState.lastName.length < 0) &&
                                              (modifiedState.mobileNo == null || modifiedState.mobileNo == '' || modifiedState.mobileNo.length < 0)
                                             )

        ? "Please enter atleast any one field for Search." 
        : "";

        // if any one of the required fields are not entered by user then return the function and show error message.
        if(modifiedState.errors.requiredFiled.length > 0) {
            
            setState({...modifiedState});  
            return
        }
        else {
            // Convert email to lowercase 
            modifiedState.ownersEmailID = (modifiedState.ownersEmailID != null && modifiedState.ownersEmailID.length > 0) ? modifiedState.ownersEmailID.toLowerCase() : "" ;

            const jsonBody = {
                FirstName: trimStringAndRemoveTrailingComma(modifiedState.firstName), 
                LastName: trimStringAndRemoveTrailingComma(modifiedState.lastName),
                EmailID: trimStringAndRemoveTrailingComma(modifiedState.ownersEmailID),
                PhoneNo: modifiedState.mobileNo, 
                InvokeFromPg: "TrackDevices",
            };

            axios.post(`${getAPIHostURL()}/wclient/searchUserInfoForTrackingDevices`, jsonBody)
            .then(response => {
                
                if(response.data.code == 'SUCCESS') {

                    if(response.data.retreivedSearchResult == null || response.data.retreivedSearchResult.length <= 0) {
                        modifiedState.required = "No Search Result Found";
                        alert("No matched search result with entered keywords.")

                    } else {
                        let stateSearchDataArr = [];
                        modifiedState.data = [];       

                        // React table checks using referential integrity. So if you do not create a
                        // new array (but just add to the existing array), the change detection will not trigger.
                        // So create a brand new array from existing product line Details data.
                        stateSearchDataArr = [...modifiedState.data]
                                                
                        const retreivedSearchData = response.data.retreivedSearchResult;

                        for(let i = 0; i < retreivedSearchData.length; i++) {
                            const searchResultDetails = retreivedSearchData[i];
                            let singleSearchRsltData = {
                                FirstName: searchResultDetails.FirstName == null ? "" : searchResultDetails.FirstName,
                                LastName: searchResultDetails.LastName == null ? "" : searchResultDetails.LastName,
                                OwnersEmailID: searchResultDetails.UserEmailID == null ? "" : searchResultDetails.UserEmailID,
                                MobileNo: searchResultDetails.UserPhoneNo == null ? "" : searchResultDetails.UserPhoneNo,
                            };
                            stateSearchDataArr.push(singleSearchRsltData);
                        } 
                        
                        modifiedState.data = stateSearchDataArr;
                    }
                } else {
                    if(response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the Required parameters were not sent to the Server
                        modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                    } else if(response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                setState({...modifiedState});  
            })
            .catch(error => {
                console.log(error);
                console.log("Network error:");
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});  
                }
            })
        }
    }

    const onTrackDeviceToggle = () => {
        let appRelevantDataContextValue = context;


        state.trackDeviceModal = !state.trackDeviceModal;
        setState({...state}); 
        appRelevantDataContextValue.onChangeTrackDeviceInfo([], {}, "");
        appRelevantDataContextValue.onSelectedDevice("", "");
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = state.errors;
        
        if(name == "mobileNo" && value.length > 12 ) {
            return;
        }

        switch (name) {

            case 'firstName': 
                errors.requiredFiled = ""       
            break;

            case 'lastName': 
                errors.requiredFiled = ""       
            break;

            case 'ownersEmailID': 
                errors.requiredFiled = ""  
            break;

            case 'mobileNo': 
                errors.requiredFiled = ""       
            break;

            case 'strDeviceID': 
                errors.requiredFiled = ""       
            break;

            default:
            break;
        }

        setState({
            ...state,
            errors, 
            [name]: value,             
        });
    }

    const {errors}=state;

    let objPvgProductionSupervisor = (props.objPrivilege != null &&
        props.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) &&
        props.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true
    );

    let objPvgProductionWorker = (props.objPrivilege != null &&
        props.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) &&
        props.objPrivilege[PVG_PRODUCTION_WORKER] == true
    );

    return (
        <div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-12,col-md-12 ,col-sm-12 ">
                        <div className = "customerTableHeading" style={{marginTop: "0.2rem"}}>Track Device(s) / View User Tree</div> 
                        <div className="modal-body p-4 box" style={{marginTop: "0.2rem"}}>        
                            <form onSubmit= {state.searchedBy == "searchedByUser" ? 
                            searchUserInfoForTrackingDevices : state.searchedBy == "searchByDeviceID" ? 
                            onClickTrackByDevcID : ""}>
                                
                            {(objPvgProductionSupervisor || objPvgProductionWorker)
                                ?
                                    <div>
                                        <div style={{display: "flex"}}>
                                            <div style={{flex: 1}}>
                                                <button type="button" className="trackDevcbtn" 
                                                onClick = {onClickTrackProdDevc}>
                                                    Track Production Device(s)
                                                </button>
                                            </div>  
                                            <div style={{flex: 1}}>
                                                <button type="button" className="trackDevcbtn" 
                                                onClick = {onClickTrackOnlineProdDevc}>
                                                    Track Online Production Device(s)
                                                </button>
                                            </div>  
                                            
                                        </div> 
                                        <div style={{display: "flex"}}>
                                            <div style={{flex: 1}}>
                                                <button type="button" className="trackDevcbtn" 
                                                onClick = {onClickTrackOfflineDevc}>
                                                    Track All Offline Device(s)
                                                </button>
                                            </div>   
                                            <div style={{flex: 1}}>
                                                <button type="button" className="trackDevcbtn" 
                                                onClick = {onClickTrackNotInstalledDevc}>
                                                    Track Device(s) Not Yet Installed
                                                </button>
                                            </div>  
                                        </div> 
                                    </div>
                                    
                                : 
                                    <div/>     
                                } 
                                <div className="trackRadioDiv">
                                    <div className = "trackradioBox">
                                        <div>
                                            <input type='radio' name='searchBy'
                                                id = "searchByUser"
                                                value= "searchedByUser"
                                                onChange={onSearchByRadioBtnChange} noValidate 
                                                defaultChecked={true}
                                            /> 
                                            <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Search by User</span>
                                        </div>

                                        <div>
                                            <input type='radio' name='searchBy'  
                                                id = "searchByDeviceID" 
                                                value= "searchByDeviceID"
                                                onChange={onSearchByRadioBtnChange} noValidate 
                                                
                                            />
                                            <span style={{marginLeft: "0.3rem"}}> Search by Device ID</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{display: state.searchedBy == "searchedByUser" ? "block" : "none", }} >
                                    <div className="form-group trackFrmrow">
                                        <div className="trackFrmLblAndInputDiv" >
                                            <div className="inputgroupCustom">
                                                <label className="trackFrmLabel">EmailID:</label>
                                                <div className="trackFrmInput">
                                                    <input type='text' name='ownersEmailID' className="trackFrmInputForm"
                                                        value={state.ownersEmailID}
                                                        onChange={handleChange} noValidate    
                                                    />
                                                </div>     
                                            </div>
                                        </div>

                                        <span className="trackFrmLblAndInputDiv" >
                                            <div className="inputgroupCustom">
                                                <label className="trackFrmLabel">Mobile No:</label>
                                                <div className="trackFrmInput">
                                                    <input type='number' name='mobileNo' className=" trackFrmInputForm"
                                                        value={state.mobileNo}
                                                        onChange={handleChange} noValidate    
                                                    />
                                                </div>     
                                            </div>
                                        </span>
                                    </div>

                                    <div className="form-group trackFrmrow">
                                        <div className="trackFrmLblAndInputDiv" >
                                            <div className="inputgroupCustom">
                                                <label className="trackFrmLabel">First Name:</label>
                                                <div className="trackFrmInput">
                                                    <input type='text' name='firstName' className=" trackFrmInputForm"
                                                        value={state.firstName}
                                                        onChange={handleChange} noValidate    
                                                    />
                                                </div>     
                                            </div>
                                        </div>

                                        <span className="trackFrmLblAndInputDiv" >
                                            <div className="inputgroupCustom">
                                                <label className="trackFrmLabel">Last Name:</label>
                                                <div className="trackFrmInput">
                                                    <input type='text' name='lastName' className=" trackFrmInputForm"
                                                        value={state.lastName}
                                                        onChange={handleChange} noValidate    
                                                    />
                                                </div>     
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div  style={{display: state.searchedBy == "searchByDeviceID" ? "block" : "none"}}>
                                    <div className="inputgroupCustom">
                                        <label className="reg-form-label">Device ID: 
                                            <span className="addCustRequiredMarkStar">*</span>
                                        </label>    
                                        <input type='text' name='strDeviceID' className="input-form"  
                                            value={state.strDeviceID}
                                            onChange={handleChange} noValidate 
                                        />
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                    <div>
                                        <button type="submit" className="addCustSavebtn"  name="Save" >
                                            {state.searchedBy == "searchedByUser" ? "Search" : "Track Device"}
                                        </button>
                                    </div>   
                                </div>                                        
                                <div className = "buttonErrorMessage">
                                    {errors.others.length > 0 && 
                                        <p className='addCustError' style={{textAlign: "center"}}>{errors.others}</p>}
                                    {errors.requiredFiled.length > 0 && 
                                        <p className='addCustError' style={{textAlign: "center"}}>{errors.requiredFiled}</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div style={{display: state.searchedBy == "searchedByUser" ? "block" : "none", 
                                borderStyle: "solid", marginTop: "0.5rem", borderWidth: "1px"}}> */}
                <div style={{display: state.searchedBy == "searchedByUser" ? "block" : "none"}}>
                    <div className = "customerTableHeading"> Search Result</div> 
                    {/* <div className = "customerTableHeading" style ={{borderBottom:" 1px solid rgba(0,0,0,.1)"}}> Search Result</div>  */}
                    <div className="border border-3 ReactTableStyle">
                        <ReactTable  data = {state.data}
                            columns = {state.columns}
                            getCellProps={(cellInfo) => {
                                if (cellInfo.row != undefined) {
                                    let onclick;
                                    return {
                                        onClick: () => {
                                            if(cellInfo.column.id == "TrackDevice") {
                                                onClickTrackDevices(cellInfo.row, cellInfo.column)     
                                            }       
                                        },    
                                    }
                                } else {
                                    return {}
                                }}
                            }
                            renderBasedOnTable = {"SearchResultTable"}
                        />
                    </div>
                </div>
            </div>
            
            <Modal size="xl" isOpen={state.trackDeviceModal} toggle={onTrackDeviceToggle} backdrop={state.backdrop}>
                <ModalHeader toggle={onTrackDeviceToggle}>
                    {state.invokedFrom == "TrackProdDevc" ? "Track Production Device(s)" :
                        state.invokedFrom == "TrackOnlineProdDevc" ?  "Track Online Production Device(s)" : 
                        state.invokedFrom == "TrackNotInstalledDevc" ?  "Track Device(s) Not Yet Installed" : 
                        state.invokedFrom == "TrackOfflineDevc" ?  "Track All Offline Device(s)" : "Tracked Device(s)"
                    } 
                </ModalHeader>  
                <ModalBody>  
                {
                    (state.invokedFrom != "TrackProdDevc" && state.invokedFrom != "TrackOnlineProdDevc" &&
                        state.invokedFrom != "TrackNotInstalledDevc" && state.invokedFrom != "TrackOfflineDevc" 
                    )
                    ?
                    // <div style={{borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", marginBottom: "1rem"}}> 
                        <div className="border border-3 ReactTableStyle" style = {{ marginBottom: "1rem"}}>
                            <ReactTable  data = {state.selectedUserData}
                                columns = {state.selectedUserColumns}
                                getCellProps = {(cellInfo) => ({})}
                                renderBasedOnTable = {"UserInfoTable"}
                            />
                        </div>
                    // </div> 
                    : 
                    null
                }

                {/* {state.hasAssociatedTreeNodeIds ?
                    <div style={{textAlign:"center", marginBottom:"1rem"}}>
                        <button type = "button" className = "createOrderButton" onClick={onClickViewTreeData} id= "openWindow" value="Open Window">
                            View User Tree
                        </button> 
                    </div>
                    :
                    <div/>
                } */}

                {state.trackDeviceTableDataArr != null && state.trackDeviceTableDataArr.length > 0 ?
                    // <div style={{borderStyle: "solid", marginTop: "0rem", borderWidth: "1px"}}> 
                    <div>
                        <div className = "customerTableHeading"> Tracked Device Data </div> 
                        {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", borderColor: "lightgray"}}> */}
                        <div className="border border-3 ReactTableStyle">
                            <ReactTable  data = {state.trackDeviceTableDataArr}
                                columns = {state.trackDeviceColumns}
                                getCellProps={(cellInfo) => {
                                    if (cellInfo.row != undefined) {
                                        return {
                                            onClick: () => {
                                                if(cellInfo.column.id == "trackDevice") {
                                                    onClickViewData(cellInfo.row, cellInfo.column)                
                                                }   
                                            },      
                                        }
                                    } else {
                                        return {}
                                    }
                                }
                                }
                                renderBasedOnTable = {"TrackedDeviceDataTable"}
                            />
                        </div>
                    </div>
                    :
                    <div/>
                }
                </ModalBody>
            </Modal>

        </div>
    );
}

export default VcTrackDevices;