import React, { Component } from 'react'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import { AppRelevantDataContext } from '../../AppContext';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

export class VcCrmUsersAndPrivileges extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            errors: {
                others: "",
                errRemoveUserPresent: "",
                errPvgToRemove: "",
                errPvgToAdd: "",
                errValidEmail: "",
            },
            arrCrmUserAndItsPvg: [],
            arrAllCrmUserID: [],
            arrListOfCrmPvg: [],
            typeOfUserId: "",

            arrUsersToBeRemove: [],
            selectedUserID: "",
            arrAssignedPvgOfSelectedUserID: [],
            selectedPvgToRemove: "",
            arrPvgToBeRemoved: [],
            selectedPvgToAdd: "",
            arrPvgToBeAdd: [],
            filteredArrPvgToBeAdd: [],
            removePvgRef: React.createRef(),
            updatePvgRef: React.createRef(),
            UserIdRef: React.createRef(),

        }
    }

    // In mozila firefox datalist not shows data in Single click bellow code is fixes of mozila firefox data list problem.
    onClickDataList = (e) => {

        let UserIdDataListShow = this.state.UserIdRef.current;
        UserIdDataListShow.style.display = "block";
        UserIdDataListShow.style.display = "none";

        if(this.state.typeOfUserId == "ExistingUserID" || this.state.typeOfUserId == "NewUserID") {
            let UpdatePvgDataListShow = this.state.updatePvgRef.current;
            UpdatePvgDataListShow.style.display = "block";
            UpdatePvgDataListShow.style.display = "none";
        }

        if(this.state.typeOfUserId == "ExistingUserID") {
            let RemovePvgDataListShow = this.state.removePvgRef.current;
            RemovePvgDataListShow.style.display = "block";
            RemovePvgDataListShow.style.display = "none";
        }
    }

    componentDidMount() {

        this.getAllCrmUsersWithPrivileges();
    }

    // Getting all the CRM User along with thier assigend crm privileges.
    getAllCrmUsersWithPrivileges = (inModifiedState = null) => {

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        axios.post(`${getAPIHostURL()}/wclient/getAllCrmUsersWithPrivileges`)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {    

                if(response.data.retrievedCrmUserWithPrivileges == null && response.data.retrievedCrmUserWithPrivileges.length <= 0 
                    && response.data.arrListOfCrmPvg == null && response.data.arrListOfCrmPvg.length <= 0 ) {
                    modifiedState.errors.others = "No Crm User found on Server";

                } else {        
                    modifiedState.arrCrmUserAndItsPvg = [];
                    modifiedState.arrAllCrmUserID = [];
                    modifiedState.arrListOfCrmPvg = [];
                    // Array List of all the crm privileges defined in Server vConstant.
                    modifiedState.arrListOfCrmPvg = response.data.arrListOfCrmPvg;
                    // Array of all the crm users and their privileges.
                    modifiedState.arrCrmUserAndItsPvg = response.data.retrievedCrmUserWithPrivileges;
                    modifiedState.arrAllCrmUserID = [...new Set(modifiedState.arrCrmUserAndItsPvg.map(CrmUsersWithPrivileges => CrmUsersWithPrivileges.UserID))];
                }
               
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState)
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        });
    }   

    onChangeUserID = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedUserID = e.target.value;
        modifiedState.errors.others = "";
        modifiedState.errors.errValidEmail = "";
        modifiedState.errors.errPvgToRemove = "";
        modifiedState.errors.errPvgToAdd = "";
        modifiedState.selectedPvgToRemove = "";
        modifiedState.selectedPvgToAdd = "";
        modifiedState.typeOfUserId = "";
        modifiedState.arrPvgToBeRemoved = [];
        modifiedState.arrPvgToBeAdd = [];

        this.setState(modifiedState);

    }

    onClickProceed = () => {
        let modifiedState = this.state;

        if(!validEmailRegex.test(this.state.selectedUserID)) {
            modifiedState.errors.errValidEmail = "EmailID is not valid.";
        } else {

            modifiedState.arrAssignedPvgOfSelectedUserID = [];
            modifiedState.arrPvgToBeAdd = [];
            modifiedState.arrPvgToBeRemoved = [];
            modifiedState.filteredArrPvgToBeAdd = [];

            // If Entred or selected User ID is allready exist in arrAllCrmUsersID list. then we consider it as existing user.
            // and set typeOfUserID to "ExistingUserID" and based on typeOfUserID value shows the Remove Privilege and Add Privilege Field to update privilege.
            // If Entred or selected User ID is not exist in arrAllCrmUsersID list. then we consider it as New UserID.
            // and set typeOfUserID to "NewUserID" and based on typeOfUserID value shows the only Add Privilege Field to Add New User.
            if(modifiedState.arrAllCrmUserID.includes(modifiedState.selectedUserID)) {
                modifiedState.typeOfUserId = "ExistingUserID";

                for(let i = 0; i < modifiedState.arrCrmUserAndItsPvg.length; i++) {
                    if(modifiedState.arrCrmUserAndItsPvg[i].UserID == null || modifiedState.arrCrmUserAndItsPvg[i].UserID.length <= 0 || 
                        modifiedState.arrCrmUserAndItsPvg[i].PrivilegeName == null || modifiedState.arrCrmUserAndItsPvg[i].PrivilegeName.length <= 0) {
                        continue;
                    } else {

                        if(modifiedState.arrCrmUserAndItsPvg[i].UserID.toLowerCase() == modifiedState.selectedUserID.toLowerCase()) {
                            if(!modifiedState.arrAssignedPvgOfSelectedUserID.includes(modifiedState.arrCrmUserAndItsPvg[i].PrivilegeName)) {
                                modifiedState.arrAssignedPvgOfSelectedUserID.push(modifiedState.arrCrmUserAndItsPvg[i].PrivilegeName);
                            }
                        }
                    }
                }

                modifiedState.filteredArrPvgToBeAdd 
                    = modifiedState.arrListOfCrmPvg.filter(function(singleCrmPvg) {
                        return !modifiedState.arrAssignedPvgOfSelectedUserID.includes(singleCrmPvg); 
                    });

            } else {
                modifiedState.typeOfUserId = "NewUserID";
                modifiedState.filteredArrPvgToBeAdd = modifiedState.arrListOfCrmPvg;
            }
        }

        this.setState(modifiedState);
    }

    addUserWithPvgOrChangePvg = () => {
        let modifiedState = this.state;

        if(modifiedState.selectedUserID == null || modifiedState.selectedUserID.length <= 0) {
            modifiedState.errors.errValidEmail = "Please Select User EmailID First.";
            this.setState(modifiedState);
            return;
        }

        if(modifiedState.typeOfUserId != null && modifiedState.typeOfUserId.length > 0 && modifiedState.typeOfUserId == "ExistingUserID" &&
            (
                (modifiedState.arrPvgToBeRemoved == null || modifiedState.arrPvgToBeRemoved.length <= 0 ) &&
                (modifiedState.arrPvgToBeAdd == null || modifiedState.arrPvgToBeAdd.length <= 0 )
            )
        ) {
            modifiedState.errors.errPvgToAdd = "Please Select Privilege to be Removed or Privilege to be Added.";
            this.setState(modifiedState);
            return;
        }

        if(modifiedState.typeOfUserId != null && modifiedState.typeOfUserId.length > 0 && modifiedState.typeOfUserId == "NewUserID" &&
            (
                (modifiedState.arrPvgToBeAdd == null || modifiedState.arrPvgToBeAdd.length <= 0 )
            )
        ) {
            modifiedState.errors.errPvgToAdd = "Please Select Privilege to be Added.";
            this.setState(modifiedState);
            return;
        }

        if(modifiedState.arrAssignedPvgOfSelectedUserID != null && modifiedState.arrPvgToBeRemoved != null &&
            modifiedState.arrAssignedPvgOfSelectedUserID.length == modifiedState.arrPvgToBeRemoved.length &&
            (modifiedState.arrPvgToBeAdd == null || modifiedState.arrPvgToBeAdd.length <= 0)
        ) {
            let strMsg = `You have chosen to Remove all Privileges of the user with User-ID:'${modifiedState.selectedUserID}'\n` + 
                        `Hence, User will be assigned General User Privilege.` +
                        ` Do you want to continue?`;
            if(!window.confirm(strMsg)) {
                return;
            }
        }

        let jsonBody = {
            userID: trimStringAndRemoveTrailingComma(modifiedState.selectedUserID).toLowerCase(),
            arrPvgToRemove: modifiedState.arrPvgToBeRemoved,
            arrPvgToAdd: modifiedState.arrPvgToBeAdd,
            TypeOfUserId: modifiedState.typeOfUserId,
        }

        axios.post(`${getAPIHostURL()}/wclient/addUserWithPvgOrChangePvg`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') { 
                
                if(modifiedState.typeOfUserId == "ExistingUserID") {
                    alert(`Successfully Updated Privileges of User with User ID: ${modifiedState.selectedUserID}`);

                } else {
                    alert(`Successfully Added User with User ID: ${modifiedState.selectedUserID}`);
                }

                modifiedState.selectedUserID = "";
                modifiedState.arrPvgToBeRemoved = [];
                modifiedState.arrPvgToBeAdd = [];
                modifiedState.arrAssignedPvgOfSelectedUserID = [];
                modifiedState.selectedPvgToRemove = "";
                modifiedState.selectedPvgToAdd = "";
                modifiedState.filteredArrPvgToBeAdd = [];
                modifiedState.errors.others = "";
                modifiedState.errors.errPvgToAdd = "";
                modifiedState.errors.errPvgToRemove = "";
                modifiedState.errors.errRemoveUserPresent = "";
                modifiedState.errors.errValidEmail = "";
                modifiedState.typeOfUserId = "";

                this.getAllCrmUsersWithPrivileges(modifiedState);
               
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later (required Parameters not sent).';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        });

    }

    onChangeRemoveUserPvg = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedPvgToRemove = e.target.value;

        modifiedState.errors.others = "";
        modifiedState.errors.errPvgToAdd = "";
        modifiedState.errors.errPvgToRemove = "";
        
        this.setState(modifiedState);
    }

    onClickRemovePvg = () => {
        let modifiedState =  this.state;

        let EnteredPvg = modifiedState.selectedPvgToRemove;

        modifiedState.errors.others = "";
        modifiedState.errors.errPvgToAdd = "";
        modifiedState.errors.errPvgToRemove = "";
        
        if(EnteredPvg == null || EnteredPvg.length <= 0 || !modifiedState.arrAssignedPvgOfSelectedUserID.includes(EnteredPvg)) {
            modifiedState.errors.errPvgToRemove = 'Please Enter Valid Privilege.';
            this.setState(modifiedState);
            return;
        } 

        if(modifiedState.arrPvgToBeRemoved.findIndex(singlePvgToBeRemoved => EnteredPvg.toLowerCase() == singlePvgToBeRemoved.toLowerCase()) >= 0  
        ) {
            modifiedState.errors.errPvgToRemove = 'Entered Privilege already exist.';
            this.setState(modifiedState);

        } else {

            modifiedState.selectedPvgToRemove = "";
            modifiedState.errors.others = "";
            modifiedState.errors.errPvgToRemove = "";
            // Just wanted to show last inserted privileges at the top of DisplayBox.
            modifiedState.arrPvgToBeRemoved.unshift(EnteredPvg);
            modifiedState.filteredArrPvgToBeAdd.push(EnteredPvg);
        }
        this.setState(modifiedState);
    }

    onChangeAddUserPvg = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedPvgToAdd = e.target.value;

        modifiedState.errors.others = "";
        modifiedState.errors.errPvgToAdd = "";
        modifiedState.errors.errPvgToRemove = "";
        
        this.setState(modifiedState);
    }

    onClickAddPvg = () => {
        let modifiedState =  this.state;

        let EnteredPvg = modifiedState.selectedPvgToAdd;
        
        modifiedState.errors.others = "";
        modifiedState.errors.errPvgToAdd = "";
        modifiedState.errors.errPvgToRemove = "";
        
        if(EnteredPvg == null || EnteredPvg.length <= 0 || !modifiedState.filteredArrPvgToBeAdd.includes(EnteredPvg)) {
            modifiedState.errors.errPvgToAdd = 'Please Enter Valid Privilege.';
            this.setState(modifiedState);
            return;
        } 

        if(modifiedState.arrPvgToBeAdd.findIndex(item => EnteredPvg.toLowerCase() == item.toLowerCase()) >=0  
        ) {
            modifiedState.errors.errPvgToAdd = 'Entered Privilege already exist.';
            this.setState(modifiedState);

        } else {

            modifiedState.selectedPvgToAdd = "";
            modifiedState.errors.others = "";
            modifiedState.errors.errPvgToAdd = "";
            // Just wanted to show last inserted privileges at the top of DisplayBox.
            modifiedState.arrPvgToBeAdd.unshift(EnteredPvg);
        }
        this.setState(modifiedState);
    }
    
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="container col-lg-10 col-md-10 col-sm-10 ">
                            <div className = "customerTableHeading" style={{marginTop: "0.2rem"}}>
                                CRM Users and Privileges
                            </div> 
                            <div className="modal-body box" style={{marginTop: "0.2rem", padding:"1rem"}}>                  
                                <div className="form-group addCustForm">
                                    <div className="inputgroupCustom">
                                        <label className="addCustFormLabelWithRequiredFiled" style={{marginTop:"0.4rem"}}>
                                        Add/Select User Emails ID:
                                        </label>
                                        <div className="addCustInputAndError">
                                            <input type="list" list="dataListUserId" 
                                                className="input-form" 
                                                value={this.state.selectedUserID} 
                                                onClick={this.onClickDataList}
                                                onChange={this.onChangeUserID}
                                            />
                                            <datalist id="dataListUserId" ref = {this.state.UserIdRef}>
                                                {this.state.arrAllCrmUserID.map((singleCrmUserID, index) =>
                                                    <option key={index} value={singleCrmUserID}>{singleCrmUserID}</option>
                                                )}
                                            </datalist>
                                            {this.state.errors.errValidEmail.length > 0 && 
                                                <span  className='validationErrorLabel'>{this.state.errors.errValidEmail}</span>} 
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: (this.state.typeOfUserId == "ExistingUserID" || this.state.typeOfUserId == "NewUserID") ? "none" : "block", 
                                        textAlign: "center"}}>
                                    <button 
                                        type="submit" 
                                        className="addCustSavebtn" 
                                        onClick={this.onClickProceed}
                                    >
                                        Proceed
                                    </button>
                                    <div className = "buttonErrorMessage">
                                        {this.state.errors.others.length > 0 && 
                                            <p  className='addCustError' style={{textAlign: "center", marginTop: "0.5rem"}}>{this.state.errors.others}</p>}
                                    </div>
                                </div>

                                {this.state.typeOfUserId != null && this.state.typeOfUserId.length > 0 && (this.state.typeOfUserId == "ExistingUserID" || this.state.typeOfUserId == "NewUserID")
                                ?
                                    <div>
                                        {this.state.typeOfUserId == "ExistingUserID"
                                        ?
                                            <div style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", marginBottom:"0.6rem", background:"#fafafa"}}>
                                                <div className="form-group addCustForm">
                                                    <div className="inputgroupCustom">
                                                        <label className="addCustFormLabelWithRequiredFiled" style={{marginTop:"0.4rem"}}>
                                                            Select Privilege To Remove:
                                                        </label>
                                                        <div className="addCustInputAndError">
                                                            <input 
                                                                type="list" list="FilteredDiagnosisName" 
                                                                className="addCustInputForm"
                                                                style={{width: "85%"}}
                                                                onClick={this.onClickDataList}
                                                                value={this.state.selectedPvgToRemove} 
                                                                onChange={this.onChangeRemoveUserPvg} 
                                                            />
                                                            <datalist id="FilteredDiagnosisName" ref = {this.state.removePvgRef}>
                                                                {this.state.arrAssignedPvgOfSelectedUserID.map((singlePvg,index) => 
                                                                    <option key={index} value={singlePvg}></option>)}
                                                            </datalist>
                                                            <button type="button" 
                                                                    style={{padding:"0.4rem", borderRadius:"5px", marginLeft:"0.5rem", 
                                                                            background:"var(--primaryColor)", color:"white", border:"tranparent",
                                                                            fontWeight:"700"
                                                                        }} 
                                                                    onClick={this.onClickRemovePvg}> Remove 
                                                            </button> 
                                                        </div>     
                                                    </div>
                                                </div>
                                                <div className="form-group addCustForm">
                                                    <div className="inputgroupCustom">
                                                        <label className="addCustFormLabelWithRequiredFiled">
                                                            Privileges To Be Removed:
                                                        </label>
                                                        <div className="addCustInputAndError">
                                                            <select size="5" 
                                                                    style={{width:"100%", borderRadius:"5px", 
                                                                            border:"1px solid var(--primaryColor)", color:"black" 
                                                                    }}
                                                            >
                                                                {(this.state.arrPvgToBeRemoved).map((addedPvg,index) => <option key={index} value={addedPvg}>{addedPvg}</option>)}
                                                            </select>
                                                        </div>     
                                                    </div>
                                                </div>
                                                <div className = "buttonErrorMessage">
                                                    {this.state.errors.errPvgToRemove.length > 0 && 
                                                        <p  className='addCustError' style={{textAlign: "center"}}>{this.state.errors.errPvgToRemove}</p>}
                                                </div>
                                            </div>
                                        :
                                            null
                                        }
                                        <div 
                                            style={{border:"1px solid var(--primaryColor)", 
                                                    padding:"0.5rem", borderRadius:"5px", 
                                                    marginBottom:"0.6rem", background:"#fafafa",
                                            }}
                                        >
                                            <div className="form-group addCustForm">
                                                <div className="inputgroupCustom">
                                                    <label className="addCustFormLabelWithRequiredFiled" style={{marginTop:"0.4rem"}}>
                                                        Select Privilege To Add:
                                                    </label>
                                                    <div className="addCustInputAndError">
                                                        <input 
                                                            type="list" list="FilteredPvgToAdd" 
                                                            className="addCustInputForm"
                                                            style={{width: "90%"}}
                                                            onClick={this.onClickDataList}
                                                            value={this.state.selectedPvgToAdd} 
                                                            onChange={this.onChangeAddUserPvg} 
                                                        />
                                                        <datalist id="FilteredPvgToAdd" ref = {this.state.updatePvgRef}>
                                                            {this.state.filteredArrPvgToBeAdd.map((singlePvg,index) => 
                                                            <option key={index} value={singlePvg}></option>)}
                                                        </datalist>
                                                        <button type="button" 
                                                                style={{padding:"0.4rem", borderRadius:"5px", marginLeft:"0.5rem", 
                                                                        background:"var(--primaryColor)", color:"white", border:"tranparent",
                                                                        fontWeight:"700"
                                                                    }} 
                                                                onClick={this.onClickAddPvg}> Add 
                                                        </button> 
                                                    </div>     
                                                </div>
                                            </div>
                                            <div className="form-group addCustForm">
                                                <div className="inputgroupCustom">
                                                    <label className="addCustFormLabelWithRequiredFiled">
                                                        Added Privileges:
                                                    </label>
                                                    <div className="addCustInputAndError">
                                                        <select size="5" 
                                                                style={{width:"100%", borderRadius:"5px", 
                                                                        border:"1px solid var(--primaryColor)", color:"black" 
                                                                }}
                                                        >
                                                            {(this.state.arrPvgToBeAdd).map((addedPvg,index) => <option key={index} value={addedPvg}>{addedPvg}</option>)}
                                                        </select>
                                                    </div>   
                                                </div> 
                                                <div className = "buttonErrorMessage">
                                                    {this.state.errors.errPvgToAdd.length > 0 && 
                                                        <p  className='addCustError' style={{textAlign: "center"}}>{this.state.errors.errPvgToAdd}</p>}
                                                </div>  
                                            </div>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                            <div>
                                                <button 
                                                    type="submit" 
                                                    className="addCustSavebtn" 
                                                    onClick={this.addUserWithPvgOrChangePvg}
                                                >
                                                    {this.state.typeOfUserId == "ExistingUserID" ? "Change Privilege(s)" : "Add User with Selected Privilege(s)"}
                                                </button>
                                            </div>
                                        </div>
                                        <div className = "buttonErrorMessage">
                                            {this.state.errors.others.length > 0 && 
                                                <p  className='addCustError' style={{textAlign: "center"}}>{this.state.errors.others}</p>}
                                        </div>
                                    </div>
                                :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

VcCrmUsersAndPrivileges.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcCrmUsersAndPrivileges
