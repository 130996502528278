import React, { Component } from 'react'
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext } from '../../AppContext';
import { getLocalTimezoneOffset, convertUTCDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateWithFormatDDMMMYY} from '../../vtUtil';
import { CO, O3, CH4, LPG, CO2, HUM, NO2, VOC, PM1, PM10, PM25, TEMP, LAT, NH3, H2S, SO2, NH3OD, NH3OD_RS,
    LOW, POOR_L, POOR_U, MAX, SMOKE, MRI, VRI } from '../../VcConstants';
import { IDS_Close, IDS_Download, IDS_StartDateTime, IDS_EndDateTime, IDS_RDEndDtGrtCurDt, IDS_RDEndDTAdjust,
        IDS_RDStartDTAdjust, IDS_RDStartDTAdjustNotMaxRng, IDS_RDStartDTSetAcrodEndDT, IDS_RDStartDTAdjustMinPossible,
        IDS_RDEndDTNotInRng, IDS_RDEndDTSetAcrodStartDT, IDS_RDDeviceNotContainRawData, IDS_RDCheckDownload,
        IDS_RDDownloadNotSave, IDS_LoginServerIssue, IDS_AUSrvrIssueReqParamsNotSent, IDS_RegistNetworkError, IDS_NoRawDataFound,
        IDS_WarningPeriodForSubs, IDS_GracePeriodForSubs, IDS_SubsInfoMissing, IDS_RestrictDlDataAfterExpiredSubs, IDS_SubsServerIssue, 
        IDS_AUSrvrIssueReqParamsNotSentForSubsInfo, IDS_NoteToProdUsr, IDS_RenewSubsToDlDataAfterExpiredSubs, IDS_SubNotApplicable,
        IDS_DevcBlockedDueToNonPaymentOfSomeCost } from '../../VcLanguage';

const DEVICE_ID = 'DeviceID';
const DEVICE_NAME = 'DeviceName';
const FMT_LOG_DATE = 'FmtLogDate';
const FMT_LOG_TIME = 'FmtLogTime';
const CSV_COLUMN_SEPERATOR = ',';
const CSV_LINE_SEPERATOR = '\r\n';

export class VcDeviceRawData extends Component {
    constructor(props) {
        super(props);

        this.csvLink = React.createRef();

        this.textFile = null;

        this.state = {
            notifyOnCloseForm: this.props.onCloseRawDataPopup,
            DeviceOwnerEmailID: this.props.LoggedInUserID,
            SelectedDeviceName: this.props.DeviceName,
            SelectedDeviceID: this.props.DeviceID,
            StartDateTime: new Date(new Date().setHours(new Date().getHours() - 24)),
            EndDateTime: new Date(),
            DeviceRawData: [],
            csvData : [],
            errors:'',
            timeRelatedErrors:'',
            subscriptionStartDtTm: null,
            subscriptionEndDtTm: null,
            gracePeriod: 0,
            warningPeriod: 0,
            subscriptionErr: '',
            subscriptionInfo: '',
            noteToShowIfApplicable: '',
            subNotApplicableErr: '',
            showPopUpForRawDataDownload: false,
            bCheckDeviceBlockedStatus: false,
            havingPvgToViewBlockedDeviceData: false,
        }
    }

    componentDidMount() {
        this.getCurrentSubscriptionInfo();
    }

    // Get SubscriptionInfo for the selected Device and show appropriate subscription info
    // for the particular Device.
    // During Warning Period: At the top of the Chart show message: "Your subscription will expire on <Date>. 
    //                            Please renew the same before the end date to be able to view older data."
    // During Grace Period: At the top of the Chart show message: "Your subscription has expired on <Date>. 
    //                            Please renew the same at the earliest to be able to view older data."
    // After Expiration: At the top of the Chart show message: "Your subscription has expired on <Date>. 
    //                            You will be able to view only last <7> days data. Please renew your subscription at the earliest."
    getCurrentSubscriptionInfo = () => {

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;    

        let localTimeZone = (new Date()).getTimezoneOffset();
        let modifiedState = this.state;

        modifiedState.subscriptionErr = '';
        modifiedState.subscriptionInfo = '';
        modifiedState.subNotApplicableErr = '';
        modifiedState.subscriptionStartDtTm = null;
        modifiedState.subscriptionEndDtTm = null;
        modifiedState.gracePeriod = 0;
        modifiedState.warningPeriod = 0;
        modifiedState.showPopUpForRawDataDownload = false;
        modifiedState.bCheckDeviceBlockedStatus = false;
        modifiedState.havingPvgToViewBlockedDeviceData = false;

        let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let jsonParams = {
            DeviceID: modifiedState.SelectedDeviceID,
            localTimeZone: getLocalTimezoneOffset( localTimeZone ),
            LoggedInUserID: LoggedInUserID
        }

        axios.post(`${getAPIHostURL()}/wclient/getCurrentSubscriptionInfo`,jsonParams)
        .then(response => {
            if(response.data.code == "SUCCESS" ) {

                modifiedState.showPopUpForRawDataDownload = true;

                if((response.data["countOfHavingPvgToViewOlderDataWithoutSubs"] != null && 
                    response.data["countOfHavingPvgToViewOlderDataWithoutSubs"].length > 0 &&
                    response.data["countOfHavingPvgToViewOlderDataWithoutSubs"] > 0 
                  ) ||
                  response.data["isSpecialProductionUserID"]
                ) {
                   modifiedState.noteToShowIfApplicable = t(IDS_NoteToProdUsr);
                } else {
                    modifiedState.noteToShowIfApplicable = '';
                }

                // Show blocked Devices Data to Production/Support Users.                
                modifiedState.bCheckDeviceBlockedStatus = response.data.bCheckDeviceBlockedStatus != null && 
                                                          response.data.bCheckDeviceBlockedStatus == 0 ? true : false;
                modifiedState.havingPvgToViewBlockedDeviceData = response.data.havingPvgToViewBlockedDeviceData != null && 
                                                                 response.data.havingPvgToViewBlockedDeviceData > 0 ? true : false;

                if(response.data["SubscriptionInfo"] == null || response.data["SubscriptionInfo"].length <=0) {
                    modifiedState.subNotApplicableErr = t(IDS_SubNotApplicable);
                } else {

                    let receivedSubscriptionInfo = response.data["SubscriptionInfo"];

                    if(receivedSubscriptionInfo[0]["SubscriptionStartDtTm"] != null && receivedSubscriptionInfo[0]["SubscriptionEndDtTm"] != null ) {

                        modifiedState.subscriptionStartDtTm = convertLocalDateWithFormatDDMMMYY(receivedSubscriptionInfo[0]["SubscriptionStartDtTm"]);
                        modifiedState.subscriptionEndDtTm = convertLocalDateWithFormatDDMMMYY(receivedSubscriptionInfo[0]["SubscriptionEndDtTm"]);
                        modifiedState.gracePeriod = receivedSubscriptionInfo[0]["GracePeriod"];
                        modifiedState.warningPeriod = receivedSubscriptionInfo[0]["WarningPeriod"];

                        let receivedSubsEndDtTmForWarningPeriod = new Date(receivedSubscriptionInfo[0]["SubscriptionEndDtTm"]);
                        let receivedSubsEndDtTmForGracePeriod = new Date(receivedSubscriptionInfo[0]["SubscriptionEndDtTm"]);

                        let DtTmAfterRemovalOfWarningPeriod = new Date(receivedSubsEndDtTmForWarningPeriod.setDate(receivedSubsEndDtTmForWarningPeriod.getDate() - receivedSubscriptionInfo[0]["WarningPeriod"]));
                        let DtTmAfterAddingOfGracePeriod = new Date(receivedSubsEndDtTmForGracePeriod.setDate(receivedSubsEndDtTmForGracePeriod.getDate() + receivedSubscriptionInfo[0]["GracePeriod"]));

                        let currentTimeStamp = new Date();

                        if(new Date(modifiedState.subscriptionEndDtTm) > currentTimeStamp && DtTmAfterRemovalOfWarningPeriod < currentTimeStamp) {
                            modifiedState.subscriptionInfo = t(IDS_WarningPeriodForSubs, modifiedState.subscriptionEndDtTm);
                        } else if(new Date(modifiedState.subscriptionEndDtTm) <= currentTimeStamp && DtTmAfterAddingOfGracePeriod > currentTimeStamp) {
                            modifiedState.subscriptionInfo = t(IDS_GracePeriodForSubs, modifiedState.subscriptionEndDtTm);
                        } else if(new Date(modifiedState.subscriptionEndDtTm) < currentTimeStamp) {
                            // If a user has valid suscription plan and he/she is in Grace Period thn only he can Download the Data.
                            modifiedState.subscriptionInfo = t(IDS_RestrictDlDataAfterExpiredSubs, modifiedState.subscriptionEndDtTm);
                        } else {
                            modifiedState.subscriptionInfo = '';
                        }
                    } else {
                        modifiedState.subNotApplicableErr = t(IDS_SubNotApplicable);
                    }
                }
                this.setState(modifiedState);

            } else if(response.data.code == 'MODEL_WITHOUT_SUBSCRIPTION') {
                console.log("Subscription plan is not applicable for this type of Model.")
                modifiedState.subscriptionErr = '';
                modifiedState.subscriptionInfo = '';
                modifiedState.subNotApplicableErr = '';
                modifiedState.showPopUpForRawDataDownload = true;
                this.setState(modifiedState);

            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    modifiedState.subscriptionErr = t(IDS_AUSrvrIssueReqParamsNotSentForSubsInfo);
                    this.setState(modifiedState);
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.subscriptionErr = t(IDS_SubsServerIssue);
                    this.setState(modifiedState);
                } else if(response.data.code == 'DEVICE_IS_BLOCKED') {
                    modifiedState.notifyOnCloseForm();
                    alert(t(IDS_DevcBlockedDueToNonPaymentOfSomeCost));
                } else {
                    console.log('Should not reach here');
                    modifiedState.subscriptionErr = t(IDS_SubsServerIssue);
                    this.setState(modifiedState);
                }
            }
        })
        .catch(error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.subscriptionErr = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
            }
        })
    }

    onChangeEndDateTime = (updatedTime) => {
        
        let modifiedState = this.state; 

        let updatedEndDateTime = updatedTime;

        modifiedState.EndDateTime = updatedEndDateTime;

        this.setState(modifiedState);
    }

    onChangeStartDateTime = (updatedTime) => {

        // console.log(updatedTime);
        let modifiedState = this.state;
        let updatedActiveStartDateTime = updatedTime;

        modifiedState.StartDateTime = updatedActiveStartDateTime;
        this.setState(modifiedState);
    }

    onEndDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;  
        let strMsg ="";

        // let modifiedState = this.state;

        this.setState( 
            prevState => {

                let currentlySetEndDateTime = prevState.EndDateTime;

                let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
                minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

                let currentlySetStartDateTime = prevState.StartDateTime;

                let minRequiredDateRange = new Date(currentlySetStartDateTime.valueOf());
                minRequiredDateRange.setDate(minRequiredDateRange.getDate() + 31);

                let currentDateTime = new Date();

                let modifiedState = prevState;

                if(currentlySetEndDateTime > currentDateTime) {
                    // strMsg = 'End Date Time cannot be greater than the current date time. It will be set to current date time.';
                    strMsg = t(IDS_RDEndDtGrtCurDt);

                    modifiedState.timeRelatedErrors = strMsg;
                    modifiedState.EndDateTime = currentDateTime
                } else if(currentlySetEndDateTime <= currentlySetStartDateTime) {

                    let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
                    let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );

                    if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {
                        // strMsg = `End Date Time will be adjusted because it is not within a Range. The time will be adjusted to max possible for the day.`;
                        strMsg = t(IDS_RDEndDTAdjust);
                        
                        modifiedState.timeRelatedErrors = strMsg;
                        modifiedState.EndDateTime = new Date(currentlySetStartDateTime.valueOf());
                        modifiedState.EndDateTime.setHours(23);
                        modifiedState.EndDateTime.setMinutes(59);
                        modifiedState.EndDateTime.setSeconds(59);
                    } else {

                        // strMsg = `Start Date Time will be adjusted because it is not within a Range. It will be set according to End Date Time.`;
                        strMsg = t(IDS_RDStartDTAdjust);
                        
                        modifiedState.timeRelatedErrors = strMsg;

                        modifiedState.StartDateTime = minRequiredStartDateTime
                    }
                    
                    if(modifiedState.EndDateTime > currentDateTime) {
                        modifiedState.timeRelatedErrors = strMsg;

                        modifiedState.EndDateTime = currentDateTime
                    }

                } else if(currentlySetEndDateTime > minRequiredDateRange) {

                    // strMsg = `Start Date Time has been adjusted because it is not within a Maximum Range according to EndDateTime.`;
                    strMsg = t(IDS_RDStartDTAdjustNotMaxRng);
                    
                    modifiedState.timeRelatedErrors = strMsg;
                    modifiedState.StartDateTime = minRequiredStartDateTime;

                } else {
                    strMsg = "";
                    modifiedState.timeRelatedErrors = strMsg;
                }

                return modifiedState;
            },
        );
    }

    onStartDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;    
        let strMsg = '';

        let modifiedState = this.state;

        this.setState( 
            prevState => {

                let currentlySetEndDateTime = prevState.EndDateTime;

                // Incase time is greater than currentdatetime.
                let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
                minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

                
                let currentlySetStartDateTime = prevState.StartDateTime;

                // Incase of startDateTime greater than EndDateTime. Set EndDateTime According to StartDateTime.
                let minRequiredEndDateTime = new Date(currentlySetStartDateTime.valueOf());
                minRequiredEndDateTime.setHours(minRequiredEndDateTime.getHours() + 24);

                // required to compare Range between Start and End Date Time.
                let minRequiredDateRange = new Date(currentlySetEndDateTime.valueOf());
                minRequiredDateRange.setDate(minRequiredDateRange.getDate() - 31);

                let modifiedState = prevState;

                let currentDateTime = new Date();

                // Give a tolerance of -1 minutes to the current date time to avoid unnecessary messages
                // currentDateTime.setMinutes(currentDateTime.getMinutes() - 1)                

                // console.log("updatedStartDateTime: ", updatedStartDateTime);
                // console.log("currentlySetEndDateTime: ", currentlySetEndDateTime);
                // console.log("currentlySetStartDateTime: ", currentlySetStartDateTime);

                let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
                let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );
                
                if(currentlySetStartDateTime != null && currentlySetStartDateTime >= currentDateTime) {

                    // strMsg = `Start Date Time cannot be greater than the current date time. It will be set according to End date time.`;
                    strMsg = t(IDS_RDStartDTSetAcrodEndDT);
                    
                    modifiedState.timeRelatedErrors = strMsg;
                    modifiedState.StartDateTime = minRequiredStartDateTime;

                } else if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {

                    // strMsg = `Start Date Time will be adjusted because it is not within a Range. The time will be adjusted to minimum possible for the day.`;
                    strMsg = t(IDS_RDStartDTAdjustMinPossible);
                    
                    modifiedState.timeRelatedErrors = strMsg;

                    modifiedState.StartDateTime = new Date(currentlySetEndDateTime.valueOf());
                    modifiedState.StartDateTime.setHours(0);
                    modifiedState.StartDateTime.setMinutes(0);
                    modifiedState.StartDateTime.setSeconds(0);

                } else if(currentlySetStartDateTime >= currentlySetEndDateTime) {

                    // strMsg = `End Date Time has been adjusted because it is not within a Range.`;
                    strMsg = t(IDS_RDEndDTNotInRng);
                    
                    modifiedState.timeRelatedErrors = strMsg;
                    modifiedState.EndDateTime = minRequiredEndDateTime;

                    if(modifiedState.EndDateTime >= currentDateTime) {
                        modifiedState.EndDateTime = new Date();
                    }

                } else if(currentlySetStartDateTime < minRequiredDateRange) {

                    // strMsg = `End Date Time has been adjusted because it is not within a Maximum Range according to StartDateTime.`;
                    strMsg = t(IDS_RDEndDTSetAcrodStartDT);
                    
                    modifiedState.timeRelatedErrors = strMsg;
                    modifiedState.EndDateTime = minRequiredEndDateTime;

                } else {
                    strMsg = "";
                    modifiedState.timeRelatedErrors = strMsg;
                }

                return modifiedState;
            },

        );
    }

    onCloseDeviceRawDataPopup = () => {
        this.state.notifyOnCloseForm();
    }

    renderParamNameBasedOnType(inParamNameType) {

        switch(inParamNameType) {
            case DEVICE_ID:
                return "DeviceID";
            case FMT_LOG_DATE:
                return "Date";
            case FMT_LOG_TIME:
                return "Time";        
            case NO2:
                return "Nitrogen Dioxide (NO2)";
            case O3:
                return "Ozone (O3)";
            case SO2:
                return "Sulpure Dioxide (SO2)";
            case VOC:
                return "Total Volatile Organic Compound(VOC)";
            case CO:
                return "Carbon Monoxide (CO)";
            case NH3:
                return "Ammonium";
            case CO2:
                return "Carbon Dioxide (CO2)";
            case H2S:
                return "Hydrogen sulphide (H2S)";
            case CH4:
                return "Methane (CH4)";
            case LPG:
                return "LPG";    
            case PM1:
                return "Dust (PM1)";
            case PM25:
                return "Dust (PM2.5)";
            case PM10:
                return "Dust (PM10)";
            case TEMP:
                return "Temperature";
            case HUM:
                return "Humidity";
            case SMOKE:
                return "Smoke";
            case NH3OD:
                return "Odour";
            case NH3OD_RS:
                return "Resistance Value of Odour";
            case MRI:
                return "Mould Risk Index (MRI)";
            case VRI:
                return "Virus Risk Index (VRI)";
    
            default:
                console.error(`Unable to get ParamName. Unknown Param Type: ${inParamNameType}`);
                return (""); // Return empty tag
        }
    }

    makeTextFile = (arrText) =>  {

        let data = new Blob(arrText, {type: 'text/plain'});

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (this.textFile !== null) {
            window.URL.revokeObjectURL(this.textFile);
        }

        this.textFile = window.URL.createObjectURL(data);

        // Return a URL you can use as an href
        return this.textFile;
    }

    createAndFillCsvFile = (inarrCsvAllLines) => {

        let retVal = true;

        let foramttedStartDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(this.state.StartDateTime);
        let foramttedEndDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(this.state.EndDateTime);
        let fileName = "HhmRawData From " + foramttedStartDateTime + " To " + foramttedEndDateTime + ".csv";

        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = this.makeTextFile(inarrCsvAllLines);
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            let event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });

        // Return true to indicate that operation performed successfully (in future any failures in 
        // the previous calls can be checked to return false)
        retVal = true;
        return retVal;
    }

    onSubmitDeviceRawDataForm = () => {

        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;    

        let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let StartDateTime = modifiedState.StartDateTime;
        let EndDateTime = modifiedState.EndDateTime;
        modifiedState.timeRelatedErrors = '';
        modifiedState.csvData = [];
        let arrColumnKeys = []; // Will contain the Measured Param Keys from the DeviceModelInfo 'Seq' array

        let strStartDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(StartDateTime) );
        let strEndDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(EndDateTime) );
        
        // Get the Browser Timezone Offset
        let localTimeZone = (new Date()).getTimezoneOffset();

        const jsonDeviceInfo = {
            DeviceID : modifiedState.SelectedDeviceID,
            StartDateTime : strStartDateTime,
            EndDateTime : strEndDateTime,
            localTimeZone: getLocalTimezoneOffset( localTimeZone ),
            loggedInUserID: LoggedInUserID
        }

        // Show spinner to restrict other user actions to be perform.
        appRelevantDataContextValue.onChangeProcessingReq(true);

        // console.log("UTC_StartDateTime: ");
        // console.log(strStartDateTime);
        // console.log("UTC_EndDateTime: ");
        // console.log(strEndDateTime);

        // console.log("StartDateTime: ");
        // console.log(StartDateTime);
        // console.log("EndDateTime: ");
        // console.log(EndDateTime);

        // First get the Model Information for this device (we are especially interested in the param sequence)
        axios.post( `${getAPIHostURL()}/wclient/getRelevantDeviceModelInfoForDevcRawData`, jsonDeviceInfo )    
        .then( response => {
            if(response.data.code == 'SUCCESS') {
                if(response.data["retrievedModelInfo"] == null || response.data["retrievedModelInfo"].length <= 0 ) {

                    modifiedState.errors = t(IDS_RDDeviceNotContainRawData);
                } else {
                
                    try {
                        let retrievedModelInfo = response.data["retrievedModelInfo"];
                        console.log(retrievedModelInfo);

                        for(let i=0; i<retrievedModelInfo.length; i++) {

                            let retrievedModelParamsInfo = (retrievedModelInfo != null && retrievedModelInfo.length > 0 
                                                            && retrievedModelInfo[i]["MeasuredParams"] != null
                                                          ) ?
                                                           JSON.parse( retrievedModelInfo[i]["MeasuredParams"] ) : 
                                                           null;
                            
                            if(retrievedModelParamsInfo != null && retrievedModelParamsInfo["Seq"] != null && 
                                retrievedModelParamsInfo["Seq"].length > 0
                            ) {
                                arrColumnKeys = [...arrColumnKeys, ...retrievedModelParamsInfo["Seq"]];
                            }

                            let additionalRawDataInfo = (retrievedModelInfo != null && 
                                                            retrievedModelInfo.length > 0 && 
                                                            retrievedModelInfo[i]["AdditionalInfo"] != null
                                                        ) ?
                                                        JSON.parse( retrievedModelInfo[i]["AdditionalInfo"] ) : 
                                                        null;

                            if(additionalRawDataInfo != null && additionalRawDataInfo.hasOwnProperty("AdditionalRawData") &&
                                additionalRawDataInfo["AdditionalRawData"] != null && 
                                additionalRawDataInfo["AdditionalRawData"].length > 0
                            ) {
                                arrColumnKeys = [...arrColumnKeys, ...additionalRawDataInfo["AdditionalRawData"]];
                            }
                        }

                        // In case of two different models Get only distict parametes.
                        arrColumnKeys = [...new Set(arrColumnKeys)];

                    } catch (error) {
                        console.log(`Should not happen. The device data obtained from server for DeviceID ${this.state.SelectedDeviceID} is in invalid JSON format.`);

                        // Don't go any further (arrColumnKeys is empty array; the errors will be handled accordingly).
                    }

                    if(arrColumnKeys.length <= 0) {

                        // Remove spinner and allow user to perform action.
                        appRelevantDataContextValue.onChangeProcessingReq(false);

                        console.log('Though Model Info retreival call is successful, the Sequence Information for Device Model could not be obtained from the result. Not processing further.');

                        // modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                        modifiedState.errors = t(IDS_LoginServerIssue);
                        this.setState(modifiedState);
                        return; // Dont process further
                    }

                    // Retrieved Model Information successfully for the device. Now get the actual raw data for the device and create CSV file.
                    // ==== START: GET RAW DATA AND CREATE CSV FILE =======================================

                    axios.post(`${getAPIHostURL()}/wclient/getRawData`, jsonDeviceInfo)    
                    .then( response => {
                        if(response.data.code == 'SUCCESS') {
            
                            let strCsvHeaderLine = "DeviceID" + CSV_COLUMN_SEPERATOR + "DeviceName" + CSV_COLUMN_SEPERATOR + "Date" + CSV_COLUMN_SEPERATOR + "Time";
                            // let strCsvHeaderLine = "DeviceID" + CSV_COLUMN_SEPERATOR + "Date" + CSV_COLUMN_SEPERATOR + "Time";
                            let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
                            let strSingleCsvDataLine = "";
                            let strParamNameBasedOnType = "";

                            // Create rest of the columns in the header
                            let noOfParams = arrColumnKeys.length;
                            for (let j=0; j<noOfParams; j++) {
                                strParamNameBasedOnType = this.renderParamNameBasedOnType(arrColumnKeys[j]);
                                strCsvHeaderLine += (CSV_COLUMN_SEPERATOR + strParamNameBasedOnType);
                            }

                            let receivedDeviceRawData = response.data.retrievedDeviceRawData;
            
                            if( receivedDeviceRawData == null || receivedDeviceRawData.length <= 0 ) {
                                // modifiedState.errors = 'Selected Device does not contain Device Raw Data on the Server.';

                                modifiedState.errors = t(IDS_NoRawDataFound);
                            } else {
                                modifiedState.DeviceRawData.push(receivedDeviceRawData);
            
                                let DeviceRawDataLen = receivedDeviceRawData.length;
                                let allInstanceInfo = [];
            
                                let singleInstanceMeasuredParamValues = {};
            
                                // First push the header CSV line
                                arrCsvAllLines.push(strCsvHeaderLine);
            
                                for(let i = 0; i < DeviceRawDataLen; i++) {
            
                                    try {
                                        singleInstanceMeasuredParamValues = JSON.parse(receivedDeviceRawData[i]["MeasuredParams"]);
            
                                        let singleInstanceInfo = {
                                            DeviceID: receivedDeviceRawData[i][DEVICE_ID],
                                            DeviceName: receivedDeviceRawData[i][DEVICE_NAME],
                                            FmtLogDate: receivedDeviceRawData[i][FMT_LOG_DATE],
                                            FmtLogTime: receivedDeviceRawData[i][FMT_LOG_TIME],
                                            ...singleInstanceMeasuredParamValues
                                        }

                                        allInstanceInfo.push(singleInstanceInfo);
            
                                        strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                                            ( (receivedDeviceRawData[i][DEVICE_ID] == null) ? '' : receivedDeviceRawData[i][DEVICE_ID] ) +
                                            CSV_COLUMN_SEPERATOR +
                                            ( (modifiedState.SelectedDeviceName == null) ? '' : modifiedState.SelectedDeviceName ) + // as we dont need to show name brought from server as it is already present our context. 
                                            CSV_COLUMN_SEPERATOR +                                                                   // As in Device replaced case it might conflict.
                                            ( (receivedDeviceRawData[i][FMT_LOG_DATE] == null) ? '' : receivedDeviceRawData[i][FMT_LOG_DATE] ) +
                                            CSV_COLUMN_SEPERATOR +
                                            ( (receivedDeviceRawData[i][FMT_LOG_TIME] == null) ? '' : receivedDeviceRawData[i][FMT_LOG_TIME] );
            
                                        for (let j=0; j < arrColumnKeys.length; j++) {
                                            strSingleCsvDataLine += ( CSV_COLUMN_SEPERATOR +
                                                ( singleInstanceMeasuredParamValues[ arrColumnKeys[j] ] == null ? '' :
                                                singleInstanceMeasuredParamValues[ arrColumnKeys[j] ]
                                                )
                                            )
                                        }
            
                                        arrCsvAllLines.push(strSingleCsvDataLine);
            
                                    } catch(e) {
                                        console.log(`Should not happen. The device raw data obtained from server for DeviceID [${this.state.SelectedDeviceID}] is in invalid JSON format.`);
                                        
                                        // Skip this row information and move to next
                                    }
                                }
            
                                modifiedState.csvData = allInstanceInfo;
                                // Create CSV File and fill it
                                let bFileCreationStatus = this.createAndFillCsvFile(arrCsvAllLines);
            
                                // alert(`Raw Device Data download has started. Check your Browser's status bar or Downloads Folder.`);
                                alert(t(IDS_RDCheckDownload));
            
                                if (bFileCreationStatus) {

                                    // Remove spinner and allow user to perform action.
                                    appRelevantDataContextValue.onChangeProcessingReq(false);

                                    // CSV file creation successful.
                                    // Close the date selection pop up.
                                    this.state.notifyOnCloseForm(); // Notify the parent to close the popup.
                                    // Pop up closed, no need to do any further processing
                                    return;
                                } else {
                                    modifiedState.errors = t(IDS_RDDownloadNotSave);
                                }
                            }
            
                        } else {
                            console.log("Server returned failure response while getting Raw Data for this Device.");

                            if(response.data.code == 'REQ_PARAMS_MISSING') {
                                // Let the user know that the Required parameters were not sent to the Server
                                modifiedState.errors = t(IDS_AUSrvrIssueReqParamsNotSent);
                                
                            } else if (response.data.code == 'SQL_ERROR') {
                                // Tell the user that Server is experiencing errors
                                modifiedState.errors = t(IDS_LoginServerIssue);
                            } else if(response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                                modifiedState.errors = t(IDS_LoginServerIssue);
                            } else if(response.data.code == 'OUT_OFF_GRACE_PERIOD') {

                                if(modifiedState.subscriptionEndDtTm == null || modifiedState.subscriptionEndDtTm.length <=0) {
                                    modifiedState.errors = t(IDS_SubsInfoMissing);
                                } else {
                                    modifiedState.errors = t(IDS_RenewSubsToDlDataAfterExpiredSubs, modifiedState.subscriptionEndDtTm);
                                }
                                modifiedState.notifyOnCloseForm();
                                alert(modifiedState.errors);

                            } else {
                                console.log('Should not reach here');
                                modifiedState.errors = t(IDS_LoginServerIssue);
                            }
                        }
                        
                        // Remove spinner and allow user to perform action.
                        appRelevantDataContextValue.onChangeProcessingReq(false);

                        this.setState(modifiedState);
            
                    })
                    .catch( error => {
                        // Remove spinner and allow user to perform action.
                        appRelevantDataContextValue.onChangeProcessingReq(false);

                        console.log("Network Error while getting raw data for the Device:");
                        console.log(error);
                        if (axios.isCancel(error)) {
                            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                        } else {
                            // Tell the user that there are network issues
                            modifiedState.errors = t(IDS_RegistNetworkError);
                            this.setState(modifiedState);
                        }
                    });

                    // ==== END: GET RAW DATA AND CREATE CSV FILE =======================================
                }
            } else {
                console.log("Server returned failure response while getting Model Information for this Device.");

                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    modifiedState.errors = t(IDS_AUSrvrIssueReqParamsNotSent);
                    
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors = t(IDS_LoginServerIssue);
                } else if(response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    modifiedState.errors = t(IDS_LoginServerIssue);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors = t(IDS_LoginServerIssue);
                }
            }

            // Remove spinner and allow user to perform action.
            appRelevantDataContextValue.onChangeProcessingReq(false);

            this.setState(modifiedState);
        })
        .catch( error => {

            // Remove spinner and allow user to perform action.
            appRelevantDataContextValue.onChangeProcessingReq(false);

            console.log("Network Error while getting Model Information for this Device: ");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
            }
        });

    }
    
    render() {        
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        return (
            <div>
                {this.state.showPopUpForRawDataDownload == false ?
                    <div> </div> 
                :
                    <div className="adduser-blackfilm">  
                        <div className="container">
                            <div className="row">
                                <div className="container col-lg-4 col-lg-offset-4
                                                        col-md-6 col-md-offset-3">
                                    <div className="mvAddScroll">
                                        <div className="adduser-popup">
                                            <div className="popup-scroll">

                                                {(this.state.subscriptionInfo.length > 0 || this.state.subscriptionErr.length > 0 || (this.state.subNotApplicableErr != null && this.state.subNotApplicableErr.length > 0)) ?
                                                    <div style={{paddingTop:"0.5rem", paddingLeft:"0.5rem", paddingRight:"0.5rem", paddingBottom:"0.2rem"}}>
                                                        <div style={{border:"1px solid var(--primaryColor)", backgroundColor:"rgb(168,204,168,0.3)", borderRadius:"0.3rem", padding:"0.1rem"}}>
                                                            <div style={{color:"red", fontSize:"0.8rem"}}>{this.state.subscriptionInfo}</div>
                                                            {this.state.subNotApplicableErr != null && this.state.subNotApplicableErr.length > 0 && <div style={{color:"green", fontSize:"0.8rem"}}>{this.state.subNotApplicableErr}</div>}
                                                            {this.state.subscriptionErr != null && this.state.subscriptionErr.length > 0 && <div style={{color:"red", fontSize:"0.8rem"}}>{this.state.subscriptionErr}</div>}
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {/* Show blocked Devices Data to Production/Support Users. */}
                                                { this.state.bCheckDeviceBlockedStatus == true && this.state.havingPvgToViewBlockedDeviceData == true ?
                                                    <div style={{color:"green", marginTop:"0.1rem", fontSize:"0.8rem"}}>
                                                        Note: The Device is blocked. Production/Support Team can download the data.
                                                    </div>
                                                : 
                                                    <div style={{color:"green", fontSize:"0.8rem"}}>
                                                        {this.state.noteToShowIfApplicable.length > 0 ? this.state.noteToShowIfApplicable : ""}
                                                    </div>
                                                }

                                                <h6 className="deviceDetail" style={{marginTop:"0rem"}}> {this.state.SelectedDeviceName} </h6>

                                                <div className="form-group update-form">
                                                    <div className="inputgroupCustom">
                                                        <label className="adduser-form-label">{t(IDS_StartDateTime)}:</label>
                                                        <DateTimePicker
                                                            clearIcon={null}
                                                            onChange={this.onChangeStartDateTime}
                                                            onCalendarClose={this.onStartDateTimeCalendarOrClockClose}
                                                            onClockClose={this.onStartDateTimeCalendarOrClockClose}
                                                            className="input-form-datetime"
                                                            format={"yyyy/MM/dd HH:mm:ss"} 
                                                            value={this.state.StartDateTime}    
                                                            name="StartDateTime"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group update-form">
                                                    <div className="inputgroupCustom">
                                                        <label className="adduser-form-label">{t(IDS_EndDateTime)}:</label>
                                                        <DateTimePicker
                                                            clearIcon={null}
                                                            selected={this.state.EndDateTime}
                                                            onChange={this.onChangeEndDateTime}
                                                            onCalendarClose={this.onEndDateTimeCalendarOrClockClose}
                                                            onClockClose={this.onEndDateTimeCalendarOrClockClose}
                                                            className="input-form-datetime"
                                                            format={"yyyy/MM/dd HH:mm:ss"} 
                                                            value={this.state.EndDateTime} 
                                                            name="EndDateTime"
                                                        />
                                                    </div>

                                                </div>

                                                <div style={{display: "flex", fontSize: "0.8rem", color: "green", padding:"0.3rem"}}>{this.state.timeRelatedErrors}</div>
                            
                                                <div>
                                                    <span>
                                                        <button onClick={this.onSubmitDeviceRawDataForm} 
                                                                className="adduserButton btn-lg"
                                                                title={"Download Raw Data"} 
                                                                value={"Download Raw Data"}
                                                                style={{backgroundColor:"var(--primaryColor)", color: "white"}}
                                                        >
                                                            {t(IDS_Download)} 
                                                        </button>
                                                        <button type="button" 
                                                                title={"Close Raw Data Popup"} 
                                                                value={"Close Raw Data Popup"} 
                                                                className="adduserButton btn-lg"
                                                                onClick={this.onCloseDeviceRawDataPopup}
                                                        >
                                                            {t(IDS_Close)}
                                                        </button>
                                                    </span>
                                                    <div style={{display: "flex", fontSize: "0.8rem", color: "var(--errorColor)", padding:"0.2rem"}}>{this.state.errors}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

VcDeviceRawData.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcDeviceRawData
