import React, { Component } from 'react'
import axios from 'axios';
import { AppRelevantDataContext} from '../../AppContext';
import { getAPIHostURL } from '../../ClientConfig';
// import {QrReader} from '@otterscan/react-qr-reader';
import {QrScanner} from '@yudiel/react-qr-scanner';
import { FaQrcode } from 'react-icons/fa'
import {  convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertUTCDateToStrYYYYMMDDHH24MMSS, convertLocalDateWithFormatDDMMMYY} from '../../vtUtil';
import { ITM_STATE_MAPPED_TO_OWNER, ITM_STATE_MAPPED_AS_REPLACEMENT } from '../../VcConstants';

export class VcCancelSubscription extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            LoggedInUserID: "",
            deviceID:"",
            deviceExistenceErr: "",
            enableSubscriptionCancellationField: false,
            DevcIDQRCodeFlag: false,
            CurrentSubStartDtTm: "",
            CurrentSubEndDtTm: "",     
            orderType: "",
            orderID: "",
            selectedSubPeriodCancelOption: "",
            subPeriodCancellationOption: "" ,
            subPeriodCancellationDate: "",
            daysLeftToEndSubPeriod: "",
            maxReturnableSubAmountINR: "",
            returnableSubAmountINR: "",
            comments: "",
            displaySubCancellationInfo: false,

            errors: {
                others: "",
                deviceID: "",
                subPeriodCancellationOption: "",
                returnableSubAmountINR: "",
                comments: "",
                selectedSubPeriodCancelOption: "",
                SubscriptionStatus: ""
            },

        }
    }

    onChangeDeviceID = (e) => {
        let modifiedState = this.state;
        modifiedState.deviceID = e.target.value;

        // set all fields to blank as it will not give a wrong values if user change deviceID.
        modifiedState.errors.others = "";
        modifiedState.enableSubscriptionCancellationField = false;
        modifiedState.DevcIDQRCodeFlag = false;
        modifiedState.deviceExistenceErr = "";
        modifiedState.DevcIDQRCodeFlag = false;
        modifiedState.CurrentSubStartDtTm = "";
        modifiedState.CurrentSubEndDtTm = "";     
        modifiedState.orderType = "";
        modifiedState.orderID = "";
        modifiedState.selectedSubPeriodCancelOption = "";
        modifiedState.subPeriodCancellationOption = "" ;
        modifiedState.subPeriodCancellationDate = "";
        modifiedState.daysLeftToEndSubPeriod = "";
        modifiedState.maxReturnableSubAmountINR = "";
        modifiedState.returnableSubAmountINR = "";
        modifiedState.comments = "";
        modifiedState.displaySubCancellationInfo = false;

        modifiedState.errors.returnableSubAmountINR = "";
        modifiedState.errors.comments = "";
        modifiedState.errors.selectedSubPeriodCancelOption = "";
        modifiedState.errors.SubscriptionStatus = "";
        modifiedState.errors.others = "";
        this.setState(modifiedState);
    }


    handleKeyDown = (e) => {
        if (e.key == 'Enter') {
            // This is just to prevent form submission on pressing "enter".
            e.preventDefault();
            return;
        }
    }

    openQRCameraForDevcIDOfCancelSubscription = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 
        
        this.setState(prevState => {
            let modifiedState = prevState;

            modifiedState.DevcIDQRCodeFlag = !modifiedState.DevcIDQRCodeFlag;

            // set all fields to blank as it will not give a wrong values if user change deviceID.
            modifiedState.deviceID = "";
            modifiedState.deviceExistenceErr = "";
            modifiedState.errors.others = "";
            modifiedState.errors.SubscriptionStatus = "";
            modifiedState.errors.comments = "";
            modifiedState.enableSubscriptionCancellationField = false;
            return modifiedState
        })
    } 

    handleScanResultOfCancelSubDevcID = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.deviceID = data;
            if((modifiedState.deviceID != null && modifiedState.deviceID.length > 0)) {
                modifiedState.DevcIDQRCodeFlag = false;
            } else {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
            }
            this.setState(modifiedState);
        }
    }

    handleQRCodeError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;

        let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        modifiedState.errors.deviceID = (modifiedState.deviceID == null || modifiedState.deviceID.length <= 0)
            ? "Please Enter Device ID of Product" 
            : "";

        modifiedState.errors.selectedSubPeriodCancelOption = (modifiedState.selectedSubPeriodCancelOption == null || modifiedState.selectedSubPeriodCancelOption.length <= 0)
            ? ((modifiedState.orderType == 'Sale') ? "Please Select Device Subscription Period Cancellation Option" : "Please Select Device Rental Subscription Period Cancellation Option")
            : "";
        
        modifiedState.errors.returnableSubAmountINR = (modifiedState.returnableSubAmountINR == null || modifiedState.returnableSubAmountINR.length <= 0)
            ? ((modifiedState.orderType == 'Sale') ? "Please enter valid Returnable Subscription Amount(INR)." : "Please enter valid Returnable Rental Period Amount(INR).")
            : "";

        // if any one of the required fields is not entered by user then return the function and show error message.
        if(modifiedState.errors.deviceID.length > 0 || modifiedState.errors.returnableSubAmountINR.length > 0 ||
            modifiedState.errors.selectedSubPeriodCancelOption.length > 0){     
            this.setState(modifiedState);
            return;
        }

        if((modifiedState.orderType != null && modifiedState.orderType == 'Sale')
            ? !window.confirm(`Are you sure you want to Cancel Subscription for selected Device ID "${modifiedState.deviceID}"?`)
            : !window.confirm(`Are you sure you want to Cancel Rental Subscription for selected Device ID "${modifiedState.deviceID}"?`)){
            return;
        }

        let cancelSubComments = modifiedState.comments.replace(/'/g, "''").replace(/\\/g, "\\\\");

        let jsonParams = {
            orderType: modifiedState.orderType,
            deviceID: modifiedState.deviceID,
            orderID: modifiedState.orderID,
            daysLeftToEndSub: modifiedState.daysLeftToEndSubPeriod,
            subAmountToReturn: modifiedState.returnableSubAmountINR,
            subPeriodCancellationDate: convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(modifiedState.subPeriodCancellationDate)),
            currentSubEndDtTm: convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(modifiedState.CurrentSubEndDtTm)),
            comments: cancelSubComments,
            UserID: LoggedInUserID
        }

        // Calling API to cancel Subscription Details.
        axios.post(`${getAPIHostURL()}/wclient/cancelSubscriptionDetails`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(modifiedState.orderType != null && modifiedState.orderType == 'Sale') {
                    alert(`Succesfully Cancelled Subscription for the Selected Device ID "${modifiedState.deviceID}". Subscription will be End on "Date: ${modifiedState.subPeriodCancellationDate}"`);
                } else {
                    alert(`Succesfully Cancelled Rental Period for the Selected Device ID "${modifiedState.deviceID}". Rental Period will be End on "Date: ${modifiedState.subPeriodCancellationDate}"`);
                }

                modifiedState.enableSubscriptionCancellationField = false;
                modifiedState.deviceID = "";
                modifiedState.selectedSubPeriodCancelOption = "";
                modifiedState.subPeriodCancellationOption = "";
                modifiedState.subPeriodCancellationDate = "";
                modifiedState.daysLeftToEndSubPeriod = "";
                modifiedState.maxReturnableSubAmountINR = "";
                modifiedState.returnableSubAmountINR = "";
                modifiedState.comments = "";
                modifiedState.CurrentSubStartDtTm = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(response.data["SubscriptionInfo"][0]["SubscriptionStartDtTm"]);
                modifiedState.CurrentSubEndDtTm = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(response.data["SubscriptionInfo"][0]["SubscriptionEndDtTm"]);

                modifiedState.errors.others = "";
                modifiedState.errors.deviceID = "";
                modifiedState.errors.subPeriodCancellationOption = "";
                modifiedState.errors.returnableSubAmountINR = "";
                modifiedState.errors.comments = "";
                modifiedState.errors.selectedSubPeriodCancelOption = "";
                modifiedState.errors.SubscriptionStatus = "";


            } else {
                if(response.data.code == 'SUBSCRIPTION_OR_RENTAL_PERIOD_IS_ALREADY_CANCELLED') {
                    if(modifiedState.orderType == "Sale") {
                        alert(`Subscription already cancelled for selected DeviceID."${modifiedState.deviceID}".`);
                    } else {
                        alert(`Rental period is already cancelled for selected DeviceID."${modifiedState.deviceID}".`);
                    }
                } else if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (Request Params not received).\n Try again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }  
            }
            this.setState(modifiedState); 
        })
        .catch( errors => {

            if (axios.isCancel(errors)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    verifyEnteredDeviceID = () => {

        let modifiedState = this.state;

        if(modifiedState.deviceID == null || modifiedState.deviceID.length <= 0 ) {
            modifiedState.deviceExistenceErr = 'Please enter DeviceID.';
            this.setState(modifiedState);
            return;
        }

        let jsonParams = {
            EnteredDeviceID: modifiedState.deviceID,
            StateToValidate: [ITM_STATE_MAPPED_TO_OWNER, ITM_STATE_MAPPED_AS_REPLACEMENT],
            invokedFrom: "cancelSubscription"
        }

        // Calling API to check device id existence
        axios.post(`${getAPIHostURL()}/wclient/checkDeviceIdExistence`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if(response.data.isDeviceIDExist == null) {
                    modifiedState.deviceExistenceErr = "Server experiencing issues.\nTry again later.";
                    this.setState(modifiedState);

                } else {
                    if(response.data.isDeviceIDExist == 0) {
                        modifiedState.deviceExistenceErr = 'Invalid Device ID.';
                        this.setState(modifiedState);
                        return;

                    } else {
                        // if state of device is valid, then get type of order of that device
                        if(response.data.isStateIDValid == 1){
                            modifiedState.deviceExistenceErr = "";
                            this.getTypeOfOrder(modifiedState);
                            
                        } else {

                            modifiedState.deviceExistenceErr = 'You cannot cancel subscription/Rental Period for selected device because existing state of this Device is not valid.';
                            this.setState(modifiedState);
                        }
                    }
                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.deviceExistenceErr = 'Server experiencing issues (Request Params not received).\n Try again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    modifiedState.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';                    
                }
                this.setState(modifiedState);
            }
        })
        .catch( errors => {

            if (axios.isCancel(errors)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.deviceExistenceErr = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    // Function to call API to get order type of a device.
    getTypeOfOrder = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        if(modifiedState.deviceID == null || modifiedState.deviceID.length <= 0 ) {
            modifiedState.deviceExistenceErr = 'Please enter DeviceID.';
            this.setState(modifiedState);
            return;
        }

        let jsonTogetTypeOfOrder = {
            DeviceID: modifiedState.deviceID
        }

        axios.post(`${getAPIHostURL()}/wclient/getTypeOfOrder`, jsonTogetTypeOfOrder)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if(response.data["retrievedOrderType"] == null || response.data["retrievedOrderType"].length <= 0 || 
                    response.data["retrievedOrderType"]["AdditionalID"] == null || response.data["retrievedOrderType"]["AdditionalID"] <= 0){

                        modifiedState.errors.SubscriptionStatus = "Order for this device is not Created. Please select correct DeviceId.";
                        this.setState(modifiedState);
                } else {
                    let retrievedOrderType = response.data["retrievedOrderType"];

                    modifiedState.orderType = retrievedOrderType["OrderType"] != null &&  retrievedOrderType["OrderType"].length > 0 ?
                        retrievedOrderType["OrderType"] : "";
                    modifiedState.orderID = retrievedOrderType["OrderLineNumber"] != null &&  retrievedOrderType["OrderLineNumber"].length > 0 ?
                        retrievedOrderType["OrderLineNumber"] : "";

                    // If order type is Sale or Rental, then check if subscription is already cancelled or not.
                    if(modifiedState.orderType == "Sale" || modifiedState.orderType == "Rental"){
                        this.checkIfSubscriptionIsCancelled(modifiedState)
                        
                    } else {
                        alert("Subscription/Rental Period Cannot be cancelled for orders other than 'Sale' or 'Rental'. Please Select correct DeviceID to cancel subscription/Rental Period.");
                        this.setState(modifiedState);
                    }
                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (Request Params not received).\n Try again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }
            
        })
        .catch( errors => {

            if (axios.isCancel(errors)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 

    }

    // Function to call API to check if subscription is already cancelled or not.
    checkIfSubscriptionIsCancelled = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        if(modifiedState.deviceID == null || modifiedState.deviceID.length <= 0 ) {
            modifiedState.deviceExistenceErr = 'Please enter DeviceID.';
            this.setState(modifiedState);
            return;
        }

        let jsonTocheckIfSubscriptionIsCancelled = {
            deviceID: modifiedState.deviceID,
            invokedFrom: "cancelSubscription"
        }
        
        // Calling API to check if subscription is already cancelled or not
        axios.post(`${getAPIHostURL()}/wclient/checkIfSubscriptionIsCancelled`, jsonTocheckIfSubscriptionIsCancelled)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if(response.data["isSubscriptionCancelled"] == null || response.data["isSubscriptionCancelled"].length <= 0){
                    (modifiedState.orderType == "Sale") ?
                        modifiedState.errors.SubscriptionStatus = "Subscription already expired for the selected Device ID " + "'" + modifiedState.deviceID + "'."
                    :   
                        modifiedState.errors.SubscriptionStatus = "Rental period already expired for the selected Device ID " + "'" + modifiedState.deviceID + "'."
                } else if( response.data == null || response.data.length <= 0 ||
                    response.data["SubscriptionInfo"] == null || response.data["SubscriptionInfo"].length <= 0) {
                        (modifiedState.orderType == "Sale") ?
                            modifiedState.errors.SubscriptionStatus = "Current Subscription Info is not present on the server for selected Device ID " + "'" + modifiedState.deviceID + "'."
                        :
                            modifiedState.errors.SubscriptionStatus = "Current Rental Period Info is not present on the server for selected Device ID " + "'" + modifiedState.deviceID + "'."
                } else {
                    let retrievedIsSubCancelled = response.data["isSubscriptionCancelled"];
                    let retrievedSubInfoToCancel = response.data["SubscriptionInfo"][0];

                    // if subscription is not expired, then get subscription information to cancel
                    if(retrievedIsSubCancelled["subExpiryDate"] != null && retrievedIsSubCancelled["subExpiryDate"] != "SubscriptionExpired" &&
                        retrievedIsSubCancelled["DtTmWhenSubCancelled"] == null){

                        modifiedState.CurrentSubStartDtTm = retrievedSubInfoToCancel["SubscriptionStartDtTm"] != null && retrievedSubInfoToCancel["SubscriptionStartDtTm"].length > 0
                                                            ? convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(retrievedSubInfoToCancel["SubscriptionStartDtTm"])
                                                            : null;
                        modifiedState.CurrentSubEndDtTm = retrievedSubInfoToCancel["SubscriptionEndDtTm"] != null && retrievedSubInfoToCancel["SubscriptionEndDtTm"].length > 0
                                                            ? convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(retrievedSubInfoToCancel["SubscriptionEndDtTm"])
                                                            : null;
                        modifiedState.errors.subPeriodCancellationOption = "";
                        modifiedState.errors.returnableSubAmountINR = "";
                        modifiedState.errors.comments = "";
                        modifiedState.errors.others = "";
                        modifiedState.errors.SubscriptionStatus = "";
                        modifiedState.enableSubscriptionCancellationField = true;
                        
                    } 
                    else if(retrievedIsSubCancelled["subExpiryDate"] != null && retrievedIsSubCancelled["subExpiryDate"] != "SubscriptionExpired" &&
                        retrievedIsSubCancelled["DtTmWhenSubCancelled"] != null){

                        (modifiedState.orderType == "Sale") ?
                            modifiedState.errors.SubscriptionStatus = "Subscription is cancelled for the selected Device ID " + "'" + modifiedState.deviceID + "'. Subscription is going expire on date '" 
                                                                    + convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(retrievedIsSubCancelled["subExpiryDate"]) + "'."
                        :   
                            modifiedState.errors.SubscriptionStatus = "Rental period is cancelled for the selected Device ID " + "'" + modifiedState.deviceID + "'. Rental period is going expire on date '" 
                                                                    + convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(retrievedIsSubCancelled["subExpiryDate"]) + "'."
                    } else{
                        (modifiedState.orderType == "Sale") ?
                            modifiedState.errors.SubscriptionStatus = "Subscription already expired for the selected Device ID " + "'" + modifiedState.deviceID + "'."
                        :   
                            modifiedState.errors.SubscriptionStatus = "Rental period already expired for selected Device ID " + "'" + modifiedState.deviceID + "'."
                    }
                }
            }
            else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (Request Params not received).\n Try again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( errors => {

            if (axios.isCancel(errors)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 

    }

    // Function to decide the subscription Cancellation Date
    onChangeSubPeriodCancelOption = (e) => {

        let modifiedState = this.state;
        modifiedState.selectedSubPeriodCancelOption = e.target.value;

        let startDate = new Date();
        let endDate = new Date(modifiedState.CurrentSubEndDtTm.valueOf());
        let currTime;

        // If user have selected option to cancel subscription at next bill date
        if(modifiedState.selectedSubPeriodCancelOption == "cancelAtNextBillDate") {
            modifiedState.errors.selectedSubPeriodCancelOption = "";
            modifiedState.errors.returnableSubAmountINR = "";
            startDate = new Date();

            // If subscrition end date is less than 15, then set subscription cancellation date to 15th of that month
            if (startDate.getDate() <= 15 && startDate.getDate() > 0) {
                startDate.setDate(15);
                modifiedState.subPeriodCancellationDate = convertLocalDateWithFormatDDMMMYY(startDate) + " 23:59:59";

            } else if (startDate.getDate() > 15) {
                // If subscrition end date is greater than 15, then set subscription cancellation date to last day of that month
                let DtTmOfLastDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
                modifiedState.subPeriodCancellationDate = convertLocalDateWithFormatDDMMMYY(DtTmOfLastDayOfMonth) + " 23:59:59";
            } 

        // If user have selected option to cancel subscription at end of the day, then set subscription cancellation date, to date on that particular day
        } else if(modifiedState.selectedSubPeriodCancelOption == "cancelAtEndOfDay") {
            modifiedState.errors.selectedSubPeriodCancelOption = "";
            modifiedState.errors.returnableSubAmountINR = "";
            startDate = new Date();
            modifiedState.subPeriodCancellationDate = convertLocalDateWithFormatDDMMMYY(startDate) + " 23:59:59";

        } else if(modifiedState.selectedSubPeriodCancelOption == "cancelImmediately") {
            // If user have selected option to cancel subscription immediately, then set subscription cancellation date to current time.
            modifiedState.errors.selectedSubPeriodCancelOption = "";
            modifiedState.errors.returnableSubAmountINR = "";
            startDate = new Date();
            currTime = startDate.getHours() + ":" + startDate.getMinutes() + ":" + startDate.getSeconds();
            modifiedState.subPeriodCancellationDate = convertLocalDateWithFormatDDMMMYY(startDate) + " " + currTime;

        } else {
            modifiedState.subPeriodCancellationDate = ""; 
            modifiedState.daysLeftToEndSubPeriod = "";
            modifiedState.returnableSubAmountINR = "";
            modifiedState.errors.selectedSubPeriodCancelOption = "";
            modifiedState.errors.returnableSubAmountINR = "";
            this.setState(modifiedState);
            return;
        }

        // If subscription cancellation date is greater than subscription end date then, set subscription cancellation date same as subscription end date
        if( new Date(modifiedState.subPeriodCancellationDate) >= endDate){
            modifiedState.subPeriodCancellationDate = convertLocalDateWithFormatDDMMMYY(endDate) + " 23:59:59";
            modifiedState.daysLeftToEndSubPeriod = "0";
            modifiedState.returnableSubAmountINR = "0.00";
            this.setState(modifiedState);
            return;
        }

        let jsonParams = {
            deviceID: modifiedState.deviceID,
            subPeriodCancellationDate: convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(modifiedState.subPeriodCancellationDate))
        }

        //calling API to get Subscription AmountToReturn Info
        axios.post(`${getAPIHostURL()}/wclient/getSubscriptionAmountToReturnInfo`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if(
                    response.data== null || response.data.length <= 0 ||
                    response.data["retrievedReturnableSubAmountINR"] == null || response.data["retrievedReturnableSubAmountINR"].length <= 0 ||
                    response.data["retrievedDaysLeftToEndSubPeriod"] == null || response.data["retrievedDaysLeftToEndSubPeriod"].length <= 0
                ) {
                    modifiedState.daysLeftToEndSubPeriod = "";
                    modifiedState.maxReturnableSubAmountINR = "";
                    modifiedState.returnableSubAmountINR = "";
                } else{
                    modifiedState.daysLeftToEndSubPeriod = response.data["retrievedDaysLeftToEndSubPeriod"];
                    modifiedState.maxReturnableSubAmountINR = response.data["retrievedReturnableSubAmountINR"];
                    modifiedState.returnableSubAmountINR = response.data["retrievedReturnableSubAmountINR"];
                }
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (Request Params not received).\n Try again later.';
        
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( errors => {

            if (axios.isCancel(errors)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        let modifiedState = this.state;

        switch (name) {

            case 'returnableSubAmountINR': 
                modifiedState.returnableSubAmountINR = value;
                modifiedState.errors.others= "";
                let maxReturnableSubAmntINR = modifiedState.returnableSubAmountINR == "" ? "100" : Number(Number(modifiedState.maxReturnableSubAmountINR) + 100);
                // IF returnableSubAmountINR is greater than its value when added 100 in it, then display error.
                modifiedState.errors.returnableSubAmountINR = (value > maxReturnableSubAmntINR) 
                    ? "This Amount Should Not Be Greater Than " + maxReturnableSubAmntINR 
                    : value < 0 ? "This Amount Should Not Be less Than 0 " : "";
                    
            break;

            case 'comments': 
                modifiedState.comments = value;
                modifiedState.errors.others = "";
                modifiedState.errors.comments = "";
            break;

            default:
            break;
        }

        this.setState({
            modifiedState, 
            [name]: value,
            // [event.target.name]: event.target.value,   
        }, ()=> {
        })
    }
    
    
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-lg-offset-2
                                              col-md-12">
                        <div className="modal-body p-4 box">
                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1rem"}}>Cancel Subscription/Rental Period</div> 
                            <form onSubmit={this.onFormSubmit}>
                                <div>
                                    <label className="reg-form-label" 
                                        style={{width:"100%"}}
                                    >
                                        Enter DeviceID of Product Or Scan the QR Code:
                                    </label>
                                    <input style={{width:"100%"}}
                                           className="input-form"
                                           value= {this.state.deviceID}
                                           onKeyDown={this.handleKeyDown}
                                           onChange = {this.onChangeDeviceID}
                                           required
                                    />
                                    <label onClick = {this.openQRCameraForDevcIDOfCancelSubscription} className = "qrcodeTextBtn">
                                        <FaQrcode className = "qrcodeTextBtnIcon"/>
                                    </label>
                                    <div>
                                        {(this.state.DevcIDQRCodeFlag == true)
                                        ?
                                            // <div style={{display: "flex", justifyContent: "center"}}>
                                            //     <QrReader
                                            //         scanDelay={300}
                                            //         onResult={(result, error) => {
                                            //             if (!!result) {
                                            //                 this.handleScanResultOfCancelSubDevcID(result?.text);
                                            //             }
                                            //         }}
                                            //         className = "QRCodeCamBoxForModalAndContainer"
                                            //     />
                                            // </div>
                                            <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                        marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                        marginRight: "auto"}}>                                            
                                            <QrScanner  
                                                scanDelay={300}
                                                onResult={(result, error) => {
                                                    if (!!result) {
                                                        this.handleScanResultOfCancelSubDevcID(result?.text);
                                                    }
                                                }}
                                                className = "QRCodeCamBoxForModalAndContainer"
                                            />
                                        </div>
                                        :
                                            <div/>
                                        }
                                    </div>

                                    {this.state.enableSubscriptionCancellationField == false &&
                                        <div style={{display:"flex", justifyContent: "center", flexDirection:"column"}}>
                                            <button type={"button"}
                                                style={{borderRadius: "5px", 
                                                        width:'40%', 
                                                        marginLeft:'30%',
                                                        marginTop:"0.5rem",
                                                        backgroundColor: "var(--primaryColor)", color:"white",
                                                        fontSize: "1.2rem", padding: "0.4rem"}}
                                                className="btn-lg"
                                                onClick={this.verifyEnteredDeviceID} 
                                            >
                                                Proceed
                                            </button>
                                            {this.state.deviceExistenceErr.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", paddingTop: "0.3rem"}} className='errors'>{this.state.deviceExistenceErr}</p>} 
                                            {this.state.errors.SubscriptionStatus.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", paddingTop: "0.3rem"}} className='errors'>{this.state.errors.SubscriptionStatus}</p>}  
                                        </div>
                                    }                          
                                </div>
                                {(this.state.enableSubscriptionCancellationField == true) && 
                                    <div>
                                        <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                            <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem"}}>
                                                {this.state.orderType == "Sale" ? "Subscription Package Details" : "Rental Subscription Package Details"}
                                            </label>
                                            <div className = "startAndEnddate" style = {{ marginTop: "0.5rem"}}>
                                                <div style={{flex: "1", paddingRight:"1rem"}}>
                                                    <label className="reg-form-label" style={{width:"100%"}}>
                                                        {this.state.orderType == "Sale" ? "Subscription Start Date Time:" : "Rental Period Start Date Time:"}
                                                    </label>
                                                    <input style={{color: "#505050", background:"#F5F5F5"}}
                                                            className="input-form"
                                                            value= {this.state.CurrentSubStartDtTm}
                                                            readOnly
                                                    />
                                                </div>

                                                <div style={{flex: "1", paddingLeft:"1rem"}}>
                                                    <label className="reg-form-label" style={{width:"100%"}}>
                                                        {this.state.orderType == "Sale" ? "Subscription End Date Time:" : "Rental Period End Date Time:"}
                                                    </label>
                                                    <input style={{color: "#505050", background:"#F5F5F5"}}
                                                            className="input-form"
                                                            value= {this.state.CurrentSubEndDtTm}
                                                            readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                            <div>
                                                <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem"}}>
                                                    {this.state.orderType == "Sale" ? "Subscription Cancellation Details" : "Rental Subscription Cancellation Details"}
                                                </label>
                                                <div className = "startAndEnddate" style={{ marginTop: "0.5rem"}}>
                                                    <div style={{flex: "1"}}>
                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                            {this.state.orderType == "Sale" ? "Select Subscription Period Cancellation Options:" : "Select Rental Period Cancellation Options:"}
                                                        </label>
                                                        <div style={{display: "flex"}}>
                                                            <div style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"0rem"}}
                                                                className="input-form"
                                                                readOnly>
                                                                <select className="input-form"
                                                                    onChange={this.onChangeSubPeriodCancelOption}
                                                                    value = {this.selectedSubPeriodCancelOption}
                                                                    defaultValue = {""}
                                                                >
                                                                    <option value = "" disabled>Select...</option>
                                                                    <option value = "cancelAtNextBillDate">{this.state.orderType == "Sale" ? "Cancel Subscription at Next Bill Date" : "Cancel Rental Period at Next Bill Date"}</option>                                                                    
                                                                    <option value = "cancelAtEndOfDay">{this.state.orderType == "Sale" ? "Cancel Subscription at EOD" : "Cancel Rental Period at EOD"}</option>
                                                                    <option value = "cancelImmediately">{this.state.orderType == "Sale" ? "Cancel Subscription Immediately" : "Cancel Rental Period Immediately"}</option>
                                                                </select>
                                                            </div>
                                                        </div>  
                                                        {this.state.errors.selectedSubPeriodCancelOption.length > 0 && 
                                                                <span  className='validationErrorLabel'>{this.state.errors.selectedSubPeriodCancelOption}</span>}
                                                    </div>
                                                    
                                                    <div style={{paddingLeft: "0.8rem", paddingRight: "0.8rem", paddingTop: "0.3rem", alignItems: "center"}}>
                                                        <br/> =
                                                    </div>
                                                    <div style={{flex: "1"}}>
                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                            {this.state.orderType == "Sale" ? "Subscription Period Cancellation Date:" : "Rental Period Cancellation Date:"}
                                                        </label>
                                                        <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                                className="input-form"
                                                                value= {this.state.subPeriodCancellationDate}
                                                                readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>    
                                            {this.state.displaySubCancellationInfo = true ? 
                                                <div>
                                                    <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                        <div style={{flex: "1", paddingRight:"1rem"}}>
                                                                <label className="reg-form-label" style={{width:"100%"}}>
                                                                    {this.state.orderType == "Sale" ? "Days Left To End Subscription Period:" : "Days Left To End Rental Period:"}
                                                                </label>
                                                                <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                                        className="input-form"
                                                                        value= {this.state.daysLeftToEndSubPeriod}
                                                                        readOnly
                                                                />
                                                        </div>
                                                        <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                            <label className="reg-form-label" style={{width:"100%"}}>
                                                                {this.state.orderType == "Sale" ? "Returnable Subscription Amount (INR):" : "Returnable Rental Period Amount (INR):"}
                                                            </label>
                                                        
                                                            <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", marginRight:"2rem"}}
                                                                className="input-form"
                                                                type= "number"
                                                                step="any"
                                                                min = "0"
                                                                name = "returnableSubAmountINR"
                                                                value= {this.state.returnableSubAmountINR}
                                                                onChange= {this.handleChange}
                                                            />
                                                            {this.state.errors.returnableSubAmountINR.length > 0 && 
                                                                <span  className='validationErrorLabel'>{this.state.errors.returnableSubAmountINR}</span>}
                                                        </div>   
                                                    </div>  
                                                    <div>
                                                        <label className="reg-form-label" style={{width:"100%", marginTop:"0.5rem"}}>
                                                            Comment:
                                                        </label>
                                                        <input style={{width:"100%"}}
                                                                className="input-form"
                                                                value= {this.state.comments}
                                                                name = "comments"
                                                                onChange = {this.handleChange}
                                                        />
                                                    </div>   
                                                </div>
                                            : null}
                                                  
                                        </div>  
                                                                             
                                        <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                            <button type="submit" style={{borderRadius: "5px", width:"30%", marginTop:"1rem",
                                                            marginLeft:"35%",
                                                            backgroundColor: "var(--primaryColor)", color:"white",
                                                        }}
                                                    className="btn-lg"
                                            >
                                                Save
                                            </button>
                                            {this.state.errors.others.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>} 
                                        </div> 
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}
VcCancelSubscription.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcCancelSubscription;
