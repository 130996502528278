import React, { useState, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import '../CSS/VcCustomer.css'
import '../CSS/VcTrackDevices.css'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday} from '../../vtUtil';
import { FaSearch}  from 'react-icons/fa';
import { AppRelevantDataContext } from '../../AppContext';
import { Select, Pagination } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

const defaultPropGetter = () => ({});

const ReactTable = ({ columns, data }) => {

    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: {pageSize: 5},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;No Data Found.</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* {page.length > 0 && */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"
                                    onClick = {(e, handleOriginal) => {
                                        setSelectedRowIndex(row.index);
                                    }}       
                                    style={{
                                        cursor: '',
                                        background: row.index === selectedRowIndex ? '#6c95ba' : '',
                                        color: row.index === selectedRowIndex ? 'white' : 'black',
                                        alignItems: "center",
                                    }}  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style,},
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || 
                        //     <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex", justifyContent:"left"}}>{"No Data Found."}</span>
                        //         </td>
                        //     </tr>                            
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcTrackRtcIssues (props) {
    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        if(id != "DateTimeIssueSeen") {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
            });
        } else {
            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id];
                                
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                    else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        }
    };

    const [state, setState] = useState({
        deviceID: "",
        trackBy: "trackByAllDevices",
        data: [],

        errors: {
            others: "",
            requiredField: "",
        },

        // React table columns for displaying RTC Issues.
        columns: [
            {       
                Header:() => <div className="AlertLogTableHeader">Device ID</div>,  
                accessor: 'DeviceID',
                filter: filterCaseInsensitive,
                width: 250,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Has RTC Issue</div>,  
                accessor: 'HasRtcIssue',
                filter: filterCaseInsensitive,
                width: 192,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">RTC Date Time from Device (UTC)</div>,  
                accessor: 'DeviceRTCDateTime',
                filter: filterCaseInsensitive,
                width: 300,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },

            { 
                Header:() => <div className="AlertLogTableHeader">Date Time At Which Issue Seen</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'DateTimeIssueSeen',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 300,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },  

            {       
                Header:() => <div className="AlertLogTableHeader">Issue Seen In</div>,  
                accessor: 'IssueSeenSrc',
                width: 250,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },  
        ],
    });

    // Radio Button to Switch between track RTC issue for All Devices and Track by Device ID.
    // on switch we also Empty the TextBoxes and Array.
    const onTrackByRadioBtnChange = (e) => {
        
        let modifiedState = state;

        modifiedState.errors.requiredField = "";
        modifiedState.errors.others = "";
        modifiedState.deviceID = "";
        modifiedState.data = [];
        modifiedState.trackBy = e.target.value;

        setState({...modifiedState});  
    }

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return "Today " + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    // Function will get RTC Issue details the basis of tracked for all devices or signle device.
    const getRtcIssueDetails = (event) => {
    
        let modifiedState = state;
        event.preventDefault();
        modifiedState.data = [];

        let jsonBody = {}

        if (modifiedState.trackBy == "trackBySingleDeviceID") {
            if( (modifiedState.deviceID == null || modifiedState.deviceID.length <= 0)) {

                modifiedState.errors.requiredField = "Please enter Device ID - (Required field)";
                setState({...modifiedState});  
                return

            } else {
                jsonBody = {
                    DeviceID: modifiedState.deviceID,
                    TrackBy: modifiedState.trackBy  
                };
            }
        } else {
            jsonBody = {
                TrackBy: modifiedState.trackBy  
            };
        }

        axios.post(`${getAPIHostURL()}/wclient/getRtcIssueDetails`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {

                if(response.data.retrieveRtcIssueDetails == null || response.data.retrieveRtcIssueDetails.length <= 0) {
                    if(modifiedState.trackBy == "trackByAllDevices") {
                        alert("No RTC Issue(s) found in last 1 hour.");
                    } else {
                        modifiedState.errors.requiredField = `No Data found for Device ID '${modifiedState.deviceID}'. Please enter valid Device ID.`;
                    }

                } else {
                    let stateSearchDataArr = [];
                    modifiedState.data = [];   

                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing product line Details data.
                    stateSearchDataArr = [...modifiedState.data]
                                            
                    const retrieveRtcIssueDetails = response.data.retrieveRtcIssueDetails;

                    for(let i = 0; i < retrieveRtcIssueDetails.length; i++) {
                        const RtcIssueDetails = retrieveRtcIssueDetails[i];
                        let RcvdDtTmLocaleDateTime = RtcIssueDetails.RcvdDtTm == null ? "-" : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(RtcIssueDetails.RcvdDtTm);

                        // If the retrieved parameters value is null or RcvdDtTm is null then we show '-' in the react table.
                        // this case will only happen when we track RTC issue by Device ID. 
                        // Otherwise we are not showing rows which not having RTC issue in last 1 hours.
                        let singleRtcIssueData = {
                            DeviceID: RtcIssueDetails.DeviceID == null ? "-" : RtcIssueDetails.DeviceID,
                            HasRtcIssue: RtcIssueDetails.HasRtcIssue == null || RtcIssueDetails.RcvdDtTm == null ? "No" : RtcIssueDetails.HasRtcIssue == "false" ? "Yes" : "No",
                            DeviceRTCDateTime: RtcIssueDetails.DvcSentDtTm == null || RtcIssueDetails.RcvdDtTm == null ? "-" : RtcIssueDetails.DvcSentDtTm,
                            DateTimeIssueSeen: RcvdDtTmLocaleDateTime,
                            IssueSeenSrc: RtcIssueDetails.Src == null || RtcIssueDetails.RcvdDtTm == null ? "-" : RtcIssueDetails.Src,
                        };
                        stateSearchDataArr.push(singleRtcIssueData);
                    } 
                    
                    modifiedState.data = stateSearchDataArr;
                }
            } else {
                if(response.data.code == 'REQ_PARAM_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (Device Params not received).\n Try again later.';
        
                } else if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});  
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});  
            }
        })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = state.errors;

        switch (name) {

            case 'deviceID': 
                errors.requiredField = ""       
            break;

            default:
            break;
        }

        setState({
            ...state,
            errors, 
            [name]: value,             
        });
    }

    const {errors}=state;

    return (
        <div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-12,col-md-12 ,col-sm-12 ">
                        <div className = "customerTableHeading" style={{marginTop: "0.2rem"}}>Track RTC Issue(s)</div> 
                        <div className="modal-body p-4 box" style={{marginTop: "0.2rem"}}>        
                            <form onSubmit= {getRtcIssueDetails}>
                                
                                <div className="trackRadioDiv">
                                    <div className = "trackradioBox">
                                        <div>
                                            <input type='radio' name='trackBy'
                                                id = "trackByAllDevices"
                                                value= "trackByAllDevices"
                                                onChange={onTrackByRadioBtnChange} noValidate 
                                                defaultChecked={true}
                                            /> 
                                            <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Track for All Devices</span>
                                        </div>

                                        <div>
                                            <input type='radio' name='trackBy'  
                                                id = "trackBySingleDeviceID" 
                                                value= "trackBySingleDeviceID"
                                                onChange={onTrackByRadioBtnChange} noValidate 
                                                
                                            />
                                            <span style={{marginLeft: "0.3rem"}}> Track by Device ID</span>
                                        </div>
                                    </div>
                                </div>

                                <div  style={{display: state.trackBy == "trackBySingleDeviceID" ? "block" : "none"}}>
                                    <div className="inputgroupCustom">
                                        <label className="reg-form-label">Device ID: 
                                            <span className="addCustRequiredMarkStar">*</span>
                                        </label>    
                                        <input type='text' name='deviceID' className="input-form"  
                                            value={state.deviceID}
                                            onChange={handleChange} noValidate 
                                        />
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                    <div>
                                        <button type="submit" className="addCustSavebtn"  name="Save" >
                                            Refresh
                                        </button>
                                    </div>   
                                </div>                                        
                                <div className = "buttonErrorMessage">
                                    {errors.others.length > 0 && 
                                        <p className='addCustError' style={{textAlign: "center"}}>{errors.others}</p>}
                                    {errors.requiredField.length > 0 && 
                                        <p className='addCustError' style={{textAlign: "center"}}>{errors.requiredField}</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div style={{borderStyle: "solid", marginTop: "0rem", borderWidth: "1px"}}> */}
                <div>
                    {/* <div className = "customerTableHeading" style ={{borderBottom:" 1px solid rgba(0,0,0,.1)"}}> Tracked RTC Issue(s) Result</div>  */}
                    <div className = "customerTableHeading"> Tracked RTC Issue(s) Result</div> 
                    <div className="border border-3 ReactTableStyle">
                        <ReactTable 
                            columns={state.columns} 
                            data={state.data}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

VcTrackRtcIssues.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcTrackRtcIssues;
