import React, { Component } from 'react';
import '../CSS/FAQ.css';
import { AppRelevantDataContext } from '../../AppContext';

class VcFAQContent extends Component {
  
  onClick = () => {
    const { onClick, id } = this.props;
    onClick(id);
  }

  render() {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;
    const { no,question, answer,active } = this.props;

    return (
      <div className="container">
          <div className="row">
              <div className="container col-lg-10 ">
                  <div className="faqbox" style={{paddingBottom: "0.5rem"}}>
                      <div onClick={this.onClick} className="faq-que">
                        <h5>{no}.<span className="faq-que-content" dangerouslySetInnerHTML={{__html: t(question)}}></span></h5> 
                      </div>
                      {active 
                      ? 
                        <div style={{marginLeft: "0.5rem", marginRight: "0.5rem"}} className="faq-ans" dangerouslySetInnerHTML={{__html: t(answer)}}></div>
                      :
                        null 
                      }
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

VcFAQContent.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcFAQContent

