// This file contains all the common utility functions which can be used
// by all modules

////////////// To put zero in front of single digit number ////////////////////
function pad(num) {
    return (num < 10) ? ("0" + num) : num;
}

///////////////// To convert the Date object to local YYYY-MM-DD HH24:MI:SS format string  ///////////////////////////////////////
function convertLocalDateToStrYYYYMMDDHH24MMSS(inpDate) {

    let dy = inpDate.getYear()+1900;
    let dm = inpDate.getMonth()+1;
    let dd = inpDate.getDate();
    let dh = inpDate.getHours();
    let dmin = inpDate.getMinutes();
    let ds = inpDate.getSeconds();

    let strDateAsString = pad(dy) + "-" + pad(dm) + "-" + pad(dd) + " " + pad(dh) + ":" + pad(dmin) + ":" + pad(ds);

    return strDateAsString;
}

///////////////// To convert the Date object to UTC YYYY-MM-DD HH24:MI:SS format string  ///////////////////////////////////////
function convertUTCDateToStrYYYYMMDDHH24MMSS(inpDate) {

    let dy = inpDate.getUTCFullYear();
    let dm = inpDate.getUTCMonth()+1;
    let dd = inpDate.getUTCDate();
    let dh = inpDate.getUTCHours();
    let dmin = inpDate.getUTCMinutes();
    let ds = inpDate.getUTCSeconds();

    let strDateAsString = pad(dy) + "-" + pad(dm) + "-" + pad(dd) + " " + pad(dh) + ":" + pad(dmin) + ":" + pad(ds);

    return strDateAsString;
}

/////////////// To get Local Time Zone Offset ///////////////////////////////////////////////////////////////////

function getLocalTimezoneOffset ( localTimeZone ) {

    let tzOffset = localTimeZone;
    let sign = (tzOffset < 0) ? '+' : '-';

    let tzOffsetWithoutSign = Math.abs(tzOffset);

    let hourPart = Math.floor(tzOffsetWithoutSign / 60);  // To retrieve the Hour part without decimal, Floor is applied
    let minutePart = tzOffsetWithoutSign % 60;

    let finalTimeZoneOffset = sign + pad(hourPart) + ":" + pad(minutePart);

    return finalTimeZoneOffset;
}

///////////////// To convert UTC Date string to Locale String ///////////////////////////////////////

function convertUTCDateStringToLocaleString ( UTCDateString ) {
    let strUTCdate = UTCDateString;

    let strStandardDateTime = new Date(strUTCdate);
    let strLocaleDateTime = strStandardDateTime.toLocaleString(navigator.language);

    return strLocaleDateTime;

}

///////////////// To convert UTC Date string to Local Date with Format 'DD-MMM-YY HH24:MI:SS' ///////////////////////////////////////
function convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS( UTCDateString ) {

    let strUTCdate = UTCDateString;

    let inpDate = new Date(strUTCdate);

    let strArrMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let dy = inpDate.getYear()+1900;
    let dm = strArrMonths[inpDate.getMonth()];
    let dd = inpDate.getDate();
    let dh = inpDate.getHours();
    let dmin = inpDate.getMinutes();
    let ds = inpDate.getSeconds();

    let strDateAsString = pad(dd) + "-" + dm + "-" + pad(dy) + " " + pad(dh) + ":" + pad(dmin) + ":" + pad(ds);

    return strDateAsString;

}

///////////////// To convert Local Date with Format 'DD-MMM-YY ' ///////////////////////////////////////
function convertLocalDateWithFormatDDMMMYY( LocalDateString ) {

    let strLocaldate = LocalDateString;

    let inpDate = new Date(strLocaldate);

    let strArrMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let dy = inpDate.getYear()+1900;
    let dm = strArrMonths[inpDate.getMonth()];
    let dd = inpDate.getDate();

    let strDateAsString = pad(dd) + "-" + dm + "-" + pad(dy);

    return strDateAsString;

}


///////////////// To Replace Local Date string with 'Today' for current Date  ///////////////////////////////////////

function convertLocalDateToDisplayToday( LocalDateString ) {


    let inpDate = new Date(LocalDateString);
    let strDateTimeLocale;

    if( new Date(inpDate).getFullYear() == new Date().getFullYear() && 
        new Date(inpDate).getDate() == new Date().getDate() && 
        new Date(inpDate).getMonth() == new Date().getMonth()
        ) {
            strDateTimeLocale =  "Today," + " " + pad(new Date(inpDate).getHours()) + ":" + pad(new Date(inpDate).getMinutes()) + ":" + pad(new Date(inpDate).getSeconds());
        } else {
            strDateTimeLocale = LocalDateString;
        }

    return strDateTimeLocale;

}

///////////////// To trim the String, and if last character is ',' then remove ',' and agin trim.///////////////////////////////////////
function trimStringAndRemoveTrailingComma( inString ) {
    
    if(inString == null || inString.length <= 0) {
        return inString; // No need to process further.
    } else {
        let strAfterFirstTrim = inString.trim();
        let strAfterFirstTrimLength = strAfterFirstTrim.length;
        let strAfterSlice;
        let strRetVal;

        if(strAfterFirstTrim.charAt(strAfterFirstTrimLength - 1) == ',') {
            strAfterSlice = strAfterFirstTrim.slice(0, -1);
            strRetVal = strAfterSlice.trim();
        } else {
            strRetVal = strAfterFirstTrim;
        }

        return strRetVal;         
    }

}

///////////////// To Calculate distance between two points.///////////////////////////////////////
function calculateDistancebetweenTwoPoints( P1Lat, P1Lon, P2Lat, P2Lon, isMiles ) {

    function toRad(x) {
        return x * Math.PI / 180;
    }

    let EarthRadius = 6371; // km

    let diffbwLat = P2Lat - P1Lat;
    let dLat = toRad(diffbwLat);
    let diffbwLon = P2Lon - P1Lon;
    let dLon = toRad(diffbwLon)

    // Haversine Formula
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(P1Lat)) * Math.cos(toRad(P2Lat)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in Km.
    let distance = EarthRadius * c;

    if(isMiles) {
        distance /= 1.60934;
    }

    return distance;
}

///////////////// To format a string containing dynamic variables.///////////////////////////////////////
function tFormat(fmt, ...args) {
    if (!fmt.match(/^(?:(?:(?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{[0-9]+\}))+$/)) {
        console.log(`Invalid format string: [${fmt}]` );
        return fmt;
    }
    return fmt.replace(/((?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{([0-9]+)\})/g, (m, str, index) => {
        if (str) {
            return str.replace(/(?:{{)|(?:}})/g, m => m[0]);
        } else {
            if (index >= args.length) {
                console.log(`Argument index ${index} is out of range in format string: [${fmt}]` );
                return '';
            }
            return args[index];
        }
    });
}

///////////////// Get list of dates between two dates - including start and end date  ///////////////////////
function getListOfDatesBetweenRange(inStartDate, inEndDate) {

    let retList = [];

    if(inStartDate == null || inEndDate == null) {
        console.log(`Util::getListOfDatesBetweenRange - Error - Date Range not specified.`);
        return retList; // Return empty list
    }

    let nextDate = new Date(inStartDate.valueOf());
    while (nextDate < inEndDate){
        retList.push( new Date(nextDate.valueOf()) ); // Create new copy of the date before pushing to the list
        nextDate.setDate( nextDate.getDate() + 1 );
    };

    // Finally push the end date into the list
    retList.push( new Date(inEndDate.valueOf()) ); // Create new copy of the date before pushing to the list

    return retList; // Return empty list
}

///////////////// Get list of Hours between two date times - including the first hour and the current hour ///////////////////////
function getListOfHoursBetweenRange(inStartDate, inEndDate) {

    let retList = [];

    if(inStartDate == null || inEndDate == null) {
        console.log(`Util::getListOfHoursBetweenRange - Error - Date Range not specified.`);
        return retList; // Return empty list
    }

    let nextDateTm = new Date(inStartDate.valueOf());
    while (nextDateTm < inEndDate){
        retList.push( new Date(nextDateTm.valueOf()) ); // Create new copy of the date before pushing to the list
        nextDateTm.setHours( nextDateTm.getHours() + 1 );
    };

    // Finally push the end date time into the list
    retList.push( new Date(inEndDate.valueOf()) ); // Create new copy of the date before pushing to the list

    return retList; // Return empty list
}

///////////////// Get list of Minutes between two date times - including the first minute and the current minute ///////////////////////
function getListOfMinutesBetweenRange(inStartDate, inEndDate) {

    let retList = [];

    if(inStartDate == null || inEndDate == null) {
        console.log(`Util::getListOfMinutesBetweenRange - Error - Date Range not specified.`);
        return retList; // Return empty list
    }

    let nextDateTm = new Date(inStartDate.valueOf());
    while (nextDateTm < inEndDate){
        retList.push( new Date(nextDateTm.valueOf()) ); // Create new copy of the date before pushing to the list
        nextDateTm.setMinutes( nextDateTm.getMinutes() + 1 );
    };

    // Finally push the end date time into the list
    retList.push( new Date(inEndDate.valueOf()) ); // Create new copy of the date before pushing to the list

    return retList; // Return empty list
}

///////////////// Get list of Seconds between two date times - including the first Seccond and the current Second ///////////////////////
function getListOfSecondsBetweenRange(inStartDate, inEndDate) {

    let retList = [];

    if(inStartDate == null || inEndDate == null) {
        console.log(`Util::getListOfSecondsBetweenRange - Error - Date Range not specified.`);
        return retList; // Return empty list
    }

    let nextDateTm = new Date(inStartDate.valueOf());
    while (nextDateTm < inEndDate){
        retList.push( new Date(nextDateTm.valueOf()) ); // Create new copy of the date before pushing to the list
        nextDateTm.setSeconds( nextDateTm.getSeconds() + 1 );
    };

    // Finally push the end date time into the list
    retList.push( new Date(inEndDate.valueOf()) ); // Create new copy of the date before pushing to the list

    return retList; // Return empty list
}

///////////////// To convert the Date object to local 'MON-DD' format string  ///////////////////////////////////////
function convertDateToStrMMMDD(inpDate) {

    let dateToConvert = new Date(inpDate.valueOf());

    let strArrMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let dm = strArrMonths[dateToConvert.getMonth()];
    let dd = dateToConvert.getDate();

    let strDateAsString =  dm + "-" + pad(dd);

    return strDateAsString;
}

///////////////// To convert the Date object to local 'Dow MON-DD' format string  ///////////////////////////////////////
function convertDateToStrDowMMMDD(inpDate) {

    let dateToConvert = new Date(inpDate.valueOf());

    let strArrMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let strArrDayOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    let dm = strArrMonths[dateToConvert.getMonth()];
    let dd = dateToConvert.getDate();
    let dow = strArrDayOfTheWeek[dateToConvert.getDay()];

    let strDateAsString = dow + " " + dm + "-" + pad(dd);

    return strDateAsString;
}

///////////////// To convert the Date object to local 'MON-DD HH:00' format string  ///////////////////////////////////////
function convertDateToStrMMMDDHH00(inpDate) {

    let dateToConvert = new Date(inpDate.valueOf());

    let strArrMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let dm = strArrMonths[dateToConvert.getMonth()];
    let dd = dateToConvert.getDate();
    let dh = dateToConvert.getHours();

    let strDateAsString = dm + "-" + pad(dd) + " " + pad(dh) + ":" + "00";

    return strDateAsString;
}

///////////////// To convert the Date object to local 'MON-DD HH:MI' format string  ///////////////////////////////////////
function convertDateToStrMMMDDHHMI(inpDate) {

    let dateToConvert = new Date(inpDate.valueOf());

    let strArrMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let dm = strArrMonths[dateToConvert.getMonth()];
    let dd = dateToConvert.getDate();
    let dh = dateToConvert.getHours();
    let dmin = dateToConvert.getMinutes();

    let strDateAsString = dm + "-" + pad(dd) + " " + pad(dh) + ":" + pad(dmin);

    return strDateAsString;
}

///////////////// To convert the Date object to local 'MON-DD HH:MI:SS' format string  ///////////////////////////////////////
function convertDateToStrMMMDDHHMISS(inpDate) {

    let dateToConvert = new Date(inpDate.valueOf());

    let strArrMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let dm = strArrMonths[dateToConvert.getMonth()];
    let dd = dateToConvert.getDate();
    let dh = dateToConvert.getHours();
    let dmin = dateToConvert.getMinutes();
    let dsec = dateToConvert.getSeconds();

    let strDateAsString = dm + "-" + pad(dd) + " " + pad(dh) + ":" + pad(dmin) + ":" + pad(dsec);

    return strDateAsString;
}

///////////////// Get nested children from the array of tree node object  ///////////////////////////////////////
function getNestedChildren(inarrHierarchicalNodeInfo, parentId) {
    let arrNestedChildren = []

    if(inarrHierarchicalNodeInfo == null || inarrHierarchicalNodeInfo.length <= 0) {
        console.log("Should not happen. Received Hierarchical node info is empty or null");
        return arrNestedChildren;
    }

    for(let i in inarrHierarchicalNodeInfo) {
        if(inarrHierarchicalNodeInfo[i].ParentNode == parentId) {
            let children = getNestedChildren(inarrHierarchicalNodeInfo, inarrHierarchicalNodeInfo[i].id)

            if(children.length > 0) {
                inarrHierarchicalNodeInfo[i].children = children
            }
            arrNestedChildren.push(inarrHierarchicalNodeInfo[i]);
        }
    }
    return arrNestedChildren;
}

module.exports.convertLocalDateWithFormatDDMMMYY = convertLocalDateWithFormatDDMMMYY;
module.exports.convertLocalDateToDisplayToday = convertLocalDateToDisplayToday;
module.exports.trimStringAndRemoveTrailingComma = trimStringAndRemoveTrailingComma;
module.exports.convertLocalDateToStrYYYYMMDDHH24MMSS = convertLocalDateToStrYYYYMMDDHH24MMSS;
module.exports.convertUTCDateToStrYYYYMMDDHH24MMSS = convertUTCDateToStrYYYYMMDDHH24MMSS;
module.exports.getLocalTimezoneOffset = getLocalTimezoneOffset;
module.exports.convertUTCDateStringToLocaleString = convertUTCDateStringToLocaleString;
module.exports.convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS;
module.exports.pad = pad;
module.exports.calculateDistancebetweenTwoPoints = calculateDistancebetweenTwoPoints;
module.exports.tFormat = tFormat;
module.exports.getListOfDatesBetweenRange = getListOfDatesBetweenRange;
module.exports.getListOfHoursBetweenRange = getListOfHoursBetweenRange;
module.exports.getListOfMinutesBetweenRange = getListOfMinutesBetweenRange;
module.exports.getListOfSecondsBetweenRange = getListOfSecondsBetweenRange;
module.exports.convertDateToStrMMMDD = convertDateToStrMMMDD;
module.exports.convertDateToStrDowMMMDD = convertDateToStrDowMMMDD;
module.exports.convertDateToStrMMMDDHH00 = convertDateToStrMMMDDHH00;
module.exports.convertDateToStrMMMDDHHMI = convertDateToStrMMMDDHHMI;
module.exports.convertDateToStrMMMDDHHMISS = convertDateToStrMMMDDHHMISS;
module.exports.getNestedChildren = getNestedChildren;
// module.exports.renderLoadingPage = renderLoadingPage;
