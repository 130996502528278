import React, { useState, useEffect, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext } from '../../AppContext';
import {convertUTCDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS} from '../../vtUtil';
import { IDS_RDEndDtGrtCurDt, IDS_RDEndDTAdjust, IDS_RDStartDTAdjust, IDS_RDStartDTAdjustNotMaxRng, IDS_RDStartDTSetAcrodEndDT, IDS_RDStartDTAdjustMinPossible,
        IDS_RDEndDTNotInRng, IDS_RDEndDTSetAcrodStartDT } from '../../VcLanguage';
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaSearch} from 'react-icons/fa';
import { CSV_COLUMN_SEPERATOR, CSV_LINE_SEPERATOR, MAX_DAYS_RANGE, MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA, ORDER_STATE_MARKED_FOR_SHIPPING, ORDER_STATE_PLACED, ORDER_STATE_PROCESSING, ORDER_TYPE_DEMO, ORDER_TYPE_SALE, ORDER_TYPE_RENTAL, INCLUDES_ALL_PRODUCT_NAME } from '../../VcConstants';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    )
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({columns, data, renderBasedOnTable, getCellProps = defaultPropGetter, passedStateVariables}) => {

    // following if statements are to hide and show columns as per ordertype selected and to change col names as well
    let columnsToHideAndShow = [];
    let HeadersToShowForSubscriptionPrice;
    let HeadersToShowForSubscriptionTax;
    if(passedStateVariables == ORDER_TYPE_DEMO || passedStateVariables == ORDER_TYPE_SALE){
        columnsToHideAndShow = ["TaxPriceOnOneTimeChrgPUOI", "OneTimeChrgOnRentPUOI", "SecurityDepositOnRentPUOI"];
        HeadersToShowForSubscriptionPrice = <div className="AlertLogTableHeader">Sub Price Per<br/>Unit Order (INR)</div>
        HeadersToShowForSubscriptionTax = <div className="AlertLogTableHeader">Sub Tax Price Per<br/>Unit Order (INR)</div>
    } else if(passedStateVariables == ORDER_TYPE_RENTAL ){
        columnsToHideAndShow = ["PricePerUnitOrderINR", "TaxPerUnitOrderINR"];
        HeadersToShowForSubscriptionPrice = <div className="AlertLogTableHeader">Rental Period<br/>Price Per<br/>Unit Order (INR)</div>
        HeadersToShowForSubscriptionTax = <div className="AlertLogTableHeader">Rental Period<br/>Tax Price Per<br/>Unit Order (INR)</div>
    } else {
        columnsToHideAndShow = [];
        HeadersToShowForSubscriptionPrice = <div className="AlertLogTableHeader">Sub/Rental Period<br/>Price Per<br/>Unit Order (INR)</div>
        HeadersToShowForSubscriptionTax = <div className="AlertLogTableHeader">Sub/Rental Period<br/>Tax Price Per<br/>Unit Order (INR)</div>
    }

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({        
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
        getHeaderProps = defaultPropGetter,
        getColumnProps = defaultPropGetter,
        setHiddenColumns,
        } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // set page size based on tables
            initialState: { pageSize: renderBasedOnTable == "FilteredAllOrderDataTable" || 
                renderBasedOnTable == "AllOrderDetailsTable" ? 10 : 3,
                // hide columns
                hiddenColumns: columnsToHideAndShow,  
            },  
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    useEffect(() => {   // set hidden column
        // To update hiddenColumns, pass a new array into setHiddenColumns which is supplied by useTable.
        // Changing hiddenColumns directly won't cause the table to add the hidden columns back.
        setHiddenColumns(
            columnsToHideAndShow
        );
    }, [passedStateVariables]);
   
    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp; No Data Found</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header').props.column.id == "SubscriptionPricePerUnitOrderINR" ? HeadersToShowForSubscriptionPrice : 
                                        column.render('Header').props.column.id == "SubscriptionTaxPerUnitOrderINR" ? HeadersToShowForSubscriptionTax :
                                        column.render('Header') 
                                    }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead hidden = {renderBasedOnTable == "SalesDashboardTable" ? true : false}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr" 
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style,},
                                            getCellProps(cell),
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || 
                        //     // when there is no data found 
                        //     <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex", justifyContent:"left"}}>{"No Data Found."}</span>
                        //         </td>
                        //     </tr>                            
                        }
                        {/* add empty rows based on page size */}
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            {renderBasedOnTable != "SalesDashboardTable" &&
                <div className='w-100 d-flex justify-content-end antdPagination'>
                    <div className='d-flex py-1 justify-content-end align-items-center'>
                        <Pagination showQuickJumper 
                            defaultCurrent={pageIndex + 1} 
                            total={pageOptions.length * 10}
                            // total={10 * 10} 
                            onChange={(page) => gotoPage(page - 1)} 
                            showSizeChanger={false}
                            current={pageIndex + 1} 
                        />

                        <Select
                            style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                            aria-label="rows per page"
                            className="paginationDropdown mySelector"
                            value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                            onChange={e => setPageSize(Number(e))} 
                            options={[
                                {
                                    label: "5 rows",
                                    value: 5
                                },
                                {
                                    label: "10 rows",
                                    value: 10
                                },
                                {
                                    label: "20 rows",
                                    value: 20
                                },
                                {
                                    label: "25 rows",
                                    value: 25
                                },
                                {
                                    label: "100 rows",
                                    value: 100
                                }
                            ]} // Assuming options is the array of options for the Select component
                        />
                    </div>
                </div>
            }

            {/* <div hidden = {renderBasedOnTable == "SalesDashboardTable" ? true : false}>
                <div className="-pagination">
                <div className="-previous">
                    <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                </div>
                <div className="-center">
                    <span className="-pageInfo">
                        Page {" "}    
                        <div className="-pageJump">
                            <input 
                                value={pageIndex + 1} 
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                aria-label="jump to page" type="number"
                            />
                        </div>
                        of {" "}  
                        <span className="-totalPages">{pageOptions.length}</span>
                    </span>
                    <span className="select-wrap -pageSizeOptions">
                        <select aria-label="rows per page"
                            style={{padding:"0.2rem"}}
                            value={pageSize}
                            onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}>
                            {[5, 10, 20, 25, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize} rows
                                </option>
                            ))}
                        </select>
                    </span>  
                </div>
                <div className="-next">
                    <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                </div>
            </div>
            </div> */}
        </div>
    );
};

function VcAllOrderDetailsInfo (props) {

    const csvLink = React.createRef();
    let textFile = null;

    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        if (id == "OrderDate" || id == "RequireDate" || id == "FulfilledDate" || id == "LastModifiedTime" || id == "OrderDate" || id == "RequireDate"){

            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]                    
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        } else {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
            });
        }
    };

    const [state, setState] = useState({
        StartDateTime: new Date(new Date().setHours(new Date().getHours() - 24)),
        EndDateTime: new Date(),
        csvData : [],
        AllOrderDataForSelectedTimePeriod: [],
        FilteredAllOrderDataForSelectedTimePeriod:[],
        bAllOrderInfoViewDataModalOpen: false,
        backdrop: 'static',
        enableStagewiseProdStat: false,
        onHoverRowIndex: null,
        onHoverColID: "",
        selectedCellOrderStatus: "",
        arrOrderDasboardData: [],
        selectedOrderType: INCLUDES_ALL_PRODUCT_NAME,
        ArrForOrderType: [INCLUDES_ALL_PRODUCT_NAME, ORDER_TYPE_DEMO, ORDER_TYPE_SALE, ORDER_TYPE_RENTAL],
        OrderType:"",
        productColumns: [
            {       
                Header:() => <div className="AlertLogTableHeader">Order<br/>Number</div>, 
                accessor: 'OrderNumber',
                filter: filterCaseInsensitive,
                width: 110,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Status</div>, 
                accessor: 'Status',
                filter: filterCaseInsensitive,
                width: 140,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">Order<br/>Type</div>,  
                accessor: 'OrderType',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">Order<br/>Date</div>,  
                accessor: 'OrderDate',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">Required<br/>Date</div>,  
                accessor: 'RequireDate',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Fulfilled<br/>Date</div>,  
                accessor: 'FulfilledDate',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Customer<br/>Name</div>,  
                accessor: 'CustName',
                filter: filterCaseInsensitive,
                width: 120,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Customer<br/>ID</div>,  
                accessor: 'CustID',
                filter: filterCaseInsensitive,
                width: 120,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Customer<br/>Email ID</div>,  
                accessor: 'CustEmailID',
                filter: filterCaseInsensitive,
                width: 120,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Customer<br/>Organisation Name</div>,  
                accessor: 'CustOrganisationName',
                filter: filterCaseInsensitive,
                width: 180,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">Customer<br/>Phone No</div>,  
                accessor: 'CustPhoneNo',
                filter: filterCaseInsensitive,
                width: 130,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Price Per Unit<br/>Order (INR)</div>,  
                accessor: 'PricePerUnitOrderINR',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Tax Per Unit<br/>Order (INR)</div>,  
                accessor: 'TaxPerUnitOrderINR',
                filter: filterCaseInsensitive,
                width: 131,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">One Time<br/>Charge (INR)</div>,  
                accessor: 'OneTimeChrgOnRentPUOI',
                filter: filterCaseInsensitive,
                width: 137,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Tax Price On One<br/>Time Charge (INR)</div>,  
                accessor: 'TaxPriceOnOneTimeChrgPUOI',
                filter: filterCaseInsensitive,
                width: 190,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Security<br/>Deposit (INR)</div>,  
                accessor: 'SecurityDepositOnRentPUOI',
                filter: filterCaseInsensitive,
                width: 141,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Sub/Rental Period<br/>Price Per<br/>Unit Order (INR)</div>,  
                accessor: 'SubscriptionPricePerUnitOrderINR',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Sub/Rental Period<br/>Tax Price Per<br/>Unit Order (INR)</div>,  
                accessor: 'SubscriptionTaxPerUnitOrderINR',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Total Order<br/>Value (INR)</div>,  
                accessor: 'TotalOrderValue',
                filter: filterCaseInsensitive,
                width: 130,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Comment</div>,  
                accessor: 'Comment',
                filter: filterCaseInsensitive,
                width: 120,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Sales Rep Email ID</div>,  
                accessor: 'SalesRepUserID',
                filter: filterCaseInsensitive,
                width: 160,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">LastModifiedTime</div>,  
                accessor: 'LastModifiedTime',
                filter: filterCaseInsensitive,
                width: 170,
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = firstRow.original[columnId] == null ? "" : new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = secondRow.original[columnId] == null ? "" : new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            },
        ],
        OrderDashboardColumns: [
            {       
                Header:() => <div className="AlertLogTableHeader">Order Status</div>, 
                accessor: 'OrderStatus',
                width: 331,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Order Count</div>, 
                accessor: 'OrderCount',
                width: 331,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
            }, 
        ],
        errors:'',
        timeRelatedErrors:'',
    });

    const onClickOrderStatusCount = (inModifiedState = null) => {

        let modifiedState;
        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        if((props.invokedFromProp == "SalesDashboard" && (modifiedState.selectedCellOrderStatus == ORDER_STATE_PLACED ||
            modifiedState.selectedCellOrderStatus == ORDER_STATE_PROCESSING )
           )
        ) {
            modifiedState.bAllOrderInfoViewDataModalOpen = false;
            getAllOrderInfoForSelectedDate(modifiedState);
        } else {
            setState({...modifiedState});
            return;
        }
    }

    useEffect(() =>{
        // To Get Device Profile Information.
        if(props.invokedFromProp == "SalesDashboard") {
            getTotalNumberOfOrderCountForEachOrderStatus();
        }    
    }, []);

    const onHoverGetRowIndexAndColID = (rowInfo, column) => {
        let modifiedState = state;
        modifiedState.onHoverRowIndex = rowInfo["index"];
        modifiedState.onHoverColID = column["id"]
        setState({...modifiedState});
    }

    const getTotalNumberOfOrderCountForEachOrderStatus = () => {

        let modifiedState = state;
        modifiedState.arrOrderDasboardData = [];

        axios.post(`${getAPIHostURL()}/wclient/getTotalNumberOfOrderCountForEachOrderStatus`)    
        .then( response => {
            if(response.data.code == 'SUCCESS') {

                let retrievedOrderCountForEachOrderStatus = response.data.retrievedOrderCountForEachOrderStatus;

                const arrOrderStatus = [ORDER_STATE_PLACED, ORDER_STATE_PROCESSING, ORDER_STATE_MARKED_FOR_SHIPPING];
                for(let k = 0; k < arrOrderStatus.length; k++) {
                    let singleOrderCountData = {}
                    let isOrderStatusIncludeInArr = false;
                    for(let l = 0; l < retrievedOrderCountForEachOrderStatus.length; l++) {
                        if(arrOrderStatus[k] == retrievedOrderCountForEachOrderStatus[l]["OrderStatus"]){
                            isOrderStatusIncludeInArr = true;
                            singleOrderCountData = {
                                OrderStatus: arrOrderStatus[k],
                                OrderCount: retrievedOrderCountForEachOrderStatus[l]["OrderCount"]
                            }
                            break;
                        }
                    }
                    if(isOrderStatusIncludeInArr == false) {
                        singleOrderCountData = {
                            OrderStatus: arrOrderStatus[k],
                            OrderCount: "0",
                        }
                    }
                    modifiedState.arrOrderDasboardData.push(singleOrderCountData);
                }

            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});

        })
        .catch( error => {
            console.log("Network Error while getting all order data");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        });
    }

    const onRowClick = (rowInfo) => {
        
        if (rowInfo.row != undefined && rowInfo.column != undefined) {
            let modifiedState = state;
            return {
                onClick: (e, handleOriginal) => {

                    modifiedState.selectedRowIndex = rowInfo.row.index;
                    let selectedRowOrderStatusCell = rowInfo.row["original"]["OrderStatus"];

                    if(rowInfo.row["original"]["OrderCount"] != 0) {
                        modifiedState.selectedCellOrderStatus = selectedRowOrderStatusCell;
                    } else {
                        modifiedState.selectedCellOrderStatus = "";
                    } 

                    setState({...modifiedState});
                    onClickOrderStatusCount(modifiedState);
                                    
                },
                style: {
                    cursor: 'pointer',
                    background: rowInfo.row.index == state.selectedRowIndex ? '#6c95ba' : '',
                    color: rowInfo.row.index == state.selectedRowIndex ? 'white' : 'black',
                    alignItems: "center"
                }
            }
            
        } else {
            return {}
        }
    }

    const onChangeEndDateTime = (updatedTime) => {
        
        let modifiedState = state; 
        modifiedState.errors = '';

        let updatedEndDateTime = updatedTime;
        modifiedState.EndDateTime = updatedEndDateTime;

        setState({...modifiedState});
    }

    const onChangeStartDateTime = (updatedTime) => {

        let modifiedState = state;
        modifiedState.errors = '';

        let updatedActiveStartDateTime = updatedTime;

        modifiedState.StartDateTime = updatedActiveStartDateTime;
        setState({...modifiedState});
    }

    // function to filter out data as per selected ordertype
    const filterDataAsOrder = (selectedOrderType) => {
        let modifiedState = state;
        modifiedState.FilteredAllOrderDataForSelectedTimePeriod = selectedOrderType == INCLUDES_ALL_PRODUCT_NAME ? 
                                                                    modifiedState.AllOrderDataForSelectedTimePeriod 
                                                                    : 
                                                                    modifiedState.AllOrderDataForSelectedTimePeriod.filter(col => col.OrderType == selectedOrderType);
    }

    const onChangeOfOrderType = (e) => {
        let modifiedState = state;

        modifiedState.selectedOrderType = e.target.value;
        filterDataAsOrder(modifiedState.selectedOrderType);

        // following if statements are to hide and show columns as per ordertype selected and to change col names as well
        // if(modifiedState.selectedOrderType != INCLUDES_ALL_PRODUCT_NAME && modifiedState.selectedOrderType != ORDER_TYPE_RENTAL) {

            // for(let i=0; i<modifiedState.productColumns.length; i++) {
            //     if(modifiedState.productColumns[i]["accessor"] == "TaxPriceOnOneTimeChrgPUOI" || modifiedState.productColumns[i]["accessor"] == "OneTimeChrgOnRentPUOI" || modifiedState.productColumns[i]["accessor"] == "SecurityDepositOnRentPUOI") {
            //         modifiedState.productColumns[i]["show"] = false;
            //     } 
            //     if(modifiedState.productColumns[i]["accessor"] == "PricePerUnitOrderINR" || modifiedState.productColumns[i]["accessor"] == "TaxPerUnitOrderINR") {
            //         modifiedState.productColumns[i]["show"] = true;
            //     } 
            //     if(modifiedState.productColumns[i]["accessor"] == "SubscriptionPricePerUnitOrderINR") {
            //         modifiedState.productColumns[i]["Header"] = <div className="AlertLogTableHeader">Sub Price Per<br/>Unit Order (INR)</div>;
            //     }
            //     if(modifiedState.productColumns[i]["accessor"] == "SubscriptionTaxPerUnitOrderINR") {
            //         modifiedState.productColumns[i]["Header"] = <div className="AlertLogTableHeader">Sub Tax Price Per<br/>Unit Order (INR)</div>;
            //     }
            // }
        // }

        // if(modifiedState.selectedOrderType == ORDER_TYPE_RENTAL) {
            
            // for(let i=0; i<modifiedState.productColumns.length; i++) {
            //     if(modifiedState.productColumns[i]["accessor"] == "PricePerUnitOrderINR" || modifiedState.productColumns[i]["accessor"] == "TaxPerUnitOrderINR") {
            //         modifiedState.productColumns[i]["show"] = false;
            //     } 
            //     if(modifiedState.productColumns[i]["accessor"] == "TaxPriceOnOneTimeChrgPUOI" || modifiedState.productColumns[i]["accessor"] == "OneTimeChrgOnRentPUOI" || modifiedState.productColumns[i]["accessor"] == "SecurityDepositOnRentPUOI") {
            //         modifiedState.productColumns[i]["show"] = true;
            //     }
            //     if(modifiedState.productColumns[i]["accessor"] == "SubscriptionPricePerUnitOrderINR") {
            //         modifiedState.productColumns[i]["Header"] = <div className="AlertLogTableHeader">Rental Period<br/>Price Per<br/>Unit Order (INR)</div>;
            //     }
            //     if(modifiedState.productColumns[i]["accessor"] == "SubscriptionTaxPerUnitOrderINR") {
            //         modifiedState.productColumns[i]["Header"] = <div className="AlertLogTableHeader">Rental Period<br/>Tax Price Per<br/>Unit Order (INR)</div>;
            //     }
            // }
        // }

        // if(modifiedState.selectedOrderType == INCLUDES_ALL_PRODUCT_NAME) {
           
            // for(let i=0; i<modifiedState.productColumns.length; i++) {
            //     if(modifiedState.productColumns[i]["accessor"] == "PricePerUnitOrderINR" || modifiedState.productColumns[i]["accessor"] == "TaxPerUnitOrderINR") {
            //         modifiedState.productColumns[i]["show"] = true;
            //     } 
            //     if(modifiedState.productColumns[i]["accessor"] == "TaxPriceOnOneTimeChrgPUOI" || modifiedState.productColumns[i]["accessor"] == "OneTimeChrgOnRentPUOI" || modifiedState.productColumns[i]["accessor"] == "SecurityDepositOnRentPUOI") {
            //         modifiedState.productColumns[i]["show"] = true;
            //     }
            //     if(modifiedState.productColumns[i]["accessor"] == "SubscriptionPricePerUnitOrderINR") {
            //         modifiedState.productColumns[i]["Header"] = <div className="AlertLogTableHeader">Sub/Rental Period<br/>Price Per<br/>Unit Order (INR)</div>;
            //     }
            //     if(modifiedState.productColumns[i]["accessor"] == "SubscriptionTaxPerUnitOrderINR") {
            //         modifiedState.productColumns[i]["Header"] = <div className="AlertLogTableHeader">Sub/Rental Period<br/>Tax Price Per<br/>Unit Order (INR)</div>;
            //     }
            // }
        // } 

        modifiedState.OrderType = modifiedState.selectedOrderType ;
        setState({...modifiedState});
    }

    const onEndDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  
        let strMsg ="";

        let modifiedState = state;

        let currentlySetEndDateTime = modifiedState.EndDateTime;

        let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
        minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

        let currentlySetStartDateTime = modifiedState.StartDateTime;

        let minRequiredDateRange = new Date(currentlySetStartDateTime.valueOf());
        minRequiredDateRange.setDate(minRequiredDateRange.getDate() + 31);

        let currentDateTime = new Date();

        if(currentlySetEndDateTime > currentDateTime) {
            // strMsg = 'End Date Time cannot be greater than the current date time. It will be set to current date time.';
            strMsg = t(IDS_RDEndDtGrtCurDt);

            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = currentDateTime
        } else if(currentlySetEndDateTime <= currentlySetStartDateTime) {

            let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
            let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );

            if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {
                // strMsg = `End Date Time will be adjusted because it is not within a Range. The time will be adjusted to max possible for the day.`;
                strMsg = t(IDS_RDEndDTAdjust);
                
                modifiedState.timeRelatedErrors = strMsg;
                modifiedState.EndDateTime = new Date(currentlySetStartDateTime.valueOf());
                modifiedState.EndDateTime.setHours(23);
                modifiedState.EndDateTime.setMinutes(59);
                modifiedState.EndDateTime.setSeconds(59);
            } else {

                // strMsg = `Start Date Time will be adjusted because it is not within a Range. It will be set according to End Date Time.`;
                strMsg = t(IDS_RDStartDTAdjust);
                
                modifiedState.timeRelatedErrors = strMsg;

                modifiedState.StartDateTime = minRequiredStartDateTime
            }
            
            if(modifiedState.EndDateTime > currentDateTime) {
                modifiedState.timeRelatedErrors = strMsg;

                modifiedState.EndDateTime = currentDateTime
            }

        } else if(currentlySetEndDateTime > minRequiredDateRange) {

            // strMsg = `Start Date Time has been adjusted because it is not within a Maximum Range according to EndDateTime.`;
            strMsg = t(IDS_RDStartDTAdjustNotMaxRng);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.StartDateTime = minRequiredStartDateTime;

        } else {
            strMsg = "";
            modifiedState.timeRelatedErrors = strMsg;
        }

        setState({...modifiedState});
                
    }

    const onStartDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;    
        let strMsg = '';

        let modifiedState = state;

        let currentlySetEndDateTime = modifiedState.EndDateTime;

        // Incase time is greater than currentdatetime.
        let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
        minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

        
        let currentlySetStartDateTime = modifiedState.StartDateTime;

        // Incase of startDateTime greater than EndDateTime. Set EndDateTime According to StartDateTime.
        let minRequiredEndDateTime = new Date(currentlySetStartDateTime.valueOf());
        minRequiredEndDateTime.setHours(minRequiredEndDateTime.getHours() + 24);

        // required to compare Range between Start and End Date Time.
        let minRequiredDateRange = new Date(currentlySetEndDateTime.valueOf());
        minRequiredDateRange.setDate(minRequiredDateRange.getDate() - MAX_DAYS_RANGE);

        let currentDateTime = new Date();

        // Give a tolerance of -1 minutes to the current date time to avoid unnecessary messages
        // currentDateTime.setMinutes(currentDateTime.getMinutes() - 1)                

        let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
        let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );
        
        if(currentlySetStartDateTime != null && currentlySetStartDateTime >= currentDateTime) {

            // strMsg = `Start Date Time cannot be greater than the current date time. It will be set according to End date time.`;
            strMsg = t(IDS_RDStartDTSetAcrodEndDT);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.StartDateTime = minRequiredStartDateTime;

        } else if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {

            // strMsg = `Start Date Time will be adjusted because it is not within a Range. The time will be adjusted to minimum possible for the day.`;
            strMsg = t(IDS_RDStartDTAdjustMinPossible);
            
            modifiedState.timeRelatedErrors = strMsg;

            modifiedState.StartDateTime = new Date(currentlySetEndDateTime.valueOf());
            modifiedState.StartDateTime.setHours(0);
            modifiedState.StartDateTime.setMinutes(0);
            modifiedState.StartDateTime.setSeconds(0);

        } else if(currentlySetStartDateTime >= currentlySetEndDateTime) {

            // strMsg = `End Date Time has been adjusted because it is not within a Range.`;
            strMsg = t(IDS_RDEndDTNotInRng);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = minRequiredEndDateTime;

            if(modifiedState.EndDateTime >= currentDateTime) {
                modifiedState.EndDateTime = new Date();
            }

        } else if(currentlySetStartDateTime < minRequiredDateRange) {

            // strMsg = `End Date Time has been adjusted because it is not within a Maximum Range according to StartDateTime.`;
            strMsg = t(IDS_RDEndDTSetAcrodStartDT);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = minRequiredEndDateTime;

        } else {
            strMsg = "";
            modifiedState.timeRelatedErrors = strMsg;
        }

        setState({...modifiedState});
    }

    const onCloseButtonClick = () => {
        state.enableStagewiseProdStat = false;
    }

    const makeTextFile = (arrText) =>  {

        let data = new Blob(arrText, {type: 'text/plain'});

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (textFile !== null) {
            window.URL.revokeObjectURL(textFile);
        }

        textFile = window.URL.createObjectURL(data);

        // Return a URL you can use as an href
        return textFile;
    }

    const createAndFillCsvFile = (inarrCsvAllLines) => {

        let retVal = true;

        let foramttedStartDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.StartDateTime);
        let foramttedEndDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.EndDateTime);
        let fileName = "All Order Data From " + foramttedStartDateTime + " To " + foramttedEndDateTime + ".csv";

        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = makeTextFile(inarrCsvAllLines);
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            let event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });

        // Return true to indicate that operation performed successfully (in future any failures in 
        // the previous calls can be checked to return false)
        retVal = true;
        return retVal;
    }

    const toggleStagewiseProdStat = (e) => {

        setState({
            ...state,
            enableStagewiseProdStat: !state.enableStagewiseProdStat,
            bAllOrderInfoViewDataModalOpen: !state.bAllOrderInfoViewDataModalOpen
        });
    }

    const getAllOrderInfoForSelectedDate = (inModifiedState = null, bDownloadCsvOfAllOrder= false) => {

        let modifiedState;
        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let StartDateTime = modifiedState.StartDateTime;
        let EndDateTime = modifiedState.EndDateTime;
        modifiedState.timeRelatedErrors = '';
        modifiedState.AllOrderDataForSelectedTimePeriod = [];
        modifiedState.FilteredAllOrderDataForSelectedTimePeriod = [];
        let strStartDateTime = null;
        let strEndDateTime = null;

        if((props.invokedFromProp != null && props.invokedFromProp.length > 0 && props.invokedFromProp == "SalesDashboard" &&
            modifiedState.selectedCellOrderStatus != null && modifiedState.selectedCellOrderStatus.length > 0 ) && 
            (modifiedState.selectedCellOrderStatus == ORDER_STATE_PLACED || modifiedState.selectedCellOrderStatus == ORDER_STATE_PROCESSING )
        ) {
            strStartDateTime = null;
            strEndDateTime = null;
        } else {

            let diffBetweenTwoDates = Math.abs((StartDateTime - EndDateTime)/ (1000*60*60*24) );

            if( diffBetweenTwoDates > MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA ) {
                if(!window.confirm(`Are you sure you want to view the large data for more than 90 days of period?`)){
                    return
                }
            }

            strStartDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(StartDateTime) );
            strEndDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(EndDateTime) );

        }

        const jsonDeviceInfo = {
            InvokeFrom : props.invokedFromProp,
            SelectedCellOrderStatus: modifiedState.selectedCellOrderStatus,
            StartDateTime : strStartDateTime,
            EndDateTime : strEndDateTime,
        }

        axios.post(`${getAPIHostURL()}/wclient/getAllOrdersInfo`, jsonDeviceInfo)    
        .then( response => {
            if(response.data.code == 'SUCCESS') {

                let receivedAllOrderInformation = response.data.retrievedAllOrderData;

                if( receivedAllOrderInformation == null || receivedAllOrderInformation.length <= 0 ) {
                    modifiedState.errors = 'For Selected Time Period does not contain Order Data.';
                } else {
                    let receivedAllOrderInfoLen = receivedAllOrderInformation.length;

                    if(modifiedState.bAllOrderInfoViewDataModalOpen == true) {
                        modifiedState.enableStagewiseProdStat = true;
                    }

                    for(let i = 0; i < receivedAllOrderInfoLen; i++) {

                        let custFullName = receivedAllOrderInformation[i]["FirstName"] +" " +receivedAllOrderInformation[i]["LastName"]
                        let strOrderDateTimeLocale = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedAllOrderInformation[i]["OrderDate"]);
                        let strRequiredDateTimeLocale = (receivedAllOrderInformation[i]["RequiredDate"] == null) ? null :convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedAllOrderInformation[i]["RequiredDate"]);
                        let strFulFilledDateTimeLocale = (receivedAllOrderInformation[i]["FulfilledDate"] == null) ? null :convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedAllOrderInformation[i]["FulfilledDate"]);
                        let strLastModifiedDateTimeLocale = (receivedAllOrderInformation[i]["LastModifiedTime"] == null) ? null :convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedAllOrderInformation[i]["LastModifiedTime"]);

                        let singleInstanceInfo = {
                            OrderNumber: receivedAllOrderInformation[i]["OrderNumber"] == null ? '-': receivedAllOrderInformation[i]["OrderNumber"],
                            Status: receivedAllOrderInformation[i]["Status"] == null ? '-': receivedAllOrderInformation[i]["Status"],
                            OrderType: receivedAllOrderInformation[i]["OrderType"] == null ? '-': receivedAllOrderInformation[i]["OrderType"],
                            OrderDate: strOrderDateTimeLocale == null ? '-': strOrderDateTimeLocale,
                            RequireDate: strRequiredDateTimeLocale == null ? '-': strRequiredDateTimeLocale,
                            FulfilledDate: strFulFilledDateTimeLocale == null ? '-': strFulFilledDateTimeLocale,
                            CustName: custFullName == null ? '-': custFullName,
                            CustID: receivedAllOrderInformation[i]["CustID"] == null ? '-': receivedAllOrderInformation[i]["CustID"],
                            CustEmailID: receivedAllOrderInformation[i]["CustEmailID"] == null ? '-': receivedAllOrderInformation[i]["CustEmailID"],
                            CustOrganisationName: receivedAllOrderInformation[i]["OrganizationName"] == null ? '-': receivedAllOrderInformation[i]["OrganizationName"],
                            CustPhoneNo: receivedAllOrderInformation[i]["CustMobileNo"] == null ? '-': receivedAllOrderInformation[i]["CustMobileNo"],
                            PricePerUnitOrderINR: receivedAllOrderInformation[i]["PricePerUnitOrderINR"] == null ? '-': receivedAllOrderInformation[i]["PricePerUnitOrderINR"],
                            TaxPerUnitOrderINR: receivedAllOrderInformation[i]["TaxPerUnitOrderINR"] == null ? '-': receivedAllOrderInformation[i]["TaxPerUnitOrderINR"],
                            OneTimeChrgOnRentPUOI: receivedAllOrderInformation[i]["OneTimeChrgOnRentPUOI"] == null ? '-': receivedAllOrderInformation[i]["OneTimeChrgOnRentPUOI"],
                            TaxPriceOnOneTimeChrgPUOI: receivedAllOrderInformation[i]["TaxPriceOnOneTimeChrgPUOI"] == null ? '-': receivedAllOrderInformation[i]["TaxPriceOnOneTimeChrgPUOI"],
                            SecurityDepositOnRentPUOI: receivedAllOrderInformation[i]["SecurityDepositOnRentPUOI"] == null ? '-': receivedAllOrderInformation[i]["SecurityDepositOnRentPUOI"],
                            SubscriptionPricePerUnitOrderINR: receivedAllOrderInformation[i]["SubscriptionPricePerUnitOrderINR"] == null ? '-': receivedAllOrderInformation[i]["SubscriptionPricePerUnitOrderINR"],
                            SubscriptionTaxPerUnitOrderINR: receivedAllOrderInformation[i]["SubscriptionTaxPerUnitOrderINR"] == null ? '-': receivedAllOrderInformation[i]["SubscriptionTaxPerUnitOrderINR"],
                            TotalOrderValue: receivedAllOrderInformation[i]["TotalOrderValue"] == null ? '-': receivedAllOrderInformation[i]["TotalOrderValue"],
                            Comment: receivedAllOrderInformation[i]["Comments"] == null ? '-': receivedAllOrderInformation[i]["Comments"],
                            SalesRepUserID: receivedAllOrderInformation[i]["SalesRepEmailID"] == null ? '-': receivedAllOrderInformation[i]["SalesRepEmailID"],
                            LastModifiedTime: strLastModifiedDateTimeLocale == null ? '-': strLastModifiedDateTimeLocale,
                        }
                        modifiedState.AllOrderDataForSelectedTimePeriod.push(singleInstanceInfo);
                        modifiedState.FilteredAllOrderDataForSelectedTimePeriod.push(singleInstanceInfo)
                    }
                    filterDataAsOrder(modifiedState.selectedOrderType);
                    if(bDownloadCsvOfAllOrder == true) {
                        modifiedState.enableStagewiseProdStat = false;
                        DownloadCsv(modifiedState.FilteredAllOrderDataForSelectedTimePeriod)
                    }
                    modifiedState.errors = "";
                    modifiedState.timeRelatedErrors = "";
                }
            } else {

                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    modifiedState.errors = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                    
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});

        })
        .catch( error => {
            console.log("Network Error while getting all order data");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        });
    }

    const OnClickViewButton = () => {
        let modifiedState = state;
        modifiedState.bAllOrderInfoViewDataModalOpen = true;

        // setState({...modifiedState});
        getAllOrderInfoForSelectedDate(modifiedState,);
    }

    const OnClickDownloadAsCsvButton = () => {
        let bDownloadCsvOfAllOrder = true;
        let modifiedState = state;
        getAllOrderInfoForSelectedDate(modifiedState, bDownloadCsvOfAllOrder)
    }

    const OnClickDownloadDataAsCsvButtonInView = () => {
        let modifiedState = state;
        
        if(modifiedState.FilteredAllOrderDataForSelectedTimePeriod.length > 0) {
            DownloadCsv(modifiedState.FilteredAllOrderDataForSelectedTimePeriod);
        } else {
            console.error("Downloaded data could not be saved to file.")
        }
    }

    const DownloadCsv = (inCsvData) => {      

        let modifiedState = state;

        let arrColumnKeys = modifiedState.selectedOrderType == INCLUDES_ALL_PRODUCT_NAME ? [ "Status", "Order Type", "Order Date", "Required Date", "Fulfilled Date", "Cust Name", "Cust ID", "Cust EmailID", "Cust OrganisationName", 
                                 "Cust PhoneNo","Price Per Unit Order (INR)", "Tax Per Unit Order (INR)", "One Time Charge (INR)", 
                                 "Tax Price On One Time Charge (INR)", "Security Deposit (INR)", "Sub/RentalPeriod Price Per Unit Order (INR)", "Sub/RentalPeriod Tax Price Per Unit Order (INR)", "TotalOrderValue (INR)", "Comment", "SalesRepUserID", "LastModifiedTime"]
                                 : 
                                 modifiedState.selectedOrderType == ORDER_TYPE_RENTAL ? [ "Status", "Order Type", "Order Date", "Required Date", "Fulfilled Date", "Cust Name", "Cust ID", "Cust EmailID", "Cust OrganisationName", 
                                 "Cust PhoneNo", "One Time Charge (INR)", "Security Deposit (INR)", "Tax Price On One Time Charge (INR)", "Rental Period Price Per Unit Order (INR)", 
                                 "Rental Period Tax Price Per Unit Order (INR)", "TotalOrderValue (INR)", "Comment", "SalesRepUserID", "LastModifiedTime"]
                                 : 
                                 modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO ? [ "Status", "Order Type", "Order Date", "Required Date", "Fulfilled Date", "Cust Name", "Cust ID", "Cust EmailID", "Cust OrganisationName", 
                                 "Cust PhoneNo","Price Per Unit Order (INR)", "Tax Per Unit Order (INR)", "Sub Price Per Unit Order (INR)", "Sub Tax Price Per Unit Order (INR)", "TotalOrderValue (INR)", "Comment", "SalesRepUserID", "LastModifiedTime"]
                                 : 
                                 [ "Status", "Order Date", "Required Date", "Fulfilled Date", "Cust Name", "Cust ID", "Cust EmailID", "Cust OrganisationName", 
                                 "Cust PhoneNo","Price Per Unit Order (INR)", "Tax Per Unit Order (INR)", "One Time Charge (INR)", "Security Deposit (INR)", 
                                 "Tax Price On One Time Charge (INR)", "Sub Price Per Unit Order (INR)", "Sub Tax Price Per Unit Order (INR)", "TotalOrderValue (INR)", "Comment", "SalesRepUserID", "LastModifiedTime"];
        filterDataAsOrder(modifiedState.selectedOrderType);
        let strCsvHeaderLine = "OrderNumber";
        let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
        let strSingleCsvDataLine = "";
        let strColumnName = "";
        modifiedState.csvData = [];

        // Create rest of the columns in the header
        let noOfParams = arrColumnKeys.length;
        for (let j=0; j<noOfParams; j++) {
            strColumnName = arrColumnKeys[j];
            strCsvHeaderLine += (CSV_COLUMN_SEPERATOR + strColumnName);
        }

        let receivedAllOrderInformation = inCsvData;
        let receivedAllOrderInfoLen = inCsvData.length;
        let allInstanceInfo = [];

        // First push the header CSV line
        arrCsvAllLines.push(strCsvHeaderLine);

        for(let i = 0; i < receivedAllOrderInfoLen; i++) {

            let singleInstanceInfo = {
                OrderNumber: receivedAllOrderInformation[i]["OrderNumber"],
                Status: receivedAllOrderInformation[i]["Status"],
                OrderType: receivedAllOrderInformation[i]["OrderType"],
                OrderDate: receivedAllOrderInformation[i]["OrderDate"],
                RequireDate: receivedAllOrderInformation[i]["RequireDate"],
                FulfilledDate: receivedAllOrderInformation[i]["FulfilledDate"],
                CustName: receivedAllOrderInformation[i]["CustName"],
                CustID: receivedAllOrderInformation[i]["CustID"],
                CustEmailID: receivedAllOrderInformation[i]["CustEmailID"],
                CustOrganisationName: receivedAllOrderInformation[i]["CustOrganisationName"],
                CustPhoneNo: receivedAllOrderInformation[i]["CustPhoneNo"],
                PricePerUnitOrderINR: receivedAllOrderInformation[i]["PricePerUnitOrderINR"],
                TaxPerUnitOrderINR: receivedAllOrderInformation[i]["TaxPerUnitOrderINR"],
                OneTimeChrgOnRentPUOI: receivedAllOrderInformation[i]["OneTimeChrgOnRentPUOI"],
                TaxPriceOnOneTimeChrgPUOI: receivedAllOrderInformation[i]["TaxPriceOnOneTimeChrgPUOI"],
                SecurityDepositOnRentPUOI: receivedAllOrderInformation[i]["SecurityDepositOnRentPUOI"],
                SubscriptionPricePerUnitOrderINR: receivedAllOrderInformation[i]["SubscriptionPricePerUnitOrderINR"],
                SubscriptionTaxPerUnitOrderINR: receivedAllOrderInformation[i]["SubscriptionTaxPerUnitOrderINR"],
                TotalOrderValue: receivedAllOrderInformation[i]["TotalOrderValue"],
                Comment: receivedAllOrderInformation[i]["Comment"],
                SalesRepUserID: receivedAllOrderInformation[i]["SalesRepUserID"],
                LastModifiedTime: receivedAllOrderInformation[i]["LastModifiedTime"],
            }
            allInstanceInfo.push(singleInstanceInfo);

            let strRentalOrderCol = "";
            let strSaleDemoALLColumns = "";
            let strALLSubscriptionColumns = "";

            if(modifiedState.selectedOrderType == ORDER_TYPE_SALE || modifiedState.selectedOrderType == ORDER_TYPE_DEMO || modifiedState.selectedOrderType == INCLUDES_ALL_PRODUCT_NAME){
                strSaleDemoALLColumns = CSV_COLUMN_SEPERATOR +
                    ( (receivedAllOrderInformation[i]["PricePerUnitOrderINR"] == null) ? '-' : receivedAllOrderInformation[i]["PricePerUnitOrderINR"] ) +
                    CSV_COLUMN_SEPERATOR +
                    ( (receivedAllOrderInformation[i]["TaxPerUnitOrderINR"] == null) ? '-' : receivedAllOrderInformation[i]["TaxPerUnitOrderINR"] )
            }

            if(modifiedState.selectedOrderType == ORDER_TYPE_RENTAL || modifiedState.selectedOrderType == INCLUDES_ALL_PRODUCT_NAME){
                strRentalOrderCol = CSV_COLUMN_SEPERATOR +
                    ( (receivedAllOrderInformation[i]["OneTimeChrgOnRentPUOI"] == null) ? '-' : receivedAllOrderInformation[i]["OneTimeChrgOnRentPUOI"] ) +
                    CSV_COLUMN_SEPERATOR +
                    ( (receivedAllOrderInformation[i]["TaxPriceOnOneTimeChrgPUOI"] == null) ? '-' : receivedAllOrderInformation[i]["TaxPriceOnOneTimeChrgPUOI"] ) +
                    CSV_COLUMN_SEPERATOR +
                    ( (receivedAllOrderInformation[i]["SecurityDepositOnRentPUOI"] == null) ? '-' : receivedAllOrderInformation[i]["SecurityDepositOnRentPUOI"] ) 
            }

            strALLSubscriptionColumns = CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["SubscriptionPricePerUnitOrderINR"] == null) ? '-' : receivedAllOrderInformation[i]["SubscriptionPricePerUnitOrderINR"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["SubscriptionTaxPerUnitOrderINR"] == null) ? '-' : receivedAllOrderInformation[i]["SubscriptionTaxPerUnitOrderINR"] );

            strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                ((receivedAllOrderInformation[i]["OrderNumber"] == null) ? '-' : receivedAllOrderInformation[i]["OrderNumber"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["Status"] == null) ? '-' : receivedAllOrderInformation[i]["Status"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["OrderType"] == null) ? '-' : receivedAllOrderInformation[i]["OrderType"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["OrderDate"] == null) ? '-' : receivedAllOrderInformation[i]["OrderDate"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["RequireDate"] == null) ? '-' : receivedAllOrderInformation[i]["RequireDate"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["FulfilledDate"] == null) ? '-' : receivedAllOrderInformation[i]["FulfilledDate"] ) +
                CSV_COLUMN_SEPERATOR +
                ((receivedAllOrderInformation[i]["CustName"] == null) ? '-' : receivedAllOrderInformation[i]["CustName"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["CustID"] == null) ? '-' : receivedAllOrderInformation[i]["CustID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["CustEmailID"] == null) ? '-' : receivedAllOrderInformation[i]["CustEmailID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["CustOrganisationName"] == null) ? '-' : receivedAllOrderInformation[i]["CustOrganisationName"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["CustPhoneNo"] == null) ? '-' : receivedAllOrderInformation[i]["CustPhoneNo"] )+

                strSaleDemoALLColumns + strRentalOrderCol + strALLSubscriptionColumns +
            
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["TotalOrderValue"] == null ? '-' : receivedAllOrderInformation[i]["TotalOrderValue"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["Comment"] == null) ? '-' : receivedAllOrderInformation[i]["Comment"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["SalesRepUserID"] == null) ? '-' : receivedAllOrderInformation[i]["SalesRepUserID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["LastModifiedTime"] == null ? '-' : receivedAllOrderInformation[i]["LastModifiedTime"] );

            arrCsvAllLines.push(strSingleCsvDataLine);
        }

        modifiedState.csvData = allInstanceInfo;
        // Create CSV File and fill it
        let bFileCreationStatus = createAndFillCsvFile(arrCsvAllLines);

        alert(`All Order Data download has started. Check your Browser's status bar or Downloads Folder.`);

        if (bFileCreationStatus) {
            // CSV file creation successful.
            // Close the date selection modal.
            // modifiedState.enableStagewiseProdStat = true;

            // props.toggleAllOrderDetailsInfoModal(); // Notify the parent to close the modal.
            // modal closed, no need to do any further processing
            // return;
        } else {
            modifiedState.errors = 'Downloaded data could not be saved to file.';
        }

        setState({...modifiedState});
    } 
    
    return (
        <div>
            {props.invokedFromProp == "SalesDashboard" 
            &&
            <div className="row justify-content-center">
                <div className="container col-lg-6 col-md-6">
                    <div className="modal-body box" style={{padding: "1rem"}}>
                        <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "0.2rem"}}>
                        Sales Dashboard
                        </div>
                        <div className="border border-3 ReactTableStyle">
                        {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", borderColor: "lightgray"}}> */}
                            <ReactTable columns={state.OrderDashboardColumns} 
                                data={state.arrOrderDasboardData}
                                renderBasedOnTable = {"SalesDashboardTable"}
                                getCellProps = {onRowClick}
                                passedStateVariables = {state.selectedOrderType}
                            />
                        </div> 

                        {(props.invokedFromProp == "SalesDashboard" && state.selectedCellOrderStatus != ORDER_STATE_MARKED_FOR_SHIPPING)
                        &&
                            <div style={{fontSize: "0.8rem", color: "var(--errorColor)", textAlign:"center"}}>{state.errors}</div>
                        }
                    </div>
                </div>
            </div>
            }
            { (props.invokedFromProp == "SalesDashboard" && (state.selectedCellOrderStatus == ORDER_STATE_PLACED ||
                state.selectedCellOrderStatus == ORDER_STATE_PROCESSING ) && state.AllOrderDataForSelectedTimePeriod.length > 0
            ) 
            &&
                <div className="row justify-content-center">
                    <div className="container col-lg-12 col-md-12">
                        <div className="modal-body box" style={{padding: "1rem"}}>
                            <div className = "orderPageTitle">
                                <div>
                                    <label className="adduser-form-label" style={{marginLeft:"0.1rem", marginRight: "0.1rem"}}>Select Order Type: </label>
                                    <select className="input-formt"
                                            style={{background:"white", height:"1.8rem"}} 
                                            required
                                            value={state.selectedOrderType}
                                            onChange={onChangeOfOrderType}
                                    >
                                    {(state.ArrForOrderType).map((singleOrderType,index) => <option key={index} value={singleOrderType}>{singleOrderType}</option>)}
                                    </select>
                                </div>
                                <div className = "headingForComponentsOfCrmPage">
                                    All Order Details for Selected Order Status - {state.selectedCellOrderStatus}
                                </div>
                                <div className = "orderDownLoadCSVButton">
                                    <button style={{backgroundColor:"var(--primaryColor)", border:"1px solid var(--primaryColor)", borderRadius: "0.3rem", color: "white", fontWeight:"bold"}} onClick={OnClickDownloadDataAsCsvButtonInView}>Download As CSV</button>
                                </div>
                            </div>
                            <div className="border border-3 ReactTableStyle">
                            {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", borderColor: "lightgray"}}> */}
                                <ReactTable 
                                    columns={state.productColumns} 
                                    data={state.FilteredAllOrderDataForSelectedTimePeriod}
                                    renderBasedOnTable = {"FilteredAllOrderDataTable"}
                                    getCellProps = {(cellInfo) => ({})}
                                    // as React Table is outside the main function, state varaibles cant be accessed there 
                                    // so passing all the state variables on which react table is dependent through passedStateVariable
                                    passedStateVariables = {state.selectedOrderType}
                                />
                            </div> 
                        </div>
                    </div>
                </div>
            }
            { (props.invokedFromProp == "AllOrderDetails" ||
                (props.invokedFromProp == "SalesDashboard" && state.selectedCellOrderStatus == ORDER_STATE_MARKED_FOR_SHIPPING)
            )   
            &&
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-md-8">
                        <div className="modal-body p-3 box" style={{padding: "1rem"}}>
                            <div className = "headingForComponentsOfCrmPage" 
                                style={{marginBottom: "1.2rem", 
                                    display: props.invokedFromProp == "SalesDashboard" ? "none" : "block" 
                                }}
                            >
                                Report - All Order Details
                            </div>
                            <div style={{display:"flex", justifyContent:"space-evenly"}} className="startAndEnddate">
                                <div className="form-group update-form">
                                    <div className="inputgroupCustom">
                                        <label className="adduser-form-label">Start Date Time:</label>
                                        <DateTimePicker
                                            clearIcon={null}
                                            onChange={onChangeStartDateTime}
                                            onCalendarClose={onStartDateTimeCalendarOrClockClose}
                                            onClockClose={onStartDateTimeCalendarOrClockClose}
                                            className="input-form-datetime"
                                            format={"yyyy/MM/dd HH:mm:ss"} 
                                            value={state.StartDateTime}    
                                            name="StartDateTime"
                                        />
                                    </div>
                                </div>

                                <div className="form-group update-form">
                                    <div className="inputgroupCustom">
                                        <label className="adduser-form-label">End Date Time:</label>
                                        <DateTimePicker
                                            clearIcon={null}
                                            selected={state.EndDateTime}
                                            onChange={onChangeEndDateTime}
                                            onCalendarClose={onEndDateTimeCalendarOrClockClose}
                                            onClockClose={onEndDateTimeCalendarOrClockClose}
                                            className="input-form-datetime"
                                            format={"yyyy/MM/dd HH:mm:ss"} 
                                            value={state.EndDateTime} 
                                            name="EndDateTime"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{fontSize: "0.8rem", color: "green", textAlign:"center"}}>{state.timeRelatedErrors}</div>
                            <div style={{display:"flex", justifyContent:"center"}} >
                                <div>
                                    <button type="button"
                                            onClick={OnClickDownloadAsCsvButton} 
                                            className="btn-sm reg-btn"
                                            style={{textTransform: "capitalize", color:"white", fontWeight:"bold", fontSize: "0.9rem"}}
                                    >
                                        Download As CSV 
                                    </button>
                                </div>
                                <div>
                                    <button type="button"
                                            className="btn-sm reg-btn"
                                            style={{textTransform: "capitalize", color:"white", marginLeft:"2rem", fontWeight:"bold", width:"100%", fontSize: "0.9rem"}} 
                                            onClick={OnClickViewButton}>View
                                    </button>
                                </div>
                            </div>
                            <Modal size="xl" isOpen={state.enableStagewiseProdStat} toggle={toggleStagewiseProdStat} backdrop={state.backdrop}>
                                <ModalHeader toggle={toggleStagewiseProdStat}>
                                    {props.invokedFromProp == "SalesDashboard" ? "All Order Details for Selected Order Status - " + state.selectedCellOrderStatus : "All Orders Information"}
                                </ModalHeader>
                                <ModalBody>
                                    <div style={{display: "flex", justifyContent: "space-between", marginBottom:"1rem"}}>
                                        <div>
                                            <label style={{marginLeft:"0rem", marginRight:"0.5rem"}}>Select Order Type: </label>
                                            <select className="input-form"
                                                    style={{background:"white", height:"1.8rem", marginRight:"1rem"}} 
                                                    required
                                                    value={state.selectedOrderType}
                                                    onChange={onChangeOfOrderType}
                                            >
                                                {(state.ArrForOrderType).map((singleOrderType,index) => <option key={index} value={singleOrderType}>{singleOrderType}</option>)}
                                            </select>
                                        </div>
                                        <div>
                                            <button style={{backgroundColor:"var(--primaryColor)", border:"1px solid var(--primaryColor)", borderRadius: "0.3rem", color: "white", alignContent: "right", fontWeight:"bold"}} onClick={OnClickDownloadDataAsCsvButtonInView}>Download As CSV</button>
                                        </div>
                                    </div>                                            
                                    {state.AllOrderDataForSelectedTimePeriod != null && state.AllOrderDataForSelectedTimePeriod.length > 0 ?
                                        // <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px"}}>
                                        <div className="border border-3 ReactTableStyle"                                        >
                                            <ReactTable 
                                                columns={state.productColumns} 
                                                data={state.FilteredAllOrderDataForSelectedTimePeriod}
                                                renderBasedOnTable = {"AllOrderDetailsTable"}
                                                getCellProps = {(cellInfo) => ({})}
                                                // as React Table is outside the main function, state varaibles cant be accessed there 
                                                // so passing all the state variables on which react table is dependent through passedStateVariable
                                                passedStateVariables = {state.selectedOrderType}
                                            />
                                        </div>                                        
                                        :
                                        null
                                    }
                                    <div style={{textAlign:"center"}}>
                                        <button type="button"
                                                className="adduserButton btn-lg"
                                                onClick={toggleStagewiseProdStat}
                                        > 
                                            Close
                                        </button>
                                    </div>
                                </ModalBody>
                            </Modal>
                            <div style={{fontSize: "0.8rem", color: "var(--errorColor)", textAlign:"center"}}>{state.errors}</div>
                        </div>
                    </div>
                </div>
            }
        </div>    
    );
}

export default VcAllOrderDetailsInfo;

