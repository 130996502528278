import React, { useState, useContext, useEffect } from 'react'
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import '../CSS/VcCustomer.css'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday} from '../../vtUtil';
import { FaSearch}  from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import { AppRelevantDataContext } from '../../AppContext';
import { IoMdAddCircleOutline} from 'react-icons/io';
import '../CSS/ReactTable.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data, getCellProps = defaultPropGetter, passedStateVariables}) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
            },
        }),
        []
    );

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: {pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // autoResetPage: false,
            // set page size based on tables
            initialState: { pageSize: 10},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );
    
    // if the state.goToPage1 is true and if new data is inserted
    // go to page 1
    React.useEffect(() =>{
        if(passedStateVariables[1] == true){
            gotoPage(0)
        }

    },[passedStateVariables[1]]);

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;{!passedStateVariables[0] ? "No Data Found." : ""}</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };

    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* {page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr" 
                                    style={{
                                        cursor: '',
                                        background: '',
                                        color: 'black',
                                        alignItems: "center",
                                    }}  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style,},
                                            getCellProps(cell),
                                        ]
                                    )} 
                                    className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || 
                        //     // when there is no data found 
                        //     <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex", justifyContent: "left"}}>{!passedStateVariables[0] ? "No Data Found." : ""}</span>
                        //         </td>
                        //     </tr> 
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0

                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcProductLine() {
    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {

        if(id != "lastModifiedTime") {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
                });
        } else {
            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]

                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                    else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        }
    };

    const [state, setState] = useState({
        productLnModal: false,
        backdrop: 'static',
        defineNewProdLnMsg: "",
        selectedProductLn: "",
        productLineArr: [],
        productLnDescription: "",
        noDataFound: "",
        formViewMode: "",
        selectedRowIndex: null,
        lastModifiedUtcDtTmOfReceivedProductLnData: "",
        page: 0,
        isSelected: false,
        goToPage1: false,
        data:[], 
        errors: {
            others: "",
            productLn: "",
            productLnDescription: "",
        },

        columns: [
            {
                Header:() => <div className="AlertLogTableHeader">Product Line ID</div>,  
                accessor: 'productLnID',
                width: 160,
                filter: filterCaseInsensitive,
                style: ({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {
                Header:() => <div className="AlertLogTableHeader">Product Line </div>,  
                accessor: 'productLn', 
                width: 250,
                filter: filterCaseInsensitive,
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                },
              
            },
            {
                Header:() => <div className="AlertLogTableHeader">Product Line <br/> Description</div>,  
                accessor: 'productLnDescription',
                filter: filterCaseInsensitive,
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                },
                
            },
            {
                Header:() => <div className="AlertLogTableHeader">Last<br/>Modified Time</div>, 
                accessor: 'lastModifiedTime',
                Cell: (props) => getCustomizedTodaysDate(props.value),
                width: 290,
                filter: filterCaseInsensitive,
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                },
                
            }   
        ]
    });

    useEffect(() =>{
        getLatestProductLineDetails();
    },[]);
    
    // For entering or selecting product line details..
    const onChangeProductLnSelection = (e) => {
        
        let modifiedState = state;
        modifiedState.errors.productLn = "";
        modifiedState.errors.others = "";
        modifiedState.selectedProductLn = e.target.value;
        setState({...modifiedState});
    }

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 
    
        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");
    
        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return "Today " + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    //when first time page will load we send LastModifiedUtcDtTmForInsertProductLnDetails empty, 
    //and retrieved all the product line data from database and filled out in the Product table.
    //In case of create new product line details, we send latest LastModifiedUtcDtTmForInsertProductLnDetails
    //received from product line table, and axios will retrieved the product line details next to LastModifiedUtcDtTmForInsertProductLnDetails.
    //we put latest retrived data on top of the product table.
    const getLatestProductLineDetails = (inModifiedState = null) => {
        
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.goToPage1 = false;

        let LastModifiedUtcDtTmForInsertProductLnDetails;
        //In case of create new product line(flag is set formViewMode == InsertMode) details, 
        //we send latest LastModifiedUtcDtTmForInsertProductLnDetails received from Customer table
        //or send empty LastModifiedUtcDtTmForInsertProductLnDetails.
         if((modifiedState.formViewMode != null && modifiedState.formViewMode.length > 0 && modifiedState.formViewMode == "insertMode")){

            LastModifiedUtcDtTmForInsertProductLnDetails = modifiedState.lastModifiedUtcDtTmOfReceivedProductLnData;
        } else {
            LastModifiedUtcDtTmForInsertProductLnDetails = null;
        }
       
        const jsonBody = {
            lastModifiedTime: LastModifiedUtcDtTmForInsertProductLnDetails, 
        };

        axios.post(`${getAPIHostURL()}/wclient/getLatestProductLineDetails`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {

                if(response.data.retreivedProductLnDetails == null || response.data.retreivedProductLnDetails.length <= 0) {
                    modifiedState.noDataFound = "No Product Line Data Found";
                } else {
                    let stateProductLineDetailsArrForInsertingData = [];
                    let stateProductLineDetailsArrForFechingData = []
                                            
                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing product line Details data.
                    // stateProductLineDetailsArrForInsertingData = [...modifiedState.data]
                    stateProductLineDetailsArrForInsertingData = [...modifiedState.data]
                    const receiveProductLnData = response.data.retreivedProductLnDetails;

                    for(let i = 0; i < receiveProductLnData.length; i++) {
                        const productLnDetails = receiveProductLnData[i];
                        let productLnlastModifiedLocaleDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(productLnDetails.ProductLineLastModifiedTime);
                        let singleProductLnDetails = {
                            productLnID: productLnDetails.ProductLineID,
                            productLn: productLnDetails.ProductLine == null ? "" : productLnDetails.ProductLine,
                            productLnDescription: productLnDetails.TextDescription == null ? "" : productLnDetails.TextDescription,
                            setByUserID: productLnDetails.SetByUser == null ? "" : productLnDetails.SetByUser,
                            lastModifiedTime: productLnlastModifiedLocaleDateTime,
                            lastModifiedUtcDtTm : productLnDetails.LastModifiedUtcDtTm == null ? "" : productLnDetails.LastModifiedUtcDtTm,
                        };

                        //if form is open in insertMode(create new product line) then just add new retrieved data(new added product line details) at top of the product line table.
                        if((modifiedState.formViewMode == "insertMode" && modifiedState.formViewMode != null && modifiedState.formViewMode.length > 0)){
                            // modifiedState.data  = [];
                            stateProductLineDetailsArrForInsertingData.unshift(singleProductLnDetails);
                            modifiedState.selectedRowIndex = 0;
                            modifiedState.goToPage1 = true;
                        }
                        //on first time page load its will retrieved all product line details and add it in product line table,
                        // in order of latest data on top of the product line table.
                        else { 
                            stateProductLineDetailsArrForFechingData.push(singleProductLnDetails);
                            modifiedState.goToPage1 = false;
                        }
                    } 
                    if((modifiedState.formViewMode == "insertMode" && modifiedState.formViewMode != null && modifiedState.formViewMode.length > 0)){
                        // stored latest received UTC Date Time of product line table in State.
                        modifiedState.lastModifiedUtcDtTmOfReceivedProductLnData = stateProductLineDetailsArrForInsertingData[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateProductLineDetailsArrForInsertingData;
                    } else {
                        // stored latest received UTC Date Time of product line table in State.
                        modifiedState.lastModifiedUtcDtTmOfReceivedProductLnData = stateProductLineDetailsArrForFechingData[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateProductLineDetailsArrForFechingData;
                    }
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        })
    }

    
    const getUnAddedProductLines = () => {

        let modifiedState = state; 

        modifiedState.productLnModal = true;

        // To avoid appending of array values.
        modifiedState.productLineArr = [];

        // To get distinct product type(product line) which not is present in product line table.
        axios.post(`${getAPIHostURL()}/wclient/getUnAddedProductLines`)
        .then(response => {
            if(response.data.code == "SUCCESS") {
                // IF response gets inadequate data.
                if(response.data.retrievedDeviceTypeForProductLine == null || response.data.retrievedDeviceTypeForProductLine.length <= 0 )
                   {
                    modifiedState.defineNewProdLnMsg = "You can define a new Product Line."
                    console.log("ProductLine Details are not present on Server. You can define a new Product Line.");
                    
                } else {
                    for(let i=0; i< response.data.retrievedDeviceTypeForProductLine.length; i++) {
                        modifiedState.productLineArr.push(response.data.retrievedDeviceTypeForProductLine[i].DeviceType);
                    }
                }

            } else {
                if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }  
        });
    }

    const insertProductLineInfo = (event) => {
        
        let modifiedState = state;
        modifiedState.formViewMode = "insertMode";

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let loggedinUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        event.preventDefault();

        // validation of all required fields.
        modifiedState.errors.productLn = (modifiedState.selectedProductLn == null || modifiedState.selectedProductLn == "" || modifiedState.selectedProductLn.length < 0)
            ? "Product Line is Required." 
            : "";

        // If any duplicate entry entered for unique column ProductLine then we are checking and handling it in front end first.
        // Although we are also handling it on backend by error code [ER_DUP_ENTRY].
        modifiedState.errors.others = (modifiedState.data.some(findProductLn => findProductLn.productLn == modifiedState.selectedProductLn))
            ? `Product Line Details ['${modifiedState.selectedProductLn}'] Already Exists on Server.`
            : "";

        modifiedState.errors.productLnDescription = (modifiedState.productLnDescription == null || modifiedState.productLnDescription == "" || modifiedState.productLnDescription.length < 0)
            ? "Product Line Description is Required." 
            : "";

        // if any one of the required fields are not entered or duplicate entry for productline 
        // entered by user then return the function and show error message.
        if((modifiedState.errors.productLn.length > 0 && modifiedState.errors.productLn != null) ||
         (modifiedState.errors.productLnDescription.length > 0 && modifiedState.errors.productLnDescription != null) ||
         (modifiedState.errors.others.length > 0 && modifiedState.errors.others != null)) {
            
            // modifiedState.errors.others = "Please enter all required filled.";
            setState({...modifiedState});
            return

        } else {
            let productLineDescriptionToStore = modifiedState.productLnDescription.replace(/'/g, "''").replace(/\\/g, "\\\\");

            const jsonBody = {
                ProductLn: trimStringAndRemoveTrailingComma(modifiedState.selectedProductLn), 
                ProductLineDescp: trimStringAndRemoveTrailingComma(productLineDescriptionToStore),
                LoggedInUserID: trimStringAndRemoveTrailingComma(loggedinUserID),
            };

            axios.post(`${getAPIHostURL()}/wclient/insertProductLineInfo`, jsonBody)
            .then(response => {
                if(response.data.code == 'SUCCESS') {    
                    alert(`Successfully Saved Product Line '${[modifiedState.selectedProductLn]}' Details.`);

                    modifiedState.productLnModal = false;
                    modifiedState.productLnDescription = ""
                    modifiedState.selectedProductLn = ""
                    modifiedState.errors.productLn = ""
                    modifiedState.errors.productLnDescription = ""
                    modifiedState.errors.others= ""
                    getLatestProductLineDetails(modifiedState)

                } else if(response.data.code == 'SQL_ERROR' || response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";

                } else if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                    
                } else if(response.data.code == 'ER_DUP_ENTRY'){
                    modifiedState.errors.others = 'Product Line Details Already Exists on Server.'
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            
                setState({...modifiedState});
            })
            .catch(error => {
                console.log(error);
                console.log("Network error:");
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});
                }
            })
        } 
    }

    const toggleAddNewProductLn = (e) => {
            
        let modifiedState = state;
        modifiedState.productLnModal = !modifiedState.productLnModal
        modifiedState.productLnDescription = ""
        modifiedState.selectedProductLn = ""
        modifiedState.errors.productLn = ""
        modifiedState.errors.productLnDescription = ""
        modifiedState.errors.others= ""

        setState({...modifiedState});
    }

    const closeModal = () => {
        toggleAddNewProductLn();
    }

    // const onPageChange = (e) => {
    //     let modifiedState = state;
    //     modifiedState.page = e.target.value ? Number(e.target.value) - 1 : 0;
    //     setState({...modifiedState});
    // }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = state.errors;

        switch (name) {

            case 'productLnDescription': 
                // errors.others= ""
                errors.productLnDescription = ""       
            break;

            default:
            break;
        }

        setState({
            ...state,
            errors, 
            [name]: value,
            // [event.target.name]: event.target.value,   
        });
    }

    const onClickRow = (rowInfo) => {

        if (rowInfo.row != undefined && rowInfo.column != undefined) {
            let modifiedState = state;
            return {
                onClick: (e, handleOriginal) => {
                    modifiedState.selectedRowIndex = rowInfo.row.index;
                    setState({...modifiedState});
                },
                style:
                {
                    cursor: '',
                    background: rowInfo.row.index === modifiedState.selectedRowIndex ? '#6c95ba' : '',
                    color: rowInfo.row.index === modifiedState.selectedRowIndex ? 'white' : 'black',
                    alignItems: "center",
                }
            }
            
        } else {
            return {}
        }
    }

    const {errors}=state;
    return (
        <div>
            <div className="container-fluid col-lg-10 col-md-12 col-sm-12">
                <div style = {{display: "flex", justifyContent: "space-between", padding: "0.2rem" }}>
                    <div>
                        <button type = "button" 
                            className = "btn-md addCustBtn" 
                            style = {{background: "transparent", pointerEvents: "none", border: "none", color:"transparent"}} 
                        >
                            Add New Product Line
                        </button> 
                        <IoMdAddCircleOutline className = "addProductIcon"
                            style = {{background: "transparent", pointerEvents: "none", border: "none", color:"transparent"}} />   
                    </div>

                    <div className = "customerTableHeading">Create And View Product Line</div> 
                    <div>
                        <button type = "button" 
                            className = "btn-md addCustBtn" 
                            onClick = {getUnAddedProductLines}>Add New Product Line
                        </button>
                        <IoMdAddCircleOutline className = "addProductIcon" onClick = {getUnAddedProductLines} title="Add New Product Line"/>    
                    </div>
                </div>
                {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px"}}> */}
                <div className="border border-3 ReactTableStyle">
                    <ReactTable 
                        columns={state.columns} 
                        data={state.data}
                        getCellProps = {onClickRow}
                        // as React Table is outside the main function, state varaibles cant be accessed there 
                        // so passing all the state variables on which react table is dependent through passedStateVariable
                        passedStateVariables = {[state.noDataFound, state.goToPage1]}
                    />
                </div>
            </div>
            <Modal size="lg" isOpen={state.productLnModal} toggle={toggleAddNewProductLn} backdrop={state.backdrop}>
                <ModalHeader toggle={toggleAddNewProductLn} style={{textAlign: "center"}}> Create Product Line
                </ModalHeader>
                <ModalBody>  
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="container col-lg-10 col-lg-offset-4
                                                    col-md-8 col-md-offset-2">
                                <div className="modal-body box">
                                    <form onSubmit={insertProductLineInfo} >
                                        <div className="form-group reg-form">
                                            <div className="inputgroupCustom" style={{paddingTop:"1rem"}}>
                                                <label className="reg-form-label">Product Line:<span className="addCustRequiredMarkStar">*</span></label>
                                                <input type="list" list="data" className="input-form" 
                                                    value={state.selectedProductLn} onChange={onChangeProductLnSelection} />
                                                <datalist id="data">
                                                    {(state.productLineArr).map((singleProductLineArr,index) => 
                                                    <option key={index} value={singleProductLineArr}>{singleProductLineArr}</option>)}
                                                </datalist>
                                                {errors.productLn.length > 0 && 
                                                    <span  className='addCustError'>{errors.productLn}</span>}  
                                            </div>
                                        </div>

                                        <div className="form-group reg-form">
                                            <div className="inputgroupCustom" style={{paddingTop:"1rem"}}>
                                                <label className="reg-form-label">Product Line Description:<span className="addCustRequiredMarkStar">*</span></label>
                                                <textarea className="input-form" rows="4" style={{height: "1%"}}  
                                                    name="productLnDescription" value={state.productLnDescription} onChange={handleChange} />
                                                {errors.productLnDescription.length > 0 && 
                                                    <span  className='addCustError'>{errors.productLnDescription}</span>}  
                                            </div>
                                        </div>

                                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                            <div>
                                                <button type="button" className="addCustSavebtn" onClick={closeModal} name="Back" >Back</button>
                                            </div>
                                            <div>
                                                <button type="submit" className="addCustSavebtn"  name="Save" >Save</button>
                                            </div>   
                                        </div>   

                                        <div className = "buttonErrorMessage">
                                            {errors.others.length > 0 && 
                                                <p  className='addCustError' style={{textAlign: "center"}}>{errors.others}</p>}
                                            {state.defineNewProdLnMsg.length > 0 && 
                                                <p  className='addCustError' style={{textAlign: "center", color: "green" }}>{state.defineNewProdLnMsg}</p>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )

}

export default VcProductLine;
