import React, { Component } from 'react';
import Slider, {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';

// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const RangeWithTooltip = createSliderWithTooltip(Slider.Range);
// const Handle = Slider.Handle;

// const handle = (props) => {
//   console.log("Props: ");
//   console.log(props);
//   const { value, dragging, index, ...restProps } = props;
//   return (
//     <Tooltip
//       prefixCls="rc-slider-tooltip"
//       overlay={value}
//       visible={dragging}
//       placement="top"
//       // key={index}
//     >
//       <Handle value={value} {...restProps} />
//     </Tooltip>
//   );
// };

class VcRange extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            inputValue: this.props.defaultValue,
            step: ((this.props.defaultValue[1] > -1) && (this.props.defaultValue[1] < 1)) ? 0.1 : 1
            // Note: If Max value is between (-1 and 1) then the step should be (0.1).
            // If value is greater than 1 then the step should be (1).
        }
    }

    handleVcRangeChange = (value) => {
        
        let goodU = this.props.stdAirQualityGoodRange[1];
        let goodL = this.props.stdAirQualityGoodRange[0];

        if (value[1] < goodU){
            value[1] = goodU;
        }
        if (value[0] > goodL){
            value[0] = goodL;
        }

        this.setState({
            inputValue: value,
            step: ((value[1] > -1) && (value[1] < 1)) ? 0.1 : 1
        })
        this.props.handleRangeValueChange(this.props.dataStateArrIndex, value);
    }
    
    render() {

      return (
        <Slider
            range
            key={this.props.selectedTreeNodeID + "-" + this.props.shortName}
            min={this.props.min} max={this.props.max} step={this.state.step}
            defaultValue={this.props.defaultValue}  
            onChange={this.handleVcRangeChange}       
            handleStyle={{backgroundColor: "var(--secondaryColor)", borderColor: "var(--secondaryColor)"}}
            trackStyle={[{backgroundColor: "var(--secondaryColor)"}]} 
            value={this.state.inputValue}
            style={{backgroundColor:"transparent"}}
            disabled = {this.props.NoPrivilegeForChangeSettings == true} 
        />
      )
    }
}

export default VcRange;

