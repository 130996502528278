import React, { Component } from 'react'
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import "react-tabs/style/react-tabs.css";
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';

export class VcDeployViaOTA extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this);
        this.state = {
            isCheckeBoxSelected: false,
            activeTab: 'selectByHWModel',
            selectedHardwareName: "",
            selectedSoftwareName: "",
            selectedSoftwareVer: "",
            SWDescription: "",
            selectedDeviceID: "",
            DeviceIdCount: "",
            HardwareModelArr: [],
            SoftwareNameArr: [],
            swVersionArrForSelectedSWName: [],
            manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID: [],
            HWandSWVersionInfoArr: [],
            defaultStoredVersandNameBasedOnHWModel: [],
            errors: { 
              others:'',
              invalidDevcID: ''
            },
            enableFwEditBasedOnHwModel: false,
            swNameArrBasedOnHwModel: [],
            swVersionArrBasedOnHwModel: [],
            swVersionToBeDeployed: "",
            swNameToBeDeployed: "",
            swVersionArrForSelectedSWNameToBeDeployed: [],
            swDescriptionToBeDeployed: ""
        }
    }

    componentDidMount() {
        this.getHWModelandSWversionInfo();
    }

    onChangeHardwareName = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedHardwareName = e.target.value; 

        modifiedState.errors.invalidDevcID = "";
        modifiedState.selectedDeviceID = "";
        modifiedState.errors.others = "";
        // modifiedState.SWDescription = "";
        modifiedState.defaultStoredVersandNameBasedOnHWModel = [];

        // console.log(modifiedState.HWandSWVersionInfoArr);
        
        let filteredHWandSWVersionInfoArr = modifiedState.HWandSWVersionInfoArr.filter((singleHWandSWVersInfo) => 
                                                singleHWandSWVersInfo.ModelName == modifiedState.selectedHardwareName );

        // console.log(filteredHWandSWVersionInfoArr);

        let modelDefSWID = filteredHWandSWVersionInfoArr[0]["ModelDefaultSoftwareID"];
        // console.log(modelDefSWID);

        modifiedState.defaultStoredVersandNameBasedOnHWModel 
                                = filteredHWandSWVersionInfoArr.filter((singleHWandSWVersInfo) => 
                                    singleHWandSWVersInfo.SoftwareID == modelDefSWID );

        // console.log(modifiedState.defaultStoredVersandNameBasedOnHWModel);
        this.setState(modifiedState);
    }

    onChangeSoftwareName = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedSoftwareName = e.target.value; 
        modifiedState.errors.others = "";
        modifiedState.errors.invalidDevcID = "";
        modifiedState.selectedDeviceID = "";

        modifiedState.swVersionArrForSelectedSWName = [];

        // as selected tab is 'Select by firmware name and version' just set selectedHardwareName to blank.
        modifiedState.selectedHardwareName = "";

        // explicitly set softwareVersion and SWDescription dropdown value to blank inorder to avoid unneceessary appending of array elements.
        modifiedState.selectedSoftwareVer = "";
        modifiedState.SWDescription = "";

        // Filtering those elemets from array which having selected software name.
        let filteredHWandSWVersionInfoArr = modifiedState.HWandSWVersionInfoArr.filter((singleHWandSWVersInfo) => 
                                                singleHWandSWVersInfo.SoftwareName == modifiedState.selectedSoftwareName);
        
        for(let i=0; i<filteredHWandSWVersionInfoArr.length; i++) {
            let versionAndSWIDObj = {
                SWVersion: filteredHWandSWVersionInfoArr[i].Version,
                SWID: filteredHWandSWVersionInfoArr[i].SoftwareID,
            }
            modifiedState.swVersionArrForSelectedSWName.push(versionAndSWIDObj);
        }

        // Sort the array of object in Descending order as we want to show latest version on the top.
        for (let i = 0; i < modifiedState.swVersionArrForSelectedSWName.length; i++) {     
            for (let j = i+1; j < modifiedState.swVersionArrForSelectedSWName.length; j++) {  
               if(modifiedState.swVersionArrForSelectedSWName[i]["SWID"] < modifiedState.swVersionArrForSelectedSWName[j]["SWID"]) {    
                   let temp = modifiedState.swVersionArrForSelectedSWName[i];    
                   modifiedState.swVersionArrForSelectedSWName[i] = modifiedState.swVersionArrForSelectedSWName[j];    
                   modifiedState.swVersionArrForSelectedSWName[j] = temp;    
               }     
            }     
        }    
                                                                                
        this.setState(modifiedState);
    }

    onChangeSoftwareVersion = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedSoftwareVer = e.target.value; 

        modifiedState.selectedHardwareName = "";
        modifiedState.selectedDeviceID = "";
        modifiedState.errors.invalidDevcID = "";

        // console.log(modifiedState.HWandSWVersionInfoArr);

        let filteredArrBySelectedSWVerandName = modifiedState.HWandSWVersionInfoArr.filter((singleHWandSWVersInfo) => 
                            singleHWandSWVersInfo.SoftwareName == modifiedState.selectedSoftwareName &&
                            singleHWandSWVersInfo.Version == modifiedState.selectedSoftwareVer);

        // console.log(filteredArrBySelectedSWVerandName);

        modifiedState.SWDescription = filteredArrBySelectedSWVerandName[0]["SoftwareDescription"];

        this.setState(modifiedState);
    }

    onChangeDeviceID = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedDeviceID = (e.target.value); 
        modifiedState.errors.invalidDevcID = "";

        this.setState(modifiedState);
    }

    onClickAddDataList = (e) => {
        let modifiedState = this.state;

        let EnteredDeviceID = modifiedState.selectedDeviceID;
        modifiedState.errors.others = "";

        if(modifiedState.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID.includes(EnteredDeviceID)) {
            modifiedState.errors.invalidDevcID = 'DeviceID already exist.';
            this.setState(modifiedState);

        } else {
            if(EnteredDeviceID == null || EnteredDeviceID.length <= 0) {
                modifiedState.errors.invalidDevcID = 'Please Enter Device ID.';
                this.setState(modifiedState);
                return;
            }
            let jsonBody = {
                EnteredDeviceID: EnteredDeviceID,
            }

            axios.post( `${getAPIHostURL()}/wclient/checkDeviceIdExistence`, jsonBody)
            .then(response => {
        
                if(response.data.code == 'SUCCESS') {    
    
                    if(response.data.isDeviceIDExist == null) {
                        modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                    } else {
                        if(response.data.isDeviceIDExist == 0) {
                            modifiedState.errors.invalidDevcID = 'Invalid Device ID.'
                        } else {
                            modifiedState.selectedDeviceID = "";
                            modifiedState.errors.invalidDevcID = "";
                            // Just wanted to show last inserted DeviceID at the top of DisplayBox.
                            modifiedState.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID.unshift(EnteredDeviceID);
                        }
                    }
                } else {
                    if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                this.setState(modifiedState);
            })
            .catch( error => {
                console.log("Network error:");
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    this.setState(modifiedState);
                }
            });   
        }
    }

    // To toggle between Software Name or Firmware Name & Firmware Version
    toggle(tab) {
        let modifiedState = this.state;
        modifiedState.selectedDeviceID = "";
        modifiedState.swVersionToBeDeployed = "";
        modifiedState.swNameToBeDeployed = "";
        modifiedState.defaultStoredVersandNameBasedOnHWModel = [];
        modifiedState.swDescriptionToBeDeployed = "";
        modifiedState.selectedHardwareName = "";
        modifiedState.selectedSoftwareName = "";
        modifiedState.selectedSoftwareVer = "";
        modifiedState.errors.others = "";
        modifiedState.errors.invalidDevcID = "";
        modifiedState.SWDescription = "";
        if (modifiedState.activeTab !== tab) {
            modifiedState.activeTab = tab;
        } else {
            modifiedState.activeTab = modifiedState.activeTab;
        }
        
        this.setState(modifiedState);
    }

    isUpdateAllDeviceConditionChecked = (e) => {
        let modifiedState = this.state;
        modifiedState.isCheckeBoxSelected = e.target.checked;
        modifiedState.errors.others = "";
        this.setState(modifiedState);
        
        if(modifiedState.isCheckeBoxSelected == false) {

            modifiedState.enableFwEditBasedOnHwModel = false;

        } 
        this.setState(modifiedState);
    }
    
    getHWModelandSWversionInfo = () => {

        let modifiedState = this.state;

        modifiedState.HardwareModelArr = [];
        modifiedState.SoftwareNameArr = [];
        modifiedState.swVersionArrForSelectedSWName = [];

        axios.post(`${getAPIHostURL()}/wclient/getHWModelandSWversionInfoForOTA`)
        .then(response => {

            if(response.data.code == 'SUCCESS') {
                let receivedHWandSWVersionInfo = response.data.retrivedHardwareModel;
                // console.log("response.data.receivedHWandSWVersionInfo",response.data.receivedHWandSWVersionInfo)

                if(receivedHWandSWVersionInfo == null || receivedHWandSWVersionInfo.length <= 0 ) {
                    modifiedState.errors.others = "Unable to Get Hardware Model and Software version Information.";
                } else {
                    // console.log("ModelName", receivedHWandSWVersionInfo)
                    modifiedState.HWandSWVersionInfoArr = receivedHWandSWVersionInfo;

                    for(let i = 0; i < receivedHWandSWVersionInfo.length; i++) {
                        if(!modifiedState.HardwareModelArr.includes(receivedHWandSWVersionInfo[i].ModelName)) { 
                            modifiedState.HardwareModelArr.push(receivedHWandSWVersionInfo[i].ModelName);
                        }
                        if(!modifiedState.SoftwareNameArr.includes(receivedHWandSWVersionInfo[i].SoftwareName)) { 
                            modifiedState.SoftwareNameArr.push(receivedHWandSWVersionInfo[i].SoftwareName);
                        }
                    }
                }
            } else {
                if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors = "Server experiencing issues.\nTry again later.";
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = "Network issues.\nCheck your Internet and Try again later.";
                this.setState(modifiedState);
            }
       })
    }

    onSubmitDeployViaOTA = (e) => {
        e.preventDefault();
        let modifiedState = this.state;

        if(modifiedState.activeTab == "selectByHWModel") {
            if(modifiedState.selectedHardwareName.length <= 0) {
                modifiedState.errors.others = `Please select Hardware model.`;
                this.setState(modifiedState);
                return;
            } else if(modifiedState.enableFwEditBasedOnHwModel == true &&
                        (modifiedState.swNameToBeDeployed.length <= 0 || modifiedState.swVersionToBeDeployed.length <= 0)
            ) {
                modifiedState.errors.others = `Please Choose Firmware name/Version to be Deployed.`;
                this.setState(modifiedState);
                return;
            } else if( modifiedState.isCheckeBoxSelected == false && 
                        (modifiedState.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID == null || modifiedState.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID.length <= 0) 
            ) {
                modifiedState.errors.others = `Please Enter Device ID to Deploy via OTA.`;
                this.setState(modifiedState);
                return;
            } else {
                this.deployByHwModel(modifiedState);
            }

        } else if(modifiedState.activeTab == "selectBySwVersionandName") {
            if(modifiedState.isCheckeBoxSelected == true && modifiedState.selectedSoftwareName.length <= 0
            ) {
                modifiedState.errors.others = `Please select Firmware name.`;
                this.setState(modifiedState);
                return;
            } else if(modifiedState.isCheckeBoxSelected == false && 
                (modifiedState.selectedSoftwareName.length <= 0 || modifiedState.selectedSoftwareVer <= 0)
            ) {
                modifiedState.errors.others = `Please select Firmware name/Version.`;
                this.setState(modifiedState);
                return;
            } else if(modifiedState.isCheckeBoxSelected == true &&
                      (modifiedState.swNameToBeDeployed.length <= 0 || modifiedState.swVersionToBeDeployed.length <= 0)
            ) {
                modifiedState.errors.others = `Please Choose Firmware name/Version to be Deployed.`;
                this.setState(modifiedState);
                return;
            } else if(modifiedState.isCheckeBoxSelected == false && 
                     (modifiedState.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID == null || modifiedState.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID.length <= 0)
            ) { 
                modifiedState.errors.others = `Please Enter Device ID to Deploy via OTA.`;
                this.setState(modifiedState);
                return;
            } else {
                this.deployBySWNameAndVers(modifiedState);
            }
        }

    }

    deployByHwModel = (inModifiedState) => {

        let modifiedState = inModifiedState;

        let selectedHardwareName = modifiedState.selectedHardwareName;
        let bApplyToAllDevcs = modifiedState.isCheckeBoxSelected;
        let enableFwEditBasedOnHwModel = modifiedState.enableFwEditBasedOnHwModel;
        let swNameToBeDeployed = modifiedState.swNameToBeDeployed;
        let swVersionToBeDeployed = modifiedState.swVersionToBeDeployed;
        let deployOTAForDevcIDArrOrExcludeDeviceIDsFromBulkUpdate = modifiedState.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID;

        let jsonBody = {
            selectedHardwareName: selectedHardwareName,
            bApplyToAllDevcs: bApplyToAllDevcs,
            enableFwEdit: enableFwEditBasedOnHwModel,
            swNameToBeDeployed: swNameToBeDeployed,
            swVersionToBeDeployed: swVersionToBeDeployed,
            deployOTAForDevcIDArrOrExcludeDeviceIDsFromBulkUpdate: deployOTAForDevcIDArrOrExcludeDeviceIDsFromBulkUpdate
        }


        axios.post( `${getAPIHostURL()}/wclient/deployByHwModel`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {    
                // Show alert to user with success msg.
                alert("Successfully Deployed information to Devices to Update Firmware via OTA.");
                modifiedState.selectedSoftwareName = "";
                modifiedState.selectedSoftwareVer = "";
                modifiedState.SWDescription = "";
                modifiedState.selectedHardwareName = "";
                modifiedState.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID = [];
                modifiedState.swVersionArrForSelectedSWName = [];
                modifiedState.defaultStoredVersandNameBasedOnHWModel = [];
                modifiedState.swVersionArrForSelectedSWNameToBeDeployed = [];
                modifiedState.selectedDeviceID = "";
                modifiedState.swNameToBeDeployed = "";
                modifiedState.swVersionToBeDeployed = "";
                modifiedState.swDescriptionToBeDeployed = "";
                modifiedState.errors.others ="";
                modifiedState.enableFwEditBasedOnHwModel = false;
            } else {
                if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        });  
    }

    deployBySWNameAndVers = (inModifiedState) => {

        let modifiedState = inModifiedState;

        let selectedSoftwareName = modifiedState.selectedSoftwareName;
        let selectedSoftwareVer = modifiedState.selectedSoftwareVer;
        let bApplyToAllDevcs = modifiedState.isCheckeBoxSelected;
        let swNameToBeDeployed = modifiedState.swNameToBeDeployed;
        let swVersionToBeDeployed = modifiedState.swVersionToBeDeployed;
        let deployOTAForDevcIDArrOrExcludeDeviceIDsFromBulkUpdate = modifiedState.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID;


        let jsonBody = {
            selectedSoftwareName: selectedSoftwareName,
            selectedSoftwareVer: selectedSoftwareVer,
            bApplyToAllDevcs: bApplyToAllDevcs,
            swNameToBeDeployed: swNameToBeDeployed,
            swVersionToBeDeployed: swVersionToBeDeployed,
            deployOTAForDevcIDArrOrExcludeDeviceIDsFromBulkUpdate: deployOTAForDevcIDArrOrExcludeDeviceIDsFromBulkUpdate
        }

        axios.post( `${getAPIHostURL()}/wclient/deployBySwNameAndVersion`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {    
                // Show alert to user with success msg.
                alert("Successfully Deployed information to Devices to Update Firmware via OTA.");
                modifiedState.selectedSoftwareName = "";
                modifiedState.selectedSoftwareVer = "";
                modifiedState.SWDescription = "";
                modifiedState.selectedHardwareName = "";
                modifiedState.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID = [];
                modifiedState.swVersionArrForSelectedSWName = [];
                modifiedState.swVersionArrForSelectedSWNameToBeDeployed = [];
                modifiedState.selectedDeviceID = "";
                modifiedState.swNameToBeDeployed = "";
                modifiedState.swVersionToBeDeployed = "";
                modifiedState.swDescriptionToBeDeployed = "";
                modifiedState.errors.others ="";
            } else {
                if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == "UPDATE_INCOMPLETE"){
                    modifiedState.errors.others = `Unable to Deploy OTA updates for some Devices.`;
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        });  
    }


    onClickChooseDifferentSWNameVer = (e) => {
        let modifiedState = this.state;
        modifiedState.errors.others = "";

        if(modifiedState.enableFwEditBasedOnHwModel == false) {
            let strMsg = `Are you sure you want to Deploy a Non-Default firmware version for the above selected Hardware Model?`;
            if(window.confirm(strMsg)) {
                modifiedState.enableFwEditBasedOnHwModel = !modifiedState.enableFwEditBasedOnHwModel;
            }
        } else {
            alert(`Resetting back to Default Firmware name and version for this Hardware Model.`);
            modifiedState.enableFwEditBasedOnHwModel = !modifiedState.enableFwEditBasedOnHwModel;
        }

        this.setState(modifiedState);
    }

    onChangeFwNameToBeDeployed = (e) => {
        let modifiedState = this.state;
        modifiedState.swNameToBeDeployed = e.target.value;
        modifiedState.swVersionArrForSelectedSWNameToBeDeployed = [];
        modifiedState.swVersionToBeDeployed = "";
        modifiedState.swDescriptionToBeDeployed = "";

         // Filtering those elemets from array which having selected software name.
        let filteredHWandSWVersionInfoArr = modifiedState.HWandSWVersionInfoArr.filter((singleHWandSWVersInfo) => 
                                                singleHWandSWVersInfo.SoftwareName == modifiedState.swNameToBeDeployed);
        
        for(let i=0; i<filteredHWandSWVersionInfoArr.length; i++) {
            let versionAndSWIDObj = {
                SWVersion: filteredHWandSWVersionInfoArr[i].Version,
                SWID: filteredHWandSWVersionInfoArr[i].SoftwareID,
            }
            modifiedState.swVersionArrForSelectedSWNameToBeDeployed.push(versionAndSWIDObj);
        }

        // Sort the array of object in Descending order as we want to show latest version on the top.
        for (let i = 0; i < modifiedState.swVersionArrForSelectedSWNameToBeDeployed.length; i++) {     
            for (let j = i+1; j < modifiedState.swVersionArrForSelectedSWNameToBeDeployed.length; j++) {  
               if(modifiedState.swVersionArrForSelectedSWNameToBeDeployed[i]["SWID"] < modifiedState.swVersionArrForSelectedSWNameToBeDeployed[j]["SWID"]) {    
                   let temp = modifiedState.swVersionArrForSelectedSWNameToBeDeployed[i];    
                   modifiedState.swVersionArrForSelectedSWNameToBeDeployed[i] = modifiedState.swVersionArrForSelectedSWNameToBeDeployed[j];    
                   modifiedState.swVersionArrForSelectedSWNameToBeDeployed[j] = temp;    
               }     
            }     
        }    
        this.setState(modifiedState);
    }

    onChangeFwVersionToBeDeployed = (e) => {
        let modifiedState = this.state;
        modifiedState.swVersionToBeDeployed = e.target.value;
        modifiedState.errors.others = "";

        let filteredArrBySelectedSWVerandName = modifiedState.HWandSWVersionInfoArr.filter((singleHWandSWVersInfo) => 
                        singleHWandSWVersInfo.SoftwareName == modifiedState.swNameToBeDeployed &&
                        singleHWandSWVersInfo.Version == modifiedState.swVersionToBeDeployed);


        modifiedState.swDescriptionToBeDeployed = filteredArrBySelectedSWVerandName[0]["SoftwareDescription"];
        this.setState(modifiedState);
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-lg-offset-2
                                              col-md-12 col-md-offset-2">
                        <div className="modal-body box">
                            <form onSubmit={this.onSubmitDeployViaOTA}>
                                <Nav tabs className="tabular">
                                  <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab == 'selectByHWModel' })}
                                        onClick={() => { this.toggle('selectByHWModel'); }}
                                    >
                                        <b>Deploy by Hardware Model</b>
                                    </NavLink>
                                  </NavItem>

                                  <NavItem>
                                    <NavLink
                                    className={classnames({ active: this.state.activeTab == 'selectBySwVersionandName' })}
                                    onClick={() => { this.toggle('selectBySwVersionandName'); }}>
                                        <b>Deploy by Firmware Name and Version</b>
                                    </NavLink>
                                  </NavItem>
                                </Nav>

                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="selectByHWModel" className="tabular2">
                                      <Row>
                                        <Col sm="12">
                                            <div className="form-group reg-form">
                                                <div className="inputgroupCustom" style={{paddingTop:"1rem"}}>
                                                    <label className="reg-form-label">Select Hardware Model:</label>
                                                    <select className="input-form" style={{height:"2.5rem"}}
                                                            value={this.state.selectedHardwareName}
                                                            onChange={this.onChangeHardwareName}
                                                    >
                                                        <option value="" disabled select= "true">Select Hardware...</option>
                                                            {(this.state.HardwareModelArr).map((SingleHardwareModelName,index) => <option key={index} value={SingleHardwareModelName}>{SingleHardwareModelName}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginBottom:"1rem", background:"#F5F5F5"}}>
                                                <div style={{pointerEvents: this.state.enableFwEditBasedOnHwModel ? "auto" : "none"}}>
                                                    <label>Firmware To Be Deployed:</label>
                                                    <div style={{display:"flex"}}>
                                                        <label style={{flex:"1"}}> Name: </label>
                                                        {this.state.isCheckeBoxSelected == true && this.state.enableFwEditBasedOnHwModel == true ?
                                                            <select className="" style={{width:"100%", border:"1px solid #ABADB3", flex:"4"}}
                                                                    value={this.state.swNameToBeDeployed}
                                                                    onChange={this.onChangeFwNameToBeDeployed}
                                                            >
                                                                <option value="" disabled select= "true">Firmware Name...</option>
                                                                {(this.state.SoftwareNameArr).map((singleFirmwareName,index) => <option key={index} value={singleFirmwareName}>{singleFirmwareName}</option>)}
                                                            </select>
                                                            :
                                                            <input placeholder={"Firmware Name..."}
                                                                value={this.state.defaultStoredVersandNameBasedOnHWModel.map((val) => val.SoftwareName)}
                                                                style={{flex:"4", background:"#F5F5F5", width:"100%"}}
                                                                disabled
                                                            />
                                                        }
                                                    </div>
                                                    <div style={{display:"flex", marginTop:"0.5rem"}}>
                                                        <label style={{flex:"1"}}>Version: </label>
                                                        {this.state.isCheckeBoxSelected == true && this.state.enableFwEditBasedOnHwModel ?
                                                            <select className="" style={{width:"100%", border:"1px solid #ABADB3", flex:"4"}}
                                                                    value={this.state.swVersionToBeDeployed}
                                                                    onChange={this.onChangeFwVersionToBeDeployed}
                                                            >
                                                                <option value="" disabled select= "true">Firmware Version...</option>
                                                                    {(this.state.swVersionArrForSelectedSWNameToBeDeployed).map((singleVersion,index) => <option key={index} value={singleVersion["SWVersion"]}>{singleVersion["SWVersion"]}</option>)}
                                                            </select>
                                                            :
                                                            <input placeholder={"Firmware Version..."}
                                                                value={this.state.defaultStoredVersandNameBasedOnHWModel.map((val) => val.Version)}
                                                                style={{flex:"4", background:"#F5F5F5", width:"100%"}}
                                                                disabled
                                                            /> 
                                                        }
                                                    </div>
                                                    <div style={{display:"flex", marginTop:"0.5rem"}}>
                                                        <label style={{flex:"1"}}>Description: </label>
                                                        {this.state.isCheckeBoxSelected == true && this.state.enableFwEditBasedOnHwModel ?

                                                            <input placeholder={"Firmware Description..."}
                                                                    value={this.state.swDescriptionToBeDeployed}
                                                                    style={{flex:"4", background:"#F5F5F5", width:"100%"}}
                                                                    disabled
                                                            /> 
                                                            :
                                                            <input placeholder={"Firmware Discription..."}
                                                                    value={this.state.defaultStoredVersandNameBasedOnHWModel.map((val) => val.SoftwareDescription)}
                                                                    style={{flex:"4", background:"#F5F5F5", width:"100%"}}
                                                                    disabled
                                                            /> 
                                                        }
                                                    </div>
                                                </div>    
                                                <div style={{textAlign:"center", marginTop:"0.5rem"}}>
                                                    {this.state.isCheckeBoxSelected == false 
                                                     ? 
                                                        <div style={{fontSize:"0.8rem", textAlign:"left"}}>
                                                            <b>Note</b>: This is the Default Firmware Name and Version for the above selected Hardware model.
                                                            If you want to Deploy OTA by a different Firmware Name and Version, please select 
                                                            "<b>Deploy by Firmware Name and Version</b>" Tab.
                                                        </div>
                                                     : 
                                                        <button type="button" 
                                                                style={{padding:"0.4rem", borderRadius:"5px", width:"70%", textTransform: "capitalize"}} 
                                                                className="reg-btn" 
                                                                onClick={this.onClickChooseDifferentSWNameVer}> 
                                                                {this.state.enableFwEditBasedOnHwModel == false ? 
                                                                    "Choose different Firmware Name and Version." : 
                                                                    "Reset Back to Default Firmware Name and Version."} 
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </Col> 
                                      </Row>
                                    </TabPane>
                                    <TabPane tabId="selectBySwVersionandName" className="tabular2">
                                      <Row>
                                        <Col sm="12">
                                            <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem",  marginBottom:"1rem"}}>
                                                <label>{this.state.isCheckeBoxSelected ? "Select Devices based on Firmware:" : "Choose Firmware To Be Deployed:" }</label>
                                                <div className="form-group reg-form">
                                                    <div className="inputgroupCustom">
                                                        <label className="reg-form-label">Select Firmware Name:</label>
                                                        <select className="input-form" 
                                                                style={{height:"2.5rem"}}
                                                                value={this.state.selectedSoftwareName}
                                                                onChange={this.onChangeSoftwareName}
                                                        >
                                                                <option value="" select= "true" disabled>Select Firmware Name...</option> 
                                                                {(this.state.SoftwareNameArr).map((singleFirmwareName,index) => <option key={index} value={singleFirmwareName}>{singleFirmwareName}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group reg-form">
                                                    <div className="inputgroupCustom">
                                                        <label className="reg-form-label"> Select Firmware Version: </label>
                                                        <select className="input-form" 
                                                                style={{height:"2.5rem"}}
                                                                value={this.state.selectedSoftwareVer}
                                                                onChange={this.onChangeSoftwareVersion}
                                                        >
                                                            <option value="" select= "true" disabled>Select Firmware Version...</option> 
                                                            {(this.state.swVersionArrForSelectedSWName).map((singleVersion,index) => <option key={index} value={singleVersion["SWVersion"]}>{singleVersion["SWVersion"]}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group reg-form">
                                                    <div className="inputgroupCustom">
                                                        <label className="reg-form-label"> Firmware Description: </label>
                                                        <input placeholder={"Firmware Description"}
                                                            className="input-form" 
                                                            value={this.state.SWDescription}
                                                                // style={{border:"none"}}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                      </Row>
                                    </TabPane>
                                </TabContent> 
                                <div className="form-group reg-form" >
                                  <div style={{textAlign:"left"}}>
                                    <span><input type="checkbox" onChange={this.isUpdateAllDeviceConditionChecked}/></span>
                                        { this.state.activeTab == "selectByHWModel" 
                                            ? <span style={{marginLeft: "0.5rem"}}> Update all devices which currently have above Hardware Model.</span>
                                            : <span style={{marginLeft: "0.5rem"}}> Update all devices which currently have above Firmware Name and Version.</span>
                                        }

                                  </div>
                                </div>
                                <div style={{display: (this.state.activeTab == "selectBySwVersionandName" && this.state.isCheckeBoxSelected )? "block" : "none"}}>
                                    <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginBottom:"1rem", background:"#F5F5F5"}}>
                                        <div>
                                            <label>Choose Firmware To Be Deployed:</label>
                                            <div style={{display:"flex"}}>
                                                <label style={{flex:"1"}}> Name: </label>
                                                    <select style={{width:"100%", border:"1px solid #ABADB3", flex:"4"}}
                                                            value={this.state.swNameToBeDeployed}
                                                            onChange={this.onChangeFwNameToBeDeployed}
                                                    >
                                                        <option value="" disabled select= "true">Firmware Name...</option>
                                                        {(this.state.SoftwareNameArr).map((singleFirmwareName,index) => <option key={index} value={singleFirmwareName}>{singleFirmwareName}</option>)}
                                                    </select>
                                            </div>
                                            <div style={{display:"flex", marginTop:"0.5rem"}}>
                                                <label style={{flex:"1"}}>Version: </label>
                                                    <select style={{width:"100%", border:"1px solid #ABADB3", flex:"4"}}
                                                            value={this.state.swVersionToBeDeployed}
                                                            onChange={this.onChangeFwVersionToBeDeployed}
                                                    >
                                                        <option value="" disabled select= "true">Firmware Version...</option>
                                                            {(this.state.swVersionArrForSelectedSWNameToBeDeployed).map((singleVersion,index) => <option key={index} value={singleVersion["SWVersion"]}>{singleVersion["SWVersion"]}</option>)}
                                                    </select>
                                            </div>
                                            <div style={{display:"flex", marginTop:"0.5rem"}}>
                                                <label style={{flex:"1"}}>Description: </label>
                                                    <input placeholder={"Firmware Description..."}
                                                            value={this.state.swDescriptionToBeDeployed}
                                                            style={{flex:"4", background:"#F5F5F5", width:"100%"}}
                                                            disabled
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="reg-form-label">
                                        {this.state.isCheckeBoxSelected == true && this.state.activeTab == "selectByHWModel" ? 
                                            "Enter Device ID to Exclude from devices which currently have above Hardware Model:" : 
                                            this.state.isCheckeBoxSelected == true && this.state.activeTab == "selectBySwVersionandName" ? 
                                            "Enter Device ID to Exclude from devices which currently have above Firmware Name and Version:" : 
                                            "Enter Device ID:"
                                        }
                                    </label>
                                    <div style={{display:"flex", className:""}}>
                                    <span style={{width:"", flex:"8"}} >
                                        <input type="text"
                                            className="input-form" 
                                            value={this.state.selectedDeviceID}  
                                            onChange={this.onChangeDeviceID} 
                                        />
                                    </span>
                                    <span style={{marginLeft:"1rem", flex:"1"}}>
                                        <button type="button" 
                                                style={{padding:"0.4rem", borderRadius:"5px", fontSize: "1rem" }} 
                                                className=" reg-btn" 
                                                onClick={this.onClickAddDataList}>{this.state.isCheckeBoxSelected == true ? "EXCLUDE" : "ADD"} 
                                        </button>
                                    </span>
                                    </div>
                                    <div>
                                        {this.state.errors.invalidDevcID.length > 0 && 
                                        <p style={{color:"Red", fontSize:"0.8rem", textAlign:"left"}} className='error'>{this.state.errors.invalidDevcID}</p>}
                                    </div>
                                    <div className="inputgroupCustom" style={{marginTop:"1.2rem"}}>
                                        <label className="reg-form-label">
                                            {this.state.isCheckeBoxSelected == true ? "Displaying Excluded DeviceIDs:" :"Displaying Selected DeviceIDs:"}
                                        </label>
                                        <select  className="input-form" size="5" style={{height:"5%" }}>
                                            {(this.state.manuallyAddedDeviceIDArrToDeployByDeviceIDOrExcludeDeviceID).map((addedDevcID,index) => <option key={index} value={addedDevcID}>{addedDevcID}</option>)}
                                        </select>
                                    </div>                                    
                                </div>
                                <div className="form-group reg-form" style={{marginTop:"1.5rem"}}>
                                    <button type="submit" className="btn-lg reg-btn" style={{ textTransform: "capitalize"}}>Deploy Via OTA</button>
                                    <div className = "buttonErrorMessage">
                                        {this.state.errors.others.length > 0 && 
                                            <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>}
                                    </div>
                                </div>
                            </form>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

export default VcDeployViaOTA
    