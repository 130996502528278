import React, { useState, useContext, useMemo } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import '../CSS/VcCustomer.css'
import '../CSS/VcTrackDevices.css'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday} from '../../vtUtil';
import { FaSearch, FaCircle}  from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import { AppRelevantDataContext } from '../../AppContext';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.8rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

const defaultPropGetter = () => ({});

const ReactTable = ({ columns, data, getCellProps = defaultPropGetter, renderBasedOnTable}) => {

    const [selectedRowIndexforSearchTbl, setSelectedRowIndexforSearchTbl] = useState(null);
    const [selectedRowIndexforTrackAppVersionTbl, setSelectedRowIndexforTrackAppVersionTbl] = useState(null);

    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const tableColumns = useMemo(() => columns, [columns]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns: tableColumns,
            data,
            defaultColumn,
            initialState: {pageSize: renderBasedOnTable == "SearchResultTable" || 
                renderBasedOnTable == "TrackedAppVersionTable" ? 5 : 1},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    )

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;No Data Found.</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };

    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()} style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trforHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    {
                        renderBasedOnTable != "UserInfoTable" &&
                        <thead >
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                    {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className="tdForSearchField">
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                    }
                    <tbody {...getTableBodyProps()} >
                        {page.length > 0 && page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"
                                    onClick = {(e, handleOriginal) => {
                                        if(renderBasedOnTable == "SearchResultTable") {
                                            setSelectedRowIndexforSearchTbl(row.index);
                                        } else if(renderBasedOnTable == "TrackedAppVersionTable"){
                                            setSelectedRowIndexforTrackAppVersionTbl(row.index);
                                        }
                                        else {
                                            //nothing
                                        }
                                    }} 
                                    style = {
                                        (renderBasedOnTable == "SearchResultTable") ?  
                                        {
                                            cursor: 'pointer',
                                            background: row.index == selectedRowIndexforSearchTbl ? '#6c95ba' : '',
                                            color: row.index == selectedRowIndexforSearchTbl ? 'white' : 'black',
                                            alignItems: "center",
                                        }
                                        : (renderBasedOnTable == "TrackedAppVersionTable") ?  
                                        {
                                            cursor: 'pointer',
                                            background: row.index == selectedRowIndexforTrackAppVersionTbl ? '#6c95ba' : '',
                                            color: row.index == selectedRowIndexforTrackAppVersionTbl ? 'white' : 'black',
                                            alignItems: "center",
                                        } 
                                        :
                                        {}
                                    }  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {
                                            style: cell.column.style,
                                            },
                                            getCellProps(cell),
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || <tr style = {{backgroundColor: "white"}}><td><span  style={{paddingLeft:"1rem", color: "green", display: "flex", justifyContent: "left"}}>{"No Data Found."}</span></td></tr>
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            {renderBasedOnTable != "UserInfoTable" &&
                <div className='w-100 d-flex justify-content-end antdPagination'>
                    <div className='d-flex py-1 justify-content-end align-items-center'>
                        <Pagination showQuickJumper 
                            defaultCurrent={pageIndex + 1} 
                            total={pageOptions.length * 10}
                            // total={10 * 10} 
                            onChange={(page) => gotoPage(page - 1)} 
                            showSizeChanger={false}
                            current={pageIndex + 1} 
                        />

                        <Select
                            style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                            aria-label="rows per page"
                            className="paginationDropdown mySelector"
                            value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                            onChange={e => setPageSize(Number(e))} 
                            options={[
                                {
                                    label: "5 rows",
                                    value: 5
                                },
                                {
                                    label: "10 rows",
                                    value: 10
                                },
                                {
                                    label: "20 rows",
                                    value: 20
                                },
                                {
                                    label: "25 rows",
                                    value: 25
                                },
                                {
                                    label: "100 rows",
                                    value: 100
                                }
                            ]} // Assuming options is the array of options for the Select component
                        />
                    </div>
                </div>
            }
            

            {/* <div hidden = {renderBasedOnTable != "UserInfoTable" ? false : true} >
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcTrackAppVersion (props){
    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        if(id != "LastDataReceivedAtDtTm" || id != "LastesAppUpdatedAtDtTm") {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
            });
        } else {
            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]                    
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                    else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        }
    };

    const [state, setState] = useState({
        trackAppVerModal: false,
        backdrop: "static",
        UsersEmailID: "",
        firstName: "",
        lastName: "",
        mobileNo: "",
        userAppRefCodeArr: "",
        selectedUsersEmailID: "",
        searchedBy: "searchedByUser",
        searchResultDataArr: [],
        trackedAppVerDataArr: [],
        selectedUserData: [],

        errors: {
            others: "",
            UsersEmailID: "",
            firstName: "",
            lastName: "",
            mobileNo: "",
            userAppRefCodeArr: "",
            requiredFiled: "",
        },

        // React table columns for displaying Search Result of Track App's Version Info.
        columns: [
            {   
                Header:() => <div className="AlertLogTableHeader">Action</div>, 
                accessor: "TrackDevice",
                width: 200,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
                filter: filterCaseInsensitive,
                Cell: ( rowInfo ) => {
                    return (
                    <button type = "button" className = "createOrderButton">
                        Track App Version
                    </button> 
                    );
                },
            },

            {       
                Header:() => <div className="AlertLogTableHeader">User Email ID</div>,  
                accessor: 'UsersEmailID',
                filter: filterCaseInsensitive,
                width: 300,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Mobile No</div>,  
                accessor: 'MobileNo',
                filter: filterCaseInsensitive,
                width: 230,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 

            {       
                Header:() => <div className="AlertLogTableHeader">First Name</div>,  
                accessor: 'FirstName',
                width: 300,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Last Name</div>,  
                accessor: 'LastName',
                width: 260,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },    
        ],

        // React table columns for displaying Selected User Info for tracking App Version.
        selectedUserColumns: [
            {       
                Header:() => <div className="AlertLogTableHeader">User Email ID</div>,  
                accessor: 'selctUsrEmailID',
                disableFilters: true,
                width: 350,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Mobile No</div>,  
                accessor: 'selctUsrMobNo',
                disableFilters: true,
                width: 226,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            }, 

            {       
                Header:() => <div className="AlertLogTableHeader">First Name</div>,  
                accessor: 'selctUsrFirstName',
                width: 250,
                disableFilters: true,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Last Name</div>,  
                accessor: 'selctUsrLastName',
                width: 250,
                disableFilters: true,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    
                    height: "2.4rem"
                }),
            },    
        ],

        // React table columns for displaying tracked App Version details of selected User.
        trackedAppVersionTblColumns: [
            {       
                Header:() => <div className="AlertLogTableHeader">UserApp/Alert<br/>Reference Code</div>,  
                accessor: 'UserAppReferenceCode',
                filter: filterCaseInsensitive,
                width: 195,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">App Name</div>,  
                accessor: 'AppName',
                filter: filterCaseInsensitive,
                width: 160,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 

            {       
                Header:() => <div className="AlertLogTableHeader">App<br/>Platform</div>,  
                accessor: 'Platform',
                width: 110,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">User App<br/>Version</div>,  
                accessor: 'AppVersion',
                width: 120,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },

            { 
                Header:() => <div className="AlertLogTableHeader">Last User App Info<br/>Received At</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'LastDataReceivedAtDtTm',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 210,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },  

            {       
                Header:() => <div className="AlertLogTableHeader">Latest App<br/>Version</div>,  
                accessor: 'LatestAppVersion',
                width: 130,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },

            { 
                Header:() => <div className="AlertLogTableHeader">Latest App Version<br/>Updated On</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'LastesAppUpdatedAtDtTm',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 210,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },  

        ],

    });

    // Function will call on clicking of TrackAppVersion Button.
    // Fetching the value from react table selected User "UsersEmailID" and set it in the state.
    // Calling function "getDetailedInfoForTrackingAppVer" and sending the state to that function.
    // This fetched Emailid from ReactTable will use to call axios "getDetailedInfoForTrackingAppVer"
    const onClickTrackAppVer = (rowInfo, column) => {

        let modifiedState = state;

        modifiedState.selectedUsersEmailID = rowInfo.original.UsersEmailID;

        getDetailedInfoForTrackingAppVer(modifiedState);
    }

    // Radio Button to Switch between Search by User Info and search by Device ID.
    // on switch we also Empty the TextBoxes and Array.
    const onSearchByRadioBtnChange = (e) => {
        
        let modifiedState = state;

        modifiedState.errors.requiredFiled = "";
        modifiedState.firstName = "";
        modifiedState.lastName = "";
        modifiedState.UsersEmailID = "";
        modifiedState.mobileNo = "";
        modifiedState.userAppRefCodeArr = "";
        modifiedState.searchResultDataArr = [];
        modifiedState.searchedBy = e.target.value;

        setState({...modifiedState});  
    }

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return "Today " + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    // Function will get detaild information of Tracked App Version and store in the React Table.
    const getDetailedInfoForTrackingAppVer = (event, inModifiedState = null) => {
    
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        };

        let userAppRefCodeArrToSent = [];
        userAppRefCodeArrToSent.push(modifiedState.userAppRefCodeArr);

        let jsonBody = {}

        // if any one of the required fields are not entered by user then return the function and show error message.
        // Getting the Tracked App Version Information on basis of UserApp/Aler Reference Code entered by user.
        if (modifiedState.searchedBy == "searchByUserAppRefCode") {
            if( (modifiedState.userAppRefCodeArr == null || modifiedState.userAppRefCodeArr.length <= 0)) {

                event.preventDefault();
                modifiedState.errors.requiredFiled = "Please enter UserApp/Alert Reference Code - (required field)";
                setState({...modifiedState});  
                return

            } else {
                event.preventDefault();

                jsonBody = {
                    UserAppRefCodeArr: userAppRefCodeArrToSent,
                };
            }
        
        } else {
            // if any one of the required fields are not entered by user then return the function and show error message.
            // Getting the Tracked Devices Information on basis of "selectedUsersEmailID" Selected by user.
            if( (modifiedState.selectedUsersEmailID == null || modifiedState.selectedUsersEmailID.length <= 0)) {

                modifiedState.errors.requiredFiled = "User Email ID is Missing";
                setState({...modifiedState});  
                return

            } else {

                jsonBody = {
                    UserID: modifiedState.selectedUsersEmailID,
                };
            }

        }

        axios.post(`${getAPIHostURL()}/wclient/getDetailedInfoForTrackingAppVer`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {

                if(response.data.detailedInfoForAppVerTracking == null || response.data.detailedInfoForAppVerTracking.length <= 0) {

                    if(modifiedState.searchedBy == "searchedByUser") {
                        alert(`No App Version information found. Please check if the specified user "${modifiedState.selectedUsersEmailID}"` +
                                ` is currently logged into an app.`);
                    } else{
                        alert(`No App version information found for the specified UserApp/Alert Reference Code "${userAppRefCodeArrToSent}".` +
                                `Please check if the user is currently logged into an app and the Reference Code provided is matching with ` +
                                `the one present in UserProfile screen.`);
                    }

                } else {

                    modifiedState.trackAppVerModal = true;

                    let stateTrackedAppVerDataArr = [];
                    let stateUserdata = [];                        
                    modifiedState.trackedAppVerDataArr = [];

                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing product line Details data.
                    stateTrackedAppVerDataArr = [...modifiedState.trackedAppVerDataArr]
                    stateUserdata = [...modifiedState.selectedUserData = []]
                                            
                    const infoOfAppVerTrackingData = response.data.detailedInfoForAppVerTracking;

                    let singleDeviceOwnUserInfo ={
                        selctUsrFirstName: infoOfAppVerTrackingData[0].FirstName == null ? "" : infoOfAppVerTrackingData[0].FirstName,
                        selctUsrLastName: infoOfAppVerTrackingData[0].LastName == null ? "" : infoOfAppVerTrackingData[0].LastName,
                        selctUsrEmailID: infoOfAppVerTrackingData[0].UserEmailID == null ? "" : infoOfAppVerTrackingData[0].UserEmailID,
                        selctUsrMobNo: infoOfAppVerTrackingData[0].UserPhoneNo == null ? "" : infoOfAppVerTrackingData[0].UserPhoneNo,   
                    };
                    stateUserdata.push(singleDeviceOwnUserInfo);
                    modifiedState.selectedUserData = stateUserdata;

                    for(let i = 0; i < infoOfAppVerTrackingData.length; i++) {
                        let appVerTrackingDetails = infoOfAppVerTrackingData[i];
                        
                        let singleDeviceTrackingDetails = {
                            UserAppReferenceCode: (appVerTrackingDetails.UserAppReferenceCode == null || appVerTrackingDetails.UserAppReferenceCode.length <= 0) ? 
                                "NA" : appVerTrackingDetails.UserAppReferenceCode,
                            AppName: (appVerTrackingDetails.AppName == null || appVerTrackingDetails.UserAppReferenceCode.length <= 0) ? 
                                "NA" : appVerTrackingDetails.AppName,
                            AppVersion: (appVerTrackingDetails.AppVersion == null || appVerTrackingDetails.UserAppReferenceCode.length <= 0) ? 
                                "NA" : appVerTrackingDetails.AppVersion,
                            Platform: (appVerTrackingDetails.Platform == null || appVerTrackingDetails.UserAppReferenceCode.length <= 0) ? 
                                "NA" : appVerTrackingDetails.Platform == "ANDR" ? "Android" : appVerTrackingDetails.Platform,
                            LastDataReceivedAtDtTm: (appVerTrackingDetails.LastDataReceivedAtDtTm == null || appVerTrackingDetails.UserAppReferenceCode.length <= 0) ?
                                "NA" : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(appVerTrackingDetails.LastDataReceivedAtDtTm),
                            LatestAppVersion: (appVerTrackingDetails.LatestAppVersion == null || appVerTrackingDetails.UserAppReferenceCode.length <= 0) ? 
                                "NA" : appVerTrackingDetails.LatestAppVersion,
                            LastesAppUpdatedAtDtTm: (appVerTrackingDetails.LastesAppUpdatedAtDtTm == null || appVerTrackingDetails.UserAppReferenceCode.length <= 0) ? 
                                "NA" : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(appVerTrackingDetails.LastesAppUpdatedAtDtTm),

                        };
                        stateTrackedAppVerDataArr.push(singleDeviceTrackingDetails);
                    } 
                    
                    modifiedState.trackedAppVerDataArr = stateTrackedAppVerDataArr;
            
                }
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                } else if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});  
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});  
            }
        })
    }

    // Function will get searched user details on the basis of entered keywords(EmailID, PhoneNo, FirstName, & LastName).
    const searchUserInfoForTrackingDevices = (event) => {
    
        let modifiedState = state;
        event.preventDefault();
        modifiedState.searchResultDataArr = [];

        modifiedState.errors.requiredFiled = ((modifiedState.UsersEmailID == null || modifiedState.UsersEmailID == '' || modifiedState.UsersEmailID.length < 0) &&
                                              (modifiedState.firstName == null || modifiedState.firstName == '' || modifiedState.firstName.length < 0) &&
                                              (modifiedState.lastName == null || modifiedState.lastName == '' || modifiedState.lastName.length < 0) &&
                                              (modifiedState.mobileNo == null || modifiedState.mobileNo == '' || modifiedState.mobileNo.length < 0)
                                             )

        ? "Please enter atleast any one field for Search." 
        : "";

        // if any one of the required fields are not entered by user then return the function and show error message.
        if(modifiedState.errors.requiredFiled.length > 0) {
            
            setState({...modifiedState});  
            return
        }
        else {
            const jsonBody = {
                FirstName: trimStringAndRemoveTrailingComma(modifiedState.firstName), 
                LastName: trimStringAndRemoveTrailingComma(modifiedState.lastName),
                EmailID: trimStringAndRemoveTrailingComma(modifiedState.UsersEmailID),
                PhoneNo: modifiedState.mobileNo, 
                InvokeFromPg: "TrackAppVersion"
            };

            axios.post(`${getAPIHostURL()}/wclient/searchUserInfoForTrackingDevices`, jsonBody)
            .then(response => {
                
                if(response.data.code == 'SUCCESS') {

                    if(response.data.retreivedSearchResult == null || response.data.retreivedSearchResult.length <= 0) {
                        modifiedState.required = "No Search Result Found";
                        alert("No matched search result with entered keywords.")

                    } else {
                        let stateSearchDataArr = [];
                        modifiedState.searchResultDataArr = [];       

                        // React table checks using referential integrity. So if you do not create a
                        // new array (but just add to the existing array), the change detection will not trigger.
                        // So create a brand new array from existing product line Details data.
                        stateSearchDataArr = [...modifiedState.searchResultDataArr]
                                                
                        const retreivedSearchData = response.data.retreivedSearchResult;

                        for(let i = 0; i < retreivedSearchData.length; i++) {
                            const searchResultDetails = retreivedSearchData[i];
                            let singleSearchRsltData = {
                                FirstName: searchResultDetails.FirstName == null ? "" : searchResultDetails.FirstName,
                                LastName: searchResultDetails.LastName == null ? "" : searchResultDetails.LastName,
                                UsersEmailID: searchResultDetails.UserEmailID == null ? "" : searchResultDetails.UserEmailID,
                                MobileNo: searchResultDetails.UserPhoneNo == null ? "" : searchResultDetails.UserPhoneNo,
                            };
                            stateSearchDataArr.push(singleSearchRsltData);
                        } 
                        
                        modifiedState.searchResultDataArr = stateSearchDataArr;
                    }
                } else {
                    if(response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the Required parameters were not sent to the Server
                        modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                    } else if(response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                setState({...modifiedState});  
            })
            .catch(error => {
                console.log(error);
                console.log("Network error:");
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});  
                }
            })
        }
    }

    const onTrackAppVersionToggle = () => {
        state.trackAppVerModal = !state.trackAppVerModal;
        setState({...state});
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = state.errors;
        
        if(name == "mobileNo" && value.length > 10 ) {
            return;
        }

        switch (name) {

            case 'firstName': 
                errors.requiredFiled = ""       
            break;

            case 'lastName': 
                errors.requiredFiled = ""       
            break;

            case 'UsersEmailID': 
                errors.requiredFiled = ""  
            break;

            case 'mobileNo': 
                errors.requiredFiled = ""       
            break;

            case 'userAppRefCodeArr': 
                errors.requiredFiled = ""       
            break;

            default:
            break;
        }

        setState({
            ...state,
            errors, 
            [name]: value,
             
            // [event.target.name]: event.target.value,   
        });
    }

    const {errors}=state;

    return (
        <div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-12,col-md-12 ,col-sm-12 ">
                        <div className = "customerTableHeading" style={{marginTop: "0.2rem"}}>Track App(s) Version</div> 
                        <div className="modal-body p-4 box" style={{marginTop: "0.2rem"}}>        
                            <form onSubmit= {state.searchedBy == "searchedByUser" ? searchUserInfoForTrackingDevices : state.searchedBy == "searchByUserAppRefCode" ? getDetailedInfoForTrackingAppVer : ""}>
                                <div className="trackRadioDiv">
                                    <div className = "trackradioBox">
                                        <div style={{flex : ""}}>
                                            <input type='radio' name='searchBy'
                                                id = "searchByUser"
                                                value= "searchedByUser"
                                                onChange={onSearchByRadioBtnChange} noValidate 
                                                defaultChecked={true}
                                            /> 
                                            <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Search by User</span>
                                        </div>

                                        <div style={{flex : ""}}>
                                            <input type='radio' name='searchBy'  
                                                id = "searchByUserAppRefCode" 
                                                value= "searchByUserAppRefCode"
                                                onChange={onSearchByRadioBtnChange} noValidate 
                                                
                                            />
                                            <span style={{marginLeft: "0.3rem"}}> Search by UserApp/Alert Reference Code</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{display: state.searchedBy == "searchedByUser" ? "block" : "none", }} >
                                    <div className="form-group trackFrmrow">
                                        <div className="trackFrmLblAndInputDiv" >
                                            <div className="inputgroupCustom">
                                                <label className="trackFrmLabel">User EmailID:</label>
                                                <div className="trackFrmInput">
                                                    <input type='text' name='UsersEmailID' className="trackFrmInputForm"
                                                        value={state.UsersEmailID}
                                                        onChange={handleChange} noValidate    
                                                    />
                                                </div>     
                                            </div>
                                        </div>

                                        <span className="trackFrmLblAndInputDiv" >
                                            <div className="inputgroupCustom">
                                                <label className="trackFrmLabel">Mobile No:</label>
                                                <div className="trackFrmInput">
                                                    <input type='number' name='mobileNo' className=" trackFrmInputForm"
                                                        value={state.mobileNo}
                                                        onChange={handleChange} noValidate    
                                                    />
                                                </div>     
                                            </div>
                                        </span>
                                    </div>

                                    <div className="form-group trackFrmrow">
                                        <div className="trackFrmLblAndInputDiv" >
                                            <div className="inputgroupCustom">
                                                <label className="trackFrmLabel">First Name:</label>
                                                <div className="trackFrmInput">
                                                    <input type='text' name='firstName' className=" trackFrmInputForm"
                                                        value={state.firstName}
                                                        onChange={handleChange} noValidate    
                                                    />
                                                </div>     
                                            </div>
                                        </div>

                                        <span className="trackFrmLblAndInputDiv" >
                                            <div className="inputgroupCustom">
                                                <label className="trackFrmLabel">Last Name:</label>
                                                <div className="trackFrmInput">
                                                    <input type='text' name='lastName' className=" trackFrmInputForm"
                                                        value={state.lastName}
                                                        onChange={handleChange} noValidate    
                                                    />
                                                </div>     
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div  style={{display: state.searchedBy == "searchByUserAppRefCode" ? "block" : "none"}}>
                                    <div>
                                        <label className="reg-form-label">UserApp/Alert Reference Code: 
                                            <span className="addCustRequiredMarkStar">*</span>
                                        </label>    
                                    </div>
                                    <div>
                                        <input type='text' name='userAppRefCodeArr' className="input-form"  
                                            value={state.userAppRefCodeArr}
                                            onChange={handleChange} noValidate 
                                        />
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                    <div>
                                        <button type="submit" className="addCustSavebtn"  name="Save" >
                                            {state.searchedBy == "searchedByUser" ? "Search" : "Track App Version"}
                                        </button>
                                    </div>   
                                </div>                                        
                                <div className = "buttonErrorMessage">
                                    {errors.others.length > 0 && 
                                        <p className='addCustError' style={{textAlign: "center"}}>{errors.others}</p>}
                                    {errors.requiredFiled.length > 0 && 
                                        <p className='addCustError' style={{textAlign: "center"}}>{errors.requiredFiled}</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div style={{display: state.searchedBy == "searchedByUser" ? "block" : "none", 
                                borderStyle: "solid", marginTop: "0.5rem", borderWidth: "1px"}}> */}
                <div style={{display: state.searchedBy == "searchedByUser" ? "block" : "none"}}>
                    {/* <div className = "customerTableHeading"  style ={{borderBottom:" 1px solid rgba(0,0,0,.1)"}}> Search Result</div>  */}
                    <div className = "customerTableHeading"> Search Result</div> 
                    <div className="border border-3 ReactTableStyle">
                        <ReactTable  data = {state.searchResultDataArr}
                            columns = {state.columns}
                            getCellProps={cellInfo => ({
                                onClick: () => {
                                    if(cellInfo.column.id == "TrackDevice") {
                                        onClickTrackAppVer(cellInfo.row, cellInfo.column)                
                                    }                              
                                },
                            })}
                            renderBasedOnTable = {"SearchResultTable"}
                        />
                    </div>
                </div>
            </div>
            
            <Modal size="xl" isOpen={state.trackAppVerModal} toggle={onTrackAppVersionToggle} backdrop={state.backdrop}>
                <ModalHeader toggle={onTrackAppVersionToggle}>Tracked App(s) Version</ModalHeader>
                <ModalBody>  
                {/* <div style={{borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", marginLeft:"0.9rem", marginRight:"0.9rem", marginBottom: "1rem"}}>  */}
                    <div className="border border-3 ReactTableStyle" style = {{ marginLeft:"0.9rem", marginRight:"0.9rem", marginBottom: "1rem"}}>
                        <ReactTable  data = {state.selectedUserData}
                            columns = {state.selectedUserColumns}
                            getCellProps = {(cellInfo) => ({})}
                            renderBasedOnTable = {"UserInfoTable"}
                        />
                    </div>
                {/* </div>     */}

                {/* <div style={{borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", marginLeft:"0.9rem", marginRight:"0.9rem"}}>  */}
                <div style={{marginLeft:"0.9rem", marginRight:"0.9rem"}}> 
                    <div className = "customerTableHeading"> Tracked App(s) Version Data </div> 
                    {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", borderColor: "lightgray"}}> */}
                    <div className="border border-3 ReactTableStyle">
                        <ReactTable  data = {state.trackedAppVerDataArr}
                            columns = {state.trackedAppVersionTblColumns}
                            getCellProps = {(cellInfo) => ({})}
                            renderBasedOnTable = {"TrackedAppVersionTable"}
                        />
                    </div>
                </div>
                </ModalBody>
            </Modal>

        </div>
    );
}

export default VcTrackAppVersion;
