import React, { useState, useEffect, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import '../CSS/VcCustomer.css';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertUTCDateToStrYYYYMMDDHH24MMSS, convertLocalDateToDisplayToday} from '../../vtUtil';
import { FaSearch, FaEdit, FaEye, FaUserPlus } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_AUSrvrIssueReqParamsNotSent } from '../../VcLanguage';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

// Create a default prop getter
const defaultPropGetter = () => ({});

const ReactTable = ({ columns, data, getCellProps = defaultPropGetter, passedStateVariable}) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    );

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
          minWidth: 30,
          width: 450,
          Filter: DefaultColumnFilter,
          canFilter: true,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // set page size based on table
            initialState: {pageSize: 10},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    // if the state.goToPage1 is true and if new data is inserted
    // go to page 1
    React.useEffect(() =>{
        if(passedStateVariable[1] == true){
            gotoPage(0)
        }
    },[passedStateVariable[1]]);

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;{!passedStateVariable[0] ? "No Data Found." : ""}</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                <div className='fa' >
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <FaArrowDownLong />
                                        : <FaArrowUpLong />
                                    : ''}
                                </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"
                                >
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps(
                                            [
                                                {style: cell.column.style,},
                                                getCellProps(cell),
                                            ]
                                            )} className="td" 
                                        >{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                            // || 
                            //     // when there is no data found 
                            //     <tr style = {{backgroundColor: "white"}}>
                            //         <td>
                            //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex", justifyContent:"left"}}>{!passedStateVariable[0] ? "No Data Found." : ""}</span>
                            //         </td>
                            // </tr>                             
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div>
                    <div className="-pagination">
                        <div className="-previous">
                            <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                        </div>
                        <div className="-center">
                            <span className="-pageInfo">
                                Page {" "}    
                                <div className="-pageJump">
                                    <input 
                                        value={pageIndex + 1} 
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(page)
                                        }}
                                        aria-label="jump to page" type="number"
                                    />
                                </div>
                                of {" "}  
                                <span className="-totalPages">{pageOptions.length}</span>
                            </span>
                            <span className="select-wrap -pageSizeOptions">
                                <select aria-label="rows per page"
                                    style={{padding:"0.2rem"}}
                                    value={pageSize}
                                    onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}>
                                    {[5, 10, 20, 25, 100].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                        </option>
                                    ))}
                                </select>
                            </span>  
                        </div>
                        <div className="-next">
                            <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcOrganization (props) {
    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {

        if (id == "lastModifiedTime"){
            return rows.filter(row => {

                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]; 

                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        } else {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
            });
        }
    };

    const [state, setState] = useState({
        modal: false,
        backdrop: 'static',
        organizationName: "",
        email: "",
        phoneNo: "",
        workPhoneNo: "",
        address1: "",
        address2: "",
        city: "",
        district: "",
        state: "",
        pinCode: "",
        country: "",
        comments: "",
        orgID: "",
        lastModifiedTime: "",
        LastModifiedUtcDtTmOfReceivedOrganizationData: "",
        others: "",
        formViewMode: "",
        setByUserID: "",
        ArrCountryNames: [],
        ArrStateNames: [],
        page: 0,
        goToPage1: false,
        selectedRowIndex: null,

        originalOrganizationName: "",
        originalEmailID: "",
        originalPhoneNo: "",
        originalWorkPhoneNo: "",
        originalCountryCode: "+91",
        originalCountryCodeForWorkPhnNo: "+91",
        originalAddress1: "",
        originalAddress2: "",
        originalCity: "",
        originalDistrict: "",
        originalState: "",
        originalPinCode: "",
        originalCountry: "",
        originalComment: "",
        noDataFound: "",
        CountryInfo: [],
        CountryCode: "+91",
        CountryCodeForWorkPhnNo: "+91",

        errors: { 
            organizationName: "",
            email: "",
            phoneNo: "",
            workPhoneNo: "",
            contactNo:"",
            address1: "",
            address2: "",
            city: "",
            district: "",
            cityAndDistrict: "",
            state: "",
            pinCode: "",
            country: "",
            comments: "",
            others: "",
        },
        data: [],

        columns: [ 
            {       
                Header:() => <div className="AlertLogTableHeader">View</div>,
                accessor: 'view',
                width: 80,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
                Cell: (props) => <span className='deviceNameCell'>{props.value}</span>
            },
            {   
                Header:() => <div className="AlertLogTableHeader">Edit</div>, 
                accessor: "edit",
                width: 80,
                style:({
                    textAlign: "center",
                    verticalAlign: "middle",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
                Cell:  props => <span className='deviceNameCell'>{props.value}</span>
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Organization ID</div>,  
                accessor: 'orgID',
                width: 190,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Organization<br/>Name</div>,  
                accessor: 'organizationName',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },  
            {       
                Header:() => <div className="AlertLogTableHeader">Email ID</div>,  
                accessor: 'email',
                width: 200,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left", 
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"    
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Phone No</div>,  
                accessor: 'phoneNo',
                filter: filterCaseInsensitive,
                width: 135,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Work<br/>Phone No</div>,  
                accessor: 'workPhoneNo',
                filter: filterCaseInsensitive,
                width: 135,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 
            
            {       
                Header:() => <div className="AlertLogTableHeader">Address1</div>,  
                accessor: 'address1',
                filter: filterCaseInsensitive,
                width: 180,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Address2</div>,  
                accessor: 'address2',
                filter: filterCaseInsensitive,
                width: 180,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },  
            {       
                Header:() => <div className="AlertLogTableHeader">City</div>,  
                accessor: 'city',
                filter: filterCaseInsensitive,
                width: 135,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {   
                Header:() => <div className="AlertLogTableHeader">District</div>,  
                accessor: 'district',
                width: 135,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">Pincode</div>,  
                accessor: 'pinCode',
                width: 110,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">State</div>,  
                accessor: 'state',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">Country</div>,  
                accessor: 'country',
                width: 135,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">Comments</div>,  
                accessor: 'comments',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },  
            {
                Header:() => <div className="AlertLogTableHeader">Set By<br/>User ID</div>,  
                accessor: 'setByUserID',
                width: 200,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            { 
                Header:() => <div className="AlertLogTableHeader">Last<br/>Modified Time</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'lastModifiedTime',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 190,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },      
        ],  
    });

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return "Today " + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    // function will call on cliking of eye icon, setting the flag formViewMode to viewMode to display form in readonly only.
    // fetching the value from react table selected organization details row and set it in the state.
    // setting modal value to true to display Form view only mode.
    const onViewOrgDetails = (rowInfo, column) => {
        let modifiedState = state;
        modifiedState.formViewMode = "viewMode";
        modifiedState.orgID = rowInfo.original.orgID;
        modifiedState.organizationName = rowInfo.original.organizationName;
        modifiedState.email = rowInfo.original.email;
        modifiedState.phoneNo = rowInfo.original.phoneNo.split(" ")[1];
        modifiedState.CountryCode = rowInfo.original.phoneNo.split(" ")[0];
        modifiedState.workPhoneNo = rowInfo.original.workPhoneNo.split(" ")[1];
        modifiedState.CountryCodeForWorkPhnNo = rowInfo.original.workPhoneNo.split(" ")[0];
        modifiedState.state = rowInfo.original.state;
        modifiedState.address1 = rowInfo.original.address1;
        modifiedState.address2 = rowInfo.original.address2;
        modifiedState.city = rowInfo.original.city;
        modifiedState.district = rowInfo.original.district;
        modifiedState.pinCode = rowInfo.original.pinCode;
        modifiedState.country = rowInfo.original.country;
        modifiedState.comments = rowInfo.original.comments;
        modifiedState.modal = true;

        if(modifiedState.country != null || modifiedState.country.length > 0) {
            getStatesOnSelectedCountry();
        } 

        setState({...modifiedState});
    }

    // function will call on cliking of edit icon, setting the flag formViewMode to editMode to display form in Editable screen.
    // fetching the value from react table to allow edting the exsiting details of selected organization.
    // setting modal value to true to display Form in Edit mode.
    // also setting organization original details(before any changes) to state to compaire it latter on with value of changed fields
    // to propmpt confirmation while saving edited fields.
    const onEditOrgDetails = (rowInfo, column) => {
        let modifiedState = state;
        modifiedState.formViewMode = "editMode";

        modifiedState.organizationName = rowInfo.original.organizationName;
        modifiedState.email = rowInfo.original.email;
        modifiedState.phoneNo = (rowInfo.original.phoneNo.split(" ")[1] != null && rowInfo.original.phoneNo.split(" ")[1].length > 0) ? rowInfo.original.phoneNo.split(" ")[1] : rowInfo.original.phoneNo;
        modifiedState.workPhoneNo = (rowInfo.original.workPhoneNo.split(" ")[1] != null && rowInfo.original.workPhoneNo.split(" ")[1].length > 0) ? rowInfo.original.workPhoneNo.split(" ")[1] : rowInfo.original.workPhoneNo;
        modifiedState.state = rowInfo.original.state;
        modifiedState.address1 = rowInfo.original.address1;
        modifiedState.address2 = rowInfo.original.address2;
        modifiedState.city = rowInfo.original.city;
        modifiedState.district = rowInfo.original.district;
        modifiedState.CountryCode = (rowInfo.original.phoneNo.split(" ")[0] != null && rowInfo.original.phoneNo.split(" ")[0].length > 0 && rowInfo.original.phoneNo.startsWith("+"))? rowInfo.original.phoneNo.split(" ")[0] : "+91";
        modifiedState.CountryCodeForWorkPhnNo = (rowInfo.original.workPhoneNo.split(" ")[0] != null && rowInfo.original.workPhoneNo.split(" ")[0].length > 0 && rowInfo.original.workPhoneNo.startsWith("+")) ? rowInfo.original.workPhoneNo.split(" ")[0] : "+91";
        modifiedState.pinCode = rowInfo.original.pinCode;
        modifiedState.country = rowInfo.original.country;
        modifiedState.comments = rowInfo.original.comments;
        modifiedState.orgID = rowInfo.original.orgID;
        modifiedState.lastModifiedTime = rowInfo.original.lastModifiedTime;

        modifiedState.originalOrganizationName =rowInfo.original.organizationName;
        modifiedState.originalEmailID = rowInfo.original.email;
        modifiedState.originalPhoneNo = (rowInfo.original.phoneNo.split(" ")[1] != null && rowInfo.original.phoneNo.split(" ")[1].length > 0) ? rowInfo.original.phoneNo.split(" ")[1] : rowInfo.original.phoneNo;
        modifiedState.originalWorkPhoneNo = (rowInfo.original.workPhoneNo.split(" ")[1] != null && rowInfo.original.workPhoneNo.split(" ")[1].length > 0) ? rowInfo.original.workPhoneNo.split(" ")[1] : rowInfo.original.workPhoneNo;
        modifiedState.originalCountryCode = (rowInfo.original.phoneNo.split(" ")[0] != null && rowInfo.original.phoneNo.split(" ")[0].length > 0 && rowInfo.original.phoneNo.startsWith("+"))? rowInfo.original.phoneNo.split(" ")[0] : "";
        modifiedState.originalCountryCodeForWorkPhnNo = (rowInfo.original.workPhoneNo.split(" ")[0] != null && rowInfo.original.workPhoneNo.split(" ")[0].length > 0 && rowInfo.original.workPhoneNo.startsWith("+")) ? rowInfo.original.workPhoneNo.split(" ")[0] : "";
        modifiedState.originalAddress1 = rowInfo.original.address1;
        modifiedState.originalAddress2 = rowInfo.original.address2;
        modifiedState.originalCity = rowInfo.original.city;
        modifiedState.originalDistrict = rowInfo.original.district;
        modifiedState.originalState = rowInfo.original.state;
        modifiedState.originalCountry = rowInfo.original.country;
        modifiedState.originalPinCode = rowInfo.original.pinCode;
        modifiedState.originalComment = rowInfo.original.comments;

        modifiedState.modal = true;

        if(modifiedState.country != null || modifiedState.country.length > 0) {
            getStatesOnSelectedCountry();
        }

        setState({...modifiedState});
    }

    //putting the flag formViewMode to Insert Mode(Create new Organization)
    //toggling the Organization create form. 
    const toggle = () => {
        let modifiedState = state;
        modifiedState.modal = !modifiedState.modal
        modifiedState.orgID = "";
        modifiedState.organizationName = "";
        modifiedState.email = "";
        modifiedState.phoneNo = "";
        modifiedState.workPhoneNo = "";
        modifiedState.address1 = "";
        modifiedState.address2 = "";
        modifiedState.city = "";
        modifiedState.district = "";
        modifiedState.state = "";
        modifiedState.pinCode = "";
        modifiedState.country = "";
        modifiedState.comments = "";
        modifiedState.CountryCode = "+91";
        modifiedState.CountryCodeForWorkPhnNo = "+91";
        modifiedState.formViewMode = "insertMode";
        modifiedState.ArrStateNames = [];

        modifiedState.errors.organizationName = "";
        modifiedState.errors.email = "";
        modifiedState.errors.phoneNo = "";
        modifiedState.errors.contactNo = "";
        modifiedState.errors.address1 = "";
        modifiedState.errors.address2 = "";
        modifiedState.errors.cityAndDistrict = "";
        modifiedState.errors.state = "";
        modifiedState.errors.pinCode = "";
        modifiedState.errors.country = "";
        modifiedState.errors.comments = "";
        modifiedState.errors.others = "";
        setState({...modifiedState});
    }
    
    const closeModal = () => {
        toggle();
    }

    useEffect(() =>{
        getLatestOrganizationInfo();
        getAllCountryCodeInfo();
    }, []);

    //when first time page will load we send LastModifiedUtcDtTmForInsertOrUpdateOrgDetails empty, 
    //and retrieved all the Organization data from database and filled out in the Organization table.
    //In case of create new Organization and edit existing Organization details, we send latest LastModifiedUtcDtTmForInsertOrUpdateCustDetails
    //received from Organization table, and axios will retrieved the Organization details next to LastModifiedUtcDtTmForInsertOrUpdateCustDetails.
    //we put latest retrived data on top of the table.
    const getLatestOrganizationInfo = (inModifiedState = null) => {
        
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.goToPage1 = false;

        let LastModifiedUtcDtTmForInsertOrUpdateOrgDetails;
        //In case of create new organization(flag formViewMode == InsertMode) and edit existing organization(flag formViewMode == "editMode") details, 
        //we send latest LastModifiedUtcDtTmForInsertOrUpdateOrgDetails received from organization table
        //or send empty LastModifiedUtcDtTmForInsertOrUpdateOrgDetails.
        if(modifiedState.formViewMode == "editMode" || modifiedState.formViewMode == "insertMode"){
            LastModifiedUtcDtTmForInsertOrUpdateOrgDetails = modifiedState.LastModifiedUtcDtTmOfReceivedOrganizationData;
        } else {
            LastModifiedUtcDtTmForInsertOrUpdateOrgDetails = null;
        }

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        const jsonBody = {
            lastModifiedTime: LastModifiedUtcDtTmForInsertOrUpdateOrgDetails, 
        };
        axios.post(`${getAPIHostURL()}/wclient/getLatestOrganizationInfo`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {    
                if(response.data.retreivedOrganizationDetails == null || response.data.retreivedOrganizationDetails.length <= 0) {
                    modifiedState.noDataFound = "No Organization Data Found";
                } else {
                    let stateOrganizationDetailsArr = [];
                    let stateProductLineDetailsArrForFechingData = [];
                                            
                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing Organization Details data.
                    stateOrganizationDetailsArr = [...modifiedState.data]

                    const receiveOrganizationDataArr = response.data.retreivedOrganizationDetails;
                    let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
                    let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>
                   
                    for(let i = 0; i < receiveOrganizationDataArr.length; i++) {
                        const organizationDetails = receiveOrganizationDataArr[i];
                        let organizationlastModifiedLocaleDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(organizationDetails.OrgLastModifiedTime);
                        let singleOrganizationDetails = {
                            view: viewEyeIcon,  
                            edit: editIcon,
                            orgID:organizationDetails.OrgID,
                            organizationName: organizationDetails.OrgName == null ? "" : organizationDetails.OrgName,
                            email: organizationDetails.EmailID == null ? "" : organizationDetails.EmailID,
                            phoneNo: organizationDetails.PhoneNo == null ? "" : organizationDetails.PhoneNo,
                            workPhoneNo: organizationDetails.WorkPhoneNo == null ? "" : organizationDetails.WorkPhoneNo,
                            address1: organizationDetails.Address1 == null ? "" : organizationDetails.Address1,
                            address2: organizationDetails.Address2 == null ? "" : organizationDetails.Address2,
                            city: organizationDetails.City == null ? "" : organizationDetails.City,
                            district: organizationDetails.District == null ? "" : organizationDetails.District,
                            pinCode: organizationDetails.PinCode == null ? "" : organizationDetails.PinCode,
                            state: organizationDetails.State == null ? "" : organizationDetails.State,
                            country: organizationDetails.Country == null ? "" : organizationDetails.Country,
                            comments: organizationDetails.Comments == null ? "" : organizationDetails.Comments,
                            setByUserID:organizationDetails.SetByUserID == null ? "" : organizationDetails.SetByUserID,
                            lastModifiedTime: organizationlastModifiedLocaleDateTime,
                            lastModifiedUtcDtTm : organizationDetails.LastModifiedUtcDtTm == null ? "" : organizationDetails.LastModifiedUtcDtTm,
                        };

                        //if form is open in insertMode(create new Organization) then just add new retrieved data(new added Organization details) at top of the Organization table.
                        if(modifiedState.formViewMode == "insertMode"){
                            stateOrganizationDetailsArr.unshift(singleOrganizationDetails);
                            // modifiedState.page = 0;
                            modifiedState.selectedRowIndex = 0;
                            modifiedState.goToPage1 = true;
                        }
                        //if form is open in editMode(edit existing Organization details) then compare all OrgID in Organization table
                        //with retrieved OrgID after editing Organization details. If both orgId get match then remove the selected Organization data
                        //from Organization table, and add new retrived Organization data at top of the Organization table.
                        else if(modifiedState.formViewMode == "editMode"){
                            for(let j = 0; j <stateOrganizationDetailsArr.length; j++){
                                if(stateOrganizationDetailsArr[j].orgID == singleOrganizationDetails.orgID){
                                    stateOrganizationDetailsArr.splice(j, 1);
                                }
                            }
                            // modifiedState.page = 0;
                            modifiedState.selectedRowIndex = 0;
                            modifiedState.goToPage1 = true;
                            stateOrganizationDetailsArr.unshift(singleOrganizationDetails);
                        }    
                        //on first time page load its will retrieved all Organization details and add it in Organization table,
                        // in order of latest data on top of the Organization table.
                        else { 
                            stateProductLineDetailsArrForFechingData.push(singleOrganizationDetails);
                            modifiedState.goToPage1 = false;
                        }
                    } 

                    if(modifiedState.formViewMode == "insertMode"){
                        // stored latest received UTC Date Time of Organization table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedOrganizationData = stateOrganizationDetailsArr[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateOrganizationDetailsArr;
                    } else if(modifiedState.formViewMode == "editMode"){
                       // stored latest received UTC Date Time of Organization table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedOrganizationData = stateOrganizationDetailsArr[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateOrganizationDetailsArr;
                    } else {
                       // stored latest received UTC Date Time of Organization table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedOrganizationData = stateProductLineDetailsArrForFechingData[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateProductLineDetailsArrForFechingData;
                    }
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        })
    }

    const insertOrUpdateOrganizationInfo = (event) => {
        
        let modifiedState = state;

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let loggedinUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        event.preventDefault();
        let organizationID;
        let confirmationForOrgFormEditFileds = "";

        // if flag formviewMode is set "editMode"(edit Organization details) then check which fields are edited by user,
        // and ask a confirmation for that edited fields of Organization edit form.
        // if there is no change in form then return a function and give message "There are no changes in Organization Details". 
        // aslo adding Organization id of selected Organization which user want to edit.
        if(modifiedState.formViewMode == "editMode")
        {   
            organizationID = modifiedState.orgID;
            
            if(modifiedState.originalOrganizationName != modifiedState.organizationName){
                confirmationForOrgFormEditFileds = " Organization Name,";
            }
            if(modifiedState.originalEmailID != modifiedState.email){
                confirmationForOrgFormEditFileds = confirmationForOrgFormEditFileds + " Email ID,";
            }
            if(modifiedState.originalPhoneNo != modifiedState.phoneNo){
                confirmationForOrgFormEditFileds = confirmationForOrgFormEditFileds + " Phone No,";
            }
            if(modifiedState.originalWorkPhoneNo != modifiedState.workPhoneNo){
                confirmationForOrgFormEditFileds = confirmationForOrgFormEditFileds + " Work Phone No,";
            }
            if( modifiedState.phoneNo != null && modifiedState.phoneNo.length > 0 && modifiedState.originalCountryCode != modifiedState.CountryCode){
                confirmationForOrgFormEditFileds = confirmationForOrgFormEditFileds + " Country Code Of Phone No,";
            }
            if( modifiedState.workPhoneNo != null && modifiedState.workPhoneNo.length > 0 && (modifiedState.originalCountryCodeForWorkPhnNo != modifiedState.CountryCodeForWorkPhnNo)){
                confirmationForOrgFormEditFileds = confirmationForOrgFormEditFileds + " Country Code Of Work Phone No,";
            }
            
            if(confirmationForOrgFormEditFileds != null && confirmationForOrgFormEditFileds.length > 0 ){
                if(!window.confirm(`Are you sure you want to change the ${confirmationForOrgFormEditFileds} for this organization ?`)){
                    return
                }
            // this check is only for ensue that if no single fileds are edited than its must go in else part and return function 
            //with alert message
            } else if(modifiedState.originalAddress1 != modifiedState.address1 || modifiedState.originalAddress2 != modifiedState.address2 ||
                        modifiedState.originalCity != modifiedState.city || modifiedState.originalDistrict != modifiedState.district || 
                        modifiedState.originalPinCode != modifiedState.pinCode || modifiedState.originalState != modifiedState.state || 
                        modifiedState.originalComment != modifiedState.comments || modifiedState.originalCountry != modifiedState.country
                     )
            {
                        //cosole.log("some fileds are edited")
            } else {
                alert("There are no changes in Organization Details")
                return
            }
        } 
        // if flag formViewMode is not set as EditMode(Edit existing Organization Details) then organizationID will send null.
        // in case of create new organization we send Organization Id =  Null
        else {
            organizationID = null;    
        }

        // validation of all required fields and combination of two fields as required fields.

        modifiedState.errors.organizationName = (modifiedState.organizationName == null || modifiedState.organizationName.length <= 0)
            ? "Organization Name is required." 
            : "";

        modifiedState.errors.address1 = (modifiedState.address1 == null || modifiedState.address1.length <= 0)
            ? "Address Line1 is required." 
            : "";

        modifiedState.errors.state = (modifiedState.state == null || modifiedState.state.length <= 0)
            ? "State is required." 
            : "";

        modifiedState.errors.pinCode = (modifiedState.pinCode == null || modifiedState.pinCode.length <= 0)
            ? "Pincode is required." 
            : "";

        modifiedState.errors.country = (modifiedState.country == null || modifiedState.country.length <= 0)
            ? "Country is required." 
            : "";
            
        modifiedState.errors.contactNo = ((modifiedState.phoneNo == null || modifiedState.phoneNo.length <= 0) &&
                                          (modifiedState.workPhoneNo == null || modifiedState.workPhoneNo.length <= 0)
                                         )
            ? "One of the two contact numbers are required." 
            : "";
        
        modifiedState.errors.cityAndDistrict = ((modifiedState.city == null || modifiedState.city.length <= 0) &&
                                                (modifiedState.district == null || modifiedState.district.length <= 0)
                                               )
            ? "Either one of 'City' or 'District' is required."
            : "";

        // if any one of the required fields are not entered by user then return the function and show error message.
        if( modifiedState.errors.organizationName.length > 0 ||  modifiedState.errors.address1.length > 0 || 
            modifiedState.errors.state.length > 0 || modifiedState.errors.pinCode.length > 0 || 
            modifiedState.errors.country.length > 0 || modifiedState.errors.contactNo.length > 0 || 
            modifiedState.errors.phoneNo.length > 0 || modifiedState.errors.cityAndDistrict.length > 0 ||
            modifiedState.errors.email.length > 0) {
            // modifiedState.errors.others = "Please enter all required filled.";
            setState({...modifiedState});
            return
        }
        else {

                // Convert email to lowercase 
                modifiedState.email = (modifiedState.email != null && modifiedState.email.length > 0) ? modifiedState.email.toLowerCase() : "" ;
                
                const jsonBody = {
                OrgID: organizationID,
                OrganizationName: trimStringAndRemoveTrailingComma(modifiedState.organizationName), 
                EmailID: trimStringAndRemoveTrailingComma(modifiedState.email),
                PhoneNo: (modifiedState.phoneNo != null && modifiedState.phoneNo.length > 0) ? modifiedState.CountryCode +" " +modifiedState.phoneNo : modifiedState.phoneNo, 
                WorkPhoneNo: (modifiedState.workPhoneNo != null && modifiedState.workPhoneNo.length > 0) ? modifiedState.CountryCodeForWorkPhnNo +" " +modifiedState.workPhoneNo : modifiedState.workPhoneNo, 
                Address1: trimStringAndRemoveTrailingComma(modifiedState.address1), 
                Address2: trimStringAndRemoveTrailingComma(modifiedState.address2), 
                City: trimStringAndRemoveTrailingComma(modifiedState.city), 
                District: trimStringAndRemoveTrailingComma(modifiedState.district), 
                State: trimStringAndRemoveTrailingComma(modifiedState.state), 
                PinCode: trimStringAndRemoveTrailingComma(modifiedState.pinCode), 
                Country: trimStringAndRemoveTrailingComma(modifiedState.country), 
                Comments: trimStringAndRemoveTrailingComma(modifiedState.comments),
                SetByUserID: loggedinUserID,
            };

            axios.post(`${getAPIHostURL()}/wclient/insertOrUpdateOrganizationInfo`, jsonBody)
            .then(response => {
                if(response.data.code == 'SUCCESS') {    
                    if(modifiedState.formViewMode == "editMode") {
                        alert(`Successfully 'updated' Organization details with OrganizationName: ${modifiedState.organizationName}.`);
                    } else{
                        alert(`Successfully registered new Organization with OrganizationName: ${modifiedState.organizationName}.`);
                    }

                    modifiedState.modal = false;
                    getLatestOrganizationInfo(modifiedState)
                } else {
                    if(response.data.code == 'EMAILID_ALREADY_EXIST_FOR_ANOTHER_ORG') {
                        if(modifiedState.formViewMode == "editMode") {
                            alert(`A Organization with Email ID '${modifiedState.email}' already exists for another Organization. You cannot use this Email ID.`)
                        } else {
                            alert(`A Organization with Email ID '${modifiedState.email}' already exists. cannot create new Organization.`)
                        }
                    } else if(response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                    else if(response.data.code == 'REQ_PARAMS_MISSING'){
                        modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                setState({...modifiedState});
            })
            .catch(error => {
                console.log(error);
                console.log("Network error:");
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});
                }
            })
        } 
    }

    const handleKeyPress = (e) => {
        const characterCode = e.key
        const characterNumber = Number(characterCode)
        if (characterNumber >= 0 && characterNumber <= 9) {

            if (e.currentTarget.value && e.currentTarget.value.length) {
                return;
            } 
            else if (characterNumber == 0) {
                e.preventDefault()
            }
        } 
        else {
            e.preventDefault()
        }
    };       
    
    const handleChange = (event) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        event.preventDefault();
        const { name, value } = event.target;
        let errors = state.errors;

        // if(name == "pinCode" && value.length > 10 ) {
        //     return;
        // }
        switch (name) {
            case 'email': 
                errors.email = 
                    value.length > 0 ?
                        (
                            (validEmailRegex.test(value)) ? 
                            '' :  
                            "EmailID is not valid!"
                        )
                    : '';
            break;

            case 'organizationName':     
                errors.organizationName = "";  
            break;

            case 'phoneNo': 
                errors.contactNo = "";
                errors.phoneNo = 
                    (((value.length) > 0 && (value.length) < 4) || (value.length) > 12)
                        ? 'Phone number must be 4 to 12 digits.' : '';      
            break;

            case 'workPhoneNo': 
                errors.contactNo = "";
            break;

            case 'address1': 
                errors.address1 = "";
            break;

            case 'district': 
                errors.cityAndDistrict = "";
            break;

            case 'city': 
                errors.cityAndDistrict = "";
            break;

            case 'pinCode': 
                errors.pinCode = "";
            break;

            default:
            break;
        }

        setState({
            ...state,
            errors, 
            [name]: value,
        });
    }
    
    const getAllCountryCodeInfo = () => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
    
        let modifiedState = state;
        modifiedState.ArrCountryNames = [];
    
        axios.post(`${getAPIHostURL()}/wclient/getAllCountryCodeInformation`)
        .then(response => {
          if(response.data.code == "SUCCESS") {
              modifiedState.CountryInfo = [];
              modifiedState.CountryInfo = response.data.CountryInfo;
              for(let i=0; i<modifiedState.CountryInfo.length; i++) {
                let SingleCountryName = modifiedState.CountryInfo[i]['Country'];
                modifiedState.ArrCountryNames.push(SingleCountryName);
              }
          } else {
    
            if(response.data.code == "SQL_ERROR"){
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
            }
          }
          setState({...modifiedState});
        })
        .catch(error => {
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            // Tell the user that there are network issues
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            setState({...modifiedState});
        } 
        });
    }

    const getStatesOnSelectedCountry = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
    
        let modifiedState = state;
        
        modifiedState.ArrStateNames = [];

        if (modifiedState.country == null || modifiedState.country.length <= 0) {
            setState({...modifiedState});
            return;
        } else {

            let jsonBody = {
                EnteredCountryName: modifiedState.country,
            };

            axios.post(`${getAPIHostURL()}/wclient/getAllStatesInformation`, jsonBody)
            .then(response => {
                if(response.data.code == "SUCCESS") {
                    modifiedState.StateInfo = [];
                    modifiedState.StateInfo = response.data.StateInfo;
                    for(let i=0; i<modifiedState.StateInfo.length; i++) {
                        let SingleStateName = modifiedState.StateInfo[i]['StateName'];
                        modifiedState.ArrStateNames.push(SingleStateName);
                    }
                } else {      
                    if(response.data.code == "SQL_ERROR") {
                        modifiedState.errors.others = t(IDS_LoginServerIssue);
                    } else if(response.data.code == "REQ_PARAMS_MISSING") {
                        modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                setState({...modifiedState});
            })
            .catch(error => {
                console.log("Network error:");
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    modifiedState.errors.others = t(IDS_RegistNetworkError);
                    setState({...modifiedState});
                } 
            }); 
        }  
    }

    const onChangeCountryCode = (e) => {
        let modifiedState = state;
        modifiedState.CountryCode = e.target.value;
        setState({...modifiedState});
    }
  
    const onChangeCountryCodeOfWorkPhnNo = (e) => {
        let modifiedState = state;
        modifiedState.CountryCodeForWorkPhnNo = e.target.value;
        setState({...modifiedState});
    }

    const onChangeCountry = (e) => {
        let modifiedState = state;
        modifiedState.country = e.target.value;
        modifiedState.errors.country = "";

        if(modifiedState.country != null || modifiedState.country.length > 0 ) {
            getStatesOnSelectedCountry();
            modifiedState.state = "";
        } 

        setState({...modifiedState});
    }

    const onChangeState = (e) => {
        let modifiedState = state;
        modifiedState.state = e.target.value;
        modifiedState.errors.state = "";

        if (modifiedState.country == null || modifiedState.country.length <= 0) {
            modifiedState.state = "";
            modifiedState.errors.country = "Please select Country";
        }

        setState({...modifiedState});
    }

    const onPageChange = (page) => {
        let modifiedState = state;
        modifiedState.page = page;
        setState({...modifiedState});
    };

    const {errors} = state;
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    return (
    <div>
        <div className = "container col-lg-12" style = {{display: "flex", justifyContent: "space-between", padding: "0.2rem" }}>
            <div>
                <button type = "button" 
                    className = "btn-md addCustBtn" 
                    style = {{background: "transparent", pointerEvents: "none", border: "none"}}
                >
                    Add New Organization
                </button>  
                <FaUserPlus style = {{background: "transparent", border: "none", color:"transparent"}} className = "addCustIcon"/>  
            </div>

            
            <div className = "customerTableHeading">
                Add, Edit or View All Organization Details
            </div> 
            <div>
                <button type = "button" 
                    className = "btn-md addCustBtn"
                    onClick = {toggle}>Add New Organization
                </button>  
                <FaUserPlus onClick = {toggle} className = "addCustIcon" 
                    title="Add New Organization" 
                    style={{justifyContent: "flex-end", marginBottom: "0rem"}}
                />  
            </div>
        </div>
            {/* <div style={{borderStyle: "solid", borderWidth: "1px"}}> */}
            <div className="border border-3 ReactTableStyle">
                <ReactTable 
                    columns={state.columns} 
                    data={state.data}
                    getCellProps={(cellInfo) => {
                        let modifiedState = state;
                        return {
                            onClick: () => {
                                modifiedState.selectedRowIndex = cellInfo.row.index;
                                setState({...modifiedState});

                                if(cellInfo.column.id == "view") {
                                    onViewOrgDetails(cellInfo.row, cellInfo.column); 
                                }  else if(cellInfo.column.id == "edit"){
                                    onEditOrgDetails(cellInfo.row, cellInfo.column);
                                }             
                            }, 
                            style: {
                                cursor: '',
                                background: cellInfo.row.index === modifiedState.selectedRowIndex ? '#6c95ba' : '',
                                color: cellInfo.row.index === modifiedState.selectedRowIndex ? 'white' : 'black',
                                alignItems: "center",
                            }
                        }
                    }}
                    // as React Table is outsdde the main function, state varaibles cant be accessed there 
                    // so passing all the state variables on which react table is dependent through passedStateVariable
                    passedStateVariable = {[state.noDataFound, state.goToPage1]}
                />
            </div>
        
        <Modal size="lg" isOpen={state.modal} backdrop={state.backdrop}>
                <ModalHeader toggle={toggle} style={{textAlign: "center"}}>
                    {state.formViewMode == "viewMode" ? <span> Organization Details View Only </span> : 
                    state.formViewMode == "editMode" ? <span> Edit Organization Details </span> 
                    : <span>Add New Organization Details </span>}
                </ModalHeader>
                <ModalBody>  
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="container col-lg-11 col-md-12">
                                <div className="modal-body addCustbox">
                                    <form 
                                        style={{pointerEvents: state.formViewMode == "viewMode" ? "none" : "auto"}} 
                                    >
                                        <label style={{ display: "flex", justifyContent:"flex-end", fontSize:"0.7rem"}}><b style={{marginRight:"0.1rem"}}>Note: </b> Fields marked in <span style={{color:"var(--errorColor)", fontSize:"1rem", marginLeft:"0.1rem", marginRight:"0.1rem"}}>* </span> are compulsory.</label>
                                        <div className="form-group addCustForm" 
                                            style={state.formViewMode == "insertMode" ? {display: "none"} :{display: "block"}}
                                        >
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">Organization ID:
                                                <span className="addCustRequiredMarkStar"></span></label>
                                                <div className="addCustInputAndError">
                                                    <input type='text' name='orgID' className=" addCustInputForm"  
                                                        value={state.orgID} noValidate  readOnly = {true}
                                                        style={{color: "#505050", backgroundColor: "#F0F0F0"}}
                                                    />  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">Organization Name:<span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                    <input type='text' name='organizationName' className=" addCustInputForm"
                                                        value={state.organizationName}
                                                        onChange={handleChange} noValidate 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                    />
                                                    {errors.organizationName.length > 0 && 
                                                        <span  className='addCustError'>{errors.organizationName}</span>}   
                                                </div>     
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled"> Email ID:</label>
                                                <div className="addCustInputAndError">
                                                    <input type='email' name='email' className=" addCustInputForm" 
                                                        value={state.email.toLocaleLowerCase()}
                                                        onChange={handleChange} noValidate 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }} 
                                                    />
                                                    {errors.email.length > 0 && 
                                                        <span className='addCustError'>{errors.email}</span>}   
                                                </div> 
                                            </div>
                                        </div>
                                        <div style={{marginBottom: "0.5rem", border: "0.1rem solid #a8cca8", backgroundColor:"#F8F8F8", borderRadius:"0.7rem"}}>
                                            {state.formViewMode == "viewMode" ? <label style={{ display: "flex", justifyContent:"flex-start", fontSize:"0.7rem", marginLeft:"0.5rem"}}></label> : 
                                                <label style={{ display: "flex", justifyContent:"flex-start", fontSize:"0.7rem", marginLeft:"0.5rem"}}><span style={{color:"var(--errorColor)", fontSize:"1rem", marginRight:"0.2rem"}}> * </span> Please enter at least one of your Contact Numbers.</label>
                                            }
                                            <div className="form-group addCustForm">
                                                <div className="inputgroupCustom">
                                                    <label className="addCustFormLabelWithoutRequiredFiled">Phone No:</label>
                                                    <div className="addCustInputAndError">
                                                        <span style={{display:"flex", justifyContent:"space-around"}}>
                                                            <select className="addCustInputForm"
                                                                    required
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    value={state.CountryCode}
                                                                    onChange={onChangeCountryCode}
                                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", width:"4.3rem", marginRight:"0.5rem"} : {color: "", backgroundColor: "", width:"4.3rem", marginRight:"0.5rem"}}
                                                            >
                                                                <option value="+91" select= "true">+91</option> 
                                                                {(state.CountryInfo).map((singleCountryCode, index) => 
                                                                <option key={index} value={singleCountryCode["CountryCode"]}>
                                                                {singleCountryCode["CountryCode"]}
                                                                {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                                                {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                                                {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                                                {'\xA0'+singleCountryCode["Country"]}</option>)}
                                                            </select>

                                                            <input type="number" className="addCustInputForm" name="phoneNo" 
                                                                    value={state.phoneNo}
                                                                    onChange={handleChange} 
                                                                    // onKeyPress= {handleKeyPress} 
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        />
                                                        </span>

                                                        {errors.phoneNo.length > 0 && 
                                                            <span  className='addCustError'>{errors.phoneNo}</span>}       
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group addCustForm">
                                                <div className="inputgroupCustom">
                                                    <label className="addCustFormLabelWithoutRequiredFiled">Work Phone No:</label>
                                                    <div className="addCustInputAndError">
                                                        <span style={{display:"flex", justifyContent:"space-around"}}>
                                                            <select className="addCustInputForm"
                                                                    required
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    value={state.CountryCodeForWorkPhnNo}
                                                                    onChange={onChangeCountryCodeOfWorkPhnNo}
                                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", width:"4.3rem", marginRight:"0.5rem"} : {color: "", backgroundColor: "", width:"4.3rem", marginRight:"0.5rem"}}

                                                            >
                                                                <option value="+91" select= "true">+91</option> 
                                                                {(state.CountryInfo).map((singleCountryCode, index) => 
                                                                <option key={index} value={singleCountryCode["CountryCode"]}>
                                                                {singleCountryCode["CountryCode"]}
                                                                {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                                                {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                                                {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                                                {'\xA0'+singleCountryCode["Country"]}</option>)}
                                                            </select>
                                                            <input type="number" className="addCustInputForm" name="workPhoneNo" 
                                                                    value={state.workPhoneNo}
                                                                    onChange={handleChange} 
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                            />
                                                        </span>

                                                        {errors.contactNo.length > 0 && 
                                                            <span  className='addCustError'>{errors.contactNo}</span>}  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled"> Address Line1:<span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                <input type="text" className=" addCustInputForm" name="address1" 
                                                    value={state.address1}
                                                    onChange={handleChange} 
                                                    // required = "required"
                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                />
                                                    {errors.address1.length > 0 && 
                                                        <span  className='addCustError'>{errors.address1}</span>}     
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">Address Line2:</label>
                                                <div className="addCustInputAndError">
                                                    <input type="text" className=" addCustInputForm" name="address2"   
                                                        value={state.address2}
                                                        onChange={handleChange}
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                    />
                                                    {errors.address2.length > 0 && 
                                                        <span  className='addCustError'>{errors.address2}</span>}  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">Country:<span className="addCustRequiredMarkStar">*</span></label>    
                                                <div className="addCustInputAndError">
                                                    <select className="addCustInputForm" 
                                                        type = "text"
                                                        name="country"
                                                        required
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        value={state.country}
                                                        onChange={onChangeCountry}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0"} : {color: "", backgroundColor: ""}}
                                                    >
                                                        <option value="" select="true" >Select...</option> 
                                                        {(state.ArrCountryNames).map((singleCountryName, index) => 
                                                            <option key={index} value={singleCountryName}>
                                                            {singleCountryName}</option>)}
                                                    </select>
                                                    {errors.country.length > 0 && 
                                                        <span  className='addCustError'>{errors.country}</span>}    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">State:<span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                    {(state.ArrStateNames == null || state.ArrStateNames.length <= 0)
                                                    ?
                                                        <input type="text" className="addCustInputForm" name="state"
                                                            required   
                                                            value={state.state}
                                                            onChange={onChangeState} 
                                                            readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                            style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        />
                                                    :
                                                        <select type="text" className="addCustInputForm" name="state"
                                                            required           
                                                            value={state.state}
                                                            onChange={onChangeState} 
                                                            readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                            style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        >
                                                            <option value="" select="true" >Select...</option> 
                                                            {(state.ArrStateNames).map((SingleStateName, index) => 
                                                            <option key={index} value={SingleStateName}>
                                                            {SingleStateName}</option>)}
                                                        </select>
                                                    }

                                                    {errors.state.length > 0 && 
                                                        <span  className='addCustError'>{errors.state}</span>}    
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{marginBottom: "0.5rem", border: "0.1rem solid #a8cca8", backgroundColor:"#F8F8F8", borderRadius:"0.7rem",}}>
                                        {state.formViewMode == "viewMode" ? <label style={{display: "flex", justifyContent:"flex-start", fontSize:"0.7rem", marginLeft:"0.5rem"}}></label> :
                                            <label style={{ display: "flex", justifyContent:"flex-start", fontSize:"0.7rem", marginLeft:"0.5rem"}}><span style={{color:"var(--errorColor)", fontSize:"1rem", marginRight:"0.2rem"}}> * </span> Please enter at least your City or District.</label>
                                        }
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">District:</label>
                                                <div className="addCustInputAndError">
                                                    <input type="text" className=" addCustInputForm" name="district"   
                                                        value={state.district}
                                                        onChange={handleChange} 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        
                                                    />
                                                    {errors.district.length > 0 && 
                                                        <span  className='addCustError'>{errors.district}</span>} 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">City:</label>
                                                <div className="addCustInputAndError">
                                                    <input type="text" className=" addCustInputForm" name="city"   
                                                        value={state.city}
                                                        onChange={handleChange} 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                    />
                                                    {errors.cityAndDistrict.length > 0 && 
                                                    <span  className='addCustError'>{errors.cityAndDistrict}</span>} 
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">Pincode:<span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                    <input type="text" className=" addCustInputForm" name="pinCode"   
                                                        value={state.pinCode}
                                                        onChange={handleChange} 
                                                        // onKeyPress= {handleKeyPress}
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        //pattern = '\d*'
                                                        //maxlength="10"    
                                                    />
                                                    {errors.pinCode.length > 0 && 
                                                        <span  className='addCustError'>{errors.pinCode}</span>}  
                                                </div>
                                            </div>
                                        </div>    
                                        <div className="form-group addCustForm" style={{marginBottom: ""}}>
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">Comments:</label>
                                                <div className="addCustInputAndError">
                                                    <textarea className=" addCustInputForm" name="comments"   
                                                        value={state.comments}
                                                        onChange={handleChange} 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0"} : {color: "", backgroundColor: ""}}
                                                    />
                                                    {errors.comments.length > 0 && 
                                                        <span  className='addCustError'>{errors.comments}</span>}  
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                            <div>
                                                <button type="button" className="addCustSavebtn" 
                                                    onClick={closeModal} name="Back" 
                                                    style={{pointerEvents: "auto"}}
                                                > 
                                                Back</button>
                                            </div >
                                            <div style={{ display: `${state.formViewMode == "viewMode" ? "none" : "block"}` }}>
                                                <button type="button" className="addCustSavebtn"  name="Save" onClick={insertOrUpdateOrganizationInfo}>
                                                Save</button>
                                            </div>
                                        </div>
                                        <div className = "buttonErrorMessage">
                                            {errors.others.length > 0 && 
                                                <p  className='addCustError' style={{textAlign: "center"}}>{errors.others}</p>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
        </Modal>
    </div>

    );
}

export default VcOrganization;