import React, { useState, useEffect, useContext } from 'react';
import { AppRelevantDataContext } from '../../AppContext';
import axios from 'axios';
import '../CSS/SingleDeviceData.css';
import { TfiFilter } from "react-icons/tfi";
import { AQI, CAQI, CO, O3, LPG, SMOKE, CH4, CO2, HUM, NO2, VOC, PM10, PM25, TEMP, FIRE, LAT, LON, PM1, NH3, NH4, H2S, ALCO, SO2, NH3OD, MRI, VRI,
    LOW, LOW_SEVERE_L, LOW_SEVERE_U, GOOD_L, GOOD_U, SATISFACTORY_L, SATISFACTORY_U, MODERATE_L, MODERATE_U, POOR_L, POOR_U, V_POOR_L, V_POOR_U,
    HIGH_SEVERE_L, HIGH_SEVERE_U, MAX, NO_DEVC_FOUND_FOR_LOGGED_IN_USER, ALERT_BASED, NO_CALIB, ONWARDS, DISP_LOW_SEVERE_RANGE, DISP_SATISFACTORY_RANGE, 
    DISP_GOOD_RANGE, DISP_MODERATE_RANGE, DISP_POOR_RANGE, DISP_VERY_POOR_RANGE, DISP_HIGH_SEVERE_RANGE, DEVICE_TYPE_PFC, UPTO, POSITION_PRIORITY, PROD_DEVC_OWNER_USER_ID,
    LOC, TTS, LAS, SOA, PFC, WLIP, TCWL, VOL, AWL, AWC, ACTIVE_STATUS_MINIMUM_TIME, SLIP, WLI, STLI, PEOPLE_COUNTER, WATER_LEVEL_INDICATOR, SEPTIC_TANK_LEVEL_INDICATOR,
    HCHO, O2, PM100, NO, CL, CH3SH, NOISE, LUX, UV, RADON, AP, WS, WD, DLV, RNFL,
    LOW_V_POOR_L,
    LOW_V_POOR_U,
    DISP_LOW_VERY_POOR_RANGE, PARAM_FOR_SHOWING_IMPACTS
} from '../../VcConstants';
import { getAPIHostURL, getLPGSingleDeviceDataRefreshTime, getSingleDeviceDataRefreshTime } from '../../ClientConfig';
import { FaSadTear, FaFrownOpen, FaGrinBeam, FaDizzy, FaMeh, FaSmile, FaGraduationCap, FaList, FaFunnelDollar, FaThermometer, FaCopy, FaFireAlt, FaVirus, FaGasPump, FaWater, FaHandHoldingWater, FaFilter, FaLongArrowAltUp, FaMoon, FaSun, FaCloudSun} from 'react-icons/fa';
import { GiPoisonGas } from 'react-icons/gi';
import { WiFire } from 'react-icons/wi';
import { FiInfo } from "react-icons/fi";
import { convertLocalDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateWithFormatDDMMMYY, convertLocalDateToDisplayToday, convertUTCDateToStrYYYYMMDDHH24MMSS } from '../../vtUtil';
import { IDS_nitrogenDioxide, IDS_Ozone, IDS_sulphurDioxide, IDS_TVOC, IDS_carbonMonoxide, IDS_Ammonia, IDS_carbonDioxide, 
    IDS_hydrogenSulphide, IDS_Methane, IDS_LPG, IDS_SMOKE, IDS_Dust, IDS_Temperature, IDS_Humidity, IDS_Odour, IDS_PPB, IDS_PPM, 
    IDS_Celsius, IDS_Percent, IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_SrvrIssueDevcParamsNotRec, IDS_DevcNoDataOnSrvr, 
    IDS_BarelySensed, IDS_WeakOdour, IDS_EasilyRecognized, IDS_EasilyRecognizedInSingleDevcData, IDS_StrongOdour, IDS_Pungent, 
    IDS_SDDNH3ODLevel1, IDS_SDDNH3ODLevel2, IDS_SDDNH3ODLevel3, IDS_SDDNH3ODLevel4, IDS_SDDNH3ODLevel5, IDS_SDDNH3ODLevel6, IDS_ReplaceDateTostrToday, 
    IDS_SDD_NoGasLeakage, IDS_SDD_NoSmokeDetected, IDS_SDD_GasLeakageDetected, IDS_SDD_SmokeDetected, IDS_TodaySearch, IDS_LastDataReceivedAt, 
    IDS_LPGLeakDtTm, IDS_SmokeDetectDtTm, IDS_PossibleFire, IDS_RiskOfFlammation, IDS_NoPossibilityOfFire, IDS_NoRiskOfFlammation, 
    IDS_GeneralUser, IDS_Good, IDS_Satisfactory, IDS_Moderate, IDS_Poor, IDS_Severe, IDS_NormalRange, IDS_ParamsRange,
    IDS_DetailsAndRangesOfSensor, IDS_ParameterValue, IDS_DetailsAndRanges, IDS_Onwards, IDS_AlertLevel1, IDS_AlertLevel2, 
    IDS_AlertLevel3, IDS_AlertLevel4, IDS_AlertLevel5, IDS_NoRange, IDS_RangeLowSevere, IDS_RangeHighSevere, IDS_RangeVeryPoor,IDS_RangeLowVeryPoor,
    IDS_Comfortable, IDS_Hot, IDS_ColdAndChilly, IDS_Humid, IDS_Sticky, IDS_Dry, IDS_Warm, IDS_BarelySensedInSingleDeviceData,
    IDS_WeakOdourInSingleDeviceData, IDS_EasilyRecognizedInSingleDeviceData, IDS_StrongOdourInSingleDeviceData,
    IDS_PungentInSingleDeviceData, IDS_TodayPeopleCount, IDS_DefInfoMsgForTreeNodeInDevcPg, IDS_Search, 
    IDS_InfoMsgForTreeNodeWhileChildDevicesMissingInDevcPg, IDS_InfoMsgForTreeNodeWhileChildNodesMissingInDevcPg, 
    IDS_RefreshData, IDS_InfoMsgForTreeNodeMissingInDevcPg, IDS_VRI, IDS_MRI, IDS_Index, IDS_Upto, IDS_WhatIsVirusRiskIndex, 
    IDS_WhatIsMouldRiskIndex, IDS_NoOfPpl, IDS_DevcBlockedDueToNonPaymentOfSomeCost, IDS_NoOfSeats, IDS_TotalOccupancyAvgPerDay, IDS_TotalNoOfToiletSeats, IDS_LiveAvailableSeats,
    IDS_LiveOccupiedSeatsPercent, IDS_PFC, IDS_TotalSeats, IDS_OccupiedSeats, IDS_AvailableSeats, IDS_SeatAverage,
    IDS_WaterLevelIndication, IDS_TotalUsedWater, IDS_AvailableWater, IDS_TankCapacity, IDS_Yesterday_s, IDS_WaterUsagePerUser,
    IDS_TotalWaterUsage, IDS_TotalPeopleCount, IDS_Litre_Short, IDS_SewageLevelIndication, IDS_PrevBtn, IDS_NxtBtn, IDS_airQualityIndex, IDS_currentAirQualityIndex, IDS_SevereAqi, IDS_VeryPoor, IDS_PoorAqi, IDS_VeryPoorAqi, IDS_ProminentPollutant, IDS_vilisoAqiMsg, IDS_SatisfactoryOdour, IDS_AlertLevel6, IDS_SatisfactoryInSingleDeviceData,
    IDS_HygieneMsgGood, IDS_HygieneMsgModerate, IDS_HygieneMsgPoor, IDS_HygieneMsgVPoor, IDS_HygieneMsgSevere, IDS_Parameter, IDS_Track, IDS_TrackOnMap,
    IDS_Formaldehyde, IDS_Oxygen, IDS_PM100, IDS_NitricOxide, IDS_Chlorine, IDS_MethylMercaptan, IDS_Noise, IDS_LightIntensity, IDS_UV, IDS_Radon, IDS_AirPressure, IDS_WindSpeed, IDS_WindDirection, IDS_DaylightVisibility, IDS_Rainfall,
    IDS_North, IDS_Northeast, IDS_East, IDS_Southeast, IDS_Southwest, IDS_South, IDS_West, IDS_Northwest,
    IDS_LightRain, IDS_ModerateRain, IDS_HeavyRain, IDS_VeryHeavyRain, IDS_TorrentialRain, IDS_GoodVisibility, IDS_ModerateVisibility, IDS_FairVisibility, IDS_PoorVisibility, IDS_CalmBreeze, IDS_ModerateBreeze, IDS_StrongGale, IDS_Storm, IDS_LowLightIntensity, IDS_MediumLightIntensity, IDS_HighLightIntensity, IDS_LowNoiseLevel, IDS_MediumNoiseLevel, IDS_HighNoiseLevel,
    IDS_ExtremeLow, IDS_Low, IDS_High, IDS_ExtremeHigh,
    IDS_Normal, IDS_Extreme, IDS_VeryHigh, IDS_VeryLow, IDS_Litre, IDS_LowSevere, IDS_PeopleCount, IDS_NoRain, IDS_GentleBreeze,
    IDS_Weak,
    IDS_Strong,
    IDS_OdourLevel
} from '../../VcLanguage';
import VcSetupDevice from './VcSetupDevice';
import {Modal, ModalHeader, ModalBody, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import VcVRIandMRIInfo from './VcVRIandMRIInfo';
import ReactSpeedometer from "react-d3-speedometer";
import VcSimpleBarChart from './DeviceHome/Charts/VcSimpleBarChart';
import VcDailyChart from "./DeviceHome/Charts/VcDailyChart";
import { TbAlertTriangle, TbWind } from 'react-icons/tb';
import { HiOutlineViewGrid } from 'react-icons/hi';
import VcWLIc from './DeviceHome/VcWLIc';
import VcPFC from './DeviceHome/VcPFC';
import MapCrop from './../IMAGES/MapCrop.png'
import { useNavigate } from 'react-router-dom';
import VcInfoBox from './DeviceHome/VcInfoBox';
import VcAQIBox from './DeviceHome/VcAQIBox';
import { setToSearchDeviceIDOnMap } from './Store/Slices/variables';
import { useDispatch } from 'react-redux';
import VcLoader from './DeviceHome/Charts/VcLoader';
import useInterval from './useInterval';
import VcDetailsAndRanges from './VcDetailsAndRanges';
      
function VcSingleDeviceData (props) {
    const navigate = useNavigate();
    const context = useContext(AppRelevantDataContext);
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t; 
    const [state, setState] = useState({  // Will be refreshed from context
        selectedTreeNodeID: '', 
        parentIdOfSelectedNodeDevc: '', 
        isRootSelected: true, // Initially AllDeviceData will be rendered
        selectedTreeNodeTitle: '',  
        LoggedInUserID: '',
        SingleDeviceData: [],
        relevantModelInfo: [],
        arrReceivedStdAirQualityRange: [],
        SingleDeviceParamValue: [],
        SingleDeviceParamSequence: [],
        EntityLocationIDOfSelectedDevc: "",
        Range: "AllRange",
        stdAirQualityRanges: {}, // Easier to access individual parame from JSON object
        errors: {
            others: 'Please wait. Fetching Details for the Device from the server.'
        },
        Good:[],
        Satisfactory:[],
        LowVeryPoor: [],
        Moderate: [],
        Poor:[],
        VeryPoor:[],
        Severe: [],
        Others: [],
        nullValue:[],
        AlertBasedParam: [],
        PositionProrityParam: [],
        LogTime: "",
        receivedDeviceModelInfo: [],
        singleDeviceImportantAlertInfo: [],
        modalFromIcon: false,
        backdrop: 'static',
        backdropForInfoButton: 'static',
        modalFromInfoButton: false,
        paramToShowDetailedInfo: "",
        rangeToDisplay: '',
        totalTodaysPeopleCount: null,
        totalTodaysPeopleOccupancyPercent: null,
        selectedParamToViewRangeDetails: '',
        selectedParamsCurrentValue: '',
        styleToBeAppliedToPopup: '',
        highSevereStartValue: '',
        languageToViewIn: '',
        highSevereStartValueInPopUp: '',
        arrOfRanges: [],
        arrOfDispRangeValues: [],
        showNormalRange: '',
        goodEndValueInPopUp: '',
        arrTodaysPplCount: [],
        bSelectedNodeContainsDevcToShowData: false,
        isDeviceBlocked: false,
        selectedTreeNodeIsDevice: false,
        bCheckDeviceBlockedStatus: false,
        havingPvgToViewBlockedDeviceData: false,
        PfcParamModelInfo: [],
        receivedModelInfoForPFC: [],
        waterLevel: 0,
        sewageLevel: 0,
        volumeOfWaterTank: 0,
        availableWater: 0,
        consumedWater: 0,
        isPfcDevicePresentAsSibling: false,
        arrDeviceIDsForPFC: [],
        arrDeviceIDsForWLI: [],
        arrDeviceIDsForHHM: [],
        totalPeopleCount: 0,
        LoggedInUserID: '',
        pfcDeviceValues: [],
        resultOfWLI: [],
        yestTotalWaterUsage: 0,
        waterUsagePerUser: 0,
        bDeviceTypeWLI: false,
        bDeviceTypeSTLI: false,
        odourLevel: null,
        dailyChartFlag: false,
        chartAPICall: false,
        showImpactsOfParam: false
    });

    const [lastHourData, setlastHourData] = useState(null)
    const [listView, setlistView] = useState(false)
    const dispatch = useDispatch()
    // Interval is cleared whenever flag changes
    // If flag is set true then interval is created
    const [getDeviceDataInterval, setGetDeviceDataInterval] = useState(false) 
    const [getCrossingCountDataInterval, setGetCrossingCountDataInterval] = useState(false) 
    const [siblingsDataInterval, setSiblingsDataInterval] = useState(false) 
    
    let crossint;
    let devicedataInt;
    let siblingsDataInt;

    const clearIntervals = () => {
        if (siblingsDataInterval) {
            setSiblingsDataInterval(false)
        }
        if (getDeviceDataInterval) {
            setGetDeviceDataInterval(false)
        }
        if (getCrossingCountDataInterval) {
            setGetCrossingCountDataInterval(false);
        }
    }

    useEffect(() => {
        if(getCrossingCountDataInterval){
            crossint = setInterval(
                () => { getCrossingCountInfoForSelectedNodeId()},
                getSingleDeviceDataRefreshTime() 
            )
        }
      return () => {
        clearInterval(crossint)
        }
    }, [getCrossingCountDataInterval, state.selectedTreeNodeID])

    // Written a custom hook (useInterval) to make sure updated state is always used for setInterval.
    useInterval(() => {
        dbGetLatestDeviceData()
    }, getSingleDeviceDataRefreshTime())


    // The below useInterval() fetches device data after every 3 seconds
    // This is implemented for testing purpose of LPG model or for any model consisting 'LPG' parameter
    useInterval(() => {
        if(state.SingleDeviceParamSequence != null && state.SingleDeviceParamSequence.length == 1 && state.SingleDeviceParamSequence[0] == LPG) {
            dbGetLatestDeviceData();
        } else {
            return; // No further processing required
        }
    }, getLPGSingleDeviceDataRefreshTime())

 
    // useEffect(() => {
    //     if(getDeviceDataInterval || context.selectedNodeInfo.SelectedNodeDeviceType != ""){
    //             devicedataInt = setInterval(
    //                 () => { 
    //                  dbGetLatestDeviceData(); },
    //                 // getSingleDeviceDataRefreshTime() 
    //                 10000
    //             )
    //     } 

    //   return () => {
    //     clearInterval(devicedataInt)
    //   }
    // }, [getDeviceDataInterval , state.selectedTreeNodeID, context.selectedNodeInfo.SelectedNodeDeviceType])

    // useEffect(() => {
    //     if(siblingsDataInterval){
    //         siblingsDataInt = setInterval(
    //             () => { getSiblingsOfTheSelectedNode() },
    //             getSingleDeviceDataRefreshTime() 
    //         )
    //     }
    //   return () => {
    //     clearInterval(siblingsDataInt)
    //   }
    // }, [siblingsDataInterval, state.selectedTreeNodeID])
    

    // Called when Icon for More (Triple Dot Icon) is clicked.
    const toggleFromIcon = (singleParam, singleValue) => {
        let modifiedState = state;
        modifiedState.arrOfRanges = [];
        modifiedState.arrOfDispRangeValues = [];
        modifiedState.modalFromIcon = !state.modalFromIcon;
        modifiedState.selectedParamToViewRangeDetails = singleParam;
        modifiedState.selectedParamsCurrentValue = singleValue;

        let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(modifiedState.selectedParamsCurrentValue, modifiedState.selectedParamToViewRangeDetails);
        modifiedState.styleToBeAppliedToPopup = styleValueBox;

        let arrOfRangesOfSelectedParam = modifiedState.stdAirQualityRanges[modifiedState.selectedParamToViewRangeDetails] != null ? 
                                         modifiedState.stdAirQualityRanges[modifiedState.selectedParamToViewRangeDetails]['DispRngSeq'] : [] ;

        if(arrOfRangesOfSelectedParam != null && arrOfRangesOfSelectedParam.length > 0){
            arrOfRangesOfSelectedParam.forEach((childSnapshot) => {
                modifiedState.arrOfRanges.push(Object.keys(childSnapshot)[0]);
                modifiedState.arrOfDispRangeValues.push(Object.values(childSnapshot)[0]);
            });
        }   

        // show Details and Ranges old or new model based on if impacts for those params are present.
        // PARAM_FOR_SHOWING_IMPACTS contains all the parameters which have impacts.
        for(let i=0; i<PARAM_FOR_SHOWING_IMPACTS.length; i++) {
              
            if(PARAM_FOR_SHOWING_IMPACTS[i] == modifiedState.selectedParamToViewRangeDetails) {
                modifiedState.showImpactsOfParam = true;
                break;           
            } else {
                modifiedState.showImpactsOfParam = false;
            }
        }

        setState({...modifiedState});
    }

    // Called when Info Button is clicked.
    const toggleFromInfoButton = (shortNameOfParam) => {
        let modifiedState = state;
        modifiedState.modalFromInfoButton = !state.modalFromInfoButton;    
        modifiedState.paramToShowDetailedInfo = shortNameOfParam;                          
        
        setState({...modifiedState});
    }

    const onChangeSelectedQuickTrackParam = (param) => {
        let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
        appRelevantDataContextValue.onChangeQuickTrackParam(param);
    }

    const getDirectionName = (degrees, fullForm = null) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  
        if(degrees > 360 || degrees < 0) {
            return ""
        }

        if(fullForm == null) {
            if (degrees >= 337.5 || degrees < 22.5) {
                return "N";
            } else if (degrees >= 22.5 && degrees < 67.5) {
                return "NE";
            } else if (degrees >= 67.5 && degrees < 112.5) {
                return "E"
            } else if (degrees >= 112.5 && degrees < 157.5) {
                return "SE"
            } else if (degrees >= 157.5 && degrees < 202.5) {
                return "S"
            } else if (degrees >= 202.5 && degrees < 247.5) {
                return "SW"
            } else if (degrees >= 247.5 && degrees < 292.5) {
                return "W"
            } else if (degrees >= 292.5 && degrees < 337.5) {
                return "NW"
            } else {
                return "";
            }
        }
       
        if (degrees >= 337.5 || degrees < 22.5) {
            return t(IDS_North);
        } else if (degrees >= 22.5 && degrees < 67.5) {
            return t(IDS_Northeast);
        } else if (degrees >= 67.5 && degrees < 112.5) {
            return t(IDS_East)
        } else if (degrees >= 112.5 && degrees < 157.5) {
            return t(IDS_Southeast)
        } else if (degrees >= 157.5 && degrees < 202.5) {
            return t(IDS_South);
        } else if (degrees >= 202.5 && degrees < 247.5) {
            return t(IDS_Southwest)
        } else if (degrees >= 247.5 && degrees < 292.5) {
            return t(IDS_West)
        } else if (degrees >= 292.5 && degrees < 337.5) {
            return t(IDS_Northwest)
        } else {
            return "";
        }
    }
    
    const getUnitsBasedOnParamtype = (inParamType, singleValue = null) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;          
        let singleDeviceMeasuredParams = JSON.parse(state.SingleDeviceData[0].MeasuredParams);

        let retUnit = '';
        switch(inParamType) {
            case NO2:
            case O3:
            case SO2:
            case VOC:
            case H2S:
            case HCHO:
            case CL:
            case CH3SH:
                retUnit = <small>{ t(IDS_PPB) }</small>;
                break;
            case CO:
            case NH3:
            case CO2:
            case NH3OD:  
            case NO:
            case NH3:  
                retUnit = <small>{ t(IDS_PPM) }</small>;
                break;
            case PM1:
            case PM25:
            case PM10:
            case PM100:
                retUnit = <small>㎍/m<sup>3</sup></small>;
                break;
            case TEMP:
                retUnit = <small>{ t(IDS_Celsius) }</small>;
                break;
            case HUM:
                retUnit = <small>{ t(IDS_Percent) }</small>;
                break;
            case MRI:
            case VRI:
            case UV:
                retUnit = <small>{ t(IDS_Index) }</small>;
                break;
            case AWC:
                retUnit = <small>L</small>;
                break;
            case AQI:
                // retUnit = singleValue ? <small>{(singleDeviceMeasuredParams.PPAQI) ? singleDeviceMeasuredParams.PPAQI : "--"}</small>
                //         :<small>--</small>;
                retUnit = singleValue ? <small>{(singleDeviceMeasuredParams.PPAQI) ? singleDeviceMeasuredParams.PPAQI : ""}</small>
                        :<small/>;
                break;
            case CAQI:
                // retUnit = singleValue ? <small>{(singleDeviceMeasuredParams.PPCAQI) ? singleDeviceMeasuredParams.PPCAQI : "--"}</small>
                //         :<small>--</small>;
                retUnit = singleValue ? <small>{(singleDeviceMeasuredParams.PPCAQI) ? singleDeviceMeasuredParams.PPCAQI : ""}</small>
                        :<small/>;
                break;
            case SLIP:
            case O2:
            case CH4:
                retUnit = '%'
                break;
            case NOISE:
                retUnit = 'dB';
                break;
            case LUX:
                retUnit = 'lux';
                break;
            case RADON:
                retUnit = 'Bq/m³';
                break;
            case AP:
                retUnit = 'hPa';
                break;
            case WS:
                retUnit = 'm/s';
                break;
            case WD:
                // if(singleValue) {
                //     retUnit = getDirectionName(singleValue)
                    retUnit = '°';
                // }
                break;
            case DLV:
                retUnit = 'km';
                break;
            case RNFL:
                retUnit = 'mm';
                break;

            default:
                // console.error(`Unable to get Units. Unknown Param Type: ${inParamType}`);
                retUnit = '';
                break;
        }
        
        return retUnit;
    }


    // Function to get units in text format for charts
    const getUnits = (inParamType) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let retUnit = ''  
        switch(inParamType) {
            case AQI:
            case CAQI:
            case MRI:
            case VRI:
            case UV:
                retUnit = t(IDS_Index);
                break;
            case NO2:
            case O3:
            case SO2:
            case VOC:
            case H2S:
            case HCHO:
            case CL:
            case CH3SH:
                retUnit =  t(IDS_PPB);
                break;
            case CO:
            case NH3:
            case CO2:
            case PM100:
            case NO:
            case NH3:
                retUnit = t(IDS_PPM);
                break;
            case NH3OD: 
                retUnit = `(${t(IDS_OdourLevel)})`
                break;
            case PM1:
            case PM25:
            case PM10:
            case PM100:
                retUnit = '㎍/m³';
                break;
            case TEMP:
                retUnit = `${'\u00B0'}C`
                break;
            case HUM:
            case SLIP:
            case O2:
            case CH4:
                retUnit =  '%';
                break;
            case AWC:
            case WLIP:
                retUnit = t(IDS_Litre)
                break;
            case PFC:
                retUnit = t(IDS_PeopleCount)
                break;
            case NOISE:
                retUnit = 'dB';
                break;
            case LUX:
                retUnit = 'lux';
                break;
            case RADON:
                retUnit = 'Bq/m³';
                break;
            case AP:
                retUnit = 'hPa';
                break;
            case WS:
                retUnit = 'm/s';
                break;
            case WD:
                retUnit = '°';
                break;
            case DLV:
                retUnit = 'km';
                break;
            case RNFL:
                retUnit = 'mm';
                break;
            default:
                // console.error(`Unable to get Units. Unknown Param Type: ${inParamType}`);
                retUnit = '';
                break;
        }
        
        return retUnit;
    }

    const renderParamSymbolBasedOnType = (inSymbolType) => {
        let symbolStyle = {
            className: '',
            style: {
                fontSize:'0.8rem', 
                fontWeight:"bold",
                color: "#0d6efd",
                // color: "#303597",
            }
        }

        switch(inSymbolType) {
            case AQI:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >AQI</span>);
            case CAQI:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >Viliso-AQI</span>);
            case NO2:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >NO<sub className="">2</sub></span>);
            case O3:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >O<sub className="">3</sub></span>);
            case SO2:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >SO<sub className="">2</sub></span>);
            case VOC:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >TVOC</span>);
            case CO:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >CO</span>);
            case NH3:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >NH<sub className="">3</sub></span> );
            case CO2:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >CO<sub className="">2</sub></span>);
            case H2S:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >H<sub className="">2</sub>S</span>);
            case CH4:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >CH<sub className="">4</sub></span>);
            case LPG:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >LPG</span>);
            case SMOKE:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >{t(IDS_SMOKE)}</span>);
            case PM1:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >PM<sub className="">1</sub></span>);
            case PM25:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >PM<sub className="">2.5</sub></span>);
            case PM10:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >PM<sub className="">10</sub></span> );
            case TEMP:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >Temp<sup className="">o</sup></span>);
            case HUM:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >Hum<sup className="">%</sup></span>);
            case NH3OD:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >Odour</span>);  
            case MRI:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >Mould</span>);
            case VRI:
                return (<span  className={symbolStyle.className} style={symbolStyle.style} >Virus</span>);
            case WLIP: 
                return (<span className="">{t(IDS_WaterLevelIndication)}</span>);
            case VOL:
                return (<span className="NameOfPFCParameterHeading">{t(IDS_TankCapacity)}</span>);
            case AWL:
                return (<span className="NameOfPFCParameterHeading">{t(IDS_AvailableWater)}</span>);
            case SLIP:
                return (<span className="">{t(IDS_SewageLevelIndication)}</span>);
            case O2:
                return (<span className={symbolStyle.className} style={symbolStyle.style}>O<sub className="">2</sub></span>);
            case PM100:
                return (<span className={symbolStyle.className} style={symbolStyle.style}>PM<sub className="">100</sub></span>);
            case CH3SH:
                return (<span className={symbolStyle.className} style={symbolStyle.style}>CH<sub className="">3</sub>SH</span>);
            case CL:
            case NO:
            case HCHO:
            case LUX:
            case UV:
            case AP:
            case WS:
            case WD:
            case DLV:
            case RNFL:
                return (<span className={symbolStyle.className} style={symbolStyle.style}>{inSymbolType}</span>);
            case RADON:
                return (<span className={symbolStyle.className} style={symbolStyle.style}>{t(IDS_Radon)}</span>);
            case NOISE:
                return (<span className={symbolStyle.className} style={symbolStyle.style}>{t(IDS_Noise)}</span>);

            default:
                // console.error(`Unable to get Symbol. Unknown Param Type: ${inSymbolType}`);
                return (<span></span>); // Return empty span
        }
    }

    

    const renderParamNameBasedOnType = (inParamNameType) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 
        switch (inParamNameType) {
            case AQI:
                return (<span className="airQualityParamName">{t(IDS_airQualityIndex)}</span>);
            case CAQI:
                return (<span className="airQualityParamName">{t(IDS_currentAirQualityIndex)}</span>);
            case NO2:
                return (<span className="airQualityParamName">{t(IDS_nitrogenDioxide)}</span>);
            case O3:
                return (<span className="airQualityParamName">{t(IDS_Ozone)}</span>);
            case SO2:
                return (<span className="airQualityParamName">{t(IDS_sulphurDioxide)}</span>);
            case VOC:
                return (<span className="airQualityParamName">{t(IDS_TVOC)}</span>);
            case CO:
                return (<span className="airQualityParamName">{t(IDS_carbonMonoxide)}</span>);
            case NH3:
                return (<span className="airQualityParamName">{t(IDS_Ammonia)}</span>);
            case CO2:
                return (<span className="airQualityParamName">{t(IDS_carbonDioxide)}</span>);
            case H2S:
                return (<span className="airQualityParamName">{t(IDS_hydrogenSulphide)}</span>);
            case CH4:
                return (<span className="airQualityParamName">{t(IDS_Methane)}</span>);
            case PM1:
            case PM25:
            case PM10:
            case PM100:
                return (<span className="airQualityParamName">{t(IDS_Dust)}</span>);
            case TEMP:
                return (<span className="airQualityParamName">{t(IDS_Temperature)}</span>);
            case WLIP:
                return (<span className="airQualityParamName">{t(IDS_WaterLevelIndication)}</span>);
            case SLIP:
            case STLI:
                return (<span className="airQualityParamName">{t(IDS_SewageLevelIndication)}</span>);
            case HUM:
                return (<span className="airQualityParamName">{t(IDS_Humidity)}</span>);
            case NH3OD:
                return (<span className="airQualityParamName">{t(IDS_Odour)}</span>);
            case MRI:
                return (<span className="airQualityParamName">{t(IDS_MRI)}</span>);
            case VRI:
                return (<span className="airQualityParamName">{t(IDS_VRI)}</span>);
            case LPG:
                return (<span className="airQualityParamName">{t(IDS_LPG)}</span>);
            case SMOKE:
                return (<span className="airQualityParamName">{t(IDS_SMOKE)}</span>);
            case HCHO:
                return (<span className="airQualityParamName">{t(IDS_Formaldehyde)}</span>);
            case O2:
                return (<span className="airQualityParamName">{t(IDS_Oxygen)}</span>);
            case NO:
                return (<span className="airQualityParamName">{t(IDS_NitricOxide)}</span>);
            case CL:
                return (<span className="airQualityParamName">{t(IDS_Chlorine)}</span>);
            case CH3SH:
                return (<span className="airQualityParamName">{t(IDS_MethylMercaptan)}</span>);
            case NOISE:
                return (<span className="airQualityParamName">{t(IDS_Noise)}</span>);
            case LUX:
                return (<span className="airQualityParamName">{t(IDS_LightIntensity)}</span>);
            case UV:
                return (<span className="airQualityParamName">{t(IDS_UV)}</span>);
            case RADON:
                return (<span className="airQualityParamName">{t(IDS_Radon)}</span>);
            case AP:
                return (<span className="airQualityParamName">{t(IDS_AirPressure)}</span>);
            case WS:
                return (<span className="airQualityParamName">{t(IDS_WindSpeed)}</span>);
            case WD:
                return (<span className="airQualityParamName">{t(IDS_WindDirection)}</span>);
            case DLV:
                return (<span className="airQualityParamName">{t(IDS_DaylightVisibility)}</span>);
            case RNFL:
                return (<span className="airQualityParamName">{t(IDS_Rainfall)}</span>);
            case PFC:
                return (<span className="airQualityParamName">{t(IDS_PeopleCount)}</span>);
    
            default:
                // console.error(`Unable to get ParamName. Unknown Param Type: ${inParamNameType}`);
                return (<span className="airQualityParamName">{inParamNameType}</span>); // Return empty tag with class
        }
        
    }

    const renderPfcParamNameBasedOnType = (inParamNameType) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;          

        switch(inParamNameType) {
            case PFC:
                return (<h6 className="NameOfPfcParameter">{t(IDS_TodayPeopleCount)}</h6>);
            case TTS:
                return (<h6 className="NameOfPfcParameter">{t(IDS_TotalNoOfToiletSeats)}</h6>);
            case LOC:
                return (<h6 className="NameOfPfcParameter">{t(IDS_LiveOccupiedSeatsPercent)}</h6>);
            case LAS:
                return (<h6 className="NameOfPfcParameter">{t(IDS_LiveAvailableSeats)}</h6>);
            case SOA:
                return (<h6 className="NameOfPfcParameter">{t(IDS_TotalOccupancyAvgPerDay)}</h6>);              
            default:
                // console.error(`Unable to get ParamName. Unknown Param Type: ${inParamNameType}`);
                return (<h6 className="NameOfPfcParameter"><br></br></h6>); // Return empty tag
        }
    }
 
    const getParamValueTextAndBoxStyleBasedOnRange = (inParamValue, inParamNameType) => {

        let modifiedState = state;

        let styleValueText = "ParamsValueMissingOrWithoutRange";
        let styleValueBox = "parameterOuterDivWithoutRange";

        let singleParamAirQualityRange = modifiedState.stdAirQualityRanges[inParamNameType];
        // modifiedState.rangeToDisplay = "";   // If need to show Current Range in future.
        modifiedState.highSevereStartValue = "";
        
        if(singleParamAirQualityRange == null) {
            // console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
            // Return Default styles if stdAirQualityRange missing.
            return { styleValueText: styleValueText, styleValueBox: styleValueBox }; 
        }

        let paramHighLowValues = modifiedState.relevantModelInfo[inParamNameType];
        let paramAttributes = modifiedState.relevantModelInfo[inParamNameType];
        let bIsParamNonCalibrated = ( paramAttributes != null
                                      && paramAttributes["NoCalib"] != null
                                    ) ? paramAttributes["NoCalib"] : false;

        let bIsAlertBasedParam = ( paramAttributes != null
                                    && paramAttributes[ALERT_BASED] != null
                                 ) ? paramAttributes[ALERT_BASED] : false;

        let singleParamAirQualityRangeLowSevereL = singleParamAirQualityRange[LOW_SEVERE_L];
        let singleParamAirQualityRangeLowSevereU = singleParamAirQualityRange[LOW_SEVERE_U];
        let singleParamAirQualityRangeLowVPoorL = singleParamAirQualityRange[LOW_V_POOR_L];
        let singleParamAirQualityRangeLowVPoorU = singleParamAirQualityRange[LOW_V_POOR_U];
        let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
        let singleParamAirQualityRangeGoodU = (bIsAlertBasedParam && inParamNameType == SMOKE) ? paramHighLowValues["DefMax"] : singleParamAirQualityRange[GOOD_U];
        let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamAirQualityRangeSatisfactoryU = (bIsAlertBasedParam && inParamNameType == SMOKE) ? paramHighLowValues["DefMax"] : singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
        let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
        let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
        let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
        let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
        let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
        let singleParamAirQualityRangeHighSevereL= (bIsAlertBasedParam && inParamNameType == SMOKE) ? paramHighLowValues["DefMax"] : singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];

        // If need to show Current Value in future.
        // let lowSevereRng = "";
        // let goodRng = "";
        // let modRng = "";
        // let poorRng = "";
        // let veryPoorRng = "";
        // let highSevereRng = "";
        let arrOfRangesToShowRange = [];
        arrOfRangesToShowRange = modifiedState.stdAirQualityRanges[inParamNameType]['DispRngSeq'];
    
        if(arrOfRangesToShowRange != null){
            let objRange = {};
            for(let i=0; i<arrOfRangesToShowRange.length; i++){
                objRange = arrOfRangesToShowRange[i];

                // For showing Normal Range for individual parameter in Single Device Data.
                if(objRange.hasOwnProperty(DISP_GOOD_RANGE)){
                    modifiedState.showNormalRange = objRange.hasOwnProperty(DISP_GOOD_RANGE) ? Object.values(objRange)[0] : "";
                } else {
                    // console.log("Not Found");
                }

                // If in future, we want to display Current Range, this is useful.
                // if(objRange.hasOwnProperty(DISP_LOW_SEVERE_RANGE)){
                //     lowSevereRng = objRange.hasOwnProperty(DISP_LOW_SEVERE_RANGE) ? Object.values(objRange)[0] : "";
                // } else if(objRange.hasOwnProperty(DISP_GOOD_RANGE)){
                //     goodRng = objRange.hasOwnProperty(DISP_GOOD_RANGE) ? Object.values(objRange)[0] : "";
                //     modifiedState.showNormalRange = goodRng;
                // } else if(objRange.hasOwnProperty(DISP_MODERATE_RANGE)){
                //     modRng = objRange.hasOwnProperty(DISP_MODERATE_RANGE) ? Object.values(objRange)[0] : "";
                // } else if(objRange.hasOwnProperty(DISP_POOR_RANGE)){
                //     poorRng = objRange.hasOwnProperty(DISP_POOR_RANGE) ? Object.values(objRange)[0] : "";
                // } else if(objRange.hasOwnProperty(DISP_VERY_POOR_RANGE)){
                //     veryPoorRng = objRange.hasOwnProperty(DISP_VERY_POOR_RANGE) ? Object.values(objRange)[0] : "";
                // } else if(objRange.hasOwnProperty(DISP_HIGH_SEVERE_RANGE)){
                //     highSevereRng = objRange.hasOwnProperty(DISP_HIGH_SEVERE_RANGE) ? Object.values(objRange)[0] : "";
                // } else {
                //     console.log("Not Found");
                // }
            }
        }

        if( inParamValue == null || 
            ( bIsParamNonCalibrated == false && inParamNameType != "PFC" &&
                (inParamValue < paramHighLowValues["RangeMin"] || inParamValue > paramHighLowValues["RangeMax"]) 
            )
        ) {
            if(inParamNameType == DLV && inParamValue != null) {
                styleValueText = inParamValue < paramHighLowValues["RangeMin"] ? "ParamsValueRangeSevere" : "ParamsValueRangeGood";
                styleValueBox = inParamValue < paramHighLowValues["RangeMin"] ? "parameterOuterDivRangeSevere" : "parameterOuterDivRangeGood";
            } else {
                styleValueText = ((inParamNameType == TEMP || inParamNameType == HUM) && 
                                (inParamValue < paramHighLowValues["RangeMin"] || inParamValue > paramHighLowValues["RangeMax"])) ?
                                "ParamsValueRangeSevere" :
                                inParamValue < paramHighLowValues["RangeMin"] ? "ParamsValueRangeGood" :
                                inParamValue > paramHighLowValues["RangeMax"] ? "ParamsValueRangeSevere" :
                                inParamValue == null ? "ParamsValueMissingOrWithoutRange" :
                                "ParamsValueMissingOrWithoutRange";
                styleValueBox = ((inParamNameType == TEMP || inParamNameType == HUM) && 
                                (inParamValue < paramHighLowValues["RangeMin"] || inParamValue > paramHighLowValues["RangeMax"])) ?
                                "parameterOuterDivRangeSevere" :
                                inParamValue < paramHighLowValues["RangeMin"] ? "parameterOuterDivRangeGood" :
                                inParamValue > paramHighLowValues["RangeMax"] ? "parameterOuterDivRangeSevere" : 
                                inParamValue == null ? "parameterOuterDivWithoutRange" :
                                "parameterOuterDivWithoutRange";
            }


            // If in future, we need to show Current Range for Parameteres in Single Device Data.
            // modifiedState.rangeToDisplay = ((inParamNameType == TEMP || inParamNameType == HUM) && inParamValue < paramHighLowValues["RangeMin"]) ? 
            //                                 modifiedState.stdAirQualityRanges[inParamNameType][DISP_LOW_SEVERE_RANGE] :
            //                                ((inParamNameType == TEMP || inParamNameType == HUM) && inParamValue > paramHighLowValues["RangeMax"]) ?
            //                                 modifiedState.stdAirQualityRanges[inParamNameType][DISP_HIGH_SEVERE_RANGE] :
            //                                inParamValue < paramHighLowValues["RangeMin"] ? 
            //                                modifiedState.stdAirQualityRanges[inParamNameType][DISP_GOOD_RANGE] : 
            //                                inParamValue > paramHighLowValues["RangeMax"] ?
            //                                modifiedState.stdAirQualityRanges[inParamNameType][DISP_HIGH_SEVERE_RANGE] : "";    
        } 
        else {
            if( ( singleParamAirQualityRangeLowSevereL == null && inParamValue <= singleParamAirQualityRangeLowSevereU ) ||
                ( singleParamAirQualityRangeLowSevereU == null && inParamValue > singleParamAirQualityRangeLowSevereL ) ||
                ( inParamValue >= singleParamAirQualityRangeLowSevereL && inParamValue <= singleParamAirQualityRangeLowSevereU ) )
            {            
                if (inParamNameType == LUX) {
                    styleValueText = "ParamsValueRangeLSevereLux";
                    styleValueBox = "parameterOuterDivRangeLSevereLux";
                } else {
                    styleValueText = "ParamsValueRangeSevere";
                    styleValueBox = "parameterOuterDivRangeSevere";
                }
                
                // modifiedState.rangeToDisplay = lowSevereRng;     // If need to show Current Range in future.

            } else if( ( singleParamAirQualityRangeLowVPoorL == null && inParamValue <= singleParamAirQualityRangeLowVPoorU ) ||
                ( singleParamAirQualityRangeLowVPoorU == null && inParamValue > singleParamAirQualityRangeLowVPoorL ) ||
                ( inParamValue > singleParamAirQualityRangeLowVPoorL && inParamValue <= singleParamAirQualityRangeLowVPoorU ) )
            {            
                styleValueText = "ParamsValueRangeLowVPoor";
                styleValueBox = "parameterOuterDivRangeLowVPoor";
                // modifiedState.rangeToDisplay = lowSevereRng;     // If need to show Current Range in future.

            } else if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                        ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
                        ( inParamValue >= singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
            {
                
                // modifiedState.rangeToDisplay = goodRng;      // If need to show Current Range in future.
                if (inParamNameType == LUX) {
                    styleValueText = "ParamsValueRangeGoodLux";
                    styleValueBox = "parameterOuterDivRangeGoodLux";
                } else {
                    styleValueText = "ParamsValueRangeGood";
                    styleValueBox = "parameterOuterDivRangeGood";
                }

            } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
                        ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
                        ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
            {
                styleValueText = "ParamsValueRangeSatisfactory";
                styleValueBox = "parameterOuterDivRangeSatisfactory"; 

            }
             else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                        ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                        ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
            {
                styleValueText = "ParamsValueRangeModerate";
                styleValueBox = "parameterOuterDivRangeModerate"; 
                // modifiedState.rangeToDisplay = modRng;       // If need to show Current Range in future.

            } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                        ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                        ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
            {
                styleValueText = "ParamsValueRangePoor";
                styleValueBox = "parameterOuterDivRangePoor"; 
                // modifiedState.rangeToDisplay = poorRng;      // If need to show Current Range in future.

            } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                        ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                        ( inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
            {
                styleValueText = "ParamsValueRangeVeryPoor";
                styleValueBox = "parameterOuterDivRangeVeryPoor";
                // modifiedState.rangeToDisplay = veryPoorRng;      // If need to show Current Range in future.

            } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                        ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                        ( inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
            { 
                if (inParamNameType == LUX) {
                    styleValueText = "ParamsValueRangeHSevereLux";
                    styleValueBox = "parameterOuterDivRangeHSevereLux";
                } else {
                    styleValueText = "ParamsValueRangeSevere";
                    styleValueBox = "parameterOuterDivRangeSevere";
                }

            } 
            // else if( singleParamAirQualityRangeLowSevereL != null && inParamValue <= singleParamAirQualityRangeLowSevereL ) 
            // {            
            //     styleValueText = "ParamsValueRangeSevere";
            //     styleValueBox = "parameterOuterDivRangeSevere";

            // } 
            else {
                // Default black/grey
                styleValueText = "ParamsValueMissingOrWithoutRange";
                styleValueBox = "parameterOuterDivWithoutRange";
                // modifiedState.rangeToDisplay = "";       // If need to show Current Range in future.
            }
        }

        return { styleValueText: styleValueText, styleValueBox: styleValueBox };
    }

    const renderParamOdourLevelIcon = ( inParamValue, inParamNameType) => {

        // if(inParamNameType == NH3OD) {

            // Incase of Sensor value for NH3OD is missing.
            if(inParamValue === "--") {
                return "--";
            }

            let singleParamAirQualityRange = state.stdAirQualityRanges[inParamNameType];

            if(singleParamAirQualityRange == null) {
                console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
                // If singleParamAirQualityRange missing.
                return "--"; 
            }    

            let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
            let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
            let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
            let singleParamAirQualityRangeSatisfactoryU = singleParamAirQualityRange[SATISFACTORY_U];
            let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
            let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
            let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
            let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
            let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
            let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
            let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
            let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];


            if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                        ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
                        ( inParamValue > singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
            {
                return <FaGrinBeam style={{fontSize: '2rem'}}/>

            } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
                        ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
                        ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
            {
                return <FaSmile style={{fontSize: '2rem'}}/>;

            } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                        ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                        ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
            {
                return <FaMeh style={{fontSize: '2rem'}}/>;

            } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                        ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                        ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
            {
                return <FaFrownOpen style={{fontSize: '2rem'}}/>;

            } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                        ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                        (inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
            {
                return <FaSadTear style={{fontSize: '2rem'}}/>;

            } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                        ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                        (inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
            {            
                return <FaDizzy style={{fontSize: '2rem'}}/>;

            } else {

                if( inParamValue < 0 ) {
                    return <FaGrinBeam style={{fontSize: '2rem'}}/>;
                } else {
                    return<FaDizzy style={{fontSize: '2rem'}}/>;
                }
            } 
        // }
    }

    // const renderPFCIconBasedOnParam = (inParamNameType) => {

    //     if(inParamNameType == PFC)
    //     {
    //         return <FaWalking style={{fontSize: '1.7rem'}}/>

    //     } else if(inParamNameType == TTS || inParamNameType == LAS)
    //     {
    //         return <FaToilet style={{fontSize: '1.7rem'}}/>;

    //     } else if(inParamNameType == SOA) {

    //         return <MdAirlineSeatReclineNormal style={{fontSize: '2rem'}}/>;
    //     } else {
    //         return;
    //     }
    // }


    const renderIconForAlertBasedParam = (AlertBasedSingleParam, inParamName, inParamValue) => {

        if(inParamName == null || inParamValue == null || inParamValue == '--') {
            return <div>--</div>; // return div with dash
        }

        let objTextAndBoxStyleForAlertBasedParamValue = getParamValueTextAndBoxStyleBasedOnRange(inParamValue, inParamName);

        if(AlertBasedSingleParam == true ||
           (objTextAndBoxStyleForAlertBasedParamValue["styleValueText"] != "ParamsValueRangeGood" && objTextAndBoxStyleForAlertBasedParamValue["styleValueText"] != "ParamsValueMissingOrWithoutRange" ) 
        ) {

            // If there is an alert for the "AlertBasedParam" in the appropriate interval.
            // Or if the DeviceData has a value which doesn't fall in the good range (obivously the value is not missing).
            // It means that an imp alert situation has happened.
            switch(inParamName) {
                case LPG:
                    return <GiPoisonGas className='iconSize' style={{color: '#C00000'}}/>;
                case SMOKE:
                    return <WiFire className='iconSize'/>;
                default:
                    // console.error(`Unable to get Value icon for specified ParamName. Unknown Param Type: ${inParamName}`);
                    return <div>--</div>; // return div with dash
                }
        } else {

            switch(inParamName) {
                case LPG:
                    return <FaGrinBeam className='iconSize'/>
                case SMOKE:
                    return <FaGrinBeam className='iconSize'/>
                default:
                    // console.error(`Unable to get Value icon for specified ParamName. Unknown Param Type: ${inParamName}`);
                    return <div>--</div>; // return div with dash
            }
        }
    }

    const renderParamOdourLevelTxt = ( inParamValue, inParamNameType ) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  
        if( inParamValue == '--' || inParamValue == null) return '--'
        let singleParamAirQualityRange = state.stdAirQualityRanges[inParamNameType];

        if(singleParamAirQualityRange == null) {
            console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
            // If singleParamAirQualityRange missing.
            return(t(IDS_BarelySensed)); 
        }

        let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
        let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
        let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamAirQualityRangeSatisfactoryU = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
        let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
        let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
        let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
        let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
        let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
        let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];

        if(inParamNameType == NH3OD) {

            if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                    ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
                    ( inParamValue > singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
            {
                return(t(IDS_BarelySensed));

            } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
            ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
            ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
            {
                return(t(IDS_SatisfactoryInSingleDeviceData));

            } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                    ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                    ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
            {
                return(t(IDS_Weak));

            } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                    ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                    ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
            {
                // return(t(IDS_EasilyRecognized));
                return(t(IDS_EasilyRecognizedInSingleDeviceData));

            } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                ( inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
            {
                return(t(IDS_Strong));

            } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                    ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                    ( inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
            { 
                return(t(IDS_PungentInSingleDeviceData));
            } else {
                if(inParamValue < 0 ) {
                    return(t(IDS_BarelySensed));
                } else {
                    return(t(IDS_PungentInSingleDeviceData));
                }
            }
        }    
    }

    const getPFCUnitsBasedOnParamtype = (inParamNameType ) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  

        let retUnit = '';
        switch(inParamNameType) {
            case PFC:
                retUnit = t(IDS_NoOfPpl);
                break;
            case TTS:  
                retUnit = t(IDS_NoOfSeats);
                break;
            case LOC:   
                retUnit = t(IDS_NoOfSeats);
                break;
            case LAS:   
                retUnit = t(IDS_NoOfSeats);
                break;
            case SOA:   
                let todaysDate = new Date();
                let yesterdayDate = todaysDate.setDate(todaysDate.getDate() - 1);
                
                retUnit = convertLocalDateWithFormatDDMMMYY(yesterdayDate);

            break;
            default:
                // console.error(`Unable to get Units. Unknown Param Type: ${inParamNameType}`);
                return (<h6><br></br></h6>); // Return empty tag
            }
        return retUnit;
    }

    const renderOdourLevel = ( inParamValue, inParamNameType ) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  

        if(inParamValue === "--") {
            return "(--)";
        }

        if(inParamNameType == NH3OD) {
            let singleParamAirQualityRange = state.stdAirQualityRanges[inParamNameType];

            if(singleParamAirQualityRange == null) {
                console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
                // If singleParamAirQualityRange missing.
                return "(--)"; 
            }

            let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
            let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
            let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
            let singleParamAirQualityRangeSatisfactoryU = singleParamAirQualityRange[SATISFACTORY_U];
            let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
            let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
            let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
            let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
            let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
            let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
            let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
            let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];


            if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                        ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
                        ( inParamValue > singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
            {
                // return "(Level 1)";
                return t(IDS_SDDNH3ODLevel1, inParamValue);

            } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
                        ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
                        ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
            {
                // return "(Level 2)";
                return t(IDS_SDDNH3ODLevel2, inParamValue);

            } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                        ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                        ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
            {
                // return "(Level 2)";
                return t(IDS_SDDNH3ODLevel3, inParamValue);

            } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                        ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                        ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
            {
                // return "(Level 3)";
                return t(IDS_SDDNH3ODLevel4, inParamValue);


            } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                        ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                        (inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
            {
                // return "(Level 4)";
                return t(IDS_SDDNH3ODLevel5, inParamValue);


            } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                        ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                        (inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
            {            
                // return "(Level 5)";
                return t(IDS_SDDNH3ODLevel6, inParamValue);

            } else {
                if(inParamValue < 0 ) {
                    // return "(Level 1)";
                    return t(IDS_SDDNH3ODLevel1, inParamValue);

                } else {
                    // return "(Level 6)";
                    return t(IDS_SDDNH3ODLevel6, inParamValue);

                }
            }
        }    
    }

    // const renderOdourMessage = (  ) => {
    //     let inParamNameType = NH3OD
    //     let inParamValue = state.SingleDeviceParamValue[NH3OD]
    //     let appRelevantDataContextValue = context;

    //     if(inParamValue === "--") {
    //         return "No data present";
    //     }

    //     if(inParamNameType == NH3OD) {
    //         let singleParamAirQualityRange = state.stdAirQualityRanges[inParamNameType];

    //         if(singleParamAirQualityRange == null) {
    //             console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
    //             // If singleParamAirQualityRange missing.
    //             return '...'
    //             return "No data present";

    //         }

    //         let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
    //         let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
    //         let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
    //         let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
    //         let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
    //         let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
    //         let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
    //         let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
    //         let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
    //         let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];

    //         // if(styleValueText == 'ParamsValueRangeGood') {
    //         //     return '#50c878';
    //         // } else if (styleValueText == 'ParamsValueRangeSatisfactory') {
    //         //     return '#9acd32';
    //         // } else if (styleValueText == 'ParamsValueRangeModerate') {
    //         //     return '#ffff00';
    //         // } else if (styleValueText == 'ParamsValueRangePoor') {
    //         //     return '#ffa500';
    //         // } else if (styleValueText == 'ParamsValueRangeVeryPoor') {
    //         //     return '#ff0000';
    //         // } else if (styleValueText == 'ParamsValueRangeSevere') {
    //         //     return '#c00000'
    //         // } else if (styleValueText == 'ParamsValueMissingOrWithoutRange') {
    //         //     return 'transparent'
    //         // }


    //         if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
    //                     ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
    //                     ( inParamValue > singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
    //         {
    //             return <span className='w-100 '> {t(IDS_HygieneMsgGood)} </span>
    //             return <div className='w-100 rounded px-2 py-1' style={{backgroundColor:"#50c87850"}}>
    //                     {t(IDS_HygieneMsgGood)}
    //                     </div>

    //         } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
    //                     ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
    //                     ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
    //         {
    //             return <span className='w-100 '> {t(IDS_HygieneMsgModerate)} </span>
    //             return <div className='w-100 rounded px-2 py-1' style={{backgroundColor:"#ffff0050"}}>
    //                         {t(IDS_HygieneMsgModerate)} 
    //                     </div>
                
    //         } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
    //                     ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
    //                     ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
    //         {
    //             return <span className='w-100 '> {t(IDS_HygieneMsgPoor)} </span>
    //             return <div className='w-100 rounded px-2 py-1' style={{backgroundColor:"#ffa50050"}}>
    //                         {t(IDS_HygieneMsgPoor)} 
    //                     </div>


    //         } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
    //                     ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
    //                     (inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
    //         {
    //             return <span className='w-100 '> {t(IDS_HygieneMsgVPoor)} </span>
    //             return <div className='w-100 rounded px-2 py-1' style={{backgroundColor:"#ff000050"}}>
    //                         {t(IDS_HygieneMsgVPoor)}
    //                     </div>


    //         } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
    //                     ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
    //                     (inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
    //         {            
    //             return <span className='w-100 '> {t(IDS_HygieneMsgSevere)} </span>
    //             return  <div className='w-100 rounded px-2 py-1' style={{backgroundColor:"#c0000060"}}>
    //                         {t(IDS_HygieneMsgSevere)}
    //                     </div>
                
    //         } else {
    //             if(inParamValue < 0 ) {
    //                 return <span className='w-100 '> {t(IDS_HygieneMsgGood)} </span>
    //             } else {
    //                 return <span className='w-100 '> {t(IDS_HygieneMsgSevere)} </span>
    //             }
    //         }
    //     }    
    // }


    useEffect(() => {
        let appRelevantDataContext = context;  // Get all the relevant data from AppContext
        // Showing loading page to user to avoid unnecessary clicks from the user while fetching data from the server.
        appRelevantDataContext.onChangeProcessingReq(true);

        // Note: No need to start a new 'setInterval' in componentDidUpdate as 'appRelevantDataContextValue'
        // is passed as reference (so it will automatically get updated based on changes in the global context)
        
        // Clear interval if present.
        // if(state.interval != null) {
        //     clearInterval(state.interval);
        // }        
        
        // let isSelectedNodeDevc = appRelevantDataContext.selectedNodeInfo.isDevc;

        // // If the selected node itself is a Device
        // // then show the devicedata of that device on interval (i.e for HHM devc).
        // // Otherwise check the selected node contains any kind of devices and display data based on device type.
        // if(isSelectedNodeDevc){
        //     dbGetLatestDeviceData();
        //     state.interval = setInterval( () => { dbGetLatestDeviceData() }, getSingleDeviceDataRefreshTime() );
        // } else {
        //     // If the selected node has property HasDevice 'true' then this node contains Devices of diff type
        //     // then check whether the selected node associated with Device which has property "DisplayAsParentNodedata".
        //     CheckWhetherSelectedNodeContainsDevcToShowData();
        // }

        CheckWhetherSelectedNodeContainsDevcToShowData();
        return () => {
            // Anything in here is fired on component unmount.
            // clearInterval(state.interval);
            setGetCrossingCountDataInterval(false);
            setGetDeviceDataInterval(false);            
            setSiblingsDataInterval(false);
        }

    }, []);

    useEffect(() => {
        let currentState = state;
        let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext

        if( appRelevantDataContextValue == null ||
            ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.loggedInUserInfo == null ||
            ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
            appRelevantDataContextValue.loggedInUserInfo.userID == null ||
            appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
            ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.selectedNodeInfo == null ||
            ("nodeID" in appRelevantDataContextValue.selectedNodeInfo) == false ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID == null ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID.length <= 0
        ) {
            // console.error("VcSingleDeviceData:componentDidUpdate - Should not happen. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
            return; // Do not process further
        }

        if( currentState.LoggedInUserID != appRelevantDataContextValue.loggedInUserInfo.userID ||
            currentState.selectedTreeNodeID != appRelevantDataContextValue.selectedNodeInfo.nodeID ||
            currentState.languageToViewIn != appRelevantDataContextValue.language.languageToViewIn
        ) {

            clearIntervals();

        //     let isSelectedNodeDevc = appRelevantDataContextValue.selectedNodeInfo.isDevc;
    
        //     // The selected tree node has changed. Update the state based on the new selected node.

        //     // If the selected node itself is a Device
        //     // then show the devicedata of that device on interval (i.e for HHM devc).
        //     // Otherwise check the selected node contains any kind of devices and display data based on device type.
        //    if(isSelectedNodeDevc){
        //         dbGetLatestDeviceData();
        //         state.interval = setInterval( () => { dbGetLatestDeviceData() }, getSingleDeviceDataRefreshTime() );                
        //     } else {
        //         // If the selected node has property HasDevice 'true' then this node contains Devices of diff type
        //         // then check whether the selected node associated with Device which has property "DisplayAsParentNodedata".
        //         CheckWhetherSelectedNodeContainsDevcToShowData();
        //     }

            // Showing loading page to user to avoid unnecessary clicks from the user while fetching data from the server.
            appRelevantDataContextValue.onChangeProcessingReq(true);
            CheckWhetherSelectedNodeContainsDevcToShowData();
        }

    }, [
        state.selectedTreeNodeID, 
        state.languageToViewIn,
        context.selectedNodeInfo.nodeID,
        // context.loggedInUserInfo.userID,
    ]);

    const CheckWhetherSelectedNodeContainsDevcToShowData = () => {
        
        // Clear interval if present.
        // if(state.interval != null) {
        //     clearInterval(state.interval);
        // }
        clearIntervals()
        let inAppRelevantDataContextValue = context;
        let t = inAppRelevantDataContextValue.t;
        let selectedTreeNodeID = inAppRelevantDataContextValue.selectedNodeInfo.nodeID;
        let selectedTreeNodeTitle = inAppRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
        let isRootSelected = inAppRelevantDataContextValue.selectedNodeInfo.isRoot; 
        let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;
        let HasSelectedNodeDevices = inAppRelevantDataContextValue.selectedNodeInfo.hasDevc;
        let isSelectedNodeDevc = inAppRelevantDataContextValue.selectedNodeInfo.isDevc;
        let parentIdOfSelectedNodeDevc = inAppRelevantDataContextValue.selectedNodeInfo.parentID;
        let languageToViewIn = inAppRelevantDataContextValue.language.languageToViewIn;

        let modifiedState = state;
        modifiedState.selectedTreeNodeID = selectedTreeNodeID;
        modifiedState.parentIdOfSelectedNodeDevc = parentIdOfSelectedNodeDevc;
        modifiedState.selectedTreeNodeTitle = selectedTreeNodeTitle;
        modifiedState.isRootSelected = isRootSelected;
        modifiedState.LoggedInUserID = loggedInUserID;
        modifiedState.bSelectedNodeContainsDevcToShowData = false;
        modifiedState.selectedTreeNodeIsDevice = false;
        modifiedState.languageToViewIn = languageToViewIn;
        modifiedState.isPfcDevicePresentAsSibling = false;

        // If the selcted node doesnot contain any devices 
        // then show the User default HTML page indicating user to select diff node to view data.
        if((!HasSelectedNodeDevices) && (!isSelectedNodeDevc)) {
            setState({...modifiedState});
            inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
            return;
        }

        let ownerOfTrackedDevices =  inAppRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
        let hasAssociatedTreeNodeIds =  inAppRelevantDataContextValue.devicesToTrack.hasAssociatedTreeNodeIds;

        let strLoggedInOrOwnerUserIDToBringDevcData;

        if(ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0) {
            strLoggedInOrOwnerUserIDToBringDevcData = ownerOfTrackedDevices;
        } else if (hasAssociatedTreeNodeIds) {
            strLoggedInOrOwnerUserIDToBringDevcData = ownerOfTrackedDevices;
        } else {
            strLoggedInOrOwnerUserIDToBringDevcData = loggedInUserID;
        }

        // Not tested can be used is want to avoid api call if user already has no device 
        // if(selectedTreeNodeID.length > 0 && selectedTreeNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER) {
        //     setState({...modifiedState});
        //     inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
        //     return;
        // }
        let jsonParams = {
            "SelectedTreeNodeID": selectedTreeNodeID,
            "LoggedInUserID": strLoggedInOrOwnerUserIDToBringDevcData,
            "isSelectedNodeIsDevc": isSelectedNodeDevc
        }

        axios.post(`${getAPIHostURL()}/wclient/CheckWhetherSelectedNodeContainsDevcToShowData`, jsonParams)
        .then(response =>{
            if(response.data.code == 'SUCCESS') {
                if(response.data.hasDevcsOnSelectedTreeNode != null || response.data.selectedTreeNodeIsDevice != null) {
                    if(response.data.hasDevcsOnSelectedTreeNode == true || response.data.selectedTreeNodeIsDevice == true) {
                        modifiedState.selectedTreeNodeIsDevice = response.data.selectedTreeNodeIsDevice;
                        modifiedState.bSelectedNodeContainsDevcToShowData = true;
                        // Clear interval if present.
                        // if(state.interval != null) {
                            // clearInterval(state.interval);
                        // }
                        
                        clearIntervals();

                        // If selected node itself is a device and is a PFC device 
                        // or If selected node itself is not a device and slected node contains Devices of that node which were usually PFC 
                        // then show the count of that device on interval (i.e for HHM devc).
                        if((isSelectedNodeDevc == true && modifiedState.selectedTreeNodeIsDevice == true) || (isSelectedNodeDevc == false && response.data.hasDevcsOnSelectedTreeNode)){
                            getCrossingCountInfoForSelectedNodeId(modifiedState);                         
                            setGetDeviceDataInterval(false);
                            setGetCrossingCountDataInterval(true);

                            // if(!state.interval){
                            //     state.interval = setInterval( () => { getCrossingCountInfoForSelectedNodeId() }, getSingleDeviceDataRefreshTime() );
                            // }
                        } else if(isSelectedNodeDevc == true) {
                            // If the selected node itself is a Device
                            // then show the devicedata of that device on interval (i.e for HHM devc).
                            dbGetLatestDeviceData(modifiedState); 
                            setGetDeviceDataInterval(true);
                            setGetCrossingCountDataInterval(false);
                            // if(!state.interval){
                            // state.interval = setInterval( () => { dbGetLatestDeviceData() }, getSingleDeviceDataRefreshTime() );
                            // }
                        } else {
                            inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
                            //stop processing
                        }
                    } else {
                        if(isSelectedNodeDevc) {
                            // Clear interval if present.
                            // if(state.interval != null) {
                                // clearInterval(state.interval);
                            // }
                            clearIntervals()

                            // If the selected node itself is a Device
                            // then show the devicedata of that device on interval (i.e for HHM devc).

                            dbGetLatestDeviceData(modifiedState);                       
                            setGetDeviceDataInterval(true);
                            setGetCrossingCountDataInterval(false);
                            // if(!state.interval){
                            //     state.interval = setInterval( () => { dbGetLatestDeviceData() }, getSingleDeviceDataRefreshTime() );
                            // }
                        } else {
                            setState({...modifiedState});
                            inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner. 
                        }
                    }
                } else {
                    setState({...modifiedState});
                    inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner. 
                }
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = t(IDS_SrvrIssueDevcParamsNotRec);
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else if(response.data.code == 'SERVER_EXPERINCING_ISSUES') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                setState({...modifiedState});
                inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
            }
        })
        .catch(err => {
            inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
            console.log("Network error");
            console.log(err);
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled because of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                console.log(modifiedState.errors.others);
            }

            // Do not update state in case of Network error. Continue to show the last updated value.
            // setState({...modifiedState});
        })

    }

    const onClickRefreshButton = () => {
        let refreshIconEle = document.getElementById('SingleDeviceDataRefresh');
        if (refreshIconEle) {
            refreshIconEle?.classList.add('spinn')
            setTimeout(() => {
                refreshIconEle?.classList.remove('spinn')
            }, 1000);
        }
        let inAppRelevantDataContextValue = context;
        let isSelectedNodeDevc = inAppRelevantDataContextValue.selectedNodeInfo.SelectedNodeDeviceType == 'PFC' ?  true : false;

        if(isSelectedNodeDevc) {
            getCrossingCountInfoForSelectedNodeId();       
            setGetDeviceDataInterval(false);
            setGetCrossingCountDataInterval(true);
        } else {
            dbGetLatestDeviceData();
        }
    }

    const getSiblingsOfTheSelectedNode = () => { 
        
        let modifiedState = state;
        let inAppRelevantDataContextValue = context;

        let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;

        let ownerOfTrackedDevices =  inAppRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
        let hasAssociatedTreeNodeIds =  inAppRelevantDataContextValue.devicesToTrack.hasAssociatedTreeNodeIds;

        // Send the Two different Logged in User Id from Mobile App  also. Once mobile code get updated change in this API Variable names as well.
        let strLoggedInOrOwnerUserIDToBringDevcData;

        if(ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0) {
            strLoggedInOrOwnerUserIDToBringDevcData = ownerOfTrackedDevices;
        } else if (hasAssociatedTreeNodeIds == true) {
            strLoggedInOrOwnerUserIDToBringDevcData = ownerOfTrackedDevices;
        } else {
            strLoggedInOrOwnerUserIDToBringDevcData = loggedInUserID;
        }

        let DeviceIDsForPFC = [];
        let DeviceIDsForWLI = [];
        modifiedState.arrDeviceIDsForPFC = [];
        modifiedState.arrDeviceIDsForWLI = [];
        modifiedState.isPfcDevicePresentAsSibling = false;
        
        let jsonBody = {
            NodeID: modifiedState.parentIdOfSelectedNodeDevc,
            LoggedInUserID: strLoggedInOrOwnerUserIDToBringDevcData
        }

        axios.post( `${getAPIHostURL()}/wclient/getDevicesForSelectedTreeNode`, jsonBody)
        .then(response => {  
            if(response.data.code == 'SUCCESS') {
                if((response.data.ArrAssignedDevicesToNode == null || response.data.ArrAssignedDevicesToNode.length <=0) &&
                    !response.data.hasOwnProperty("hasDevcsOnSelectedTreeNode")
                ) {
                    console.log("There are no child tree node Devices and Devices to be shown as Parent Node data are not present for the selected node.");
                } else {
                    modifiedState.arrAssignedDevicesToNode = response.data.ArrAssignedDevicesToNode;

                    for(let i=0; i<modifiedState.arrAssignedDevicesToNode.length; i++) {

                        // seperate the HHM and PFC devices and store in an array.
                        if(modifiedState.arrAssignedDevicesToNode[i]["ModelName"] == PEOPLE_COUNTER) {
                            DeviceIDsForPFC.push(modifiedState.arrAssignedDevicesToNode[i]["DeviceID"]);                      
                        } else if(modifiedState.arrAssignedDevicesToNode[i]["ModelName"] == WATER_LEVEL_INDICATOR) {
                            DeviceIDsForWLI.push(modifiedState.arrAssignedDevicesToNode[i]["DeviceID"]);                      
                        } else {
                            // DeviceIDsForHHM.push(modifiedState.arrAssignedDevicesToNode[i]["DeviceID"]);
                            // Do Nothing
                        }
                    }  

                    modifiedState.arrDeviceIDsForPFC = DeviceIDsForPFC;
                    
                    modifiedState.arrDeviceIDsForWLI = DeviceIDsForWLI;

                    if(DeviceIDsForPFC == null || DeviceIDsForPFC.length <= 0){
                        modifiedState.isPfcDevicePresentAsSibling = false;
                        modifiedState.SingleDeviceParamSequence = modifiedState.SingleDeviceParamSequence.filter(function(e) { return e !== AWC })
                    } else if((DeviceIDsForPFC != null && DeviceIDsForPFC.length > 0) &&
                              (DeviceIDsForWLI != null && DeviceIDsForWLI.length > 0) &&
                               modifiedState.bDeviceTypeWLI == true)
                    {
                        modifiedState.isPfcDevicePresentAsSibling = true;
                        // get all the devices data for the all the fetched Device Id's.
                        if(modifiedState.yestTotalWaterUsage == 0) {
                            dbGetDeviceDataForPfcAndWLI(modifiedState);
                        }
                        // bring the live and updated data after every 5 mins for the toilet dashboard.
                        if (!getDeviceDataInterval) {
                            setGetDeviceDataInterval(true);
                        }
                        if (getCrossingCountDataInterval) {
                            setGetCrossingCountDataInterval(false);
                        }
                        // if(!state.interval){
                        //     state.interval = setInterval( () => { dbGetDeviceDataForPfcAndWLI() }, getSingleDeviceDataRefreshTime() );
                        // }
                    }

                    // if(state.interval != null) {
                    //     clearInterval(state.interval);
                    // }
                    // if(siblingsDataInterval){
                    //     setSiblingsDataInterval(false)
                    // }
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    console.log('Server experiencing issues.\nTry again later.'); 
                } else if(response.data.code == 'SERVER_EXPERINCING_ISSUES') {
                    console.log('Server experiencing issues.\nTry again later.'); 
                } else {
                    console.log('Should not reach here');
                    console.log('Server experiencing issues.\nTry again later.');  
                }
                setState({...modifiedState});
            }
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                console.log('Network issues.\nCheck your Internet and Try again later.');
            }
        }); 
    }

    const checkTimeDifference = (now, deviceDataTime) => {
        // Get the current date and time
        let modifiedState = state;
        modifiedState.chartAPICall = false;
    
        // Extract the hours, minutes, and seconds from the deviceDataTime
        let [hours, minutes, seconds] = deviceDataTime[1].split(":").map(Number);
    
        // Create a Date object for the device data time, using today's date
        let deviceTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds);
    
        // Calculate the difference in milliseconds
        let differenceInMillis = now - deviceTime;
    
        // Convert the difference to seconds
        let differenceInSeconds = differenceInMillis / 1000;
    
        // Reutrns 1, 0 or -1 for if differenceInSeconds value is negative, zero or positive
        let positiveDiffInSecs = Math.sign(differenceInSeconds);

        if(positiveDiffInSecs != -1 && positiveDiffInSecs != 0) {
            // Check if the difference is more than 3600 seconds (1 hour)
            if (differenceInSeconds > 3600) {
                modifiedState.chartAPICall = false;
            } else {
                modifiedState.chartAPICall = true;
            }   
        }

        setState({...modifiedState});
    }

    const dbGetLastHourData = (logTime) => {

        const convertedLogTime = renderDtTmToDisplay(logTime);

        const now = new Date();
        checkTimeDifference(now, convertedLogTime)

        let inAppRelevantDataContextValue = context;
        let selectedTreeNodeID = inAppRelevantDataContextValue.selectedNodeInfo.nodeID;
        let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;
        let t = inAppRelevantDataContextValue.t;  
        
        let [date, mon, year] = splitedDateTime[0].split("-");
        const month = now.toLocaleString('default', {month: 'short'}) // Returns "Sep" from "September"
        const currentYear = now.getFullYear(); // Returns Current Running Year
        const intYear = Number(year); // Converts to Number Type

        let jsonParams = {
            "DeviceID" : selectedTreeNodeID
        }

        if((state.chartAPICall === true && mon === month && intYear === currentYear) || (state.chartAPICall === true && convertedLogTime[0].includes("Today"))) {
            axios.post(`${getAPIHostURL()}/wclient/getLastHourData`, jsonParams)
                .then(response =>{

                    if(response.data.code == 'SUCCESS') {
                        let dataObj = response.data.dataObj;
                        let times = response.data.time;
                        if(dataObj != null || times != null) {
                            setlastHourData({
                                lastSixDataObj: {...dataObj},
                                lastSixTimes: times,
                            })
                        }
                        
            
                    } else {
                        // if(response.data.code == 'REQ_PARAMS_MISSING'){
                        //     modifiedState.errors.others = t(IDS_SrvrIssueDevcParamsNotRec);
                        // } else if(response.data.code == 'SQL_ERROR') {
                        //     modifiedState.errors.others = t(IDS_LoginServerIssue);
                        // } else {
                        //     console.log("Should not reach here");
                        //     modifiedState.errors.others = t(IDS_LoginServerIssue);
                        // }
                    }
                    
                    // Do not update state in case of axios error. Continue to show the last updated value.
                    // setState({...modifiedState});

                })
                .catch(err => {
                    console.log("Network error");
                    console.log(err);
                    // if (axios.isCancel(err)) {
                    //     console.log('Axios request cancelled because of too many requests being sent to the Server.');
                    // } else {
                    //     modifiedState.errors.others = t(IDS_RegistNetworkError);
                    //     console.log(modifiedState.errors.others);
                    // }

                    // // Do not update state in case of Network error. Continue to show the last updated value.
                    // setState({...modifiedState});
                })
        } else {
            setlastHourData(null)
        }
    }

    const dbGetLatestDeviceData = () => {
        let inAppRelevantDataContextValue = context;

        let selectedTreeNodeID = inAppRelevantDataContextValue.selectedNodeInfo.nodeID;
        let selectedTreeNodeTitle = inAppRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
        let selectedTreeParentNodeID = inAppRelevantDataContextValue.selectedNodeInfo.parentID;  
        let isRootSelected = inAppRelevantDataContextValue.selectedNodeInfo.isRoot; 
        let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;
        let t = inAppRelevantDataContextValue.t;  

        let modifiedState = state;
        modifiedState.bDeviceTypeWLI = inAppRelevantDataContextValue.selectedNodeInfo.SelectedNodeDeviceType == WLI ? true : false;
        modifiedState.bDeviceTypeSTLI = inAppRelevantDataContextValue.selectedNodeInfo.SelectedNodeDeviceType == STLI ? true : false;
        modifiedState.stdAirQualityRanges = [];
        modifiedState.relevantModelInfo = [];
        modifiedState.SingleDeviceParamValue = [];
        modifiedState.dailyChartFlag = !modifiedState.dailyChartFlag;

        modifiedState.selectedTreeNodeID = selectedTreeNodeID;
        modifiedState.selectedTreeNodeTitle = selectedTreeNodeTitle;
        modifiedState.isRootSelected = isRootSelected;
        modifiedState.LoggedInUserID = loggedInUserID;
        modifiedState.bCheckDeviceBlockedStatus = false;
        modifiedState.havingPvgToViewBlockedDeviceData = false;

        modifiedState.isDeviceBlocked = false;

        let jsonParams = {
            selectedTreeNodeID: selectedTreeNodeID,
            LoggedInUserID: loggedInUserID,
        }
        
        // Incase the LHS Tree does not have any devices OR if it is a General user (a user without Devices) Logged in
        // and the User has clicked the node with ID 'NO_DEVC_FOUND_FOR_LOGGED_IN_USER'.
        // In this case no need to bring data from server as DeviceID is not valid and we have to show him a HTML page with message.
        // Also you have to update state with loggedinUser, selectedTreeNodeID as it will avoid triggering componentDidUpdate infinitely.
        if(selectedTreeNodeID.length > 0 && selectedTreeNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER) {
            setState({...modifiedState});
            inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
            return;
        }

        axios.post(`${getAPIHostURL()}/wclient/getLatestDeviceData`, jsonParams)
        .then(response =>{
            if(response.data.code == 'SUCCESS') {
                modifiedState.receivedDeviceModelInfo = response.data.retreivedrelevantModelInfo;
                let receivedParamsHighLowValues = response.data.retreivedstdAirQualityRanges;
                let SingleDeviceValues = response.data.selectSingleDeviceData;
                modifiedState.singleDeviceImportantAlertInfo = [];

                // Show blocked Devices Data to Production/Support Users.                
                modifiedState.bCheckDeviceBlockedStatus = response.data.bCheckDeviceBlockedStatus != null && 
                    response.data.bCheckDeviceBlockedStatus == 0 ? true : false;
                modifiedState.havingPvgToViewBlockedDeviceData = response.data.havingPvgToViewBlockedDeviceData != null && 
                    response.data.havingPvgToViewBlockedDeviceData > 0 ? true : false;

                dbGetLastHourData(SingleDeviceValues[0].LogTime);

                if(response.data["importantAlertInfo"] == null || response.data.importantAlertInfo.length <= 0) {
                    modifiedState.singleDeviceImportantAlertInfo = [];
                } else {
                    modifiedState.singleDeviceImportantAlertInfo = response.data.importantAlertInfo;
                }

                modifiedState.SingleDeviceData = SingleDeviceValues; // Not used directly, but store in case required
                // modifiedState.relevantModelInfo = receivedDeviceModelInfo[0]; // Not used directly, but store in case required
                modifiedState.arrReceivedStdAirQualityRange = receivedParamsHighLowValues; // Not used directly, but store in case required
                let Paramsvalues;
                let HighLow;
                let MeasuredParam;
                let SeqParam;
                let modelInfo;

                try {
                    Paramsvalues = JSON.parse(SingleDeviceValues[0].MeasuredParams);
                    let additionalInfo = JSON.parse(SingleDeviceValues[0].AdditionalInfo);

                    if(modifiedState.bDeviceTypeWLI){
                        Paramsvalues[VOL] = additionalInfo[VOL];
                        modifiedState.volumeOfWaterTank = additionalInfo[VOL]; // Volume
                        modifiedState.waterLevel = Paramsvalues[WLIP]; // Water level indicator percentage
                        modifiedState.availableWater = Paramsvalues[AWL]; // Available water level 
                        modifiedState.consumedWater = Paramsvalues[TCWL]; // Total consumed water level
                    }

                    if(modifiedState.bDeviceTypeSTLI){
                        modifiedState.sewageLevel = (Paramsvalues[SLIP] == null || Paramsvalues[SLIP] < 0) ? 0 : Paramsvalues[SLIP];
                    }

                    SeqParam = JSON.parse(modifiedState.receivedDeviceModelInfo[0].MeasuredParams);
                    modelInfo = JSON.parse(modifiedState.receivedDeviceModelInfo[0].MeasuredParams);
                    modifiedState.AlertBasedParam = [];
                    modifiedState.PositionProrityParam = [];

                    let MeasuredParamSeq = ( SeqParam != null && SeqParam["Seq"] != null ) ? SeqParam["Seq"] : null;

                    for(let i = 0; i <MeasuredParamSeq.length; i++) {
                        
                        let singleMeasuredParam = MeasuredParamSeq[i];

                        let bIsParamAlertBased = SeqParam[singleMeasuredParam][ALERT_BASED] != null ? SeqParam[singleMeasuredParam][ALERT_BASED] : false;
                        let bIsParamPositionPriority = SeqParam[singleMeasuredParam][POSITION_PRIORITY] != null ? SeqParam[singleMeasuredParam][POSITION_PRIORITY] : false;

                        if(bIsParamAlertBased) { // Filter out AlertBasedParam.
                            modifiedState.AlertBasedParam.push(singleMeasuredParam);
                        }

                        if(bIsParamPositionPriority) { // Filter out PositionProrityParam
                            modifiedState.PositionProrityParam.push(singleMeasuredParam);
                        }

                        if(!Paramsvalues.hasOwnProperty(singleMeasuredParam) ) {

                            // If key is not present in json. Add same to json.
                            let objParamValue = {
                                [singleMeasuredParam]: null
                            };
                            
                            Paramsvalues = {...Paramsvalues, ...objParamValue};

                        } else if( Paramsvalues[singleMeasuredParam] == null || Paramsvalues[singleMeasuredParam].length <=0 ) {

                            // If value is null or a blank string make it to the null.
                            Paramsvalues[singleMeasuredParam] = null;

                        } else if( isNaN(Paramsvalues[singleMeasuredParam]) ) {

                            console.log(`Value for ${singleMeasuredParam} - ${Paramsvalues[singleMeasuredParam]} is invalid. Setting it to null for further processing`);
                            Paramsvalues[singleMeasuredParam] = null;
                        } else {
                            // No need to modify the value.
                        }
                    }
                    
                    // Convert individual maxHeight:'77vh' AirQuality JSONString into object and store in array
                    for(let i=0; i < receivedParamsHighLowValues.length; i++){
                        let singleAirQualityRangeValues = receivedParamsHighLowValues[i];
                        MeasuredParam = singleAirQualityRangeValues.MeasuredParam;

                        HighLow = JSON.parse(receivedParamsHighLowValues[i].RangeValues);
                        let singleRangeValues = {
                            MeasuredParam: MeasuredParam,
                            ...HighLow
                        }
                        modifiedState.stdAirQualityRanges[MeasuredParam] = singleRangeValues;
                    }
                    modifiedState.relevantModelInfo = modelInfo;
                    
                } catch (error) {
                    console.log(`Should not happen. The device data obtained from server for DeviceID ${SingleDeviceValues.DeviceID} is in invalid JSON format.`);
                    // Skip this device information and move to next
                }

                modifiedState.EntityLocationIDOfSelectedDevc = "";
                modifiedState.SingleDeviceParamValue = [];
                modifiedState.SingleDeviceParamSequence = [];
                modifiedState.LogTime = "";

                if(Paramsvalues == null || Paramsvalues.length <= 0 || SeqParam == null || SeqParam.length <= 0 || SeqParam.Seq == null || SeqParam.Seq.length <= 0){
                    Paramsvalues = {};
                    SeqParam = {};
                    modifiedState.AlertBasedParam = [];
                    modifiedState.PositionProrityParam = [];
                    modifiedState.totalTodaysPeopleCount = null;
                    modifiedState.totalTodaysPeopleOccupancyPercent = null;
                    // modifiedState.errors.others = 'Selected Device does not have required data on the server.';
                    modifiedState.errors.others = t(IDS_DevcNoDataOnSrvr);
                    setState({...modifiedState});
                    inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
                    return;
                } else {
                    modifiedState.errors.others = '';

                    // Store converted 'DeviceParamValue' and 'ParamSequence' in state
                    modifiedState.SingleDeviceParamValue = Paramsvalues;
                    modifiedState.SingleDeviceParamSequence = SeqParam.Seq;
                    modifiedState.LogTime = SingleDeviceValues[0].LogTime;
                    modifiedState.EntityLocationIDOfSelectedDevc = SingleDeviceValues[0].EntityLocnID;
                    // modifiedState.SingleDeviceParamValue = getParamValueBasedOnRangeValues(Paramsvalues);
                }

                if(selectedTreeParentNodeID != null && selectedTreeParentNodeID.length > 0 && selectedTreeParentNodeID != loggedInUserID) {
                    setState({...modifiedState});
                    inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
                } else {
                    getCrossingCountInfo();
                }

                if(modifiedState.parentIdOfSelectedNodeDevc != null){
                    getSiblingsOfTheSelectedNode(modifiedState);
                    // WHy is a interval needed
                    // if (!siblingsDataInterval) {
                    //     setSiblingsDataInterval(true)
                    // }
                    // state.interval = setInterval( () => { getSiblingsOfTheSelectedNode() }, getSingleDeviceDataRefreshTime() );
                } else {
                    modifiedState.SingleDeviceParamSequence = modifiedState.SingleDeviceParamSequence.filter(function(e) { return e !== 'AWC' })
                    setState({...modifiedState});
                }

            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = t(IDS_SrvrIssueDevcParamsNotRec);
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else if(response.data.code == 'DEVICE_IS_BLOCKED') {
                    modifiedState.isDeviceBlocked = true;
                    // Clear the interval if the Device is blocked so that it will not get the data from the server on every interval for the blocked device.
                    // clearInterval(state.interval);
                    clearIntervals();
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
            }

            // Do not update state in case of axios error. Continue to show the last updated value.
            // setState({...modifiedState});

        })
        .catch(err => {
            inAppRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
            console.log("Network error");
            console.log(err);
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled because of too many requests being sent to the Server.');
            } else {
                // modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
            }

            // Do not update state in case of Network error. Continue to show the last updated value.
            // setState({...modifiedState});
        })
    }

    const dbGetDeviceDataForPfcAndWLI = (inModifiedState = null) => {

        let inAppRelevantDataContextValue = context;
        let t = inAppRelevantDataContextValue.t;  

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let currentBrowserDtTm = new Date();
        let strCurrentBrowserDtTm = convertLocalDateToStrYYYYMMDDHH24MMSS(currentBrowserDtTm);

        let BrowserStartDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "00:00:00";
        let BrowserEndDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "23:59:59";
  
        let UtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(BrowserStartDtTm.valueOf()));
        let UtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(BrowserEndDtTm.valueOf()));        

        modifiedState.pfcDeviceValues = [];
        modifiedState.resultOfWLI = [];
        modifiedState.totalPeopleCount = 0;

        let jsonParams = {
            arrDeviceIDsForPFC: modifiedState.arrDeviceIDsForPFC != null || modifiedState.arrDeviceIDsForPFC.length > 0 ? modifiedState.arrDeviceIDsForPFC : [],
            arrDeviceIDsForWLI: modifiedState.arrDeviceIDsForWLI != null || modifiedState.arrDeviceIDsForWLI.length > 0 ? modifiedState.arrDeviceIDsForWLI : [],
            StartDtTmUTC: UtcStartDtTm,
            EndDtTmUTC: UtcEndDtTm,
            ActiveStatusMinimumTime: ACTIVE_STATUS_MINIMUM_TIME
        }

        axios.post(`${getAPIHostURL()}/wclient/getWliAndPfcData`, jsonParams)
        .then(response =>{

            if(response.data.code == 'SUCCESS') {
                
                modifiedState.pfcDeviceValues = response.data.arrCrossCountBasedOnDevc;
                modifiedState.resultOfWLI = response.data.allWLIDeviceDetails;
                
                if(modifiedState.resultOfWLI != null && modifiedState.resultOfWLI.length > 0){
                    modifiedState.yestTotalWaterUsage = modifiedState.resultOfWLI[0]['yesterdayUsedWater'];
                }

                if(modifiedState.pfcDeviceValues != null && modifiedState.pfcDeviceValues.length > 0) {
                    for(let i=0; i<modifiedState.pfcDeviceValues.length; i++) {
                        modifiedState.totalPeopleCount = modifiedState.pfcDeviceValues[i]["PFCCountPerDay"];
                    }
                }

                if(modifiedState.totalPeopleCount != null && modifiedState.totalPeopleCount > 0){
                    modifiedState.waterUsagePerUser = (modifiedState.yestTotalWaterUsage / modifiedState.totalPeopleCount).toFixed(2);
                }
                setState({...modifiedState});
     
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = t(IDS_SrvrIssueDevcParamsNotRec);
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                setState({...modifiedState});

            }
            

        })
        .catch(err => {
            console.log("Network error");
            console.log(err);
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled because of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                console.log(modifiedState.errors.others);
            }

            // Do not update state in case of Network error. Continue to show the last updated value.
            setState({...modifiedState});
        })
    }

    const getCrossingCountInfo = (inModifiedState = null) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
  
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        };
  
        modifiedState.totalTodaysPeopleCount = null;
        modifiedState.totalTodaysPeopleOccupancyPercent = null;
  
        if(modifiedState.EntityLocationIDOfSelectedDevc == null || modifiedState.EntityLocationIDOfSelectedDevc.length < 0) {
          setState({...modifiedState});
          appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
          return;
        }
  
        let currentBrowserDtTm = new Date();
        let strCurrentBrowserDtTm = convertLocalDateToStrYYYYMMDDHH24MMSS(currentBrowserDtTm);
        // let crossingCountBrowserStartDtTm = strCurrentBrowserDtTm.split(" ")[0] + " 00:00:00";
        // let crossingCountBrowserEndDtTm = strCurrentBrowserDtTm.split(" ")[0] + " 23:59:59";

        let crossingCountBrowserStartDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "00:00:00";
        let crossingCountBrowserEndDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "23:59:59";
  
        let crossingCountUtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserStartDtTm.valueOf()));
        let crossingCountUtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserEndDtTm.valueOf()));
  
        let jsonBody = {
            EntityLocationId: modifiedState.EntityLocationIDOfSelectedDevc,
            StartDtTmUTC: crossingCountUtcStartDtTm,
            EndDtTmUTC: crossingCountUtcEndDtTm,
        }
  
        // To get getCrossingCountInfo. 
        axios.post( `${getAPIHostURL()}/wclient/getCrossingCountInfo`, jsonBody)
        .then(response => {
          appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  

          if(response.data.code == 'SUCCESS') {
            modifiedState.totalTodaysPeopleCount = null;
            modifiedState.totalTodaysPeopleOccupancyPercent = null;
            modifiedState.totalTodaysPeopleCount = response.data.InOutCount;
            modifiedState.totalTodaysPeopleOccupancyPercent = response.data.PeopleOccupancyPercentToSend;
            
          } else {
            if(response.data.code == 'REQ_PARAMS_MISSING') {
              // Let the user know that the Required parameters were not sent to the Server
              modifiedState.errors.others = t(IDS_SrvrIssueDevcParamsNotRec);
            } else if(response.data.code == 'SQL_ERROR') {
                // Tell the user that Server is experiencing errors
                modifiedState.errors.others = t(IDS_LoginServerIssue);
  
              } else {
                console.log('Should not reach here');
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
            // Display the specific error message for login failure.
          }
          setState({...modifiedState});
        })
        .catch( error => {
          appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            // Tell the user that there are network issues
            // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
            modifiedState.errors.others = t(IDS_RegistNetworkError);
  
            setState({...modifiedState});
          }
        }); 
    }

    const getCrossingCountInfoForSelectedNodeId = (inModifiedState = null) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let arrDeviceType = appRelevantDataContextValue.selectedNodeInfo.deviceType;
        let selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
        let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        let selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;
        let isSelectedNodeDevc = appRelevantDataContextValue.selectedNodeInfo.isDevc;    
        let isSelectedDevcIsPFC = appRelevantDataContextValue.selectedNodeInfo.SelectedNodeDeviceType == 'PFC' ? true : false;
        let HasSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.hasDevc;
        let languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        };

        modifiedState.bSelectedNodeContainsDevcToShowData = true;
        modifiedState.totalTodaysPeopleCount = null;
        modifiedState.totalTodaysPeopleOccupancyPercent = null;
        modifiedState.selectedTreeNodeID = selectedTreeNodeID;
        modifiedState.LoggedInUserID = loggedInUserID;
        modifiedState.selectedTreeNodeTitle = selectedTreeNodeTitle;
        modifiedState.arrTodaysPplCount = [];
        modifiedState.PfcParamModelInfo = [];
        modifiedState.LogTime = "";
        modifiedState.bCheckDeviceBlockedStatus = false;
        modifiedState.havingPvgToViewBlockedDeviceData = false;
        modifiedState.isDeviceBlocked = false;
        modifiedState.languageToViewIn = languageToViewIn;
        modifiedState.dailyChartFlag = !modifiedState.dailyChartFlag;

  
        let currentBrowserDtTm = new Date();
        let strCurrentBrowserDtTm = convertLocalDateToStrYYYYMMDDHH24MMSS(currentBrowserDtTm);
        // let crossingCountBrowserStartDtTm = strCurrentBrowserDtTm.split(" ")[0] + " 00:00:00";
        // let crossingCountBrowserEndDtTm = strCurrentBrowserDtTm.split(" ")[0] + " 23:59:59";

        let crossingCountBrowserStartDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "00:00:00";
        let crossingCountBrowserEndDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "23:59:59";
  
        let crossingCountUtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserStartDtTm.valueOf()));
        let crossingCountUtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserEndDtTm.valueOf()));        

        let strCommaSeperatedDeviceType = (arrDeviceType.includes(DEVICE_TYPE_PFC) || modifiedState.selectedTreeNodeIsDevice || isSelectedDevcIsPFC) ? '"' + DEVICE_TYPE_PFC + '"' : "";
        
        // If device type is null and if its not a PFC device 
        // then do not proceed.
        if( (strCommaSeperatedDeviceType == null || strCommaSeperatedDeviceType.length <=0) && 
            (modifiedState.selectedTreeNodeIsDevice == false)
        ) {
            console.log("Do not proceed. Device type (eg: PFC) is missing");
            setState({...modifiedState});
            appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
            return;
        }

        let ownerOfTrackedDevices =  appRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
        let hasAssociatedTreeNodeIds =  appRelevantDataContextValue.devicesToTrack.hasAssociatedTreeNodeIds;

        // Send the Two different Logged in User Id from Mobile App  also. Once mobile code get updated change in this API Variable names as well.
        let strLoggedInOrOwnerUserIDToBringDevcData;

        if(ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0) {
            strLoggedInOrOwnerUserIDToBringDevcData = ownerOfTrackedDevices;
        } else if (hasAssociatedTreeNodeIds == true) {
            strLoggedInOrOwnerUserIDToBringDevcData = ownerOfTrackedDevices;
        } else {
            strLoggedInOrOwnerUserIDToBringDevcData = loggedInUserID;
        }
        
        let jsonBody = {
            StartDtTmUTC: crossingCountUtcStartDtTm,
            EndDtTmUTC: crossingCountUtcEndDtTm,
            DeviceType: strCommaSeperatedDeviceType,
            SelectedTreeNodeID: selectedTreeNodeID,
            LoggedInUserID: loggedInUserID,
            strLoggedInOrOwnerUserID: strLoggedInOrOwnerUserIDToBringDevcData,
            isSelectedNodeDevc: isSelectedNodeDevc,
        }
  
        // To get getCrossingCountInfoForSelectedNodeId. 
        axios.post( `${getAPIHostURL()}/wclient/getCrossingCountInfoForSelectedNodeId`, jsonBody)
        .then(response => {
            appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  

            if(response.data.code == 'SUCCESS') {

                modifiedState.bCheckDeviceBlockedStatus = false;
                // Show blocked Devices Data to Production/Support Users.
                modifiedState.havingPvgToViewBlockedDeviceData = response.data.havingPvgToViewBlockedDeviceData != null && 
                    response.data.havingPvgToViewBlockedDeviceData > 0 ? true : false;

                if(response.data.arrCrossCountBasedOnDevc == null || response.data.arrCrossCountBasedOnDevc.length <= 0) {
                    console.log("Should not happen. Today's devicewise People foot count is missing or empty.");
                } else {
                    modifiedState.arrTodaysPplCount = [];

                    modifiedState.LogTime = response.data.arrCrossCountBasedOnDevc.length == 1 ? response.data.arrCrossCountBasedOnDevc[0]["LogTime"] : "";
                    modifiedState.receivedModelInfoForPFC = response.data.modelInfoForPFC != null && response.data.modelInfoForPFC.length > 0 ? response.data.modelInfoForPFC : "";

                    let PFCSeq = [];

                    try { 
                        PFCSeq = JSON.parse(modifiedState.receivedModelInfoForPFC[0]["ModelSeqInfoForPFC"]);  
                    } catch (error) {
                        console.log(`Should not happen. The device data obtained from server for DeviceID ${selectedTreeNodeID} is in invalid JSON format.`);
                        // Skip this device information and move to next
                    }

                    if(PFCSeq != null && PFCSeq.length > 0){
                        modifiedState.PfcParamModelInfo = PFCSeq;
                    } else{
                        PFCSeq = [];
                    }
                    
                    for(let i=0; i<response.data.arrCrossCountBasedOnDevc.length; i++) {
                        let singleCrossCountBasedOnDevc = response.data.arrCrossCountBasedOnDevc[i];

                        let singleCrossCount = singleCrossCountBasedOnDevc["CrossCount"];

                        // Checking If the Device is blocked or Unblocked based on the Active value If Device is blocked state (Active is 0) 
                        // than we show the Block Device message for PFC Device other wise we show the data for PFC Device. 
                        let InOutCount = singleCrossCountBasedOnDevc["Active"] != null && singleCrossCountBasedOnDevc["Active"] == 0 && modifiedState.havingPvgToViewBlockedDeviceData == 0 ? t(IDS_DevcBlockedDueToNonPaymentOfSomeCost) : (singleCrossCount != null) ? (singleCrossCount) : 0;
                        let splitedDateTime = renderDtTmToDisplay(singleCrossCountBasedOnDevc["LogTime"]);
                        let strLogTime = singleCrossCountBasedOnDevc["Active"] != null && singleCrossCountBasedOnDevc["Active"] == 0 && modifiedState.havingPvgToViewBlockedDeviceData == 0 ? "--" : 
                                                    ((splitedDateTime[0].toLowerCase() == "Today,".toLowerCase() ? t(IDS_TodaySearch) : splitedDateTime[0]) + " " + splitedDateTime[1]);

                        
                        if (singleCrossCountBasedOnDevc["Active"] != null && singleCrossCountBasedOnDevc["Active"] == 0) {
                            modifiedState.bCheckDeviceBlockedStatus = true;
                        }
                        
                        let objAditionalKeyForPFC = {
                            "InOutCount" : InOutCount, 
                            "LogTime": strLogTime
                        }
                        let objFinalCrossCountBasedOnDevc = {...singleCrossCountBasedOnDevc, ...objAditionalKeyForPFC};

                        modifiedState.arrTodaysPplCount.push(objFinalCrossCountBasedOnDevc);
                    }

                    // If there is only one device in Node and user don't have specail privilage to view data than we directly show the blocked data.
                    modifiedState.isDeviceBlocked = response.data.arrCrossCountBasedOnDevc != null && 
                        response.data.arrCrossCountBasedOnDevc.length == 1 && modifiedState.havingPvgToViewBlockedDeviceData == 0 
                        && response.data.arrCrossCountBasedOnDevc[0]["Active"] != null && 
                        response.data.arrCrossCountBasedOnDevc[0]["Active"] == 0 ? true : false;

                    // Clear the interval if the Device is blocked so that it will not get the data from the server on every interval for the blocked device.
                    if(modifiedState.isDeviceBlocked == true) {
                        // clearInterval(state.interval);
                        clearIntervals();
                    }
                }
                
            } else {
                console.log(response.data.code == 'REQ_PARAMS_MISSING');
                if(response.data.code == 'REQ_PARAMS_MISSING') {
                // Let the user know that the Required parameters were not sent to the Server
                modifiedState.errors.others = t(IDS_SrvrIssueDevcParamsNotRec);
                } else if(response.data.code == 'DEVICE_IS_BLOCKED') {
                    modifiedState.isDeviceBlocked = true;
                    // Clear the interval if the Device is blocked so that it will not get the data from the server on every interval for the blocked device.
                    // clearInterval(state.interval);
                    clearIntervals();
                } else if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else if(response.data.code == 'SERVER_EXPERINCING_ISSUES') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                // Display the specific error message for login failure.
            }
            setState({...modifiedState});
        })
        .catch( error => {
          appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.  
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            // Tell the user that there are network issues
            // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
            modifiedState.errors.others = t(IDS_RegistNetworkError);
  
            setState({...modifiedState});
          }
        }); 
    }

    const RangeSevere = () => {
        let modifiedState = state;
        modifiedState.Range = "Severe";
        setState({...modifiedState});
    }

    const RangeVeryPoor = () => {
        let modifiedState = state;
        modifiedState.Range = "VeryPoor";
        setState({...modifiedState});
    }

    const RangePoor = () => {
        let modifiedState = state;
        modifiedState.Range = "Poor";
        setState({...modifiedState});
    }

    const RangeModerate = () => {
        let modifiedState = state;
        modifiedState.Range = "Moderate";
        setState({...modifiedState});
    }

    

    const RangeGood = () => {
        let modifiedState = state;
        modifiedState.Range = "Good";
        setState({...modifiedState});
    }

    const RangeSatisfactory = () => {
        let modifiedState = state;
        modifiedState.Range = "Satisfactory";
        setState({...modifiedState});
    }

    const AllRange = () => {
        let modifiedState = state;
        modifiedState.Range = "AllRange";
        setState({...modifiedState});
    }

    const renderCheckIfAlertReceived = (singleParam) => {
        // Currently this function is applicable for SMOKE/LPG sensor.
        let modifiedState = state;
        let bAlertDetectedForParam = false;
        let AlertBasedSingleParamValue = null;

        if(singleParam == null) {
            return {AlertBasedSingleParamValue, bAlertDetectedForParam}
        }

        for(let i = 0; i < modifiedState.singleDeviceImportantAlertInfo.length; i++) {

            if( modifiedState.singleDeviceImportantAlertInfo[i].MeasuredParam == singleParam
            ) {
                AlertBasedSingleParamValue = modifiedState.singleDeviceImportantAlertInfo[i].Value
                bAlertDetectedForParam = true;
                break;
            }
        }
        return {AlertBasedSingleParamValue, bAlertDetectedForParam}
    }

    const renderParamNameForAlertBasedParam = (AlertBasedSingleParam, inParamName, inParamValue) => {

        if(inParamName == null || inParamValue == null || inParamValue == '--') {
            return (<h6 className="NameOfParameter">&nbsp;</h6>); // return space
        }

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        let objTextAndBoxStyleForAlertBasedParamValue = getParamValueTextAndBoxStyleBasedOnRange(inParamValue, inParamName);

        if(AlertBasedSingleParam == true ||
           (objTextAndBoxStyleForAlertBasedParamValue["styleValueText"] != "ParamsValueRangeGood" && objTextAndBoxStyleForAlertBasedParamValue["styleValueText"] != "ParamsValueMissingOrWithoutRange" ) 
        ) {

            // If there is an alert for the "AlertBasedParam" in the appropriate interval.
            // Or if the DeviceData has a value which doesn't fall in the good range (obivously the value is not missing).
            // It means that an imp alert situation has happened.
            switch(inParamName) {
                case LPG:
                    
                    return (<small className="">{t(IDS_SDD_GasLeakageDetected)}</small>); // "Gas Leakage Detected"
                case SMOKE:
                    return (<small className="">{t(IDS_SDD_SmokeDetected)}</small>); // "Smoke Detected"
                default:
                    // console.error(`Unable to get paramname text for specified ParamName. Unknown Param Type: ${inParamName}`);
                    return (<small className="">&nbsp;</small>); // return space
                }
        } else {
            switch(inParamName) {
                case LPG:
                    return (<small className="">{t(IDS_SDD_NoGasLeakage)}</small>); // "No Gas Leakage"
                case SMOKE:
                    return (<small className="">{t(IDS_SDD_NoSmokeDetected)}</small>); // "No Smoke Detected"
                default:
                    // console.error(`Unable to get paramname text for specified ParamName. Unknown Param Type: ${inParamName}`);
                    return (<small className="">&nbsp;</small>); // return space
                }
        }
    }

    const renderUnitsForAlertBasedParam = (AlertBasedSingleParam, inParamName, inParamValue) => {

        if(inParamName == null || inParamValue == null) {
            return "--";
        }

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        let objTextAndBoxStyleForAlertBasedParamValue = getParamValueTextAndBoxStyleBasedOnRange(inParamValue, inParamName);

        if(AlertBasedSingleParam ||
           (objTextAndBoxStyleForAlertBasedParamValue["styleValueText"] != "ParamsValueRangeGood" && objTextAndBoxStyleForAlertBasedParamValue["styleValueText"] != "ParamsValueMissingOrWithoutRange" )
        ) {

            // If there is an alert for the "AlertBasedParam" in the appropriate interval.
            // Or if the DeviceData has a value which doesn't fall in the good range (obivously the value is not missing).
            // It means that an imp alert situation has happened.
            switch(inParamName) {
                case LPG:
                    return t(IDS_RiskOfFlammation) // Risk of Flammation
                case SMOKE:
                    return t(IDS_PossibleFire) // Possible Fire
                default:
                    // console.error(`Unable to get Unit Text for specified ParamName. Unknown Param Type: ${inParamName}`);
                    return "--"; // Return dash
            }
        } else {
            switch(inParamName) {
                case LPG:
                    return t(IDS_NoRiskOfFlammation) // No Risk of Flammation
                case SMOKE:
                    return t(IDS_NoPossibilityOfFire) // No Possibility of Fire
                default:
                    // console.error(`Unable to get Unit Text for specified ParamName. Unknown Param Type: ${inParamName}`);
                    return "--"; // Return dash
            }
        }
    }
    
    const renderDeviceSingleParameterDetails = (noOfParams, singleParam, styleValueBox, styleValueText, singleValue) => {

        let finalStyleValueBox = `col`;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        let quickTrackParam = context.quickTrackParamInfo.quickTrackParam;

        let modifiedState = state;

        let selectedModelParamsInfo = {};
        let selectedModelSingleParamInfo = {};

        for(let i = 0; i < modifiedState.receivedDeviceModelInfo.length; i++) {
            selectedModelParamsInfo = JSON.parse(modifiedState.receivedDeviceModelInfo[i].MeasuredParams);
        }
        // Extract a particular MeasuredParam Object.
        selectedModelSingleParamInfo = selectedModelParamsInfo[singleParam];
        let AlertBased = selectedModelSingleParamInfo != null && selectedModelSingleParamInfo.hasOwnProperty(ALERT_BASED) != null && selectedModelSingleParamInfo.AlertBased != null ?  selectedModelSingleParamInfo.AlertBased  : false;
        if(singleParam === NH3OD) {
            modifiedState.odourLevel = renderOdourLevel(singleValue, singleParam)
            
            if (listView) {
                return (
                    <div key={singleParam} onClick={()=>onChangeSelectedQuickTrackParam(singleParam)} className='flex justify-between rounded bg-white w-100 px-2 listView' style={{borderColor: quickTrackParam == singleParam ? '#1875f6aa' : 'transparent', paddingTop: "0.7rem", paddingBottom: "0.7rem"}}>
                        <div className='flex gap-2 flex-center'>
                            <span className={`rounded flex-center shadow-md p-1`} style={{width:"50px", fontSize:"1.3rem"}}>
                                {renderParamSymbolBasedOnType(singleParam)}   
                            </span>
                            <div className='text-start'>
                                {renderParamOdourLevelTxt(singleValue, singleParam)}
                            </div>
                        </div>
                        <div className='flex gap-2 px-2 flex-center'>
                            <div className={`${styleValueText} flex flex-center`} >
                                {renderParamOdourLevelIcon(singleValue, singleParam)}
                            </div>
                            <span>
                                {renderOdourLevel(singleValue, singleParam)}
                            </span>
                        </div>

                    </div>
                )
            } else {
                return (
                    <div key={singleParam} className={finalStyleValueBox}>
                        <div  onClick={()=>onChangeSelectedQuickTrackParam(singleParam)} className={`${quickTrackParam == singleParam && 'shadow-md'} gridView `} style={{borderColor: quickTrackParam == singleParam ? '#1875f6aa' : 'transparent'}}>
                            <div className='px-1 text-start '>
                                <div className='flex justify-between ' style={{padding:'0px'}}>
                                    <div className='flex flex-wrap align-items-baseline'>
                                        <span className={styleValueText} >
                                            {/* {singleValue == null ? "--" : singleValue} */}
                                            {renderParamOdourLevelIcon(singleValue, singleParam)}
                                        </span>
                                        <small className='ps-1'>
                                            <span className='text-mute'>
                                                {renderParamOdourLevelTxt(singleValue, singleParam)}
                                            </span>
                                            {/* <span className='ps-1'>
                                                ({getUnitsBasedOnParamtype(singleParam, true)})
                                            </span> */}
                                        </small>
                                    </div>
                                    <div >
                                        <span id="toggleSpan" className="tooltipForRangeIcon2" onClick={ () => toggleFromIcon(singleParam, singleValue)}>
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="rangeIcon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="15" cy="12" r="1"></circle>
                                                <circle cx="15" cy="5" r="1"></circle>
                                                <circle cx="15" cy="19" r="1"></circle>
                                            </svg>
                                            <span className="tooltiptextForRangeIcon">{t(IDS_DetailsAndRanges)}</span>
                                        </span>
                                    </div>
                                </div>

                                {/* <div className='text-start '>
                                    {renderParamNameBasedOnType(singleParam)} 
                                    
                                    {renderOdourLevel(singleValue, singleParam)}
                                </div> */}

                                <div className='text-start flex flex-wrap align-items-baseline gap-1'>
                                {renderParamNameBasedOnType(singleParam)} 
                                    <small>
                                    {renderOdourLevel(singleValue, singleParam)}
                                    </small>
                                </div>
                                
                            </div>
                            <div className=' flex '
                                style={{
                                        // minHeight:lastHourData && lastHourData?.lastSixTimes.length > 0 ? '40%' : '25%',
                                        // minHeight:'40%',
                                        paddingTop: "5px",
                                        maxHeight:'75px',
                                        height:'75px',
                                        display:'flex',
                                        justifyContent:"center"
                                    }}>
                                <VcSimpleBarChart  getUnits={getUnits} getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange} paramName={singleParam} paramTimes={lastHourData?.lastSixTimes} paramData={lastHourData?.lastSixDataObj[singleParam]}/>
                            </div>
                        </div>
                    </div>
                )
            }

        } else if(AlertBased == true) {
            let AlertBasedSingleParam =  renderCheckIfAlertReceived(singleParam);
            let CheckAlertForSingleParam = AlertBasedSingleParam["bAlertDetectedForParam"];
            if (listView) {
                return (
                    <div key={singleParam} className='flex justify-between rounded bg-white w-100 py-1 px-2 listView' >
                        <div className='flex gap-2 flex-center'>
                            <span className={`rounded flex-center shadow-md p-1`} style={{width:"50px", fontSize:"1.3rem"}}>
                                {renderParamSymbolBasedOnType(singleParam)}            
                            </span>
                            <div className='text-start'>
                                {renderParamNameBasedOnType(singleParam)}
                            </div>
                        </div>
                        <div className={styleValueText} >
                            {renderIconForAlertBasedParam(CheckAlertForSingleParam, singleParam, singleValue)}
                        </div>
                    </div>
                )
    
            } else {
                return (
                    <div key={singleParam} className={finalStyleValueBox}>
                        <div  className='bg-white  h-100 flex flex-col p-1 ' style={{ borderRadius:"10px" }}>
                            <div className='flex flex-col align-items-start px-1 '>
                                <div className='text-start'>
                                    {renderParamNameBasedOnType(singleParam)}
                                </div>
                                <div>
                                    {renderParamNameForAlertBasedParam(CheckAlertForSingleParam, singleParam, singleValue)}
                                </div>
                            </div>
                            <div 
                            className={`${styleValueText} flex-center flex-grow-1`} 
                            style={{
                                minHeight:'75px',
                                display:'flex',
                                justifyContent:"center"
                            }}>
                                    {renderIconForAlertBasedParam(CheckAlertForSingleParam, singleParam, singleValue)}
                            </div>
                        </div>
                    </div>
                    )
            }
        } else if (singleParam == WD ) {
            if (listView) {
                return (
                    <div key={singleParam}   className='flex justify-between rounded bg-white w-100 py-1 px-2'  style={{cursor:"auto"}}>
                        <div className='flex gap-2 flex-center'>
                            <span className={`rounded flex-center shadow-md p-1`} style={{width:"50px", fontSize:"1.3rem"}}>
                                {renderParamSymbolBasedOnType(singleParam)}            
                            </span>
                            <div className='text-start'>
                                {renderParamNameBasedOnType(singleParam)}
                            </div>
                        </div>
                        {singleValue != null && singleValue != '--'  ? 
                            <div className='windDirectionFont'>
                                {getDirectionName(singleValue) + "-" + singleValue + getUnitsBasedOnParamtype(singleParam)}
                            </div>
                            :
                            <div className={styleValueText}>
                                --
                            </div>
                        }
                    </div>
                )
            } else {
                return (
                    <div key={singleParam} className={finalStyleValueBox}>
                        <div  className={` gridView `} style={{cursor:"auto"}}>
                            <div className='px-1 text-start '>
                                <div className='flex flex-wrap align-items-baseline'>
                                    {singleValue != null && singleValue != '--'  ?<div className='windDirectionFont'>
                                        {getDirectionName(singleValue) + "-" + singleValue + getUnitsBasedOnParamtype(singleParam)}
                                    </div>:
                                    <div className={styleValueText}>
                                        --
                                    </div>}
                                </div>
                                <div className='text-start flex flex-wrap align-items-baseline gap-1'>
                                    {renderParamNameBasedOnType(singleParam)} 
                                    <small>
                                        ({renderParamSymbolBasedOnType(singleParam)})
                                    </small>
                                </div>
                                {/* <div className='flex flex-wrap align-items-baseline'>
                                    {getUnitsBasedOnParamtype(singleParam, singleValue)}
                                </div> */}

                            </div>
                            <div 
                            className=' flex items-end'
                            style={{
                                paddingTop: "5px",
                                maxHeight:'75px',
                                height:'75px',
                                display:'flex',
                                justifyContent:"center"
                                }}>
                                <div className='w-100 h-100 flex flex-col g-2 items-center'>
                                    {singleValue != null ?
                                    <>
                                    <div className='directionArrow'>
                                        {/* <TbWind style={{rotate: (singleValue-90) + 'deg'}}/> */}
                                        <TbWind />
                                    </div>
                                    <span>{getDirectionName(singleValue, "fullForm")}</span>
                                    </> :
                                    <>
                                    <div className={styleValueText} >
                                        <>--</>
                                    </div>
                                    </>
                                }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else if (singleParam == LUX) {
            if (listView) {
                return (
                    <div key={singleParam} className='flex justify-between rounded bg-white w-100 py-1 px-2' style={{cursor:"auto"}}>
                        <div className='flex gap-2 flex-center'>
                            <span className={`rounded flex-center shadow-md p-1`} style={{width:"50px", fontSize:"1.3rem"}}>
                                {renderParamSymbolBasedOnType(singleParam)}  
                            </span>
                            <div className="text-start" > 
                                {singleValue == null ? renderParamNameBasedOnType(singleParam) :
                                styleValueText == "ParamsValueRangeLSevereLux" ? t(IDS_LowLightIntensity):
                                styleValueText == "ParamsValueRangeGoodLux" ? t(IDS_MediumLightIntensity) : t(IDS_HighLightIntensity)
                                }
                            </div>
                        </div>
    
                        <div className='flex gap-2 px-2 flex-center'>
                            <span className={styleValueText}>{singleValue == null ? "--" : singleValue}</span>
                            <small className="" >
                                ({getUnitsBasedOnParamtype(singleParam)})
                            </small>
                        </div>
                    </div>
                )
    
            } else {
                return (
                    <div key={singleParam} className={finalStyleValueBox}>
                        <div  className={`gridView `} style={{cursor:"auto"}}>
                            <div className='px-1 text-start '>
                                <div className='flex justify-between ' style={{padding:'0px'}}>

                                    <div className='flex flex-wrap align-items-baseline'>
                                        <span className={styleValueText} >
                                            {singleValue == null ? "--" : singleValue}
                                        </span>
                                        <small className='ps-1'>
                                            ({getUnitsBasedOnParamtype(singleParam, singleValue)})
                                        </small>
                                    </div>
                                    <div >
                                        <span id="toggleSpan" className="tooltipForRangeIcon2" onClick={ () => toggleFromIcon(singleParam, singleValue)}>
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="rangeIcon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="15" cy="12" r="1"></circle>
                                                <circle cx="15" cy="5" r="1"></circle>
                                                <circle cx="15" cy="19" r="1"></circle>
                                            </svg>
                                            <span className="tooltiptextForRangeIcon">{t(IDS_DetailsAndRanges)}</span>
                                        </span>
                                    </div>
                                </div>
    
                                <div className='text-start flex flex-wrap align-items-baseline gap-1'>
                                    {renderParamNameBasedOnType(singleParam)} 
                                    <small>
                                        ({renderParamSymbolBasedOnType(singleParam)})
                                    </small>
                                </div>
                            </div>
                            <div 
                            className=' flex-center'
                            style={{
                                maxHeight:'75px',
                                height:'75px',
                                }}>
                                {
                                    styleValueBox == "parameterOuterDivRangeLSevereLux" ?
                                    <FaMoon style={{fontSize:"3rem", color:"#606444"}}/> :
                                    styleValueBox == "parameterOuterDivRangeHSevereLux" ?
                                    <FaSun style={{fontSize:"3rem", color:"#FF4500"}}/> :
                                    styleValueBox == "parameterOuterDivRangeGoodLux"?
                                    <FaCloudSun style={{fontSize:"3rem", color:"#F89C3C"}}/> :
                                    <span className={styleValueText}>--</span>

                                }
                            </div>
                        </div>
                    </div>
                )
            }
        }
        
        else {
            if (listView) {
                return (
                    <div key={singleParam} onClick={()=>onChangeSelectedQuickTrackParam(singleParam)} className='flex justify-between rounded bg-white w-100 py-1 px-2 listView' style={{borderColor: quickTrackParam == singleParam ? '#1875f6aa' : 'transparent'}}>
                            <div className='flex gap-2 flex-center'>
                                <span className={`rounded flex-center shadow-md p-1`} style={{width:"50px", fontSize:"1.3rem"}}>
                                    {renderParamSymbolBasedOnType(singleParam)} 
                                </span>
                                <div className='text-start'>
                                    {renderParamNameBasedOnType(singleParam)}
                                 </div>
                            </div>
                            <div className='flex gap-2 px-2 flex-center'>
                                    <span className={styleValueText}>{singleValue == null ? "--" : singleValue}</span>
                                    <small className="" >
                                        ({getUnitsBasedOnParamtype(singleParam, singleValue)})
                                    </small>
                            </div>
                    </div>
                )
    
            } else {
                return (
                    <div key={singleParam} className={finalStyleValueBox}>
                        <div onClick={()=>onChangeSelectedQuickTrackParam(singleParam)} className={`${quickTrackParam == singleParam && 'shadow-md'} gridView `} style={{borderColor: quickTrackParam == singleParam ? '#1875f6aa' : 'transparent'}}>
                            <div className='px-1 text-start '>
                                <div className='flex justify-between ' style={{padding:'0px'}}>

                                    <div className='flex flex-wrap align-items-baseline'>
                                        <span className={styleValueText} >
                                            {singleValue == null ? "--" : singleValue}
                                        </span>
                                        <span className='ps-1'>
                                            ({getUnitsBasedOnParamtype(singleParam, singleValue)})
                                            {
                                                (singleParam == RNFL && singleValue == 0 )&& 
                                                <span className='ps-2'>
                                                    ({t(IDS_NoRain)})
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div >
                                        <span id="toggleSpan" className="tooltipForRangeIcon2" onClick={ () => toggleFromIcon(singleParam, singleValue)}>
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="rangeIcon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="15" cy="12" r="1"></circle>
                                                <circle cx="15" cy="5" r="1"></circle>
                                                <circle cx="15" cy="19" r="1"></circle>
                                            </svg>
                                            <span className="tooltiptextForRangeIcon">{t(IDS_DetailsAndRanges)}</span>
                                        </span>
                                    </div>
                                </div>
    
                                <div className='text-start flex flex-wrap align-items-baseline gap-1'>
                                    {renderParamNameBasedOnType(singleParam)} 
                                    <small>
                                        ({renderParamSymbolBasedOnType(singleParam)})
                                    </small>
                                </div>
                            </div>
                            <div 
                            className=' flex items-end'
                            style={{
                                // minHeight:lastHourData && lastHourData?.lastSixTimes.length > 0 ? '40%' : '25%',
                                // minHeight:'40%',
                                paddingTop: "5px",
                                // maxHeight:'40%',
                                maxHeight:'75px',
                                height:'75px',
                                display:'flex',
                                // width:"100%",
                                // alignItems:"self-end",
                                justifyContent:"center"
                                }}>
                                
                                <VcSimpleBarChart  getUnits={getUnits} getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange} paramName={singleParam} paramTimes={lastHourData?.lastSixTimes} paramData={lastHourData?.lastSixDataObj[singleParam]}/>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    const renderCriticalAlertIcon = (singleParam) => {
        switch(singleParam) {
            case LPG:
                return <GiPoisonGas style={{fontSize: '1.5rem', color: '#C00000'}}/> 
            case SMOKE:
                return <WiFire style={{fontSize: '1.5rem', color: '#C00000'}}/>
            default:
                // console.error(`Unable to get Unit Text for specified ParamName. Unknown Param Type: ${singleParam}`);
                return ""; // Return empty string
        }
    }

    const renderCriticalAlertText = (singleParam) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        switch(singleParam) {
            case LPG:
                return t(IDS_LPGLeakDtTm) //'LPG/LNG' Leakage Detected At:
            case SMOKE:
                return t(IDS_SmokeDetectDtTm) // Smoke Detected (Possible Fire) At:
            default:
                // console.error(`Unable to get Unit Text for specified ParamName. Unknown Param Type: ${singleParam}`);
                return ""; // Return empty string
        }
    }


    const renderCriticalAlertTime = (singleParam, CreatedByDeviceTime) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  
        
        let strCriticalAlertDtTm = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(CreatedByDeviceTime);
        let strLocalCriticalAlertDtTm = convertLocalDateToDisplayToday(strCriticalAlertDtTm);
        let strLocalCriticalAlertDtTmToBeDisplayed = strLocalCriticalAlertDtTm.split(" ");
        
        // return <p>{strLocalCriticalAlertDtTmToBeDisplayed}</p>;
        return <div> &nbsp; {strLocalCriticalAlertDtTmToBeDisplayed[0].toLowerCase() == "Today,".toLowerCase() ? 
                                                                                            t(IDS_TodaySearch) : 
                                                                                            strLocalCriticalAlertDtTmToBeDisplayed[0]} {strLocalCriticalAlertDtTmToBeDisplayed[1]}
               </div>
    }

    const renderCriticalAlerts = (singleParam, CreatedByDeviceTime) => {

        if(singleParam == null || CreatedByDeviceTime == null){
            return;
        }

        return(
            // <div className="col-xs-12 col-sm-12 col-md-6 offset-xs-3 dateForFireAlarmAndGasLeaked flex items-center gap-2 px-2 pb-1" >
            <div className="col-xs-12 col-sm-12 col-md-6 offset-xs-3 dateForFireAlarmAndGasLeaked flex items-center gap-2 px-2 py-2" >
                <span className='jiggle flex flex-center'>
                    <TbAlertTriangle style={{color:"#fff"}}/>
                </span>
                {renderCriticalAlertText(singleParam)}
                {renderCriticalAlertTime(singleParam, CreatedByDeviceTime)}
                <span className='bg-white p-1 rounded-full'>
                    {renderCriticalAlertIcon(singleParam)}
                </span>
            </div>  
        );
    }
    
    const mvRenderCriticalAlerts = (singleParam, CreatedByDeviceTime) => {

        return(
            <div className="col-xs-12 col-sm-12 col-md-6 offset-xs-3 mvDateForFireAlarmAndGasLeaked">
                <div key={singleParam} style={{display:"flex", justifyContent:"center", alignItems:"center", margin:"0rem 0rem"}} >

                    <div style= {{marginRight: "0.5rem"}}>{renderCriticalAlertIcon(singleParam)}</div>
                
                    <div> 
                        <div>
                            {renderCriticalAlertText(singleParam)}
                        {/* </div>
                        <div> */}
                            {renderCriticalAlertTime(singleParam, CreatedByDeviceTime)}
                        </div>
                    </div>
                </div>  
            </div>
        );
    }

    const restrictParamValIfGoesBeyondMinOrMax = (singleValue, singleParam) => {
       
        let modifiedState = state;
       
        if(singleValue == null) {
            // Assign default value of '--';
            singleValue = '--';

            // console.log(`Should not happen. Value is missing for Param ${singleParam} for Device ${state.selectedTreeNodeTitle}`);                                
        } else if ( singleParam == WD ) {
            // When wind direction value is not proper
            singleValue = (singleValue < 0 || singleValue > 360) ? "--" : singleValue;
        } else  {

            let paramAttributes = modifiedState.relevantModelInfo[singleParam];
            let bIsParamNonCalibrated = ( paramAttributes != null
                                          && paramAttributes[NO_CALIB] != null
                                        ) ? paramAttributes[NO_CALIB] : false;

            if(singleValue < modifiedState.relevantModelInfo[singleParam]["RangeMin"] && bIsParamNonCalibrated == false) {
                singleValue = modifiedState.relevantModelInfo[singleParam]["RangeMin"];
            } else if(singleValue > modifiedState.relevantModelInfo[singleParam]["RangeMax"] && bIsParamNonCalibrated == false){
                singleValue = modifiedState.relevantModelInfo[singleParam]["RangeMax"];
            }
        }
        return singleValue;
    }

    const showRangeNameAndColor = (DispRange, selectedParamToViewRangeDetails) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;           

        let retRangeName = '';
        let retRangeBgColor = '';
        let retRangeFontColor = '';

        switch(DispRange) {
            case DISP_LOW_SEVERE_RANGE:
                retRangeName = t(IDS_RangeLowSevere);
                retRangeBgColor = selectedParamToViewRangeDetails == LUX ? "#606444":"#C00000";
                retRangeFontColor = selectedParamToViewRangeDetails == LUX ? "white":"lightGrey";
                break;
            case DISP_LOW_VERY_POOR_RANGE:
                //retRangeName = `${t(IDS_Good)} / ${t(IDS_Satisfactory)}`;
                retRangeName = t(IDS_RangeLowVeryPoor);
                retRangeBgColor = "red";
                retRangeFontColor = "lightGrey";
                break;
            case DISP_GOOD_RANGE:
                //retRangeName = `${t(IDS_Good)} / ${t(IDS_Satisfactory)}`;
                retRangeName = t(IDS_Good);
                retRangeBgColor = selectedParamToViewRangeDetails == LUX ? "#F89C3C":"#50C878";
                // retRangeFontColor = selectedParamToViewRangeDetails == LUX ? "white":"black";
                break;
                
            case DISP_SATISFACTORY_RANGE:
                retRangeName = t(IDS_Satisfactory);
                // retRangeBgColor = "#9acd32";
                // retRangeBgColor = "#9acd32";
                retRangeBgColor = "#9ACD32";
                retRangeFontColor = "black";
                break;
            case DISP_MODERATE_RANGE:
                retRangeName = t(IDS_Moderate);
                // retRangeBgColor = "yellow";
                // retRangeBgColor = "#FFDF00";
                retRangeBgColor = "#FFD700";
                retRangeFontColor = "black";
                break;
            case DISP_HIGH_SEVERE_RANGE:
                retRangeName = (selectedParamToViewRangeDetails == TEMP || selectedParamToViewRangeDetails == HUM) ? t(IDS_RangeHighSevere) : (selectedParamToViewRangeDetails == AQI || selectedParamToViewRangeDetails == CAQI) ? t(IDS_SevereAqi) : t(IDS_Severe);
                // retRangeBgColor = "#C00000";
                // retRangeFontColor = "lightGrey";
                retRangeBgColor = selectedParamToViewRangeDetails == LUX ? "#FF4500":"#C00000";
                retRangeFontColor = selectedParamToViewRangeDetails == LUX ? "white":"lightGrey";
                break;
            case DISP_POOR_RANGE:
                retRangeName = (selectedParamToViewRangeDetails == AQI || selectedParamToViewRangeDetails == CAQI) ? t(IDS_PoorAqi) : t(IDS_Poor);
                retRangeBgColor = "orange";
                retRangeFontColor = "black";
                break;
            case DISP_VERY_POOR_RANGE:
                retRangeName = (selectedParamToViewRangeDetails == AQI || selectedParamToViewRangeDetails == CAQI) ? t(IDS_VeryPoorAqi  ) : t(IDS_VeryPoor);;
                retRangeBgColor = "red";
                retRangeFontColor = "lightGrey";
                break;
            default:
                // console.error(`Unable to get DispRangeName. Unknown Range Type: ${DispRange}`);
                retRangeName = IDS_NoRange;
                retRangeBgColor = '';
                retRangeFontColor = "";
                break;
            }
        return {retRangeName: retRangeName, retRangeBgColor: retRangeBgColor, retRangeFontColor: retRangeFontColor};

    }

    const showIconAndLevelForOdour = (DispRange) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;           

        let retRangeIcon = '';
        let retRangeName = '';

        switch(DispRange) {
            case DISP_GOOD_RANGE:
                retRangeName = t(IDS_AlertLevel1);
                // retRangeIcon = <i class="fa-solid fa-face-grin-wide"></i>
                // <i class="fa-solid fa-face-smile-beam" style={{fontSize: '1.5rem', color:"#50C878"}}></i>
                retRangeIcon = <FaGrinBeam style={{fontSize: '1.5rem', color:"#50C878"}}/>;
                break;
            case DISP_SATISFACTORY_RANGE:
                retRangeName = t(IDS_AlertLevel2);
                // retRangeIcon = <FaSmile style={{fontSize: '1.5rem', color:"#9acd32"}}/>;
                retRangeIcon = <FaSmile style={{fontSize: '1.5rem', color:"#9ACD32"}}/>;
                break;
            case DISP_MODERATE_RANGE:
                retRangeName = t(IDS_AlertLevel3);
                retRangeIcon = <FaMeh style={{fontSize: '1.5rem', color:"#FFD700"}}/>;
                break;
            case DISP_POOR_RANGE:
                retRangeName = t(IDS_AlertLevel4);
                retRangeIcon = <FaFrownOpen style={{fontSize: '1.5rem', color:"orange"}}/>;
                break;
            case DISP_VERY_POOR_RANGE:
                retRangeName = t(IDS_AlertLevel5);
                retRangeIcon = <FaSadTear style={{fontSize: '1.5rem', color:"red"}}/>;
                break;
            case DISP_HIGH_SEVERE_RANGE:
                retRangeName = t(IDS_AlertLevel6);
                retRangeIcon = <FaDizzy style={{fontSize: '1.5rem', color:"#C00000"}}/>;
                break;
            default:
                // console.error(`Unable to get DispRangeName and Icon. Unknown Range Type: ${DispRange}`);
                retRangeName = '';
                retRangeIcon = <div></div>
                break;
            }
        return {retRangeName: retRangeName, retRangeIcon: retRangeIcon};

    }

    const showRangesForSelectedParam = (DispRange, key) => {

        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 
        let RangeNameToShow = null;
        let currentLanguage = appRelevantDataContextValue.language.languageToViewIn;

        if(key.includes(ONWARDS)){
            modifiedState.highSevereStartValueInPopUp = key.split(" ")[0];
        }

        if(key.includes(UPTO)){
            modifiedState.goodEndValueInPopUp = key.split(" ")[1];
        }

        let selectedRangeInfo = showRangeNameAndColor(DispRange, modifiedState.selectedParamToViewRangeDetails);

        if(modifiedState.selectedParamToViewRangeDetails == TEMP){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_ColdAndChilly);
                    break;
                //case (`${t(IDS_Good)} / ${t(IDS_Satisfactory)}`):
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Comfortable);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_Warm);
                    break;
                case t(IDS_RangeHighSevere):
                    RangeNameToShow = t(IDS_Hot);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 

        } else if(modifiedState.selectedParamToViewRangeDetails == HUM) {

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_Dry);
                    break;
                //case (`${t(IDS_Good)} / ${t(IDS_Satisfactory)}`):
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Comfortable);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_Humid);
                    break;
                case t(IDS_RangeHighSevere):
                    RangeNameToShow = t(IDS_Sticky);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            }
        } else if(modifiedState.selectedParamToViewRangeDetails == NH3OD) {

            switch(selectedRangeInfo.retRangeName) {
                //case (`${t(IDS_Good)} / ${t(IDS_Satisfactory)}`):
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_BarelySensedInSingleDeviceData);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_WeakOdourInSingleDeviceData);
                    break;
                case t(IDS_Satisfactory):
                    RangeNameToShow = t(IDS_SatisfactoryInSingleDeviceData);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_EasilyRecognizedInSingleDeviceData);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_StrongOdourInSingleDeviceData);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_PungentInSingleDeviceData);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(modifiedState.selectedParamToViewRangeDetails == RNFL){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_LightRain);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_ModerateRain);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_HeavyRain);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_VeryHeavyRain);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_TorrentialRain);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(modifiedState.selectedParamToViewRangeDetails == NOISE){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_LowNoiseLevel);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_MediumNoiseLevel);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HighNoiseLevel);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(modifiedState.selectedParamToViewRangeDetails == LUX){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_LowSevere):
                RangeNameToShow = t(IDS_LowLightIntensity);
                break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_MediumLightIntensity);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HighLightIntensity);
                    break;
                default:
                    RangeNameToShow =  t(IDS_LowLightIntensity)
                    // RangeNameToShow = '';
                    break;
            } 
        } 
        else if(modifiedState.selectedParamToViewRangeDetails == O2){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_Normal);
                break;
            case t(IDS_RangeLowSevere):
                RangeNameToShow = t(IDS_Low);
                break;
            case t(IDS_Severe):
                RangeNameToShow = t(IDS_High);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } else if(modifiedState.selectedParamToViewRangeDetails == DLV){ 

            switch(selectedRangeInfo.retRangeName) {
            case t(IDS_Good):
                RangeNameToShow = t(IDS_GoodVisibility);
                break;
            case t(IDS_Moderate):
                RangeNameToShow = t(IDS_ModerateVisibility);
                break;
            case t(IDS_Poor):
                RangeNameToShow = t(IDS_FairVisibility);
                break;
            case t(IDS_VeryPoor):
                RangeNameToShow = t(IDS_PoorVisibility);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } 
        else if(modifiedState.selectedParamToViewRangeDetails == WS){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_CalmBreeze);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_GentleBreeze);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_ModerateBreeze);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_StrongGale);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Storm);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(modifiedState.selectedParamToViewRangeDetails == CL || modifiedState.selectedParamToViewRangeDetails == CH3SH){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_VeryLow);
                    break;
                case t(IDS_Satisfactory):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_Moderate);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_Poor);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Severe);
                    break;
                default:
                    RangeNameToShow = t(IDS_Low);
                    break;
                } 
        } else if(modifiedState.selectedParamToViewRangeDetails == UV){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_Moderate);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_High);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_VeryHigh);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Extreme);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(modifiedState.selectedParamToViewRangeDetails == AP){ 

            switch(selectedRangeInfo.retRangeName) {
              
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_ExtremeLow);
                    break;
                case t(IDS_RangeLowVeryPoor):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Normal);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_High);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_ExtremeHigh);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } 
        
        else {
            RangeNameToShow = selectedRangeInfo.retRangeName;
        }
    
        let RangeBgColorToShow = selectedRangeInfo.retRangeBgColor;
        let RangeFontColorToShow = selectedRangeInfo.retRangeFontColor;

        let RangeInfoToShowForNH3OD = null;
        let RangeNameToShowForNH3OD = null;
        let RangeIconToShowForNH3OD = <div></div>;

        if(modifiedState.selectedParamToViewRangeDetails != null && modifiedState.selectedParamToViewRangeDetails == NH3OD){
            RangeInfoToShowForNH3OD = showIconAndLevelForOdour(DispRange);
            RangeNameToShowForNH3OD = RangeInfoToShowForNH3OD.retRangeName;
            RangeIconToShowForNH3OD = RangeInfoToShowForNH3OD.retRangeIcon;
        }

        return(
            <div key={key}>
                <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                    <div style={{flex:"6", textAlign: "left"}}>
                        {/* <label className="adduser-form-label" style={{fontWeight: "bold"}}> */}
                        <label className="labelForSingleDeviceDataPopup" style={{fontWeight: "bold"}}>
                            {RangeNameToShow} :
                        </label>
                    </div>

                    <div style={{flex:"1", display: RangeInfoToShowForNH3OD != null ? 'block' : 'none', marginRight:"0.5rem"}}>
                        {RangeIconToShowForNH3OD}
                    </div>
                    
                    <div style={{flex:RangeInfoToShowForNH3OD != null ? "5" : "6", textAlign: "left", height: "0%",
                                 color: RangeFontColorToShow, backgroundColor: RangeBgColorToShow}}>
                        <label className="adduser-form-label" style = {{fontSize: "0.8rem"}} >
                            {(modifiedState.selectedParamToViewRangeDetails == NH3OD && RangeInfoToShowForNH3OD != null) ? 
                                RangeNameToShowForNH3OD
                            :
                                key.includes(ONWARDS) ?  `${modifiedState.highSevereStartValueInPopUp} ${t(IDS_Onwards)}` : 
                                (key.includes(UPTO) && currentLanguage != 'en') ? `${modifiedState.goodEndValueInPopUp} ${t(IDS_Upto)}` :  key
                            }
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    const renderDtTmToDisplay = (receivedLogTime) => {
        // To avoid showing Date as the "NaN" which might occur during an initial rendering.
        let strDateTimeLocale = "";
        let strLocaleDateTimeToBeDisplayed = "";
        let splitedDateTime = ["--", ""];

        if(receivedLogTime != null && receivedLogTime.length > 0) {
            strDateTimeLocale = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedLogTime);

            strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(strDateTimeLocale);
            // As we want a date to be displayed on CurrentDateTime here we are splitting in itno a separate date and time.
            splitedDateTime = strLocaleDateTimeToBeDisplayed.split(" ");
        } else {
            // No need further processing.
        }
        return splitedDateTime;
    }

    const toSearchOnMap = () => {
        // context.toSearchDeviceID = state.selectedTreeNodeID
        dispatch(setToSearchDeviceIDOnMap(state.selectedTreeNodeID))
        navigate('/mapsonlogin')
        
    }

    const getRangeColor = () => {
        if(state.Range == "Good") {
            return 'darkgreen';
        } else if (state.Range == "Satisfactory") {
            return '#9acd32';
        }
        else if (state.Range == "Moderate") {
            return '#F4C430';
        }
        else if (state.Range == "Poor") {
            return '#ffa500';
        }
        else if (state.Range == "VeryPoor") {
            return '#ff0000';
        }
        else if (state.Range == "Severe") {
            return '#c00000';
        } else {
            return 'purple';
        }
    }
 
    let modifiedState = state;

    let nameOfParamToShowInPopup = (modifiedState.selectedParamToViewRangeDetails != null && 
                                    modifiedState.selectedParamToViewRangeDetails.length > 0) ? 
                                    renderParamNameBasedOnType(modifiedState.selectedParamToViewRangeDetails)['props']['children'] : "";

    let shortNameOfParam = (modifiedState.selectedParamToViewRangeDetails != null && 
                            modifiedState.selectedParamToViewRangeDetails.length > 0) ? 
                            renderParamSymbolBasedOnType(modifiedState.selectedParamToViewRangeDetails)['props']['children'] : "";

   
    let { SingleDeviceParamValue, SingleDeviceParamSequence, stdAirQualityRanges, relevantModelInfo } = state;
    let Good = state.Good;
    let Satisfactory = state.Satisfactory;
    let LowVeryPoor = state.LowVeryPoor;
    let Moderate = state.Moderate;
    let Poor = state.Poor;
    let VeryPoor = state.VeryPoor;
    let Severe = state.Severe;
    let Others = state.Others;
    let nullValue = state.nullValue;
    Good = [];
    Satisfactory = [];
    LowVeryPoor = [];
    Moderate = [];
    Poor = [];
    VeryPoor = [];
    Severe = [];
    nullValue = [];
    Others = [];
    let noOfParams;
    let receivedLogTime = state.LogTime;
    
    // let strDateTimeLocale = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedLogTime);

    // let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(strDateTimeLocale);
    // // As we want a date to be displayed on CurrentDateTime here we are splitting in itno a separate date and time.
    // let splitedDateTime = strLocaleDateTimeToBeDisplayed.split(" ");

    // // To avoid showing Date as the "NaN" which might occur during an initial rendering.
    // let strDateTimeLocale = "";
    // let strLocaleDateTimeToBeDisplayed = "";
    // let splitedDateTime = ["--", ""];

    // if(receivedLogTime != null && receivedLogTime.length > 0) {
    //     strDateTimeLocale = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedLogTime);

    //     strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(strDateTimeLocale);
    //     // As we want a date to be displayed on CurrentDateTime here we are splitting in itno a separate date and time.
    //     splitedDateTime = strLocaleDateTimeToBeDisplayed.split(" ");
    // } else {
    //     // No need further processing.
    // }
    
    let splitedDateTime = renderDtTmToDisplay(receivedLogTime);

    let HasSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.hasDevc;
    let SelectedNodeContainsChildNodes = appRelevantDataContextValue.selectedNodeInfo.containsChildNode;
    let selectedTreeParentNodeID = appRelevantDataContextValue.selectedNodeInfo.parentID;
    let IsSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.isDevc;
    let arrDeviceType = appRelevantDataContextValue.selectedNodeInfo.deviceType;
    let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    let isContainsChildNode = appRelevantDataContextValue.selectedNodeInfo.containsChildNode;
    let isRootSelected = appRelevantDataContextValue.selectedNodeInfo.isRoot;

    // md break point
    let isMobileScreen = window.innerWidth <= 768;
    return(

        (state.selectedTreeNodeID == null || state.selectedTreeNodeID.length <=0)
        ?
            <div className=" sddSingleParam">
                <VcLoader/>
                {/* Don't know when it is required / It is shown while moving from ToiletNodeDashBoard to SingleDeviceData */}
            </div>
        :
        (state.selectedTreeNodeID.length > 0 && state.selectedTreeNodeID == NO_DEVC_FOUND_FOR_LOGGED_IN_USER)
        ?
            <div className=" sddSingleParam">
                <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 fs-5 d-flex justify-content-center align-items-center">
                        {t(IDS_GeneralUser)}
                    <div style={{margin:"0.5rem"}}>
                        <VcSetupDevice/>
                    </div>
                </div>
            </div>

        :
        (!HasSelectedNodeDevices && !IsSelectedNodeDevices && !isRootSelected && isContainsChildNode)
        ?
        <div className="  sddSingleParam">
            <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 ">
                    {t(IDS_DefInfoMsgForTreeNodeInDevcPg, state.selectedTreeNodeTitle)}
            </div>
        </div>
        :
        // If the dveice has been blocked due to non-payment of some cost.
        (state.isDeviceBlocked == true)
        ?
        <div className="  sddSingleParam">
            <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 ">
                    {t(IDS_DevcBlockedDueToNonPaymentOfSomeCost)}
            </div>
        </div>
        :
        ((HasSelectedNodeDevices && state.bSelectedNodeContainsDevcToShowData) || (state.selectedTreeNodeIsDevice))
        ?
        <div className="px-1 pb-2 " >
            { (state.bCheckDeviceBlockedStatus == true && state.havingPvgToViewBlockedDeviceData == true) &&
                <div style={{color:"green", marginTop:"0.1rem", fontSize:"0.8rem"}}>Note: The Device is blocked or Rental Period is expired. Production/Support Team can view the data.</div>
            }
            <div className="flex flex-col flex-md-row items-start text-left justify-content-md-between py-2 w-100">
                <span className='text-left flex align-items-center gap-1' style={{minHeight: "30px"}}>
                    <small>{props.fullPath}</small>
                    <small className='selectedNodeTitle' style={{fontWeight:"600"}}>
                        {state.selectedTreeNodeTitle}
                    </small>

                    {/* <small className='selectedNodeTitle' style={{fontWeight:"600"}}>
                        {props.fullPath && props.fullPath.length > 0
                            ? `${props.fullPath} ${state.selectedTreeNodeTitle}`
                            : state.selectedTreeNodeTitle}
                    </small> */}
                </span>
                <small className='flex gap-2 justify-between items-center'>
                    <span >
                        {t(IDS_LastDataReceivedAt)} 
                        <span className='selectedNodeTitle ps-1'>
                            {splitedDateTime[0].toLowerCase() == "Today,".toLowerCase() ? t(IDS_TodaySearch) : splitedDateTime[0]} {splitedDateTime[1]}
                        </span> 
                    </span>
                    <button className='refreshButtonStyle'
                        onClick={onClickRefreshButton} 
                        title={t(IDS_RefreshData)}
                    >
                        <i className="fa fa-refresh flex-center" id='SingleDeviceDataRefresh'
                        style={{ paddingLeft: "0", fontSize: "20px"}} 
                        aria-hidden="true"></i>
                    </button>
                </small>
            </div>
            

            <div className='d-flex gap-2 px-2 px-md-0 flex-col'>
                   {state.arrTodaysPplCount.length > 0 &&  <VcPFC PfcParamModelInfo={state.PfcParamModelInfo} pfcData={state.arrTodaysPplCount[0]}/>}
                    <VcDailyChart 
                        dailyChartFlag={state.dailyChartFlag} 
                        isDeviceBlocked={state.bCheckDeviceBlockedStatus}
                        isUserHavingPvgToViewBlockedData={state.havingPvgToViewBlockedDeviceData}
                        renderParamNameBasedOnType={renderParamNameBasedOnType}
                        getUnits={getUnits} 
                        isPFC={true} 
                        getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange} 
                        SingleDeviceParamSequence={state.SingleDeviceParamSequence}
                        splitedDateTime={splitedDateTime} 
                    />
            </div>
        </div>

        : 
        // <div className="container-fluid padding" >
        <div className="px-1 pb-2" >
            {/* Show blocked Devices Data to Production/Support Users. */}
            { state.bCheckDeviceBlockedStatus == true && state.havingPvgToViewBlockedDeviceData == true ?
                <div style={{color:"green", marginTop:"0.1rem", fontSize:"0.8rem"}}>Note: The Device is blocked or Rental Period is expired. Production/Support Team can view the data.</div>
            : 
                null
            }

            <div className="flex flex-col flex-md-row items-start justify-content-md-between py-2 w-100" >
               
                <span className='text-left flex align-items-center gap-1'>
                    <small>{props.fullPath}</small>
                    <small className='selectedNodeTitle' style={{fontWeight:"600"}}>
                        {state.selectedTreeNodeTitle}
                    </small>

                    {/* <small className='selectedNodeTitle' style={{ fontWeight: "600" }}>
                        {props.fullPath && props.fullPath.length > 0
                            ? `${props.fullPath} ${state.selectedTreeNodeTitle}`
                            : state.selectedTreeNodeTitle}
                    </small> */}


                </span>
                <small className='flex gap-2 justify-between items-center'>
                    <span>
                        {t(IDS_LastDataReceivedAt)} 
                        <span className='selectedNodeTitle ps-1'>
                            {splitedDateTime[0].toLowerCase() == "Today,".toLowerCase() ? t(IDS_TodaySearch) : splitedDateTime[0]} {splitedDateTime[1]}
                        </span> 
                    </span>
                    <button className='refreshButtonStyle'
                        onClick={onClickRefreshButton} 
                        title={t(IDS_RefreshData)}
                    >
                        <i className="fa fa-refresh flex-center" id='SingleDeviceDataRefresh'
                        style={{ paddingLeft: "0", fontSize: "20px"}} 
                        aria-hidden="true"></i>
                    </button>
                </small>
            </div>
            
                
            {modifiedState.singleDeviceImportantAlertInfo.length > 0 &&
                <div className='px-2 py-1  rounded flex flex-md-row d-md-flex d-none flex-column flex-center my-1 gap-2'>
                    {modifiedState.AlertBasedParam.map((singleAlertBasedParam) => { 
                        let SingaleParamCreatedAlertTime = null;         
                        modifiedState.singleDeviceImportantAlertInfo.map((ObjAlertBasedParam) => {
                            if(singleAlertBasedParam == ObjAlertBasedParam["MeasuredParam"]) {
                                SingaleParamCreatedAlertTime = ObjAlertBasedParam["CreatedByDeviceTime"];
                            }
                        })
                        let bIsAlertBasedParam = modifiedState.AlertBasedParam.includes(singleAlertBasedParam);

                        if(bIsAlertBasedParam) {
                            return(
                                renderCriticalAlerts(singleAlertBasedParam, SingaleParamCreatedAlertTime)
                            )
                        }
                    })}
                </div>
            }
            
            {state.modalFromIcon == true &&
                <VcDetailsAndRanges arrOfRanges={state.arrOfRanges} arrOfDispRangeValues={modifiedState.arrOfDispRangeValues} selectedParamToViewRangeDetails={modifiedState.selectedParamToViewRangeDetails}
                    nameOfParamToShowInPopup={nameOfParamToShowInPopup} shortNameOfParam={shortNameOfParam} styleToBeAppliedToPopup={modifiedState.styleToBeAppliedToPopup}  
                    selectedParamsCurrentValue={modifiedState.selectedParamsCurrentValue} modalFromIcon={modifiedState.modalFromIcon} showImpactsOfParam={modifiedState.showImpactsOfParam}
                    backdrop={modifiedState.backdrop} singleDeviceMeasuredParams = {state.SingleDeviceData[0].MeasuredParams} toggleModal={toggleFromIcon} invokedFrom={"singleDeviceData"}
                />      
            }

            {/* <Modal size="l" isOpen={state.modalFromIcon} toggle={toggleFromIcon} backdrop={state.backdrop}>
                <ModalHeader toggle={toggleFromIcon} >{t(IDS_DetailsAndRangesOfSensor)}</ModalHeader>
                <ModalBody>
                    <div className={modifiedState.styleToBeAppliedToPopup}>
                    
                        <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                            <div style={{flex:"1", textAlign: "center"}}>
                                <label className="adduser-form-label" style={{fontWeight: "bold", fontSize: "1.2rem", color: "#606060"}}>{t(IDS_ParameterValue)}</label>
                            </div>
                        </div>
                        <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                            <div style={{flex:"1", textAlign: "left", marginBottom: "0.8rem"}}>
                                <label className="adduser-form-label" style={{fontWeight: "bold", color: "black"}}>
                                    {nameOfParamToShowInPopup} :
                                </label>
                            </div>
                            <div style={{flex:"1", textAlign: "left", backgroundColor: ""}}>
                                <label className="adduser-form-label">
                                    {modifiedState.selectedParamsCurrentValue}&nbsp;   
                                    {shortNameOfParam != AQI &&
                                        ((modifiedState.selectedParamToViewRangeDetails != null && 
                                        modifiedState.selectedParamToViewRangeDetails.length > 0) ? 
                                        getUnitsBasedOnParamtype(modifiedState.selectedParamToViewRangeDetails) : "")
                                    }
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="boxForRangesForSensors">
                        <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                            <div style={{flex:"1", textAlign: "center", marginBottom: "0.8rem"}}>
                                <div style={{display: "inline-block"}}>
                                    <label className="adduser-form-label" style={{fontWeight: "bold", fontSize: "1.2rem"}}>
                                        {nameOfParamToShowInPopup}{t(IDS_ParamsRange)} ({shortNameOfParam})
                                    </label>
                                </div>

                                <div style={{marginLeft: "1rem", display: (shortNameOfParam == "Virus" || shortNameOfParam == "Mould") ? "inline-block" : "none"}}>
                                    <span id="toggleSpan" className="tooltipForRangeIcon2" onClick={ () => toggleFromInfoButton(shortNameOfParam)}>
                                        <FiInfo className="rangeIcon" style={{fontSize: "1.3rem", fontWeight: "bold"}}/>
                                        <span className="tooltiptextForRangeIcon">
                                            {shortNameOfParam == "Virus" ? t(IDS_WhatIsVirusRiskIndex) : shortNameOfParam == "Mould" ? t(IDS_WhatIsMouldRiskIndex) : ""}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div>
                            {state.arrOfRanges.map( (DispRange, index) => {
                                return(
                                    showRangesForSelectedParam(DispRange, modifiedState.arrOfDispRangeValues[index])
                                );                            
                            })
                            }  
                        </div>

                    </div>
                </ModalBody>
            </Modal> */}
{/* 
            <Modal size="xl" isOpen={state.modalFromInfoButton} toggle={toggleFromInfoButton} backdrop={state.backdropForInfoButton}>
                <ModalHeader toggle={toggleFromInfoButton}>
                    {modifiedState.paramToShowDetailedInfo == "Virus" ? t(IDS_WhatIsVirusRiskIndex) : modifiedState.paramToShowDetailedInfo == "Mould" ? t(IDS_WhatIsMouldRiskIndex) : ""}
                </ModalHeader>
                <ModalBody>
                    <VcVRIandMRIInfo 
                        paramToShowDetailedInfo={modifiedState.paramToShowDetailedInfo}
                    />
                </ModalBody>
            </Modal> */}

            {/* <div className="mvVcSingleDeviceData">
                <div style={{fontSize: "0.8rem"}}>
                    {modifiedState.singleDeviceImportantAlertInfo.length > 0 ?
                        modifiedState.AlertBasedParam.map((singleAlertBasedParam) => { 
                            let SingaleParamCreatedAlertTime = null;         
                            modifiedState.singleDeviceImportantAlertInfo.map((ObjAlertBasedParam) => {
                                if(singleAlertBasedParam == ObjAlertBasedParam["MeasuredParam"]) {
                                    SingaleParamCreatedAlertTime = ObjAlertBasedParam["CreatedByDeviceTime"];
                                }
                            })
                            let bIsAlertBasedParam = modifiedState.AlertBasedParam.includes(singleAlertBasedParam);

                            if(bIsAlertBasedParam) {
                                return(
                                    mvRenderCriticalAlerts(singleAlertBasedParam, SingaleParamCreatedAlertTime)
                                )
                            }
                        })
                    :
                    null
                    }
                </div> 
            </div>  */}
            {modifiedState.singleDeviceImportantAlertInfo.length > 0 &&
            <div className='px-2 py-1  rounded flex flex-md-row d-md-none d-flex w-100 flex-column flex-center m-1 gap-2'>
                {modifiedState.AlertBasedParam.map((singleAlertBasedParam) => { 
                    let SingaleParamCreatedAlertTime = null;         
                    modifiedState.singleDeviceImportantAlertInfo.map((ObjAlertBasedParam) => {
                        if(singleAlertBasedParam == ObjAlertBasedParam["MeasuredParam"]) {
                            SingaleParamCreatedAlertTime = ObjAlertBasedParam["CreatedByDeviceTime"];
                        }
                    })
                    let bIsAlertBasedParam = modifiedState.AlertBasedParam.includes(singleAlertBasedParam);

                    if(bIsAlertBasedParam) {
                        return(
                            renderCriticalAlerts(singleAlertBasedParam, SingaleParamCreatedAlertTime)
                        )
                    }
                })}
                
            </div>
            }


            {/* All Parameters */}

            {
                state.bDeviceTypeWLI ? 
                <div className='d-flex flex-col gap-2'>
                    <VcWLIc wliDetails={{
                            SingleDeviceParamSequence: state.SingleDeviceParamSequence, 
                            DeviceID: state.selectedTreeNodeID,
                            totalPeopleCount: state.totalPeopleCount,
                            yestTotalWaterUsage: state.yestTotalWaterUsage,
                            waterUsagePerUser: state.waterUsagePerUser,
                            isPfcDevicePresentAsSibling: state.isPfcDevicePresentAsSibling,
                            singleDeviceParamValue: state.SingleDeviceParamValue,
                    }}/>
                    <VcDailyChart 
                        dailyChartFlag={state.dailyChartFlag} 
                        isDeviceBlocked={state.bCheckDeviceBlockedStatus}
                        isUserHavingPvgToViewBlockedData={state.havingPvgToViewBlockedDeviceData}
                        renderParamNameBasedOnType={renderParamNameBasedOnType}
                        isWli={true} 
                        getUnits={getUnits} 
                        getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange} 
                        SingleDeviceParamSequence={state.SingleDeviceParamSequence} 
                        splitedDateTime={splitedDateTime} 
                    />
                </div>
                
                :state.bDeviceTypeSTLI ?
                    <div className='d-flex flex-col gap-2 '>
                        <div className='flex flex-col flex-center gap-2 rounded bg-white w-100 p-2 ' style={{height:"275px"}} >
                            <div style={{maxHeight:'30%'}}>
                                <div className='flex justify-center align-center px-1' >
                                    {renderParamSymbolBasedOnType(SLIP)}
                                </div>
                            </div>
                            <div className='stliMeter'>
                                <ReactSpeedometer
                                    fluidWidth={true}
                                    maxValue={100}
                                    value={modifiedState.sewageLevel == null ? 0 : modifiedState.sewageLevel}
                                    needleColor="steelblue"
                                    needleHeightRatio={0.7}
                                    startColor= "#f0dba6"
                                    endColor= "#412f01"
                                    segments={10}
                                    // currentValueText={`${value} %`}
                                    ringWidth={30}
                                    labelFontSize={12}
                                    valueTextFontSize={25}
                                    needleTransitionDuration={1000}
                                />
                            </div>
                        </div>
                        <VcDailyChart 
                            dailyChartFlag={state.dailyChartFlag}
                            isDeviceBlocked={state.bCheckDeviceBlockedStatus}
                            isUserHavingPvgToViewBlockedData={state.havingPvgToViewBlockedDeviceData}
                            renderParamNameBasedOnType={renderParamNameBasedOnType}
                            getUnits={getUnits} 
                            getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange} 
                            SingleDeviceParamSequence={state.SingleDeviceParamSequence} 
                            splitedDateTime={splitedDateTime} 
                        />
                    </div>
                
                
                : 
                <div className='d-flex gap-2 flex-column-reverse flex-md-row'>
                    <div className='analysis' >

                        {/* To show AQI and CAQI block*/}
                        {((SingleDeviceParamSequence.includes(AQI) || SingleDeviceParamSequence.includes(CAQI))) && 
                            <div className='d-md-flex d-none'>
                            <VcAQIBox
                                key={2}
                                SingleDeviceParamSequence={SingleDeviceParamSequence}
                                getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange}
                                renderParamNameBasedOnType ={renderParamNameBasedOnType}
                                getUnitsBasedOnParamtype = {getUnitsBasedOnParamtype}
                                SingleDeviceParamValue={SingleDeviceParamValue}
                            />      
                            </div>

                        }

                        {/* To Show Information box in Gandhved */}
                        {/* {
                        SingleDeviceParamSequence.includes(NH3OD) &&
                            <div className='bg-white rounded text-start p-2' >
                                {renderOdourMessage()}
                            </div>
                        } */}


                        {!isMobileScreen && <VcInfoBox
                            getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange}
                            SingleDeviceParamValue={state.SingleDeviceParamValue}  
                            stdAirQualityRanges={state.stdAirQualityRanges}
                            SingleDeviceParamSequence = {state.SingleDeviceParamSequence}
                        />}

                        {/* {
                            state.SingleDeviceParamValue[NH3OD] == 0 &&
                            <VcInfoBox
                            getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange}
                            SingleDeviceParamValue={state.SingleDeviceParamValue}  
                            stdAirQualityRanges={state.stdAirQualityRanges}
                            SingleDeviceParamSequence = {state.SingleDeviceParamSequence}
                        />
                        } */}

                        

                        <VcDailyChart 
                            dailyChartFlag={state.dailyChartFlag} 
                            isDeviceBlocked={state.bCheckDeviceBlockedStatus}
                            isUserHavingPvgToViewBlockedData={state.havingPvgToViewBlockedDeviceData}
                            renderParamNameBasedOnType={renderParamNameBasedOnType}
                            getUnits={getUnits} 
                            getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange} 
                            SingleDeviceParamSequence={state.SingleDeviceParamSequence}
                            splitedDateTime={splitedDateTime} 
                        />
                    

                        {/* To show track toilet div */}
                        {
                        (state.SingleDeviceParamSequence.includes(NH3OD) && state.EntityLocationIDOfSelectedDevc != null) && 
                        <div className='bg-white rounded flex flex-col items-center justify-center p-2' style={{minHeight:"50px"}}>
                            <div className='p-1 flex items-center justify-between w-100'>
                                {t(IDS_TrackOnMap)}
                                <button className='buttonStyle' onClick={toSearchOnMap}>
                                    {t(IDS_Track)}
                                </button>
                            </div>
                            <img className='w-100 blurImg ' src={MapCrop} alt="" />
                        </div>
                        }

                    </div>

                    <div className='params'>
                        {((SingleDeviceParamSequence.includes(AQI) || SingleDeviceParamSequence.includes(CAQI))) && 
                            <div className='d-md-none d-flex w-100 py-2'>
                            <VcAQIBox
                            key={1}
                                SingleDeviceParamSequence={SingleDeviceParamSequence}
                                getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange}
                                renderParamNameBasedOnType ={renderParamNameBasedOnType}
                                getUnitsBasedOnParamtype = {getUnitsBasedOnParamtype}
                                SingleDeviceParamValue={SingleDeviceParamValue}
                            />      
                            </div>

                        }
                        {isMobileScreen && <VcInfoBox
                            flag={true}
                            getParamValueTextAndBoxStyleBasedOnRange={getParamValueTextAndBoxStyleBasedOnRange}
                            SingleDeviceParamValue={state.SingleDeviceParamValue}  
                            stdAirQualityRanges={state.stdAirQualityRanges}
                            SingleDeviceParamSequence = {state.SingleDeviceParamSequence}
                        />}
                        <div className="row justify-content-center text-center " style={{position:"sticky", top:"20px"}} >
                        {
                            SingleDeviceParamSequence.map( (singleParam) => {
                                let singleValue = SingleDeviceParamValue[singleParam];
                                noOfParams = SingleDeviceParamSequence.length;
                                let AlertBasedVlaue = renderCheckIfAlertReceived(singleParam);
                                
                                if(AlertBasedVlaue["bAlertDetectedForParam"] == true) {
                                    singleValue = AlertBasedVlaue["AlertBasedSingleParamValue"];
                                } else {
                                    singleValue = singleValue;

                                }
                                let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);
                                
                                if(styleValueBox === "parameterOuterDivRangeGood" ) {
                                    Good.push(singleParam);
                                }
                                if(styleValueBox === "parameterOuterDivRangeSatisfactory" ) {
                                    Satisfactory.push(singleParam);
                                }
                                if(styleValueBox === "parameterOuterDivRangeLowVPoor" ) {
                                    LowVeryPoor.push(singleParam);
                                }
                                if(styleValueBox == "parameterOuterDivRangeModerate") {
                                    Moderate.push(singleParam);
                                }
                                if(styleValueBox === "parameterOuterDivRangePoor") {
                                    Poor.push(singleParam);
                                }
                                if(styleValueBox === "parameterOuterDivRangeVeryPoor") {
                                    VeryPoor.push(singleParam);
                                }
                                if(styleValueBox == "parameterOuterDivRangeSevere") {
                                    Severe.push(singleParam);
                                }    
                                if(styleValueBox === "parameterOuterDivWithoutRange") {
                                    nullValue.push(singleParam);
                                }
                                if(styleValueBox.includes('Lux')) {
                                    Others.push(singleParam)
                                }

                            })
                        }

                    
                    {state.Range != 'AllRange' && <div className="flex flex-col gap-2 border-1">
                            <div className='flex justify-between align-items-center padding-right-2  position-relative pb-2'>
                                <span className='flex gap-2'>
                                    <span onClick={()=>setlistView(false)} className={`${!listView && 'bg-white'} py-1 px-2 c-pointer rounded`}>
                                        <HiOutlineViewGrid />
                                    </span>
                                    <span onClick={()=>setlistView(true)} className={`${listView && 'bg-white'} py-1 px-2 c-pointer rounded`}>
                                        <FaList />
                                    </span>
                                </span>
                                <span className='absolute-center'>{t(IDS_Parameter)}</span>
                                { (state.bDeviceTypeWLI == false && state.bDeviceTypeSTLI == false) &&
                                <div className='position-relative flex flex-center filtersDivPar ' style={{zIndex:3}}>
                                    <span className='bg-white py-1 px-2 rounded'>
                                        {/* <RiFilter2Line/> */}
                                        {/* <HiFilter fill={getRangeColor()}/> */}
                                        {/* <FaFilter fill={getRangeColor()}/> */}
                                        {state.Range === "AllRange" ?
                                            <TfiFilter/>
                                            :
                                            <FaFilter fill={getRangeColor()}/>
                                            }

                                        

                                    </span>
                                    <div  className='position-absolute top-100 left-50 d-flex flex-col gap-1 filtersDiv' >
                                        <div className=""><button onClick ={RangeSevere} className="rangeSevereCategory" title={"Severe"}/></div>
                                        <div className=""><button onClick ={RangeVeryPoor} className="rangeVeryPoorCategory" title={"VeryPoor"}/></div>
                                        <div className=""><button onClick ={RangePoor} className="rangePoorCategory" title={"Poor"}/></div>
                                        <div className=""><button onClick ={RangeModerate} className="rangeModerateCategory" title={"Moderate"}/></div>
                                        <div className=""><button onClick ={RangeSatisfactory} className="rangeSatisfactoryCategory" title={"Satisfactory"}/></div>
                                        <div className=""><button onClick ={RangeGood} className="rangeGoodCategory" title={"Good"}/></div>
                                        <div className=""><button onClick = {AllRange} className="AllRangeCategory" title={"AllRange"}></button></div>
                                    </div>
                                </div>
                                    }
                            </div>
                            {/* <div className={`row ${listView ? 'row-cols-1' : 'row-cols-2' } row-cols-sm-1 row-cols-md-2 g-1 g-md-2 `} */}
                            {/* <div className={`flex flex-wrap gap-2`} */}
                            <div className={`row row-cols-2 row-cols-sm-1 row-cols-md-2 g-1 g-md-2 ${listView && 'p-1'}`}
                            style={{ maxHeight:'77vh', overflowY:'auto', overflowX:"hidden"}} >
                        {
                            state.Range == "Good" ? 
                            Good.map( (singleParam) => {
                                let noOfParams = Good.length;
                                let singleValue = SingleDeviceParamValue[singleParam];
                                let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                return(
                                    renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                )                               
                            })
                            :
                            null
                        }
                        {
                            state.Range == "Satisfactory" ? 
                            Satisfactory.map( (singleParam) => {
                                let noOfParams = Satisfactory.length;
                                let singleValue = SingleDeviceParamValue[singleParam];
                                let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                return(
                                    renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                )                               
                            })
                            :
                            null
                        } 
                        
                        {                       
                            state.Range == "Moderate" ? 
                            Moderate.map( (singleParam) => {
                                let noOfParams = Moderate.length;
                                let singleValue = SingleDeviceParamValue[singleParam];
                                let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                return(
                                    renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                )                               
                            })
                            :
                            null
                        }
                        {
                            state.Range == "Poor" ? 
                            Poor.map( (singleParam) => {
                                let noOfParams = Poor.length;
                                let singleValue = SingleDeviceParamValue[singleParam];
                                let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                return(
                                    renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                )                               
                            })
                            :
                            null
                        }

                        {
                            state.Range == "VeryPoor" ? 
                            VeryPoor.map( (singleParam) => {
                                let noOfParams = VeryPoor.length;
                                let singleValue = SingleDeviceParamValue[singleParam];
                                let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                return(
                                    renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                )                               
                            })
                            :
                            null
                        }

                        {
                            state.Range == "Severe" ? 
                            Severe.map( (singleParam) => {
                                let noOfParams = Severe.length;
                                let singleValue = SingleDeviceParamValue[singleParam];
                                let AlertBasedVlaue = renderCheckIfAlertReceived(singleParam);
                                
                                if(AlertBasedVlaue["bAlertDetectedForParam"] == true) {
                                    singleValue = AlertBasedVlaue["AlertBasedSingleParamValue"];
                                } else {
                                    singleValue = singleValue;
                                }
                                let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                return(
                                    renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                )                               
                            })
                            :
                            null
                        }  

                        </div>
                    </div>}
                        
                        {/* ---- Below html elements conditionally renders the parameter block/list ---- */}
                        {
                        state.Range === "AllRange" && noOfParams >= 6
                            ?
                            <div className="flex flex-col gap-1 border-1 container" >
                                    <div className='flex justify-between align-items-center padding-right-2  position-relative pb-2'>
                                        <span className='flex gap-2'>
                                            <span onClick={()=>setlistView(false)} className={`${!listView && 'bg-white'} py-1 px-2 c-pointer rounded`}>
                                                <HiOutlineViewGrid />
                                            </span>
                                            <span onClick={()=>setlistView(true)} className={`${listView && 'bg-white'} py-1 px-2 c-pointer rounded`}>
                                                <FaList />
                                            </span>
                                        </span>
                                        <span className='absolute-center'>{t(IDS_Parameter)}</span>
                                        { (state.bDeviceTypeWLI == false && state.bDeviceTypeSTLI == false) &&
                                        <div className='position-relative flex flex-center filtersDivPar' style={{zIndex:3}}>
                                            <span className='bg-white py-1 px-2 rounded'>
                                                {/* <HiFilter fill={getRangeColor()}/> */}
                                        {/* <FaFilter fill={getRangeColor()}/> */}
                                        {state.Range === "AllRange" ?
                                        <TfiFilter/>
                                        :
                                        <FaFilter fill={getRangeColor()}/>
                                        }


                                            </span>
                                            <div  className='position-absolute top-100 left-50 d-flex flex-col gap-1 filtersDiv' >
                                                <div className=""><button onClick ={RangeSevere} className="rangeSevereCategory" title={"Severe"}/></div>
                                                <div className=""><button onClick ={RangeVeryPoor} className="rangeVeryPoorCategory" title={"VeryPoor"}/></div>
                                                <div className=""><button onClick ={RangePoor} className="rangePoorCategory" title={"Poor"}/></div>
                                                <div className=""><button onClick ={RangeModerate} className="rangeModerateCategory" title={"Moderate"}/></div>
                                                <div className=""><button onClick ={RangeSatisfactory} className="rangeSatisfactoryCategory" title={"Satisfactory"}/></div>
                                                <div className=""><button onClick ={RangeGood} className="rangeGoodCategory" title={"Good"}/></div>
                                                <div className=""><button onClick = {AllRange} className="AllRangeCategory" title={"AllRange"}></button></div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    {/* <div className={`flex-row justify-center d-flex gap-2 flex-wrap`} style={{marginLeft:'3%', maxHeight:'70vh', overflowY:'overlay', overflowX:"hidden"}} > */}
                                    <div className={`row row-cols-2 row-cols-sm-1 row-cols-md-2 g-1 g-md-2 ${listView && 'p-1'}`} style={{ maxHeight:'77vh', overflowY:'auto', overflowX:"hidden"}} >

                                        {modifiedState.PositionProrityParam.map((singleParam)=>{
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            singleValue = restrictParamValIfGoesBeyondMinOrMax(singleValue, singleParam);

                                            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )                                
                                        })}
                                        {modifiedState.AlertBasedParam.map((singleParam)=>{
                                            let singleValue = SingleDeviceParamValue[singleParam];

                                            let AlertBasedVlaue = renderCheckIfAlertReceived(singleParam);

                                            if(AlertBasedVlaue["bAlertDetectedForParam"] == true) {
                                                singleValue = AlertBasedVlaue["AlertBasedSingleParamValue"];
                                            } else {
                                                singleValue = singleValue;
                                            }

                                            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )                                
                                        })}
                                        {Severe.map((singleParam)=>{
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            let foundAlertBasedParam = modifiedState.AlertBasedParam.includes(singleParam);
                                            let foundPositionProrityParam = modifiedState.PositionProrityParam.includes(singleParam);
                                            if(foundAlertBasedParam == true || foundPositionProrityParam){
                                                return; 
                                            }
                                            let AlertBasedVlaue = renderCheckIfAlertReceived(singleParam);
                                            if(AlertBasedVlaue["bAlertDetectedForParam"] == true) {
                                                singleValue = AlertBasedVlaue["AlertBasedSingleParamValue"];
                                            } else {
                                                singleValue = singleValue
                                            }

                                            singleValue = restrictParamValIfGoesBeyondMinOrMax(singleValue, singleParam);

                                            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )                                
                                        })}
                                        {VeryPoor.map((singleParam)=>{
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            let foundAlertBasedParam = modifiedState.AlertBasedParam.includes(singleParam);
                                            let foundPositionProrityParam = modifiedState.PositionProrityParam.includes(singleParam);
                                            if(foundAlertBasedParam == true || foundPositionProrityParam){
                                                return; 
                                            }
                                            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )                                
                                        })}
                                        {LowVeryPoor.map((singleParam)=>{
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            let foundAlertBasedParam = modifiedState.AlertBasedParam.includes(singleParam);
                                            let foundPositionProrityParam = modifiedState.PositionProrityParam.includes(singleParam);
                                            if(foundAlertBasedParam == true || foundPositionProrityParam){
                                                return; 
                                            }
                                            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )                                
                                        })}
                                        {Poor.map((singleParam)=>{
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            let foundAlertBasedParam = modifiedState.AlertBasedParam.includes(singleParam);
                                            let foundPositionProrityParam = modifiedState.PositionProrityParam.includes(singleParam);
                                            if(foundAlertBasedParam == true || foundPositionProrityParam){
                                                return; 
                                            }
                                            let { styleValueText, styleValueBox } =  getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);
                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )                                
                                        })}
                                        {Moderate.map((singleParam)=>{
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            let foundAlertBasedParam = modifiedState.AlertBasedParam.includes(singleParam);
                                            let foundPositionProrityParam = modifiedState.PositionProrityParam.includes(singleParam);
                                            if(foundAlertBasedParam == true || foundPositionProrityParam){
                                                return; 
                                            }
                                            let { styleValueText, styleValueBox } =  getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);
                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )                                
                                        })}
                                        {Good.map((singleParam)=>{
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            let foundAlertBasedParam = modifiedState.AlertBasedParam.includes(singleParam);
                                            let foundPositionProrityParam = modifiedState.PositionProrityParam.includes(singleParam);
                                            if(foundAlertBasedParam == true || foundPositionProrityParam){
                                                return; 
                                            }

                                            singleValue = restrictParamValIfGoesBeyondMinOrMax(singleValue, singleParam);

                                            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);
                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )
                                        })}              
                                        {Satisfactory.map((singleParam)=>{
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            let foundAlertBasedParam = modifiedState.AlertBasedParam.includes(singleParam);
                                            let foundPositionProrityParam = modifiedState.PositionProrityParam.includes(singleParam);
                                            if(foundAlertBasedParam == true || foundPositionProrityParam){
                                                return; 
                                            }

                                            singleValue = restrictParamValIfGoesBeyondMinOrMax(singleValue, singleParam);

                                            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);
                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )
                                        })}
                                        {Others.map( (singleParam) => {
                                            let noOfParams = Others.length;
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);
                                            
                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )                               
                                        })}                
                                        {nullValue.map((singleParam)=>{
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            let foundAlertBasedParam = modifiedState.AlertBasedParam.includes(singleParam);
                                            let foundPositionProrityParam = modifiedState.PositionProrityParam.includes(singleParam);
                                            if(foundAlertBasedParam == true || foundPositionProrityParam){
                                                return; 
                                            }

                                            singleValue = restrictParamValIfGoesBeyondMinOrMax(singleValue, singleParam);

                                            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);
                                            
                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )
                                        })}
                                    </div>

                                </div>
                            : state.Range === "AllRange" && noOfParams < 6 
                            ?
                            <div className="flex flex-col gap-2 border-1"  >
                                <div className='flex justify-between align-items-center padding-right-2  position-relative pb-2'>
                                    <span className='flex gap-2'>
                                        <span onClick={()=>setlistView(false)} className={`${!listView && 'bg-white'} py-1 px-2 c-pointer rounded`}>
                                            <HiOutlineViewGrid />
                                        </span>
                                        <span onClick={()=>setlistView(true)} className={`${listView && 'bg-white'} py-1 px-2 c-pointer rounded`}>
                                            <FaList />
                                        </span>
                                    </span>
                                    <span className='absolute-center'>{t(IDS_Parameter)}</span>
                                    { (state.bDeviceTypeWLI == false && state.bDeviceTypeSTLI == false) &&
                                    <div className='position-relative flex flex-center filtersDivPar' style={{zIndex:3}}>
                                        <span className='bg-white py-1 px-2 rounded'>
                                            {/* <RiFilter2Line/> */}
                                            {/* <HiFilter fill={getRangeColor()}/> */}
                                            {state.Range === "AllRange" ?
                                            <TfiFilter/>
                                            :
                                            <FaFilter fill={getRangeColor()}/>
                                            }


                                        </span>
                                        <div  className='position-absolute top-100 left-50 d-flex flex-col gap-1 filtersDiv' >
                                            <div className=""><button onClick ={RangeSevere} className="rangeSevereCategory" title={"Severe"}/></div>
                                            <div className=""><button onClick ={RangeVeryPoor} className="rangeVeryPoorCategory" title={"VeryPoor"}/></div>
                                            <div className=""><button onClick ={RangePoor} className="rangePoorCategory" title={"Poor"}/></div>
                                            <div className=""><button onClick ={RangeModerate} className="rangeModerateCategory" title={"Moderate"}/></div>
                                            <div className=""><button onClick ={RangeSatisfactory} className="rangeSatisfactoryCategory" title={"Satisfactory"}/></div>
                                            <div className=""><button onClick ={RangeGood} className="rangeGoodCategory" title={"Good"}/></div>
                                            <div className=""><button onClick = {AllRange} className="AllRangeCategory" title={"AllRange"}></button></div>
                                        </div>
                                    </div>
                                    }
                                </div>
                                
                                    {/* <div className={`row ${listView ? 'row-cols-1' : 'row-cols-2' } row-cols-sm-1 row-cols-md-2 g-1 g-md-2 `} */}
                                    <div className={`row row-cols-2 row-cols-sm-1 row-cols-md-2 g-1 g-md-2 ${listView && 'p-1'}`}
                                    style={{ maxHeight:'77vh', overflowY:'auto', overflowX:"hidden"}} 
                                    >
                                        {SingleDeviceParamSequence.map( (singleParam) => {
                                            let noOfParams = SingleDeviceParamSequence.length;
                                            let singleValue = SingleDeviceParamValue[singleParam];
                                            
                                            singleValue = restrictParamValIfGoesBeyondMinOrMax(singleValue, singleParam);

                                            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);

                                            return(
                                                renderDeviceSingleParameterDetails(noOfParams, singleParam, styleValueBox, styleValueText, singleValue)
                                            )                               
                                        })}
                                    </div>
                                </div>
                            :
                            null
                        }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default VcSingleDeviceData;