import { createSlice } from "@reduxjs/toolkit";

const deviceList = createSlice({
    name: "Device List",
    initialState: {
        devices: [],
        globalSearchNode: false,
    },
    reducers: {
        appendDevices: (state, action) => {
            state.devices.push(action.payload)
        },

        clearDeviceList: (state) => {
            state.devices = []
        },

        appendGlobalSearchNode: (state, action) => {
            state.globalSearchNode = action.payload;
        }
    }
});

export const { appendDevices, clearDeviceList, appendGlobalSearchNode } = deviceList.actions;

export default deviceList.reducer;