import React, { useContext, useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { AppRelevantDataContext } from '../../AppContext';
import App from '../../App';
import '@studysync/react-splitter-layout/lib/index.css';
import  VcMaps from './VcMaps';
import VcSideNav from './DeviceHome/VcSideNav';
import { GrMenu } from 'react-icons/gr';
import vilisoLogoTransparent from './../IMAGES/vilisoLogoTransparent.png'
import { FaArrowAltCircleLeft, FaWindowClose } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { clearDeviceList } from './Store/Slices/deviceList';
import { clearTreeNodeList } from './Store/Slices/treeNodeList';
import VcMapsTrackDevice from './VcMapsTrackDevice';
import { setToSearchDeviceIDOnMap } from './Store/Slices/variables';
import { RiMenuUnfoldLine } from 'react-icons/ri';

function VcMapPg (props) {

  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const deviceIdToSearch = useSelector(s => s.variables.toSearchDeviceIDOnMap)
  const [state, setState] = useState({
    showDrawer: false,
  })

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  useEffect(() => {

    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.
      // Try to get this value if present in LocalStorage and update the app state (so that
      // re-render will happen). If app context data not present in LocalStorage then
      // show the Login Page
      if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
        // App Relevant Data Context not present in LocalStorage as well.
        navigate('/');
      } else {
        // App Relevant Data Context loaded from LocalStorage at AppLeval.
        // Re-render will happen automatically (no action required).
      }

    }

    dispatch(clearTreeNodeList())
    dispatch(clearDeviceList())
    
    return () => {
      dispatch(setToSearchDeviceIDOnMap(''))
      // toSearchDeviceIDOnMap
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
    window.removeEventListener('resize', handleResize);
    };
}, []);
    
    let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext

    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.

      // Next re-render will happen automatically when the AppState is updated again 
      // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
      return App.renderLoadingPage();
    }

       if(windowWidth < 550) {
        return  <>  
          <div  className={`drawerDiv ${state.showDrawer ? 'drawerDivIn' : 'drawerDivOut'}`}>
              <VcSideNav />
              <FaArrowAltCircleLeft 
                  className="closingArrow top-nav" onClick={()=> setState({...state , showDrawer : false})}
              />
          </div>
          <div className='bg-white shadow flex items-center justify-between px-2 py-1 m-1 ' style={{position:"sticky", top:"0", borderRadius:"0.75rem"}}>
              <div className='' style={{width:"60px"}}>
                <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
              </div>
              {/* <FaWindowClose onClick={()=> setState({...state , showDrawer : !state.showDrawer})}/> */}
              {state.showDrawer ? 
              <MdClose classname='transition-all' onClick={()=> setState({...state , showDrawer : false})}/>:
              <span onClick={()=> setState({...state , showDrawer : true})}><RiMenuUnfoldLine style={{ fontSize: "1.2rem" }} /></span> 
              }
          </div>
            {(deviceIdToSearch && deviceIdToSearch.length > 0) ?
              <VcMapsTrackDevice invokedFrom = "VcMapPg" DeviceID={deviceIdToSearch} />
            :<VcMaps invokedFrom = "VcMapPg"/>                  
            }
          
          </>
       } 
        return (
          <div className='w-100 d-flex relative'>
              <div style={{width:"4%", position:"relative", zIndex:"5"}}>
                  <VcSideNav/>
              </div>
              <div style={{width:"96%" }}>
              {(deviceIdToSearch && deviceIdToSearch.length > 0) ?
              <VcMapsTrackDevice invokedFrom = "VcMapPg" DeviceID={deviceIdToSearch} />
            :<VcMaps invokedFrom = "VcMapPg"/>                  
            }
                {/* <VcMaps invokedFrom = "VcMapPg"/>                   */}
              </div>
          </div>
        )
}

export default VcMapPg;

