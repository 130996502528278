import React, { useState, useEffect, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext } from '../../AppContext';
import DateTimePicker from 'react-datetime-picker';
import { MAX_DAYS_RANGE, ARR_DEFAULT_RATING_LEVEL, DEFAULT_RATING_LEVEL_STARS, DEFAULT_RATING_LEVEL_SIGNAL, MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA } from '../../VcConstants';
import { convertLocalDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertUTCDateToStrYYYYMMDDHH24MMSS, getLocalTimezoneOffset } from '../../vtUtil';
import { FaSearch, FaStar } from 'react-icons/fa';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

const CSV_COLUMN_SEPERATOR = ',';
const CSV_LINE_SEPERATOR = '\r\n';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder="Search"                    
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data }) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    );

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // set page size based on tables
            initialState: { pageSize: 10},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;No Data Found.</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* {page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"
                                    style={{
                                        cursor: '',
                                        background: '',
                                        color: 'black',
                                        alignItems: "center",
                                    }}  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style},
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // // when there is no data found 
                        // || <span style={{paddingLeft:"1rem", color: "green", display: "flex" ,justifyContent:"left"}}>{"No Data Found."}</span>
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcToiletRatingReport (props) {
    const searchStringStyle = {fontSize: "0.9rem", width: "80%"};
    const textFile = null;
    const context = useContext(AppRelevantDataContext);
    const filterCaseInsensitive = (rows, id, filterValue) => {
        return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
                ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                : false
            });
    };

    const [state, setState] = useState({            
        startDate: "",
        endDate: "",
        selectedPincode: "",
        selectedRatingLevel: DEFAULT_RATING_LEVEL_SIGNAL,
        bChngeBtnName: false,
        arrPincode: [],
        arrToiletInfo: [],
        arrRatingInfoOfToilet: [],
        arrToiletInfoAndItsRatingInfo: [],
        invokedFrom: props.invokedFrom,
        UserIdRef: React.createRef(),
        errors:{
            others:'',
            timeRelatedErr: '',
        },
        showClearBtn: false,
        startDateSelected: false,
        endDateSelected: false,
        startDateLength: 0,
        endDateLength: 0, 
        areDatesSelected: false,
        noDataFound: "",
        columnsForStarRating: [
            {       
            Header:() => <div className="AlertLogTableHeader">Toilet<br/>Name</div>,
                accessor: 'Name',
                filter: filterCaseInsensitive,
                width: 170,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">Overall<br/>Odour<br/>Rating</div>, 
                accessor: "overAllRatingForOdour",
                width: 100,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Odour<br/>5 <FaStar/></div>,  
                accessor: 'fiveStarRatingForOdour',
                width: 95,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Odour<br/>4 <FaStar/></div>,  
                accessor: 'fourStarRatingForOdour',
                filter: filterCaseInsensitive,
                width: 95,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Odour<br/>3 <FaStar/></div>,  
                accessor: 'threeStarRatingForOdour',
                filter: filterCaseInsensitive,
                width: 95,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                disableFilters: true,
            },    
            {       
                Header:() => <div className="AlertLogTableHeader">Odour<br/>2 <FaStar/></div>,  
                accessor: 'twoStarRatingForOdour',
                width: 95,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Odour<br/>1 <FaStar/></div>,  
                accessor: 'oneStarRatingForOdour',
                width: 95,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Overall<br/>Cleanliness<br/>Rating</div>,
                accessor: 'overAllRatingForCleanliness',
                width: 130,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">Clean<br/>5 <FaStar/></div>,  
                accessor: 'fiveStarRatingForCleanliness',
                width: 90,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },   
            {       
                Header:() => <div className="AlertLogTableHeader">Clean<br/>4 <FaStar/></div>,  
                accessor: 'fourStarRatingForCleanliness',
                width: 90,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Clean<br/>3 <FaStar/></div>,  
                accessor: 'threeStarRatingForCleanliness',
                width: 90,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },  
            {       
                Header:() => <div className="AlertLogTableHeader">Clean<br/>2 <FaStar/></div>,  
                accessor: 'twoStarRatingForCleanliness',
                width: 90,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"

                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">Clean<br/>1 <FaStar/></div>,  
                accessor: 'oneStarRatingForCleanliness',
                width: 90,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">People Count</div>,  
                accessor: 'PublicFootCount',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },     
            {       
                Header:() => <div className="AlertLogTableHeader">Toilet<br/>Type</div>,  
                accessor: 'EntityType',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },    
            {       
                Header:() => <div className="AlertLogTableHeader">Pincode</div>,  
                accessor: 'PinCode',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },  
            {       
                Header:() => <div className="AlertLogTableHeader">Address</div>,  
                accessor: 'Address',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">City</div>,  
                accessor: 'City',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">District</div>,  
                accessor: 'District',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">State</div>,  
                accessor: 'State',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Country</div>,  
                accessor: 'Country',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },      
        ],
        columnsForSignalRating: [
            {       
            Header:() => <div className="AlertLogTableHeader">Toilet<br/>Name</div>,
                accessor: 'Name',
                width: 170,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,

            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">Total<br/>No. of<br/>Reviews</div>, 
                accessor: "totalNoOfReviews",
                width: 110,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Good<br/>(Odour)</div>,  
                accessor: 'threeStarIncludesFourAndFiveStarRatingForOdour',
                filter: filterCaseInsensitive,
                width: 105,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                disableFilters: true,
            },    
            {       
                Header:() => <div className="AlertLogTableHeader">Bad<br/>(Odour)</div>,  
                accessor: 'twoStarRatingForOdour',
                width: 105,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Worse<br/>(Odour)</div>,  
                accessor: 'oneStarRatingForOdour',
                width: 105,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Good<br/>(Cleanliness)</div>,  
                accessor: 'threeStarIncludesFourAndFiveStarRatingForCleanliness',
                width: 140,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },  
            {       
                Header:() => <div className="AlertLogTableHeader">Bad<br/>(Cleanliness)</div>,  
                accessor: 'twoStarRatingForCleanliness',
                width: 140,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"

                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">Worse<br/>(Cleanliness)</div>,  
                accessor: 'oneStarRatingForCleanliness',
                width: 140,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">People Count</div>,  
                accessor: 'PublicFootCount',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },    
            {       
                Header:() => <div className="AlertLogTableHeader">Toilet<br/>Type</div>,  
                accessor: 'EntityType',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },    
            {       
                Header:() => <div className="AlertLogTableHeader">Pincode</div>,  
                accessor: 'PinCode',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },  
            {       
                Header:() => <div className="AlertLogTableHeader">Address</div>,  
                accessor: 'Address',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">City</div>,  
                accessor: 'City',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">District</div>,  
                accessor: 'District',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">State</div>,  
                accessor: 'State',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Country</div>,  
                accessor: 'Country',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },       
        ],
        columnsForPublicFootCount: [
            {       
                Header:() => <div className="AlertLogTableHeader">Toilet<br/>Name</div>,
                accessor: 'Name',
                width: 170,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,

            },
            {       
                Header:() => <div className="AlertLogTableHeader">Toilet Users Count</div>,  
                accessor: 'PublicFootCount',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },    
            {       
                Header:() => <div className="AlertLogTableHeader">Toilet<br/>Type</div>,  
                accessor: 'EntityType',
                filter: filterCaseInsensitive,
                hidden: true,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },    
            {       
                Header:() => <div className="AlertLogTableHeader">Pincode</div>,  
                accessor: 'PinCode',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },  
            {       
                Header:() => <div className="AlertLogTableHeader">Address</div>,  
                accessor: 'Address',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">City</div>,  
                accessor: 'City',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">District</div>,  
                accessor: 'District',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">State</div>,  
                accessor: 'State',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Country</div>,  
                accessor: 'Country',
                filter: filterCaseInsensitive,
                width: 175,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },        
        ],
    });

    useEffect(() =>{
        getPincodeForAllAvailableToilets();
    }, []);

    const getPincodeForAllAvailableToilets = () => {
        let modifiedState = state;
        modifiedState.arrPincode = [];

        axios.post(`${getAPIHostURL()}/wclient/getPincodeForAllAvailableToilets`)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                
                if(response.data["arrAllAvailablePincodes"] == null || response.data["arrAllAvailablePincodes"].length <=0) {
                    modifiedState.errors.others = 'Pincodes for the available toilets are missing. Please Try again later.'
                } else {
                    modifiedState.arrPincode = response.data["arrAllAvailablePincodes"];
                }
                
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});

        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        }); 
    }

    const onChangePincode = (e) => {
        let modifiedState = state;
        modifiedState.selectedPincode = e.target.value;
        modifiedState.arrToiletInfoAndItsRatingInfo = [];
        modifiedState.arrRatingInfoOfToilet = [];
        modifiedState.arrToiletInfo = [];
        modifiedState.errors.others = "";
        setState({...modifiedState});
    }

    // In mozila firefox datalist not shows data in Single click bellow code is fixes of mozila firefox data list problem.
    const onClickDataList = (e) => {

        let UserIdDataListShow = state.UserIdRef.current;
        UserIdDataListShow.style.display = "block";
        UserIdDataListShow.style.display = "none";
    }

    const onChangeRatingLevel = (e) => {
        let modifiedState = state;
        modifiedState.selectedRatingLevel = e.target.value;
        modifiedState.arrToiletInfoAndItsRatingInfo = [];
        modifiedState.arrRatingInfoOfToilet = [];
        modifiedState.arrToiletInfo = [];
        modifiedState.errors.others = "";
        setState({...modifiedState});
    }

    const onClickRefresh = () => {

        let modifiedState = state;
        modifiedState.bChngeBtnName = true;
        modifiedState.errors.timeRelatedErr = "";
        modifiedState.errors.others = "";

        getToiletAndItsRatingInfo(modifiedState);

    }

    const onClickDownload = () => {
        let modifiedState = state;

        if(modifiedState.arrToiletInfoAndItsRatingInfo.length > 0) {
            DownloadCsv(modifiedState.arrToiletInfoAndItsRatingInfo);
        } else {
            modifiedState.bChngeBtnName = true;
            getToiletAndItsRatingInfo(modifiedState, true);
        }
    }

    const getToiletAndItsRatingInfo = (inModifiedState = null, isDownload = false) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        if(modifiedState.selectedPincode == null || modifiedState.selectedPincode.length <=0) {
            modifiedState.errors.others = "Please select Pincode.";
            setState({...modifiedState});
            return;
        }

        if(modifiedState.invokedFrom == 'invokedFromToiletReport' && (modifiedState.selectedRatingLevel == null || modifiedState.selectedRatingLevel.length <=0)) {
            modifiedState.errors.others = "Please select Rating Level.";
            setState({...modifiedState});
            return;
        }

        // let diffBetweenTwoDates = Math.abs((modifiedState.startDate - modifiedState.endDate)/ (1000*60*60*24) );

        // if( diffBetweenTwoDates > MAX_DAYS_RANGE ) {
        //     modifiedState.errors.others = "Date Range should be within Period of 365 days. Please, choose appropriate date.";
        //     setState(modifiedState);
        //     return;
        // } 

        // if( diffBetweenTwoDates > MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA ) {
        //     if(!window.confirm(`Are you sure you want to view the large data for more than 90 days of period?`)){
        //         return
        //     }
        // }

        let strStartDate = modifiedState.startDate.toString();
        let strEndDate = modifiedState.endDate.toString();
        let utcStartDate = null;
        let utcEndDate = null;

        if((strStartDate != null && strStartDate.length > 0) && (strEndDate != null && strEndDate.length > 0)) {  

            let strLoclStartDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.startDate);
            let strLoclEndDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.endDate);
    
            let strLoclStartDtTm = strLoclStartDate.split(" ")[0] + "T" + "00:00:00";
            let strLoclEndDtTm = strLoclEndDate.split(" ")[0] + "T" + "23:59:59";
      
            utcStartDate = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclStartDtTm.valueOf()));
            utcEndDate = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclEndDtTm.valueOf()));

        } 

        if(((strStartDate != null && strStartDate.length > 0) && (strEndDate == null || strEndDate.length <= 0)) || 
        ((strEndDate != null && strEndDate.length > 0) && (strStartDate == null || strStartDate.length <= 0))) {
            modifiedState.errors.others = "Please select both Start Date Time and End Date Time.";
            setState({...modifiedState});
            return;
        }

        // Get the Browser Timezone Offset
        let localTimeZone = (new Date()).getTimezoneOffset();

        modifiedState.arrToiletInfoAndItsRatingInfo = [];
        modifiedState.arrRatingInfoOfToilet = [];
        modifiedState.arrToiletInfo = [];

        let jsonBody = {
            Pincode: modifiedState.selectedPincode,
            RatingLevel: modifiedState.selectedRatingLevel,
            StartDate: utcStartDate,
            EndDate: utcEndDate,
            localTimeZone: getLocalTimezoneOffset(localTimeZone),
            invokedFrom: state.invokedFrom
        }

        axios.post(`${getAPIHostURL()}/wclient/getToiletAndItsRatingInfo`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(state.invokedFrom == 'invokedFromToiletReport' && (response.data["arrToiletInfo"] == null || response.data["arrToiletInfo"].length <=0 ||
                    response.data["arrRatingInfoOfToilet"] == null || response.data["arrRatingInfoOfToilet"].length <=0)
                ) {

                    modifiedState.errors.others = `Toilet info and its rating info is not present on the server for the selected period and filter.`

                } else if(state.invokedFrom != null && state.invokedFrom == 'invokedFromPublicCountReport' && (response.data["arrToiletInfo"] == null || response.data["arrToiletInfo"].length <=0)){
                    modifiedState.errors.others = `Toilet info and its user count info is not present on the server for the selected period and filter.`

                } else{

                    modifiedState.arrToiletInfo = response.data["arrToiletInfo"];
                    modifiedState.arrRatingInfoOfToilet = response.data["arrRatingInfoOfToilet"];
                    modifiedState.arrToiletInfoAndItsRatingInfo = [];
                    
                    for(let i=0; i<modifiedState.arrToiletInfo.length; i++) {
                        let singleToiletInfo = modifiedState.arrToiletInfo[i];

                        if(singleToiletInfo.NoOfCounterDvcs != null && singleToiletInfo.NoOfCounterDvcs > 0) {
                            singleToiletInfo.PublicFootCount = singleToiletInfo.PublicFootCount == 'NA' ? 0 : singleToiletInfo.PublicFootCount;
                        }

                        let objCombinedAddr = {
                            "Address": singleToiletInfo["Address2"] != null && singleToiletInfo["Address2"].length > 0 ? singleToiletInfo["Address1"] + ", " + singleToiletInfo["Address2"] : singleToiletInfo["Address1"]
                        }
                        let singleToiletRatingInfo = modifiedState.arrRatingInfoOfToilet.filter((singleToiletRatingInfo) => singleToiletRatingInfo.RevieweeID == singleToiletInfo.EntityLocnID);

                        let cleanlinessTotalStarRating = 0;
                        let cleanlinessTotalNumberOfReviews = 0;
                        let cleanlinessAvgStarRating = 0;

                        let odourTotalStarRating = 0;
                        let odourTotalNumberOfReviews = 0;
                        let odourAvgStarRating = 0;

                        // Store individual cleanliness value for the each rating count.
                        let oneStarRatingForCleanliness = 0;
                        let twoStarRatingForCleanliness = 0;
                        let threeStarRatingForCleanliness = 0;
                        let threeStarIncludesFourAndFiveStarRatingForCleanliness = 0;
                        let fourStarRatingForCleanliness = 0;
                        let fiveStarRatingForCleanliness = 0;
                        
                        // Store individual odour value for the each rating count.
                        let oneStarRatingForOdour = 0;
                        let twoStarRatingForOdour = 0;
                        let threeStarRatingForOdour = 0;
                        let threeStarIncludesFourAndFiveStarRatingForOdour = 0
                        let fourStarRatingForOdour = 0;
                        let fiveStarRatingForOdour = 0;

                        let oneStarPercentageRatingForCleanliness = 0;
                        let twoStarPercentageRatingForCleanliness = 0;
                        let threeStarPercentageRatingForCleanliness = 0;

                        let oneStarPercentageRatingForOdour = 0;
                        let twoStarPercentageRatingForOdour = 0;
                        let threeStarPercentageRatingForOdour = 0;

                        for(let j=0; j<singleToiletRatingInfo.length; j++) {

                            if(singleToiletRatingInfo[j]["RatingType"] == "Cleanliness") {

                                if(singleToiletRatingInfo[j]["Rating"] == 1) {
                                    oneStarRatingForCleanliness = singleToiletRatingInfo[j]["CountPerRating"];
                                } else if(singleToiletRatingInfo[j]["Rating"] == 2){
                                    twoStarRatingForCleanliness = singleToiletRatingInfo[j]["CountPerRating"];
                                } else if(singleToiletRatingInfo[j]["Rating"] == 3){ // Special case for signal rating
                                    threeStarRatingForCleanliness = singleToiletRatingInfo[j]["CountPerRating"];
                                    threeStarIncludesFourAndFiveStarRatingForCleanliness = singleToiletRatingInfo[j]["CountPerRating"];
                                } else if(singleToiletRatingInfo[j]["Rating"] == 4){
                                    fourStarRatingForCleanliness = singleToiletRatingInfo[j]["CountPerRating"];
                                } else {
                                    fiveStarRatingForCleanliness = singleToiletRatingInfo[j]["CountPerRating"];
                                }

                                // In case of the Signal Rating Level we are merging Rating 4 and rating 5 value (if exists) into the Rating 3
                                // in order to avoid wrong percentage total for all the 3 Level of the rating.
                                if(modifiedState.selectedRatingLevel == DEFAULT_RATING_LEVEL_SIGNAL && 
                                    (singleToiletRatingInfo[j]["Rating"] == 4 || singleToiletRatingInfo[j]["Rating"] == 5)
                                ) {
                                    threeStarIncludesFourAndFiveStarRatingForCleanliness = parseInt(threeStarIncludesFourAndFiveStarRatingForCleanliness) + parseInt(singleToiletRatingInfo[j]["CountPerRating"]);
                                }

                                if(modifiedState.selectedRatingLevel == DEFAULT_RATING_LEVEL_STARS){
                                    cleanlinessTotalStarRating += parseInt(singleToiletRatingInfo[j]["SumPerRating"]);
                                }
                                cleanlinessTotalNumberOfReviews += parseInt(singleToiletRatingInfo[j]["CountPerRating"]);  
                
                            } else {

                                if(singleToiletRatingInfo[j]["Rating"] == 1) {
                                    oneStarRatingForOdour = singleToiletRatingInfo[j]["CountPerRating"];
                                } else if(singleToiletRatingInfo[j]["Rating"] == 2){
                                    twoStarRatingForOdour = singleToiletRatingInfo[j]["CountPerRating"];
                                } else if(singleToiletRatingInfo[j]["Rating"] == 3){ // Special case for signal rating
                                    threeStarRatingForOdour = singleToiletRatingInfo[j]["CountPerRating"];
                                    threeStarIncludesFourAndFiveStarRatingForOdour = singleToiletRatingInfo[j]["CountPerRating"];
                                } else if(singleToiletRatingInfo[j]["Rating"] == 4){
                                    fourStarRatingForOdour = singleToiletRatingInfo[j]["CountPerRating"];
                                } else {
                                    fiveStarRatingForOdour = singleToiletRatingInfo[j]["CountPerRating"];
                                }

                                // In case of the Signal Rating Level we are merging Rating 4 and rating 5 value (if exists) into the Rating 3
                                // in order to avoid wrong percentage total for all the 3 Level of the rating.
                                if(modifiedState.selectedRatingLevel == DEFAULT_RATING_LEVEL_SIGNAL && 
                                    (singleToiletRatingInfo[j]["Rating"] == 4 || singleToiletRatingInfo[j]["Rating"] == 5)
                                ) {
                                    threeStarIncludesFourAndFiveStarRatingForOdour = parseInt(threeStarIncludesFourAndFiveStarRatingForOdour) + parseInt(singleToiletRatingInfo[j]["CountPerRating"]);
                                }

                                if(modifiedState.selectedRatingLevel == DEFAULT_RATING_LEVEL_STARS) {
                                    odourTotalStarRating += parseInt(singleToiletRatingInfo[j]["SumPerRating"]);
                                }
                                odourTotalNumberOfReviews += parseInt(singleToiletRatingInfo[j]["CountPerRating"]);  
                            }
                        }

                        // In case of only Rating Level Star, 
                        // Divide the total rating by total number of reviews gives us Weighted Avg Rating.
                        if(modifiedState.selectedRatingLevel == DEFAULT_RATING_LEVEL_STARS &&
                            cleanlinessTotalStarRating != null && cleanlinessTotalStarRating > 0 &&
                            cleanlinessTotalNumberOfReviews != null && cleanlinessTotalNumberOfReviews > 0
                        ) {
                            cleanlinessAvgStarRating = (cleanlinessTotalStarRating / cleanlinessTotalNumberOfReviews).toFixed(1);
                        } else {
                            cleanlinessAvgStarRating = 0;
                        }
                        
                        // In case of only Rating Level Star, 
                        // Divide the total rating by total number of reviews gives us Weighted Avg Rating.
                        if(modifiedState.selectedRatingLevel == DEFAULT_RATING_LEVEL_STARS &&
                            odourTotalStarRating != null && odourTotalStarRating > 0 &&
                            odourTotalNumberOfReviews != null && odourTotalNumberOfReviews > 0
                        ) {
                            odourAvgStarRating = (odourTotalStarRating / odourTotalNumberOfReviews).toFixed(1);
                        } else {
                            odourAvgStarRating = 0;
                        }

                        // In case of only Rating Level Signal, 
                        // find out the no of percentage for each Level and show the same.
                        if(modifiedState.selectedRatingLevel == DEFAULT_RATING_LEVEL_SIGNAL && 
                            cleanlinessTotalNumberOfReviews != null && 
                            cleanlinessTotalNumberOfReviews > 0
                        ) {

                            oneStarPercentageRatingForCleanliness = oneStarRatingForCleanliness > 0 ? ((oneStarRatingForCleanliness / cleanlinessTotalNumberOfReviews) * 100).toFixed(0) : 0;
                            twoStarPercentageRatingForCleanliness = twoStarRatingForCleanliness > 0 ? ((twoStarRatingForCleanliness / cleanlinessTotalNumberOfReviews) * 100).toFixed(0) : 0;
                            threeStarPercentageRatingForCleanliness = threeStarIncludesFourAndFiveStarRatingForCleanliness > 0 ? ((threeStarIncludesFourAndFiveStarRatingForCleanliness / cleanlinessTotalNumberOfReviews) * 100).toFixed(0) : 0;
                            
                            oneStarPercentageRatingForOdour = oneStarRatingForOdour > 0 ? ((oneStarRatingForOdour / cleanlinessTotalNumberOfReviews) * 100).toFixed(0) : 0;
                            twoStarPercentageRatingForOdour = twoStarRatingForOdour > 0 ? ((twoStarRatingForOdour / cleanlinessTotalNumberOfReviews) * 100).toFixed(0) : 0;
                            threeStarPercentageRatingForOdour = threeStarIncludesFourAndFiveStarRatingForOdour > 0 ? ((threeStarIncludesFourAndFiveStarRatingForOdour / cleanlinessTotalNumberOfReviews) * 100).toFixed(0) : 0;
                        }

                        let objOverAllRating = {
                            totalNoOfReviews: cleanlinessTotalNumberOfReviews, // In every scenario the total no of reviews will be exact same for both odour and cleanliness. 

                            // This will contain average value for the odour and cleanliness which we are showing in case of the Star rating Level.
                            // Averge for odour and cleanliness migh be zero in case of Signal rating Level as we are not calculating the same.
                            overAllRatingForOdour: odourAvgStarRating,
                            overAllRatingForCleanliness: cleanlinessAvgStarRating,

                            // This will contain count of the reviews for each Rating of odour and cleanliness.
                            oneStarRatingForOdour: oneStarRatingForOdour,
                            twoStarRatingForOdour: twoStarRatingForOdour,
                            threeStarRatingForOdour: threeStarRatingForOdour,
                            fourStarRatingForOdour: fourStarRatingForOdour,
                            fiveStarRatingForOdour: fiveStarRatingForOdour,
                            oneStarRatingForCleanliness: oneStarRatingForCleanliness,
                            twoStarRatingForCleanliness: twoStarRatingForCleanliness,
                            threeStarRatingForCleanliness: threeStarRatingForCleanliness,
                            fourStarRatingForCleanliness: fourStarRatingForCleanliness,
                            fiveStarRatingForCleanliness: fiveStarRatingForCleanliness,

                            // These are the special values which contains addition of 4 star and 5 star (if exists) rating count.
                            threeStarIncludesFourAndFiveStarRatingForOdour: threeStarIncludesFourAndFiveStarRatingForOdour,
                            threeStarIncludesFourAndFiveStarRatingForCleanliness: threeStarIncludesFourAndFiveStarRatingForCleanliness,

                            // This will contain % value for the odour and cleanliness which we are showing in case of the Signal rating Level.
                            // Percentage for odour and cleanliness migh be zero in case of Star rating Level as we are not calculating the same.
                            oneStarPercentageRatingForCleanliness: oneStarPercentageRatingForCleanliness,
                            twoStarPercentageRatingForCleanliness: twoStarPercentageRatingForCleanliness,
                            threeStarPercentageRatingForCleanliness: threeStarPercentageRatingForCleanliness, // Includes 4 Star and 5 Star count if exists.
                            oneStarPercentageRatingForOdour: oneStarPercentageRatingForOdour,
                            twoStarPercentageRatingForOdour: twoStarPercentageRatingForOdour,
                            threeStarPercentageRatingForOdour: threeStarPercentageRatingForOdour, // Includes 4 Star and 5 Star count if exists.
                        }

                        let toiletAndItsReviewInfo = {...singleToiletInfo, ...objOverAllRating, ...objCombinedAddr};
                        modifiedState.arrToiletInfoAndItsRatingInfo.push(toiletAndItsReviewInfo);
                    }

                    if(isDownload) {
                        DownloadCsv(modifiedState.arrToiletInfoAndItsRatingInfo);
                    }
                }
                
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (Params not received).\n Try again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        });
    }

    const DownloadCsv = (inarrToiletInfoAndItsRatingInfo) => {
        let modifiedState = state;

        let arrColumnKeys = [];

        if(state.invokedFrom != null && state.invokedFrom == "invokedFromPublicCountReport") {
            arrColumnKeys = ["Toilet User Count", "Toilet Type", "Pincode", "Address", "City", "District", "State", "Country"];

        } else if(modifiedState.selectedRatingLevel == DEFAULT_RATING_LEVEL_STARS) {
            arrColumnKeys = [ "Overall Odour Rating", "Odour 5 Star Rating", "Odour 4 Star Rating", "Odour 3 Star Rating", "Odour 2 Star Rating", "Odour 1 Star Rating", 
                              "Overall Cleanliness Rating", "Clean 5 Star Rating", "Clean 4 Star Rating", "Clean 3 Star Rating", "Clean 2 Star Rating", "Clean 1 Star Rating",
                              "People Count", "Toilet Type", "Pincode", "Address", "City", "District", "State", "Country"];
        } else {
            arrColumnKeys = [ "Total No. Of Reviews", "Good (Odour)", "Bad (Odour)", "Worse (Odour)", 
                              "Good (Cleanliness)", "Bad (Cleanliness)", "Worse (Cleanliness)", 
                              "People Count", "Toilet Type", "Pincode", "Address", "City", "District", "State", "Country"];

        }

        let strCsvHeaderLine = "Toilet Name";
        let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
        let strSingleCsvDataLine = "";
        let strColumnName = "";

        // Create rest of the columns in the header
        let noOfParams = arrColumnKeys.length;
        for (let j=0; j<noOfParams; j++) {
            strColumnName = arrColumnKeys[j];
            strCsvHeaderLine += (CSV_COLUMN_SEPERATOR + strColumnName);
        }

        let receivedAllOrderInformation = inarrToiletInfoAndItsRatingInfo;
        let receivedAllOrderInfoLen = inarrToiletInfoAndItsRatingInfo.length;

        // First push the header CSV line
        arrCsvAllLines.push(strCsvHeaderLine);

        for(let i = 0; i < receivedAllOrderInfoLen; i++) {

            let strFirstToConcatBasedOnRatingLevel = "";

            if(state.invokedFrom != null && state.invokedFrom == "invokedFromPublicCountReport") {
                strFirstToConcatBasedOnRatingLevel = '';

            } else if(modifiedState.selectedRatingLevel == DEFAULT_RATING_LEVEL_STARS) {
                strFirstToConcatBasedOnRatingLevel = 
                ( (receivedAllOrderInformation[i]["overAllRatingForOdour"] == null) ? '' : receivedAllOrderInformation[i]["overAllRatingForOdour"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["fiveStarRatingForOdour"] == null) ? '' : receivedAllOrderInformation[i]["fiveStarRatingForOdour"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["fourStarRatingForOdour"] == null) ? '' : receivedAllOrderInformation[i]["fourStarRatingForOdour"] ) +
                CSV_COLUMN_SEPERATOR +
                ((receivedAllOrderInformation[i]["threeStarRatingForOdour"] == null) ? '' : receivedAllOrderInformation[i]["threeStarRatingForOdour"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["twoStarRatingForOdour"] == null) ? '' : receivedAllOrderInformation[i]["twoStarRatingForOdour"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["oneStarRatingForOdour"] == null) ? '' : receivedAllOrderInformation[i]["oneStarRatingForOdour"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["overAllRatingForCleanliness"] == null) ? '' : receivedAllOrderInformation[i]["overAllRatingForCleanliness"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["fiveStarRatingForCleanliness"] == null) ? '' : receivedAllOrderInformation[i]["fiveStarRatingForCleanliness"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["fourStarRatingForCleanliness"] == null ? '' : receivedAllOrderInformation[i]["fourStarRatingForCleanliness"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["threeStarRatingForCleanliness"] == null) ? '' : receivedAllOrderInformation[i]["threeStarRatingForCleanliness"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["twoStarRatingForCleanliness"] == null) ? '' : receivedAllOrderInformation[i]["twoStarRatingForCleanliness"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["oneStarRatingForCleanliness"] == null ? '' : receivedAllOrderInformation[i]["oneStarRatingForCleanliness"] ) +
                CSV_COLUMN_SEPERATOR;
                

            } else {
                strFirstToConcatBasedOnRatingLevel =
                ( (receivedAllOrderInformation[i]["totalNoOfReviews"] == null) ? '' : receivedAllOrderInformation[i]["totalNoOfReviews"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["threeStarIncludesFourAndFiveStarRatingForOdour"] == null) ? '' : receivedAllOrderInformation[i]["threeStarIncludesFourAndFiveStarRatingForOdour"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["twoStarRatingForOdour"] == null) ? '' : receivedAllOrderInformation[i]["twoStarRatingForOdour"] ) +
                CSV_COLUMN_SEPERATOR +
                ((receivedAllOrderInformation[i]["oneStarRatingForOdour"] == null) ? '' : receivedAllOrderInformation[i]["oneStarRatingForOdour"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["threeStarIncludesFourAndFiveStarRatingForCleanliness"] == null) ? '' : receivedAllOrderInformation[i]["threeStarIncludesFourAndFiveStarRatingForCleanliness"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["twoStarRatingForCleanliness"] == null) ? '' : receivedAllOrderInformation[i]["twoStarRatingForCleanliness"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllOrderInformation[i]["oneStarRatingForCleanliness"] == null) ? '' : receivedAllOrderInformation[i]["oneStarRatingForCleanliness"] ) +
                CSV_COLUMN_SEPERATOR;
            }

            strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                ((receivedAllOrderInformation[i]["Name"] == null) ? '' : receivedAllOrderInformation[i]["Name"] ) +
                CSV_COLUMN_SEPERATOR +
                strFirstToConcatBasedOnRatingLevel +
                ( receivedAllOrderInformation[i]["PublicFootCount"] == null ? '' : receivedAllOrderInformation[i]["PublicFootCount"]) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["EntityType"] == null ? '' : receivedAllOrderInformation[i]["EntityType"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["PinCode"] == null ? '' : receivedAllOrderInformation[i]["PinCode"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["Address"] == null ? '' : receivedAllOrderInformation[i]["Address"].replace(/,/g, ' ') ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["City"] == null ? '' : receivedAllOrderInformation[i]["City"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["District"] == null ? '' : receivedAllOrderInformation[i]["District"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["State"] == null ? '' : receivedAllOrderInformation[i]["State"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllOrderInformation[i]["Country"] == null ? '' : receivedAllOrderInformation[i]["Country"] );

            arrCsvAllLines.push(strSingleCsvDataLine);
        }

        // Create CSV File and fill it
        let bFileCreationStatus = createAndFillCsvFile(arrCsvAllLines);

        if(state.invokedFrom != null && state.invokedFrom == 'invokedFromPublicCountReport'){
            alert(`Toilet user count Data download has started. Check your Browser's status bar or Downloads Folder.`);

        } else {
            alert(`Toilet Rating Data download has started. Check your Browser's status bar or Downloads Folder.`);
        }

        if (!bFileCreationStatus) {
            modifiedState.errors.others = 'Downloaded data could not be saved to file.';
        } 
    }

    const createAndFillCsvFile = (inarrCsvAllLines) => {

        let retVal = true;
        let fileName;

        if((state.startDate != null && state.startDate.toString().length > 0) && 
           (state.endDate != null && state.endDate.toString().length > 0)){
            let foramttedStartDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.startDate);
            let foramttedEndDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.endDate);
            fileName = "Toilet Rating Data From " + foramttedStartDateTime.split(" ")[0] + " To " + foramttedEndDateTime.split(" ")[0] + ".csv";
        } else {
            fileName = "Toilet Rating Data" + ".csv";
        }


        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = makeTextFile(inarrCsvAllLines);
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            let event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });

        // Return true to indicate that operation performed successfully (in future any failures in 
        // the previous calls can be checked to return false)
        retVal = true;
        return retVal;
    }

    const makeTextFile = (arrText) =>  {

        let data = new Blob(arrText, {type: 'text/plain'});

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (textFile !== null) {
            window.URL.revokeObjectURL(textFile);
        }

        textFile = window.URL.createObjectURL(data);

        // Return a URL you can use as an href
        return textFile;
    }

    const renderRatingLevel = (inRatingLevel) => {
        switch(inRatingLevel) {
            case DEFAULT_RATING_LEVEL_SIGNAL:
                return "3 Level";
            case DEFAULT_RATING_LEVEL_STARS:
                return "5 Level";
            default:
                console.error(`Unable to get Rating Level text for specified RatingLevel. Unknown Rating Level: ${inRatingLevel}`);
                return ""; // return space
            }

    }

    const onChangeStartDate = (updatedStartDt) => {
        let modifiedState = state;
        let currentDate = new Date();

        if(updatedStartDt > currentDate) {
            modifiedState.errors.timeRelatedErr = "Start Date can not be greater than Current Date. It will be set to Current Date.";
            modifiedState.startDate = currentDate;
            modifiedState.endDate = currentDate;

            if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0 && updatedStartDt > modifiedState.endDate) {
                modifiedState.errors.timeRelatedErr = "End Date can not be less than Start Date. It will be set same as to Start Date.";
                modifiedState.startDate = updatedStartDt;
                modifiedState.endDate = updatedStartDt;
            }

        } else if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0 && updatedStartDt > modifiedState.endDate) {
            modifiedState.errors.timeRelatedErr = "End Date can not be less than Start Date. It will be set same as to Start Date.";
            modifiedState.startDate = updatedStartDt;
            modifiedState.endDate = updatedStartDt;

        } else {
            modifiedState.errors.timeRelatedErr = "";
            modifiedState.startDate = updatedStartDt;
        }
        modifiedState.custComlaintTableData = [];
        modifiedState.selectedRowIndex = null;

        modifiedState.startDateLength = modifiedState.startDate.toString().length;
        modifiedState.endDateLength = modifiedState.endDate.toString().length;

        if(modifiedState.startDateLength != null && modifiedState.startDateLength > 0){
            modifiedState.startDateSelected = true;
        }

        if(modifiedState.startDateLength > 0 || modifiedState.endDateLength > 0){
            modifiedState.showClearBtn = true;
        }

        setState({...modifiedState});
    }

    const onChangeEndDate = (updatedEndDt) => {
        let modifiedState = state;
        let currentDate = new Date();

        if(updatedEndDt > currentDate) {
            modifiedState.errors.timeRelatedErr = "End Date can not be greater than Current Date. It will be set to Current Date.";
            modifiedState.endDate = currentDate;

        } else if(updatedEndDt < modifiedState.startDate) {
            modifiedState.errors.timeRelatedErr = "Start Date can not be greater than End Date. It will be set as same to End Date.";
            modifiedState.startDate = updatedEndDt;
            modifiedState.endDate = updatedEndDt;

        } else {
            modifiedState.errors.timeRelatedErr = "";
            modifiedState.endDate = updatedEndDt;
        }

        modifiedState.startDateLength = modifiedState.startDate.toString().length;
        modifiedState.endDateLength = modifiedState.endDate.toString().length;

        if(modifiedState.endDateLength != null && modifiedState.endDateLength > 0){
            modifiedState.endDateSelected = true;
        }

        if(modifiedState.startDateLength > 0 || modifiedState.endDateLength > 0){
            modifiedState.showClearBtn = true;
        }

        modifiedState.custComlaintTableData = [];
        modifiedState.selectedRowIndex = null;

        setState({...modifiedState});
    }

    const clearDates = () => {

        let modifiedState = state;

        if(modifiedState.startDate != null && modifiedState.startDate.toString().length > 0){
            modifiedState.startDate = '';
        }
        
        if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0){
            modifiedState.endDate = '';
        }

        modifiedState.showClearBtn = false;
        modifiedState.startDateSelected = false;
        modifiedState.endDateSelected = false;
        modifiedState.errors.others = "";
        modifiedState.errors.timeRelatedErr = "";

        getToiletAndItsRatingInfo(modifiedState);

    }

    return (
        <div>
            <div className="row justify-content-center">
                <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1.2rem"}}>
                    {state.invokedFrom == "invokedFromToiletReport" ? "Report - Toilet Rating Info And User Count" : "Report - Toilet Users Count Info"}
                </div>
                <div className="divForShowingDashBoard">
                    <div className="startAndEnddate">
                        <div style={{marginLeft:"-0.5rem"}}>
                            <label style={{fontSize:"0.9rem", marginRight:"-1.2rem"}}>
                                Start Date:
                            </label>
                            <DateTimePicker
                                clearIcon={null}
                                onChange={onChangeStartDate}
                                format={"yyyy/MM/dd"} 
                                value={state.startDate} 
                                name="startDateTime"
                                timeFormat={false}
                                className="dashBoardDateTimeZIndexToiletRatingReport"
                            />                                
                        </div>
                        <div className='divForEndDateToiletRatingReport'>
                            <label style={{fontSize:"0.9rem", marginRight:"-1.2rem"}}>
                                &nbsp;End Date:
                            </label>
                            <DateTimePicker
                                clearIcon={null}
                                onChange={onChangeEndDate}
                                format={"yyyy/MM/dd"} 
                                value={state.endDate} 
                                name="endDateTime"
                                timeFormat={false}
                                className="dashBoardDateTimeZIndexToiletRatingReport"
                            />           
                        </div>

                        <div className='divToShowRefreshAndClearBtnDashBoard'
                                style={{display: "flex", justifyContent: "center", flexDirection: "row"}}>
                            <div style={{marginLeft: "1.8rem"}}
                                hidden = {state.showClearBtn == true ? false : true}
                            >
                                <button type="button"
                                        title= {state.startDateSelected == true ? "Clear Selected Dates." : 
                                                state.endDateSelected == true ? "Clear End Date." : "Clear"}
                                        onClick={ () => clearDates()} 
                                        style={{borderRadius: "5px",
                                                color:"white", 
                                                fontWeight:"900",
                                                width: "110%"

                                        }}
                                        className="iconButton"
                                > 
                                    X 
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="divToShowInWeb" style={{width: state.invokedFrom=="invokedFromPublicCountReport" ? "24%" : "39%", justifyContent: "space-between"}}>
                        <div style={{display: "flex", width: state.invokedFrom=="invokedFromPublicCountReport" ? "62%" : "35%"}}>
                            <label style={{fontSize:"0.95rem", marginTop: "0.3rem"}}>
                                Select Pincode: 
                            </label>
                                <input type="list" list="dataListUserId"
                                    style={{width: state.invokedFrom=="invokedFromPublicCountReport" ? "48%" : "43%", height: "2rem", marginLeft: "0.2rem"}}
                                    value={state.selectedPincode}
                                    onClick={onClickDataList}
                                    onChange={onChangePincode}
                                />
                                <datalist id="dataListUserId" ref = {state.UserIdRef}>
                                    <option value="" selected disabled>Select Pincode...</option> 
                                    {state.arrPincode.map((singlePincode, index) =>
                                        <option key={index} value={singlePincode.PinCode}>{singlePincode.PinCode}</option>
                                    )}
                                </datalist>
                        </div>
                        <div style={{display: state.invokedFrom=="invokedFromPublicCountReport" ? "none" : "block"}}> 
                            <label style={{fontSize:"0.95rem", marginRight: "0.2rem"}}>
                                Select Rating Level:
                            </label>
                            <select style={{ background:"white", height:"2rem"}} 
                                    required
                                    value={state.selectedRatingLevel}
                                    onChange={onChangeRatingLevel}
                            >
                                {(ARR_DEFAULT_RATING_LEVEL).map((singleRatingLevel, index) => <option key={index} value={singleRatingLevel}>{renderRatingLevel(singleRatingLevel)}</option>)}
                            </select>
                        </div>
                        <div>
                            <button type="button"
                                    title="Get Toilet Rating Info and User Count."
                                    onClick = {onClickRefresh}
                                    className="iconButton"
                                    style={{borderRadius: "5px", 
                                            color:"white",
                                            fontWeight:"700",
                                            height: "2rem"
                                        }}
                            >
                                Refresh
                            </button>
                        </div>
                        <div>
                            <button type="button"
                                    name = "download"
                                    title= 'Download Data in CSV file format.'
                                    onClick = {onClickDownload}
                                    className="iconButton"
                                    style={{
                                            borderRadius: "5px", 
                                            color:"white", 
                                            fontWeight:"700",
                                            height: "2rem",
                                            width: "2rem"
                                        }}
                            >
                                <i className="fa fa-download" aria-hidden="true" style={{paddingLeft: "0rem"}}></i>
                            </button>
                        </div>
                    </div>

                    <div className="divToShowInMob">
                        <div>
                            <label style={{fontSize:"0.9rem", marginTop: "0.3rem"}}>
                                Select Pincode: 
                            </label>
                                <input type="list" list="dataListUserId"
                                    style={{width: "46%", height: "2rem", marginLeft: "0.2rem"}}
                                    value={state.selectedPincode}
                                    onClick={onClickDataList}
                                    onChange={onChangePincode}
                                />
                                <datalist id="dataListUserId" ref = {state.UserIdRef}>
                                    <option value="" selected disabled>Select Pincode...</option> 
                                    {state.arrPincode.map((singlePincode, index) =>
                                        <option key={index} value={singlePincode.PinCode}>{singlePincode.PinCode}</option>
                                    )}
                                </datalist>
                        </div>
                        <div style={{display: state.invokedFrom=="invokedFromPublicCountReport" ? "none" : "block"}}>
                            <label style={{fontSize:"0.9rem", marginTop: "0.5rem", marginRight: "0.2rem"}}>
                                Select Rating Level:
                            </label>
                            <select style={{ background:"white", height:"2rem"}} 
                                    required
                                    value={state.selectedRatingLevel}
                                    onChange={onChangeRatingLevel}
                            >
                                {(ARR_DEFAULT_RATING_LEVEL).map((singleRatingLevel, index) => <option key={index} value={singleRatingLevel}>{renderRatingLevel(singleRatingLevel)}</option>)}
                            </select>
                        </div>
                        <div>
                            <button type="button"
                                    title="Get Toilet Rating Info and User Count."
                                    onClick = {onClickRefresh}
                                    className="iconButton"
                                    style={{borderRadius: "5px", 
                                            color:"white",
                                            fontWeight:"700",
                                            height: "2rem"
                                        }}
                            >
                                Refresh
                            </button>
                            <button type="button"
                                    name = "download"
                                    title= 'Download Data in CSV file format.'
                                    onClick = {onClickDownload}
                                    className="iconButton"
                                    style={{
                                            borderRadius: "5px", 
                                            color:"white",
                                            marginLeft:"0.2rem", 
                                            fontWeight:"700",
                                            height: "2rem"
                                        }}
                            >
                                <i className="fa fa-download" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div>
                    {state.errors.timeRelatedErr.length > 0 && 
                        <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center", marginTop: "1rem"}} className='error'>{state.errors.timeRelatedErr}</p> 
                    }
                </div>

                <div>
                    {state.errors.others.length > 0 && 
                        <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center", marginTop: "1rem"}} className='error'>{state.errors.others}</p> 
                    }
                </div>
                <div style = {{padding: "0.8rem" }}>
                    {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px"}}> */}
                    <div className="border border-3 ReactTableStyle">
                        <ReactTable 
                            columns={state.invokedFrom != null && 
                                state.invokedFrom == "invokedFromPublicCountReport" ? 
                                state.columnsForPublicFootCount : state.selectedRatingLevel == DEFAULT_RATING_LEVEL_STARS ? 
                                state.columnsForStarRating : state.columnsForSignalRating}
                            data={state.arrToiletInfoAndItsRatingInfo}
                        />
                    </div>
                </div>
            </div>
        </div>  
    );
}

export default VcToiletRatingReport;