import React, { useContext, useEffect, useState } from 'react'
import { IoWater, IoWaterOutline } from 'react-icons/io5'
import { AWL, TCWL, VOL, WLIP } from '../../../VcConstants'
import axios from 'axios'
import { getAPIHostURL } from '../../../ClientConfig'
import { IDS_AlertAt, IDS_AvailableWater, IDS_BusiestHour, IDS_CurrentHourWaterUsage, IDS_Last24HourData, IDS_LeastBusyHour, IDS_MaxWaterUsage, IDS_MinWaterUsage, IDS_TankCapacity, IDS_TotalPeopleCount, IDS_TotalUsed, IDS_TotalUsedWater, IDS_WaterLevelIndication, IDS_WaterUsagePerUser, IDS_YesterdayWaterUsage, IDS_Yesterday_s, IDS_TodaysWaterLevelIndication, IDS_Litre_Short, IDS_Last24HourHighlights} from '../../../VcLanguage'
import { AppRelevantDataContext } from '../../../AppContext'
import { useSelector } from 'react-redux'



// let wliDetails = {
//     totalPeopleCount: state.totalPeopleCount,
//      SingleDeviceParamSequence: state.SingleDeviceParamSequence
//     yestTotalWaterUsage: state.yestTotalWaterUsage,
//     waterUsagePerUser: state.waterUsagePerUser,
//    isPfcDevicePresentAsSibling: state.isPfcDevicePresentAsSibling,
//     alertRange: state.relevantModelInfo.WLIP,
//     singleDeviceParamValue: state.SingleDeviceParamValue,
// }
const VcWLIc = ({wliDetails}) => {
    const context = useContext(AppRelevantDataContext)
    let t = context.t;    

    const [yesterdayUsageAthour, setyesterdayUsageAthour] = useState(0)
    const [alertSetting, setalertSetting] = useState({
        alertLow: '-',
        alertHigh: '-',
    })

    const wliData = useSelector(s => s.variables.wliData)



    let wave2style =  {
        position: "absolute",
        top: `${50 - (wliDetails?.singleDeviceParamValue.WLIP < 5 ? 5 : wliDetails?.singleDeviceParamValue.WLIP )}%`,
        left: '50%',
        width: '200%',
        height: '200%',
        transform: 'translate(-50%, -75%)'
      }

    
  return (
    <div className='flex gap-2  rounded flex-column flex-md-row justify-center '>
        <div className='flex items-center gap-2 bg-white rounded w-100 w-md-50 flex-col py-2 px-1'>
            <div className='fs-6'>
                {t(IDS_TodaysWaterLevelIndication)}
            </div>
            <div className='flex-center h-100 flex-column'>
                {wliDetails?.SingleDeviceParamSequence.includes(VOL) && <div className='flex items-center gap-2'>
                    <span className='flex-center p-2 rounded-full border border-1 border-primary'><IoWater/></span>
                    <span className='flex flex-col text-sm text-start'>
                        <span className='tracking-wide'>{t(IDS_TankCapacity)}</span>
                        <span className='fw-semibold text-primary fs-6 '>{wliDetails?.singleDeviceParamValue.VOL == null ? "--" : wliDetails?.singleDeviceParamValue.VOL} {t(IDS_Litre_Short)}</span>
                    </span>
                </div>}
                <div className='position-relative bg-red  flex flex-col justify-center' style={{height:'200px'}}>
                    
                    <div className='p-4 flex items-center justify-center'>
                        <div className="circle">
                            <span className='z-1 h4 absolute-center' style={{zIndex:1}}>{wliDetails?.singleDeviceParamValue.WLIP == null ? "--" : wliDetails?.singleDeviceParamValue.WLIP}%</span>
                            <div className="wave">
                                <div className=' wavea' style={wave2style}></div>
                                <div className=' waveb' style={wave2style}></div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='flex flex-column flex-md-row justify-between gap-md-6 gap-2 w-100  text-start'>
                    
                
                    {wliDetails?.SingleDeviceParamSequence.includes(TCWL) &&
                    <div className='flex items-center  gap-2'>
                        <span className='flex-center p-2 rounded-full border border-1 border-primary '><IoWaterOutline/></span>
                        <span className='flex flex-col text-sm'>
                            <span className='tracking-wide'>{t(IDS_TotalUsedWater)}</span>
                            <span className='fw-semibold text-primary fs-6 '>{wliDetails?.singleDeviceParamValue.TCWL == null ? "--" : wliDetails?.singleDeviceParamValue.TCWL} {t(IDS_Litre_Short)}</span>
                        </span>
                    </div>}
                    {wliDetails?.SingleDeviceParamSequence.includes(AWL) &&
                    <div className='flex items-center  gap-2'>
                        <span className='flex-center p-2 rounded-full border border-1 border-primary'><IoWaterOutline/></span>
                        <span className='flex flex-col text-sm'>
                            <span className='tracking-wide'>{t(IDS_AvailableWater)}</span>
                            <span className='fw-semibold text-primary fs-6 '>{wliDetails?.singleDeviceParamValue.AWL == null ? "--" : wliDetails?.singleDeviceParamValue.AWL} {t(IDS_Litre_Short)}</span>
                        </span>
                    </div>}
                </div>
            </div>
            
            
        </div>
        
        <div className='flex flex-col w-100 w-md-50 gap-2 text-start'>

            {wliDetails?.isPfcDevicePresentAsSibling && <div className='flex bg-white rounded flex-col px-3 py-2 gap-2'>
                <p className='text-center'>{t(IDS_YesterdayWaterUsage)}</p>
                <div className='flex flex-col h-100  '>
                    <div className='flex flex-col justify-around ps-4 startBlueBorder'>
                        <span className='text-red-500 '>{t(IDS_WaterUsagePerUser)}</span>
                        <span className='fw-light fs-6 fs-md-3'>{wliDetails?.waterUsagePerUser} {t(IDS_Litre_Short)}</span>
                    </div>
                    <hr className='border-1 w-100 w-md-50'/>

                    <div className='flex flex-col justify-around ps-4 startBlueBorder'>
                        <span className='text-blue-700 '>{t(IDS_TotalUsedWater)}</span>
                        <span className='fw-light fs-6 fs-md-3'>{wliDetails?.yestTotalWaterUsage} {t(IDS_Litre_Short)}</span>
                    </div>
                    <hr className='border-1 w-100 w-md-50'/>

                    <div className='flex flex-col justify-around ps-4  startBlueBorder'>
                        <span className='text-green-700 '>{t(IDS_TotalPeopleCount)}</span>
                        <span className='fw-light fs-6 fs-md-3'>{wliDetails?.totalPeopleCount}</span>
                    </div>

                </div>
            </div> }
            
            <div className='flex flex-col h-100  bg-white px-3 py-2 rounded '>
                <p className='text-center'>{t(IDS_Last24HourHighlights)}</p>

                <div className='flex flex-col justify-around ps-4 startBlueBorder'>
                    <span className='text-red-500 '>{t(IDS_CurrentHourWaterUsage)}</span>
                    <span className='fw-light fs-6 fs-md-3'>{wliData.currentHourUsage} {t(IDS_Litre_Short)}</span>
                </div>
                <hr className='border-1 w-100 w-md-50'/>


                <div className='flex flex-col justify-around ps-4 startBlueBorder'>
                    <span className='text-green-700 '>{t(IDS_MaxWaterUsage)}</span>
                    <span className='fw-light fs-6 fs-md-3'>{wliData.maxUsageHour}</span>
                </div>
                <hr className='border-1 w-100 w-md-50'/>

                <div className='flex flex-col justify-around ps-4 startBlueBorder'>
                    <span className='text-blue-700 '>{t(IDS_MinWaterUsage)}</span>
                    <span className='fw-light fs-6 fs-md-3'>{wliData.minUsageHour}</span>
                </div>

                
            </div>

        </div>
     
  </div>
  )
}

export default VcWLIc