import { createSlice } from "@reduxjs/toolkit";

const treeAndSearchSlice = createSlice({
    name: "DeviceTree/Search",
    initialState: {
        searchedOrClickedToiletNodeID: '',
        openToiletNodesID: [],
    },
    reducers: {

        setSearchedOrClickedToiletNode: (state, action) => {
            state.searchedOrClickedToiletNodeID = action.payload;
        },


        addToOpenToiletNodes: (state, action) => {
            let currentOpenedToiletsArr = state.openToiletNodesID;
            if(!currentOpenedToiletsArr.includes(action.payload)) {
                currentOpenedToiletsArr.push(action.payload)
            }
            state.openToiletNodesID = currentOpenedToiletsArr;
        },

        toggleToiletNodeInOpenToiletNodes : (state, action) => {
            let toiletID = action.payload;
            let currentOpenedToiletsArr = state.openToiletNodesID;
            if (currentOpenedToiletsArr.includes(toiletID)) {
                currentOpenedToiletsArr = currentOpenedToiletsArr.filter(id => id != toiletID);
            } else {
                currentOpenedToiletsArr.push(toiletID);
            }
            state.openToiletNodesID = currentOpenedToiletsArr;
        },

        clearSearchOrClickedToiletNode: (state) => {
            if(state.searchedOrClickedToiletNodeID != null && state.searchedOrClickedToiletNodeID.length > 0) {
                state.searchedOrClickedToiletNodeID = ''
            }
        },

        clearOpenToiletNodes: (state) => {
            if(state.openToiletNodesID != null && state.openToiletNodesID.length > 0) {
                state.openToiletNodesID = []
            }
        }
    }
});

export const { setSearchedOrClickedToiletNode, addToOpenToiletNodes, toggleToiletNodeInOpenToiletNodes, clearSearchOrClickedToiletNode, clearOpenToiletNodes } = treeAndSearchSlice.actions;

export default treeAndSearchSlice.reducer;
