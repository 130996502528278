import React, { useContext, useEffect, useState } from 'react';
import '../CSS/Support.css';
import App from '../../App';
import { AppRelevantDataContext } from '../../AppContext';
import VcSupportForm from './VcSupportForm';
import {useNavigate} from 'react-router-dom';
import VcSideNav from './DeviceHome/VcSideNav';
import { GrMenu } from 'react-icons/gr';
import vilisoLogoTransparent from './../IMAGES/vilisoLogoTransparent.png'
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { clearDeviceList } from './Store/Slices/deviceList';
import { clearTreeNodeList } from './Store/Slices/treeNodeList';
import { RiMenuUnfoldLine } from 'react-icons/ri';

function VcSupport (props) {

  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [state, setState] = useState({
    showDrawer: false,
  })
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  
  useEffect(() => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
    
    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.
      // Try to get this value if present in LocalStorage and update the app state (so that
      // re-render will happen). If app context data not present in LocalStorage then
      // show the Login Page
      if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() === false ) {
        // App Relevant Data Context not present in LocalStorage as well.
        navigate('/', { replace: true });
      } else {
        // App Relevant Data Context loaded from LocalStorage at AppLeval.
        // Re-render will happen automatically (no action required).
      }

    }

    dispatch(clearTreeNodeList())
    dispatch(clearDeviceList())
    
  }, []);

  useEffect(() => {

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
    window.removeEventListener('resize', handleResize);
    };
}, []);


  let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

  if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.

      // Next re-render will happen automatically when the AppState is updated again 
      // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
      return App.renderLoadingPage();
  }


  if(windowWidth < 570) {
   return  <>  
     <div  className={`drawerDiv ${state.showDrawer ? 'drawerDivIn' : 'drawerDivOut'}`}>
         <VcSideNav />
         <FaArrowAltCircleLeft 
             className="closingArrow top-nav" onClick={()=> setState({...state , showDrawer : false})}
         />
     </div>
     <div className='bg-white shadow flex items-center justify-between px-2 py-1 m-1 ' style={{position:"sticky", top:"0", borderRadius:"0.75rem"}}>
         <div className='' style={{width:"60px"}}>
           <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
         </div>
         {state.showDrawer ? 
         <MdClose classname='transition-all' onClick={()=> setState({...state , showDrawer : false})}/>:
         <span onClick={()=> setState({...state , showDrawer : true})}><RiMenuUnfoldLine style={{ fontSize: "1.2rem" }} /></span> 
         }
     </div>

      <VcSupportForm/>           

     
     </>
  } 
   return (
     <div className='w-100 d-flex relative '>
         <div className='shadow' style={{width:"4%", position:"relative", zIndex:"5"}}>
              <VcSideNav/>
         </div>
         <div style={{width:"96%" }}>
          <VcSupportForm/>

         </div>
     </div>
   )
}

export default VcSupport;