import React, { useState, useContext, useEffect } from 'react';
import logoviliso from '../IMAGES/vilisoLogo.png';
import '../CSS/RegistrationForm.css';
import { Link } from "react-router-dom";
import {Navigate} from 'react-router-dom';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { IDS_RegistNoDevice, IDS_LoginServerIssue, IDS_PhoneNumberErrMsg, IDS_RegistUpdtPlsLogin, IDS_RegistLinkExpBckToLogin, IDS_ShowPassword } from '../../VcLanguage';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_EmailID, IDS_FirstName, IDS_LastName, IDS_PhoneNo, IDS_Password, IDS_ConfirmPassword, IDS_Register,
        IDS_RegistTermsIHave, IDS_RegistTermsOfUse, IDS_RegistTermsAnd, IDS_RegistTermsIHavePrivacy, IDS_ModalTemsOfUse,
        IDS_RegistAlreadyRegistred, IDS_RegistEmailError, IDS_RegistPasswordError,
        IDS_RegistTerms, IDS_RegistSuccessfulyMessage, IDS_RegistEmailAlready, IDS_RegistNetworkError,
        IDS_RegistSuccessfulyLogin, IDS_RegistPasswordLengthError, IDS_RegistLinkSentChkEmail} from '../../VcLanguage';
import { SMART_HHM_AUTH } from '../../VcConstants';
import {useLocation} from 'react-router-dom';

// const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

function VcRegistration (props) {
  const location = useLocation();
  const context = useContext(AppRelevantDataContext);
  const [state, setState] = useState({
    redirect:false,
    email: "",
    password: "",
    phoneno: "",
    confirmPassword: "",
    firstname: "",
    lastname: '',
    isTermsAndConditionsChecked: false,
    invokedFrom: props.invokedFrom,
    selectedUserID: "",
    hideForm: true,
    CountryInfo: [],
    CountryCode: "+91",
    passwordShown: false,
    errors: { 
      email: '',
      password: '',
      phoneno:'',
      confirmPassword:'',
      others:''
    },
    successfulRegisterMsg: '',
    termsAndConditionsIsChecked: '',
    modal: false,
    backdrop: 'static'
  })

  const isTermsAndConditionsChecked = (e) => {
    let modifiedState = state;
    modifiedState.termsAndConditionsIsChecked = "";
    modifiedState.isTermsAndConditionsChecked = e.target.checked;
    modifiedState.successfulRegisterMsg = "";

    setState({...modifiedState});
  }  

  useEffect(() => {
    let appRelevantDataContextValue = context;

    // To get Info from LS which have persisted even after logout 
    // (for eg. Language and Last Loggedin UserID).
    appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage(true);

    getAllCountryCodeInfo();
  },[]);

  const getValidateUserDetails =  (inModifiedState=null) => {

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let modifiedState;

    if(inModifiedState == null){
      modifiedState = state;
    } else {
      modifiedState = state;
    }

    // getting unique key as forgotPassKey from url of reset passowrd page.
    modifiedState.selectedUserID = location.pathname.split('/').pop()

    const jsonBody = {
      userId: modifiedState.selectedUserID,
      authType: SMART_HHM_AUTH,
    }

    axios.post( `${getAPIHostURL()}/wclient/getUserInformation`, jsonBody)
    .then(response => {

      if(response.data.code == 'SUCCESS') {

        const resData = (response.data.retrivedUserInformation == null || response.data.retrivedUserInformation.length <= 0) ?
                        [] : response.data.retrivedUserInformation[0];

        if(resData == null || resData.length <=0) {
          alert(t(IDS_RegistLinkExpBckToLogin));
          modifiedState.redirect = !modifiedState.redirect;
        } else {
          modifiedState.hideForm = false;

          modifiedState.email = resData.UserEmailID;
          modifiedState.CountryCode = resData.userPhoneNo.split(' ')[0] == null || resData.userPhoneNo.split(' ')[0].length <= 0 ? '+91' : resData.userPhoneNo.split(' ')[0];
          modifiedState.phoneno = resData.userPhoneNo.split(' ')[1];
          modifiedState.firstname = resData.FirstName;
          modifiedState.lastname = resData.LastName;
        }
        setState({...modifiedState});
      } else {

        if ( response.data.code == 'SQL_ERROR' ) {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);

        } else {
          console.log('Should not reach here');
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
        // Display the specific error message for Reset Password failure
        setState({...modifiedState});
      }
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError);
      setState({...modifiedState});
    }); 

  }

  const onChangeCountryCode = (e) => {
    let modifiedState = state;
    modifiedState.CountryCode = e.target.value;
    modifiedState.successfulRegisterMsg = "";

    setState({...modifiedState});
  }

  const getAllCountryCodeInfo = () => {

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = state;

    axios.post(`${getAPIHostURL()}/wclient/getAllCountryCodeInformationForReg`)
    .then(response => {
      if(response.data.code == "SUCCESS") {
          modifiedState.CountryInfo = [];
          modifiedState.CountryInfo = response.data.CountryInfo;
          if(state.invokedFrom != null && state.invokedFrom.length > 0) {
            getValidateUserDetails(modifiedState);
          } else {
            modifiedState.hideForm = false;
            setState({...modifiedState});
          }
      } else {

        if(response.data.code == "SQL_ERROR") {
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          modifiedState.successfulRegisterMsg = t(IDS_RegistSuccessfulyLogin);
        }
        setState({...modifiedState});
      }
    })
    .catch(error => {
      console.log("Network error:");
      console.log(error);
      if (axios.isCancel(error)) {
        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
      } else {
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        setState({...modifiedState});
      } 
    });
  }

  const onRegisterSubmit = (event) => {
    event.preventDefault();

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = state;
    modifiedState.successfulRegisterMsg = '';
    
    if(!validEmailRegex.test( modifiedState.email)) {
      modifiedState.errors.email = t(IDS_RegistEmailError);
      setState({...modifiedState});
    } else if(modifiedState.password != modifiedState.confirmPassword && modifiedState.invokedFrom != "validateEmailId") {   
      modifiedState.errors.confirmPassword = t(IDS_RegistPasswordError);
      setState({...modifiedState});
    } else if (modifiedState.isTermsAndConditionsChecked == false && modifiedState.invokedFrom != "validateEmailId") {
      modifiedState.termsAndConditionsIsChecked = t(IDS_RegistTerms);
      setState({...modifiedState});
    } else if(modifiedState.phoneno == null || modifiedState.phoneno.length < 4 || modifiedState.phoneno.length > 12) {
      setState({...modifiedState});
    } else {

      // Convert UserID to lowercase and remove all whiteSpaces before sending to Server as it makes comparison easy and valid.
      let UserID = modifiedState.email.trim();
      UserID = UserID.toLowerCase();

      const allData = {
        email: UserID,
        firstname:modifiedState.firstname, 
        lastname:modifiedState.lastname, 
        phoneno: modifiedState.CountryCode +" " +modifiedState.phoneno, 
        password: modifiedState.password,
        selectedUserID: modifiedState.selectedUserID,
        authType: SMART_HHM_AUTH
      };

      if(window.location.pathname.includes("/registration") == true ) {
        // Show spinner to restrict other user actions to be perform.
        appRelevantDataContextValue.onChangeProcessingReq(true);
      }

      axios.post(`${getAPIHostURL()}/wclient/userRegister`, {allData})
      .then(response => {
        // Remove spinner and allow user to perform action.
        appRelevantDataContextValue.onChangeProcessingReq(false);
        
        if(response.data.code == "SUCCESS") {
          if(modifiedState.selectedUserID != null && modifiedState.selectedUserID.length > 0) {
            if(window.confirm(t(IDS_RegistSuccessfulyMessage))) {
              modifiedState.redirect = !modifiedState.redirect;
            }
          } else {
            alert(t(IDS_RegistLinkSentChkEmail));
            modifiedState.email =  "";
            modifiedState.phoneno =  "";
            modifiedState.password =  "";
            modifiedState.confirmPassword =  "";
            modifiedState.firstname =  "";
            modifiedState.lastname =  "";
            modifiedState.isTermsAndConditionsChecked = false;
            modifiedState.successfulRegisterMsg = t(IDS_RegistLinkSentChkEmail);
          }
        } else {
          // if(response.data.sqlMessage == "EMLID_ALREADY_REGISTERED") {
          //   modifiedState.errors.others = t(IDS_RegistEmailAlready);
          // } else if (response.data.sqlMessage == "NO_DEVICE_REGISTERED_FOR_USER"){
          //   modifiedState.errors.others = t(IDS_RegistNoDevice);
          // } else {
          //   modifiedState.successfulRegisterMsg = t(IDS_RegistSuccessfulyLogin);
          // }

          if(response.data.code == "EMLID_ALREADY_REGISTERED") {
            modifiedState.successfulRegisterMsg = t(IDS_RegistEmailAlready);
          } else if (response.data.code == "REGIST_DETAILS_UPDATED_SUCCESS") {
            modifiedState.errors.others = t(IDS_RegistUpdtPlsLogin);
          } else if (response.data.code == "REQ_PARAMS_MISSING") {
            modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else if(response.data.code == "SQL_ERROR"){
            modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else if(response.data.code == 'SUPPORT_DETAILS_MISSING'){
            modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
            modifiedState.successfulRegisterMsg = t(IDS_RegistSuccessfulyLogin);
          }
        }
        setState({...modifiedState});
      })
      .catch(error => {
        // Remove spinner and allow user to perform action.
        appRelevantDataContextValue.onChangeProcessingReq(false);
        console.log(error);
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        setState({...modifiedState});
      });
    }
  }

  const renderRedirect = () =>{
    if(state.redirect){
      return<Navigate to="/"/>    
    }
  }

  const handleChange = (event) => {
    event.preventDefault();
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let modifiedState = state;
    modifiedState.errors.confirmPassword = "";
    modifiedState.successfulRegisterMsg = "";

    const { name, value } = event.target;
    let errors = state.errors;
  
    switch (name) {
      case 'email': 
        errors.email = 
        value.length > 0 ?
            (
                (validEmailRegex.test(value)) ? 
                '' :  
                t(IDS_RegistEmailError)
            )
        : '';
        break;
      case 'password': 
        errors.password = 
        ((value.length < 1 ) || (value.length > 7))
            ? ""
            : t(IDS_RegistPasswordLengthError);
        break;
      case 'phoneno': 
        errors.phoneno = 
        (value.length < 4 ||  value.length > 12)
            ? t(IDS_PhoneNumberErrMsg) : '';      
        break;
      default:
        break;
    }
  
    setState({
      ...modifiedState,
      errors, 
      [name]: value,
      [event.target.name]:event.target.value,
      })
  }

  const toggle = (e) => {
    let modifiedState = state;

    modifiedState.modal = !modifiedState.modal;
    setState({...modifiedState});
  }

  const handleKeyPress = (e) => {
    const characterCode = e.key
    const characterNumber = Number(characterCode)
    if (characterNumber >= 0 && characterNumber <= 9) {

        if (e.currentTarget.value && e.currentTarget.value.length) {
            return;
        } 
        else if (characterNumber == 0) {
            e.preventDefault()
        }
    } 
    else {
        e.preventDefault()
    }
} 

const onTogglePassword = () => {
  let modifiedState = state;
  modifiedState.passwordShown = !modifiedState.passwordShown;
  setState({...modifiedState});
}

  const{errors, successfulRegisterMsg, termsAndConditionsIsChecked}=state;
  let appRelevantDataContextValue = context;
  let t = appRelevantDataContextValue.t;

  const color = {
      color : "var(--errorColor)",
      fontSize:"13px"
  }

  return (
      <div className="container" hidden={state.hideForm}>
          <div className="row justify-content-center">
              <div className="container col-lg-6 col-lg-offset-3
                                        col-md-8 col-md-offset-2">
                  <div className="modal-body p-4 box">
                      <div className="form-group reg-form">
                          <img src={logoviliso}/>
                      </div>
                      <form onSubmit={onRegisterSubmit} >
                          <div className="form-group reg-form">
                              <div className="inputgroupCustom">
                                  <label className="reg-form-label">{t(IDS_EmailID)}:</label>
                                  <input type='email' name='email' className="rounded-pill form-control input-form" required="required" 
                                    value={state.email}
                                    disabled = {state.invokedFrom != null && state.invokedFrom == "validateEmailId"}
                                    onChange={handleChange} noValidate />
                                        {errors.email.length > 0 && 
                                      <span style={color} className='error'>{errors.email}</span>}        
                              </div>
                          </div>
                          <div className="form-group reg-form">
                              <div className="inputgroupCustom">
                                  <label className="reg-form-label">{t(IDS_FirstName)}:</label>
                                  <input type="text" className="rounded-pill form-control input-form" name="firstname" 
                                    value={state.firstname}
                                    onChange={handleChange} 
                                    required="required"/>
                              </div>
                          </div>
                          <div className="form-group reg-form">
                              <div className="inputgroupCustom">
                                  <label className="reg-form-label">{t(IDS_LastName)}:</label>
                                  <input type="text" className="rounded-pill form-control input-form" name="lastname"   
                                  value={state.lastname}
                                  onChange={handleChange} 
                                  required="required"/>
                              </div>
                          </div>
                          <div className="form-group reg-form">
                              <div className="inputgroupCustom">
                                  <label className="reg-form-label">{t(IDS_PhoneNo)}:</label>
                                  <span style={{display:"flex", justifyContent: "space-around"}}>
                                    <select className="rounded-pill form-control input-form" 
                                            style={{width:"4.3rem", marginRight:"0.5rem"}}
                                            required
                                            value={state.CountryCode}
                                            onChange={onChangeCountryCode}
                                    >
                                      <option value="+91" select= "true">+91</option> 
                                    {(state.CountryInfo).map((singleCountryCode, index) => 
                                    <option key={index} value={singleCountryCode["CountryCode"]}>
                                      {singleCountryCode["CountryCode"]}
                                      {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                      {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                      {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                      {'\xA0'+singleCountryCode["Country"]}</option>)}

                                    </select>
                                    <input type='number' name='phoneno' className="rounded-pill form-control input-form" required="required"
                                      value={state.phoneno}
                                      onKeyPress= {handleKeyPress} 
                                      style={{width:"15rem"}}
                                      onChange={handleChange} noValidate />
                                  </span>
                              </div>
                              <div style={{textAlign: "initial"}}>
                                  {errors.phoneno.length > 0 && 
                                    <div style={color} className='error'>{errors.phoneno}</div>}  
                              </div>
                          </div>
                          <div className="form-group reg-form" hidden={state.invokedFrom != null && state.invokedFrom == "validateEmailId"}>
                              <div className="inputgroupCustom">
                                  <label className="reg-form-label">{t(IDS_Password)}:</label>
                                  <input autoComplete="off" type={state.passwordShown == true ? "text" : "password"}  name='password' className="rounded-pill form-control input-form" required={state.invokedFrom != "validateEmailId"} 
                                    value={state.password}
                                    onChange={handleChange} noValidate />
                                    {errors.password.length > 0 && 
                                  <p style={color} className='error'>{errors.password}</p>}
                              </div>
                          </div>
                          <div className="form-group reg-form" style ={{marginBottom:"0rem"}} hidden={state.invokedFrom != null && state.invokedFrom == "validateEmailId"}>
                              <div className="inputgroupCustom">
                                  <label className="reg-form-label">{t(IDS_ConfirmPassword)}:</label>
                                  <input autoComplete="off" type={state.passwordShown == true ? "text" : "password"}  name='confirmPassword' className="rounded-pill form-control input-form" required={state.invokedFrom != "validateEmailId"} 
                                    value={state.confirmPassword}
                                    onChange={handleChange} noValidate />
                                    {errors.confirmPassword.length > 0 && 
                                  <p style={color} className='error'>{errors.confirmPassword}</p>}
                              </div>
                          </div>
                          <div className="form-group reg-form" style={{marginTop:"0rem", textAlign: "start"}} hidden={state.invokedFrom != null && state.invokedFrom == "validateEmailId"}>
                              <input type="checkbox"
                                  className="rounded-pill userProfileFormLabel"
                                  onClick= {onTogglePassword}
                                  value = {state.passwordShown}
                              />    
                              <label style={{display: "inline", marginLeft: "0.5rem" }} className="reg-form-label">{t(IDS_ShowPassword)}</label>
                          </div>
                          <div className="form-group reg-form" hidden={state.invokedFrom != null && state.invokedFrom == "validateEmailId"}>
                              <div>
                                <span><input type="checkbox" checked = {state.isTermsAndConditionsChecked} onChange={isTermsAndConditionsChecked}/></span>
                                <span style={{marginLeft: "0.5rem"}}>{t(IDS_RegistTermsIHave)} </span>
                                <span><Button style={{color:"#007bff", backgroundColor: "transparent", border:"none", fontWeight: "900", paddingLeft: "0rem", paddingRight: "0.3rem"}} onClick={toggle}><span>{t(IDS_RegistTermsOfUse)}</span></Button></span>
                                <span  style={{marginTop: "1rem"}}>{t(IDS_RegistTermsAnd)}</span>
                                <span><Button style={{color:"#007bff", backgroundColor: "transparent", border:"none", fontWeight: "900", paddingLeft: "0.3rem"}} onClick={toggle}><span>{t(IDS_RegistTermsIHavePrivacy)}</span></Button></span>
                              </div>
                              <Modal size="xl" isOpen={state.modal} toggle={toggle} backdrop={state.backdrop}>
                                  <ModalHeader toggle={toggle}>{t(IDS_ModalTemsOfUse)}</ModalHeader>
                                  <ModalBody>
                                      <iframe src="https://www.viliso.in/legal/" style={{width: "100%", height: "75vh"}}/>
                                  </ModalBody>
                              </Modal>
                              {<p style={color} className='error'>{termsAndConditionsIsChecked}</p>}
                          </div>
                          <div className="form-group reg-form">
                              {renderRedirect()}
                              <button type="submit" className="loginMapBtn btn-lg rounded-pill btn-block btn" style={{width: "100%", backgroundColor:"#303597", color: "white", opacity: "0.9"}}>{t(IDS_Register)}</button>
                              <div className = "buttonErrorMessage">
                                  {errors.others.length > 0 && 
                                      <p style={color} className='error'>{errors.others}</p>}
                                  {successfulRegisterMsg.length > 0 &&
                                      <p style={{color:'green'}} className='error'>{successfulRegisterMsg}</p>}
                              </div>
                          </div>
                          <div className="foot" hidden={state.invokedFrom != null && state.invokedFrom == "validateEmailId"}> <Link to ="/" className = "textDecoNone">{t(IDS_RegistAlreadyRegistred)}</Link></div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default VcRegistration;


