import React, { Component } from 'react'
import axios from 'axios';
import { IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_AUSrvrIssueReqParamsNotSent, IDS_LoginEmailError } from '../../VcLanguage';
import { AppRelevantDataContext} from '../../AppContext';
import { getAPIHostURL } from '../../ClientConfig';
import {  convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertUTCDateToStrYYYYMMDDHH24MMSS, convertLocalDateToStrYYYYMMDDHH24MMSS, convertLocalDateWithFormatDDMMMYY } from '../../vtUtil';
import { HHM_API_DURATION_ARRAY, HHM_API_HITS_ARRAY, HHM_API_RENEWADDON, HHM_API_RENEWPACKAGE, ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS} from '../../VcConstants';
import { MdCancel } from 'react-icons/md';
import { DatePicker, Select } from 'antd';
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

export class VcRenewVilisoApiPkg extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            LoggedInUserID: "",
            DeviceID:"",
            enableHhmApiRenewalField: false,
            arrAllOrdLineNo: [],
            userEmailID: "",
            UserName: "",
            selectedOrdLineNo: "",
            authInfoIdOfSelectedOrdLineNo: "",
            PkgStartDtTmOfselectedOrdLineNo: "",
            PkgEndDtTmOfselectedOrdLineNo: "",
            PkgStructure : "",
            AddOnStructure: "",
            PkgDurationInMonthOfselectedOrdLineNo:"",
            PkgDurationInDaysOfselectedOrdLineNo: "",
            selectedDurationForApiPkg: "",
            selectedDurationInDaysForApiPkg: "",
            apiHitsOfselectedOrdLineNo: "",
            StatusOfselectedOrdLineNo: "",
            productCodeOfSelectedOrdLineNo: "",
            renewalType: "",
            pricePerDayINR: "",
            pricePerHitINR: "",
            totalPriceOnSelectedDurationINR:"",
            TaxPriceINR: "0.00",
            TaxPercentage: "0",
            taxPercentageOnSelectedDuration: "",
            discountPercentage: "0",
            discountPriceINR: "0.00",
            totalPriceForApiPkgINR: "",
            DurationForApiOrder: HHM_API_DURATION_ARRAY,
            HitsForApiOrder: HHM_API_HITS_ARRAY,
            DurationForApiPkg: [],
            DurationInDaysForApiPkg: [],
            HitsForApiPkg: [],
            HitsForApiAddOn: [],
            selectedRenewalPeriodType: "Day",
            inputSubRenewalPeriod: 1,
            renewalSubPeriodInMonth: 1,
            countOfApiHits: 1,
            renewalStartDate: "",
            renewalEndDate: "",
            basePriceForApiPkgINR: 0,
            totalAddOnApiHits: 1,
            totalAddOnApiHitsPriceINR: "",
            showCustomRenewPackageForm: false,
            showCustomAddOnForm: false,
            customSelectionFlag: false,

            errors: {
                others: "",
                email: "",
                errApiOrder: "",
                selectedDurationForApiPkg: "",
                renewalStartDate: "",
                inputSubRenewalPeriod: "",
                apiHitsForAddOnErr: "",
                countOfApiHits: "",
                taxPercntErr: "",
                totalAddOnApiHits: "",
            },
        }
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        let modifiedState = this.state;

        modifiedState.errors.selectedDurationForApiPkg = modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && 
            (modifiedState.selectedDurationForApiPkg == null || modifiedState.selectedDurationForApiPkg.length <= 0)
            ?
            "Please select Viliso API package duration"
            : "";
        
        modifiedState.errors.inputSubRenewalPeriod = modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWPACKAGE && 
            (modifiedState.inputSubRenewalPeriod == null || modifiedState.inputSubRenewalPeriod.length <= 0 || modifiedState.selectedRenewalPeriodType == null || modifiedState.selectedRenewalPeriodType.length <= 0)
            ?
            "Please select Viliso API package renewal period"
            : "";
        
        modifiedState.errors.countOfApiHits = modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWPACKAGE && 
            (modifiedState.countOfApiHits == null || modifiedState.countOfApiHits.length <= 0)
            ?
            "Please select Viliso API package renewal hit count"
            : "";

        modifiedState.errors.taxPercntErr = modifiedState.renewalType != null && modifiedState.renewalType.length > 0 &&
            (modifiedState.TaxPercentage == null || modifiedState.TaxPercentage.length <= 0)
            ?
            "Please enter tax percentage"
            : "";

        modifiedState.errors.totalAddOnApiHits = modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWADDON && 
            (modifiedState.totalAddOnApiHits == null || modifiedState.totalAddOnApiHits.length <= 0)
            ?
            "Please enter Viliso Add-On API hits"
            : "";

        // if any one of the required fields is not entered by user then return the function and show error message.
        if((modifiedState.errors.selectedDurationForApiPkg != null && modifiedState.errors.selectedDurationForApiPkg.length > 0) ||
            (modifiedState.errors.totalAddOnApiHits != null && modifiedState.errors.totalAddOnApiHits.length > 0) || 
            (modifiedState.errors.renewalStartDate != null && modifiedState.errors.renewalStartDate.length > 0) ||
            (modifiedState.errors.taxPercntErr != null && modifiedState.errors.taxPercntErr.length > 0) ||
            (modifiedState.errors.inputSubRenewalPeriod != null && modifiedState.errors.inputSubRenewalPeriod.length > 0) ||
            (modifiedState.errors.countOfApiHits != null && modifiedState.errors.countOfApiHits.length > 0) 
        ) {
            // if user inputs the renewal start date later than the current date (today) & previous of current package end date (day when the package expired)
            if(modifiedState.errors.renewalStartDate != null && modifiedState.errors.renewalStartDate.length > 0 && modifiedState.showCustomRenewPackageForm) {
                // alert(`Please Enter a Valid Renewal Start Date.\n(The Renewal Start Date must be later than the current package end date: ${this.state.PkgEndDtTmOfselectedOrdLineNo} and today or earlier than today.)`);
                alert(`Please Enter a Valid Renewal Start Date.\n(The Renewal Start Date must be after the current package end date ${this.state.PkgEndDtTmOfselectedOrdLineNo} and no later than today.)`);
            }

            this.setState(modifiedState);
            return;
        }

        // show alert message before renewing Viliso api package
        if(modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWPACKAGE){
            if(!window.confirm(`Are you sure you want to Renew Viliso API package for "${modifiedState.selectedDurationForApiPkg}" for selected order "ApiOrder${modifiedState.UserName}${modifiedState.selectedOrdLineNo}" ?`)){
                return;
            }
        } else if(modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWADDON){
            if(!window.confirm(`Are you sure you want to Renew Viliso API Add-On for "${modifiedState.apiHitsOfselectedOrdLineNo} Hit(s)" for selected order "ApiOrder${modifiedState.UserName}${modifiedState.selectedOrdLineNo}" ?`)){
                return;
            }
        }

        let renewedPkgStartDtTm;
        let renewedPkgValidTillDtTm;
        let strRenewedPkgStartDtTm;
        let strRenewedPkgValidTillDtTm;
        let CurrentPkgStartDtTm;
        let jsonParams;

        // For Renewal of Add-On API Hits, it is considered that the package is active
        if(this.state.renewalType != null && this.state.renewalType == HHM_API_RENEWADDON) {
            strRenewedPkgStartDtTm = convertLocalDateToStrYYYYMMDDHH24MMSS(new Date(modifiedState.PkgStartDtTmOfselectedOrdLineNo));
            strRenewedPkgValidTillDtTm = convertLocalDateToStrYYYYMMDDHH24MMSS(new Date(modifiedState.PkgEndDtTmOfselectedOrdLineNo));

            jsonParams = {
                UserID: modifiedState.userEmailID,
                AuthInfoId: modifiedState.authInfoIdOfSelectedOrdLineNo,
                OrderLineNumber: modifiedState.selectedOrdLineNo,
                StatusOfselectedOrdLineNo: modifiedState.StatusOfselectedOrdLineNo,
                ProductCode: modifiedState.productCodeOfSelectedOrdLineNo,
                PackageDuration: modifiedState.selectedDurationInDaysForApiPkg == "" ? "0" : modifiedState.selectedDurationInDaysForApiPkg,
                ApiHitsInPkg: modifiedState.showCustomAddOnForm ? modifiedState.totalAddOnApiHits : modifiedState.selectedDurationForApiPkg,
                PriceOfHitsInPkgINR: modifiedState.showCustomAddOnForm ? modifiedState.totalAddOnApiHitsPriceINR : modifiedState.totalPriceOnSelectedDurationINR,
                PriceOfDurationInPkgINR: modifiedState.selectedDurationInDaysForApiPkg * modifiedState.pricePerDayINR,
                DiscountPercentage: modifiedState.discountPercentage,
                DiscountPriceINR: modifiedState.discountPriceINR,
                TaxPerPkgINR: modifiedState.TaxPercentage,
                TaxPricePerPkgINR: modifiedState.TaxPriceINR,
                IsRenewal: '1',
                RenewalType: modifiedState.renewalType,
                LoggedInUserID: modifiedState.LoggedInUserID,
                CurrentPkgStartDtTm: strRenewedPkgStartDtTm,
                RenewedPkgStartDtTm: strRenewedPkgStartDtTm,
                RenewedPkgValidTillDtTm: strRenewedPkgValidTillDtTm
            }

        } else {
            // For renewal of expired package, if user makes renewal changes with "Custom Selection", the code attaches the explicitly inputted renewal start & 
            // end dates respectively below
            if(this.state.StatusOfselectedOrdLineNo == "PkgExpired" && this.state.showCustomRenewPackageForm) {
                strRenewedPkgStartDtTm = modifiedState.renewalStartDate;
                strRenewedPkgValidTillDtTm = modifiedState.renewalEndDate;

            } else {
                renewedPkgStartDtTm = new Date(modifiedState.PkgEndDtTmOfselectedOrdLineNo.valueOf());
                renewedPkgStartDtTm.setDate(renewedPkgStartDtTm.getDate() + 1);

                let renewedPkgValidTillDtTm = new Date(renewedPkgStartDtTm.valueOf());
                renewedPkgValidTillDtTm.setDate(renewedPkgValidTillDtTm.getDate() + modifiedState.selectedDurationInDaysForApiPkg);

                strRenewedPkgStartDtTm = this.convertDateToYYYMMDDFormat(renewedPkgStartDtTm);
                strRenewedPkgValidTillDtTm = this.convertDateToYYYMMDDFormat(renewedPkgValidTillDtTm);
            }

            jsonParams = {
                UserID: modifiedState.userEmailID,
                AuthInfoId: modifiedState.authInfoIdOfSelectedOrdLineNo,
                OrderLineNumber: modifiedState.selectedOrdLineNo,
                StatusOfselectedOrdLineNo: modifiedState.StatusOfselectedOrdLineNo,
                ProductCode: modifiedState.productCodeOfSelectedOrdLineNo,
                PackageDuration: modifiedState.selectedDurationInDaysForApiPkg == "" ? "0" : modifiedState.selectedDurationInDaysForApiPkg,
                ApiHitsInPkg: modifiedState.StatusOfselectedOrdLineNo == "PkgExpired" && modifiedState.showCustomRenewPackageForm ? Math.round(modifiedState.countOfApiHits) : modifiedState.apiHitsOfselectedOrdLineNo,
                PriceOfHitsInPkgINR: modifiedState.StatusOfselectedOrdLineNo == "PkgExpired" && modifiedState.showCustomRenewPackageForm ? Math.round(modifiedState.countOfApiHits) * modifiedState.pricePerHitINR : modifiedState.apiHitsOfselectedOrdLineNo * modifiedState.pricePerHitINR,
                PriceOfDurationInPkgINR: modifiedState.selectedDurationInDaysForApiPkg * modifiedState.pricePerDayINR,
                DiscountPercentage: modifiedState.discountPercentage,
                DiscountPriceINR: modifiedState.discountPriceINR,
                TaxPerPkgINR: modifiedState.TaxPercentage,
                TaxPricePerPkgINR: modifiedState.TaxPriceINR,
                IsRenewal: '1',
                RenewalType: modifiedState.renewalType,
                LoggedInUserID: modifiedState.LoggedInUserID,
                CurrentPkgStartDtTm: modifiedState.PkgStartDtTmOfselectedOrdLineNo,
                RenewedPkgStartDtTm: strRenewedPkgStartDtTm,
                RenewedPkgValidTillDtTm: strRenewedPkgValidTillDtTm
            }
        }

        axios.post(`${getAPIHostURL()}/wclient/renewHHMApiPackage`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                // show alert message after renewing hhm api package
                if(modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWPACKAGE){
                    alert(`Succesfully Renewed Viliso API Package for "${modifiedState.selectedDurationForApiPkg}" \nfrom Start Date: ` +
                        `"${strRenewedPkgStartDtTm}" to \nEnd Date: "${strRenewedPkgValidTillDtTm}" for selected order "ApiOrder${modifiedState.UserName}${modifiedState.selectedOrdLineNo}"`);

                } else if(modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWADDON){
                        alert(`Succesfully Renewed Viliso API Add-on for "${modifiedState.showCustomAddOnForm ? modifiedState.totalAddOnApiHits : modifiedState.apiHitsOfselectedOrdLineNo} Hit(s)" for selected order "ApiOrder${modifiedState.UserName}${modifiedState.selectedOrdLineNo}"`);
                }

                modifiedState.enableHhmApiRenewalField = false;
                modifiedState.selectedOrdLineNo = "";
                modifiedState.renewalType = "";
                modifiedState.arrAllOrdLineNo = [];
                modifiedState.DurationForApiPkg = [];
                modifiedState.DurationInDaysForApiPkg = [];
                modifiedState.HitsForApiPkg = [];
                modifiedState.HitsForApiAddOn = [];
                modifiedState.selectedRenewalPeriodType = "Day";
                modifiedState.inputSubRenewalPeriod = 1;
                modifiedState.renewalSubPeriodInMonth = 1;
                modifiedState.countOfApiHits = 1;
                modifiedState.renewalStartDate = "";
                modifiedState.renewalEndDate = "";
                modifiedState.basePriceForApiPkgINR = 0;
                modifiedState.totalAddOnApiHits = 1;
                modifiedState.totalAddOnApiHitsPriceINR = "";
                modifiedState.showCustomRenewPackageForm = false;
                modifiedState.showCustomAddOnForm = false;
                modifiedState.customSelectionFlag = false;
                modifiedState.userEmailID = "";
                modifiedState.errors.others = "";
                modifiedState.errors.taxPercntErr = "";
                modifiedState.errors.apiHitsForAddOnErr = "";
                modifiedState.errors.errApiOrder = "";
                modifiedState.errors.selectedDurationForApiPkg = "";
                modifiedState.errors.email = "";
                
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);

        })
        .catch( errors => {

            if (axios.isCancel(errors)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    // Radio Button to Switch between viliso api renewPackage and viliso api renew addOn.
    // on switch we also Empty the TextBoxes and Array.
    // on selection of order line number dropdown, "Renew with Package" radio button is enabled as default
    onRadioBtnChange = (e) => {
        
        let modifiedState = this.state;

        modifiedState.renewalType = e.target.value;

        modifiedState.errors.apiHitsForAddOnErr = "";
        modifiedState.errors.selectedDurationForApiPkg = "";
        modifiedState.errors.taxPercntErr = "";

        if(modifiedState.renewalType == HHM_API_RENEWPACKAGE){
            modifiedState.apiHitsOfselectedOrdLineNo = "";
            modifiedState.totalPriceOnSelectedDurationINR = "";
            modifiedState.TaxPriceINR = "";
            modifiedState.totalPriceForApiPkgINR = "";
            modifiedState.TaxPercentage = "";
            modifiedState.discountPercentage = "0";
            modifiedState.discountPriceINR = "0.00";
            modifiedState.selectedDurationForApiPkg = "";
            modifiedState.showCustomAddOnForm = false;
            modifiedState.totalAddOnApiHits = 1;
            modifiedState.errors.others = "";
            modifiedState.errors.email = "";
            modifiedState.errors.errApiOrder = "";
            modifiedState.errors.selectedDurationForApiPkg = "";
            modifiedState.errors.renewalStartDate = "";
            modifiedState.errors.inputSubRenewalPeriod = "";
            modifiedState.errors.apiHitsForAddOnErr = "";
            modifiedState.errors.countOfApiHits = "";
            modifiedState.errors.taxPercntErr = "";
            modifiedState.errors.totalAddOnApiHits = "";
        }
        else if(modifiedState.renewalType == HHM_API_RENEWADDON){
            modifiedState.apiHitsOfselectedOrdLineNo = "";
            modifiedState.selectedDurationForApiPkg = "";
            modifiedState.selectedDurationInDaysForApiPkg = "";
            modifiedState.totalPriceOnSelectedDurationINR = "";
            modifiedState.TaxPriceINR = "";
            modifiedState.TaxPercentage = "";
            modifiedState.totalPriceForApiPkgINR = "";
            modifiedState.discountPercentage = "0";
            modifiedState.discountPriceINR = "0.00";
            modifiedState.showCustomRenewPackageForm = false;
            modifiedState.countOfApiHits = 1;
            modifiedState.inputSubRenewalPeriod = 1;
            modifiedState.selectedRenewalPeriodType = "Day";
            modifiedState.renewalStartDate = "";
            modifiedState.renewalEndDate = "";
            modifiedState.errors.others = "";
            modifiedState.errors.email = "";
            modifiedState.errors.errApiOrder = "";
            modifiedState.errors.selectedDurationForApiPkg = "";
            modifiedState.errors.renewalStartDate = "";
            modifiedState.errors.inputSubRenewalPeriod = "";
            modifiedState.errors.apiHitsForAddOnErr = "";
            modifiedState.errors.countOfApiHits = "";
            modifiedState.errors.taxPercntErr = "";
            modifiedState.errors.totalAddOnApiHits = "";
        }
        this.setState(modifiedState);  
    }

    // Below code logic sets add-on renewal api hits from dropdown
    onChangeApiAddOnDuration = (e) => {
        let modifiedState = this.state;

        modifiedState.errors.apiHitsForAddOnErr = "";
        modifiedState.errors.taxPercntErr = "";
        modifiedState.errors.selectedDurationForApiPkg = "";

        modifiedState.selectedDurationForApiPkg = e.target.value;

        for(let i = 0; i < modifiedState.HitsForApiAddOn.length; i++){

            if(modifiedState.HitsForApiAddOn[i] == modifiedState.selectedDurationForApiPkg){

                if(modifiedState.selectedDurationForApiPkg == "Custom Selection") {
                    modifiedState.showCustomAddOnForm = true;
                } else {
                    modifiedState.showCustomAddOnForm = false;
                }

                modifiedState.totalPriceOnSelectedDurationINR = modifiedState.pricePerDayINR != null && modifiedState.pricePerDayINR.length > 0 &&
                    modifiedState.pricePerHitINR != null && modifiedState.pricePerHitINR.length > 0  && modifiedState.selectedDurationForApiPkg != "Custom Selection" 
                    ?
                    (modifiedState.selectedDurationForApiPkg * modifiedState.pricePerHitINR)
                    : 0.00;

                modifiedState.discountPriceINR = modifiedState.totalPriceOnSelectedDurationINR != null && 
                    modifiedState.discountPercentage != null && modifiedState.discountPercentage.length > 0 
                    ? 
                    (modifiedState.totalPriceOnSelectedDurationINR * (Number(modifiedState.discountPercentage) / 100 )).toFixed(2) 
                    : 0.00;

                modifiedState.TaxPercentage = modifiedState.taxPercentageOnSelectedDuration;
                modifiedState.TaxPriceINR = modifiedState.totalPriceOnSelectedDurationINR != null &&
                    modifiedState.TaxPercentage != null && modifiedState.TaxPercentage.length > 0 
                    ?
                    (modifiedState.totalPriceOnSelectedDurationINR * (Number(modifiedState.TaxPercentage) / 100 )).toFixed(2) 
                    : 0.00;

                modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.totalPriceOnSelectedDurationINR) + Number(modifiedState.TaxPriceINR) - Number(modifiedState.discountPriceINR)).toFixed(2);
            } 
        }
        this.setState(modifiedState);  
    }

    // Below code logic sets package renewal duration from dropdown
    onChangeApiPackageRenewalDuration = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedDurationForApiPkg = e.target.value;

        modifiedState.errors.selectedDurationForApiPkg = "";
        modifiedState.errors.taxPercntErr = "";
        modifiedState.errors.inputSubRenewalPeriod = "";
        modifiedState.errors.countOfApiHits = "";
        modifiedState.errors.renewalStartDate = "";

        for(let i = 0; i < modifiedState.DurationForApiPkg.length; i++){

            if(modifiedState.DurationForApiPkg[i] == modifiedState.selectedDurationForApiPkg){
                modifiedState.TaxPercentage = modifiedState.taxPercentageOnSelectedDuration;

                if(modifiedState.selectedDurationForApiPkg == "Custom Selection") {
                    modifiedState.showCustomRenewPackageForm = true;
                    modifiedState.selectedDurationInDaysForApiPkg = modifiedState.inputSubRenewalPeriod; 
                    modifiedState.TaxPriceINR = "0.00";
                    
                    let endDateFieldValue = this.state.renewalStartDate != null ? this.state.renewalStartDate : null;
                    if(endDateFieldValue && modifiedState.selectedDurationInDaysForApiPkg > 0) {
                        const day = endDateFieldValue.split('-')[2];
                        const setRenewalEndDateDays = Number(day) + modifiedState.selectedDurationInDaysForApiPkg;
                        modifiedState.renewalEndDate = this.updateRenewalEndDate(endDateFieldValue, setRenewalEndDateDays);
                    }

                } else {
                    modifiedState.showCustomRenewPackageForm = false;
                    modifiedState.apiHitsOfselectedOrdLineNo = modifiedState.HitsForApiPkg[i];
                    modifiedState.selectedDurationInDaysForApiPkg = modifiedState.DurationInDaysForApiPkg[i] * 30;

                    modifiedState.totalPriceOnSelectedDurationINR = modifiedState.pricePerDayINR != null && modifiedState.pricePerDayINR.length > 0 &&
                                                                    modifiedState.pricePerHitINR != null && modifiedState.pricePerHitINR.length > 0 
                        ? (modifiedState.selectedDurationInDaysForApiPkg * modifiedState.pricePerDayINR) 
                            + (modifiedState.apiHitsOfselectedOrdLineNo * modifiedState.pricePerHitINR)
                        : 0.00;

                    modifiedState.discountPriceINR = modifiedState.totalPriceOnSelectedDurationINR != null && modifiedState.discountPercentage != null && 
                                                     modifiedState.discountPercentage.length > 0 
                        ? (modifiedState.totalPriceOnSelectedDurationINR * (Number(modifiedState.discountPercentage) / 100 )).toFixed(2) 
                        : 0.00;

                    modifiedState.TaxPriceINR = modifiedState.totalPriceOnSelectedDurationINR != null && modifiedState.TaxPercentage != null && 
                                                modifiedState.TaxPercentage.length > 0 
                        ? (modifiedState.totalPriceOnSelectedDurationINR * (Number(modifiedState.TaxPercentage) / 100 )).toFixed(2) 
                        : 0.00;

                    modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.totalPriceOnSelectedDurationINR) + Number(modifiedState.TaxPriceINR) - Number(modifiedState.discountPriceINR)).toFixed(2);
                }
            } 
        }
        this.setState(modifiedState);  
    }

    // Below function fetches the current package details
    getSelectedOrdLineNoDetails = (modifiedState) => {
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;
            
        let jsonParams = {
            UserID: modifiedState.userEmailID,
            OrderLineNumber: modifiedState.selectedOrdLineNo,
            AuthInfoId: modifiedState.authInfoIdOfSelectedOrdLineNo
        }
    
        axios.post(`${getAPIHostURL()}/wclient/getHHMApiCurrentPkgInfo`, jsonParams)
        .then(response =>{
    
            if(response.data.code == 'SUCCESS') {
              if (response.data != null && response.data.hhmApiCurrentPkgInfo.length > 0) {
    
                
                let allOrdLineNoDetailsRecieved = response.data.hhmApiCurrentPkgInfo[0];

                modifiedState.taxPercentageOnSelectedDuration = allOrdLineNoDetailsRecieved.TaxPrcntINR != null && allOrdLineNoDetailsRecieved.TaxPrcntINR.length > 0 
                    ? allOrdLineNoDetailsRecieved.TaxPrcntINR
                    : "";

                modifiedState.pricePerDayINR = allOrdLineNoDetailsRecieved.PricePerDayINR != null && allOrdLineNoDetailsRecieved.PricePerDayINR.length > 0 
                    ? allOrdLineNoDetailsRecieved.PricePerDayINR
                    : "";

                modifiedState.pricePerHitINR = allOrdLineNoDetailsRecieved.PricePerHitINR != null && allOrdLineNoDetailsRecieved.PricePerHitINR.length > 0 
                    ? allOrdLineNoDetailsRecieved.PricePerHitINR
                    : "";

                modifiedState.productCodeOfSelectedOrdLineNo = allOrdLineNoDetailsRecieved.ProductCode != null
                    ? allOrdLineNoDetailsRecieved.ProductCode
                    : ""; 

                modifiedState.PkgStartDtTmOfselectedOrdLineNo = allOrdLineNoDetailsRecieved.PkgStartDt != null && allOrdLineNoDetailsRecieved.PkgStartDt.length > 0 
                    ? allOrdLineNoDetailsRecieved.PkgStartDt
                    : "";       

                modifiedState.PkgEndDtTmOfselectedOrdLineNo = allOrdLineNoDetailsRecieved.PkgEndDt != null && allOrdLineNoDetailsRecieved.PkgEndDt.length > 0 
                    ? allOrdLineNoDetailsRecieved.PkgEndDt
                    : "";  
                    
                modifiedState.PkgStructure = allOrdLineNoDetailsRecieved.PkgStructure != null && allOrdLineNoDetailsRecieved.PkgStructure.length > 0
                    ? allOrdLineNoDetailsRecieved.PkgStructure
                    : "";

                modifiedState.AddOnStructure = allOrdLineNoDetailsRecieved.AddOnStructure != null && allOrdLineNoDetailsRecieved.AddOnStructure.length > 0
                    ? allOrdLineNoDetailsRecieved.AddOnStructure
                    : "";

                let PkgStructure = JSON.parse(modifiedState.PkgStructure);
                let AddOnStructure = JSON.parse(modifiedState.AddOnStructure);

                // logic to display duration in form of year and months
                Object.keys(PkgStructure).forEach(function(key) {
                    modifiedState.DurationInDaysForApiPkg.push(key);
                    // if number of month is less than 12, display it as it is.
                    if(key <= 12){
                        // modifiedState.DurationForApiPkg.push(key + " Month");
                        modifiedState.DurationForApiPkg.push(key <= 1 ? key + " Month" : key + " Months");
                    // if number of month is greater than 12, calculate and display it as year and month
                    } else if(key > 12){
                        let year = Math.trunc(key/12) > 0 ? Math.floor(key/12,1)+ " Year " : "";
                        let month = key%12 > 0 ? key%12+ " Month" : "";
                        modifiedState.DurationForApiPkg.push(year + "" + month);
                    }
                    modifiedState.HitsForApiPkg.push(PkgStructure[key]);
                })

                // Below code line appends an <option> element into the package renewal duration only at whose package is expired 
                modifiedState.customSelectionFlag && modifiedState.DurationForApiPkg.push("Custom Selection");
                
                for(let i = 0; i < AddOnStructure.length; i++){
                    modifiedState.HitsForApiAddOn.push(AddOnStructure[i]);
                }
                

                // Below code line appends an <option> element into the add-on renewal api hits
                modifiedState.HitsForApiAddOn.push("Custom Selection");

                let PkgStartDtTm = new Date(convertLocalDateWithFormatDDMMMYY(modifiedState.PkgStartDtTmOfselectedOrdLineNo));
                let PkgEndDtTm = new Date(convertLocalDateWithFormatDDMMMYY(modifiedState.PkgEndDtTmOfselectedOrdLineNo));

                modifiedState.PkgDurationInDaysOfselectedOrdLineNo = Math.floor(Math.abs((PkgStartDtTm - PkgEndDtTm)/ (1000*60*60*24)));
                modifiedState.PkgDurationInMonthOfselectedOrdLineNo = Math.round(modifiedState.PkgDurationInDaysOfselectedOrdLineNo/30, 1);
              }         
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING'){
                    // modifiedState.errors.others = 'Server experiencing issues (Device Params not received).\n Try again later.';
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
                } else if(response.data.code == 'SQL_ERROR') {
                    // modifiedState.errors = 'Server experiencing issues.\n Try again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    // modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
            }
            
            this.setState(modifiedState);
        })
        .catch(err => {
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled because of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
            }
            this.setState(modifiedState);
        })
    }

    onSelectOrderLineNumber = (e) => {
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;
    
        modifiedState.PkgStartDtTmOfselectedOrdLineNo = "";
        modifiedState.PkgEndDtTmOfselectedOrdLineNo = "";
        modifiedState.PkgDurationInMonthOfselectedOrdLineNo = "";
        modifiedState.PkgDurationInDaysOfselectedOrdLineNo = "";
        modifiedState.StatusOfselectedOrdLineNo = "";
        modifiedState.totalPriceOnSelectedDurationINR = "";
        modifiedState.TaxPriceINR = "";
        modifiedState.totalPriceForApiPkgINR = "";
        modifiedState.selectedDurationForApiPkg = "";
        modifiedState.apiHitsOfselectedOrdLineNo = "";
        modifiedState.selectedDurationInDaysForApiPkg = "";
        modifiedState.TaxPercentage = "";
        modifiedState.discountPercentage = "0";
        modifiedState.discountPriceINR = "0.00";
        modifiedState.errors.others = "";
        modifiedState.errors.errApiOrder = "";
        modifiedState.errors.selectedDurationForApiPkg = "";
        modifiedState.errors.apiHitsForAddOnErr = "";
        modifiedState.errors.taxPercntErr = "";
        modifiedState.renewalType = HHM_API_RENEWPACKAGE;
        modifiedState.DurationForApiPkg = [];
        modifiedState.DurationInDaysForApiPkg = [];
        modifiedState.HitsForApiPkg = [];
        modifiedState.HitsForApiAddOn = [];
        modifiedState.customSelectionFlag = false;
        modifiedState.showCustomRenewPackageForm = false;
        modifiedState.showCustomAddOnForm = false;

        let orderlineNumber = e.target.value;
        modifiedState.selectedOrdLineNo = orderlineNumber;
        
        for(let i=0; i < modifiedState.arrAllOrdLineNo.length; i++){
          if(modifiedState.arrAllOrdLineNo[i].OrderLineNumber == orderlineNumber){
            
    
            modifiedState.authInfoIdOfSelectedOrdLineNo = modifiedState.arrAllOrdLineNo[i].AuthInfoId != null && modifiedState.arrAllOrdLineNo[i].AuthInfoId.length > 0 
                ? modifiedState.arrAllOrdLineNo[i].AuthInfoId : "";

            modifiedState.StatusOfselectedOrdLineNo = modifiedState.arrAllOrdLineNo[i].CurrAPIStatus != null && modifiedState.arrAllOrdLineNo[i].CurrAPIStatus.length > 0 
                ? modifiedState.arrAllOrdLineNo[i].CurrAPIStatus : "";

            modifiedState.customSelectionFlag = modifiedState.StatusOfselectedOrdLineNo != null && modifiedState.StatusOfselectedOrdLineNo == "PkgExpired"
                ? true : false;

            modifiedState.errors.errApiOrder = modifiedState.arrAllOrdLineNo[i].Active != null && modifiedState.arrAllOrdLineNo[i].Active >= 1 
                ? "This order cannot be renewed as it is currently blocked. Please select a different order line number." 
                : "";

          }
        }
    
        this.getSelectedOrdLineNoDetails(modifiedState);
    }

    // This function fetches all the api orders against entered email ID
    getOrdLineNoWithHhmApiDetailsBasedOnUserID = () => {
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;
        
        modifiedState.errors.errApiOrder = "";
        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        
        let jsonParams = {
          userID: modifiedState.userEmailID,
          invokedFrom: "renewHhmApiPkg"
        }
    
        axios.post(`${getAPIHostURL()}/wclient/getOrdLineNoWithHhmApiDetailsBasedOnUserID`, jsonParams)
        .then(response =>{
            if(response.data.code == 'SUCCESS') {

              if (response.data != null && response.data.ordLineNoForHhmApi.length > 0) {
                let allOrdLineNoDetailsRecieved = response.data.ordLineNoForHhmApi;

                modifiedState.UserName = allOrdLineNoDetailsRecieved[0].FirstName != null && allOrdLineNoDetailsRecieved[0].FirstName.length > 0
                    ? allOrdLineNoDetailsRecieved[0].FirstName 
                    : "";

                for(let i=0; i < allOrdLineNoDetailsRecieved.length; i++) {
                  modifiedState.arrAllOrdLineNo.push(allOrdLineNoDetailsRecieved[i]);
                }
                modifiedState.enableHhmApiRenewalField = true;
                
              } else {
                  modifiedState.errors.others = "Viliso APIs Order Details Not Available for Entered EmailID. Please enter a valid EmailID.";
              }          
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
            }
            this.setState(modifiedState);
    
        })
        .catch(err => {
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled because of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
            }
            this.setState(modifiedState);
        })
      }    
      
    onClickProceed = () => {
        let modifiedState = this.state;  

        if(modifiedState.userEmailID == null || modifiedState.userEmailID.length <= 0) {
            modifiedState.errors.email = 'Please Enter Valid Email ID.';
            this.setState(modifiedState);
            return;
        } else { 
            this.getOrdLineNoWithHhmApiDetailsBasedOnUserID();
        }

    }

    handleEmailChange = (event) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;    

        modifiedState.userEmailID = event.target.value;
        if(modifiedState.userEmailID.length <= 0) {
            modifiedState.errors.others = "";
            modifiedState.errors.email = "";
            modifiedState.errors.errApiOrder = "";
            modifiedState.errors.selectedDurationForApiPkg = "";
            modifiedState.errors.apiHitsForAddOnErr = "";
            modifiedState.errors.taxPercntErr = "";
            modifiedState.enableHhmApiRenewalField = false;
            modifiedState.selectedOrdLineNo = "";
            modifiedState.PkgStartDtTmOfselectedOrdLineNo = "";
            modifiedState.PkgEndDtTmOfselectedOrdLineNo = "";
            modifiedState.PkgDurationInMonthOfselectedOrdLineNo = "";
            modifiedState.PkgDurationInDaysOfselectedOrdLineNo = "";
            modifiedState.renewalType = "";
            modifiedState.arrAllOrdLineNo = [];
            modifiedState.DurationForApiPkg = [];
            modifiedState.DurationInDaysForApiPkg = [];
            modifiedState.HitsForApiPkg = [];
            modifiedState.HitsForApiAddOn = [];
            modifiedState.errors.email = "";
            modifiedState.userEmailID = "";

            modifiedState.selectedRenewalPeriodType = "Day";
            modifiedState.inputSubRenewalPeriod = 1;
            modifiedState.renewalSubPeriodInMonth = 1;
            modifiedState.countOfApiHits = 1;
            modifiedState.renewalStartDate = "";
            modifiedState.renewalEndDate = "";
            modifiedState.basePriceForApiPkgINR = 0;
            modifiedState.totalAddOnApiHits = 1;
            modifiedState.totalAddOnApiHitsPriceINR = "";
            modifiedState.showCustomRenewPackageForm = false;
            modifiedState.showCustomAddOnForm = false;
            modifiedState.customSelectionFlag = false;
        }
             
        this.setState(modifiedState);
    }

    handleClearInput = () => {
        let modifiedState = this.state;    

        modifiedState.errors.email = "";
        modifiedState.errors.others = "";
        modifiedState.errors.errApiOrder = "";
        modifiedState.errors.selectedDurationForApiPkg = "";
        modifiedState.errors.renewalStartDate = "";
        modifiedState.errors.inputSubRenewalPeriod = "";
        modifiedState.errors.apiHitsForAddOnErr = "";
        modifiedState.errors.countOfApiHits = "";
        modifiedState.errors.taxPercntErr = "";
        modifiedState.errors.totalAddOnApiHits = "";
        modifiedState.enableHhmApiRenewalField = false;
        modifiedState.selectedOrdLineNo = "";
        modifiedState.PkgStartDtTmOfselectedOrdLineNo = "";
        modifiedState.PkgEndDtTmOfselectedOrdLineNo = "";
        modifiedState.PkgDurationInMonthOfselectedOrdLineNo = "";
        modifiedState.PkgDurationInDaysOfselectedOrdLineNo = "";
        modifiedState.renewalType = "";
        modifiedState.arrAllOrdLineNo = [];
        modifiedState.DurationForApiPkg = [];
        modifiedState.DurationInDaysForApiPkg = [];
        modifiedState.HitsForApiPkg = [];
        modifiedState.HitsForApiAddOn = [];
        modifiedState.userEmailID = "";
        modifiedState.showCustomAddOnForm = false;
        modifiedState.showCustomRenewPackageForm = false;
        modifiedState.customSelectionFlag = false;
        modifiedState.renewalStartDate = "";
        modifiedState.renewalEndDate = "";

        this.setState(modifiedState);
    };

    // Below function handles all input fields updations & calculations
    changeApiRenewalFieldValues = (event) => {
        let { name, value } = event.target;
        let modifiedState = this.state;
        let endDateFieldValue = this.state.renewalStartDate != null ? this.state.renewalStartDate : null;

        switch (name) {

            case 'inputApiRenewalPeriodValue': 
                let inputDurationFieldValue = Math.abs(value) > 0 ? Math.abs(value) : "";

                if(this.state.selectedRenewalPeriodType == "Month") {
                    modifiedState.renewalSubPeriodInMonth = inputDurationFieldValue;
                    modifiedState.inputSubRenewalPeriod = inputDurationFieldValue;
                    modifiedState.selectedDurationInDaysForApiPkg = Math.round(modifiedState.renewalSubPeriodInMonth * ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS); 
                } else if(this.state.selectedRenewalPeriodType == "Year") {
                    modifiedState.renewalSubPeriodInMonth = inputDurationFieldValue * 12;
                    modifiedState.inputSubRenewalPeriod = inputDurationFieldValue;
                    modifiedState.selectedDurationInDaysForApiPkg = Math.round(modifiedState.renewalSubPeriodInMonth * ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS);
                } else if(this.state.selectedRenewalPeriodType == "Day") {
                    modifiedState.renewalSubPeriodInMonth = "";
                    modifiedState.inputSubRenewalPeriod = inputDurationFieldValue;
                    modifiedState.selectedDurationInDaysForApiPkg = inputDurationFieldValue;
                } else {
                    modifiedState.renewalSubPeriodInMonth = "";
                    modifiedState.selectedDurationInDaysForApiPkg = ""; 
                    modifiedState.inputSubRenewalPeriod = 0;
                }

                modifiedState.errors.others= "";
                modifiedState.errors.inputSubRenewalPeriod = "";

                // Update the "Renewal End Date" Field 
                if(endDateFieldValue && (modifiedState.selectedDurationInDaysForApiPkg != null && modifiedState.selectedDurationInDaysForApiPkg > 0)) {
                    const day = this.state.renewalStartDate.split('-')[2];
                    let setRenewalEndDateDays = Number(day) + modifiedState.selectedDurationInDaysForApiPkg;
                    modifiedState.renewalEndDate = this.updateRenewalEndDate(endDateFieldValue, setRenewalEndDateDays);
                }

                // Calculate the base price for (API Renewal Package) / (API Add-On Hits) without tax or discounts
                if(this.state.renewalType == HHM_API_RENEWPACKAGE) {
                    modifiedState.basePriceForApiPkgINR = (this.state.countOfApiHits * this.state.pricePerHitINR) + (modifiedState.selectedDurationInDaysForApiPkg * this.state.pricePerDayINR);
                } else {
                    modifiedState.basePriceForApiPkgINR = (this.state.totalAddOnApiHits * this.state.pricePerHitINR);
                }

                // Calculate Total Payable Price based on base price, apply Applicable tax & discount & update the total price
                modifiedState.TaxPriceINR = (modifiedState.basePriceForApiPkgINR * this.state.TaxPercentage) / 100;
                modifiedState.discountPriceINR = (modifiedState.basePriceForApiPkgINR * this.state.discountPercentage) / 100;
                modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.basePriceForApiPkgINR) + Number(modifiedState.TaxPriceINR) - Number(modifiedState.discountPriceINR)).toFixed(2);
            break;

            case 'inputApiRenewalPeriodDuration':
                modifiedState.selectedRenewalPeriodType = value;

                if(modifiedState.selectedRenewalPeriodType == "Month") {
                    modifiedState.renewalSubPeriodInMonth = modifiedState.inputSubRenewalPeriod; 
                    modifiedState.selectedDurationInDaysForApiPkg = modifiedState.renewalSubPeriodInMonth * ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS;
                } else if(modifiedState.selectedRenewalPeriodType == "Year"){
                    modifiedState.renewalSubPeriodInMonth = modifiedState.inputSubRenewalPeriod * 12;
                    modifiedState.selectedDurationInDaysForApiPkg = modifiedState.renewalSubPeriodInMonth * ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS;
                } else if(modifiedState.selectedRenewalPeriodType == "Day"){
                    modifiedState.renewalSubPeriodInMonth = "";
                    modifiedState.selectedDurationInDaysForApiPkg = modifiedState.inputSubRenewalPeriod;
                } else {
                    modifiedState.renewalSubPeriodInMonth = ""; 
                    modifiedState.selectedDurationInDaysForApiPkg = "";
                }

                // Update the "Renewal End Date" Field 
                if(endDateFieldValue && (modifiedState.selectedDurationInDaysForApiPkg != null && modifiedState.selectedDurationInDaysForApiPkg > 0)) {
                    const day = this.state.renewalStartDate.split('-')[2];
                    let setRenewalEndDateDays = Number(day) + modifiedState.selectedDurationInDaysForApiPkg;
                    modifiedState.renewalEndDate = this.updateRenewalEndDate(endDateFieldValue, setRenewalEndDateDays);
                }

                modifiedState.basePriceForApiPkgINR = (this.state.countOfApiHits * this.state.pricePerHitINR) + (modifiedState.selectedDurationInDaysForApiPkg * this.state.pricePerDayINR);
                modifiedState.discountPriceINR = (modifiedState.basePriceForApiPkgINR * this.state.discountPercentage) / 100;
                modifiedState.TaxPriceINR = (modifiedState.basePriceForApiPkgINR * this.state.TaxPercentage) / 100;

                modifiedState.totalPriceForApiPkgINR = (Number(this.state.basePriceForApiPkgINR) + Number(modifiedState.TaxPriceINR) - Number(modifiedState.discountPriceINR)).toFixed(2);
            break;

            case 'ApiHitsFrequency':
                let totalApiHits = Math.abs(value) > 0 ? Math.abs(value) : "";
                modifiedState.countOfApiHits = totalApiHits;
                modifiedState.errors.others= "";
                modifiedState.errors.countOfApiHits = "";   
                
                // Recalculate the base price based on the new number of API hits and renewal period
                modifiedState.basePriceForApiPkgINR = (modifiedState.countOfApiHits * this.state.pricePerHitINR) + (this.state.selectedDurationInDaysForApiPkg * Number(this.state.pricePerDayINR));

                // Reapply the tax based on the updated base price
                modifiedState.TaxPriceINR = (modifiedState.basePriceForApiPkgINR * this.state.TaxPercentage) / 100;
                modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.basePriceForApiPkgINR) + Number(this.state.TaxPriceINR) - Number(this.state.discountPriceINR)).toFixed(2);
            break;

            case 'discountPercentageInput': 
                let discountValue = value >= 0 ? value : "";
                modifiedState.discountPercentage = Number(discountValue) <= 100 ? discountValue : "";

                modifiedState.errors.others= "";

                // Calculate the base price for (API Renewal Package) / (API Add-On Hits) without tax or discounts
                if(this.state.renewalType == HHM_API_RENEWPACKAGE) {
                    if(this.state.selectedDurationForApiPkg != "Custom Selection") {
                        modifiedState.basePriceForApiPkgINR = (this.state.apiHitsOfselectedOrdLineNo * this.state.pricePerHitINR) + (this.state.selectedDurationInDaysForApiPkg * this.state.pricePerDayINR);   
                    } else {
                        modifiedState.basePriceForApiPkgINR = (this.state.countOfApiHits * this.state.pricePerHitINR) + (this.state.selectedDurationInDaysForApiPkg * this.state.pricePerDayINR);
                    }
                } else {
                    modifiedState.basePriceForApiPkgINR = (this.state.totalAddOnApiHits * this.state.pricePerHitINR);
                    if(this.state.selectedDurationForApiPkg != "Custom Selection") {
                        modifiedState.basePriceForApiPkgINR = this.state.selectedDurationForApiPkg * this.state.pricePerHitINR;
                    } else {
                        modifiedState.basePriceForApiPkgINR = modifiedState.totalAddOnApiHits * this.state.pricePerHitINR;
                    }
                }

                // Calculate discount based on base price, Apply Tax & Update Total Price After Discount
                modifiedState.discountPriceINR = (modifiedState.basePriceForApiPkgINR * Number(modifiedState.discountPercentage)) / 100;
                modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.basePriceForApiPkgINR) + Number(this.state.TaxPriceINR) - Number(modifiedState.discountPriceINR)).toFixed(2); 
            break;

            case 'taxPercentageInput': 
                let appliedTaxValue = value >= 0 ? value : "";
                modifiedState.TaxPercentage = Number(appliedTaxValue) < 100 ? appliedTaxValue : "";
                modifiedState.errors.others= "";
                modifiedState.errors.taxPercntErr = "";

                // Calculate the base price for (API Renewal Package) / (API Add-On Hits) without tax or discounts
                if(modifiedState.renewalType == HHM_API_RENEWPACKAGE) {
                    if(this.state.selectedDurationForApiPkg != "Custom Selection") {
                        modifiedState.basePriceForApiPkgINR = (this.state.apiHitsOfselectedOrdLineNo * this.state.pricePerHitINR) + (this.state.selectedDurationInDaysForApiPkg * this.state.pricePerDayINR);   
                    } else {
                        modifiedState.basePriceForApiPkgINR = (modifiedState.countOfApiHits * this.state.pricePerHitINR) + (this.state.selectedDurationInDaysForApiPkg * this.state.pricePerDayINR);
                    }
                } else {
                    if(this.state.selectedDurationForApiPkg != "Custom Selection") {
                        modifiedState.basePriceForApiPkgINR = this.state.selectedDurationForApiPkg * this.state.pricePerHitINR;
                    } else {
                        modifiedState.basePriceForApiPkgINR = modifiedState.totalAddOnApiHits * Number(this.state.pricePerHitINR);
                    }
                    
                }
                
                // Always apply tax to the base price, not the modified total price
                // Calculate the total price again by adding the base price and tax
                modifiedState.TaxPriceINR = (modifiedState.basePriceForApiPkgINR * Number(modifiedState.TaxPercentage)) / 100;
                modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.basePriceForApiPkgINR) + Number(modifiedState.TaxPriceINR) - Number(this.state.discountPriceINR)).toFixed(2);
            break;

            case 'addOnApiHits':
                let addOnHits = Math.abs(value) > 0 ? Math.abs(value) : "";
                modifiedState.totalAddOnApiHits = Math.round(addOnHits);
                modifiedState.errors.others= "";

                modifiedState.totalAddOnApiHitsPriceINR = modifiedState.totalAddOnApiHits * Number(this.state.pricePerHitINR);
                // modifiedState.basePriceForApiPkgINR = modifiedState.totalAddOnApiHits * this.state.pricePerHitINR;
                
                // Always apply tax to the base price, not the modified total price
                modifiedState.TaxPriceINR = ((modifiedState.totalAddOnApiHitsPriceINR * Number(this.state.TaxPercentage)) / 100).toFixed(2);
                modifiedState.discountPriceINR = ((modifiedState.totalAddOnApiHitsPriceINR * Number(this.state.discountPercentage)) / 100).toFixed(2);

                // Calculate the total price again by adding the base price and tax
                modifiedState.totalPriceForApiPkgINR = (Number(modifiedState.totalAddOnApiHitsPriceINR) + Number(modifiedState.TaxPriceINR) - Number(modifiedState.discountPriceINR)).toFixed(2);
            break;

            default:
            break;
        }

        this.setState(modifiedState)
    }

    // This function calculates the renewal end date on the basis of field inputs & renewal start date
    updateRenewalEndDate = (baseDate, newDay) => {
        
        
        if(baseDate == null) {
            return; // No processing required
        }

        // Create a new Date object from the base date string
        const date = new Date(baseDate);
      
        const addOnDays = newDay - 1;

        // Replace the day part of the date with the provided new day
        date.setDate(addOnDays);

        return this.convertDateToYYYMMDDFormat(date);
    }

    convertDateToYYYMMDDFormat = (inputDate) => {

        // Format the new inputDate in 'YYYY-MM-DD' format
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns month index (0-11), so add 1
        const day = inputDate.getDate().toString().padStart(2, '0'); // Ensure day is 2 digits

        // Return the formatted date string
        return `${year}-${month}-${day}`;
    }

    // This function below validates if the current inputted renewal start date is valid or not
    verifyRenewalDuration = (pkgDate, inputStartDate) => {
        
        let newPkgEndDate = pkgDate.split(" ")[0]; // This will be "2025-03-11"

        let convertedPkgEndDateObject = new Date(newPkgEndDate);
        let convertedrenewalPkgStartDateObject = inputStartDate && new Date(inputStartDate);
        let currentDateObject = new Date();

        // Move to one day after current package end date as user shouldn't renew package on & from last day of current pkg end date
        convertedPkgEndDateObject.setDate(convertedPkgEndDateObject.getDate() + 1); 

        if(convertedrenewalPkgStartDateObject < convertedPkgEndDateObject) {
            alert(`The Renewal Start Date must be later than the Current Package End Date ${newPkgEndDate}`)
        } else if(convertedrenewalPkgStartDateObject > currentDateObject) {
            alert(`The Renewal Start Date must be Today's Date or previous of Today's Date.`)
        } else {
            return {convertedPkgEndDateObject, convertedrenewalPkgStartDateObject};
        }
    }
    
    changeRenewalStartDate = (date, dateString) => {
        
        let modifiedState = this.state;
        
        if(date == null || dateString != null && dateString.length <= 0) {
            modifiedState.renewalStartDate = "";
            modifiedState.renewalEndDate = "";
            modifiedState.errors.renewalStartDate = "";
            this.setState(modifiedState);

            return; // No further processing required
        }
        
        let pkgEndDateAndRenewalStartDate;
        pkgEndDateAndRenewalStartDate = this.verifyRenewalDuration(this.state.PkgEndDtTmOfselectedOrdLineNo, dateString);

        if(pkgEndDateAndRenewalStartDate?.convertedPkgEndDateObject != null && pkgEndDateAndRenewalStartDate?.convertedrenewalPkgStartDateObject != null) {
            // convert the renewal input date to "yyyy-mm-dd" format
            let formattedRenewalStartDate = `${pkgEndDateAndRenewalStartDate.convertedrenewalPkgStartDateObject.getFullYear()}-${String(pkgEndDateAndRenewalStartDate.convertedrenewalPkgStartDateObject.getMonth() + 1).padStart(2, '0')}-${String(pkgEndDateAndRenewalStartDate.convertedrenewalPkgStartDateObject.getDate()).padStart(2, '0')}`;
            modifiedState.renewalStartDate = formattedRenewalStartDate;   
            modifiedState.errors.renewalStartDate = "";

            if(date) {
                let totalDaysFromRenewalStartDate = date != null && (date?.$D + this.state.selectedDurationInDaysForApiPkg);
                if(totalDaysFromRenewalStartDate != null && totalDaysFromRenewalStartDate > 0) {
                    modifiedState.renewalEndDate = this.updateRenewalEndDate(modifiedState.renewalStartDate, totalDaysFromRenewalStartDate);
                }
            }
        } else {
            // Case: when user selected a valid renewal start date in first iteration & selects a deprecated date in second iteration
            if((this.state.renewalStartDate != null && this.state.renewalStartDate.length > 0) || (this.state.renewalEndDate != null && this.state.renewalEndDate.length > 0)) {
                modifiedState.renewalStartDate = "";
                modifiedState.renewalEndDate = "";
            }
        }

        modifiedState.errors.renewalStartDate = modifiedState.renewalType != null && modifiedState.renewalType.length > 0 && modifiedState.renewalType == HHM_API_RENEWPACKAGE && 
                this.state.StatusOfselectedOrdLineNo == "PkgExpired" && this.state.showCustomRenewPackageForm && (modifiedState.renewalStartDate == null || modifiedState.renewalStartDate.length <= 0)
            ? "Please select valid Viliso API package renewal date"
            : "";

        this.setState(modifiedState);
    };
    
    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        const color = {
            color : "var(--errorColor)",
            fontSize:"13px"
        }

        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-lg-offset-2 col-md-12" style={{ width: "70%" }}>
                        <div className="modal-body box" style={{ borderRadius: "0.6rem" }}>
                            <div className = "headingForComponentsOfCrmPage" style={{ marginTop: "0.6rem", marginBottom: "0.6rem" }}>
                                <span style={{ marginTop: "0.5rem" }}>Renew Viliso API Package</span>
                            </div> 
                            <form onSubmit={this.onFormSubmit}>
                                <div>
                                    <label className="reg-form-label" 
                                        style={{width:"94%"}}
                                    >
                                        Enter EmailID of User:
                                    </label>

                                    <div className='d-flex justify-content-center align-items-center'>
                                        <input style={{width:"95%", paddingLeft: "0.4rem"}}
                                            className="input-form" type='email'
                                            value= {this.state.userEmailID}
                                            onChange={this.handleEmailChange}
                                            required
                                        />
                                        {/* Clear (x): Empty Search Button */}
                                        {this.state.userEmailID != null && this.state.userEmailID.length > 0 && 
                                            <span style={{ cursor: "pointer", marginLeft: "-1.5rem", paddingRight: "0.5rem" }} onClick={this.handleClearInput}>
                                                <MdCancel />
                                            </span> 
                                        }
                                    </div>
                                    {this.state.errors.email.length > 0 && 
                                        <span style={color} className='error'>{this.state.errors.email}</span>} 
                                    {this.state.enableHhmApiRenewalField == false &&
                                        <div style={{display:"flex", justifyContent: "center", flexDirection:"column"}}>
                                            <button type={"button"}
                                                    style={{borderRadius: "5px", 
                                                            width:'40%', 
                                                            marginLeft:'30%',
                                                            marginTop:"1.6rem",
                                                            backgroundColor: "var(--primaryColor)", color:"white",
                                                            marginBottom: "1rem",
                                                            marginTop: "1.7rem"
                                                        }}
                                                    className="btn-lg"
                                                    onClick={this.onClickProceed} 
                                            >
                                                Proceed
                                            </button>
                                            {this.state.errors.others.length > 0 && 
                                                <span style={{color:"Red", fontSize:"0.9rem", textAlign:"center", marginTop: "0.3rem"}} className='error'>{this.state.errors.others}</span>} 
                                        </div>
                                    }                          
                                </div>
                                {(this.state.enableHhmApiRenewalField == true) && 
                                    <div>
                                        <label className="reg-form-label" style={{width:"94%", marginTop: "1rem"}}>Select Order To Renew:</label>
                                        <div>
                                            <select className="input-form"
                                                style={{width:"95%", marginBottom: "1rem", paddingLeft: "0.4rem"}}
                                                required
                                                placeholder='Select Order Line Number...'
                                                value={this.state.selectedOrdLineNo}
                                                onChange={this.onSelectOrderLineNumber}
                                            >
                                                <option value="" selected disabled>Select Order Line Number...</option> 
                                                {(this.state.arrAllOrdLineNo.map((singleOrdLineNo, index) => 
                                                    <option key={index} value={singleOrdLineNo.OrderLineNumber}>{"ApiOrder" + this.state.UserName + singleOrdLineNo.OrderLineNumber}</option>
                                                ))} 
                                            </select>
                                        <br/>
                                        </div>

                                        {this.state.errors.errApiOrder.length > 0 && 
                                            <span className='validationErrorLabel'>{this.state.errors.errApiOrder}</span>
                                        } 
                                    </div>
                                }

                                {(this.state.enableHhmApiRenewalField == true) && this.state.selectedOrdLineNo != null && this.state.selectedOrdLineNo.length > 0 && this.state.errors.errApiOrder != null && this.state.errors.errApiOrder.length <= 0 &&
                                    <div>
                                        <div className='d-flex justify-content-center align-items-center' style={{ margin: "0px 25px" }}>
                                            <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                                <div style={{ marginBottom: "0.7rem" }}>
                                                    <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem"}}>
                                                    Current Viliso API Package Details
                                                    </label>
                                                    <div>
                                                        <div className = "startAndEnddate" style={{marginTop: "0.5rem"}}>
                                                            <div style={{flex: "1"}}>
                                                                <label className="reg-form-label" style={{width:"100%"}}>
                                                                Current Viliso API Package Duration In Months:
                                                                </label>
                                                                <div style={{display: "flex"}}>
                                                                    <input style={{fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", paddingLeft: "0.4rem"}}
                                                                        className="input-form"
                                                                        value= {this.state.PkgDurationInMonthOfselectedOrdLineNo + " Month"}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div style={{paddingLeft: "0.8rem", paddingRight: "0.8rem", paddingTop: "0.3rem", alignItems: "center"}}>
                                                            <br/>
                                                                =
                                                            </div>
                                                            <div style={{flex: "1"}}>
                                                                <label className="reg-form-label" style={{width:"100%"}}>
                                                                Current Viliso API Package Duration In Days:
                                                                </label>
                                                                <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", width:"100%", paddingLeft: "0.4rem"}}
                                                                        className="input-form"
                                                                        value= {this.state.PkgDurationInDaysOfselectedOrdLineNo + " Days"}
                                                                        readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className = "startAndEnddate" style = {{ marginTop: "0.5rem"}}>
                                                            <div style={{flex: "1", paddingRight:"1rem"}}>
                                                                <label className="reg-form-label" style={{width:"100%"}}>
                                                                Current Viliso API Package Start Date Time:
                                                                </label>
                                                                <input style={{color: "#505050", background:"#F5F5F5", paddingLeft: "0.4rem"}}
                                                                        className="input-form"
                                                                        value= {this.state.PkgStartDtTmOfselectedOrdLineNo}
                                                                        readOnly
                                                                />
                                                            </div>

                                                            <div style={{flex: "1", paddingLeft:"1rem"}}>
                                                                <label className="reg-form-label" style={{width:"100%"}}>
                                                                Current Viliso API Package End Date Time:
                                                                </label>
                                                                <input style={{color: "#505050", background:"#F5F5F5", paddingLeft: "0.4rem"}}
                                                                        className="input-form"
                                                                        value= {this.state.PkgEndDtTmOfselectedOrdLineNo}
                                                                        readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.selectedOrdLineNo != null && this.state.selectedOrdLineNo.length > 0 && this.state.errors.errApiOrder != null && this.state.errors.errApiOrder.length <= 0 &&
                                            <div style={{ margin:"1.5rem 1.5rem 0rem 1.6rem", paddingBottom: "1rem" }}>
                                                <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", background:"white"}}>
                                                    <div>
                                                        <label style={{ display: "flex", justifyContent:"flex-end", fontSize:"0.7rem"}}><b style={{marginRight:"0.1rem"}}>Note: </b> Fields marked in <span style={{color:"var(--errorColor)", fontSize:"1rem", marginLeft:"0.1rem", marginRight:"0.1rem"}}>* </span> are compulsory.</label>
                                                    </div>
                                                    <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem"}}>
                                                        Select Viliso APIs Renewal Type
                                                    </label>
                                                    <div className="trackRadioDiv" style={{ marginTop: "0.5rem" }}>
                                                        <div className = "trackradioBox">
                                                            <div className="d-flex align-items-center">
                                                                <input type='radio' name='trackBy'  
                                                                    id = "trackBySingleDeviceID" 
                                                                    value= {HHM_API_RENEWADDON}
                                                                    onChange={this.onRadioBtnChange} noValidate 
                                                                    checked={this.state.renewalType == HHM_API_RENEWADDON ? true : false}
                                                                />
                                                                <span style={{marginLeft: "0.3rem"}}> Renew with Add-On Hits</span>
                                                            </div>

                                                            <div className="d-flex align-items-center">
                                                                <input type='radio' name='trackBy'  
                                                                    id = "trackBySingleDeviceID" 
                                                                    value= {HHM_API_RENEWPACKAGE}
                                                                    onChange={this.onRadioBtnChange} noValidate
                                                                    checked={this.state.renewalType == HHM_API_RENEWPACKAGE ? true : false}
                                                                    style={{marginLeft: "1rem"}}
                                                                />
                                                                <span style={{marginLeft: "0.3rem"}}> Renew with Package</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    {this.state.renewalType != null && this.state.renewalType.length > 0 && 
                                                        <div>
                                                            <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                                                <div>
                                                                    <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem"}}>
                                                                        {this.state.renewalType != null && this.state.renewalType.length > 0 && this.state.renewalType == HHM_API_RENEWPACKAGE ? "Viliso API Package Renewal Details" : "Viliso API Add-On Hits Renewal Details"}
                                                                    </label>
                                                                    
                                                                    {this.state.renewalType != null && this.state.renewalType.length > 0 && this.state.renewalType == HHM_API_RENEWPACKAGE &&
                                                                        <div>
                                                                            <div className = "startAndEnddate" style={{ marginTop: "0.5rem"}}>
                                                                                <div style={this.state.showCustomRenewPackageForm ? { width: "100%" } : { width: "46.5%" }}>
                                                                                    <label className="reg-form-label">
                                                                                    Select Viliso API Package Renewal Duration:
                                                                                        <span className="addCustRequiredMarkStar">*</span>
                                                                                    </label>
                                                                                    <div>
                                                                                        <select  className="input-form" 
                                                                                                    style={{height:"2.5rem", marginBottom: "0.1rem", paddingLeft: "0.1rem"}}
                                                                                                    value={this.state.selectedDurationForApiPkg}
                                                                                                    onChange={this.onChangeApiPackageRenewalDuration}
                                                                                        >
                                                                                            <option value="" disabled selected>Select Renewal Duration...</option>
                                                                                            {(this.state.DurationForApiPkg).map((singleDuration,index) => <option key={index} 
                                                                                                value={singleDuration}
                                                                                            > {singleDuration}</option>)}
                                                                                        </select>
                                                                                        {this.state.errors.selectedDurationForApiPkg.length > 0 && 
                                                                                        <span  className='validationErrorLabel'>{this.state.errors.selectedDurationForApiPkg}</span>} 
                                                                                    </div>
                                                                                </div>

                                                                                {!this.state.showCustomRenewPackageForm && 
                                                                                    <div style={{paddingTop: "0.8rem", paddingLeft: "1rem", paddingRight: "1.1rem"}}>
                                                                                        <br/> =
                                                                                    </div>
                                                                                }

                                                                                {!this.state.showCustomRenewPackageForm && 
                                                                                    <div style={{ width: "50%" }}>
                                                                                        <label className="reg-form-label">
                                                                                        Viliso API Package Renewal Duration In Days:
                                                                                            <span className="addCustRequiredMarkStar"></span>

                                                                                        </label>
                                                                                        <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", paddingLeft: "0.1rem"}}
                                                                                                className="input-form"
                                                                                                value={`${this.state.selectedDurationInDaysForApiPkg} ${this.state.selectedDurationInDaysForApiPkg === 1 ? 'Day' : 'Days'}`}
                                                                                                readOnly
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            {this.state.customSelectionFlag && this.state.showCustomRenewPackageForm && 
                                                                                <div className='d-flex align-items-center justify-content-between' style={{ marginTop: "0.5rem" }}>
                                                                                    <div style={{ width: "46.5%" }}>
                                                                                        <label className="reg-form-label" style={{ marginLeft: "0.1rem" }}>Enter Viliso API Package Renewal Period:
                                                                                            <span className="addCustRequiredMarkStar">*</span>
                                                                                        </label>
                                                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                                                            <input style={{ fontSize:"1rem", borderRadius:"0.3rem", paddingLeft: "0.4rem", width: "90%"}}
                                                                                                className="input-form"
                                                                                                name="inputApiRenewalPeriodValue"
                                                                                                type="number"
                                                                                                min="1"
                                                                                                value={this.state.inputSubRenewalPeriod}
                                                                                                onChange= {this.changeApiRenewalFieldValues}
                                                                                            />

                                                                                            <select className="input-form" style={{ marginLeft: "1.5rem", paddingLeft: "0.4rem" }}
                                                                                                value={this.state.selectedRenewalPeriodType}
                                                                                                name="inputApiRenewalPeriodDuration"
                                                                                                onChange={this.changeApiRenewalFieldValues}
                                                                                            >
                                                                                                <option value="Month">Month</option>
                                                                                                <option value="Year">Year</option>  
                                                                                                <option value="Day">Day</option>                                                                    
                                                                                            </select>
                                                                                        </div>

                                                                                        {this.state.errors.inputSubRenewalPeriod.length > 0 &&
                                                                                            <span className='validationErrorLabel'>{this.state.errors.inputSubRenewalPeriod}</span>
                                                                                        }
                                                                                    </div>

                                                                                    <div style={{paddingTop: "1.6rem"}}>
                                                                                        =
                                                                                    </div>
                                                                                    
                                                                                    <div style={{ width: "47.5%" }}>
                                                                                        <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>
                                                                                        Viliso API Package Renewal Duration In Days:
                                                                                            <span className="addCustRequiredMarkStar"></span>

                                                                                        </label>
                                                                                        <input
                                                                                            style={{fontSize: "1rem", borderRadius: "0.3rem", color: "#505050", background: "#F5F5F5", marginRight: "2rem", paddingLeft: "0.4rem"}}
                                                                                            className="input-form"
                                                                                            value={`${this.state.selectedDurationInDaysForApiPkg} ${this.state.selectedDurationInDaysForApiPkg === 1 ? 'Day' : 'Days'}`}
                                                                                            readOnly
                                                                                        />

                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            {this.state.showCustomRenewPackageForm 
                                                                                ? <div className='d-flex align-items-center justify-content-between' style={{ marginTop: "0.5rem" }}>
                                                                                    <div style={{ width: "46.5%" }}>
                                                                                        <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>Enter API Hit Count:
                                                                                            <span className="addCustRequiredMarkStar">*</span>
                                                                                        </label>
                                                                                        <div>
                                                                                            <input style={{ fontSize:"1rem", borderRadius:"0.3rem", paddingLeft: "0.4rem"}}
                                                                                                className="input-form"
                                                                                                name="ApiHitsFrequency"
                                                                                                type="number"
                                                                                                min="1"
                                                                                                value= {this.state.countOfApiHits}
                                                                                                onChange= {this.changeApiRenewalFieldValues}
                                                                                            />
                                                                                        </div>

                                                                                        {this.state.errors.countOfApiHits.length > 0 && 
                                                                                            <span className='validationErrorLabel'>{this.state.errors.countOfApiHits}</span>
                                                                                        }
                                                                                    </div>

                                                                                    <div style={{paddingTop: "1.6rem"}}>
                                                                                        =
                                                                                    </div>

                                                                                    <div style={{ width: "47.5%" }}>
                                                                                        <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>
                                                                                        Total Hit Count for <b>{this.state.inputSubRenewalPeriod} {this.state.selectedRenewalPeriodType == "Day" && this.state.inputSubRenewalPeriod > 1 ? " Days" :
                                                                                                                                                    this.state.selectedRenewalPeriodType == "Month" && this.state.inputSubRenewalPeriod > 1 ? " Months" :
                                                                                                                                                    this.state.selectedRenewalPeriodType == "Year" && this.state.inputSubRenewalPeriod > 1 ? " Years" : this.state.selectedRenewalPeriodType} 
                                                                                                                                                    {this.state.selectedRenewalPeriodType != "Day" && 
                                                                                                                                                        ` (${this.state.selectedDurationInDaysForApiPkg}${this.state.selectedDurationInDaysForApiPkg > 1 ? " Days" : " Day"})`}</b>:
                                                                                            <span className="addCustRequiredMarkStar"></span>
                                                                                        </label>

                                                                                        <input
                                                                                            style={{fontSize: "1rem", borderRadius: "0.3rem", color: "#505050", background: "#F5F5F5", marginRight: "2rem", paddingLeft: "0.4rem"}}
                                                                                            className="input-form"
                                                                                            value={`${Math.round(this.state.countOfApiHits)}${Math.round(this.state.countOfApiHits) > 1 ? " Hits" : " Hit"}`}
                                                                                            readOnly
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                : 
                                                                                <div className='d-flex justify-content-center mt-1'>                                                                   
                                                                                    <div style={{ width: "50%" }}>
                                                                                        <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>
                                                                                        Total Hit Count for <b>{this.state.selectedDurationForApiPkg} {`(${this.state.selectedDurationInDaysForApiPkg} Days)`}</b>:
                                                                                            <span className="addCustRequiredMarkStar"></span>
                                                                                        </label>

                                                                                        <input
                                                                                            style={{fontSize: "1rem", borderRadius: "0.3rem", color: "#505050", background: "#F5F5F5", marginRight: "2rem", paddingLeft: "0.4rem"}}
                                                                                            className="input-form"
                                                                                            value={`${Math.round(this.state.apiHitsOfselectedOrdLineNo)}${Math.round(this.state.apiHitsOfselectedOrdLineNo) > 1 ? " Hits" : " Hit"}`}
                                                                                            readOnly
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            {this.state.customSelectionFlag && this.state.showCustomRenewPackageForm &&
                                                                                <div className='d-flex justify-content-between align-items-center my-3'>
                                                                                    <div style={{ paddingTop: "1.6rem" }}>
                                                                                        <b style={{ color: "#8d8d8d" }}>From:</b>
                                                                                    </div>

                                                                                    <div style={{ width: "40%" }}>
                                                                                        <label className="reg-form-label" style={{marginLeft: "0.1rem"}}>Enter Renewal Start Date:
                                                                                            <span className="addCustRequiredMarkStar">*</span>
                                                                                        </label>

                                                                                        <DatePicker 
                                                                                            className='input-form'
                                                                                            style={{ width: "96%", height: "2.6rem", border: "1px solid #ababab", marginBottom: "0.2rem", borderRadius: "0.3rem" }} 
                                                                                            onChange={this.changeRenewalStartDate}
                                                                                        />

                                                                                        {this.state.errors.renewalStartDate.length > 0 && 
                                                                                            <span className='validationErrorLabel'>{this.state.errors.renewalStartDate}</span>}
                                                                                    </div>

                                                                                    <div style={{ paddingTop: "1.6rem" }}>
                                                                                        <b style={{ color: "#8d8d8d" }}>To:</b>
                                                                                    </div>

                                                                                    <div style={{ width: "40%" }}>
                                                                                        <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>API Renewal End Date:
                                                                                            <span className="addCustRequiredMarkStar"></span>
                                                                                        </label>

                                                                                        <input
                                                                                            style={{fontSize: "1rem", borderRadius: "0.3rem", color: "#505050", background: "#F5F5F5", marginRight: "2rem", paddingLeft: "0.4rem", paddingLeft: "0.7rem", fontSize: "0.93rem", letterSpacing: "0.4px"}}
                                                                                            className="input-form"
                                                                                            value={this.state.renewalEndDate}
                                                                                            readOnly
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    
                                                                    {this.state.renewalType != null && this.state.renewalType.length > 0 && this.state.renewalType == HHM_API_RENEWADDON && 
                                                                        (this.state.StatusOfselectedOrdLineNo == "PkgExpired" ? (
                                                                            <div className='validationErrorLabel' style={{ textAlign: "center", marginTop: "0.5rem", marginBottom: "0.3rem", fontWeight: "bold" }}>
                                                                                Since the current package is expired, it must be renewed before additional API hits can be added accordingly.
                                                                            </div> 
                                                                            ) : (
                                                                                <>
                                                                                    <div>
                                                                                        <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                                                            <div style={this.state.showCustomAddOnForm ? {width: "100%"} : {flex:"1", paddingRight:"1rem"}}>
                                                                                                <label className="reg-form-label" style={{marginLeft: "0.1rem"}}>
                                                                                                Select API hits for Add-On:
                                                                                                    <span className="addCustRequiredMarkStar">*</span>
                                                                                                </label>
                                                                                                <div>
                                                                                                    <select className="input-form" 
                                                                                                                style={{height:"2.5rem", marginBottom: "0.1rem"}}
                                                                                                                value={this.state.selectedDurationForApiPkg}
                                                                                                                onChange={this.onChangeApiAddOnDuration}
                                                                                                    >
                                                                                                        <option value="" disabled selected>Select Add-On Hits...</option>
                                                                                                        {(this.state.HitsForApiAddOn).map((singleDuration,index) => <option key={index} value={singleDuration}>{singleDuration}</option>)}
                                                                                                    </select>

                                                                                                    {this.state.errors.selectedDurationForApiPkg.length > 0 && 
                                                                                                    <span className='validationErrorLabel'>{this.state.errors.selectedDurationForApiPkg}</span>}
                                                                                                </div>
                                                                                            </div>

                                                                                            {!this.state.showCustomAddOnForm &&
                                                                                                <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                                                                    <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>
                                                                                                        Price of Api-Hits for Add-On (INR):<span className="addCustRequiredMarkStar"></span>
                                                                                                    </label>
                                                                                                
                                                                                                    <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", paddingLeft: "0.4rem"}}
                                                                                                        className="input-form"
                                                                                                        type= "number"
                                                                                                        value= {this.state.totalPriceOnSelectedDurationINR}
                                                                                                        readOnly
                                                                                                    />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    {this.state.showCustomAddOnForm &&
                                                                                        <div className='d-flex justify-content-between mt-2 mb-3'>
                                                                                            <div style={{ width: "48%" }}>
                                                                                                <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>
                                                                                                    Add Custom Add-On API Hits:
                                                                                                    <span className="addCustRequiredMarkStar">*</span>
                                                                                                </label>
                            
                                                                                                <input style={{ width: "100%", fontSize:"1rem", borderRadius:"0.3rem"}}
                                                                                                    className="input-form"
                                                                                                    name="addOnApiHits"
                                                                                                    type="number"
                                                                                                    min="1"
                                                                                                    value={this.state.totalAddOnApiHits}
                                                                                                    onChange= {this.changeApiRenewalFieldValues}
                                                                                                />
                            
                                                                                                {this.state.errors.totalAddOnApiHits.length > 0 && 
                                                                                                    <span className='validationErrorLabel'>{this.state.errors.totalAddOnApiHits }</span>
                                                                                                }
                                                                                            </div>
                            
                                                                                            <div style={{ width: "48%" }}>
                                                                                                <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>
                                                                                                    Price of Api-Hits for Add-On (INR):<span className="addCustRequiredMarkStar"></span>
                                                                                                </label>
                                                                                            
                                                                                                <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", paddingLeft: "0.4rem"}}
                                                                                                    className="input-form"
                                                                                                    type= "number"
                                                                                                    value={this.state.totalAddOnApiHitsPriceINR}
                                                                                                    readOnly
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </> 
                                                                            )
                                                                        )
                                                                    }
                                                                </div>    

                                                                {this.state.renewalType != null && this.state.renewalType.length > 0 && this.state.renewalType == HHM_API_RENEWADDON && this.state.StatusOfselectedOrdLineNo == "PkgExpired" ?
                                                                    null :
                                                                    <>
                                                                        <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                                            <div style={{flex:"1", paddingRight:"1rem"}}>
                                                                                <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>
                                                                                Enter Discount %:
                                                                                </label>
                                                                            
                                                                                <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", paddingLeft: "0.4rem"}}
                                                                                    className="input-form"
                                                                                    type= "number"
                                                                                    step="any"
                                                                                    name="discountPercentageInput"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value= {this.state.discountPercentage}
                                                                                    onChange={this.changeApiRenewalFieldValues}
                                                                                />  
                                                                            </div>

                                                                            <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                                                <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>
                                                                                    Discount Amount (INR):
                                                                                </label>
                                                                            
                                                                                <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", paddingLeft: "0.4rem"}}
                                                                                    className="input-form"
                                                                                    type= "number"
                                                                                    value= {this.state.discountPriceINR}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                                            <div style={{flex:"1", paddingRight:"1rem"}}>
                                                                                <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>
                                                                                Enter Tax %:<span className="addCustRequiredMarkStar">*</span>
                                                                                </label>
                                                                            
                                                                                <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", paddingLeft: "0.4rem"}}
                                                                                    className="input-form"
                                                                                    type= "number"
                                                                                    step="any"
                                                                                    name="taxPercentageInput"
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value= {this.state.TaxPercentage}
                                                                                    onChange={this.changeApiRenewalFieldValues}
                                                                                />  
                                                                                
                                                                                {this.state.errors.taxPercntErr.length > 0 && 
                                                                                    <span className='validationErrorLabel'>{this.state.errors.taxPercntErr}</span>
                                                                                }
                                                                            </div>

                                                                            <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                                                <label className="reg-form-label" style={{width:"100%", marginLeft: "0.1rem"}}>
                                                                                    <span className="addCustRequiredMarkStar"></span>
                                                                                    Applicable Tax After Discount (INR):
                                                                                </label>
                                                                            
                                                                                <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", paddingLeft: "0.4rem"}}
                                                                                    className="input-form"
                                                                                    type= "number"
                                                                                    value= {this.state.TaxPriceINR}
                                                                                    readOnly
                                                                                    />
                                                                            </div>
                                                                        </div>
                                                                                    
                                                                        <div className = "startAndEnddate" style={{display:"flex", justifyContent:"center", width:"100%",marginTop:"1rem", marginBottom: "0.5rem"}}>
                                                                            <div style={{width:"48%"}}>
                                                                                <label className="reg-form-label" style={{width:"100%",textAlign:"center", marginLeft: "0.1rem"}}>
                                                                                {this.state.renewalType != null && this.state.renewalType.length > 0 && 
                                                                                    this.state.renewalType == HHM_API_RENEWADDON ?  "Total Payable Amount for Add-Ons Hits" : "Total Payable Amount for Package Renewal"}                                                        
                                                                                </label>
                                                                            
                                                                                <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", fontWeight:"bolder", paddingLeft: "0.4rem"}}
                                                                                    className="input-form"
                                                                                    value={this.state.totalPriceForApiPkgINR > 0 ? `₹ ${this.state.totalPriceForApiPkgINR}` : ""}
                                                                                    readOnly
                                                                                /> 
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div> 

                                                            {this.state.renewalType != null && this.state.renewalType.length > 0 && this.state.renewalType == HHM_API_RENEWADDON && this.state.StatusOfselectedOrdLineNo == "PkgExpired" ? 
                                                                null :
                                                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                                    <button style={{borderRadius: "5px", width:"30%", marginTop:"1rem", marginBottom: "0.4rem",
                                                                                    backgroundColor: "var(--primaryColor)", color:"white"
                                                                                }} className="btn-lg"
                                                                    >
                                                                    Save
                                                                    </button>
                                                                    {this.state.errors.others.length > 0 && 
                                                                        <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>} 
                                                                </div> 
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}
VcRenewVilisoApiPkg.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcRenewVilisoApiPkg
