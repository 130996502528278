import React, { useContext } from 'react'
import { IDS_Good, IDS_Satisfactory, IDS_Moderate, IDS_Poor, IDS_Severe, IDS_PoorAqi, IDS_VeryPoorAqi, IDS_SevereAqi, IDS_vilisoAqiMsg, IDS_airQualityIndex, IDS_ProminentPollutant, } from '../../../VcLanguage';
import { FiInfo } from 'react-icons/fi';
import { AQI, CAQI } from '../../../VcConstants';
import { AppRelevantDataContext } from '../../../AppContext';

const VcAQIBox = ({SingleDeviceParamSequence,getParamValueTextAndBoxStyleBasedOnRange,SingleDeviceParamValue, renderParamNameBasedOnType, getUnitsBasedOnParamtype }) => {
    let context = useContext(AppRelevantDataContext)
    let t = context.t;
    return (
    <div className='flex flex-col gap-3 py-2 pt-3 px-3 rounded bg-white w-100'>
        {
            <div className=' w-100 flex gap-4 flex-col flex-md-row' >
            {SingleDeviceParamSequence.map( (singleParam) => {
                if(singleParam == AQI || singleParam == CAQI){
                    let singleValue = SingleDeviceParamValue[singleParam];
                    let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(singleValue, singleParam);
                
                    return(
                        <div style={{background:"white"}} className={` ${styleValueBox} rounded flex w-100 w-md-50 flex-row`}>
                            <div className='flex text-start align-middle flex-col align-items-flex-start ps-1 py-1 justify-around ' style={{width:"75%"}}>
                                <span className='flex flex-wrap align-items-baseline'>
                                    {renderParamNameBasedOnType(singleParam)}  
                                    {singleParam == CAQI && 
                                    <span id="toggleSpan" className="tooltipForRangeIcon px-1">
                                        <>
                                            <FiInfo className="rangeIcon" style={{fontSize: ".8rem", fontWeight: "bold"}}/>
                                            <span className="tooltiptextForRangeIcon px-1" >{t(IDS_vilisoAqiMsg, t(IDS_airQualityIndex))}</span>
                                        </>
                                    </span>
                                }
                                    
                                </span>
                                <small className='flex gap-1 ' style={{fontSize:'0.8em'}}> 
                                    <span>
                                        {t(IDS_ProminentPollutant)}:
                                    </span>
                                    <strong>
                                        {getUnitsBasedOnParamtype(singleParam, singleValue)}
                                    </strong> 
                                </small>    
                            </div>
                            <div className={styleValueText + ' flex-center'} style={{width:"25%" }}> {singleValue == null ? "--" : singleValue} </div> 
                        </div>
                    ) 
                }
            })}
            </div>
        }

            <div className='d-flex flex-md-row items-center flex-wrap justify-center flex-column aqiRangesParent ' >
                <div className='d-flex flex-row' >
                    <div className='d-flex flex-column justify-content-center align-items-center airQualityRanges'>
                        <span
                        style={{ background: "#50C878", width:"100%", borderRadius: "inherit"}}
                        title='Good'
                        >
                            0 ~ 50
                        </span>
                        <strong style={{color:"#50C878"}}>
                            {t(IDS_Good)}
                        </strong>
                    </div>

                    <div className='d-flex flex-column justify-content-center align-items-center airQualityRanges'>
                        <span
                        style={{ background: "#9acd32", width:"100%", borderRadius: "inherit"}}
                        title='Satisfactory'
                        >
                            50 ~ 100
                        </span>
                        <strong style={{color:"#9acd32"}}>
                            {t(IDS_Satisfactory)}
                        </strong>
                    </div>

                    <div className='d-flex flex-column justify-content-center align-items-center airQualityRanges'>
                        <span
                        style={{ background: "#FFD700", width:"100%", borderRadius: "inherit"}}
                        title='Moderate'
                        >
                            100 ~ 200
                        </span>
                        <strong style={{color:"#FFD700"}}>
                            {t(IDS_Moderate)}
                        </strong>
                    </div>
                </div>

                <div className="d-flex flex-row">
                    <div className='d-flex flex-column justify-content-center align-items-center airQualityRanges'>
                        <span
                        style={{ background: "orange", width:"100%", borderRadius: "inherit"}}
                        title='Poor'
                        >
                            200 ~ 300
                        </span>
                        <strong style={{color:"orange"}}>
                            {t(IDS_PoorAqi)}
                        </strong>
                    </div>

                    <div className='d-flex flex-column justify-content-center align-items-center airQualityRanges'>
                        <span
                        style={{ background: "red", width:"100%", borderRadius: "inherit", color: "white"}}
                        title='Very Poor'
                        >
                            300 ~ 400
                        </span>
                        <strong style={{color:"red" }}>
                            {t(IDS_VeryPoorAqi)}
                        </strong>
                    </div>

                    <div className='d-flex flex-column justify-content-center align-items-center airQualityRanges'>
                        <span
                        style={{ background: "#c00000", width:"100%", borderRadius: "inherit", color: "white"}}
                        title='Severe'
                        >
                            400+
                        </span>
                        <strong style={{color:"#c00000"}}>
                            {t(IDS_SevereAqi)}
                        </strong>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default VcAQIBox