import React, { useState, useEffect, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import { AppRelevantDataContext } from '../../AppContext';
import {FaSearch} from 'react-icons/fa';
import '../CSS/AllDeviceData.css';
import axios from 'axios';
import { getAPIHostURL, getAllDeviceDataRefreshTime } from '../../ClientConfig';
import { CO, O3, LPG, SMOKE, CH4, CO2, HUM, NO2, VOC, PM10, PM25, TEMP, PM1, NH3, H2S, SO2, NH3OD, 
         PVG_GENERAL_USER, ALERT_BASED, NO_CALIB, VRI, MRI, CLIENT_TYPE, PFC, CAQI, AQI,
         HCHO, O2, PM100, NO, CL, CH3SH, NOISE, LUX, UV, RADON, AP, WS, DLV, RNFL, 
         WD,
         LOW_V_POOR_L,
         LOW_V_POOR_U,
         WATER_LEVEL_INDICATOR} from '../../VcConstants';
import { LOW, LOW_SEVERE_L, LOW_SEVERE_U, GOOD_L, GOOD_U, SATISFACTORY_L, SATISFACTORY_U, MODERATE_L, MODERATE_U, POOR_L, POOR_U, V_POOR_L, V_POOR_U, 
         HIGH_SEVERE_L, HIGH_SEVERE_U, MAX, WLIP, AWL, VOL, AWC, TCWL, SLIP, PEOPLE_COUNTER } from '../../VcConstants';
import {convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday, 
         convertLocalDateToStrYYYYMMDDHH24MMSS, convertUTCDateToStrYYYYMMDDHH24MMSS } from '../../vtUtil';
import { IDS_Device, IDS_Log, IDS_Ozone, IDS_Sulphur, IDS_TVOC, IDS_Carbon, IDS_Ammonia, IDS_Hydrogen,IDS_NoGasLeakage, IDS_NoSmokeDetected,
            IDS_Methane, IDS_LPG, IDS_Dust, IDS_Temperature, IDS_Humidity, IDS_Odour, IDS_Name, IDS_Time, IDS_Dioxide, IDS_GasLeakageDetected,
            IDS_Monoxide, IDS_Sulphide, IDS_Nitrogen, IDS_Search, IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_SmokeDetected,
            IDS_SrvrIssueDevcIDNotReceived, IDS_AlertLevel1, IDS_AlertLevel2, IDS_AlertLevel3, IDS_AlertLevel4, IDS_DevcDataNotPresentOnSrvrForVisibleDevcID,IDS_SMOKE,
            IDS_AlertLevel5, IDS_LevelSearch, IDS_TodaySearch, IDS_GeneralUser, 
            IDS_AlertSrvcIssue, IDS_DevcDataNotPresentOnSrvrForLoggedInUser, IDS_DefInfoMsgForTreeNodeInDevcPg, IDS_RefreshData, 
            IDS_VRI, IDS_MRI, IDS_TodayPeopleCount, IDS_WL, IDS_Indication, IDS_Available, IDS_Water, IDS_Tank, IDS_Capacity, 
            IDS_TotalUsed, IDS_UsedWater, IDS_Litre_Short, IDS_SL, IDS_PrevBtn, IDS_NxtBtn, IDS_airQualityIndex, IDS_currentAirQualityIndex, IDS_AlertLevel6,
        
            IDS_Formaldehyde, IDS_Oxygen, IDS_PM100, IDS_NitricOxide, IDS_Chlorine, IDS_MethylMercaptan, IDS_Noise, IDS_LightIntensity, IDS_UV, IDS_Radon, IDS_AirPressure, IDS_WindSpeed, IDS_WindDirection, IDS_DaylightVisibility, IDS_Rainfall,
            IDS_PPM,
            IDS_PPB,
            IDS_North,
            IDS_Northeast,
            IDS_Southeast,
            IDS_Southwest,
            IDS_Northwest,
            IDS_West,
            IDS_South,
            IDS_East,} from '../../VcLanguage';
import VcSetupDevice from './VcSetupDevice';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { useDispatch } from 'react-redux';
import { singleDeviceData, singleDeviceDataNodeTitle } from './Store/Slices/nodeInfo';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';
// import { NavLink } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import useInterval from './useInterval';

const COLOR_FOR_GOOD = "#00b050"; // hex code for green color.
const COLOR_FOR_SATISFACTORY = "#9acd32";
const COLOR_FOR_LOW_V_POOR = "#ff0000";
const COLOR_FOR_SEVERE = "darkred";
const COLOR_FOR_VERY_POOR = "red";
const COLOR_FOR_MODERATE = "#FFD700";
const COLOR_FOR_POOR = "orange";
const COLOR_FOR_MISSING_OR_OUT_OF_RANGE = "black";
const COLOR_FOR_WLI = "#678AEE";
const COLOR_FOR_STLI = "#412f01"; 

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const context = useContext(AppRelevantDataContext);
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;  
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                className='searchInputBox'
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder={t(IDS_Search)}
                // style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem 0.8rem", border:"1px solid rgba(0,0,0,.1)", borderRadius: "1rem"}}
            />
        </div>
    )
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data, getCellProps = defaultPropGetter, passedStateVariable, onViewDeviceData}) => {

    const context = useContext(AppRelevantDataContext);
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { 
                // set page size based on tables
                pageSize: 10, 
                // hide and show columns based on condition
                hiddenColumns: ["LOC", "SOA", "TTS", "LAS"]},
            filterTypes,
            // whichever function called from react table which is inside main function
            // should be passed to react table and added in useTable
            onViewDeviceData
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;{!passedStateVariable ? "No Data Found." : ""}</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };

    const handlePageChange = (page) => {
        gotoPage(page - 1);
    };
    
    return (
        <div className='bg-white' style={{borderRadius:"15px", border:"2px solid #00000035", padding:"0px 2px", overflow:"hidden"}}>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader d-flex justify-content-center align-items-center">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={index} {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr
                                {...row.getRowProps(getRowProps(row))}
                                    className = "tr" 
                                    style={{
                                        cursor: '',
                                        background: '',
                                        color: 'black',
                                        alignItems: "center",
                                    }}  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style,},
                                            getCellProps(cell),
                                        ]
                                    )} 
                                    onClick = {() => {
                                        if(cell.column.id == "DeviceName"){
                                            onViewDeviceData(cell.row, cell.column);
                                        }
                                    }}

                                    className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || 
                        //     // when there is no data found 
                        //     <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex", justifyContent: "left"}}>{!passedStateVariable ? "No Data Found." : ""}</span>
                        //         </td>
                        //     </tr> 
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>
            
            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={handlePageChange} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => { 
                            console.log("setPageSize(Number(e)):", e)
                            setPageSize(Number(e)) 
                        }} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>
            
        </div>
    );
};

function VcAllDeviceData (props) {
    const context = useContext(AppRelevantDataContext);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const filterCaseInsensitive = (rows, id, filterValue) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        // If the cell for the filtered column contains null value (it is blank) then exclude that row
        if(id == null ) {
            return false;
        } 
       
        if(id == "DeviceName") {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false // False to not show blank text (not required since already taken care of above)
                })
        } else if(id == "LogTime") {

            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id];

                if(String(t(IDS_TodaySearch).toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                    new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                    new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                    new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });

        } else if(id == "NH3OD") {
            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id];

                let {minval, maxval, RangeMin, RangeMax} = getRangeValuesBasedOnAppliedColumnFilter(filterValue.toLowerCase(), id);

                let valueToBeCompared = rowValue;
            
                if( String(t(IDS_LevelSearch).toString().toLowerCase()).includes(filterValue.toLowerCase()) ) {
                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    return true
                } 
                else if(valueToBeCompared <= RangeMin &&
                        (String(t(IDS_AlertLevel1).toString().toLowerCase()).includes(filterValue.toLowerCase()))) {
                    // NOTE: (Only for Black Text cells)
                    // Only for the case where the string entered in the filter box has corresponding internal value is less than RangeMin
                    // then show the row in the final result (by returning true)
                    return true;
                }  else if(valueToBeCompared >= RangeMax &&
                            (String(t(IDS_AlertLevel5).toString().toLowerCase()).includes(filterValue.toLowerCase()))) {

                    // NOTE: (Only for Black Text cells)
                    // Only for the case where the string entered in the filter box has corresponding internal value is greater than RangeMax
                    // then show the row in the final result (by returning true)
                    return true;
                } 
                else if( minval != null && valueToBeCompared > minval && maxval != null && valueToBeCompared < maxval) {
                    // NOTE: (This is the normal in range case - Non Black Text cells)
                    // Only for the case where the string entered in the filter box has corresponding internal value is within 
                    // the minVal and maxVal then show the row in the final result (by returning true)
                    return true;
                } else {
                    return false; 
                }
            })
               
        } else {

            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id];
                let {minval, maxval, RangeMin, RangeMax} = getRangeValuesBasedOnAppliedColumnFilter(filterValue, id);

                let valueToBeCompared = rowValue;

                if( ( valueToBeCompared <= RangeMin && 
                    ( filterValue == RangeMin || String(RangeMin.toString().toLowerCase()).includes(filterValue.toLowerCase()) )
                    ) 
                ) {
                    // NOTE: (Only for Black Text cells)
                    // Only for the case where the value entered in the filter box is exactly
                    // the RangeMin for that ParamType, and if the internal value stored in the
                    // cell is less than RangeMin, then show the row in the final result (by returning true)
                    return true;
                } else if( ( valueToBeCompared >= RangeMax && 
                    ( filterValue == RangeMax || String(RangeMax.toString().toLowerCase()).includes(filterValue.toLowerCase()) )
                ) 
                ) {
                    // NOTE: (Only for Black Text cells)
                    // Only for the case where the value entered in the filter box is exactly
                    // the RangeMax for that ParamType, and if the internal value stored in the
                    // cell is greater than RangeMax, then show the row in the final result (by returning true)
                    return true
                } else {
                    // NOTE: (This is the normal in range case - Non Black Text cells)
                    // Only check if the a part of the filter text is present in the cell value and return true if found.
                    return  String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                } 
            });
        }       
        
    };

    const [state, setState] = useState({ 
        selectedModelName: '',
        selectedModelID: '',
        LoggedInUserID: '',
        allDeviceData: [],
        stdAirQualityRanges: [],
        relevantModelInfo: [],
        languageToViewIn: '',
        errors: {
            others: ''
        },
        data: [
            // { name: 'Tanner Linsley', age: 50, Occupation: 'H/W'},
        ],
        visibleDeviceData: [
            // { name: 'Tanner Linsley', age: 50, Occupation: 'H/W'},
        ],
        columns: [
            // {   Header: 'Name', 
            //     accessor: 'name',
            //     getProps: (state, rowInfo, column) => {
            //         return {
            //             style: {
            //                 color: rowInfo && rowInfo.row.age >50 ? 'red' : null,
            //             },
            //         };   
            //     },
            //     Filter:({filter, onChange}) => {
            //         return(
            //             <div>
            //                 <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)"}}/>
            //                 <input
            //                     onChange={event => onChange(event.target.value)}
            //                     value={filter ? filterValue : ''}
            //                     placeholder="Search Device name"
            //                 />
            //             </div>
            //         )
            //     },
            // }, 
            // {Header: 'Age', accessor: 'age'},
            // {Header: 'Occupation', accessor: 'Occupation'}
        // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        ],
        PrivilegeEncKey: "",
        objPrivilege:{},
        EnctyptedPrivilege: "",
        DevicesImportantAlertInfo: [],
        AlertBasedParam: "",
        intervalOfVisibleDeviceIdsData: null,
        dataFetchBoolean: false,
        reactTableDataFlag: "",
    });

    const getCellStyle = (cell) => {
        let selectedModelName = appRelevantDataContextValue.selectedModelInfo.modelName;
        let selectedModelID = appRelevantDataContextValue.selectedModelInfo.modelID;

        // console.log("AllDeviceDataDemo - State: ", state);
        // console.log("AllDeviceDataDemo - RowInfo: ", rowInfo);
        // console.log("AllDeviceDataDemo - Column: ", column.id);

        // For Extra Empty rows of the React Table, the column value is null.
        let columnValue = cell.column && (cell.column.id != null) ? cell.column.id : null;
        let SelectedRowDeviceID = (cell.row && cell.row.original && cell.row.original.DeviceID) != null ? cell.row.original.DeviceID : null;

        // console.log("AllDeviceDataDemo - RowInfo - ColumnValue: ", columnValue);

        // objALertBasedParamInfo returns the value of AletBasedColor and AlertValue.
        // if AletBasedColor value is true when "SelectedRowDeviceID" and "column.id" have generated alert.
        // if "AletBasedColor" its value is true then for showing colour of text we use that "AlertValue" else use value comes frome devicedata.
        let objALertBasedParamInfo = getValueForAlertBasedParam(SelectedRowDeviceID, cell.column.id);

        let columnTextColor = "";
        // here columnValue access original Value taken from DB.
        if(columnValue == null) {
            columnTextColor = 'black';   
        } else if(cell.column.id === PFC) {
            columnTextColor = COLOR_FOR_GOOD;
        } else if(cell.column.id == AWC || cell.column.id == WLIP || cell.column.id == AWL || cell.column.id == VOL) {
            columnTextColor = COLOR_FOR_WLI;
        } else if(cell.column.id == SLIP) {
            columnTextColor = COLOR_FOR_STLI;
        } else {
            if(objALertBasedParamInfo !== null && objALertBasedParamInfo["AletBasedColor"] === true && objALertBasedParamInfo["AlertValue"] !== null) {
                columnTextColor = getParamValueTextStyleBasedOnRange(selectedModelID, cell.column.id, objALertBasedParamInfo["AlertValue"]);
            } else {
                columnTextColor = getParamValueTextStyleBasedOnRange(selectedModelID, cell.column.id, cell.value);
            }
        }

        let textStyle = {
            color: columnTextColor,
            // textShadow: columnTextColor === "#FFD700" ? "1px 0px 5px gray": null,
            fontWeight: 700,
            textAlign: objALertBasedParamInfo["AletBasedColor"] == true ? "center" : "right",
        }

        return textStyle;
    }

    useInterval(() => {
        GetDeviceInformationOnCurrentlyVisibleTableViewport();
      }, getAllDeviceDataRefreshTime())

    useEffect (() => {

        let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext

        // Showing loading page to user to avoid unnecessary clicks from the user while fetching data from the server.
        appRelevantDataContextValue.onChangeProcessingReq(true);

        checkUserPrivilegeAndGetLatestDeviceData();

        // Whenever an explicit refresh happens or the page is loaded for the first time
        // get data for all the devices for this owner.
        // getLatestDeviceData(true);
        
        // interval = setInterval( () => { getLatestDeviceData() }, getAllDeviceDataRefreshTime() );
        
        // There-after, get data only for the device rows which are currently visible on the table viewport

        // Clear interval if present.
        // if(state.intervalOfVisibleDeviceIdsData != null) {
        //     clearInterval(state.intervalOfVisibleDeviceIdsData);
        // }
        // state.intervalOfVisibleDeviceIdsData = setInterval( () => { GetDeviceInformationOnCurrentlyVisibleTableViewport() }, getAllDeviceDataRefreshTime() );
        // return () => {
        //     // Anything in here is fired on component unmount.
        //     clearInterval(state.intervalOfVisibleDeviceIdsData);
        // }

    }, []);

    const onRefreshBtnClicked = () => {
        let refreshIconEle = document.getElementById('addRefreshBtn');
        if (refreshIconEle) {
            refreshIconEle?.classList.add('spinn')
            setTimeout(() => {
                refreshIconEle?.classList.remove('spinn')
            }, 1000);
        }
        GetDeviceInformationOnCurrentlyVisibleTableViewport();
    }

    const checkUserPrivilegeAndGetLatestDeviceData = () => {
        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  

        let encryptedPrivileges = appRelevantDataContextValue.loggedInUserPrivilege.Privilege;
        modifiedState.EnctyptedPrivilege = encryptedPrivileges;

        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        modifiedState.languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

        axios.post(`${getAPIHostURL()}/wclient/getEncChaabi`)
        .then(response =>{
            if(response.data.code == 'SUCCESS') {
               if(response.data.retrievedEncChaabi == null || response.data.retrievedEncChaabi.length <= 0) {
                    modifiedState.errors.others = `Unable to get encryption key.`;
                    appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
               } else {
                   modifiedState.PrivilegeEncKey = response.data["retrievedEncChaabi"][0]["PassKey"];

                //    let bytes  = CryptoJS.AES.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);
                   let bytes  = aes.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);

                //    let strPrivilege = bytes.toString(CryptoJS.enc.Utf8);
                   let strPrivilege = bytes.toString(enc);

                   try {
                        modifiedState.objPrivilege = JSON.parse(strPrivilege);
                        getLatestDeviceData(true, modifiedState);
                   } catch(e) {
                        // props.history.replace('/');
                        console.log(`Should not happen. The Privilege obtained from Context is in invalid JSON format.`);
                        appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
                   }
               }

            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // modifiedState.errors = 'Server experiencing issues.\n Try again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    // modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                console.log(modifiedState.errors.others);
                setState({...modifiedState});
                appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
            }

        })
        .catch(err => {
            appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
            console.log("Network error");
            console.log(err);
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                console.log(modifiedState.errors.others);
                setState({...modifiedState});
            }
        }) 
    }

    // Note: inbFormatTableColumns will be 'true' only when the table is rendered for the first time and whenever the model
    // selection in the drop down is changed. This will prevent unnecessary column formatting when only the data is changing
    // (thereby preventing focus loss when someone is typing in the search box and data refresh happens at the same time).  
    const getLatestDeviceData = (inbFormatTableColumns = false, inModifiedState = null) => {

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        } 

        let appRelevantDataContextValue = context;

        let t = appRelevantDataContextValue.t;

        let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        modifiedState.languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

        let appNotifyOnSelectedDevice = appRelevantDataContextValue.onSelectedDevice;
       
        // Get the currently stored ModelName and ModelID from context.
        // Note: If there is no ModelName at the AppContext or if the currently 
        // selected ModelName is not present in the list of Models for the logged in User,
        // then we will select the first model retrieved from the server.
        modifiedState.appNotifyOnSelectedDevice = appNotifyOnSelectedDevice;

        // Incase the LHS Tree does not have any devices OR if it is a General user (a user without Devices) Logged in
        // and the has a node with ID 'NO_DEVC_FOUND_FOR_LOGGED_IN_USER'.
        // In this case no need the data brought from server is empty for that loggedInUser so we have to show him a HTML page with message.
        // Also you have to update state with loggedinUser, selectedTreeNodeID as it will avoid triggering componentDidUpdate infinitely.
        if(modifiedState.objPrivilege != null &&
            modifiedState.objPrivilege.hasOwnProperty(PVG_GENERAL_USER) && 
            modifiedState.objPrivilege[PVG_GENERAL_USER] == true
        ) {
            // Also you have to update a state  with loggedinUser as it will avoid triggering componentDidUpdate.
            modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
            modifiedState.selectedModelName = appRelevantDataContextValue.selectedModelInfo.modelName;
            modifiedState.languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;
    
            setState({...modifiedState});
            appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
            return;
        }
        // If user wishes to Track Devices of DeviceOwner, then he/she will choose to "Track Devices" and "View Data",
        // on clicking "View Data", Devices page will get open in new tab of browser and
        // AppContext will get updated for values of "ownerOfTrackedDevices".
        // If AppContext's "ownerOfTrackedDevices" value has got updated then we send "OwnersUserID" and 
        // boolean flag "bGetOnlyOwnedDevices = true" in json body, this will update table for "AllDevices"
        // according to OwnersUserID which has got updated in AppContext for respective DeviceOwner,
        // else table for "AllDevices" will get updated according to "LoggedinUserID".

        let loggedInOrOwnerUserID = "";
        let bGetOnlyOwnedDevices = false;
        let ownerOfTrackedDevices = appRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
        let trackDevicesArr = appRelevantDataContextValue.devicesToTrack.DevicesInfo;
        let deviceArr = [];

        trackDevicesArr != null && trackDevicesArr.length > 0 && trackDevicesArr.map((device) => {
            deviceArr.push(device.DeviceID)
        })
        
        if(ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0){
            loggedInOrOwnerUserID = ownerOfTrackedDevices;
            bGetOnlyOwnedDevices = true;
            
        } else {
            loggedInOrOwnerUserID = loggedInUserID;
            bGetOnlyOwnedDevices = false;
        }

        let currentBrowserDtTm = new Date();
        let strCurrentBrowserDtTm = convertLocalDateToStrYYYYMMDDHH24MMSS(currentBrowserDtTm);

        let crossingCountBrowserStartDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "00:00:00";
        let crossingCountBrowserEndDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "23:59:59";
  
        let crossingCountUtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserStartDtTm.valueOf()));
        let crossingCountUtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserEndDtTm.valueOf()));

        let jsonParams = {
            loggedInOrOwnerUserID: loggedInOrOwnerUserID,
            bGetOnlyOwnedDevices : bGetOnlyOwnedDevices,
            ClientType: CLIENT_TYPE,
            LoggedInUserID: loggedInUserID,
            StartDtTmUTC: crossingCountUtcStartDtTm, // As for the PFC model we are showing summed Devicedata of Todays's date.
            EndDtTmUTC: crossingCountUtcEndDtTm, // As for the PFC model we are showing summed Devicedata of Todays's date.
            deviceArr: deviceArr
        }

        axios.post(`${getAPIHostURL()}/wclient/getLatestAllDeviceData`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
                modifiedState.dataFetchBoolean = true;

                if(response.data.retrievedAllDeviceData == null || response.data.retrievedAllDeviceData.length <=0 ) {
                    appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
                    modifiedState.reactTableDataFlag = "NoData";
                    // Incase the LHS Tree does not have any devices OR if Logged in user have not devices in current timestamp.
                    // In this case device data brought from server is empty for that loggedInUser so we have to show him a HTML page with message.
                    // Also you have to update state with loggedinUser, selectedTreeNodeID as it will avoid triggering componentDidUpdate infinitely.
                    modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
                    modifiedState.selectedModelName = appRelevantDataContextValue.selectedModelInfo.modelName;
                    modifiedState.languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;
            
                    setState({...modifiedState});
                    return;
                } else if( (response.data.retrievedAllDeviceData != null && response.data.retrievedAllDeviceData.length > 0) &&
                            (response.data.retrievedModelInfo == null || response.data.retrievedModelInfo.length <=0 ||
                             response.data.retrievedAirQualityRange == null || response.data.retrievedAirQualityRange.length <=0 
                            )
                ) {
                    appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
                    // if device data bring from server is not empty and relevantModelinfo or airQualityRanges are empty then 
                    // we have to show appropriate error to the user.
                    modifiedState.errors.others = t(IDS_DevcDataNotPresentOnSrvrForLoggedInUser)
                    setState({...modifiedState});
                    return;
                } else {

                    // // Store the entire data that was retrieved from the server into the state
                    // modifiedState.allDeviceData = response.data.retrievedAllDeviceData;
                    // modifiedState.stdAirQualityRanges = response.data.retrievedAirQualityRange;
                    // modifiedState.relevantModelInfo = response.data.retrievedModelInfo;

                    // Using the latest data from server, update those properties of the state which are
                    // relevant to render
                    
                    modifiedState.reactTableDataFlag = "Data";
                    let relevantModelInfo = response.data.retrievedModelInfo;
                    let FirstRetrivedModelName = relevantModelInfo[0]["ModelName"];
                    let FirstRetrivedModelID = relevantModelInfo[0]["ModelID"];

                    modifiedState.AlertBasedParam = (response.data["AlertBasedParam"] == null && response.data["AlertBasedParam"].length <= 0) ? "" : response.data.AlertBasedParam; 
                    modifiedState.selectedModelName = FirstRetrivedModelName;
                    modifiedState.selectedModelID = FirstRetrivedModelID;

                    if(appRelevantDataContextValue.selectedModelInfo.modelID == '' && appRelevantDataContextValue.selectedModelInfo.modelName == '') {
                        appRelevantDataContextValue.onChangeModelName(FirstRetrivedModelID, FirstRetrivedModelName);
                    }

                    let DeviceIDArrToTrack = appRelevantDataContextValue.devicesToTrack.DevicesInfo;

                    // This is the case where AllDevcData page invoked from "TrackDevice".
                    // hence, we have to show only those Device which he wants to track 
                    // instead of showing all the device of that particular owner.
                    // Also we have to filterout only those required models based on DeviceID's.
                    // if((DeviceIDArrToTrack != null && DeviceIDArrToTrack.length > 0 && ownerOfTrackedDevices == PROD_DEVC_OWNER_USER_ID) || 
                    //     (DeviceIDArrToTrack != null && DeviceIDArrToTrack.length == 1)) {

                    //     let FilteredDeviceIDArrToTrackFromRetrivedAllDevcData  
                    //                                 = response.data.retrievedAllDeviceData.filter(function(D1){
                    //                                     return DeviceIDArrToTrack.some(function(D2){
                    //                                         return D1.DeviceID == D2.DeviceID;          // assumes unique id
                    //                                     });
                    //                                 });

                    //     let FilteredModelInfoFromRetrievedModelInfo              
                    //                                     = response.data.retrievedModelInfo.filter(function(M1){
                    //                                         return FilteredDeviceIDArrToTrackFromRetrivedAllDevcData.some(function(M2){
                    //                                             return M1.ModelID == M2.ModelID;          // assumes unique id
                    //                                         });
                    //                                     });

                    //     updateStatePropertiesRelevantToRender(
                    //         inbFormatTableColumns, FilteredDeviceIDArrToTrackFromRetrivedAllDevcData, response.data.retrievedAirQualityRange, FilteredModelInfoFromRetrievedModelInfo, response.data.ImportantAlertInfo);
                                
                    // } else {
                    //     updateStatePropertiesRelevantToRender(
                    //         inbFormatTableColumns, response.data.retrievedAllDeviceData, response.data.retrievedAirQualityRange, response.data.retrievedModelInfo, response.data.ImportantAlertInfo);
    
                    // }      
                    
                    
                    /// Above code is commented because in the new feature of "toilet nodes",
                    /// requirement was in the case where AllDevcData page invoked from "TrackDevice", we should be able to see all devices in all device data react table.

                    updateStatePropertiesRelevantToRender(inbFormatTableColumns, response.data.retrievedAllDeviceData, response.data.retrievedAirQualityRange, response.data.retrievedModelInfo, response.data.ImportantAlertInfo, modifiedState);

                }
                
            } else {
                appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = t(IDS_AlertSrvcIssue);
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } 

                setState({...modifiedState});
            }
        })
        .catch( error => {
            appRelevantDataContextValue.onChangeProcessingReq(false); // Removing loading spinner.
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                setState({...modifiedState});
            }
        });    
    }

    // Note: inbFormatTableColumns will be 'true' only when the table is rendered for the first time and whenever the model
    // selection in the drop down is changed. This will prevent unnecessary column formatting when only the data is changing
    // (thereby preventing focus loss when someone is typing in the search box and data refresh happens at the same time).  
    const updateStatePropertiesRelevantToRender = (
        inbFormatTableColumns = false, inAllDeviceData = null, inStdAirQualityRanges = null, inRelevantModelInfo = null, inAlertInfoForAlertBased = null, inModifiedState = null) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        } 

        let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        let languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

        let appNotifyOnSelectedDevice = appRelevantDataContextValue.onSelectedDevice;
        
        // Get the currently stored ModelName and ModelID from context.
        // Note: If there is no ModelName at the AppContext or if the currently 
        // selected ModelName is not present in the list of Models for the logged in User,
        // then we will select the first model retrieved from the server.

        let selectedModelName = modifiedState.selectedModelName;
        
        let selectedModelID = modifiedState.selectedModelID;

        appRelevantDataContextValue.onChangeModelName(modifiedState.selectedModelID, modifiedState.selectedModelName);

        // If passed by the caller, update new values in State for AllDeviceData,
        // StandardAirQualityRanges and RelevantModelInfo (will happen in DidMount
        // and whenever new data is retrieved during interval)
        if(inAllDeviceData != null) {
            modifiedState.allDeviceData = inAllDeviceData;
        }
        if(inStdAirQualityRanges != null) {
            modifiedState.stdAirQualityRanges = inStdAirQualityRanges;
        }
        if(inRelevantModelInfo != null) {
            modifiedState.relevantModelInfo = inRelevantModelInfo;
            modifiedState.selectedModelName = modifiedState.relevantModelInfo[0]["ModelName"];
            modifiedState.selectedModelID = modifiedState.relevantModelInfo[0]["ModelID"]

        }
        if(inAlertInfoForAlertBased != null) {
            modifiedState.DevicesImportantAlertInfo = inAlertInfoForAlertBased;
        }

        modifiedState.LoggedInUserID = LoggedInUserID;
        modifiedState.selectedModelName = selectedModelName;
        modifiedState.selectedModelID = selectedModelID;
        modifiedState.appNotifyOnSelectedDevice = appNotifyOnSelectedDevice;
        modifiedState.languageToViewIn = languageToViewIn;

        // ========== Model Processing: Start ===============
        // In case the currently selected Model Name is not present in the list of ModelNames received
        // from the server, then set the first ModelName as the 'SelectedModelName'
        let bCurrSelectedModelPresent = false;
        let selectedModelAdditionalInfo = {};
        let selectedModelColumnSequence = [];
        let noOfModels = modifiedState.relevantModelInfo.length;
        for (let i=0; i < noOfModels; i++) {
            if( modifiedState.selectedModelName == modifiedState.relevantModelInfo[i].ModelName) {
                // Currently selected model name is present in the list from the server
                bCurrSelectedModelPresent = true;

                try {
                    selectedModelAdditionalInfo = JSON.parse(modifiedState.relevantModelInfo[i].MeasuredParams);

                    if( ("Seq" in selectedModelAdditionalInfo) == true && selectedModelAdditionalInfo.Seq != null ) {
                        if(selectedModelName == PEOPLE_COUNTER){
                            selectedModelColumnSequence = selectedModelAdditionalInfo.Seq.filter(function(e) { return e == PFC });
                        }
                        else if(selectedModelName == WATER_LEVEL_INDICATOR) {
                            selectedModelColumnSequence = selectedModelAdditionalInfo.Seq.filter(function(e) { return e != TCWL});;
                        }  else {
                            selectedModelColumnSequence = selectedModelAdditionalInfo.Seq;
                        }  
                    } else {
                        console.log(`Should not happen. The Column 'Seq' information is missing in 'MeasuredParams' obtained from server for ModelName [${selectedModelName}] is in invalid JSON format.`);
                        // The selectedModelColumnSequence information for selected Model will be an empty array (will not cause crash when used later)
                    }
                } catch (e) {
                    console.log(`Should not happen. The Additional Information (MeasuredParamsJSON) obtained from server for ModelName [${selectedModelName}] is in invalid JSON format.`);
                    // The additional information for selected Model will be an empty object
                }

                break;
            }
        }
        // Change the selectModelName to first ModelName from server if not found in the server list
        if(!bCurrSelectedModelPresent) {
            selectedModelName = (noOfModels > 0) ? modifiedState.relevantModelInfo[0].ModelName : '';
            selectedModelID = (noOfModels > 0) ? modifiedState.relevantModelInfo[0].ModelID : '';
        
            modifiedState.selectedModelName = selectedModelName;
            modifiedState.selectedModelID = selectedModelID;
        }
        // ========== Model Processing: End =================
        
        // ========== Device Data Processing: Start =================
        // Fill out the data only for the currently selected model
        
        let allDeviceCount = modifiedState.allDeviceData.length;
        // console.log(`All Device Data Count: ${allDeviceCount}`);
        let singleDeviceMeasuredParams = {};
        let selectedModelDevicesData = [];
        for (let j = 0; j < allDeviceCount; j++) {
            const singleDeviceData = modifiedState.allDeviceData[j];
            let LogTime = singleDeviceData.LogTime;
            let Volume = 0;

            // let strLogTime = new Date(LogTime);
            // let strLogTimeToLocaleString = strLogTime.toLocaleString();
            let strLogTimeToLocaleString = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(LogTime);

            // Push the data only for the currently selected Model
            // if(selectedModelID == singleDeviceData.ModelID && singleDeviceData.Active == 1) {
            if(selectedModelID == singleDeviceData.ModelID) {
                try {
                    singleDeviceMeasuredParams = JSON.parse(singleDeviceData.MeasuredParams);

                    if(singleDeviceData.AdditionalInfo != null){
                        Volume = JSON.parse(singleDeviceData.AdditionalInfo)[VOL]
                    }

                    let startDate = new Date().toISOString().split('T')[0];
                    let currDate = new Date(LogTime).toISOString().split('T')[0];

                    if(singleDeviceMeasuredParams.hasOwnProperty(WLIP)){
                        singleDeviceMeasuredParams[VOL] = Volume;
                        if(currDate != startDate){
                            singleDeviceMeasuredParams[WLIP] = 0;
                            singleDeviceMeasuredParams[AWL] = 0;
                            singleDeviceMeasuredParams[TCWL] = 0;
                        }
                    }

                    if(singleDeviceMeasuredParams.hasOwnProperty(PFC)){
                        if(currDate != startDate){
                            singleDeviceMeasuredParams[PFC] = 0;
                        }
                    }

                    // ==================== start work for handle some wrong value/ string in json ======

                    for(let i=0; i<selectedModelColumnSequence.length; i++) {

                        let SingleParamInSeq = selectedModelColumnSequence[i];
                        let SingleParamValue = singleDeviceMeasuredParams[SingleParamInSeq == AWC ? TCWL : SingleParamInSeq];

                        // isNaN is a function which determines whether a value is an illegal number (Not-a-Number) 
                        if(SingleParamValue == null || SingleParamValue.length <= 0 || isNaN(SingleParamValue)) {
                            // console.log(`Value for ${SingleParamInSeq} - ${SingleParamValue} is invalid. Setting it to null for further processing`)
                            singleDeviceMeasuredParams[SingleParamInSeq] = null;
                        } else if(SingleParamInSeq == PFC) {
                            // singleDeviceMeasuredParams[SingleParamInSeq] = Math.ceil((SingleParamValue)/2);
                            singleDeviceMeasuredParams[SingleParamInSeq] = (SingleParamValue);
                        } else if(SingleParamInSeq == AWC) {
                            singleDeviceMeasuredParams[SingleParamInSeq] = (SingleParamValue);
                        } else {
                            // No need to modify the value.
                            // singleDeviceMeasuredParams[SingleParamInSeq] = SingleParamValue;
                        }
                    }

                    // ==================== end work for handle some wrong value/ string in  json ===========

                    // Add the other information which will be used for rendering and identifying the device
                    // along with measured params
                    let singleDeviceDataForTable = {  
                        DeviceName: singleDeviceData.DeviceName,
                        DeviceID: singleDeviceData.DeviceID,
                        ModelID: singleDeviceData.ModelID,
                        Status: singleDeviceData.Status,
                        LogTime: strLogTimeToLocaleString,
                        SelectedNodeDeviceType : singleDeviceData.SelectedNodeDeviceType,
                        ...singleDeviceMeasuredParams
                    }

                    selectedModelDevicesData.push(singleDeviceDataForTable);

                } catch (e) {
                    console.log(`Should not happen. The device data obtained from server for DeviceID [${singleDeviceData.DeviceID}] is in invalid JSON format.`);
                    // Skip this device information and move to next
                }
            }
        }
        modifiedState.data = selectedModelDevicesData;

        // ========== Device Data Processing: End ===============

        // ========== Table Column Formatting: Start =================

        // Format the table columns only on first time render and whenever the device model selection changes
        if(inbFormatTableColumns) {

            let allColumnSeqAndFormat = [];
            let singleColumnFormat = {};

            // First define the column format for the DeviceName column
            singleColumnFormat = { // For First Device Column 
                Header: renderParamNameBasedOnType("DeviceName"), 
                accessor: "DeviceName",
                width: renderColumnWidthBasedOnType("DeviceName"),   
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                }),
                Cell:  (props) => {
                    return (<span className='deviceNameCell' deviceid={props.row.original.DeviceID}>{props.value}</span>)},
                filter: filterCaseInsensitive,
                
            } // singleColumnFormat for first Device Column

            allColumnSeqAndFormat.push(singleColumnFormat);

            let selectedModelColumnCount = selectedModelColumnSequence.length;
            let columnID = null;
            let columnValue = null;
            let columnTextColor = null;
            // let columnSearchString = null;
            let SelectedRowDeviceID = null;
            let singleParamAirQualityInfo = null;
            let singleParamAirQualityRangeValues = {};

            // For each column in sequence, create the column format object
            for (let k = 0; k < selectedModelColumnCount; k++) {
                columnID = selectedModelColumnSequence[k];

                singleParamAirQualityInfo = 
                    modifiedState.stdAirQualityRanges.find( (arrElement) => arrElement["MeasuredParam"] == columnID && arrElement["RangeValues"] != null );

                // if(singleParamAirQualityInfo == null) {
                //     console.log(`Should not happen. Param [${columnID}] was not found in stdAirQualityRange.`);
                //     return []; // Return empty array if the Param was not found in stdAirQualityRange
                // }

                try {

                    if(singleParamAirQualityInfo == null) {
                        console.log(`Should not happen. Param [${columnID}] was not found in stdAirQualityRange.`);
                        // return []; // Return empty array if the Param was not found in stdAirQualityRange
                    } else {
                        singleParamAirQualityRangeValues = JSON.parse(singleParamAirQualityInfo.RangeValues);
                    }
                    // Create the column format information for the current Param in sequence
                    singleColumnFormat = {   
                        Header: renderParamNameBasedOnType(columnID),
                        // value that has to showed to user.
                        Cell: (props) => {
                            return (<span>
                                {getParamValueBasedOnRange(selectedModelID, selectedModelColumnSequence[k], props.value, props.row.original.DeviceID)}
                            </span>)
                        },
                        accessor: columnID, // Actual value taken from DB.
                        width: renderColumnWidthBasedOnType(columnID),
                        disableFilters: true,
                        style: {
                            textOverflow: "ellipsis", 
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            height: selectedModelColumnSequence != null && selectedModelColumnSequence.length == 1 && columnID === LPG ? "3.5rem" : "2.6rem",
                            paddingRight: "1rem",
                        },
                        filter: filterCaseInsensitive,
                    } // singleColumnFormat

                    allColumnSeqAndFormat.push(singleColumnFormat);

                } catch (e) {
                    console.log(`Should not happen. The Air Quality Range Value for Param [${columnID}] is in invalid JSON format.`);
                    // Skip this column and move to next
                }
                
                // modifiedState.columns = allColumnSeqAndFormat;

                
            } // for: end

            // Define the column format for the LogTime column
            singleColumnFormat = { // For First Device Column 
                Header: renderParamNameBasedOnType("LogTime"), 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: "LogTime",
                width: renderColumnWidthBasedOnType("LogTime"),   
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    flexGrow:1,
                    color: "black",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem",
                    paddingRight: "1rem"
                }),
                disableFilters: true,
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                filter: filterCaseInsensitive,
            } // singleColumnFormat for LogTime Column

            allColumnSeqAndFormat.push(singleColumnFormat);

            modifiedState.columns = allColumnSeqAndFormat;
        }

        // ========== Table Column Formatting: End =================
        
        setState({...modifiedState}); // To trigger render

        // In case the selectedModelName in the Context is not up-to-date, then change the 
        // same (Note: Though it will again trigger an 'onComponentUpdate', it will not cause a re-render
        // as there is check to setState only if the SelectedModelName has changed)
        if(!bCurrSelectedModelPresent && noOfModels > 0) {
            let modelIDForContext = selectedModelID == null || selectedModelID.length <= 0 ? state.selectedModelID : selectedModelID;
            let modelNameForContext = selectedModelName == null || selectedModelName.length <= 0 ? state.selectedModelName : selectedModelName;

            appRelevantDataContextValue.onChangeModelName(modelIDForContext, modelNameForContext);
        }
    }

    // First gets the list of all devices which are currently visible in the table viewport.
    // Then calls API to get the device data only for the above devices.
    const GetDeviceInformationOnCurrentlyVisibleTableViewport = () => {
        let VisibleDeviceID = [];

        // this is the case of general user or the user without devices in current timestamp.
        if(state.allDeviceData == null || state.allDeviceData.length <= 0) {
            return; // No need further processing.
        }

        if(document.getElementById("allDeviceDataTable") == null) {
            return; // No need further processing.
        }
        // To get Viewport co-ordinates for ReactTable.
        // let table = document.querySelectorAll("div.rt-tbody")[0].getBoundingClientRect();
        let table= document.getElementById("allDeviceDataTable").getBoundingClientRect();

        // To get only the cells which have the device name inside them.
        let InnerTable = document.querySelectorAll("span.deviceNameCell")
        
        let childnode = [];
        let deviceData = []; // Not required
        let deviceName;  // Not required             
        
        for(let i = 0; i< InnerTable.length; i++) {
            childnode[i] = InnerTable[i].getBoundingClientRect();
            // To get Device Information which is currently visible in Viewport of ReactTable
            if(childnode[i].top > table.top && childnode[i].bottom < table.bottom) {
                deviceName = InnerTable[i].textContent;

                if(InnerTable[i].getAttribute("deviceid") != null) {  
                    VisibleDeviceID.push(InnerTable[i].getAttribute("deviceid"));

                    // deviceData.push({ deviceName, VisibleDeviceID }); // Not required
                }
            }
        }
        
        // Call api to get device data based on the visible list of DeviceIDs. Update state accordingly.
        getLatestAllVisibleDeviceData(VisibleDeviceID);
    }

    const getLatestAllVisibleDeviceData = (VisibleDeviceID) => {
        let modifiedState = state;

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        modifiedState.visibleDeviceData = [];
        let DeviceIDArr = VisibleDeviceID;

        if(DeviceIDArr == null || DeviceIDArr.length <=0) {
            return; // No need further processing.
        }

        let currentBrowserDtTm = new Date();
        let strCurrentBrowserDtTm = convertLocalDateToStrYYYYMMDDHH24MMSS(currentBrowserDtTm);

        let crossingCountBrowserStartDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "00:00:00";
        let crossingCountBrowserEndDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "23:59:59";
  
        let crossingCountUtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserStartDtTm.valueOf()));
        let crossingCountUtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserEndDtTm.valueOf()));

        let jsonParams = {
            DeviceIDArr: DeviceIDArr,
            AlertBasedParam: modifiedState.AlertBasedParam,
            StartDtTmUTC: crossingCountUtcStartDtTm, // As for the PFC model we are showing summed Devicedata of Todays's date.
            EndDtTmUTC: crossingCountUtcEndDtTm, // As for the PFC model we are showing summed Devicedata of Todays's date.
        }
    
        axios.post(`${getAPIHostURL()}/wclient/getLatestVisibleDeviceData`, jsonParams)
        .then(response => {
            if(response.data.code === 'SUCCESS') {
                if(response.data.retrievedAllVisibleDeviceData == null || response.data.retrievedAllVisibleDeviceData.length <= 0 )
                {
                    modifiedState.errors.others = t(IDS_DevcDataNotPresentOnSrvrForVisibleDevcID);
                } else {
                    modifiedState.visibleDeviceData = response.data.retrievedAllVisibleDeviceData
                    let LengthOfVisibleDeviceData =  modifiedState.visibleDeviceData.length;

                    for(let i = 0; i < LengthOfVisibleDeviceData; i++ ) {
                        for(let j = 0; j < modifiedState.allDeviceData.length; j++) {
                            if(modifiedState.visibleDeviceData[i]["DeviceID"] === modifiedState.allDeviceData[j]["DeviceID"]) {
                                
                                modifiedState.allDeviceData[j]["MeasuredParams"] = modifiedState.visibleDeviceData[i]["MeasuredParams"];
                                modifiedState.allDeviceData[j]["LogTime"] = modifiedState.visibleDeviceData[i]["LogTime"];
                                let additionalInfoOfDevc = modifiedState.visibleDeviceData[i]["AdditionalInfo"];

                                modifiedState.allDeviceData[j]["Volume"] = additionalInfoOfDevc != null && additionalInfoOfDevc.length > 0 ? 
                                                                            JSON.parse(additionalInfoOfDevc)[VOL] : 0;
                            }
                        }       
                    }
                    updateStatePropertiesRelevantToRender(false, modifiedState.allDeviceData, modifiedState.stdAirQualityRanges, modifiedState.relevantModelInfo, response.data.ImportantAlertInfo );
                }
            } else {
                if(response.data.code == 'REQ_PARAM_MISSING') {
                    modifiedState.errors.others = t(IDS_SrvrIssueDevcIDNotReceived);
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } 

                setState({...modifiedState});
            }
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                setState({...modifiedState});
            }
        }); 
    }

    // In case of "AlertBased" params(LPG/Smoke) when Alerts comes that time take alert value for showing colour of Message. 
    const getValueForAlertBasedParam = (SelectedRowDeviceID, columnId) => {

        let AlertValue = null;
        let AletBasedColor = false;

        if(SelectedRowDeviceID == null) {
            return {AlertValue, AletBasedColor};
        }

        let modifiedState = state;
        let modelInfo = {};
        for(let j=0; j<modifiedState.relevantModelInfo.length; j++) {
            if(modifiedState.relevantModelInfo[j]["ModelID"] == modifiedState.selectedModelID) {
                modelInfo = JSON.parse(modifiedState.relevantModelInfo[j].MeasuredParams);
            }
        }

        let AlertBased = modelInfo[columnId] != null && modelInfo[columnId].hasOwnProperty(ALERT_BASED) ?  modelInfo[columnId].AlertBased : false;

        if( AlertBased === true && modifiedState.DevicesImportantAlertInfo != null && modifiedState.DevicesImportantAlertInfo.length > 0){

            for(let i=0; i<modifiedState.DevicesImportantAlertInfo.length; i++) {
                
                let MeasuredParam = modifiedState.DevicesImportantAlertInfo[i].MeasuredParam
                let AlertDeviceID = modifiedState.DevicesImportantAlertInfo[i].DeviceID;

                    if(AlertDeviceID === SelectedRowDeviceID && columnId === MeasuredParam){
                        AlertValue = modifiedState.DevicesImportantAlertInfo[i].Value;
                        AletBasedColor = true;
                        break;
                    }

            }
        }

        return {AlertValue, AletBasedColor};
    }

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);

        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return t(IDS_TodaySearch) + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }


    const getParamValueBasedOnRange = (selectedModelID, inParamName, inParamValue, DeviceID) => {

        let modifiedState = state;
        let selectedModelParamsInfo = {};
        let selectedModelSingleParamInfo = {};

        for(let i = 0; i < modifiedState.relevantModelInfo.length; i++) {
            if(selectedModelID === modifiedState.relevantModelInfo[i]["ModelID"] ) {

                selectedModelParamsInfo = JSON.parse(modifiedState.relevantModelInfo[i].MeasuredParams);
                break;
            }
        }
        // Extract a particular MeasuredParam Object.
        selectedModelSingleParamInfo = selectedModelParamsInfo[inParamName];
        let AlertBased = (selectedModelSingleParamInfo.hasOwnProperty(ALERT_BASED) && selectedModelSingleParamInfo[ALERT_BASED] == true) ? selectedModelSingleParamInfo[ALERT_BASED] : false;
        let Nocalib = selectedModelSingleParamInfo.hasOwnProperty(NO_CALIB) ? selectedModelSingleParamInfo.NoCalib : false;

        if(inParamValue == null ) {
            return null;
        } else if(inParamValue < selectedModelSingleParamInfo["RangeMin"] && Nocalib == false) {

            // Incase Sensor value is less than "RangeMin" restrict it to RangeMin value.
            inParamValue = selectedModelSingleParamInfo["RangeMin"];
        } else if(inParamValue > selectedModelSingleParamInfo["RangeMax"] && Nocalib == false) {

            // Incase Sensor value is greater than "RangeMax" restrict it to RangeMax value.
            inParamValue = selectedModelSingleParamInfo["RangeMax"];
        } 

        // In case of NH3OD show level instead of param value.
        if(inParamName === NH3OD) {
            inParamValue = getParamLevelBasedOnRange(inParamValue, inParamName);
        }

        if(inParamName == WD) { 
            let shortName = true;   
            if ( inParamValue > 360 || inParamValue < 0) {
                inParamValue = ""
            } else {
                inParamValue = getDirectionName(inParamValue, shortName) + ' (' + inParamValue + ')'
            }
        }

        // In case of Smoke/LPG show text instead of param value.
        if(AlertBased == true) {
            inParamValue =  getValueTextForAlertBasedParam(selectedModelID, inParamValue, inParamName, DeviceID);
        }
        return inParamValue;
    }

    useEffect(() => {

        let currentState = state;
        let appRelevantDataContextValue = context; 

        if( appRelevantDataContextValue == null ||
            ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.loggedInUserInfo == null ||
            ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
            appRelevantDataContextValue.loggedInUserInfo.userID == null ||
            appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
            ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.selectedNodeInfo == null ||
            appRelevantDataContextValue.selectedNodeInfo.modelName == "" ||
            appRelevantDataContextValue.selectedNodeInfo.modelID == "" || 
            ("nodeID" in appRelevantDataContextValue.selectedNodeInfo) == false ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID == null ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID.length <= 0

        ) {
            console.error("AllDeviceData:componentDidUpdate - Should not happen. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
            return; // Do not process further
        }

        // Get latest data only if the logged in User has changed (useful for future in case
        // we have switch user option)
        if((currentState.LoggedInUserID != appRelevantDataContextValue.loggedInUserInfo.userID  ||
            currentState.languageToViewIn != appRelevantDataContextValue.language.languageToViewIn)
        ) {
            // The LoggedInUser has changed. Update the state based on the new selected node.
            // console.log("AllDeviceData - LoggedInUserID not same as Context UserID");
            // getLatestDeviceData(appRelevantDataContextValue);
            getLatestDeviceData(true);
            
            // The visible device list (based on selected model) will be updated in 'getLatestDeviceData' so no other actions needed

        } else if(currentState.selectedModelName != appRelevantDataContextValue.selectedModelInfo.modelName ) {
            // If the model (or language) selection has changed, then show only the devices corresponding to that model
            // (No need to get the latest device data)
            updateStatePropertiesRelevantToRender(true); // Also update column formatting when model changes (by passing inbFormatTableColumns = true)

        } else {
            // Should not reach here.
        }

    } ,[context.selectedModelInfo.modelName, context.loggedInUserInfo.userID, state.selectedModelName, context.language.languageToViewIn]);

    // const onChangeModelName = (e) => {
    //     let selectedModelName = e.target.value;
    //     let selectedModelID = '';

    //     let modifiedState = state;
    //     for(let i=0; i<state.relevantModelInfo.length; i++) {
    //         let singleModelInfo = state.relevantModelInfo[i];
    //         if(singleModelInfo.ModelName == selectedModelName) {
    //             selectedModelID = singleModelInfo.ModelID;
    //             break; // Found ModelID for ModelName
    //         }
    //     }
    //     let appRelevantDataContextValue = context; 
    //     modifiedState.selectedModelID = selectedModelID;
    //     modifiedState.selectedModelName = selectedModelName;

    //     setState({...modifiedState});
    // }
    const onChangeModelName = (value) => {
        let selectedModelID = '';
        let selectedModelName = value;

        let modifiedState = state;
        for(let i=0; i<state.relevantModelInfo.length; i++) {
            let singleModelInfo = state.relevantModelInfo[i];
            if(singleModelInfo.ModelName == selectedModelName) {
                selectedModelID = singleModelInfo.ModelID;
                break; // Found ModelID for ModelName
            }
        }
        let appRelevantDataContextValue = context; 
        modifiedState.selectedModelID = selectedModelID;
        modifiedState.selectedModelName = selectedModelName;

        setState({...modifiedState});
    }
    const onViewDeviceData = (rowInfo, column, cellInfo) => {
        let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
        let clickedNodeID = rowInfo.original.DeviceID;
        let clickedNodeTitle = rowInfo.original.DeviceName
        let SelectedNodeDeviceType = rowInfo.original.SelectedNodeDeviceType;

        appRelevantDataContextValue.onSelectedDevice(clickedNodeID, clickedNodeTitle, false, null, true, [], null, SelectedNodeDeviceType);

        dispatch(singleDeviceData({
            nodeID: clickedNodeID, 
            nodeTitle: clickedNodeTitle, 
            isRoot: false, 
            hasDevc: null, 
            isDevc: true, 
            deviceType: [], 
            parentID: null,  
            containsChildNode: null, 
            SelectedNodeDeviceType: "",
        }))

        dispatch(singleDeviceDataNodeTitle({
            nodeTitle: "NEW DATA"
        }));

        props.dashboardDeviceTreeToggle();

        // Redirect to the dashboard page
        navigate('/device/dashboard');

        // dispatch(singleDeviceData(prevState => ({
        //     ...prevState,
        //     nodeTitle: "ABC", 
        // })))
    }

    const renderParamNameBasedOnType = (inParamNameType) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        switch(inParamNameType) {
            case "DeviceName":
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Device)} <br/>{t(IDS_Name)}</h6>);
            case "LogTime":
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Log)} <br/>{t(IDS_Time)}</h6>);
            case AQI:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_airQualityIndex)}</h6>);
            case CAQI:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_currentAirQualityIndex)}</h6>);
            case NO2:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Nitrogen)} <br/>{t(IDS_Dioxide)} (NO<sub>2</sub>)</h6>);
            case O3:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Ozone)} <br/>(O<sub>3</sub>)</h6>);
            case SO2:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Sulphur)} <br/>{t(IDS_Dioxide)} (SO<sub>2</sub>)</h6>);
            case VOC:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_TVOC)}<br/>(TVOC)</h6>);
            case CO:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Carbon)} <br/>{t(IDS_Monoxide)} (CO)</h6>);
            case NH3:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Ammonia)} (NH<sub>3</sub>)</h6>);
            case CO2:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Carbon)} <br/>{t(IDS_Dioxide)} (CO<sub>2</sub>)</h6>);
            case H2S:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Hydrogen)} <br/>{t(IDS_Sulphide)} (H<sub>2</sub>S)</h6>);
            case CH4:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Methane)} <br/>(CH<sub>4</sub>)</h6>);
            case LPG:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_LPG)}</h6>);
            case PM1:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Dust)}<br/>(PM<sub>1</sub>)</h6>);
            case PM25:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Dust)}<br/>(PM<sub>2.5</sub>)</h6>);
            case PM10:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Dust)}<br/>(PM<sub>10</sub>)</h6>);
            case TEMP:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Temperature)}</h6>);
            case HUM:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Humidity)}</h6>);
            case NH3OD:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Odour)}</h6>);
            case SMOKE:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_SMOKE)}</h6>);            
            case VRI:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_VRI)}</h6>);    
            case MRI:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_MRI)}</h6>);
            case PFC:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_TodayPeopleCount)}</h6>);
            case WLIP:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_WL)} <br/> (%)</h6>);
            case AWL:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Available)} <br/> {t(IDS_Water)} ({t(IDS_Litre_Short)})</h6>);
            case VOL:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Tank)} <br/> {t(IDS_Capacity)} ({t(IDS_Litre_Short)})</h6>);
            case AWC:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_TotalUsed)} <br/> {t(IDS_UsedWater)} ({t(IDS_Litre_Short)})</h6>);
            case SLIP:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_SL)} (%)</h6>);
            case HCHO:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Formaldehyde)} <br/>(HCHO)</h6>);
            case O2:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Oxygen)}  <br/>(02)</h6>);
            case PM100:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_PM100)} <br/>(PM<sub>100</sub>)</h6>);
            case NO:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_NitricOxide)} <br/>(NO)</h6>);
            case CH3SH:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_MethylMercaptan)} <br/>(CH3SH)</h6>);
            case CL:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Chlorine)} <br/>(CL)</h6>);
            case NOISE:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Noise)} <br />(NOISE)</h6>);
            case LUX:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_LightIntensity)} <br />(LUX)</h6>);
            case UV:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_UV)} <br />(UV)</h6>);
            case RADON:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Radon)} <br />(RADON)</h6>);
            case AP:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_AirPressure)} <br />(AP)</h6>);
            case WS:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_WindSpeed)} <br />(WS)</h6>);
            case WD:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_WindDirection)} <br />(WD)</h6>);
            case DLV:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_DaylightVisibility)} <br />(DLV)</h6>);
            case RNFL:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Rainfall)} <br />(RNFL)</h6>);
            case O3:
                return (<h6 className="VcAddNameOfParameter">{t(IDS_Ozone)} <br />(O<sub>3</sub>)</h6>);
    
            default:
                console.error(`Unable to get ParamName. Unknown Param Type: ${inParamNameType}`);
                return (<h6></h6>); // Return empty tag
        }
    }

    const getParamLevelBasedOnRange = (inParamValue, inParamName) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 


        // Currently this function is applicable for NH3OD(Odour) sensor.
        let modifiedState = state;

        let singleParamAirQualityInfo = 
                modifiedState.stdAirQualityRanges.find( (arrElement) => arrElement["MeasuredParam"] == inParamName && arrElement["RangeValues"] != null );
        
        if(singleParamAirQualityInfo == null) {
            console.log(`Should not happen. Param [${inParamName}] was not found in stdAirQualityRange.`);
            return ""; // Better than returning a Wrong level.
        }

        let singleParamAirQualityRange = {};
        try {
            singleParamAirQualityRange = JSON.parse(singleParamAirQualityInfo.RangeValues);
        } catch {
            console.log(`Should not happen. StdAirQualityRange for Param [${inParamName}] is in invalid JSON format.`);
            return ""; // Better than returning a Wrong level.
        }
      
        let singleParamLowRange = singleParamAirQualityRange[LOW];
        let singleParamGoodLRange = singleParamAirQualityRange[GOOD_L];
        let singleParamGoodURange = singleParamAirQualityRange[GOOD_U];
        let singleParamSatisfactoryLRange = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamSatisfactoryURange = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamModerateLRange = singleParamAirQualityRange[MODERATE_L];
        let singleParamModerateURange = singleParamAirQualityRange[MODERATE_U];
        let singleParamPoorLRange = singleParamAirQualityRange[POOR_L];
        let singleParamPoorURange = singleParamAirQualityRange[POOR_U];
        let singleParamVPoorLRange = singleParamAirQualityRange[V_POOR_L];
        let singleParamVPoorURange = singleParamAirQualityRange[V_POOR_U];
        let singleParamHighSevereLRange = singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamHighSevereURange = singleParamAirQualityRange[HIGH_SEVERE_U];
        let singleParamMaxRange = singleParamAirQualityRange[MAX];

        if( (singleParamGoodLRange == null && inParamValue <= singleParamGoodURange) || 
             (singleParamGoodURange == null && inParamValue > singleParamGoodLRange) ||
              (inParamValue > singleParamGoodLRange && inParamValue <= singleParamGoodURange )
          ) {

            // console.log(inParamValue, singleParamGoodLRange, singleParamGoodURange);
            // return "Level 1";
            return t(IDS_AlertLevel1);

        } else if( (singleParamSatisfactoryLRange == null && inParamValue <= singleParamSatisfactoryURange) ||
                (singleParamSatisfactoryURange == null && inParamValue > singleParamSatisfactoryLRange) ||
                (inParamValue > singleParamSatisfactoryLRange && inParamValue <= singleParamSatisfactoryURange )
         ) {

            return t(IDS_AlertLevel2);

        } else if( (singleParamModerateLRange == null && inParamValue <= singleParamModerateURange) ||
                (singleParamModerateURange == null && inParamValue > singleParamModerateLRange) ||
                (inParamValue > singleParamModerateLRange && inParamValue <= singleParamModerateURange )
         ) {
            // console.log(inParamValue, singleParamModerateLRange, singleParamModerateURange);
            return t(IDS_AlertLevel3);

        } else if( (singleParamPoorLRange == null && inParamValue <= singleParamPoorURange) ||
                    (singleParamPoorURange == null && inParamValue > singleParamPoorLRange) ||
                    (inParamValue > singleParamPoorLRange && inParamValue <= singleParamPoorURange )
                 ) {

            // console.log(inParamValue, singleParamPoorLRange, singleParamPoorURange);

            return t(IDS_AlertLevel4);
        } else if( (singleParamVPoorLRange == null && inParamValue <= singleParamVPoorURange) ||
                     (singleParamVPoorURange == null && inParamValue > singleParamVPoorLRange) ||
                     (inParamValue > singleParamVPoorLRange && inParamValue <= singleParamVPoorURange )
                 ) {
            // console.log(inParamValue, singleParamVPoorLRange, singleParamVPoorURange);

            return t(IDS_AlertLevel5);
        } else if( (singleParamHighSevereLRange == null && inParamValue <= singleParamHighSevereURange) ||
                     (singleParamHighSevereURange == null && inParamValue > singleParamHighSevereLRange) ||
                     (inParamValue > singleParamHighSevereLRange && inParamValue <= singleParamHighSevereURange )
                 ) {
            // console.log(inParamValue, singleParamHighSevereLRange, singleParamHighSevereURange);

            return t(IDS_AlertLevel6);
        } else {
            return ""
        }
    }

    const getValueTextForAlertBasedParam = (selectedModelID, inParamValue, inParamName, DeviceID) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        // Currently this function is applicable for SMOKE/LPG sensor.
        let modifiedState = state;
        let colourForAlertBasedParamValueText = getParamValueTextStyleBasedOnRange(selectedModelID, inParamName, inParamValue);

        let strAlertDetectedDtTm = null;
        let strAlertDatectedDateTime = null;
        let bAlertDetectedForSpecifiedParam = false;

        for(let i = 0; i < modifiedState.DevicesImportantAlertInfo.length; i++) {

            if( modifiedState.DevicesImportantAlertInfo[i].DeviceID.toLowerCase() === DeviceID.toLowerCase()
                && modifiedState.DevicesImportantAlertInfo[i].MeasuredParam === inParamName
            ) {
                bAlertDetectedForSpecifiedParam = true;
                strAlertDetectedDtTm = modifiedState.DevicesImportantAlertInfo[i].CreatedByDeviceTime == null ? null : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(modifiedState.DevicesImportantAlertInfo[i].CreatedByDeviceTime);
                strAlertDatectedDateTime = strAlertDetectedDtTm == null ? null : getCustomizedTodaysDate(strAlertDetectedDtTm);
                break;
            }
        }

        if(bAlertDetectedForSpecifiedParam || 
            (colourForAlertBasedParamValueText !== COLOR_FOR_MISSING_OR_OUT_OF_RANGE  && colourForAlertBasedParamValueText !== COLOR_FOR_GOOD)
        ) {

            switch(inParamName) {
                case LPG:
                    return <p>{t(IDS_GasLeakageDetected)} <br/>{strAlertDatectedDateTime}</p>; // "Gas Leakage Detected"
                case SMOKE:
                    return <p>{t(IDS_SmokeDetected)} <br/> {strAlertDatectedDateTime}</p>; // "Smoke Detected"
                default:
                    console.error(`Unable to get Value Text for specified ParamName. Unknown Param Type: ${inParamName}`);
                    return <p></p>; // Return empty string
            }
        } else {

            switch(inParamName) {
                case LPG:
                    return t(IDS_NoGasLeakage); // "No Gas Leakage"
                case SMOKE:
                    return t(IDS_NoSmokeDetected); // "No Smoke Detected"
                default:
                    console.error(`Unable to get Value Text for specified ParamName. Unknown Param Type: ${inParamName}`);
                    return <p></p>; // Return empty string
            }
        }
        console.error(`Should not reach here. Value text not obtained for specified ParamName [${inParamName}] and DeviceID [${DeviceID}].`);
        return <p></p>; // Should not reach here

    }

    const getParamValueTextStyleBasedOnRange = (selectedModelID, inParamName, inParamValue)  => {

        let modifiedState = state;

        let AllParamHighLowValues = {};
        let singleParamHighLowValues = {};
        let singleParamAirQualityRange = {};

        for(let i = 0; i < modifiedState.relevantModelInfo.length; i++) {
            if(selectedModelID === modifiedState.relevantModelInfo[i]["ModelID"] ) {

                AllParamHighLowValues = JSON.parse(modifiedState.relevantModelInfo[i].MeasuredParams);
                // console.log(AllParamHighLowValues);
                break;
            }
        }

        singleParamHighLowValues = AllParamHighLowValues[inParamName];
        let bIsParamNonCalibrated = ( singleParamHighLowValues != null
                                        && singleParamHighLowValues[NO_CALIB] != null
                                    ) ? singleParamHighLowValues[NO_CALIB] : false;


        let singleParamAirQualityInfo = 
                modifiedState.stdAirQualityRanges.find( (arrElement) => arrElement["MeasuredParam"] === inParamName && arrElement["RangeValues"] != null );

        if(singleParamAirQualityInfo == null) {
            console.log(`Should not happen. Param [${inParamName}] was not found in stdAirQualityRange.`);
            // return "black"; // Better than returning a Wrong color.
            return COLOR_FOR_MISSING_OR_OUT_OF_RANGE;
        }

        try {
            singleParamAirQualityRange = JSON.parse(singleParamAirQualityInfo.RangeValues);
        } catch {
            console.log(`Should not happen. StdAirQualityRange for Param [${inParamName}] is in invalid JSON format.`);
            // return "black"; // Better than returning a Wrong color.
            return COLOR_FOR_MISSING_OR_OUT_OF_RANGE;
        }


        let singleParamLowRange = singleParamAirQualityRange[LOW];
        let singleParamLowSevereLRange = singleParamAirQualityRange[LOW_SEVERE_L];
        let singleParamLowSevereURange = singleParamAirQualityRange[LOW_SEVERE_U];
        let singleParamAirQualityRangeLowVPoorL = singleParamAirQualityRange[LOW_V_POOR_L];
        let singleParamAirQualityRangeLowVPoorU = singleParamAirQualityRange[LOW_V_POOR_U];
        let singleParamGoodLRange = singleParamAirQualityRange[GOOD_L];
        let singleParamGoodURange = singleParamAirQualityRange[GOOD_U];
        let singleParamSatisfactoryLRange = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamSatisfactoryURange = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamModerateLRange = singleParamAirQualityRange[MODERATE_L];
        let singleParamModerateURange = singleParamAirQualityRange[MODERATE_U];
        let singleParamPoorLRange = singleParamAirQualityRange[POOR_L];
        let singleParamPoorURange = singleParamAirQualityRange[POOR_U];
        let singleParamVPoorLRange = singleParamAirQualityRange[V_POOR_L];
        let singleParamVPoorURange = singleParamAirQualityRange[V_POOR_U];
        let singleParamHighSevereLRange = singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamHighSevereURange = singleParamAirQualityRange[HIGH_SEVERE_U];
        let singleParamMaxRange = singleParamAirQualityRange[MAX];

        // Sensor value is Less/Greater than RangeMin/RangeMax return "Black" Color.
        // if( bIsParamNonCalibrated == false && (inParamValue < singleParamHighLowValues["RangeMin"] || inParamValue > singleParamHighLowValues["RangeMax"]) ) {
        //     // return "black";
        //     return COLOR_FOR_MISSING_OR_OUT_OF_RANGE
        // } 

        // if(inParamName == SMOKE || inParamName == LPG){
        //     if( (singleParamGoodLRange == null && inParamValue <= singleParamGoodURange) || 
        //             (singleParamGoodURange == null && inParamValue > singleParamGoodLRange) ||
        //             (inParamValue > singleParamGoodLRange && inParamValue <= singleParamGoodURange)
        //             ) {

        //         console.log("Good for alert based param");
        //         // console.log(inParamValue, singleParamGoodLRange, singleParamGoodURange);
        //         // return "#00b050"; // Hex code for "Green".
        //         return COLOR_FOR_SEVERE;
        //     } else if( (singleParamHighSevereLRange == null && inParamValue <= singleParamHighSevereURange) ||
        //                 (singleParamHighSevereURange == null && inParamValue > singleParamHighSevereLRange) ||
        //                 (inParamValue > singleParamHighSevereLRange && inParamValue <= singleParamHighSevereURange)
        //             ) {

        //         console.log("High severe for alert based param");
        //         // console.log(inParamValue, singleParamHighSevereLRange, singleParamHighSevereURange);
        //         // return "darkred";
        //         return COLOR_FOR_SEVERE;

        //     } 
        // }

        if( (singleParamLowSevereLRange == null && inParamValue <= singleParamLowSevereURange) ||
            (singleParamLowSevereURange == null && inParamValue > singleParamLowSevereLRange) ||
            (inParamValue > singleParamLowSevereLRange && inParamValue <= singleParamLowSevereURange)
            ) {

            // console.log(inParamValue, singleParamLowSevereLRange, singleParamLowSevereURange);
            // return "darkred";
            return COLOR_FOR_SEVERE;

        } else if( (singleParamGoodLRange == null && inParamValue <= singleParamGoodURange) || 
                    (singleParamGoodURange == null && inParamValue > singleParamGoodLRange) ||
                    (inParamValue > singleParamGoodLRange && inParamValue <= singleParamGoodURange)
                    ) {

            // console.log(inParamValue, singleParamGoodLRange, singleParamGoodURange);
            // return "#00b050"; // Hex code for "Green".
            return COLOR_FOR_GOOD;

        } else if( (singleParamAirQualityRangeLowVPoorL == null && inParamValue <= singleParamAirQualityRangeLowVPoorU) || 
                    (singleParamAirQualityRangeLowVPoorU == null && inParamValue > singleParamAirQualityRangeLowVPoorL) ||
                    (inParamValue > singleParamAirQualityRangeLowVPoorL && inParamValue <= singleParamAirQualityRangeLowVPoorU)
                    ) {
            return COLOR_FOR_LOW_V_POOR;

        }
         else if( (singleParamSatisfactoryLRange == null && inParamValue <= singleParamSatisfactoryURange) || 
                    (singleParamSatisfactoryURange == null && inParamValue > singleParamSatisfactoryLRange) ||
                    (inParamValue > singleParamSatisfactoryLRange && inParamValue <= singleParamSatisfactoryURange)
                    ) {

            return COLOR_FOR_SATISFACTORY;

        }else if( (singleParamModerateLRange == null && inParamValue <= singleParamModerateURange) ||
                    (singleParamModerateURange == null && inParamValue > singleParamModerateLRange) ||
                    (inParamValue > singleParamModerateLRange && inParamValue <= singleParamModerateURange)
                ) {

            // console.log(inParamValue, singleParamModerateLRange, singleParamModerateURange);
            // return "yellow";
            return COLOR_FOR_MODERATE;

        } else if( (singleParamPoorLRange == null && inParamValue <= singleParamPoorURange) ||
                    (singleParamPoorURange == null && inParamValue > singleParamPoorLRange) ||
                    (inParamValue > singleParamPoorLRange && inParamValue <= singleParamPoorURange)
                ) {
        
            // console.log(inParamValue, singleParamPoorLRange, singleParamPoorURange);        
            // return "orange";
            return COLOR_FOR_POOR;

        } else if( (singleParamVPoorLRange == null && inParamValue <= singleParamVPoorURange) ||
                    (singleParamVPoorURange == null && inParamValue > singleParamVPoorLRange) ||
                    (inParamValue > singleParamVPoorLRange && inParamValue <= singleParamVPoorURange)
                ) {

            // console.log(inParamValue, singleParamVPoorLRange, singleParamVPoorURange);
            // return "red";
            return COLOR_FOR_VERY_POOR;

        } else if( (singleParamHighSevereLRange == null && inParamValue <= singleParamHighSevereURange) ||
                    (singleParamHighSevereURange == null && inParamValue > singleParamHighSevereLRange) ||
                    (inParamValue > singleParamHighSevereLRange && inParamValue <= singleParamHighSevereURange)
                ) {

            // console.log(inParamValue, singleParamHighSevereLRange, singleParamHighSevereURange);
            // return "darkred";
            return COLOR_FOR_SEVERE;

        } else {

            // return "black";
            return COLOR_FOR_MISSING_OR_OUT_OF_RANGE;
        }
    }
    

    const renderColumnWidthBasedOnType = (inParamNameType) => {

        switch(inParamNameType) {
            case "DeviceName":
                return 325;
            case "LogTime":
                return 300;
            case AQI:
                return 150;
            case CAQI:
                return 200;
            case NO2:
                return 150;
            case O3:
                return 100;
            case SO2:
                return 150;
            case VOC:
                return 190;
            case CO:
                return 150;
            case NH3:
                return 150;
            case CO2:
                return 150;
            case H2S:
                return 150;
            case CH4:
                return 110;
            case LPG:
                return 240;
            case PM1:
                return 100;
            case PM25:
                return 100;
            case PM10:
                return 100;
            case TEMP:
                return 135;
            case HUM:
                return 110;
            case NH3OD:
                return 110;
            case SMOKE:
                return 240;
            case VRI:
                return 160;
            case MRI:
                return 160;    
            case PFC:
                return 360;
            case WLIP:
                return 129;
            case AWL:
                return 112;
            case VOL:
                return 109;
            case AWC:
                return 133;
            case SLIP:
                return 355;
            case HCHO:
                return 109;
            case O2:
                return 133;
            case CL:
                return 133;
            case NOISE:
                return 133;
            case LUX:
                return 133;
            case UV:
                return 133;
            case AP:
                return 133;
            case WS:
                return 133;
            case WD:
                return 133;
            case DLV:
                return 133;
            case RNFL:
                return 133;
            case RNFL:
                return 133;
            case PM100:
                return 133;
            default:
                console.error(`Unable to get Column Width. Unknown Param Type: ${inParamNameType}`);
                return 150; // Return empty tag
        }
    }

    const getRangeValuesBasedOnAppliedColumnFilter = (LevelValue, inParamName) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        
        let modifiedState = state;     
        let minval = null;
        let maxval = null;
        let RangeMin = null;
        let RangeMax = null;

        let AllParamHighLowValues;

        for(let i = 0; i < modifiedState.relevantModelInfo.length; i++) {
            let selectedModelID = modifiedState.selectedModelID;
            if(selectedModelID === modifiedState.relevantModelInfo[i]["ModelID"] ) {

                AllParamHighLowValues = JSON.parse(modifiedState.relevantModelInfo[i].MeasuredParams);
                // console.log(AllParamHighLowValues);
                break;
            }
        }

        let singleParamHighLowValues = AllParamHighLowValues[inParamName];

        RangeMin = singleParamHighLowValues["RangeMin"];
        RangeMax = singleParamHighLowValues["RangeMax"];

        if(inParamName != "NH3OD") {
            // As their is no need of minVal And maxVal for measuredParam other than NH3OD.
            return {minval: minval, maxval: maxval, RangeMin:RangeMin, RangeMax:RangeMax};
        }

        let singleParamAirQualityInfo = 
                modifiedState.stdAirQualityRanges.find( (arrElement) => arrElement["MeasuredParam"] == inParamName && arrElement["RangeValues"] != null );

        let singleParamAirQualityRange = {};
        try {
            singleParamAirQualityRange = JSON.parse(singleParamAirQualityInfo.RangeValues);
        } catch {
            console.log(`Should not happen. StdAirQualityRange for Param [${inParamName}] is in invalid JSON format.`);
            return {minval: minval, maxval: maxval, RangeMin:RangeMin, RangeMax:RangeMax};
        }
       
        let singleParamLowRange = singleParamAirQualityRange[LOW];
        let singleParamGoodLRange = singleParamAirQualityRange[GOOD_L];
        let singleParamGoodURange = singleParamAirQualityRange[GOOD_U];
        let singleParamSatisfactoryLRange = singleParamAirQualityRange[SATISFACTORY_L];
        let singleParamSatisfactoryURange = singleParamAirQualityRange[SATISFACTORY_U];
        let singleParamModerateLRange = singleParamAirQualityRange[MODERATE_L];
        let singleParamModerateURange = singleParamAirQualityRange[MODERATE_U];
        let singleParamPoorLRange = singleParamAirQualityRange[POOR_L];
        let singleParamPoorURange = singleParamAirQualityRange[POOR_U];
        let singleParamVPoorLRange = singleParamAirQualityRange[V_POOR_L];
        let singleParamVPoorURange = singleParamAirQualityRange[V_POOR_U];
        let singleParamHighSevereLRange = singleParamAirQualityRange[HIGH_SEVERE_L];
        let singleParamHighSevereURange = singleParamAirQualityRange[HIGH_SEVERE_U];
        let singleParamMaxRange = singleParamAirQualityRange[MAX];

        if(LevelValue == t(IDS_AlertLevel1).toLowerCase() || LevelValue == '1'.toLowerCase()) {

            // console.log(inParamValue, singleParamGoodLRange, singleParamGoodURange);
            minval= singleParamLowRange;
            maxval= singleParamGoodURange;

        } else if( LevelValue == t(IDS_AlertLevel2).toLowerCase() || LevelValue == '2'.toLowerCase()) {

            minval= singleParamSatisfactoryLRange;
            maxval= singleParamSatisfactoryURange;

        } else if( LevelValue == t(IDS_AlertLevel3).toLowerCase() || LevelValue == '3'.toLowerCase()) {

            // console.log(singleParamModerateLRange, singleParamModerateURange);
            minval= singleParamModerateLRange;
            maxval= singleParamModerateURange;

        } else if( LevelValue == t(IDS_AlertLevel4).toLowerCase() || LevelValue == '4'.toLowerCase()) {

            // console.log(inParamValue, singleParamPoorLRange, singleParamPoorURange);
            minval= singleParamPoorLRange;
            maxval= singleParamPoorURange;

        } else if( LevelValue == t(IDS_AlertLevel5).toLowerCase() || LevelValue == '5'.toLowerCase() ) {
            // console.log(inParamValue, singleParamVPoorLRange, singleParamVPoorURange);
            minval= singleParamVPoorLRange;
            maxval= singleParamVPoorURange;

        } else if( LevelValue == t(IDS_AlertLevel6).toLowerCase() || LevelValue == '6'.toLowerCase()) {
            // console.log(inParamValue, singleParamHighSevereLRange, singleParamHighSevereURange);
            minval= singleParamHighSevereLRange;
            maxval= singleParamMaxRange;
        } else {
            minval = null;
            maxval = null;
        }
        return {minval: minval, maxval: maxval, RangeMin:RangeMin, RangeMax:RangeMax};
    }


    const deviceModelList = () => {
        const options = state.relevantModelInfo && state.relevantModelInfo.map(singleRelevantModel => ({
            label: <span>{singleRelevantModel.ModelName}</span>,
            value: singleRelevantModel.ModelName
        }));
            
        return options;
    };

    const getDirectionName = (degrees, shortform = null) => {
        let t = context.t;   

        if(shortform != null) {
            if (degrees >= 337.5 || degrees < 22.5) {
                return "N";
            } else if (degrees >= 22.5 && degrees < 67.5) {
                return "NE";
            } else if (degrees >= 67.5 && degrees < 112.5) {
                return "E"
            } else if (degrees >= 112.5 && degrees < 157.5) {
                return "SE"
            } else if (degrees >= 157.5 && degrees < 202.5) {
                return "S"
            } else if (degrees >= 202.5 && degrees < 247.5) {
                return "SW"
            } else if (degrees >= 247.5 && degrees < 292.5) {
                return "W"
            } else if (degrees >= 292.5 && degrees < 337.5) {
                return "NW"
            } else {
                return "";
            }
        }
       
        
        if (degrees >= 337.5 || degrees < 22.5) {
            return t(IDS_North);
        } else if (degrees >= 22.5 && degrees < 67.5) {
            return t(IDS_Northeast);
        } else if (degrees >= 67.5 && degrees < 112.5) {
            return t(IDS_East)
        } else if (degrees >= 112.5 && degrees < 157.5) {
            return t(IDS_Southeast)
        } else if (degrees >= 157.5 && degrees < 202.5) {
            return t(IDS_South);
        } else if (degrees >= 202.5 && degrees < 247.5) {
            return t(IDS_Southwest)
        } else if (degrees >= 247.5 && degrees < 292.5) {
            return t(IDS_West)
        } else if (degrees >= 292.5 && degrees < 337.5) {
            return t(IDS_Northwest)
        } else {
            return "";
        }

        
    }
    
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let HasSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.hasDevc;
    let IsSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.isDevc;
    let selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;
    // let isContainsChildNode = appRelevantDataContextValue.selectedNodeInfo.containsChildNode;
        
    return (
        ((state.objPrivilege != null &&
            state.objPrivilege.hasOwnProperty(PVG_GENERAL_USER) && 
            state.objPrivilege[PVG_GENERAL_USER] == true) 
            // || 
            // state.retrievedAllDeviceData == undefined 
        ) 
        ?
        <div className="sddSingleParam">
            <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 fs-5 d-flex justify-content-center align-items-center">
                {t(IDS_GeneralUser)}
                <div style={{margin:"0.5rem"}}>
                    <VcSetupDevice/>
                </div>               
            </div>
        </div>
        :
        <div className=''>
            <div 
            className="w-100 flex-center my-3 graphTypeDropdwon relative" 
            // style = {{position: "relative", display: "flex", justifyContent: "center", marginBottom:'1rem', marginTop:'1rem'}}
            >
                
                <Select
                    value={state.selectedModelName}
                    onChange={onChangeModelName} 
                    style={{ width: '250px' }}
                    className='mySelector'
                    options={deviceModelList()} 
                />
                <button className='refreshButtonStyle'
                    onClick={onRefreshBtnClicked} 
                    style={{position: "absolute", right:"20px"}}
                    title={t(IDS_RefreshData)}
                >
                    <i className="fa fa-refresh flex-center" id="addRefreshBtn"
                    style={{ paddingLeft: "0", fontSize: "20px"}} 
                    aria-hidden="true"></i>
                </button>
            </div>
            <div id="allDeviceDataTable" className=" ReactTableStyle rounded flex-center"  style={{width:"auto"}} >
                {
                    state.dataFetchBoolean ? 
                        state.reactTableDataFlag == "Data" ?
                            <ReactTable columns={state.columns} 
                                data={state.data} 
                                getCellProps={cellInfo => ({
                                    style : cellInfo.column.id != "DeviceName" && cellInfo.column.id != "LogTime" ? getCellStyle(cellInfo) : {}
                                })}
                                // as React Table is outside the main function, state varaibles cant be accessed there 
                                // so passing all the state variables on which react table is dependent through passedStateVariable
                                passedStateVariable = {state.loading}
                                onViewDeviceData = {onViewDeviceData}
                            />
                            : null
                        : null
                }
            </div>
        </div>
    );
}

export default VcAllDeviceData;
