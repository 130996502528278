import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios';
import { getAPIHostURL, getMappedToiletDataRefreshTime, getMappedToiletTrackingDataRefreshTime } from '../../ClientConfig';
import { useJsApiLoader, GoogleMap, InfoWindow, Marker} from '@react-google-maps/api';
import Sidebar from "react-sidebar";
// import {QrReader} from '@otterscan/react-qr-reader'
import {QrScanner} from '@yudiel/react-qr-scanner';
import { FaGrinBeam, FaQrcode, FaSmile } from 'react-icons/fa'
import '../CSS/VcMaps.css';
import DraggerIcon from '../IMAGES/DraggerIcon.png';
import greenMarker from '../IMAGES/greenmarker.png';
import yellowMarker from '../IMAGES/yellowmarker.png';
import grayMarker from '../IMAGES/lightgreymarker.png';
import orangeMarker from '../IMAGES/orangemarker.png';
import redMarker from '../IMAGES/redmarker.png';
import darkRedMarker from '../IMAGES/darkRedMarker.png';
import greenWithGreyMarker from '../IMAGES/greenmarker_g.png';
import redWithGreyMarker from '../IMAGES/redmarker_g.png';
import orangeWithGreyMarker from '../IMAGES/orangemarker_g.png';
import grayWithGreyMarker from '../IMAGES/lightgreymarker_g.png';
import yellowWithGreyMarker from '../IMAGES/yellowmarker_g.png';
import darkRedWithGreyMarker from '../IMAGES/darkRedMarker_g.png';
import yellowGreenWithGreyMarker from '../IMAGES/satisfactory_g.png';
import yellowGreenMarker from '../IMAGES/satisfactory.png';
import purplemarker from '../IMAGES/purplemarker.png';
import currentLocation from '../IMAGES/currentLocation.png';
import { NH3OD, LOW, LOW_SEVERE_L, LOW_SEVERE_U, GOOD_L, GOOD_U, MODERATE_L, MODERATE_U, POOR_L, POOR_U, V_POOR_L, V_POOR_U,HIGH_SEVERE_L, HIGH_SEVERE_U, MAX, NH3, RADIUS_KM, IS_MILES, ALL_TOILET, WHEEL, E_TOILET, BRICK_N_MORTAR_TOILET, CONTAINER_TOILET, SHE_TOILET, DEFAULT_RATING_LEVEL_STARS, DEFAULT_RATING_LEVEL_SIGNAL, GANDHVEDH_TOILETS, HYGIENIC_TOILETS, SATISFACTORY_L, SATISFACTORY_U} from '../../VcConstants';
import { FaSadCry, FaSmileBeam, FaMeh, FaFrownOpen, FaSadTear, FaDizzy, FaDirections, FaCrosshairs, FaCircle } from 'react-icons/fa';
import VcSaveLocationInfo from '../UnusedFilesAndPackages/VcSaveLocationInfo';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { calculateDistancebetweenTwoPoints, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday, 
        convertUTCDateToStrYYYYMMDDHH24MMSS, convertLocalDateToStrYYYYMMDDHH24MMSS} from '../../vtUtil';
import {IDS_MapStandard, IDS_MapToiletNotMaped, IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_BarelySensed, IDS_Pungent, IDS_WeakOdour, IDS_EasilyRecognized, IDS_StrongOdour,IDS_AlertLevel1, IDS_AlertLevel2, IDS_AlertLevel3, IDS_AlertLevel4, IDS_AlertLevel5, IDS_MapModelInfo, IDS_MapDeviceDataInfo, IDS_MapMulDevcTitle, IDS_TodaySearch, IDS_AUSrvrIssueReqParamsNotSentForSubsInfo, IDS_Odour, IDS_Cleanliness, IDS_BeTheFirstToRateThisToilet, IDS_RateThisToilet, IDS_TotalNoOfRating, IDS_GoodRating,IDS_Bad,IDS_Normal, IDS_Worse, IDS_OdourRatingSummary, IDS_CleanlinessRatingSummary, IDS_SeeMore, IDS_TodayPeopleCount, IDS_RefreshCountAndRating, IDS_RefreshData, IDS_SearchData, IDS_EnterSearchChar, IDS_NoSearchResultFound, IDS_TrackingToilet, IDS_StopTracking, IDS_Search, IDS_SearchToilet, IDS_SearchByToiletName, IDS_SearchByDeviceID, IDS_EnterToiletNameToSearch, IDS_EnterDeviceIdOrQrCode, IDS_SelcetToiletNameToLocate, IDS_SelcetDeviceIDToLocate, IDS_NoLocationPermissionOn, IDS_SatisfactoryOdour} from '../../VcLanguage';
import { AppRelevantDataContext} from '../../AppContext';
import VcNavBar from './VcNavbar';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import VcRateToilet from './VcRateToilet';
import StarRatings from 'react-star-ratings';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Progress } from 'reactstrap';
import useInterval from './useInterval';
import { useNavigate } from 'react-router-dom';
          

function VcMapsTrackDevice (props) {

    const context = useContext(AppRelevantDataContext);
    const navigate = useNavigate();
    const [state, setState] = useState({
        relevantModelInfo: [],
        stdAirQualityRange: [],
        deviceDataArr: [],
        errors:{
            others:'',
            noLocPermissonEnabled: '',
            searchDeviceStringErr: ''
        },
        markerDataArr:[
          // { 
            // DeviceID: "DA008", DeviceName: "Devc 008", ModelID: 4, Lat: "19.17471900", Lon: "72.95635600", EntityLocnID: "6",        // EntityType: "CBT-BrickAndMortar", name: "SBM Toilet", Address1: "552-B, Dr Rajendra Prasad Rd", Address2: "Siddharth Nagar", City: "Mulund", State: "Maharashtra", District: "Mumbai", PinCode: "400081", Level: "(Level 2)", LevelType: "Weak Odour",    icon: "yellowMarker", textStyle: "#ffff00", EmojiIcon: "<FaSmileBeam/>", showingInfoWindow: false
          // },
        // { 
            // DeviceID: "DA008", DeviceName: "Devc 008", ModelID: 4, Lat: "19.17471900", Lon: "72.95635600", EntityLocnID: "6",            EntityType: "CBT-BrickAndMortar", name: "SBM Toilet", Address1: "552-B, Dr Rajendra Prasad Rd", Address2: "Siddharth         Nagar",   City: "Mulund", State: "Maharashtra", District: "Mumbai", PinCode: "400081", Level: "(Level 2)", LevelType:        "Weak Odour", icon: "yellowMarker", textStyle: "#ffff00", EmojiIcon: "<FaSmileBeam/>", showingInfoWindow: false
          // }
        ],
        mappedToiletInfoArr: [],
        LocationInfoArr:[],
        DeviceIDArr: [], // contains DeviceIDs which are currently visible on current VP.
        showingInfoWindow: false,
        activeMarker: {},
        lastSeachedMarkerReference: {},
        getCleanToilet: false,
        getGandhvedhToilet: false,
        visibleSearchOption: false,
        searchDeviceString: "",
        searchToiletNameString: "",
        searchedDevicesArrList: [],
        DevcIDQRCodeFlag: false,
        activeTab: '1',
        toiletToTrack: "",
        searchedToiletEntityLocID: "",

        selectedPlace: {},
        sidebarOpen: false,
        CurrentLocationLat:0,
        CurrentLocationLon:0 ,
        backdrop: 'static',
        modal: false,     
        DraggerMarkerLat:0,
        DraggerMarkerLon:0,
        DestinationMarkerLat:0,
        DestinationMarkerLon:0,
        ChangedPositionLat:0,
        ChangedPositionLon:0,
        isPositionChanged: false,
        PrevPointLocnLat: 0,
        PrevPointLocnLon: 0,
        languageToViewIn:'',       
        toiletTypeArr:[ALL_TOILET],  
        showRateToiletModal: false,
        SelectedEntityLocnID: "",
        SelectedToiletName: "",
        cleanlinessAvgStarRating: 0,
        odourAvgStarRating: 0,
        cleanlinessTotalNumberOfReviews: 0,
        odourTotalNumberOfReviews: 0,
        cleanlinessTotalStarRating: 0,
        odourTotalStarRating: 0,
        ratingDataArr: [],
        ratingSummaryOpen: false,
        ratingLevel: "",
        totalTodaysPeopleCount: null,
        onRefreshGetLatestToiletDataOfMap: false,
        // interval: null,
        // ratingInterval: null,
        trackToiletInterval: null,
        timeoutToRemoveBounceAnimation: null,
        _map: null,
        marker: null
    });

    // const containerStyle = props.invokedFrom == "VcMapPg" ?
    //                       {
    //                         width: '100%',
    //                         height: '90vh'
    //                       }
    //                       :
    //                       {
    //                         width: '100%',
    //                         height: '100vh'
    //                       };

    const { isLoaded } = useJsApiLoader({googleMapsApiKey: "AIzaSyAOmgOiNLFAftNFlBigy_ud7vN_70LhdNU"});
    const [map, setMap] = useState(null);

    const toggle = (e) => {
      let modifiedState = state;
      modifiedState.SelectedToiletName = modifiedState.selectedPlace["name"];
      modifiedState.SelectedEntityLocnID = modifiedState.selectedPlace["EntityLocnID"];
      modifiedState.modal = !modifiedState.modal;
      setState({...modifiedState});
    }

    const toggleSearchData = (e) => {
      let modifiedState = state;
      modifiedState.visibleSearchOption = !modifiedState.visibleSearchOption;
      modifiedState.errors.searchDeviceStringErr = '';
      modifiedState.searchedDevicesArrList = [];
      modifiedState.activeTab = '1';
      modifiedState.DevcIDQRCodeFlag = false;
      modifiedState.searchDeviceString = "";
      modifiedState.searchToiletNameString = "";
      setState({...modifiedState});
    }

    const onClickRateToilet = (e) => {
      let modifiedState = state;
      modifiedState.showRateToiletModal = !modifiedState.showRateToiletModal;
      setState({...modifiedState});

    }

    const ratingSummaryToggle = () => {
      let modifiedState = state;
      modifiedState.ratingSummaryOpen = !modifiedState.ratingSummaryOpen;
      setState({...modifiedState});
    }

    // Written a custom hook (useInterval) to make sure updated state is always used for setInterval.
    useInterval(() => {
      getMappedDeviceData();
    }, getMappedToiletDataRefreshTime())

    useInterval(() => {
      getRatingDataPerReviewee()

    }, getMappedToiletDataRefreshTime())

    

    useEffect(() => {
      let appRelevantDataContextValue = context;

      // To get Info from LS which have been stored in LS 
      // (for eg. Language and Last Loggedin UserID).
      appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage(true);

      // Get Air Quality Ranges from server and saved same a state.
      getAirQualityRanges();

      // To get Toilets present within a Radius_km distance.
      getToiletListBasedOnCurrentLocation();



      return () => {
        //Only these interval's are needed to be cleared as MappedDeviceData and ratingInterval are using custom hook.
        clearInterval(state.trackToiletInterval);
        clearTimeout(state.timeoutToRemoveBounceAnimation);      
      }

    }, []);

    useEffect(() => {
      let currentState = state;
      let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext

      if( appRelevantDataContextValue == null ||
          ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
          appRelevantDataContextValue.loggedInUserInfo == null ||
          ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
          appRelevantDataContextValue.loggedInUserInfo.userID == null ||
          appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
          ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
          appRelevantDataContextValue.selectedNodeInfo == null ||
          ("nodeID" in appRelevantDataContextValue.selectedNodeInfo) == false ||
          appRelevantDataContextValue.selectedNodeInfo.nodeID == null ||
          appRelevantDataContextValue.selectedNodeInfo.nodeID.length <= 0
      ) {
          console.error("VcMaps:componentDidUpdate - Should not happen. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
          return; // Do not process further
      }

      if(currentState.languageToViewIn != appRelevantDataContextValue.language.languageToViewIn) {
          // The selected tree node has changed. Update the state based on the new selected node.
          // getAlertLogData(appRelevantDataContextValue);
          getMappedDeviceData();
      } 

    }, [state.languageToViewIn, context]);

    const getToiletListBasedOnCurrentLocation = () => {   

      let modifiedState = state;

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t;

      navigator.permissions.query({name:'geolocation'}).then(function(result) {
        // Will return ['granted', 'prompt', 'denied']

        if(result.state == "denied") {
          alert(t(IDS_NoLocationPermissionOn))
          modifiedState.errors.noLocPermissonEnabled = t(IDS_NoLocationPermissionOn);
          setState({...modifiedState});
          return;
        } 

        navigator.geolocation.getCurrentPosition((position) => {

          // intially initalize all location lat lon with current location lat lon point.
          modifiedState.CurrentLocationLat = position.coords.latitude;
          modifiedState.CurrentLocationLon = position.coords.longitude;

          modifiedState.ChangedPositionLat = position.coords.latitude;
          modifiedState.ChangedPositionLon = position.coords.longitude;

          modifiedState.PrevPointLocnLat = position.coords.latitude;
          modifiedState.PrevPointLocnLon = position.coords.longitude;

          modifiedState.errors.noLocPermissonEnabled = "";

          // setState({...modifiedState});
          // modifiedState.DraggerMarkerLat = position.coords.latitude;
          // modifiedState.DraggerMarkerLon = position.coords.longitude;
          
          getListOfToiletsAndAplicableDevices(modifiedState);
          
        
        }, 

          function errorCallback(error) {
            if(error.PERMISSION_DENIED){
                alert(t(IDS_NoLocationPermissionOn))
                if(context.loggedInUserInfo != null){
                  navigate('/device/dashboard')
                }else{
                  navigate('/')
                }
              };
          },
          {
            timeout:5000
          }

        );
      }).catch( error => {
        console.log("Network error:", error);
      });
    }

    const getRelevantModelInfo = (modifiedState) => {

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t;

      modifiedState.relevantModelInfo = [];
      modifiedState.languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

      if(modifiedState.DeviceIDArr == null || modifiedState.DeviceIDArr.length <= 0) {
        // modifiedState.errors.others = 'Toilets are not mapped for your current viewing Location.';
        setState({...modifiedState});
        return;
      }

      let DeviceIDArr = modifiedState.DeviceIDArr;

      let jsonBody = {
          DeviceIDArr: DeviceIDArr
      }

      // To get RelevantModelInfo. 
      axios.post( `${getAPIHostURL()}/wclient/getRelevantDeviceModelInfo`, jsonBody)
      .then(response => {
  
        if(response.data.code == 'SUCCESS') {

          let receivedModelIfo = response.data.retrievedModelInfo;

          if(receivedModelIfo == null || receivedModelIfo.length <= 0 ) {
              modifiedState.errors.others = t(IDS_MapModelInfo);
              setState({...modifiedState});
            } else {
              modifiedState.relevantModelInfo = receivedModelIfo;

              // In order to get all mapped toilet Devices data
              getMappedDeviceData(modifiedState);
            }
        } else {
            if(response.data.code == 'SQL_ERROR') {
              // Tell the user that Server is experiencing errors
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
            // Display the specific error message for login failure.
            setState({...modifiedState});
          }
        })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        if (axios.isCancel(error)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          // Tell the user that there are network issues
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          setState({...modifiedState});
        }
      }); 
    }

    const getAirQualityRanges = () => {

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t;

      let modifiedState = state;
      modifiedState.stdAirQualityRange = [];
      modifiedState.languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

      // To get RelevantModelInfo. 
      axios.post( `${getAPIHostURL()}/wclient/getAirQualityRange`)
      .then(response => {
  
        if(response.data.code == 'SUCCESS') {

          let receivedAirQualityRanges = response.data.retrievedAirQualityRange;

          if( receivedAirQualityRanges == null || receivedAirQualityRanges.length <= 0 ) {
              modifiedState.errors.others = t(IDS_MapStandard);
          } else {
              modifiedState.stdAirQualityRange = receivedAirQualityRanges;
          }
          
        } else {
            if(response.data.code == 'SQL_ERROR') {
              // Tell the user that Server is experiencing errors
              modifiedState.errors.others = t(IDS_LoginServerIssue);

            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);

          }
          // Display the specific error message for login failure
        }
        setState({...modifiedState});
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        if (axios.isCancel(error)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          // Tell the user that there are network issues
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          setState({...modifiedState});
        }
      }); 
    }

    const getListOfToiletsAndAplicableDevices = (inModifiedState = null) => {

      let modifiedState;
      // If passed by the caller, update State with All new values (will happen in DidMount
      // and whenever new data is retrieved during interval)
      if(inModifiedState != null) {
        modifiedState = inModifiedState; 
      } else {
        modifiedState = state;
      }

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t;

      modifiedState.LocationInfoArr = [];
      modifiedState.mappedToiletInfoArr = [];
      modifiedState.DeviceIDArr = [];

      let inCurrentLocationLat = modifiedState.CurrentLocationLat;
      let inCurrentLocationLon = modifiedState.CurrentLocationLon;

      modifiedState.languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

      // If a current Viewing Locn is greater than users's curr locn then bring the all Toilets whhich are present 
      // whithin a RADIUS_KM of a current viewing locn.
      if(modifiedState.isPositionChanged === true) {

        inCurrentLocationLat = modifiedState.ChangedPositionLat;
        inCurrentLocationLon = modifiedState.ChangedPositionLon;

        // update the prev point locn with a changed point locn if a users current viewing locn on map is exceeds than 
        // RADIUS_KM/2 of a distance from users current locn which will avoid unnecessary axios call to bring a Toilets when disance exceeds than 
        // RADIUS_KM/2 from users current locn.
        modifiedState.PrevPointLocnLat = modifiedState.ChangedPositionLat;
        modifiedState.PrevPointLocnLon = modifiedState.ChangedPositionLon;
        
      } else {
        inCurrentLocationLat = modifiedState.CurrentLocationLat;
        inCurrentLocationLon = modifiedState.CurrentLocationLon;
      }

      let jsonBody = {
         DeviceID: props.DeviceID
      }

      // To get list of MappedDevices. 
      axios.post( `${getAPIHostURL()}/wclient/getSingleSearchedDeviceData`, jsonBody)
      .then(response => {
        if(response.data.code === 'SUCCESS') {

          let receivedDeviceInfo = response.data.retreivedDevicesInfo;

          if( receivedDeviceInfo == null || receivedDeviceInfo.length <= 0 ) {
              modifiedState.errors.others = t(IDS_MapToiletNotMaped);
              setState({...modifiedState});

            } else {

            let  mappedToiletInfoArrLen = receivedDeviceInfo.length;

            for(let i=0 ; i<mappedToiletInfoArrLen; i++) {
              let singleMappedToiletInfo = receivedDeviceInfo[i];

              let singleMarkerData = {
                DeviceID:singleMappedToiletInfo["DeviceID"],
                DeviceName:singleMappedToiletInfo["DeviceName"],
                ModelID:singleMappedToiletInfo["ModelID"],
                Lat:JSON.parse(singleMappedToiletInfo["Lat"]),
                Lon:JSON.parse(singleMappedToiletInfo["Lon"]),
                EntityLocnID:singleMappedToiletInfo["EntityLocnID"],
                RatingLevel:singleMappedToiletInfo["RatingLevel"],
                EntityType:singleMappedToiletInfo["EntityType"],
                name:singleMappedToiletInfo["Name"],
                Address1:singleMappedToiletInfo["Address1"],
                Address2:singleMappedToiletInfo["Address2"],
                City:singleMappedToiletInfo["City"],
                State:singleMappedToiletInfo["State"],
                District:singleMappedToiletInfo["District"],
                PinCode:singleMappedToiletInfo["PinCode"],
              }

              if(singleMappedToiletInfo["DeviceID"] != null) {
                modifiedState.DeviceIDArr.push(singleMappedToiletInfo["DeviceID"]);
              } 
              modifiedState.mappedToiletInfoArr.push(singleMarkerData);

              //Change location
              modifiedState.ChangedPositionLat = singleMarkerData.Lat;
              modifiedState.ChangedPositionLon = singleMarkerData.Lon;
            }

            if( modifiedState.DeviceIDArr.length <= 0 && modifiedState.mappedToiletInfoArr.length > 0) {
              modifiedState.markerDataArr = [];

              let tempMappedToiletInfoArr;
              let filteredToilet =[];

              // This is the case where we have to show a filtered toilets based on user toiletType selection.
              if(modifiedState.toiletTypeArr.includes(ALL_TOILET)) {
                tempMappedToiletInfoArr = modifiedState.mappedToiletInfoArr;
                //Additional filters
             
              } else {
                for(let i = 0; i< modifiedState.toiletTypeArr.length; i++) {
                  let singleFilteredToilet = 
                    modifiedState.mappedToiletInfoArr.filter(
                      (toiletType) => toiletType.EntityType.split("-").pop().includes(modifiedState.toiletTypeArr[i])
                    );
                    //Additional filters
                  
                    filteredToilet.push(singleFilteredToilet);
                }
                tempMappedToiletInfoArr= [].concat.apply([], filteredToilet);
  
              }

              for (let i = 0 ; i< modifiedState.mappedToiletInfoArr.length; i++) {
                let singleMarkerDataWithoutDevc = {
                  ...tempMappedToiletInfoArr[i],
                  icon: purplemarker,
                  Level: null,
                  LevelType:null,
                  color: null,
                  EmojiIcon: null
                }
                modifiedState.markerDataArr.push(singleMarkerDataWithoutDevc);
              }    

              modifiedState.errors.others = '';
              setState({...modifiedState});
            } else {
              getRelevantModelInfo(modifiedState);
            }
          }
        } else {
          if(response.data.code == 'SQL_ERROR') {
            // Tell the user that Server is experiencing errors
            modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
            console.log('Should not reach here');
            modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          // Display the specific error message.
          setState({...modifiedState});
        }
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        if (axios.isCancel(error)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          // Tell the user that there are network issues
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          setState({...modifiedState});
        }
      }); 
    }

    const getMappedDeviceData = (inModifiedState = null) => {
      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t;
      let modifiedState;

      // If passed by the caller, update State with All new values (will happen in DidMount
      // and whenever new data is retrieved during interval)
      if(inModifiedState != null) {
        modifiedState = inModifiedState; 
      } else {
        modifiedState = state;
      }
      modifiedState.languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

      // if toilets are not mapped with a devices no need to bring a data.
      if(modifiedState.DeviceIDArr == null || modifiedState.DeviceIDArr.length <= 0 ) {
        console.log("getMappedDeviceData - DeviceIDArr not present");
        setState({...modifiedState});
        return; // no need further processing.
      }

      modifiedState.deviceDataArr = [];
      modifiedState.markerDataArr = [];

      let DeviceIDArr = modifiedState.DeviceIDArr;

      let jsonBody = {
          DeviceIDArr: DeviceIDArr
      }

      // To get getMappedDeviceData. 
      axios.post( `${getAPIHostURL()}/wclient/getMappedDeviceData`, jsonBody)
      .then(response => {
 
        if(response.data.code == 'SUCCESS') {

          modifiedState.errors.others = "";

          let receivedMappedDeviceData = response.data.retrievedMappedDeviceData;

          if( receivedMappedDeviceData == null || receivedMappedDeviceData.length <= 0 ) {
              modifiedState.errors.others = t(IDS_MapDeviceDataInfo)
          } else {
            modifiedState.deviceDataArr = receivedMappedDeviceData;

            let mappedToiletAndApplicableDevicesInfoArr = modifiedState.mappedToiletInfoArr;

          
            // This is the case where we have to show a filtered toilets based on user toiletType selection.
            let filteredToilet = [];
            if(modifiedState.toiletTypeArr.includes(ALL_TOILET)) {
              let mappedToiletInfoArr = modifiedState.mappedToiletInfoArr;
              //Additional filters
              if(modifiedState.getCleanToilet){
                mappedToiletInfoArr = mappedToiletInfoArr.filter(mappedToilet => isCleanBasedOnParam(mappedToilet, modifiedState));
              }
              if(modifiedState.getGandhvedhToilet){ 
                mappedToiletInfoArr = mappedToiletInfoArr.filter(mappedToilet => mappedToilet.DeviceID != null)
              }
              
              mappedToiletAndApplicableDevicesInfoArr = mappedToiletInfoArr;
            } else {
              for(let i = 0; i< modifiedState.toiletTypeArr.length; i++) {
                let singleFilteredToilet = 
                  modifiedState.mappedToiletInfoArr.filter(
                    (toiletType) => toiletType.EntityType.split("-").pop().includes(modifiedState.toiletTypeArr[i])
                  );
                //Additional filters
                if(modifiedState.getCleanToilet){
                  singleFilteredToilet = singleFilteredToilet.filter(mappedToilet => isCleanBasedOnParam(mappedToilet, modifiedState));
                }
                if(modifiedState.getGandhvedhToilet){ 
                  singleFilteredToilet = singleFilteredToilet.filter(mappedToilet => mappedToilet.DeviceID != null)
                }
                filteredToilet.push(singleFilteredToilet);
              }
              mappedToiletAndApplicableDevicesInfoArr= [].concat.apply([], filteredToilet);

            }

            for(let i=0; i< mappedToiletAndApplicableDevicesInfoArr.length; i++) {

              let multipleDevicesAtOneLocn = [];
              let DeviceIDArr = [];
              let DeviceNameArr = [];
              let DeviceLevelArr = [];
              let DeviceLevelTypeArr = [];
              let DevicetextStyleArr = [];
              let DeviceEmojiIconArr = [];
              let NH3ODVal = 0;
              let modelIdOfSpecificDevice;
              let DateTobeShown = null;
              let receivedLogTimeArr = [];
              let singleDeviceStatus = false;

              if(mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"] != null &&
                    !modifiedState.markerDataArr.some( DeviceLocn => DeviceLocn['EntityLocnID'] == mappedToiletAndApplicableDevicesInfoArr[i].EntityLocnID)
              ) {

                // find currently visible toilets from a Toilets which are under "radius_km"
                if(modifiedState.deviceDataArr.some(Device => Device['DeviceID'] == mappedToiletAndApplicableDevicesInfoArr[i].DeviceID) ) {

                  // in case of one toilet contains multiple devices filter it out from mappedToiletAndApplicableDevicesInfoArr.
                  multipleDevicesAtOneLocn = mappedToiletAndApplicableDevicesInfoArr.filter((location) => 
                                location.EntityLocnID == mappedToiletAndApplicableDevicesInfoArr[i].EntityLocnID);

                  // multiple Devices at one locn
                  if(multipleDevicesAtOneLocn.length > 1) {
                    let sumOfNH3OD = 0;
                    let NoOfDevcAtLocn = 0; 
                    for(let mulDevc=0; mulDevc<multipleDevicesAtOneLocn.length; mulDevc++) {

                      let strSingleDeviceData =  
                        modifiedState.deviceDataArr.find((device) => device.DeviceID ==  multipleDevicesAtOneLocn[mulDevc]["DeviceID"]);

                      try {
                        let objSingleMeasuredParam =  JSON.parse(strSingleDeviceData["MeasuredParams"]);

                        if(strSingleDeviceData["DeviceStatus"] == true) {
                          singleDeviceStatus =  strSingleDeviceData["DeviceStatus"];
                        }

                        let mulDevcAtSingLocnReceivedLogTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(strSingleDeviceData["LogTime"]);

                        let mulDevcDateTobeShown = convertLocalDateToDisplayToday(mulDevcAtSingLocnReceivedLogTime);

                        let splitedDateTime = mulDevcDateTobeShown.split(" ");

                        // get customized today's date.
                        if(splitedDateTime[0].toLowerCase() == "Today,".toLowerCase()) {
                          mulDevcDateTobeShown = t(IDS_TodaySearch) + splitedDateTime[1];;
                        } 

                        modelIdOfSpecificDevice = mappedToiletAndApplicableDevicesInfoArr[i]["ModelID"];

                        sumOfNH3OD += objSingleMeasuredParam.hasOwnProperty("NH3OD") && objSingleMeasuredParam["NH3OD"] != null && objSingleMeasuredParam["NH3OD"];

                        // Count the No of Devices which are sending Nh3OD sensor data to server.
                        objSingleMeasuredParam.hasOwnProperty("NH3OD") && objSingleMeasuredParam["NH3OD"] != null && NoOfDevcAtLocn++;

                        // If You want to Exclude all Devices to show on map which not contains NH3OD Paramater just uncommet bellow line.
                        // if(objSingleMeasuredParam.hasOwnProperty("NH3OD") && objSingleMeasuredParam["NH3OD"] != null) {
                        let {Level, LevelType, icon, textStyle, EmojiIcon} 
                            = getLevelBasedOnParamValue( objSingleMeasuredParam["NH3OD"], modelIdOfSpecificDevice, modifiedState["relevantModelInfo"], singleDeviceStatus);
                            DeviceIDArr.push(multipleDevicesAtOneLocn[mulDevc]["DeviceID"]);
                            DeviceNameArr.push(multipleDevicesAtOneLocn[mulDevc]["DeviceName"]);
                            DeviceLevelArr.push(Level);
                            DeviceLevelTypeArr.push(LevelType);
                            DevicetextStyleArr.push(textStyle);
                            DeviceEmojiIconArr.push(EmojiIcon);
                            receivedLogTimeArr.push(mulDevcDateTobeShown);
                        // } 

                      } catch {
                        console.log(`Should not happen. The device data obtained from Server is in invalid JSON format.`);
                        // Skip this device information and move to next
                      }
                    }
                    // Get avg of NH3OD
                    NH3ODVal = sumOfNH3OD/NoOfDevcAtLocn;

                  } else {
                    // Single Device at one locn

                    let strSingleDeviceData =  
                        modifiedState.deviceDataArr.find((device) => 
                                                                device.DeviceID ==  mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"]);
                    
                    try {
                      let objSingleMeasuredParam =  JSON.parse(strSingleDeviceData["MeasuredParams"]);
                      singleDeviceStatus = strSingleDeviceData['DeviceStatus']
                      let strLocaleDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(strSingleDeviceData["LogTime"]);
                      DateTobeShown = convertLocalDateToDisplayToday(strLocaleDateTime);

                      let splitedDateTime = DateTobeShown.split(" ");

                      // get customized today's date.
                      if(splitedDateTime[0].toLowerCase() == "Today,".toLowerCase()) {
                        DateTobeShown = t(IDS_TodaySearch) + splitedDateTime[1];;
                      } 

                      NH3ODVal = objSingleMeasuredParam["NH3OD"];

                      modelIdOfSpecificDevice = mappedToiletAndApplicableDevicesInfoArr[i]["ModelID"];

                      DeviceIDArr.push(mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"])
                      DeviceNameArr.push(mappedToiletAndApplicableDevicesInfoArr[i]["DeviceName"]);

                    } catch(error) {
                      console.log(`Should not happen. The device data obtained from Server is in invalid JSON format.`);
                      // Skip this device information and move to next
                    }
                  }
              } else {
                // here we are skipping mapped Devices which are not visible on current view port.
              }     
            } else {
              // No need to do anything as we are filling markerDataArr for Locn without devc in next if Block.
            }  

            if(!modifiedState.markerDataArr.some( DeviceLocn => DeviceLocn['EntityLocnID'] == mappedToiletAndApplicableDevicesInfoArr[i].EntityLocnID)) { 
              let objOdourType = {};
              if(mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"] != null &&
                 !modifiedState.deviceDataArr.some(Device => Device['DeviceID'] == mappedToiletAndApplicableDevicesInfoArr[i].DeviceID) 
              ) {
                // Skipping markers creation which are not visible on current view port.
              } else {
                // create a markers which are visible on current view port.
                if(mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"] == null) {
                  // Toilets without Devices.
                  objOdourType =  null;
                } else {
                  objOdourType = getLevelBasedOnParamValue( NH3ODVal, modelIdOfSpecificDevice, modifiedState["relevantModelInfo"], singleDeviceStatus);
                }

                let singleMarkerData = {
                  DeviceID:mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"] == null ? null : DeviceIDArr,
                  DeviceName: mappedToiletAndApplicableDevicesInfoArr[i]["DeviceName"] == null ? null : DeviceNameArr,
                  ModelID:mappedToiletAndApplicableDevicesInfoArr[i]["ModelID"],
                  Lat:JSON.parse(mappedToiletAndApplicableDevicesInfoArr[i]["Lat"]),
                  Lon:JSON.parse(mappedToiletAndApplicableDevicesInfoArr[i]["Lon"]),
                  EntityLocnID:mappedToiletAndApplicableDevicesInfoArr[i]["EntityLocnID"],
                  EntityType:mappedToiletAndApplicableDevicesInfoArr[i]["EntityType"],
                  RatingLevel:mappedToiletAndApplicableDevicesInfoArr[i]["RatingLevel"],
                  name: mappedToiletAndApplicableDevicesInfoArr[i]["name"],
                  Address1:mappedToiletAndApplicableDevicesInfoArr[i]["Address1"],
                  Address2:mappedToiletAndApplicableDevicesInfoArr[i]["Address2"],
                  City:mappedToiletAndApplicableDevicesInfoArr[i]["City"],
                  State:mappedToiletAndApplicableDevicesInfoArr[i]["State"],
                  District:mappedToiletAndApplicableDevicesInfoArr[i]["District"],
                  PinCode:mappedToiletAndApplicableDevicesInfoArr[i]["PinCode"],
                  Level: mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"] == null ? null : 
                                                                                        DeviceIDArr.length > 1 
                                                                                        ? DeviceLevelArr : objOdourType.Level,
                  LevelType: mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"] == null ? null :
                                                                                        DeviceIDArr.length > 1 
                                                                                        ? DeviceLevelTypeArr : objOdourType.LevelType,
                  icon: mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"] == null ? purplemarker : objOdourType.icon,
                  color: mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"] == null ? null :
                                                                                    DeviceIDArr.length > 1 ? DevicetextStyleArr : objOdourType.textStyle,
                  EmojiIcon: mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"] == null ? null : 
                                                                                    DeviceIDArr.length > 1 ? DeviceEmojiIconArr : objOdourType.EmojiIcon,
                  LogTime: mappedToiletAndApplicableDevicesInfoArr[i]["DeviceID"] == null ? null : 
                                                                                    DeviceIDArr.length > 1 ?receivedLogTimeArr : DateTobeShown
                }
                modifiedState.markerDataArr.push(singleMarkerData);

              }
            } else {
              // Here we are skipping a marker creation for a location with multiple Devices as marker for that Location is already created.
            }
          }     
        }          
                 
          // Incase of sidebar/showingInfoWindow is open we need to update props with current fetch data.
          if(modifiedState.sidebarOpen == true || modifiedState.showingInfoWindow == true) {

            let selectedMarkerEntityLocnID = modifiedState.selectedPlace["EntityLocnID"];

            let selectedMarker = modifiedState.markerDataArr.find( (arrElement) => arrElement["EntityLocnID"] == selectedMarkerEntityLocnID );

            if(selectedMarker != null ) {
              modifiedState.selectedPlace = {};
              modifiedState.selectedPlace = selectedMarker;
            } 
            
          }
          
        } else {
          if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
            // Tell the user that Server is experiencing errors
            modifiedState.errors.others = t(IDS_LoginServerIssue);

          } else {
            console.log('Should not reach here');
            modifiedState.errors.others = t(IDS_LoginServerIssue);

          }
          // Display the specific error message for login failure
        }
        setState({...modifiedState});
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        if (axios.isCancel(error)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          // Tell the user that there are network issues
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          setState({...modifiedState});
        }
      }); 
    }

    const getSearchedDeviceData = (map, inModifiedState = null) => {

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t; 
      let modifiedState;
      if(inModifiedState != null){
        modifiedState = inModifiedState;
        
      } else{
        modifiedState = state;
      }

      // modifiedState.visibleSearchOption = true;
      modifiedState.errors.searchDeviceStringErr = "";

      if(modifiedState.trackToiletInterval != null) {
        clearInterval(modifiedState.trackToiletInterval);
        modifiedState.searchedToiletEntityLocID = "";

        if (modifiedState.marker != null) {
          modifiedState.lastSeachedMarkerReference = modifiedState.marker;
          modifiedState.marker.marker.setAnimation(null);
        } 
      }

      if(document.getElementById("trackingToilet") != null) {
        map.controls[window.google.maps.ControlPosition.TOP_CENTER].pop(document.getElementById("trackingToilet"));
      }

      // setState({...modifiedState});
      return modifiedState; 
    }

    const isCleanBasedOnParam = (mappedToilet, modifiedState) =>{
      
      if(modifiedState.deviceDataArr == null) {
        return false;
      }
      let deviceData = modifiedState.deviceDataArr.find(device => device.DeviceID == mappedToilet.DeviceID);
      if(deviceData == null || deviceData.DeviceStatus == '0'){
        return false;
      }
      let paramName = NH3OD;
      let MeasuredParamObj = deviceData.MeasuredParams;
      let inParamValue = JSON.parse(MeasuredParamObj)[paramName] || null
      if(inParamValue == null) return false;
      
      let AirQualityRanges = modifiedState.stdAirQualityRange.find( (arrElement) => arrElement ["MeasuredParam"] == paramName && arrElement["RangeValues"] != null );
      let singleParamAirQualityRange;
      
      try {
        singleParamAirQualityRange = JSON.parse(AirQualityRanges["RangeValues"]);
      } catch (error) {
        console.log("Air quality Ranges/Sensor Info obtained from server is in invalid Json format.")
        return false;
      }

      let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
      let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];

      if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
      ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
      ( inParamValue > singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
      {
        return true;
      }
      
      return false;
    }

    const getLevelBasedOnParamValue = (inParamValue, inDeviceModelId, inRelevantModelInfo, singleDeviceStatus) => {

      let modifiedState = state

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t; 

      let Level = "(" + t(IDS_AlertLevel1) + ")";
      let LevelType = t(IDS_BarelySensed);
      let icon = (singleDeviceStatus == true ? grayMarker : grayWithGreyMarker);
      let textStyle = "gray";
      let EmojiIcon = <FaSmileBeam/>;

      let AirQualityRanges = modifiedState.stdAirQualityRange.find( (arrElement) => arrElement ["MeasuredParam"] == "NH3OD" && arrElement["RangeValues"] != null );
      let specificModelInfo = inRelevantModelInfo.find( (arrElement) => arrElement ["ModelID"] == inDeviceModelId && arrElement["MeasuredParams"] != null );

      let singleParamAirQualityRange;
      let singleDeviceModelInfo;
      let singleParamSensorInfo;

      try {
        singleParamAirQualityRange = JSON.parse(AirQualityRanges["RangeValues"]);
        singleDeviceModelInfo = JSON.parse(specificModelInfo["MeasuredParams"]);
        singleParamSensorInfo = singleDeviceModelInfo["NH3OD"];
      } catch (error) {
        console.log("Air quality Ranges/Sensor Info obtained from server is in invalid Json format.")
        return { Level: Level, LevelType: LevelType,  icon:icon, textStyle: textStyle, EmojiIcon: EmojiIcon};
      }

      if(singleParamSensorInfo == null || singleParamSensorInfo.length <= 0){
        console.log("singleParamSensorInfo missing for NH3OD.")
        return { Level: Level, LevelType: LevelType,  icon:icon, textStyle: textStyle, EmojiIcon: EmojiIcon};
      }

      let singleParamSensorRangeMax = singleParamSensorInfo["RangeMax"];
      let singleParamSensorRangeMin = singleParamSensorInfo["RangeMin"];
      
      let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
      let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
      let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
      let singleParamAirQualityRangeSatisfactoryU = singleParamAirQualityRange[SATISFACTORY_U];
      let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
      let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
      let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
      let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
      let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
      let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
      let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
      let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];

      // in case of Nh3OD value received is null.
      if(inParamValue == null || inParamValue.length <=0) {
        Level = "--";
        LevelType = "--";
        icon = (singleDeviceStatus == true ? grayMarker : grayWithGreyMarker);
        textStyle = "gray";
        EmojiIcon = "--";

        return { Level: Level, LevelType: LevelType,  icon:icon, textStyle: textStyle, EmojiIcon: EmojiIcon};
      }
      
      if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
          ( singleParamAirQualityRangeGoodU == null && inParamValue > singleParamAirQualityRangeGoodL ) ||
          ( inParamValue > singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
        {
            Level = "(" + t(IDS_AlertLevel1) + ")";
            LevelType = t(IDS_BarelySensed);
            icon = (singleDeviceStatus == true ? greenMarker : greenWithGreyMarker);
            textStyle = "#009903";
            EmojiIcon = <FaGrinBeam/>

        } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
        ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
        ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
        {
            Level = "(" + t(IDS_AlertLevel2) + ")";
            LevelType = t(IDS_SatisfactoryOdour);
            icon = (singleDeviceStatus == true ? yellowGreenMarker : yellowGreenWithGreyMarker);
            textStyle = "#9ACD32";
            EmojiIcon = <FaSmile />


        } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
        {
            Level = "(" + t(IDS_AlertLevel2) + ")";
            LevelType = t(IDS_WeakOdour);
            icon = (singleDeviceStatus == true ? yellowMarker : yellowWithGreyMarker);
            textStyle = "#ffff00";
            EmojiIcon = <FaMeh style={{color:"gold"}}/>


        } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
        {
            Level = "(" + t(IDS_AlertLevel3) + ")";
            LevelType = t(IDS_EasilyRecognized);
            icon = (singleDeviceStatus == true ? orangeMarker : orangeWithGreyMarker);
            textStyle = "#FFA500";
            EmojiIcon = <FaFrownOpen/>

        } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
            ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
            ( inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
        {
            Level = "(" + t(IDS_AlertLevel4) + ")";
            LevelType = t(IDS_StrongOdour);
            icon = (singleDeviceStatus == true ? redMarker : redWithGreyMarker);
            textStyle = "#ff0000";
            EmojiIcon = <FaSadTear/>

        } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                ( inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
        { 
            Level = "(" + t(IDS_AlertLevel5) + ")";
            LevelType = t(IDS_Pungent);
            icon = (singleDeviceStatus == true ? darkRedMarker : darkRedWithGreyMarker);
            textStyle = "#8b0000";
            EmojiIcon = <FaDizzy/>

        } else {
          Level = "--";
          LevelType = "--";
          icon = (singleDeviceStatus == true ? grayMarker : grayWithGreyMarker);
          textStyle = "gray";
          EmojiIcon = "--"
        }
      return { Level: Level, LevelType: LevelType,  icon:icon, textStyle:textStyle, EmojiIcon: EmojiIcon};
    }

    const onClickOpenSideBar = () => {

      let modifiedState = state;
      modifiedState.sidebarOpen = true;
      modifiedState.showingInfoWindow = true;
      setState({...modifiedState});

    }

    const onMarkerClick = (props, marker) => {

        let modifiedState = state;
        modifiedState.selectedPlace = {};
        modifiedState.activeMarker = {};
        modifiedState.selectedPlace = props;
        modifiedState.activeMarker = marker;
        modifiedState.showingInfoWindow = true;
        modifiedState.DestinationMarkerLat = props.Lat;
        modifiedState.DestinationMarkerLon = props.Lon;

        getRatingDataPerReviewee(modifiedState);         
    }

    const onClose = (props) => {
      let modifiedState = state;

      if (modifiedState.showingInfoWindow) {
        modifiedState.showingInfoWindow = false;
        modifiedState.activeMarker = null;
        setState({...modifiedState});

      }
    }; 

    const showDirectionOnMap = () => {
      let SourceLocationLat = state.CurrentLocationLat;
      let SourceLocationLon = state.CurrentLocationLon;
      let DestinationMarkerLat = state.DestinationMarkerLat;
      let DestinationMarkerLon = state.DestinationMarkerLon;
      
      let url = "https://www.google.com/maps/dir/?api=1&origin=" + SourceLocationLat + "," + SourceLocationLon + "&destination=" + DestinationMarkerLat + "," + DestinationMarkerLon;
      window.open(url);  
    }

    const onSideBarClose = () => {
      let modifiedState = state;

      modifiedState.showingInfoWindow = false;
      modifiedState.activeMarker = null;
      modifiedState.sidebarOpen = false;
      setState({...modifiedState});
    }; 
      
    const renderMarkersWithToiletsAndApplicableDevice = () => {

      return state.markerDataArr.map((singlePosition, index) => {
        let ref = `${singlePosition}`+ index;
        return( 
          <Marker
            ref= {singlePosition.EntityLocnID == state.searchedToiletEntityLocID ? ((marker) => state.marker =  marker) : null }
            key={index}
            onClick={(marker) => onMarkerClick(singlePosition, index)}         
            DeviceID={singlePosition.DeviceID}
            DeviceName={singlePosition.DeviceName}
            name={singlePosition.name}
            Address1={singlePosition.Address1}
            Address2={singlePosition.Address2}
            City={singlePosition.City}
            State={singlePosition.State}
            District={singlePosition.District}
            PinCode={singlePosition.PinCode}
            EntityLocnID={singlePosition.EntityLocnID}
            RatingLevel={singlePosition.RatingLevel}
            Level={singlePosition.Level}
            LevelType={singlePosition.LevelType}
            color={singlePosition.color}
            icon={singlePosition.icon}
            animation = {singlePosition.EntityLocnID == state.searchedToiletEntityLocID ? window.google.maps.Animation.BOUNCE : null}
            EmojiIcon={singlePosition.EmojiIcon}
            position={{lat: Number(singlePosition.Lat), lng: Number(singlePosition.Lon)}}
            LogTime= {singlePosition.LogTime}
          >
            {/* Set lat lng to number */}
            {state.showingInfoWindow && state.activeMarker === index &&
                <InfoWindow
                  position={{lat: Number(singlePosition.Lat), lng: Number(singlePosition.Lon)}} 
                  onCloseClick={onClose}  
                >
                  <div style={{boxSizing:"border-box", margin:"0.3rem", minWidth:"20rem", maxWidth: "25rem"}}>
                    { state.sidebarOpen ? 
                        <div style={{fontSize:"1.4rem", textTransform:"capitalize", color:"#424242", fontWeight:"500", marginBottom:"0.1rem"}}>
                          {state.selectedPlace["name"]}
                        </div>
                        : 
                        <div style={{color:"#424242", fontWeight:"500"}}>
                          <div style = {{display: "flex", justifyContent: "space-between"}}>
                            <div style={{justifyContent: "flex-start", flex: "1", fontSize: "0.8rem"}}>
                              <button className="deviceTreeRefreshButton" id= "refreshRatingData" style={{marginLeft: "0rem", verticalAlign:"bottom", alignItems:"center", fontSize: "0.8rem"}}  onClick={onClickRefreshRating} title={t(IDS_RefreshCountAndRating)}>
                                  <i className="fa fa-refresh" style={{fontSize: "0.8rem", paddingLeft: "0rem"}} aria-hidden="true"></i>
                              </button>
                            </div>
                            <div style={{fontSize:"1.4rem", textTransform:"capitalize", color:"#424242", justifyContent: "center", flex: "10", fontWeight:"500"}}>
                              {state.selectedPlace["name"]}
                            </div>
                            <div style={{ justifyContent: "flex-end", flex: "1"}}>
                            </div>
                          </div>
                          { state.totalTodaysPeopleCount != null &&
                            <div style={{textAlign:"center"}}>
                              <hr style={{margin:"0.3rem 0rem"}}/>
                              {t(IDS_TodayPeopleCount)} : <b>{state.totalTodaysPeopleCount}</b>
                            </div>
                          }
                          <hr style={{margin:"0.3rem 0rem"}}/>
                          <div style={{textAlign:"center"}}>
                            {t(IDS_TotalNoOfRating)} : <b>{state.odourTotalNumberOfReviews}</b>
                          </div>
                          {/* <div style={{marginTop: "0.3rem", marginbottom: "0.1rem", padding: "0rem", fontSize: "1rem", verticalAlign: "bottom"}}> */}
                          <div id= "InfoWindowRating" style={{cursor: "pointer"}} onClick = { () => ratingSummaryToggle()}>
                            {state.ratingLevel != null && state.ratingLevel.length > 0 && state.ratingLevel == DEFAULT_RATING_LEVEL_STARS
                            ?
                            <div>
                              <div style={{display: "flex", justifyContent: "center", marginTop: "0.3rem"}}>
                                <div style={{paddingRight: "0.2rem", paddingTop: "0.1rem", fontSize: "0.8rem", fontWeight: "bold"}}>
                                  {t(IDS_Odour)} :
                                </div>
                                <StarRatings
                                  rating={state.odourAvgStarRating}
                                  numberOfStars={5}
                                  isSelectable = {false}
                                  starDimension = '1rem'
                                  starSpacing = '1px'
                                  starRatedColor = '#FDCC0D'
                                  starEmptyColor = 'lightGray'
                                  name='ToiletSideBarOdourRating'
                                  style = {{flexShrink: "0"}}
                                />
                                <div style={{paddingLeft: "0.4rem", paddingTop: "0.1rem", fontSize: "0.8rem", fontWeight: "bold"}}>
                                  <a href ="#" className = "textDecoNone"> ({state.odourAvgStarRating} / 5) 
                                    <i style ={{paddingLeft : "0.2rem" , fontSize: "0.8rem"}} className="fa fa-caret-down"></i>
                                  </a>
                                </div>
                              </div>

                              <div style={{display: "flex", justifyContent: "center", marginbottom: "0.3rem", marginTop: "0.1rem", padding: "0rem"}}>
                                <div style={{paddingRight: "0.2rem", paddingTop: "0.1rem", fontSize: "0.8rem", fontWeight: "bold"}}>
                                {t(IDS_Cleanliness)} :
                                </div>
                                <StarRatings
                                  rating={state.cleanlinessAvgStarRating}
                                  numberOfStars={5}
                                  isSelectable = {false}
                                  starDimension = '1rem'
                                  starSpacing = '1px'
                                  starRatedColor = '#FDCC0D'
                                  starEmptyColor = 'lightGray'
                                  name='ToiletSideBarCleanlinessRating'
                                />
                                <div style={{paddingLeft: "0.4rem", paddingTop: "0.1rem", fontSize: "0.8rem", fontWeight: "bold"}}>
                                  <a href ="#" className = "textDecoNone"> ({state.cleanlinessAvgStarRating} / 5) 
                                    <i style ={{paddingLeft : "0.2rem" , fontSize: "0.8rem"}} className="fa fa-caret-down"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            :
                            <div>
                              <div className="AddressInfo" style={{fontSize: "0.9rem", margin: "0.5rem 0rem", boxShadow: "0rem 0.1rem 0.5rem #464946", display: "flex"}}>
                                <div style ={{ flex: "1", justifyContent: "flex-start"}}>
                                  <div style={{paddingRight: "0.2rem", paddingTop: "0.1rem", fontSize: "0.8rem", fontWeight: "bold", textAlign:"left"}}>
                                    {t(IDS_Odour)} :
                                  </div>
                                  <div style = {{textAlign: "left"}}>
                                    {getSignalCountOfRating("Odour", 3, "green", t(IDS_GoodRating))}
                                    {getSignalCountOfRating("Odour", 2, "yellow", t(IDS_Normal))}
                                    {getSignalCountOfRating("Odour", 1, "red", t(IDS_Bad))}
                                  </div>
                              </div>
                            
                              <div style={{flex: "1"}}>
                                <div style={{paddingRight: "0.2rem", paddingTop: "0.1rem", fontSize: "0.8rem", fontWeight: "bold", textAlign:"left"}}>
                                  {t(IDS_Cleanliness)} :
                                </div>
                                <div style = {{textAlign: "left"}}>
                                  {getSignalCountOfRating("Cleanliness", 3, "green", t(IDS_GoodRating))}
                                  {getSignalCountOfRating("Cleanliness", 2, "yellow", t(IDS_Normal))}
                                  {getSignalCountOfRating("Cleanliness", 1, "red", t(IDS_Bad))}
                                </div>
                              </div>
                              </div>
                          </div>
                        }
                          </div>
                          
                          <div style={{marginTop: "0.5rem", marginbottom: "0.3rem", padding: "0rem", fontSize: "0.8rem"}}>
                              <a href ="#" className = "textDecoNone" id="moreDetailsButton" onClick={toggle}>
                                <b>{state.cleanlinessAvgStarRating == 0 && state.odourAvgStarRating == 0 ? t(IDS_RateThisToilet) : t(IDS_RateThisToilet)}</b>
                              </a>
                          </div>

                          {state.selectedPlace["DeviceID"] != null ?
                            state.selectedPlace["DeviceID"].length > 1 ?
                              <div style={{ boxSizing:"border-box", borderRadius:"5px"}}>
                                  <hr style={{marginBottom:"0rem", marginTop:"0.3rem"}}/> 
                                  <div style={{fontSize:"1.2rem", fontWeight:"500", color:"#424242", padding:"0.5rem 0.5rem 0rem 0.5rem"}}> 
                                    {t(IDS_MapMulDevcTitle)}
                                  </div>
                              </div>
                              :
                              <div>
                                <hr style={{marginTop:"0.3rem", marginBottom: "0.6rem"}}/>
                                <div style={{ boxSizing:"border-box", 
                                              borderWidth: state.selectedPlace["color"] == "#ffff00" &&
                                                    state.selectedPlace["Level"] == "(Level 2)" && `1.5px 2.5px 2.5px 1.5px`,
                                              borderStyle:state.selectedPlace["color"] == "#ffff00" &&
                                                          state.selectedPlace["Level"] == "(Level 2)" && `solid`,
                                              borderColor: state.selectedPlace["color"] == "#ffff00" &&
                                                            state.selectedPlace["Level"] == "(Level 2)" && `${state.selectedPlace["color"]}`,
                                              boxShadow: state.selectedPlace["color"] == "#ffff00" && 
                                                          state.selectedPlace["Level"] == "(Level 2)" ? `inset 0.5px 0.5px 1px 1px #D3D3D3, 1px 1px 2px 2px #D3D3D3`
                                                                                                          : `0.5px 0.5px 2px 2px ${state.selectedPlace["color"]}`,
                                              borderRadius:"5px"
                                      }}
                                >
                                    <div style={{fontSize:"1.2rem", fontWeight:"500", color:"#424242", padding:"0.5rem 0.5rem 0rem 0.5rem"}}>
                                      {state.selectedPlace["DeviceName"]}
                                    </div>
                                    <hr style={{marginBottom:"0rem", marginTop:"0.3rem"}}/> 
                                    <div style={{fontSize:"1.8rem", color: state.selectedPlace["color"],fontWeight:"700", marginBottom:"0.3rem",marginTop:"0.3rem", padding:"0rem 0.5rem"}}>      
                                      {state.selectedPlace["EmojiIcon"]}
                                    </div>
                                    <div style={{fontSize:"1rem", color: "#808080", fontWeight:"600", marginBottom:"0.3rem", padding:"0rem 0.5rem"}}>  
                                    {state.selectedPlace["LevelType"]}
                                    </div>
                                    <div style={{fontSize:"0.8rem", color: "#808080",fontWeight:"500", marginBottom:"0.3rem", padding:"0rem 0.5rem 0.5rem 0.5rem"}}>
                                        {state.selectedPlace["Level"]}
                                    </div>
                                </div>
                              </div>
                          : 
                            <div>
                              <hr style={{marginBottom:"0rem", marginTop:"0.3rem"}}/> 
                              <div className="AddressComp">
                                <div style={{textAlign:"left", fontSize:"1rem", textTransform: "capitalize", paddingBottom: "0.3rem"}}> {state.selectedPlace["Address1"]},&nbsp;{state.selectedPlace["Address2"]}</div>
                              </div>
                              <div className="AddrMainDiv">
                                <div className="AddrSubComp1">City:&nbsp;</div>
                                <div className="AddrSubComp2">{state.selectedPlace["City"]}</div>
                              </div>
                              <div className="AddrMainDiv">
                                <div className="AddrSubComp1">District:&nbsp;</div>
                                <div className="AddrSubComp2">{state.selectedPlace["District"]}</div>
                              </div>
                              <div className="AddrMainDiv">
                                <div className="AddrSubComp1">State:&nbsp;</div>
                                <div className="AddrSubComp2">{state.selectedPlace["State"]}</div>
                              </div>
                              <div className="AddrMainDiv">
                                <div className="AddrSubComp1">Pincode:&nbsp;</div>
                                <div className="AddrSubComp2">{state.selectedPlace["PinCode"]}</div>
                              </div>

                            </div>
                          }
                          <div style={{marginTop: "0.7rem", marginbottom: "0rem", padding: "0rem", fontSize: "0.8rem"}}>
                            <a href ="#" className = "textDecoNone" id="OpenSideBar" onClick={() => {onClickOpenSideBar()}}><b>{t(IDS_SeeMore)}</b></a>
                          </div>
                        </div>
                      }
                  </div>
                </InfoWindow>  
                }
          </Marker>
        )
      })
    }

    const getToiletsBasedOnSelectedToiletType = (inToiletType, modifiedState = null) => {

      if(modifiedState == null){
        modifiedState = state;
      }

      let e = document.getElementById(inToiletType);
      let selectedDropDown = e.value;
      let count = e.dataCount;
      modifiedState.visibleSearchOption = false;
      if(inToiletType != null && inToiletType.length > 0 && inToiletType != ALL_TOILET) {

        // case where selected button toiletType is other than "AllToilet".
        // so we have to explicitely set All toiletype status to inactive.
        document.getElementById(ALL_TOILET).style.backgroundColor = "white";
        document.getElementById(ALL_TOILET).dataCount = 1;   
        let index = modifiedState.toiletTypeArr.indexOf(ALL_TOILET);

        if (index > -1) { // for allToilet
          modifiedState.toiletTypeArr.splice(index, 1);
        }

        //Chetan' code
        if (index == -1 && count == 0 && modifiedState.toiletTypeArr != null && modifiedState.toiletTypeArr.length == 1) { // for allToilet
          selectedDropDown = 'All';
          if (!modifiedState.getCleanToilet && !modifiedState.getGandhvedhToilet) {
            document.getElementById(ALL_TOILET).style.backgroundColor = "var(--secondaryColor)";
          }
          document.getElementById(ALL_TOILET).dataCount = 0;
        }

        // This is for the selected toiletType.
        if(count == 0) {
          e.style.backgroundColor = "white"
          e.dataCount = 1;   
          let index = modifiedState.toiletTypeArr.indexOf(selectedDropDown);

          if(index > -1) { // remove clicked button from toiletTypeArr.
            modifiedState.toiletTypeArr.splice(index, 1);
          } else if(index > -2) { // remove clicked button from toiletTypeArr.
            modifiedState.toiletTypeArr.splice(index, 1);
            modifiedState.toiletTypeArr.push(ALL_TOILET);
          } else if(index <= 0){
            modifiedState.toiletTypeArr.push(ALL_TOILET);
          } 

        } else {
            e.style.backgroundColor = "var(--secondaryColor)"
            e.dataCount = 0;
            modifiedState.toiletTypeArr.push(selectedDropDown);
        }

      } else if((inToiletType != null && inToiletType.length > 0 && inToiletType == ALL_TOILET) ||
                (inToiletType == null && inToiletType.length < 0)) {

        // case where selected button is All toilet.
        modifiedState.getCleanToilet = false;
        modifiedState.getGandhvedhToilet = false;
        document.getElementById(ALL_TOILET).style.backgroundColor = "var(--secondaryColor)";
        e.dataCount = 0;

        // To Enable Track GPS (Toilets on wheel) feature Uncomment the bellow line to see Toilets on Wheel option in Toilet Type.
        // document.getElementById(WHEEL).style.backgroundColor = "white";
        document.getElementById(E_TOILET).style.backgroundColor = "white";
        document.getElementById(BRICK_N_MORTAR_TOILET).style.backgroundColor = "white";
        document.getElementById(CONTAINER_TOILET).style.backgroundColor = "white";
        document.getElementById(SHE_TOILET).style.backgroundColor = "white";
        document.getElementById(GANDHVEDH_TOILETS).style.backgroundColor = "white";
        document.getElementById(HYGIENIC_TOILETS).style.backgroundColor = "white";
        // To Enable Track GPS (Toilets on wheel) feature Uncomment the bellow line to see Toilets on Wheel option in Toilet Type.
        // document.getElementById(WHEEL).dataCount = 1;   
        document.getElementById(E_TOILET).dataCount = 1;   
        document.getElementById(BRICK_N_MORTAR_TOILET).dataCount = 1;   
        document.getElementById(CONTAINER_TOILET).dataCount = 1;   
        document.getElementById(SHE_TOILET).dataCount = 1;   
        
        // First blank whole array in case of selected toilet type is "ALL" and then add "All" toilet type.
        while(modifiedState.toiletTypeArr.length > 0) {
          modifiedState.toiletTypeArr.pop();
        }

        // modifiedState.toiletTypeArr = [];
        modifiedState.toiletTypeArr.push(ALL_TOILET);
      }
   
      if(modifiedState.DeviceIDArr == null || modifiedState.DeviceIDArr.length <=0) {
        getListOfToiletsAndAplicableDevices(modifiedState);
      } else {
        // because dont neeed to bring a data from server as it is already taken in state.
        getMappedDeviceData(modifiedState);
      }
    }

    // center the map to users current location.
    const createCenterControl = (controlDiv, map) => {

      // let Currentloc = {lat: Number(state.CurrentLocationLat), lng: Number(state.CurrentLocationLon)}; 
      //Not needed. Also map.center does not work

      // Set CSS for the control border.
      let controlUI = document.createElement('div');
      controlUI.style.backgroundColor = '#fff';
      controlUI.style.border = '2px solid #fff';
      controlUI.style.borderRadius = '3px';
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      controlUI.style.cursor = 'pointer';
      controlUI.style.marginBottom = '22px';
      controlUI.style.textAlign = 'center';
      controlUI.style.marginRight = '10px';

      controlUI.title = 'Click to recenter the map';
      controlDiv.appendChild(controlUI);
    
      // Set CSS for the control interior.
      let controlText = document.createElement('div');
      controlText.style.color = '#666666';
      controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
      controlText.style.fontSize = '1.5rem';
      controlText.style.lineHeight = '1.2rem';
      controlText.style.paddingLeft = '0rem';
      controlText.style.paddingRight = '0.5rem';
      controlText.style.paddingTop = '0.5rem';
      controlText.style.paddingBottom = '0.5rem';
      controlText.innerHTML = '<i class="fa fa-crosshairs" aria-hidden="true"></i>'
      controlUI.appendChild(controlText);
    
      // Setup the click event listeners: simply set the map to Current Location.
      controlUI.addEventListener('click', () => {

        setState((prevState) => {
          let modifiedState = prevState;
          // map.setCenter(Currentloc);
          //Done to improve rendering speed 
          modifiedState.ChangedPositionLat = modifiedState.CurrentLocationLat;
          modifiedState.ChangedPositionLon = modifiedState.CurrentLocationLon;

          getListOfToiletsAndAplicableDevices(modifiedState);
          return {...modifiedState, ChangedPositionLat: modifiedState.CurrentLocationLat, ChangedPositionLon: modifiedState.CurrentLocationLon};
        });
      });
      return controlUI;
    }

    const createRefreshMapDataControl = (controlDiv, map) => {
      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t; 
      //To check if state here is updated or not
      let Currentloc = {lat: state.ChangedPositionLat, lng: state.ChangedPositionLon}; 

      // Set CSS for the control border.
      let controlUI = document.createElement('div');
      controlUI.style.backgroundColor = '#fff';
      controlUI.style.border = '2px solid #fff';
      controlUI.style.borderRadius = '3px';
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      controlUI.style.cursor = 'pointer';
      controlUI.style.marginTop = '1rem';
      controlUI.style.textAlign = 'center';
      controlUI.style.marginRight = '10px';

      controlUI.title = t(IDS_RefreshData)
      controlDiv.appendChild(controlUI);
    
      // Set CSS for the control interior.
      let controlText = document.createElement('div');
      controlText.style.color = '#666666';
      controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
      controlText.style.fontSize = '1.4rem';
      controlText.style.lineHeight = '1.2rem';
      controlText.style.paddingLeft = '0rem';
      controlText.style.paddingRight = '0.5rem';
      controlText.style.paddingTop = '0.5rem';
      controlText.style.paddingBottom = '0.5rem';
      controlText.innerHTML = '<i class="fa fa-refresh" aria-hidden="true"></i>';

      controlUI.addEventListener('click', () => {
        setState((prevState) => {
          let modifiedState = prevState;
          getMappedDeviceData(modifiedState);
          return modifiedState;
        });

      });
    
      // Setup the click event listeners: simply refresh the map data for current visible markers.
      // controlUI.addEventListener("click", () => {
      //   let modifiedState = state;
      //   // // // To get Toilets present within a Radius_km distance.
      //   // getToiletListBasedOnCurrentLocation();

      //   modifiedState.visibleSearchOption = false;
      //   getMappedDeviceData(modifiedState);
      // });
      controlUI.appendChild(controlText);

      return controlUI;
    }

   

    // Just create a button for all toilet types.
    const createButton = (inToiletType, controlDiv, map) => {

      let controlUI = document.createElement('BUTTON');
      controlUI.id = inToiletType;

      controlUI.style.borderRadius = '10px';
      controlUI.style.cursor = 'pointer';
      // controlUI.style.marginBottom = '1rem';
      controlUI.style.textAlign = 'center';
      controlUI.style.marginRight = '10px';
      controlUI.style.height = '1.8rem';
      controlUI.style.fontSize = '0.9rem';
      controlUI.style.background = 'white';
      controlUI.style.paddingLeft = '0.8rem';
      controlUI.style.paddingRight = '0.8rem';

      let onMobileView = window.matchMedia("(max-width: 550px)")
      
      if(onMobileView.matches) { // Mobile view
        controlUI.style.fontSize = '0.8rem';
        controlUI.style.width = '100%';
      }

      controlUI.value = inToiletType; // will just add a hidden value
      controlUI.innerHTML = inToiletType;

      controlUI.addEventListener('click', () => { // onclick event

        setState((prevState) => {
          let modifiedState = prevState;
          getToiletsBasedOnSelectedToiletType(inToiletType, modifiedState);
          return modifiedState;
        });
      
      });

      return controlUI;
     
    }

    const createAdditionalFilterButton = (filterType) => {

      let controlUI = document.createElement('BUTTON');
      controlUI.id = filterType;
      controlUI.innerHTML = filterType;
      
      controlUI.style.borderRadius = '10px';
      controlUI.style.cursor = 'pointer';
      // controlUI.style.marginBottom = '0rem';
      controlUI.style.textAlign = 'center';
      controlUI.style.marginRight = '10px';
      controlUI.style.height = '1.8rem';
      controlUI.style.fontSize = '0.9rem';
      controlUI.style.backgroundColor = 'white';
      controlUI.style.paddingLeft = '0.8rem';
      controlUI.style.paddingRight = '0.8rem';

      let onMobileView = window.matchMedia("(max-width: 550px)")
      
      if(onMobileView.matches) { // Mobile view
        controlUI.style.fontSize = '0.8rem';
        controlUI.style.width = '100%';
      }

      if(filterType == GANDHVEDH_TOILETS){
        controlUI.addEventListener('click', () => { // onclick event
          setState(prevState =>  {
            let modifiedState = prevState;
            let getGandhvedhToilet = modifiedState.getGandhvedhToilet;
            let getCleanToilet = modifiedState.getCleanToilet;
            let toiletTypeArr = modifiedState.toiletTypeArr;
            if(getGandhvedhToilet == true){
              modifiedState.getGandhvedhToilet = false;
              document.getElementById(GANDHVEDH_TOILETS).style.backgroundColor = "white";
              if(toiletTypeArr.includes(ALL_TOILET) && !getCleanToilet){
                document.getElementById(ALL_TOILET).style.backgroundColor =  "var(--secondaryColor)";
              } 
            } else {
              modifiedState.getGandhvedhToilet = true;
              document.getElementById(GANDHVEDH_TOILETS).style.backgroundColor =  "var(--secondaryColor)";
              document.getElementById(ALL_TOILET).style.backgroundColor =  "white";
            }  
            modifiedState.toiletTypeArr = toiletTypeArr;
            if(modifiedState.DeviceIDArr == null || modifiedState.DeviceIDArr.length <=0) {
              getListOfToiletsAndAplicableDevices(modifiedState);
            } else {
              getMappedDeviceData(modifiedState);
            }
            return modifiedState;
          });
  
        });
      } else {
        controlUI.addEventListener('click', () => {
          setState(prevState =>  {
            let modifiedState = prevState;
            let getCleanToilet = modifiedState.getCleanToilet;
            let getGandhvedhToilet = modifiedState.getGandhvedhToilet;
            let toiletTypeArr = modifiedState.toiletTypeArr;
            if(getCleanToilet == true){
              modifiedState.getCleanToilet = false;
              document.getElementById(HYGIENIC_TOILETS).style.backgroundColor = "white";
              if(toiletTypeArr.includes(ALL_TOILET) && !getGandhvedhToilet){
                document.getElementById(ALL_TOILET).style.backgroundColor =  "var(--secondaryColor)";
              } 
            } else {
              modifiedState.getCleanToilet = true;
              document.getElementById(HYGIENIC_TOILETS).style.backgroundColor =  "var(--secondaryColor)";
              document.getElementById(ALL_TOILET).style.backgroundColor =  "white";
  
            }
  
            modifiedState.toiletTypeArr = toiletTypeArr;
            if(modifiedState.DeviceIDArr == null || modifiedState.DeviceIDArr.length <=0) {
              getListOfToiletsAndAplicableDevices(modifiedState);
            } else {
              getMappedDeviceData(modifiedState);
            }
            return modifiedState;
          });
  
        });
      }
      return controlUI;
    }

    const createAllToilets = (controlDiv, map) => {
      let toiletType = createButton(ALL_TOILET, controlDiv, map);
      // first set the active count to 0 and color to orange as we have to show all toilets.
      toiletType.dataCount = '0';
      toiletType.style.backgroundColor = 'var(--secondaryColor)';
      return toiletType;
    }

    const createWheel = (controlDiv, map) => {
      let toiletType = createButton(WHEEL, controlDiv, map);
      toiletType.dataCount = '1';
      return toiletType;
    }

    const createEToilet = (controlDiv, map) => {
      let toiletType = createButton(E_TOILET, controlDiv, map);
      toiletType.dataCount = '1';
      return toiletType;
    }

    const createBrickAndMortar = (controlDiv, map) => {  
      let toiletType = createButton(BRICK_N_MORTAR_TOILET, controlDiv, map);
      toiletType.dataCount = '1';
      return toiletType;
    }

    const createContainerToilet = (controlDiv, map) => {
      let toiletType = createButton(CONTAINER_TOILET, controlDiv, map);
      toiletType.dataCount = '1';
      return toiletType;
    }

    const createSheToilet = (controlDiv, map) => {
      let toiletType = createButton(SHE_TOILET, controlDiv, map);
      // toiletType.value = 'sheToilet'; // will just add a hidden value
      toiletType.dataCount = '1';
      return toiletType;
    }

    // creating toilet Type Buttons.
    const createToiletTypeDiv = (controlDiv, map) => {

      let appRelevantDataContextValue = context;
      let userFirstName = appRelevantDataContextValue.loggedInUserInfo.userFirstName;

      // Mobile view.
      let onMobileView = window.matchMedia("(max-width: 550px)")
      
      if(onMobileView.matches) { // for Mobile view
        controlDiv.style.display = 'flex';
        controlDiv.style.width = "70%";
        controlDiv.style.overflowX = "scroll";
        controlDiv.style.marginBottom = "0.8rem";

        if(userFirstName.length <=0) { // case where user dont have login credentials.
          document.getElementById("displayMapForDiffScreenOuterDiv").style.height = "100vh";
          document.getElementById("displayMapForDiffScreenInnerDiv").style.height = "100vh";
        } else { // case where user have login credentials.
          document.getElementById("displayMapForDiffScreenOuterDiv").style.height = "100vh";
          document.getElementById("displayMapForDiffScreenInnerDiv").style.height = "100vh";
        }
       
      } else { // for Desktop view
        controlDiv.style.display ="flex";
        controlDiv.style.alignItems = 'start'
        controlDiv.style.justifyContent = 'center'
        controlDiv.style.marginBottom = "1rem";
      }

      controlDiv.appendChild(createAllToilets(controlDiv, map) );
      // To Enable Track GPS (Toilets on wheel) feature Uncomment the bellow line to see Toilets on Wheel option in Toilet Type.
      // controlDiv.appendChild(createWheel(controlDiv, map));
      controlDiv.appendChild(createEToilet(controlDiv, map));
      controlDiv.appendChild(createContainerToilet(controlDiv, map));
      controlDiv.appendChild(createSheToilet(controlDiv, map));
      controlDiv.appendChild(createBrickAndMortar(controlDiv, map));
      controlDiv.appendChild(createAdditionalFilterButton(GANDHVEDH_TOILETS));
      controlDiv.appendChild(createAdditionalFilterButton(HYGIENIC_TOILETS));
      
      return controlDiv;

    }

    const createTrackingDeviceControlDiv = (controlDiv, map) => {

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t; 
    
      let controlUI = document.createElement('BUTTON');
      let controlUI1 = document.createElement('BUTTON');

      controlUI.id = "trackingToilet";

      controlUI.style.borderRadius = '10px';
      controlUI.style.cursor = 'pointer';
      controlUI.style.marginTop = '0.5rem';
      controlUI.style.textAlign = 'center';
      controlUI.style.marginRight = '10px';
      controlUI.style.height = '1.7rem';
      controlUI.style.fontSize = '0.9rem';
      controlUI.style.fontWeight = 'bold';
      controlUI.style.paddingTop = '0.1rem';
      controlUI.style.display = 'inline';
      controlUI.style.color = '#565656';
      controlUI.style.paddingLeft = '1rem';
      controlUI.style.paddingRight = '1rem';

      controlUI.innerHTML = t(IDS_TrackingToilet) + state.toiletToTrack;

      controlUI1.style.borderRadius = '30px';
      controlUI1.style.cursor = 'pointer';
      controlUI1.style.marginBottom = '0.3rem';
      controlUI1.style.textAlign = 'center';
      controlUI1.style.fontWeight = '0.2rem';
      controlUI1.style.marginRight = '5px';
      controlUI1.style.marginLeft = '5px';

      controlUI1.style.marginTop = '0rem';
      controlUI1.style.height = '1.1rem';
      controlUI1.style.fontSize = '0.6rem';
      controlUI1.style.color = 'black';
      controlUI1.style.backgroundColor = 'red';
      controlUI1.style.display = 'inline';
      controlUI1.style.color = 'white';
      controlUI1.style.backgroundColor = 'var(--primaryColor)';
      controlUI1.style.paddingLeft = '0.5rem';
      controlUI1.style.paddingRight = '0.5rem';
      controlUI1.style.fontWeight = 'bold';

      controlUI1.title = t(IDS_StopTracking);
      controlUI1.value = t(IDS_StopTracking); // will just add a hidden value
      controlUI1.innerHTML = t(IDS_StopTracking);

      let onMobileView = window.matchMedia("(max-width: 550px)")
      
      if(onMobileView.matches) { // Mobile view
        controlUI.style.fontSize = '0.8rem';
        controlUI.style.width = '100%';
      }
      controlUI.appendChild(controlUI1);

      controlDiv.appendChild(controlUI);

      controlUI.addEventListener('click', () => { // onclick event
        let modifiedState = state;

        if(state.trackToiletInterval != null) {
          clearInterval(state.trackToiletInterval);
          // clearSearchedMarkerAnimation(modifiedState);
          modifiedState.searchedToiletEntityLocID = "";

          if (state.marker != null) {
            modifiedState.lastSeachedMarkerReference = state.marker;
            state.marker.marker.setAnimation(null);
          } 
          setState({...modifiedState});

        }
        map.controls[window.google.maps.ControlPosition.TOP_CENTER].pop(controlUI);

      });
      return controlDiv;

    }

    const onMapReady = React.useCallback(function callback(map) {

      // setMap(map);

      let centerControlDiv = document.createElement('div');
      let searchControlDiv = document.createElement('div');
      let refreshControlDiv = document.createElement('div');

      // this is for showing ToiletTypes buttons.
      let ToiletTypeDiv = document.createElement('div');

      // centerControl variable value not used currently. (May be Required in future).
      // let centerControl = createCenterControl(centerControlDiv, map);

      // let ToiletMainDiv = createToiletTypeDiv(ToiletTypeDiv, map);
      let refreshMapDataControlDiv = createRefreshMapDataControl(refreshControlDiv, map);
      // let trackingDeviceControlDiv = createTrackingDeviceControlDiv(TrackingControlDiv, map);

      centerControlDiv.index = 1;
      // ToiletTypeDiv.index = 1;

      // map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(searchControlDiv);
      map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(refreshControlDiv);
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
      // map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(ToiletTypeDiv);

      setMap(map);

    }, []);

    

    
    const getRatingDataPerReviewee = (inModifiedState = null) => {

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t;

      let modifiedState;
      if(inModifiedState == null) {
          modifiedState = state;
      } else {
          modifiedState = inModifiedState;
      };

      modifiedState.modal = false;
      // modifiedState.RevieweeID = modifiedState.selectedPlace.EntityLocnID;
      modifiedState.ratingLevel = modifiedState.selectedPlace.RatingLevel;

      if(modifiedState.selectedPlace.EntityLocnID == null || modifiedState.selectedPlace.EntityLocnID.length < 0) {
        console.log('EntityLocnID is not found for selected Toilet');
        setState({...modifiedState});
        return;
      }

      modifiedState.cleanlinessTotalStarRating = 0;
      modifiedState.odourTotalStarRating = 0;
      modifiedState.cleanlinessTotalNumberOfReviews = 0;
      modifiedState.odourTotalNumberOfReviews = 0;
      modifiedState.cleanlinessAvgStarRating = 0;
      modifiedState.odourAvgStarRating = 0;
      modifiedState.ratingDataArr = [];

      let jsonBody = {
        RevieweeID: modifiedState.selectedPlace.EntityLocnID,
        RevieweeType: "Toilet"

      }

      // To get RelevantModelInfo. 
      axios.post( `${getAPIHostURL()}/wclient/getRatingDataPerReviewee`, jsonBody)
      .then(response => {
  
        if(response.data.code == 'SUCCESS') {

          let receivedRatingDataPerReviewee = response.data.retrievedRatingDataPerReviewee;

          if( receivedRatingDataPerReviewee == null || receivedRatingDataPerReviewee.length <= 0 ) {
              console.log("receivedRatingDataPerReviewee data not found on server.");
          } else {

            // In a slow netwrok when we click multiple time on toilet marker. this trigger a multiple Api calls in a sequential row.
            // The result of Api request is take while to response and some inccorect value adds into the bellow variables.
            // Hence we are setting bellow variables to 0 or array to empty array to overcome above mentioned issues.
            modifiedState.cleanlinessTotalStarRating = 0;
            modifiedState.odourTotalStarRating = 0;
            modifiedState.cleanlinessTotalNumberOfReviews = 0;
            modifiedState.odourTotalNumberOfReviews = 0;
            modifiedState.cleanlinessAvgStarRating = 0;
            modifiedState.odourAvgStarRating = 0;
            modifiedState.ratingDataArr = [];

            // Spliting the receivedRatingDataPerReviewee data based on Rating Type Odour and Cleanliness for showing a Avg Star rating for both.
            modifiedState.ratingDataArr = receivedRatingDataPerReviewee;
            for(let i = 0; i < receivedRatingDataPerReviewee.length; i++) {
              if(receivedRatingDataPerReviewee[i]["RatingType"] == "Cleanliness") {
                modifiedState.cleanlinessTotalStarRating += parseInt(receivedRatingDataPerReviewee[i]["SumPerRating"]);
                modifiedState.cleanlinessTotalNumberOfReviews += parseInt(receivedRatingDataPerReviewee[i]["CountPerRating"]);  

              } else {
                modifiedState.odourTotalStarRating += parseInt(receivedRatingDataPerReviewee[i]["SumPerRating"]);
                modifiedState.odourTotalNumberOfReviews += parseInt(receivedRatingDataPerReviewee[i]["CountPerRating"]);  
              }
            }

            // Divide the total rating by total number of reviews gives us Weighted Avg Rating.
            if(modifiedState.cleanlinessTotalStarRating != null && modifiedState.cleanlinessTotalStarRating > 0 &&
              modifiedState.cleanlinessTotalNumberOfReviews != null && modifiedState.cleanlinessTotalNumberOfReviews > 0
            ) {
              modifiedState.cleanlinessAvgStarRating = modifiedState.cleanlinessTotalStarRating / modifiedState.cleanlinessTotalNumberOfReviews;
              modifiedState.cleanlinessAvgStarRating = Math.round(modifiedState.cleanlinessAvgStarRating * 10) / 10;
            } else {
              modifiedState.cleanlinessAvgStarRating = 0;
            }

            // only in case of Star Rating (5 Level), 
            // Divide the total rating by total number of reviews gives us Weighted Avg Rating.
            if(modifiedState.odourTotalStarRating != null && modifiedState.odourTotalStarRating > 0 &&
              modifiedState.odourTotalNumberOfReviews != null && modifiedState.odourTotalNumberOfReviews > 0
            ) {
              modifiedState.odourAvgStarRating = modifiedState.odourTotalStarRating / modifiedState.odourTotalNumberOfReviews;
              modifiedState.odourAvgStarRating = Math.round(modifiedState.odourAvgStarRating * 10) / 10;
            } else {
              modifiedState.odourAvgStarRating = 0;
            }
          }

          // Bellow function will fetch people counter data from server. currently we are hiding people counter feature from Map. 
          // If you want to show people counter feature then uncomment this below function the people counter feature will start working 
          // automatically in the mapped toilets locations also you need to comment below setState code.
          // getCrossingCountInfo(modifiedState)

          // If you are enabling people counter feature please comment bellow line setState code of axios success block.
          // setState({...modifiedState});

          // We are fetching latest 
          if(modifiedState.onRefreshGetLatestToiletDataOfMap == true) {
            modifiedState.onRefreshGetLatestToiletDataOfMap = false;
            getMappedDeviceData(modifiedState);
          } else {
            modifiedState.onRefreshGetLatestToiletDataOfMap = false;
            setState({...modifiedState});
          }
          
        } else {
          if(response.data.code == 'REQ_PARAMS_MISSING') {
            // Let the user know that the Required parameters were not sent to the Server
            // modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
            modifiedState.subscriptionErr = t(IDS_AUSrvrIssueReqParamsNotSentForSubsInfo);
          } else if(response.data.code == 'SQL_ERROR') {
              // Tell the user that Server is experiencing errors
              // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
              modifiedState.errors.others = t(IDS_LoginServerIssue);

            } else {
              console.log('Should not reach here');
              // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          
          setState({...modifiedState});
        }
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        if (axios.isCancel(error)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          // Tell the user that there are network issues
          // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
          modifiedState.errors.others = t(IDS_RegistNetworkError);

          setState({...modifiedState});
        }
      }); 
    }

    const getLatestRatingDataOnSucessToiletRating = () => {
      getRatingDataPerReviewee();
    }


    const getSignalCountOfRating = (inputRatingType, starWeightage, barColor, signalType) => {

      let singleCountPerRating = 0;
      for(let i = 0; i < state.ratingDataArr.length; i++) {
        // In case of the Signal Rating Level we are merging Rating 4 and rating 5 value (if exists) into the Rating 3
        // in order to avoid wrong percentage total for all the 3 Level of the rating.
        if(state.ratingDataArr[i]["RatingType"] == 'Cleanliness' && inputRatingType == 'Cleanliness') {
          if(state.ratingDataArr[i]["Rating"] == starWeightage) {
            singleCountPerRating = (parseInt(state.ratingDataArr[i]["CountPerRating"]));
            if(starWeightage != 3) {
              break;
            }
          } else if((starWeightage == 3 ) && (state.ratingDataArr[i]["Rating"] == 4 || state.ratingDataArr[i]["Rating"] == 5)) {
            singleCountPerRating += (parseInt(state.ratingDataArr[i]["CountPerRating"]));
          } else {
            singleCountPerRating = 0;
          }
        }
        if(state.ratingDataArr[i]["RatingType"] == 'Odour' && inputRatingType == 'Odour') {
          if(state.ratingDataArr[i]["Rating"] == starWeightage) {
            singleCountPerRating = (parseInt(state.ratingDataArr[i]["CountPerRating"]));
            if(starWeightage != 3) {
              break;
            }
          } else if((starWeightage == 3 ) && (state.ratingDataArr[i]["Rating"] == 4 || state.ratingDataArr[i]["Rating"] == 5)) {
            singleCountPerRating += (parseInt(state.ratingDataArr[i]["CountPerRating"]));
          } else {
            singleCountPerRating = 0;
          }
        } 
      }

      let showSignalRating = 
        <div style={{marginTop: "0.2rem", display: "flex"}}>
          <FaCircle style={{color: barColor, fontSize:"1rem", marginRight:"0.2rem"}}/>
            {signalType} ({singleCountPerRating})
        </div>

      return showSignalRating;
    }


    const getSingleStarRatingPercentage = (inputRatingType, starWeightage, barColor, ratingLevel, levelText) => {

      let singleStarRatingPercent = 0;
      let singleCountPerRating = 0;
      for(let i = 0; i < state.ratingDataArr.length; i++) {
        if(ratingLevel == DEFAULT_RATING_LEVEL_STARS) {
        if(state.ratingDataArr[i]["RatingType"] == 'Cleanliness' && inputRatingType == 'Cleanliness') {
          if(state.ratingDataArr[i]["Rating"] == starWeightage) {
            singleStarRatingPercent = ((parseInt(state.ratingDataArr[i]["CountPerRating"]) / state.cleanlinessTotalNumberOfReviews) * 100);
            break;
          } else {
            singleStarRatingPercent = 0;
          }
        } else if(state.ratingDataArr[i]["RatingType"] == 'Odour' && inputRatingType == 'Odour') {
          if(state.ratingDataArr[i]["Rating"] == starWeightage) {
            singleStarRatingPercent = ((parseInt(state.ratingDataArr[i]["CountPerRating"]) / state.odourTotalNumberOfReviews) * 100);
            break;
          } else {
            singleStarRatingPercent = 0;
          }
        } else {
          singleStarRatingPercent = 0;
        }
      // In case of the Signal Rating Level we are merging Rating 4 and rating 5 value (if exists) into the Rating 3
      // in order to avoid wrong percentage total for all the 3 Level of the rating.
       } else {
          if(state.ratingDataArr[i]["RatingType"] == 'Cleanliness' && inputRatingType == 'Cleanliness') {
            if(state.ratingDataArr[i]["Rating"] == starWeightage) {
              singleCountPerRating = (parseInt(state.ratingDataArr[i]["CountPerRating"]));
              if(starWeightage != 3) {
                break;
              }
            } else if((starWeightage == 3 ) && (state.ratingDataArr[i]["Rating"] == 4 || state.ratingDataArr[i]["Rating"] == 5)) {
              singleCountPerRating += (parseInt(state.ratingDataArr[i]["CountPerRating"]));
            } else {
              singleCountPerRating = 0;
            }
          }
          if(state.ratingDataArr[i]["RatingType"] == 'Odour' && inputRatingType == 'Odour') {
            if(state.ratingDataArr[i]["Rating"] == starWeightage) {
              singleCountPerRating = (parseInt(state.ratingDataArr[i]["CountPerRating"]));
              if(starWeightage != 3) {
                break;
              }
            } else if((starWeightage == 3 ) && (state.ratingDataArr[i]["Rating"] == 4 || state.ratingDataArr[i]["Rating"] == 5)) {
              singleCountPerRating += (parseInt(state.ratingDataArr[i]["CountPerRating"]));
            } else {
              singleCountPerRating = 0;
            }
          } 
        }
      }

      // In case of only Rating Level Signal, 
      // find out the no of percentage for each Level and show the same.
      if(ratingLevel == DEFAULT_RATING_LEVEL_SIGNAL) {
        singleStarRatingPercent = singleCountPerRating > 0 ? 
          (singleCountPerRating / state.cleanlinessTotalNumberOfReviews * 100) :
          singleCountPerRating;
      }

      singleStarRatingPercent = Math.round(singleStarRatingPercent);

      let showReviewPercentageBar = 
        <div style={{marginTop: "0.2rem", display: "flex"}}>
          <div style={{flex: "1",fontSize: "0.8rem", fontWeight: "bold"}}> 
          {
            (ratingLevel == DEFAULT_RATING_LEVEL_SIGNAL) ?  levelText : starWeightage + " " + levelText
          }
          </div>
          <div style={{flex: "4"}} >
            <Progress 
            // color= {barColor}
            barStyle = {{backgroundColor:barColor}}
            value = {singleStarRatingPercent}
          />
          </div>
          <div style={{flex: "1",fontSize: "0.8rem", fontWeight: "bold"}}> 
            {singleStarRatingPercent} %
          </div>
        </div>  

      return showReviewPercentageBar;
    }

    const getCrossingCountInfo = (inModifiedState = null) => {

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t;

      let modifiedState;
      if(inModifiedState == null) {
          modifiedState = state;
      } else {
          modifiedState = inModifiedState;
      };

      modifiedState.modal = false;
      modifiedState.totalTodaysPeopleCount = null;

      if(modifiedState.selectedPlace.EntityLocnID == null || modifiedState.selectedPlace.EntityLocnID.length < 0) {
        console.log('EntityLocnID is not found for selected Toilet');
        setState({...modifiedState});
        return;
      }

      let currentBrowserDtTm = new Date();
      let strCurrentBrowserDtTm = convertLocalDateToStrYYYYMMDDHH24MMSS(currentBrowserDtTm);
      // let crossingCountBrowserStartDtTm = strCurrentBrowserDtTm.split(" ")[0] + " 00:00:00";
      // let crossingCountBrowserEndDtTm = strCurrentBrowserDtTm.split(" ")[0] + " 23:59:59";
      let crossingCountBrowserStartDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "00:00:00";
      let crossingCountBrowserEndDtTm = strCurrentBrowserDtTm.split(" ")[0] + "T" + "23:59:59";

      let crossingCountUtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserStartDtTm.valueOf()));
      let crossingCountUtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(crossingCountBrowserEndDtTm.valueOf()));

      let jsonBody = {
          EntityLocationId: modifiedState.selectedPlace.EntityLocnID,
          StartDtTmUTC: crossingCountUtcStartDtTm,
          EndDtTmUTC: crossingCountUtcEndDtTm,
      }

      // To get getCrossingCountInfo. 
      axios.post( `${getAPIHostURL()}/wclient/getCrossingCountInfo`, jsonBody)
      .then(response => {
  
        if(response.data.code == 'SUCCESS') {
          modifiedState.totalTodaysPeopleCount = null;
          modifiedState.totalTodaysPeopleCount = response.data.InOutCount;
          
        } else {
          if(response.data.code == 'REQ_PARAMS_MISSING') {
            // Let the user know that the Required parameters were not sent to the Server
            modifiedState.subscriptionErr = t(IDS_AUSrvrIssueReqParamsNotSentForSubsInfo);
          } else if(response.data.code == 'SQL_ERROR') {
              // Tell the user that Server is experiencing errors
              modifiedState.errors.others = t(IDS_LoginServerIssue);

            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          // Display the specific error message for login failure.
        }
        setState({...modifiedState});
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        if (axios.isCancel(error)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          // Tell the user that there are network issues
          modifiedState.errors.others = t(IDS_RegistNetworkError);

          setState({...modifiedState});
        }
      }); 
    }

    const onClickRefreshRating = () => {
      // onRefreshClick get immediately Updated Rating and People count Data.
      let modifiedState = state;
      modifiedState.onRefreshGetLatestToiletDataOfMap = true;
      getRatingDataPerReviewee(modifiedState);
    }

    const onChangeDeviceIDToSearch = (e) => {

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t;

      let modifiedState = state;    
      let searchString = e.target.value;

      modifiedState.searchedDevicesArrList = [];

      if (searchString == null || searchString.length < 0) {
        modifiedState.searchDeviceString = '';
      } else {
        modifiedState.errors.searchDeviceStringErr = searchString.length > 0 && searchString.length < 3  ? t(IDS_EnterSearchChar) : '';
        modifiedState.searchDeviceString = searchString;
  
      }
      setState({...modifiedState});
    }

    const onChangeToiletNameToSearch = (e) => {

      let appRelevantDataContextValue = context;
      let t = appRelevantDataContextValue.t;

      let modifiedState = state;    
      let searchString = e.target.value;

      modifiedState.searchedDevicesArrList = [];

      if (searchString == null || searchString.length < 0) {
        modifiedState.searchDeviceString = '';
        
      } else {
        modifiedState.errors.searchDeviceStringErr = searchString.length > 0 &&  searchString.length < 3 ? t(IDS_EnterSearchChar) : '';
        modifiedState.searchToiletNameString = searchString;
      }

      setState({...modifiedState});
    }

  
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t; 

    let userFirstName = appRelevantDataContextValue.loggedInUserInfo.userFirstName;

    const center = {
      lat: state.CurrentLocationLat,
      lng: state.CurrentLocationLon
    };
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])

    return (
      <div>
        <div id="Disablelocation" style={{backgroundColor:"gold", color:"black"  }} ></div>
        <div id = "displayMapForDiffScreenOuterDiv" >
          
          <div id = "displayMapForDiffScreenInnerDiv" >
            {state.errors["noLocPermissonEnabled"].length > 0 &&
              <div style={{color: 'red'}} >{state.errors["noLocPermissonEnabled"]}</div>
            }
            {/* add map here */}
            {isLoaded ?
            <GoogleMap
              id="Gmap"
              mapContainerStyle={{
                height:(userFirstName.length > 0 && (state.errors["others"].length > 0 || state.errors["noLocPermissonEnabled"].length > 0)) ? "96vh" : 
                          (userFirstName.length <= 0 && (state.errors["others"].length > 0 || state.errors["noLocPermissonEnabled"].length > 0)) ? "96vh" : 
                          (userFirstName.length <= 0) ? "100vh": (userFirstName.length > 0) ? "100vh" : 
                          "100vh",
                width: '100%'
              }}
              center={{
                lat: Number(state.ChangedPositionLat),
                lng: Number(state.ChangedPositionLon)
              }} 
              zoom={14}
              // onZoomChanged = {getDeviceIDsForCurrentlyVisibleMarkers(map, state)}         
              // onDragEnd={onDragEnd}
              streetView={false}
              onUnmount={onUnmount}
              onLoad={onMapReady}
            >
              { /* Child components, such as markers, info windows, etc. */ }

              <Marker 
                name={'current location'}
                icon={currentLocation} 
                position={{lat: state.CurrentLocationLat, lng: state.CurrentLocationLon}}
              />

                {renderMarkersWithToiletsAndApplicableDevice()}

                <div className="col-md parameters-first-row d-flex justify-content-center">
                  <Modal placement="top" isOpen={state.ratingSummaryOpen} 
                            // autohide={false}
                            target="InfoWindowRating" 
                            toggle={ratingSummaryToggle} className="mycontent">
                      <div style = {{alignItems: "center", textAlign: "center"}}>
                        { state.totalTodaysPeopleCount != null &&
                          <div style = {{marginTop: "0.5rem", fontSize: "0.9rem"}}>
                            {t(IDS_TodayPeopleCount)} : <b>{state.totalTodaysPeopleCount}</b>
                            <hr style={{margin:"0.3rem 0rem"}}/>
                          </div>
                        }
                        <div style = {{marginTop: "0.3rem", fontSize: "0.9rem"}}>
                            {t(IDS_TotalNoOfRating)} : <b>{state.odourTotalNumberOfReviews}</b>
                        </div>  
                        {state.ratingLevel != null && state.ratingLevel.length > 0 && state.ratingLevel == DEFAULT_RATING_LEVEL_STARS
                        ?
                          <div>
                            <div style={{display: "flex", fontSize:"0.7rem", justifyContent: "center", marginTop: "0.3rem"}}>
                              <div style={{paddingRight: "0.2rem", fontSize: "0.9rem", fontWeight: "bold"}}>
                                {t(IDS_Odour)} :
                              </div>
                              <StarRatings
                                rating={state.odourAvgStarRating}
                                numberOfStars={5}
                                isSelectable = {false}
                                starDimension = '1rem'
                                starSpacing = '1px'
                                starRatedColor = '#FDCC0D'
                                starEmptyColor = 'lightGray'
                                name='ToiletSideBarOdourRating'
                                style = {{flexShrink: "0"}}
                              />
                              <div style={{paddingLeft: "0.4rem", fontSize: "0.9rem", fontWeight: "bold"}}>
                                ({state.odourAvgStarRating} / 5) 
                              </div>
                            </div>

                            <div style={{display: "flex", justifyContent: "center", fontSize:"0.7rem", marginbottom: "0.3rem", marginTop: "0.1rem", padding: "0rem"}}>
                              <div style={{paddingRight: "0.2rem", fontSize: "0.9rem", fontWeight: "bold"}}>
                              {t(IDS_Cleanliness)} :
                              </div>
                              <StarRatings
                                rating={state.cleanlinessAvgStarRating}
                                numberOfStars={5}
                                isSelectable = {false}
                                starDimension = '1rem'
                                starSpacing = '1px'
                                starRatedColor = '#FDCC0D'
                                starEmptyColor = 'lightGray'
                                name='ToiletSideBarCleanlinessRating'
                              />
                              <div style={{paddingLeft: "0.4rem", fontSize: "0.9rem", fontWeight: "bold"}}>
                                ({state.cleanlinessAvgStarRating} / 5) 
                              </div>
                            </div>
                          </div>
                        :
                          <div className="AddressInfo" style={{fontSize: "0.9rem", marginTop:"0.2rem",  display: "flex"}}>
                              <div style ={{ flex: "1", justifyContent: "flex-start"}}>
                                <div style={{paddingRight: "0.2rem", paddingTop: "0.1rem", fontSize: "0.8rem", fontWeight: "bold", textAlign:"left"}}>
                                  {t(IDS_Odour)} :
                                </div>
                                <div style = {{textAlign: "left"}}>
                                  {getSignalCountOfRating("Odour", 3, "green", t(IDS_GoodRating))}
                                  {getSignalCountOfRating("Odour", 2, "yellow", t(IDS_Normal))}
                                  {getSignalCountOfRating("Odour", 1, "red", t(IDS_Bad))}
                                </div>
                            </div>
                          
                            <div style={{flex: "1"}}>
                              <div style={{paddingRight: "0.2rem", paddingTop: "0.1rem", fontSize: "0.8rem", fontWeight: "bold", textAlign:"left"}}>
                                {t(IDS_Cleanliness)} :
                              </div>
                                <div style = {{textAlign: "left"}}>
                                  {getSignalCountOfRating("Cleanliness", 3, "green", t(IDS_GoodRating))}
                                  {getSignalCountOfRating("Cleanliness", 2, "yellow", t(IDS_Normal))}
                                  {getSignalCountOfRating("Cleanliness", 1, "red", t(IDS_Bad))}
                                </div>
                            </div>
                          </div>
                        }
                        <div className="AddressInfo" style={{fontSize: "0.9rem", marginTop: "0.6rem", }}>
                            {t(IDS_OdourRatingSummary)}
                          {state.ratingLevel != null && state.ratingLevel.length > 0 && state.ratingLevel == DEFAULT_RATING_LEVEL_STARS ?
                            <div style={{marginBottom: "0.4rem"}}>
                              {getSingleStarRatingPercentage("Odour", 5, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                              {getSingleStarRatingPercentage("Odour", 4, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                              {getSingleStarRatingPercentage("Odour", 3, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                              {getSingleStarRatingPercentage("Odour", 2, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                              {getSingleStarRatingPercentage("Odour", 1, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                            </div>
                          :
                            <div style={{marginBottom: "0.4rem"}}>
                              {getSingleStarRatingPercentage("Odour", 3, "green", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_GoodRating))}
                              {getSingleStarRatingPercentage("Odour", 2, "yellow", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_Normal))}
                              {getSingleStarRatingPercentage("Odour", 1, "red", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_Bad))}
                            </div>
                          }
                        </div>
                        <div className="AddressInfo" style={{fontSize: "0.9rem", marginTop: "0.2rem", }}>
                          {t(IDS_CleanlinessRatingSummary)}
                          {state.ratingLevel != null && state.ratingLevel.length > 0 && state.ratingLevel == DEFAULT_RATING_LEVEL_STARS ?
                            <div style={{marginBottom: "0.4rem"}}>
                              {getSingleStarRatingPercentage("Cleanliness", 5, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                              {getSingleStarRatingPercentage("Cleanliness", 4, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                              {getSingleStarRatingPercentage("Cleanliness", 3, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                              {getSingleStarRatingPercentage("Cleanliness", 2, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                              {getSingleStarRatingPercentage("Cleanliness", 1, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                            </div>
                          :
                            <div style={{marginBottom: "0.4rem"}}>
                              {getSingleStarRatingPercentage("Cleanliness", 3, "green", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_GoodRating))}
                              {getSingleStarRatingPercentage("Cleanliness", 2, "yellow", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_Normal))}
                              {getSingleStarRatingPercentage("Cleanliness", 1, "red", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_Bad))}
                            </div>
                          }
                        </div>
                    </div>
                  </Modal>
                </div>

                <Modal size="xl" isOpen = {state.modal} toggle = {toggle} backdrop = {state.backdrop}>
                  <ModalHeader toggle = {toggle} >{t(IDS_RateThisToilet)}</ModalHeader>
                  <ModalBody>  
                      {/* <div style={{width: "100%", height: "75vh", overflow: "auto"}}> */}
                    <VcRateToilet EntityLocnID = {state.SelectedEntityLocnID} invokedFrom = "MapToilet" SelectedToiletName={state.SelectedToiletName} 
                                  toggle = {toggle} getLatestRatingDataOnSucessToiletRating = {getLatestRatingDataOnSucessToiletRating}
                    />
                      {/* </div> */}
                  </ModalBody>
                </Modal>

              <></>
            </GoogleMap>
            : <></>
            }
          </div>
          
        </div>
        
        {state.errors["others"].length > 0 &&
            <div style={{color: 'green', marginTop:"0.3rem"}} >{state.errors["others"]}</div>
        }

        {state.sidebarOpen 
        ?             
          <Sidebar
            sidebarClassName = "sidebar"
            styles={{right: "0"}}
            sidebar={
              <div className="sidebarInfo" style={{minWidth:"25rem"}}>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div className="sidebarBtn" style={{justifyContent: "flex-start"}}>
                      <button className="deviceTreeRefreshButton" id= "refreshRatingData" style={{marginLeft: "0rem", paddingLeft: "0rem", paddingRight: "0.5rem"}}  onClick={onClickRefreshRating} title={t(IDS_RefreshCountAndRating)}>
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="sidebarBtn">
                      <button onClick={onSideBarClose}
                              className ="btnCloseSideBar"
                      > X
                      </button>
                    </div>
                </div>

                <div>
                    <div style={{fontWeight:700, color:"#424242",fontSize:"1.8rem", textTransform:"capitalize", textAlign:"center", padding:"0rem 0.5rem"}}> {state.selectedPlace["name"]}</div>
                </div>
                { state.totalTodaysPeopleCount != null &&
                  <div style = {{marginTop: "0.5rem", fontSize: "0.9rem"}}>
                    <hr style={{margin:"0.3rem 0rem"}}/>
                    {t(IDS_TodayPeopleCount)} : <b>{state.totalTodaysPeopleCount}</b>
                  </div>
                }
                <hr style={{margin:"0.3rem 0rem"}}/>
                <div style = {{marginTop: "0.3rem", fontSize: "0.9rem"}}>
                  {t(IDS_TotalNoOfRating)} : <b>{state.odourTotalNumberOfReviews}</b>

                </div>  
                {state.ratingLevel != null && state.ratingLevel.length > 0 && state.ratingLevel == DEFAULT_RATING_LEVEL_STARS
                ?
                <div style={{textAlign: "center"}}>
                  <div style={{display: "flex", fontSize:"0.7rem", justifyContent: "center", marginTop: "0.3rem"}}>
                    <div style={{paddingRight: "0.2rem", fontSize: "0.9rem", fontWeight: "bold"}}>
                      {t(IDS_Odour)} :
                    </div>
                    <StarRatings
                      rating={state.odourAvgStarRating}
                      numberOfStars={5}
                      isSelectable = {false}
                      starDimension = '1rem'
                      starSpacing = '1px'
                      starRatedColor = '#FDCC0D'
                      starEmptyColor = 'lightGray'
                      name='ToiletSideBarOdourRating'
                      style = {{flexShrink: "0"}}
                    />
                    <div style={{paddingLeft: "0.4rem", fontSize: "0.9rem", fontWeight: "bold"}}>
                      ({state.odourAvgStarRating} / 5) 
                    </div>
                  </div>

                  <div style={{display: "flex", justifyContent: "center", fontSize:"0.7rem", marginbottom: "0.3rem", marginTop: "0.1rem", padding: "0rem"}}>
                    <div style={{paddingRight: "0.2rem", fontSize: "0.9rem", fontWeight: "bold"}}>
                    {t(IDS_Cleanliness)} :
                    </div>
                    <StarRatings
                      rating={state.cleanlinessAvgStarRating}
                      numberOfStars={5}
                      isSelectable = {false}
                      starDimension = '1rem'
                      starSpacing = '1px'
                      starRatedColor = '#FDCC0D'
                      starEmptyColor = 'lightGray'
                      name='ToiletSideBarCleanlinessRating'
                    />
                    <div style={{paddingLeft: "0.4rem", fontSize: "0.9rem", fontWeight: "bold"}}>
                      ({state.cleanlinessAvgStarRating} / 5) 
                    </div>
                  </div>
                </div>
                :
                <div className="AddressInfo" style={{fontSize: "0.9rem", display: "flex", marginTop: "0.2rem", minWidth:"20rem"}}>
                    <div style ={{ flex: "1", justifyContent: "flex-start"}}>
                      <div style={{paddingRight: "0.2rem", paddingTop: "0.1rem", fontSize: "0.8rem", fontWeight: "bold", textAlign:"left"}}>
                        {t(IDS_Odour)} :
                      </div>
                      <div style = {{textAlign: "left"}}>
                        {getSignalCountOfRating("Odour", 3, "green", t(IDS_GoodRating))}
                        {getSignalCountOfRating("Odour", 2, "yellow", t(IDS_Normal))}
                        {getSignalCountOfRating("Odour", 1, "red", t(IDS_Bad))}
                      </div>
                  </div>
                
                  <div style={{flex: "1"}}>
                    <div style={{paddingRight: "0.2rem", paddingTop: "0.1rem", fontSize: "0.8rem", fontWeight: "bold", textAlign:"left"}}>
                      {t(IDS_Cleanliness)} :
                    </div>
                      <div style = {{textAlign: "left"}}>
                        {getSignalCountOfRating("Cleanliness", 3, "green", t(IDS_GoodRating))}
                        {getSignalCountOfRating("Cleanliness", 2, "yellow", t(IDS_Normal))}
                        {getSignalCountOfRating("Cleanliness", 1, "red", t(IDS_Bad))}
                      </div>
                  </div>
                </div>
                }

                <div className="AddressInfo" style={{fontSize: "0.9rem", marginTop: "0.6rem"}}>
                    {t(IDS_OdourRatingSummary)}
                  {state.ratingLevel != null && state.ratingLevel.length > 0 && state.ratingLevel == DEFAULT_RATING_LEVEL_STARS ?
                    <div style={{marginBottom: "0.4rem"}}>
                      {getSingleStarRatingPercentage("Odour", 5, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                      {getSingleStarRatingPercentage("Odour", 4, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                      {getSingleStarRatingPercentage("Odour", 3, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                      {getSingleStarRatingPercentage("Odour", 2, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                      {getSingleStarRatingPercentage("Odour", 1, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                    </div>
                  :
                    <div style={{marginBottom: "0.4rem"}}>
                      {getSingleStarRatingPercentage("Odour", 3, "green", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_GoodRating))}
                      {getSingleStarRatingPercentage("Odour", 2, "yellow", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_Normal))}
                      {getSingleStarRatingPercentage("Odour", 1, "red", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_Bad))}
                    </div>
                  }

                </div>
                <div className="AddressInfo" style={{fontSize: "0.9rem", marginTop: "0.2rem", }}>
                  {t(IDS_CleanlinessRatingSummary)}
                  {state.ratingLevel != null && state.ratingLevel.length > 0 && state.ratingLevel == DEFAULT_RATING_LEVEL_STARS ?
                    <div style={{marginBottom: "0.4rem"}}>
                      {getSingleStarRatingPercentage("Cleanliness", 5, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                      {getSingleStarRatingPercentage("Cleanliness", 4, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                      {getSingleStarRatingPercentage("Cleanliness", 3, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                      {getSingleStarRatingPercentage("Cleanliness", 2, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                      {getSingleStarRatingPercentage("Cleanliness", 1, "orange", DEFAULT_RATING_LEVEL_STARS, "Star")}
                    </div>
                  :
                    <div style={{marginBottom: "0.4rem"}}>
                      {getSingleStarRatingPercentage("Cleanliness", 3, "green", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_GoodRating))}
                      {getSingleStarRatingPercentage("Cleanliness", 2, "yellow", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_Normal))}
                      {getSingleStarRatingPercentage("Cleanliness", 1, "red", DEFAULT_RATING_LEVEL_SIGNAL, t(IDS_Bad))}
                    </div>
                  }

                </div>

                <div style={{marginTop: "0.5rem", marginbottom: "0.3rem", padding: "0rem", fontSize: "0.8rem"}}>
                    <a href ="#" id="moreDetailsButton" className = "textDecoNone" style={{fontSize: "0.9rem"}} onClick={toggle}>
                      <b>{state.cleanlinessAvgStarRating == 0 && state.odourAvgStarRating == 0 ? t(IDS_RateThisToilet) : t(IDS_RateThisToilet)}</b>
                    </a>
                </div>
                <hr style={{margin:"0.3rem 0rem"}}/>
                <div className="AddressInfo">
                  <FaDirections onClick={showDirectionOnMap} className="DirectionIcon" title = "Direction"/>

                  <div className="AddressComp">
                    {/* <div style={{textAlign:"center"}}>Address</div> */}
                    <div style={{textAlign:"center", textTransform: "capitalize", fontSize:"1rem"}}> {state.selectedPlace["Address1"]},&nbsp;{state.selectedPlace["Address2"]}</div>
                  </div>
                  <hr style={{margin:"0.3rem 0rem"}}/>
                  <div className="AddrMainDiv">
                    <div className="AddrSubComp1">City:&nbsp;</div>
                    <div className="AddrSubComp2">{state.selectedPlace["City"]}</div>
                  </div>
                  <div className="AddrMainDiv">
                    <div className="AddrSubComp1">District:&nbsp;</div>
                    <div className="AddrSubComp2">{state.selectedPlace["District"]}</div>
                  </div>
                  <div className="AddrMainDiv">
                    <div className="AddrSubComp1">State:&nbsp;</div>
                    <div className="AddrSubComp2">{state.selectedPlace["State"]}</div>
                  </div>
                  <div className="AddrMainDiv">
                    <div className="AddrSubComp1">Pincode:&nbsp;</div>
                    <div className="AddrSubComp2">{state.selectedPlace["PinCode"]}</div>
                  </div>
                </div>
                <hr style={{margin:"0.3rem 0rem"}}/>
                {state.selectedPlace["DeviceID"] != null && state.selectedPlace["DeviceID"].length > 1 ? 
                  state.selectedPlace["DeviceID"].map((singleDevc, id) => {
                    return(
                      <div key={id}
                          className="DeviceStatusBox" 
                          style={{ borderWidth: state.selectedPlace["Level"][id] == "(Level 2)" && 
                                                state.selectedPlace["color"][id] == "#ffff00" && `2.5px 5px 5px 2.5px`,
                                  borderStyle: state.selectedPlace["Level"][id] == "(Level 2)" && 
                                                state.selectedPlace["color"][id] == "#ffff00" && `solid`,
                                  borderColor: state.selectedPlace["Level"][id] == "(Level 2)" && 
                                                state.selectedPlace["color"][id] == "#ffff00" && `${state.selectedPlace["color"][id]}`,
                                  boxShadow: state.selectedPlace["Level"][id] == "(Level 2)" && 
                                            state.selectedPlace["color"][id] == "#ffff00" ?`inset 0.5px 0.5px 1px 1px #D3D3D3, 1px 1px 2px 2px #D3D3D3`
                                                                                                : `1px 1px 5px 2px ${state.selectedPlace["color"][id]}`}}
                      >
                        <div>{state.selectedPlace.LogTime[id]}</div>
                        <div className="mapDeviceName">{state.selectedPlace["DeviceName"][id]}</div>
                        {state.selectedPlace["DeviceID"] != null ? <hr style={{marginBottom:"0rem"}}/> : null}
                        {/* <hr style={{marginBottom:"0rem"}}/> */}
                        <div className="mapEmojiIcon"
                              style={{color:state.selectedPlace["color"][id]}}> 
                              {state.selectedPlace["EmojiIcon"][id]}
                        </div>
                        <div className= "DeviceStatus"
                              style={{color:"#808080", fontWeight:"600", fontSize:"1.2rem"}}>
                              {state.selectedPlace["LevelType"][id]}
                        </div>
                        <div className= "DeviceStatus"
                              style={{color:"#808080", fontSize:"0.9rem"}}>
                                {state.selectedPlace["Level"][id]}
                        </div>
                      </div>
                    )
                  })
                :
                <div className="DeviceStatusBox" 
                    style={{ 
                      borderWidth: state.selectedPlace["color"] == "#ffff00" &&
                                    state.selectedPlace["Level"] == "(Level 2)" && `2.5px 5px 5px 2.5px`,
                      borderStyle:state.selectedPlace["color"] == "#ffff00" &&
                                  state.selectedPlace["Level"] == "(Level 2)" && `solid`,
                      borderColor: state.selectedPlace["color"] == "#ffff00" &&
                                    state.selectedPlace["Level"] == "(Level 2)" && `${state.selectedPlace["color"]}`,
                      boxShadow: state.selectedPlace["color"] == "#ffff00" && 
                                  state.selectedPlace["Level"] == "(Level 2)" ? `inset 0.5px 0.5px 1px 1px #D3D3D3, 1px 1px 2px 2px #D3D3D3`
                                                                                    : `1px 1px 5px 2px ${state.selectedPlace["color"]}`
                    }}
                >
                    <div>{state.selectedPlace.LogTime}</div>
                    <div className="mapDeviceName">{state.selectedPlace["DeviceName"]}</div>
                    {state.selectedPlace["DeviceID"] != null ? <hr style={{marginBottom:"0rem"}}/> : null}
                    {/* <hr style={{marginBottom:"0rem"}}/> */}
                    <div className="mapEmojiIcon"
                          style={{color:state.selectedPlace["color"]}}> 
                          {state.selectedPlace["EmojiIcon"]}
                    </div>
                    <div className= "DeviceStatus"
                          style={{color:"#808080", fontWeight:"600", fontSize:"1.2rem"}}>
                          {state.selectedPlace["LevelType"]}
                    </div>
                    <div className= "DeviceStatus"
                          style={{color:"#808080", fontSize:"0.9rem"}}>
                            {state.selectedPlace["Level"]}
                    </div>
                </div>
                }
            </div>
            }
            open={state.sidebarOpen}
            transitions= {true}
          >
          <div/>
          </Sidebar>
        : 
          null}            
      </div>

    )
}

export default VcMapsTrackDevice;
