import React, { Component } from 'react'
import { FaQrcode } from 'react-icons/fa';
// import {QrReader} from '@otterscan/react-qr-reader'
import {QrScanner} from '@yudiel/react-qr-scanner';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext} from '../../AppContext';

export class VcAddOrRemoveRawDataParams extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
             EnteredDeviceID: '',
             manuallyAddedDeviceIDArrForDisplayBox: [],
             DeviceIdCount: "", 
             rplcDevcQRCodeFlag: false,
             invokedFrom: this.props.invokedFrom,
             errors: { 
                others: '',
                invalidDevcID: '',
            },
        }
    }

    componentDidUpdate () {
        let modifiedState = this.state;
        if(modifiedState.invokedFrom != this.props.invokedFrom) {
            modifiedState.EnteredDeviceID = "";
            modifiedState.manuallyAddedDeviceIDArrForDisplayBox = [];
            modifiedState.invokedFrom = "";
            modifiedState.errors.others = "";
            modifiedState.errors.invalidDevcID = "";
            modifiedState.DeviceIdCount = "";
            modifiedState.invokedFrom = this.props.invokedFrom;
            this.setState(modifiedState);
            return;
        } else {
            return;
        }
    }

    onEnterDeviceID = (e) => {
        let modifiedState = this.state;
        modifiedState.EnteredDeviceID = e.target.value;
        modifiedState.EnteredDeviceID = modifiedState.EnteredDeviceID.toUpperCase();
        modifiedState.errors.others = '';
        modifiedState.errors.invalidDevcID = '';
        this.setState(modifiedState);  
    }
 
    handleKeyDown = (e) => {
        if (e.key == 'Enter') {
            // This is just to prevent form submission on pressing "enter".
            e.preventDefault();
            return;
        }
    }

    openQRCameraForDeviceRawData = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 
        
        this.setState(prevState => {
            let modifiedState = prevState;
            modifiedState.rplcDevcQRCodeFlag = !modifiedState.rplcDevcQRCodeFlag;
            modifiedState.errors.others = "";
            modifiedState.errors.invalidDevcID = "";
            return modifiedState
        })
    }

    handleError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    handleScanResultOfDevciceRawData = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.EnteredDeviceID = data;
            modifiedState.EnteredDeviceID = modifiedState.EnteredDeviceID.toUpperCase();
            modifiedState.rplcDevcQRCodeFlag = false;
            modifiedState.errors.others = "";
            modifiedState.errors.invalidDevcID = "";
            if((modifiedState.EnteredDeviceID == null || modifiedState.EnteredDeviceID.length <= 0)) {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again."
            } 
            this.setState(modifiedState);
        }
    }
    
    onClickAddDeviceValidation = () => {
        let modifiedState = this.state;

        modifiedState.errors.invalidDevcID = "";
        modifiedState.errors.others = "";

        if(modifiedState.manuallyAddedDeviceIDArrForDisplayBox.indexOf(modifiedState.EnteredDeviceID) != -1)  {
            modifiedState.errors.invalidDevcID = 'DeviceID already exist.';
            this.setState(modifiedState);
            return;
        } else {
            if(modifiedState.EnteredDeviceID == null || modifiedState.EnteredDeviceID.length <= 0) {
                modifiedState.errors.invalidDevcID = 'Please Enter Valid Device ID.';
                this.setState(modifiedState);
                return;
            } else { 
                this.getDeviceIDValidation();
            }
        }
    }

    getDeviceIDValidation =  () => {
        let modifiedState = this.state;

        let jsonBody = {
            EnteredDeviceID: modifiedState.EnteredDeviceID,
        }

        axios.post( `${getAPIHostURL()}/wclient/checkDeviceIdExistence`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {    

                if(response.data.isDeviceIDExist == null) {
                    modifiedState.errors.others = "Invalid Device ID.";
                } else {
                    if(response.data.isDeviceIDExist == 0) {
                        modifiedState.errors.invalidDevcID = 'Invalid Device ID.';
                    } else { 
                        // Just wanted to show last inserted DeviceID at the top of DisplayBox.
                        modifiedState.manuallyAddedDeviceIDArrForDisplayBox.unshift(modifiedState.EnteredDeviceID);
                        modifiedState.EnteredDeviceID = "";
                    } 
                }
            } else {
                if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    checkIfDisplayBoxisEmpty = () => {
        let modifiedState = this.state;

        modifiedState.errors.invalidDevcID = "";
        modifiedState.errors.others = "";

        if((modifiedState.manuallyAddedDeviceIDArrForDisplayBox == null  || modifiedState.manuallyAddedDeviceIDArrForDisplayBox.length <= 0) 
            && (modifiedState.EnteredDeviceID.length <= 0 || modifiedState.EnteredDeviceID == null)){
            modifiedState.errors.invalidDevcID = 'Please Enter Valid Device ID.';
            this.setState(modifiedState);
            return;
        } else if ((modifiedState.manuallyAddedDeviceIDArrForDisplayBox == null  || modifiedState.manuallyAddedDeviceIDArrForDisplayBox.length <= 0) 
            && (modifiedState.EnteredDeviceID != null || modifiedState.EnteredDeviceID.length > 0)) {
            modifiedState.errors.invalidDevcID = 'Please Add Entered Device ID into Display Box.';
            this.setState(modifiedState);
            return;
        } else {
            this.addOrRemoveRawData();
        }
    }

    addOrRemoveRawData = () => {
        let modifiedState = this.state;
        
        let jsonBody = { 
            manuallyAddedDeviceIDArrForDisplayBox: modifiedState.manuallyAddedDeviceIDArrForDisplayBox,
            invokedFrom: modifiedState.invokedFrom
        }

        axios.post( `${getAPIHostURL()}/wclient/addOrRemoveRawDataParametersForSelectedDeviceIDs`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {  
                // Show alert to user with success msg.
                if(modifiedState.invokedFrom != null && modifiedState.invokedFrom == 'AddRawDataParameters'){
                    alert("Successfully Added Raw Data Parametrers for Selected Device ID(s)");

                } else {
                    alert("Successfully Removed Raw Data Parameters for Selected Device ID(s)");
                }
                modifiedState.EnteredDeviceID = "";
                modifiedState.manuallyAddedDeviceIDArrForDisplayBox = [];
                modifiedState.invokedFrom = "";
                modifiedState.errors.others = "";
                modifiedState.errors.invalidDevcID = "";
                modifiedState.DeviceIdCount = "";
            }  else {
                if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        });  
    }

    NoteAccordingToPageInvokedFrom = (e) => {
        let modifiedState = this.state;

        if(modifiedState.invokedFrom != null && modifiedState.invokedFrom.length > 0 && modifiedState.invokedFrom == "AddRawDataParameters") { 
            return(
                <div style={{border: "1px solid #a8cca8", textAlign:"left", borderRadius:"0.5rem", marginBottom:"1rem", paddingTop:"0.5rem"}} >
                    <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem"}}>Note:</div>
                    <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                        - Don't forget to remove all the added Devices after Raw Data Analysis is done.
                        <br/>
                        - You can remove all the added devices by clicking on 'Remove Raw Data Analysis Parameters' option.
                    </div>
                </div>       
            );
        } else {
            return(<div></div>);
        }
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="container col-lg-7 col-lg-offset-3 col-md-8 col-md-offset-2">
                            <div className="modal-body p-4 box">
                                <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1.2rem"}}>
                                    {this.state.invokedFrom == "AddRawDataParameters" ? "Add Raw Data Analysis Parameters" : "Remove Raw Data Analysis Parameters"}
                                </div>
                                {this.NoteAccordingToPageInvokedFrom()}
                                <form>
                                    <div>
                                        <label className="reg-form-label" 
                                            style={{width:"100%"}}
                                        > Scan or Enter Device ID:
                                        </label>
                                        <input style={{width:"100%"}}
                                            className="input-form"
                                            value= {this.state.EnteredDeviceID}
                                            onKeyDown={this.handleKeyDown}
                                            onChange = {this.onEnterDeviceID}
                                            required
                                        />
                                        <label onClick = {this.openQRCameraForDeviceRawData} className = "qrcodeTextBtn">
                                            <FaQrcode className = "qrcodeTextBtnIcon"/>
                                        </label>
                                        <div>
                                            {(this.state.rplcDevcQRCodeFlag == true)
                                            ?
                                            // <div style={{display: "flex", justifyContent: "center", marginTop: "1rem"}}>
                                            //     <QrReader
                                            //         scanDelay={300}
                                            //         onResult={(result, error) => {
                                            //             if (!!result) {
                                            //                 this.handleScanResultOfDevciceRawData(result?.text);
                                            //             }
                                            //         }}
                                            //         className = "QRCodeCamBoxForModalAndContainer"
                                            //     />
                                            // </div>
                                                <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                        marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                        marginRight: "auto"}}>                                                
                                                <QrScanner
                                                    scanDelay={300}
                                                    onResult={(result, error) => {
                                                        if (!!result) {
                                                            this.handleScanResultOfDevciceRawData(result?.text);
                                                        }
                                                    }}
                                                    className = "QRCodeCamBoxForModalAndContainer"
                                                />
                                            </div>
                                            :
                                            <div/>
                                            }
                                        </div>
                                        <div style={{display:"flex", justifyContent: "center", flexDirection:"column",marginTop: "1rem"}}>
                                            <button type={"button"}
                                                    style={{borderRadius: "5px", 
                                                        width:'30%', 
                                                        marginLeft:'35%',
                                                        marginRight:'35%',
                                                        marginTop:"0.5rem",
                                                        backgroundColor: "var(--primaryColor)", color:"white",
                                                        fontSize: "1.2rem", padding: "0.4rem"}}
                                                    className="btn-lg"
                                                    onClick={this.onClickAddDeviceValidation} 
                                            > ADD
                                            </button>
                                            <span style={{color:"red", fontSize:"0.8rem", marginTop: "1rem"}}>{(this.state.errors.invalidDevcID != null && this.state.errors.invalidDevcID.length > 0) && this.state.errors.invalidDevcID}</span> 
                                        </div>
                                    </div>
                                    <div className="inputgroupCustom" style={{marginTop:"1.2rem"}}>
                                        <label className="reg-form-label">
                                            Displaying Entered Device ID(s):
                                        </label>
                                        <select  className="input-form" size="5" style={{height:"5%"}}>
                                            {(this.state.manuallyAddedDeviceIDArrForDisplayBox).map((addedDevcID,index) => <option key={index} value={addedDevcID}>{addedDevcID}</option>)}
                                        </select>                                    
                                    </div>  
                                    <div className="form-group reg-form" style={{marginTop:"1.5rem"}}>
                                        <button onClick = {this.checkIfDisplayBoxisEmpty} 
                                            type="button" 
                                            className="btn-lg reg-btn" 
                                            style={{textTransform:"capitalize"}}>
                                            {this.state.invokedFrom == "AddRawDataParameters" ? "Add Raw Data Parameters" : <div>Remove Raw Data Parameters</div>}
                                        </button>
                                        <span style={{color:"red", fontSize:"0.8rem"}}>{(this.state.errors.others != null && this.state.errors.others.length > 0) && this.state.errors.others}</span>
                                    </div>                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

VcAddOrRemoveRawDataParams.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcAddOrRemoveRawDataParams;   