import React, { Component } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { NH3OD } from '../../VcConstants';

// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const SliderWithTooltip = createSliderWithTooltip(Slider);
// const Handle = Slider.Handle;

// const handle = (props) => {
//   console.log("Props: ");
//   console.log(props);
//   const { value, dragging, index, ...restProps } = props;
//   return (
//     <Tooltip
//       prefixCls="rc-slider-tooltip"
//       overlay={value}
//       visible={dragging}
//       placement="top"
//       // key={index}
//     >
//       <Handle value={value} {...restProps} />
//     </Tooltip>
//   );
// };

class VcSlider extends Component {

    constructor(props) {

      super(props);

      let convertedValue = null;
      let calculatedStep = null;

      let settingLevelsCount = this.props.settingLevels.length;

      if(settingLevelsCount > 0) {
        // Provide level based value in case of Level Slider

        // The value here will be ('L2', 'L3'...). It has to be converted to index.
        let strInputLevel = this.props.defaultValue[0] == null ? this.props.defaultValue[1] : this.props.defaultValue[0];

        convertedValue = 0; // Default level will be the first index in case the input level is not found in the list
        for(let i=0; i<settingLevelsCount; i++) {
          if(this.props.settingLevels[i] == strInputLevel) {
            convertedValue = i; // Got the index
            break;
          }
        }

        calculatedStep = null; // Step not applicable
      } else {
        // Normal slider without levels

        convertedValue = this.props.defaultValue[0] == null ? this.props.defaultValue[1] : this.props.defaultValue[0];
        calculatedStep = ((convertedValue > -1) && (convertedValue < 1)) ? 0.1 : 1;
        // Note: If value is between (-1 and 1) then the step should be (0.1).
        // If value is greater than 1 then the step should be (1).
      }

      this.state = {
        inputValue: convertedValue,
        step: calculatedStep
      }

    }

    handleVcSliderChange = (value) => {

      let newValue = value;
      let modifiedStep = null;
      let goodU = this.props.stdAirQualityGoodRange[1];
      
      if(this.props.shortName != NH3OD) {
        if(value < goodU){
              this.setState({
                inputValue: goodU,
              });
              return;
            }
      }

      let settingLevelsCount = this.props.settingLevels.length;

      if(settingLevelsCount > 0) {
        // Provide level based value in case of Level Slider
        newValue = this.props.settingLevels[value];
        modifiedStep = null; // Step not applicable for Level based slider
      } else {
        modifiedStep = ((value > -1) && (value < 1)) ? 0.1 : 1;
      }

      this.setState({
          inputValue: value,
          step: modifiedStep
      });

      this.props.handleSliderValueChange(this.props.dataStateArrIndex, newValue);
    }
    
    render() {

      // Create the Marks, MinValue and MaxValue for Marks in case of Level based range slider
      let valueForMarks = {};
      let minValForMarks = 0;
      let maxValForMarks = 0;
      let settingLevelsCount = this.props.settingLevels.length;
      if(settingLevelsCount > 0) {
        minValForMarks = 0;
        maxValForMarks = settingLevelsCount - 1;

        for(let i=0; i < settingLevelsCount; i++) {
          valueForMarks[i] = this.props.settingLevels[i];
        }
      }

      return (
        <div>
          { this.props.settingLevels.length > 0 
            ? 
            <Slider
              key={this.props.selectedTreeNodeID + "-" + this.props.shortName}
              min={minValForMarks} max={maxValForMarks} step={this.state.step}
              defaultValue={this.state.inputValue} marks={valueForMarks} 
              trackStyle={{display: "none"}}
              dotStyle={{borderColor: "lightgray"}}
              handleStyle={{backgroundColor: "var(--secondaryColor)", borderColor: "var(--secondaryColor)"}}
              onChange={this.handleVcSliderChange}
              style={{backgroundColor:"transparent"}}
              disabled = {this.props.NoPrivilegeForChangeSettings == true}
            />
            :
            <Slider
              key={this.props.selectedTreeNodeID + "-" + this.props.shortName}
              min={this.props.min} max={this.props.max} step={this.state.step}
              defaultValue={this.state.inputValue}
              onChange={this.handleVcSliderChange}
              handleStyle={{backgroundColor: "var(--secondaryColor)", borderColor: "var(--secondaryColor)"}}
              trackStyle={{backgroundColor: "var(--secondaryColor)"}}
              style={{backgroundColor:"transparent"}}
              value={this.state.inputValue}
              disabled = {this.props.NoPrivilegeForChangeSettings == true}
            /> 
          }
        </div>     
      )
    }

}

export default VcSlider;
