import React from 'react';
import { AppRelevantDataContext } from '../../../AppContext';
import {NavLink} from 'react-router-dom';
import { IDS_Dashboard, IDS_Settings, IDS_Charts, IDS_Alerts, IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_AllDeviceData, IDS_ToggleTree} from '../../../VcLanguage';
import axios from 'axios';
import { getAPIHostURL } from '../../../ClientConfig';
import { PVG_DEVC_OWNER, PVG_GENERAL_USER, PVG_DEVC_VIEWER, PVG_SALES_EXECUTIVE, PVG_SALES_SUPERVISOR, 
         PVG_PRODUCTION_SUPERVISOR, PVG_PRODUCTION_WORKER, PVG_BACK_OFFICE_EXECUTIVE, PVG_PRODUCT_OWNER, 
         PVG_FIELD_ENGINEER, PVG_INST_PRIMARY_OWNER, PVG_INST_ADDITIONAL_VIEWER, PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING, 
         PVG_DEVC_VIEWER_WITH_SETTING, PVG_TOILET_ADMIN, PVG_SW_DEV_ADMIN, PVG_TREE_DEFINITION_ADMIN, PVG_TREE_NODE_VIEWER, PVG_EXTERNAL_API_USER} from '../../../VcConstants';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { IoSettings, IoSettingsOutline, IoStatsChart, IoStatsChartOutline } from "react-icons/io5";
import { TbLayoutDashboard, TbListTree } from 'react-icons/tb';
import { BsBell, BsBellFill } from 'react-icons/bs';
import { CiViewTable } from "react-icons/ci";

class VcTopNav extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {  
      ActionDropDownOpen: false,
      userID: "",
      CountryInfo: [],
      CountryCode: "",
      oldPassword: "",
      newPassword: "",
      cnfrmNewPassword: "",
      passwordShown: false,
      errors: { 
        others:'',
      },
      // EncryptedPvg:"",
      PrivilegeEncKey:"",
      objPrivilege:{},
      deviceMenuPvg: false,
      crmMenuPvg: false,
    };
  }

  componentDidMount () {
    // this.getUserPrivileges();
    this.handlePvg();
  }

  componentDidUpdate () {
    let currentState = this.state;
    let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext

    if( appRelevantDataContextValue == null ||
        ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
        appRelevantDataContextValue.loggedInUserInfo == null ||
        ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
        appRelevantDataContextValue.loggedInUserInfo.userID == null ||
        appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
        ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
        appRelevantDataContextValue.selectedNodeInfo == null  
    ) {
        console.log("NavBar:componentDidUpdate - First time render. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
        return; // Do not process further
    }

    // if(appRelevantDataContextValue.loggedInUserPrivilege.Privilege != currentState.EncryptedPvg) {
    //   // on Refresh values stored in LocalStorage doesnot refelect into appcontext hence 
    //   // we need to call componenentDidUpdate which again take privilege from context and store into state.
    //   // this.getUserPrivileges();
    //   this.handlePvg();
    // } 
  }

  handlePvg = () => {

    let modifiedState = this.state;
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;  

    if(Object.entries(this.props.objPriv) != null && Object.entries(this.props.objPriv).length > 0) {
      modifiedState.objPrivilege = this.props.objPriv; 

        modifiedState.deviceMenuPvg = ((modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_OWNER) && modifiedState.objPrivilege[PVG_DEVC_OWNER] == true ) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_GENERAL_USER) && modifiedState.objPrivilege[PVG_GENERAL_USER] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_VIEWER) && modifiedState.objPrivilege[PVG_DEVC_VIEWER] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_VIEWER_WITH_SETTING) && modifiedState.objPrivilege[PVG_DEVC_VIEWER_WITH_SETTING] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_PRIMARY_OWNER) && modifiedState.objPrivilege[PVG_INST_PRIMARY_OWNER] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_ADDITIONAL_VIEWER) && modifiedState.objPrivilege[PVG_INST_ADDITIONAL_VIEWER] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING) && modifiedState.objPrivilege[PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_TREE_NODE_VIEWER) && modifiedState.objPrivilege[PVG_TREE_NODE_VIEWER] == true) );

        modifiedState.crmMenuPvg = ((modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && modifiedState.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && modifiedState.objPrivilege[PVG_PRODUCTION_WORKER] == true) || 
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && modifiedState.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && modifiedState.objPrivilege[PVG_SALES_SUPERVISOR] == true) || 
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && modifiedState.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && modifiedState.objPrivilege[PVG_PRODUCT_OWNER] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && modifiedState.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && modifiedState.objPrivilege[PVG_TOILET_ADMIN] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && modifiedState.objPrivilege[PVG_SW_DEV_ADMIN] == true) ||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_TREE_DEFINITION_ADMIN) && modifiedState.objPrivilege[PVG_TREE_DEFINITION_ADMIN] == true)||
                      (modifiedState.objPrivilege.hasOwnProperty(PVG_EXTERNAL_API_USER) && modifiedState.objPrivilege[PVG_EXTERNAL_API_USER] == true)) ;

      if ((modifiedState.objPrivilege.hasOwnProperty(PVG_EXTERNAL_API_USER) && modifiedState.objPrivilege[PVG_EXTERNAL_API_USER] == true) 
        && (appRelevantDataContextValue.navMenu.menuSwitchBtn != null && appRelevantDataContextValue.navMenu.menuSwitchBtn.length <= 0)) {
        appRelevantDataContextValue.onChangeSwitchBtn("CrmMenu")   
      } else if ((modifiedState.deviceMenuPvg != null && modifiedState.deviceMenuPvg) && (modifiedState.crmMenuPvg != null && modifiedState.crmMenuPvg) && 
        appRelevantDataContextValue.navMenu.menuSwitchBtn != null && appRelevantDataContextValue.navMenu.menuSwitchBtn.length <= 0) {
        appRelevantDataContextValue.onChangeSwitchBtn("DeviceMenu")   
      } 

      this.setState(modifiedState);

    } else {
      return; // No further processing required
    }
  }

  // check loggedIn user privileges.
  // getUserPrivileges = () => {
  //   let modifiedState = this.state;
  //   let appRelevantDataContextValue = this.context;
  //   let t = appRelevantDataContextValue.t;  

  //   let encryptedPrivileges = appRelevantDataContextValue.loggedInUserPrivilege.Privilege;
  //   modifiedState.EncryptedPvg = encryptedPrivileges;

  //   axios.post(`${getAPIHostURL()}/wclient/getEncChaabi`)
  //   .then(response =>{
  //     modifiedState.errors.others = "";
  //       if(response.data.code == 'SUCCESS') {
  //          if(response.data.retrievedEncChaabi == null || response.data.retrievedEncChaabi.length <= 0) {
  //               modifiedState.errors.others = `Unable to get encryption key.`;
  //          } else {
  //              modifiedState.PrivilegeEncKey = response.data["retrievedEncChaabi"][0]["PassKey"];

  //             //  let bytes  = CryptoJS.AES.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);
  //              let bytes  = aes.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);

  //             //  let strPrivilege = bytes.toString(CryptoJS.enc.Utf8);
  //              let strPrivilege = bytes.toString(enc);

  //              try {
  //                   modifiedState.objPrivilege = JSON.parse(strPrivilege);

  //                   modifiedState.deviceMenuPvg = ((modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_OWNER) && modifiedState.objPrivilege[PVG_DEVC_OWNER] == true ) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_GENERAL_USER) && modifiedState.objPrivilege[PVG_GENERAL_USER] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_VIEWER) && modifiedState.objPrivilege[PVG_DEVC_VIEWER] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_VIEWER_WITH_SETTING) && modifiedState.objPrivilege[PVG_DEVC_VIEWER_WITH_SETTING] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_PRIMARY_OWNER) && modifiedState.objPrivilege[PVG_INST_PRIMARY_OWNER] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_ADDITIONAL_VIEWER) && modifiedState.objPrivilege[PVG_INST_ADDITIONAL_VIEWER] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING) && modifiedState.objPrivilege[PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_TREE_NODE_VIEWER) && modifiedState.objPrivilege[PVG_TREE_NODE_VIEWER] == true) );

  //                   modifiedState.crmMenuPvg = ((modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && modifiedState.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && modifiedState.objPrivilege[PVG_PRODUCTION_WORKER] == true) || 
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && modifiedState.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && modifiedState.objPrivilege[PVG_SALES_SUPERVISOR] == true) || 
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && modifiedState.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && modifiedState.objPrivilege[PVG_PRODUCT_OWNER] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && modifiedState.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && modifiedState.objPrivilege[PVG_TOILET_ADMIN] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && modifiedState.objPrivilege[PVG_SW_DEV_ADMIN] == true) ||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_TREE_DEFINITION_ADMIN) && modifiedState.objPrivilege[PVG_TREE_DEFINITION_ADMIN] == true)||
  //                                 (modifiedState.objPrivilege.hasOwnProperty(PVG_EXTERNAL_API_USER) && modifiedState.objPrivilege[PVG_EXTERNAL_API_USER] == true)) ;

                    
                    
  //                     if ((modifiedState.objPrivilege.hasOwnProperty(PVG_EXTERNAL_API_USER) && modifiedState.objPrivilege[PVG_EXTERNAL_API_USER] == true) 
  //                       && (appRelevantDataContextValue.navMenu.menuSwitchBtn != null && appRelevantDataContextValue.navMenu.menuSwitchBtn.length <= 0)) {
  //                       appRelevantDataContextValue.onChangeSwitchBtn("CrmMenu")   
  //                     } else if ((modifiedState.deviceMenuPvg != null && modifiedState.deviceMenuPvg) && (modifiedState.crmMenuPvg != null && modifiedState.crmMenuPvg) && 
  //                       appRelevantDataContextValue.navMenu.menuSwitchBtn != null && appRelevantDataContextValue.navMenu.menuSwitchBtn.length <= 0) {
  //                       appRelevantDataContextValue.onChangeSwitchBtn("DeviceMenu")   
  //                     }  
  //              } catch(e) {
  //                 // this.props.history.replace('/');
  //                 console.log(`Should not happen. The Privilege obtained from Context is in invalid JSON format.`);
  //              }
  //          }

  //       } else {
  //           // this.props.history.replace('/');
  //           if(response.data.code == 'SQL_ERROR') {
  //               modifiedState.errors.others = t(IDS_LoginServerIssue);
  //           } else {
  //               console.log("Should not reach here");
  //               modifiedState.errors.others = t(IDS_LoginServerIssue);
  //           }
  //           console.log(modifiedState.errors.others);
  //       }
  //       this.setState(modifiedState);

  //   })
  //   .catch(err => {
  //       console.log("Network error");
  //       console.log(err);
  //       if (axios.isCancel(err)) {
  //         console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
  //       } else {
  //         modifiedState.errors.others = t(IDS_RegistNetworkError);
  //         console.log(modifiedState.errors.others);
  //         this.setState(modifiedState);
  //       }
  //   }) 
  // }


  toggleTreeAndDashboard = () => {
    if(this.props.setTreeOnMobile != null) {
      this.props.setTreeOnMobile(false)
    }
    if(this.props.dashboardDeviceTreeToggle != null) {
      this.props.dashboardDeviceTreeToggle();
    } 
    // console.log('reached again');

  }

  render() {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;
    let trackedDeviceInfoArr = appRelevantDataContextValue.devicesToTrack.DevicesInfo;
    let ownerOfTrackedDevices = appRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
    let bShowMenuBasedOnTracedDevcsKeyValue =  ((trackedDeviceInfoArr != null && trackedDeviceInfoArr.length > 0) || (ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0)) ? true : false;  

    let menuSwitchBtn = appRelevantDataContextValue.navMenu.menuSwitchBtn;
    let invokedLoginPgInfo = appRelevantDataContextValue.invokedLoginPgInfo;
    let loginPagePath = invokedLoginPgInfo.loginPagePath;
    let showNextPagePathAfterLogin = invokedLoginPgInfo.showNextPagePathAfterLogin;

    let bShowMenuBasedOnLoginPathKeyValue =  ((invokedLoginPgInfo != null && Object.keys(invokedLoginPgInfo).length > 0) &&
      (loginPagePath != null && loginPagePath.length > 0) && (showNextPagePathAfterLogin != null 
        && showNextPagePathAfterLogin.length > 0)) ? true : false;
    
    return (
        !bShowMenuBasedOnLoginPathKeyValue &&
                (this.state.objPrivilege != null) && 
                  ( ((this.state.deviceMenuPvg != null && this.state.deviceMenuPvg) && (this.state.crmMenuPvg != null && this.state.crmMenuPvg) && (!bShowMenuBasedOnTracedDevcsKeyValue)?
                      (menuSwitchBtn == "DeviceMenu")
                      : 
                      ((this.state.deviceMenuPvg != null && this.state.deviceMenuPvg) || (bShowMenuBasedOnTracedDevcsKeyValue))
                  )
                  ) &&
                  
                  <div className= {`flex   items-center gap-2 ${this.props.fromSideNav == null ? 'flex-row' : 'flex-col'}`}>
                        
                        <NavLink to="/device/allDevices" onClick={this.toggleTreeAndDashboard}
                        className={({ isActive }) => ((isActive && !this.props.showTreeOnMobile) ? "top-nav-active " : "top-nav")}>
                                
                                  <div className="tooltipPar">
                                    <span className='outlineIcon'>
                                      <CiViewTable style={{ fontSize: "1.45rem" }} />
                                    </span>
                                    <span className='filledIcon'>
                                      <CiViewTable style={{fontSize:'1.45rem', fill:"green"}}/>
                                    </span>
                                    <span className="tooltiptextbottom">
                                      {t(IDS_AllDeviceData)}  
                                    </span>
                                </div>
                        </NavLink>

                        {this.props.showTreeOnMobile != null && <span
                            className={`${this.props.showTreeOnMobile ? 'top-nav-active' : 'top-nav'}`}
                            onClick={() =>{
                                this.props.setTreeOnMobile(true)
                            }}>
                            <div className="tooltipPar c-pointer">
                              <TbListTree fontSize={'1.3rem'}/>
                              <span className="tooltiptextbottom">
                                  {t(IDS_ToggleTree)}  
                                </span>
                              </div>
                        </span>}

                        <NavLink to="/device/dashboard"
                        // onClick={()=>{this.props.setTreeOnMobile && this.props.setTreeOnMobile(false)}}
                        onClick={this.toggleTreeAndDashboard}
                        className={({ isActive }) => ((isActive && !this.props.showTreeOnMobile) ? "top-nav-active " : "top-nav")}>

                            <div className="tooltipPar">
                              <span className='outlineIcon'>
                                <TbLayoutDashboard  style={{fontSize:'1.3rem'}}/>
                              </span>
                              <span className='filledIcon'>
                                <TbLayoutDashboard style={{fontSize:'1.3rem', fill:"green"}}/>
                              </span>
                              <span className="tooltiptextbottom">
                                {t(IDS_Dashboard)}  
                              </span>
                            </div>
                        </NavLink>

                        <NavLink to="/device/charts"
                        onClick={()=>{this.props.setTreeOnMobile && this.props.setTreeOnMobile(false)}}
                        className={({ isActive }) => ((isActive && !this.props.showTreeOnMobile) ? "top-nav-active " : "top-nav")}>
                            <div className="tooltipPar">
                                <span className='outlineIcon'>
                                  <IoStatsChartOutline  style={{fontSize:'1.3rem'}}/>
                                </span>
                                <span className='filledIcon'>
                                  <IoStatsChart style={{fontSize:'1.3rem'}}/>
                                </span>
                                <span className="tooltiptextbottom">
                                  {t(IDS_Charts)}  
                                </span>
                            </div>
                        </NavLink>

                        <NavLink to="/device/settings"
                        onClick={()=>{this.props.setTreeOnMobile && this.props.setTreeOnMobile(false)}}
                        className={({ isActive }) => ((isActive && !this.props.showTreeOnMobile) ? "top-nav-active " : "top-nav")}>
                            <div className="tooltipPar">
                              <span className='outlineIcon'>
                                <IoSettingsOutline  style={{fontSize:'1.3rem'}}/>
                              </span>
                              <span className='filledIcon'>
                                <IoSettings  style={{fontSize:'1.3rem'}}/>
                              </span>
                              <span className="tooltiptextbottom">
                                {t(IDS_Settings)}  
                              </span>
                            </div>
                        </NavLink>

                        <NavLink to="/device/alerts"
                        onClick={()=>{this.props.setTreeOnMobile && this.props.setTreeOnMobile(false)}}
                        className={({ isActive }) => ((isActive && !this.props.showTreeOnMobile) ? "top-nav-active " : "top-nav")}>  

                          <div className="tooltipPar">
                              <span className='outlineIcon'>
                                <BsBell  style={{fontSize:'1.3rem'}}/>
                              </span>
                              <span className='filledIcon'>
                                <BsBellFill style={{fontSize:'1.3rem'}}/>
                              </span>
                              <span className="tooltiptextbottom">
                                {t(IDS_Alerts)}  
                              </span>
                          </div>

                        </NavLink>            
                  </div>
        
    );
  }
}

VcTopNav.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods
export default VcTopNav;