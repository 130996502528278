import React, { useContext, useState, useEffect } from 'react'
import { AppRelevantDataContext } from '../../AppContext';
import App from '../../App';
import '@studysync/react-splitter-layout/lib/index.css';
import  VcProvideSolForCustComplaint from './VcProvideSolForCustComplaint';
import {useNavigate} from 'react-router-dom';
import VcNavBar from "./VcNavbar";
import VcSideNav from './DeviceHome/VcSideNav';

function VcProvideSolForCustComplaintPg (props) {

  const [ state, setState ] = useState({
    windowWidth: window.innerWidth,
  });

  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();

  const handleResize = () => {
    if ((window.innerWidth <= 570 && state.windowWidth > 570) || (window.innerWidth > 570 && state.windowWidth <= 570)) {
      setState(prevState => ({
        ...prevState,
        windowWidth: window.innerWidth
      }))
    } 
  }

  useEffect(() => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.
      // Try to get this value if present in LocalStorage and update the app state (so that
      // re-render will happen). If app context data not present in LocalStorage then
      // show the Login Page
      if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
        // App Relevant Data Context not present in LocalStorage as well.
        navigate('/', { replace: true });
      } else {
        // App Relevant Data Context loaded from LocalStorage at AppLeval.
        // Re-render will happen automatically (no action required).
      }

    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize());
    }
  }, [state.windowWidth <= 570])

  let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext

  if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
    // User login information is not present in appRelevantDataContext.

    // Next re-render will happen automatically when the AppState is updated again 
    // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
    return App.renderLoadingPage();
  }

  return (
    (state.windowWidth <= 570) ? 
      <div className="d-flex">
        <div className="treeAndContentCRM">
          <VcNavBar />

          <VcProvideSolForCustComplaint />
        </div>
      </div>
    :
    <div className='d-flex'>
      <div className='d-flex' style={{ width: "4%" }}>
        <div className="shadow sideNav" style={{ width: "100%" }}>
          <VcSideNav />
        </div>
      </div>

      <div className="treeAndContentCRM">
        <VcNavBar />

        <VcProvideSolForCustComplaint />
      </div>
    </div>
  )
}

export default VcProvideSolForCustComplaintPg;

