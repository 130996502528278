import React, { useState, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters, useRowSelect}  from 'react-table';
import axios from 'axios';
import '../CSS/VcCustomer.css'
import '../CSS/VcTrackDevices.css'
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext } from '../../AppContext';
import DateTimePicker from 'react-datetime-picker';
import { FaSearch}  from 'react-icons/fa';
import { convertLocalDateToDisplayToday, trimStringAndRemoveTrailingComma } from '../../vtUtil';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { convertUTCDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS} from '../../vtUtil';
import {IDS_RDEndDTAdjust, IDS_RDStartDTAdjust, IDS_RDStartDTAdjustNotMaxRng, IDS_RDStartDTSetAcrodEndDT, IDS_RDStartDTAdjustMinPossible,
        IDS_RDEndDTNotInRng, IDS_RDEndDTSetAcrodStartDT } from '../../VcLanguage';
import { SUBS_RENT_START, SUBS_RENT_END, SUBS_RENT_CANCELLED, CSV_COLUMN_SEPERATOR, CSV_LINE_SEPERATOR, MAX_DAYS_RANGE, MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA } from '../../VcConstants';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    )
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data, getCellProps = defaultPropGetter, renderBasedOnTable}) => {

    const [selectedRowIndexForSearchTbl, setSelectedRowIndexForSearchTbl] = useState(null);
    const [selectedRowIndexForSubDetailsTbl, setSelectedRowIndexForSubDetailsTbl] = useState(null);

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // set page size based on tables
            initialState: {pageSize: renderBasedOnTable == "SubscriptionDetailTable" ? 10 : 5},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
        // useRowSelect,
        // hooks => {
        //     if(renderBasedOnTable == "SearchResultTable"){
        //         hooks.visibleColumns.push(columns => [
        //             // Let's make a column for selection
        //             {
        //             id: 'selection',
        //             Header: <div className="AlertLogTableHeader">Selected</div>,
        //             width: 110,
        //             style:({
        //                 textAlign: "center",
        //                 paddingLeft: "1rem",
        //                 textOverflow: "ellipsis", 
        //                 overflow: "hidden",
        //                 whiteSpace: "nowrap",
        //                 height: "2.6rem"
        //             }),
        //             Cell: ({ row }) => (
        //                 <div>
        //                     <input
        //                         type = "radio"
        //                         name = "srchRsltTblSelectedRowIndicator"
        //                         id = "indicatingSelectedRow"
        //                         className = "selectedRadioBtn"
        //                         title = "Indicating selected row"
        //                         readOnly
        //                         checked={renderBasedOnTable == "SearchResultTable" && selectedRowIndexForSearchTbl == row.index
        //                                 ? true 
        //                                 : renderBasedOnTable == "SubscriptionDetailTable" && selectedRowIndexForSubDetailsTbl == row.index ? true
        //                                 : false }
        //                     />
        //                 </div>
        //             ),},
        //             ...columns,
        //         ])}
        //     }
    );
    
   //uses the table header group props for the empty rows so resizing and flex layout still works
   const createEmptyRow = (NoData=false) => {
    return(
        <tr className = "tr"
            style= {{
                textAlign:"left",
                paddingLeft: "1rem",
                textOverflow: "ellipsis", 
                overflow: "hidden",
                whiteSpace: "nowrap",
                height: "2.6rem"
            }}
        >
            <td className = "td"
            >
                {NoData == true?
                <div><span>&nbsp; No Data Found</span></div>
                :
                <div><span>&nbsp;</span></div>
                }
            </td>
        </tr>
            
    )};

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };

    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* {page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"
                                    onClick = {(e, handleOriginal) => {
                                        if(renderBasedOnTable == "SearchResultTable") {
                                            setSelectedRowIndexForSearchTbl(row.index);
                                        } else if(renderBasedOnTable == "SubscriptionDetailTable"){
                                            setSelectedRowIndexForSubDetailsTbl(row.index);
                                        }
                                        else {
                                            //nothing
                                        }
                                    }}       
                                    style = {
                                        (renderBasedOnTable == "SearchResultTable") ?  
                                        {
                                            cursor: '',
                                            background: row.index === selectedRowIndexForSearchTbl ? '#6c95ba' : '',
                                            color: row.index === selectedRowIndexForSearchTbl ? 'white' : 'black',
                                            alignItems: "center",
                                        }
                                        : (renderBasedOnTable == "SubscriptionDetailTable") ?  
                                        {
                                            cursor: '',
                                            background: row.index === selectedRowIndexForSubDetailsTbl ? '#6c95ba' : '',
                                            color: row.index === selectedRowIndexForSubDetailsTbl ? 'white' : 'black',
                                            alignItems: "center",
                                        } 
                                        :
                                        {}
                                    }   
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style},
                                            getCellProps(cell),
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                            // || 
                            // // when there is no data found 
                            // <tr style = {{backgroundColor: "white"}}>
                            //     <td>
                            //         <span  style={{paddingLeft:"1rem", color: "green", display: "flex" ,justifyContent:"left"}}>{"No Data Found."}</span>
                            //     </td>
                            // </tr>                            
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcAllSubscriptionDetailsInfo (props) {
    const csvLink = React.createRef();
    let textFile = null;

    const filterCaseInsensitive = (rows, id, filterValue) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;


        if (id == "SubscriptionStartDtTm" || id == "SubscriptionEndDtTm" || id == "SubscriptionCancellationDate" || id == "LastModifiedTime"){

            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]                    
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        } else {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
            });
        }
    };
    const context = useContext(AppRelevantDataContext);
    const [state, setState] = useState({
        searchedBy: "SearchByUser",
        ownersEmailID: "",
        firstName: "",
        lastName: "",
        mobileNo: "",
        selectedUserEmailID: "",
        StartDateTime: new Date(new Date().setHours(new Date().getHours() - 24)),
        EndDateTime: new Date(),
        bAllSubscriptionInfoViewDataModalOpen: false,
        selectedReportType: "",
        ArrForStartEndSubscription: [SUBS_RENT_START, SUBS_RENT_END, SUBS_RENT_CANCELLED],
        enableSubscriptionStat: false,
        backdrop: 'static',
        AllSubscriptionDataForSelectedTimePeriod: [],
        data: [],
        SubscriptionDetailColumns: [
    
            {       
                Header:() => <div className="AlertLogTableHeader">Item ID</div>, 
                accessor: 'ItemID',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Device Owner<br/>Email ID</div>, 
                accessor: 'DeviceOwnerEmailID',
                width: 170,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">Phone No.</div>,  
                accessor: 'PhoneNo',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            }, 
            {       
                Header:() => <div className="AlertLogTableHeader">Subscription<br/>Start Date Time</div>,  
                accessor: 'SubscriptionStartDtTm',
                Cell: (props) => getCustomizedTodaysDate(props.value),
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 190,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Subscription<br/>End Date Time</div>,  
                accessor: 'SubscriptionEndDtTm',
                Cell: (props) => getCustomizedTodaysDate(props.value),
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 190,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Device Owner<br/>Name</div>,  
                accessor: 'DeviceOwnerFullName',
                width: 160,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Renewed/Cancelled<br/>Status</div>,  
                accessor: 'RenewedCancelledStatus',
                width: 185,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Subscription<br/>Cancellation Date</div>,  
                accessor: 'SubscriptionCancellationDate',
                Cell: (props) => getCustomizedTodaysDate(props.value),
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 190,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Amount Returned<br/>On Cancel <br/>Period Days</div>,  
                accessor: 'AmountReturnedOnCancel',
                width: 220,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Period Days</div>,  
                accessor: 'PeriodDays',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Price<br/>Per Unit Order<br/>(INR)</div>,  
                accessor: 'PricePerUnitOrderINR',
                width: 190,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Tax<br/>Per Unit Order<br/>(INR)</div>,  
                accessor: 'TaxPerUnitOrderINR',
                width: 190,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">LastModifiedTime</div>,  
                accessor: 'LastModifiedTime',
                Cell: (props) => getCustomizedTodaysDate(props.value),
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 173,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
        ],

        // React table columns for displaying Search Result of Device Owner's Info.
        columns: [
            {   
                Header:() => <div className="AlertLogTableHeader">Action</div>, 
                accessor: "SubscriptionDetails",
                width: 380,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
                disableFilters: true,
                Cell: ( rowInfo ) => {
                    return (
                    <button type = "button" className = "createOrderButton">
                        View Subscription/Rental Period Details
                    </button> 
                    );
                },
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Email ID</div>,  
                accessor: 'OwnersEmailID',
                filter: filterCaseInsensitive,
                width: 300,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"

                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Mobile No</div>,  
                accessor: 'MobileNo',
                filter: filterCaseInsensitive,
                width: 200,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            }, 

            {       
                Header:() => <div className="AlertLogTableHeader">First Name</div>,  
                accessor: 'FirstName',
                width: 210,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
    
                }),
            },

            {       
                Header:() => <div className="AlertLogTableHeader">Last Name</div>,  
                accessor: 'LastName',
                width: 200,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },    
        ],
        errors: {
            others: "",
            ownersEmailID: "",
            firstName: "",
            lastName: "",
            mobileNo: "",
            requiredFiled: "",
        },
        timeRelatedErrors: '',
    });
    

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return "Today " + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    const onClickSubscriptionDetails = (rowInfo, column) => {

        let modifiedState = state;
        modifiedState.selectedUserEmailID = rowInfo.original.OwnersEmailID;
        modifiedState.bAllSubscriptionInfoViewDataModalOpen = true;
        getAllSubscriptionDetails(modifiedState)
    }

    const onChangeStartDateTime = (updatedTime) => {

        let modifiedState = state;
        modifiedState.errors.others = '';

        let updatedActiveStartDateTime = updatedTime;

        modifiedState.StartDateTime = updatedActiveStartDateTime;
        setState({...modifiedState});
    }

    const onStartDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;    
        let strMsg = '';

        let modifiedState = state;

        let currentlySetEndDateTime = modifiedState.EndDateTime;

        // Incase time is greater than currentdatetime.
        let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
        minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

        
        let currentlySetStartDateTime = modifiedState.StartDateTime;

        // Incase of startDateTime greater than EndDateTime. Set EndDateTime According to StartDateTime.
        let minRequiredEndDateTime = new Date(currentlySetStartDateTime.valueOf());
        minRequiredEndDateTime.setHours(minRequiredEndDateTime.getHours() + 24);

        // required to compare Range between Start and End Date Time.
        let minRequiredDateRange = new Date(currentlySetEndDateTime.valueOf());
        minRequiredDateRange.setDate(minRequiredDateRange.getDate() - MAX_DAYS_RANGE);

        let currentDateTime = new Date();

        // Give a tolerance of -1 minutes to the current date time to avoid unnecessary messages
        // currentDateTime.setMinutes(currentDateTime.getMinutes() - 1)                

        let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
        let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );
        
        if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {

            // strMsg = `Start Date Time will be adjusted because it is not within a Range. The time will be adjusted to minimum possible for the day.`;
            strMsg = t(IDS_RDStartDTAdjustMinPossible);
            
            modifiedState.timeRelatedErrors = strMsg;

            modifiedState.StartDateTime = new Date(currentlySetEndDateTime.valueOf());
            modifiedState.StartDateTime.setHours(0);
            modifiedState.StartDateTime.setMinutes(0);
            modifiedState.StartDateTime.setSeconds(0);

        } else if(currentlySetStartDateTime >= currentlySetEndDateTime) {

            // strMsg = `End Date Time has been adjusted because it is not within a Range.`;
            strMsg = t(IDS_RDEndDTNotInRng);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = minRequiredEndDateTime;

            if(modifiedState.EndDateTime >= currentDateTime) {
                modifiedState.EndDateTime = new Date();
            }

        } else if(currentlySetStartDateTime < minRequiredDateRange) {

            // strMsg = `End Date Time has been adjusted because it is not within a Maximum Range according to StartDateTime.`;
            strMsg = t(IDS_RDEndDTSetAcrodStartDT);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = minRequiredEndDateTime;

        } else {
            strMsg = "";
            modifiedState.timeRelatedErrors = strMsg;
        }

        setState({...modifiedState});

    }

    const onChangeEndDateTime = (updatedTime) => {
        
        let modifiedState = state; 
        modifiedState.errors.others = '';

        let updatedEndDateTime = updatedTime;
        modifiedState.EndDateTime = updatedEndDateTime;

        setState({...modifiedState});
    }

    const onEndDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  
        let strMsg ="";

        let modifiedState = state;

        let currentlySetEndDateTime = modifiedState.EndDateTime;

        let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
        minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

        let currentlySetStartDateTime = modifiedState.StartDateTime;

        let minRequiredDateRange = new Date(currentlySetStartDateTime.valueOf());
        minRequiredDateRange.setDate(minRequiredDateRange.getDate() + MAX_DAYS_RANGE);

        let currentDateTime = new Date();
        
        if(currentlySetEndDateTime <= currentlySetStartDateTime) {

            let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
            let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );

            if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {
                // strMsg = `End Date Time will be adjusted because it is not within a Range. The time will be adjusted to max possible for the day.`;
                strMsg = t(IDS_RDEndDTAdjust);
                
                modifiedState.timeRelatedErrors = strMsg;
                modifiedState.EndDateTime = new Date(currentlySetStartDateTime.valueOf());
                modifiedState.EndDateTime.setHours(23);
                modifiedState.EndDateTime.setMinutes(59);
                modifiedState.EndDateTime.setSeconds(59);
            } else {

                // strMsg = `Start Date Time will be adjusted because it is not within a Range. It will be set according to End Date Time.`;
                strMsg = t(IDS_RDStartDTAdjust);
                
                modifiedState.timeRelatedErrors = strMsg;

                modifiedState.StartDateTime = minRequiredStartDateTime
            }
            
            if(modifiedState.EndDateTime > currentDateTime) {
                modifiedState.timeRelatedErrors = strMsg;

                modifiedState.EndDateTime = currentDateTime
            }

        } else if(currentlySetEndDateTime > minRequiredDateRange) {

            // strMsg = `Start Date Time has been adjusted because it is not within a Maximum Range according to EndDateTime.`;
            strMsg = t(IDS_RDStartDTAdjustNotMaxRng);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.StartDateTime = minRequiredStartDateTime;

        } else {
            strMsg = "";
            modifiedState.timeRelatedErrors = strMsg;
        }

        setState({...modifiedState});

    }

    const OnClickViewButton = () => {
        let modifiedState = state;
        modifiedState.bAllSubscriptionInfoViewDataModalOpen = true;

        getAllSubscriptionDetails(modifiedState);
    }

    const OnClickDownloadAsCsvButton = () => {
        let bDownloadCsvOfAllSubscriptions = true;
        let modifiedState = state;

        getAllSubscriptionDetails(modifiedState, bDownloadCsvOfAllSubscriptions)
    }

    const getAllSubscriptionDetails = (inModifiedState = null, bDownloadCsvOfAllSubscriptions = false ) => {

        let modifiedState;
        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let StartDateTime = modifiedState.StartDateTime;
        let EndDateTime = modifiedState.EndDateTime;
        modifiedState.timeRelatedErrors = '';
        modifiedState.AllSubscriptionDataForSelectedTimePeriod = [];
        let strStartDateTime = null;
        let strEndDateTime = null;

        if(modifiedState.searchedBy != null && modifiedState.searchedBy.length > 0 && modifiedState.searchedBy == "SearchByDtTm"){
            let diffBetweenTwoDates = Math.abs((StartDateTime - EndDateTime)/ (1000*60*60*24) );

            if( diffBetweenTwoDates > MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA ) {
                if(!window.confirm(`Are you sure you want to view the large data for more than 90 days of period?`)){
                    return
                }
            }
        }

        let jsonBody = {}

        strStartDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(StartDateTime) );
        strEndDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(EndDateTime) );

        if (modifiedState.searchedBy == "SearchByDtTm" && (modifiedState.selectedReportType == null || modifiedState.selectedReportType.length <= 0)) {
            modifiedState.errors.others = "Please Select Report Type.";
            setState({...modifiedState});
            return;
        } else if(modifiedState.searchedBy == "SearchByDtTm" && modifiedState.timeRelatedErrors != null && modifiedState.timeRelatedErrors > 0){
            setState({...modifiedState});
            return;
        } else if (modifiedState.searchedBy == "SearchByUser" && (modifiedState.selectedUserEmailID == null || modifiedState.selectedUserEmailID.length <= 0)) {
            modifiedState.errors.requiredFiled = "Owners Email ID is Missing.";
            setState({...modifiedState});
            return;
        } else {
            jsonBody = {
                SelectedReportType: modifiedState.searchedBy == "SearchByDtTm" ? modifiedState.selectedReportType : null,
                StartDateTime :  modifiedState.searchedBy == "SearchByDtTm" ? strStartDateTime : null,
                EndDateTime :  modifiedState.searchedBy == "SearchByDtTm" ? strEndDateTime :  null,
                invokedFrom: modifiedState.searchedBy,
                selectedUserEmailID : modifiedState.searchedBy == "SearchByUser" ? modifiedState.selectedUserEmailID : null
            };

            axios.post(`${getAPIHostURL()}/wclient/getAllOrdersSubscriptionDetails`, jsonBody)    
            .then( response => {
                if(response.data.code == 'SUCCESS') {

                    let receivedAllSubscriptionInformation = response.data.retrievedAllDevcSubscriptionData;

                    if(modifiedState.searchedBy == "SearchByDtTm" && (receivedAllSubscriptionInformation == null || receivedAllSubscriptionInformation.length <= 0)) {
                        modifiedState.errors.others = 'For Selected Time Period does not contain Subscription/Rental period Data.';
                    } else if(modifiedState.searchedBy == "SearchByUser" && (receivedAllSubscriptionInformation == null || receivedAllSubscriptionInformation.length <= 0)){
                        alert("No Subscription/Rental period Data available for " + modifiedState.selectedUserEmailID);
                    } else {
                        let receivedAllSubscriptionInfoLen = receivedAllSubscriptionInformation.length;

                        if(modifiedState.bAllSubscriptionInfoViewDataModalOpen == true) {
                            modifiedState.enableSubscriptionStat = true;
                        }

                        for(let i = 0; i < receivedAllSubscriptionInfoLen; i++) {

                            let CustName = receivedAllSubscriptionInformation[i]["FirstName"] + " " + receivedAllSubscriptionInformation[i]["LastName"]
                            let strSubscriptionStartDtTm = (receivedAllSubscriptionInformation[i]["SubscriptionStartDtTm"] == null) ? "-" :convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedAllSubscriptionInformation[i]["SubscriptionStartDtTm"]);
                            let strSubscriptionEndDtTm = (receivedAllSubscriptionInformation[i]["SubscriptionEndDtTm"] == null) ? "-" :convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedAllSubscriptionInformation[i]["SubscriptionEndDtTm"]);
                            let SubscriptionCancellationDate = (receivedAllSubscriptionInformation[i]["DtTmWhenSubCancelled"] == null) ? "-" :convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedAllSubscriptionInformation[i]["DtTmWhenSubCancelled"]);
                            let LastModifiedDtTm = (receivedAllSubscriptionInformation[i]["LastModifiedDtTm"] == null) ? null :convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedAllSubscriptionInformation[i]["LastModifiedDtTm"]);
                            let strRenewedCancelledStatus = (receivedAllSubscriptionInformation[i]["Status"] != null && receivedAllSubscriptionInformation[i]["Status"] == 0) ? "Subscribed" : 
                                (receivedAllSubscriptionInformation[i]["Status"] != null && receivedAllSubscriptionInformation[i]["Status"] == 1) ? "Renewed" :
                                (receivedAllSubscriptionInformation[i]["Status"] != null && receivedAllSubscriptionInformation[i]["Status"] == "Cancelled") ? "Cancelled" : "-";
                            let PricePerUnitOrderINR = receivedAllSubscriptionInformation[i]["PricePerUnitOrderINR"] == null ? "-" :receivedAllSubscriptionInformation[i]["PricePerUnitOrderINR"];
                            let TaxPerUnitOrderINR = receivedAllSubscriptionInformation[i]["TaxPerUnitOrderINR"] == null ? "-" :receivedAllSubscriptionInformation[i]["TaxPerUnitOrderINR"];
                            let SubscriptionAmtToReturn = receivedAllSubscriptionInformation[i]["SubscriptionAmtToReturn"] == null ? "-" :receivedAllSubscriptionInformation[i]["SubscriptionAmtToReturn"];
                            let PeriodDays = receivedAllSubscriptionInformation[i]["PeriodDays"] == null ? '-' : receivedAllSubscriptionInformation[i]["PeriodDays"];

                            let singleInstanceInfo = {
                                ItemID: receivedAllSubscriptionInformation[i]["ItemID"],
                                DeviceOwnerEmailID: receivedAllSubscriptionInformation[i]["DeviceOwnerEmailID"],
                                PhoneNo: receivedAllSubscriptionInformation[i]["PhoneNo"],
                                SubscriptionStartDtTm: strSubscriptionStartDtTm,
                                SubscriptionEndDtTm: strSubscriptionEndDtTm,
                                SubscriptionCancellationDate: SubscriptionCancellationDate,
                                AmountReturnedOnCancel: SubscriptionAmtToReturn,
                                DeviceOwnerFullName: CustName,
                                RenewedCancelledStatus: strRenewedCancelledStatus,
                                PeriodDays: PeriodDays,
                                PricePerUnitOrderINR: PricePerUnitOrderINR,
                                TaxPerUnitOrderINR: TaxPerUnitOrderINR,
                                LastModifiedTime: LastModifiedDtTm

                            }
                            modifiedState.AllSubscriptionDataForSelectedTimePeriod.push(singleInstanceInfo);
                        }
                        if(bDownloadCsvOfAllSubscriptions == true) {
                            modifiedState.enableSubscriptionStat = false;
                            DownloadCsv(modifiedState.AllSubscriptionDataForSelectedTimePeriod)
                        }
                        modifiedState.errors.others = "";
                        modifiedState.timeRelatedErrors = "";
                    }
                } else {

                    if(response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the Required parameters were not sent to the Server
                        modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                        
                    } else if (response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing otherTimeRelatedErrors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                setState({...modifiedState});

            })
            .catch( error => {
                console.log("Network Error while getting all Subscription data");
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});
                }
            });
        }
    }

    const onChangeSelectedReportType = (e) => {

        let modifiedState = state;
        modifiedState.errors.others = "";
        modifiedState.selectedReportType = e.target.value;
        setState({...modifiedState});
    }

    const toggleSubscriptionStat = (e) => {
        setState({
            ...state,
            enableSubscriptionStat: !state.enableSubscriptionStat,
            bAllSubscriptionInfoViewDataModalOpen: !state.bAllSubscriptionInfoViewDataModalOpen
        });
    }

    const OnClickDownloadDataAsCsvButtonInView = () => {
        let modifiedState = state;

        if(modifiedState.AllSubscriptionDataForSelectedTimePeriod.length > 0) {
            DownloadCsv(modifiedState.AllSubscriptionDataForSelectedTimePeriod);
        } else {
            console.error("Downloaded data could not be saved to file.")
        }
    }

    const DownloadCsv = (inCsvData) => {      

        let modifiedState = state;

        let arrColumnKeys = ["Device Owner Email ID", "Phone No.", "Subscription Start Date Time", "Subscription End Date Time", "Device Owner Name", "Renewed/Cancelled Status", "Subscription Cancellation Date", "Amount Returned On Cancel Period Days",
                                 "Period Days", "Price Per Unit Order (INR)", "Tax per Unit Order (INR)", "LastModifiedTime"];

        let strCsvHeaderLine = "ItemID";
        let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
        let strSingleCsvDataLine = "";
        let strColumnName = "";
        modifiedState.csvData = [];

        // Create rest of the columns in the header
        let noOfParams = arrColumnKeys.length;
        for (let j=0; j<noOfParams; j++) {
            strColumnName = arrColumnKeys[j];
            strCsvHeaderLine += (CSV_COLUMN_SEPERATOR + strColumnName);
        }

        let receivedAllSubscriptionInformation = inCsvData;
        let receivedAllSubscriptionInfoLen = inCsvData.length;
        let allInstanceInfo = [];

        // First push the header CSV line
        arrCsvAllLines.push(strCsvHeaderLine);

        for(let i = 0; i < receivedAllSubscriptionInfoLen; i++) {

            let singleInstanceInfo = {
                ItemID: receivedAllSubscriptionInformation[i]["ItemID"],
                DeviceOwnerEmailID: receivedAllSubscriptionInformation[i]["DeviceOwnerEmailID"],
                PhoneNo: receivedAllSubscriptionInformation[i]["PhoneNo"],
                SubscriptionStartDtTm: receivedAllSubscriptionInformation[i]["SubscriptionStartDtTm"],
                SubscriptionEndDtTm: receivedAllSubscriptionInformation[i]["SubscriptionEndDtTm"],
                CustName: receivedAllSubscriptionInformation[i]["DeviceOwnerFullName"],
                RenewedCancelledStatus: receivedAllSubscriptionInformation[i]["RenewedCancelledStatus"],
                SubscriptionCancellationDate: receivedAllSubscriptionInformation[i]["SubscriptionCancellationDate"],
                AmountReturnedOnCancel: receivedAllSubscriptionInformation[i]["AmountReturnedOnCancel"],
                PeriodDays: receivedAllSubscriptionInformation[i]["PeriodDays"],
                PricePerUnitOrderINR: receivedAllSubscriptionInformation[i]["PricePerUnitOrderINR"],
                TaxPerUnitOrderINR: receivedAllSubscriptionInformation[i]["TaxPerUnitOrderINR"],
                LastModifiedTime: receivedAllSubscriptionInformation[i]["LastModifiedTime"]
            }

            allInstanceInfo.push(singleInstanceInfo);

            strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                ((receivedAllSubscriptionInformation[i]["ItemID"] == null) ? '' : receivedAllSubscriptionInformation[i]["ItemID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllSubscriptionInformation[i]["DeviceOwnerEmailID"] == null) ? '' : receivedAllSubscriptionInformation[i]["DeviceOwnerEmailID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllSubscriptionInformation[i]["PhoneNo"] == null) ? '' : receivedAllSubscriptionInformation[i]["PhoneNo"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllSubscriptionInformation[i]["SubscriptionStartDtTm"] == null) ? '' : receivedAllSubscriptionInformation[i]["SubscriptionStartDtTm"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllSubscriptionInformation[i]["SubscriptionEndDtTm"] == null) ? '' : receivedAllSubscriptionInformation[i]["SubscriptionEndDtTm"] ) +
                CSV_COLUMN_SEPERATOR +
                ((receivedAllSubscriptionInformation[i]["DeviceOwnerFullName"] == null) ? '' : receivedAllSubscriptionInformation[i]["DeviceOwnerFullName"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllSubscriptionInformation[i]["RenewedCancelledStatus"] == null) ? '' : receivedAllSubscriptionInformation[i]["RenewedCancelledStatus"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllSubscriptionInformation[i]["SubscriptionCancellationDate"] == null) ? '' : receivedAllSubscriptionInformation[i]["SubscriptionCancellationDate"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllSubscriptionInformation[i]["AmountReturnedOnCancel"] == null) ? '' : receivedAllSubscriptionInformation[i]["AmountReturnedOnCancel"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllSubscriptionInformation[i]["PeriodDays"] == null) ? '' : receivedAllSubscriptionInformation[i]["PeriodDays"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllSubscriptionInformation[i]["PricePerUnitOrderINR"] == null) ? '' : receivedAllSubscriptionInformation[i]["PricePerUnitOrderINR"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllSubscriptionInformation[i]["TaxPerUnitOrderINR"] == null ? '' : receivedAllSubscriptionInformation[i]["TaxPerUnitOrderINR"] ) + 
                CSV_COLUMN_SEPERATOR +
                ( receivedAllSubscriptionInformation[i]["LastModifiedTime"] == null ? '' : receivedAllSubscriptionInformation[i]["LastModifiedTime"] );

            arrCsvAllLines.push(strSingleCsvDataLine);
        }

        modifiedState.csvData = allInstanceInfo;
        // Create CSV File and fill it
        let bFileCreationStatus = createAndFillCsvFile(arrCsvAllLines);

        alert(`All Subscription/Rental period Data download has started. Check your Browser's status bar or Downloads Folder.`);

        if (bFileCreationStatus) {
            // CSV file creation successful.
            // Close the date selection modal.
            // modifiedState.enableStagewiseProdStat = true;

            // props.toggleAllOrderDetailsInfoModal(); // Notify the parent to close the modal.
            // modal closed, no need to do any further processing
            // return;
        } else {
            modifiedState.errors.others = 'Downloaded data could not be saved to file.';
        }

        setState({...modifiedState});
    }

    const createAndFillCsvFile = (inarrCsvAllLines) => {

        let retVal = true;

        let foramttedStartDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.StartDateTime);
        let foramttedEndDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.EndDateTime);
        let fileName = "All Subscription/Rental period Data From " + foramttedStartDateTime + " To " + foramttedEndDateTime + ".csv";

        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = makeTextFile(inarrCsvAllLines);
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            let event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });

        // Return true to indicate that operation performed successfully (in future any failures in 
        // the previous calls can be checked to return false)
        retVal = true;
        return retVal;
    }

    const makeTextFile = (arrText) =>  {

        let data = new Blob(arrText, {type: 'text/plain'});

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (textFile !== null) {
            window.URL.revokeObjectURL(textFile);
        }

        textFile = window.URL.createObjectURL(data);

        // Return a URL you can use as an href
        return textFile;
    }

    // Function will get searched user details on the basis of entered keywords(EmailID, PhoneNo, FirstName, & LastName).
    const searchUserInfoForSubscriptionDetails = (event) => {
    
        let modifiedState = state;
        event.preventDefault();
        modifiedState.data = [];

        modifiedState.errors.requiredFiled = ((modifiedState.ownersEmailID == null || modifiedState.ownersEmailID == '' || modifiedState.ownersEmailID.length < 0) &&
                                              (modifiedState.firstName == null || modifiedState.firstName == '' || modifiedState.firstName.length < 0) &&
                                              (modifiedState.lastName == null || modifiedState.lastName == '' || modifiedState.lastName.length < 0) &&
                                              (modifiedState.mobileNo == null || modifiedState.mobileNo == '' || modifiedState.mobileNo.length < 0)
                                             )

        ? "Please enter atleast any one field for Search." 
        : "";

        // if any one of the required fields are not entered by user then return the function and show error message.
        if(modifiedState.errors.requiredFiled.length > 0) {
            
            setState({...modifiedState});
            return
        }
        else {
            // Convert email to lowercase 
            modifiedState.ownersEmailID = (modifiedState.ownersEmailID != null && modifiedState.ownersEmailID.length > 0) ? modifiedState.ownersEmailID.toLowerCase() : "" ;

            const jsonBody = {
                FirstName: trimStringAndRemoveTrailingComma(modifiedState.firstName), 
                LastName: trimStringAndRemoveTrailingComma(modifiedState.lastName),
                EmailID: trimStringAndRemoveTrailingComma(modifiedState.ownersEmailID),
                PhoneNo: modifiedState.mobileNo, 
                InvokeFromPg: "AllSubscriptionDetails",
            };

            axios.post(`${getAPIHostURL()}/wclient/searchUserInfoForTrackingDevices`, jsonBody)
            .then(response => {
                
                if(response.data.code == 'SUCCESS') {

                    if(response.data.retreivedSearchResult == null || response.data.retreivedSearchResult.length <= 0) {
                        modifiedState.required = "No Search Result Found.";
                        alert("No matched search result with entered keywords.")
                    } else {
                        let stateSearchDataArr = [];
                        modifiedState.data = [];       

                        // React table checks using referential integrity. So if you do not create a
                        // new array (but just add to the existing array), the change detection will not trigger.
                        // So create a brand new array from existing product line Details data.
                        stateSearchDataArr = [...modifiedState.data]
                                                
                        const retreivedSearchData = response.data.retreivedSearchResult;

                        for(let i = 0; i < retreivedSearchData.length; i++) {
                            const searchResultDetails = retreivedSearchData[i];
                            let singleSearchRsltData = {
                                FirstName: searchResultDetails.FirstName == null ? "" : searchResultDetails.FirstName,
                                LastName: searchResultDetails.LastName == null ? "" : searchResultDetails.LastName,
                                OwnersEmailID: searchResultDetails.UserEmailID == null ? "" : searchResultDetails.UserEmailID,
                                MobileNo: searchResultDetails.UserPhoneNo == null ? "" : searchResultDetails.UserPhoneNo,
                            };
                            stateSearchDataArr.push(singleSearchRsltData);
                        } 
                        
                        modifiedState.data = stateSearchDataArr;
                    }
                } else {
                    if(response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the Required parameters were not sent to the Server
                        modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                    } else if(response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                setState({...modifiedState});
            })
            .catch(error => {
                console.log(error);
                console.log("Network error:");
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});
                }
            })
        }
    }

    // Radio Button to Switch between Search by User Info and search by Date and Time.
    // on switch we also Empty the TextBoxes and Array.
    const onSearchByRadioBtnChange = (e) => {
        
        let modifiedState = state;

        modifiedState.errors.requiredFiled = "";
        modifiedState.firstName = "";
        modifiedState.lastName = "";
        modifiedState.ownersEmailID = "";
        modifiedState.mobileNo = "";
        modifiedState.data = [];
        modifiedState.searchedBy = e.target.value;
        modifiedState.selectedReportType = "";
        modifiedState.errors.others = "";
        modifiedState.timeRelatedErrors = "";

        setState({...modifiedState});
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = state.errors;
        
        if(name == "mobileNo" && value.length > 12 ) {
            return;
        }

        switch (name) {

            case 'firstName': 
                errors.requiredFiled = ""       
            break;

            case 'lastName': 
                errors.requiredFiled = ""       
            break;

            case 'ownersEmailID': 
                errors.requiredFiled = ""  
            break;

            case 'mobileNo': 
                errors.requiredFiled = ""       
            break;

            default:
            break;
        }

        setState({
            ...state,
            errors, 
            [name]: value,
             
            // [event.target.name]: event.target.value,   
        });
    }

    // Function will highlight the selected row of React Table and also set selected row index no in State to further use.
    const getSelectedTrProps = (state, rowInfo, column, instance) => {
        let modifiedState = state
        if (rowInfo != undefined) {
            return {
                
                onClick: (e, handleOriginal) => {
                    modifiedState.selectedRowIndex = rowInfo.index;
                    setState({...modifiedState});
                },
                style: {
                    cursor: '',
                    background: rowInfo.index == state.selectedRowIndex ? '#6c95ba' : '',
                    color: rowInfo.index == state.selectedRowIndex ? 'white' : 'black',
                    alignItems: "center"
                }
            }
        } else {
            return {}
        }
    }

    return (
        <div>
            <div className="row justify-content-center">
                <div className="container col-lg-12,col-md-12 ,col-sm-12 ">
                    <div className = "customerTableHeading" style={{marginTop: "0.2rem"}}> Report - All Subscription/Rental Period Details</div> 
                    <div className="modal-body p-4 box" style={{marginTop: "0.2rem"}}>        
                        <div className="trackRadioDiv">
                            <div className = "trackradioBox">
                                <div>
                                    <input type='radio' name='searchBy'
                                        id = "searchByUser"
                                        value= "SearchByUser"
                                        onChange={onSearchByRadioBtnChange} noValidate 
                                        defaultChecked={true}
                                    /> 
                                    <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Search by User</span>
                                </div>
                                <div>
                                    <input type='radio' name='searchBy'  
                                        id = "SearchByDtTm" 
                                        value= "SearchByDtTm"
                                        onChange={onSearchByRadioBtnChange} noValidate  
                                    />
                                    <span style={{marginLeft: "0.3rem"}}> Search by Date and Time</span>
                                </div>
                            </div>
                        </div>
                        <div style={{display: state.searchedBy == "SearchByUser" ? "block" : "none", }} >
                            <div className="form-group trackFrmrow">
                                <div className="trackFrmLblAndInputDiv" >
                                    <div className="inputgroupCustom">
                                        <label className="trackFrmLabel">EmailID:</label>
                                        <div className="trackFrmInput">
                                            <input type='text' name='ownersEmailID' className="trackFrmInputForm"
                                                value={state.ownersEmailID}
                                                onChange={handleChange} noValidate    
                                            />
                                        </div>     
                                    </div>
                                </div>
                                <span className="trackFrmLblAndInputDiv" >
                                    <div className="inputgroupCustom">
                                        <label className="trackFrmLabel">Mobile No:</label>
                                        <div className="trackFrmInput">
                                            <input type='number' name='mobileNo' className=" trackFrmInputForm"
                                                value={state.mobileNo}
                                                onChange={handleChange} noValidate    
                                            />
                                        </div>     
                                    </div>
                                </span>
                            </div>
                            <div className="form-group trackFrmrow">
                                <div className="trackFrmLblAndInputDiv" >
                                    <div className="inputgroupCustom">
                                        <label className="trackFrmLabel">First Name:</label>
                                        <div className="trackFrmInput">
                                            <input type='text' name='firstName' className=" trackFrmInputForm"
                                                value={state.firstName}
                                                onChange={handleChange} noValidate    
                                            />
                                        </div>     
                                    </div>
                                </div>
                                <span className="trackFrmLblAndInputDiv" >
                                    <div className="inputgroupCustom">
                                        <label className="trackFrmLabel">Last Name:</label>
                                        <div className="trackFrmInput">
                                            <input type='text' name='lastName' className=" trackFrmInputForm"
                                                value={state.lastName}
                                                onChange={handleChange} noValidate    
                                            />
                                        </div>     
                                    </div>
                                </span>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                <div>
                                    <button type="submit" className="addCustSavebtn"  name="Save" onClick={searchUserInfoForSubscriptionDetails} >
                                        Search
                                    </button>
                                </div>    
                            </div>
                            <div style={{fontSize: "0.8rem", color: "var(--errorColor)", textAlign:"center"}}>{state.errors.requiredFiled}</div>
                            <div style={{fontSize: "0.8rem", color: "var(--errorColor)", textAlign:"center"}}>{state.errors.others}</div>                                                                          
                        </div>
                        <div  style={{display: state.searchedBy == "SearchByDtTm" ? "block" : "none"}}>
                            <div className="dropDownOfModelInfo" style = {{marginBottom: "2rem", marginTop: "2rem", position: "relative", right: "-1.2rem"}}>
                                <div className="selectedModelLable"
                                style = {{fontSize: "1rem"}}
                                >
                                    Select Report Type:
                                </div>
                                <div style={{marginLeft:"1%"}}>
                                    <select className="ProdInfoInput"
                                            style={{width:"100%", background:"white", height:"2rem"}} 
                                            required
                                            value={state.selectedReportType}
                                            onChange={onChangeSelectedReportType}
                                    >
                                        <option value="" select= "true" disabled>Report Type...</option> 
                                        {(state.ArrForStartEndSubscription).map((singleReportType,index) => <option key={index} value={singleReportType}>{singleReportType}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div style={{display:"flex", justifyContent:"space-evenly"}} className="startAndEnddate">
                                <div className="form-group update-form">
                                    <div className="inputgroupCustom">
                                        <label className="adduser-form-label">Start Date Time:</label>
                                        <DateTimePicker
                                            clearIcon={null}
                                            onChange={onChangeStartDateTime}
                                            onCalendarClose={onStartDateTimeCalendarOrClockClose}
                                            onClockClose={onStartDateTimeCalendarOrClockClose}
                                            className="input-form-datetime"
                                            format={"yyyy/MM/dd HH:mm:ss"} 
                                            value={state.StartDateTime}    
                                            name="StartDateTime"
                                        />
                                    </div>
                                </div>
                                <div className="form-group update-form">
                                    <div className="inputgroupCustom">
                                        <label className="adduser-form-label">End Date Time:</label>
                                        <DateTimePicker
                                            clearIcon={null}
                                            selected={state.EndDateTime}
                                            onChange={onChangeEndDateTime}
                                            onCalendarClose={onEndDateTimeCalendarOrClockClose}
                                            onClockClose={onEndDateTimeCalendarOrClockClose}
                                            className="input-form-datetime"
                                            format={"yyyy/MM/dd HH:mm:ss"} 
                                            value={state.EndDateTime} 
                                            name="EndDateTime"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{fontSize: "0.8rem", color: "green", textAlign:"center"}}>{state.timeRelatedErrors}</div>
                            <div style={{display:"flex", justifyContent:"center", marginBottom: "1rem", marginTop: "1.5rem"}} >
                                <div>
                                    <button type="button"
                                            onClick={OnClickDownloadAsCsvButton} 
                                            className="btn-sm reg-btn"
                                            style={{textTransform: "capitalize", color:"white", fontWeight:"bold"}}
                                    >
                                        Download As CSV 
                                    </button>
                                </div>
                                <div>
                                    <button type="button"
                                            className="btn-sm reg-btn"
                                            style={{textTransform: "capitalize", color:"white", marginLeft:"2rem", fontWeight:"bold", width:"100%"}} 
                                            onClick={OnClickViewButton}>View
                                    </button>
                                </div>
                            </div>
                            <div style={{fontSize: "0.8rem", color: "var(--errorColor)", textAlign:"center"}}>{state.errors.others}</div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {/* <div style={{display: state.searchedBy == "SearchByUser" ? "block" : "none", 
                                borderStyle: "solid", marginTop: "0rem", borderWidth: "1px"}}> */}
                <div style={{display: state.searchedBy == "SearchByUser" ? "block" : "none", 
                                }}>
                    <div className = "customerTableHeading"> Search Result</div> 
                    {/* <div  class="border border-3 ReactTableStyle"> Search Result</div>  */}
                    <div className="border border-3 ReactTableStyle">
                        <ReactTable columns={state.columns} 
                            data={state.data}
                            renderBasedOnTable = {"SearchResultTable"}
                            getCellProps={cellInfo => ({
                                onClick: () => {
                                    if(cellInfo.column.id == "SubscriptionDetails") {
                                        onClickSubscriptionDetails(cellInfo.row, cellInfo.column)                
                                    }                              
                                },
                            })}
                        />
                    </div>
                </div>
                <Modal size="xl" isOpen={state.enableSubscriptionStat} toggle={toggleSubscriptionStat} backdrop={state.backdrop}>
                    <ModalHeader toggle={toggleSubscriptionStat}>
                        All Subscription/Rental Period Information
                    </ModalHeader> 
                    <ModalBody>
                        <div style={{textAlign:"right", marginBottom:"1rem"}}>
                            <button style={{backgroundColor:"var(--primaryColor)", border:"1px solid var(--primaryColor)", borderRadius: "0.3rem", color: "white", fontWeight:"bold"}} onClick={OnClickDownloadDataAsCsvButtonInView}>Download As CSV</button>
                        </div>
                        {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px"}}> */}
                        <div className="border border-3 ReactTableStyle">
                            <ReactTable columns={state.SubscriptionDetailColumns} 
                                data={state.AllSubscriptionDataForSelectedTimePeriod}
                                renderBasedOnTable = {"SubscriptionDetailTable"}
                                getCellProps={() => ({})}
                            />
                        </div>
                        <div style={{textAlign:"center"}}>
                            <button type="button"
                                    className="adduserButton btn-lg"
                                    onClick={toggleSubscriptionStat}
                            > 
                                Close
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </div>
    );
}

export default VcAllSubscriptionDetailsInfo;


