import React, { Component } from 'react'
import { AppRelevantDataContext } from '../../AppContext';
// import {QrReader} from ' @otterscan/react-qr-reader';
import {QrScanner} from '@yudiel/react-qr-scanner';
import { FaQrcode } from 'react-icons/fa';
import axios from 'axios';
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import '../CSS/ComplaintForm.css';
import { getAPIHostURL } from '../../ClientConfig';
import { IDS_LoginServerIssue, IDS_RegistNetworkError } from '../../VcLanguage';
import { REGISTERED_STATUS } from '../../VcConstants';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

export class ComplaintRegistrationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            EmailID: "",
            phoneNo: "",
            selectedComplaintType: "",
            EnteredDeviceID: "",
            ComplaintDetails: "",
            rplcDevcQRCodeFlag: false,
            CountryCode: "+91",
            CountryInfo: [],
            ArrComplaintType:[],
            ComplaintType: [],
            manuallyAddedDeviceIDArrForDisplayBox: [],
            combinedAllUploadedFileName: [],
            fileToUploadFullData: [],
            fileToUploadInDisplayBox: "",
            filesToSendToBucket : [],
            arrUploadedModifiedFileName: [],
            errors: { 
                EmailID: '',
                phoneNo: '',
                ComplaintDetailsText: '',
                ComplaintCategory: '',
                invalidDevcID:"",
                fileUpload:"",
                others:''
            },
        }
    }

    handleChange = (event) => {
       
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        event.preventDefault();
        let modifiedState = this.state;

        modifiedState.errors.others = "";

        const { name, value } = event.target;
        let errors = modifiedState.errors;

        switch (name) {
            case 'EmailID': 
                errors.EmailID = 
                    value.length > 0 ?
                        (
                            (validEmailRegex.test(value)) ? 
                            '' :  
                            "EmailID is not valid!"
                        )
                    : '';
            break;

            case 'phoneNo': 
            errors.phoneNo = 
                (((value.length) > 0 && (value.length) < 4) || (value.length) > 12)
                    ? 'Phone number must be between 4 to 12 digits.' : '';      
            break;

            default:
            break;
        }

        this.setState({
            errors, 
            [name]: value,
            [event.target.name]:event.target.value,
            }, ()=> {
          })

    }

    componentDidMount () {
        this.getAllCountryCodeInfo();
        this.getAllComplaintType();
    }

    onChangeCountryCode = (e) => {
        let modifiedState = this.state;
        modifiedState.CountryCode = e.target.value;
        this.setState(modifiedState);
    }

    getAllCountryCodeInfo = () => {

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
    
        let modifiedState = this.state;
    
        axios.post(`${getAPIHostURL()}/wclient/getAllCountryCodeInformation`)
        .then(response => {
          if(response.data.code == "SUCCESS") {
            modifiedState.CountryInfo = [];
            modifiedState.CountryInfo = response.data.CountryInfo;
          } else {
            if(response.data.code == "SQL_ERROR"){
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
            }
          }
          this.setState(modifiedState);
        })
        .catch(error => {
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            // Tell the user that there are network issues
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            this.setState(modifiedState);
          } 
        });
    }

    onChangeComplaintSelection = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedComplaintType = e.target.value;
        this.setState(modifiedState);

        modifiedState.errors.ComplaintCategory = "";
    }

    // get complaint types for Complaint Type Dropdown field.
    getAllComplaintType = () => {

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
    
        let modifiedState = this.state;
        modifiedState.ArrComplaintType = [];

        axios.post(`${getAPIHostURL()}/wclient/getAllComplaintType`)
        .then(response => {
          if(response.data.code == "SUCCESS") {
              modifiedState.ComplaintType = [];
              modifiedState.ComplaintType = response.data.retrivedComplaintType;
              for(let i=0; i<modifiedState.ComplaintType.length; i++) {
                let SingleComplaintName = modifiedState.ComplaintType[i]['ComplaintCategory'];
                modifiedState.ArrComplaintType.push(SingleComplaintName);
              }
          } else {
            if(response.data.code == "SQL_ERROR"){
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
            }
        }
          this.setState(modifiedState);
        })
        .catch(error => {
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            // Tell the user that there are network issues
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            this.setState(modifiedState);
          } 
        });
    }

    handleKeyDown = (e) => {
        if (e.key == 'Enter') {
            // This is just to prevent form submission on pressing "enter".
            e.preventDefault();
            return;
        }
    }

    onEnterDeviceID = (e) => {
        let modifiedState = this.state;
        modifiedState.EnteredDeviceID = e.target.value;
        modifiedState.EnteredDeviceID = modifiedState.EnteredDeviceID.toUpperCase();
        //modifiedState.errors.others = '';
        modifiedState.errors.invalidDevcID = '';
        this.setState(modifiedState);  
    }

    openQRCameraForComplaintRegistrationForm  = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 
        
        this.setState(prevState => {
            let modifiedState = prevState;
            modifiedState.rplcDevcQRCodeFlag = !modifiedState.rplcDevcQRCodeFlag;
            // modifiedState.errors.others = "";
            modifiedState.errors.invalidDevcID = "";
            return modifiedState
        })
    }

    handleError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    handleScanResultOfComplaintRegistration = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.EnteredDeviceID = data;
            modifiedState.EnteredDeviceID = modifiedState.EnteredDeviceID.toUpperCase();
            modifiedState.rplcDevcQRCodeFlag = false;
            // modifiedState.errors.others = "";
            modifiedState.errors.invalidDevcID = "";
            if((modifiedState.EnteredDeviceID == null || modifiedState.EnteredDeviceID.length <= 0)) {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again."
            } 
            this.setState(modifiedState);
        }
    }

    onClickDeviceValidation = () => {
        let modifiedState = this.state;

        modifiedState.errors.invalidDevcID = "";
        // modifiedState.errors.others = "";

        if(modifiedState.manuallyAddedDeviceIDArrForDisplayBox.indexOf(modifiedState.EnteredDeviceID) != -1)  {
            modifiedState.errors.invalidDevcID = 'DeviceID already exist.';
            this.setState(modifiedState);
            return;
        } else if(modifiedState.EnteredDeviceID.length > 0) {
            this.getDeviceIDValidation();
        } else {
            //do nothing
        }
    }

    // validate entered Device ID(s).
    getDeviceIDValidation =  () => {
        let modifiedState = this.state;

        let jsonBody = {
            EnteredDeviceID: trimStringAndRemoveTrailingComma(modifiedState.EnteredDeviceID),
            invokedFrom: "complaintRegistrationForm"
        }

        axios.post( `${getAPIHostURL()}/wclient/checkDeviceIdExistence`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {    
                if(response.data.isDeviceIDExist == null) {
                    modifiedState.errors.invalidDevcID = "Invalid Device ID.";
                } else if(response.data.isDeviceIDExist == 0) {
                    modifiedState.errors.invalidDevcID = 'Invalid Device ID.';
                } else if(response.data.isStateIDValid == 0 || response.data.isStateIDValid == null) { 
                    modifiedState.errors.invalidDevcID = 'Invalid State of Device ID.';
                } else {
                    // Just wanted to show last inserted DeviceID at the top of DisplayBox.
                    modifiedState.manuallyAddedDeviceIDArrForDisplayBox.unshift(modifiedState.EnteredDeviceID);
                    modifiedState.EnteredDeviceID = "";
                }               
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                // modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
            }
        }); 
    }

    onChangeComplaintDetails = (e) => {
        let modifiedState = this.state;
        modifiedState.ComplaintDetails = e.target.value;
        modifiedState.errors.ComplaintDetailsText = "";

        this.setState(modifiedState);
    }

    // For saving all complaint registration information to database and sending email to privileges.
    onSubmit = (e) => {
        e.preventDefault();

        let modifiedState = this.state;

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        if((modifiedState.errors.phoneNo != null && modifiedState.errors.phoneNo.length > 0 ) 
            || (modifiedState.errors.EmailID != null && modifiedState.errors.EmailID.length > 0)) {
            this.setState(modifiedState);
            return;
        } else if((modifiedState.EmailID == null || modifiedState.EmailID.length <= 0) &&
            (modifiedState.phoneNo == null || modifiedState.phoneNo.length <= 0)) {
            modifiedState.errors.others = "Either one of 'Email ID' or 'Phone No.' is required."
            this.setState(modifiedState);
            return;
        } else if((modifiedState.manuallyAddedDeviceIDArrForDisplayBox == null || modifiedState.manuallyAddedDeviceIDArrForDisplayBox.length <= 0) && 
            (modifiedState.EnteredDeviceID != null && modifiedState.EnteredDeviceID.length > 0)) {
            modifiedState.errors.invalidDevcID = 'Please Add Entered Device ID into Display Box.';
            this.setState(modifiedState);
            return;
        } else if(modifiedState.selectedComplaintType == null  || modifiedState.selectedComplaintType <= 0) {
            modifiedState.errors.ComplaintCategory = 'Please select Complaint Type.';
            this.setState(modifiedState);
            return;
        } else if(modifiedState.ComplaintDetails == null  || modifiedState.ComplaintDetails <= 0) {
            modifiedState.errors.ComplaintDetailsText = 'Please Enter Complaint Details.';
            this.setState(modifiedState);
            return;
        } else if(modifiedState.errors.invalidDevcID != null && modifiedState.errors.invalidDevcID.length > 0) {
            this.setState(modifiedState);
            return;
        } else if(modifiedState.ComplaintDetails != null && modifiedState.ComplaintDetails.length > 1000) {
            modifiedState.errors.ComplaintDetailsText = 'Complaint Details Character limit has exceed. It should be between 1 to 1000 Characters.';
            this.setState(modifiedState);
            return;
        } else if(modifiedState.errors.fileUpload != null && modifiedState.errors.fileUpload.length > 0) {
            this.setState(modifiedState);
            return;
        } else {
            // If modifiedState.filesToSendToBucket variable is empty that means there is no uploaded image
            // as upload image is not a compulsory field, so if modifiedState.filesToSendToBucket variable is empty then
            // call this.updateComplaintRegstDataAndSendEmailToPrivileges(), that is register complaint and send email to privileges and 
            // if modifiedState.filesToSendToBucket variable is not empty means there is uploaded image so
            // in that case call this.uploadComplaintFileIntoS3Bucket() that is upload image into s3 bucket and then 
            // register complaint and send email to privileges.
            if(modifiedState.filesToSendToBucket != null && modifiedState.filesToSendToBucket.length > 0 ){
                this.uploadComplaintFileIntoS3Bucket();
            } else {
                this.updateComplaintRegstDataAndSendEmailToPrivileges();
            }
        }
    }

    uploadComplaintFileIntoS3Bucket = () => {

        // e.preventDefault();
        let modifiedState = this.state;

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        // append all the uploaded files into file data and send fileData to backend.
        let fileData = new FormData(); 
        for(var x = 0; x< modifiedState.filesToSendToBucket.length; x++) {
            fileData.append('multipleFilesToUploaded', modifiedState.filesToSendToBucket[x]);
        }

        // Show spinner to restrict the user to perform any actions.
        appRelevantDataContextValue.onChangeProcessingReq(true);

        axios.post(`${getAPIHostURL()}/wclient/uploadComplaintFileIntoS3Bucket`, fileData)
        .then(response => {

            modifiedState.arrUploadedModifiedFileName = [];

            if(response.data.code == "SUCCESS") {
                
                if(response.data.uploadedFile == null || response.data.uploadedFile.length <= 0 ) {

                    // Remove spinner and allow user to perform action.
                    appRelevantDataContextValue.onChangeProcessingReq(false);   

                    // File upload was unsuccessful because the file name was null or the file name was empty.
                    modifiedState.errors.others = "Please select Files for Uploading.";
                    this.setState(modifiedState);
                    return;

                } else {

                    let arrMultipleUploadedFileInfo = response.data.uploadedFile;

                    modifiedState.arrUploadedModifiedFileName = [];

                    for(let i = 0;  i<arrMultipleUploadedFileInfo.length; i++) {

                        if (arrMultipleUploadedFileInfo[i]["key"] != null && arrMultipleUploadedFileInfo[i]["key"].length > 0 ){
                            modifiedState.arrUploadedModifiedFileName.push(arrMultipleUploadedFileInfo[i]["key"]);
                        }
                    }
                
                    // if the file is uploaded successfully into the s3 bucket then call this.updateComplaintRegstDataAndSendEmailToPrivileges()
                    // in order to register the complaint and send email to privileges with the uploaded image(s).
                    this.updateComplaintRegstDataAndSendEmailToPrivileges(modifiedState);
                }
            } else {

                // Remove spinner and allow user to perform action.
                appRelevantDataContextValue.onChangeProcessingReq(false);

                if(response.data.code == 'UPLOAD_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues while uploading file.\nTry again later.";
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = "Server experiencing issues while uploading file.\nTry again later.";
                }
            }
            this.setState(modifiedState);
        })
        .catch(error => {

            // Remove spinner and allow user to perform action.
            appRelevantDataContextValue.onChangeProcessingReq(false);

            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
            //   modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState); 
            } 
        });
    }
    
    updateComplaintRegstDataAndSendEmailToPrivileges = (inModifiedState = null) => {

       // e.preventDefault();

        let modifiedState;

        if(inModifiedState == null){
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let loggedinUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        let RegisteredStatus = REGISTERED_STATUS;

        // replace all the single quotes to double quotes and escape character with double escape character while storing into database.
        let ComplaintDescription = modifiedState.ComplaintDetails.replace(/'/g, "''").replace(/\\/g, "\\\\");

        let jsonBody = {
            EmailID: (modifiedState.EmailID != null && modifiedState.EmailID.length > 0) ? trimStringAndRemoveTrailingComma(modifiedState.EmailID) : "",
            PhoneNo:(modifiedState.phoneNo != null && modifiedState.phoneNo.length > 0) ? modifiedState.CountryCode + " " + modifiedState.phoneNo : "",
            ComplaintType: (modifiedState.selectedComplaintType != null && modifiedState.selectedComplaintType.length > 0) ? modifiedState.selectedComplaintType : "",
            DeviceID: (modifiedState.manuallyAddedDeviceIDArrForDisplayBox != null && modifiedState.manuallyAddedDeviceIDArrForDisplayBox.length > 0) ? (modifiedState.manuallyAddedDeviceIDArrForDisplayBox) : [],
            ComplaintDetails: trimStringAndRemoveTrailingComma(ComplaintDescription),
            StatusOfComplaint: RegisteredStatus, // set status as registered on every complaint registration.
            SetByUser: loggedinUserID,
            FileName: (modifiedState.arrUploadedModifiedFileName != null && modifiedState.arrUploadedModifiedFileName.length > 0) ? (modifiedState.arrUploadedModifiedFileName) : []
        }

        // Show spinner to restrict the user to perform any actions.
        appRelevantDataContextValue.onChangeProcessingReq(true);

        axios.post(`${getAPIHostURL()}/wclient/updateComplaintRegstDataAndSendEmailToPrivileges`, jsonBody)
        .then(response => {

            // Remove spinner and allow user to perform action.
            appRelevantDataContextValue.onChangeProcessingReq(false);

            if(response.data.code == 'SUCCESS') {  
                
                alert(`Successfully Registered New Complaint and the Email will be sent to the respective person with Complaint details.`);

                // Clear all fields in the form when user successfully registered a new complaint.
                modifiedState.errors.others = "";
                modifiedState.errors.EmailID = "";
                modifiedState.errors.phoneNo = "";
                modifiedState.errors.invalidDevcID = "";
                modifiedState.EmailID = "";
                modifiedState.phoneNo = "";
                modifiedState.selectedComplaintType = "";
                modifiedState.manuallyAddedDeviceIDArrForDisplayBox = [];
                modifiedState.EnteredDeviceID = "";
                modifiedState.ComplaintDetails = "";
                modifiedState.rplcDevcQRCodeFlag = false;
                modifiedState.combinedAllUploadedFileName = [];
                modifiedState.errors.fileUpload = "";
                modifiedState.fileToUploadFullData = [];
                modifiedState.fileToUploadInDisplayBox = "";
                modifiedState.filesToSendToBucket = [];
                modifiedState.arrUploadedModifiedFileName = [];

                document.getElementById("ComplaintRegst").reset();
    
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                else if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                }
                else if(response.data.code == 'CUST_DETAILS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.'
                }
                else if(response.data.code == 'PRIVILEGES_NAME_MISSING') { 
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.'
                }
                else if(response.data.code == 'EMAIL_ID_MISSING'){
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.'
                }
                else if(response.data.code == 'SUPPORT_DETAILS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.'
                }
                else if(response.data.code == 'ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues while sending Email.\nTry again later.'
                }
                else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {

            // Remove spinner and allow user to perform action.
            appRelevantDataContextValue.onChangeProcessingReq(false);

            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                // modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
            }
        });           
    }

    handleKeyPress = (e) => {
        const characterCode = e.key
        const characterNumber = Number(characterCode)
        if (characterNumber >= 0 && characterNumber <= 9) {

            if (e.currentTarget.value && e.currentTarget.value.length) {
                return;
            } 
            else if (characterNumber == 0) {
                e.preventDefault()
            }
        } 
        else {
            e.preventDefault()
        }
    } 

    onFileSelection = (e) => {

        let modifiedState = this.state;

        // For getting all information about file.
        const files = e.target.files;

        modifiedState.errors.fileUpload = "";
        this.setState(modifiedState);

        if(modifiedState.combinedAllUploadedFileName != null && modifiedState.combinedAllUploadedFileName.length > 3 ){
            alert("You can only select maximum of 4 Files");
            document.getElementById("files").value = "";
            return;
        } else {
        
            // call this.checkMimeType(e) to check if image format is valid or not.
            let objCheckMimeType = this.checkMimeType(e);

            // call this.checkFileSize(e) to check if size is within 1 MB or not.
            let objCheckFileSize = this.checkFileSize(e);

            if((files != null) && (objCheckFileSize.isFileSizeWithinLimit && objCheckMimeType.isMimeTypeFromDeclaredType)){ 
                // if return true allow to assign data
                modifiedState.fileToUploadFullData = files;

                // here we are storing just the file name which we are going to add in the display box.
                modifiedState.fileToUploadInDisplayBox = modifiedState.fileToUploadFullData[0].name;

                // call the function to upload selected image into the display box
                // Only image name will be displayed on the top.
                this.uploadFileIntoDisplayBox(modifiedState);

            } else {
                modifiedState.fileToUploadFullData  = [];
                modifiedState.errors.fileUpload = (objCheckMimeType.fileErrToSend != null && objCheckMimeType.fileErrToSend.length > 0) ?
                                                    objCheckMimeType.fileErrToSend : objCheckFileSize.fileErrToSend;
                
                this.setState(modifiedState);
            }
            
        }
    }


    checkMimeType = (e) => {
        //getting file object
        let files = e.target.files;

        let isMimeTypeFromDeclaredType = false;
        let fileErrToSend = ""

        // list allow mime type
        const types = ['image/png', 'image/jpeg', 'image/gif', "image/bmp", "image/tiff"];

        for(var x = 0; x<files.length; x++) {
            // compare file type and see which file doesn't match then show the error message.
            if (types.every(type => files[x].type !== type)) { 
                fileErrToSend = `${(files[x].type == null || files[x].type.length <= 0) ? 'This' : 'The ' + "'" + files[x].type + "'"} is not a supported format.`;
            } else {
                fileErrToSend = "";
                isMimeTypeFromDeclaredType = true;
            }
        };
        return {isMimeTypeFromDeclaredType, fileErrToSend};
    }

    checkFileSize = (e) => {

        //getting file object
        let files = e.target.files
        let size = 1048576; //1MB

        let isFileSizeWithinLimit = false;
        let fileErrToSend = ""

        for(let x = 0; x<files.length; x++) {
            // if file is less than 1 mb then show the error message.
            if(files[x].size > size) {
                fileErrToSend = 'The file size should not exceed 1MB, please select a smaller file.';
            } else {
                fileErrToSend = "";
                isFileSizeWithinLimit = true;
            }  
        };

        return {isFileSizeWithinLimit, fileErrToSend};
    }

    // after checking size and image format allow user to upload image into the display box.
    uploadFileIntoDisplayBox = (inModifiedState = null) => {

        let modifiedState ;

        if(inModifiedState == null){
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }
        
        if(modifiedState.errors.fileUpload != null && modifiedState.errors.fileUpload.length > 0) {
            return;
        } else if(modifiedState.combinedAllUploadedFileName != null && modifiedState.combinedAllUploadedFileName.length > 0 &&
                modifiedState.combinedAllUploadedFileName.indexOf( modifiedState.fileToUploadInDisplayBox) != -1)  {

            // if the same file is already uploaded then show the error message.
            modifiedState.errors.fileUpload = "This Particular File is already Uploaded. If you want to upload the same file, please change the name of the file and try uploading again.";
            this.setState(modifiedState);
            return;
        } else {

            if(modifiedState.fileToUploadFullData != null && modifiedState.fileToUploadFullData.length > 0) {
                // just show the uploaded file name at the top of the display box
                // here we are uploading just the file name into the display box and not the whole file data.
                modifiedState.combinedAllUploadedFileName.unshift(modifiedState.fileToUploadInDisplayBox);
            }

            // keep appending all the file imformation in modifiedState.filesToSendToBucket 
            // and finally pass this variable to the uploadComplaintFileIntoS3Bucket function
            // all the uploaded files information is stored in this variable and at last passed to uploadComplaintFileIntoS3Bucket function
            // for appending to form data 
            // as for storing all the files into S3 bucket we require the whole file data and not just the name of the file.
            modifiedState.filesToSendToBucket.push(modifiedState.fileToUploadFullData[0]);

            modifiedState.fileToUploadFullData  = [];
            document.getElementById("files").value = "";

            this.setState(modifiedState);
        }

    }
        
    render() {
        return (
            <div>
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-md-8">
                        <div className = "customerTableHeading" style={{marginTop: "0.2rem", fontSize:"1.75rem"}}>Complaint Form</div>
                        <div className="modal-body p-4 box" style={{marginTop: "0.2rem", paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
                            <label style={{ display: "flex", justifyContent:"flex-start", fontSize:"0.7rem", marginLeft:"1rem", marginBottom:"0rem"}}>
                                <b style={{marginRight:"0.1rem"}}>Note:
                                </b> Fields marked in 
                                <span style={{color:"var(--errorColor)", fontSize:"1rem", marginLeft:"0.1rem", marginRight:"0.1rem"}}>* 
                                </span> are compulsory.
                            </label>
                            <form onSubmit={this.onSubmit} id= "ComplaintRegst">
                                <div style={{marginBottom: "0.5rem", border: "0.1rem solid #a8cca8", backgroundColor:"#F8F8F8", borderRadius:"0.7rem"}} >
                                    <div className="trackFrmLblAndInputDiv">
                                        <label className="complaint-form-label" style={{fontSize:"0.8rem"}}
                                        >
                                            <span style={{color:"var(--errorColor)", fontSize:"1rem", marginRight:"0.2rem"}}> * </span> Please Enter at least Email ID or Phone No.
                                        </label>
                                    </div>
                                    <div className="form-group trackFrmrow" style={{marginBottom:"0.5rem"}}>
                                        <div className="trackFrmLblAndInputDiv" style={{marginBottom:"0.5rem"}}>
                                            <label className="reg-form-label" 
                                                style={{width:"90%", fontSize:"0.9rem"}}
                                            >
                                                Email ID:
                                            </label>
                                            <input type='text' name='EmailID' className="trackFrmInputForm"
                                                style={{width:"90%", fontSize:"0.9rem"}}
                                                value={this.state.EmailID.toLowerCase()}
                                                onChange={this.handleChange} Validate    
                                            />
                                            {this.state.errors.EmailID.length > 0 && 
                                                <div className='addCustError' style={{marginTop:"1rem", textAlign:"center", fontSize:"0.8rem"}}>{this.state.errors.EmailID}</div>}  
                                        </div>
                                        <div className="trackFrmLblAndInputDiv" style={{marginBottom:"0.5rem"}}>
                                            <label className="reg-form-label" 
                                                style={{width:"90%", fontSize:"0.9rem"}}
                                            >
                                                Phone No:
                                            </label>
                                            <div style={{display:"flex", justifyContent:"space-around"}}>
                                                <span style={{flex:"2"}}>
                                                    <select className="trackFrmInputForm" 
                                                            style={{width:"85%", marginRight:"-1.5rem", textAlign:"center", fontSize:"0.9rem"}}
                                                            value={this.state.CountryCode}
                                                            onChange={this.onChangeCountryCode}
                                                    >
                                                        <option value="+91" select= "true">+91</option> 
                                                            {(this.state.CountryInfo).map((singleCountryCode, index) =>
                                                        <option key={index} value={singleCountryCode["CountryCode"]}>
                                                            {singleCountryCode["CountryCode"]}
                                                            {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                                            {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                                            {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                                            {'\xA0'+singleCountryCode["Country"]}</option>)}
                                                    </select>
                                                </span>
                                                <span style={{ flex:"6"}}>
                                                    <input type="number" name='phoneNo' className="trackFrmInputForm"
                                                        value={this.state.phoneNo}
                                                        onKeyPress= {this.handleKeyPress} 
                                                        style={{width:"85%", fontSize:"0.9rem"}}
                                                        onChange={this.handleChange} validate
                                                    />
                                                    {this.state.errors.phoneNo.length > 0 && 
                                                        <div className='addCustError' style={{marginTop:"1rem", textAlign:"center", marginLeft:"-2.5rem", fontSize:"0.8rem"}}>{this.state.errors.phoneNo}</div>}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group trackFrmrow" style={{marginTop: "0.5rem", marginBottom:"0.5rem"}}>
                                    <div className="trackFrmLblAndInputDiv" >
                                        <label className="reg-form-label" 
                                            style={{width:"90%", fontSize:"0.9rem"}}
                                        >
                                            Complaint Type:
                                            <span className="addCustRequiredMarkStar">*</span>
                                        </label>
                                        <select type='text' className="trackFrmInputForm"
                                            style={{width: "90%", fontSize:"0.9rem"}}
                                            value={this.state.selectedComplaintType}
                                            onChange={this.onChangeComplaintSelection}     
                                        >
                                            <option value="" select= "true" disabled>Complaint Type</option> 
                                            {(this.state.ArrComplaintType).map((singleComplaintType,index) => <option key={index} value={singleComplaintType}>{singleComplaintType}</option>)}
                                        </select>
                                        {this.state.errors.ComplaintCategory.length > 0 && 
                                                <div className='addCustError' style={{marginTop:"1rem", textAlign:"center", fontSize:"0.8rem"}}>{this.state.errors.ComplaintCategory}</div>}  
                                    </div>
                                    <div className="trackFrmLblAndInputDiv" style={{marginTop:"0.29rem"}}>
                                        <label className="reg-form-label" 
                                            style={{width:"90%", fontSize:"0.9rem"}}
                                        >
                                            Enter Device ID(s):
                                        </label>
                                        <div style={{display:"flex", className:""}}>
                                            <span style={{flex:"3"}}>
                                                <input className="trackFrmInputForm"
                                                    style={{width:"87%", fontSize:"0.9rem"}}
                                                    value={this.state.EnteredDeviceID}
                                                    onKeyDown={this.handleKeyDown}
                                                    onChange={this.onEnterDeviceID}     
                                                />
                                                <label onClick = {this.openQRCameraForComplaintRegistrationForm} className = "qrcodeTextBtn">
                                                    <FaQrcode className = "qrcodeTextBtnIcon"/>
                                                </label>
                                                <div>
                                                    {(this.state.rplcDevcQRCodeFlag == true)
                                                    ?
                                                        // <div style={{display: "flex", justifyContent: "center", marginTop: "1rem", marginLeft:"3rem"}}>
                                                        //     <QrReader
                                                        //         scanDelay={300}
                                                        //         onResult={(result, error) => {
                                                        //             if (!!result) {
                                                        //                 this.handleScanResultOfComplaintRegistration(result?.text);
                                                        //             }
                                                        //             // if (!!error) {
                                                        //             //     console.log("error = ",error);
                                                        //             // }
                                                        //         }}
                                                        //         // onError={this.handleError}
                                                        //         // onScan={this.handleScanResultOfComplaintRegistration}
                                                        //         className = "QRCodeCamBoxForModalAndContainer"
                                                        //     />
                                                        // </div>
                                                        <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                                marginTop:"1rem", marginBottom: "1rem", display: "block", marginLeft: "auto",
                                                                marginRight: "auto"}}>                                                       
                                                        <QrScanner
                                                            scanDelay={300}
                                                            onResult={(result, error) => {
                                                                if (!!result) {
                                                                    this.handleScanResultOfComplaintRegistration(result?.text);
                                                                }
                                                                // if (!!error) {
                                                                //     console.log("error = ",error);
                                                                // }
                                                            }}
                                                            // onError={this.handleError}
                                                            // onScan={this.handleScanResultOfComplaintRegistration}
                                                            className = "QRCodeCamBoxForModalAndContainer"
                                                        />
                                                    </div>
                                                    :
                                                        <div/>
                                                    }
                                                </div>
                                                {this.state.errors.invalidDevcID.length > 0 && 
                                                    <div className='addCustError' style={{marginTop:"1rem", textAlign:"center", marginLeft:"2rem", fontSize:"0.8rem"}}>{this.state.errors.invalidDevcID}</div>}
                                            </span>
                                            <span style={{flex:"1", marginLeft:"1rem"}}> 
                                                <button type="button" 
                                                    style={{textTransform:"capitalize", fontWeight:"bold", borderRadius:"0.3rem", height: "2.2rem", marginLeft: "-2rem", fontSize: "0.9rem",
                                                    width:"85%"}}
                                                    className="btn-sm reg-btn"
                                                    onClick={this.onClickDeviceValidation} 
                                                > ADD
                                                </button>
                                            </span> 
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group trackFrmrow" style={{marginTop: "0.5rem", marginBottom:"0.5rem"}}>
                                    <div className="trackFrmLblAndInputDiv" >
                                        <label className="reg-form-label" 
                                            style={{width:"90%", fontSize:"0.9rem"}}
                                        >
                                            Complaint Details(Max limit 1000 Characters):
                                            <span className="addCustRequiredMarkStar">*</span>
                                        </label>
                                        <textarea type='message' name='ComplaintDetails' className="trackFrmInputForm"
                                            style={{width: "90%", height:"9.3rem", fontSize:"0.9rem"}}
                                            value={this.state.ComplaintDetails}
                                            onChange={this.onChangeComplaintDetails}
                                            maxLength = "1000" 
                                        />
                                        {this.state.errors.ComplaintDetailsText.length > 0 && 
                                                <div className='addCustError' style={{marginTop:"1rem", textAlign:"center", fontSize:"0.8rem"}}>{this.state.errors.ComplaintDetailsText}</div>}  
                                    </div>
                                    <div className="trackFrmLblAndInputDiv" style={{marginTop:"0.25rem"}}>
                                        <label className="reg-form-label" style={{width:"90%", fontSize:"0.9rem"}}>
                                            Displaying Device ID(s):
                                        </label>
                                        <select  className="input-form DisplayBoxForSelect" size="10" style={{height:"9.3rem", width: "90%", fontSize:"0.9rem"}}>
                                            {(this.state.manuallyAddedDeviceIDArrForDisplayBox).map((addedDevcID,index) => <option key={index} value={addedDevcID}>{addedDevcID}</option>)}
                                        </select>                                  
                                    </div> 
                                </div>
                                <div style={{paddingRight: "2.5rem", marginTop: "0.5rem", marginBottom:"0.5rem"}}>
                                    <div className="form-group addCustForm">
                                        <div className="input-group" style={{marginLeft:"1.5rem", marginRight:"1.5rem"}}>
                                            <label className="addCustFormLabelWithRequiredFiled" style={{fontSize: "0.9rem"}}>
                                            Upload Files(Max size 1MB):
                                                <span className="addCustRequiredMarkStar"></span>
                                            </label>
                                            <div className="addCustInputAndError">
                                                <input type="file" className="" 
                                                    style={{width: "85%"}}
                                                    id='files'
                                                    onChange={this.onFileSelection} 
                                                    // multiple    
                                                />
                                                <div>
                                                    {this.state.errors.fileUpload.length > 0 && 
                                                        <span className='addCustError' style={{fontSize:"0.8rem"}}>{this.state.errors.fileUpload}</span>} 
                                                </div>
                                                {/* <button type="button" name="fileUpload" 
                                                onClick={this.uploadFileIntoDisplayBox}
                                                    style={{ borderRadius:"0.3rem", marginLeft:"0.5rem", 
                                                            background:"var(--primaryColor)", color:"white", border:"tranparent",
                                                            textTransform:"capitalize", fontWeight:"bold", height: "2.2rem",
                                                            borderColor: "var(--primaryColor)", fontSize:"0.9rem"
                                                    }} 
                                                >
                                                    <b>UPLOAD</b>
                                                </button> */}
                                            </div>     
                                        </div> 
                                    </div> 
                                    <div className="form-group addCustForm" style={{marginRight:"0.4rem"}}>
                                        <div className="input-group" style={{marginLeft:"1.5rem", marginRight:"1.5rem"}}>
                                            <label className="addCustFormLabelWithRequiredFiled" style={{fontSize:"0.9rem"}}>
                                                Uploaded Files(Max 4 files):
                                            </label>
                                            <div className="addCustInputAndError">
                                                <select size="4"
                                                        style={{width:"100%", borderRadius:"5px", 
                                                                border:"1px solid var(--primaryColor)", color:"black"
                                                        }}
                                                >
                                                    {(this.state.combinedAllUploadedFileName).map((singleUploadedFileName,index) => <option key={index} value={singleUploadedFileName}>{singleUploadedFileName}</option>)}
                                                </select>
                                            </div>     
                                        </div>
                                    </div>
                                </div>
                                <span style={{display:"flex", justifyContent: "center", flexDirection:"column", marginTop: "0.5rem"}}>
                                    <button type={"submit"}
                                            className="btn-lg reg-complaint-btn"
                                            style={{fontSize:"1.1rem"}}
                                    > 
                                        <b>Register Complaint</b>
                                    </button>
                                    <span style={{color:"red", fontSize:"0.8rem", marginTop: "1rem", fontSize:"0.8rem"}}>{(this.state.errors.others != null && this.state.errors.others.length > 0) && this.state.errors.others}</span> 
                                </span> 
                            </form> 
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

ComplaintRegistrationForm.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default ComplaintRegistrationForm
