import React, { useState, useContext} from 'react'
import '@studysync/react-splitter-layout/lib/index.css';
import { AppRelevantDataContext } from '../../AppContext';
import { FaSadTear, FaFrownOpen, FaGrinBeam, FaDizzy, FaMeh, FaSmile} from 'react-icons/fa';
import '../CSS/ParamDocumentation.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FiInfo } from "react-icons/fi";
import VcVRIandMRIInfo from './VcVRIandMRIInfo';
import { 
    IDS_Good, IDS_Satisfactory, IDS_Moderate, IDS_Poor, IDS_Severe, IDS_Onwards, IDS_AlertLevel1, IDS_AlertLevel2, 
    IDS_AlertLevel3, IDS_AlertLevel4, IDS_AlertLevel5, IDS_NoRange, IDS_RangeLowSevere, IDS_RangeHighSevere, IDS_RangeVeryPoor,IDS_RangeLowVeryPoor,
    IDS_Comfortable, IDS_Hot, IDS_ColdAndChilly, IDS_Humid, IDS_Sticky, IDS_Dry, IDS_Warm, IDS_BarelySensedInSingleDeviceData,
    IDS_WeakOdourInSingleDeviceData, IDS_EasilyRecognizedInSingleDeviceData, IDS_StrongOdourInSingleDeviceData,
    IDS_PungentInSingleDeviceData, IDS_Upto, IDS_SevereAqi, IDS_VeryPoor, IDS_PoorAqi, IDS_VeryPoorAqi, IDS_AlertLevel6, IDS_SatisfactoryInSingleDeviceData,
    IDS_LightRain, IDS_ModerateRain, IDS_HeavyRain, IDS_VeryHeavyRain, IDS_TorrentialRain, IDS_GoodVisibility, IDS_ModerateVisibility, IDS_FairVisibility, 
    IDS_PoorVisibility, IDS_CalmBreeze, IDS_ModerateBreeze, IDS_StrongGale, IDS_Storm, IDS_LowLightIntensity, IDS_MediumLightIntensity, IDS_HighLightIntensity, IDS_LowNoiseLevel, IDS_MediumNoiseLevel, IDS_HighNoiseLevel,
    IDS_ExtremeLow, IDS_Low, IDS_High, IDS_ExtremeHigh,
    IDS_Normal, IDS_Extreme, IDS_VeryHigh, IDS_VeryLow, IDS_LowSevere, IDS_GentleBreeze, IDS_DetailsAndRangesOfSensor, IDS_ParamsRange, IDS_WhatIsVirusRiskIndex, IDS_WhatIsMouldRiskIndex,
    IDS_Value, IDS_SafeRange, IDS_WarningRange, IDS_CriticalRange, IDS_EmergencyRange, IDS_HazardousRange, IDS_HealthImpacts_AQI_Level6, IDS_HealthImpacts_TEMP_Level1, 
    IDS_HealthImpacts_TEMP_Level2, IDS_HealthImpacts_TEMP_Level3, IDS_HealthImpacts_TEMP_Level4, IDS_HealthImpacts_HUM_Level1, IDS_HealthImpacts_HUM_Level2, IDS_HealthImpacts_HUM_Level3, IDS_HealthImpacts_HUM_Level4, IDS_HealthImpacts_Odour_Level1, 
    IDS_HealthImpacts_Odour_Level2, IDS_HealthImpacts_Odour_Level3, IDS_HealthImpacts_Odour_Level4, IDS_HealthImpacts_Odour_Level5, IDS_HealthImpacts_Odour_Level6, IDS_HealthImpacts_VOC_Level1, IDS_HealthImpacts_VOC_Level2, IDS_HealthImpacts_VOC_Level3, 
    IDS_HealthImpacts_VOC_Level4, IDS_HealthImpacts_VOC_Level5, IDS_HealthImpacts_AQI_Level1, IDS_HealthImpacts_AQI_Level2, IDS_HealthImpacts_AQI_Level3, IDS_HealthImpacts_AQI_Level4, IDS_HealthImpacts_AQI_Level5, IDS_HealthImpacts_CAQI_Level1, IDS_HealthImpacts_CAQI_Level2, 
    IDS_HealthImpacts_CAQI_Level3, IDS_HealthImpacts_CAQI_Level4, IDS_HealthImpacts_CAQI_Level5, IDS_HealthImpacts_CAQI_Level6, IDS_HealthImpacts_CO_Level1, IDS_HealthImpacts_CO_Level2, IDS_HealthImpacts_CO_Level3, IDS_HealthImpacts_CO_Level4, IDS_HealthImpacts_CO_Level5, 
    IDS_HealthImpacts_CO2_Level1, IDS_HealthImpacts_CO2_Level2, IDS_HealthImpacts_CO2_Level3, IDS_HealthImpacts_CO2_Level4, IDS_HealthImpacts_CO2_Level5, IDS_HealthImpacts_H2S_Level1, IDS_HealthImpacts_H2S_Level2, IDS_HealthImpacts_H2S_Level3, IDS_HealthImpacts_H2S_Level4, 
    IDS_HealthImpacts_H2S_Level5, IDS_HealthImpacts_MRI_Level1, IDS_HealthImpacts_MRI_Level2, IDS_HealthImpacts_MRI_Level3, IDS_HealthImpacts_MRI_Level4, IDS_HealthImpacts_MRI_Level5, IDS_HealthImpacts_NO2_Level1, IDS_HealthImpacts_NO2_Level2, IDS_HealthImpacts_NO2_Level3,
    IDS_HealthImpacts_NO2_Level4, IDS_HealthImpacts_NO2_Level5, IDS_HealthImpacts_O3_Level1, IDS_HealthImpacts_O3_Level2, IDS_HealthImpacts_O3_Level3, IDS_HealthImpacts_O3_Level4, IDS_HealthImpacts_O3_Level5, IDS_HealthImpacts_PM1_Level1, IDS_HealthImpacts_PM1_Level2,
    IDS_HealthImpacts_PM1_Level3, IDS_HealthImpacts_PM1_Level4, IDS_HealthImpacts_PM1_Level5, IDS_HealthImpacts_PM10_Level1, IDS_HealthImpacts_PM10_Level2, IDS_HealthImpacts_PM10_Level3, IDS_HealthImpacts_PM10_Level4, IDS_HealthImpacts_PM10_Level5, 
    IDS_HealthImpacts_PM25_Level1, IDS_HealthImpacts_PM25_Level2, IDS_HealthImpacts_PM25_Level3, IDS_HealthImpacts_PM25_Level4, IDS_HealthImpacts_PM25_Level5, IDS_HealthImpacts_SO2_Level1, IDS_HealthImpacts_SO2_Level2, IDS_HealthImpacts_SO2_Level3, 
    IDS_HealthImpacts_SO2_Level4, IDS_HealthImpacts_SO2_Level5, IDS_HealthImpacts_VRI_Level1, IDS_HealthImpacts_VRI_Level2, IDS_HealthImpacts_VRI_Level3, IDS_HealthImpacts_VRI_Level4, IDS_HealthImpacts_VRI_Level5, IDS_hydrogenSulphide, IDS_Methane, 
    IDS_PPB, IDS_PPM, IDS_Celsius, IDS_Percent, IDS_Index, IDS_ParameterValue, IDS_airQualityIndex, IDS_currentAirQualityIndex, IDS_nitrogenDioxide, IDS_Ozone, IDS_sulphurDioxide, IDS_TVOC, IDS_carbonMonoxide, IDS_Ammonia, IDS_carbonDioxide, 
    IDS_LPG, IDS_SMOKE, IDS_Dust, IDS_Temperature, IDS_Humidity, IDS_Odour, IDS_VRI, IDS_MRI, IDS_PFC, IDS_CW, IDS_SL, IDS_Formaldehyde, IDS_Oxygen, IDS_NitricOxide, IDS_Chlorine, IDS_MethylMercaptan, IDS_Noise, IDS_LightIntensity, IDS_UV, IDS_Radon, IDS_AirPressure, IDS_WindSpeed, 
    IDS_WindDirection, IDS_DaylightVisibility, IDS_Rainfall, IDS_Legends_And_Range_Of_Sensore
} from '../../VcLanguage';
import { AQI, CAQI, HUM, TEMP, NH3OD, ONWARDS, DISP_LOW_SEVERE_RANGE, DISP_SATISFACTORY_RANGE, CH4,
    DISP_GOOD_RANGE, DISP_MODERATE_RANGE, DISP_POOR_RANGE, DISP_VERY_POOR_RANGE, DISP_HIGH_SEVERE_RANGE, UPTO, O2, CL, CH3SH, NOISE, LUX, UV, AP, WS, DLV, RNFL, LPG, 
    DISP_LOW_VERY_POOR_RANGE, VRI, SMOKE, 
    CO2, VOC, H2S, MRI, CO, NO2, PM25, SO2,
    O3, NH3, NO, PM100, AWC, SLIP, RADON, WD, 
    PM1, HCHO, DEVICE_TYPE_PFC, WLIP, 
    PM10
} from '../../VcConstants';

function VcDetailsAndRanges (props) {

  const context = useContext(AppRelevantDataContext);
  let appRelevantDataContextValue = context;
  let t = appRelevantDataContextValue.t; 

  const [state, setState] = useState({
        highSevereStartValueInPopUp: '',
        goodEndValueInPopUp: '',
        modalFromIcon: false,
        backdrop: 'static',
        showModel : props.modalFromIcon,
        showImpactsOfParam: false,
        modalFromInfoButton: false,
        paramToShowDetailedInfo: "",
        backdropForInfoButton: 'static',
    });

    const {arrOfRanges, arrOfDispRangeValues, selectedParamToViewRangeDetails, nameOfParamToShowInPopup, shortNameOfParam, styleToBeAppliedToPopup,
        selectedParamsCurrentValue, modalFromIcon, backdrop, toggleModal } = props;

    const showRangeNameAndColor = (DispRange, selectedParamToViewRangeDetails) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;           

        let retRangeName = '';
        let retRangeBgColor = '';
        let retRangeFontColor = '';

        switch(DispRange) {
            case DISP_LOW_SEVERE_RANGE:
                retRangeName = t(IDS_RangeLowSevere);
                retRangeBgColor = selectedParamToViewRangeDetails == LUX ? "#606444":"#C00000";
                retRangeFontColor = selectedParamToViewRangeDetails == LUX ? "white":"lightGrey";
                break;
            case DISP_LOW_VERY_POOR_RANGE:
                retRangeName = t(IDS_RangeLowVeryPoor);
                retRangeBgColor = "red";
                retRangeFontColor = "lightGrey";
                break;
            case DISP_GOOD_RANGE:
                retRangeName = t(IDS_Good);
                retRangeBgColor = selectedParamToViewRangeDetails == LUX ? "#F89C3C":"#50C878";
                break;
                
            case DISP_SATISFACTORY_RANGE:
                retRangeName = t(IDS_Satisfactory);
                retRangeBgColor = "#9ACD32";
                retRangeFontColor = "black";
                break;
            case DISP_MODERATE_RANGE:
                retRangeName = t(IDS_Moderate);
                retRangeBgColor = "#FFD700";
                retRangeFontColor = "black";
                break;
            case DISP_HIGH_SEVERE_RANGE:
                retRangeName = (selectedParamToViewRangeDetails == TEMP || selectedParamToViewRangeDetails == HUM) ? t(IDS_RangeHighSevere) : (selectedParamToViewRangeDetails == AQI || selectedParamToViewRangeDetails == CAQI) ? t(IDS_SevereAqi) : t(IDS_Severe);
                retRangeBgColor = selectedParamToViewRangeDetails == LUX ? "#FF4500":"#C00000";
                retRangeFontColor = selectedParamToViewRangeDetails == LUX ? "white":"lightGrey";
                break;
            case DISP_POOR_RANGE:
                retRangeName = (selectedParamToViewRangeDetails == AQI || selectedParamToViewRangeDetails == CAQI) ? t(IDS_PoorAqi) : t(IDS_Poor);
                retRangeBgColor = "orange";
                retRangeFontColor = "black";
                break;
            case DISP_VERY_POOR_RANGE:
                retRangeName = (selectedParamToViewRangeDetails == AQI || selectedParamToViewRangeDetails == CAQI) ? t(IDS_VeryPoorAqi  ) : t(IDS_VeryPoor);;
                retRangeBgColor = "red";
                retRangeFontColor = "lightGrey";
                break;
            default:
                retRangeName = IDS_NoRange;
                retRangeBgColor = '';
                retRangeFontColor = "";
                break;
            }
        return {retRangeName: retRangeName, retRangeBgColor: retRangeBgColor, retRangeFontColor: retRangeFontColor};

    }

    const getImpactsBasedOnRange = (DispRange) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;           

        let retrivedImpactsBasedOnRanges = "";

        if(props.selectedParamToViewRangeDetails == TEMP){ 

            switch(DispRange.retRangeName) {
                case t(IDS_RangeLowSevere):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_TEMP_Level1);
                    break;
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_TEMP_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_TEMP_Level3);
                    break;
                case t(IDS_RangeHighSevere):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_TEMP_Level4);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 

        } else if(props.selectedParamToViewRangeDetails == HUM) {

            switch(DispRange.retRangeName) {
                case t(IDS_RangeLowSevere):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_HUM_Level1);
                    break;
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_HUM_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_HUM_Level3);
                    break;
                case t(IDS_RangeHighSevere):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_HUM_Level4);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            }
        } else if(props.selectedParamToViewRangeDetails == NH3OD) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_Odour_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_Odour_Level2);
                    break;
                case t(IDS_Satisfactory):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_Odour_Level3);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_Odour_Level4);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_Odour_Level5);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_Odour_Level6);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == VOC) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_VOC_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_VOC_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_VOC_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_VOC_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_VOC_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == AQI) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_AQI_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_AQI_Level2);
                    break;
                case t(IDS_Satisfactory):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_AQI_Level3);
                    break;
                case t(IDS_PoorAqi):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_AQI_Level4);
                    break;
                case t(IDS_VeryPoorAqi):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_AQI_Level5);
                    break;
                case t(IDS_SevereAqi):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_AQI_Level6);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == CAQI) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CAQI_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CAQI_Level2);
                    break;
                case t(IDS_Satisfactory):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CAQI_Level3);
                    break;
                case t(IDS_PoorAqi):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CAQI_Level4);
                    break;
                case t(IDS_VeryPoorAqi):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CAQI_Level5);
                    break;
                case t(IDS_SevereAqi):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CAQI_Level6);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == H2S) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_H2S_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_H2S_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_H2S_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_H2S_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_H2S_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == MRI) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_MRI_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_MRI_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_MRI_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_MRI_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_MRI_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == CO) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CO_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CO_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CO_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CO_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CO_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == CO2) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CO2_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CO2_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CO2_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CO2_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_CO2_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == NO2) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_NO2_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_NO2_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_NO2_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_NO2_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_NO2_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == PM1) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM1_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM1_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM1_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM1_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM1_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == PM10) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM10_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM10_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM10_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM10_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM10_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == PM25) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM25_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM25_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM25_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM25_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_PM25_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == SO2) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_SO2_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_SO2_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_SO2_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_SO2_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_SO2_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == VRI) {

            switch(DispRange.retRangeName) {
                case t(IDS_Good):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_VRI_Level1);
                    break;
                case t(IDS_Moderate):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_VRI_Level2);
                    break;
                case t(IDS_Poor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_VRI_Level3);
                    break;
                case t(IDS_RangeVeryPoor):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_VRI_Level4);
                    break;
                case t(IDS_Severe):
                    retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_VRI_Level5);
                    break;
                default:
                    retrivedImpactsBasedOnRanges = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == O3) {

                switch(DispRange.retRangeName) {
                    case t(IDS_Good):
                        retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_O3_Level1);
                        break;
                    case t(IDS_Moderate):
                        retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_O3_Level2);
                        break;
                    case t(IDS_Poor):
                        retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_O3_Level3);
                        break;
                    case t(IDS_RangeVeryPoor):
                        retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_O3_Level4);
                        break;
                    case t(IDS_Severe):
                        retrivedImpactsBasedOnRanges = t(IDS_HealthImpacts_O3_Level5);
                        break;
                    default:
                        retrivedImpactsBasedOnRanges = '';
                        break;
                }  
        } else {
            retrivedImpactsBasedOnRanges = "";
        }

        return retrivedImpactsBasedOnRanges;

    }

    const showIconAndLevelForOdour = (DispRange) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;           

        let retRangeIcon = '';
        let retRangeName = '';

        switch(DispRange) {
            case DISP_GOOD_RANGE:
                retRangeName = t(IDS_AlertLevel1);
                retRangeIcon = <FaGrinBeam style={{fontSize: '1.5rem', color:"#50C878"}}/>;
                break;
            case DISP_SATISFACTORY_RANGE:
                retRangeName = t(IDS_AlertLevel2);
                retRangeIcon = <FaSmile style={{fontSize: '1.5rem', color:"#9ACD32"}}/>;
                break;
            case DISP_MODERATE_RANGE:
                retRangeName = t(IDS_AlertLevel3);
                retRangeIcon = <FaMeh style={{fontSize: '1.5rem', color:"#FFD700"}}/>;
                break;
            case DISP_POOR_RANGE:
                retRangeName = t(IDS_AlertLevel4);
                retRangeIcon = <FaFrownOpen style={{fontSize: '1.5rem', color:"orange"}}/>;
                break;
            case DISP_VERY_POOR_RANGE:
                retRangeName = t(IDS_AlertLevel5);
                retRangeIcon = <FaSadTear style={{fontSize: '1.5rem', color:"red"}}/>;
                break;
            case DISP_HIGH_SEVERE_RANGE:
                retRangeName = t(IDS_AlertLevel6);
                retRangeIcon = <FaDizzy style={{fontSize: '1.5rem', color:"#C00000"}}/>;
                break;
            default:
                retRangeName = '';
                retRangeIcon = <div></div>
                break;
            }
        return {retRangeName: retRangeName, retRangeIcon: retRangeIcon};

    }

    const showRangesForSelectedParam = (DispRange, key) => {
    
        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 
        let RangeNameToShow = null;
        let currentLanguage = appRelevantDataContextValue.language.languageToViewIn;

        if(key.includes(ONWARDS)){
            modifiedState.highSevereStartValueInPopUp = key.split(" ")[0];
        }

        if(key.includes(UPTO)){
            modifiedState.goodEndValueInPopUp = key.split(" ")[1];
        }

        let selectedRangeInfo = showRangeNameAndColor(DispRange, props.selectedParamToViewRangeDetails);

        let impactsBasedOnRange = getImpactsBasedOnRange(selectedRangeInfo);

        if(props.selectedParamToViewRangeDetails == TEMP){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_ColdAndChilly);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Comfortable);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_Warm);
                    break;
                case t(IDS_RangeHighSevere):
                    RangeNameToShow = t(IDS_Hot);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 

        } else if(props.selectedParamToViewRangeDetails == HUM) {

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_Dry);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Comfortable);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_Humid);
                    break;
                case t(IDS_RangeHighSevere):
                    RangeNameToShow = t(IDS_Sticky);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            }
        } else if(props.selectedParamToViewRangeDetails == NH3OD) {

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_BarelySensedInSingleDeviceData);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_WeakOdourInSingleDeviceData);
                    break;
                case t(IDS_Satisfactory):
                    RangeNameToShow = t(IDS_SatisfactoryInSingleDeviceData);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_EasilyRecognizedInSingleDeviceData);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_StrongOdourInSingleDeviceData);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_PungentInSingleDeviceData);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == RNFL){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_LightRain);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_ModerateRain);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_HeavyRain);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_VeryHeavyRain);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_TorrentialRain);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == NOISE){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_LowNoiseLevel);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_MediumNoiseLevel);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HighNoiseLevel);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
            } 
        } else if(props.selectedParamToViewRangeDetails == LUX){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_LowSevere):
                RangeNameToShow = t(IDS_LowLightIntensity);
                break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_MediumLightIntensity);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HighLightIntensity);
                    break;
                default:
                    RangeNameToShow =  t(IDS_LowLightIntensity)
                    break;
            } 
        } 
        else if(props.selectedParamToViewRangeDetails == O2){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_Normal);
                break;
            case t(IDS_RangeLowSevere):
                RangeNameToShow = t(IDS_Low);
                break;
            case t(IDS_Severe):
                RangeNameToShow = t(IDS_High);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } else if(props.selectedParamToViewRangeDetails == DLV){ 

            switch(selectedRangeInfo.retRangeName) {
            case t(IDS_Good):
                RangeNameToShow = t(IDS_GoodVisibility);
                break;
            case t(IDS_Moderate):
                RangeNameToShow = t(IDS_ModerateVisibility);
                break;
            case t(IDS_Poor):
                RangeNameToShow = t(IDS_FairVisibility);
                break;
            case t(IDS_VeryPoor):
                RangeNameToShow = t(IDS_PoorVisibility);
                break;
            default:
                RangeNameToShow = '';
                break;
            } 
        } 
        else if(props.selectedParamToViewRangeDetails == WS){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                RangeNameToShow = t(IDS_CalmBreeze);
                break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_GentleBreeze);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_ModerateBreeze);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_StrongGale);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Storm);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(props.selectedParamToViewRangeDetails == CL || props.selectedParamToViewRangeDetails == CH3SH){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_VeryLow);
                    break;
                case t(IDS_Satisfactory):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_Moderate);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_Poor);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Severe);
                    break;
                default:
                    RangeNameToShow = t(IDS_Low);
                    break;
                } 
        } else if(props.selectedParamToViewRangeDetails == UV){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_Moderate);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_High);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_VeryHigh);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_Extreme);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(props.selectedParamToViewRangeDetails == CH4){ 

            switch(selectedRangeInfo.retRangeName) {
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_SafeRange);
                    break;
                case t(IDS_Moderate):
                    RangeNameToShow = t(IDS_WarningRange);
                    break;
                case t(IDS_Poor):
                    RangeNameToShow = t(IDS_CriticalRange);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_EmergencyRange);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_HazardousRange);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } else if(props.selectedParamToViewRangeDetails == AP){ 

            switch(selectedRangeInfo.retRangeName) {
                
                case t(IDS_RangeLowSevere):
                    RangeNameToShow = t(IDS_ExtremeLow);
                    break;
                case t(IDS_RangeLowVeryPoor):
                    RangeNameToShow = t(IDS_Low);
                    break;
                case t(IDS_Good):
                    RangeNameToShow = t(IDS_Normal);
                    break;
                case t(IDS_RangeVeryPoor):
                    RangeNameToShow = t(IDS_High);
                    break;
                case t(IDS_Severe):
                    RangeNameToShow = t(IDS_ExtremeHigh);
                    break;
                default:
                    RangeNameToShow = '';
                    break;
                } 
        } 
        
        else {
            RangeNameToShow = selectedRangeInfo.retRangeName;
        }

        let RangeBgColorToShow = selectedRangeInfo.retRangeBgColor;
        let RangeFontColorToShow = selectedRangeInfo.retRangeFontColor;

        let RangeInfoToShowForNH3OD = null;
        let RangeNameToShowForNH3OD = null;
        let RangeIconToShowForNH3OD = <div></div>;

        if(props.selectedParamToViewRangeDetails != null && props.selectedParamToViewRangeDetails == NH3OD){
            RangeInfoToShowForNH3OD = showIconAndLevelForOdour(DispRange);
            RangeNameToShowForNH3OD = RangeInfoToShowForNH3OD.retRangeName;
            RangeIconToShowForNH3OD = RangeInfoToShowForNH3OD.retRangeIcon;
        }

        return(
            <div key={key}>
                {props.showImpactsOfParam == true ?
                    <div class="container">
                        <div class="tab-pane active show" id="tasks-tab" role="tabpanel">      
                            <div class="customizedCol12">
                                { props.selectedParamToViewRangeDetails == NH3OD ?
                                    <div class="task-list-box" id="landing-task">
                                        <div id="task-item-1">
                                            <div class="card task-box rounded-3">
                                                <div class="card-body">
                                                    <div class="row align-items-center">
                                                        <div class="col-5 col-sm-6 col-md-6 col-xl-3">
                                                        {/* <div class="col-3"> */}
                                                            <div class="row align-items-center">
                                                                <div class="col-6 col-sm-6 col-md-6 col-xl-9">
                                                                {/* <div class="col-9"> */}
                                                                    <div class="checklist form-check font-size-15">
                                                                        <label  class="paramForDetailsAndRanges rangeTextforParamDoc" style={{fontWeight: "bold"}}>
                                                                            {RangeNameToShow} :
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3 d-flex justify-content-end">
                                                                    <label class="smileyForDetailsAndRanges">
                                                                        {RangeIconToShowForNH3OD}
                                                                    </label> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-sm-6 col-xl-9">
                                                        {/* <div class="col-9"> */}
                                                            <div class="row align-items-center">
                                                                <div class="col-4 col-sm-4 col-md-4 col-xl-3">
                                                                {/* <div class="col-3"> */}
                                                                    <div style={{flex: "1", textAlign: "center", height: "0%",
                                                                                color: RangeFontColorToShow, backgroundColor: RangeBgColorToShow, borderRadius: "0.5rem"}}>
                                                                        <label className="adduser-form-label rangeValueforParamDoc" style = {{marginLeft: "0rem"}} >
                                                                            {(props.selectedParamToViewRangeDetails == NH3OD && RangeInfoToShowForNH3OD != null) ? 
                                                                                RangeNameToShowForNH3OD
                                                                            :
                                                                                key.includes(ONWARDS) ?  `${modifiedState.highSevereStartValueInPopUp} ${t(IDS_Onwards)}` : 
                                                                                (key.includes(UPTO) && currentLanguage != 'en') ? `${modifiedState.goodEndValueInPopUp} ${t(IDS_Upto)}` :  key
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-8 col-sm-8 col-md-8 col-xl-9">
                                                                {/* <div class="col-9"> */}
                                                                    <div class="d-flex flex-wrap gap-3 mt-3 mt-xl-0 justify-content-md-end">
                                                                        <div style={{ textAlign: "left", height: "0%",
                                                                                        width: "100%"}}>
                                                                            <label className="adduser-form-label" style = {{fontSize: "0.9rem"}} >
                                                                                {impactsBasedOnRange}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div class="task-list-box" id="landing-task">
                                        <div id="task-item-1">
                                            <div class="card task-box rounded-3">
                                                <div class="card-body">
                                                    <div class="row align-items-center">
                                                        <div class="col-5">
                                                            <div class="row align-items-center">
                                                                <div class="col-sm-9 col-xl-6">
                                                                    <div class="checklist form-check font-size-15">
                                                                        <label  class="paramForDetailsAndRanges rangeTextforParamDoc" style={{fontWeight: "bold"}}>
                                                                            {RangeNameToShow} :
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-9 col-xl-6">
                                                                    <div style={{flex: "1", textAlign: "center", height: "0%",
                                                                                color: RangeFontColorToShow, backgroundColor: RangeBgColorToShow, borderRadius: "0.5rem"}}>
                                                                        <label className="adduser-form-label rangeValueforParamDoc" style = {{marginLeft: "0rem"}} >
                                                                            {(props.selectedParamToViewRangeDetails == NH3OD && RangeInfoToShowForNH3OD != null) ? 
                                                                                RangeNameToShowForNH3OD
                                                                            :
                                                                                key.includes(ONWARDS) ?  `${modifiedState.highSevereStartValueInPopUp} ${t(IDS_Onwards)}` : 
                                                                                (key.includes(UPTO) && currentLanguage != 'en') ? `${modifiedState.goodEndValueInPopUp} ${t(IDS_Upto)}` :  key
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-7">
                                                            <div class="d-flex flex-wrap gap-3 mt-3 mt-xl-0 justify-content-md-end">
                                                                <div style={{ textAlign: "left", height: "0%",
                                                                                width: "100%"}}>
                                                                    <label className="adduser-form-label" style = {{fontSize: "0.9rem"}} >   
                                                                        {impactsBasedOnRange}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                    <div style={{flex:"6", textAlign: "left"}}>
                        <label className="labelForSingleDeviceDataPopup" style={{fontWeight: "bold"}}>
                            {RangeNameToShow} :
                        </label>
                    </div>

                    <div style={{flex:"1", display: RangeInfoToShowForNH3OD != null ? 'block' : 'none', marginRight:"0.5rem"}}>
                        {RangeIconToShowForNH3OD}
                    </div>
                    
                    <div style={{flex:RangeInfoToShowForNH3OD != null ? "5" : "6", textAlign: "left", height: "0%",
                                 color: RangeFontColorToShow, backgroundColor: RangeBgColorToShow}}>
                        <label className="adduser-form-label" style = {{fontSize: "0.8rem"}} >
                            {(modifiedState.selectedParamToViewRangeDetails == NH3OD && RangeInfoToShowForNH3OD != null) ? 
                                RangeNameToShowForNH3OD
                            :
                                key.includes(ONWARDS) ?  `${modifiedState.highSevereStartValueInPopUp} ${t(IDS_Onwards)}` : 
                                (key.includes(UPTO) && currentLanguage != 'en') ? `${modifiedState.goodEndValueInPopUp} ${t(IDS_Upto)}` :  key
                            }
                        </label>
                    </div>
                </div>
                }
            </div>
        )
    }

    const getParamNameBasedOnType = (inParamNameType, t) => {
        switch(inParamNameType) {
            case AQI:
                return t(IDS_airQualityIndex);
              case CAQI:
                return t(IDS_currentAirQualityIndex);
            case NO2:
                return t(IDS_nitrogenDioxide);
            case O3:
                return t(IDS_Ozone);
            case SO2:
                return t(IDS_sulphurDioxide);
            case VOC:
                return t(IDS_TVOC);
            case CO:
                return t(IDS_carbonMonoxide);
            case NH3:
                return t(IDS_Ammonia);
            case CO2:
                return t(IDS_carbonDioxide);
            case H2S:
                return t(IDS_hydrogenSulphide);
            case CH4:
                return t(IDS_Methane);
            case LPG:
                return t(IDS_LPG);
            case SMOKE:
                return t(IDS_SMOKE);
            case PM1:
                return t(IDS_Dust) + " (PM1)";
            case PM25:
                return t(IDS_Dust) + " (PM2.5)";
            case PM10:
                return t(IDS_Dust) + " (PM10)";
            case PM100:
                return t(IDS_Dust) + " (PM100)";
            case TEMP:
                return t(IDS_Temperature);
            case HUM:
                return t(IDS_Humidity);
            case NH3OD:
                return t(IDS_Odour);
            case VRI:
                return t(IDS_VRI);
            case MRI:
                return t(IDS_MRI);
            case DEVICE_TYPE_PFC:
                return t(IDS_PFC);
            case WLIP:
                return t(IDS_CW);
            case SLIP:
                return t(IDS_SL); 
            case HCHO:
                return t(IDS_Formaldehyde);
            case O2:
                return t(IDS_Oxygen);
            case NO:
                return t(IDS_NitricOxide);
            case CL:
                return t(IDS_Chlorine);
            case CH3SH:
                return t(IDS_MethylMercaptan);
            case NOISE:
                return t(IDS_Noise);
            case LUX:
                return t(IDS_LightIntensity);
            case UV:
                return t(IDS_UV);
            case RADON:
                return t(IDS_Radon);
            case AP:
                return t(IDS_AirPressure);
            case WS:
                return t(IDS_WindSpeed);
            case WD:
                return t(IDS_WindDirection);
            case DLV:
                return t(IDS_DaylightVisibility);
            case RNFL:
                return t(IDS_Rainfall);                                
            default:
                console.error(`Unable to get ParamName. Unknown Param Type: ${inParamNameType}`);
                return (""); // Return empty tag
        }
    } 

    const getUnitsBasedOnParamtype = (inParamType, singleValue = null) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;          

        let retUnit = '';
        switch(inParamType) {
            case NO2:
            case O3:
            case SO2:
            case VOC:
            case H2S:
            case HCHO:
            case CL:
            case CH3SH:
                retUnit = <small>{ t(IDS_PPB) }</small>;
                break;
            case CO:
            case NH3:
            case CO2:
            case NH3OD:  
            case NO:
            case NH3:  
                retUnit = <small>{ t(IDS_PPM) }</small>;
                break;
            case PM1:
            case PM25:
            case PM10:
            case PM100:
                retUnit = <small>㎍/m<sup>3</sup></small>;
                break;
            case TEMP:
                retUnit = <small>{ t(IDS_Celsius) }</small>;
                break;
            case HUM:
                retUnit = <small>{ t(IDS_Percent) }</small>;
                break;
            case MRI:
            case VRI:
            case UV:
                retUnit = <small>{ t(IDS_Index) }</small>;
                break;
            case AWC:
                retUnit = <small>L</small>;
                break;
            case AQI:
                retUnit = singleValue ? <small>{(props.singleDeviceMeasuredParams.PPAQI) ? props.singleDeviceMeasuredParams.PPAQI : ""}</small>
                        :<small/>;
                break;
            case CAQI:
                retUnit = singleValue ? <small>{(props.singleDeviceMeasuredParams.PPCAQI) ? props.singleDeviceMeasuredParams.PPCAQI : ""}</small>
                        :<small/>;
                break;
            case SLIP:
            case O2:
            case CH4:
                retUnit = '%'
                break;
            case NOISE:
                retUnit = 'dB';
                break;
            case LUX:
                retUnit = 'lux';
                break;
            case RADON:
                retUnit = 'Bq/m³';
                break;
            case AP:
                retUnit = 'hPa';
                break;
            case WS:
                retUnit = 'm/s';
                break;
            case WD:
                retUnit = '°';
                break;
            case DLV:
                retUnit = 'km';
                break;
            case RNFL:
                retUnit = 'mm';
                break;
            default:
                retUnit = '';
                break;
        }
        
        return retUnit;
    }

     // Called when Info Button is clicked.
     const toggleFromInfoButton = (shortNameOfParam) => {
        let modifiedState = state;
        modifiedState.modalFromInfoButton = !state.modalFromInfoButton;    
        modifiedState.paramToShowDetailedInfo = shortNameOfParam;                          
        
        setState({...modifiedState});
    }


    const closeModal = () => {
        
        let modifiedState = state;
        modifiedState.showModel = !modifiedState.showModel;
        props.toggleModal();
        setState({...modifiedState});

    }
  
    return (
        <>
            <div hidden = {props.invokedFrom == "paramDoc"} className = {props.invokedFrom == "charts" || props.invokedFrom == "toiletDashboard" ? "modal-overlay" : ""}  style={{position: "fixed"}}>
                <Modal size = {props.showImpactsOfParam == true ? "xl" : "l"} isOpen={state.showModel} toggle={closeModal} backdrop={props.backdrop}>
                <ModalHeader toggle={closeModal} >{props.invokedFrom == "charts" ? t(IDS_Legends_And_Range_Of_Sensore) : t(IDS_DetailsAndRangesOfSensor)}</ModalHeader>
                    <ModalBody>
                    {props.showImpactsOfParam == true ?
                        <div>
                            <div class="row justify-content-between" >
                                <div class="col-xl-6" style = {{marginLeft: "1rem"}}>
                                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                                        <div style={{display: "inline-block"}}>
                                        {props.invokedFrom == "charts" ?
                                                <label style={{fontWeight: "bold", fontSize: "1.2rem", marginRight: "0.5rem", marginBottom: "0rem"}}> 
                                                    {getParamNameBasedOnType(props.selectedParamType, t)}{t(IDS_ParamsRange)} {props.unitOfSelectedParamInRangesPopup}
                                                </label>
                                                :
                                                props.invokedFrom == "toiletDashboard" ?
                                                <label style={{fontWeight: "bold", fontSize: "1.2rem", marginRight: "0.5rem", marginBottom: "0rem"}}> 
                                                    {props.nameOfParamToShowInPopup}{t(IDS_ParamsRange)} ({props.shortNameOfParam})
                                                </label>
                                                :
                                                <label style={{fontWeight: "bold", fontSize: "1.2rem", marginRight: "0.5rem", marginBottom: "0rem"}}>
                                                    {props.nameOfParamToShowInPopup}{t(IDS_ParamsRange)} ({props.shortNameOfParam})
                                                </label>
                                            }
                                        </div>
                                        <div hidden={props.invokedFrom == "charts" || props.invokedFrom == "toiletDashboard"} style={{marginLeft: "1rem", marginBottom: "0.1rem", display: (props.shortNameOfParam == "Virus" || props.shortNameOfParam == "Mould") ? "inline-block" : "none"}}>
                                            <span id="toggleSpan" className="tooltipForRangeIcon2" >
                                                <span id="toggleSpan" className="tooltipForRangeIcon2" onClick={ () => toggleFromInfoButton(props.shortNameOfParam)}>
                                                    <FiInfo className="rangeIcon" style={{fontSize: "1.3rem", fontWeight: "bold"}}/>
                                                    <span className="tooltiptextForRangeIcon">
                                                        {props.shortNameOfParam == "Virus" ? t(IDS_WhatIsVirusRiskIndex) : props.shortNameOfParam == "Mould" ? t(IDS_WhatIsMouldRiskIndex) : ""}
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 d-flex justify-content-end">
                                    <div hidden={props.invokedFrom == "charts" || props.invokedFrom == "toiletDashboard"} className = {`${props.styleToBeAppliedToPopup} card me-4`} style = {{width: "auto", fontSize: "2rem", padding: "0.3rem"}}>
                                        <label className="adduser-form-label" style={{fontWeight: "bold", color: "black", marginLeft: "0rem"}}>
                                            {props.nameOfParamToShowInPopup}'s {t(IDS_Value)}:  {props.selectedParamsCurrentValue}&nbsp;   
                                            {props.shortNameOfParam != AQI &&
                                                ((props.selectedParamToViewRangeDetails != null && 
                                                    props.selectedParamToViewRangeDetails.length > 0) ? 
                                                    "":"")
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>  
                            <div>
                                {props.arrOfRanges.map((DispRange, index) => {
                                        return(
                                            showRangesForSelectedParam(DispRange, props.arrOfDispRangeValues[index])
                                        );                            
                                    })
                                }  
                            </div>
                        </div>
                        :
                        <div>
                            <div hidden={props.invokedFrom == "charts" || props.invokedFrom == "toiletDashboard"} className={props.styleToBeAppliedToPopup}>
                                <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                    <div style={{flex:"1", textAlign: "center"}}>
                                        <label className="adduser-form-label" style={{fontWeight: "bold", fontSize: "1.2rem", color: "#606060"}}>{t(IDS_ParameterValue)}</label>
                                    </div>
                                </div>
                                <div style={{display: "flex", paddingBottom: "0.1rem"}} >
                                    <div style={{flex:"1", textAlign: "left", marginBottom: "0.8rem"}}>
                                        <label className="adduser-form-label" style={{fontWeight: "bold", color: "black"}}>
                                            {props.nameOfParamToShowInPopup} :
                                        </label>
                                    </div>
                                    <div style={{flex:"1", textAlign: "left", backgroundColor: ""}}>
                                        <label className="adduser-form-label">
                                            {props.selectedParamsCurrentValue}&nbsp;   
                                            {props.shortNameOfParam != AQI &&
                                                ((props.selectedParamToViewRangeDetails != null && 
                                                props.selectedParamToViewRangeDetails.length > 0) ? 
                                                getUnitsBasedOnParamtype(props.selectedParamToViewRangeDetails) : "")
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="boxForRangesForSensors">
                                <div style={{display: "flex", paddingBottom: "0.1rem"}}>
                                    <div style={{flex:"1", textAlign: "center", marginBottom: "0.8rem"}}>
                                        <div style={{display: "inline-block"}}>
                                            {props.invokedFrom == "charts" ?
                                                <label className="adduser-form-label" style={{fontWeight: "bold", fontSize: "1.2rem"}}>
                                                    {getParamNameBasedOnType(props.selectedParamType, t)}{t(IDS_ParamsRange)} {props.unitOfSelectedParamInRangesPopup}
                                                </label>
                                                :
                                                props.invokedFrom == "toiletDashboard" ?
                                                <label style={{fontWeight: "bold", fontSize: "1.2rem", marginRight: "0.5rem", marginBottom: "0rem"}}> 
                                                    {props.nameOfParamToShowInPopup}{t(IDS_ParamsRange)} ({props.shortNameOfParam})
                                                </label>
                                                :
                                                <label className="adduser-form-label" style={{fontWeight: "bold", fontSize: "1.2rem"}}>
                                                    {props.nameOfParamToShowInPopup}{t(IDS_ParamsRange)} ({props.shortNameOfParam})
                                                </label>
                                            }
                                        </div>
                                        <div hidden={props.invokedFrom == "charts" || props.invokedFrom == "toiletDashboard"} style={{marginLeft: "1rem", display: (props.shortNameOfParam == "Virus" || props.shortNameOfParam == "Mould") ? "inline-block" : "none"}}>
                                            <span id="toggleSpan" className="tooltipForRangeIcon2" onClick={ () => toggleFromInfoButton(props.shortNameOfParam)}>
                                                <FiInfo className="rangeIcon" style={{fontSize: "1.3rem", fontWeight: "bold"}}/>
                                                <span className="tooltiptextForRangeIcon">
                                                    {props.shortNameOfParam == "Virus" ? t(IDS_WhatIsVirusRiskIndex) : shortNameOfParam == "Mould" ? t(IDS_WhatIsMouldRiskIndex) : ""}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {props.arrOfRanges.map( (DispRange, index) => {
                                        return(
                                            showRangesForSelectedParam(DispRange, props.arrOfDispRangeValues[index])
                                        );                            
                                    })
                                    }  
                                </div>
                            </div>
                        </div>
                    }
                    </ModalBody>
                </Modal>

                <Modal size="xl" isOpen={state.modalFromInfoButton} toggle={toggleFromInfoButton} backdrop={state.backdropForInfoButton}>
                    <ModalHeader toggle={toggleFromInfoButton}>
                        {state.paramToShowDetailedInfo == "Virus" ? t(IDS_WhatIsVirusRiskIndex) : state.paramToShowDetailedInfo == "Mould" ? t(IDS_WhatIsMouldRiskIndex) : ""}
                    </ModalHeader>
                    <ModalBody>
                        <VcVRIandMRIInfo 
                            paramToShowDetailedInfo={state.paramToShowDetailedInfo}
                        />
                    </ModalBody>
                </Modal>
            </div>

            {/* // this div is only called from parameter documentation page */}
            <div hidden = {props.invokedFrom == "charts" || props.invokedFrom == "toiletDashboard" || props.invokedFrom == "singleDeviceData"}>
                {props.arrOfRanges.map( (DispRange, index) => {
                    return(
                        showRangesForSelectedParam(DispRange, props.arrOfDispRangeValues[index])
                    );                            
                })
                }  
            </div>
        </> 
    )
}

export default VcDetailsAndRanges;
