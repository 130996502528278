import React, { Component } from 'react'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext} from '../../AppContext';
// import {QrReader} from '@otterscan/react-qr-reader'
import {QrScanner} from '@yudiel/react-qr-scanner';
import { FaQrcode } from 'react-icons/fa'
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import { ITM_STATE_UNDER_REPAIR } from '../../VcConstants';

export class VcChangeModel extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            errors: {
                others:"",
                deviceExistenceErr: "",
            },
            itemID: "",
            devcIDQRCodeFlag: false,
            showProceedBtn: false,
            arrDefModelInfo: [],
            arrExcludedAssignedModelInfo: [],
            changedModelCodeOfDevc: "",
            currModelNameOfDevc: "",
            currModelCodeOfDevc: "",
            comment: "",
            arrMeasuredParamNotInCurrentModel: [],
            arrIncludedCalibParamsNotInCurrentModel: [],
            nextStateOfItemId: "",
            arrPossibleNextState: [],
            arrDefPossibleNextState: []
        }
    }

    onChangeItemID = (e) => {
        let modifiedState = this.state;
        modifiedState.itemID = e.target.value;
        modifiedState.devcIDQRCodeFlag = false;
        modifiedState.showProceedBtn = false;
        modifiedState.comment = "";
        modifiedState.nextStateOfItemId = "";
        modifiedState.changedModelCodeOfDevc = "";
        modifiedState.errors = {
            others:"",
            deviceExistenceErr: "",
        }

        this.setState(modifiedState);
    }

    handleKeyDown = (e) => {
       
        if (e.key == 'Enter') {
            // This is just to prevent form submission on pressing "enter".
            e.preventDefault();
            return;
        }
    }

    openQRCameraForPrdItmStateDevcID  = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 

        this.setState(prevState => {
            let modifiedState = prevState;

            modifiedState.devcIDQRCodeFlag = !modifiedState.devcIDQRCodeFlag;
            // Explicitly Make all state value to blank in order to avoid wrong values.
            modifiedState.showProceedBtn = false;
            modifiedState.itemID = "";
            modifiedState.errors = {
                others:"",
                deviceExistenceErr: "",
            }
            return modifiedState
        })
    }

    handleQRCodeError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    handleScanResultOfPrdItmStateDevcID = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.itemID = data;
            if((modifiedState.itemID != null && modifiedState.itemID.length > 0)) {
                modifiedState.devcIDQRCodeFlag = false;
            } else {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
            }
            this.setState(modifiedState);
        }
    }

    // User is allowed to change the model of the Device
    // if the entered Device is valid 
    // and the Device is not in "MappedToOwner" and "MappedAsReplacement" state
    verifyAndBringModelInfoForEnteredDeviceID = () => {
        let modifiedState = this.state;
        if(modifiedState.itemID == null || modifiedState.itemID.length <=0 ) {
            modifiedState.deviceExistenceErr = 'Please enter itemID.';
            this.setState(modifiedState);
            return;
        }

        let trimSpacesOfItemID = trimStringAndRemoveTrailingComma(modifiedState.itemID);

        let jsonParams = {
            DeviceID: trimSpacesOfItemID,
        }

        axios.post(`${getAPIHostURL()}/wclient/verifyAndBringModelInfoForEnteredDeviceID`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data.retrievedDevcInfo == null || response.data.retrievedDevcInfo.length <=0) {
                    modifiedState.showProceedBtn = false;
                    modifiedState.errors.deviceExistenceErr = `Cannot change the Model of the Entered DeviceID. Either Device ID is Invalid or the current state of the Device is Invalid.`;
                } else {
                    modifiedState.errors.deviceExistenceErr = "";
                    modifiedState.showProceedBtn = true;
                    modifiedState.arrDefModelInfo = response.data.retrievedModelInfo;
                    modifiedState.receivedDeviceInfo = response.data.retrievedDevcInfo;
                    modifiedState.arrExcludedAssignedModelInfo = modifiedState.arrDefModelInfo.filter((singleModelInfo) => singleModelInfo.ModelCode != modifiedState.receivedDeviceInfo[0]["ModelCode"]);
                    modifiedState.currModelNameOfDevc = modifiedState.receivedDeviceInfo[0]["ModelName"];
                    modifiedState.currModelCodeOfDevc = modifiedState.receivedDeviceInfo[0]["ModelCode"];
                    modifiedState.arrPossibleNextState = response.data.PossibleNextState;
                    modifiedState.arrDefPossibleNextState = response.data.PossibleNextState

                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.deviceExistenceErr = 'Network issues.\nCheck your Internet and Try again later.';
                // modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
            }
        }); 

    }

    // If the changed model has extra calibrated param which where not present in the current model of the Device
    // then next state of the device should be "Under Repair".
    // If the changed model has similar parameters compared to current model of the Device
    // then next state of the device can be "Under Repair" or the current state of the Device.
    // "NA" repesents state of the Device will be remain same.
    onChangeModel = (e) => {
        let modifiedState = this.state;
        modifiedState.arrMeasuredParamNotInCurrentModel = [];
        modifiedState.arrIncludedCalibParamsNotInCurrentModel = [];

        modifiedState.changedModelCodeOfDevc = e.target.value;
        modifiedState.nextStateOfItemId = "";

        let filteredCurrentModelInfoOfDevc = modifiedState.arrDefModelInfo.filter((singleModelInfo) => singleModelInfo.ModelName == modifiedState.currModelNameOfDevc);
        let filteredChangedModelInfoOfDevc = modifiedState.arrDefModelInfo.filter((singleModelInfo) => singleModelInfo.ModelCode == modifiedState.changedModelCodeOfDevc);
        if(!modifiedState.arrPossibleNextState.includes('NA')) {
            modifiedState.arrPossibleNextState.splice(0, 0, 'NA')
        }

        try {
            if(filteredCurrentModelInfoOfDevc != null && filteredChangedModelInfoOfDevc != null) {
                let measureParamsForCurrentModelOfDevc =  JSON.parse(filteredCurrentModelInfoOfDevc[0]["MeasuredParams"]);
                let measureParamsForChangedModelOfDevc = JSON.parse(filteredChangedModelInfoOfDevc[0]["MeasuredParams"]);

                let seqOfMeasuredParamForCurrentModelOfDevc = measureParamsForCurrentModelOfDevc["Seq"];
                let seqOfMeasuredParamForChangedModelOfDevc = measureParamsForChangedModelOfDevc["Seq"];

                let arrCalibParamForChangedModelOfDevc = [];
                for (let key in measureParamsForChangedModelOfDevc) {
                    if(key != null && key != "Seq" && measureParamsForChangedModelOfDevc[key].hasOwnProperty("NoCalib") == false) {
                        arrCalibParamForChangedModelOfDevc.push(key);
                    }
                }

                modifiedState.arrMeasuredParamNotInCurrentModel = seqOfMeasuredParamForChangedModelOfDevc.filter((singleMeasuredParam) => !seqOfMeasuredParamForCurrentModelOfDevc.includes(singleMeasuredParam));
                
                if(arrCalibParamForChangedModelOfDevc != null && arrCalibParamForChangedModelOfDevc.length > 0) {
                    modifiedState.arrIncludedCalibParamsNotInCurrentModel = modifiedState.arrMeasuredParamNotInCurrentModel.filter((singleMeasuredParam) => arrCalibParamForChangedModelOfDevc.includes(singleMeasuredParam));
                }

                if(modifiedState.arrIncludedCalibParamsNotInCurrentModel.length > 0) {
                    modifiedState.nextStateOfItemId = ITM_STATE_UNDER_REPAIR;
                    modifiedState.arrPossibleNextState.shift();
                }
            }

        } catch(e) {
            console.log(e);
            console.log("Should not happen.")
        }
        this.setState(modifiedState);
    }

    onChangeComment = (e) => {
        let modifiedState = this.state;
        modifiedState.comment = e.target.value;
        modifiedState.errors.others = "";
        this.setState(modifiedState);
    }


    onChangeState = (e) => {
        let modifiedState = this.state;
        modifiedState.nextStateOfItemId = e.target.value;
        this.setState(modifiedState);
    }

    // While changing the model of the device
    // Ensure that we are removing the already present alert setting for that device
    // Also, mainitain the History of the device in History table with reason "changeModel"
    // Set the state of the Device depending upon a parameters of the selected model.
    onFormSubmit = (e) => {
        e.preventDefault();
        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;
        let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let trimSpacesOfItemID = trimStringAndRemoveTrailingComma(modifiedState.itemID);

        let checkcommentLength = trimStringAndRemoveTrailingComma(modifiedState.comment);

        if(checkcommentLength.length == "" ) {
            modifiedState.errors.others = "Please Enter Comment."
            this.setState(modifiedState);
            return;
        }

        let jsonParams = {
            DeviceID: trimSpacesOfItemID,
            NextState: modifiedState.nextStateOfItemId,
            ChangedModelCode: modifiedState.changedModelCodeOfDevc,
            CurrentModelCode: modifiedState.currModelCodeOfDevc,
            IsCalibParamForChangedModel: modifiedState.arrIncludedCalibParamsNotInCurrentModel != null && modifiedState.arrIncludedCalibParamsNotInCurrentModel.length > 0 ? true : false,
            Comment: trimStringAndRemoveTrailingComma(modifiedState.comment),
            LoggedInUserID: LoggedInUserID
        }

        axios.post(`${getAPIHostURL()}/wclient/changeModelOfDevice`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                alert(`Sucessfully Changed the Model.`);
                
                modifiedState.errors.deviceExistenceErr = "";
                modifiedState.showProceedBtn = false;
                modifiedState.arrDefModelInfo = [];
                modifiedState.receivedDeviceInfo = "";
                modifiedState.arrExcludedAssignedModelInfo = [];
                modifiedState.currModelNameOfDevc = "";
                modifiedState.errors.others = "";
                modifiedState.errors.deviceExistenceErr = "";
                modifiedState.itemID = "";
                modifiedState.changedModelCodeOfDevc = "";
                modifiedState.currModelCodeOfDevc = "";
                modifiedState.comment = "";
                modifiedState.arrMeasuredParamNotInCurrentModel = [];
                modifiedState.arrIncludedCalibParamsNotInCurrentModel = [];
                modifiedState.nextStateOfItemId = "";
                modifiedState.arrPossibleNextState = modifiedState.arrDefPossibleNextState;


            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'ERR_INVLD_DEVC_OR_CURR_ST_INVLD') {
                    modifiedState.errors.others = 'Cannot change the Model of the Entered DeviceID. Either Device ID is Invalid or the current state of the Device is Invalid.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';                    
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 

    }

    decideNoteAccordingToPageInvokedFrom = (e) => {

        return(
            <div style={{border: "1px solid #a8cca8", textAlign:"left", borderRadius:"0.5rem", marginBottom:"1rem", paddingTop:"0.5rem"}} >
                {/* <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem"}}>Why this Action might be needed ?</div>
                <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                    A Device is suitable for Shipment in the following scenarios: 
                    <br/>- The Device is Functional Tested and has Passed. 
                    <br/>- Sometimes a Device is brought back for Replenishment within a few days. In that case, it can be directly used for another customer without making any changes to the Device.
                    <br/><br/>For the above scenarios, the device can be packed in a Box and can be shipped as per requirement.
                    Here, the 'Functional Tested'/'Device Taken Back For Replenishment' Device can be marked as 'Ready for Shipment' using this UI.
                </div> */}
                <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                    Who can Perform this Action ?
                </div>
                <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem"}}>
                    A CRM User having 'ProductionWorker' or 'ProductionSupervisor' Privilege.
                </div>
                <div style={{fontSize:"0.8rem", fontWeight:"bold", marginLeft:"0.5rem", marginTop:"0.8rem"}}>
                    What should be the Current State of the Device ?
                </div>
                <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                    The Device should be in 'Manufactured and Hardware Tested', 'Functional Tested', 'Ready For Shipment' State.
                </div>
            </div>
        )
    }

    render() {
        const color = {
            color : "var(--errorColor)",
            fontSize:"13px",
            marginLeft: "2rem",
            paddingBottom:"1rem"
        }

        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-lg-offset-2
                                              col-md-12">
                        <div className="modal-body p-4 box">
                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1rem"}}>
                                Change Model
                            </div>
                            {this.decideNoteAccordingToPageInvokedFrom()}
                            <form onSubmit={this.onFormSubmit}>
                                <div>
                                    <div>
                                        <label className="reg-form-label" 
                                            style={{width:"100%"}}
                                        >
                                            Scan QR Code for Serial No / Device ID of the Product Item or Enter the same:
                                        </label>
                                        <input style={{width:"100%"}}
                                            className="input-form"
                                            value= {this.state.itemID}
                                            onChange = {this.onChangeItemID}
                                            onKeyDown={this.handleKeyDown}
                                            required
                                        />
                                        <label onClick= {this.openQRCameraForPrdItmStateDevcID} className = "qrcodeTextBtn">
                                            <FaQrcode className = "qrcodeTextBtnIcon"/>
                                        </label>
                                        <div>
                                            {(this.state.devcIDQRCodeFlag == true)
                                            ?
                                                // <div style={{display: "flex", justifyContent: "center"}}>
                                                //     <QrReader
                                                //         scanDelay={300}
                                                //         onResult={(result, error) => {
                                                //             if (!!result) {
                                                //                 this.handleScanResultOfPrdItmStateDevcID(result?.text);
                                                //             }
                                                //         }}
                                                //         className = "QRCodeCamBoxForModalAndContainer"
                                                //     />
                                                // </div>
                                                <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                        marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                        marginRight: "auto"}}>                                                    
                                                    <QrScanner
                                                        scanDelay={300}
                                                        onResult={(result, error) => {
                                                            if (!!result) {
                                                                this.handleScanResultOfPrdItmStateDevcID(result?.text);
                                                            }
                                                        }}
                                                        className = "QRCodeCamBoxForModalAndContainer"
                                                    />
                                                </div>
                                            :
                                                <div/>
                                            }
                                        </div>

                                        {this.state.showProceedBtn == false &&
                                            <div style={{display:"flex", justifyContent: "center", flexDirection:"column"}}>
                                                <button type= {"button"}
                                                    style={{borderRadius: "5px", 
                                                        width:'40%', 
                                                        marginLeft:'30%',
                                                        marginTop:"0.5rem",
                                                        backgroundColor: "var(--primaryColor)", color:"white",
                                                        fontSize: "1.2rem", padding: "0.4rem"}}
                                                    className="btn-lg"
                                                    onClick={this.verifyAndBringModelInfoForEnteredDeviceID} 
                                                >
                                                    Proceed
                                                </button>
                                                {this.state.errors.deviceExistenceErr.length > 0 && 
                                                    <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.deviceExistenceErr}</p>}  
                                            </div>
                                        }  
                                    </div>

                                    {this.state.showProceedBtn == true &&
                                        <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                            <div>
                                                <label className="reg-form-label" 
                                                        style={{width:"100%"}}
                                                >
                                                    Current Model Name:
                                                </label>
                                                <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", background:"#F5F5F5"}}
                                                    className="input-form"
                                                    value= {this.state.currModelNameOfDevc}
                                                    readOnly
                                                />
                                            </div>
                                            <div>
                                                <label className="reg-form-label" 
                                                        style={{width:"100%"}}
                                                >
                                                    Select Model:
                                                </label>
                                                <select className="input-form" 
                                                        style={{width:"100%", background:"white"}} 
                                                        required
                                                        value={this.state.changedModelCodeOfDevc}
                                                        onChange={this.onChangeModel}

                                                >
                                                    <option value="" select= "true" disabled>Select Model</option> 
                                                    {(this.state.arrExcludedAssignedModelInfo).map((singleProductCategory, index) => <option key={index} value={singleProductCategory.ModelCode}>{singleProductCategory.ModelName}</option>)}

                                                </select>
                                            </div>
                                            <div>
                                                <label className="reg-form-label" 
                                                        style={{width:"100%"}}
                                                >
                                                    Reason to Change:
                                                </label>
                                                <textarea className="input-form" 
                                                        style={{width:"100%"}}
                                                        value={this.state.comment}
                                                        onChange={this.onChangeComment}
                                                        required
                                                />                                                    
                                            </div>
                                            <div>
                                                <label className="reg-form-label" 
                                                        style={{width:"100%"}}
                                                >
                                                    Next state of the Product Item:
                                                </label>
                                                <select className="input-form" 
                                                        style={{width:"100%", background:"white", color: "black"}} 
                                                        required
                                                        value={this.state.nextStateOfItemId}
                                                        onChange={this.onChangeState}
                                                        // disabled

                                                >
                                                    <option value="" select= "true" disabled>Select Next State</option> 
                                                    {(this.state.arrPossibleNextState).map((singleState) => <option key={singleState} value={singleState}>{singleState}</option>)}

                                                </select>
                                            </div>
                                            <div style={{fontSize:"0.8rem", marginLeft:"0.5rem", marginRight:"0.5rem", marginBottom:"0.5rem"}}>
                                                <b>Note: </b> If you select the next state of the device 'NA', the device will remain in the current state only.
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* {this.state.markProductState == true && */}
                                {this.state.showProceedBtn == true &&
                                    <div>
                                        <button type= {"submit"}
                                                style={{borderRadius: "5px", 
                                                        width:'40%', 
                                                        marginTop:"0.5rem",
                                                        backgroundColor: "var(--primaryColor)", color:"white"}}
                                                className="btn-lg"
                                        >
                                            Save
                                        </button>                                       
                                    </div>
                                }
                                {this.state.errors.others.length > 0 && 
                                        <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p> }       
                            </form>
                        </div>
                    </div>
                </div>
            </div>       
        )
    }
}

VcChangeModel.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcChangeModel;
