import React from 'react';
import { AppRelevantDataContext } from '../../AppContext';
import '../CSS/Navbar.css';
import { FaSignOutAlt, FaLanguage, FaTimes, FaUser, FaAddressBook, FaQuestionCircle, FaUserSecret, FaArrowAltCircleLeft} from 'react-icons/fa';
import {Navigate} from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, Button, NavbarBrand} from 'reactstrap';
import { ButtonToolbar, OverlayTrigger, Popover } from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import App from '../../App'; 
import { IDS_LogoutConfirmation, IDS_Dashboard, IDS_Settings, IDS_Charts, IDS_Alerts, IDS_Support, IDS_About, IDS_RegistPhoneError, IDS_PhoneNumberErrMsg,
      IDS_FAQs, IDS_Logout, IDS_Name, IDS_EmailID, IDS_PhoneNo, IDS_NumberOfOwnDevice, IDS_NumberOfVisbleDevices, IDS_AUSrvrIssueReqParamsNotSent, IDS_CrmMenu, IDS_DeviceMenu,
      IDS_UserProfile, IDS_UserProfileError, IDS_LoginServerIssue, IDS_RegistNetworkError,  APPLANG_EN, APPLANG_MR, APPLANG_HI, IDS_TrackToilet, IDS_Close,
      IDS_ChangeUserPassword, IDS_EditUserProfile, IDS_NewAndOldPassNotMatched, IDS_NewPassCannotSameAsOldPass, IDS_SuccessChnagedUsrPassword, IDS_SuccessChangedUsrPasswordReLogin,
      IDS_OldPassIncorrect, IDS_ChangePassword, IDS_Edit, IDS_OldPass, IDS_NewPass, IDS_CnfrmPass, IDS_FirstName, IDS_LastName, IDS_Save, IDS_PhoneError, IDS_EnterFirstName,
      IDS_EnterLastName, IDS_SuccessUpdatedUsrProf, IDS_OldPassError, IDS_NewPassError, IDS_CnfrmPassErrro, IDS_OldPassLengthError, IDS_NewPassLengthError, IDS_ConfrimPassLengthError,
      IDS_ShowPassword} from '../../VcLanguage';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { PVG_DEVC_OWNER, PVG_FWMR_ADMIN, PVG_ADMIN, PVG_GENERAL_USER, PVG_DEVC_VIEWER, PVG_SALES_EXECUTIVE, PVG_SALES_SUPERVISOR, 
         PVG_PRODUCTION_SUPERVISOR, PVG_PRODUCTION_WORKER, PVG_BACK_OFFICE_EXECUTIVE, PVG_PRODUCT_OWNER, 
         PVG_FIELD_ENGINEER, PVG_INST_PRIMARY_OWNER, PVG_INST_ADDITIONAL_VIEWER, PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING, 
         PVG_DEVC_VIEWER_WITH_SETTING, PVG_TOILET_ADMIN, PVG_SW_DEV_ADMIN, PVG_TREE_DEFINITION_ADMIN, PVG_TREE_NODE_VIEWER, CLIENT_TYPE, PVG_EXTERNAL_API_USER} from '../../VcConstants';
import { TiArrowBack } from 'react-icons/ti';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import vilisoLogoTransparent from "../IMAGES/vilisoLogoTransparent.png";
import VcSideNav from './DeviceHome/VcSideNav';
import { MdClose } from 'react-icons/md';
import { ImMenu3 } from "react-icons/im";
import { RiMenuUnfoldLine } from "react-icons/ri";

 class VcNavBar extends React.Component { 
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {  
      // LoggedInUserID: this.props.LoggedInUserID,
      // selectedTreeNodeID: this.props.LoggedInUserID, // Initially Root Node will be selected
      // isRoot: this.props.isRoot, // Initially AllDeviceData will be rendered
      // UserFirstName: this.props.UserFirstName,
      // UserLastName: this.props.UserLastName,
      redirect: false,   
      isOpen: false,
      ReportDropDownOpen: false,
      treeDefNavActive: false,
      calibAppLinks: false,
      ActionDropDownOpen: false,
      DeviceActionDropDownOpen: false,
      ProductActionDropDownOpen: false,
      TrackDropDownOpen: false,
      OrderDropDownOpen: false,
      showEditForm: false,
      RawDataParametersActionDropDownOpen: false,
      calibAppDropDownOpen: false,
      treeDefDropDownOpen: false,
      toggleCrmSmartHmmApp: false,
      smartHHMAppDropDownOpen: false,
      MoreDropDownOpen: false,
      ComplaintManagementDropDownOpen: false,
      ProductPartActionDropDownOpen: false,
      showEditProfileFields: "",
      userID: "",
      ownDeviceCount: "",
      visibleDeviceCount: "",
      userPhoneNo: "",
      firstName: "",
      lastName: "",
      CountryInfo: [],
      CountryCode: "",
      oldPassword: "",
      newPassword: "",
      cnfrmNewPassword: "",
      passwordShown: false,
      errors: { 
        others:'',
      },
      EncryptedPvg:"",
      PrivilegeEncKey:"",
      objPrivilege:{},
      // objLanguageToViewIn: ''
      languageToViewIn:'',
      showRootClose: true,
      deviceMenuPvg: false,
      crmMenuPvg: false,
      arrOfAllSessionIDs: '',
      showDrawer: false,
    };
  }
  
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  // onLogOut = () => {
  //   let appRelevantDataContextValue = this.context;
  //   let t = appRelevantDataContextValue.t; 
  //   if(window.confirm(t(IDS_LogoutConfirmation))) {
  //     // User wants to Log Out

  //     // Clear the AppRelevantDataContext that has been stored in the LocalStorage,
  //     // (Except UserID and Language Selection and a few other default values).
  //     App.clearAppRelevantDataContextFromLocalStorage();

  //     // Trigger Logout
  //     this.setState({
  //       redirect: true
  //     });
  //   }
  // }

  // To send notifications, we need FCM (Firebase Cloud Messaging) registration Id of particular user
  // Depending on the registration id, the notifications will be sent to the users whereever they are having active sessions.
  // The function below will save the FCM registration id of user into the database.
  // We call this function in following cases: Login, Logout, Language change and Token Refresh
  saveDeviceRegIdIntoDB = async (userEmailId, currToken, newToken, action, sessionID, inModifiedState = null) => {

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = this.state;
    } else {
      modifiedState = inModifiedState;
    }

    let sessID = "";

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t; 

    sessID = sessionID != null ? sessionID : null;
    let languageToViewIn = appRelevantDataContextValue.language.languageToViewIn

    var requestObject = {
      "UserID": userEmailId,
      "CurrentToken": currToken,
      "NewToken": newToken,
      "UserClientLanguage": languageToViewIn,
      "UserClientMode": "WEB",
      "Action": action,
      "SessionID": sessID
    };

    return await axios.post( `${getAPIHostURL()}/wclient/UpdateDeviceNotificationRegID`, requestObject )
    .then(response => {
      if(response.data.code == 'SUCCESS') {
        // console.log("Successfully Updated Notification ID");
      } else {
        if(response.data.code == 'REQ_PARAMS_MISSING'){
          modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);

        } else if (response.data.code == 'SQL_ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          console.log("Should not reach here.")
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
      }
      return [response.data.code, modifiedState];
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError)
      this.setState(modifiedState);
      return;
    }); 
  }

  onLogOut = (invokeFrom, inModifiedState = null) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t; 

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = this.state;
    } else {
      modifiedState = inModifiedState;
    }

    let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    let sessEncChallan = appRelevantDataContextValue.sessJankari.sessChallan;
    let sessChaabi = appRelevantDataContextValue.sessJankari.sessChaabi;
    let sessID = appRelevantDataContextValue.sessJankari.sessID;
    let fcmRegID = appRelevantDataContextValue.fcmRegDetails.fcmRegID;

    if((invokeFrom == "LogoutButton" && window.confirm(t(IDS_LogoutConfirmation))) || (invokeFrom == "ChangePassword")) {
      // User wants to Log Out

      this.saveDeviceRegIdIntoDB(LoggedInUserID, fcmRegID, "null", 'Logout', sessID, modifiedState).then((response) => {

        let responseCode = response[0];
        let updatedState = response[1];

        if(responseCode == "SUCCESS") {

          this.endDateSesJankari(updatedState);          

        } else {
          this.setState(updatedState);
        }

      })
      .catch((error) => {
        console.log("Network error:");
        console.log(error);
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError)
        this.setState(modifiedState);
      });
      // Here, On Log out End date the current session of Logged in user.
      
    }
  }

  endDateSesJankari = (inModifiedState = null) => {

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = this.state;
    } else {
      modifiedState = inModifiedState;
    }

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t; 

    let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    let sessID = appRelevantDataContextValue.sessJankari.sessID;

    let jsonParams = {
      LoggedInUserID: LoggedInUserID,
      // strChalan: strChalan,
      sessID: sessID
    }
    
    axios.post(`${getAPIHostURL()}/wclient/endDateSesJankari`, jsonParams)
    .then(response =>{

        if(response.data.code == 'SUCCESS') {

          // In VcLoginPg component on useEffect we added check if sesstion ID present in context than don't login page, 
          // redirect to default home page as per privilage and only if sesstion ID not present in context than only show login page form.
          // Bellow clearAppRelevantDataContextFromLocalStorage function was taking time to clear and set default empty context.
          // hence page redirect to login component before context get clean and its throws authorization failed message after logout.
          // so we explicitly clean the "onChangeSessJankari" context so after logout we can show login page.
          appRelevantDataContextValue.onChangeSessJankari("", "", "");

          // User wants to Log Out
          // Clear the AppRelevantDataContext that has been stored in the LocalStorage,
          // (Except UserID and Language Selection and a few other default values).
          App.clearAppRelevantDataContextFromLocalStorage();

          modifiedState.redirect = true;
          
          this.setState(modifiedState);

        } else {
          // this.props.history.replace('/');
          if(response.data.code == 'SQL_ERROR') {
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
              console.log("Should not reach here");
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          this.setState(modifiedState);

      }
    })
    .catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          this.setState(modifiedState);
        }
    })

  }

  renderRedirect = () => {
    if(this.state.redirect) {
      return <Navigate to="/"/>
    }
  }

  toggleTreeDef = (e) => {
    this.setState(prevState => ({
        treeDefNavActive: !prevState.treeDefNavActive
    }));
  }

  toggleCalibAppLinks = (e) => {
    this.setState(prevState => ({
      calibAppLinks: !prevState.calibAppLinks
    }));
  }
  
  toggleCrmReport = (e) => {
    this.setState(prevState => ({
        ReportDropDownOpen: !prevState.ReportDropDownOpen
    }));
  }  

  toggleCrmAction = (e) => {
    this.setState(prevState => ({
        ActionDropDownOpen: !prevState.ActionDropDownOpen
    }));
  } 

  toggleCrmOrder = (e) => {
    this.setState(prevState => ({
      OrderDropDownOpen: !prevState.OrderDropDownOpen
    }));
  } 

  toggleCrmDeviceAction = (e) => {
    this.setState(prevState => ({
      DeviceActionDropDownOpen: !prevState.DeviceActionDropDownOpen
    }));
  } 
  
  toggleCrmProductAction = (e) => {
    this.setState(prevState => ({
      ProductActionDropDownOpen: !prevState.ProductActionDropDownOpen
    }));
  } 
  
  toggleCrmTrack = (e) => {
    this.setState(prevState => ({
      TrackDropDownOpen: !prevState.TrackDropDownOpen
    }));
  }  

  toggleCrmRawDataParametersAction= (e) => {
    this.setState(prevState => ({      
      RawDataParametersActionDropDownOpen: !prevState.RawDataParametersActionDropDownOpen
    }));
  }

  toggleCrmCalibApp= (e) => {
    this.setState(prevState => ({      
      calibAppDropDownOpen: !prevState.calibAppDropDownOpen
    }));
  }

  toggleCrmTreeDef= (e) => {
    this.setState(prevState => ({      
      treeDefDropDownOpen: !prevState.treeDefDropDownOpen
    }));
  }

  toggleCrmSmartHmmApp = (e) => {
    this.setState(prevState => ({      
      smartHHMAppDropDownOpen: !prevState.smartHHMAppDropDownOpen
    }));
  }

  toggleCrmMore= (e) => {
    this.setState(prevState => ({
      MoreDropDownOpen: !prevState.MoreDropDownOpen,
    }));
  }

  toggleCrmProductPartAction= (e) => {
    this.setState(prevState => ({
      ProductPartActionDropDownOpen: !prevState.ProductPartActionDropDownOpen,
    }));
  }

  toggleCrmComplaintManagement = (e) => {
    this.setState(prevState => ({
      ComplaintManagementDropDownOpen: !prevState.ComplaintManagementDropDownOpen
    }));
  }

  onChangeLanguageToViewIn = async (e) => {
    let strLanguageToViewIn = e.target.value;
    let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext

    let modifiedState = this.state;
    // Save into the App Context any current Date changes that might have happened on the UI
    // before the Chart Param type was changed
    await appRelevantDataContextValue.onChangeLanguageToViewIn(strLanguageToViewIn);

    let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    let sessID = appRelevantDataContextValue.sessJankari.sessID;
    let fcmRegID = appRelevantDataContextValue.fcmRegDetails.fcmRegID;

    modifiedState.languageToViewIn = strLanguageToViewIn;

    this.saveDeviceRegIdIntoDB(LoggedInUserID, fcmRegID, "null", "LanguageChange", sessID, modifiedState).then((response) => {
      let updatedState = response[1];

      this.setState(updatedState);

    })

  }

  componentDidMount () {
    this.getUserPrivileges();
    this.getAllCountryCodeInfo();

  }

  componentDidUpdate () {
  
    let currentState = this.state;
    let appRelevantDataContextValue = this.context;  // Get all the relevant data from AppContext

    if( appRelevantDataContextValue == null ||
        ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
        appRelevantDataContextValue.loggedInUserInfo == null ||
        ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
        appRelevantDataContextValue.loggedInUserInfo.userID == null ||
        appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
        ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
        appRelevantDataContextValue.selectedNodeInfo == null  
    ) {
        console.log("NavBar:componentDidUpdate - First time render. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
        return; // Do not process further
    }

    if(appRelevantDataContextValue.loggedInUserPrivilege.Privilege != currentState.EncryptedPvg) {
      // on Refresh values stored in LocalStorage doesnot refelect into appcontext hence 
      // we need to call componenentDidUpdate which again take privilege from context and store into state.
      this.getUserPrivileges();
    }
  }

  // check loggedIn user privileges.
  getUserPrivileges = () => {
    let modifiedState = this.state;
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;  

    let encryptedPrivileges = appRelevantDataContextValue.loggedInUserPrivilege.Privilege;
    modifiedState.EncryptedPvg = encryptedPrivileges;

    axios.post(`${getAPIHostURL()}/wclient/getEncChaabi`)
    .then(response =>{
      modifiedState.errors.others = "";
        if(response.data.code == 'SUCCESS') {
           if(response.data.retrievedEncChaabi == null || response.data.retrievedEncChaabi.length <= 0) {
                modifiedState.errors.others = `Unable to get encryption key.`;
           } else {
               modifiedState.PrivilegeEncKey = response.data["retrievedEncChaabi"][0]["PassKey"];

              //  let bytes  = CryptoJS.AES.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);
               let bytes  = aes.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);

              //  let strPrivilege = bytes.toString(CryptoJS.enc.Utf8);
               let strPrivilege = bytes.toString(enc);

               try {
                    modifiedState.objPrivilege = JSON.parse(strPrivilege);

                    modifiedState.deviceMenuPvg = ((modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_OWNER) && modifiedState.objPrivilege[PVG_DEVC_OWNER] == true ) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_GENERAL_USER) && modifiedState.objPrivilege[PVG_GENERAL_USER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_VIEWER) && modifiedState.objPrivilege[PVG_DEVC_VIEWER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_DEVC_VIEWER_WITH_SETTING) && modifiedState.objPrivilege[PVG_DEVC_VIEWER_WITH_SETTING] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_PRIMARY_OWNER) && modifiedState.objPrivilege[PVG_INST_PRIMARY_OWNER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_ADDITIONAL_VIEWER) && modifiedState.objPrivilege[PVG_INST_ADDITIONAL_VIEWER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING) && modifiedState.objPrivilege[PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_TREE_NODE_VIEWER) && modifiedState.objPrivilege[PVG_TREE_NODE_VIEWER] == true) );

                    modifiedState.crmMenuPvg = ((modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && modifiedState.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && modifiedState.objPrivilege[PVG_PRODUCTION_WORKER] == true) || 
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && modifiedState.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && modifiedState.objPrivilege[PVG_SALES_SUPERVISOR] == true) || 
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && modifiedState.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && modifiedState.objPrivilege[PVG_PRODUCT_OWNER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && modifiedState.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && modifiedState.objPrivilege[PVG_TOILET_ADMIN] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && modifiedState.objPrivilege[PVG_SW_DEV_ADMIN] == true) ||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_TREE_DEFINITION_ADMIN) && modifiedState.objPrivilege[PVG_TREE_DEFINITION_ADMIN] == true)||
                                  (modifiedState.objPrivilege.hasOwnProperty(PVG_EXTERNAL_API_USER) && modifiedState.objPrivilege[PVG_EXTERNAL_API_USER] == true)) ;

                    
                    
                      if ((modifiedState.deviceMenuPvg != null && modifiedState.deviceMenuPvg) && (modifiedState.crmMenuPvg != null && modifiedState.crmMenuPvg) && 
                        appRelevantDataContextValue.navMenu.menuSwitchBtn != null && appRelevantDataContextValue.navMenu.menuSwitchBtn.length <= 0) {
                        appRelevantDataContextValue.onChangeSwitchBtn("DeviceMenu")   
                      }  
               } catch(e) {
                  // this.props.history.replace('/');
                  // console.log(`Should not happen. The Privilege obtained from Context is in invalid JSON format.`);
                  // No further processing required since the Privilege obtained from context is in invalid JSON format
               }
           }

        } else {
            // this.props.history.replace('/');
            if(response.data.code == 'SQL_ERROR') {
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
                console.log("Should not reach here");
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
            console.log(modifiedState.errors.others);
        }
        this.setState(modifiedState);

    })
    .catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          console.log(modifiedState.errors.others);
          this.setState(modifiedState);
        }
    }) 
  }

  getAllCountryCodeInfo = () => {

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = this.state;

    axios.post(`${getAPIHostURL()}/wclient/getAllCountryCodeInformation`)
    .then(response => {
      if(response.data.code == "SUCCESS") {
          modifiedState.CountryInfo = [];
          modifiedState.CountryInfo = response.data.CountryInfo;
      } else {
        if(response.data.code == "SQL_ERROR"){
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          modifiedState.successfulRegisterMsg = t(IDS_LoginServerIssue);
        }
      }
      this.setState(modifiedState);
    })
    .catch(error => {
      console.log("Network error:");
      console.log(error);
      if (axios.isCancel(error)) {
        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
      } else {
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        this.setState(modifiedState);
      } 
    });
  }

  getLoggedInUserInfo = () => {
    let modifiedState = this.state;

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;
    
    let OwnerUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    modifiedState.userID = OwnerUserID;

    let jsonBody = {
      OwnerUserID: OwnerUserID,
      ClientType: CLIENT_TYPE
    }

    axios.post(`${getAPIHostURL()}/wclient/getLoggedInUserInfo`, jsonBody)
    .then(response => {

      if(response.data.code == 'SUCCESS') {
       
        if(response.data.DeviceIdCount == null || response.data.DeviceIdCount.length <= 0 || 
           response.data.VisibleDeviceIDCount == null || response.data.VisibleDeviceIDCount.length <= 0 
          //  ||
          //  response.data.UserPhoneNo == null || response.data.UserPhoneNo.length <= 0  
        ) {
          // modifiedState.errors.others = `User Profile Information Missing.`;
          modifiedState.errors.others = t(IDS_UserProfileError);
          
        } else {
          if( response.data.UserPhoneNo == null || response.data.UserPhoneNo.length <= 0 ){
            modifiedState.errors.others = t(IDS_UserProfileError);
          }
          let NumberofDeviceCount = response.data.DeviceIdCount;
          let visibleDeviceIDCount = response.data.VisibleDeviceIDCount;
          let userPhoneNo = response.data.UserPhoneNo || 'NA';
          let UserFirstName = response.data.UserFirstName;
          let UserLastName = response.data.UserLastName;

          modifiedState.ownDeviceCount = NumberofDeviceCount;
          modifiedState.visibleDeviceCount = visibleDeviceIDCount;
          modifiedState.userPhoneNo = (userPhoneNo != null && userPhoneNo.length > 0 && userPhoneNo.split(" ")[1] != null && userPhoneNo.split(" ")[1].length > 0) ? userPhoneNo.split(" ")[1] : userPhoneNo;
          modifiedState.firstName = UserFirstName;
          modifiedState.lastName = UserLastName;
          modifiedState.CountryCode = (userPhoneNo != null && userPhoneNo.length > 0 && userPhoneNo.startsWith("+") && userPhoneNo.split(" ")[0] != null && userPhoneNo.split(" ")[0].length > 0) ? userPhoneNo.split(" ")[0] : "";
          this.setState(modifiedState);
        }
      } else {

          if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAM_MISSING') {
            modifiedState.errors.others = t(IDS_LoginServerIssue);

          } else if(response.data.code == 'USER_PROFILE_INFO_MISSING' || response.data.code == "INVALID_USER_EMAILID") {
              modifiedState.errors.others = t(IDS_UserProfileError);

          } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
      }
      this.setState(modifiedState);
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      if (axios.isCancel(error)) {
        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
      } else {
        // Tell the user that there are network issues
        // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        this.setState(modifiedState);
      }
    })
  }

  onBackButton = () => {
    window.close();
    let appRelevantDataContextValue = this.context;
    appRelevantDataContextValue.onChangeTrackDeviceInfo([], {}, "");
    appRelevantDataContextValue.onSelectedDevice("", "");

  }

  toggleRootCloseValueBasedOnSetupDeviceModal = () => {
    this.setState(prevState => ({
      showRootClose: !prevState.showRootClose
    }))
  }

  onEditButtonclick = ()=> {
    this.setState(prevState => ({
      showEditForm: !prevState.showEditForm,
      showRootClose: !prevState.showRootClose,
      CountryCode: prevState.CountryCode != null && prevState.CountryCode.length > 0 ? prevState.CountryCode : "+91",
      showEditProfileFields: "EditProfile",
    }))
  }

  onClickChangePassword = ()=> {
    this.setState(prevState => ({
      showEditForm: !prevState.showEditForm,
      showRootClose: !prevState.showRootClose,
      showEditProfileFields: "ChangePassword",
    }))
  }

  onCloseButtonclick = ()=> {
    let modifiedState = this.state;
    modifiedState.showRootClose = true;
    modifiedState.errors.others = '';
    this.setState(modifiedState);
    document.body.click();
  }

  onChnageFisrtName = (e) => {
    let modifiedState = this.state;
    modifiedState.errors.others = '';
    modifiedState.firstName = e.target.value;
    this.setState(modifiedState);
  }

  onChnageLastName = (e) => {
    let modifiedState = this.state;
    modifiedState.errors.others = '';
    modifiedState.lastName = e.target.value;
    this.setState(modifiedState);
  }

  onChnagePhoneNo = (e) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = this.state;
    modifiedState.errors.others = '';
    modifiedState.userPhoneNo = e.target.value;
    if(modifiedState.userPhoneNo.length < 4 || modifiedState.userPhoneNo.length > 12) {
      modifiedState.errors.others = t(IDS_PhoneNumberErrMsg);
    }
    this.setState(modifiedState);
  }

  onChangeOldPassword = (e) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = this.state;
    modifiedState.oldPassword = e.target.value;
    if(modifiedState.oldPassword != null && modifiedState.oldPassword.length < 8) {
      modifiedState.errors.others = t(IDS_OldPassLengthError);
    } else {
      modifiedState.errors.others = '';
    }
    this.setState(modifiedState);
  }

  onChnageNewPassword = (e) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = this.state;
    modifiedState.newPassword = e.target.value;
    if(modifiedState.newPassword != null && modifiedState.newPassword.length < 8) {
      modifiedState.errors.others = t(IDS_NewPassLengthError);
    } else {
      modifiedState.errors.others = '';
    }
    this.setState(modifiedState);
  }

  onChnageCnfrmNewPassword = (e) => {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;

    let modifiedState = this.state;
    modifiedState.errors.others = '';
    modifiedState.cnfrmNewPassword = e.target.value;
    if(modifiedState.cnfrmNewPassword != null && modifiedState.cnfrmNewPassword.length < 8) {
      modifiedState.errors.others = t(IDS_ConfrimPassLengthError);
    } else {
      modifiedState.errors.others = '';
    }
    this.setState(modifiedState);
  }

  onEditCloseButtonclick = (e) => {
    let modifiedState = this.state;
    modifiedState.errors.others = "";
    modifiedState.oldPassword = "";
    modifiedState.newPassword = "";
    modifiedState.cnfrmNewPassword = "";
    modifiedState.showEditProfileFields = "";
    modifiedState.showRootClose = true;
    modifiedState.showEditForm = false;
    this.setState(modifiedState);
    document.body.click();
  }

  onSaveButtonclick = (e) => {

    let modifiedState =  this.state;
    let inAppRelevantDataContextValue = this.context;
    let t = inAppRelevantDataContextValue.t
    let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;

    // if(modifiedState.userPhoneNo != null && modifiedState.userPhoneNo.length > 10) {
    //   modifiedState.errors.others = t(IDS_PhoneError);
    //   this.setState(modifiedState);
    //   return;
    // } else if(modifiedState.userPhoneNo != null && modifiedState.userPhoneNo.length < 10){
    //   modifiedState.errors.others = t(IDS_RegistPhoneError);
    //   this.setState(modifiedState);
    //   return;
    // }
    
    if(modifiedState.userPhoneNo == null || modifiedState.userPhoneNo.length < 4 || modifiedState.userPhoneNo.length > 12 ) {
      modifiedState.errors.others = t(IDS_PhoneNumberErrMsg);
      this.setState(modifiedState);
      return;
    } else if(modifiedState.firstName == null || modifiedState.firstName.length <= 0){
      modifiedState.errors.others = t(IDS_EnterFirstName);
      this.setState(modifiedState);
      return;
    } else if (modifiedState.lastName == null || modifiedState.lastName.length <= 0) {
      modifiedState.errors.others = t(IDS_EnterLastName);
      this.setState(modifiedState);
      return;

    } else{
      // No need to proccess.
    }

    let jsonParams = {
      firstName: modifiedState.firstName,
      lastName: modifiedState.lastName,
      phoneNo: modifiedState.CountryCode +" " +modifiedState.userPhoneNo,
      loggedInUserID: loggedInUserID,
    }

    axios.post(`${getAPIHostURL()}/wclient/updateUserInfo`, jsonParams)
    .then(response =>{

        if(response.data.code == 'SUCCESS') {
          inAppRelevantDataContextValue.onChangeloggedInUserInfo(modifiedState.firstName, modifiedState.lastName);

          alert(t(IDS_SuccessUpdatedUsrProf))

          modifiedState.showRootClose = true;
          // modifiedState.firstName = "";
          // modifiedState.lastName = "";
          modifiedState.showEditForm = false;
          this.setState(modifiedState);

          document.body.click(); 
          document.body.click(); 

        } else {
            if(response.data.code == 'REQ_PARAMS_MISSING'){
                modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
            } else if(response.data.code == 'SQL_ERROR') {
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
                console.log("Should not reach here");
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
          this.setState(modifiedState);
        }
    })
    .catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
            console.log('Axios request cancelled because of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
        }
        this.setState(modifiedState);
    }) 
  }

  changeUserPassword = (e) => {

    let modifiedState =  this.state;
    let inAppRelevantDataContextValue = this.context;
    let t = inAppRelevantDataContextValue.t
    let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;
    let sessID = inAppRelevantDataContextValue.sessJankari.sessID;

    if(modifiedState.oldPassword == null || modifiedState.oldPassword.length <= 0) {
      modifiedState.errors.others = t(IDS_OldPassError);
      this.setState(modifiedState);
      return;
    } else if(modifiedState.newPassword == null || modifiedState.newPassword.length <= 0) {
      modifiedState.errors.others = t(IDS_NewPassError);
      this.setState(modifiedState);
      return;
    } else if(modifiedState.cnfrmNewPassword == null || modifiedState.cnfrmNewPassword.length <= 0) {
      modifiedState.errors.others = t(IDS_CnfrmPassErrro);
      this.setState(modifiedState);
      return;
    } else if(modifiedState.oldPassword.length < 8) {
      modifiedState.errors.others = t(IDS_OldPassLengthError);
      this.setState(modifiedState);
      return;  
    } else if(modifiedState.newPassword.length < 8) {
      modifiedState.errors.others = t(IDS_NewPassLengthError);
      this.setState(modifiedState);
      return;      
    } else if (modifiedState.newPassword.length != modifiedState.cnfrmNewPassword.length ||
      modifiedState.newPassword != modifiedState.cnfrmNewPassword
    ) {
      modifiedState.errors.others = t(IDS_NewAndOldPassNotMatched);
      this.setState(modifiedState);
      return;
    } else if (modifiedState.oldPassword.length == modifiedState.cnfrmNewPassword.length && 
      modifiedState.oldPassword == modifiedState.cnfrmNewPassword
    ) {
      modifiedState.errors.others = t(IDS_NewPassCannotSameAsOldPass);
      this.setState(modifiedState);
    return;
    } else{
      // No need to proccess.
    }

    let jsonParams = {
      OldPassword: modifiedState.oldPassword,
      NewPassword: modifiedState.newPassword,
      CnfrmNewPassword: modifiedState.cnfrmNewPassword,
      LoggedInUserID: loggedInUserID,
    }

    axios.post(`${getAPIHostURL()}/wclient/changeUserPassword`, jsonParams)
    .then(response =>{

        if(response.data.code == 'SUCCESS') {

          // alert(t(IDS_SuccessChnagedUsrPassword))
          alert(t(IDS_SuccessChangedUsrPasswordReLogin));

          modifiedState.showRootClose = true;
          modifiedState.oldPassword = "";
          modifiedState.newPassword = "";
          modifiedState.cnfrmNewPassword = "";
          modifiedState.showEditProfileFields = "";
          modifiedState.showEditForm = false;

          // Here, On ChangePassword, End date the current session of Logged in user.
          let jsonParams = {
            LoggedInUserID: loggedInUserID,
          }

          axios.post(`${getAPIHostURL()}/wclient/bringAllSessIDsOfLoggedInUser`, jsonParams)
          .then(response =>{
      
              if(response.data.code == 'SUCCESS') {

                if(response.data.allSessionIDsOfLoggedInUser == null || response.data.allSessionIDsOfLoggedInUser.length <= 0 )
                {
                    console.log("Should not reach here.");
                } else {
                    let allSessionIDsOfLoggedInUser = response.data.allSessionIDsOfLoggedInUser;
                    let arrayOfSessionIDs = [];

                    for(let i=0; i<allSessionIDsOfLoggedInUser.length; i++){
                      arrayOfSessionIDs.push(allSessionIDsOfLoggedInUser[i]["SessionID"]);
                    }

                    modifiedState.arrOfAllSessionIDs = arrayOfSessionIDs.join(",");

                }

                this.onLogOut("ChangePassword", modifiedState);

              } else {
                // this.props.history.replace('/');
                if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                this.setState(modifiedState);

            }
          })
          .catch(err => {
              console.log("Network error");
              console.log(err);
              if (axios.isCancel(err)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
              } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
              }
          }) 
      
          this.setState(modifiedState);

          document.body.click(); 
          document.body.click(); 

        } else {

          if(response.data.code == 'REQ_PARAMS_MISSING'){
              modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
          } else if(response.data.code == 'INCORECT_OLD_PASSWORD') {
            modifiedState.errors.others = t(IDS_OldPassIncorrect);
          } else if(response.data.code == 'SQL_ERROR') {
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
              console.log("Should not reach here");
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          this.setState(modifiedState);
        }
    })
    .catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
            console.log('Axios request cancelled because of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
        }
        this.setState(modifiedState);
    }) 
  }

  onChangeCountryCode = (e) => {
    let modifiedState = this.state;
    modifiedState.CountryCode = e.target.value;
    this.setState(modifiedState);
  }

  switchMenu = (inMenuType) => {
    let appRelevantDataContextValue = this.context;
    let menuSwitchBtn = appRelevantDataContextValue.navMenu.menuSwitchBtn;
    
    if(inMenuType != null && inMenuType == "CrmMenu" && inMenuType != menuSwitchBtn) {
      appRelevantDataContextValue.onChangeSwitchBtn("CrmMenu")  
    } else {
      appRelevantDataContextValue.onChangeSwitchBtn("DeviceMenu")
    }

    if(inMenuType === "DeviceMenu") {
      appRelevantDataContextValue.onSelectedDevice(appRelevantDataContextValue.loggedInUserInfo.userID, appRelevantDataContextValue.loggedInUserInfo.userFirstName + " " + appRelevantDataContextValue.loggedInUserInfo.userLastName, true, false, false, [], null, "");
      appRelevantDataContextValue.onSelectedNodeContainsChildNode(false);
    }
  }

  onTogglePassword = () => {
    let modifiedState = this.state;
    modifiedState.passwordShown = !modifiedState.passwordShown;
    this.setState(modifiedState);
  }

  render() {
    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t;
    let languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

    let userFirstName = appRelevantDataContextValue.loggedInUserInfo.userFirstName;
    let userLastName = appRelevantDataContextValue.loggedInUserInfo.userLastName;
    let trackedDeviceInfoArr = appRelevantDataContextValue.devicesToTrack.DevicesInfo;
    let ownerOfTrackedDevices = appRelevantDataContextValue.devicesToTrack.ownerOfTrackedDevices;
    let bShowMenuBasedOnTracedDevcsKeyValue =  ((trackedDeviceInfoArr != null && trackedDeviceInfoArr.length > 0) || (ownerOfTrackedDevices != null && ownerOfTrackedDevices.length > 0)) ? true : false;  

    let menuSwitchBtn = appRelevantDataContextValue.navMenu.menuSwitchBtn;
    let invokedLoginPgInfo = appRelevantDataContextValue.invokedLoginPgInfo;
    let loginPagePath = invokedLoginPgInfo.loginPagePath;
    let showNextPagePathAfterLogin = invokedLoginPgInfo.showNextPagePathAfterLogin;

    let bShowMenuBasedOnLoginPathKeyValue =  ((invokedLoginPgInfo != null && Object.keys(invokedLoginPgInfo).length > 0) &&
      (loginPagePath != null && loginPagePath.length > 0) && (showNextPagePathAfterLogin != null 
        && showNextPagePathAfterLogin.length > 0)) ? true : false;
    let pathname = window.location.pathname;
    let windowWidth = window.innerWidth;

    return (
      <div>

        {!bShowMenuBasedOnLoginPathKeyValue &&
          <Navbar light expand="md" className="navBarSubMenuBandCRM bg-white shadow-sm flex align-items-center justify-between px-2 py-2" style={ windowWidth <= 570 ? { marginRight: "-10px", paddingTop: "0.2rem", paddingBottom: "0.2rem" } : { marginRight: "16px", paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
            {(pathname.includes("/crm/") || pathname.includes("/admin") || pathname.includes("/otaStatusReport")) ?
              <div className='' style={{width:"70px"}}>
                <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
              </div>
              : null
            } 
            {/* {(pathname.includes("/admin") || pathname.includes("/otaStatusReport")) ?
              <div className='' style={{width:"70px"}}>
                <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
              </div>
            : null} */}
            {/* <NavbarBrand className="mr-auto"></NavbarBrand> */}
            {/* <NavbarToggler onClick={this.toggle}/> */}
            {/* <ImMenu3 onClick={this.toggle} /> */}
            {windowWidth <= 570 &&
              (<>
                <div className='d-flex justify-content-center align-items-center'>
                  <ImMenu3 style={{ fontSize: "1.7rem" }} onClick={this.toggle} />

                  <div className='d-flex justify-content-end ms-3 me-1'>
                    <div className="tooltipPar">
                        {this.state.showDrawer ? 
                        <MdClose className='transition-all' onClick={()=> this.setState({...this.state , showDrawer : false})}/>:
                        <span onClick={()=> this.setState({...this.state , showDrawer : true})}><RiMenuUnfoldLine style={{ fontSize: "1.2rem" }} /></span> 
                        }
                    </div>
                  </div>
                </div>

                <div className={`drawerDiv ${this.state.showDrawer ? 'drawerDivIn' : 'drawerDivOut'}`}>
                  <VcSideNav />
                  <FaArrowAltCircleLeft 
                      className="closingArrow" onClick={()=> this.setState({...this.state , showDrawer : false})}
                  />
                </div>
              </>)
            }
            <Collapse isOpen={this.state.isOpen} navbar >
            <Nav className="ms-auto" navbar>

                {(this.state.objPrivilege != null) && 
                  ( ((this.state.deviceMenuPvg != null && this.state.deviceMenuPvg) && (this.state.crmMenuPvg != null && this.state.crmMenuPvg) && (!bShowMenuBasedOnTracedDevcsKeyValue)?
                      (menuSwitchBtn == "DeviceMenu")
                      : 
                      ((this.state.deviceMenuPvg != null && this.state.deviceMenuPvg) || (bShowMenuBasedOnTracedDevcsKeyValue))
                  )
                  ) &&
                  <div className= "navItemToBeDisplayed">
                    <NavItem className="navitem">
                      <NavLink to='/mapsonlogin' 
                               className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                        {t(IDS_TrackToilet)}
                      </NavLink>
                    </NavItem>

                    <NavItem className="navitem">
                        {/* <NavLink to='/devices'  */}
                        <NavLink to="/device/dashboard"
                                className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                          {t(IDS_Dashboard)}
                        </NavLink>
                    </NavItem>

                    <NavItem className="navitem_desktopViewLayout">
                        <NavLink to="/device/settings"
                                className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                          {t(IDS_Settings)}
                        </NavLink>
                    </NavItem>

                    <NavItem className="navitem_desktopViewLayout">
                        <NavLink to="/device/charts"
                                className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                          {t(IDS_Charts)}
                        </NavLink>
                    </NavItem>

                    <NavItem className="navitem_desktopViewLayout">
                        <NavLink to="/device/alerts"
                                className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                          {t(IDS_Alerts)}
                        </NavLink>            
                    </NavItem>

                    {/* <NavItem className="navitem">
                        <NavLink to="/abouthhm"  
                                className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                          {t(IDS_About)} 
                        </NavLink>
                    </NavItem> */}

                    <NavItem className="navitem">
                        <NavLink to="/support"  
                                className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                          <FaAddressBook/>
                        </NavLink> 
                    </NavItem>

                    <NavItem className="navitem">
                        <NavLink to="/faqs"  
                                className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                          <FaQuestionCircle/>
                        </NavLink>
                    </NavItem>

                  {(bShowMenuBasedOnTracedDevcsKeyValue) &&
                    <NavItem className="navitem" >
                      {this.renderRedirect()}
                      <div style={{color: "white"}} onClick = {this.onBackButton} className="closeCurrentTab" >
                        <TiArrowBack/>
                      </div>
                    </NavItem>
                  }
                  </div>  
                }

                {(this.state.objPrivilege != null) && 
                  (
                    (this.state.objPrivilege.hasOwnProperty(PVG_ADMIN) && this.state.objPrivilege[PVG_ADMIN] == true) || 
                    (this.state.objPrivilege.hasOwnProperty(PVG_FWMR_ADMIN) && this.state.objPrivilege[PVG_FWMR_ADMIN] == true) 
                  ) && (!bShowMenuBasedOnTracedDevcsKeyValue) && (!bShowMenuBasedOnLoginPathKeyValue)
                  ?           
                  <div className= "navItemToBeDisplayed">
                    <NavItem className="navitem" style={{ paddingTop: "0.2rem" }}>
                        <NavLink to="/otaStatusReport"  
                                className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                                  OTA Status
                        </NavLink>
                    </NavItem>
                    <NavItem className="navitem"> 
                        <NavLink to="/admin"  
                                className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                          <FaUserSecret/>
                        </NavLink>
                    </NavItem>
                  </div>
                  : null
                }

              
                {(this.state.objPrivilege != null) && 
                  (
                    ((this.state.deviceMenuPvg != null && this.state.deviceMenuPvg) && (this.state.crmMenuPvg != null && this.state.crmMenuPvg) ?
                      (menuSwitchBtn == "CrmMenu") && (!bShowMenuBasedOnTracedDevcsKeyValue) 
                      :
                      ((this.state.crmMenuPvg != null && this.state.crmMenuPvg) && (!bShowMenuBasedOnTracedDevcsKeyValue))
                    )
                  )
                  ? 
                    <div className= "navItemToBeDisplayed">
                      {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && this.state.objPrivilege[PVG_PRODUCT_OWNER] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && this.state.objPrivilege[PVG_TOILET_ADMIN] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_TREE_DEFINITION_ADMIN) && this.state.objPrivilege[PVG_TREE_DEFINITION_ADMIN] == true)
                      ) &&
                        <div className= "navItemToBeDisplayed">
                          {/* To be shown only In Mobile view as DropDown Menu. 
                          If this part is getting modified, please ensure to modify the navbar part of Multilevel Sub Menu of web view. */}
                          <NavItem className="navitem showNavBarInMobView" >          
                            <Dropdown isOpen={this.state.ReportDropDownOpen} toggle={this.toggleCrmReport} direction= "down" >
                              <DropdownToggle tag = "button" type="button" caret className = {pathname.includes('/crm/report/')? "navBarDropDownActive": "navBarDropDown"} >
                                <span className="navitem" style={{marginRight: "0rem"}}>Reports</span>
                              </DropdownToggle>
                                <DropdownMenu end>

                                  {
                                    (this.state.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && this.state.objPrivilege[PVG_TOILET_ADMIN] == false)
                                      &&
                                        <NavLink className = "textDecoNone" to = {`/crm/report/allStatesDashboard`}> <DropdownItem style={ pathname.includes("/crm/report/allStatesDashboard") ? { backgroundColor: "#e5fcff" } : { backgroundColor: "white" } }>All States-Dashboard</DropdownItem> </NavLink>
                                  }

                                  {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                    (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                                    (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                    (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                                  ) &&
                                    <span>
                                      <NavLink className = "textDecoNone" to = {`/crm/report/currentStateInventory`}> <DropdownItem style={ pathname.includes("/crm/report/currentStateInventory") ? { backgroundColor: "#e5fcff" } : {} }>Current State Inventory</DropdownItem> </NavLink>
                                      <NavLink className = "textDecoNone" to = {`/crm/report/rawDataAnalysis`}> <DropdownItem style={ pathname.includes("/crm/report/rawDataAnalysis") ? { backgroundColor: "#e5fcff" } : {} }>Raw Data For Analysis</DropdownItem> </NavLink>
                                    </span>
                                  }
                                  {((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                    (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                                  ) &&
                                    <span>
                                      <NavLink className = "textDecoNone" to = {`/crm/report/salesDashboard`}> <DropdownItem style={ pathname.includes("/crm/report/salesDashboard") ? { backgroundColor: "#e5fcff" } : {} }>Sales Dashboard</DropdownItem> </NavLink>
                                      <NavLink className = "textDecoNone" to = {`/crm/report/allTakenBackRentalDevices`}> <DropdownItem style={ pathname.includes("/crm/report/allTakenBackRentalDevices") ? { backgroundColor: "#e5fcff" } : {} }>All Taken Back Rental Devices</DropdownItem> </NavLink>
                                    </span>
                                  }
                                  {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                    (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                                  ) &&
                                    <NavLink className = "textDecoNone" to = {`/crm/report/stagewiseProductStatus`}> <DropdownItem style={ pathname.includes("/crm/report/stagewiseProductStatus") ? { backgroundColor: "#e5fcff" } : {} }>Stagewise Product Status</DropdownItem> </NavLink>
                                  }
                                  {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                    (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                                  ) &&
                                    <NavLink className = "textDecoNone" to = {`/crm/report/productionCount`}> <DropdownItem style={ pathname.includes("/crm/report/productionCount") ? { backgroundColor: "#e5fcff" } : {} }>Production Count</DropdownItem> </NavLink>
                                  }
                                  {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                    (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                                    (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
                                    (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true)
                                  ) &&
                                    <NavLink className = "textDecoNone" to = {`/crm/report/deviceHistory`}> <DropdownItem style={ pathname.includes("/crm/report/deviceHistory") ? { backgroundColor: "#e5fcff" } : {} }>Device History</DropdownItem> </NavLink>
                                  }
                                  {
                                  ((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                  (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                                  (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                  (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                                  ) &&
                                    <NavLink className = "textDecoNone" to = {`/crm/report/allOrderDetails`}> <DropdownItem style={ pathname.includes("/crm/report/allOrderDetails") ? { backgroundColor: "#e5fcff" } : {} }>All Order Details</DropdownItem> </NavLink>
                                  }  
                                  {
                                  (this.state.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && this.state.objPrivilege[PVG_TOILET_ADMIN] == true)
                                    &&
                                    <span>
                                        <NavLink className = "textDecoNone" to = {`/crm/report/viewToiletAndItsRatingInfo`}> <DropdownItem style={ pathname.includes("/crm/report/viewToiletAndItsRatingInfo") ? { backgroundColor: "#e5fcff" } : {} }>Toilet Rating Info</DropdownItem> </NavLink>
                                        <NavLink className = "textDecoNone" to = {`/crm/report/viewPublicFootContInfo`}> <DropdownItem style={ pathname.includes("/crm/report/viewPublicFootContInfo") ? { backgroundColor: "#e5fcff" } : {} }>Toilet Users Count Info</DropdownItem> </NavLink>
                                    </span>
                                  } 
                                  {
                                  ((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                  (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true) || 
                                  (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
                                  (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true)
                                  ) &&
                                    <NavLink className = "textDecoNone" to = {`/crm/report/allSubscriptionOrRentalDetails`}> <DropdownItem style={ pathname.includes("/crm/report/allSubscriptionOrRentalDetails") ? { backgroundColor: "#e5fcff" } : {} }>All Subscription/Rental Details</DropdownItem> </NavLink>
                                  }
                                  {((this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
                                  (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) 
                                  ) &&
                                    <NavLink className = "textDecoNone" to = {`/crm/report/inactiveFieldDevices`}> <DropdownItem style={ pathname.includes("/crm/report/inactiveFieldDevices") ? { backgroundColor: "#e5fcff" } : {} }>Inactive Field Devices</DropdownItem> </NavLink>
                                  }
                              </DropdownMenu>
                            </Dropdown>
                          </NavItem>

                          {/* To be shown only in Web view as multilevel Menu bar.
                              If this part is getting modified, please ensure to modify the navbar part of drop down menu in Mobile View.  */}
                          {/* This part is not being used in webview of nultilevel menu bar.
                            We could use this in future for any update or modification needed in multilevel menus. */}
                          
                          {/* <NavItem className="navitem showNavBarInWebView">
                            <NavLink to = 
                              {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true))
                              ? 
                                `/crm/report/currentProductionInventory`
                              :
                                ((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true))
                              ?
                                `/crm/report/salesDashboard`
                              :
                              ((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true))
                              ?
                                `/crm/report/currentFieldDevices`
                              :
                              (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true)
                              ?
                                `/crm/report/inactiveFieldDevices`
                              :
                                (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
                                (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) 
                              ?
                                `/crm/report/deviceHistory`
                              :
                                (this.state.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && this.state.objPrivilege[PVG_TOILET_ADMIN] == true)
                              ?
                                `/crm/report/viewToiletAndItsRatingInfo`
                              : `/crm/report`
                              }
                              className = {pathname.includes('/crm/report/') ? "main-nav main-nav-multilevel-active" : "main-nav"}
                            >
                              Reports <i className="fa fa-caret-down"></i>
                            </NavLink>
                          </NavItem> */}
                        </div>
                      }

                      {(this.state.objPrivilege.hasOwnProperty(PVG_EXTERNAL_API_USER) && this.state.objPrivilege[PVG_EXTERNAL_API_USER] == true) 
                        && (!bShowMenuBasedOnTracedDevcsKeyValue) && (!bShowMenuBasedOnLoginPathKeyValue)
                        ?
                          <span className= "navItemToBeDisplayed">
                          <NavItem className="navitem" style={{ paddingTop: "0.2rem" }}>
                            <NavLink to="/vilisoApis" 
                              className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}
                            >
                              Viliso APIs
                            </NavLink>
                          </NavItem>
                          </span>
                        :
                        null
                      }

                      {((this.state.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && this.state.objPrivilege[PVG_SW_DEV_ADMIN] == true)
                      ) ?
                        <span className= "navItemToBeDisplayed">
                          {/* To be shown only In Mobile view as DropDown Menu. 
                          If this part is getting modified, please ensure to modify the navbar part of Multilevel Sub Menu of web view. */}
                          <NavItem className="navitem showNavBarInMobView" >          
                            <Dropdown isOpen={this.state.ActionDropDownOpen} toggle={this.toggleCrmAction} direction="down">
                            <DropdownToggle tag = "button" type="button" caret className = {pathname.includes('/crm/action/')? "navBarDropDownActive": "navBarDropDown"} >
                              <span className="navitem" style={{marginRight: "0rem"}}>Production-Action</span>                                
                              </DropdownToggle>
                              <DropdownMenu end>    
                              {(this.state.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && this.state.objPrivilege[PVG_SW_DEV_ADMIN] == true) &&  
                                  <div>
                                    <NavLink className = "textDecoNone" to = {`/crm/action/productItemState`} style={ pathname.includes("/crm/action/productItemState") ? { backgroundColor: "#e5fcff" } : {} }> <DropdownItem>Set Product Item State</DropdownItem> </NavLink>                    
                                    <NavLink className = "textDecoNone" to = {`/crm/action/updateNewAppVersion`} style={ pathname.includes("/crm/action/updateNewAppVersion") ? { backgroundColor: "#e5fcff" } : {} }> <DropdownItem>Add Or Update New App Version</DropdownItem> </NavLink>
                                </div>
                              }
                              </DropdownMenu>
                            </Dropdown>
                          </NavItem>

                          {/* To be shown only in Web view as multilevel Menu bar.
                          If this part is getting modified, please ensure to modify the navbar part of drop down menu in Mobile View. */}
                          {/* This part is not being used in webview of nultilevel menu bar.
                            We could use this in future for any update or modification needed in multilevel menus. */}
                          {/* <NavItem className="navitem showNavBarInWebView">
                            <NavLink to = 
                                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true))
                                    ? 
                                      `/crm/action/definePcbTypeAndVersion`
                                    :
                                    (this.state.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && this.state.objPrivilege[PVG_SW_DEV_ADMIN] == true)
                                    ?
                                      `/crm/action/productItemState`
                                    :
                                    `/crm/action`
                                    }
                                    className = {pathname.includes('/crm/action/') ? "main-nav main-nav-multilevel-active" : "main-nav"}
                            >
                              Production-Action <i className="fa fa-caret-down"></i>  
                            </NavLink>
                          </NavItem> */}
                        </span>
                      : null
                      }
                      {/* {
                        (modifiedState.objPrivilege.hasOwnProperty(PVG_EXTERNAL_API_USER) && modifiedState.objPrivilege[PVG_EXTERNAL_API_USER] == true)
                        ?


                      } */}
                      
                      {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                        (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                        (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                        (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true) ||
                        (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true)
                      ) ?
                        <span className= "navItemToBeDisplayed">
                          {/* To be shown only In Mobile view as DropDown Menu. 
                          If this part is getting modified, please ensure to modify the navbar part of Multilevel Sub Menu of web view. */}
                          <NavItem className="navitem showNavBarInMobView" >          
                            <Dropdown isOpen={this.state.OrderDropDownOpen} toggle={this.toggleCrmOrder} direction= {(windowWidth <= 550 ? "down" : "down")}>
                              <DropdownToggle tag = "button" type="button" caret className = {pathname.includes('/crm/customerOrder/') ? "navBarDropDownActive": "navBarDropDown"}>
                              <span className="navitem" style={{marginRight: "0rem"}}>Customer Order</span>                                
                              </DropdownToggle>
                              <DropdownMenu end> 
                                <div>
                                  {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                  (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                                  (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                  (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                                  ) &&
                                  <NavLink className = "textDecoNone" to = {`/crm/customerOrder/fulfillOrder`}> <DropdownItem style={ pathname.includes("/crm/customerOrder/fulfillOrder") ? { backgroundColor: "#e5fcff" } : {} }>Fulfill Order</DropdownItem> </NavLink>
                                  }   
                                  {((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                    (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                                  ) &&
                                    <div>
                                      <NavLink className = "textDecoNone" to = {`/crm/customerOrder/createOrder`}> <DropdownItem style={ pathname.includes("/crm/customerOrder/createOrder") ? { backgroundColor: "#e5fcff" } : {} }>Create/Edit Order</DropdownItem> </NavLink>
                                      <NavLink className = "textDecoNone" to= {`/crm/customerOrder/customer`}><DropdownItem style={ pathname.includes("/crm/customerOrder/customer") ? { backgroundColor: "#e5fcff" } : {} }>Add New Customer</DropdownItem> </NavLink>
                                      <NavLink className = "textDecoNone" to= {`/crm/customerOrder/renewSubscription`}><DropdownItem style={ pathname.includes("/crm/customerOrder/renewSubscription") ? { backgroundColor: "#e5fcff" } : {} }>Renew Subscription/Rental Period</DropdownItem> </NavLink>
                                      <NavLink className = "textDecoNone" to= {`/crm/customerOrder/renewVilisoApiPkg`}><DropdownItem>Renew Viliso API Package</DropdownItem> </NavLink>
                                    </div>
                                  }   
                                  {((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                    (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true) ||
                                    (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true)
                                  ) &&
                                    <div>
                                      <NavLink className = "textDecoNone" to = {`/crm/customerOrder/CancelSubscription`}> <DropdownItem style={ pathname.includes("/crm/customerOrder/CancelSubscription") ? { backgroundColor: "#e5fcff" } : {} }>Cancel Subscription/Rental Period</DropdownItem> </NavLink>
                                    </div>
                                  }  
                                  {( !(this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true)) &&
                                  <NavLink className="textDecoNone" to={`/crm/customerOrder/organization`}><DropdownItem style={ pathname.includes("/crm/customerOrder/organization") ? { backgroundColor: "#e5fcff" } : {} }>Organization-Details</DropdownItem> </NavLink>
                                  }
                                </div>
                              </DropdownMenu>
                            </Dropdown>
                          </NavItem>

                          {/* To be shown only in Web view as multilevel Menu bar.
                          If this part is getting modified, please ensure to modify the navbar part of drop down menu in Mobile View. */}

                          {/* This part is not being used in webview of nultilevel menu bar.
                          We could use this in future for any update or modification needed in multilevel menus. */}
                          {/* <NavItem className="navitem showNavBarInWebView">
                            <NavLink to = 
                              {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true))
                              ? 
                                `/crm/order/fulfillOrder`
                              :
                                ((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                                (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true))
                              ?
                                `/crm/order/createOrder`
                              : 
                                `/crm/order`
                              }
                              className = {pathname.includes('/crm/order/') ? "main-nav main-nav-multilevel-active" : "main-nav"}
                            >
                              Orders-Details <i className="fa fa-caret-down"></i>
                            </NavLink>
                          </NavItem> */}
                        </span>
                      : null
                      }
                      {/* {((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                        (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                      ) ?
                        <span className= "navItemToBeDisplayed">
                          <NavItem className="navitem">
                            <NavLink className = "textDecoNone" to="/crm/customer"  
                                    className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                              Customer-Details
                            </NavLink>
                          </NavItem> 


                          <NavItem className="navitem">
                            <NavLink className = "textDecoNone" to="/crm/renewSubscription"  
                                    className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                              Renew-Subscription
                            </NavLink>
                          </NavItem>
                        </span>
                      : null  
                      } */}

                      {(this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) 
                      ?
                        <span className= "navItemToBeDisplayed">
                          {/* Add here new NavItems for this PVG_BACK_OFFICE_EXECUTIVE  */}
                        </span>
                      : null
                      }

                      {(this.state.objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && this.state.objPrivilege[PVG_PRODUCT_OWNER] == true) 
                      ?
                        <span className= "navItemToBeDisplayed">
                          <NavLink className = "textDecoNone" to = {`/crm/productAction/productDetails`}></NavLink>
                          <NavItem className="navitem showNavBarInMobView" >          
                            <Dropdown isOpen={this.state.ProductActionDropDownOpen} toggle={this.toggleCrmProductAction}>
                              <DropdownToggle tag = "button" type="button" caret className = {pathname.includes('/crm/productAction/')? "navBarDropDownActive": "navBarDropDown"} >
                                <span className="navitem" style={{marginRight: "0rem"}}>Product Action</span>                                
                              </DropdownToggle>
                              <DropdownMenu end>       
                                <div>
                                  <NavLink className = "textDecoNone" to = {`/crm/productAction/productLine`}> <DropdownItem style={ pathname.includes("/crm/productAction/productLine") ? { backgroundColor: "#e5fcff" } : {} }>Product-Line</DropdownItem> </NavLink>
                                  <NavLink className = "textDecoNone" to = {`/crm/productAction/productDetails`}> <DropdownItem style={ pathname.includes("/crm/productAction/productDetails") ? { backgroundColor: "#e5fcff" } : {} }>Product-Details</DropdownItem> </NavLink>
                                  <NavLink className = "textDecoNone" to = {`/crm/productAction/productSubscription`}> <DropdownItem style={ pathname.includes("/crm/productAction/productSubscription") ? { backgroundColor: "#e5fcff" } : {} }>Product-Subscription</DropdownItem> </NavLink>
                                  <NavLink className = "textDecoNone" to = {`/crm/productAction/rentalProductDetails`}> <DropdownItem style={ pathname.includes("/crm/productAction/rentalProductDetails") ? { backgroundColor: "#e5fcff" } : {} }>Rental Product-Details</DropdownItem> </NavLink>
                                </div>
                              </DropdownMenu>
                            </Dropdown>
                          </NavItem>

                          {/* <NavItem className="navitem">
                            <NavLink to="/crm/productLine"  
                                    className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                              Product-Line
                            </NavLink>
                          </NavItem>

                          <NavItem className="navitem">
                            <NavLink to="/crm/product"  
                                    className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                              Product-Details
                            </NavLink>
                          </NavItem>

                          <NavItem className="navitem">
                            <NavLink to="/crm/productSubscription"  
                                    className={({ isActive }) => (isActive ? "main-nav-active" : "main-nav")}>
                              Product-Subscription
                            </NavLink>
                          </NavItem> */}
                        </span>
                      : null
                      }

                      {(this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true)  ||
                        (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                        (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)  ||
                        (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                        (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                      ?
                        <span className= "navItemToBeDisplayed">
                          {/* To be shown only In Mobile view as DropDown Menu. 
                              If this part is getting modified, please ensure to modify the navbar part of Multilevel Sub Menu of web view. */}
                          <NavItem className="navitem showNavBarInMobView" >          
                            <Dropdown isOpen={this.state.DeviceActionDropDownOpen} toggle={this.toggleCrmDeviceAction}>
                              <DropdownToggle tag = "button" type="button" caret className = {pathname.includes('/crm/deviceAction/')? "navBarDropDownActive": "navBarDropDown"} >
                                <span className="navitem" style={{marginRight: "0rem"}}>Device-Action</span>                                
                              </DropdownToggle>
                              <DropdownMenu end>       
                              {(this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) &&
                                <div>
                                  <NavLink className = "textDecoNone" to = {`/crm/deviceAction/deviceReplacementUpgrade`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/deviceReplacementUpgrade") ? { backgroundColor: "#e5fcff" } : {} }>Device Replacement/Upgrade</DropdownItem> </NavLink>
                                  <NavLink className = "textDecoNone" to = {`/crm/deviceAction/takeBackDemoDevice`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/takeBackDemoDevice") ? { backgroundColor: "#e5fcff" } : {} }>Take Back Demo Device</DropdownItem> </NavLink>
                                  <NavLink className="textDecoNone" to={`/crm/deviceAction/takeBackRentalDevice`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/takeBackRentalDevice") ? { backgroundColor: "#e5fcff" } : {} }>Take Back Rental Device</DropdownItem> </NavLink>
                                  <NavLink className = "textDecoNone" to = {`/crm/deviceAction/recallSoldDevice`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/recallSoldDevice") ? { backgroundColor: "#e5fcff" } : {} }>Recall Sold Device</DropdownItem> </NavLink>
                                </div>
                              }
                              {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                              (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                              (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true)               
                              ) &&
                                <div>
                                  <NavLink className = "textDecoNone" to = {`/crm/deviceAction/replaceFulfilledFaultyDevice`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/replaceFulfilledFaultyDevice") ? { backgroundColor: "#e5fcff" } : {} }>Replace Fulfilled Faulty Device</DropdownItem> </NavLink>
                                </div>
                              }
                              {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)                 
                              ) &&
                                <div>
                                  <NavLink className = "textDecoNone" to = {`/crm/deviceAction/readyForShipment`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/readyForShipment") ? { backgroundColor: "#e5fcff" } : {} }>Make Ready for Shipment</DropdownItem> </NavLink>
                                  <NavLink className = "textDecoNone" to = {`/crm/deviceAction/moveDevcInUnderRepairSt`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/moveDevcInUnderRepairSt") ? { backgroundColor: "#e5fcff" } : {} }>Put Under Repair</DropdownItem> </NavLink>
                                  <NavLink className = "textDecoNone" to = {`/crm/deviceAction/moveDevcInDecommisionSt`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/moveDevcInDecommisionSt") ? { backgroundColor: "#e5fcff" } : {} }>Decommision DeviceID</DropdownItem> </NavLink>
                                  <NavLink className = "textDecoNone" to = {`/crm/deviceAction/changeModel`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/changeModel") ? { backgroundColor: "#e5fcff" } : {} }>Change Model</DropdownItem> </NavLink>                                
                                </div>
                              }
                              {(this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                              &&
                                <div>
                                  <NavLink className = "textDecoNone" to = {`/crm/deviceAction/blockDeviceData`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/blockDeviceData") ? { backgroundColor: "#e5fcff" } : {} }>Block Device Data</DropdownItem> </NavLink>
                                  <NavLink className = "textDecoNone" to = {`/crm/deviceAction/unblockDeviceData`}> <DropdownItem style={ pathname.includes("/crm/deviceAction/unblockDeviceData") ? { backgroundColor: "#e5fcff" } : {} }>Unblock Device Data</DropdownItem> </NavLink>
                                </div>
                              }
                              </DropdownMenu>
                            </Dropdown>
                          </NavItem>

                          {/* To be shown only in Web view as multilevel Menu bar.
                              If this part is getting modified, please ensure to modify the navbar part of drop down menu in Mobile View. */}
                          {/* This part is not being used in webview of nultilevel menu bar.
                            We could use this in future for any update or modification needed in multilevel menus. */}
                          {/* {(this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) ?

                            <NavItem className="navitem showNavBarInWebView">
                              <NavLink to = {`/crm/deviceAction/deviceReplacementUpgrade`}
                                      className = {pathname.includes('/crm/deviceAction/') ? "main-nav main-nav-multilevel-active" : "main-nav"}
                                      >
                                Device-Action <i className="fa fa-caret-down"></i>
                              </NavLink>
                            </NavItem>
                            :
                            ((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)                    
                            ) ?

                              <NavItem className="navitem showNavBarInWebView">
                                <NavLink to = {`/crm/deviceAction/readyForShipment`}
                                        className = {pathname.includes('/crm/deviceAction/') ? "main-nav main-nav-multilevel-active" : "main-nav"}
                                        >
                                  Device-Action <i className="fa fa-caret-down"></i>
                                </NavLink>
                              </NavItem>
                            :
                            (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                              ?

                              <NavItem className="navitem showNavBarInWebView">
                                <NavLink to = {`/crm/deviceAction/blockDeviceData`}
                                        className = {pathname.includes('/crm/deviceAction/') ? "main-nav main-nav-multilevel-active" : "main-nav"}
                                        >
                                  Device-Action <i className="fa fa-caret-down"></i>
                                </NavLink>
                              </NavItem>
                            :
                            null
                          } */}
                        </span>
                      : null
                      }
                      
                      {((this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true))
                      ?
                        <span className= "navItemToBeDisplayed">
                          {/* To be shown only In Mobile view as DropDown Menu. 
                              If this part is getting modified, please ensure to modify the navbar part of Multilevel Sub Menu of web view. */}
                          <NavItem className="navitem showNavBarInMobView" >          
                            <Dropdown isOpen={this.state.ComplaintManagementDropDownOpen} toggle={this.toggleCrmComplaintManagement}>
                              <DropdownToggle tag = "button" type="button" caret className = {pathname.includes('/crm/cms/')? "navBarDropDownActive": "navBarDropDown"} >
                                <span className="navitem" style={{marginRight: "0rem"}}>CMS</span>                                
                              </DropdownToggle>
                              <DropdownMenu end>  
                                <NavLink className = "textDecoNone" to = {`/crm/cms/complaintDetails`}> <DropdownItem style={ pathname.includes("/crm/cms/complaintDetails") ? { backgroundColor: "#e5fcff" } : {} }>Complaint Details</DropdownItem> </NavLink>
                                <NavLink className = "textDecoNone" to = {`/crm/cms/registerComplaint`}> <DropdownItem style={ pathname.includes("/crm/cms/registerComplaint") ? { backgroundColor: "#e5fcff" } : {} }>Register Complaint</DropdownItem> </NavLink>
                                <NavLink className = "textDecoNone" to = {`/crm/cms/resolveComplaint`}> <DropdownItem style={ pathname.includes("/crm/cms/resolveComplaint") ? { backgroundColor: "#e5fcff" } : {} }>Resolve/Reopen Complaint</DropdownItem> </NavLink>                            
                              </DropdownMenu>
                            </Dropdown>
                          </NavItem>

                          {/* To be shown only in Web view as multilevel Menu bar.
                              If this part is getting modified, please ensure to modify the navbar part of drop down menu in Mobile View. */}

                          {/* This part is not being used in webview of nultilevel menu bar.
                            We could use this in future for any update or modification needed in multilevel menus. */}
                          {/* <NavItem className="navitem showNavBarInWebView">
                            <NavLink className = "textDecoNone" to = {`/crm/cms/complaintDetails`}
                                    className = {pathname.includes('/crm/cms/') ? "main-nav main-nav-multilevel-active" : "main-nav"}
                                    >
                              Complaint-Management-System <i className="fa fa-caret-down"></i>
                            </NavLink>
                          </NavItem> */}
                        </span>
                      : null
                      }
                      

                      {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                        (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                        (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                        (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true) ||
                        (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
                        (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) 
                      ) &&
                        <span className= "navItemToBeDisplayed">
                          {/* To be shown only In Mobile view as DropDown Menu. 
                              If this part is getting modified, please ensure to modify the navbar part of Multilevel Sub Menu of web view. */}
                          <NavItem className="navitem showNavBarInMobView" >          
                            <Dropdown isOpen={this.state.TrackDropDownOpen} toggle={this.toggleCrmTrack}>
                            <DropdownToggle tag = "button" type="button" caret className = {pathname.includes('/crm/track/')? "navBarDropDownActive": "navBarDropDown"} >
                              <span className="navitem" style={{marginRight: "0rem"}}>Track</span>                                
                              </DropdownToggle>
                              <DropdownMenu end>                           
                                <NavLink className = "textDecoNone" to = {`/crm/track/trackDevices`}> <DropdownItem style={ pathname.includes("/crm/track/trackDevices") ? { backgroundColor: "#e5fcff" } : {} }>Track Devices / View User Tree</DropdownItem> </NavLink>
                                <NavLink className = "textDecoNone" to = {`/crm/track/trackAppVersion`}> <DropdownItem style={ pathname.includes("/crm/track/trackAppVersion") ? { backgroundColor: "#e5fcff" } : {} }>Track App Version</DropdownItem> </NavLink>
                                <NavLink className = "textDecoNone" to = {`/crm/track/trackRtcIssues`}> <DropdownItem style={ pathname.includes("/crm/track/trackRtcIssues") ? { backgroundColor: "#e5fcff" } : {} }>Track RTC Issues</DropdownItem> </NavLink>
                              </DropdownMenu>
                            </Dropdown>
                          </NavItem>

                          {/* To be shown only in Web view as multilevel Menu bar.
                          If this part is getting modified, please ensure to modify the navbar part of drop down menu in Mobile View. */}

                          {/* This part is not being used in webview of nultilevel menu bar.
                            We could use this in future for any update or modification needed in multilevel menus. */}

                          {/* <NavItem className="navitem showNavBarInWebView">
                            <NavLink to = {`/crm/track/trackDevices`}
                                    className = {pathname.includes('/crm/track/')  ? "main-nav main-nav-multilevel-active" : "main-nav"}
                                    >
                              Track <i className="fa fa-caret-down"></i>
                            </NavLink>
                          </NavItem> */}
                        </span>
                      }

                      {((this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true)  ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && this.state.objPrivilege[PVG_PRODUCT_OWNER] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_TREE_DEFINITION_ADMIN) && this.state.objPrivilege[PVG_TREE_DEFINITION_ADMIN] == true))                 
                      ?
                      <span className= "navItemToBeDisplayed">
                        {/* To be shown only In Mobile view as DropDown Menu. 
                            If this part is getting modified, please ensure to modify the navbar part of Multilevel Sub Menu of web view. */}
                        <NavItem className="navitem showNavBarInMobView" > 
                          <Dropdown isOpen={this.state.MoreDropDownOpen} toggle={this.toggleCrmMore} direction= {(windowWidth <= 550 ? "down" : "down")}>
                            <DropdownToggle tag = "button" type="button" caret className = {pathname.includes('/crm/more/')? "navBarDropDownActive": "navBarDropDown"} >
                              <span className="navitem" style={{marginRight: "0rem"}}>More</span>                                
                            </DropdownToggle>
                            <DropdownMenu end style={{width: "max-content"}}>  
                              {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)) 
                                ?
                                <Dropdown isOpen={this.state.ProductPartActionDropDownOpen} toggle={this.toggleCrmProductPartAction} direction= {(windowWidth <= 550 ? "down" : "left")}>
                                  <DropdownToggle tag = "button" type="button" 
                                  // caret-hide 
                                  style={{textDecoration: "none", border: "1px orange", backgroundColor: "white"}} >
                                    <div style={{padding: "0.2rem 0.5rem 0.2rem "}} className={this.state.ProductPartActionDropDownOpen == true ? "navBarDropDownsubmenuActive" : null}>
                                      <span><i className="fa fa-caret-left"></i> Product Part Action</span>
                                    </div>
                                  </DropdownToggle>
                                  <DropdownMenu end={windowWidth <= 550 ? true : false}>
                                  <div>     
                                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                    (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)) &&  
                                      <div>
                                        <NavLink className = "textDecoNone" to = {`/crm/more/productPartAction/definePcbTypeAndVersion`}> <DropdownItem onClick={this.toggleCrmMore} style={ pathname.includes("/crm/more/productPartAction/definePcbTypeAndVersion") ? { backgroundColor: "#e5fcff" } : {} }>Define New PCB Name or Version</DropdownItem> </NavLink>
                                        <NavLink className = "textDecoNone" to = {`/crm/more/productPartAction/productItemImpParts`}> <DropdownItem onClick={this.toggleCrmMore} style={ pathname.includes("/crm/more/productPartAction/productItemImpParts") ? { backgroundColor: "#e5fcff" } : {} }>Note Product Item Important Parts</DropdownItem> </NavLink>
                                      </div>                     
                                    }
                                  </div>
                                  </DropdownMenu>
                                </Dropdown>
                                :
                                null
                              }
                              {((this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) || 
                                (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) ||
                                (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                               ) &&
                                <Dropdown isOpen={this.state.RawDataParametersActionDropDownOpen} 
                                  toggle={this.toggleCrmRawDataParametersAction} direction= {(windowWidth <= 550 ? "down" : "left")}>
                                  <DropdownToggle tag = "button" type="button"
                                  //  caret-hide  
                                  style={{textDecoration: "none", border: "none", backgroundColor: "white"}}>
                                    <div style={{padding: "0.2rem 0.5rem 0.2rem "}} className={
                                      this.state.RawDataParametersActionDropDownOpen == true ? "navBarDropDownsubmenuActive" : null}>
                                      <span><i className="fa fa-caret-left"></i> Raw Data Analysis</span>
                                    </div>                           
                                  </DropdownToggle>
                                    <DropdownMenu end={windowWidth <= 550 ? true : false}>
                                        <div>
                                          <NavLink className = "textDecoNone" to = {`/crm/more/rawDataParamsAction/addRawDataParams`}> 
                                            <DropdownItem onClick={this.toggleCrmMore} style={ pathname.includes("/crm/more/rawDataParamsAction/addRawDataParams") ? { backgroundColor: "#e5fcff" } : {} }>Add Raw Data Action Parameters</DropdownItem> 
                                          </NavLink>
                                          <NavLink className = "textDecoNone" to = {`/crm/more/rawDataParamsAction/removeRawDataParams`}> 
                                            <DropdownItem onClick={this.toggleCrmMore} style={ pathname.includes("/crm/more/rawDataParamsAction/removeRawDataParams") ? { backgroundColor: "#e5fcff" } : {} }>Remove Raw Data Action Parameters</DropdownItem> 
                                          </NavLink>
                                        </div>
                                    </DropdownMenu>
                                </Dropdown>
                              }
                              {(this.state.objPrivilege.hasOwnProperty(PVG_TREE_DEFINITION_ADMIN) && this.state.objPrivilege[PVG_TREE_DEFINITION_ADMIN] == true)  &&
                                <Dropdown isOpen={this.state.treeDefDropDownOpen} toggle={this.toggleCrmTreeDef} style={ pathname.includes("/crm/more/treeDefinition") ? { backgroundColor: "#e5fcff" } : {} }>
                                  <NavLink className = "textDecoNone" to = {`/crm/more/treeDefinition`}>    
                                    <DropdownToggle tag = "button" type="button" onClick = { () => {this.toggleCrmMore()}}
                                      style={ pathname.includes("/crm/more/treeDefinition") ? {textDecoration: "none", border: "none", backgroundColor: "#e5fcff"} : { textDecoration: "none", border: "none", backgroundColor: "white" } }>
                                      <div style={{padding: "0.2rem 0.5rem 0.2rem "}} className={
                                        this.state.treeDefDropDownOpen == true ? "navBarDropDownsubmenuActive" : null}>
                                        <span style={{paddingLeft: "1.1rem"}}>Tree Definition</span>
                                      </div>                    
                                    </DropdownToggle>
                                  </NavLink>
                                </Dropdown>
                              }
                              {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                                (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                                (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
                                (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && this.state.objPrivilege[PVG_PRODUCT_OWNER] == true)
                               ) &&
                               <div>
                                  <Dropdown isOpen={this.state.calibAppDropDownOpen} toggle={this.toggleCrmCalibApp} style={ pathname.includes("/crm/more/calibAppLinks") ? { backgroundColor: "#e5fcff" } : {} }>
                                    <NavLink className = "textDecoNone" to = {`/crm/more/calibAppLinks`}>    
                                      <DropdownToggle tag = "button" type="button" onClick = { () => {this.toggleCrmMore()}}
                                        style={ pathname.includes("/crm/more/calibAppLinks") ? {textDecoration: "none", border: "none", backgroundColor: "#e5fcff"} : { textDecoration: "none", border: "none", backgroundColor: "white" } }>
                                        <div style={{padding: "0.2rem 0.5rem 0.2rem "}} className={
                                          this.state.calibAppDropDownOpen == true ? "navBarDropDownsubmenuActive" : null}>
                                          <span style={{paddingLeft: "1.1rem"}}>Calibration App</span>
                                        </div>                    
                                      </DropdownToggle>
                                    </NavLink>
                                  </Dropdown>

                                  <Dropdown isOpen={this.state.toggleCrmSmartHmmApp} toggle={this.toggleCrmSmartHmmApp} style={ pathname.includes("/crm/more/hhmAppLinks") ? { backgroundColor: "#e5fcff" } : {} }>
                                    <NavLink className = "textDecoNone" to = {`/crm/more/hhmAppLinks`}>    
                                      <DropdownToggle tag = "button" type="button" onClick = { () => {this.toggleCrmMore()}}
                                      style={ pathname.includes("/crm/more/hhmAppLinks") ? {textDecoration: "none", border: "none", backgroundColor: "#e5fcff"} : { textDecoration: "none", border: "none", backgroundColor: "white" } }>
                                        <div style={{padding: "0.2rem 0.5rem 0.2rem "}} className={
                                          this.state.toggleCrmSmartHmmApp == true ? "navBarDropDownsubmenuActive" : null}>
                                          <span style={{paddingLeft: "1.1rem"}}>SmartHHM App</span>
                                        </div>                    
                                      </DropdownToggle>
                                    </NavLink>
                                  </Dropdown>
                                </div>
                              }
                            </DropdownMenu>
                          </Dropdown>
                        </NavItem>
                        {/* To be shown only in Web view as multilevel Menu bar.
                            If this part is getting modified, please ensure to modify the navbar part of drop down menu in Mobile View. */}

                        {/* This part is not being used in webview of nultilevel menu bar.
                            We could use this in future for any update or modification needed in multilevel menus. */}

                        {/* {((this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) || 
                          (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) ||
                          (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                        ) ?

                          <NavItem className="navitem showNavBarInWebView">
                            <NavLink to = {`/crm/rawDataParamsAction/addRawDataParams`}
                                    className = {pathname.includes('/crm/rawDataParamsAction/') ? "main-nav main-nav-multilevel-active" : "main-nav"}
                                    >
                              <i className="fa fa-caret-left"></i>Raw Data Analysis
                            </NavLink>
                          </NavItem>
                          :
                          null
                        }*/}
                      </span>
                      : null
                      } 
                    </div>
                  : null 
                } 
              </Nav>
            </Collapse>
          </Navbar>
        }

        {bShowMenuBasedOnLoginPathKeyValue && pathname.includes("/provideSolForCustComplaint/") &&
          <Navbar light expand="md" className="navBarSubMenuBandCRM py-2" style={ windowWidth <= 570 ? { marginRight: "-10px", paddingTop: "0.2rem", paddingBottom: "0.2rem" } : { marginRight: "16px", paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
            {(pathname.includes("/provideSolForCustComplaint/")) ?
              <div className='' style={{width:"70px"}}>
                <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
              </div>
              : null
            }

            <Nav className="ms-auto" navbar>
            <span className= "navItemToBeDisplayed">
              <NavItem className="navitem">
                  <NavLink to={showNextPagePathAfterLogin}
                            className={({ isActive }) => (isActive ? "main-nav-active" : 'main-nav')}>
                    Provide Solution
                  </NavLink>
              </NavItem>
            </span>
            </Nav>

            {windowWidth <= 570 &&
              (<>
                <div className='d-flex justify-content-center align-items-center'>

                  <div className='d-flex justify-content-end ms-3 me-1'>
                    <div className="tooltipPar">
                        {this.state.showDrawer ? 
                          <MdClose className='transition-all' onClick={()=> this.setState({...this.state , showDrawer : false})}/>:
                          <span onClick={()=> this.setState({...this.state , showDrawer : true})}><RiMenuUnfoldLine style={{ fontSize: "1.2rem" }} /></span> 
                        }
                    </div>
                  </div>
                </div>

                <div className={`drawerDiv ${this.state.showDrawer ? 'drawerDivIn' : 'drawerDivOut'}`}>
                  <VcSideNav />
                  <FaArrowAltCircleLeft 
                      className="closingArrow" onClick={()=> this.setState({...this.state , showDrawer : false})}
                  />
                </div>
              </>)
            }
            </Navbar>
          }

        {/* To be shown only in Web view as multilevel Menu bar.
            If this part is getting modified, please ensure to modify the navbar part of drop down menu in Mobile View. */}
        
        {/* This part is not being used in webview of nultilevel menu bar.
            We could use this in future for any update or modification needed in multilevel menus. */}

        {/*}
        {(!bShowMenuBasedOnLoginPathKeyValue) || ((!bShowMenuBasedOnLoginPathKeyValue) && menuSwitchBtn == "CrmMenu")?
          <div className = "showNavBarInWebView">
            {(this.state.objPrivilege != null) && (
              (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
              (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
              (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
              (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true) ||
              (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
              (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
              (this.state.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && this.state.objPrivilege[PVG_TOILET_ADMIN] == true)
            ) &&
              (pathname.includes('/crm/report/')) ? 
              < Navbar light expand="md" className = "navBarSubMenuBand">
                  <Nav className="ms-auto" navbar style={{paddingBottom: "0rem"}}>
                  {((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                    (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                  ) &&
                    <NavItem className="navitemSubMenu">
                      <NavLink to = {`/crm/report/salesDashboard`}
                        className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}>                         
                      > Sales Dashboard
                      </NavLink>
                    </NavItem>
                  }
                  {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/report/currentProductionInventory`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                               
                        > Current Production Inventory
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/report/stagewiseProductStatus`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                               
                        > Stagewise Product Status
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/report/productionCount`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                               
                        > Production Count
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/report/allOrderDetails`}   
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > All Order Details
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/report/deviceHistory`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Device History
                        </NavLink>
                      </NavItem>
                    }
                    {(this.state.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && this.state.objPrivilege[PVG_TOILET_ADMIN] == true)
                    &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/report/viewToiletAndItsRatingInfo`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Toilet Rating Info
                        </NavLink>
                      </NavItem>
                    }
                    {(this.state.objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && this.state.objPrivilege[PVG_TOILET_ADMIN] == true)
                    &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/report/viewPublicFootContInfo`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Toilet Users Count Info
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/report/allSubscriptionDetails`}           
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                                   
                        > All Subscription Details 
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/report/currentFieldDevices`}   
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                                             
                        > Current Field Devices
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/report/inactiveFieldDevices`}    
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                                           
                        > Inactive Field Devices
                        </NavLink>
                      </NavItem>
                    }
                  </Nav>
                </Navbar>
            : null
            }
            {(this.state.objPrivilege != null) && (
              (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
              (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
              (this.state.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && this.state.objPrivilege[PVG_SW_DEV_ADMIN] == true)
            ) &&
              (pathname.includes('/crm/action/')) 
              ? 
                <Navbar light expand="md" className = "navBarSubMenuBand">
                  <Nav className="ms-auto" navbar style={{paddingBottom: "0rem"}}>
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)) &&
                        <NavItem className="navitemSubMenu">
                          <NavLink to = {`/crm/action/definePcbTypeAndVersion`}
                            className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                          > Define New PCB Name or Version
                          </NavLink>
                        </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)) &&

                        <NavItem className="navitemSubMenu">
                          <NavLink to = {`/crm/action/productItemImpParts`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                          > Note Product Item Important Parts
                          </NavLink>
                        </NavItem>
                    }
                    {(this.state.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && this.state.objPrivilege[PVG_SW_DEV_ADMIN] == true) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/action/productItemState`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Set Product Item State
                        </NavLink>
                      </NavItem>
                    }

                    {(this.state.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && this.state.objPrivilege[PVG_SW_DEV_ADMIN] == true) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/action/updateNewAppVersion`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Add Or Update New App Version
                        </NavLink>
                      </NavItem>
                    }
                  </Nav>
                </Navbar>
              : null
            }

            {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
              (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
              (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
              (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
            ) &&
              (pathname.includes('/crm/order/')) 
              ? 
                <Navbar light expand="md" className = "navBarSubMenuBand">
                  <Nav className="ms-auto" navbar style={{paddingBottom: "0rem"}}>

                    {((this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/order/createOrder`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Create/Edit Order
                        </NavLink>
                      </NavItem>
                    }
                    
                    <NavItem className="navitemSubMenu">
                      <NavLink to = {`/crm/order/fulfillOrder`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                      > Fulfill Order
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Navbar>
              : null
            }

            {(this.state.objPrivilege != null) && (
              (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
              (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
              (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
              (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
              (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true) ||
              (this.state.objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && this.state.objPrivilege[PVG_SW_DEV_ADMIN] == true)
            ) &&
              (pathname.includes('/crm/deviceAction/')) 
              ? 
                <Navbar light expand="md" className = "navBarSubMenuBand">
                  <Nav className="ms-auto" navbar style={{paddingBottom: "0rem"}}>
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/readyForShipment`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Make Ready for Shipment
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/replaceFulfilledFaultyDevice`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Mark Faulty
                        </NavLink>
                      </NavItem>
                    }
                    {(this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/deviceReplacementUpgrade`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Device Replacement/Upgrade
                        </NavLink>
                      </NavItem>
                    }
                    {(this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/takeBackDemoDevice`}                          
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Take Back Demo Device
                        </NavLink>
                      </NavItem>
                    }
                    {(this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/recallSoldDevice`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Recall Sold Device
                        </NavLink>
                      </NavItem>
                    }
                    {(this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/replaceFulfilledFaultyDevice`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Mark Faulty
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/moveDevcInUnderRepairSt`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Put Under Repair
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/moveDevcInDecommisionSt`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Decommission DeviceID
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/changeModel`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Change Model
                        </NavLink>
                      </NavItem>
                    }
                    {(this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                    &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/blockDeviceData`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Block Device Data
                        </NavLink>
                      </NavItem>
                    }
                    {(this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true)
                    &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/deviceAction/unblockDeviceData`}
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Unblock Device Data
                        </NavLink>
                      </NavItem>
                    }
                  </Nav>
                </Navbar>
              : null
            }

            {(this.state.objPrivilege != null) && (
              (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
              (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
              (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
            ) &&
              (pathname.includes('/crm/rawDataParamsAction/')) 
              ? 
                <Navbar light expand="md" className = "navBarSubMenuBand">
                  <Nav className="ms-auto" navbar style={{paddingBottom: "0rem"}}>
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) ||
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/rawDataParamsAction/addRawDataParams`}                          
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Add Raw Data Analysis Parameters
                        </NavLink>
                      </NavItem>
                    }
                    {((this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true) || 
                      (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/rawDataParamsAction/removeRawDataParams`}                          
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Remove Raw Data Analysis Parameters
                        </NavLink>
                      </NavItem>
                    }
                  </Nav>
                </Navbar>
              : null
            }

            {(this.state.objPrivilege != null) && (
            (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) 
            ) &&
              (pathname.includes('/crm/cms/')) 
              ? 
                <Navbar light expand="md" className = "navBarSubMenuBand">
                  <Nav className="ms-auto" navbar style={{paddingBottom: "0rem"}}>
                    <NavItem className="navitemSubMenu">
                      <NavLink to = {`/crm/cms/complaintDetails`}                        
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                      > Complaint Details
                      </NavLink>
                    </NavItem>
                    <NavItem className="navitemSubMenu">
                      <NavLink to = {`/crm/cms/registerComplaint`}                        
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                      > Register Complaint
                      </NavLink>
                    </NavItem>
                    <NavItem className="navitemSubMenu">
                      <NavLink to = {`/crm/cms/resolveComplaint`}                        
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                      > Resolve/Reopen Complaint
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Navbar>              
              : null 
              }
            
            // follwoing part for ComplaintManagement is commented
            {/* {(this.state.objPrivilege != null) && (
              (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) 
            ) &&
              (pathname.includes('/crm/ComplaintManagement/')) 
              ? 
                <Navbar light expand="md" className = "navBarSubMenuBand">
                  <Nav className="ms-auto" navbar style={{paddingBottom: "0rem"}}>
                    {((this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true)
                    ) &&
                      <NavItem className="navitemSubMenu">
                        <NavLink to = {`/crm/ComplaintManagement/RegisterComplaint`}                          
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                        > Register Complaint
                        </NavLink>
                      </NavItem>
                    }
                  </Nav>
                </Navbar>
              : null
            }

            {(this.state.objPrivilege != null) && (
            (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && this.state.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) || 
            (this.state.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && this.state.objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
            (this.state.objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && this.state.objPrivilege[PVG_SALES_EXECUTIVE] == true) || 
            (this.state.objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && this.state.objPrivilege[PVG_SALES_SUPERVISOR] == true) ||
            (this.state.objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && this.state.objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
            (this.state.objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && this.state.objPrivilege[PVG_FIELD_ENGINEER] == true)
            ) &&
              (pathname.includes('/crm/track/')) 
              ? 
                <Navbar light expand="md" className = "navBarSubMenuBand">
                  <Nav className="ms-auto" navbar style={{paddingBottom: "0rem"}}>
                    <NavItem className="navitemSubMenu">
                      <NavLink to = {`/crm/track/trackDevices`}                        
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                      > Track Devices / View User Tree
                      </NavLink>
                    </NavItem>
                    <NavItem className="navitemSubMenu">
                      <NavLink to = {`/crm/track/trackAppVersion`}                        
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                      > Track App Version
                      </NavLink>
                    </NavItem>
                    <NavItem className="navitemSubMenu">
                      <NavLink to = {`/crm/track/trackRtcIssues`}                        
                          className={({ isActive }) => (isActive ? "mainNavActiveSubMenu" : "mainNavSubMenu")}                                                                     
                      > Track RTC Issues
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Navbar>              
              : null 
              }
          </div>
        :
          null
        } */}
        <div style = {{marginBottom: "0.1rem"}}/>
      </div>
    );
  }
}

VcNavBar.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods
export default VcNavBar;