import { configureStore } from "@reduxjs/toolkit";
import deviceListReducer from "./Slices/deviceList";
import nodeInfoReducer from "./Slices/nodeInfo";
import variables from "./Slices/variables";
import treeNodeListReducer from "./Slices/treeNodeList"
import treeAndSearchSlice from "./Slices/treeAndSearchSlice";

export const appStore = configureStore({
    reducer: { // Main Reducer consisting many sub-reducers, each sub-reducer representing that slice's reducer functions
        deviceList: deviceListReducer,
        SelectedNodeInfo: nodeInfoReducer,
        variables: variables,
        treeNodeList: treeNodeListReducer,
        treeAndSearchSlice: treeAndSearchSlice,
    }
})






































