import React, { Component } from 'react';
import { getAPIHostURL } from '../../ClientConfig';
import axios from 'axios';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_LoginServerIssue, IDS_RegistNetworkError , IDS_LoginEmailError, IDS_EmailID, IDS_FEEmailNotMatch, IDS_FESelectPrivilege, 
         IDS_FEReEnterEmail, IDS_SelectPrivilege, IDS_FEMapToilets, IDS_FEMapDeviceToToilets, IDS_Save} from '../../VcLanguage';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }

class VcAdminEnrollFieldEngineer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          FieldEngineerEmailID: "",
          confirmemail: "",
          redirect: false,
          bOverwritePvgIfExists: true,
          Privilege:'',
          errors: { 
            FieldEngineerEmailID: '',
            confirmemail:'',
            Privilege:'',
            others:''
          },
          successfulEnrolledMsg: '',
          modal: false,
          backdrop: 'static'
        };
      }

      // onSubmit = (event) => {
      //   let appRelevantDataContextValue = this.context;
      //   let t = appRelevantDataContextValue.t;
      //   event.preventDefault();

      //   let modifiedState = this.state;
      //   modifiedState.successfulEnrolledMsg = '';

      //   if(modifiedState.FieldEngineerEmailID !== modifiedState.confirmemail) {  
      //     // modifiedState.errors.confirmemail = 'Email did not match. Try again!';
      //     modifiedState.errors.confirmemail = t(IDS_FEEmailNotMatch);
      //     this.setState(modifiedState);

      //   } else if(modifiedState.Privilege == "") {
      //     // modifiedState.errors.others = "Please Select Privilege.";
      //     modifiedState.errors.others = t(IDS_FESelectPrivilege);
      //     this.setState(modifiedState);

      //   } else {

      //     const allData = {
      //       FieldEngineerEmailID: this.state.FieldEngineerEmailID, 
      //       Privilege: this.state.Privilege
      //     };
    
      //     axios.post(`${getAPIHostURL()}/wclient/EnrollFieldEngineer`, allData)
      //     .then(response => {
      //       // if(response.data.code == "ER_DUP_ENTRY" && window.confirm(`EmailID Already Exists. Do you want to Update?`)) {
      //       if(response.data.code == "ER_DUP_ENTRY" && window.confirm(t(DS_FEEmailAlrdExists))) {

      //           this.setState({
      //             redirect :!this.state.redirect,
      //           }) 

      //           if(this.state.redirect == true){

      //             const allData = {
      //                 FieldEngineerEmailID: this.state.FieldEngineerEmailID, 
      //                 Privilege: this.state.Privilege,
      //                 bOverwritePvgIfExists: this.state.bOverwritePvgIfExists
      //             }

      //             // In case Field Engineer already exists and Admin wants to overwrite his existing privilege with new privileges.
      //             axios.post(`${getAPIHostURL()}/wclient/EnrollFieldEngineer`, allData)
      //             .then(response => {

      //               if(response.data.code == "SUCCESS") {
      //                 // modifiedState.successfulEnrolledMsg = "Field Engineer Privilege Updated Successfully."
      //                 modifiedState.successfulEnrolledMsg = t(IDS_FEPrivilegeUpdateSuccess)

      //                 modifiedState.FieldEngineerEmailID = "";
      //                 modifiedState.Privilege = "";
      //                 modifiedState.confirmemail = "";
      //                 modifiedState.errors.others = "";
      //                 modifiedState.errors.confirmemail = "";
                      
      //               } else {
      //                 if (response.data.code == 'SQL_ERROR') {
      //                     // Tell the user that Server is experiencing errors
      //                     // modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
      //                     modifiedState.errors.others = t(IDS_LoginServerIssue);
      //                   } else {
      //                     console.log("Should not reach here.")
      //                     // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
      //                     modifiedState.errors.others =  t(IDS_LoginServerIssue);
      //                   }
      //                 // // Display the specific error message for login failure
      //                 // this.setState(modifiedState);
      //               }
      //               this.setState(modifiedState);

      //             })
                  
      //             .catch(error => {
      //               console.log(error);
      //               // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
      //               modifiedState.errors.others = t(IDS_RegistNetworkError);
      //               this.setState(modifiedState); 
      //             })

      //           }

      //       } else {
              
      //         if(response.data.code == "SUCCESS") {
      //           // modifiedState.successfulEnrolledMsg = "Field Engineer Enrolled Successfully."
      //           modifiedState.successfulEnrolledMsg = t(IDS_FEEnrollSuccess)
      //           modifiedState.errors.others = "";
      //           modifiedState.FieldEngineerEmailID = "";
      //           modifiedState.Privilege = "";
      //           modifiedState.confirmemail = "";
      //         } else {
      //           if (response.data.code == 'SQL_ERROR') {t(IDS_LoginServerIssue);
      //             // Tell the user that Server is experiencing errors
      //             // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
      //             modifiedState.errors.others = t(IDS_LoginServerIssue);
      //           } else {
      //             console.log("Should not reach here.")
      //             // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
      //             modifiedState.errors.others = t(IDS_LoginServerIssue);
      //           }
      //           // // Display the specific error message for login failure
      //           // this.setState(modifiedState);
      //         }

      //       }
      //       this.setState(modifiedState);
      //     })
      //     .catch(error => {
      //       console.log(error);
      //       // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
      //       modifiedState.errors.others = t(IDS_RegistNetworkError);
      //       this.setState(modifiedState);  
      //     });
      //   }

      // }

      onSubmit = (event) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        event.preventDefault();

        let modifiedState = this.state;
        modifiedState.successfulEnrolledMsg = '';

        if(!validEmailRegex.test(this.state.FieldEngineerEmailID)) {
          modifiedState.errors.FieldEngineerEmailID = t(IDS_LoginEmailError);
          this.setState(modifiedState);
        } else if(modifiedState.FieldEngineerEmailID != modifiedState.confirmemail) {  
          modifiedState.errors.confirmemail = t(IDS_FEEmailNotMatch);
          this.setState(modifiedState);
        } else if(modifiedState.Privilege == "") {
          modifiedState.errors.others = t(IDS_FESelectPrivilege);
          this.setState(modifiedState);
        } else {

          // Convert UserID to lowercase and remove all whiteSpaces before sending to Server as it makes comparison easy and valid.
          let UserID = modifiedState.FieldEngineerEmailID.trim();
          UserID = UserID.toLowerCase();

          const allData = {
            FieldEngineerEmailID: UserID, 
            Privilege: modifiedState.Privilege
          };
    
          axios.post(`${getAPIHostURL()}/wclient/EnrollFieldEngineer`, allData)
          .then(response => {
              if(response.data.code == "SUCCESS") {
                alert("Field Engineer Enrolled Successfully.");
                modifiedState.errors.others = "";
                modifiedState.FieldEngineerEmailID = "";
                modifiedState.Privilege = "";
                modifiedState.confirmemail = "";
              } else { 
                if (response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                  // Tell the user that Server is experiencing errors
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                  console.log("Should not reach here.")
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
              }
            this.setState(modifiedState);
          })
          .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
              console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
              modifiedState.errors.others = t(IDS_RegistNetworkError);
              this.setState(modifiedState);
            }  
          });
        }
      }

      handleChange = (event) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        let modifiedState = this.state;
        modifiedState.errors.confirmemail = "";
      
        switch (name) {
          case 'FieldEngineerEmailID': 
            errors.FieldEngineerEmailID = 
            value.length > 0 ?
            (
                (validEmailRegex.test(value)) ? 
                '' :  
                t(IDS_LoginEmailError)
                // : 'Email is not valid!';
            )
            : '';
            break;
            
          default:
            break;
        }
      
        this.setState({
          errors, 
          [name]: value,
          [event.target.name]:event.target.value,
          }, ()=> {
            // console.log(errors)
            // console.log(this.state.confirmPassword)
        })
      }
    
      toggle = () => {
        let modifiedState = this.state;
    
        modifiedState.modal = !this.state.modal;
        this.setState(modifiedState);
      }

      onChangeSelectePrivilege = (e) => {

        let modifiedState = this.state;
        modifiedState.Privilege = e.target.value
        this.setState(modifiedState);
      }
    
    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        const{errors, successfulEnrolledMsg, successfulEnrolledUpdateMsg}=this.state;

        const color = {
            color : "var(--errorColor)",
            fontSize:"13px"
        }
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-6 col-lg-offset-3
                                              col-md-8 col-md-offset-2">
                        <div className="modal-body p-4 box">
                            <form onSubmit={this.onSubmit} >
                                <div className="form-group reg-form">
                                        <div className="inputgroupCustom" style={{paddingTop:"1rem"}}>
                                            <label className="reg-form-label">{t(IDS_EmailID)}:</label>
                                            <input type='email' name='FieldEngineerEmailID' className=" input-form" required="required" 
                                            value={this.state.FieldEngineerEmailID}
                                            onChange={this.handleChange} noValidate />
                                            {errors.FieldEngineerEmailID.length > 0 && 
                                            <span style={color} className='error'>{errors.FieldEngineerEmailID}</span>}        
                                        </div>
                                </div>
                                <div className="form-group reg-form">
                                        <div className="inputgroupCustom">
                                            {/* <label className="reg-form-label">Re-enter EmailID:</label> */}
                                            <label className="reg-form-label">{t(IDS_FEReEnterEmail)}</label>
                                            <input type='email' name='confirmemail' className=" input-form" required="required" 
                                            value={this.state.confirmemail}
                                            onChange={this.handleChange} noValidate />
                                                {errors.confirmemail.length > 0 && 
                                                <span style={color} className='error'>{errors.confirmemail}</span>}        
                                        </div>    
                                </div>
                                <div >
                                    <select  className="input-form" style={{height:"2.5rem"}}
                                            value={this.state.Privilege}
                                            onChange={this.onChangeSelectePrivilege}>
                                                {/* <option value="" disabled select= "true">Select Privilege...</option>
                                                <option value="MapToilet">Map Toilets</option>
                                                <option value="MapToiletsAndDevice">Map Device To Toilets</option> */}
                                                <option value="" disabled select= "true">{t(IDS_SelectPrivilege)}</option>
                                              <option value="MapToilet">{t(IDS_FEMapToilets)}</option>
                                              <option value="MapToiletsAndDevice">{t(IDS_FEMapDeviceToToilets)}</option>
                                    </select>
                                </div>
                                <div className="form-group reg-form" style={{marginTop:"1.5rem"}}>
                                    {/* <button type="submit" className="btn-lg reg-btn">Save</button> */}
                                          <button type="submit" className="btn-lg reg-btn">{t(IDS_Save)}</button>
                                    <div className = "buttonErrorMessage">
                                        {errors.others.length > 0 && 
                                            <p style={color} className='error'>{errors.others}</p>}
                                        {successfulEnrolledMsg.length > 0 &&
                                            <p style={{color:'green', fontSize:"13px"}} className='error'>{successfulEnrolledMsg}</p>} 
                                    </div>
                                </div>
                            </form>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

// export default VcAdminEnrollFieldEngineer;

VcAdminEnrollFieldEngineer.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcAdminEnrollFieldEngineer;
                               