import React, { Component } from 'react'
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext } from '../../AppContext';
import { convertUTCDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS,convertLocalDateWithFormatDDMMMYY} from '../../vtUtil';
import { IDS_RDEndDtGrtCurDt, IDS_RDEndDTAdjust, IDS_RDStartDTAdjust, IDS_RDStartDTSetAcrodEndDT, IDS_RDStartDTAdjustMinPossible,
        IDS_RDEndDTNotInRng, IDS_AUSrvrIssueReqParamsNotSentForSubsInfo ,IDS_LoginServerIssue} from '../../VcLanguage';
import { CSV_COLUMN_SEPERATOR, CSV_LINE_SEPERATOR, MAX_DAYS_RANGE, NH3OD, HUM, TEMP, VOC } from '../../VcConstants';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import { FaQrcode } from 'react-icons/fa';
// import {QrReader} from '@otterscan/react-qr-reader';
import {QrScanner} from '@yudiel/react-qr-scanner';
import '../CSS/VcRawDataAnalysis.css'

export class VcRawDataAnalysis extends Component {
    constructor(props) {
        super(props);
        this.searchStringStyle = {fontSize: "0.8rem", width:"85%"};
        this.csvLink = React.createRef();
        this.textFile = null;
        this.state = { 
            activeTab: '1',
            StartDateTime: new Date(new Date().getFullYear() - (new Date().getMonth() > 0 ? 0 : 1), (new Date().getMonth() - 1 + 12) % 12, 1),
            EndDateTime: new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23,59,59),
            csvData : [],
            backdrop: 'static',
            EnterDeviceID: "",
            DevcIDQRCodeFlag: false,
            DeviceIDForCsvFileName: "",
            searchedDevicesArr: [],
            selectedDevices: [],
            searchedUserEmailId: "",
            searchedDeviceName: "",
            searchedDeviceId: "",
            bGetSeparateFileOption: true,
            RawDataForAnalysis: [],
            csvFileLinks: [],
            isOdourSelected: true,
            isTempSelected: false,
            isHumSelected: false,
            isTvocSelected: false,
            selectedParamArr: [NH3OD],
            errors: {
                others: "",
                emptyValueError: "",
                noDataFoundErr: "",
                noParamSelectedErr: "",
                noDeviceFoundErr: "",
                timeRelatedErrors: ""
            },
        }
    }

    onChangeStartDateTime = (updatedTime) => {

        let modifiedState = this.state;
        modifiedState.errors.others = '';

        let updatedActiveStartDateTime = updatedTime;

        modifiedState.StartDateTime = updatedActiveStartDateTime;

        modifiedState.errors.noDataFoundErr = "";
        this.setState(modifiedState);
    }

    onChangeEndDateTime = (updatedTime) => {
        
        let modifiedState = this.state; 
        modifiedState.errors.others = "";

        let updatedEndDateTime = updatedTime;

        modifiedState.EndDateTime = updatedEndDateTime;
        modifiedState.errors.noDataFoundErr = "";

        this.setState(modifiedState);
    }

    onStartDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;    
        let strMsg = '';

        this.state.errors.noDataFoundErr = "";

        this.setState( 
            prevState => {

                let currentlySetEndDateTime = prevState.EndDateTime;

                // Incase time is greater than currentdatetime.
                let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
                minRequiredStartDateTime.setHours(0);
                minRequiredStartDateTime.setMinutes(0);
                minRequiredStartDateTime.setSeconds(0);
                
                let currentlySetStartDateTime = prevState.StartDateTime;

                // Incase of startDateTime greater than EndDateTime. Set EndDateTime According to StartDateTime.
                let minRequiredEndDateTime = new Date(currentlySetStartDateTime.valueOf());
                minRequiredEndDateTime.setHours(23);
                minRequiredEndDateTime.setMinutes(59);
                minRequiredEndDateTime.setSeconds(59);

                // required to compare Range between Start and End Date Time.
                let minRequiredDateRange = new Date(currentlySetEndDateTime.valueOf());
                minRequiredDateRange.setDate(minRequiredDateRange.getDate() - MAX_DAYS_RANGE);

                let modifiedState = prevState;

                let currentDateTime = new Date();

                // Give a tolerance of -1 minutes to the current date time to avoid unnecessary messages
                // currentDateTime.setMinutes(currentDateTime.getMinutes() - 1)                

                let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
                let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );
                
                if(currentlySetStartDateTime != null && currentlySetStartDateTime >= currentDateTime) {

                    // strMsg = `Start Date Time cannot be greater than the current date time. It will be set according to End date time.`;
                    strMsg = t(IDS_RDStartDTSetAcrodEndDT);
                    
                    modifiedState.errors.timeRelatedErrors = strMsg;
                    modifiedState.StartDateTime = minRequiredStartDateTime;

                } else if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {

                    // strMsg = `Start Date Time will be adjusted because it is not within a Range. The time will be adjusted to minimum possible for the day.`;
                    strMsg = t(IDS_RDStartDTAdjustMinPossible);
                    
                    modifiedState.errors.timeRelatedErrors = strMsg;

                    modifiedState.StartDateTime = new Date(currentlySetEndDateTime.valueOf());
                    modifiedState.StartDateTime.setHours(0);
                    modifiedState.StartDateTime.setMinutes(0);
                    modifiedState.StartDateTime.setSeconds(0);

                } else if(currentlySetStartDateTime >= currentlySetEndDateTime) {

                    // strMsg = `End Date Time has been adjusted because it is not within a Range.`;
                    strMsg = t(IDS_RDEndDTNotInRng);
                    
                    modifiedState.errors.timeRelatedErrors = strMsg;
                    modifiedState.EndDateTime = minRequiredEndDateTime;

                    if(modifiedState.EndDateTime >= currentDateTime) {
                        modifiedState.EndDateTime = new Date();
                    }

                } else {
                    strMsg = "";
                    modifiedState.errors.timeRelatedErrors = strMsg;
                }

                return modifiedState;
            },

        );
    }

    onEndDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;  
        let strMsg ="";

        this.state.errors.noDataFoundErr = "";


        this.setState( 
            prevState => {

                let currentlySetEndDateTime = prevState.EndDateTime;

                let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
                minRequiredStartDateTime.setHours(0);
                minRequiredStartDateTime.setMinutes(0);
                minRequiredStartDateTime.setSeconds(0);

                let currentlySetStartDateTime = prevState.StartDateTime;

                let minRequiredDateRange = new Date(currentlySetStartDateTime.valueOf());
                minRequiredDateRange.setDate(minRequiredDateRange.getDate() + 31);

                let currentDateTime = new Date();

                let modifiedState = prevState;

                if(currentlySetEndDateTime > currentDateTime) {
                    // strMsg = 'End Date Time cannot be greater than the current date time. It will be set to current date time.';
                    strMsg = t(IDS_RDEndDtGrtCurDt);

                    modifiedState.errors.timeRelatedErrors = strMsg;
                    modifiedState.EndDateTime = currentDateTime
                } else if(currentlySetEndDateTime <= currentlySetStartDateTime) {

                    let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
                    let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );

                    if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {
                        // strMsg = `End Date Time will be adjusted because it is not within a Range. The time will be adjusted to max possible for the day.`;
                        strMsg = t(IDS_RDEndDTAdjust);
                        
                        modifiedState.errors.timeRelatedErrors = strMsg;
                        modifiedState.EndDateTime = new Date(currentlySetStartDateTime.valueOf());
                        modifiedState.EndDateTime.setHours(23);
                        modifiedState.EndDateTime.setMinutes(59);
                        modifiedState.EndDateTime.setSeconds(59);
                    } else {

                        // strMsg = `Start Date Time will be adjusted because it is not within a Range. It will be set according to End Date Time.`;
                        strMsg = t(IDS_RDStartDTAdjust);
                        
                        modifiedState.errors.timeRelatedErrors = strMsg;

                        modifiedState.StartDateTime = minRequiredStartDateTime
                    }
                    
                    if(modifiedState.EndDateTime > currentDateTime) {
                        modifiedState.errors.timeRelatedErrors = strMsg;

                        modifiedState.EndDateTime = currentDateTime
                    }

                } else {
                    strMsg = "";
                    modifiedState.errors.timeRelatedErrors = strMsg;
                }

                return modifiedState;
            },
        );
    }

    OnClickDownloadAsCsvButton = () => {
        let modifiedState = this.state;
        this.getRawDataForSelectedDateAndDevices(modifiedState)
    }

    getRawDataForSelectedDateAndDevices = (inModifiedState = null) => {

        let modifiedState;
        if(inModifiedState == null ) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        if(
            // modifiedState.errors.emptyValueError.length > 0 || 
            modifiedState.errors.noDataFoundErr.length > 0 || 
            modifiedState.errors.noParamSelectedErr.length > 0 ||
            // modifiedState.errors.noDeviceFoundErr.length > 0 || 
            modifiedState.errors.others.length > 0 
            ){
            return;
        }

        let StartDateTime = modifiedState.StartDateTime;
        let EndDateTime = modifiedState.EndDateTime;
        let strStartDateTime = null;
        let strEndDateTime = null;

        strStartDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(StartDateTime) );
        strEndDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(EndDateTime) );

        let selectedDeviceIDs = [];

        // store IDs of all selected devices into an array
        for(let i=0; i<modifiedState.selectedDevices.length; i++){
            let deviceID = modifiedState.selectedDevices[i].split(" ")[0];
            selectedDeviceIDs.push(deviceID);
        }

        const json = {
            DeviceList: selectedDeviceIDs,
            StartDateTime : strStartDateTime,
            EndDateTime : strEndDateTime,
            bGetSeparateFiles: modifiedState.bGetSeparateFileOption,
            selectedParamArr: modifiedState.selectedParamArr
        }

        axios.post(`${getAPIHostURL()}/wclient/getRawDataForAnalysis`, json)    
        .then( response => {
            if(response.data.code == 'SUCCESS') {
                modifiedState.RawDataForAnalysis = [];
                let receivedRawDataForAnalysis = response.data.rawDataForAnalysis;

                if( receivedRawDataForAnalysis == null 
                    // || receivedRawDataForAnalysis.length <= 0
                    ) {
                    modifiedState.errors.noDataFoundErr = "Selected Time Period does not contain any device Data.";
                } else {

                    let noDataFields = 0;
                    // to check if arrays inside receivedRawDataForAnalysis are empty/dont have data
                    for(let i = 0; i < receivedRawDataForAnalysis.length; i++){
                        if(receivedRawDataForAnalysis[i].length <= 0){
                            noDataFields += 1;
                        }
                    }

                    if(noDataFields == receivedRawDataForAnalysis.length){
                        modifiedState.errors.noDataFoundErr = "Selected Time Period does not contain any device Data.";
                        this.setState(modifiedState);
                        return;
                    }

                    if(modifiedState.bGetSeparateFileOption == true){
                        for(let i = 0; i < receivedRawDataForAnalysis.length; i++){
                            modifiedState.RawDataForAnalysis = [];
                            let deviceData = receivedRawDataForAnalysis[i];
                            if(deviceData.length > 0){
                                for(let k = 0; k < deviceData.length; k++) {
                                    let singleInstanceInfo = {
                                        DeviceID: deviceData[k]["DeviceID"] == null ? '-': deviceData[k]["DeviceID"],
                                        DeviceName: deviceData[k]["currentName"] == null ? '-': deviceData[k]["currentName"],
                                        TimeInHour: deviceData[k]["TimeInHour"] == null ? '-': deviceData[k]["TimeInHour"],
                                        OdourLevel: deviceData[k]["OdourLevel"] == null ? '': deviceData[k]["OdourLevel"],
                                        Temp: deviceData[k]["TEMP"] == null ? '': deviceData[k]["Temp"],
                                        HUM: deviceData[k]["HUM"] == null ? '': deviceData[k]["HUM"],
                                        VOC: deviceData[k]["VOC"] == null ? '': deviceData[k]["VOC"],
                                        LogTime: deviceData[k]["LogTime"] == null ? '-': convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(deviceData[k]["LogTime"]),
                                    }
                                    modifiedState.RawDataForAnalysis.push(singleInstanceInfo);
                                }
                                this.DownloadCsv(modifiedState.RawDataForAnalysis);
                            }

                        }

                    } else{

                        for(let i = 0; i < receivedRawDataForAnalysis.length; i++) {

                            let singleInstanceInfo = {
                                DeviceID: receivedRawDataForAnalysis[i]["DeviceID"] == null ? '-': receivedRawDataForAnalysis[i]["DeviceID"],
                                DeviceName: receivedRawDataForAnalysis[i]["currentName"] == null ? '-': receivedRawDataForAnalysis[i]["currentName"],
                                TimeInHour: receivedRawDataForAnalysis[i]["TimeInHour"] == null ? '-': receivedRawDataForAnalysis[i]["TimeInHour"],
                                OdourLevel: receivedRawDataForAnalysis[i]["OdourLevel"] == null ? '': receivedRawDataForAnalysis[i]["OdourLevel"],
                                TEMP: receivedRawDataForAnalysis[i]["TEMP"] == null ? '': receivedRawDataForAnalysis[i]["TEMP"],
                                HUM: receivedRawDataForAnalysis[i]["HUM"] == null ? '': receivedRawDataForAnalysis[i]["HUM"],
                                VOC: receivedRawDataForAnalysis[i]["VOC"] == null ? '': receivedRawDataForAnalysis[i]["VOC"],
                                LogTime: receivedRawDataForAnalysis[i]["LogTime"] == null ? '-': convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedRawDataForAnalysis[i]["LogTime"]),
                            }
                            
                            modifiedState.RawDataForAnalysis.push(singleInstanceInfo);
                        }
                        this.DownloadCsv(modifiedState.RawDataForAnalysis);
                    }

                    modifiedState.errors.others = "";
                    modifiedState.errors.timeRelatedErrors = "";
                    modifiedState.errors.noDataFoundErr = "";
                    modifiedState.errors.noDeviceFoundErr = "";
                }
            } else {

                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                    
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);

        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        });
    }

    DownloadCsv = (inCsvData) => {      

        let modifiedState = this.state;
        let arrColumnKeys = [ "DeviceID", "DeviceName", "TimeInHour", "LogTime"];

        let strCsvHeaderLine = "";
        let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
        let strSingleCsvDataLine = "";
        let strColumnName = "";
        modifiedState.csvData = [];

        // Create rest of the columns in the header
        let noOfParams = arrColumnKeys.length;
        for (let j=0; j<noOfParams; j++) {
            strColumnName = arrColumnKeys[j];
            if(strColumnName == "TimeInHour"){
                strCsvHeaderLine += ( strColumnName + CSV_COLUMN_SEPERATOR);

                if(modifiedState.selectedParamArr.includes('NH3OD')){
                    strCsvHeaderLine += ("OdourLevel" + CSV_COLUMN_SEPERATOR);
                } 
                if(modifiedState.selectedParamArr.includes('TEMP')){
                    strCsvHeaderLine += ("TEMP" + CSV_COLUMN_SEPERATOR);
                } 
                if(modifiedState.selectedParamArr.includes('HUM')){
                    strCsvHeaderLine += ("HUM" + CSV_COLUMN_SEPERATOR);
                } 
                if(modifiedState.selectedParamArr.includes('VOC')){
                    strCsvHeaderLine += ("VOC" + CSV_COLUMN_SEPERATOR);
                } 
            } else{
                strCsvHeaderLine += ( strColumnName + CSV_COLUMN_SEPERATOR);
            } 
        }

        let receivedRawDataForAnalysis = inCsvData;
        let receivedRawDataForAnalysisLen = inCsvData.length;
        let allInstanceInfo = [];
        let deviceNameForFileName = "";
        let bFileCreationStatus = "";

        // First push the header CSV line
        arrCsvAllLines.push(strCsvHeaderLine);

        if(receivedRawDataForAnalysisLen > 0){
            for(let i = 0; i < receivedRawDataForAnalysisLen; i++) {

                let logTimeDate = convertLocalDateWithFormatDDMMMYY(receivedRawDataForAnalysis[i]["LogTime"]);
                let logTime = logTimeDate + " " + ("0" + new Date(receivedRawDataForAnalysis[i]["LogTime"]).getHours()).slice(-2) + ":00:00";

                deviceNameForFileName = receivedRawDataForAnalysis[i]["DeviceName"];
                let singleInstanceInfo = {
                    DeviceID: receivedRawDataForAnalysis[i]["DeviceID"],
                    DeviceName: receivedRawDataForAnalysis[i]["DeviceName"],
                    TimeInHour: receivedRawDataForAnalysis[i]["TimeInHour"],
                    OdourLevel: receivedRawDataForAnalysis[i]["OdourLevel"],
                    TEMP: receivedRawDataForAnalysis[i]["Temp"],
                    HUM: receivedRawDataForAnalysis[i]["HUM"],
                    VOC: receivedRawDataForAnalysis[i]["Temp"],
                    LogTime: logTime,
                }
                
                allInstanceInfo.push(singleInstanceInfo);

                strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                    ((receivedRawDataForAnalysis[i]["DeviceID"] == null) ? '-' : receivedRawDataForAnalysis[i]["DeviceID"] ) +
                    CSV_COLUMN_SEPERATOR +
                    ( (receivedRawDataForAnalysis[i]["DeviceName"] == null) ? '-' : receivedRawDataForAnalysis[i]["DeviceName"] ) +
                    CSV_COLUMN_SEPERATOR +
                    ( (receivedRawDataForAnalysis[i]["TimeInHour"] == null) ? '-' : receivedRawDataForAnalysis[i]["TimeInHour"] );

                if(modifiedState.selectedParamArr.includes('NH3OD')){
                    strSingleCsvDataLine += CSV_COLUMN_SEPERATOR + ( (receivedRawDataForAnalysis[i]["OdourLevel"] == null) ? '' : receivedRawDataForAnalysis[i]["OdourLevel"] );
                } 
                if(modifiedState.selectedParamArr.includes('TEMP')){
                    strSingleCsvDataLine += CSV_COLUMN_SEPERATOR + ( (receivedRawDataForAnalysis[i]["TEMP"] == null) ? '' : receivedRawDataForAnalysis[i]["TEMP"] );
                } 
                if(modifiedState.selectedParamArr.includes('HUM')){
                    strSingleCsvDataLine += CSV_COLUMN_SEPERATOR + ( (receivedRawDataForAnalysis[i]["HUM"] == null) ? '' : receivedRawDataForAnalysis[i]["HUM"] );
                } 
                if(modifiedState.selectedParamArr.includes('VOC')){
                    strSingleCsvDataLine += CSV_COLUMN_SEPERATOR + ( (receivedRawDataForAnalysis[i]["VOC"] == null) ? '' : receivedRawDataForAnalysis[i]["VOC"] );
                }                     

                strSingleCsvDataLine += CSV_COLUMN_SEPERATOR +
                    ( (receivedRawDataForAnalysis[i]["LogTime"] == null) ? '-' : logTime );

                arrCsvAllLines.push(strSingleCsvDataLine);
                modifiedState.csvData = allInstanceInfo;
            }
            
            // Create CSV File and fill it
            bFileCreationStatus = this.createAndFillCsvFile(arrCsvAllLines, deviceNameForFileName);

            if (bFileCreationStatus) {
                // CSV file creation successful.
                // Close the date selection modal.

            } else {
                modifiedState.errors.noDataFoundErr = 'Downloaded data could not be saved to file/file size is greater than 1GB.';
            }
        }

        this.setState(modifiedState);
    } 

    createAndFillCsvFile = (inarrCsvAllLines, deviceNameForFileName) => {

        let retVal = true;

        let foramttedStartDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(this.state.StartDateTime);
        let foramttedEndDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(this.state.EndDateTime);
        let fileName = deviceNameForFileName + "_" + "RawDataForAnalysis " + foramttedStartDateTime + " To " + foramttedEndDateTime + ".csv";

        let link = document.createElement('a');
        link.setAttribute('download', fileName);

        let linkRef = this.makeTextFile(inarrCsvAllLines);

        if(linkRef == null){
            retVal = false;

        } else{

            link.href = linkRef;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            retVal = true;
        }

        return retVal;
    }

    makeTextFile = (arrText) =>  {

        let data = new Blob(arrText, {type: 'text/plain'});

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (this.textFile !== null) {
            window.URL.revokeObjectURL(this.textFile);
        }

        // Limit size of downloading file to 1GB ~ 1000000000 Bytes
        // data.size gives us file size in bytes
        // We are restricting file downloads which are of size 1GB or exceeding 1GB
        if(data.size >= 1000000000){
            alert(`File size larger than 1GB cannot be downloaded. Please give different data inputs.`);
            this.textFile = null;

        } else{
            this.textFile = window.URL.createObjectURL(data);
        }

        // Return a URL you can use as an href
        return this.textFile;
    }

    onEnterDeviceID = (e) => {
        let modifiedState = this.state;
        modifiedState.searchedDevicesArr = [];

        modifiedState.searchedDeviceId = e.target.value;
        modifiedState.searchedDeviceId = modifiedState.searchedDeviceId.toUpperCase();
        
        modifiedState.errors.others = "";
        modifiedState.errors.emptyValueError = modifiedState.searchedDeviceId.length >= 4 || modifiedState.searchedDeviceId.length == 0 ? "" : "Please enter atleast 4 characters";
        modifiedState.errors.noDataFoundErr = "";
        modifiedState.errors.noDeviceFoundErr = "";
        this.setState(modifiedState);  
    }

    onEnterUserEmailID = (e) => {
        let modifiedState = this.state;

        modifiedState.searchedDevicesArr = [];

        modifiedState.searchedUserEmailId = e.target.value;

        modifiedState.errors.others = '';
        modifiedState.errors.emptyValueError = modifiedState.searchedUserEmailId.length >= 4 || modifiedState.searchedUserEmailId.length == 0 ? "" : "Please enter atleast 4 characters";
        modifiedState.errors.noDataFoundErr = "";
        modifiedState.errors.noDeviceFoundErr = "";
        this.setState(modifiedState);  
    }

    onEnterDeviceName = (e) => {
        let modifiedState = this.state;
        modifiedState.searchedDevicesArr = [];

        modifiedState.searchedDeviceName = e.target.value;

        modifiedState.errors.others = '';
        modifiedState.errors.emptyValueError = modifiedState.searchedDeviceName.length >= 4 || modifiedState.searchedDeviceName.length == 0 ? "" : "Please enter atleast 4 characters";
        modifiedState.errors.noDataFoundErr = "";
        modifiedState.errors.noDeviceFoundErr = "";
        this.setState(modifiedState);  
    }

    handleKeyDown = (e) => {
       
        if (e.key == 'Enter') {
            // This is just to prevent form submission on pressing "enter".
            e.preventDefault();
            return;
        }
    }

    openQRCameraForGetDevc = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 

        this.setState(prevState => {
            let modifiedState = prevState;
            modifiedState.DevcIDQRCodeFlag = !modifiedState.DevcIDQRCodeFlag;
            modifiedState.errors.emptyValueError = "";
            return modifiedState
        })
    }

    handleScanResultOfDevc = (data) => {

        if(data) {
            let modifiedState = this.state;
            modifiedState.searchedDeviceId = data;

            if((modifiedState.searchedDeviceId != null && modifiedState.searchedDeviceId.length > 0)) {
                modifiedState.DevcIDQRCodeFlag = false;
            } else {
                modifiedState.errors.emptyValueError = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
            }
            this.setState(modifiedState);
        }   
    }

    handleError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    // To toggle between Notification and Range Setting
    toggleTab(tab) {

        let modifiedState = this.state;
  
        if (modifiedState.activeTab != tab) {
          modifiedState.activeTab = tab;
          modifiedState.searchedDevicesArr = [];
          modifiedState.searchedDeviceId = "";
          modifiedState.searchedDeviceName = "";
          modifiedState.searchedUserEmailId = "";
          modifiedState.DevcIDQRCodeFlag = false;
          modifiedState.errors.others = "";
          modifiedState.errors.emptyValueError = "";
          modifiedState.errors.noDataFoundErr = "";
          modifiedState.errors.noDeviceFoundErr = "";
  
          this.setState(modifiedState);
        }
    }

    handleChange = (e) => {
        let modifiedState = this.state;

        let target = e.target
        let selectedDeviceArr = target.selectedOptions;

        for(let i = 0; i < selectedDeviceArr.length; i++){
            if (!modifiedState.selectedDevices.includes(selectedDeviceArr[i].value)){
                modifiedState.selectedDevices.push(selectedDeviceArr[i].value);
            }
        }

        this.setState(modifiedState)
    }

    OnClickGetDevicesBtn = () => {
        let modifiedState = this.state;

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        if(modifiedState.errors.emptyValueError.length > 0 || modifiedState.errors.noDataFoundErr.length > 0 ||
            modifiedState.errors.noDeviceFoundErr.length > 0 || modifiedState.errors.others > 0){
            return;
        }

        if((modifiedState.activeTab == '1' && modifiedState.searchedDeviceName.length <= 0 && modifiedState.searchedUserEmailId <= 0)
            || (modifiedState.activeTab == '2' && modifiedState.searchedDeviceId.length <= 0 )
            ){
            modifiedState.errors.emptyValueError = modifiedState.activeTab == '1' && modifiedState.searchedDeviceName.length <= 0 && modifiedState.searchedUserEmailId <= 0
                                    ? 'Please enter EmailID/DeviceName' 
                                    : modifiedState.activeTab == '2' && modifiedState.searchedDeviceId.length <= 0 
                                    ? 'Please scan or enter Device ID'
                                    : '';
            this.setState(modifiedState);
            return;
        }

        let jsonParams = {
            searchedDeviceName: modifiedState.searchedDeviceName,
            searchedUserEmailId: modifiedState.searchedUserEmailId,
            searchedDeviceId: modifiedState.searchedDeviceId,
          }

        axios.post(`${getAPIHostURL()}/wclient/getSearchedDeviceListForRawDataAnalysis`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

              if(response.data.SearchedDeviceList == null || response.data.SearchedDeviceList.length <= 0) {
                modifiedState.errors.noDeviceFoundErr = "No device found for given input";
                modifiedState.searchedDevicesArr = [];

              } else {

                modifiedState.searchedDevicesArr = [];

                let recievedSearchedDeviceList = response.data.SearchedDeviceList;

                for(let i = 0; i<recievedSearchedDeviceList.length; i++){
                    let device = recievedSearchedDeviceList[i]["DeviceID"] + " - " + recievedSearchedDeviceList[i]["DeviceName"];
                    modifiedState.searchedDevicesArr.push(device);
                }
              }

            } else {
              if(response.data.code == 'REQ_PARAMS_MISSING') {
                // Let the user know that the Required parameters were not sent to the Server
                modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSentForSubsInfo);
              } else if(response.data.code == 'SQL_ERROR') {
                  // Tell the user that Server is experiencing errors
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
    
                } else {
                  console.log('Should not reach here');
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
              }
              // Display the specific error message for login failure.
            }
            this.setState(modifiedState);
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                // modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
            }
        });

        this.setState(modifiedState);

    }

    onClickToRemoveSelectedDvc = (e) => {

        let modifiedState = this.state;

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        let indexOfRemovedElement = e.currentTarget.value;
        let removedElement = modifiedState.selectedDevices[indexOfRemovedElement];

        // remove device from selected device list
        modifiedState.selectedDevices.splice(indexOfRemovedElement, 1);

        if(modifiedState.selectedDevices.length <= 0){
            modifiedState.errors.noDataFoundErr = "";
            modifiedState.errors.noParamSelectedErr = "";
            modifiedState.selectedParamArr = [NH3OD];
            modifiedState.isHumSelected = false;
            modifiedState.isTempSelected = false;
            modifiedState.isTvocSelected  = false;
            modifiedState.isOdourSelected = true;
            // modifiedState.bGetSeparateFileOption = false;
        }

        let elements = document.getElementById("deviceList").options;

        // unselect device from list of devices
        for(let i = 0; i<elements.length; i++){
            if(elements[i].innerHTML == removedElement){
                elements[i].selected = false;
            }
        }

        

        this.setState(modifiedState);
    }

    // onGetSeparateFileOptionChange = () =>{
    //     let modifiedState = this.state;
    //     modifiedState.bGetSeparateFileOption = !modifiedState.bGetSeparateFileOption;

    //     this.setState(modifiedState);
    // }

    handleParamterSelection = (e) =>{
        let modifiedState = this.state;

        let param = e.target.value;

        switch(param) {
            case NH3OD:
                modifiedState.isOdourSelected = !modifiedState.isOdourSelected;

                if(modifiedState.isOdourSelected == true && !modifiedState.selectedParamArr.includes(NH3OD)) {
                    modifiedState.selectedParamArr.push(NH3OD);
                }
                if(modifiedState.isOdourSelected == false && modifiedState.selectedParamArr.includes(NH3OD)) {
                    modifiedState.selectedParamArr.splice(modifiedState.selectedParamArr.indexOf(NH3OD), 1);
                }
                break;
            case TEMP:
                modifiedState.isTempSelected = !modifiedState.isTempSelected;

                if(modifiedState.isTempSelected == true && !modifiedState.selectedParamArr.includes(TEMP)) {
                    modifiedState.selectedParamArr.push(TEMP);
                }
                if(modifiedState.isTempSelected == false && modifiedState.selectedParamArr.includes(TEMP)) {
                    modifiedState.selectedParamArr.splice(modifiedState.selectedParamArr.indexOf(TEMP), 1);
                }
                
                break;
            case HUM:
                modifiedState.isHumSelected = !modifiedState.isHumSelected;

                if(modifiedState.isHumSelected == true && !modifiedState.selectedParamArr.includes(HUM)) {
                    modifiedState.selectedParamArr.push(HUM);
                }
                if(modifiedState.isHumSelected == false && modifiedState.selectedParamArr.includes(HUM)) {
                    modifiedState.selectedParamArr.splice(modifiedState.selectedParamArr.indexOf(HUM), 1);

                }  
                              
                break;
            case VOC:
                modifiedState.isTvocSelected = !modifiedState.isTvocSelected;

                if(modifiedState.isTvocSelected == true && !modifiedState.selectedParamArr.includes(VOC)) {
                    modifiedState.selectedParamArr.push(VOC);
                }
                if(modifiedState.isTvocSelected == false && modifiedState.selectedParamArr.includes(VOC)) {
                    modifiedState.selectedParamArr.splice(modifiedState.selectedParamArr.indexOf(VOC), 1);
                }
                
                break;
            default:
                break;
        }

        modifiedState.errors.noParamSelectedErr = modifiedState.selectedParamArr.length <= 0 
                                            ? "Please select at least one measured parameter."
                                            : "";
        this.setState(modifiedState);
    }

    render() { 
        return(
            <div>
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-md-8">
                        <div className="modal-body box" style={{padding: "1rem"}}>
                            <div className = "headingForComponentsOfCrmPage" 
                                style={{marginBottom: "0.8rem", display: "block", alignSelf: "center"
                                }}
                            >
                                Download Raw Data for Analysis
                            </div>
                            <label style={{ display: "flex", justifyContent:"flex-start", fontSize:"0.7rem", marginLeft:"1.5rem", marginBottom:"0rem"}}>
                                <b style={{marginRight:"0.1rem"}}>Note:
                                </b> User Ctrl/Command key to select/deselect devices from list of devices.
                            </label>
                            <div className="container col-md-12 col-lg-12">                               
                                <Nav tabs className="tabular">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => { this.toggleTab('1'); }}
                                        >
                                        <b>Search by Device Name</b>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggleTab('2'); }}
                                        >
                                        <b>Search by Device ID</b>
                                        </NavLink>
                                    </NavItem> 
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1" className="tabular2"></TabPane>
                                    {this.state.activeTab == '1' 
                                    ?
                                    <form >
                                        <div style={{paddingTop:"0.2rem"}}>
                                            <div className="inputgroupCustom col-lg-12 p-1">
                                                <div className="col-md-6 col-lg-6 p-1">
                                                    <label className="reg-form-label" style={{fontSize: "1rem"}}>Enter User Email ID:</label>
                                                    <input type='text' 
                                                        className="input-form"
                                                        required="required" 
                                                        value={this.state.searchedUserEmailId} 
                                                        onChange={this.onEnterUserEmailID}
                                                        onKeyDown={this.handleKeyDown}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-lg-6 p-1">
                                                    <label className="reg-form-label" style={{fontSize: "1rem"}}>Enter Device Name:</label>
                                                    <input type='text' 
                                                        className="input-form"
                                                        required="required" 
                                                        value={this.state.searchedDeviceName} 
                                                        onChange={this.onEnterDeviceName}
                                                        onKeyDown={this.handleKeyDown}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{display:"flex", justifyContent:"center", alignItems: "center"}}>
                                                <button type="button" 
                                                        className="adduserButton btn-sm"
                                                        style={{marginBottom: "0rem",width: "10rem",fontSize:"1rem", fontWeight: "bold"}}
                                                        onClick={this.OnClickGetDevicesBtn}> 
                                                        Get Devices
                                                </button>
                                            </div>
                                            {this.state.errors.noDeviceFoundErr.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center",marginBottom: "0rem"}} className='error'>{this.state.errors.noDeviceFoundErr}</p>
                                            }
                                            {this.state.errors.emptyValueError.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center",marginBottom: "0rem"}} className='error'>{this.state.errors.emptyValueError}</p>
                                            }
                                        </div>
                                    </form>
                                    :
                                    null} 
                                </TabContent>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="2" className="tabular2"></TabPane>
                                    {this.state.activeTab == '2' 
                                    ?
                                    <form >
                                        <div style={{paddingTop:"0.2rem"}}>
                                            <div className="inputgroupCustom col-lg-12 p-1">
                                                <div className="col-md-12 col-lg-12 p-1">
                                                    <label className="reg-form-label">Scan or Enter Device ID:</label>
                                                    <input type='text' 
                                                        className="input-form"
                                                        value={this.state.searchedDeviceId} 
                                                        onChange={this.onEnterDeviceID}
                                                        required
                                                    />
                                                    <label onClick= {this.openQRCameraForGetDevc} className = "qrcodeTextBtn">
                                                        <FaQrcode className = "qrcodeTextBtnIcon"/>
                                                    </label>
                                                    {(this.state.DevcIDQRCodeFlag == true)
                                                    ?
                                                        <div>
                                                            {/* <div style={{display: "flex", justifyContent: "center"}}>
                                                                <QrReader
                                                                    scanDelay={300}
                                                                    onResult={(result, error) => {
                                                                        if (!!result) {
                                                                            this.handleScanResultOfDevc(result?.text);
                                                                        }
                                                                    }}
                                                                    className = "QRCodeCamBoxForModalAndContainer"
                                                                />
                                                            </div> */}
                                                            <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                                    marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                                    marginRight: "auto"}}>                                                               
                                                                <QrScanner
                                                                    scanDelay={300}
                                                                    onResult={(result, error) => {
                                                                        if (!!result) {
                                                                            this.handleScanResultOfDevc(result?.text);
                                                                        }
                                                                    }}
                                                                    className = "QRCodeCamBoxForModalAndContainer"
                                                                />
                                                            </div>
                                                        </div>
                                                    :
                                                        <div/>
                                                    }
                                                </div>
                                            </div>
                                            <div style={{display:"flex", justifyContent:"center", alignItems: "center"}}>
                                                <button type="button" 
                                                        className="adduserButton btn-sm"
                                                        style={{marginBottom: "0rem",width: "10rem",fontSize:"1rem", fontWeight: "bold"}}
                                                        onClick={this.OnClickGetDevicesBtn}> 
                                                        Get Devices
                                                </button>
                                            </div>
                                            {this.state.errors.noDeviceFoundErr.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center",marginBottom: "0rem"}} className='error'>{this.state.errors.noDeviceFoundErr}</p>
                                            }
                                            {this.state.errors.emptyValueError.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center",marginBottom: "0rem"}} className='error'>{this.state.errors.emptyValueError}</p>
                                            }
                                        </div>
                                    </form>
                                    :
                                    null
                                    }
                                </TabContent>  
                            </div>
                            <div className="container col-lg-12">                               
                                <div className="inputgroupCustom col-lg-12 p-1 mb-1" >
                                    <div className="col-md-6 col-lg-6 p-1">
                                        <label className="reg-form-label" style={{fontSize: "1rem"}}>List of Devices:</label>
                                        <select className="input-form" multiple
                                            style={{height: "22vh"}}
                                            size= '5'
                                            required
                                            id="deviceList"
                                            onChange={this.handleChange}
                                            >
                                            {(this.state.searchedDevicesArr).map((device,index) => 
                                                <option key={index} value={device} style={{padding: "0.4rem", margin: "0.3rem",fontSize:"0.9rem", border: "1px solid lightgray", borderRadius:"0.5rem"}}
                                                >
                                                    {device}
                                                </option>)
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-lg-6 p-1">
                                        <label className="reg-form-label" style={{fontSize: "1rem"}}>Selected Devices:</label>
                                        <div className="input-form" style={{height: "22vh", overflow: "auto"}}>
                                        {(this.state.selectedDevices).map((option, index) =>
                                            <div className="task">
                                                <span id="taskname"> {option}
                                                </span>
                                                <button value={index} onClick={this.onClickToRemoveSelectedDvc}>
                                                    <i className="fa fa-trash fa-lg"></i>
                                                </button>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </div>
                                {this.state.selectedDevices.length > 0 &&
                                <div>
                                    <div className="mt-2" style={{marginBottom: "0.8rem", display:"flex", justifyContent:"space-evenly"}}>
                                        <div >
                                            <input type="checkbox" name='searchBy'
                                                // id = {NH3OD}
                                                value= {NH3OD}
                                                onChange={this.handleParamterSelection} 
                                                // defaultChecked={true}
                                                checked = {this.state.isOdourSelected}
                                            /> 
                                            <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Odour</span>
                                        </div>
                                        <div >
                                            <input type="checkbox" name='searchBy'
                                                value = {TEMP}
                                                // value= {this.state.isTempSelected}
                                                onChange={this.handleParamterSelection} 
                                                checked = {this.state.isTempSelected}

                                            /> 
                                            <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Temperature</span>
                                        </div>
                                        <div >
                                            <input type="checkbox" name='searchBy'
                                                value = {HUM}
                                                checked= {this.state.isHumSelected}
                                                onChange={this.handleParamterSelection} 
                                            /> 
                                            <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Humidity</span>
                                        </div>
                                        <div >
                                            <input type="checkbox" name='searchBy'
                                                value = {VOC}
                                                checked= {this.state.isTvocSelected}
                                                onChange={this.handleParamterSelection} 
                                            /> 
                                            <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>TVOC</span>
                                        </div>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"space-evenly", marginBottom:"0rem"}} className="startAndEnddate">
                                        <div className="form-group update-form">
                                            <div className="inputgroupCustom">
                                                <label className="adduser-form-label" style={{fontSize: "1rem"}}>Start Date Time:</label>
                                                <DateTimePicker
                                                    clearIcon={null}
                                                    onChange={this.onChangeStartDateTime}
                                                    onCalendarClose={this.onStartDateTimeCalendarOrClockClose}
                                                    onClockClose={this.onStartDateTimeCalendarOrClockClose}
                                                    className="input-form-datetime"
                                                    format={"yyyy/MM/dd HH:mm:ss"} 
                                                    value={this.state.StartDateTime}    
                                                    name="StartDateTime"
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group update-form">
                                            <div className="inputgroupCustom">
                                                <label className="adduser-form-label" style={{fontSize: "1rem"}}>End Date Time:</label>
                                                <DateTimePicker
                                                    clearIcon={null}
                                                    selected={this.state.EndDateTime}
                                                    onChange={this.onChangeEndDateTime}
                                                    onCalendarClose={this.onEndDateTimeCalendarOrClockClose}
                                                    onClockClose={this.onEndDateTimeCalendarOrClockClose}
                                                    className="input-form-datetime"
                                                    format={"yyyy/MM/dd HH:mm:ss"} 
                                                    value={this.state.EndDateTime} 
                                                    name="EndDateTime"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        // className="mt-3" 
                                        style={{marginBottom: "0.8rem", display:"flex", justifyContent:"space-evenly"}} className="startAndEnddate">
                                        <div >
                                            <input type="checkbox" name='searchBy'
                                                onChange={this.onGetSeparateFileOptionChange} noValidate 
                                                defaultChecked={true}
                                                disabled
                                            /> 
                                            <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Get separate files for all selected device</span>
                                        </div>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"center", alignItems: "center"}}>
                                        <button type="button" 
                                                className="adduserButton btn-sm"
                                                style={{width:"12rem",fontSize:"1rem", fontWeight: "bold",marginBottom: "0rem"}}
                                                onClick={this.OnClickDownloadAsCsvButton}> 
                                                Download As CSV
                                        </button>
                                    </div>
                                    {this.state.errors.noDataFoundErr.length > 0 && 
                                        <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center",marginBottom: "0rem"}} className='error'>{this.state.errors.noDataFoundErr}</p>
                                    }
                                    {this.state.errors.timeRelatedErrors.length > 0 && 
                                        <p style={{color:"green", fontSize:"0.9rem", textAlign:"center",marginBottom: "0rem"}} className='error'>{this.state.errors.timeRelatedErrors}</p>
                                    }
                                    {this.state.errors.noParamSelectedErr.length > 0 && 
                                        <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center",marginBottom: "0rem"}} className='error'>{this.state.errors.noParamSelectedErr}</p>
                                    }
                                    {this.state.errors.others.length > 0 && 
                                        <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center",marginBottom: "0rem"}} className='error'>{this.state.errors.others}</p>
                                    }
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

VcRawDataAnalysis.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods
export default VcRawDataAnalysis;