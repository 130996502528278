import React from 'react'

import "../../../CSS/DeviceHome.css";

const VcLoader = () => {
  return (
    <div className='w-full h-full flex flex-center'>
        <div className='spinner'></div>
    </div>
  )
}

export default VcLoader;