import React, { useState, useContext, useEffect } from 'react'
import { AppRelevantDataContext } from '../../AppContext';
import VcLogin from './VcLogin'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { PVG_ADMIN, PVG_DEVC_OWNER, PVG_GENERAL_USER, PVG_DEVC_VIEWER, PVG_FWMR_ADMIN, PVG_SALES_EXECUTIVE,
  PVG_SALES_SUPERVISOR, PVG_PRODUCTION_SUPERVISOR, PVG_PRODUCTION_WORKER, PVG_BACK_OFFICE_EXECUTIVE, PVG_PRODUCT_OWNER, PVG_FIELD_ENGINEER,
  PVG_INST_PRIMARY_OWNER, PVG_INST_ADDITIONAL_VIEWER, PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING, PVG_DEVC_VIEWER_WITH_SETTING, 
  PVG_FWMR_FLASHING_ADMIN, PVG_TOILET_ADMIN, PVG_SW_DEV_ADMIN, PVG_TREE_DEFINITION_ADMIN, PVG_TREE_NODE_VIEWER, PVG_EXTERNAL_API_USER} from '../../VcConstants';
import { IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_LoggedInSuccessfully, IDS_NotHaveReqPvg } from '../../VcLanguage';
import {useNavigate} from 'react-router-dom';
import aes from 'crypto-js/aes';
import { getMessaging, getToken} from "firebase/messaging";

function VcLoginPg(props) {

  const [state, setState] = useState({
    invokedFrom: "invokedFromLoginPg",
    fcmRegID: "",
    SessIdToStore: "",
    errors: {
      others: ''        
    }
  });

  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();


  useEffect(() => {
    let appRelevantDataContext = context;  // Get all the relevant data from AppContext
    let modifiedState = state;
    
    if(appRelevantDataContext.sessJankari.sessID != null && appRelevantDataContext.sessJankari.sessID.length > 0 && 
        (modifiedState.invokedFrom != null && modifiedState.invokedFrom == "invokedFromLoginPg")) {

        let resData = {};

        resData.SessIdToStore = appRelevantDataContext.sessJankari.sessID;
          
      {handleSuccessfulAuth([appRelevantDataContext.loggedInUserInfo.userID, appRelevantDataContext.loggedInUserInfo.userFirstName, appRelevantDataContext.loggedInUserInfo.userLastName, "invokedFromLoginPg"], resData)}
    }

  }, [context.sessJankari.sessID, state.invokedFrom]);

  const handleSuccessfulAuth = (loginData, resData) => {

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;    

    let modifiedState = state;

    modifiedState.invokedFrom = loginData[3];
    let SavedUserPrivilegeType = "";
    const UserID = loginData[0];
    const RetrievedFirstName = loginData[1];
    const RetrievedLastName = loginData[2];
    modifiedState.SessIdToStore = resData.SessIdToStore;

    props.handleLogin([UserID, RetrievedFirstName, RetrievedLastName]);

    const checkPrivilegeData = {
      UserID: UserID,
      ArrOfPrivilege: [ PVG_ADMIN, PVG_GENERAL_USER, PVG_DEVC_OWNER, PVG_DEVC_VIEWER, PVG_FWMR_ADMIN, PVG_SALES_EXECUTIVE, 
                        PVG_SALES_SUPERVISOR, PVG_PRODUCTION_SUPERVISOR, PVG_PRODUCTION_WORKER, PVG_BACK_OFFICE_EXECUTIVE, 
                        PVG_PRODUCT_OWNER, PVG_FIELD_ENGINEER, PVG_INST_PRIMARY_OWNER, PVG_INST_ADDITIONAL_VIEWER,
                        PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING, PVG_DEVC_VIEWER_WITH_SETTING, PVG_FWMR_FLASHING_ADMIN, PVG_TOILET_ADMIN,
                        PVG_SW_DEV_ADMIN, PVG_TREE_DEFINITION_ADMIN, PVG_TREE_NODE_VIEWER, PVG_EXTERNAL_API_USER
                      ]
    }

    axios.post( `${getAPIHostURL()}/wclient/checkPrivilege`, checkPrivilegeData )
    .then(response => {

      if(response.data.code == 'SUCCESS') {

        const UserPrivilegeInfo = response.data.retrievedUserPrivilegeInfo;

        SavedUserPrivilegeType = '"' + UserPrivilegeInfo[0]["PrivilegeName"] + '"' + ":" + UserPrivilegeInfo[0]["bPrivilege"];
        for(let i=1; i < UserPrivilegeInfo.length; i++) {          
          SavedUserPrivilegeType += ", " + '"' + UserPrivilegeInfo[i]["PrivilegeName"] + '"' + ":" + UserPrivilegeInfo[i]["bPrivilege"];
        }

        let strOfPrivileges = '{' + SavedUserPrivilegeType + '}';

        let PassKey = UserPrivilegeInfo[0]["PassKey"];
        
        // Encrypt privilege using aes_encrypt() and convert it to the string.
        // let encryptedPvg = CryptoJS.AES.encrypt(strOfPrivileges, PassKey);
        let encryptedPvg = aes.encrypt(strOfPrivileges, PassKey);

        let strEncryptedPvg = encryptedPvg.toString();

        // Store same Encrypted privileges into a context.
        appRelevantDataContextValue.onChangeLoggedInUserPvg(strEncryptedPvg);

        let DeviceIDArrToTrack = appRelevantDataContextValue.devicesToTrack.DevicesInfo;

        if(DeviceIDArrToTrack != null && DeviceIDArrToTrack.length > 0) {

          // this is just to avoid a wrong data to be displayed.
          // So, here we are setting explicitly DeviceToTrack obj to blank which is present in our context.
          // And, also we are explicitly showing parent tree node as selected.
          // Hence if a user has TrackDevcPvg and he is owner or additional user and he tries to login from 
          // two different tabs on browser he will not get any wrong or in consistent data.
          appRelevantDataContextValue.onChangeTrackDeviceInfo([], []);
          appRelevantDataContextValue.onSelectedDevice("", "", true);
          appRelevantDataContextValue.onSelectedRoot(UserID, RetrievedFirstName + " " + RetrievedLastName);
        } else {
          // No need to do anything.
        }

        let objPrivilege = {};

        try {
          objPrivilege = JSON.parse(strOfPrivileges);

        } catch(e) {
          modifiedState.errors.others = t(IDS_LoginServerIssue);
          setState({...modifiedState});
          return; // No need further Processing.
        }

        // If the context having the value "invokedLoginPgInfo" that means the login page is invoked from some different login url path. 
        // Based on that login path in context we redirect him or show the next page acording to the "invokedLoginPgInfo.showNextPagePathAfterLogin" in context.
        if(appRelevantDataContextValue.invokedLoginPgInfo != null && Object.keys(appRelevantDataContextValue.invokedLoginPgInfo).length > 0 &&
          appRelevantDataContextValue.invokedLoginPgInfo.loginPagePath != null && appRelevantDataContextValue.invokedLoginPgInfo.loginPagePath.length > 0 &&
          appRelevantDataContextValue.invokedLoginPgInfo.showNextPagePathAfterLogin != null && appRelevantDataContextValue.invokedLoginPgInfo.showNextPagePathAfterLogin.length > 0) {

          alert(t(IDS_LoggedInSuccessfully));
          navigate(appRelevantDataContextValue.invokedLoginPgInfo.showNextPagePathAfterLogin);
        } else if(objPrivilege != null && 
          (
            (objPrivilege.hasOwnProperty(PVG_GENERAL_USER) && objPrivilege[PVG_GENERAL_USER] == true) ||
            (objPrivilege.hasOwnProperty(PVG_DEVC_OWNER) && objPrivilege[PVG_DEVC_OWNER] == true) ||
            (objPrivilege.hasOwnProperty(PVG_DEVC_VIEWER) && objPrivilege[PVG_DEVC_VIEWER] == true) ||
            (objPrivilege.hasOwnProperty(PVG_DEVC_VIEWER_WITH_SETTING) && objPrivilege[PVG_DEVC_VIEWER_WITH_SETTING] == true) ||
            (objPrivilege.hasOwnProperty(PVG_INST_PRIMARY_OWNER) && objPrivilege[PVG_INST_PRIMARY_OWNER] == true) ||
            (objPrivilege.hasOwnProperty(PVG_INST_ADDITIONAL_VIEWER) && objPrivilege[PVG_INST_ADDITIONAL_VIEWER] == true) ||
            (objPrivilege.hasOwnProperty(PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING) && objPrivilege[PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING] == true) ||
            (objPrivilege.hasOwnProperty(PVG_TREE_NODE_VIEWER) && objPrivilege[PVG_TREE_NODE_VIEWER] == true) ||
            (
              objPrivilege.hasOwnProperty(PVG_DEVC_OWNER) && objPrivilege[PVG_DEVC_OWNER] == true &&
              (
                (objPrivilege.hasOwnProperty(PVG_ADMIN) && objPrivilege[PVG_ADMIN] == true) ||
                (objPrivilege.hasOwnProperty(PVG_FWMR_ADMIN) && objPrivilege[PVG_FWMR_ADMIN] == true)
              )
            )
          )
        ) {
          alert(t(IDS_LoggedInSuccessfully));

          // Enable if Wanted to use Notification Feature on HHM Web.
          // reqNotificationPermissionAndGetToken();

          navigate('/device/dashboard');

        } else if( (objPrivilege.hasOwnProperty(PVG_ADMIN) && objPrivilege[PVG_ADMIN] == true) ||
                    (objPrivilege.hasOwnProperty(PVG_FWMR_ADMIN) && objPrivilege[PVG_FWMR_ADMIN] == true)
        ) {
          alert(t(IDS_LoggedInSuccessfully));
          navigate('/admin');

        } else if (objPrivilege != null && 
                    ((objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) ||
                    (objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && objPrivilege[PVG_PRODUCTION_WORKER] == true) ||
                    (objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && objPrivilege[PVG_SALES_EXECUTIVE] == true) ||
                    (objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && objPrivilege[PVG_SALES_SUPERVISOR] == true) ||
                    (objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true) ||
                    (objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && objPrivilege[PVG_PRODUCT_OWNER] == true) ||
                    (objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && objPrivilege[PVG_FIELD_ENGINEER] == true) ||
                    (objPrivilege.hasOwnProperty(PVG_TREE_DEFINITION_ADMIN) && objPrivilege[PVG_TREE_DEFINITION_ADMIN] == true)
        )) {

          alert(t(IDS_LoggedInSuccessfully));
          navigate('/crm/report/allStatesDashboard');

        } else if (objPrivilege != null && 
          (objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && objPrivilege[PVG_TOILET_ADMIN] == true)
        ) {

          alert(t(IDS_LoggedInSuccessfully));
          navigate('/crm/report/viewToiletAndItsRatingInfo');
        }
        /* else if( (objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) && objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true) ||
                   (objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) && objPrivilege[PVG_PRODUCTION_WORKER] == true)
        ) {
          alert(t(IDS_LoggedInSuccessfully));
          this.props.history.push('/crm/report/currentProductionInventory');

        } 
        else if( (objPrivilege.hasOwnProperty(PVG_SALES_EXECUTIVE) && objPrivilege[PVG_SALES_EXECUTIVE] == true) ||
                   (objPrivilege.hasOwnProperty(PVG_SALES_SUPERVISOR) && objPrivilege[PVG_SALES_SUPERVISOR] == true)
        ) {
          alert(t(IDS_LoggedInSuccessfully));
          this.props.history.push('/crm/report/salesDashboard');

        } else if( (objPrivilege.hasOwnProperty(PVG_BACK_OFFICE_EXECUTIVE) && objPrivilege[PVG_BACK_OFFICE_EXECUTIVE] == true)
        ) {
          alert(t(IDS_LoggedInSuccessfully));
          this.props.history.push('/crm/report/deviceHistory');

        } else if( (objPrivilege.hasOwnProperty(PVG_PRODUCT_OWNER) && objPrivilege[PVG_PRODUCT_OWNER] == true)
        ) {
          alert(t(IDS_LoggedInSuccessfully));
          this.props.history.push('/crm/productAction/productDetails');

        } else if( (objPrivilege.hasOwnProperty(PVG_FIELD_ENGINEER) && objPrivilege[PVG_FIELD_ENGINEER] == true) 
        ) {
        alert(t(IDS_LoggedInSuccessfully));
        this.props.history.push('/crm/report/deviceHistory');
        } else if( (objPrivilege.hasOwnProperty(PVG_TOILET_ADMIN) && objPrivilege[PVG_TOILET_ADMIN] == true) 
        ) {
          alert(t(IDS_LoggedInSuccessfully));
          this.props.history.push('/crm/report/viewToiletAndItsRatingInfo');
        } else if((objPrivilege.hasOwnProperty(PVG_TREE_DEFINITION_ADMIN) && objPrivilege[PVG_TREE_DEFINITION_ADMIN] == true)) {
          alert(t(IDS_LoggedInSuccessfully));
          this.props.history.push('/crm/treeDefinition');
        } */
        else if((objPrivilege.hasOwnProperty(PVG_SW_DEV_ADMIN) && objPrivilege[PVG_SW_DEV_ADMIN] == true) 
        ) {
          alert(t(IDS_LoggedInSuccessfully));
          navigate('/crm/action/productItemState');

        } else {
          modifiedState.errors.others = t(IDS_NotHaveReqPvg);
          setState(...state, modifiedState);

        }      
        
      } else {

        if (response.data.code == 'SQL_ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          console.log("Should not reach here.")
          modifiedState.errors.others = t(IDS_LoginServerIssue);

        }
        // Display the specific error message for checking Privilege of User failure
        setState(...state, modifiedState);
      }

    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError)
      setState({...modifiedState});
    }); 
  }

  // After successfull login, to enable the web app to recieve the notifications,
  // we need to ask permission of the browser to enable the notification.
  // If the notifiction permission is granted, then only we will generate new token for that perticular user
  // and store that in the database as FCM registration id.
  const reqNotificationPermissionAndGetToken = async () => {

    let modifiedState = state;
    let appRelevantDataContextValue = context;
    let UserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    const messaging = getMessaging();

    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;

      // Generate new FCM (Firebase Cloud Messaging) token
      let newFcmRegID = await getToken(messaging, {vapidKey: 'BJlckiLcJPvCc602vk4BgsgfHkt28OpqGrVtC9ul2NWVXzNB7tM3J5nYTy7Awfo3W1csdb7zJXzcY3Rq-lq8Vo8'})

      modifiedState.fcmRegID = newFcmRegID;

      // Store FcmRegID in context
      appRelevantDataContextValue.onChangeFCMRegId(modifiedState.fcmRegID);

      // Store FcmRegID in database 
      saveDeviceRegIdIntoDB(UserID, "null", modifiedState.fcmRegID, 'Login', modifiedState.SessIdToStore)

    } else if (Notification.permission !== "denied") {

      // We need to ask the user for permission
      Notification.requestPermission().then(async (permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {

          // Generate new FCM (Firebase Cloud Messaging) token
          let newFcmRegID = await getToken(messaging, {vapidKey: 'BJlckiLcJPvCc602vk4BgsgfHkt28OpqGrVtC9ul2NWVXzNB7tM3J5nYTy7Awfo3W1csdb7zJXzcY3Rq-lq8Vo8'})

          modifiedState.fcmRegID = newFcmRegID;

          // Store FcmRegID in context
          appRelevantDataContextValue.onChangeFCMRegId(modifiedState.fcmRegID);

          // Store FcmRegID in database 
          saveDeviceRegIdIntoDB(UserID, "null", modifiedState.fcmRegID, 'Login', modifiedState.SessIdToStore)

        }
      });
    } 
    setState({...modifiedState});
  }

  // To send notifications, we need FCM (Firebase Cloud Messaging) registration Id of particular user
  // Depending on the registration id, the notifications will be sent to the users whereever they are having active sessions.
  // The function below will save the FCM registration id of user into the database.
  // We call this function in following cases: Login, Logout, Language change and Token Refresh
  const saveDeviceRegIdIntoDB = (userEmailId, currToken, newToken, action, sessionID) => {

    let modifiedState = state;
    let selectedlanguage = "";
    let sessID = "";

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    let languageToViewIn = appRelevantDataContextValue.language.languageToViewIn;

    selectedlanguage = languageToViewIn != null ? languageToViewIn : "en";
    sessID = sessionID != null ? sessionID : null;

    var requestObject = {
      "UserID": userEmailId,
      "CurrentToken": currToken,
      "NewToken": newToken,
      "UserClientLanguage": selectedlanguage,
      "UserClientMode": "WEB",
      "Action": action,
      "SessionID": sessID
    };

    axios.post( `${getAPIHostURL()}/wclient/UpdateDeviceNotificationRegID`, requestObject )
    .then(response => {

      if(response.data.code == 'SUCCESS') {
        // console.log("Successfully Updated Notification ID");

      } else {
        if (response.data.code == 'SQL_ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          console.log("Should not reach here.")
          modifiedState.errors.others = t(IDS_LoginServerIssue);

        }
        // Display the specific error message for checking Privilege of User failure
      }
      setState({...modifiedState});

    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError)
      setState({...modifiedState});
    }); 
  }
  
  return (
     
    context.sessJankari.sessID == null || context.sessJankari.sessID.length <= 0 ? 
    <div>
      <VcLogin handleSuccessfulAuth={handleSuccessfulAuth} aStateValFromVcLoginPage = {state}/>
    </div>
    :
    <div></div>
  );
}

export default VcLoginPg;
