import React, { Component } from 'react'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext} from '../../AppContext';
import SortableTree from '@namratadeshmukh/react-sortable-tree';
import '@namratadeshmukh/react-sortable-tree/style.css';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FaDesktop, FaHSquare, FaWalking, FaSearch } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
// import {QrReader} from '@otterscan/react-qr-reader';
import {QrScanner} from '@yudiel/react-qr-scanner';
import { FaQrcode } from 'react-icons/fa';
import { getNestedChildren } from '../../vtUtil';
import '../CSS/TreeView.css';
import '../CSS/HeirarchyDefinitionFormInstitution.css';
import "../CSS/HeirarchyDefinitionFormRailway.css"
import {IDS_AUSrvrIssueReqParamsNotSent, IDS_AddEditRmvOwnedToiletsAndDevices, IDS_AddNewToilet, IDS_AddNewDevice,
    IDS_EditSelectedNodeInfo, IDS_RemoveSelectedNode, IDS_RemoveSelectedDevice, IDS_ViewSelectedDeviceInfo, IDS_NoteToAddNewToilet, IDS_Note, IDS_ToiletNodeName,
    IDS_ToiletNodeDescription, IDS_Save, IDS_Back, IDS_NoteToAddNewDevice, IDS_ScanQRCodeOfDeviceID, IDS_ConfirmationToRemoveNode, IDS_AlertOnAddingNewToilet, IDS_AlertOnRemoveNode,
    IDS_AlertOnAddingNewDeviceNode, IDS_AlertOnEditNode, IDS_NoToiletAvailable, IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_NoToiletAvailableExtUsr} from '../../VcLanguage';
import {PVG_GENERAL_USER } from '../../VcConstants';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { connect } from 'react-redux';
import { setTreeDefinitionFlag } from "./Store/Slices/variables";

export class VcTreeDefinition extends Component {
    constructor(props) {
        super(props)
        this.onClickEachChildDropDown = this.onClickEachChildDropDown.bind(this);
        this.onClickNodeTitle = this.onClickNodeTitle.bind(this);
        this.timeOutForHighlightAndScrollToTheSelectedNode = null;

        this.state = {
            InvokedFromDeviceTree: this.props.InvokedFromDeviceTree,
            LoggedInUserFromDeviceTree: this.props.loggedInUserID,
            objPrivilege: {},
            selectedTreeRootID: "",
            arrAllTreeNameIDAndDesc: [],
            selectedTreeInformation: [],
            TreeInfoArr: [],
            showTreeStructure: false,
            addEditNodeModal: false,
            addDeviceNodeModal: false,
            addToiletNodeModal: false,
            backdrop: true,
            selectedNodeDisplayName: "",
            formViewMode: "",
            isNodeHasDevices: false,
            isShowNodeHasDeviceCheckbox: false,
            selectedNodeDescription: "",
            parentNodeIDOfselectedRemoveNode: '',
            selectedNodeID: "",
            DeviceID: "",
            DisplayDeviceInfo: "DisplayAsChildNode",
            displayTreeDescription: "",
            selectedNodeDeviceType: [],
            selectedAddEditRmvNodeTitleToDisplay: "",
            objSelectedNodeInformation: {},
            LoggedInUserID: '', // Value will come from context
            arrToStoreAllTreeNodeId: [],
            retreivedTreeInfo: [],
            fetchedTreeInfoArrayForDeviceTree: [],
            retreivedEmailID: "",
            UserFirstName: '', // Value will come from context
            UserLastName: '',
            bOnlyGenrlUsrPvg: false,
            errors: {
                others:"", 
                selectedNodeDisplayName: "",
                errDeviceTypeToAdd: "", 
                errToShowNoToiletsFound: "",    
                errSelectTreeName: "",                                  
            },
            isDeviceRemoved: false,
        }
    }

    componentDidMount () {

        this.checkUserPrivilegesAndCreateTreeNodes();
        
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutForHighlightAndScrollToTheSelectedNode);
        this.setState({DevcIDQRCodeFlag: false});

    }

    checkUserPrivilegesAndCreateTreeNodes = () => {

        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;  

        let encryptedPrivileges = appRelevantDataContextValue.loggedInUserPrivilege.Privilege;
        modifiedState.encryptedPrivileges = encryptedPrivileges;
        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        axios.post(`${getAPIHostURL()}/wclient/getEncChaabi`)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
               if(response.data.retrievedEncChaabi == null || response.data.retrievedEncChaabi.length <= 0) {
                    modifiedState.errors = `Unable to get encryption key.`;
               } else {
                   modifiedState.PrivilegeEncKey = response.data["retrievedEncChaabi"][0]["PassKey"];

                   let bytes  = aes.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);
                   let strPrivilege = bytes.toString(enc);

                   try {
                        modifiedState.objPrivilege = JSON.parse(strPrivilege);

                        if(modifiedState.objPrivilege != null &&
                            modifiedState.objPrivilege.hasOwnProperty(PVG_GENERAL_USER) && 
                            modifiedState.objPrivilege[PVG_GENERAL_USER] == true
                        ) {
                            delete modifiedState.objPrivilege["GeneralUser"];

                            // check if any privilege other than GeneralUser is true
                            modifiedState.bOnlyGenrlUsrPvg = Object.values(modifiedState.objPrivilege).some(val => val === true) == false ? true : false;

                        }

                        delete modifiedState.objPrivilege["GeneralUser"];

                        this.getAllTreeParentNodeInfo(modifiedState);

                   } catch(e) {
                        console.log(`Should not happen. The Privilege obtained from Context is in invalid JSON format.`);
                   }
               }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors = t(IDS_LoginServerIssue);
                }
                this.setState(modifiedState);
            }
        })
        .catch(err => {
            console.log("Network error");
            console.log(err);

            if (axios.isCancel(err)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors = t(IDS_RegistNetworkError);
            }
        }) 
    }

    getAllTreeParentNodeInfo = (inModifiedState = null) => {

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }
        modifiedState.arrAllTreeNameIDAndDesc = [];

        let loggedInUserFromDeviceTree = "";
        let invokedFrom = "";

        if(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree" &&
            modifiedState.LoggedInUserFromDeviceTree != null && modifiedState.LoggedInUserFromDeviceTree.length > 0){
                
                loggedInUserFromDeviceTree = modifiedState.LoggedInUserFromDeviceTree;
                invokedFrom = "deviceTree";
                
        } else{
                loggedInUserFromDeviceTree = null;
                invokedFrom = null;
        }

        let jsonBody = {
            LoggedInUserFromDeviceTree: loggedInUserFromDeviceTree,
            InvokedFrom: invokedFrom
        }

        axios.post(`${getAPIHostURL()}/wclient/getTreeInfo`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data["retreivedTreeNodeIDNameAndDesc"] == null ||
                    response.data["retreivedTreeNodeIDNameAndDesc"].length <= 0
                ) {
                    if(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree" &&
                        modifiedState.LoggedInUserFromDeviceTree != null && modifiedState.LoggedInUserFromDeviceTree.length > 0)
                    {
                        modifiedState.TreeInfoArr = []; // Set the device tree to empty initially
                        this.setState(modifiedState);

                    } else{
                        console.log("Parent Node information is not present on server.");
                    }
                } else {
                    // Include all ParentNode ID and its Name information (MasterData)
                    if(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree" &&
                        modifiedState.LoggedInUserFromDeviceTree != null && modifiedState.LoggedInUserFromDeviceTree.length > 0){
                            modifiedState.arrToStoreAllTreeNodeId = response.data["retreivedTreeNodeIDNameAndDesc"];
                            this.getSelectedTreeStructure(modifiedState);

                    } else{
                        // Include all ParentNode ID and its Name information (MasterData)
                        modifiedState.arrAllTreeNameIDAndDesc = response.data["retreivedTreeNodeIDNameAndDesc"];

                        if(modifiedState.selectedTreeRootID != null && modifiedState.selectedTreeRootID.length > 0) {
                            for(let i = 0; i < modifiedState.arrAllTreeNameIDAndDesc.length; i++) {
                                if(modifiedState.arrAllTreeNameIDAndDesc[i]["NodeId"] == modifiedState.selectedTreeRootID) {
                                    modifiedState.displayTreeDescription = modifiedState.arrAllTreeNameIDAndDesc[i]["ParentNodeDescription"] == null ? 
                                        "" : modifiedState.arrAllTreeNameIDAndDesc[i]["ParentNodeDescription"];
                                    break;
                                } else {
                                    modifiedState.displayTreeDescription = "";
                                    continue;
                                }
                            }
                            this.getSelectedTreeStructure(modifiedState);
                        } else {
                            modifiedState.selectedTreeRootID = "";
                            modifiedState.displayTreeDescription = "";
                            this.setState(modifiedState);

                        }
                    }
                }
                if(modifiedState.selectedTreeRootID == null || modifiedState.selectedTreeRootID.length <= 0) {
                    this.setState(modifiedState);
                } 
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }
        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    onChangeTreeName = (e) => {
        let modifiedState = this.state;
        modifiedState.errors.others = ""
        modifiedState.errors.errSelectTreeName = "";
        modifiedState.selectedTreeRootID = e.target.value;
        modifiedState.formViewMode = "";
        modifiedState.selectedNodeID = "";

        for(let i = 0; i < modifiedState.arrAllTreeNameIDAndDesc.length; i++) {
            if(modifiedState.arrAllTreeNameIDAndDesc[i]["NodeId"] == modifiedState.selectedTreeRootID) {
                modifiedState.displayTreeDescription = modifiedState.arrAllTreeNameIDAndDesc[i]["ParentNodeDescription"] == null ? 
                    "" : modifiedState.arrAllTreeNameIDAndDesc[i]["ParentNodeDescription"];
                break;
            } else {
                modifiedState.displayTreeDescription = "";
                continue;
            }
        }
        modifiedState.showTreeStructure = false;
        this.setState(modifiedState);
    }

    onclickShowSelectedTreeStructure = () => {
        this.getSelectedTreeStructure();
    }

    // This will autoscroll and highlight to the selected node after successfully rendering DeviceTree.
    highlightAndScrollToSelectedNode = () => {
        let modifiedState = this.state;
        let selectedTreeNodeID = modifiedState.formViewMode == 'RemoveNode' ? modifiedState.parentNodeIDOfselectedRemoveNode: modifiedState.selectedNodeID;

        if(selectedTreeNodeID != null && selectedTreeNodeID.length > 0) {
            this.setState({ 
                searchString : selectedTreeNodeID,
                clrInput: '',
                bSearch : false 
            });
            
            // This is just to remove red higlighted border after scrolling to the selected node.
            if(this.timeoutToRemoveHighlight != null) {
                clearTimeout(this.timeoutToRemoveHighlight);
                this.timeoutToRemoveHighlight = setTimeout(() => { this.clearInputField() }, 2000);
            } else {
                this.timeoutToRemoveHighlight = setTimeout(() => { this.clearInputField() }, 2000);
            }
        }
    }

    getSelectedTreeStructure = (inModifiedState=null) => {
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let inAppRelevantDataContextValue = this.context;
        let userLastName = inAppRelevantDataContextValue.loggedInUserInfo.userLastName;
        let userFirstName = inAppRelevantDataContextValue.loggedInUserInfo.userFirstName;
        let selectedTreeNodeID = inAppRelevantDataContextValue.selectedNodeInfo.nodeID;

        modifiedState.UserLastName = userLastName;
        modifiedState.UserFirstName = userFirstName;

        modifiedState.selectedTreeInformation = [];
        modifiedState.TreeInfoArr = [];

        if((modifiedState.selectedTreeRootID == null || modifiedState.selectedTreeRootID.length <= 0) && modifiedState.InvokedFromDeviceTree == null) {
            modifiedState.errors.errSelectTreeName = 'Please select Tree Name.'
            this.setState(modifiedState);
            return;
        } else {

            let jsonBody = {
                selectedParentTreeNodeID: modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree" ?
                    modifiedState.arrToStoreAllTreeNodeId : modifiedState.selectedTreeRootID,
                InvokedFrom: modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree" ? 
                    modifiedState.InvokedFromDeviceTree : "treeDefination"
            }
    
            axios.post(`${getAPIHostURL()}/wclient/defineTree`, jsonBody)
            .then(response => {
                if(response.data.code == 'SUCCESS') {
    
                    if(response.data["retreivedTreeData"] == null ||
                        response.data["retreivedTreeData"].length <= 0
    
                    ) {
                        modifiedState.errors.others = 'Selected Tree information is not present on server.'
                        this.setState(modifiedState);
                    } else {
                        modifiedState.showTreeStructure = true;
                        modifiedState.selectedTreeInformation = response.data["retreivedTreeData"];
                        modifiedState.objSelectedNodeInformation = {}

                        if(modifiedState.InvokedFromDeviceTree == null) {

                            if(modifiedState.formViewMode != null && (modifiedState.formViewMode == "AddNode" || modifiedState.formViewMode == "EditNode" || modifiedState.formViewMode == "RemoveNode")){
                                modifiedState.selectedTreeInformation.map((singleChild) => {

                                    if((singleChild.id == modifiedState.selectedNodeID) || (modifiedState.formViewMode == 'RemoveNode' && singleChild.id == modifiedState.parentNodeIDOfselectedRemoveNode)) {
                                        singleChild.isSelected = true;
                                        singleChild.expanded  = true;
                                    } else {
                                        singleChild.isSelected = false;
                                    }

                                    if(modifiedState.formViewMode == 'RemoveNode' && singleChild.id == modifiedState.parentNodeIDOfselectedRemoveNode){
                                        modifiedState.objSelectedNodeInformation = singleChild;
                                    }
                                });
                            }

                            let parentDataOfRemoveNode = {}
                            if(modifiedState.formViewMode == 'RemoveNode') {
                                try{
                                    parentDataOfRemoveNode = JSON.parse(modifiedState.objSelectedNodeInformation.objtitle);
                                } catch {
                                    console.log("Json parse error");
                                    return;
                                }
                            }

                            if(modifiedState.formViewMode == 'RemoveNode' && (parentDataOfRemoveNode != null 
                                && parentDataOfRemoveNode.hasOwnProperty("DeviceType") == true)) {

                                modifiedState.TreeInfoArr = getNestedChildren(modifiedState.selectedTreeInformation, null);
                                this.getSelectedNodeDevicesInformation(modifiedState.objSelectedNodeInformation, modifiedState.parentNodeIDOfselectedRemoveNode, modifiedState);                            
                            } else {

                                modifiedState.TreeInfoArr = getNestedChildren(modifiedState.selectedTreeInformation, null);
                                this.setState(modifiedState);
                            } 
                        } else {
                            
                            /// Device Removed
                            if(modifiedState.formViewMode != null && (modifiedState.formViewMode == "AddNode" || modifiedState.formViewMode == "EditNode" || modifiedState.formViewMode == "RemoveNode")){
                                modifiedState.selectedTreeInformation.map((singleChild) => {

                                    if((singleChild.id == modifiedState.selectedNodeID) || (modifiedState.formViewMode == 'RemoveNode' && singleChild.id == modifiedState.parentNodeIDOfselectedRemoveNode)) {
                                        singleChild.isSelected = true;
                                        singleChild.expanded  = true;
                                    } else {
                                        singleChild.isSelected = false;
                                    }

                                    if(modifiedState.formViewMode == 'RemoveNode' && singleChild.id == modifiedState.parentNodeIDOfselectedRemoveNode){
                                        modifiedState.objSelectedNodeInformation = singleChild;
                                    }
                                });
                            }

                            let parentDataOfRemoveNode = {}
                            if(modifiedState.formViewMode == 'RemoveNode' && modifiedState.isDeviceRemoved == true) {
                                try{
                                    parentDataOfRemoveNode = JSON.parse(modifiedState.objSelectedNodeInformation.objtitle);
                                } catch {
                                    console.log("Json parse error");
                                    return;
                                }
                            }

                            if(modifiedState.formViewMode == 'RemoveNode' && (parentDataOfRemoveNode != null 
                                && parentDataOfRemoveNode.hasOwnProperty("DeviceType") == true)) {

                                modifiedState.fetchedTreeInfoArrayForDeviceTree = getNestedChildren(modifiedState.selectedTreeInformation, null);
                                this.getSelectedNodeDevicesInformation(modifiedState.objSelectedNodeInformation, modifiedState.parentNodeIDOfselectedRemoveNode, modifiedState);                            
                            } else {

                                modifiedState.fetchedTreeInfoArrayForDeviceTree = getNestedChildren(modifiedState.selectedTreeInformation, null);
                                // modifiedState.fetchedTreeInfoArrayForDeviceTree = modifiedState.selectedTreeInformation;

                                this.setState(modifiedState);
                            } 
                            let singleOwnerTreeDetails = {
                                // title: receivedEmailID, // Root node 'title'
                                // Root node 'title'. May change in future (Currently RootNodeTitle is same as LoggedInUserName).
                                // Only for Tracked Devices Feature root node 'title' will be "Tracked Devices" otherwise currently RootNodeTitle is same as LoggedInUserName.
                                title: `${userFirstName} ${userLastName}`,  
                                // To identify root node. May change in future (Currently RootNodeID is same as LoggedInUserID).
                                id: modifiedState.retreivedEmailID, 
                                expanded: true,
                                isRoot: true,
                                isSelected: (modifiedState.retreivedEmailID == selectedTreeNodeID || selectedTreeNodeID == "trackedDevicesID") ? true : false,
                                children:  modifiedState.fetchedTreeInfoArrayForDeviceTree,
                                isDevice: false,
                            }
            
                            modifiedState.TreeInfoArr = []; // Set the device tree to empty initially

                            
                            // Fill the information required for constructing the tree                
                            modifiedState.TreeInfoArr.push(singleOwnerTreeDetails);

                            this.setState(modifiedState);
                        }
                            
                        if(this.timeOutForHighlightAndScrollToTheSelectedNode != null) {
                            clearTimeout(this.timeOutForHighlightAndScrollToTheSelectedNode);
                            this.timeOutForHighlightAndScrollToTheSelectedNode = setTimeout(() => { this.highlightAndScrollToSelectedNode() }, 2000);
                        } else {
                            this.timeOutForHighlightAndScrollToTheSelectedNode = setTimeout(() => { this.highlightAndScrollToSelectedNode() }, 2000);
                        }
                    }
                } else {
                    if (response.data.code == 'SQL_ERROR') {
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else if(response.data.code == 'REQ_PARAMS_MISSING') {
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.' 
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                    this.setState(modifiedState);
                }
            })
            .catch( error => {
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    this.setState(modifiedState);
                }
            }); 
        }
    }

    addNewNode = (id, title, hasDevice=false, inarrNodeHavingDevcAsChildren=null) => {
        let modifiedState = this.state;
        modifiedState.selectedNodeDisplayName = "";
        modifiedState.errors.selectedNodeDisplayName = "";
        modifiedState.errors.others = "";
        modifiedState.errors.errSelectTreeName = "";
        modifiedState.DeviceID = "";
        modifiedState.selectedNodeID = id;
        modifiedState.DisplayDeviceInfo = "DisplayAsChildNode";
        modifiedState.selectedAddEditRmvNodeTitleToDisplay = title;
        let objSelectedNodeInfo;

        try {
            objSelectedNodeInfo = JSON.parse(inarrNodeHavingDevcAsChildren);
            modifiedState.objSelectedNodeInformation = objSelectedNodeInfo;
        } catch {
            console.log("Json parse error");
            return;
        }

        if(hasDevice != null && hasDevice == "true") {
            modifiedState.addDeviceNodeModal= true;
        } else {
            modifiedState.addEditNodeModal = true;
        }
        modifiedState.formViewMode = "AddNode";
        modifiedState.selectedNodeDescription = '';
        modifiedState.isNodeHasDevices = false;
        modifiedState.selectedNodeDeviceType = [];

        modifiedState.selectedTreeInformation.map((singleChild) => {
            if(singleChild.id == id && singleChild.hasDevice == "true") {
                singleChild.isSelected = true;
                singleChild.expanded  = true;
            } else {
                singleChild.isSelected = false;
            }
        });
        this.setState(modifiedState);
    }

    addNewToiletNode = (id, title, hasDevice=false, inarrNodeHavingDevcAsChildren=null) => {        
        let modifiedState = this.state;
        modifiedState.selectedNodeDisplayName = "";
        modifiedState.errors.selectedNodeDisplayName = "";
        modifiedState.errors.others = "";
        modifiedState.errors.errSelectTreeName = "";
        modifiedState.DeviceID = "";
        modifiedState.selectedNodeID = id;
        modifiedState.DisplayDeviceInfo = "DisplayAsChildNode";
        modifiedState.selectedAddEditRmvNodeTitleToDisplay = title;
        let objSelectedNodeInfo;

        try {
            objSelectedNodeInfo = JSON.parse(inarrNodeHavingDevcAsChildren);
            modifiedState.objSelectedNodeInformation = objSelectedNodeInfo;
        } catch {
            console.log("Json parse error");
            return;
        }
        modifiedState.addDeviceNodeModal= true;
        modifiedState.formViewMode = "AddToiletNodeWithDevices";
        modifiedState.selectedNodeDescription = '';
        modifiedState.isNodeHasDevices = false;
        modifiedState.selectedNodeDeviceType = [];

        modifiedState.selectedTreeInformation.map((singleChild) => {
            if(singleChild.id == id && singleChild.hasDevice == "true") {
                singleChild.isSelected = true;
                singleChild.expanded  = true;
            } else {
                singleChild.isSelected = false;
            }
        });
        this.setState(modifiedState);
    }

    onRemoveSelectedTreeNode = (id, nodeData) => {
        let modifiedState = this.state;
        let inAppRelevantDataContextValue = this.context;
        let t = inAppRelevantDataContextValue.t;

        if(!window.confirm(
            (modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree")
            ?
            `"${nodeData.title}" ` + t(IDS_ConfirmationToRemoveNode)
            :
            `Are you sure you want to remove selected node "${nodeData.title}" from Tree.`
        )){
            return
        } else {
            modifiedState.objSelectedNodeInformation = {};
            modifiedState.selectedNodeID = id;
            modifiedState.formViewMode = "RemoveNode";
            modifiedState.selectedNodeDescription = "";
            modifiedState.selectedNodeDisplayName = "";
            modifiedState.selectedNodeDeviceType = [];
            modifiedState.errors.selectedNodeDisplayName = "";
            modifiedState.parentNodeIDOfselectedRemoveNode = nodeData.ParentNode;
            modifiedState.selectedAddEditRmvNodeTitleToDisplay = nodeData.title;

            this.addEditRmvNodeOfTree(modifiedState, nodeData.isDevice, nodeData.ParentNode);
        } 
    }

    onEditSelectedNodeInfo = (id, title, nodeData) => {

        let modifiedState = this.state;
        modifiedState.errors.selectedNodeDisplayName = "";
        modifiedState.selectedNodeDisplayName = nodeData.title;
        modifiedState.selectedAddEditRmvNodeTitleToDisplay = nodeData.title;
        modifiedState.selectedNodeDescription = nodeData.description == null ? "" : nodeData.description;
        modifiedState.selectedNodeID = nodeData.id;
        modifiedState.isNodeHasDevices = nodeData.hasDevice != null && nodeData.hasDevice.length > 0 && nodeData.hasDevice == "true" ? true : false;
        modifiedState.formViewMode = "EditNode";

        let objSelectedNodeInfo = {};
        try{
            objSelectedNodeInfo = JSON.parse(nodeData.objtitle);
        } catch {
            console.log("Json parse error");
            return;
        }

        if(objSelectedNodeInfo != null && objSelectedNodeInfo.hasOwnProperty("DeviceType") != true) {
            modifiedState.selectedNodeDeviceType = [];

            for(let i = 0; i< modifiedState.selectedTreeInformation.length; i++) {
                if(modifiedState.selectedTreeInformation[i].ParentNode == nodeData.id)
                {
                    modifiedState.isShowNodeHasDeviceCheckbox = false;
                    break;
                }
                else {
                    modifiedState.isShowNodeHasDeviceCheckbox = true;    
                }
            }
        } else {
            modifiedState.isShowNodeHasDeviceCheckbox = false;
            modifiedState.selectedNodeDeviceType = objSelectedNodeInfo["DeviceType"];
        }
        modifiedState.addEditNodeModal = true;
        this.setState(modifiedState);
    }

    onClickEachChildDropDown(e) {
        let selectNodeId = e.target.getAttribute('data-arr-index');
        let modifiedState = this.state;
        modifiedState.selectedTreeInformation.map((singleChild , index) => {
            if(singleChild.id == selectNodeId 
                // || selectNodeId == true
                )
            {
                singleChild.isDropDownVisible = true;
            } else
            {
                singleChild.isDropDownVisible = false;
            }
        })
        this.setState(modifiedState); 
    }

    customSearchMethod = ({ node, searchQuery }) =>
    (
      (searchQuery &&
       node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1) || 
      (searchQuery &&
       node.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
    );

    onViewSelectedTreeDevcNode = (id, nodeObjInfo) => {
        let modifiedState = this.state;
        modifiedState.formViewMode = "ViewNode";
        modifiedState.addDeviceNodeModal = true;
        modifiedState.selectedAddEditRmvNodeTitleToDisplay = nodeObjInfo.title;
        modifiedState.DeviceID = nodeObjInfo.id;
        modifiedState.DevcIDQRCodeFlag = false;
        modifiedState.selectedNodeID = nodeObjInfo.ParentNode;
        modifiedState.parentNodeIDOfselectedRemoveNode = nodeObjInfo.ParentNode;
        modifiedState.DisplayDeviceInfo = (nodeObjInfo.DisplayAsParentNodeData == true ? "DisplayAsParentNodeData" : "DisplayAsChildNode");
        this.setState(modifiedState);
    }

    renderDropDownMenu = (node) => {
        let modifiedState = this.state;
        let inAppRelevantDataContextValue = this.context;
        let t = inAppRelevantDataContextValue.t;

        return modifiedState.selectedTreeInformation.map((singleChild , index) => {
            return (

                node.id === singleChild.id && node.isDevice == true ?
                    
                <Dropdown key={node.id} 
                    group isOpen={singleChild.isDropDownVisible} 
                    toggle={this.onClickEachChildDropDown}  
                    className="tvDropDownParent">
                    <DropdownToggle tag="span"
                                    className="tvStyleForDropDownOnMenuVisible"
                                    data-arr-index={node.id}>
                        &#9660;
                    </DropdownToggle>
                    <DropdownMenu className="" style={{border:"1px solid var(--primaryColor)"}}>
                        <DropdownItem 
                            onClick={()=>{this.onViewSelectedTreeDevcNode(node.id, node)}}
                            data-arr-nodetitle-id={node.id}
                            data-arr-nodetitle={node.title}
                            data-arr-isnodeselected={node.isSelected}
                        >
                            {(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree") 
                                ? t(IDS_ViewSelectedDeviceInfo) : "View Selected Device Info"
                            }
                        </DropdownItem>
                        <DropdownItem 
                            onClick={()=>{this.onRemoveSelectedTreeNode(node.id, node)}}
                            data-arr-nodetitle-id={node.id}
                            data-arr-nodetitle={node.title}
                            data-arr-isnodeselected={node.isSelected}
                        > 
                            {(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree") 
                                ? t(IDS_RemoveSelectedDevice) : "Remove Selected Device"
                            }
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                :
                node.id === singleChild.id ?  
                <Dropdown key={node.id} 
                            group isOpen={singleChild.isDropDownVisible} 
                            toggle={this.onClickEachChildDropDown}  
                        className="tvDropDownParent">
                    <DropdownToggle tag="span"
                                    className="tvStyleForDropDownOnMenuVisible"
                                    data-arr-index={node.id}>
                        &#9660;
                    </DropdownToggle>
                    <DropdownMenu className="" style={{border:"1px solid var(--primaryColor)"}}>
                        <DropdownItem 
                            onClick={()=>{this.addNewNode(node.id, node.title, node.hasDevice, JSON.stringify(node))}}

                        >   
                            {node.hasDevice == "true" && (modifiedState.InvokedFromDeviceTree == null && modifiedState.InvokedFromDeviceTree != "deviceTree") 
                                ? "Add New Device" : 
                                node.hasDevice == "true" && (modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree") 
                                ? t(IDS_AddNewDevice) :"Add New Node"
                            }
                        </DropdownItem>
                        {
                        node.hasDevice == "0"?
                            <DropdownItem 
                                onClick={()=>{this.addNewToiletNode(node.id, node.title, node.hasDevice, JSON.stringify(node))}}

                            >   
                                Add Toilet Node With Devices
                            </DropdownItem>

                        : null
                        }
                        <DropdownItem 
                            onClick={()=>{this.onEditSelectedNodeInfo(node.id, node.title, node)}}
                        > 
                            {(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree") 
                                ? t(IDS_EditSelectedNodeInfo) : "Edit Selected Node Info"
                            }
                        </DropdownItem>
                        <DropdownItem 
                            onClick={()=>{this.onRemoveSelectedTreeNode(node.id, node)}}
                            data-arr-nodetitle-id={node.id}
                            data-arr-nodetitle={node.title}
                        > 
                            {(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree") 
                                ? t(IDS_RemoveSelectedNode) : "Remove Selected Node"
                            }
                        </DropdownItem>
                        {this.state.InvokedFromDeviceTree == null || this.state.InvokedFromDeviceTree != "deviceTree" ?
                            <DropdownItem 
                                onClick={this.onAddUserEmailID}
                                data-arr-nodetitle-id={node.id}
                                data-arr-nodetitle={node.title}
                                data-arr-isnodeselected={node.isSelected}
                                data-arr-hasdevice={node.hasDevice}
                            > 
                                Add/Remove User For Node
                            </DropdownItem>
                        :
                            null
                        }
                    </DropdownMenu>
                </Dropdown>
                : null
            );
        });
    }

    onAddUserEmailID = (e) => {
        let clickedNodeID = e.target.getAttribute("data-arr-nodetitle-id");
        let clickedNodeTitle = e.target.getAttribute("data-arr-nodetitle");
        let clickedNodeHasDevices = e.target.getAttribute("data-arr-hasdevice") == "true" ? true : false; 
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let onAddUserEmailID = appRelevantDataContextValue.onAddUserEmailID;
        onAddUserEmailID(clickedNodeID, clickedNodeTitle, clickedNodeHasDevices);
    }

    onclickDefineNewTree = (event) => {
        let modifiedState = this.state;
        event.preventDefault();

        modifiedState.selectedNodeDisplayName = "";
        modifiedState.selectedNodeDescription = '';
        modifiedState.isNodeHasDevices = false;
        modifiedState.selectedNodeID = null;
        modifiedState.addEditNodeModal = true;
        modifiedState.errors.selectedNodeDisplayName = "";
        modifiedState.selectedNodeDeviceType = [];
        modifiedState.InvokedFromDeviceTree == "deviceTree" ?  modifiedState.formViewMode = "AddNewToilet" : modifiedState.formViewMode = "DefineNewTree";

        this.setState(modifiedState);
    }

    onClickNodeTitle (e) {

        let modifiedState = this.state;
        modifiedState.errors.others = "";
        modifiedState.errors.errSelectTreeName = "";
        modifiedState.objSelectedNodeInformation = {};

        let clickedNodeID = e.target.getAttribute("data-arr-nodetitle-id");
        let clickedNodeTitle = e.target.getAttribute("data-arr-nodetitle");
        let inarrNodeHavingDevcAsChildren = e.target.getAttribute("data-arr-info");
        let objSelectedNodeInfo;

        try{
            objSelectedNodeInfo = JSON.parse(inarrNodeHavingDevcAsChildren);
            modifiedState.objSelectedNodeInformation = objSelectedNodeInfo;
        } catch {
            console.log("Json parse error");
            return;
        }

        modifiedState.TreeInfoArr.map(function iter(nestedChildNode) {
            if (clickedNodeID == nestedChildNode.id) {
                nestedChildNode.isSelected = true;
                nestedChildNode.expanded  = true;
            } else {
                nestedChildNode.isSelected = false;
            }
            Array.isArray( nestedChildNode.children) && nestedChildNode.children.map(iter);
        });

        if(objSelectedNodeInfo != null && objSelectedNodeInfo.hasDevice != null && objSelectedNodeInfo.hasDevice == "true") {
            this.getSelectedNodeDevicesInformation(objSelectedNodeInfo, clickedNodeID, modifiedState);
        } else {
            this.setState(modifiedState);         
        }
    }

    // give array of unique obj.
    getUniqArrBy = (props = [], arrInp = [{}]) => {
        const objKey = {}
        return arrInp.reduce((res, item) => {
          const valStr = props.reduce((res, prop) => `${res}${item[prop]}`, '')
          if(objKey[valStr]) return res
          objKey[valStr] = item
          return [...res, item]
        }, [])
    }

    getSelectedNodeDevicesInformation = (objSelectedNodeInfo, clickedNodeID, inModifiedState=null) => {

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.errors.others = "";
        modifiedState.errors.errSelectTreeName = "";

        let loggedInUserFromDeviceTree = "";
        let invokedFrom = "";

        if(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree" &&
            modifiedState.LoggedInUserFromDeviceTree != null && modifiedState.LoggedInUserFromDeviceTree.length > 0){
                
                loggedInUserFromDeviceTree = modifiedState.LoggedInUserFromDeviceTree;
                invokedFrom = "deviceTree";
                
        } else{
                loggedInUserFromDeviceTree = null;
                invokedFrom = "treeDefination";
        }

        let jsonBody = {
            NodeID: clickedNodeID,
            loggedInUserFromDeviceTree: loggedInUserFromDeviceTree,
            invokedFrom: invokedFrom,
        }

        axios.post( `${getAPIHostURL()}/wclient/getSelectedNodeDevicesInformation`, jsonBody)
        .then(response => {    
            if(response.data.code == 'SUCCESS') {

                let ArrAssignedDevicesToNode = [];

                for(let i=0; i<response.data.ArrAssignedDevicesToNode.length; i++) {

                    let singleValue = response.data.ArrAssignedDevicesToNode[i];

                    let oSingleDeviceStructure = {
                        title: singleValue.DeviceName, // Pass each device name to the child node 'title'
                        id: singleValue.DeviceID, // Device ID is unique in the backend
                        key: singleValue.DeviceID, // Just for the heck of it
                        isRoot: false,
                        ParentNode: clickedNodeID,
                        isDevice: true,
                        DeviceType: singleValue.DeviceType,
                        isSelected: false,
                        expanded: true,
                        DisplayAsParentNodeData: singleValue.DisplayAsParentNodeData == "true" ? true : false,
                        DisplayAsChildNode: singleValue.DisplayAsChildNode == "true" ? true : false
                    };
                    ArrAssignedDevicesToNode.push(oSingleDeviceStructure);                
                    modifiedState.selectedTreeInformation.push(oSingleDeviceStructure);
                }

                // give array of unique obj of Device.
                modifiedState.selectedTreeInformation = this.getUniqArrBy(['id'], modifiedState.selectedTreeInformation)

                modifiedState.TreeInfoArr.map(function iter(nestedChildNode) {
                    if (objSelectedNodeInfo.id == nestedChildNode.id) {
                        nestedChildNode.children = ArrAssignedDevicesToNode;
                    }
                    Array.isArray( nestedChildNode.children) && nestedChildNode.children.map(iter);
                });
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    console.log('Server experiencing issues.\nTry again later.');    
                } else if(response.data.code == 'REQ_PARAMS_MISSING') {
                    console.log('Server experiencing issues.\nTry again later.');
                } else {
                    console.log('Should not reach here');
                    console.log('Server experiencing issues.\nTry again later.');    
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                console.log('Network issues.\nCheck your Internet and Try again later.');
                this.setState(modifiedState);
            }
        }); 
    }

    addNodeFormToggle = () => {
        this.setState(prevState => {
            let modifiedState = prevState;
            modifiedState.addEditNodeModal = !modifiedState.addEditNodeModal;
            modifiedState.selectedAddEditRmvNodeTitleToDisplay = "";
            return modifiedState
        });
    }

    addDeviceNodeFormToggle = () => {
        this.setState(prevState => {
            let modifiedState = prevState;
            modifiedState.errors.others = "";
            modifiedState.errors.errSelectTreeName = "";
            modifiedState.DevcIDQRCodeFlag = false;
            modifiedState.selectedAddEditRmvNodeTitleToDisplay = "";
            modifiedState.addDeviceNodeModal = !modifiedState.addDeviceNodeModal;
            return modifiedState
        });
    }

    addToiletNodeFormToggle = () => {
        this.setState(prevState => {
            let modifiedState = prevState;
            modifiedState.errors.others = "";
            modifiedState.errors.errSelectTreeName = "";
            modifiedState.addToiletNodeModal = !modifiedState.addToiletNodeModal;
            return modifiedState
        });
    }
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {

            case 'selectedNodeDisplayName': 
                errors.selectedNodeDisplayName = "";       
            break;

            default:
            break;
        }
        this.setState({
            errors, 
            [name]: value,
        }, ()=> {
        })
    }

    onclickSaveButton = (event) => {
        let modifiedState = this.state;
        event.preventDefault();
        this.addEditRmvNodeOfTree(modifiedState);
    }

    addEditRmvNodeOfTree = (inModifiedState = null, isDevice = null, parentNode = null) => {

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let nodedata = {};
        if(modifiedState.formViewMode != null && modifiedState.formViewMode.length > 0 && (modifiedState.formViewMode == "AddNode" || modifiedState.formViewMode == "EditNode" || this.state.formViewMode == "DefineNewTree" ||this.state.formViewMode == "AddNewToilet" )) {
            if(modifiedState.selectedNodeDisplayName == null || modifiedState.selectedNodeDisplayName.length <= 0) {
                modifiedState.errors.selectedNodeDisplayName = "Display Name is required.";
                this.setState(modifiedState);
                return;
            }

            nodedata.DisplayName = modifiedState.selectedNodeDisplayName;
    
            if(modifiedState.selectedNodeDescription != null && modifiedState.selectedNodeDescription.length > 0) {
                nodedata.Description = modifiedState.selectedNodeDescription;
            }
    
            if((modifiedState.isNodeHasDevices != null && modifiedState.isNodeHasDevices == true) || (modifiedState.formViewMode != null && modifiedState.formViewMode == "AddNewToilet")){
                nodedata.HasDevices = true;
            }

            if(modifiedState.selectedNodeDeviceType != null && modifiedState.selectedNodeDeviceType.length > 0){
                nodedata.DeviceType = modifiedState.selectedNodeDeviceType;
            }

            try {
                nodedata = JSON.stringify(nodedata);
            } catch {
                console.log("JSON stringify error");
            }
        }

        let jsonBody = {
            selectedNodeID: modifiedState.selectedNodeID,
            nodedata: nodedata,
            loggedInUserID: loggedInUserID,
            formViewMode: modifiedState.formViewMode,
            invokedFrom: modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree" ? modifiedState.InvokedFromDeviceTree : "treeDefination",
            isDevice: isDevice != null && isDevice == true ? "true" : "false"

        }

        axios.post( `${getAPIHostURL()}/wclient/addEditRmvNodeOfTree`, jsonBody)
        .then(response => {    
            if(response.data.code == 'SUCCESS') {

                if(modifiedState.formViewMode != null && modifiedState.formViewMode.length > 0 && modifiedState.formViewMode == "DefineNewTree") {
                    alert(`Successfully defined new Tree "${modifiedState.selectedNodeDisplayName}".`);

                    if(response.data.retrivedLatestTreeNodeID != null && response.data.retrivedLatestTreeNodeID.length > 0) {
                        let latestTreeNodeID = response.data.retrivedLatestTreeNodeID[0][0]['mostRecentTreeID'];
                        modifiedState.addEditNodeModal = false;

                        if(latestTreeNodeID != null && latestTreeNodeID.length > 0) {
                            modifiedState.selectedTreeRootID = latestTreeNodeID;

                            this.getAllTreeParentNodeInfo(modifiedState);

                        } else {
                            modifiedState.selectedTreeRootID = "";
                            this.setState(modifiedState);
                        }
                    } else {
                        modifiedState.selectedTreeRootID = "";
                        this.setState(modifiedState);
                    }
                } else if(modifiedState.formViewMode != null && modifiedState.formViewMode.length > 0 && modifiedState.formViewMode == "AddNewToilet") {
                    if(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree"){
                        alert(t(IDS_AlertOnAddingNewToilet) + ` "${modifiedState.selectedNodeDisplayName}".`);
                    } else{
                        alert(`Successfully Added New Toilet "${modifiedState.selectedNodeDisplayName}".`);
                    }

                    modifiedState.addEditNodeModal = false;

                    if(response.data.retrivedLatestTreeNodeID != null && response.data.retrivedLatestTreeNodeID.length > 0) {
                        let latestTreeNodeID = response.data.retrivedLatestTreeNodeID[0][0]['mostRecentTreeID'];
                        modifiedState.addEditNodeModal = false;

                        if(latestTreeNodeID != null && latestTreeNodeID.length > 0) {
                            modifiedState.selectedTreeRootID = "";

                            // Extract and store whole node info except modified DeviceName as it is.
                            appRelevantDataContextValue.onSelectedDevice(latestTreeNodeID, modifiedState.selectedNodeDisplayName, false, true, false);
                            appRelevantDataContextValue.onEnabledTreeStructureSettings(true, true);

                            // Adding Node:
                            this.props.setTreeDefinitionFlag(true)

                            this.getAllTreeParentNodeInfo(modifiedState);

                        } else {
                            modifiedState.selectedTreeRootID = "";
                            this.setState(modifiedState);
                        }
                    } else {
                        modifiedState.selectedTreeRootID = "";
                        this.setState(modifiedState);
                    }
                } else if(modifiedState.formViewMode != null && modifiedState.formViewMode.length > 0 && modifiedState.formViewMode == "RemoveNode" 
                            && modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree") {
                    alert( t(IDS_AlertOnRemoveNode) + ` "${modifiedState.selectedAddEditRmvNodeTitleToDisplay}".`);

                    // modifiedState.selectedAddEditRmvNodeTitleToDisplay = "";
                    modifiedState.addEditNodeModal = false;

                    const { nodeList, deviceList } = this.props;
                    const firstToiletNode = nodeList[0][0];
                    const deviceListRedux = deviceList[0];

                    const removedTreeNodeID = nodeList[0].find(toilet => toilet.title == modifiedState.selectedAddEditRmvNodeTitleToDisplay)

                    // Removing Node:
                    if(removedTreeNodeID) {
                        if(nodeList[0] != null && nodeList[0].length == 1) {
                            // Setting to First Independent Device in Tree if there is only ONE Toilet Node that is being Removed:
                            appRelevantDataContextValue.onSelectedDevice(deviceList[0][0].DeviceID, deviceList[0][0].DeviceName, false, false, true, appRelevantDataContextValue.selectedNodeInfo.deviceType, null, deviceList[0][0].selectedNodeDeviceType, "");
                            this.props.setTreeDefinitionFlag(true)
                        } else {
                            // Setting to the First Node in Tree if the Node being Removed is MORE THAN ONE IN COUNT IN THE TREE DEFINITION:
                            this.props.setTreeDefinitionFlag(true)
                            appRelevantDataContextValue.onSelectedDevice(firstToiletNode.id, firstToiletNode.title, false, firstToiletNode.isToiletNode, !firstToiletNode.isToiletNode, firstToiletNode.deviceType, firstToiletNode.parentID, firstToiletNode.selectedNodeDeviceType, firstToiletNode.nodePath);
                        }
                    } 

                    // Removing Device:
                    if(removedTreeNodeID == undefined) {
                        const matchedDevice = deviceListRedux.find(device => device.DeviceName == modifiedState.selectedAddEditRmvNodeTitleToDisplay)
                        if(matchedDevice) {
                            const setTreeNode = nodeList[0].find(toilet => toilet.id == matchedDevice.TreeParentID)
                            if(setTreeNode) {
                                appRelevantDataContextValue.onSelectedDevice(setTreeNode.id, setTreeNode.title, false, setTreeNode.isToiletNode, !setTreeNode.isToiletNode, setTreeNode.deviceType, setTreeNode.parentID, setTreeNode.selectedNodeDeviceType, setTreeNode.nodePath);
                                this.props.setTreeDefinitionFlag(true)
                            }
                        }
                    }

                    // Extract and store whole node info except modified DeviceName as it is.
                    appRelevantDataContextValue.onEnabledTreeStructureSettings(true, true);

                    modifiedState.isDeviceRemoved = (isDevice != null && isDevice == true) ? true : false;
                    this.getAllTreeParentNodeInfo(modifiedState);

                } else if(modifiedState.formViewMode != null && modifiedState.formViewMode.length > 0 && modifiedState.formViewMode == "RemoveNode" 
                            && (modifiedState.InvokedFromDeviceTree == null && modifiedState.InvokedFromDeviceTree != "deviceTree")) {
                    alert(`Successfully removed selected node "${modifiedState.selectedAddEditRmvNodeTitleToDisplay}" from Tree.`);

                    modifiedState.addEditNodeModal = false;

                    if(modifiedState.selectedTreeRootID == modifiedState.selectedNodeID) {
                        modifiedState.selectedTreeRootID = "";
                        modifiedState.showTreeStructure = false;   
                        this.getAllTreeParentNodeInfo(modifiedState);
                    } else  {
                        this.getSelectedTreeStructure(modifiedState);
                    }
                } else if(modifiedState.formViewMode != null && modifiedState.formViewMode.length > 0 && modifiedState.formViewMode == "EditNode") {
                    if(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree"){
                        alert(t(IDS_AlertOnEditNode) + ` "${modifiedState.selectedAddEditRmvNodeTitleToDisplay}".`);
                    } else{
                        alert(`Successfully edited details of selected "${modifiedState.selectedAddEditRmvNodeTitleToDisplay}" node.`);
                    }

                    modifiedState.addEditNodeModal = false;

                    // Extract and store whole node info except modified DeviceName as it is.
                    appRelevantDataContextValue.onSelectedDevice(modifiedState.selectedNodeID, modifiedState.selectedNodeDisplayName, true);
                    appRelevantDataContextValue.onEnabledTreeStructureSettings(true, true);

                    if(modifiedState.selectedTreeRootID == modifiedState.selectedNodeID) {
                        this.getAllTreeParentNodeInfo(modifiedState);
                    } else  {
                        this.getSelectedTreeStructure(modifiedState);
                    }
                } else if(modifiedState.formViewMode != null && modifiedState.formViewMode.length > 0 && modifiedState.formViewMode == "AddNode") {
                    alert(`Successfully added new node "${modifiedState.selectedNodeDisplayName}" to the Tree.`);
                    modifiedState.addEditNodeModal = false;
                    this.getSelectedTreeStructure(modifiedState);
                } 
                else {
                    modifiedState.addEditNodeModal = false;
                    this.getSelectedTreeStructure(modifiedState);
                }
            } else {
                if(response.data.code == 'SELECTED_NODE_NOT_LEAF_NODE') {
                    alert(`You can not remove node which has child node(s). If you want to remove this node then please remove all the child nodes first.`); 
                } else if(response.data.code == 'SELECTED_NODE_HAS_USER') {
                    alert(`Selected node has User. If you want to remove this node then please remove user(s) from this node.`);               
                } else if(response.data.code == 'SELECTED_DEVICE_HAS_OWNER') {
                    alert(`Selected device has owner. If you want to remove this node then please remove device from user tree of owner.`);               
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';    
                }
                this.setState(modifiedState);
            }
            
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                console.log('Network issues.\nCheck your Internet and Try again later.');
                this.setState(modifiedState);
            }
        }); 
    }

    handleOnchange = (value) => {
        this.setState({
            clrInput: value
        })
    }

    onChangeIsNodeHasDevice = (e) => {
        let modifiedState = this.state;
        modifiedState.errors.others = ""; 
        modifiedState.errors.errSelectTreeName = "";
        modifiedState.isNodeHasDevices = e.target.checked;

        this.setState(modifiedState);
    }

    clearInputField = () => {

        if( this.state.clrInput!= null && this.state.clrInput.length > 0) {
            this.setState({
                clrInput:'',
                bSearch : false 
            })
        }
        
        if(this.state.searchString !=null && this.state.searchString.length > 0) {
            this.setState({
                searchString:''
            })
        }
    }

    handleSearchOnChange = (value, e) => {
        this.setState({ 
            searchString : value,
            bSearch : true 
        });
    }

    handleKeyDown = (e) => {
        if (e.key == 'Enter') {
            // This is just to prevent form submission on pressing "enter".
            e.preventDefault();
            return;
        }
    }

    onChangeDevcID = (e) => {
        let modifiedState = this.state;
        modifiedState.DeviceID = e.target.value;
        modifiedState.DevcIDQRCodeFlag = false;
        modifiedState.errors.others = "";
        modifiedState.errors.errSelectTreeName = "";

        this.setState(modifiedState);
    }

    openQRCameraForDevcID  = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 

        this.setState(prevState => {
            let modifiedState = prevState;

            modifiedState.DevcIDQRCodeFlag = !modifiedState.DevcIDQRCodeFlag;
            // Explicitly Make all state value to blank in order to avoid wrong values.
            modifiedState.DeviceID = "";
            modifiedState.errors = {
                others:"",
            }
            return modifiedState
        })
    }

    handleScanResultOfDeviceID = (data) => {

        if(data) {
            let modifiedState = this.state;
            modifiedState.errors.others = "";
            modifiedState.errors.errSelectTreeName = "";
            modifiedState.DeviceID = data;
            if((modifiedState.DeviceID != null && modifiedState.DeviceID.length > 0)) {
                modifiedState.DevcIDQRCodeFlag = false;
            } else {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
            }
            this.setState(modifiedState);
        }
    }

    handleQRCodeError = (err) => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    onSearchByRadioBtnChange = (e) => {
        
        let modifiedState = this.state;
        modifiedState.errors.others = "";
        modifiedState.errors.errSelectTreeName = "";
        modifiedState.DisplayDeviceInfo = e.target.value;
        this.setState(modifiedState);  
    }

    onClickProceedBut = () => {

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        let modifiedState = this.state;

        if(modifiedState.DeviceID == null || modifiedState.DeviceID.length <= 0) {
            modifiedState.errors.others = "Please Enter or Scan DeviceID.";
            this.setState(modifiedState);
            return;
        } else if (modifiedState.DisplayDeviceInfo == null || modifiedState.DisplayDeviceInfo.length <= 0) {
            modifiedState.errors.others = "Please select Device Display as Child Node or Parent Node Data.";
            this.setState(modifiedState);
            return;
        } else {
            if(modifiedState.formViewMode == "AddToiletNodeWithDevices") {

                this.addNewDeviceWithToilet()

            } else {
                this.addnewDeviceNodeIntoTree()
            }
        }
    }

    addNewDeviceWithToilet = () => {

        let inAppRelevantDataContextValue = this.context;
        let t = inAppRelevantDataContextValue.t;
        let modifiedState = this.state;
        
        let jsonBody = {
            DeviceID: modifiedState.DeviceID,
            selectedTreeId: modifiedState.selectedNodeID,
        }        

        axios.post( `${getAPIHostURL()}/wclient/addNewDeviceWithToilet`, jsonBody)
        .then(response => {    
            if(response.data.code == 'SUCCESS') {
                if(modifiedState.formViewMode != null && modifiedState.formViewMode == "AddToiletNodeWithDevices" ){
                    alert("Successfully added new device node with toilet to the Tree.");
                } else {
                    alert("Successfully Edited selected device node with toilet node information.");
                }
                modifiedState.addDeviceNodeModal = false;
                this.getSelectedTreeStructure(modifiedState);
                // this.getSelectedNodeDevicesInformation(modifiedState.objSelectedNodeInformation, modifiedState.selectedNodeID);                    
            } else {
                if(response.data.code == 'INVALID_DEVICE_STATE') {
                    modifiedState.errors.others = `Specified Device ${modifiedState.DeviceID} cannot be added to this node. Device State is not valid.`;    
                } else if(response.data.code == "INVALID_DEVICEID") {
                    modifiedState.errors.others = 'Invalid DeviceID.';    
                } else if(response.data.code == "DEVICE_DONT_HAVE_PARENT_NODE") {
                    modifiedState.errors.others = `Adding of new device node with toilet information Unsuccessful. Device with ID ${modifiedState.DeviceID} does not have parent node.`;    
                } else if(response.data.code == "DEVICE_PARENT_NODE_IS_NOT_OWNED_TOILET_NODE") {
                    modifiedState.errors.others = `Adding of new device node with toilet information Unsuccessful. Device with ID ${modifiedState.DeviceID} parent node is not owned toilet node.`;    
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';    
                } else if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';    
                }
                this.setState(modifiedState);
            }
            
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                console.log('Network issues.\nCheck your Internet and Try again later.');
                this.setState(modifiedState);
            }
        }); 
    }

    addnewDeviceNodeIntoTree = () => {

        let inAppRelevantDataContextValue = this.context;
        let t = inAppRelevantDataContextValue.t;
        let modifiedState = this.state;
        
        let loggedInUserID = inAppRelevantDataContextValue.loggedInUserInfo.userID;

        let selectedTreeId = modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree" 
                            ? modifiedState.selectedNodeID : modifiedState.selectedTreeRootID;
        let invokedFrom = modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree" 
                            ? "AddDeviceFromUserTree" : modifiedState.formViewMode;
        let devcDisplayAsChildeOrParentNode = modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree" 
                            ? "DisplayAsChildNode" : modifiedState.DisplayDeviceInfo;

        let jsonBody = {
            DeviceID: modifiedState.DeviceID,
            DevcDisplayAsChildeOrParentNode: devcDisplayAsChildeOrParentNode,
            parentNodeID: modifiedState.selectedNodeID,
            selectedTreeId: selectedTreeId,
            invokedFrom: invokedFrom,
            loggedInUserID: loggedInUserID
            // InvokedFromTreeDefOrDeviceTree: modifiedState.InvokedFromDeviceTree == null ? "TreeDefination" : modifiedState.InvokedFromDeviceTree
        }

        axios.post( `${getAPIHostURL()}/wclient/addnewDeviceNodeIntoTree`, jsonBody)
        .then(response => {    
            if(response.data.code == 'SUCCESS') {
                if(modifiedState.formViewMode != null && (modifiedState.formViewMode == "AddNode" || modifiedState.formViewMode == "AddDeviceFromUserTree")){
                    if(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree.length > 0 && modifiedState.InvokedFromDeviceTree == "deviceTree"){
                        alert(t(IDS_AlertOnAddingNewDeviceNode));
                    } else{
                        alert("Successfully added new device node to the Tree.");
                    }
                } else {
                    alert("Successfully Edited selected device node information.");
                }
                modifiedState.addDeviceNodeModal = false;

                // Extract and store whole node info except modified DeviceName as it is.
                if(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree"){
                    inAppRelevantDataContextValue.onSelectedDevice(modifiedState.selectedNodeID, "", false, true, false);
                    
                    // Adding Device:
                    this.props.setTreeDefinitionFlag(true)
                } else {
                    inAppRelevantDataContextValue.onSelectedDevice(modifiedState.DeviceID, "", true, true, [], modifiedState.selectedNodeID, "");
                }

                inAppRelevantDataContextValue.onEnabledTreeStructureSettings(true, true);

                if(modifiedState.InvokedFromDeviceTree != null && modifiedState.InvokedFromDeviceTree == "deviceTree"){
                    this.getSelectedTreeStructure(modifiedState);
                    this.getSelectedNodeDevicesInformation(modifiedState.objSelectedNodeInformation, modifiedState.selectedNodeID);                    
                } else{
                    this.getSelectedNodeDevicesInformation(modifiedState.objSelectedNodeInformation, modifiedState.selectedNodeID);                    
                }
                
            } else {

                if(response.data.code == 'INVALID_DEVICE_STATE') {
                    modifiedState.errors.others = 'Specified device cannot be added to this node. Device State is not valid.';    
                } else if(response.data.code == 'DEVICEID_ALREADY_ATTACHED_WITH_OTHER_TREE_NODE') {
                    modifiedState.errors.others = 'Specified device cannot be added to this node. Device already attached to a Tree Node.';    
                } else if(response.data.code == "HAS_DEVC_KEY_MISSING") {
                    modifiedState.errors.others = 'You can not add device to this node, some data is missing.';    
                }else if(response.data.code == "INVALID_DEVICEID") {
                    modifiedState.errors.others = 'Invalid DeviceID.';    
                } else if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';    
                } else if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
                } else if(response.data.code == 'DEVICE_NOT_OWNED_BY_LOGGEDINUSER') {
                    modifiedState.errors.others = 'Specified device cannot be added to this node. Device State is not owned by loggedIn user.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';    
                }
                this.setState(modifiedState);
            }
            
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                console.log('Network issues.\nCheck your Internet and Try again later.');
                this.setState(modifiedState);
            }
        }); 

    }
     
    render() {
        let inAppRelevantDataContextValue = this.context;
        let t = inAppRelevantDataContextValue.t;

        return (
            <div className="container">
                <div className="row justify-content-center">
                    {/* <div className="container col-lg-10 col-lg-offset-2
                                              col-md-12"> */}
                    <div className="container">
                        <div className="modal-body p-4 bg-white my-3 rounded-xl">
                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1rem"}}>
                                {this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree == "deviceTree" ? t(IDS_AddEditRmvOwnedToiletsAndDevices) : "Tree Definition"}
                            </div>
                            {
                                (this.state.bOnlyGenrlUsrPvg == true )
                                ? null
                                :
                                <div style={{textAlign:"right", paddingBottom:"0.5rem"}}>
                                    <button 
                                        className='buttonStyle'
                                        onClick = {this.onclickDefineNewTree}>
                                        {this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree == "deviceTree" ? t(IDS_AddNewToilet) : "Define New Tree"} 
                                    </button>
                                </div>

                            }
                            {this.state.InvokedFromDeviceTree == null || this.state.InvokedFromDeviceTree != "deviceTree" ?
                                <div style={{border: "1px solid var(--primaryColor)", borderRadius:"0.5rem"}} hidden = {this.state.InvokedFromDeviceTree == "deviceTree" ? true: false}>
                                    <div className="dropDownOfModelInfo">
                                        <div className="selectedModelLable">Select Tree Name:</div>
                                        <div style={{marginLeft:"1rem"}}>
                                            <select className="ProdInfoInput"
                                                    style={{width:"100%", background:"white", height:"2rem"}} 
                                                    required
                                                    value={this.state.selectedTreeRootID}
                                                    onChange={this.onChangeTreeName}
                                            >
                                                <option value="" select= "true" disabled>Select TreeName...</option> 
                                                {(this.state.arrAllTreeNameIDAndDesc).map((singleTreeName, index) => <option key={index} value={singleTreeName['NodeId']}>{singleTreeName['ParentNodeDispName']}</option>)}

                                            </select>
                                        </div>
                                    </div>
                                    {this.state.displayTreeDescription != null && this.state.displayTreeDescription.length > 0 &&
                                        <div style={{border:"1px solid var(--primaryColor)", color:"green", fontSize:"0.8rem", alignItems: "center", 
                                                textAlign: "center", paddingLeft: "0.3rem", paddingRight: "0.3rem", backgroundColor:"rgb(168,204,168,0.3)", 
                                                borderRadius:"0.3rem", marginBottom:"0.5rem", marginLeft: "0.5rem", marginRight: "0.5rem"
                                            }}
                                        >
                                            {this.state.displayTreeDescription}
                                        </div>
                                    }
                                    <div>
                                        <button type="button" 
                                                style={{textTransform:"capitalize"}}
                                                className="trackDevcbtn" 
                                                onClick = {this.onclickShowSelectedTreeStructure}>
                                                Show Selected Tree Structure
                                        </button>
                                    </div>
                                    <div>
                                        { this.state.errors.others != null && this.state.errors.others.length > 0 ?
                                            <span style={{fontSize:"0.8rem", textAlign:"center", color:"red"}}>{this.state.errors.others}</span>
                                            : null
                                        }
                                        { this.state.errors.errSelectTreeName != null && this.state.errors.errSelectTreeName.length > 0 ?
                                            <span style={{fontSize:"0.8rem", textAlign:"center", color:"red"}}>{this.state.errors.errSelectTreeName}</span>
                                            : null
                                        }
                                    </div> 
                                </div>
                            :   
                                <div>
                                    <hr style={{marginBottom:"0.5rem", marginTop:"0.5rem", height: "0.1rem", color: "var(--primaryColor)"}}></hr>
                                </div>
                            }
                            { this.state.TreeInfoArr != null && this.state.TreeInfoArr.length > 0 
                            ?
                                <div id="treeView" className="SortableDefineTreeView" hidden={!this.state.showTreeStructure}>
                                    { this.state.InvokedFromDeviceTree == null ?
                                        <div style={{marginTop:"0.5rem", marginBottom:"0.5rem"}}>
                                            <div style={{display:"inline-block"}}>
                                                <div style ={{display: "flex", flexDirection: "column"}}>
                                                    <span style = {{position: "relative"}}>
                                                        <input type = "text" 
                                                            placeholder='Search item'
                                                            style = {{fontSize: "14px", padding: "10px 48px", height: "2rem", border: "1px solid #cacaca96",
                                                                borderRadius: "5px", color: "#000", backgroundColor: "#fff", width: "100%"}}
                                                                onChange={(value) => {
                                                                    this.setState({ 
                                                                        searchString : value.target.value,
                                                                        bSearch : true,
                                                                        clrInput: value.target.value
                                                                    })}
                                                                }
                                                            value = {this.state.searchString}
                                                        />
                                                        <span style= {{height: "2rem", width :"48px", position: "absolute", left :"0", top :"0", display: "flex",
                                                                justifyContent: "center", alignItems: "center"}}>
                                                            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div style={{display:"inline-block"}}>
                                                <button onClick={this.clearInputField} className="btnClearInputField"> X </button>
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                    <SortableTree
                                        treeData={this.state.TreeInfoArr}
                                        onChange={TreeInfoArr => this.setState({TreeInfoArr})}
                                        canDrag={false}
                                        searchMethod={this.customSearchMethod}
                                        searchQuery={this.state.searchString}
                                        searchFocusOffset={this.state.searchFocusIndex} 
                                        getNodeKey={({ node }) => node.id}

                                        searchFinishCallback={matches =>
                                            this.setState({
                                            searchFoundCount: matches.length,
                                            searchFocusIndex:
                                                matches.length > 0 ? this.state.searchFocusIndex % matches.length : 0
                                            })
                                        }

                                        generateNodeProps={({ node, path }) => ({
                                            title: (
                                                <div className="tvOuterDiv">
                                                    <div className={node.isSelected ? "tvSelectedNodeTitle" : "tvNodeTitle"}
                                                        id={node.id} 
                                                        data-arr-nodetitle-id={node.id}
                                                        data-arr-isroot={node.isRoot}
                                                        data-arr-nodetitle={node.title}
                                                        data-arr-isnodeselected={node.isSelected}
                                                        data-arr-info={JSON.stringify(node)}
                                                        onClick={this.onClickNodeTitle}
                                                        treenodeselector={node.id}
                                                        > 
                                                        {node.hasDevice == "true" ? <FaDesktop style={{marginRight:"0.3rem"}}/> : <div/>}
                                                        {node.DeviceType == 'PFC' ? <FaWalking style={{marginRight:"0.3rem"}}/> : node.DeviceType == 'HHM' ? <FaHSquare style={{marginRight:"0.3rem"}} /> : <div/>}
                                                        {node.title} 
                                                    </div>          
                                                </div>
                                            ),

                                            buttons: (
                                                [<div>
                                                    <div className="tvStyleForDropDownToggle" > {this.renderDropDownMenu(node)} </div>
                                                </div>]
                                            ),
                                        })}
                                    />
                                </div>
                            : 

                                <div hidden ={this.state.InvokedFromDeviceTree == null || this.state.InvokedFromDeviceTree != "deviceTree"} style={{color: "green"}}>
                                   {this.state.bOnlyGenrlUsrPvg == true ? t(IDS_NoToiletAvailableExtUsr) : t(IDS_NoToiletAvailable) }
                                </div>
                            }
                        </div>  
                    </div>
                    <div>
                        <Modal size="lg" isOpen={this.state.addEditNodeModal} backdrop={this.state.backdrop}>
                            <ModalHeader toggle={this.addNodeFormToggle} style={{textAlign: "center"}}>
                                {this.state.formViewMode == "AddNode" ? <span>Add New Node In Tree</span> : 
                                this.state.formViewMode == "EditNode" && (this.state.InvokedFromDeviceTree != null  && this.state.InvokedFromDeviceTree == "deviceTree") 
                                    ? <span>{t(IDS_EditSelectedNodeInfo)}</span> :  
                                this.state.formViewMode == "EditNode" && (this.state.InvokedFromDeviceTree == null  && this.state.InvokedFromDeviceTree != "deviceTree") 
                                    ? <span>Edit Selected Tree Node</span> : 
                                this.state.formViewMode == "DefineNewTree" ? <span>Define New Tree</span> : 
                                this.state.formViewMode == "AddNewToilet" ? <span>{t(IDS_AddNewToilet)}</span> : ""}
                            </ModalHeader>
                            <ModalBody>
                            {this.state.formViewMode != "AddNewToilet" && this.state.formViewMode != "DefineNewTree" &&
                                <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1rem"}}>
                                        {this.state.selectedAddEditRmvNodeTitleToDisplay}
                                </div>  
                                } 
                                <div className="container col-lg-12 col-md-12">
                                    <form>
                                        {(this.state.InvokedFromDeviceTree != null  && this.state.InvokedFromDeviceTree == "deviceTree" &&
                                            this.state.LoggedInUserFromDeviceTree != null && this.state.LoggedInUserFromDeviceTree.length > 0)
                                            ?
                                            <label style={{ display: "flex", justifyContent:"flex-end", fontSize:"0.7rem"}}><b style={{marginRight:"0.1rem"}}>{t(IDS_Note)}: </b><span style={{color:"var(--errorColor)", fontSize:"1rem", marginLeft:"0.1rem", marginRight:"0.1rem"}}>* </span> {t(IDS_NoteToAddNewToilet)}</label>
                                            :
                                            <label style={{ display: "flex", justifyContent:"flex-end", fontSize:"0.7rem"}}><b style={{marginRight:"0.1rem"}}>Note: </b> Fields marked in <span style={{color:"var(--errorColor)", fontSize:"1rem", marginLeft:"0.1rem", marginRight:"0.1rem"}}>* </span> are compulsory.</label>   
                                        }
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">{this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree == "deviceTree" ? t(IDS_ToiletNodeName) : "Tree Node Display Name:"}
                                                <span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                    <input type='text' name='selectedNodeDisplayName' className=" addCustInputForm"  
                                                        value={this.state.selectedNodeDisplayName}
                                                        onChange={this.handleChange} noValidate 
                                                    />
                                                    {(this.state.errors.selectedNodeDisplayName != null && this.state.errors.selectedNodeDisplayName.length > 0) && 
                                                        <span className='addCustError'>{this.state.errors.selectedNodeDisplayName}</span>}     
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">{this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree == "deviceTree" ? t(IDS_ToiletNodeDescription) : "Tree Node Description:"}</label>
                                                <div className="addCustInputAndError">
                                                    <textarea className="addCustInputForm" name="selectedNodeDescription"   
                                                        value={this.state.selectedNodeDescription}
                                                        onChange={this.handleChange} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {(this.state.isShowNodeHasDeviceCheckbox == true || this.state.formViewMode == "AddNode" || this.state.formViewMode == "DefineNewTree") && this.state.InvokedFromDeviceTree == null ?
                                            <div className="form-group addCustForm">
                                                <div className="inputgroupCustom">
                                                    <input className="addCustFormLabelWithoutRequiredFiled"
                                                        type="checkbox"
                                                        checked = {this.state.isNodeHasDevices}
                                                        onChange={this.onChangeIsNodeHasDevice}
                                                    />
                                                    <span style = {{marginLeft : "0.5rem"}}>Are you going to attach Device(s) to this Node?</span>  
                                                </div>
                                            </div>
                                        :
                                            null
                                        }
                                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                            <div>
                                                <button type="button" className="addCustSavebtn" 
                                                    onClick={this.addNodeFormToggle} name="Back" 
                                                    style={{pointerEvents: "auto"}}
                                                > 
                                                {(this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree.length > 0 && this.state.InvokedFromDeviceTree == "deviceTree" &&
                                                    this.state.LoggedInUserFromDeviceTree != null && this.state.LoggedInUserFromDeviceTree.length > 0)
                                                    ? t(IDS_Back)
                                                    : "Back"
                                                }
                                                </button>
                                            </div >
                                            <div style={{ display: `${this.state.formViewMode == "ViewNode" ? "none" : "block"}` }}>
                                                <button type="button" onClick = {this.onclickSaveButton}className="addCustSavebtn"  name="Save">
                                                {(this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree.length > 0 && this.state.InvokedFromDeviceTree == "deviceTree" &&
                                                    this.state.LoggedInUserFromDeviceTree != null && this.state.LoggedInUserFromDeviceTree.length > 0)
                                                    ? t(IDS_Save)
                                                    : "Save"
                                                }
                                                </button>
                                            </div>
                                        </div>
                                        <div className = "buttonErrorMessage">
                                            {(this.state.errors.others != null && this.state.errors.others.length > 0) && 
                                                <p className='addCustError' style={{textAlign: "center"}}>{this.state.errors.others}</p>}
                                        </div>
                                    </form>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                    <div>
                        <Modal size="lg" isOpen={this.state.addDeviceNodeModal} backdrop={this.state.backdrop}>
                            <ModalHeader toggle={this.addDeviceNodeFormToggle} style={{textAlign: "center"}}>
                                {this.state.formViewMode == "AddNode" && (this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree == "deviceTree")
                                    ? t(IDS_AddNewDevice) 
                                    : this.state.formViewMode == "AddNode" && (this.state.InvokedFromDeviceTree == null && this.state.InvokedFromDeviceTree != "deviceTree")
                                    ? "Add New Device Node" 
                                    : this.state.formViewMode == "AddToiletNodeWithDevices" 
                                    ? "Add Toilet Node with devices" 
                                    : (this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree == "deviceTree") 
                                    ? t(IDS_ViewSelectedDeviceInfo)
                                    : "View Selected Node Information"
                                }
                            </ModalHeader>
                            <ModalBody> 
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="container col-lg-12 col-sm-offset-2
                                                                col-md-12">
                                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1rem"}}>
                                                {this.state.selectedAddEditRmvNodeTitleToDisplay}
                                            </div>
                                            <div className="modal-body box"> 
                                                <div>
                                                    {this.state.InvokedFromDeviceTree !== null && this.state.InvokedFromDeviceTree === "deviceTree" && this.state.formViewMode === "AddNode"
                                                    && 
                                                        <div style={{textAlign:"left", fontSize:"0.8rem", border:"1px solid var(--primaryColor)", borderRadius:"0.5rem", padding:"0.5rem"}}>
                                                            {<div style={{fontWeight:"bold"}}>{t(IDS_Note)}:</div>}      
                                                            {<ul><li>{t(IDS_NoteToAddNewDevice)}</li></ul>} 
                                                        </div>
                                                    }
                                                    {/* {this.state.InvokedFromDeviceTree === null && this.state.InvokedFromDeviceTree !== "deviceTree" &&
                                                    <div style={{textAlign:"left", fontSize:"0.8rem", border:"1px solid var(--primaryColor)", borderRadius:"0.5rem", padding:"0.5rem"}}>
                                                        <div style={{fontWeight:"bold"}}>{t(IDS_Note)}:</div>        
                                                            <ul>
                                                                <li>Display as Child Node: The Added Device will appear as a Child of this Node.</li>
                                                                <li>Display as Parent Node Data: The Device will Not appear as a Child Node. Only its Data will directly appear as part of the Parent Node.</li>
                                                            </ul>
                                                    </div>
                                                    } */}
                                                    <label className="reg-form-label" style={{width:"100%", marginTop:"0.5rem"}} >
                                                    { (this.state.formViewMode == "ViewNode") 
                                                        ? "Device ID:"
                                                        : (this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree.length > 0 && this.state.InvokedFromDeviceTree == "deviceTree")
                                                        ? t(IDS_ScanQRCodeOfDeviceID)
                                                        : "Scan QR Code of Device ID or Enter the same:"
                                                    }
                                                    </label>
                                                    <input style={{width:"100%"}}
                                                        className="input-form"
                                                        value= {this.state.DeviceID}
                                                        onChange = {this.onChangeDevcID}
                                                        onKeyDown={this.handleKeyDown}
                                                        disabled = {this.state.formViewMode == "ViewNode"}
                                                        required
                                                    />
                                                    <label onClick= {this.openQRCameraForDevcID} className = "qrcodeTextBtn" hidden={this.state.formViewMode == "ViewNode"}>
                                                        <FaQrcode className = "qrcodeTextBtnIcon"/>
                                                    </label>
                                                    <div>
                                                        {(this.state.DevcIDQRCodeFlag == true)
                                                        ?
                                                            // <div style={{display: "flex", justifyContent: "center"}}>
                                                            //     <QrReader
                                                            //         scanDelay={300}
                                                            //         onResult={(result, error) => {
                                                            //             if (!!result) {
                                                            //                 this.handleScanResultOfDeviceID(result?.text);
                                                            //             }
                                                            //           }}
                                                            //         className = "QRCodeCamBoxForModalAndContainer"
                                                            //     />
                                                            // </div>
                                                            <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                                marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                                marginRight: "auto"}}>
                                                                <QrScanner
                                                                    scanDelay={300}
                                                                    onResult={(result, error) => {
                                                                        if (!!result) {
                                                                            this.handleScanResultOfDeviceID(result?.text);
                                                                        }
                                                                    }}
                                                                    className = "QRCodeCamBoxForModalAndContainer"
                                                                />
                                                            </div>
                                                        :
                                                            <div/>
                                                        }
                                                    </div>
                                                    {/* {this.state.InvokedFromDeviceTree == null && this.state.InvokedFromDeviceTree != "deviceTree" &&
                                                        <div className="trackRadioDiv" style={{marginTop:"1rem"}}>
                                                            <div className = "trackradioBox">
                                                                <div>
                                                                    <input type='radio' name='DisplayDevcInfo'
                                                                        id = "DisplayAsParentNodeData"
                                                                        value= "DisplayAsParentNodeData"
                                                                        onChange={this.onSearchByRadioBtnChange} noValidate 
                                                                        defaultChecked={this.state.DisplayDeviceInfo == "DisplayAsParentNodeData" ? true : false}
                                                                    /> 
                                                                    <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Display as Parent Node Data</span>
                                                                </div>
                                                                <div>
                                                                    <input type='radio' name='DisplayDevcInfo'  
                                                                        id = "DisplayAsChildNode" 
                                                                        value= "DisplayAsChildNode"
                                                                        onChange={this.onSearchByRadioBtnChange} noValidate 
                                                                        defaultChecked={this.state.DisplayDeviceInfo == "DisplayAsChildNode" ? true : false}
                                                                    />
                                                                    <span style={{marginLeft: "0.3rem"}}> Display as Child Node</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    } */}
                                                    <div style={{display:"flex", marginTop: "1rem", justifyContent: "center", flexDirection:"column"}}>
                                                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                                            <div>
                                                                <button type="button" className="addCustSavebtn" 
                                                                    onClick={this.addDeviceNodeFormToggle} name="Back" 
                                                                    style={{pointerEvents: "auto"}}
                                                                > 
                                                                    {(this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree.length > 0 && this.state.InvokedFromDeviceTree == "deviceTree" &&
                                                                        this.state.LoggedInUserFromDeviceTree != null && this.state.LoggedInUserFromDeviceTree.length > 0)
                                                                        ? t(IDS_Back)
                                                                        : "Back"
                                                                    }
                                                                </button>
                                                            </div >
                                                            <div style={{ display: `${this.state.formViewMode == "ViewNode" ? "none" : "block"}` }}>
                                                                <button type="button" onClick = {this.onClickProceedBut}className="addCustSavebtn"  name="Save">
                                                                    {(this.state.InvokedFromDeviceTree != null && this.state.InvokedFromDeviceTree.length > 0 && this.state.InvokedFromDeviceTree == "deviceTree" &&
                                                                        this.state.LoggedInUserFromDeviceTree != null && this.state.LoggedInUserFromDeviceTree.length > 0)
                                                                        ? t(IDS_Save)
                                                                        : "Save"
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {(this.state.errors.others != null && this.state.errors.others.length > 0) && 
                                                            <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>}  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

// Map state to props function
const mapStateToProps = (state) => ({
    nodeList: state?.treeNodeList?.nodeList,
    deviceList: state?.deviceList?.devices
});

// Map dispatch-action function to props function
const mapDispatchToProps = (dispatch) => ({
    setTreeDefinitionFlag: (booleanValue) => {
        dispatch(setTreeDefinitionFlag(booleanValue))
    },
});


VcTreeDefinition.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default connect(mapStateToProps, mapDispatchToProps)(VcTreeDefinition);
