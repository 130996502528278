import React, { useState, useEffect, useContext } from 'react';
import '../CSS/VcCustomer.css'
import axios from 'axios';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import { getAPIHostURL } from '../../ClientConfig';
import { convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertUTCDateToStrYYYYMMDDHH24MMSS, convertLocalDateToDisplayToday} from '../../vtUtil';
import { FaSearch, FaEdit, FaEye, FaUserPlus } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import { AppRelevantDataContext } from '../../AppContext';
import VcOrder from './VcOrder';
import DateTimePicker from 'react-datetime-picker';
import { IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_AUSrvrIssueReqParamsNotSent } from '../../VcLanguage';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    )
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ data, columns, getCellProps = defaultPropGetter, passedStateVariables}) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // autoResetPage: false,
            initialState: { 
                // set page size based on tables
                pageSize: 10,
                // hide and show columns based on condition
                hiddenColumns: passedStateVariables[0] != null &&  passedStateVariables[0] != "" && 
                    ( passedStateVariables[0] == "CreateOrder" ||  passedStateVariables[0] == "FulFillOrder" ) ? ["edit"] : ["action"],
            },
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    // if the state.goToPage1 is true and if new data is inserted
    // go to page 1
    React.useEffect(() =>{
        if(passedStateVariables[2] == true){
            gotoPage(0)
        }

    },[passedStateVariables[2]]);

     //uses the table header group props for the empty rows so resizing and flex layout still works
     const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;{passedStateVariables[1].length > 0 ? passedStateVariables[2] : "No Data Found on server"}</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr" 
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {
                                            style: cell.column.style,
                                            },
                                            getCellProps(cell),
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                            // when there is no data found 
                        }) 
                        // || <tr style = {{backgroundColor: "white"}}><td ><span  style={{paddingLeft:"1rem", color: "green", display : "flex", justifyContent: "left"}}>{passedStateVariables[1].length > 0 ? passedStateVariables[2] : "No Data Found on server"}</span></td></tr>
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcCustomer (props) {

    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {

        if (id == "lastModifiedTime" || id == "acquiredDateTime"){

            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]
                    
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            })
        } else {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
            })
            
        }
    };

    const [state, setState] = useState({
            modal: false,
            backdrop: 'static',
            custFirstName: "",
            custLastName: "",
            organizationName: "",
            email: "",
            mobileNo: "",
            workPhoneNo: "",
            homePhoneNo: "",
            address1: "",
            address2: "",
            city: "",
            district: "",
            state: "",
            pinCode: "",
            country: "",
            gstin: "",
            comments: "",
            custID: "",
            lastModifiedTime: "",
            LastModifiedUtcDtTmOfReceivedCustomerData: "",
            others: "",
            formViewMode: "",
            salesRepUserID: "",
            createOrderModal: false,
            selectedRowIndex: null,
            selectedCustomerDetailsForCreateOrder: [],
            ArrCountryNames: [],
            ArrStateNames: [],
            page: 0,
            goToPage1: false,
            acquiredDateTime: new Date(),
            invokedFrom: props.propAction,

            originalFirstName: "",
            originalLastName: "",
            originalOrganizationName: "",
            originalEmailID: "",
            originalMobileNo: "",
            originalWorkPhoneNo: "",
            originalHomePhoneNo: "",
            originalCountryCode: "+91",
            originalCountryCodeForHomePhnNo: "+91",
            originalCountryCodeForWorkPhnNo: "+91",
            originalAddress1: "",
            originalAddress2: "",
            originalCity: "",
            originalDistrict: "",
            originalState: "",
            originalPinCode: "",
            originalCountry: "",
            originalGstin: "",
            originalComment: "",
            noDataFound: "",
            CountryInfo: [],
            CountryCode: "+91",
            CountryCodeForHomePhnNo: "+91",
            CountryCodeForWorkPhnNo: "+91",

            errors: { 
                custFirstName: "",
                custLastName: "",
                organizationName: "",
                email: "",
                mobileNo: "",
                workPhoneNo: "",
                homePhoneNo: "",
                contactNo:"",
                address1: "",
                address2: "",
                city: "",
                district: "",
                cityAndDistrict: "",
                state: "",
                pinCode: "",
                country: "",
                gstin: "",
                comments: "",
                others: "",
                acquiredDateTime: "",
            },
            successfulRegisterMsg: '',
            data: [],

            columns: [
                
                {       
                    Header:() => <div className="AlertLogTableHeader">View</div>,
                    accessor: 'view',
                    width: 80,
                    style:({
                        textAlign:"center",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                    disableFilters: true,
                    Cell:  (props) => <span className='deviceNameCell' view={props.row.original.view}>{props.value}</span>
                }, 
                {   
                    Header:() => <div className="AlertLogTableHeader">Edit</div>, 
                    accessor: "edit",
                    width: 80,
                    style:({
                        textAlign: "center",
                        verticalAlign: "middle",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
        
                    }),
                    disableFilters: true,
                    Cell:  (props) => <span className='deviceNameCell' edit={props.row.original.edit}>{props.value}</span>
                },
                {   
                    Header:() => <div className="AlertLogTableHeader">Action</div>, 
                    accessor: "action",
                    width: 175,
                    style:({
                        textAlign:"center",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
        
                    }),
                    disableFilters: true,
                    Cell:  (props) => 
                        <span className='deviceNameCell' action={props.row.original.action}>{props.value}</span>
                    
                },
                {       
                    Header:() => <div className="AlertLogTableHeader">Customer ID</div>,  
                    accessor: 'custID',
                    width: 190,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign: "left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },
                {       
                    Header:() => <div className="AlertLogTableHeader">First Name</div>,  
                    accessor: 'custFirstName',
                    width: 150,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign: "left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },
                {       
                    Header:() => <div className="AlertLogTableHeader">Last Name</div>,  
                    accessor: 'custLastName',
                    width: 150,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },
                {       
                    Header:() => <div className="AlertLogTableHeader">Customer<br/>Company/Org.<br/>Name</div>,  
                    accessor: 'organizationName',
                    width: 150,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },    
                {       
                    Header:() => <div className="AlertLogTableHeader">Email ID</div>,  
                    accessor: 'email',
                    width: 200,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign: "left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },
                {       
                    Header:() => <div className="AlertLogTableHeader">Mobile No</div>,  
                    accessor: 'mobileNo',
                    width: 135,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },
                {       
                    Header:() => <div className="AlertLogTableHeader">Work<br/>Phone No</div>,  
                    accessor: 'workPhoneNo',
                    width: 135,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },   
                {       
                    Header:() => <div className="AlertLogTableHeader">Home<br/>Phone No</div>,  
                    accessor: 'homePhoneNo',
                    width: 135,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign: "left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },
                {       
                    Header:() => <div className="AlertLogTableHeader">Address1</div>,  
                    accessor: 'address1',
                    width: 180,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },
                {       
                    Header:() => <div className="AlertLogTableHeader">Address2</div>,  
                    accessor: 'address2',
                    width: 180,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },  
                {       
                    Header:() => <div className="AlertLogTableHeader">City</div>,  
                    accessor: 'city',
                    width: 135,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },
                {   
                    Header:() => <div className="AlertLogTableHeader">District</div>,  
                    accessor: 'district',
                    width: 135,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                }, 
                {   
                    Header:() => <div className="AlertLogTableHeader">Pincode</div>,  
                    accessor: 'pinCode',
                    width: 110,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem"
        
                    }),
                }, 
                {   
                    Header:() => <div className="AlertLogTableHeader">State</div>,  
                    accessor: 'state',
                    width: 150,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                }, 
                {   
                    Header:() => <div className="AlertLogTableHeader">Country</div>,  
                    accessor: 'country',
                    width: 135,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                }, 
                {   
                    Header:() => <div className="AlertLogTableHeader">GSTIN</div>,  
                    accessor: 'gstin',
                    width: 170,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },  
                {   
                    Header:() => <div className="AlertLogTableHeader">Comments</div>,  
                    accessor: 'comments',
                    width: 150,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },  
                {
                    Header:() => <div className="AlertLogTableHeader">Sales Representative<br/> User ID</div>,  
                    accessor: 'salesRepUserID',
                    width: 200,
                    filter: filterCaseInsensitive,
                    style:({
                        textAlign: "left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },

                { 
                    Header:() => <div className="AlertLogTableHeader">Acquired<br/>DateTime</div>, 
                    Cell: (props) => getCustomizedTodaysDate(props.value),
                    accessor: 'acquiredDateTime',
                    filter: filterCaseInsensitive,
                    sortType: (firstRow, secondRow, columnId) => {
                        const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                        const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                        return rowFirst > rowSecond ? 1 : -1
                    },
                    width: 190,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                }, 
                
                { 
                    Header:() => <div className="AlertLogTableHeader">Last<br/>Modified Time</div>, 
                    Cell: (props) => getCustomizedTodaysDate(props.value),
                    accessor: 'lastModifiedTime',
                    filter: filterCaseInsensitive,
                    sortType: (firstRow, secondRow, columnId) => {
                        const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                        const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                        return rowFirst > rowSecond ? 1 : -1
                    },
                    width: 190,
                    style:({
                        textAlign:"left",
                        paddingLeft: "1rem",
                        textOverflow: "ellipsis", 
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "2.6rem"
                    }),
                },                    
            ],     
        });

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return "Today " + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    // function will call on cliking of eye icon, setting the flag formViewMode to viewMode to display form in readonly only.
    // fetching the value from react table selected customer details row and set it in the state.
    // setting modal value to true to display Form view only mode.
    const onViewCustDetails = (rowInfo, column) => {

        let modifiedState = state;
        modifiedState.formViewMode = "viewMode";
        modifiedState.custID = rowInfo.original.custID;
        modifiedState.custFirstName = rowInfo.original.custFirstName;
        modifiedState.custLastName = rowInfo.original.custLastName;
        modifiedState.organizationName = rowInfo.original.organizationName;
        modifiedState.email = rowInfo.original.email;
        modifiedState.mobileNo = rowInfo.original.mobileNo.split(" ")[1];
        modifiedState.CountryCode = rowInfo.original.mobileNo.split(" ")[0];
        modifiedState.workPhoneNo = rowInfo.original.workPhoneNo.split(" ")[1];
        modifiedState.CountryCodeForWorkPhnNo = rowInfo.original.workPhoneNo.split(" ")[0];
        modifiedState.homePhoneNo = rowInfo.original.homePhoneNo.split(" ")[1];
        modifiedState.CountryCodeForHomePhnNo = rowInfo.original.homePhoneNo.split(" ")[0];
        modifiedState.state = rowInfo.original.state;
        modifiedState.address1 = rowInfo.original.address1;
        modifiedState.address2 = rowInfo.original.address2;
        modifiedState.city = rowInfo.original.city;
        modifiedState.district = rowInfo.original.district;
        // modifiedState.state = rowInfo.original.state;
        modifiedState.pinCode = rowInfo.original.pinCode;
        modifiedState.country = rowInfo.original.country;
        modifiedState.gstin = rowInfo.original.gstin;
        modifiedState.acquiredDateTime = rowInfo.original.acquiredDateTime != null && new Date(rowInfo.original.acquiredDateTime);
        modifiedState.comments = rowInfo.original.comments;
        modifiedState.modal = true;

        if(modifiedState.country != null || modifiedState.country.length > 0) {
            getStatesOnSelectedCountry();
        }

        setState({...modifiedState});
    }

    // function will call on cliking of edit icon, setting the flag formViewMode to editMode to display form in Editable screen.
    // fetching the value from react table to allow edting the exsiting details of selected customer.
    // setting modal value to true to display Form in Edit mode.
    // also setting customer original details(before any changes) to state to compaire it latter on with value of changed fields
    // to propmpt confirmation while saving edited fields.
    const onEditCustDetails = (rowInfo, column) => {
        let modifiedState = state;
        modifiedState.formViewMode = "editMode";

        modifiedState.custFirstName = rowInfo.original.custFirstName;
        modifiedState.custLastName = rowInfo.original.custLastName;
        modifiedState.organizationName = rowInfo.original.organizationName;
        modifiedState.email = rowInfo.original.email;
        modifiedState.mobileNo = (rowInfo.original.mobileNo.split(" ")[1] != null && rowInfo.original.mobileNo.split(" ")[1].length > 0) ? rowInfo.original.mobileNo.split(" ")[1] : rowInfo.original.mobileNo;
        modifiedState.workPhoneNo = (rowInfo.original.workPhoneNo.split(" ")[1] != null && rowInfo.original.workPhoneNo.split(" ")[1].length > 0) ? rowInfo.original.workPhoneNo.split(" ")[1] : rowInfo.original.workPhoneNo;
        modifiedState.homePhoneNo = (rowInfo.original.homePhoneNo.split(" ")[1] != null && rowInfo.original.homePhoneNo.split(" ")[1].length > 0) ? rowInfo.original.homePhoneNo.split(" ")[1] : rowInfo.original.homePhoneNo;
        modifiedState.state = rowInfo.original.state;
        modifiedState.address1 = rowInfo.original.address1;
        modifiedState.address2 = rowInfo.original.address2;
        modifiedState.city = rowInfo.original.city;
        modifiedState.district = rowInfo.original.district;
        // modifiedState.state = rowInfo.original.state;
        modifiedState.CountryCode = (rowInfo.original.mobileNo.split(" ")[0] != null && rowInfo.original.mobileNo.split(" ")[0].length > 0 && rowInfo.original.mobileNo.startsWith("+"))? rowInfo.original.mobileNo.split(" ")[0] : "+91";
        modifiedState.CountryCodeForHomePhnNo = (rowInfo.original.homePhoneNo.split(" ")[0] != null && rowInfo.original.homePhoneNo.split(" ")[0].length > 0 && rowInfo.original.homePhoneNo.startsWith("+")) ? rowInfo.original.homePhoneNo.split(" ")[0] : "+91";
        modifiedState.CountryCodeForWorkPhnNo = (rowInfo.original.workPhoneNo.split(" ")[0] != null && rowInfo.original.workPhoneNo.split(" ")[0].length > 0 && rowInfo.original.workPhoneNo.startsWith("+")) ? rowInfo.original.workPhoneNo.split(" ")[0] : "+91";
        modifiedState.pinCode = rowInfo.original.pinCode;
        modifiedState.country = rowInfo.original.country;
        modifiedState.gstin = rowInfo.original.gstin;
        modifiedState.comments = rowInfo.original.comments;
        modifiedState.custID = rowInfo.original.custID;
        modifiedState.acquiredDateTime = rowInfo.original.acquiredDateTime != null && new Date(rowInfo.original.acquiredDateTime);
        modifiedState.lastModifiedTime = rowInfo.original.lastModifiedTime;

        modifiedState.originalFirstName = rowInfo.original.custFirstName;
        modifiedState.originalLastName = rowInfo.original.custLastName;
        modifiedState.originalOrganizationName =rowInfo.original.organizationName;
        modifiedState.originalEmailID = rowInfo.original.email;
        modifiedState.originalMobileNo = (rowInfo.original.mobileNo.split(" ")[1] != null && rowInfo.original.mobileNo.split(" ")[1].length > 0) ? rowInfo.original.mobileNo.split(" ")[1] : rowInfo.original.mobileNo;
        modifiedState.originalWorkPhoneNo = (rowInfo.original.workPhoneNo.split(" ")[1] != null && rowInfo.original.workPhoneNo.split(" ")[1].length > 0) ? rowInfo.original.workPhoneNo.split(" ")[1] : rowInfo.original.workPhoneNo;
        modifiedState.originalHomePhoneNo = (rowInfo.original.homePhoneNo.split(" ")[1] != null && rowInfo.original.homePhoneNo.split(" ")[1].length > 0) ? rowInfo.original.homePhoneNo.split(" ")[1] : rowInfo.original.homePhoneNo;
        modifiedState.originalCountryCode = (rowInfo.original.mobileNo.split(" ")[0] != null && rowInfo.original.mobileNo.split(" ")[0].length > 0 && rowInfo.original.mobileNo.startsWith("+"))? rowInfo.original.mobileNo.split(" ")[0] : "";
        modifiedState.originalCountryCodeForHomePhnNo = (rowInfo.original.homePhoneNo.split(" ")[0] != null && rowInfo.original.homePhoneNo.split(" ")[0].length > 0 && rowInfo.original.homePhoneNo.startsWith("+")) ? rowInfo.original.homePhoneNo.split(" ")[0] : "";
        modifiedState.originalCountryCodeForWorkPhnNo = (rowInfo.original.workPhoneNo.split(" ")[0] != null && rowInfo.original.workPhoneNo.split(" ")[0].length > 0 && rowInfo.original.workPhoneNo.startsWith("+")) ? rowInfo.original.workPhoneNo.split(" ")[0] : "";
        modifiedState.originalAddress1 = rowInfo.original.address1;
        modifiedState.originalAddress2 = rowInfo.original.address2;
        modifiedState.originalCity = rowInfo.original.city;
        modifiedState.originalDistrict = rowInfo.original.district;
        modifiedState.originalState = rowInfo.original.state;
        modifiedState.originalCountry = rowInfo.original.country;
        modifiedState.originalPinCode = rowInfo.original.pinCode;
        modifiedState.originalGstin = rowInfo.original.gstin;
        modifiedState.originalComment = rowInfo.original.comments;

        modifiedState.modal = true;

        if(modifiedState.country != null || modifiedState.country.length > 0) {
            getStatesOnSelectedCountry();
        }

        setState({...modifiedState});
    }

    // onClick of Create Order button of Select Customer Page sending selected customer details to create order page by using props.
    const onCreateOrder = (rowInfo, column) => {

        let modifiedState = state;

        modifiedState.selectedCustomerDetailsForCreateOrder = rowInfo.original;
        modifiedState.createOrderModal = true;
        setState({...modifiedState});
    }

    const toggleCreateOrder = () => {
        let modifiedState = state;
        modifiedState.createOrderModal = !modifiedState.createOrderModal;

        setState({...modifiedState});
    }

    //putting the flag formViewMode to Insert Mode(Create new Customer)
    //toggling the Customer create form. 
    const toggle = () => {
        let modifiedState = state;
        modifiedState.modal = !modifiedState.modal
        modifiedState.custID = "";
        modifiedState.custFirstName = "";
        modifiedState.custLastName = "";
        modifiedState.organizationName = "";
        modifiedState.email = "";
        modifiedState.mobileNo = "";
        modifiedState.workPhoneNo = "";
        modifiedState.homePhoneNo = "";
        modifiedState.address1 = "";
        modifiedState.address2 = "";
        modifiedState.city = "";
        modifiedState.district = "";
        modifiedState.state = "";
        modifiedState.pinCode = "";
        modifiedState.country = "";
        modifiedState.gstin = "";
        modifiedState.comments = "";
        modifiedState.acquiredDateTime = new Date();
        modifiedState.CountryCode = "+91";
        modifiedState.CountryCodeForWorkPhnNo = "+91";
        modifiedState.CountryCodeForHomePhnNo = "+91";
        modifiedState.formViewMode = "insertMode";
        modifiedState.ArrStateNames = [];

        modifiedState.errors.custFirstName = "";
        modifiedState.errors.custLastName = "";
        modifiedState.errors.organizationName = "";
        modifiedState.errors.email = "";
        modifiedState.errors.mobileNo = "";
        modifiedState.errors.contactNo = "";
        modifiedState.errors.address1 = "";
        modifiedState.errors.address2 = "";
        modifiedState.errors.cityAndDistrict = "";
        modifiedState.errors.state = "";
        modifiedState.errors.pinCode = "";
        modifiedState.errors.country = "";
        modifiedState.errors.gstin = "";
        modifiedState.errors.comments = "";
        modifiedState.errors.others = "";
        setState({...modifiedState});
    }

    const closeModal = () => {
        toggle();
    }

    useEffect(() =>{
        getLatestCustomerInfo();
        getAllCountryCodeInfo();
    }, []);

    const onChangeAcquiredDateTime = updatedTime => {
        let updatedActiveAcquiredTime = updatedTime;
        let modifiedState = state;

        modifiedState.acquiredDateTime = 
            (updatedActiveAcquiredTime == null) ? modifiedState.acquiredDateTime : updatedActiveAcquiredTime ;

        setState({...modifiedState});
    }

    const onChangeAcquiredDtTmCalendarOrClockClose = () => {

        let modifiedState = state;

        let updatedAcquiredDateTime = modifiedState.acquiredDateTime;

        let currentDateTime = new Date();

        if(updatedAcquiredDateTime != null && (updatedAcquiredDateTime > currentDateTime)) {

            alert('Acquired Date Time cannot be greater than the current date time.' + 
                    '\n It will be set to current date time.');
                            
            modifiedState.acquiredDateTime = currentDateTime;
        }
        setState({...modifiedState});
    }

    //when first time page will load we send LastModifiedUtcDtTmForInsertOrUpdateCustDetails empty, 
    //and retrieved all the Customer data from database and filled out in the Customer table.
    //In case of create new customer and edit existing customer details, we send latest LastModifiedUtcDtTmForInsertOrUpdateCustDetails
    //received from customer table, and axios will retrieved the Customer details next to LastModifiedUtcDtTmForInsertOrUpdateCustDetails.
    //we put latest retrived data on top of the table.
    const getLatestCustomerInfo = (inModifiedState = null) => {
        
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.goToPage1 = false;

        let LastModifiedUtcDtTmForInsertOrUpdateCustDetails;
        //In case of create new customer(flag formViewMode == InsertMode) and edit existing customer(flag formViewMode == "editMode") details, 
        //we send latest LastModifiedUtcDtTmForInsertOrUpdateCustDetails received from Customer table
        //or send empty LastModifiedUtcDtTmForInsertOrUpdateCustDetails.
        if(modifiedState.formViewMode == "editMode" || modifiedState.formViewMode == "insertMode"){
            LastModifiedUtcDtTmForInsertOrUpdateCustDetails = modifiedState.LastModifiedUtcDtTmOfReceivedCustomerData;
        } else {
            LastModifiedUtcDtTmForInsertOrUpdateCustDetails = null;
        }

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        // modifiedState.successfulRegisterMsg = '';

        const jsonBody = {
            lastModifiedTime: LastModifiedUtcDtTmForInsertOrUpdateCustDetails, 
            invokedFrom: modifiedState.invokedFrom,
        };
        axios.post(`${getAPIHostURL()}/wclient/getLatestCustomerInfo`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {    

                if(response.data.retreivedCustomerDetails == null || response.data.retreivedCustomerDetails.length <= 0) {
                    modifiedState.noDataFound = "No Customer Data Found";
                } else {
                    let stateCustomerDetailsArr = [];
                    let stateProductLineDetailsArrForFechingData = [];
                                            
                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing Customer Details data.
                    stateCustomerDetailsArr = [...modifiedState.data]

                    const receiveCustomerDataArr = response.data.retreivedCustomerDetails;
                    let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
                    let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>
                    let createOrderBtn = <button type = "button" className = "createOrderButton">
                                            {props.propAction != null && props.propAction.length > 0 && 
                                                props.propAction == "CreateOrder" ? 
                                                "Add/Edit Orders" : "Fulfill Orders"
                                            }
                                        </button> 

                    for(let i = 0; i < receiveCustomerDataArr.length; i++) {
                        const customerDetails = receiveCustomerDataArr[i];
                        let CustomerlastModifiedLocaleDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(customerDetails.CustLastModifiedTime);
                        let CustomerAcquiredLocaleDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(customerDetails.CustAcquiredDateTime);
                        let singleCustomerDetails = {
                            view: viewEyeIcon,  
                            edit: editIcon,
                            action: createOrderBtn,
                            custID:customerDetails.CustID,
                            custFirstName: customerDetails.FirstName == null ? "" : customerDetails.FirstName,
                            custLastName: customerDetails.LastName == null ? "" : customerDetails.LastName,
                            organizationName: customerDetails.OrganizationName == null ? "" : customerDetails.OrganizationName,
                            email: customerDetails.EmailID == null ? "" : customerDetails.EmailID,
                            mobileNo: customerDetails.MobileNo == null ? "" : customerDetails.MobileNo,
                            workPhoneNo: customerDetails.WorkPhoneNo == null ? "" : customerDetails.WorkPhoneNo,
                            homePhoneNo: customerDetails.HomePhoneNo == null ? "" : customerDetails.HomePhoneNo,
                            address1: customerDetails.Address1 == null ? "" : customerDetails.Address1,
                            address2: customerDetails.Address2 == null ? "" : customerDetails.Address2,
                            city: customerDetails.City == null ? "" : customerDetails.City,
                            district: customerDetails.District == null ? "" : customerDetails.District,
                            pinCode: customerDetails.PinCode == null ? "" : customerDetails.PinCode,
                            state: customerDetails.State == null ? "" : customerDetails.State,
                            country: customerDetails.Country == null ? "" : customerDetails.Country,
                            gstin: customerDetails.GSTIN == null ? "" : customerDetails.GSTIN,
                            comments: customerDetails.Comments == null ? "" : customerDetails.Comments,
                            salesRepUserID: customerDetails.SalesRepUserID == null ? "" : customerDetails.SalesRepUserID,
                            acquiredDateTime: CustomerAcquiredLocaleDateTime,
                            lastModifiedTime: CustomerlastModifiedLocaleDateTime,
                            lastModifiedUtcDtTm : customerDetails.LastModifiedUtcDtTm == null ? "" : customerDetails.LastModifiedUtcDtTm,
                        };

                        //if form is open in insertMode(create new Customer) then just add new retrieved data(new added Customer details) at top of the Customer table.
                        if(modifiedState.formViewMode == "insertMode"){
                            stateCustomerDetailsArr.unshift(singleCustomerDetails);
                            modifiedState.selectedRowIndex = 0;
                            modifiedState.goToPage1 = true;
                            // modifiedState.page = 0;
                        }
                        //if form is open in editMode(edit existing Customer details) then compare all custID in Customer table
                        //with retrieved custID after editing Customer details. If both custId get match then remove the selected Customer data
                        //from customer table, and add new retrived Customer data at top of the Customer table.
                        else if(modifiedState.formViewMode == "editMode"){
                            for(let j = 0; j <stateCustomerDetailsArr.length; j++){
                                if(stateCustomerDetailsArr[j].custID == singleCustomerDetails.custID){
                                    stateCustomerDetailsArr.splice(j, 1);
                                }
                            }
                            modifiedState.selectedRowIndex = 0;
                            modifiedState.goToPage1 = true;
                            // modifiedState.page = 0;
                            stateCustomerDetailsArr.unshift(singleCustomerDetails);
                        }    
                        //on first time page load its will retrieved all Customers details and add it in Customer table,
                        // in order of latest data on top of the Customer table.
                        else { 
                            stateProductLineDetailsArrForFechingData.push(singleCustomerDetails);
                            modifiedState.goToPage1 = false;
                        }
                    } 

                    if(modifiedState.formViewMode == "insertMode"){
                        // stored latest received UTC Date Time of Customer table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedCustomerData = stateCustomerDetailsArr[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateCustomerDetailsArr;
                    } else if(modifiedState.formViewMode == "editMode"){
                        // stored latest received UTC Date Time of Customer table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedCustomerData = stateCustomerDetailsArr[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateCustomerDetailsArr;
                    } else {
                        // stored latest received UTC Date Time of Customer table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedCustomerData = stateProductLineDetailsArrForFechingData[0].lastModifiedUtcDtTm;
                        modifiedState.data = stateProductLineDetailsArrForFechingData;
                    }
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        })
    }

    const insertOrUpdateCustomerInfo = (event) => {
        
        let modifiedState = state;

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let loggedinUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        event.preventDefault();
        // modifiedState.successfulRegisterMsg = '';
        let customerID;
        let confirmationForCustFormEditFileds = "";

        // if flag formviewMode is set "editMode"(edit Customer details) then check which fields are edited by user,
        // and ask a confirmation for that edited fields of customer edit form.
        // if there is no change in form then return a function and give message "There are no changes in Customer Details". 
        // aslo adding customer id of selected customer which user want to edit.
        if(modifiedState.formViewMode == "editMode")
        {   
            customerID = modifiedState.custID;

            if(modifiedState.originalFirstName != modifiedState.custFirstName){
                confirmationForCustFormEditFileds = "First Name,";
            }
            if(modifiedState.originalLastName != modifiedState.custLastName){
                confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Last Name,";
            }
            if(modifiedState.originalOrganizationName != modifiedState.organizationName){
                confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Organization Name,";
            }
            if(modifiedState.originalEmailID != modifiedState.email){
                confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Email ID,";
            }
            if(modifiedState.originalMobileNo != modifiedState.mobileNo){
                confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Mobile No,";
            }
            if(modifiedState.originalHomePhoneNo != modifiedState.homePhoneNo){
                confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Home Phone No,";
            }
            if(modifiedState.originalWorkPhoneNo != modifiedState.workPhoneNo){
                confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Work Phone No,";
            }
            if( modifiedState.mobileNo != null && modifiedState.mobileNo.length > 0 && modifiedState.originalCountryCode != modifiedState.CountryCode){
                confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Country Code Of Mobile No,";
            }
            if( modifiedState.homePhoneNo != null && modifiedState.homePhoneNo.length > 0 &&  modifiedState.originalCountryCodeForHomePhnNo != modifiedState.CountryCodeForHomePhnNo){
                confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Country Code Of Home Phone No,";
            }
            if( modifiedState.workPhoneNo != null && modifiedState.workPhoneNo.length > 0 && (modifiedState.originalCountryCodeForWorkPhnNo != modifiedState.CountryCodeForWorkPhnNo)){
                confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Country Code Of Work Phone No,";
            }
            // if(modifiedState.originalAddress1 != modifiedState.address1){
            //     confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Address1,";
            // }
            // if(modifiedState.originalAddress2 != modifiedState.address2){
            //     confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Address2,";
            // }
            // if(modifiedState.originalCity != modifiedState.city){
            //     confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " City,";
            // }
            // if(modifiedState.originalDistrict != modifiedState.district){
            //     confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " District,";
            // }
            // if(modifiedState.originalPinCode != modifiedState.pinCode){
            //     confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Pincode,";
            // }
            // if(modifiedState.originalState != modifiedState.state){
            //     confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " State,";
            // }
            // if(modifiedState.originalCountry != modifiedState.country){
            //     confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Country,";
            // }
            // if(modifiedState.originalGstin != modifiedState.gstin){
            //     confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " GSTIN,";
            // }
            // if(modifiedState.originalComment != modifiedState.comments){
            //     confirmationForCustFormEditFileds = confirmationForCustFormEditFileds + " Comments,";
            // }
            if(confirmationForCustFormEditFileds != null && confirmationForCustFormEditFileds.length > 0 ){
                if(!window.confirm(`Are you sure you want to change the ${confirmationForCustFormEditFileds} for this customer ?`)){
                    return
                }
            // this check is only for ensue that if no single fileds are edited than its must go in else part and return function 
            //with alert message
            } else if(modifiedState.originalAddress1 != modifiedState.address1 || modifiedState.originalAddress2 != modifiedState.address2 ||
                        modifiedState.originalCity != modifiedState.city || modifiedState.originalDistrict != modifiedState.district || 
                        modifiedState.originalPinCode != modifiedState.pinCode || modifiedState.originalState != modifiedState.state || 
                        modifiedState.originalCountry != modifiedState.country || modifiedState.originalGstin != modifiedState.gstin || 
                        modifiedState.originalComment != modifiedState.comments
                    ){
                        //cosole.log("some fileds are edited")
            } else {
                alert("There are no changes in Customer Details")
                return
            }
        } 
        // if flag formViewMode is not set as EditMode(Edit existing Customer Details) then customerID will send null.
        // in case of create new customer we send Customer Id =  Null
        else {
            customerID = null;    
        }

        // validation of all required fields and combination of two fields as required fields.
        modifiedState.errors.custFirstName = (modifiedState.custFirstName == null || modifiedState.custFirstName == "" || modifiedState.custFirstName.length < 0)
            ? "First Name is required." 
            : "";

        modifiedState.errors.custLastName = (modifiedState.custLastName == null || modifiedState.custLastName == "" || modifiedState.custLastName.length < 0)
            ? "Last Name is required." 
            : "";

        modifiedState.errors.email = (modifiedState.email == null || modifiedState.email == "" || modifiedState.email.length < 0)
            ? "Email ID is required." 
            : "";

        modifiedState.errors.address1 = (modifiedState.address1 == null || modifiedState.address1 == "" || modifiedState.email.address1 < 0)
            ? "Address Line1 is required." 
            : "";

        modifiedState.errors.state = (modifiedState.state == null || modifiedState.state == "" || modifiedState.state.length < 0)
            ? "State is required." 
            : "";

        modifiedState.errors.pinCode = (modifiedState.pinCode == null || modifiedState.pinCode == "" || modifiedState.pinCode.length < 0)
            ? "Pincode is required." 
            : "";

        modifiedState.errors.country = (modifiedState.country == null || modifiedState.country == "" || modifiedState.country.length < 0)
            ? "Country is required." 
            : "";
            
        modifiedState.errors.contactNo = ((modifiedState.mobileNo == null || modifiedState.mobileNo == '' || modifiedState.mobileNo.length < 0) &&
                                          (modifiedState.workPhoneNo == null || modifiedState.workPhoneNo == '' || modifiedState.workPhoneNo.length < 0) &&
                                          (modifiedState.homePhoneNo == null || modifiedState.homePhoneNo == '' || modifiedState.homePhoneNo.length < 0)
                                         )
            ? "One of the three contact numbers are required." 
            : "";
        
        modifiedState.errors.acquiredDateTime = (modifiedState.acquiredDateTime == null || modifiedState.acquiredDateTime == "" || modifiedState.acquiredDateTime.length < 0)
            ? "Acquired DateTime is required." 
            : "";

        modifiedState.errors.cityAndDistrict = ((modifiedState.city == null || modifiedState.city == '' || modifiedState.city.length < 0) &&
                                                (modifiedState.district == null || modifiedState.district == '' || modifiedState.district.length < 0)
                                               )
            ? "Either one of 'City' or 'District' is required."
            : "";

        // if any one of the required fields are not entered by user then return the function and show error message.
        if(modifiedState.errors.custFirstName.length > 0 || modifiedState.errors.custLastName.length > 0 || 
            modifiedState.errors.email.length > 0 || modifiedState.errors.address1.length > 0 || 
            modifiedState.errors.state.length > 0 || modifiedState.errors.pinCode.length > 0 || 
            modifiedState.errors.country.length > 0 || modifiedState.errors.contactNo.length > 0 || 
            modifiedState.errors.mobileNo.length > 0 || modifiedState.errors.cityAndDistrict.length > 0 ||
            modifiedState.errors.acquiredDateTime.length > 0) {
            
            // modifiedState.errors.others = "Please enter all required filled.";
            setState({...modifiedState});
            return
        } else if (!validEmailRegex.test(state.email)) {
            modifiedState.errors.email = "EmailID is not valid!";
            setState({...modifiedState});
        } else {
            // Convert the input dates to UTC before sending to the Server
            let strAcquiredDateTime = (modifiedState.acquiredDateTime != null) ? 
                convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(modifiedState.acquiredDateTime.valueOf()) ) : null;

            // Convert email to lowercase 
            modifiedState.email = (modifiedState.email != null && modifiedState.email.length > 0) ? modifiedState.email.toLowerCase() : "" ;

            const jsonBody = {
                CustID: customerID,
                FirstName: trimStringAndRemoveTrailingComma(modifiedState.custFirstName), 
                LastName: trimStringAndRemoveTrailingComma(modifiedState.custLastName),
                OrganizationName: trimStringAndRemoveTrailingComma(modifiedState.organizationName), 
                EmailID: trimStringAndRemoveTrailingComma(modifiedState.email),
                MobileNo: (modifiedState.mobileNo != null && modifiedState.mobileNo.length > 0) ? modifiedState.CountryCode +" " +modifiedState.mobileNo : modifiedState.mobileNo, 
                WorkPhoneNo: (modifiedState.workPhoneNo != null && modifiedState.workPhoneNo.length > 0) ? modifiedState.CountryCodeForWorkPhnNo +" " +modifiedState.workPhoneNo : modifiedState.workPhoneNo, 
                HomePhoneNo: (modifiedState.homePhoneNo != null && modifiedState.homePhoneNo.length > 0) ? modifiedState.CountryCodeForHomePhnNo +" " +modifiedState.homePhoneNo : modifiedState.homePhoneNo, 
                // state: trimStringAndRemoveTrailingComma(modifiedState.state), 
                Address1: trimStringAndRemoveTrailingComma(modifiedState.address1), 
                Address2: trimStringAndRemoveTrailingComma(modifiedState.address2), 
                City: trimStringAndRemoveTrailingComma(modifiedState.city), 
                District: trimStringAndRemoveTrailingComma(modifiedState.district), 
                State: trimStringAndRemoveTrailingComma(modifiedState.state), 
                PinCode: trimStringAndRemoveTrailingComma(modifiedState.pinCode), 
                Country: trimStringAndRemoveTrailingComma(modifiedState.country), 
                GSTIN: trimStringAndRemoveTrailingComma(modifiedState.gstin),
                Comments: trimStringAndRemoveTrailingComma(modifiedState.comments),
                SalesRepUserID: loggedinUserID,
                CustAcquiredDateTime: strAcquiredDateTime
            };

            axios.post(`${getAPIHostURL()}/wclient/insertOrUpdateCustomerInfo`, jsonBody)
            .then(response => {
                if(response.data.code == 'SUCCESS') {    
                    if(modifiedState.formViewMode == "editMode") {
                        alert(`Successfully 'updated' Customer details with EmailID: ${modifiedState.email}.`);
                    } else {
                        alert(`Successfully registered new Customer with EmailID: ${modifiedState.email}.`);
                    }

                    modifiedState.modal = false;
                    getLatestCustomerInfo(modifiedState)
                } else {
                    if(response.data.code == 'EMAILID_ALREADY_EXIST_FOR_ANOTHER_CUST') {
                        if(modifiedState.formViewMode == "editMode") {
                            alert(`A Customer with Email ID '${modifiedState.email}' already exists for another Customer. You cannot use this Email ID.`)
                        } else {
                            alert(`A Customer with Email ID '${modifiedState.email}' already exists. cannot create new customer.`)
                        }
                    } else if(response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                    else if(response.data.code == 'REQ_PARAMS_MISSING'){
                        modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                setState({...modifiedState});
            })
            .catch(error => {
                console.log(error);
                console.log("Network error:");
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});
                }
            })
        } 
    }

    const handleKeyPress = (e) => {
        const characterCode = e.key
        const characterNumber = Number(characterCode)
        if (characterNumber >= 0 && characterNumber <= 9) {

            if (e.currentTarget.value && e.currentTarget.value.length) {
                return;
            } 
            else if (characterNumber == 0) {
                e.preventDefault()
            }
        } 
        else {
            e.preventDefault()
        }
    }        

    const handleChange = (event) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        event.preventDefault();
        const { name, value } = event.target;
        let errors = state.errors;

        // if(name == "pinCode" && value.length > 10 ) {
        //     return;
        // }
        switch (name) {
            case 'email': 
                errors.email = 
                    value.length > 0 ?
                        (
                            (validEmailRegex.test(value)) ? 
                            '' :  
                            "EmailID is not valid!"
                        )
                    : '';
            break;

            case 'custFirstName': 
                errors.custFirstName = ""       
            break;

            case 'custLastName': 
                errors.custLastName = ""       
            break;

            case 'organizationName':     
                errors.organizationName = ""      
            break;

            case 'mobileNo': 
            errors.contactNo = ""
            errors.mobileNo = 
                (((value.length) > 0 && (value.length) < 4) || (value.length) > 12)
                    ? 'Phone number must be 4 to 12 digits.' : '';      
            break;

            case 'homePhoneNo': 
                errors.contactNo = ""
            break;

            case 'workPhoneNo': 
                errors.contactNo = ""
            break;

            case 'address1': 
                errors.address1 = ""
            break;

            case 'district': 
                errors.cityAndDistrict = ""
            break;

            case 'city': 
                errors.cityAndDistrict = ""
            break;

            case 'pinCode': 
                errors.pinCode = ""
            break;

            default:
            break;
        }

        setState({
            ...state,
            errors, 
            [name]: value,
            // [event.target.name]: event.target.value,   
        });
    }

    const getAllCountryCodeInfo = () => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
    
        let modifiedState = state;
        modifiedState.ArrCountryNames = [];
    
        axios.post(`${getAPIHostURL()}/wclient/getAllCountryCodeInformation`)
        .then(response => {
          if(response.data.code == "SUCCESS") {
              modifiedState.CountryInfo = [];
              modifiedState.CountryInfo = response.data.CountryInfo;
              for(let i=0; i<modifiedState.CountryInfo.length; i++) {
                let SingleCountryName = modifiedState.CountryInfo[i]['Country'];
                modifiedState.ArrCountryNames.push(SingleCountryName);
              }
          } else {
    
            if(response.data.code == "SQL_ERROR"){
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
            }
          }
          setState({...modifiedState});
        })
        .catch(error => {
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            // Tell the user that there are network issues
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            setState({...modifiedState});
        } 
        });
    }

    const getStatesOnSelectedCountry = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
    
        let modifiedState = state;
        
        modifiedState.ArrStateNames = [];

        if (modifiedState.country == null || modifiedState.country.length <= 0) {
            setState({...modifiedState});
            return;
        } else {

            let jsonBody = {
                EnteredCountryName: modifiedState.country,
            };

            axios.post(`${getAPIHostURL()}/wclient/getAllStatesInformation`, jsonBody)
            .then(response => {
                if(response.data.code == "SUCCESS") {
                    modifiedState.StateInfo = [];
                    modifiedState.StateInfo = response.data.StateInfo;
                    for(let i=0; i<modifiedState.StateInfo.length; i++) {
                        let SingleStateName = modifiedState.StateInfo[i]['StateName'];
                        modifiedState.ArrStateNames.push(SingleStateName);
                    }
                } else {      
                    if(response.data.code == "SQL_ERROR") {
                        modifiedState.errors.others = t(IDS_LoginServerIssue);
                    } else if(response.data.code == "REQ_PARAMS_MISSING") {
                        modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                setState({...modifiedState});
            })
            .catch(error => {
                console.log("Network error:");
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    modifiedState.errors.others = t(IDS_RegistNetworkError);
                    setState({...modifiedState});
                } 
            }); 
        }  
    }

    const onChangeCountryCode = (e) => {
        let modifiedState = state;
        modifiedState.CountryCode = e.target.value;
        setState({...modifiedState});
    }

    const onChangeCountryCodeOfHomePhnNo = (e) => {
        let modifiedState = state;
        modifiedState.CountryCodeForHomePhnNo = e.target.value;
        setState({...modifiedState});
    }

    const onChangeCountryCodeOfWorkPhnNo = (e) => {
        let modifiedState = state;
        modifiedState.CountryCodeForWorkPhnNo = e.target.value;
        setState({...modifiedState});
    }

    const onChangeCountry = (e) => {
        let modifiedState = state;
        modifiedState.country = e.target.value;
        modifiedState.errors.country = "";

        if(modifiedState.country != null || modifiedState.country.length > 0 ) {
            getStatesOnSelectedCountry();
            modifiedState.state = "";
        } 

        setState({...modifiedState});
    }

    const onChangeState = (e) => {
        let modifiedState = state;
        modifiedState.state = e.target.value;
        modifiedState.errors.state = "";

        if (modifiedState.country == null || modifiedState.country.length <= 0) {
            modifiedState.state = "";
            modifiedState.errors.country = "Please select Country";
        }

        setState({...modifiedState});
    }

    // const onPageChange = (page) => {
    //     let modifiedState = state;
    //     modifiedState.page = page;
    //     setState({...modifiedState});
    // }

    const {errors, successfulRegisterMsg}=state;
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    return (
        <div>
            <div className = "container col-lg-12" style = {{display: "flex", justifyContent: "space-between", padding: "0.2rem" }}>
                <div>
                    <button type = "button" 
                        className = "btn-md addCustBtn" 
                        style = {{background: "transparent", pointerEvents: "none", border: "none", 
                                display: (props.propAction == "CreateOrder" || props.propAction == "FulFillOrder") ? "none" : "", color:"transparent"}} 
                    >
                        Add New Customer
                    </button>  
                    <FaUserPlus style = {{background: "transparent", border: "none", color:"transparent", display: props.propAction == "CreateOrder" ? "none" : ""}} className = "addCustIcon"/>  
                </div>

                
                <div className = "customerTableHeading">
                    {props.propAction == "CreateOrder" ? "Select Customer for Adding and Editing Order(s)" : 
                        props.propAction == "FulFillOrder" ? "Select Customer for Fulfilling Order(s)" : 
                        "Add, Edit or View All Customer Details"}
                </div> 
                <div>
                    <button type = "button" 
                        className = "btn-md addCustBtn" 
                        style = {{ display: (props.propAction == "CreateOrder" || props.propAction == "FulFillOrder") ? "none" : ""}} 
                        onClick = {toggle}>Add New Customer
                    </button>  
                    <FaUserPlus onClick = {toggle} className = "addCustIcon" 
                        title="Add New Customer" 
                        style={{justifyContent: "flex-end", marginBottom: "0rem", 
                            display: (props.propAction == "CreateOrder" || props.propAction == "FulFillOrder") ? "none" : ""
                        }}
                    />  
                </div>
            </div>

            {/* <div style={{borderStyle: "solid", borderWidth: "1px"}}> */}
            <div className="border border-3 ReactTableStyle">
                <ReactTable
                    data = {state.data}
                    columns = {state.columns}
                    getCellProps={(cellInfo) => {
                        let modifiedState = state;
                        return {
                            onClick: () => {
                                modifiedState.selectedRowIndex = cellInfo.row.index;
                                setState({...modifiedState});

                                if(cellInfo.column.id == "view") {
                                    onViewCustDetails(cellInfo.row, cellInfo.column); 
                                }  else if(cellInfo.column.id == "edit"){
                                    onEditCustDetails(cellInfo.row, cellInfo.column);
                                } else if(cellInfo.column.id == "action"){
                                    onCreateOrder(cellInfo.row, cellInfo.column);
                                }              
                            }, 
                            style: {
                                cursor: '',
                                background: cellInfo.row.index === modifiedState.selectedRowIndex ? '#6c95ba' : '',
                                color: cellInfo.row.index === modifiedState.selectedRowIndex ? 'white' : 'black',
                                alignItems: "center",
                            }
                        }
                    }
                }
                // as React Table is outside the main function, state varaibles cant be accessed there 
                // so passing all the state variables on which react table is dependent through passedStateVariable  
                passedStateVariables = {[props.propAction, state.noDataFound, state.goToPage1]}      
                />
            </div>
            <div>
            <Modal size="xl" isOpen={state.createOrderModal} toggle={toggleCreateOrder} backdrop={state.backdrop}>
                {/* <ModalHeader toggle={toggleCreateOrder} >Create Order</ModalHeader> */}
                <ModalBody>     
                    <VcOrder toggleCreateOrder= {toggleCreateOrder}
                                        propsSelectedCustomerDetails = {state.selectedCustomerDetailsForCreateOrder} 
                                        objPrivilege = {props.objPrivilege}
                                        propAction = {props.propAction} 
                    />

                </ModalBody>
            </Modal>
        </div>
            <Modal size="lg" isOpen={state.modal} backdrop={state.backdrop}>
                <ModalHeader toggle={toggle} style={{textAlign: "center"}}>
                    {state.formViewMode == "viewMode" ? <span> Customer Details View Only </span> : 
                    state.formViewMode == "editMode" ? <span> Edit Customer Details </span> 
                    : <span>Add New Customer Details </span>}
                </ModalHeader>
                <ModalBody>  
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="container col-lg-11 col-md-12">
                                <div className="modal-body addCustbox">
                                    <form 
                                        style={{pointerEvents: state.formViewMode == "viewMode" ? "none" : "auto"}} 
                                    >
                                        <label style={{ display: "flex", justifyContent:"flex-end", fontSize:"0.7rem"}}><b style={{marginRight:"0.1rem"}}>Note: </b> Fields marked in <span style={{color:"var(--errorColor)", fontSize:"1rem", marginLeft:"0.1rem", marginRight:"0.1rem"}}>* </span> are compulsory.</label>
                                        <div className="form-group addCustForm" 
                                            style={state.formViewMode == "insertMode" ? {display: "none"} :{display: "block"}}
                                        >
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">Customer ID:
                                                <span className="addCustRequiredMarkStar"></span></label>
                                                <div className="addCustInputAndError">
                                                    <input type='text' name='custID' className=" addCustInputForm"  
                                                        value={state.custID} noValidate  readOnly = {true}
                                                        style={{color: "#505050", backgroundColor: "#F0F0F0"}}
                                                    />  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">First Name:
                                                <span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                    <input type='text' name='custFirstName' className="addCustInputForm"  
                                                        value={state.custFirstName}
                                                        onChange={handleChange} noValidate 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? 
                                                            {color: "#505050", backgroundColor: "#F0F0F0"} :
                                                            {color: "", backgroundColor: "", }}
                                                    />
                                                    {errors.custFirstName.length > 0 && 
                                                        <span className='addCustError'>{errors.custFirstName}</span>}     
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">Last Name:
                                                <span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                    <input type='text' name='custLastName' className="addCustInputForm"  
                                                        value={state.custLastName}
                                                        onChange={handleChange} noValidate 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } 
                                                                : {color: "", backgroundColor: "", }}
                                                    />
                                                    {errors.custLastName.length > 0 && 
                                                        <span className='addCustError'>{errors.custLastName}</span>}
                                                </div>        
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">Customer Company/Org. Name:</label>
                                                <div style = {{marginTop: "0.7rem"}} className="addCustInputAndError">
                                                    <input type='text' name='organizationName' className="addCustInputForm"
                                                        value={state.organizationName}
                                                        onChange={handleChange} noValidate 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                    />
                                                    {errors.organizationName.length > 0 && 
                                                        <span  className='addCustError'>{errors.organizationName}</span>}   
                                                </div>     
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled"> Email ID:<span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                    <input type='email' name='email' className="addCustInputForm" 
                                                        value={state.email.toLocaleLowerCase()}
                                                        onChange={handleChange} noValidate 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }} 
                                                    />
                                                    {errors.email.length > 0 && 
                                                        <span className='addCustError'>{errors.email}</span>}   
                                                </div> 
                                            </div>
                                        </div>
                                        <div style={{marginBottom: "0.5rem", border: "0.1rem solid #a8cca8", backgroundColor:"#F8F8F8", borderRadius:"0.7rem"}}>
                                            {state.formViewMode == "viewMode" ? <label style={{ display: "flex", justifyContent:"flex-start", fontSize:"0.7rem", marginLeft:"0.5rem"}}></label> : 
                                                <label style={{ display: "flex", justifyContent:"flex-start", fontSize:"0.7rem", marginLeft:"0.5rem"}}><span style={{color:"var(--errorColor)", fontSize:"1rem", marginRight:"0.2rem"}}> * </span> Please enter at least one of your Contact Numbers.</label>
                                            }
                                            <div className="form-group addCustForm">
                                                <div className="inputgroupCustom">
                                                    <label className="addCustFormLabelWithoutRequiredFiled">Mobile No:</label>
                                                    <div className="addCustInputAndError">
                                                        <span style={{display:"flex", justifyContent:"space-around"}}>
                                                            <select className="addCustInputForm" 
                                                                    required
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    value={state.CountryCode}
                                                                    onChange={onChangeCountryCode}
                                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", width:"4.3rem", marginRight:"0.5rem"} : {color: "", backgroundColor: "", width:"4.3rem", marginRight:"0.5rem"}}
                                                            >
                                                                <option value="+91" select= "true">+91</option> 
                                                                {(state.CountryInfo).map((singleCountryCode, index) => 
                                                                <option key={index} value={singleCountryCode["CountryCode"]}>
                                                                {singleCountryCode["CountryCode"]}
                                                                {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                                                {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                                                {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                                                {'\xA0'+singleCountryCode["Country"]}</option>)}
                                                            </select>

                                                            <input type="number" className="addCustInputForm" name="mobileNo" 
                                                                    value={state.mobileNo}
                                                                    onChange={handleChange} 
                                                                    onKeyPress= {handleKeyPress} 
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        />
                                                        </span>

                                                        {errors.mobileNo.length > 0 && 
                                                            <span  className='addCustError'>{errors.mobileNo}</span>}       
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group addCustForm">
                                                <div className="inputgroupCustom">
                                                    <label className="addCustFormLabelWithoutRequiredFiled">Work Phone No:</label>
                                                    <div className="addCustInputAndError">
                                                        <span style={{display:"flex", justifyContent:"space-around"}}>
                                                            <select className="addCustInputForm" 
                                                                    required
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    value={state.CountryCodeForWorkPhnNo}
                                                                    onChange={onChangeCountryCodeOfWorkPhnNo}
                                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", width:"4.3rem", marginRight:"0.5rem"} : {color: "", backgroundColor: "", width:"4.3rem", marginRight:"0.5rem"}}

                                                            >
                                                                <option value="+91" select= "true">+91</option> 
                                                                {(state.CountryInfo).map((singleCountryCode, index) => 
                                                                <option key={index} value={singleCountryCode["CountryCode"]}>
                                                                {singleCountryCode["CountryCode"]}
                                                                {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                                                {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                                                {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                                                {'\xA0'+singleCountryCode["Country"]}</option>)}
                                                            </select>
                                                            <input type="number" className="addCustInputForm" name="workPhoneNo" 
                                                                    value={state.workPhoneNo}
                                                                    onChange={handleChange} 
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                            />
                                                        </span>

                                                        {/* {errors.workPhoneNo.length > 0 && 
                                                            <span  className='addCustError'>{errors.workPhoneNo}</span>}        */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group addCustForm">
                                                <div className="inputgroupCustom">
                                                    <label className="addCustFormLabelWithoutRequiredFiled">Home Phone No:</label>
                                                    <div className="addCustInputAndError">
                                                    <span style={{display:"flex", justifyContent:"space-around"}}>
                                                        <select className="addCustInputForm" 
                                                                    required
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    value={state.CountryCodeForHomePhnNo}
                                                                    onChange={onChangeCountryCodeOfHomePhnNo}
                                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", width:"4.3rem", marginRight:"0.5rem"} : {color: "", backgroundColor: "", width:"4.3rem", marginRight:"0.5rem"}}
                                                            >
                                                                <option value="+91" select="true" >+91</option> 
                                                                {(state.CountryInfo).map((singleCountryCode, index) => 
                                                                <option key={index} value={singleCountryCode["CountryCode"]}>
                                                                {singleCountryCode["CountryCode"]}
                                                                {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                                                {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                                                {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                                                {'\xA0'+singleCountryCode["Country"]}</option>)}
                                                        </select>
                                                        <input type="number" className="addCustInputForm" name="homePhoneNo" 
                                                                    value={state.homePhoneNo}
                                                                    onChange={handleChange} 
                                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        />
                                                    </span>
                                                        {errors.contactNo.length > 0 && 
                                                            <span  className='addCustError'>{errors.contactNo}</span>}     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled"> Address Line1:<span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                <input type="text" className="addCustInputForm" name="address1" 
                                                    value={state.address1}
                                                    onChange={handleChange} 
                                                    // required = "required"
                                                    readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                    style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                />
                                                    {errors.address1.length > 0 && 
                                                        <span  className='addCustError'>{errors.address1}</span>}     
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">Address Line2:</label>
                                                <div className="addCustInputAndError">
                                                    <input type="text" className=" addCustInputForm" name="address2"   
                                                        value={state.address2}
                                                        onChange={handleChange}
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                    />
                                                    {errors.address2.length > 0 && 
                                                        <span  className='addCustError'>{errors.address2}</span>}  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">Country:<span className="addCustRequiredMarkStar">*</span></label>
                                                {/* <div className="addCustInputAndError">
                                                    <input type="text" className=" addCustInputForm" name="country"   
                                                        value={state.country}
                                                        onChange={handleChange} 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                    />
                                                {errors.country.length > 0 && 
                                                    <span  className='addCustError'>{errors.country}</span>}  
                                                </div> */}

                                                <div className="addCustInputAndError">
                                                    <select className="addCustInputForm" 
                                                            type = "text"
                                                            name="country"
                                                            required
                                                            readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                            value={state.country}
                                                            onChange={onChangeCountry}
                                                            style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0"} : {color: "", backgroundColor: ""}}
                                                        > 
                                                                <option value="" select="true" >Select...</option> 
                                                            {(state.ArrCountryNames).map((singleCountryName, index) => 
                                                            <option key={index} value={singleCountryName}>
                                                            {singleCountryName}</option>)}
                                                    </select>
                                                    {errors.country.length > 0 && 
                                                    <span  className='addCustError'>{errors.country}</span>}    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">State:<span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                    {(state.ArrStateNames == null || state.ArrStateNames.length <= 0)
                                                    ?
                                                        <input type="text" className="addCustInputForm" name="state"
                                                            required   
                                                            value={state.state}
                                                            onChange={onChangeState} 
                                                            readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                            style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        />
                                                    :
                                                        <select type="text" className="addCustInputForm" name="state"
                                                            required           
                                                            value={state.state}
                                                            onChange={onChangeState} 
                                                            readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                            style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        >
                                                            <option value="" select="true" >Select...</option> 
                                                            {(state.ArrStateNames).map((SingleStateName, index) => 
                                                            <option key={index} value={SingleStateName}>
                                                            {SingleStateName}</option>)}
                                                        </select>
                                                    }

                                                    {errors.state.length > 0 && 
                                                        <span  className='addCustError'>{errors.state}</span>}    
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{marginBottom: "0.5rem", border: "0.1rem solid #a8cca8", backgroundColor:"#F8F8F8", borderRadius:"0.7rem",}}>
                                        {state.formViewMode == "viewMode" ? <label style={{display: "flex", justifyContent:"flex-start", fontSize:"0.7rem", marginLeft:"0.5rem"}}></label> :
                                            <label style={{ display: "flex", justifyContent:"flex-start", fontSize:"0.7rem", marginLeft:"0.5rem"}}><span style={{color:"var(--errorColor)", fontSize:"1rem", marginRight:"0.2rem"}}> * </span> Please enter at least your City or District.</label>
                                        }
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">District:</label>
                                                <div className="addCustInputAndError">
                                                    <input type="text" className="addCustInputForm" name="district"   
                                                        value={state.district}
                                                        onChange={handleChange} 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        
                                                    />
                                                    {errors.district.length > 0 && 
                                                        <span  className='addCustError'>{errors.district}</span>} 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">City:</label>
                                                <div className="addCustInputAndError">
                                                    <input type="text" className="addCustInputForm" name="city"   
                                                        value={state.city}
                                                        onChange={handleChange} 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                    />
                                                    {errors.cityAndDistrict.length > 0 && 
                                                    <span  className='addCustError'>{errors.cityAndDistrict}</span>} 
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithRequiredFiled">Pincode:<span className="addCustRequiredMarkStar">*</span></label>
                                                <div className="addCustInputAndError">
                                                    <input type="text" className="addCustInputForm" name="pinCode"   
                                                        value={state.pinCode}
                                                        onChange={handleChange} 
                                                        // onKeyPress= {handleKeyPress}
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: "", }}
                                                        //pattern = '\d*'
                                                        //maxlength="10"    
                                                    />
                                                    {errors.pinCode.length > 0 && 
                                                        <span  className='addCustError'>{errors.pinCode}</span>}  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm" style={{marginBottom: ""}}>
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">GSTIN No:</label>
                                                <div className="addCustInputAndError">
                                                    <input type="text" className="addCustInputForm" name="gstin"   
                                                        value={state.gstin}
                                                        onChange={handleChange} 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", } : {color: "", backgroundColor: ""}}
                                                    />
                                                    {errors.gstin.length > 0 && 
                                                        <span  className='addCustError'>{errors.gstin}</span>}  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm">
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">Acquired DateTime:</label>
                                                <div className="addCustInputAndError">
                                                    <DateTimePicker type="text" 
                                                        className = "custAcquirdatetime"
                                                        clearIcon = {null}   
                                                        value = {state.acquiredDateTime}
                                                        onChange = {onChangeAcquiredDateTime}
                                                        onCalendarClose={onChangeAcquiredDtTmCalendarOrClockClose}
                                                        onClockClose={onChangeAcquiredDtTmCalendarOrClockClose}
                                                        format={"yyyy/MM/dd HH:mm:ss"}
                                                        name="acquiredDateTime"
                                                        id="custAcquiredDateTime"
                                                        disabled = {state.formViewMode == "viewMode" || state.formViewMode =="editMode" ? true : false}
                                                    />
                                                    {errors.acquiredDateTime.length > 0 && 
                                                        <span className='addCustError'>{errors.acquiredDateTime}</span>}  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group addCustForm" style={{marginBottom: ""}}>
                                            <div className="inputgroupCustom">
                                                <label className="addCustFormLabelWithoutRequiredFiled">Comments:</label>
                                                <div className="addCustInputAndError">
                                                    <textarea className="addCustInputForm" name="comments"   
                                                        value={state.comments}
                                                        onChange={handleChange} 
                                                        readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                        style={state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0"} : {color: "", backgroundColor: ""}}
                                                    />
                                                    {errors.comments.length > 0 && 
                                                        <span  className='addCustError'>{errors.comments}</span>}  
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                            <div>
                                                <button type="button" className="addCustSavebtn" 
                                                    onClick={closeModal} name="Back" 
                                                    style={{pointerEvents: "auto"}}
                                                > 
                                                Back</button>
                                            </div >
                                            <div style={{ display: `${state.formViewMode == "viewMode" ? "none" : "block"}` }}>
                                                <button type="button" className="addCustSavebtn"  name="Save" onClick={insertOrUpdateCustomerInfo}>
                                                Save</button>
                                            </div>
                                        </div>
                                        <div className = "buttonErrorMessage">
                                            {errors.others.length > 0 && 
                                                <p  className='addCustError' style={{textAlign: "center"}}>{errors.others}</p>}
                                            {successfulRegisterMsg.length > 0 &&
                                                <p style={{color:'green', textAlign: "center"}} className='addCustError'>{successfulRegisterMsg}</p>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default VcCustomer;