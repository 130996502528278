import React, { useState, useEffect, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import { AppRelevantDataContext } from '../../AppContext';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { INCLUDES_ALL_PRODUCT_NAME, CSV_COLUMN_SEPERATOR, CSV_LINE_SEPERATOR, TIMEFRAME_15MINTO30MIN, TIMEFRAME_30MINTO1HOUR, TIMEFRAME_1HOURTO8HOURS, 
    TIMEFRAME_8HOURSTO24HOURS, TIMEFRAME_24HOURSTOALL, TIMEFRAME_ALL } from '../../VcConstants';
import { IDS_TodaySearch } from '../../VcLanguage';
import { convertLocalDateToDisplayToday, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateWithFormatDDMMMYY} from '../../vtUtil';
import { FaSearch} from 'react-icons/fa';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"0.9rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.8rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    )
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data, passedStateVariable}) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // set page size based on tables
            initialState: {pageSize: 10},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;{!passedStateVariable ? "No Data Found." : ""}</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* {page.length > 0 &&  */}
                        {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps(getRowProps(row))}
                                        className = "tr"      
                                        style={{
                                            cursor: '',
                                            background: '',
                                            color: 'black',
                                            alignItems: "center",
                                        }}  
                                    >
                                        {row.cells.map(cell => {
                                        return <td {...cell.getCellProps(
                                            [
                                                {style: cell.column.style,},
                                            ]
                                        )} className="td">{cell.render("Cell")}</td>;
                                        })}
                                    </tr>
                                );
                            }) 
                            // || 
                            // <tr style = {{backgroundColor: "white"}}>
                            //     <td>
                            //         <span  style={{paddingLeft:"1rem", color: "green", display: "flex", justifyContent: "left"}}>{!passedStateVariable ? "No Data Found." : ""}</span>
                            //     </td>
                            // </tr> 
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} class="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcInactiveFieldDevices (props) {

    const searchStringStyle =  {fontSize: "0.8rem"};
    // const textFile = null;

    const context = useContext(AppRelevantDataContext);
    const filterCaseInsensitive = (rows, id, filterValue) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        if(id != "lastDataReceivedAtDtTm") {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
                });
        } else {
            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id];                    
                if(String(t(IDS_TodaySearch).toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase());
                }
            });
        }
    };

    const [state, setState] = useState({
        selectedProdName: INCLUDES_ALL_PRODUCT_NAME,
        selectedTimeFrame: TIMEFRAME_15MINTO30MIN,
        arrProductName: [],
        arrProductsInfo: [],
        prodCode: '',
        prodNameCode: '',
        prodLine: '',
        prodDescription: '',
        arrForDetailsOfInactiveFieldDevices: [],
        AllInactiveFieldDeviceDetails: [],
        ArrForTimeFrames: [TIMEFRAME_15MINTO30MIN, TIMEFRAME_30MINTO1HOUR, TIMEFRAME_1HOURTO8HOURS, 
            TIMEFRAME_8HOURSTO24HOURS, TIMEFRAME_24HOURSTOALL, TIMEFRAME_ALL],
        data: [],
        errors:{
            others:'',
        },
        bChngeBtnName: false,
        noDataFound: "",
        textFile: null,
        column: [
            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Device ID</div>,  
                accessor: 'deviceID',
                filter: filterCaseInsensitive,
                width: 180,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },

            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Device Name</div>,  
                accessor: 'deviceName',
                filter: filterCaseInsensitive,
                width: 340,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            }, 
            { 
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Last Data <br/> Received At</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'lastDataReceivedAtDtTm',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 200,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },  
            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Email <br/> ID</div>,  
                accessor: 'emailId',
                width: 290,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },
            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Phone <br/> No.</div>,  
                accessor: 'phoneNo',
                width: 160,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },
            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>First <br/> Name</div>,  
                accessor: 'firstName',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },
            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Last <br/> Name</div>,  
                accessor: 'lastName',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },
            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Current <br/> State</div>,  
                accessor: 'currentState',
                width: 220,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },
            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Hardware <br/> ID</div>,  
                accessor: 'hardwareID',
                width: 120,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },

            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Hardware <br/> Model</div>,  
                accessor: 'hardwareModel',
                width: 160,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },

            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Firmware <br/> ID</div>,  
                accessor: 'firmwareID',
                width: 120,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },

            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Firmware <br/> Name</div>,  
                accessor: 'firmwareName',
                width: 160,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },
            {       
                Header:() => <div style = {{fontSize : "0.85rem", fontWeight : "600"}}>Firmware <br/> Version</div>,  
                accessor: 'firmwareVersion',
                width: 120,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.4rem"
                }),
            },
        ]
    });

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return "Today " + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    const onChangeProdName = (e) => {
        let modifiedState = state;
        modifiedState.selectedProdName = e.target.value;
        modifiedState.arrForDetailsOfInactiveFieldDevices = [];
        modifiedState.data = [];
        modifiedState.errors.others = "";

        if(modifiedState.selectedProdName == INCLUDES_ALL_PRODUCT_NAME) {
            modifiedState.prodCode = "";
            modifiedState.prodNameCode = "";
            modifiedState.prodLine = "";
            modifiedState.prodDescription = "";

        } else {
            let filteredArrBasedOnProdName = modifiedState.arrProductsInfo.filter((singleProdInfo) => singleProdInfo["ProductName"] == modifiedState.selectedProdName);

            modifiedState.prodCode = filteredArrBasedOnProdName[0]["ProductCode"];
            modifiedState.prodNameCode = filteredArrBasedOnProdName[0]["ProductNameCode"];
            modifiedState.prodLine = filteredArrBasedOnProdName[0]["ProductLine"];
            modifiedState.prodDescription = filteredArrBasedOnProdName[0]["ProductDescription"];
        }
        setState({...modifiedState});
    }

    const onChangeTimeFrame = (e) => {
        let modifiedState = state;
        modifiedState.selectedTimeFrame = e.target.value;
        modifiedState.arrForDetailsOfInactiveFieldDevices = [];
        modifiedState.data = [];
        modifiedState.errors.others = "";
        setState({...modifiedState});
    }

    useEffect(() => {
        getRelevantProdInfo();
    }, []);

    const getRelevantProdInfo = () => {
        let modifiedState = state;
        modifiedState.arrProductName = [];
        modifiedState.arrProductsInfo = [];

        axios.post(`${getAPIHostURL()}/wclient/getProductCategoryAndItsRelevantInfo`)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data["retrievedProdTypeAndItsRelvantInfo"] == null || response.data["retrievedProdTypeAndItsRelvantInfo"].length <=0) {
                    modifiedState.errors.others = 'Product information not present on Server. Please Try again later.'
                } else {
                    modifiedState.arrProductsInfo = response.data["retrievedProdTypeAndItsRelvantInfo"];
                    
                    modifiedState.arrProductName = [...new Set(modifiedState.arrProductsInfo.map(singleProdInfo => singleProdInfo["ProductName"]))];

                    modifiedState.arrProductName.unshift(INCLUDES_ALL_PRODUCT_NAME);
                } 
                setState({...modifiedState});
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                setState({...modifiedState});
            }
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        }); 
    }

    const onClickProceed = () => {

        let modifiedState = state;
        modifiedState.bChngeBtnName = true;

        getInfoForInactiveFieldDevices(modifiedState);

    }

    const onClickDownload = () => {
        let modifiedState = state;

        if(modifiedState.data.length > 0) {
            DownloadCsv(modifiedState.data);
        } else {
            modifiedState.bChngeBtnName = true;
            getInfoForInactiveFieldDevices(modifiedState, true);
        }
    }

    const getInfoForInactiveFieldDevices = (inModifiedState = null, isDownload = false) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.data = [];
        modifiedState.arrForDetailsOfInactiveFieldDevices = [];
        modifiedState.AllInactiveFieldDeviceDetails = [];

        let jsonBody = {
            ProductName: modifiedState.selectedProdName == INCLUDES_ALL_PRODUCT_NAME ? '' : modifiedState.selectedProdName,
            TimeFrame: modifiedState.selectedTimeFrame,
        }

        axios.post(`${getAPIHostURL()}/wclient/getInfoForInactiveFieldDevices`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                
                if(response.data["retreivedInactiveFieldDevices"] == null || response.data["retreivedInactiveFieldDevices"].length <=0) {
                        modifiedState.errors.others = `Inactive Field Devices information is not present for the above selection.`
                } else {
                    modifiedState.errors.others = "";
                    modifiedState.arrForDetailsOfInactiveFieldDevices = response.data["retreivedInactiveFieldDevices"];

                    let receivedDetailsOfInactiveFieldDevices = modifiedState.arrForDetailsOfInactiveFieldDevices.length;

                    for(let i = 0; i < receivedDetailsOfInactiveFieldDevices; i++) {

                        let LastDataReceivedDateTime = (modifiedState.arrForDetailsOfInactiveFieldDevices[i]["LastDataReceivedAtDtTm"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["LastDataReceivedAtDtTm"].length <= 0) ? 
                            null :convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(modifiedState.arrForDetailsOfInactiveFieldDevices[i]["LastDataReceivedAtDtTm"]);

                        let singleInstanceInfo = {
                            deviceID: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["DeviceID"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["DeviceID"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["DeviceID"],
                            deviceName: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["DeviceName"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["DeviceName"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["DeviceName"],
                            currentState: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["CurrentState"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["CurrentState"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["CurrentState"],
                            lastDataReceivedAtDtTm: LastDataReceivedDateTime,
                            hardwareID: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["HardwareID"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["HardwareID"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["HardwareID"],
                            hardwareModel: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["HardwareModel"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["HardwareModel"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["HardwareModel"],
                            firmwareID: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirmwareID"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirmwareID"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirmwareID"],
                            firmwareName: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirmwareName"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirmwareName"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirmwareName"],
                            firmwareVersion: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirmwareVersion"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirmwareVersion"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirmwareVersion"],
                            firstName: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirstName"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirstName"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["FirstName"],
                            lastName: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["LastName"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["LastName"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["LastName"],
                            emailId: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["EmailID"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["EmailID"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["EmailID"],
                            phoneNo: modifiedState.arrForDetailsOfInactiveFieldDevices[i]["PhoneNo"] == null || modifiedState.arrForDetailsOfInactiveFieldDevices[i]["PhoneNo"].length  <= 0 ? "-" : modifiedState.arrForDetailsOfInactiveFieldDevices[i]["PhoneNo"],
                        }
                        modifiedState.AllInactiveFieldDeviceDetails.push(singleInstanceInfo);
                    }

                    modifiedState.data = modifiedState.AllInactiveFieldDeviceDetails;

                    if(isDownload) {
                        DownloadCsv(modifiedState.data);
                    }
                }
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        });
    }

    const DownloadCsv = (inCsvData) => {      

        let modifiedState = state;

        let arrColumnKeys = ["Device Name", "Last Data Received At", "Email ID", "Phone No.", "First Name", "Last Name", "Current State",  
                                "Hardware ID", "Hardware Model", "Firmware ID", "Firmware Name", "Firmware Version"];

        let strCsvHeaderLine = "Device ID";
        let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
        let strSingleCsvDataLine = "";
        let strColumnName = "";
        modifiedState.csvData = [];

        // Create rest of the columns in the header
        let noOfParams = arrColumnKeys.length;
        for (let j=0; j<noOfParams; j++) {
            strColumnName = arrColumnKeys[j];
            strCsvHeaderLine += (CSV_COLUMN_SEPERATOR + strColumnName);
        }

        let receivedAllInactiveFieldDevicesInformation = inCsvData;
        let receivedAllInactiveFieldDevicesInformationLen = inCsvData.length;
        let allInstanceInfo = [];

        // First push the header CSV line
        arrCsvAllLines.push(strCsvHeaderLine);

        for(let i = 0; i < receivedAllInactiveFieldDevicesInformationLen; i++) {

            let singleInstanceInfo = {
                deviceID: receivedAllInactiveFieldDevicesInformation[i]["deviceID"],
                deviceName: receivedAllInactiveFieldDevicesInformation[i]["deviceName"],
                currentState: receivedAllInactiveFieldDevicesInformation[i]["currentState"],
                lastDataReceivedAtDtTm: receivedAllInactiveFieldDevicesInformation[i]["lastDataReceivedAtDtTm"],
                hardwareID: receivedAllInactiveFieldDevicesInformation[i]["hardwareID"],
                hardwareModel: receivedAllInactiveFieldDevicesInformation[i]["hardwareModel"],
                firmwareID: receivedAllInactiveFieldDevicesInformation[i]["firmwareID"],
                firmwareName: receivedAllInactiveFieldDevicesInformation[i]["firmwareName"],
                firmwareVersion: receivedAllInactiveFieldDevicesInformation[i]["firmwareVersion"],
                firstName: receivedAllInactiveFieldDevicesInformation[i]["firstName"],
                lastName: receivedAllInactiveFieldDevicesInformation[i]["lastName"],
                emailId: receivedAllInactiveFieldDevicesInformation[i]["emailId"],
                phoneNo: receivedAllInactiveFieldDevicesInformation[i]["phoneNo"],
            }

            allInstanceInfo.push(singleInstanceInfo);

            strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                ((receivedAllInactiveFieldDevicesInformation[i]["deviceID"] == null) ? '' : receivedAllInactiveFieldDevicesInformation[i]["deviceID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllInactiveFieldDevicesInformation[i]["deviceName"] == null) ? '' : receivedAllInactiveFieldDevicesInformation[i]["deviceName"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllInactiveFieldDevicesInformation[i]["lastDataReceivedAtDtTm"] == null) ? '' : receivedAllInactiveFieldDevicesInformation[i]["lastDataReceivedAtDtTm"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllInactiveFieldDevicesInformation[i]["emailId"] == null) ? '' : receivedAllInactiveFieldDevicesInformation[i]["emailId"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllInactiveFieldDevicesInformation[i]["phoneNo"] == null) ? '' : receivedAllInactiveFieldDevicesInformation[i]["phoneNo"] ) +
                CSV_COLUMN_SEPERATOR +
                ((receivedAllInactiveFieldDevicesInformation[i]["firstName"] == null) ? '' : receivedAllInactiveFieldDevicesInformation[i]["firstName"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllInactiveFieldDevicesInformation[i]["lastName"] == null) ? '' : receivedAllInactiveFieldDevicesInformation[i]["lastName"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllInactiveFieldDevicesInformation[i]["currentState"] == null) ? '' : receivedAllInactiveFieldDevicesInformation[i]["currentState"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllInactiveFieldDevicesInformation[i]["hardwareID"] == null) ? '' : receivedAllInactiveFieldDevicesInformation[i]["hardwareID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllInactiveFieldDevicesInformation[i]["hardwareModel"] == null ? '' : receivedAllInactiveFieldDevicesInformation[i]["hardwareModel"] )+
                CSV_COLUMN_SEPERATOR +
                ( receivedAllInactiveFieldDevicesInformation[i]["firmwareID"] == null ? '' : receivedAllInactiveFieldDevicesInformation[i]["firmwareID"] )+
                CSV_COLUMN_SEPERATOR +
                ( receivedAllInactiveFieldDevicesInformation[i]["firmwareName"] == null ? '' : receivedAllInactiveFieldDevicesInformation[i]["firmwareName"] )+
                CSV_COLUMN_SEPERATOR +
                ( receivedAllInactiveFieldDevicesInformation[i]["firmwareVersion"] == null ? '' : receivedAllInactiveFieldDevicesInformation[i]["firmwareVersion"] );

            arrCsvAllLines.push(strSingleCsvDataLine);
        }

        modifiedState.csvData = allInstanceInfo;
        // Create CSV File and fill it
        let bFileCreationStatus = createAndFillCsvFile(arrCsvAllLines);

        alert(`Inactive Field Devices download has started. Check your Browser's status bar or Downloads Folder.`);
    }

    const createAndFillCsvFile = (inarrCsvAllLines) => {

        let retVal = true;

        let fileName = "";

        let currentDate = convertLocalDateWithFormatDDMMMYY(new Date());

        fileName = "InactiveFieldDevices on Date " + currentDate.split(" ")[0]+ ".csv";

        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = makeTextFile(inarrCsvAllLines);
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            let event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });

        // Return true to indicate that operation performed successfully (in future any failures in 
        // the previous calls can be checked to return false)
        retVal = true;
        return retVal;
    }

    const makeTextFile = (arrText) =>  {

        let data = new Blob(arrText, {type: 'text/plain'});
        let modifiedState = state;

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (modifiedState.textFile !== null) {
            window.URL.revokeObjectURL(modifiedState.textFile);
        }

        modifiedState.textFile = window.URL.createObjectURL(data);

        // Return a URL you can use as an href
        return modifiedState.textFile;
    }

    return (
        <div className="row justify-content-center">
            <div className="container col-xl-8, col-lg-10, col-md-12, col-sm-12">
                <div className="modal-body box" style={{padding: "1rem"}}>
                    <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1.2rem"}}>
                        Report - Inactive Field Devices 
                    </div>
                    <div>
                        <div style={{border:"1px solid var(--primaryColor)", color:"green", fontSize:"0.8rem", alignItems: "left", textAlign: "left", 
                                paddingLeft: "0.2rem", marginTop:"0.5rem", paddingRight: "0.2rem", backgroundColor:"rgb(168,204,168,0.3)", borderRadius:"0.3rem", marginBottom:"0.5rem"}}>
                            <b>Note :</b>
                            <ul style={{marginBottom : "0.2rem"}}>
                                <li>"Select Time Frame" dropdown option includes time intervals.</li>
                                <li>When any one time interval is selected, it will fetch all the inactive devices 
                                    which are present in between that time interval.</li>
                            </ul> 
                        </div>
                    </div> 
                    <div style={{display:"flex",  margin:"15%",
                            alignItems:"center",
                            marginTop: "1rem",
                            marginBottom: "1rem"}} >
                        <div className="selectedModelLable"     
                        >
                            Select Product Name:
                        </div>
                        <div style={{marginLeft:"1%"}}>
                            <select className="ProdInfoInput"
                                    style={{width:"100%", background:"white", height:"2rem"}} 
                                    required
                                    value={state.selectedProdName}
                                    onChange={onChangeProdName}
                            >
                                <option value="" select= "true" disabled>Select ProductName...</option> 
                                {(state.arrProductName).map((singleProdName, index) => <option key={index} value={singleProdName}>{singleProdName}</option>)}

                            </select>
                        </div>
                        <div className="selectedModelLable"
                            style={{ marginLeft:"15%"}}
                        >
                            Select Time Frame:
                        </div>
                        <div style={{margin:"1%"}}>
                            <select className="ProdInfoInput"
                                    style={{width:"100%", background:"white", height:"2rem"}} 
                                    required
                                    value={state.selectedTimeFrame}
                                    onChange={onChangeTimeFrame}
                            >
                                <option value="" select= "true" disabled>Select TimeFrame...</option> 
                                {(state.ArrForTimeFrames).map((singleTimeFrame, index) => <option key={index} value={singleTimeFrame}>{singleTimeFrame}</option>)}

                            </select>
                        </div>
                    </div>
                    <div style={{display:state.selectedProdName == INCLUDES_ALL_PRODUCT_NAME ? "none" : "block"}}>
                        <div className="ProdInfo">
                            <div className="subProdInfoFirst">
                                <label className="ProdInfolabel" >Product Code:</label>
                                <input value={state.prodCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                            </div>
                            <div className="subProdInfoSecond">
                                <label className="ProdInfolabel">Product Name Code:</label>
                                <input value={state.prodNameCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                            </div>
                        </div>
                        <div className="ProdInfo">
                            <div className="subProdInfoFirst">
                                <label className="ProdInfolabel">Product Line:</label>
                                <input value={state.prodLine} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                            </div>
                            <div className="subProdInfoSecond">
                                <div className="ProdInfolabelDiscription" style={{textAlign:"left"}}>Product Description:</div>
                                <textarea value={state.prodDescription} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>  
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{display:"flex"}}>
                            <button type="button"
                                    onClick = {() => onClickProceed()}
                                    style={{borderRadius: "5px", width:"30%", margin:"1rem",
                                            backgroundColor: "var(--primaryColor)", color:"white",
                                            marginLeft:"15%", 
                                            fontWeight:"700", fontSize: "0.9rem", padding:"0.2rem"
                                        }}
                                    className="btn-sm"
                            >
                                {state.bChngeBtnName? "Refresh" : "View"}
                            </button>
                            <button type="button"
                                    name = "download"
                                    onClick = {onClickDownload}
                                    style={{
                                            borderRadius: "5px", width:"35%", margin:"1rem",
                                            backgroundColor: "var(--primaryColor)", color:"white",
                                            marginLeft:"12%", fontWeight:"700", fontSize: "0.9rem", padding:"0.2rem"
                                        }}
                                    className="btn-sm"
                            >
                                Download Summary
                            </button>
                        </div>
                        {state.errors.others.length > 0 && 
                            <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center", marginTop:"1rem"}} className='error'>{state.errors.others}</p> 
                        }
                    </div>
                    {state.arrForDetailsOfInactiveFieldDevices.length > 0 ? 
                        // <div style={{marginTop:"1rem", border: "1px solid black", textAlign:"center"}}>
                        <div className="border border-3 ReactTableStyle">
                            <ReactTable columns={state.column} 
                                data={state.data}
                                // as React Table is outsdde the main function, state varaibles cant be accessed there 
                                // so passing all the state variables on which react table is dependent through passedStateVariable
                                passedStateVariable = {state.loading}
                            />
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );
}

export default VcInactiveFieldDevices;
