import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AppRelevantDataContext} from '../../AppContext';
import { getAPIHostURL } from '../../ClientConfig';
import DateTimePicker from 'react-datetime-picker';
import { convertUTCDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, 
    convertLocalDateToStrYYYYMMDDHH24MMSS } from '../../vtUtil';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import {FaSearch } from 'react-icons/fa';
import '../CSS/SingleDeviceData.css';
import '../CSS/ReactTable.css';
import {useNavigate} from 'react-router-dom';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import {IDS_LoginServerIssue, IDS_RegistNetworkError} from '../../VcLanguage';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';
import VcNavBar from "./VcNavbar";
import VcSideNav from './DeviceHome/VcSideNav';

function VcOTAStatusReport (props) {

    const context = useContext(AppRelevantDataContext);
    const navigate = useNavigate();

    const filterCaseInsensitive = (rows, id, filterValue) => {

        if (id == "OTALogTime" || id == "LastModifiedTime" || id == "DownloadClearanceTime") {

            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]                    
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        } else {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
            });
        }
    };

    const [state, setState] = useState({

        data: [],
        columns: [
            {
                Header:() => <div className="AlertLogTableHeader">Device ID</div>,  
                accessor: 'DeviceID',
                width: 150,
                filter: filterCaseInsensitive,
                style: ({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                }),
            },
            {
                Header:() => <div className="AlertLogTableHeader">Model Name</div>,  
                accessor: 'ModelName', 
                width: 155,
                filter: filterCaseInsensitive,
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                },
              
            },
            {
                Header:() => <div className="AlertLogTableHeader">Prev OTA<br/> Firmw Name </div>,  
                accessor: 'PrevOTAFrmwrNme',
                width: 145,
                filter: filterCaseInsensitive,
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                },
                
            },
            {
                Header:() => <div className="AlertLogTableHeader">Prev OTA<br/> Firmw Version </div>,  
                accessor: 'PrevOTAFrmwrVer',
                width: 113,
                filter: filterCaseInsensitive,
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                },
                
            },
            {
                Header:() => <div className="AlertLogTableHeader">Deployed OTA<br/> Firmw Name</div>,  
                accessor: 'CurrOTAFrmwrNme',
                width: 130,
                filter: filterCaseInsensitive,
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                },
                
            },
            {
                Header:() => <div className="AlertLogTableHeader">Deployed OTA<br/> Firmw Version</div>,  
                accessor: 'CurrOTAFrmwrVer',
                width: 130,
                filter: filterCaseInsensitive,
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                },
                
            },
            {
                Header:() => <div className="AlertLogTableHeader">Download<br/>Clearance</div>,  
                accessor: 'DownloadClearance',
                width: 90,
                filter: filterCaseInsensitive,
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                },
                
            },
            {
                Header:() => <div className="AlertLogTableHeader">Download Clearance <br/> Time </div>,  
                accessor: 'DownloadClearanceTime',
                width: 185,
                filter: filterCaseInsensitive,
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                },
                
            },
            {
                Header:() => <div className="AlertLogTableHeader">OTA Status</div>,  
                accessor: 'OTAStatus',
                width: 320,
                filter: filterCaseInsensitive,
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                },
                
            },
            {
                Header:() => <div className="AlertLogTableHeader">Log Time</div>, 
                accessor: 'OTALogTime',
                width: 185,
                filter: filterCaseInsensitive,
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                },
                
            },   
            {
                Header:() => <div className="AlertLogTableHeader">Last<br/>Modified Time</div>, 
                accessor: 'LastModifiedTime',
                width: 185,
                filter: filterCaseInsensitive,
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                style: {
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                },
                
            }   
        ],
        startDate: '',
        endDate: '',
        showRefreshBtn: false,
        showClearBtn: false,
        startDateSelected: false,
        endDateSelected: false,
        startDateLength: 0,
        endDateLength: 0, 
        areDatesSelected: false,
        searchedDeviceID: "",
        noDataFound: "",
        windowWidth: window.innerWidth,
        errors:{
            others:'',
            timeRelatedErr: '',
        },
    });

    const handleResize = () => {
        if ((window.innerWidth <= 570 && state.windowWidth > 570) || (window.innerWidth > 570 && state.windowWidth <= 570)) {
            setState(prevState => ({
                ...prevState,
                windowWidth: window.innerWidth
            }))
        } 
    }

    useEffect(() => {
        let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

       if( appRelevantDataContextValue.loggedInUserInfo.userFirstName == null || appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
          // User login information is not present in appRelevantDataContext.
          // Try to get this value if present in LocalStorage and update the app state (so that
          // re-render will happen). If app context data not present in LocalStorage then
          // show the Login Page
          if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
            // App Relevant Data Context not present in LocalStorage as well.
           navigate('/', { replace: true });
          } else {
            // App Relevant Data Context loaded from LocalStorage at AppLeval.
            // Re-render will happen automatically (no action required).
            getLatestOTAStatusDetails();

          }
        } else {
            getLatestOTAStatusDetails();
            checkPrivilegeOfLoggedInUser();
        }
        // getLatestOTAStatusDetails();
    },[]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize());
        }
    }, [state.windowWidth <= 570])

    useEffect(() => {
        let currentState = state;
        let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext
    
        if( appRelevantDataContextValue == null ||
            ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.loggedInUserInfo == null ||
            ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
            appRelevantDataContextValue.loggedInUserInfo.userID == null ||
            appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
            ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.selectedNodeInfo == null ||
            ("nodeID" in appRelevantDataContextValue.selectedNodeInfo) == false ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID == null ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID.length <= 0
        ) {
            console.log("VcAdminHome:componentDidUpdate - First time render. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
            return; // Do not process further
        }
    
        if(appRelevantDataContextValue.loggedInUserPrivilege.Privilege != currentState.EncryptedPvg) {
          // on Refresh values stored in LocalStorage doesnot refelect into appcontext hence 
          // we need to call componenentDidUpdate which again take privilege from context and store into state.
          checkPrivilegeOfLoggedInUser();
        }
    }, [context]);

    const checkPrivilegeOfLoggedInUser = () => {
        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  

        let encryptedPrivileges = appRelevantDataContextValue.loggedInUserPrivilege.Privilege;
        modifiedState.EncryptedPvg = encryptedPrivileges;
        modifiedState.selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
        modifiedState.selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        axios.post(`${getAPIHostURL()}/wclient/getEncChaabi`)
        .then(response =>{
            if(response.data.code == 'SUCCESS') {
               if(response.data.retrievedEncChaabi == null || response.data.retrievedEncChaabi.length <= 0) {
                    modifiedState.errors.others = `Unable to get encryption key.`;
               } else {
                   modifiedState.PrivilegeEncKey = response.data["retrievedEncChaabi"][0]["PassKey"];

                //    let bytes  = CryptoJS.AES.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);
                   let bytes  = aes.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);

                //    let strPrivilege = bytes.toString(CryptoJS.enc.Utf8);
                   let strPrivilege = bytes.toString(enc);

                   try {
                        modifiedState.objPrivilege = JSON.parse(strPrivilege);
                   } catch(e) {
                        console.log(`Should not happen. The Privilege obtained from Context is in invalid JSON format.`);
                   }                   
               }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
            }
            setState({...modifiedState});
        })
        .catch(err => {
            console.log("Network error");
            console.log(err);
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                console.log(modifiedState.errors.others);
                setState({...modifiedState});
            }
        }) 
    }

    // Function to get latest OTA status details for all/specified devices and for specified startDate and EndDate
    const getLatestOTAStatusDetails = () => {

        let modifiedState = state;
        let UtcStartDtTm = null;
        let UtcEndDtTm = null;
        let tempStart = modifiedState.startDate.toString();
        let tempEnd = modifiedState.endDate.toString();

        if((tempStart != null && tempStart.length > 0) && (tempEnd != null && tempEnd.length > 0)){   
            let strLoclStartDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.startDate);
            let strLoclEndDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.endDate);
    
            let strLoclStartDtTm = strLoclStartDate.split(" ")[0] + "T" + "00:00:00";
            let strLoclEndDtTm = strLoclEndDate.split(" ")[0] + "T" + "23:59:59";
      
            UtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclStartDtTm.valueOf()));
            UtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclEndDtTm.valueOf()));
        }

        const jsonBody = {
            searchedDeviceID: modifiedState.searchedDeviceID != null && modifiedState.searchedDeviceID.length > 0 ? modifiedState.searchedDeviceID : "",
            startDate: UtcStartDtTm,
            endDate:UtcEndDtTm
        };

        axios.post(`${getAPIHostURL()}/wclient/getLatestOTAStatusDetails`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {
                if(response.data.OTAStatusDetails == null || response.data.OTAStatusDetails.length <= 0){
                    modifiedState.noDataFound = "No OTA data history found.";

                } else{
                    let recievedOTAStatusDetails = response.data.OTAStatusDetails;
                    let deviceOTAStatusDetails = [];

                    for(let i = 0; i < recievedOTAStatusDetails.length; i++) {
                        const otaStatusDetails = recievedOTAStatusDetails[i];
                        let singleOtaStatusDetails = {
                            DeviceID: otaStatusDetails.DeviceID,
                            ModelName: otaStatusDetails.ModelName == null ? "-" : otaStatusDetails.ModelName,
                            PrevOTAFrmwrNme: otaStatusDetails.PrevOTAFrmwrNme == null ? "-" : otaStatusDetails.PrevOTAFrmwrNme,
                            PrevOTAFrmwrVer: otaStatusDetails.PrevOTAFrmwrVer == null ? "-" : otaStatusDetails.PrevOTAFrmwrVer,
                            CurrOTAFrmwrNme: otaStatusDetails.CurrOTAFrmwrNme == null ? "-" : otaStatusDetails.CurrOTAFrmwrNme,
                            CurrOTAFrmwrVer: otaStatusDetails.CurrOTAFrmwrVer == null ? "-" : otaStatusDetails.CurrOTAFrmwrVer,
                            DownloadClearance : otaStatusDetails.DownloadClearance == null ? "-" : otaStatusDetails.DownloadClearance == 0 ? "True" : "False",
                            DownloadClearanceTime : otaStatusDetails.DownloadClearanceTime == null ? "-" : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(otaStatusDetails.DownloadClearanceTime),
                            OTAStatus : otaStatusDetails.OTAStatus == null ? "-" : otaStatusDetails.OTAStatus,
                            OTALogTime : otaStatusDetails.OTALogTime == null ? "-" : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(otaStatusDetails.OTALogTime),
                            LastModifiedTime : otaStatusDetails.LastModifiedTime == null ? "-" : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(otaStatusDetails.LastModifiedTime),
                        };

                        deviceOTAStatusDetails.push(singleOtaStatusDetails);
                    }
                    modifiedState.data = deviceOTAStatusDetails;

                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        })

    }

    const onChangeStartDate = (updatedStartDt) => {
        let modifiedState = state;
        let currentDate = new Date();
        let minRequiredDateRange = new Date(modifiedState.endDate);
        minRequiredDateRange.setDate(minRequiredDateRange.getDate() - 31);

        if(updatedStartDt > currentDate) {
            modifiedState.errors.timeRelatedErr = "Start Date can not be greater than Current Date. It will be set to Current Date.";
            modifiedState.startDate = currentDate;
            modifiedState.endDate = currentDate;

            if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0 && updatedStartDt > modifiedState.endDate) {
                modifiedState.errors.timeRelatedErr = "End Date can not be less than Start Date. It will be set same as to Start Date.";
                modifiedState.startDate = updatedStartDt;
                modifiedState.endDate = updatedStartDt;
            }
        } else if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0 && updatedStartDt > modifiedState.endDate) {
            modifiedState.errors.timeRelatedErr = "End Date can not be less than Start Date. It will be set same as to Start Date.";
            modifiedState.startDate = updatedStartDt;
            modifiedState.endDate = updatedStartDt;

        } else if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0 
                && modifiedState.startDate != null && modifiedState.startDate.toString().length > 0) {
            let currDateRange = new Date(modifiedState.endDate.getTime() - updatedStartDt.getTime())/ (1000 * 3600 * 24);

            if(currDateRange > 31){
                modifiedState.errors.timeRelatedErr = "Start Date has been adjusted because it is not within a Range";
                modifiedState.startDate = minRequiredDateRange;
            } else {
                modifiedState.errors.timeRelatedErr = "";
                modifiedState.startDate = updatedStartDt;
            }
            
        } else {
            modifiedState.errors.timeRelatedErr = "";
            modifiedState.startDate = updatedStartDt;
        }

        modifiedState.custComlaintTableData = [];
        modifiedState.selectedRowIndex = null;

        modifiedState.startDateLength = modifiedState.startDate.toString().length;
        modifiedState.endDateLength = modifiedState.endDate.toString().length;

        if(modifiedState.startDateLength != null && modifiedState.startDateLength > 0){
            modifiedState.startDateSelected = true;
        }

        if(modifiedState.startDateLength > 0 && modifiedState.endDateLength > 0){
            modifiedState.showRefreshBtn = true;
        }

        if(modifiedState.startDateLength > 0 || modifiedState.endDateLength > 0){
            modifiedState.showClearBtn = true;
        }

        setState({...modifiedState});
    }

    const onChangeEndDate = (updatedEndDt) => {
        let modifiedState = state;
        let currentDate = new Date();
        let minRequiredDateRange = new Date(modifiedState.startDate);
        minRequiredDateRange.setDate(minRequiredDateRange.getDate() + 31);

        if(updatedEndDt > currentDate) {
            modifiedState.errors.timeRelatedErr = "End Date can not be greater than Current Date. It will be set to Current Date.";
            modifiedState.endDate = currentDate;
            modifiedState.startDate = currentDate;

        } else if(updatedEndDt < modifiedState.startDate) {
            modifiedState.errors.timeRelatedErr = "Start Date can not be greater than End Date. It will be set as same to End Date.";
            modifiedState.startDate = updatedEndDt;
            modifiedState.endDate = updatedEndDt;

        } else if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0 
                && modifiedState.startDate != null && modifiedState.startDate.toString().length > 0) {
            let currDateRangeInDays = new Date(updatedEndDt.getTime() - modifiedState.startDate.getTime())/ (1000 * 3600 * 24);

            if(currDateRangeInDays > 31){
                modifiedState.errors.timeRelatedErr = "End Date has been adjusted because it is not within a Range";
                modifiedState.endDate = minRequiredDateRange;
            } else {
                modifiedState.errors.timeRelatedErr = "";
                modifiedState.endDate = updatedEndDt;
            }
            
        } else {
            modifiedState.errors.timeRelatedErr = "";
            modifiedState.endDate = updatedEndDt;
        }

        modifiedState.startDateLength = modifiedState.startDate.toString().length;
        modifiedState.endDateLength = modifiedState.endDate.toString().length;

        if(modifiedState.endDateLength != null && modifiedState.endDateLength > 0){
            modifiedState.endDateSelected = true;
        }

        if(modifiedState.startDateLength > 0 && modifiedState.endDateLength > 0){
            modifiedState.showRefreshBtn = true;
        }

        if(modifiedState.startDateLength > 0 || modifiedState.endDateLength > 0){
            modifiedState.showClearBtn = true;
        }

        modifiedState.custComlaintTableData = [];
        modifiedState.selectedRowIndex = null;

        setState({...modifiedState});
    }

    const clearDatesAndDeviceID = () => {

        let modifiedState = state;

        if(modifiedState.startDate != null && modifiedState.startDate.toString().length > 0){
            modifiedState.startDate = '';
        }
        
        if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0){
            modifiedState.endDate = '';
        }

        if(modifiedState.searchedDeviceID != null && modifiedState.searchedDeviceID.length > 0){
            modifiedState.searchedDeviceID = "";
        }

        modifiedState.showRefreshBtn = false;
        modifiedState.showClearBtn = false;
        modifiedState.startDateSelected = false;
        modifiedState.endDateSelected = false;
        modifiedState.errors.others = "";
        modifiedState.errors.timeRelatedErr = "";

        getLatestOTAStatusDetails();

    }

    const refresh = () => {
        let modifiedState = state;
        modifiedState.data = [];
        modifiedState.errors.others = "";
        modifiedState.errors.timeRelatedErr = "";
        modifiedState.areDatesSelected = true;

        if((modifiedState.startDate != null && modifiedState.startDate.toString().length > 0 
            && modifiedState.endDate != null && modifiedState.endDate.toString().length > 0
            ) || ((modifiedState.searchedDeviceID != null && modifiedState.searchedDeviceID.length > 0) 
                    || (modifiedState.searchedDeviceID == null || modifiedState.searchedDeviceID.length <= 0)
                 )
          )
        {
            getLatestOTAStatusDetails();
        } else{
            modifiedState.errors.timeRelatedErr = "Please Select Start Date/End Date.";
        }

        setState({...modifiedState});

    }

    const searchByDeviceID = (deviceID) => {
        let modifiedState = state;
        modifiedState.searchedDeviceID = deviceID != null && deviceID.length > 0 ? deviceID : "";
        modifiedState.showRefreshBtn = true;
        modifiedState.showClearBtn = true;

        setState({...modifiedState});
    }

    return(
        (state.windowWidth <= 570) ? 
        <div className="d-flex">
            <div className="treeAndContentCRM">
                <VcNavBar />
                
                <div>
                    <div className="row justify-content-center">
                        <div className="container col-lg-12,col-md-12,col-sm-12" >
                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "0.5rem"}}>
                                Report - OTA Status History
                            </div>
                            <div style={{display: "flex", alignItems: 'right', justifyContent: 'right', marginLeft: "1rem", marginRight: "1.3rem", marginTop: "0.2rem", marginBottom: "0.5rem"}}>

                                <div>
                                    <label style={{fontSize:"0.9rem", marginRight:"-1.2rem"}}>
                                        Start Date:
                                    </label>
                                    <DateTimePicker
                                        clearIcon={null}
                                        onChange={onChangeStartDate}
                                        format={"yyyy/MM/dd"} 
                                        value={state.startDate} 
                                        name="startDateTime"
                                        timeFormat={false}
                                        className="dashBoardDateTimeZIndex"
                                    />                                
                                </div>
                                <div className='divForEndDate'> 
                                    <label style={{fontSize:"0.9rem", marginRight:"-1.2rem"}}>
                                        &nbsp;End Date:
                                    </label>
                                    <DateTimePicker
                                        clearIcon={null}
                                        onChange={onChangeEndDate}
                                        format={"yyyy/MM/dd"} 
                                        value={state.endDate} 
                                        name="endDateTime"
                                        timeFormat={false}
                                        className="dashBoardDateTimeZIndex"
                                    />           
                                </div>
                                <div style={{marginLeft: "2rem"}}>
                                    <input size = "medium" placeholder="Search Device ID" 
                                        value={state.searchedDeviceID}
                                        onChange = {e => {
                                            searchByDeviceID(e.target.value)
                                        }}
                                        style = {{fontSize: "14px", height: "2rem", border: "2px solid #a8cca8",
                                        borderRadius: "5px", marginLeft: "0.3rem",
                                        color: "#000", 
                                        backgroundColor: "#fff", width: "100%"}}
                                    />
                                </div>

                                <div className='divToShowRefreshAndClearBtnDashBoard'
                                        style={{display: "flex", justifyContent: "center", flexDirection: "row"}} >
                                    <div style={{marginLeft: "1rem"}} hidden = {state.showRefreshBtn == true ? false : true}> 
                                        <button type="button"
                                                title= "Refresh Data as per the selected date."
                                            onClick = {() => refresh()}
                                            style={{borderRadius: "5px", 
                                                    color:"white",
                                                    marginLeft:"5%", 
                                                    fontWeight:"500",
                                                    paddingLeft: "0rem"
                                                }}
                                            className="iconButton"
                                        >
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div style={{marginLeft: state.showRefreshBtn == true ? "0.6rem" : "2rem"}} 
                                        hidden = {state.showClearBtn == true ? false : true}>
                                        <button type="button"
                                                title= {state.startDateSelected == true ? "Clear Selected Dates." : 
                                                        state.endDateSelected == true ? "Clear End Date." : "Clear"}
                                                onClick={ () => clearDatesAndDeviceID()} 
                                                style={{borderRadius: "5px",
                                                        color:"white", 
                                                        fontWeight:"900",
                                                        width: "110%"

                                                }}
                                                className="iconButton"
                                        > 
                                            X 
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div>
                                {state.errors.timeRelatedErr.length > 0 && 
                                    <p style={{color:"Green", fontSize:"0.8rem", textAlign:"center", marginTop: "1rem"}} className='error'>{state.errors.timeRelatedErr}</p> 
                                }
                            </div>
                            <div className="border border-3 ReactTableStyle" style={{marginLeft: "1rem", marginRight: "1rem", marginTop: "0rem"}}>
                                <ReactTable 
                                    columns={state.columns} 
                                    data={state.data}
                                    // as React Table is outside the main function, state varaibles cant be accessed there 
                                    // so passing all the state variables on which react table is dependent through passedStateVariable
                                    passedStateVariables = {[state.noDataFound]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : 
        <div className="d-flex">
            <div className='d-flex' style={{ width: "4%" }}>
                <div className="shadow sideNav" style={{ width: "100%" }}>
                    <VcSideNav />
                </div>
            </div>

            <div className="treeAndContentCRM">
                <VcNavBar />
                <div className="row justify-content-center">
                    <div className="container col-lg-12,col-md-12,col-sm-12" >
                        <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "0.5rem"}}>
                            Report - OTA Status History
                        </div>
                        <div style={{display: "flex", alignItems: 'right', justifyContent: 'right', marginLeft: "1rem", marginRight: "1.3rem", marginTop: "0.2rem", marginBottom: "0.5rem"}}>

                            <div>
                                <label style={{fontSize:"0.9rem", marginRight:"-1.2rem"}}>
                                    Start Date:
                                </label>
                                <DateTimePicker
                                    clearIcon={null}
                                    onChange={onChangeStartDate}
                                    format={"yyyy/MM/dd"} 
                                    value={state.startDate} 
                                    name="startDateTime"
                                    timeFormat={false}
                                    className="dashBoardDateTimeZIndex"
                                />                                
                            </div>
                            <div className='divForEndDate'> 
                                <label style={{fontSize:"0.9rem", marginRight:"-1.2rem"}}>
                                    &nbsp;End Date:
                                </label>
                                <DateTimePicker
                                    clearIcon={null}
                                    onChange={onChangeEndDate}
                                    format={"yyyy/MM/dd"} 
                                    value={state.endDate} 
                                    name="endDateTime"
                                    timeFormat={false}
                                    className="dashBoardDateTimeZIndex"
                                />           
                            </div>
                            <div style={{marginLeft: "2rem"}}>
                                <input size = "medium" placeholder="Search Device ID" 
                                    value={state.searchedDeviceID}
                                    onChange = {e => {
                                        searchByDeviceID(e.target.value)
                                    }}
                                    style = {{fontSize: "14px", height: "2rem", border: "2px solid #a8cca8",
                                    borderRadius: "5px", marginLeft: "0.3rem",
                                    color: "#000", 
                                    backgroundColor: "#fff", width: "100%"}}
                                />
                            </div>

                            <div className='divToShowRefreshAndClearBtnDashBoard'
                                    style={{display: "flex", justifyContent: "center", flexDirection: "row"}} >
                                <div style={{marginLeft: "1rem"}} hidden = {state.showRefreshBtn == true ? false : true}> 
                                    <button type="button"
                                            title= "Refresh Data as per the selected date."
                                        onClick = {() => refresh()}
                                        style={{borderRadius: "5px", 
                                                color:"white",
                                                marginLeft:"5%", 
                                                fontWeight:"500",
                                                paddingLeft: "0rem"
                                            }}
                                        className="iconButton"
                                    >
                                        <i className="fa fa-refresh" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div style={{marginLeft: state.showRefreshBtn == true ? "0.6rem" : "2rem"}} 
                                    hidden = {state.showClearBtn == true ? false : true}>
                                    <button type="button"
                                            title= {state.startDateSelected == true ? "Clear Selected Dates." : 
                                                    state.endDateSelected == true ? "Clear End Date." : "Clear"}
                                            onClick={ () => clearDatesAndDeviceID()} 
                                            style={{borderRadius: "5px",
                                                    color:"white", 
                                                    fontWeight:"900",
                                                    width: "110%"

                                            }}
                                            className="iconButton"
                                    > 
                                        X 
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div>
                            {state.errors.timeRelatedErr.length > 0 && 
                                <p style={{color:"Green", fontSize:"0.8rem", textAlign:"center", marginTop: "1rem"}} className='error'>{state.errors.timeRelatedErr}</p> 
                            }
                        </div>
                        <div className="border border-3 ReactTableStyle" style={{marginLeft: "1rem", marginRight: "1rem", marginTop: "0rem"}}>
                            <ReactTable 
                                columns={state.columns} 
                                data={state.data}
                                // as React Table is outside the main function, state varaibles cant be accessed there 
                                // so passing all the state variables on which react table is dependent through passedStateVariable
                                passedStateVariables = {[state.noDataFound]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
    }) {
    return(
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
             <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"0.9rem"}}/>
             <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data, getCellProps = defaultPropGetter, passedStateVariables}) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
            },
        }),
        []
    );

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: {pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // autoResetPage: false,
            // set page size based on tables
            initialState: { pageSize: 10},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;{passedStateVariables[0] ? "No Data Found." : ""}</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };

    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr" 
                                    style={{
                                        cursor: '',
                                        background: '',
                                        color: 'black',
                                        alignItems: "center",
                                    }}  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style,},
                                            getCellProps(cell),
                                        ]
                                    )} 
                                    className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0

                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default VcOTAStatusReport;