import React, { Component } from 'react';
import { getAPIHostURL, getDevcSwLocnOnSrvr } from '../../ClientConfig';
import axios from 'axios';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_AUSrvrIssueReqParamsNotSent, IDS_AUServerError, } from '../../VcLanguage';
  
class VcDefineNewFirmware extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {
                others: ""
            },
            retrievedNewFirmwareInfo: "",
            HWandFWInfoArr: [],
            DeviceTypeArr: [],
            HWModelArr: [],
            FWNameArr: [],
            selectedHardware: "",
            selectedHardwareModel: "",
            selectedFw: "",
            isSetNewFwDefaultChecked: false,
            FWVersion: "",
            newFWDescription: "",
            fileToUploadFileName: "",
            fileToUploadFullPath: null,
        }
    }

    componentDidMount () {
        let appRelevantDataContextValue = this.context;

        // To get Info from LS which have persisted even after logout 
        // (for eg. Language and Last Loggedin UserID).
        appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage(true);

        // To select hardware, hardware model and software name.
        this.DefineNewFirmware();
    }

    DefineNewFirmware = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        let modifiedState = this.state; 
        // To avoid appending of array values.
        modifiedState.HWandFWInfoArr = [];
        modifiedState.DeviceTypeArr = [];

        // To get hardware, hardware model and software name from database.
        axios.post(`${getAPIHostURL()}/wclient/DefineNewFirmware`)
        .then(response => {
            if(response.data.code == "SUCCESS") {
                // IF response gets inadequate data.
                if(response.data.retrievedNewFirmwareInfo == null || response.data.retrievedNewFirmwareInfo.length <= 0){
                    modifiedState.errors.others = "Device Model Information is not present on Server.";
                } else {
                    modifiedState.HWandFWInfoArr = response.data.retrievedNewFirmwareInfo;
                    // For showing only one value in dropdown if value gets repeat.
                    modifiedState.DeviceTypeArr = [...new Set(modifiedState.HWandFWInfoArr.map(singleHWandFWVersion => singleHWandFWVersion.DeviceType))];

                    console.log("HWandFWInfoArr: ", modifiedState.HWandFWInfoArr);
                    console.log("HWandFWInfoArr: ", modifiedState.DeviceTypeArr);
                }
            } else {
                if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }  
        });
    }

    // For selecting values in dropdown of SELECT HARWARE MODEL as per selection of dropdown of SELECT HARDWARE.
    onChangeHardwareSelection = (e) => {

        console.log("HWModelArr");

        let modifiedState = this.state;
        modifiedState.selectedHardware = e.target.value;

        modifiedState.HWModelArr = [];
        modifiedState.FWNameArr = [];
        modifiedState.selectedHardwareModel = "";
        modifiedState.selectedFw = "";
        modifiedState.errors.others = "";

        for(let i=0; i< modifiedState.HWandFWInfoArr.length; i++) {
            if(modifiedState.HWandFWInfoArr[i]["DeviceType"] == modifiedState.selectedHardware 
            ) {
                // Assigning values to second dropdown based on selection of first dropdown.
                modifiedState.HWModelArr.push(modifiedState.HWandFWInfoArr[i]["ModelName"]);
            }
        }
        // Get all distinct HW model name to show on dropdown.
        modifiedState.HWModelArr = modifiedState.HWModelArr.filter((singleHWModel, index, arr) => arr.indexOf(singleHWModel) === index);

        this.setState(modifiedState);
    }

    // For selecting values in dropdown of SELECT FIRMWARE NAME as per selection of dropdown of SELECT HARDWARE MODEL.
    onChangeHardwareModelSelection = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedHardwareModel = e.target.value;
        modifiedState.selectedFw = "";
        modifiedState.errors.others = "";

        modifiedState.FWNameArr = [];

        for(let i=0; i< modifiedState.HWandFWInfoArr.length; i++) {
            if(modifiedState.HWandFWInfoArr[i]["ModelName"] == modifiedState.selectedHardwareModel) {
                // Assigning values to third dropdown based on selection of second dropdown.
                modifiedState.FWNameArr.push(modifiedState.HWandFWInfoArr[i]["SoftwareName"]);
            }
        }
        console.log(modifiedState.FWNameArr);
        this.setState(modifiedState);
    }

    // For selecting Firmware Name or Defining New Firmware Name.
    onChangeFWSelection = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedFw = e.target.value;
        console.log(modifiedState.selectedFw);
        this.setState(modifiedState);
    }

    // For setting Newly added Firmware as default for selected Hardware Model.
    setNewFwDefault = (e) => {
        let modifiedState = this.state;

        console.log("Checkbox Checked=", e.target.checked)
        modifiedState.isSetNewFwDefaultChecked = e.target.checked
        console.log("Checkbox Checked=", e.target.checked)
        this.setState(modifiedState);
    }

    // For adding new version of Firmware for selected Hardware Model.
    onEnteredNewVersion = (e) => {
        let modifiedState = this.state;

        modifiedState.FWVersion = e.target.value;
        this.setState(modifiedState);
    }

    // For entering detailed decription for newly added version of Firmware.
    onEnteredNewVersionDescription = (e) => {
        let modifiedState = this.state;

        modifiedState.newFWDescription = e.target.value;
        this.setState(modifiedState);  
    }

    // For selecting Firmware Binary File from user's machine disk to upload.
    onFileSelection = (e) => {
        let modifiedState = this.state;
        // For getting path of selected file.
        let uploadedFilePath = e.target.value;
        modifiedState.errors.others = "";
        console.log("File Selected=", uploadedFilePath)

        // For getting all information about file.
        modifiedState.fileToUploadFullPath= e.target.files[0];
        console.log("Uploaded File=", modifiedState.fileToUploadFullPath);

        // For fetching File's Name from File's Path.
        modifiedState.fileToUploadFileName = uploadedFilePath.split(/(\\|\/)/g).pop()
        this.setState(modifiedState);
    }

    // For saving all information to database.
    onSubmit = (e) => {
        e.preventDefault();

        let modifiedState = this.state;

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        if( modifiedState.selectedHardware == null || modifiedState.selectedHardware.length <= 0 ||
            modifiedState.selectedHardwareModel == null || modifiedState.selectedHardwareModel.length <= 0
        ) {
            modifiedState.errors.others = "Please select Hardware or Hardware Model.";
            this.setState(modifiedState);
            return; // Dont process any further
        }

        if( modifiedState.fileToUploadFullPath == null || modifiedState.fileToUploadFullPath.length <= 0 ) {
            modifiedState.errors.others = "Please select a Firmware Binary File for Uploading.";
            this.setState(modifiedState);
            return; // Dont process any further
        }

        let data = new FormData();
        data.append('fileToBeUpload', modifiedState.fileToUploadFullPath);

        // Show spinner to restrict other user actions to be perform.
        appRelevantDataContextValue.onChangeProcessingReq(true);

        // First upload the Firmware Binary File.
        axios.post(`${getAPIHostURL()}/wclient/uploadFWFile`, data)
        .then(response => {
            if(response.data.code == "SUCCESS") {

                if( response.data.uploadedFile == null || response.data.uploadedFile.length <= 0 ) {
                    // File upload was unsuccessful because the file name was null or the file name was empty.
                    modifiedState.errors.others = "Please select a Firmware Binary File for Uploading.";
                    
                    // Set error message (done below). Dont do any other processing after that.
                    
                } else {

                    // File upload was successful.
                    // Now update the New Firmware information in the database.

                    console.log(response.data.uploadedFile)
                    let uploadedFileName = response.data.uploadedFile.filename;
                    let uploadedFilePath = getDevcSwLocnOnSrvr(); // Directory

                    let JsonBody = {
                        HWModelName: modifiedState.selectedHardwareModel,
                        FWName: modifiedState.selectedFw,
                        NewFwVersion: modifiedState.FWVersion,
                        NewFWDescription: modifiedState.newFWDescription,
                        bDefaultFWVersionCheck: modifiedState.isSetNewFwDefaultChecked,
                        FileName: uploadedFileName,
                        FilePath: uploadedFilePath,
                    }

                    console.log(JsonBody)
            
                    // For saving/defining new Firmware's Information.
                    axios.post(`${getAPIHostURL()}/wclient/updateNewFWVersion`, JsonBody)
                    .then(response => {
                        // Remove spinner and allow user to perform action.
                        appRelevantDataContextValue.onChangeProcessingReq(false);

                        if(response.data.code == "SUCCESS") {

                            alert(`Successfully Uploaded New Firmware.`);

                            // Clear all fields in the form when user successfully defines new Firmware or Firmware Version.
                            modifiedState.errors.others = "";
                            modifiedState.selectedHardware = "";
                            modifiedState.selectedHardwareModel = "";
                            modifiedState.selectedFw = "";
                            modifiedState.FWNameArr = [];
                            modifiedState.FWVersion = "";
                            modifiedState.newFWDescription = "";
                            modifiedState.fileToUploadFileName = "No file Chosen";
                            modifiedState.fileToUploadFullPath = null;
                            modifiedState.isSetNewFwDefaultChecked = false;

                            document.getElementById("defineNewFWForm").reset();
            
                        } else {
                            if (response.data.code == 'REQ_PARAMS_MISSING') {
                                // Tell the user that server is experiencing issues (required params are not found).
                                modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent); // TODO: Define generic language error for this
                            } else if (response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                                // Tell the user that server is experiencing issues (unknown reason). User should try again later.
                                modifiedState.errors.others = t(IDS_AUServerError); // TODO: Define generic language error for this
                            } else if (response.data.code == 'SQL_ERROR') {
                                if(response.data.sqlerrcode == "ER_DUP_ENTRY") {
                                    alert(`Specified Firmware Version Number already exists. Please define a new Version Number.\n(In case you don't want to define a new Version Number, then define a new Firmware Name).`)
                                } else {
                                    console.log("SQL Error while uploading and saving new firmware version.")
                                    // Tell the user that server is experiencing issues (dont tell about SQL Error). User should try again later.
                                    modifiedState.errors.others = t(IDS_AUServerError); // TODO: Define generic language error for this
                                }
                            } else {
                                console.log("Should not reach here.")
                                modifiedState.errors.others = t(IDS_AUServerError); // TODO: Define generic language error for this
                            }
                        }
                        console.log("after save=", modifiedState);
                        this.setState(modifiedState);
                    })
                    .catch(error => {
                        // Remove spinner and allow user to perform action.
                        appRelevantDataContextValue.onChangeProcessingReq(false);
                        console.log(error);
                        modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                        this.setState(modifiedState);  
                    });
                }
        
            } else {
                // Remove spinner and allow user to perform action.
                appRelevantDataContextValue.onChangeProcessingReq(false);

                if(response.data.code == 'UPLOAD_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues while uploading Firmware Binary file.\nTry again later.";
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = "Server experiencing issues while uploading Firmware Binary file.\nTry again later.";
                }
            }
            this.setState(modifiedState);
        })
        .catch(error => {
            // Remove spinner and allow user to perform action.
            appRelevantDataContextValue.onChangeProcessingReq(false);

            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState); 
            } 
        });
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-6 col-lg-offset-3
                                            col-md-8 col-md-offset-2">
                        <div className="modal-body box">
                            <form onSubmit={this.onSubmit} id="defineNewFWForm" >
                                <div >
                                    <select  className="input-form" style={{height:"2.5rem", marginBottom: "1rem"}}
                                            value={this.state.selectedHardware}
                                            onChange={this.onChangeHardwareSelection}
                                    >
                                        <option value="" disabled select= "true">Select Hardware...</option>
                                        {(this.state.DeviceTypeArr).map((singleHW,index) => <option key={index} value={singleHW}>{singleHW}</option>)}
                                    </select>
                                </div>

                                <div >
                                    <select  className="input-form" 
                                             style={{height:"2.5rem", marginBottom: "1rem"}}
                                             value={this.state.selectedHardwareModel}
                                             onChange={this.onChangeHardwareModelSelection}
                                    >
                                        <option value="" disabled select="true">Select Hardware Model...</option>
                                        {(this.state.HWModelArr).map((singleHWModel,index) => <option key={index} value={singleHWModel}>{singleHWModel}</option>)}
                                    </select>
                                </div>

                                {/* <div className="inputgroupCustom">
                                    <select  className="input-form" 
                                             style={{height:"2.5rem"}}
                                             value={this.state.selectedFw}
                                             onChange={this.onChangeFWSelection}
                                    >
                                        <option value="" disabled select= "true">Select Firmware Name or Define New Firmware:</option>
                                        {(this.state.FWNameArr).map((singleFwName,index) => <option key={index} value={singleFwName}>{singleFwName}</option>)}
                                    </select>
                                </div> */}

                                <div>
                                    <label className="reg-form-label" style={{width:"100%"}}>Select Firmware Name or Define New Firmware:</label>
                                    <div>
                                        <input type="list" list="data" className="input-form" required="required" value={this.state.selectedFw} onChange={this.onChangeFWSelection} />
                                        <datalist id="data">
                                        {this.state.FWNameArr.map((singleFwName, index) =>
                                            <option key={index} value={singleFwName.displayValue}>{singleFwName}</option>
                                        )}
                                        </datalist>
                                    </div>
                                </div>

                                <div className="inputgroupCustom" style={{paddingTop:"0.8rem"}}>
                                    <label className="reg-form-label">Enter New Version For Firmware:</label>
                                    <input type='text' className="input-form" required="required" value= {this.state.FWVersion} onChange={this.onEnteredNewVersion}/>  
                                </div>

                                <div className="inputgroupCustom" style={{paddingTop:"0.8rem", paddingBottom:"0.8rem"}}>
                                    <label className="reg-form-label">Enter Description For New Firmware:</label>
                                    <input type='text' className="input-form" required="required" value={this.state.newFWDescription} onChange={this.onEnteredNewVersionDescription}/>  
                                </div>

                                <div>
                                    <input type="checkbox" 
                                           defaultChecked={this.state.isSetNewFwDefaultChecked}
                                           onClick={this.setNewFwDefault}/> Set new firmware as default for selected model. 
                                </div>

                                <div className="inputgroupCustom" style={{paddingTop:"1rem"}}>
                                    <label>Select Firmware Binary File:</label> 
                                    <input type="file" onChange={this.onFileSelection} name="fileToBeUpload"/>
                                </div>

                                <div className="form-group reg-form" style={{marginTop:"1.5rem"}}>
                                    <button type="submit" className="btn-lg reg-btn">SAVE</button>
                                    <div className = "buttonErrorMessage">
                                        {this.state.errors.others.length > 0 && 
                                            <p style={{color: 'var(--errorColor)', fontSize: '0.8rem'}} className='error'>{this.state.errors.others}</p>}
                                    </div> 
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

VcDefineNewFirmware.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcDefineNewFirmware
