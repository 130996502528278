import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_ContactNo, IDS_Email, IDS_EmailError, IDS_LoginServerIssue, IDS_PhoneNumberErrMsg, IDS_RegistNetworkError, IDS_Back,
            IDS_AUSrvrIssueReqParamsNotSent, IDS_LastName, IDS_FirstName, IDS_Save, IDS_Odour, IDS_Rating, IDS_Cleanliness, IDS_Good,
            IDS_Add_A_Review, IDS_Please_Our_Toilet, IDS_Please_Rate_Both_Odour_Cleanliness, IDS_Rating_Saved_Thanks_Msg, IDS_Rating_Success_Alert_Msg,
            IDS_Rate_Very_Bad, IDS_Rate_Very_Good, IDS_Rate_Fair, IDS_Bad, IDS_YourRating} from '../../VcLanguage';
import {Rating} from "react-simple-star-rating";
import "font-awesome/css/font-awesome.min.css";
import '../CSS/VcRateToilet.css';
import { GiNoseFront } from 'react-icons/gi';
import { FaFrown, FaGrinAlt,FaSmile } from 'react-icons/fa';
import BroomSymbol from '../IMAGES/broom.png';
import { DEFAULT_RATING_LEVEL_SIGNAL, DEFAULT_RATING_LEVEL_STARS } from '../../VcConstants';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

function VcRateToilet (props) {

    const context = useContext(AppRelevantDataContext);

    const [state, setState] = useState({
        modal: false,
        bshowRatingTypes: false,
        firstName: "",
        lastName: "",
        userEmailID: "",
        phoneNo: "",
        Comment: "",
        cleanlinessRatingVal: 0,
        odourRatingVal: 0,
        CountryInfo: [],
        CountryCode: "+91",
        EntityLocnID: props.EntityLocnID,
        others:"",
        EntityType: "",
        SelectedToiletOfRatingType: "",
        invokedFrom: props.invokedFrom,
        SelectedToiletName: props.SelectedToiletName,
        bVisibleSuccessMasg: false,
        ratingValueForCleanlinessrating: [],            
        tooltipStyle: {
            fontSize: "0.8rem",
            marginBottom:"1rem",
            padding: "0.1rem",
            backgroundColor: "#FDCC0D",
            fontWeight: "bold"
        },          
        errors: { 
            firstName: "",
            lastName: "",
            userEmailID: "",
            phoneNo: "",
            others:"",
            EntityType: "",
            rating: "",
        },
    });

    const onRateToiletFormSubmit = (event) => {
        event.preventDefault();
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = state;
        modifiedState.bVisibleSuccessMasg = false;

        if (modifiedState.phoneNo != null && modifiedState.phoneNo.length > 0 && (modifiedState.phoneNo.length < 4 || modifiedState.phoneNo.length > 12)) {
            modifiedState.errors.phoneNo = t(IDS_PhoneNumberErrMsg);
            setState({...modifiedState});
        } else if(modifiedState.odourRatingVal == 0 || modifiedState.cleanlinessRatingVal == 0) {
            modifiedState.errors.rating = t(IDS_Please_Rate_Both_Odour_Cleanliness);
            setState({...modifiedState});
        } else if(modifiedState.userEmailID != null && modifiedState.userEmailID.length > 0 && modifiedState.errors.userEmailID != null && modifiedState.errors.userEmailID.length > 0) {
            modifiedState.errors.userEmailID = t(IDS_EmailError);
            setState({...modifiedState});
        } else if(modifiedState.userEmailID != null && modifiedState.userEmailID.length > 0 && !validEmailRegex.test(modifiedState.userEmailID)){
            modifiedState.errors.userEmailID = t(IDS_EmailError);
            setState({...modifiedState});
        }
         else {
            modifiedState.errors.phoneNo = "";
            modifiedState.errors.others = "";
    
            const allData = {
                firstName: trimStringAndRemoveTrailingComma(modifiedState.firstName).toLowerCase(), 
                lastName: trimStringAndRemoveTrailingComma(modifiedState.lastName), 
                userEmailID: trimStringAndRemoveTrailingComma(modifiedState.userEmailID).toLowerCase(),
                phoneNo: (modifiedState.phoneNo != null && modifiedState.phoneNo.length > 0) ? modifiedState.CountryCode +" " + trimStringAndRemoveTrailingComma(modifiedState.phoneNo) : modifiedState.phoneNo, 
                odourRatingVal: modifiedState.odourRatingVal,
                cleanlinessRatingVal: modifiedState.cleanlinessRatingVal,
                EntityLocnID: modifiedState.EntityLocnID,
                Comment: modifiedState.Comment,
            };

            axios.post(`${getAPIHostURL()}/wclient/saveOdourAndCleanlinessOfToiletInfo`, allData)
            .then(response => {
                if(response.data.code == "SUCCESS") {

                        if(state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == 'MapToilet') {
                            alert(t(IDS_Rating_Success_Alert_Msg));
                            props.getLatestRatingDataOnSucessToiletRating();
                        } else {
                            modifiedState.bVisibleSuccessMasg = true;
                        }
                        modifiedState.firstName = "";
                        modifiedState.lastName = "";
                        modifiedState.userEmailID = "";
                        modifiedState.phoneNo = "";
                        modifiedState.cleanlinessRatingVal = 0;
                        modifiedState.odourRatingVal = 0;
                        modifiedState.EntityLocnID = "";
                        modifiedState.Comment = "";
                        modifiedState.SelectedToiletName = "";
                } else {

                    if(response.data.code == 'REQ_PARAMS_MISSING'){
                        modifiedState.errors.others = t(IDS_LoginServerIssue);
                    } else if (response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = t(IDS_LoginServerIssue);
                    } else {
                        console.log("Should not reach here.")
                        modifiedState.errors.others = t(IDS_LoginServerIssue);
                    }
                }
                setState({...modifiedState});
            })
            .catch(error => {
                console.log("Network error:");
                console.log(error);
                if (axios.isCancel(error)) {
                  console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                  // Tell the user that there are network issues
                  modifiedState.errors.others = t(IDS_RegistNetworkError);
                  setState({...modifiedState});
                }
            });
        } 
    }

    useEffect(() => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        if(state.invokedFrom != null && state.invokedFrom == "QrScanner") {
            var language = window.navigator.userLanguage || window.navigator.language;
            // alert(language);

            let selectedLanguage = (language.includes('hi') || language == 'hi') ? 'hi' : (language.includes('mr') || language == 'mr') ? 'mr' : 'en';
            appRelevantDataContextValue.onChangeLanguageToViewIn(selectedLanguage);
        }

        state.ratingValueForCleanlinessrating = [t(IDS_Rate_Very_Bad), t(IDS_Bad), t(IDS_Rate_Fair), t(IDS_Good), t(IDS_Rate_Very_Good)];           

        getAllCountryCodeInfo();
        getToiletNameOnTheBasisOfEnTityLocID();
        
    }, []);

    const getToiletNameOnTheBasisOfEnTityLocID = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = state;

        modifiedState.EntityLocnID = (modifiedState.EntityLocnID == null || modifiedState.EntityLocnID.length <= 0) 
                                      ? window.location.pathname.split('/').pop()  // getting unique key as EntityLocnID from url of RateToilet page.
                                      :  modifiedState.EntityLocnID;                               
                                    
        let jsonBody = {
            EntityLocnID: modifiedState.EntityLocnID
        }
    
        axios.post(`${getAPIHostURL()}/wclient/getToiletName`, jsonBody)
        .then(response => {
          if(response.data.code == "SUCCESS") {
            modifiedState.bshowRatingTypes = true;
            modifiedState.SelectedToiletName = response.data.retrivedToiletName != null ? response.data.retrivedToiletName['Name'] : "";
            let toiletRatingTy = response.data.retrivedToiletName != null ? JSON.parse(response.data.retrivedToiletName['AdditionalInfo']) : null;
            modifiedState.SelectedToiletOfRatingType = toiletRatingTy != null ? toiletRatingTy['RatingLevel'] : null;
        } else {

            if(response.data.code == "REQ_PARAMS_MISSING") {
                console.error(t(IDS_AUSrvrIssueReqParamsNotSent));
            } else if(response.data.code == "SQL_ERROR"){
                console.error(t(IDS_LoginServerIssue));
            } else {
              console.log('Should not reach here');
              console.error(t(IDS_LoginServerIssue));
            }
          }
          setState({...modifiedState});
        })
        .catch(error => {
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            // Tell the user that there are network issues
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            setState({...modifiedState});
          } 
        });
    }

    const getAllCountryCodeInfo = () => {
    
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
    
        let modifiedState = state;
    
        axios.post(`${getAPIHostURL()}/wclient/getAllCountryCodeInformation`)
        .then(response => {
          if(response.data.code == "SUCCESS") {
              modifiedState.CountryInfo = [];
              modifiedState.CountryInfo = response.data.CountryInfo;
          } else {
    
            if(response.data.code == "SQL_ERROR"){
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
          }
          setState({...modifiedState});
        })
        .catch(error => {
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            // Tell the user that there are network issues
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            setState({...modifiedState});
          } 
        });
    }

    const closeModal = () => {
        props.toggle();
    }

    const odourRatingChanged = (val) => {
        let modifiedState = state;
        modifiedState.errors.others = "";
        modifiedState.errors.phoneNo = "";
        modifiedState.errors.userEmailID = "";
        modifiedState.errors.rating = "";
        modifiedState.odourRatingVal = val;
        setState({...modifiedState});
    };
    
    const odourSignalRatingChanged = (e) => {
        let modifiedState = state;
        modifiedState.errors.others = "";
        modifiedState.errors.phoneNo = "";
        modifiedState.errors.userEmailID = "";
        modifiedState.errors.rating = "";
        modifiedState.odourRatingVal = e.target.value;
        setState({...modifiedState});
    }

    const cleanlinessRatingChanged = (val) => {
        let modifiedState = state;
        modifiedState.errors.others = "";
        modifiedState.errors.phoneNo = "";
        modifiedState.errors.userEmailID = "";
        modifiedState.errors.rating = "";
        modifiedState.cleanlinessRatingVal = val;
        setState({...modifiedState});
    };

    const cleanlinessSignalRatingChanged = (e) => {
        let modifiedState = state;
        modifiedState.errors.others = "";
        modifiedState.errors.phoneNo = "";
        modifiedState.errors.userEmailID = "";
        modifiedState.errors.rating = "";
        modifiedState.cleanlinessRatingVal = e.target.value;
        setState({...modifiedState});
    }

    const onChangeLastName = (e) => {
        let modifiedState = state;
        modifiedState.errors.others = "";
        modifiedState.errors.phoneNo = "";
        modifiedState.errors.userEmailID = "";
        modifiedState.errors.rating = "";
        modifiedState.lastName = e.target.value;
        setState({...modifiedState});
    }

    const onChangeComment = (e) => {
        let modifiedState = state;
        modifiedState.errors.others = "";
        modifiedState.errors.phoneNo = "";
        modifiedState.errors.userEmailID = "";
        modifiedState.errors.rating = "";
        modifiedState.Comment = e.target.value;
        setState({...modifiedState});
    }

    const onChangeFirstName = (e) => {
        let modifiedState = state;
        modifiedState.errors.others = "";
        modifiedState.errors.phoneNo = "";
        modifiedState.errors.userEmailID = "";
        modifiedState.errors.rating = "";
        modifiedState.firstName = e.target.value;
        setState({...modifiedState});
    }

    const handleChange = (event) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = state;

        const { name, value } = event.target;
        let errors = state.errors;
        modifiedState.errors.others = "";
        modifiedState.errors.phoneNo = "";
        modifiedState.errors.userEmailID = "";
        modifiedState.errors.rating = "";

        switch (name) {
          case 'userEmailID': 
            errors.userEmailID = 
            value.length > 0 ?
            (
                (validEmailRegex.test(value)) ? 
                '' :  
                t(IDS_EmailError)
            )
            : '';
            break;         
         case 'phoneNo': 
            errors.phoneNo = 
            (value.length < 4 || value.length > 12)
                ? t(IDS_PhoneNumberErrMsg) : '';      
            break;
          default:
            break;
        }
      
        setState({
            ...modifiedState,
            errors, 
            [name]: value,
            [event.target.name]:event.target.value
        })
    }

    const onChangeCountryCode = (e) => {
        let modifiedState = state;
        modifiedState.errors.others = "";
        modifiedState.errors.rating = "";
        modifiedState.CountryCode = e.target.value;
        setState({...modifiedState});
    }

    const renderCleanlinessRatingDiv = () => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        if(state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_SIGNAL) {
            return(
                <div>
                    <div style={{display:"flex", width:"100%", justifyContent:"center"}}>
                        <img src={BroomSymbol} style={{width:"8rem", height:"2.5rem"}}/>
                    </div>
                    <div className="inputgroupCustom">
                        <label className="reg-form-label" 
                                style={{textAlign:"center", fontSize:"0.9rem", fontWeight:"bold"}} 
                        > {t(IDS_Cleanliness)} {t(IDS_Rating)}<span className="addCustRequiredMarkStar">*</span>: 
                        </label>
                        <div className="middle" hidden={state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_STARS}>
                            <label>
                                <input type="radio" name="odourRating" value="3" onChange={cleanlinessSignalRatingChanged}/>
                                <div className="back-end2 goodbox">
                                    <span><FaGrinAlt style={{fontSize:"2.7rem"}}/></span>
                                </div>
                            </label>
                            <label>
                                <input type="radio" name="odourRating" value="2" onChange={cleanlinessSignalRatingChanged}/>
                                <div className="back-end badbox">
                                    <span><FaSmile style={{fontSize:"2.7rem"}}/></span>
                                </div>
                            </label>

                            <label>
                                <input type="radio" name="odourRating" value="1" onChange={cleanlinessSignalRatingChanged}/>
                                <div className="front-end worsebox">
                                    <span><FaFrown style={{fontSize:"2.7rem"}}/></span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            );
        } else {
            return(
                <div className="inputgroupCustom" style={{paddingBottom:"0rem"}}>
                    <label className="reg-form-label" 
                            style={{marginTop:"-1rem"}}> 
                            <img src={BroomSymbol} style={{width:"4rem", marginLeft:"0.5rem"}}/>
                            {t(IDS_Cleanliness)} {t(IDS_Rating)}<span className="addCustRequiredMarkStar">*</span>: 
                    </label>
                    <div hidden={state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_SIGNAL} 
                    style={{paddingLeft: "0.2rem", paddingTop: "0.3rem", marginBottom: "0rem"}}
                    >
                        <Rating
                            iconsCount={5}
                            tooltipStyle={state.tooltipStyle
                            }
                            emptyStyle={{border: "#FFD700"}}
                            showTooltip={true}
                            tooltipDefaultText={t(IDS_YourRating)}
                            tooltipArray= {state.ratingValueForCleanlinessrating}
                            allowTitleTag={false}
                            onClick={cleanlinessRatingChanged}
                        />
                    </div>
                </div>
            )
        }
    }

    const renderOdourRatingDiv = () => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        if(state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_SIGNAL){
            return(
                <div className="inputgroupCustom">
                    <GiNoseFront  className="reg-form-label" style={{fontSize:"2.4rem"}}/>
                    <label className="reg-form-label" 
                            style={{textAlign:"center", fontSize:"0.9rem", fontWeight:"bold"}}
                    >
                        {t(IDS_Odour)} {t(IDS_Rating)}
                        <span className="addCustRequiredMarkStar">*</span>:
                    </label>
                    <div className="middle" hidden={state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_STARS}>
                        <label>
                            <input type="radio" name="cleanlinessRating" value="3" onChange={odourSignalRatingChanged}/>
                            <div className="back-end2 goodbox">
                                <span><FaGrinAlt style={{fontSize:"2.7rem"}}/></span>
                            </div>
                        </label>
                        <label>
                            <input type="radio" name="cleanlinessRating" value="2" onChange={odourSignalRatingChanged}/>
                            <div className="back-end badbox">
                                <span><FaSmile style={{fontSize:"2.7rem"}}/></span>
                            </div>
                        </label>
                        <label>
                            <input type="radio" name="cleanlinessRating" value="1" onChange={odourSignalRatingChanged}/>
                            <div className="front-end worsebox">
                                <span><FaFrown style={{fontSize:"2.7rem"}}/></span>
                            </div>
                        </label>
                    </div> 
                </div>
            );
        } else {
            return(
                <div className="inputgroupCustom">
                    <label className="reg-form-label" 
                            style={{fontSize:"1.5rem", marginLeft:"0.2rem"}}
                    >
                        <GiNoseFront />
                        <span style={{fontSize:"0.9rem"}}>{t(IDS_Odour)} {t(IDS_Rating)}
                                <span className="addCustRequiredMarkStar">*</span>:
                        </span>
                    </label>
                    <div hidden={state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_SIGNAL}
                    style={{paddingLeft: "0.2rem", paddingTop: "0.3rem", marginBottom: "0rem"}}
                    >
                        <Rating
                            iconsCount={5}
                            tooltipStyle={state.tooltipStyle}
                            emptyStyle={{border: "#FFD700"}}
                            showTooltip={true}
                            tooltipDefaultText={t(IDS_YourRating)}
                            tooltipArray= {state.ratingValueForCleanlinessrating}
                            allowTitleTag={false}
                            onClick={odourRatingChanged}
                        />
                    </div> 
                </div>
            );
        }
    }
 
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    const{errors} = state;
    const color = {
        color : "var(--errorColor)",
        fontSize:"13px"
    }
    return (
        state.bshowRatingTypes != null && state.bshowRatingTypes == true
            ?
            <div className="row">
                <div className="container col-sm-12 col-lg-6">
                    <div className="ratingPgOuterDiv">
                        <div style={state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == 'MapToilet' ? 
                                            {color:"orange", fontSize:"1rem", textAlign:"center", textTransform:"capitalize", marginTop:"0.5rem"} :
                                            {color:"orange", fontSize:"1rem", textAlign:"center", textTransform:"capitalize", marginBottom:"-1rem", marginTop:"0.5rem"}}>
                            <label style={{fontWeight:"bold"}}>{state.SelectedToiletName}</label>
                        </div>
                        <label hidden={(state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == 'MapToilet') || (state.bVisibleSuccessMasg == true && state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == "QrScanner")}
                                style={(state.SelectedToiletName == null || state.SelectedToiletName.length <= 0) ? 
                                        {color:"orange", fontSize:"1rem", textAlign:"center", textTransform:"capitalize", fontWeight:"bold"} : 
                                        {color:"grey", fontSize:"0.8rem", textAlign:"center", textTransform:"capitalize", fontWeight:"bold"}}
                        >
                            {t(IDS_Please_Our_Toilet)}
                        </label>
                        <form onSubmit={onRateToiletFormSubmit} hidden={state.bVisibleSuccessMasg == true && state.invokedFrom != null && state.invokedFrom == "QrScanner"}>
                            <div style={state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_SIGNAL ?
                                        {border: "0.1rem solid #a8cca8", backgroundColor:"#F8F8F8", borderRadius:"0.7rem", marginTop:"0.5rem", marginLeft:"0.4rem", marginRight:"0.4rem"}
                                        : {border: "0.1rem solid #a8cca8", backgroundColor:"#F8F8F8", borderRadius:"0.7rem", marginTop:"0.5rem", marginLeft:"1rem", marginRight:"1rem"}}>
                                <div className = "buttonErrorMessage">
                                    {errors.rating.length > 0 && 
                                        <p style={{color:"red", fontSize:"0.8rem", textAlign:"center"}} className='error'>{errors.rating}</p>}
                                </div>

                                {renderOdourRatingDiv()}

                                <hr style={{marginTop:"0rem"}} />                                         
                                {renderCleanlinessRatingDiv()}

                                <hr style={{marginTop:"0rem"}} hidden={(state.SelectedToiletOfRatingType!= null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_SIGNAL)} />                                           

                                <div  hidden={state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_SIGNAL} style={{marginTop:"-0.5rem"}}>
                                    <div className="inputgroupCustom" style={{paddingLeft: "0.5rem", paddingRight:"0.5rem"}}>
                                        <label className="reg-form-label">{t(IDS_Add_A_Review)}:</label>
                                        <textarea type='text' name='Comment' className="input-contact"
                                            value={state.Comment}
                                            onChange={onChangeComment} noValidate />      
                                    </div>
                                </div>
                            </div>
                            
                            <div hidden={state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_SIGNAL} style={{paddingLeft: "1rem", paddingRight:"1rem", marginTop:"0.5rem"}}>
                                <div className="inputgroupCustom">
                                    <label className="reg-form-label">{t(IDS_FirstName)}: </label>
                                    <input type='text' name='firstName' className="input-contact" 
                                    value={state.firstName}
                                    onChange={onChangeFirstName}/>
                                        {errors.firstName.length > 0 && 
                                        <span style={color} className='error'>{errors.firstName}</span>}        
                                </div>
                            </div>

                            <div hidden={state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_SIGNAL} style={{paddingLeft: "1rem", paddingRight:"1rem", marginTop:"-0.3rem"}}>
                                <div className="inputgroupCustom">
                                    <label className="reg-form-label">{t(IDS_LastName)}:</label>
                                    <input type='text' name='lastName' className="input-contact"
                                    value={state.lastName}
                                    onChange={onChangeLastName} noValidate />
                                        {errors.lastName.length > 0 && 
                                        <span style={color} className='error'>{errors.lastName}</span>}        
                                </div>
                            </div>
                            <div 
                                style={{border: "0.1rem solid #a8cca8", backgroundColor:"#F8F8F8", borderRadius:"0.7rem", marginLeft:"1rem", marginRight:"1rem"}}
                                hidden={state.SelectedToiletOfRatingType != null && state.SelectedToiletOfRatingType == DEFAULT_RATING_LEVEL_SIGNAL}
                            >
                                <div style={{paddingLeft:"0.5rem", paddingRight:"0.5rem"}}>
                                    <label className="reg-form-label">{t(IDS_Email)}:</label>
                                        <input style = {{marginBottom : "0.3rem"}} type='email' name='userEmailID' className="input-contact" 
                                                value={state.userEmailID}
                                                onChange={handleChange} noValidate />
                                            {errors.userEmailID.length > 0 && 
                                            <span style = {{paddingTop: "0.43rem", color}} className='error'>{errors.userEmailID}</span>} 
                                </div>
                                <div style={{paddingTop : "1rem", paddingLeft:"0.5rem", paddingRight:"0.5rem", marginTop:"-0.7rem"}}>
                                    <label className="reg-form-label">{t(IDS_ContactNo)}:</label>
                                        <span style={{display:"flex", justifyContent: "space-around"}}>
                                        <select className="input-contact"
                                                            required
                                                            value={state.CountryCode}
                                                            onChange={onChangeCountryCode}
                                                            style={{color: "", backgroundColor: "", width:"4.3rem", marginRight:"0.5rem"}}
                                                    >
                                                    <option value="+91" select= "true">+91</option> 
                                                    {(state.CountryInfo).map((singleCountryCode, index) => 
                                                    <option key={index} value={singleCountryCode["CountryCode"]}>
                                                    {singleCountryCode["CountryCode"]}
                                                    {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                                    {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                                    {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                                    {'\xA0'+singleCountryCode["Country"]}</option>)}
                                            </select>

                                            <input type='Number' name="phoneNo" className="input-contact"
                                                    value={state.phoneNo}
                                                    onChange={handleChange} noValidate />
                                        </span>
                                        {errors.phoneNo.length > 0 && 
                                            <span style={color} >{errors.phoneNo}</span>} 
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-evenly", marginTop:"0.7rem"}}>
                                <div hidden={state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == 'QrScanner'}>
                                    <button type="button" className="vcMapsSave" onClick={closeModal} name="Back" style={{textTransform:"capitalize"}}>{t(IDS_Back)}</button>
                                </div>
                                <div>
                                    <button type="submit" className="vcMapsSave"  name="Save" style={{textTransform:"capitalize"}} >{t(IDS_Save)}</button>
                                </div>
                            </div>
                            <div className = "buttonErrorMessage">
                                    {errors.others.length > 0 && 
                                        <p style={color} className='error'>{errors.others}</p>}
                            </div>
                        </form>
                        <div hidden={state.bVisibleSuccessMasg == false} 
                            style={{border: "0.2rem solid #a8cca8", backgroundColor:"#F8F8F8", borderRadius:"0.7rem", marginTop:"0.5rem", padding: "1rem", margin:"1rem"}}>
                                {t(IDS_Rating_Saved_Thanks_Msg)}
                            <a href="https://www.viliso.in" className = "textDecoNone"><b> www.viliso.in</b></a> .
                        </div>
                    </div>
                </div>
            </div>
            :
            <div style={{fontSize:"0.5rem"}}>Please Wait...</div>
    )
}

export default VcRateToilet;
