import { createSlice } from "@reduxjs/toolkit";

const nodeInfo = createSlice({
    name: "Node Info",
    initialState: {
        nodeData: {
            nodeID: '',
            nodeTitle: '',
            isRoot: true,
            hasDevc: false,
            isDevc: false,
            deviceType: [],
            parentID: null,
            containsChildNode: false,
            SelectedNodeDeviceType: "",
        }
    },
    reducers: {
        singleDeviceData: (state, action) => {
            state.nodeData.nodeID = action.payload.nodeID
            state.nodeData.nodeTitle = action.payload.nodeTitle
            state.nodeData.isRoot = action.payload.isRoot
            state.nodeData.hasDevc = action.payload.hasDevc
            state.nodeData.isDevc = action.payload.isDevc
            state.nodeData.deviceType = action.payload.deviceType
            state.nodeData.parentID = action.payload.parentID
            state.nodeData.containsChildNode = action.payload.containsChildNode
            state.nodeData.SelectedNodeDeviceType = action.payload.SelectedNodeDeviceType
        },

        singleDeviceDataNodeTitle: (state, action) => {
            // Copy the previous state using spread operator
            return {
                ...state,
                // Override the specific key with the new value from payload
                nodeData: {
                    ...state.nodeData,
                    nodeTitle: action.payload.nodeTitle
                }
            };
        },
    }
})


export const { singleDeviceData, singleDeviceDataNodeTitle } = nodeInfo.actions;

export default nodeInfo.reducer;