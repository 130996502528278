import React, { Component } from 'react'
import axios from 'axios';
import { AppRelevantDataContext} from '../../AppContext';
import { getAPIHostURL } from '../../ClientConfig';
// import {QrReader} from '@otterscan/react-qr-reader';
import {QrScanner} from '@yudiel/react-qr-scanner';
import { FaQrcode } from 'react-icons/fa'
import DateTimePicker from 'react-datetime-picker';
import {  convertLocalDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertUTCDateToStrYYYYMMDDHH24MMSS, getLocalTimezoneOffset } from '../../vtUtil';
import { ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS, ORDER_TYPE_DEMO, ORDER_TYPE_SALE, ORDER_TYPE_RENTAL } from '../../VcConstants';
export class VcRenewSubscription extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            LoggedInUserID: "",
            DeviceID:"",
            deviceExistenceErr: "",
            enableSubscriptionRenewalField: false,
            DevcIDQRCodeFlag: false,
            CurrentSubStartDtTm: "",
            CurrentSubEndDtTm: "",     
            CurrentSubPeriodInMonth: "",
            CurrentSubPeriodInDays: "",
            inputSubRenewalPeriod: 1,
            renewalSubPeriodInMonth: 1,
            renewalSubPeriodInDays: ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS,
            pricePerUnitINR: "",
            startDate: new Date(),
            taxPerUnitINR: "",
            selectedRenewalPeriodType: "Month",
            EnteredDeviceOrderType: "",
            balancedSubscriptionOrRentalAmt: "",
            calculatedPriceForSelectedPeriod: "",
            deductedBalanceAmount: "",
            taxPercentagePerUnitINR: "",
            remainingSubscriptionOrRentalBalanceAmt: "",
            totalSubscriptionOrRentalPayableAmt: "",
            deductedBalanceAmountForDatabase: "",
            taxPerUnitINRForDatabase: "",
            orderLineNumber: "",
            subscriptionCancelledDtTm: "",

            errors: {
                others: "",
                DeviceID: "",
                inputSubRenewalPeriod: "",
                taxPercentPerUnitINR: "",
                pricePerUnitINR: "",
            },

        }
    }

    onChangeDeviceID = (e) => {
        let modifiedState = this.state;
        modifiedState.DeviceID = e.target.value;

        // set all fields to blank as it will not give a wrong values if user change deviceID.
        modifiedState.deviceExistenceErr = "";
        modifiedState.errors.others = "";
        modifiedState.enableSubscriptionRenewalField = false;
        modifiedState.DevcIDQRCodeFlag = false;

        this.setState(modifiedState);
    }

    getSubscriptionInfo = (inModifiedState = null, boolCallFromRenewSubscription) => {

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let jsonParams = {
            DeviceID: modifiedState.DeviceID
        }

        axios.post(`${getAPIHostURL()}/wclient/getSubscriptionInfo`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if(response.data["SubscriptionInfo"] == null || response.data["SubscriptionInfo"].length <= 0 || response.data["OrderInformation"] == null ||
                    response.data["OrderInformation"].length <= 0
                ) {
                    modifiedState.deviceExistenceErr = 'Subscription/Rental Period Info is not present on the server for selected Device ID.'
                } else {

                    modifiedState.orderLineNumber = response.data["OrderInformation"] == null || response.data["OrderInformation"].length <= 0 ? null : response.data["OrderInformation"][0]["OrderLineNumber"];
                    modifiedState.EnteredDeviceOrderType = response.data["OrderInformation"] == null || response.data["OrderInformation"].length <= 0 ? null : response.data["OrderInformation"][0]["OrderType"];
                    modifiedState.balancedSubscriptionOrRentalAmt = response.data["BalancedSubscriptionInfo"] == null || response.data["BalancedSubscriptionInfo"].length <= 0 ? null : response.data["BalancedSubscriptionInfo"][0]["SubscriptionAmtToReturn"];
                    modifiedState.subscriptionCancelledDtTm = response.data["BalancedSubscriptionInfo"] == null || response.data["BalancedSubscriptionInfo"].length <= 0 ? null : response.data["BalancedSubscriptionInfo"][0]["DtTmWhenSubCancelled"];
                    modifiedState.CurrentSubPeriodInMonth = response.data["SubscriptionInfo"][0]["PeriodDays"]/30;
                    modifiedState.CurrentSubPeriodInDays = response.data["SubscriptionInfo"][0]["PeriodDays"];
                    modifiedState.pricePerUnitINR = response.data["SubscriptionInfo"][0]["DefaultPricePerUnitINR"];
                    modifiedState.taxPercentagePerUnitINR = response.data["SubscriptionInfo"][0]["DefaultTaxPercentageUnitINR"];
                    modifiedState.CurrentSubStartDtTm = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(response.data["SubscriptionInfo"][0]["SubscriptionStartDtTm"]);
                    modifiedState.CurrentSubEndDtTm = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(response.data["SubscriptionInfo"][0]["SubscriptionEndDtTm"]);
                    modifiedState.inputSubRenewalPeriod = 1;
                    modifiedState.renewalSubPeriodInDays = ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS;
                    modifiedState.selectedRenewalPeriodType = "Month";
                    modifiedState.startDate = new Date();
                    modifiedState.errors.inputSubRenewalPeriod = "";
                    modifiedState.errors.taxPercentPerUnitINR = "";
                    modifiedState.errors.pricePerUnitINR = "";
                    modifiedState.errors.others = "";

                    if(boolCallFromRenewSubscription == true) {
                        if(modifiedState.EnteredDeviceOrderType != null && modifiedState.EnteredDeviceOrderType.length > 0 && (modifiedState.EnteredDeviceOrderType == ORDER_TYPE_SALE ||
                            modifiedState.EnteredDeviceOrderType == ORDER_TYPE_DEMO)){
                            alert(`Succesfully Renewed Subscription for "${modifiedState.CurrentSubPeriodInDays.length >= 30 ? modifiedState.CurrentSubPeriodInMonth + ' Month(s)' : modifiedState.CurrentSubPeriodInDays + ' Day(s)'}" \nfrom Start Date: ` +
                                `"${modifiedState.CurrentSubStartDtTm}" to \nEnd Date: "${modifiedState.CurrentSubEndDtTm}"`);
                        } else {
                            alert(`Succesfully Renewed Rental Period for "${modifiedState.CurrentSubPeriodInDays.length >= 30 ? modifiedState.CurrentSubPeriodInMonth + ' Month(s)' : modifiedState.CurrentSubPeriodInDays + ' Day(s)'}" \nfrom Start Date: ` +
                                `"${modifiedState.CurrentSubStartDtTm}" to \nEnd Date: "${modifiedState.CurrentSubEndDtTm}"`);
                        }

                        modifiedState.enableSubscriptionRenewalField = false;
                        this.setState(modifiedState);
                    } else {
                        modifiedState.enableSubscriptionRenewalField = true;

                        // get all the caluclations for order type
                        this.GetSubscriptionOrRentalCalculations(modifiedState);
                    }

                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SUBSCRIPTIONINFO_NOT_PRESENT') {
                    modifiedState.errors.others = 'Subscription Info is not present.\nTry again later.';
                } else if (response.data.code == 'ORDERINFO_NOT_PRESENT') {
                    modifiedState.errors.others = 'Order Info is not present.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }

        })
        .catch( errors => {

            if (axios.isCancel(errors)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    GetSubscriptionOrRentalCalculations = (inModifiedState = null) => {
       
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        // the price is calculated based on renewal period days
        modifiedState.calculatedPriceForSelectedPeriod =  modifiedState.pricePerUnitINR != null && modifiedState.pricePerUnitINR.length > 0  && 
            modifiedState.renewalSubPeriodInDays != null ? (((modifiedState.pricePerUnitINR) * (modifiedState.renewalSubPeriodInDays)).toFixed(2)) : null;
        
        // this calculations is not applicable for sale and demo order type
        // if the order type is Rental and if the balancedSubscriptionOrRentalAmt is more than 0 
        // and the balancedSubscriptionOrRentalAmt is more than calculatedPriceForSelectedPeriod 
        // then subtract the calculatedPriceForSelectedPeriod from balancedSubscriptionOrRentalAmt
        // and automatically set the deductedBalanceAmount to 0 as there will be no price remaining to pay
        // when deductedBalanceAmount is 0 then dont add tax price on the price
        // and hide the text fileds of tax percent and tax price  on UI in this case 
        if(modifiedState.balancedSubscriptionOrRentalAmt != null && modifiedState.balancedSubscriptionOrRentalAmt.length > 0 && modifiedState.balancedSubscriptionOrRentalAmt > 0 && 
            modifiedState.calculatedPriceForSelectedPeriod != null && modifiedState.calculatedPriceForSelectedPeriod.length > 0 && Number(modifiedState.balancedSubscriptionOrRentalAmt) > Number(modifiedState.calculatedPriceForSelectedPeriod)){
                modifiedState.remainingSubscriptionOrRentalBalanceAmt = ((Number(modifiedState.balancedSubscriptionOrRentalAmt) - Number(modifiedState.calculatedPriceForSelectedPeriod)).toFixed(2));
                // deductedBalanceAmount is shown based on selected periods which is not as per day
                modifiedState.deductedBalanceAmount = (0).toFixed(2);

                // while storing the deductedBalanceAmount in the database the deductedBalanceAmount is divided by renewalSubPeriodInDays and stored in the database
                // in this case as deductedBalanceAmount is 0, set deductedBalanceAmountForDatabase to 0 as well
                modifiedState.deductedBalanceAmountForDatabase = (0).toFixed(2);

                // while storing the taxPerUnitINR in the database, the tax is calculated on deductedBalanceAmount
                // just in the case of Rental order.
                // in this case as deductedBalanceAmount is 0, set taxPerUnitINRForDatabase to 0 as well and store that in database
                modifiedState.taxPerUnitINRForDatabase = (0).toFixed(2);

        } else if(modifiedState.balancedSubscriptionOrRentalAmt != null && modifiedState.balancedSubscriptionOrRentalAmt > 0 && modifiedState.balancedSubscriptionOrRentalAmt.length > 0 &&
            modifiedState.calculatedPriceForSelectedPeriod != null && modifiedState.calculatedPriceForSelectedPeriod.length > 0 && Number(modifiedState.balancedSubscriptionOrRentalAmt) < Number(modifiedState.calculatedPriceForSelectedPeriod)){
                // if the order type is Rental and if the balancedSubscriptionOrRentalAmt is more than 0 
                // and the balancedSubscriptionOrRentalAmt is less than calculatedPriceForSelectedPeriod 
                // then subtract the balancedSubscriptionOrRentalAmt from calculatedPriceForSelectedPeriod
                // and automatically set the remainingSubscriptionOrRentalBalanceAmt to 0 as there will be no balanced amount remaining to pay
                // when deductedBalanceAmount is more than 0 then add tax Percentage on deductedBalanceAmount
                modifiedState.remainingSubscriptionOrRentalBalanceAmt = (0).toFixed(2);
                modifiedState.deductedBalanceAmount = (((Number(modifiedState.calculatedPriceForSelectedPeriod) - Number(modifiedState.balancedSubscriptionOrRentalAmt)))).toFixed(2);
                
                // while storing the deductedBalanceAmount in the database the deductedBalanceAmount is divided by renewalSubPeriodInDays and stored in the database
                modifiedState.deductedBalanceAmountForDatabase = ((Number(modifiedState.calculatedPriceForSelectedPeriod) - Number(modifiedState.balancedSubscriptionOrRentalAmt)) / modifiedState.renewalSubPeriodInDays).toFixed(2);
                
                // while storing the taxPerUnitINR in the database, the tax is calculated on deductedBalanceAmount
                // just in the case of Rental order.
                modifiedState.taxPerUnitINRForDatabase = modifiedState.taxPercentagePerUnitINR != null && modifiedState.taxPercentagePerUnitINR.length > 0 ? 
                    ((modifiedState.taxPercentagePerUnitINR/100) * modifiedState.deductedBalanceAmountForDatabase).toFixed(2) : null
        } else if(modifiedState.balancedSubscriptionOrRentalAmt != null && modifiedState.balancedSubscriptionOrRentalAmt > 0 && modifiedState.balancedSubscriptionOrRentalAmt.length > 0 &&
            modifiedState.calculatedPriceForSelectedPeriod != null && modifiedState.calculatedPriceForSelectedPeriod.length > 0 && Number(modifiedState.balancedSubscriptionOrRentalAmt) == Number(modifiedState.calculatedPriceForSelectedPeriod)){
                
                // if the order type is Rental and if the balancedSubscriptionOrRentalAmt is more than 0 
                // and the balancedSubscriptionOrRentalAmt is equal to calculatedPriceForSelectedPeriod 
                // then subtract the balancedSubscriptionOrRentalAmt from calculatedPriceForSelectedPeriod
                // remainingSubscriptionOrRentalBalanceAmt will be 0 and deductedBalanceAmount will be 0
                modifiedState.remainingSubscriptionOrRentalBalanceAmt = (0).toFixed(2);
                modifiedState.deductedBalanceAmount = (0).toFixed(2);

                // while storing the deductedBalanceAmount in the database the deductedBalanceAmount is divided by renewalSubPeriodInDays and stored in the database
                // in this case as deductedBalanceAmount is 0, set deductedBalanceAmountForDatabase to 0 as well
                modifiedState.deductedBalanceAmountForDatabase = (0).toFixed(2);
                
                // while storing the taxPerUnitINR in the database, the tax is calculated on deductedBalanceAmount
                // just in the case of Rental order.
                // in this case as deductedBalanceAmount is 0, set taxPerUnitINRForDatabase to 0 as well and store that in database
                modifiedState.taxPerUnitINRForDatabase = (0).toFixed(2);
        } else {
            modifiedState.remainingSubscriptionOrRentalBalanceAmt = "";
            modifiedState.deductedBalanceAmount = "";
            modifiedState.deductedBalanceAmountForDatabase = null;
            modifiedState.taxPerUnitINRForDatabase = null;
        }

        // in the case of sale and demo order the taxPerUnitINR is calculated on the basis of pricePerUnitINR
        // whereas in case of Rental order the taxPerUnitINR is calculated on the basis of deductedBalanceAmount
        // if the deductedBalanceAmount is 0 then taxPerUnitINR will also be 0 and hide the text fileds of tax percent and tax price on UI in this case 
        modifiedState.taxPerUnitINR = (modifiedState.EnteredDeviceOrderType != null && modifiedState.EnteredDeviceOrderType.length > 0 && (modifiedState.EnteredDeviceOrderType == ORDER_TYPE_DEMO || modifiedState.EnteredDeviceOrderType == ORDER_TYPE_SALE)) ||
            (modifiedState.balancedSubscriptionOrRentalAmt <= 0 || modifiedState.balancedSubscriptionOrRentalAmt == null || modifiedState.balancedSubscriptionOrRentalAmt.length <= 0) && 
            modifiedState.pricePerUnitINR != null && modifiedState.pricePerUnitINR.length > 0 && modifiedState.taxPercentagePerUnitINR != null && modifiedState.taxPercentagePerUnitINR.length > 0 ?
            ((modifiedState.taxPercentagePerUnitINR / 100) * modifiedState.pricePerUnitINR).toFixed(2) : 
            modifiedState.EnteredDeviceOrderType != null && modifiedState.EnteredDeviceOrderType.length > 0 && modifiedState.EnteredDeviceOrderType == ORDER_TYPE_RENTAL &&
            modifiedState.balancedSubscriptionOrRentalAmt > 0 && modifiedState.balancedSubscriptionOrRentalAmt != null && modifiedState.balancedSubscriptionOrRentalAmt.length > 0 && 
            modifiedState.deductedBalanceAmount != null && modifiedState.deductedBalanceAmount.length > 0 && modifiedState.taxPercentagePerUnitINR != null && modifiedState.taxPercentagePerUnitINR.length > 0 ?
            ((modifiedState.taxPercentagePerUnitINR / 100) * modifiedState.deductedBalanceAmount).toFixed(2) : "";

        // in the case of sale and demo, totalSubscriptionOrRentalPayableAmt is calculated by performing this mathematical operations as ((pricePerUnitINR + taxPerUnitINR) * renewalSubPeriodInDays)
        // in the case of Rental, totalSubscriptionOrRentalPayableAmt is calculated by performing this mathematical operations as ((deductedBalanceAmount + taxPerUnitINR)
        modifiedState.totalSubscriptionOrRentalPayableAmt = (modifiedState.EnteredDeviceOrderType != null && modifiedState.EnteredDeviceOrderType.length > 0 && (modifiedState.EnteredDeviceOrderType == ORDER_TYPE_DEMO || modifiedState.EnteredDeviceOrderType == ORDER_TYPE_SALE)) ||
            (modifiedState.balancedSubscriptionOrRentalAmt <= 0 || modifiedState.balancedSubscriptionOrRentalAmt == null || modifiedState.balancedSubscriptionOrRentalAmt.length <= 0) && 
            modifiedState.pricePerUnitINR != null && modifiedState.pricePerUnitINR.length > 0 && modifiedState.taxPerUnitINR != null && modifiedState.taxPerUnitINR.length > 0 ?
                (((Number(modifiedState.pricePerUnitINR) + Number(modifiedState.taxPerUnitINR)) * modifiedState.renewalSubPeriodInDays).toFixed(2)) :
            modifiedState.EnteredDeviceOrderType != null && modifiedState.EnteredDeviceOrderType.length > 0 && modifiedState.EnteredDeviceOrderType == ORDER_TYPE_RENTAL &&
            modifiedState.deductedBalanceAmount != null && modifiedState.deductedBalanceAmount.length > 0 && modifiedState.taxPerUnitINR != null &&
            modifiedState.taxPerUnitINR.length > 0 && modifiedState.balancedSubscriptionOrRentalAmt != null && modifiedState.balancedSubscriptionOrRentalAmt.length > 0 && modifiedState.balancedSubscriptionOrRentalAmt > 0 ?
                ((Number(modifiedState.deductedBalanceAmount) + Number(modifiedState.taxPerUnitINR))).toFixed(2) : "";

        this.setState(modifiedState);
    }

    handleKeyDown = (e) => {
        if (e.key == 'Enter') {
            // This is just to prevent form submission on pressing "enter".
            e.preventDefault();
            return;
        }
    }

    openQRCameraForDevcIDOfRenewSubscription = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 
        
        this.setState(prevState => {
            let modifiedState = prevState;

            modifiedState.DevcIDQRCodeFlag = !modifiedState.DevcIDQRCodeFlag;

            // set all fields to blank as it will not give a wrong values if user change deviceID.
            modifiedState.DeviceID = "";
            modifiedState.deviceExistenceErr = "";
            modifiedState.errors.others = "";
            modifiedState.enableSubscriptionRenewalField = false;
            return modifiedState;
        })
    } 

    handleScanResultOfRenewSubDevcID = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.DeviceID = data;
            if((modifiedState.DeviceID != null && modifiedState.DeviceID.length > 0)) {
                modifiedState.DevcIDQRCodeFlag = false;
                this.setState(modifiedState);
            } else {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
                this.setState(modifiedState);
            }
        }
    }

    handleQRCodeError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;

        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        let localTimeZone = (new Date()).getTimezoneOffset();

        modifiedState.errors.DeviceID = (modifiedState.DeviceID == null || modifiedState.DeviceID.length <= 0)
            ? "Please Enter Device ID of Product" 
            : "";

        modifiedState.errors.inputSubRenewalPeriod = (modifiedState.inputSubRenewalPeriod == null || modifiedState.inputSubRenewalPeriod.length <= 0) && modifiedState.EnteredDeviceOrderType != null &&
            modifiedState.EnteredDeviceOrderType.length > 0 && (modifiedState.EnteredDeviceOrderType == ORDER_TYPE_SALE || modifiedState.EnteredDeviceOrderType == ORDER_TYPE_DEMO)
            ? "Please Enter Subscription Renewal Period."
            : (modifiedState.inputSubRenewalPeriod == null || modifiedState.inputSubRenewalPeriod.length <= 0) && modifiedState.EnteredDeviceOrderType != null &&
            modifiedState.EnteredDeviceOrderType.length > 0 && modifiedState.EnteredDeviceOrderType == ORDER_TYPE_RENTAL
            ? "Please Enter Rental Renewal Period."
            : "";

        modifiedState.errors.pricePerUnitINR = (modifiedState.pricePerUnitINR == null || modifiedState.pricePerUnitINR.length <= 0) && modifiedState.EnteredDeviceOrderType != null &&
            modifiedState.EnteredDeviceOrderType.length > 0 && (modifiedState.EnteredDeviceOrderType == ORDER_TYPE_SALE || modifiedState.EnteredDeviceOrderType == ORDER_TYPE_DEMO)
            ? "Please Enter Subscription Price Per Unit INR." 
            : (modifiedState.pricePerUnitINR == null || modifiedState.pricePerUnitINR.length <= 0) && modifiedState.EnteredDeviceOrderType != null &&
            modifiedState.EnteredDeviceOrderType.length > 0 && modifiedState.EnteredDeviceOrderType == ORDER_TYPE_RENTAL
            ? "Please Enter Rental Price Per Unit INR." 
            : "";

        modifiedState.errors.taxPercentPerUnitINR = (modifiedState.taxPercentagePerUnitINR == null || modifiedState.taxPercentagePerUnitINR.length <= 0) && modifiedState.EnteredDeviceOrderType != null &&
            modifiedState.EnteredDeviceOrderType.length > 0 && (modifiedState.EnteredDeviceOrderType == ORDER_TYPE_SALE || modifiedState.EnteredDeviceOrderType == ORDER_TYPE_DEMO)
            ? "Please Enter Subscription Tax %." 
            : (modifiedState.taxPercentagePerUnitINR == null || modifiedState.taxPercentagePerUnitINR.length <= 0) && modifiedState.EnteredDeviceOrderType != null &&
            modifiedState.EnteredDeviceOrderType.length > 0 && modifiedState.EnteredDeviceOrderType == ORDER_TYPE_RENTAL
            ? "Please Enter Rental Tax %."
            : "";

        // if any one of the required fields is not entered by user then return the function and show error message.
        if(modifiedState.errors.DeviceID.length > 0 || modifiedState.errors.inputSubRenewalPeriod.length > 0 ||
            modifiedState.errors.pricePerUnitINR.length > 0 || modifiedState.errors.taxPercentPerUnitINR.length > 0) {
                
            this.setState(modifiedState);
            return
        }

        if(modifiedState.EnteredDeviceOrderType != null && modifiedState.EnteredDeviceOrderType.length > 0 && (modifiedState.EnteredDeviceOrderType == ORDER_TYPE_SALE ||
            modifiedState.EnteredDeviceOrderType == ORDER_TYPE_DEMO)){
            if(!window.confirm(`Are you sure you want to Renew Subscription for "${modifiedState.renewalSubPeriodInDays.length >= 30 ? modifiedState.renewalSubPeriodInMonth + ' Month(s)' : modifiedState.renewalSubPeriodInDays + ' Day(s)'}" for selected Device ID "${modifiedState.DeviceID}"?`)){
                return
            }
        } else {
            if(!window.confirm(`Are you sure you want to Renew Rental Period for "${modifiedState.renewalSubPeriodInDays.length >= 30 ? modifiedState.renewalSubPeriodInMonth + ' Month(s)' : modifiedState.renewalSubPeriodInDays + ' Day(s)'}" for selected Device ID "${modifiedState.DeviceID}"?`)){
                return
            }
        }

        // if the order type is rental and the balancedSubscriptionOrRentalAmt is more than 0 then modifiedState.pricePerUnitINR is sent to database 
        let PricePerUnitINRToSend = modifiedState.pricePerUnitINR != null && modifiedState.pricePerUnitINR.length > 0 ? modifiedState.pricePerUnitINR : 0.00;

        // if the order type is sale or demo then TaxPerUnitINRValue is sent to database
        // if order type is Rental then taxPerUnitINRForDatabase is sent to the database
        let TaxPerUnitINRValue = modifiedState.taxPerUnitINR != null && modifiedState.taxPerUnitINR.length > 0 ? modifiedState.taxPerUnitINR : null;
        let TaxPerUnitINRToSend = modifiedState.EnteredDeviceOrderType != null && modifiedState.EnteredDeviceOrderType.length > 0 && (modifiedState.EnteredDeviceOrderType == ORDER_TYPE_SALE || modifiedState.EnteredDeviceOrderType == ORDER_TYPE_DEMO) || 
            (modifiedState.balancedSubscriptionOrRentalAmt <= 0 || modifiedState.balancedSubscriptionOrRentalAmt == null || modifiedState.balancedSubscriptionOrRentalAmt.length <= 0) ? TaxPerUnitINRValue : modifiedState.taxPerUnitINRForDatabase;
        
        // if the order type is rental and the balancedSubscriptionOrRentalAmt is more than 0 then remainingSubscriptionOrRentalBalanceAmtToSent is sent to database 
        // other vise if the order type is sale or demo than BalancedSubscriptionAmount is sent as null
        let remainingSubscriptionOrRentalBalanceAmtToSent = modifiedState.remainingSubscriptionOrRentalBalanceAmt != null && modifiedState.remainingSubscriptionOrRentalBalanceAmt.length > 0 ? modifiedState.remainingSubscriptionOrRentalBalanceAmt : null;
        let BalancedSubscriptionAmount = (modifiedState.EnteredDeviceOrderType != null && modifiedState.EnteredDeviceOrderType.length > 0 && (modifiedState.EnteredDeviceOrderType == ORDER_TYPE_SALE || modifiedState.EnteredDeviceOrderType == ORDER_TYPE_DEMO)) && 
            (modifiedState.balancedSubscriptionOrRentalAmt <= 0 || modifiedState.balancedSubscriptionOrRentalAmt == null || modifiedState.balancedSubscriptionOrRentalAmt.length <= 0) ? null : remainingSubscriptionOrRentalBalanceAmtToSent;

        let UtcStartDtTm = null;

        let tempStart = modifiedState.startDate.toString();

        if(((tempStart != null && tempStart.length > 0) && modifiedState.CurrentSubEndDtTm != null && new Date(modifiedState.CurrentSubEndDtTm) < new Date(modifiedState.startDate))) {  

            let strLoclStartDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.startDate);
    
            let strLoclStartDtTm = strLoclStartDate.split(" ")[0] + "T" + "00:00:00";
        
            UtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclStartDtTm.valueOf()));
        }

        let jsonParams = {
            ProductItemID: modifiedState.DeviceID,
            LoggedInUserID: modifiedState.LoggedInUserID,
            PricePerUnitOrderINR: PricePerUnitINRToSend,
            TaxPerUnitOrderINR: TaxPerUnitINRToSend,
            PeriodToRenewSubInDays: modifiedState.renewalSubPeriodInDays,
            LocalTimeZone: getLocalTimezoneOffset(localTimeZone),
            OrderLineNumber: modifiedState.orderLineNumber,
            BalancedSubscriptionAmount: BalancedSubscriptionAmount,
            EnteredDeviceOrderType: modifiedState.EnteredDeviceOrderType,
            RenewSubStartDayWhenSubExp: UtcStartDtTm
        }
        
        axios.post(`${getAPIHostURL()}/wclient/renewSubscriptionDetails`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                let boolCallFromRenewSubscription = true;
                this.getSubscriptionInfo(modifiedState, boolCallFromRenewSubscription); 

            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }
            
        })
        .catch( errors => {

            if (axios.isCancel(errors)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    verifyEnteredDeviceID = () => {

        let modifiedState = this.state;

        if(modifiedState.DeviceID == null || modifiedState.DeviceID.length <=0 ) {
            modifiedState.deviceExistenceErr = 'Please enter DeviceID.';
            this.setState(modifiedState);
            return;
        }

        let jsonParams = {
            EnteredDeviceID: modifiedState.DeviceID,
            invokedFrom: "renewSubscription"
        }

        axios.post(`${getAPIHostURL()}/wclient/checkDeviceIdExistence`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data.isDeviceIDExist == null) {
                    modifiedState.deviceExistenceErr = "Server experiencing issues.\nTry again later.";
                    this.setState(modifiedState);

                } else {
                    if(response.data.isDeviceIDExist == 0) {
                        modifiedState.deviceExistenceErr = 'Invalid Device ID.';
                        this.setState(modifiedState);
                    } else if(response.data.isStateIDValid == 0 || response.data.isStateIDValid == null) { 
                        modifiedState.deviceExistenceErr = 'Invalid State of Device ID. The Device should be in "Mapped to Owner" or "Mapped as Replacement" state.';
                        this.setState(modifiedState);
                    } else {
                        modifiedState.deviceExistenceErr = "";
                        // modifiedState.enableSubscriptionRenewalField = true;
                        let boolCallFromRenewSubscription = false;
                        modifiedState.selectedRenewalPeriodType = "";

                        this.getSubscriptionInfo(modifiedState, boolCallFromRenewSubscription);
                    }
                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                    
                }
                this.setState(modifiedState);
            }
        })
        .catch( errors => {

            if (axios.isCancel(errors)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.deviceExistenceErr = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    onChangeRenewalPeriodMntOrYr = (e) => {

        let modifiedState = this.state;
        modifiedState.selectedRenewalPeriodType = e.target.value;

        if(modifiedState.selectedRenewalPeriodType == "Month") {
            modifiedState.renewalSubPeriodInMonth = modifiedState.inputSubRenewalPeriod; 
            modifiedState.renewalSubPeriodInDays = modifiedState.renewalSubPeriodInMonth * ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS;
        } else if(modifiedState.selectedRenewalPeriodType == "Year"){
            modifiedState.renewalSubPeriodInMonth = modifiedState.inputSubRenewalPeriod * 12;
            modifiedState.renewalSubPeriodInDays = modifiedState.renewalSubPeriodInMonth * ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS;
        } else if(modifiedState.selectedRenewalPeriodType == "Day"){
            modifiedState.renewalSubPeriodInMonth = "";
            modifiedState.renewalSubPeriodInDays = modifiedState.inputSubRenewalPeriod;
        } else {
            modifiedState.renewalSubPeriodInMonth = ""; 
            modifiedState.renewalSubPeriodInDays = "";
        }

        this.GetSubscriptionOrRentalCalculations(modifiedState);
    }

    onChangeStartDate = (updatedStartDt) => {
        let modifiedState = this.state;
        let currentDate = new Date();

        modifiedState.errors.timeRelatedErr = "";

        if(updatedStartDt < new Date(this.state.CurrentSubEndDtTm)) {
            alert (`Start Date can not be less than Current/Recent ${this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Rental" : "Subscription"} End Date. It will be set to Current Date.`);
            modifiedState.startDate = currentDate;

        } else {
            modifiedState.startDate = updatedStartDt;
        }
        this.setState(modifiedState);
    }

    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        let modifiedState = this.state;

        switch (name) {

            case 'inputSubRenewalPeriod': 
                value = Math.abs(value) > 0 ? Math.abs(value) : "";

                if(modifiedState.selectedRenewalPeriodType == "Month") {
                    modifiedState.renewalSubPeriodInMonth = value;
                    modifiedState.inputSubRenewalPeriod = value;
                    modifiedState.renewalSubPeriodInDays = Math.round(modifiedState.renewalSubPeriodInMonth * ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS); 
                } else if(modifiedState.selectedRenewalPeriodType == "Year") {
                    modifiedState.renewalSubPeriodInMonth = value * 12;
                    modifiedState.inputSubRenewalPeriod = value;
                    modifiedState.renewalSubPeriodInDays = Math.round(modifiedState.renewalSubPeriodInMonth * ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS);
                } else if(modifiedState.selectedRenewalPeriodType == "Day") {
                    modifiedState.renewalSubPeriodInMonth = "";
                    modifiedState.inputSubRenewalPeriod = value;
                    modifiedState.renewalSubPeriodInDays = value;
                } else {
                    modifiedState.renewalSubPeriodInMonth = "";
                    modifiedState.renewalSubPeriodInDays = ""; 
                    modifiedState.inputSubRenewalPeriod = "";
                }

                modifiedState.errors.others= "";
                modifiedState.errors.inputSubRenewalPeriod = "";

            break;

            case 'pricePerUnitINR':
                value = value >= 0 ? value : "";
                modifiedState.pricePerUnitINR = value;
                modifiedState.errors.others= "";
                modifiedState.errors.pricePerUnitINR = "";     
            break;

            case 'taxPercentagePerUnitINR': 
                value = value >= 0 ? value : "";
                modifiedState.taxPercentagePerUnitINR = value;
                modifiedState.errors.others= "";
                modifiedState.errors.taxPercentPerUnitINR = "";
            break;

            default:
            break;
        }

        // get all the fields calculations on on change.
        this.GetSubscriptionOrRentalCalculations(modifiedState);
    }
    
    
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-lg-offset-2
                                              col-md-12">
                        <div className="modal-body p-4 box">
                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1rem"}}>Renew Subscription/Rental Period</div> 
                            <form onSubmit={this.onFormSubmit}>
                                <div>
                                    <label className="reg-form-label" 
                                        style={{width:"100%"}}
                                    >
                                        Enter DeviceID of Product Or Scan the QR Code:
                                    </label>
                                    <input style={{width:"100%"}}
                                           className="input-form"
                                           value= {this.state.DeviceID}
                                           onKeyDown={this.handleKeyDown}
                                           onChange = {this.onChangeDeviceID}
                                           required
                                    />
                                    <label onClick = {this.openQRCameraForDevcIDOfRenewSubscription} className = "qrcodeTextBtn">
                                        <FaQrcode className = "qrcodeTextBtnIcon"/>
                                    </label>
                                    <div>
                                        {(this.state.DevcIDQRCodeFlag == true)
                                        ?
                                        //     <div style={{display: "flex", justifyContent: "center"}}>
                                        //         <QrReader
                                        //             scanDelay={300}
                                        //             onResult={(result, error) => {
                                        //                 if (!!result) {
                                        //                     this.handleScanResultOfRenewSubDevcID(result?.text);
                                        //                 }
                                        //             }}
                                        //             className = "QRCodeCamBoxForModalAndContainer"
                                        //         />
                                        //     </div>

                                        <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                        marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                        marginRight: "auto"}}>                                        
                                            <QrScanner
                                            scanDelay={300}
                                            onResult={(result, error) => {
                                                if (!!result) {
                                                    this.handleScanResultOfRenewSubDevcID(result?.text);
                                                }
                                            }}
                                            className = "QRCodeCamBoxForModalAndContainer"
                                        />
                                    </div>
                                        :
                                            <div/>
                                        }
                                    </div>

                                    {this.state.enableSubscriptionRenewalField == false &&
                                        <div style={{display:"flex", justifyContent: "center", flexDirection:"column"}}>
                                            <button type={"button"}
                                                    style={{borderRadius: "5px", 
                                                            width:'40%', 
                                                            marginLeft:'30%',
                                                            marginTop:"0.5rem",
                                                            backgroundColor: "var(--primaryColor)", color:"white",
                                                            fontSize: "1.2rem", padding: "0.4rem"}}
                                                    className="btn-lg"
                                                    onClick={this.verifyEnteredDeviceID} 
                                            >
                                                Proceed
                                            </button>
                                            {this.state.errors.others.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>} 
                                            {this.state.deviceExistenceErr.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='errors'>{this.state.deviceExistenceErr}</p>}  
                                        </div>
                                    }                          
                                </div>
                                {(this.state.enableSubscriptionRenewalField == true) && 
                                    <div>
                                        <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                            <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem"}}>
                                            { this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                this.state.EnteredDeviceOrderType == ORDER_TYPE_SALE && this.state.balancedSubscriptionOrRentalAmt != null &&
                                                this.state.balancedSubscriptionOrRentalAmt.length > 0 && this.state.subscriptionCancelledDtTm != null
                                                ? "Subscription End Date Time"
                                                :   this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                    this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL && this.state.balancedSubscriptionOrRentalAmt != null &&
                                                    this.state.balancedSubscriptionOrRentalAmt.length > 0 && this.state.subscriptionCancelledDtTm != null
                                                ? "Rental Period End Date Time"
                                                :   this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                    this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL && (this.state.balancedSubscriptionOrRentalAmt == null || 
                                                    this.state.balancedSubscriptionOrRentalAmt.length <= 0 || this.state.subscriptionCancelledDtTm == null)  
                                                ? "Current/Recent Rental Package Details" 
                                                : "Current/Recent Subscription Package Details"
                                            }
                                            </label>
                                            {/* if in the case of Rental and sale , if subscriptionCancelledDtTm is null then just show the cancelled end date time */}
                                            { (this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                (this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL || this.state.EnteredDeviceOrderType == ORDER_TYPE_SALE)) && this.state.balancedSubscriptionOrRentalAmt != null &&
                                                this.state.balancedSubscriptionOrRentalAmt.length > 0 && this.state.subscriptionCancelledDtTm != null && this.state.subscriptionCancelledDtTm.length > 0
                                            ?
                                            <div>
                                                <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
                                                    <div style={{width:"48%"}}>
                                                        <input style={{color: "#505050", background:"#F5F5F5"}}
                                                            className="input-form"
                                                            value= {this.state.CurrentSubEndDtTm}
                                                            readOnly
                                                        /> 
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className='validationErrorLabel' style={{textAlign:"center", color:"green", marginBottom:"0rem", fontSize:"0.9rem"}}>
                                                        {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                            this.state.EnteredDeviceOrderType == ORDER_TYPE_SALE 
                                                            ? `Note: Subscription(s) are cancelled for Entered Device ID '${this.state.DeviceID}'` 
                                                            : `Note: Rental Period Cancelled for Entered Device ID '${this.state.DeviceID}'`
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className = "startAndEnddate" style={{marginTop: "0.5rem"}}>
                                                    <div style={{flex: "1"}}>
                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                        {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                        this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Current/Recent Rental Package Details" : "Current/Recent Subscription Package Details"}
                                                        </label>
                                                        <div style={{display: "flex"}}>
                                                            <input style={{fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5"}}
                                                                className="input-form"
                                                                value=  {`${this.state.CurrentSubPeriodInDays.length >= 30 ? this.state.CurrentSubPeriodInMonth + ' Months' : this.state.CurrentSubPeriodInDays + ' Days'}`}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{paddingLeft: "0.8rem", paddingRight: "0.8rem", paddingTop: "0.3rem", alignItems: "center"}}>
                                                    <br/>
                                                        =
                                                    </div>
                                                    <div style={{flex: "1"}}>
                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                        {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                        this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Current/Recent Rental Period In Days:" : "Current/Recent Subscription Period In Days:"}
                                                        </label>
                                                        <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", width:"100%"}}
                                                                className="input-form"
                                                                value= {this.state.CurrentSubPeriodInDays + " Days"}
                                                                readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className = "startAndEnddate" style = {{ marginTop: "0.5rem"}}>
                                                    <div style={{flex: "1", paddingRight:"1rem"}}>
                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                        {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                        this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Current/Recent Rental Period Start Date Time:" : "Current/Recent Subscription Start Date Time:"}
                                                        </label>
                                                        <input style={{color: "#505050", background:"#F5F5F5"}}
                                                                className="input-form"
                                                                value= {this.state.CurrentSubStartDtTm}
                                                                readOnly
                                                        />
                                                    </div>

                                                    <div style={{flex: "1", paddingLeft:"1rem"}}>
                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                        {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                        this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Current/Recent Rental Period End Date Time:" : "Current/Recent Subscription End Date Time:"}
                                                        </label>
                                                        <input style={{color: "#505050", background:"#F5F5F5"}}
                                                                className="input-form"
                                                                value= {this.state.CurrentSubEndDtTm}
                                                                readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginTop:"1rem", background:"white"}}>
                                            <div>
                                                <label style={{ display: "flex", justifyContent:"flex-end", fontSize:"0.7rem"}}><b style={{marginRight:"0.1rem"}}>Note: </b> Fields marked in <span style={{color:"var(--errorColor)", fontSize:"1rem", marginLeft:"0.1rem", marginRight:"0.1rem"}}>* </span> are compulsory.</label>
                                            </div>
                                            <div>
                                                <label className="reg-form-label" style={{width:"100%", display: "flex", justifyContent: "center", fontSize:"1.1rem"}}>
                                                {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                    this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Rental Period Renewal Details" : "Subscription Renewal Details"}
                                                </label>
                                                <div className = "startAndEnddate" style={{ marginTop: "0.5rem"}}>
                                                    <div style={{flex: "1"}}>
                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                        {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                    this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Enter Rental Renewal Period:" : "Enter Subscription Renewal Period:"}
                                                                    <span className="addCustRequiredMarkStar">*</span>
                                                        </label>
                                                        <div style={{display: "flex"}}>
                                                            <input style={{flex:"2", fontSize:"1rem", borderRadius:"0.3rem", paddingRight:"1.5rem"}}
                                                                className="input-form"
                                                                name = "inputSubRenewalPeriod"
                                                                type= "number"
                                                                min = "0"
                                                                value= {this.state.inputSubRenewalPeriod}
                                                                onChange= {this.handleChange}
                                                            />
                                                            <div style={{height:"2.5rem", flex:"4", paddingLeft:"1.5rem"}}>
                                                                <select className="input-form" 
                                                                    value={this.state.selectedRenewalPeriodType}
                                                                    onChange={this.onChangeRenewalPeriodMntOrYr}
                                                                >
                                                                    <option value = "Month" select="true"> Month </option>
                                                                    <option value = "Year" > Year </option>  
                                                                    <option value = "Day" > Day </option>                                                                    
                                                                </select>
                                                            </div>
                                                        </div>  
                                                        {this.state.errors.inputSubRenewalPeriod.length > 0 && 
                                                            <span  className='validationErrorLabel'>{this.state.errors.inputSubRenewalPeriod}</span>}  
                                                    </div>
                                                    <div style={{paddingLeft: "0.8rem", paddingRight: "0.8rem", paddingTop: "0.3rem", alignItems: "center"}}>
                                                        <br/> =
                                                    </div>
                                                    <div style={{flex: "1"}}>
                                                        <label className="reg-form-label" style={{width:"100%"}}>
                                                        {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                    this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Rental Renewal Period In Days:" : "Subscription Renewal Period In Days:"}
                                                        </label>
                                                        <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                                className="input-form"
                                                                value= {this.state.renewalSubPeriodInDays + " Days"}
                                                                readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                    {((this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0) && (this.state.CurrentSubEndDtTm != null 
                                                        && new Date(this.state.CurrentSubEndDtTm) < new Date())
                                                    )
                                                    ?
                                                    <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
                                                        <div style={{width:"48%"}}>
                                                             <label className="reg-form-label" style={{width:"100%",textAlign:"center"}}>
                                                                {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                    this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Enter Rental Renewal Start Date:" : "Enter Subscription Renewal Start Date:"
                                                                }
                                                                <span className="addCustRequiredMarkStar">*</span>
                                                             </label>                                                        
                                                            <DateTimePicker
                                                                style={{width:"100%", fontSize:"10rem", borderRadius:"0.3rem"}}
                                                                clearIcon={null}
                                                                onChange={this.onChangeStartDate}
                                                                format={"dd/MMM/yyyy"} 
                                                                value={this.state.startDate}
                                                                name="StartDateTime"
                                                                timeFormat={false}
                                                                className="input-form-datetime dashBoardDateTimeZIndex"
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div/>
                                                    }
                                                </div> 
                                           
                                            <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                {(this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 &&
                                                            (this.state.EnteredDeviceOrderType == ORDER_TYPE_SALE || this.state.EnteredDeviceOrderType == ORDER_TYPE_DEMO)) || (this.state.balancedSubscriptionOrRentalAmt == null ||
                                                                this.state.balancedSubscriptionOrRentalAmt.length <= 0 || this.state.balancedSubscriptionOrRentalAmt <= 0) 
                                                ?
                                                <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
                                                    <div style={{width:"48%"}}>
                                                        <label className="reg-form-label" style={{width:"100%",textAlign:"center"}}>
                                                            Default Price Per Unit (Day) INR:<span className="addCustRequiredMarkStar">*</span>
                                                        </label>
                                                    
                                                        <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem"}}
                                                            className="input-form"
                                                            type= "number"
                                                            step="any"
                                                            name = "pricePerUnitINR"
                                                            min = "0"
                                                            value= {this.state.pricePerUnitINR}
                                                            onChange= {this.handleChange}
                                                        />  
                                                        {this.state.errors.pricePerUnitINR.length > 0 && 
                                                            <span  className='validationErrorLabel'>{this.state.errors.pricePerUnitINR}</span>} 
                                                    </div>
                                                </div>
                                                :
                                                <div style={{flex:"1", paddingRight:"1rem"}}>
                                                    <label className="reg-form-label" style={{width:"100%"}}>
                                                        Default Price Per Unit (Day) INR:<span className="addCustRequiredMarkStar">*</span>
                                                    </label>
                                                
                                                    <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem"}}
                                                        className="input-form"
                                                        type= "number"
                                                        step="any"
                                                        name = "pricePerUnitINR"
                                                        value= {this.state.pricePerUnitINR}
                                                        onChange= {this.handleChange}
                                                    />  
                                                    {this.state.errors.pricePerUnitINR.length > 0 && 
                                                        <span  className='validationErrorLabel'>{this.state.errors.pricePerUnitINR}</span>} 
                                                </div>
                                                }

                                                <div style={{flex:"1", paddingLeft:"1rem"}} hidden={(this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 &&
                                                        (this.state.EnteredDeviceOrderType == ORDER_TYPE_SALE || this.state.EnteredDeviceOrderType == ORDER_TYPE_DEMO)) || (this.state.balancedSubscriptionOrRentalAmt == null ||
                                                            this.state.balancedSubscriptionOrRentalAmt.length <= 0 || this.state.balancedSubscriptionOrRentalAmt <= 0) ? true : false}>
                                                    <label className="reg-form-label" style={{width:"100%"}}>
                                                        Calculated Price for Selected Renewal Period:</label>

                                                    <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                        className="input-form"
                                                        type= "number"
                                                        name = "calculatedPriceForSelectedPeriod"
                                                        value= {this.state.calculatedPriceForSelectedPeriod}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className = "startAndEnddate" style={{marginTop:"0.5rem"}} hidden={(this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 &&
                                                        (this.state.EnteredDeviceOrderType == ORDER_TYPE_SALE || this.state.EnteredDeviceOrderType == ORDER_TYPE_DEMO)) || (this.state.balancedSubscriptionOrRentalAmt == null ||
                                                            this.state.balancedSubscriptionOrRentalAmt.length <= 0 || this.state.balancedSubscriptionOrRentalAmt <= 0) ? true : false}>
                                                <div style={{flex:"1", paddingRight:"1rem"}}>
                                                    <label className="reg-form-label" style={{width:"100%"}}>
                                                    <br/>
                                                    {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Previous Balanced Rental Amount:" : "Previous Balanced Subscription Amount:"}
                                                    </label>
                                                    <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                            className="input-form"
                                                            value= {this.state.balancedSubscriptionOrRentalAmt}
                                                            readOnly
                                                    />
                                                </div>

                                                <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                    <label className="reg-form-label" style={{width:"100%"}}>
                                                    {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Amount after deduction of Previous Balanced Rental Amount:" : "Amount after deduction of Previous Balanced Subscription Amount:"}
                                                    </label>
                                                    <input style={{ fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                            className="input-form"
                                                            value= {this.state.deductedBalanceAmount}
                                                            readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className = "startAndEnddate" style={{marginTop:"0.5rem"}} hidden={this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 &&
                                                        this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL && this.state.deductedBalanceAmount != null &&
                                                            this.state.deductedBalanceAmount.length > 0 && this.state.deductedBalanceAmount <= 0 ? true : false}>
                                                <div style={{flex:"1", paddingRight:"1rem"}}>
                                                    <label className="reg-form-label" style={{width:"100%"}}>
                                                       Default Tax %:<span className="addCustRequiredMarkStar">*</span>
                                                    </label>
                                                
                                                    <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem"}}
                                                        className="input-form"
                                                        type= "number"
                                                        step="any"
                                                        name = "taxPercentagePerUnitINR"
                                                        value= {this.state.taxPercentagePerUnitINR}
                                                        onChange= {this.handleChange}
                                                    />  
                                                    {this.state.errors.taxPercentPerUnitINR.length > 0 && 
                                                        <span  className='validationErrorLabel'>{this.state.errors.taxPercentPerUnitINR}</span>} 
                                                </div>

                                                <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                    <label className="reg-form-label" style={{width:"100%"}}>
                                                    {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                        this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL && this.state.balancedSubscriptionOrRentalAmt != null &&
                                                            this.state.balancedSubscriptionOrRentalAmt.length > 0 ? "Tax Price(INR):" : "Tax Price Per (Day) INR:"}
                                                    </label>
                                                
                                                    <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                        className="input-form"
                                                        type= "number"
                                                        name = "taxPerUnitINR"
                                                        value= {this.state.taxPerUnitINR}
                                                        readOnly
                                                        />
                                                </div>
                                            </div>

                                            <div className = "startAndEnddate" style={{marginTop:"0.5rem"}}>
                                                <div style={{flex:"1", paddingRight:"1rem"}} hidden={(this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 &&
                                                        (this.state.EnteredDeviceOrderType == ORDER_TYPE_SALE || this.state.EnteredDeviceOrderType == ORDER_TYPE_DEMO)) || (this.state.balancedSubscriptionOrRentalAmt == null ||
                                                            this.state.balancedSubscriptionOrRentalAmt.length <= 0 || this.state.balancedSubscriptionOrRentalAmt <= 0) ? true : false}>
                                                    <label className="reg-form-label" style={{width:"100%"}}>
                                                    {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                            this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Remaining Rental Balance after Renewal:" : "Remaining Subscription Balance after Renewal:"}
                                                    </label>
                                                
                                                    <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem"}}
                                                        className="input-form"
                                                        type= "number"
                                                        value= {this.state.remainingSubscriptionOrRentalBalanceAmt}
                                                        readOnly
                                                        />
                                                </div>
                                                    
                                                {(this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 &&
                                                        (this.state.EnteredDeviceOrderType == ORDER_TYPE_SALE || this.state.EnteredDeviceOrderType == ORDER_TYPE_DEMO)) || (this.state.balancedSubscriptionOrRentalAmt == null ||
                                                            this.state.balancedSubscriptionOrRentalAmt.length <= 0 || this.state.balancedSubscriptionOrRentalAmt <= 0) 
                                                ?
                                                <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
                                                    <div style={{width:"48%"}}>
                                                        <label className="reg-form-label" style={{width:"100%", fontWeight:"bolder", textAlign:"center"}}>
                                                            {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                                this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Total Payable Rental Amount:" : "Total Payable Subscription Amount"}
                                                        </label>
                                                    
                                                        <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", fontWeight:"bolder"}}
                                                            className="input-form"
                                                            name = "totalSubscriptionOrRentalPayableAmt"
                                                            value= {this.state.totalSubscriptionOrRentalPayableAmt}
                                                            readOnly
                                                        />  
                                                    </div>
                                                </div>
                                                :
                                                <div style={{flex:"1", paddingLeft:"1rem"}}>
                                                    <label className="reg-form-label" style={{width:"100%", fontWeight:"bolder"}}>
                                                        {this.state.EnteredDeviceOrderType != null && this.state.EnteredDeviceOrderType.length > 0 && 
                                                            this.state.EnteredDeviceOrderType == ORDER_TYPE_RENTAL ? "Total Payable Rental Amount:" : "Total Payable Subscription Amount"}
                                                    </label>
                                                
                                                    <input style={{width:"100%", fontSize:"1rem", borderRadius:"0.3rem", color: "#505050", background:"#F5F5F5", marginRight:"2rem", fontWeight:"bolder"}}
                                                        className="input-form"
                                                        name = "totalSubscriptionOrRentalPayableAmt"
                                                        value= {this.state.totalSubscriptionOrRentalPayableAmt}
                                                        readOnly
                                                    />   
                                                </div>
                                                }
                                            </div>
                                        </div>                                        
                                        <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                            <button style={{borderRadius: "5px", width:"30%", marginTop:"1rem",
                                                            marginLeft:"35%",
                                                            backgroundColor: "var(--primaryColor)", color:"white",
                                                        }}
                                                    className="btn-lg"
                                            >
                                                Save
                                            </button>
                                            {this.state.errors.others.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>} 
                                        </div> 
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}
VcRenewSubscription.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcRenewSubscription;
