import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AppRelevantDataContext} from '../../AppContext';
import { getAPIHostURL } from '../../ClientConfig';
import DateTimePicker from 'react-datetime-picker';
import '../CSS/VcStagewiseProdStat.css';
import { INCLUDES_ALL_PRODUCT_NAME, ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED, ITM_STATE_FUNCTIONAL_TESTED, 
         ITM_STATE_READY_FOR_SHIPMENT, ITM_STATE_DEMO, ITM_STATE_SOLD, ITM_STATE_RENTAL, ITM_STATE_FAULTY, ITM_STATE_UNDER_REPAIR, 
         ITM_STATE_MAPPED_TO_OWNER, ITM_STATE_MAPPED_AS_REPLACEMENT, CSV_COLUMN_SEPERATOR, CSV_LINE_SEPERATOR, 
         ITM_STATE_DECOMMISSIONED_DEVICEID, ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT, ITM_STATE_RECALL_SOLD_DEVICE, 
         ITM_STATE_DEVC_UPGRADE, PVG_PRODUCTION_SUPERVISOR, PVG_PRODUCTION_WORKER, ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT,
         CURRENT_FIELD_DEVICES, ACTIVE_STATUS_MINIMUM_TIME} from '../../VcConstants';
import { convertUTCDateToStrYYYYMMDDHH24MMSS, getLocalTimezoneOffset, convertLocalDateWithFormatDDMMMYY, 
         convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday,
         convertLocalDateToStrYYYYMMDDHH24MMSS } from '../../vtUtil';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { FaCircle, FaSearch } from 'react-icons/fa';
import { IDS_TodaySearch, IDS_RDEndDTNotInRng, IDS_RDEndDTSetAcrodStartDT,
         IDS_RDEndDtGrtCurDt, IDS_RDEndDTAdjust, IDS_RDStartDTAdjust, IDS_RDStartDTAdjustNotMaxRng, 
         IDS_RDStartDTSetAcrodEndDT, IDS_RDStartDTAdjustMinPossible } from '../../VcLanguage';
import '../CSS/SingleDeviceData.css';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// This file derives "All States-Dashboard" and "Current State Inventory".

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
    }) {
    return(
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
             <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"0.9rem"}}/>
             <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data, getCellProps = defaultPropGetter, passedStateVariable, renderBasedOnTable}) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // set page size based on tables
            initialState: {pageSize: renderBasedOnTable == "allStatesTable" && passedStateVariable[0] == INCLUDES_ALL_PRODUCT_NAME ? 8 : 
                renderBasedOnTable == "allStatesTable" && passedStateVariable[0] != INCLUDES_ALL_PRODUCT_NAME ? 2 : renderBasedOnTable == "oneStateTable" && passedStateVariable[1].length > 10 ? 10 : 
                    passedStateVariable[1].length + 1, 
            // hide and show columns based on condition
            hiddenColumns:  passedStateVariable[2] != null && passedStateVariable[2].length > 0 && 
                (passedStateVariable[2] != "allStatesDashboard" && passedStateVariable[2] != "currentFieldDevices") ? [] : ["Manufactured and Hardware Tested", "Functional Tested"]},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );
    
    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.5rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;{!passedStateVariable[3] ? "No Data Found." : ""}</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };

    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* page.length > 0 && */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"     
                                    style={{
                                        cursor: '',
                                        background: '',
                                        color: 'black',
                                        alignItems: "center",
                                    }}  
                                >
                                    {row.cells.map(cell => {
                                        return <td  {...cell.getCellProps(
                                                        [
                                                            {style: cell.column.style},
                                                            getCellProps(cell),
                                                        ]
                                                    )} 
                                                    className="td"
                                                >{cell.render("Cell")}
                                            </td>;
                                        })
                                    }
                                </tr>
                            );
                            // when there is no data found 
                        }) 
                        // || <tr style = {{backgroundColor: "white"}}><td><span  style={{paddingLeft:"1rem", color: "green", display: "flex" ,justifyContent:"left"}}>{!passedStateVariable[3] ? "No Data Found." : ""}</span></td></tr>
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div hidden = {renderBasedOnTable == "allStatesTable" && passedStateVariable[0] == INCLUDES_ALL_PRODUCT_NAME ? false : renderBasedOnTable == "allStatesTable" && passedStateVariable[0] != INCLUDES_ALL_PRODUCT_NAME ? true : 
                   renderBasedOnTable == "oneStateTable" && passedStateVariable[1].length == 1 ? true : false}>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                 {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcAllStateDashboard (props) {

    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {

        if(id != "StateDateAndTime") {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
                })
        } else {
            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]

                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                    else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            })
        }
    };

    const [state, setState] = useState({
        invokedFrom: props.invokedFrom,
        selectedProdName: INCLUDES_ALL_PRODUCT_NAME,
        arrProductsInfo: [],
        arrProductName: [],
        prodCode: '',
        prodNameCode: '',
        prodLine: '',
        prodDescription: '',
        arrStagewiseProdInventoryStatus: [],
        errors:{
            others:'',
            timeRelatedErr: '',
        },
        selectedRowIndex: null,
        selectedRowProdName: '',
        selectedRowProdCode: '',
        selectedRowProdNameCode: '',
        selectedRowProdLine: '',
        selectedRowProdDescription: '',
        selectedCellState: [],
        showModalForSelectedStateProductionInventory: false,
        showProductDetailsInModal: false,
        onHoverRowIndex: null,
        onHoverColID: "",
        backdrop:'static',
        data: [],
        arrSelectedStateProductionInventoryData: [],
        timeRelatedErrors: "",
        column: [
            {   
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Name</div>,
                accessor: 'ProductName',
                filter: filterCaseInsensitive,
                width: props.invokedFrom != null && props.invokedFrom.length > 0 && props.invokedFrom == "allStatesDashboard" ? 250 : 130,
                style:({
                    textAlign: props.invokedFrom != null && props.invokedFrom.length > 0 && props.invokedFrom == "currentFieldDevices" ? "center" : "left", 
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Manufactured<br/>and<br/>Hardware <br/>Tested</div>,  
                accessor: ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED,
                width: 100,
                isVisible: false,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Functional<br/>Tested</div>,  
                accessor: ITM_STATE_FUNCTIONAL_TESTED,
                width: 85,
                disableFilters: true,
                isVisible: false,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                })
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Ready for<br/>Shipment</div>,
                accessor: ITM_STATE_READY_FOR_SHIPMENT,
                width: props.invokedFrom != null && props.invokedFrom.length > 0 && props.invokedFrom == "allStatesDashboard" ? 107 : 85,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Demo</div>,  
                accessor: ITM_STATE_DEMO,
                width: 80,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Sold</div>,  
                accessor: ITM_STATE_SOLD,
                width: 80,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Rental</div>,  
                accessor: ITM_STATE_RENTAL,
                width: 83,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => props.invokedFrom == "allStatesDashboard" ? 
                            <div className="ShowOrderTableHeader">Registered<br/>Devices</div> : 
                            <div className="ShowOrderTableHeader">Current<br/>Field<br/>Devices</div>,
                accessor: ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT,
                width: props.invokedFrom == "allStatesDashboard" ? 107 : 92,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Faulty</div>,  
                accessor: ITM_STATE_FAULTY,
                width: 82,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Under<br/>Repair</div>,  
                accessor: ITM_STATE_UNDER_REPAIR,
                width: 85,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            // {   
            //     Header:() => <div className="ShowOrderTableHeader">Mapped To<br/>Owner</div>,  
            //     accessor: ITM_STATE_MAPPED_TO_OWNER,
            //     width: 110,
            //     // show: false,
            //     Filter:({filter, onChange}) => {
            //         return(
            //             <div>
            //                 <input style={{ display:"none"}} />
            //             </div>
            //         )
            //     },
            //     getProps: (state, rowInfo, column) => {
            //         if(rowInfo != undefined && column != undefined) {
            //             return {
            //                 onMouseOver: () => onHoverGetRowIndexAndColID(rowInfo, column),                                
            //                 style: (state.onHoverColID == ITM_STATE_MAPPED_TO_OWNER && rowInfo["index"] == state.onHoverRowIndex &&
            //                         rowInfo["original"][ITM_STATE_MAPPED_TO_OWNER] != 0) ? 
            //                     {
            //                         border: "1px solid #1c1c1c",
            //                         cursor: "pointer",
            //                         background: '#6c95ba',
            //                         color: 'white',
            //                     } 
            //                     :
            //                     {}, 
            //             }; 
            //         } else {
            //             return {};
            //         }
            //     },
            //     style:({
            //         textAlign:"center",
            //         // paddingLeft: "1rem",
            //         fontSize: "0.9rem",
            //         height: "2.5rem"
            //     }),
            // },
            // {   
            //     Header:() => <div className="ShowOrderTableHeader">Mapped As<br/>Replacement</div>,  
            //     accessor: ITM_STATE_MAPPED_AS_REPLACEMENT,
            //     width: 120,
            //     // show: false,
            //     Filter:({filter, onChange}) => {
            //         return(
            //             <div>
            //                 <input style={{ display:"none"}} />
            //             </div>
            //         )
            //     },
            //     getProps: (state, rowInfo, column) => {
            //         if(rowInfo != undefined && column != undefined) {
            //             return {
            //                 onMouseOver: () => onHoverGetRowIndexAndColID(rowInfo, column),                                
            //                 style: (state.onHoverColID == ITM_STATE_MAPPED_AS_REPLACEMENT && rowInfo["index"] == state.onHoverRowIndex &&
            //                         rowInfo["original"][ITM_STATE_MAPPED_AS_REPLACEMENT] != 0) ? 
            //                     {
            //                         border: "1px solid #1c1c1c",
            //                         cursor: "pointer",
            //                         background: '#6c95ba',
            //                         color: 'white',
            //                     } 
            //                     :
            //                     {}, 
            //             }; 
            //         } else {
            //             return {};
            //         }
            //     },
            //     style:({
            //         textAlign:"center",
            //         // paddingLeft: "1rem",
            //         fontSize: "0.9rem",
            //         height: "2.5rem"
            //     }),
            // },
            {   
                Header:() => <div className="ShowOrderTableHeader">Decommissioned<br/>DeviceID</div>,  
                accessor: ITM_STATE_DECOMMISSIONED_DEVICEID,
                width: 141,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Device Taken<br/>Back For<br/>Replenishment</div>,  
                accessor: ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT,
                width: 132,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Recall<br/>Sold<br/>Device</div>,  
                accessor: ITM_STATE_RECALL_SOLD_DEVICE,
                width: 115,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Upgrade</div>,  
                accessor: ITM_STATE_DEVC_UPGRADE,
                width: props.invokedFrom != null && props.invokedFrom.length > 0 && props.invokedFrom == "allStatesDashboard" ? 150 : 130,
                disableFilters: true,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"center",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
        ],
        dashManufacturedAndHardwareTested: '',
        dashFunctionalTested: '',
        dashReadyforShipment: '',
        dashDemo: '',
        dashFaulty: '',
        dashUnderRepair: '',
        dashUnderRepair: '',
        dashDecommissionedDeviceID: '',
        dashDemoDeviceTakenBackForReplenishment: '',
        dashRecallSoldDevice: '',
        dashUpgrade: '',
        arrProductionStatesForCurrStateInventory: [ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED, ITM_STATE_FUNCTIONAL_TESTED, ITM_STATE_READY_FOR_SHIPMENT, ITM_STATE_DEMO, ITM_STATE_SOLD, ITM_STATE_RENTAL, ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT, ITM_STATE_FAULTY, ITM_STATE_UNDER_REPAIR, ITM_STATE_DECOMMISSIONED_DEVICEID, ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT, ITM_STATE_RECALL_SOLD_DEVICE, ITM_STATE_DEVC_UPGRADE],
        arrProductionStatesForDashboard: [ITM_STATE_READY_FOR_SHIPMENT, ITM_STATE_DEMO, ITM_STATE_SOLD, ITM_STATE_RENTAL, ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT, ITM_STATE_FAULTY, ITM_STATE_UNDER_REPAIR, ITM_STATE_DECOMMISSIONED_DEVICEID, ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT, ITM_STATE_RECALL_SOLD_DEVICE, ITM_STATE_DEVC_UPGRADE],
        StartDateTime: new Date(new Date().setHours(new Date().getHours() - 24)),
        EndDateTime: new Date(),
        startDate: '',
        endDate: '',
        showRefreshBtn: false,
        showClearBtn: false,
        startDateSelected: false,
        endDateSelected: false,
        startDateLength: 0,
        endDateLength: 0, 
        areDatesSelected: false,

        selectedStateProductionInventoryDataColumn: [
            {   
                Header:() => <div className="ShowOrderTableHeader">Device ID</div>,  
                accessor: 'DeviceID',
                width: 370,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Date and Time</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'StateDateAndTime',
                sortMethod: (firstRow, secondRow) => {
                    firstRow = new Date(firstRow).getTime();
                    secondRow = new Date(secondRow).getTime();
                    return secondRow > firstRow ? 1 : -1;
                },
                width: 370,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Selected Product State</div>,  
                accessor: 'SelectedProductState',
                width: 370,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },                    
        ],

        stateProductionInventoryDataColumnIfCurrentFieldDevicesSelected: [
            {   
                Header:() => <div className="ShowOrderTableHeader">Device ID</div>,  
                accessor: 'DeviceID',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {       
                Header:() => <div className="ShowOrderTableHeader">Date and Time</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'StateDateAndTime',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 200,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Selected Product State</div>,  
                accessor: 'SelectedProductState',
                width: 200,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Previous Original<br/>Device ID</div>,  
                accessor: 'previousOriginalDeviceID',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Order<br/>Type</div>,  
                accessor: 'orderType',
                width: 100,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Email Id</div>,  
                accessor: 'emailId',
                width: 250,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Phone No.</div>,  
                accessor: 'phoneNo',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Last Data <br/> Received At</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'lastDataReceivedAtDtTm',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 200,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            // It will display a Green dot if devices sent last data in interval of "ACTIVE_STATUS_MINIMUM_TIME"(Device is Active). 
            // It will display a Red dot if device did not sent last data in interval of "ACTIVE_STATUS_MINIMUM_TIME"(Device is InActive).
            {       
                Header:() => <div className="ShowOrderTableHeader">Active <br/> Status</div>,  
                accessor: 'activeStatus',
                width: 115,
                disableFilters: true,
                style:({
                    textAlign: "center",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
                disableFilters: true,
                Cell: ( rowInfo ) => {
                    return (
                        // In MySQL there is no explicit boolean variable so we are returning "true" or "false"
                        // as a string. so here we are explicitly comparing with double quoted boolean string.  
                        <FaCircle title = {rowInfo.cell.value == "true" ?  "Online" : "Offline"}
                            style = {{color: rowInfo.cell.value == "true" ? "#00ff00" : "red", 
                                fontSize:"1.1rem", border: "1px solid", borderRadius: "50%", borderColor: "darkgray"}}
                        />
                    );
                },
            },  
            {   
                Header:() => <div className="ShowOrderTableHeader">First Name</div>,  
                accessor: 'firstName',
                width: 210,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Last Name</div>,  
                accessor: 'lastName',
                width: 210,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    fontSize: "0.9rem",
                    height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },                      
        ],
        // CSV related state
        arrStates: [],
        bChngeBtnName: false,
    });

    useEffect(() =>{
        getRelevantProdInfo();
    }, []);

    const onHoverGetRowIndexAndColID = (rowInfo, column) => {
        let modifiedState = state;
        modifiedState.onHoverRowIndex = rowInfo["index"];
        modifiedState.onHoverColID = column["id"]
        setState({...modifiedState});
    }

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        if(inLogTime == null) {
            return inLogTime;
        }

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return t(IDS_TodaySearch) + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    const getRelevantProdInfo = () => {
        let modifiedState = state;
        modifiedState.arrProductName = [];
        modifiedState.arrProductsInfo = [];
        modifiedState.arrStates = [];

        axios.post(`${getAPIHostURL()}/wclient/getProductCategoryAndItsRelevantInfo`)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if(response.data["retrievedProdTypeAndItsRelvantInfo"] == null || response.data["retrievedProdTypeAndItsRelvantInfo"].length <=0) {
                    modifiedState.errors.others = 'Product information not present on Server. Please Try again later.'
                } else {
                    modifiedState.arrProductsInfo = response.data["retrievedProdTypeAndItsRelvantInfo"];

                    modifiedState.arrStatesInfo = response.data["retrievedStates"];

                    modifiedState.arrProductName = [...new Set(modifiedState.arrProductsInfo.map(singleProdInfo => singleProdInfo["ProductName"]))];

                    modifiedState.arrProductName.unshift(INCLUDES_ALL_PRODUCT_NAME);

                    for(let i=0; i<modifiedState.arrStatesInfo.length; i++) {
                        modifiedState.arrStates.push(modifiedState.arrStatesInfo[i]["State"]);
                    }
                }

                // if invokedfrom is from "currentFieldDevices"
                // then include "Mapped to Owner and Mapped as Replacement" state into arrStates.
                if (modifiedState.invokedFrom != null && modifiedState.invokedFrom.length > 0 && modifiedState.invokedFrom == "currentFieldDevices"){
                    modifiedState.arrStates.push(ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT);
                } 

                onClickProceed(modifiedState);
                
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }

        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        }); 
    }

    const onChangeProdName = (e) => {
        let modifiedState = state;
        modifiedState.selectedProdName = e.target.value;
        modifiedState.arrStagewiseProdInventoryStatus = [];
        modifiedState.data = [];
        modifiedState.selectedRowIndex = null;
        modifiedState.selectedRowProdName = "";
        modifiedState.errors.others = "";

        if(modifiedState.selectedProdName == INCLUDES_ALL_PRODUCT_NAME) {
            modifiedState.prodCode = "";
            modifiedState.prodNameCode = "";
            modifiedState.prodLine = "";
            modifiedState.prodDescription = "";

        } else {
            let filteredArrBasedOnProdName = modifiedState.arrProductsInfo.filter((singleProdInfo) => singleProdInfo["ProductName"] == modifiedState.selectedProdName);

            modifiedState.prodCode = filteredArrBasedOnProdName[0]["ProductCode"];
            modifiedState.prodNameCode = filteredArrBasedOnProdName[0]["ProductNameCode"];
            modifiedState.prodLine = filteredArrBasedOnProdName[0]["ProductLine"];
            modifiedState.prodDescription = filteredArrBasedOnProdName[0]["ProductDescription"];
        }
        setState({...modifiedState});

        getStagewiseProductionInventoryStatus(modifiedState);
    }

    const refresh = () => {
        let modifiedState = state;
        modifiedState.arrStagewiseProdInventoryStatus = [];
        modifiedState.data = [];
        modifiedState.selectedRowIndex = null;
        modifiedState.selectedRowProdName = "";
        modifiedState.errors.others = "";
        modifiedState.errors.timeRelatedErr = "";

        if(modifiedState.selectedProdName == INCLUDES_ALL_PRODUCT_NAME) {
            modifiedState.prodCode = "";
            modifiedState.prodNameCode = "";
            modifiedState.prodLine = "";
            modifiedState.prodDescription = "";

        } else {
            let filteredArrBasedOnProdName = modifiedState.arrProductsInfo.filter((singleProdInfo) => singleProdInfo["ProductName"] == modifiedState.selectedProdName);

            modifiedState.prodCode = filteredArrBasedOnProdName[0]["ProductCode"];
            modifiedState.prodNameCode = filteredArrBasedOnProdName[0]["ProductNameCode"];
            modifiedState.prodLine = filteredArrBasedOnProdName[0]["ProductLine"];
            modifiedState.prodDescription = filteredArrBasedOnProdName[0]["ProductDescription"];
        }

        modifiedState.areDatesSelected = true;
        // setState({...modifiedState});

        getStagewiseProductionInventoryStatus(modifiedState);
    }

    const onClickProceed = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.bChngeBtnName = true;

        getStagewiseProductionInventoryStatus(modifiedState);

    }

    const showProductDetails = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.showProductDetailsInModal = true;

        setState({...modifiedState});

    }

    const onClickDownload = () => {
        let modifiedState = state;

        if(modifiedState.data.length > 0) {
            DownloadCsv(modifiedState.data);
        } else {
            modifiedState.bChngeBtnName = true;
            getStagewiseProductionInventoryStatus(modifiedState, true);
        }
    }

    const DownloadCsv = (inCsvData) => {      

        let modifiedState = state;
        let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
        let strSingleCsvDataLine = "";
        let DeviceStateDetails = [];
        DeviceStateDetails = inCsvData;

        if(modifiedState.showModalForSelectedStateProductionInventory == true) {
            let strCsvHeaderLine = "Device ID";
            let arrColumnKeys = [];
            let strColumnName = "";
            let noOfLogs = inCsvData.length;

            if(state.selectedCellState == "Mapped to Owner and Mapped as Replacement") {
                arrColumnKeys = ["Date and Time", "Selected Product State", "Previous Original Device Id", "Order Type",
                    "Email Id", "Phone No.", "Last Data Received At", "Active Status", "First Name", "Last Name"]

                // Create rest of the columns in the header
                let noOfParams = arrColumnKeys.length;
                for (let j=0; j<noOfParams; j++) {
                    strColumnName = arrColumnKeys[j];
                    strCsvHeaderLine += (CSV_COLUMN_SEPERATOR + strColumnName);
                }

                // First push the header CSV line
                arrCsvAllLines.push(strCsvHeaderLine);
        
                for(let i = 0; i < noOfLogs; i++) {
        
                    let selectedDeviceStateInfo = DeviceStateDetails[i];
        
                    strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                                            ((selectedDeviceStateInfo["DeviceID"] == null) ? '' : selectedDeviceStateInfo["DeviceID"] ) +
                                            CSV_COLUMN_SEPERATOR +
                                            ( (selectedDeviceStateInfo["StateDateAndTime"] == null) ? '' : selectedDeviceStateInfo["StateDateAndTime"] ) +
                                            CSV_COLUMN_SEPERATOR +
                                            ( (selectedDeviceStateInfo["SelectedProductState"] == null ) ? '' : selectedDeviceStateInfo["SelectedProductState"]) +
                                            CSV_COLUMN_SEPERATOR +
                                            ( selectedDeviceStateInfo["previousOriginalDeviceID"] == null ? '' : selectedDeviceStateInfo["previousOriginalDeviceID"] ) +
                                            CSV_COLUMN_SEPERATOR +
                                            ((selectedDeviceStateInfo["orderType"] == null ) ? '' : selectedDeviceStateInfo["orderType"]) +
                                            CSV_COLUMN_SEPERATOR +
                                            ((selectedDeviceStateInfo["emailId"] == null ) ? '' : selectedDeviceStateInfo["emailId"]) +
                                            CSV_COLUMN_SEPERATOR +
                                            ( (selectedDeviceStateInfo["phoneNo"] == null ) ? '' : selectedDeviceStateInfo["phoneNo"]) +
                                            CSV_COLUMN_SEPERATOR +
                                            ( (selectedDeviceStateInfo["lastDataReceivedAtDtTm"] == null) ? '' : selectedDeviceStateInfo["lastDataReceivedAtDtTm"] ) +
                                            CSV_COLUMN_SEPERATOR +
                                            ( (selectedDeviceStateInfo["activeStatus"] == "true") ? "Online" : "Offline") +
                                            CSV_COLUMN_SEPERATOR +
                                            ((selectedDeviceStateInfo["firstName"] == null ) ? '' : selectedDeviceStateInfo["firstName"]) +
                                            CSV_COLUMN_SEPERATOR +
                                            ((selectedDeviceStateInfo["lastName"] == null ) ? '' : selectedDeviceStateInfo["lastName"]);

                    arrCsvAllLines.push(strSingleCsvDataLine);
                }
            } else {
                arrColumnKeys = ["Date and Time", "Selected Product State"]

                // Create rest of the columns in the header
                let noOfParams = arrColumnKeys.length;
                for (let j=0; j<noOfParams; j++) {
                    strColumnName = arrColumnKeys[j];
                    strCsvHeaderLine += (CSV_COLUMN_SEPERATOR + strColumnName);
                }

                // First push the header CSV line
                arrCsvAllLines.push(strCsvHeaderLine);
        
                for(let i = 0; i < noOfLogs; i++) {
        
                    let selectedDeviceStateInfo = DeviceStateDetails[i];
        
                    let singleDeviceStateDetailsForCsv = {
                        DeviceID: selectedDeviceStateInfo.DeviceID,
                        SelectedProductState: selectedDeviceStateInfo["SelectedProductState"],
                        StateDateAndTime: selectedDeviceStateInfo["StateDateAndTime"]
                    };
        
                    strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                                            ((selectedDeviceStateInfo["DeviceID"] == null) ? '' : selectedDeviceStateInfo["DeviceID"] ) +
                                            CSV_COLUMN_SEPERATOR +
                                            ( (selectedDeviceStateInfo["StateDateAndTime"] == null) ? '' : selectedDeviceStateInfo["StateDateAndTime"] ) +
                                            CSV_COLUMN_SEPERATOR +
                                            ( (selectedDeviceStateInfo["SelectedProductState"] == null ) ? '' : selectedDeviceStateInfo["SelectedProductState"]);

                    arrCsvAllLines.push(strSingleCsvDataLine);
                }
            }

            // Create CSV File and fill it
            let bFileCreationStatus = createAndFillCsvFile(arrCsvAllLines);  

            if(props.invokedFrom == "currentStateInventory"){
                alert(`Current State Inventory Details for product state `+ state.selectedCellState +`. Check your Browser's status bar or Downloads Folder.`);
            } else {
                alert(`All States-Dashboard Details for product state `+ state.selectedCellState +`. Check your Browser's status bar or Downloads Folder.`);
            }

        } else {
            let strCsvHeaderLine = "ProductName";

            // if invokedfrom is from "currentFieldDevices"
            // then include only single state that is  "Mapped to Owner and Mapped as Replacement" into arrStateFoCurrentFieldDevicesLines
            // and show single column in csv file that is arrStateFoCurrentFieldDevicesLines column.
            if(modifiedState.invokedFrom != null && modifiedState.invokedFrom.length > 0 && modifiedState.invokedFrom == "currentFieldDevices"){

                let arrCsvForCurrentFieldDevicesLines = [];

                let arrStateFoCurrentFieldDevicesLines = [CURRENT_FIELD_DEVICES];

                strCsvHeaderLine = strCsvHeaderLine + CSV_COLUMN_SEPERATOR + arrStateFoCurrentFieldDevicesLines;

                arrCsvForCurrentFieldDevicesLines.push(strCsvHeaderLine);

                for(let i=0; i<inCsvData.length; i++) {

                    strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                    ( (inCsvData[i]["ProductName"] == null) ? '' : inCsvData[i]["ProductName"] ) + CSV_COLUMN_SEPERATOR +
                                                                ( (inCsvData[i][ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT] == null) ? '' : 
                                                                    inCsvData[i][ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT] );

                    arrCsvForCurrentFieldDevicesLines.push(strSingleCsvDataLine);
                }

                // Create CSV File and fill it
                let bFileCreationStatus = createAndFillCsvFile(arrCsvForCurrentFieldDevicesLines);

                alert(`Current Field Devices status Data download has started. Check your Browser's status bar or Downloads Folder.`);

            } else {

                if(props.invokedFrom == "currentStateInventory"){

                    for(let i=0; i<state.arrProductionStatesForCurrStateInventory.length; i++) {
                        if(modifiedState.arrProductionStatesForCurrStateInventory[i] != ITM_STATE_MAPPED_TO_OWNER && 
                            modifiedState.arrProductionStatesForCurrStateInventory[i] != ITM_STATE_MAPPED_AS_REPLACEMENT && 
                            modifiedState.arrProductionStatesForCurrStateInventory[i] != ITM_STATE_SOLD) 
                        {
                            strCsvHeaderLine += CSV_COLUMN_SEPERATOR + modifiedState.arrProductionStatesForCurrStateInventory[i];
                        }
                    }
                } else {
                    for(let i=0; i<state.arrProductionStatesForDashboard.length; i++) {
                        if(modifiedState.arrProductionStatesForDashboard[i] != ITM_STATE_MAPPED_TO_OWNER && 
                            modifiedState.arrProductionStatesForDashboard[i] != ITM_STATE_MAPPED_AS_REPLACEMENT)
                        {
                            strCsvHeaderLine += CSV_COLUMN_SEPERATOR + modifiedState.arrProductionStatesForDashboard[i];
                        }
                    }
                }

                arrCsvAllLines.push(strCsvHeaderLine); 

                for(let i=0; i<inCsvData.length; i++) {

                    if(props.invokedFrom == "allStatesDashboard"){
                        strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                        ( (inCsvData[i]["ProductName"] == null) ? '' : inCsvData[i]["ProductName"] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_READY_FOR_SHIPMENT] == null) ? '' : inCsvData[i][ITM_STATE_READY_FOR_SHIPMENT] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_DEMO] == null) ? '' : inCsvData[i][ITM_STATE_DEMO] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_SOLD] == null) ? '' : inCsvData[i][ITM_STATE_SOLD] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_RENTAL] == null) ? '' : inCsvData[i][ITM_STATE_RENTAL] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT] == null) ? '' : inCsvData[i][ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_FAULTY] == null) ? '' : inCsvData[i][ITM_STATE_FAULTY] ) + 
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_UNDER_REPAIR] == null) ? '' : inCsvData[i][ITM_STATE_UNDER_REPAIR] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_DECOMMISSIONED_DEVICEID] == null) ? '' : inCsvData[i][ITM_STATE_DECOMMISSIONED_DEVICEID] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT] == null) ? '' : inCsvData[i][ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_RECALL_SOLD_DEVICE] == null) ? '' : inCsvData[i][ITM_STATE_RECALL_SOLD_DEVICE] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_DEVC_UPGRADE] == null) ? '' : inCsvData[i][ITM_STATE_DEVC_UPGRADE] );
        
                        arrCsvAllLines.push(strSingleCsvDataLine);
                    } else {
                        strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                        ( (inCsvData[i]["ProductName"] == null) ? '' : inCsvData[i]["ProductName"] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED] == null) ? '' : inCsvData[i][ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_FUNCTIONAL_TESTED] == null) ? '' : inCsvData[i][ITM_STATE_FUNCTIONAL_TESTED] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_READY_FOR_SHIPMENT] == null) ? '' : inCsvData[i][ITM_STATE_READY_FOR_SHIPMENT] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_DEMO] == null) ? '' : inCsvData[i][ITM_STATE_DEMO] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_RENTAL] == null) ? '' : inCsvData[i][ITM_STATE_RENTAL] ) + 
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_FAULTY] == null) ? '' : inCsvData[i][ITM_STATE_FAULTY] ) + 
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_UNDER_REPAIR] == null) ? '' : inCsvData[i][ITM_STATE_UNDER_REPAIR] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_DECOMMISSIONED_DEVICEID] == null) ? '' : inCsvData[i][ITM_STATE_DECOMMISSIONED_DEVICEID] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT] == null) ? '' : inCsvData[i][ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_RECALL_SOLD_DEVICE] == null) ? '' : inCsvData[i][ITM_STATE_RECALL_SOLD_DEVICE] ) +
                                                CSV_COLUMN_SEPERATOR +
                                                    ( (inCsvData[i][ITM_STATE_DEVC_UPGRADE] == null) ? '' : inCsvData[i][ITM_STATE_DEVC_UPGRADE] );

                    arrCsvAllLines.push(strSingleCsvDataLine);
                    }   
                }

                // Create CSV File and fill it
                let bFileCreationStatus = createAndFillCsvFile(arrCsvAllLines);  

                if(props.invokedFrom == "currentStateInventory"){
                    alert(`Current State Inventory Data download has started. Check your Browser's status bar or Downloads Folder.`);
                } else if(props.invokedFrom == "allStatesDashboard"){
                    alert(`All States Dashboard Data download has started. Check your Browser's status bar or Downloads Folder.`);
                } else {
                    return
                }
            }
        }
    }

    const createAndFillCsvFile = (inarrCsvAllLines) => {

        let modifiedState = state;

        let retVal = true;

        let fileName = "";

        let currentDate = convertLocalDateWithFormatDDMMMYY(new Date());

        let csvStartDate = convertLocalDateWithFormatDDMMMYY(modifiedState.startDate);
        let csvEndDate = convertLocalDateWithFormatDDMMMYY(modifiedState.endDate);

        if(modifiedState.showModalForSelectedStateProductionInventory == true) {

        fileName = 
                    fileName = (modifiedState.startDate != null && modifiedState.startDate.toString().length > 0 &&
                    modifiedState.endDate && modifiedState.endDate.toString().length != null && 
                    modifiedState.invokedFrom != null && modifiedState.invokedFrom.length > 0)
                    ?
                        modifiedState.invokedFrom == "currentStateInventory" ? 
                        `Current State Inventory Details for product state ` + state.selectedCellState + ` from Date `+ csvStartDate + " to " + csvEndDate + ".csv" : 
                        modifiedState.invokedFrom == "allStatesDashboard" ? 
                        `All States Dashboard Data Details for product state ` + state.selectedCellState + ` from Date `+ csvStartDate + " to " + csvEndDate + ".csv" : 
                        ""
                    :
                        modifiedState.invokedFrom == "currentStateInventory" ? 
                        `Current State Inventory Details for product state ` + state.selectedCellState + ` on Date ` + currentDate.split(" ")[0] + `.csv` : 
                        modifiedState.invokedFrom == "allStatesDashboard" ? 
                        `All States Dashboard Data Details for product state ` + state.selectedCellState + ` on Date `  + currentDate.split(" ")[0] + `.csv` 
                        : 
                        "";
        } else {

            fileName = (modifiedState.startDate != null && modifiedState.startDate.toString().length > 0 &&
                    modifiedState.endDate && modifiedState.endDate.toString().length != null && 
                    modifiedState.invokedFrom != null && modifiedState.invokedFrom.length > 0)
                    ?
                        modifiedState.invokedFrom == "currentStateInventory" ? 
                        "Current State Inventory from Date " + csvStartDate + " to " + csvEndDate + ".csv" : 
                        modifiedState.invokedFrom == "allStatesDashboard" ? 
                        "All States Dashboard Data from Date " + csvStartDate + " to " + csvEndDate + ".csv" : 
                        ""
                    :
                        modifiedState.invokedFrom == "currentStateInventory" ? 
                        "Current State Inventory on Date " + currentDate.split(" ")[0] + ".csv" : 
                        modifiedState.invokedFrom == "allStatesDashboard" ? 
                        "All States Dashboard Data on Date " + currentDate.split(" ")[0]+ ".csv" : 
                        "";
        }

        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = makeTextFile(inarrCsvAllLines);
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            let event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });

        // Return true to indicate that operation performed successfully (in future any failures in 
        // the previous calls can be checked to return false)
        retVal = true;
        return retVal;
    }

    const makeTextFile = (arrText) =>  {

        let data = new Blob(arrText, {type: 'text/plain'});

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (state.textFile !== null) {
            window.URL.revokeObjectURL(state.textFile);
        }

        state.textFile = window.URL.createObjectURL(data);

        // Return a URL you can use as an href
        return state.textFile;
    }

    const getStagewiseProductionInventoryStatus = (inModifiedState = null, isDownload = false) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let UtcStartDtTm = null;
        let UtcEndDtTm = null;

        let tempStart = modifiedState.startDate.toString();
        let tempEnd = modifiedState.endDate.toString();

        if((tempStart != null && tempStart.length > 0) && (tempEnd != null && tempEnd.length > 0)){   
            let strLoclStartDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.startDate);
            let strLoclEndDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.endDate);
    
            let strLoclStartDtTm = strLoclStartDate.split(" ")[0] + "T" + "00:00:00";
            let strLoclEndDtTm = strLoclEndDate.split(" ")[0] + "T" + "23:59:59";
      
            UtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclStartDtTm.valueOf()));
            UtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclEndDtTm.valueOf()));
        }

        let jsonBody = {
            ProductName: modifiedState.selectedProdName == INCLUDES_ALL_PRODUCT_NAME ? '' : modifiedState.selectedProdName,
            startDate: UtcStartDtTm,
            endDate: UtcEndDtTm,
            invokedFrom: props.invokedFrom
        }

        modifiedState.data = [];
        modifiedState.arrStagewiseProdInventoryStatus = [];

        axios.post(`${getAPIHostURL()}/wclient/getStagewiseProductionInventoryStatus`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if((response.data["retreivedStagewiseProductionInventoryStat"] == null || response.data["retreivedStagewiseProductionInventoryStat"].length <=0) && 
                        modifiedState.invokedFrom == "currentStateInventory" && modifiedState.areDatesSelected == true) {
                    modifiedState.errors.others = `Current State Inventory status information is not present for the selected time period.`
                }else if((response.data["retreivedStagewiseProductionInventoryStat"] == null || response.data["retreivedStagewiseProductionInventoryStat"].length <=0) && 
                        modifiedState.invokedFrom == "currentStateInventory") {
                    modifiedState.errors.others = `Current State Inventory status information is not present.`
                } else if((response.data["retreivedStagewiseProductionInventoryStat"] == null || response.data["retreivedStagewiseProductionInventoryStat"].length <= 0) && 
                        modifiedState.invokedFrom == "currentFieldDevices"){
                    modifiedState.errors.others = `Current Field Devices information is not present.`
                } else if((response.data["retreivedStagewiseProductionInventoryStat"] == null || response.data["retreivedStagewiseProductionInventoryStat"].length <= 0) && 
                        modifiedState.invokedFrom == "allStatesDashboard" && modifiedState.areDatesSelected == true){
                    modifiedState.errors.others = `All States-Dashboard information is not present for the selected time period.`
                } else if((response.data["retreivedStagewiseProductionInventoryStat"] == null || response.data["retreivedStagewiseProductionInventoryStat"].length <= 0) && 
                        modifiedState.invokedFrom == "allStatesDashboard"){
                    modifiedState.errors.others = `All States-Dashboard information is not present.`
                } else {
                    modifiedState.errors.others = "";
                    modifiedState.arrStagewiseProdInventoryStatus = response.data["retreivedStagewiseProductionInventoryStat"];

                    let sortedArrBasedOnItsProdName = [];
                     // As in these case we dont want to show 'selected' column hence we are hiding it.
                     for(let i=0; i<modifiedState.column.length; i++) {
                        if(modifiedState.column[i]["accessor"] == "") {
                            modifiedState.column[i]["show"] = true;
                            break;
                        }
                    }
                    if(modifiedState.selectedProdName == INCLUDES_ALL_PRODUCT_NAME) {

                        for(let i=0; i<modifiedState.arrProductsInfo.length; i++) {
                            let singleProdInfo = modifiedState.arrProductsInfo[i];
                                
                            let objSingleProdInfo = {
                                ProductCode: singleProdInfo["ProductCode"],
                                ProductNameCode: singleProdInfo["ProductNameCode"],
                                ProductName: singleProdInfo["ProductName"],
                                ProductLine: singleProdInfo["ProductLine"],
                                ProductDescription: singleProdInfo["ProductDescription"],
                            }
                            let StateObj = {};
                            let countForCurrentFieldDevices = 0;

                            for(let j=0; j<modifiedState.arrStatesInfo.length; j++) {
                                let StateID = modifiedState.arrStatesInfo[j]["StateID"];
                                let State = modifiedState.arrStatesInfo[j]["State"];

                                let fileteredArrBasedOnStateID = modifiedState.arrStagewiseProdInventoryStatus.filter((singleRow) => singleRow["ProductCode"] == singleProdInfo["ProductCode"] && singleRow["StateID"] == StateID);

                                // if the state is "Mapped to Owner" or "Mapped as Replacement" then sum both the columns count 
                                if(State != null && State.length > 0 && (State == ITM_STATE_MAPPED_TO_OWNER || State == ITM_STATE_MAPPED_AS_REPLACEMENT)){
                                    if(fileteredArrBasedOnStateID.length > 0){
                                        countForCurrentFieldDevices = Number(countForCurrentFieldDevices) + Number(fileteredArrBasedOnStateID[0]["count"])
                                    }    
                                } else {
                                    //do nothing
                                }

                                let lenOfAboveStatID = 0;
                                if(fileteredArrBasedOnStateID.length > 0) {
                                    lenOfAboveStatID = fileteredArrBasedOnStateID[0]["count"];
                                } else {
                                    lenOfAboveStatID = 0;
                                }
                                let singleStateObj = {
                                    [State]: lenOfAboveStatID
                                }

                                // if the state is "Mapped to Owner" or "Mapped as Replacement" then sum both the columns count
                                // then put the sum of both the columns count into one column that is in "Mapped to Owner and Mapped as Replacement" column
                                let SingleStateObjForCurrentFieldDevices = {
                                    [ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT] : countForCurrentFieldDevices
                                }

                                StateObj = {...StateObj, ...singleStateObj, ...SingleStateObjForCurrentFieldDevices};
                                
                            }
                            
                            let objIncludsBothStateAndProdInfo = {...objSingleProdInfo, ...StateObj}

                            sortedArrBasedOnItsProdName.push(objIncludsBothStateAndProdInfo);
                        }
                    } else {
                        let singleProdInfo = modifiedState.arrProductsInfo.filter((singleProdInfo) => singleProdInfo["ProductName"] == modifiedState.selectedProdName);

                        singleProdInfo = singleProdInfo[0];
                            
                        let objSingleProdInfo = {
                            ProductCode: singleProdInfo["ProductCode"],
                            ProductNameCode: singleProdInfo["ProductNameCode"],
                            ProductName: singleProdInfo["ProductName"],
                            ProductLine: singleProdInfo["ProductLine"],
                            ProductDescription: singleProdInfo["ProductDescription"],
                        }
                        let StateObj = {}; 
                        let countForCurrentFieldDevices = 0;

                        for(let j=0; j<modifiedState.arrStatesInfo.length; j++) {
                            let StateID = modifiedState.arrStatesInfo[j]["StateID"];
                            let State = modifiedState.arrStatesInfo[j]["State"];
                           
                            let fileteredArrBasedOnStateID = modifiedState.arrStagewiseProdInventoryStatus.filter((singleRow) => singleRow["ProductCode"] == singleProdInfo["ProductCode"] && singleRow["StateID"] == StateID);
                                
                            // if the state is "Mapped to Owner" or "Mapped as Replacement" then sum both the columns count 
                            if(State != null && State.length > 0 && (State == ITM_STATE_MAPPED_TO_OWNER || State == ITM_STATE_MAPPED_AS_REPLACEMENT)){
                                if(fileteredArrBasedOnStateID.length > 0){
                                    countForCurrentFieldDevices = Number(countForCurrentFieldDevices) + Number(fileteredArrBasedOnStateID[0]["count"])
                                }    
                            } else {
                                //do nothing
                            }
                    
                            let lenOfAboveStatID = 0;
                            if(fileteredArrBasedOnStateID.length > 0) {
                                lenOfAboveStatID = fileteredArrBasedOnStateID[0]["count"];
                            } else {
                                lenOfAboveStatID = 0;
                            }                    
                            
                            let singleStateObj = {
                                [State]: lenOfAboveStatID
                            }

                            // if the state is "Mapped to Owner" or "Mapped as Replacement" then sum both the columns count
                            // then put the sum of both the columns count into one column that is in "Mapped to Owner and Mapped as Replacement" column
                            let SingleStateObjForCurrentFieldDevices = {
                                [ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT] : countForCurrentFieldDevices
                            }

                            StateObj = {...StateObj, ...singleStateObj, ...SingleStateObjForCurrentFieldDevices};
                        
                        }

                        let objIncludsBothStateAndProdInfo = {...objSingleProdInfo, ...StateObj}

                        sortedArrBasedOnItsProdName.push(objIncludsBothStateAndProdInfo);
                    }
                    modifiedState.data = sortedArrBasedOnItsProdName;

                    // As we have to make to show 'Download'.
                    for(let i=0; i<modifiedState.column.length; i++) {
                        if(modifiedState.column[i]["accessor"] == "Download") {
                            modifiedState.column[i]["show"] = true;
                            break;
                        }
                    }

                    if(isDownload) {
                        DownloadCsv(modifiedState.data);
                    }
                }
                
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        });
    }

    const toggleShowSelectedStateProductionInventory = () => {
        let modifiedState = state;
        modifiedState.showModalForSelectedStateProductionInventory = !modifiedState.showModalForSelectedStateProductionInventory;
        modifiedState.selectedCellState = [];
        setState({...modifiedState});
    }

    // For showing UI states having new look.
    const showNewUIShowSelectedStateProductionInventory = (singleState, singleProductCode, selectedRowProdNameCode, selectedRowProdLine, selectedRowProdDescription) => {
        let modifiedState = state;

        modifiedState.selectedCellState = singleState == 'Mapped to Owner and Mapped as Replacement' ? [ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT] : [singleState];

        modifiedState.selectedRowProdCode = singleProductCode;
        modifiedState.selectedRowProdNameCode = selectedRowProdNameCode;
        modifiedState.selectedRowProdLine = selectedRowProdLine;
        modifiedState.selectedRowProdDescription = selectedRowProdDescription;

        getDetailsBasedOnSelectedItemState(modifiedState);
    }

    const toggleShowSelectedProductDetails = () => {
        let modifiedState = state;

        modifiedState.showProductDetailsInModal = !modifiedState.showProductDetailsInModal;
        modifiedState.selectedCellState = '';
        setState({...modifiedState});

    }

    const getDetailsBasedOnSelectedItemState = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.arrSelectedStateProductionInventoryData = [];

        if( modifiedState.selectedCellState == null || modifiedState.selectedCellState.length <= 0 ||
            modifiedState.selectedRowProdCode == null || modifiedState.selectedRowProdCode.length <= 0) {
            setState({...modifiedState});
            return
        } 

        let tempStart = modifiedState.startDate.toString();
        let tempEnd = modifiedState.endDate.toString();

        let strLoclStartDtTm = null;
        let strLoclEndDtTm = null;

        if((tempStart != null && tempStart.length > 0) && (tempEnd != null && tempEnd.length > 0)){ 

            let strLoclStartDate = convertLocalDateWithFormatDDMMMYY(modifiedState.startDate);
            let strLoclEndDate = convertLocalDateWithFormatDDMMMYY(modifiedState.endDate);

            // Adding Time '00:00:00' to '23:59:59' with local Date String to make a selected date to exact 24hrs local time window.
            strLoclStartDtTm = strLoclStartDate + " 00:00:00";
            strLoclEndDtTm = strLoclEndDate + " 23:59:59";

        }

        let localTimeZone = (new Date()).getTimezoneOffset();

        let sendInvokeFromInJson = "";

        if(modifiedState.invokedFrom == "allStatesDashboard" && modifiedState.selectedCellState == ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT){
            sendInvokeFromInJson = "allStatesDashboard";
        } else {
            sendInvokeFromInJson = modifiedState.invokedFrom;
        }
        
        let jsonBody = {
            SelectedCellState: modifiedState.selectedCellState,
            InvokedFrom: sendInvokeFromInJson,
            SelectedRowProdCode: modifiedState.selectedRowProdCode,
            localTimeZone: getLocalTimezoneOffset(localTimeZone),
            ActiveStatusMinimumTime:  modifiedState.selectedCellState != null && modifiedState.selectedCellState.length > 0 &&  
                                      modifiedState.selectedCellState == ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT && 
                                      (modifiedState.invokedFrom == "currentStateInventory" || modifiedState.invokedFrom == "allStatesDashboard") ?
                                      ACTIVE_STATUS_MINIMUM_TIME : "", // time in seconds - Minimum gap in log time 
                                                                       // after which device is considered inactive
            SearchLocalStartDtTm: strLoclStartDtTm,
            SearchLocalEndDtTm: strLoclEndDtTm,
        };

        axios.post(`${getAPIHostURL()}/wclient/getDetailsBasedOnSelectedItemState`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {
                if(response.data.retrieveDetailsBasedOnSelectedItemState == null || response.data.retrieveDetailsBasedOnSelectedItemState.length <= 0) {
                    // retrieveDetailsBasedOnSelectedItemState Data not found on server.
                } else {
                    let stateArrDataBasedOnSingleDate = [];

                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing product line Details data.
                    stateArrDataBasedOnSingleDate = [...modifiedState.arrSelectedStateProductionInventoryData]
                                            
                    const retrieveDetailsBasedOnSelectedItemState = response.data.retrieveDetailsBasedOnSelectedItemState;

                    for(let i = 0; i < retrieveDetailsBasedOnSelectedItemState.length; i++) {
                        const SingleDateProdStatusDetails = retrieveDetailsBasedOnSelectedItemState[i];

                        let StateLocaleDateTime = SingleDateProdStatusDetails.StateDtTm == null ? "-" : SingleDateProdStatusDetails.StateDtTm;

                        // if invoked from is from "currentFieldDevices" and selectedCellState = "Mapped to Owner and Mapped as Replacement" 
                        // then show DeviceID, StateDateAndTime, SelectedProductState, emailId, phoneNo, firstName, lastName
                       
                        // if(modifiedState.selectedCellState != null && modifiedState.selectedCellState.length > 0 && 
                        //     (modifiedState.selectedCellState == ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT ||
                        //      modifiedState.selectedCellState == ITM_STATE_DEMO ||
                        //      modifiedState.selectedCellState == ITM_STATE_SOLD ||
                        //      modifiedState.selectedCellState == ITM_STATE_RENTAL) && 
                        //     (modifiedState.invokedFrom == "allStatesDashboard" || 
                        //      modifiedState.invokedFrom == "currentStateInventory" || 
                        //      modifiedState.invokedFrom == "currentFieldDevices")
                        //  ) {

                        if(modifiedState.selectedCellState != null && modifiedState.selectedCellState.length > 0 && 
                            (modifiedState.selectedCellState == ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT) && 
                            (modifiedState.invokedFrom == "allStatesDashboard" || 
                             modifiedState.invokedFrom == "currentStateInventory" || 
                             modifiedState.invokedFrom == "currentFieldDevices")
                         ) {
                            let LastDataReceivedAtDtTm = SingleDateProdStatusDetails["lastDataReceivedAt"] == null ? "-" : 
                                                         convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(SingleDateProdStatusDetails["lastDataReceivedAt"]);

                            let singleDateProdStatusData = {
                                DeviceID: SingleDateProdStatusDetails.ItemID == null || SingleDateProdStatusDetails.ItemID.length <= 0  ? "-" : SingleDateProdStatusDetails.ItemID,
                                StateDateAndTime: StateLocaleDateTime,
                                // SelectedProductState: SingleDateProdStatusDetails.State == null || SingleDateProdStatusDetails.State.length <= 0 ? "-" : 
                                //                       (modifiedState.selectedCellState == ITM_STATE_DEMO ? ITM_STATE_DEMO : 
                                //                        modifiedState.selectedCellState == ITM_STATE_SOLD ? ITM_STATE_SOLD : 
                                //                        modifiedState.selectedCellState == ITM_STATE_RENTAL ? ITM_STATE_RENTAL : SingleDateProdStatusDetails.State),
                                SelectedProductState: SingleDateProdStatusDetails.State == null || SingleDateProdStatusDetails.State.length <= 0 ? "-" : SingleDateProdStatusDetails.State,
                                previousOriginalDeviceID : SingleDateProdStatusDetails.PreviousOriginalID != null ? SingleDateProdStatusDetails.PreviousOriginalID.replace(/["]+/g, '') : "-",
                                orderType: SingleDateProdStatusDetails.OrderType != null ? SingleDateProdStatusDetails.OrderType : '-',
                                emailId: SingleDateProdStatusDetails.DeviceOwnerEmailID == null ||  SingleDateProdStatusDetails.DeviceOwnerEmailID.length <= 0 ? "-" : SingleDateProdStatusDetails.DeviceOwnerEmailID,
                                phoneNo: SingleDateProdStatusDetails.PhoneNo == null ||  SingleDateProdStatusDetails.PhoneNo.length <= 0 ? "-" : SingleDateProdStatusDetails.PhoneNo,
                                activeStatus: SingleDateProdStatusDetails.DeviceActiveStatus == null ||  SingleDateProdStatusDetails.DeviceActiveStatus.length <= 0 ? "-" : SingleDateProdStatusDetails.DeviceActiveStatus,
                                lastDataReceivedAtDtTm: LastDataReceivedAtDtTm,
                                firstName: SingleDateProdStatusDetails.FirstName == null ||  SingleDateProdStatusDetails.FirstName.length <= 0 ? "-" : SingleDateProdStatusDetails.FirstName,
                                lastName: SingleDateProdStatusDetails.LastName == null ||  SingleDateProdStatusDetails.LastName.length <= 0 ? "-" : SingleDateProdStatusDetails.LastName,
                            };
                            stateArrDataBasedOnSingleDate.push(singleDateProdStatusData);
                        } else {
                            let singleDateProdStatusData = {
                                DeviceID: SingleDateProdStatusDetails.ItemID == null || SingleDateProdStatusDetails.ItemID.length <= 0  ? "-" : SingleDateProdStatusDetails.ItemID,
                                StateDateAndTime: StateLocaleDateTime,
                                SelectedProductState: modifiedState.selectedCellState == null || modifiedState.selectedCellState.length <= 0 ? "-" : modifiedState.selectedCellState,
                            };
                            stateArrDataBasedOnSingleDate.push(singleDateProdStatusData);
                        }
                    } 
                    
                    modifiedState.arrSelectedStateProductionInventoryData = stateArrDataBasedOnSingleDate;
                  
                    modifiedState.errors.others = '';
                    modifiedState.showModalForSelectedStateProductionInventory = !modifiedState.showModalForSelectedStateProductionInventory;
                }
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (Params not received).\n Try again later.';
        
                } else if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        })
    }

    const onCellClick = (cellInfo) => {
        let modifiedState = state;
    
        modifiedState.selectedRowIndex = cellInfo.row.index;
        modifiedState.selectedRowProdName = cellInfo.row["original"]["ProductName"];
        modifiedState.selectedRowProdCode = cellInfo.row["original"]["ProductCode"];
        modifiedState.selectedRowProdNameCode = cellInfo.row["original"]["ProductNameCode"];
        modifiedState.selectedRowProdLine = cellInfo.row["original"]["ProductLine"];
        modifiedState.selectedRowProdDescription = cellInfo.row["original"]["ProductDescription"];
        let selectedCellCoumnHeading = cellInfo.column["id"]

        if(selectedCellCoumnHeading == "Mapped to Owner and Mapped as Replacement"){
            modifiedState.selectedCellState = "Mapped to Owner and Mapped as Replacement";
        } else {
            // This is used to prevent Click on any cell other than Product Item States and cell having value 0.  
            for(let i = 0; i < modifiedState.arrStates.length; i++) {
                if(modifiedState.arrStates[i] == selectedCellCoumnHeading && cellInfo.row["original"][selectedCellCoumnHeading] != 0) {
                    modifiedState.selectedCellState = modifiedState.arrStates[i]
                    break;
                } else {
                    modifiedState.selectedCellState = "";
                } 
            }
        }
        getDetailsBasedOnSelectedItemState(modifiedState);
    }

    // Show Dashboard
    const showDashBoard = () => {
        
        let modifiedState = state;

        return(
            <div style={{display: "flex", justifyContent: "center"}}>
                <div className = "row" style={{alignItems: "center", marginTop: "0.5rem", padding: "0.5rem", width: "59%", borderStyle: "solid", borderColor: "#b6c8de"}}>
                    {props.invokedFrom == "currentStateInventory"
                    ?   modifiedState.arrProductionStatesForCurrStateInventory.map( (singleState) => {
                            return(
                                renderSingleStateDetails(singleState)
                            )                                 
                        })
                    :
                        modifiedState.arrProductionStatesForDashboard.map( (singleState) => {
                            return(
                                renderSingleStateDetails(singleState)
                            )                                 
                        })
                    }
                </div>
            </div>
        );
    }

    const renderSingleStateDetails = (singleState) => {
        let modifiedState = state;

        let modifyClassName;
        let singleStateValue = 0;
        let singleProductCode = "";
        let selectedRowProdNameCode = "";
        let selectedRowProdLine = "";
        let selectedRowProdDescription = "";
        
        modifyClassName = "col-xs-12 col-sm-4 col-md-4";

        if(modifiedState.data != null && modifiedState.data.length > 0){

            singleStateValue = modifiedState.data[0][singleState];
            singleProductCode = modifiedState.data[0]["ProductCode"];
            selectedRowProdNameCode = modifiedState.data[0]["ProductNameCode"];
            selectedRowProdLine = modifiedState.data[0]["ProductLine"];
            selectedRowProdDescription = modifiedState.data[0]["ProductDescription"];
        }

        let styleValueBox = singleStateValue > 0 ? "dashboardBoxForState" : "dashboardBoxForStateZeroValue";
        let finalStyleValueBox = `col-xs-12 col-sm-12 ${styleValueBox}`;

        return(
            <div key={singleState} 
                 onClick={() => singleStateValue > 0 ? showNewUIShowSelectedStateProductionInventory(singleState, singleProductCode, selectedRowProdNameCode, selectedRowProdLine, selectedRowProdDescription) : null}
                 style={{display:"flex", justifyContent:"center", alignItems:"center", margin:"0.7rem 0rem", cursor: singleStateValue > 0 ? "pointer" : "arrow"}} 
                 className={'"' + modifyClassName + '"'}> 
                <div className = {finalStyleValueBox} style={{padding: "0.3rem"}}>
                    <div className = "row" style={{display:"flex"}}>
                        <div style={{fontSize: "14px", fontWeight: "bold", height: "55px"}}>  
                            {/* {singleState == "Mapped to Owner and Mapped as Replacement" ? "Current Field Devices" : singleState} */}
                            {(props.invokedFrom == "currentStateInventory" && singleState == "Mapped to Owner and Mapped as Replacement") ? "Current Field Devices" : 
                             (props.invokedFrom == "allStatesDashboard" && singleState == "Mapped to Owner and Mapped as Replacement") ? "Registered Devices" : singleState}
                        </div>
                    </div>
                    <div className = "row" style={{display:"flex"}}>
                        <div style={{fontSize: "18px", fontWeight: "bold"}}>
                            {singleStateValue}
                        </div>
                    </div>
                </div>
            </div>   
        );
    }

    const onChangeEndDateTime = (updatedTime) => {
        
        let modifiedState = state; 

        let updatedEndDateTime = updatedTime;

        modifiedState.EndDateTime = updatedEndDateTime;

        setState({...modifiedState});
    }

    const onChangeStartDateTime = (updatedTime) => {

        let modifiedState = state;
        let updatedActiveStartDateTime = updatedTime;

        modifiedState.StartDateTime = updatedActiveStartDateTime;
        setState({...modifiedState});
    }

    const onEndDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  
        let strMsg ="";
        let modifiedState = state;

        let currentlySetEndDateTime = modifiedState.EndDateTime;

        let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
        minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

        let currentlySetStartDateTime = modifiedState.StartDateTime;

        let minRequiredDateRange = new Date(currentlySetStartDateTime.valueOf());
        minRequiredDateRange.setDate(minRequiredDateRange.getDate() + 31);

        let currentDateTime = new Date();
        if(currentlySetEndDateTime > currentDateTime) {
            strMsg = t(IDS_RDEndDtGrtCurDt);

            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = currentDateTime
        } else if(currentlySetEndDateTime <= currentlySetStartDateTime) {

            let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
            let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );

            if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {
                strMsg = t(IDS_RDEndDTAdjust);
                
                modifiedState.timeRelatedErrors = strMsg;
                modifiedState.EndDateTime = new Date(currentlySetStartDateTime.valueOf());
                modifiedState.EndDateTime.setHours(23);
                modifiedState.EndDateTime.setMinutes(59);
                modifiedState.EndDateTime.setSeconds(59);
            } else {
                strMsg = t(IDS_RDStartDTAdjust);
                
                modifiedState.timeRelatedErrors = strMsg;

                modifiedState.StartDateTime = minRequiredStartDateTime
            }
            
            if(modifiedState.EndDateTime > currentDateTime) {
                modifiedState.timeRelatedErrors = strMsg;

                modifiedState.EndDateTime = currentDateTime
            }

        } else if(currentlySetEndDateTime > minRequiredDateRange) {

            strMsg = t(IDS_RDStartDTAdjustNotMaxRng);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.StartDateTime = minRequiredStartDateTime;

        } else {
            strMsg = "";
            modifiedState.timeRelatedErrors = strMsg;
        }

        setState({...modifiedState});

    }

    const onStartDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;    
        let strMsg = '';

        let modifiedState = state;

        let currentlySetEndDateTime = modifiedState.EndDateTime;

        // Incase time is greater than currentdatetime.
        let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
        minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

        
        let currentlySetStartDateTime = modifiedState.StartDateTime;

        // Incase of startDateTime greater than EndDateTime. Set EndDateTime According to StartDateTime.
        let minRequiredEndDateTime = new Date(currentlySetStartDateTime.valueOf());
        minRequiredEndDateTime.setHours(minRequiredEndDateTime.getHours() + 24);

        // required to compare Range between Start and End Date Time.
        let minRequiredDateRange = new Date(currentlySetEndDateTime.valueOf());
        minRequiredDateRange.setDate(minRequiredDateRange.getDate() - 31);

        let currentDateTime = new Date();

        // Give a tolerance of -1 minutes to the current date time to avoid unnecessary messages
        // currentDateTime.setMinutes(currentDateTime.getMinutes() - 1)                

        let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
        let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );
        
        if(currentlySetStartDateTime != null && currentlySetStartDateTime >= currentDateTime) {

            strMsg = t(IDS_RDStartDTSetAcrodEndDT);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.StartDateTime = minRequiredStartDateTime;

        } else if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {

            strMsg = t(IDS_RDStartDTAdjustMinPossible);
            
            modifiedState.timeRelatedErrors = strMsg;

            modifiedState.StartDateTime = new Date(currentlySetEndDateTime.valueOf());
            modifiedState.StartDateTime.setHours(0);
            modifiedState.StartDateTime.setMinutes(0);
            modifiedState.StartDateTime.setSeconds(0);

        } else if(currentlySetStartDateTime >= currentlySetEndDateTime) {

            strMsg = t(IDS_RDEndDTNotInRng);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = minRequiredEndDateTime;

            if(modifiedState.EndDateTime >= currentDateTime) {
                modifiedState.EndDateTime = new Date();
            }

        } else if(currentlySetStartDateTime < minRequiredDateRange) {

            strMsg = t(IDS_RDEndDTSetAcrodStartDT);
            
            modifiedState.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = minRequiredEndDateTime;

        } else {
            strMsg = "";
            modifiedState.timeRelatedErrors = strMsg;
        }

        setState({...modifiedState});

    }

    const onChangeStartDate = (updatedStartDt) => {
        let modifiedState = state;
        let currentDate = new Date();

        if(updatedStartDt > currentDate) {
            modifiedState.errors.timeRelatedErr = "Start Date can not be greater than Current Date. It will be set to Current Date.";
            modifiedState.startDate = currentDate;
            modifiedState.endDate = currentDate;

            if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0 && updatedStartDt > modifiedState.endDate) {
                modifiedState.errors.timeRelatedErr = "End Date can not be less than Start Date. It will be set same as to Start Date.";
                modifiedState.startDate = updatedStartDt;
                modifiedState.endDate = updatedStartDt;
            }
        } else if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0 && updatedStartDt > modifiedState.endDate) {
            modifiedState.errors.timeRelatedErr = "End Date can not be less than Start Date. It will be set same as to Start Date.";
            modifiedState.startDate = updatedStartDt;
            modifiedState.endDate = updatedStartDt;
        } else {
            modifiedState.errors.timeRelatedErr = "";
            modifiedState.startDate = updatedStartDt;
        }
        modifiedState.custComlaintTableData = [];
        modifiedState.selectedRowIndex = null;

        modifiedState.startDateLength = modifiedState.startDate.toString().length;
        modifiedState.endDateLength = modifiedState.endDate.toString().length;

        if(modifiedState.startDateLength != null && modifiedState.startDateLength > 0){
            modifiedState.startDateSelected = true;
        }

        if(modifiedState.startDateLength > 0 && modifiedState.endDateLength > 0){
            modifiedState.showRefreshBtn = true;
        }

        if(modifiedState.startDateLength > 0 || modifiedState.endDateLength > 0){
            modifiedState.showClearBtn = true;
        }

        setState({...modifiedState});
    }

    const onChangeEndDate = (updatedEndDt) => {
        let modifiedState = state;
        let currentDate = new Date();

        if(updatedEndDt > currentDate) {
            modifiedState.errors.timeRelatedErr = "End Date can not be greater than Current Date. It will be set to Current Date.";
            modifiedState.endDate = currentDate;

        } else if(updatedEndDt < modifiedState.startDate) {
            modifiedState.errors.timeRelatedErr = "Start Date can not be greater than End Date. It will be set as same to End Date.";
            modifiedState.startDate = updatedEndDt;
            modifiedState.endDate = updatedEndDt;

        } else {
            modifiedState.errors.timeRelatedErr = "";
            modifiedState.endDate = updatedEndDt;
        }

        modifiedState.startDateLength = modifiedState.startDate.toString().length;
        modifiedState.endDateLength = modifiedState.endDate.toString().length;

        if(modifiedState.endDateLength != null && modifiedState.endDateLength > 0){
            modifiedState.endDateSelected = true;
        }

        if(modifiedState.startDateLength > 0 && modifiedState.endDateLength > 0){
            modifiedState.showRefreshBtn = true;
        }

        if(modifiedState.startDateLength > 0 || modifiedState.endDateLength > 0){
            modifiedState.showClearBtn = true;
        }

        modifiedState.custComlaintTableData = [];
        modifiedState.selectedRowIndex = null;

        setState({...modifiedState});
    }

    const clearDates = () => {

        let modifiedState = state;

        if(modifiedState.startDate != null && modifiedState.startDate.toString().length > 0){
            modifiedState.startDate = '';
        }
        
        if(modifiedState.endDate != null && modifiedState.endDate.toString().length > 0){
            modifiedState.endDate = '';
        }

        modifiedState.showRefreshBtn = false;
        modifiedState.showClearBtn = false;
        modifiedState.startDateSelected = false;
        modifiedState.endDateSelected = false;
        modifiedState.errors.others = "";
        modifiedState.errors.timeRelatedErr = "";

        getStagewiseProductionInventoryStatus(modifiedState);
    }


    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    let objPvgProductionSupervisor = (props.objPrivilege != null &&
        props.objPrivilege.hasOwnProperty(PVG_PRODUCTION_SUPERVISOR) &&
        props.objPrivilege[PVG_PRODUCTION_SUPERVISOR] == true
    );

    let objPvgProductionWorker = (props.objPrivilege != null &&
        props.objPrivilege.hasOwnProperty(PVG_PRODUCTION_WORKER) &&
        props.objPrivilege[PVG_PRODUCTION_WORKER] == true
    );

    const downloadDetailsForProductState = () => {

        DownloadCsv(state.arrSelectedStateProductionInventoryData);
    }

    return (
        <div>
            <div className="row justify-content-center">
                <div className="container col-lg-12,col-md-12,col-sm-12">
                    <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1.2rem"}}>
                        {(state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == "currentFieldDevices") ?
                            "Report - Current Field Devices" :
                            (state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == "allStatesDashboard") ?
                            "All States-Dashboard":
                            "Current State Inventory"
                        }
                    </div>
                        
                    {state.invokedFrom != "currentFieldDevices" 
                    ?
                        <div className="divForShowingDashBoard">
                            <div className="startAndEnddate">
                                <div>
                                    <label style={{fontSize:"0.9rem", marginRight:"-1.2rem"}}>
                                        Start Date:
                                    </label>
                                    <DateTimePicker
                                        clearIcon={null}
                                        onChange={onChangeStartDate}
                                        format={"yyyy/MM/dd"} 
                                        value={state.startDate} 
                                        name="startDateTime"
                                        timeFormat={false}
                                        className="dashBoardDateTimeZIndex"
                                    />                                
                                </div>
                                <div className='divForEndDate'> 
                                    <label style={{fontSize:"0.9rem", marginRight:"-1.2rem"}}>
                                        &nbsp;End Date:
                                    </label>
                                    <DateTimePicker
                                        clearIcon={null}
                                        onChange={onChangeEndDate}
                                        format={"yyyy/MM/dd"} 
                                        value={state.endDate} 
                                        name="endDateTime"
                                        timeFormat={false}
                                        className="dashBoardDateTimeZIndex"
                                    />           
                                </div>

                                <div className='divToShowRefreshAndClearBtnDashBoard'
                                        style={{display: "flex", justifyContent: "center", flexDirection: "row"}}>
                                    <div style={{marginLeft: "2rem"}} hidden = {state.showRefreshBtn == true ? false : true}> 

                                        <button type="button"
                                                title= "Refresh Data as per the selected date."
                                            onClick = {() => refresh()}
                                            style={{borderRadius: "5px", 
                                                    color:"white",
                                                    marginLeft:"5%", 
                                                    fontWeight:"500",

                                                }}
                                            className="iconButton"
                                        >
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div style={{marginLeft: state.showRefreshBtn == true ? "0.6rem" : "2rem"}} 
                                        hidden = {state.showClearBtn == true ? false : true}
                                    >
                                        <button type="button"
                                                title= {state.startDateSelected == true ? "Clear Selected Dates." : 
                                                        state.endDateSelected == true ? "Clear End Date." : "Clear"}
                                                onClick={ () => clearDates()} 
                                                style={{borderRadius: "5px",
                                                        color:"white", 
                                                        fontWeight:"900",
                                                        width: "110%"

                                                }}
                                                className="iconButton"
                                        > 
                                            X 
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div style={{width: "50%"}}>
                                    Select Product Name:
                                </div>
                                <div style={{marginLeft:"1%"}}>
                                    <select className="selectProdName"
                                        required
                                        value={state.selectedProdName}
                                        onChange={onChangeProdName}
                                        cursor= "pointer"
                                    >
                                        <option value="" select= "true" disabled>Select Product Name...</option> 
                                        {(state.arrProductName).map((singleProdName, index) => <option key={index} value={singleProdName}>{singleProdName}</option>)}

                                    </select>
                                </div>
                                <div style={{display:"flex"}}>
                                    <button type="button"
                                            title="Get Selected Product's Info."
                                            onClick = {() => showProductDetails()}
                                            style={{borderRadius: "5px", 
                                                    color:"white",
                                                    marginLeft:"5%", 
                                                    fontWeight:"700",                                                        
                                                    paddingLeft: "0.1rem",
                                                    paddingRight: "0.6rem",
                                                    display: state.selectedProdName == INCLUDES_ALL_PRODUCT_NAME ? "none" : "block"
                                                }}
                                            className="iconButton"
                                    >
                                        <i className="fa fa-info" aria-hidden="true"></i>
                                    </button>
                                    <button type="button"
                                            name = "download"
                                            title= 'Download Data in CSV file format.'
                                            onClick = {onClickDownload}
                                            style={{
                                                    borderRadius: "5px", 
                                                    color:"white",
                                                    marginLeft:"0.2rem", 
                                                    fontWeight:"700",
                                                    paddingLeft: "0rem",
                                                    paddingRight: "0.5rem"
                                                }}
                                            className="iconButton"
                                    >
                                        <i className="fa fa-download" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    :
                        <div>
                            <div className="dropDownOfModelInfo">
                                <div className="selectedModelLable"
                                >
                                    Select Product Name:
                                </div>
                                <div style={{marginLeft:"1%"}}>
                                    <select className="ProdInfoInput"
                                            style={{width:"100%", background:"white", height:"2rem"}} 
                                            required
                                            value={state.selectedProdName}
                                            onChange={onChangeProdName}
                                    >
                                        <option value="" select= "true" disabled>Select ProductName...</option> 
                                        {(state.arrProductName).map((singleProdName, index) => <option key={index} value={singleProdName}>{singleProdName}</option>)}

                                    </select>
                                </div>
                            </div>

                            <div>
                                <div style={{display:"flex"}}>
                                    <button type="button"
                                            onClick = {() => onClickProceed()}
                                            style={{borderRadius: "5px", width:"30%", marginTop:"1rem",
                                                    backgroundColor: "var(--primaryColor)", color:"white",
                                                    marginLeft:"15%", 
                                                    fontWeight:"700",
                                                }}
                                            className="btn-sm"
                                    >
                                        {state.bChngeBtnName? "Refresh" : "View"}
                                    </button>
                                    <button type="button"
                                            name = "download"
                                            onClick = {onClickDownload}
                                            style={{
                                                    borderRadius: "5px", width:"35%", marginTop:"1rem",
                                                    backgroundColor: "var(--primaryColor)", color:"white",
                                                    marginLeft:"12%", fontWeight:"700",
                                                }}
                                            className="btn-sm"
                                    >
                                        Download Summary
                                    </button>
                                </div>
                                {state.errors.others.length > 0 && 
                                    <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center", marginTop: "1rem"}} className='error'>{state.errors.others}</p> 
                                }
                            </div>
                        </div>
                    }
                        
                    <div>
                        {state.errors.others.length > 0 && 
                            <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center", marginTop: "1rem"}} className='error'>{state.errors.others}</p> 
                        }
                    </div>

                    <div>
                        {state.errors.timeRelatedErr.length > 0 && 
                            <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center", marginTop: "1rem"}} className='error'>{state.errors.timeRelatedErr}</p> 
                        }
                    </div>

                    {(state.selectedProdName == INCLUDES_ALL_PRODUCT_NAME || state.invokedFrom == "currentFieldDevices")?
                        // <div style={{marginTop:"0.5rem", border: "1px solid black", textAlign:"center"}}>
                        <div className="border border-3 ReactTableStyle" style = {{marginTop:"0.5rem"}}>
                            <ReactTable 
                                columns={state.column} 
                                data={state.data} 
                                getCellProps={(cellInfo) => {
                                    if (cellInfo.row != undefined && cellInfo.column != undefined) {
                                        return {
                                            onClick: cellInfo.column.id != "ProductName"
                                                    ? () => {onCellClick(cellInfo)}
                                                    : () => {},

                                            onMouseOver: cellInfo.column.id != "ProductName"
                                                    ? () => {
                                                        onHoverGetRowIndexAndColID(cellInfo.row, cellInfo.column);
                                                    }
                                                    : () => {},       
                                            style: (
                                                        (cellInfo.column.id === ITM_STATE_READY_FOR_SHIPMENT && state.onHoverColID === ITM_STATE_READY_FOR_SHIPMENT && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_READY_FOR_SHIPMENT] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_FUNCTIONAL_TESTED && state.onHoverColID === ITM_STATE_FUNCTIONAL_TESTED && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_FUNCTIONAL_TESTED] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_DEMO && state.onHoverColID === ITM_STATE_DEMO && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_DEMO] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_SOLD && state.onHoverColID === ITM_STATE_SOLD && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_SOLD] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_RENTAL && state.onHoverColID === ITM_STATE_RENTAL && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_RENTAL] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT && state.onHoverColID === ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED && state.onHoverColID === ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_FAULTY && state.onHoverColID === ITM_STATE_FAULTY && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_FAULTY] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_UNDER_REPAIR && state.onHoverColID === ITM_STATE_UNDER_REPAIR && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_UNDER_REPAIR] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_DECOMMISSIONED_DEVICEID && state.onHoverColID === ITM_STATE_DECOMMISSIONED_DEVICEID && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_DECOMMISSIONED_DEVICEID] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT && state.onHoverColID === ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_RECALL_SOLD_DEVICE && state.onHoverColID === ITM_STATE_RECALL_SOLD_DEVICE && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_RECALL_SOLD_DEVICE] !== 0) ||
                                                        (cellInfo.column.id === ITM_STATE_DEVC_UPGRADE && state.onHoverColID === ITM_STATE_DEVC_UPGRADE && cellInfo.row.index === state.onHoverRowIndex && cellInfo.row.original[ITM_STATE_DEVC_UPGRADE] !== 0)
                                                    ) ? 
                                                    {
                                                        border: "1px solid #1c1c1c",
                                                        cursor: "pointer",
                                                        background: '#6c95ba',
                                                        color: 'white',
                                                    } 
                                                    :
                                                    {}, 
                                        }
                                    } else {
                                        return {}
                                    }}
                                }
                                // as React Table is outside the main function, state varaibles cant be accessed there 
                                // so passing all the state variables on which react table is dependent through passedStateVariable
                                passedStateVariable = {[state.selectedProdName, state.arrSelectedStateProductionInventoryData,
                                    props.invokedFrom, state.loading]}
                                renderBasedOnTable = {"allStatesTable"}
                            />
                        </div>
                    :
                        null
                    }

                    <div style={{display: (state.selectedProdName == INCLUDES_ALL_PRODUCT_NAME || 
                                            state.invokedFrom == "currentFieldDevices") ? "none" : "block"}}>
                        {showDashBoard()}
                    </div>

                    <Modal size="xl" isOpen={state.showModalForSelectedStateProductionInventory} toggle={toggleShowSelectedStateProductionInventory} backdrop={state.backdrop}>
                        <ModalHeader toggle={toggleShowSelectedStateProductionInventory}> 
                            {state.invokedFrom == "currentStateInventory" 
                            ? `Current State Inventory Details for product state '${state.selectedCellState}'.` 
                            : `All States-Dashboard Details for product state '${state.selectedCellState}'.`}
                        </ModalHeader>
                        <ModalBody>
                            <div>     
                                <div className="ProdInfo" style={{marginTop:"0rem"}}>
                                        <div style={{flex:"3"}}/>
                                        <button type="button"
                                            onClick = {downloadDetailsForProductState}
                                            style={{borderRadius: "5px", 
                                                    backgroundColor: "var(--primaryColor)", color:"white",
                                                    fontWeight:"700",
                                                }}
                                            className="btn-sm"
                                        >
                                            Download
                                        </button>
                                </div>                                   
                                <div className="ProdInfoDashboard">
                                    <div className="subProdInfoFirstDashboard">
                                        <label className="ProdInfolabel" >Product Code:</label>
                                        <input value={state.selectedRowProdCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                    </div>

                                    <div className="selectedProdInfoSecond">
                                        <label className="ProdInfolabel">Product Name Code:</label>
                                        <input value={state.selectedRowProdNameCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                    </div>
                                </div>
                                <div className="ProdInfoDashboardBottom">
                                    <div className="subProdInfoFirstDashboard">
                                        <label className="ProdInfolabel">Product Line:</label>
                                        <input value={state.selectedRowProdLine} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>
                                    </div>
                                    <div className="selectedProdInfoSecond">
                                        <div className="ProdInfolabelDiscription">Product Description:</div>
                                        <textarea value={state.selectedRowProdDescription} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>  
                                    </div>
                                </div>
                            </div>

                            {state.arrSelectedStateProductionInventoryData.length > 0 ?
                                // <div style={{marginTop:"1rem", border: "1px solid black", textAlign:"center"}}>
                                <div className="border border-3 ReactTableStyle">
                                    <ReactTable data={state.arrSelectedStateProductionInventoryData} 
                                        columns={(props.invokedFrom == "allStatesDashboard" && 
                                            (state.selectedCellState == ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT)) ||
                                            (props.invokedFrom == "currentStateInventory" && 
                                            state.selectedCellState == ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT) ? 
                                            state.stateProductionInventoryDataColumnIfCurrentFieldDevicesSelected : 
                                            state.selectedStateProductionInventoryDataColumn}
                                        getCellProps = {(cellInfo) => ({})}
                                        // as React Table is outside the main function, state varaibles cant be accessed there 
                                        // so passing all the state variables on which react table is dependent through passedStateVariable
                                        passedStateVariable = {[state.selectedProdName, state.arrSelectedStateProductionInventoryData,
                                            props.invokedFrom, state.loading]}
                                        renderBasedOnTable = {"oneStateTable"}
                                    />
                                </div>
                            :
                                null
                            }

                        </ModalBody>
                    </Modal>

                    <Modal size="xl" isOpen={state.showProductDetailsInModal} toggle={toggleShowSelectedProductDetails} backdrop={state.backdrop}>
                        <ModalHeader toggle={toggleShowSelectedProductDetails}> 
                            Product Details
                        </ModalHeader>
                        <ModalBody>
                            <div>                                      
                                <div className="ProdInfoDashboard">
                                    <div className="subProdInfoFirstDashboard">
                                        <label className="ProdInfolabel" >Product Code:</label>
                                        <input value={state.prodCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                    </div>
                                    <div className="selectedProdInfoSecond">
                                        <label className="ProdInfolabel">Product Name Code:</label>
                                        <input value={state.prodNameCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                    </div>
                                </div>
                                <div className="ProdInfoDashboardBottom">
                                    <div className="subProdInfoFirstDashboard">
                                        <label className="ProdInfolabel">Product Line:</label>
                                        <input value={state.prodLine} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                    </div>
                                    <div className="selectedProdInfoSecond">
                                        <div className="ProdInfolabelDiscription">Product Description:</div>
                                        <textarea value={state.prodDescription} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>  
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default VcAllStateDashboard