import React, { useState, useContext, useEffect } from 'react'
import { FaSearch } from 'react-icons/fa'
import { AppRelevantDataContext } from '../../../AppContext';
import "../../CSS/GlobalSearchBox.css";
import { useDispatch, useSelector } from 'react-redux';
import { TbDeviceDesktop } from 'react-icons/tb';
import { FaInbox } from "react-icons/fa6";
import { appendGlobalSearchNode } from '../Store/Slices/deviceList';
import { addToOpenToiletNodes, setSearchedOrClickedToiletNode } from '../Store/Slices/treeAndSearchSlice';
import { IDS_DeviceID, IDS_DeviceName, IDS_Premises, IDS_NoMatchFound } from '../../../VcLanguage';
import { MdCancel } from "react-icons/md";

const VcSearchBox = () => {

  const deviceListRedux = useSelector((state) => state?.deviceList?.devices)
  const treeNodeListRedux = useSelector((state) => state?.treeNodeList?.nodeList)

  const dispatch = useDispatch();
  
  const context = useContext(AppRelevantDataContext)
  const t = context.t;

  const [ state, setState ] = useState({
    devicesRedux: [],
    filteredList: [],
    showDropdown: false,
    treeNodesRedux: [],
    filteredToiletList: [],
    inputValue: ''
  })

  useEffect(() => {
    // Update state when deviceListRedux changes
    if (deviceListRedux != null && deviceListRedux.length > 0) {
      setState(prevState => ({
        ...prevState,
        devicesRedux: deviceListRedux[0]
      }));
    }
  
    // Update state when treeNodeListRedux changes, only if it exists
    if (treeNodeListRedux != null && treeNodeListRedux.length > 0) {
      setState(prevState => ({
        ...prevState,
        treeNodesRedux: treeNodeListRedux[0]
      }));
    }
  
    // Add or remove event listener for dropdown based on showDropdown state
    if (state.showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  
    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deviceListRedux, treeNodeListRedux, state.showDropdown]);
  


  // Called when user cliks outside the dropdown <div/> block (Search Block)
  const handleClickOutside = (event) => {
    const viewportWidth = window.innerWidth;
    let dropdown = null;
    let searchInput = null;

    if(viewportWidth <= 550) {
      dropdown = document.querySelector('.mobileViewdropdown');
      searchInput = document.querySelector('.mobileViewSearchBoxInput');
    } else {
      dropdown = document.querySelector('.desktopViewDropdown');
      searchInput = document.querySelector('.searchBoxInput');
    }

    if (dropdown && !dropdown.contains(event.target) && !searchInput.contains(event.target)) {
      setState(prevState => ({
        ...prevState,
        showDropdown: false
      }));
    }
  };

  // On-Click Function for Input Text
  const selectedDeviceName = (e) => {
    const searchedInput = e.target.value.toLowerCase();

    const deviceIDs = [];
    state.devicesRedux?.map((device) => {
      const lowerCaseDeviceID = device.DeviceID.toLowerCase()
      const lowerCaseDeviceName = device.DeviceName.toLowerCase()

      if(lowerCaseDeviceID.includes(searchedInput) || lowerCaseDeviceName.includes(searchedInput)) {
        deviceIDs.push(device);
      }
    })

    const toiletNodeIDs = []
    state.treeNodesRedux?.map((toilet) => {
      const lowerCaseToiletID = toilet.id.toLowerCase();
      const lowerCaseToiletTitle = toilet.title.toLowerCase();

      if(lowerCaseToiletID.includes(searchedInput) || lowerCaseToiletTitle.includes(searchedInput)) {
        toiletNodeIDs.push(toilet)
      }
    })
    
    setState(prevState => ({
      ...prevState,
      filteredList: searchedInput.length <= 0 ? [] : deviceIDs,
      filteredToiletList: searchedInput.length <= 0 ? [] : toiletNodeIDs,
      inputValue: searchedInput
    }));
  }

  // To Render the list of Filtered Device ID(s) / Device Name(s) / Toilet-Node Name(s) in the Serach Bar at Every Matched-Input by the User
  const renderSelectedDeviceDashboard = (e) => {
    const deviceID = e.target.value;
    const matchedDevice = state.filteredList.find((devc) => devc == deviceID)
    if(matchedDevice) {
      context.onSelectedDevice(matchedDevice, matchedDevice, false, false, true, context.selectedNodeInfo.deviceType, context.selectedNodeInfo.parentID, context.selectedNodeInfo.SelectedNodeDeviceType, context.selectedNodeInfo.nodePath)      
    }
  }

  // Called when Clicked Device from the Search Dropdown
  const handleDeviceClick = (option) => {
    dispatch(setSearchedOrClickedToiletNode(''))
    dispatch(addToOpenToiletNodes(option.TreeParentID))
    dispatch(appendGlobalSearchNode(true))

    context.onSelectedDevice(option.DeviceID, option.DeviceName, false, false, true, context.selectedNodeInfo.deviceType, option.TreeParentID, option.SelectedNodeDeviceType, context.selectedNodeInfo.nodePath)      
    setState(prevState => ({
      ...prevState,
      showDropdown: false
    }))
  };

  // Called when Clicked Toilet from the Search Dropdown
  const handleNodeClick = (toiletNode) => {
    dispatch(setSearchedOrClickedToiletNode(toiletNode.id));
    dispatch(addToOpenToiletNodes(toiletNode.id));
    dispatch(appendGlobalSearchNode(true))
    
    context.onSelectedDevice(toiletNode.id, toiletNode.title, false, true, false, context.selectedNodeInfo.deviceType, context.selectedNodeInfo.parentID, context.selectedNodeInfo.SelectedNodeDeviceType, toiletNode.path)
    context.onSelectedNodeContainsChildNode(true)
    setState(prevState => ({
      ...prevState,
      showDropdown: false
    }))
  }

  // To Empty the Input Search by User
  const handleClearInput = () => {
    setState(prevState => ({
      ...prevState,
      showDropdown: false,
      inputValue: '',
      filteredList: [],
      filteredToiletList: []
    }));
  };

  let deviceWidth = window.innerWidth;
  
  return (
    <div 
      className='w-100 rounded bg-white rounded flex items-center ps-1 my-1 ms-1'
      style={{ position: "relative" }}
    >
        <FaSearch className='animateScale'/>
        <input 
          onFocus={() => setState(prevState => ({
            ...prevState,
            showDropdown: true
          }))}
          placeholder='Search' 
          className={ deviceWidth <= 570 ? 'mobileViewSearchBoxInput' : 'searchBoxInput' }
          list="filteredList" 
          name="filteredList" 
          onInput={selectedDeviceName} 
          onChange={renderSelectedDeviceDashboard} 
          value={state.inputValue}
        />

        {/* Clear (x): Empty Search Button */}
        {state.inputValue.length > 0 && 
          <span style={{ cursor: "pointer", marginRight: "2rem" }} onClick={handleClearInput}>
            <MdCancel />
          </span>
        }

        {state.showDropdown && state.inputValue.length > 0 && (
          <div className={ deviceWidth <= 570 ? 'mobileViewdropdown' : 'desktopViewDropdown' } style={{ position: "absolute" }}>
            {
              ((state.filteredToiletList != null && state.filteredToiletList.length > 0) || 
              (state.filteredList != null && state.filteredList.length > 0)) ? (
                <>
                  {/* RENDERING DEVICES  */}
                  <div className='dropdown-title mx-1 px-2 pt-2 pb-1'>
                    <span style={{ textTransform: "uppercase" }}><b>{t(IDS_DeviceName)}</b></span>
                    <span style={{ textTransform: "uppercase" }}><b>{t(IDS_DeviceID)}</b></span>
                  </div>
                  <div style={{ overflowY: "scroll", maxHeight: "40vh" }}>
                    {state.filteredList?.map((filteredDevice, index) => (
                      <div
                        key={index}
                        className='dropdown-option'
                        id="filteredList"
                        onMouseDown={() => handleDeviceClick(filteredDevice)} // Use onMouseDown to prevent input blur
                      >
                        <span><FaInbox className='deviceIcon' /> {filteredDevice.DeviceName}</span>
                        <span>{filteredDevice.DeviceID}</span>
                      </div>
                    ))}
                  </div>

                  {/* RENDERING TOILETS  */}
                  <div className='dropdown-title mx-1 px-2 pt-2 pb-1'>
                    <span style={{ textTransform: "uppercase" }}>
                      <b>{t(IDS_Premises)}</b>
                    </span>
                  </div>
                  <div style={{ overflowY: "scroll", maxHeight: "40vh" }}>
                    {state.filteredToiletList?.map((node) => {
                      return (
                        <div
                          className='dropdown-option'
                          id="filteredList"
                          onMouseDown={() => handleNodeClick(node)} // Use onMouseDown to prevent input blur
                        >
                          <span className='d-flex justify-content-center align-items-center'>
                            <TbDeviceDesktop className='toiletNodeIcon' /> {node.title}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </>
              ) : (
                <span style={{ fontSize: "11px", color: "#b2b2b2" }} className='mx-1 px-2 pt-2 pb-1'><b>{t(IDS_NoMatchFound)}</b></span>
              )
            }
          </div>
        )}
    </div>
  )
}

export default VcSearchBox