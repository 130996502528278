import React, { useContext, useEffect } from 'react';
import { AppRelevantDataContext } from '../../AppContext';
import App from '../../App';
import '@studysync/react-splitter-layout/lib/index.css';
import VcSingleDeviceData from './VcSingleDeviceData';
import {useNavigate} from 'react-router-dom';
import { IDS_DefInfoMsgForTreeNodeInDevcPg, IDS_InfoMsgForTreeNodeWhileChildDevicesMissingInDevcPg, IDS_InfoMsgForTreeNodeWhileChildNodesMissingInDevcPg} from '../../VcLanguage';
import VcToilet from './VcToilet';

function VcDevice (props) { 

  const context = useContext(AppRelevantDataContext);
  const t = context.t;
  const navigate = useNavigate();

  useEffect(() => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.
      // Try to get this value if present in LocalStorage and update the app state (so that
      // re-render will happen). If app context data not present in LocalStorage then
      // show the Login Page
      if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
        // App Relevant Data Context not present in LocalStorage as well.
        navigate('/', { replace: true });
      } else {
        // App Relevant Data Context loaded from LocalStorage at AppLeval.
        // Re-render will happen automatically (no action required).
      }
    }

  }, []);

  let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext

  if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
    // User login information is not present in appRelevantDataContext.

    // Next re-render will happen automatically when the AppState is updated again 
    // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
    return App.renderLoadingPage();
  }

  let isRootSelected = appRelevantDataContextValue.selectedNodeInfo.isRoot;
  let deviceTreeSettingsEnabled = appRelevantDataContextValue.DevicesTreeStructureSettings.treeStructureSettingsEnabled;
  let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
  let HasSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.hasDevc;
  let IsSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.isDevc;
  let isContainsChildNode = appRelevantDataContextValue.selectedNodeInfo.containsChildNode;
  let selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle
  
  let fullPath = props?.fullPath || "";
  if (HasSelectedNodeDevices && !IsSelectedNodeDevices && isContainsChildNode) {
    //Is a tree node then do not want last subPath
    let allSubPaths = fullPath?.split('>')
    allSubPaths = allSubPaths?.slice( 0, -2)
    let updatedPath = ''
    allSubPaths?.forEach(subPath => updatedPath += (subPath + ' > ') )
    fullPath = updatedPath;
  } else {
    fullPath = props.fullPath;
  }
  return (
    <div style={{width:'100%'}}>                       
      { HasSelectedNodeDevices && !IsSelectedNodeDevices && isContainsChildNode ?
          <VcToilet fullPath={fullPath} /> :
        !isRootSelected && !HasSelectedNodeDevices && IsSelectedNodeDevices ?       
        <VcSingleDeviceData fullPath={fullPath}/> :
        null
      }
      </div>   
  )
}

export default VcDevice;
