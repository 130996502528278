import React, { useState, useEffect, useContext } from 'react';
import logoviliso from '../IMAGES/vilisoLogo.png';
import '../CSS/Logintest.css';
import axios from 'axios';
import App from '../../App'; 
import { getAPIHostURL } from '../../ClientConfig';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_Password, IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_RegistPasswordLengthError, IDS_ResetPassword,
         IDS_SUBMIT, IDS_FrgtPswdUpdateSuccess, IDS_ConfirmPassword, IDS_EmailID, IDS_RegistPasswordError, IDS_BackToFrgtPg, IDS_ShowPassword, IDS_AUSrvrIssueReqParamsNotSent } from '../../VcLanguage';
import {useNavigate, useLocation} from 'react-router-dom';

function VcResetPassword () {
  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    maskEmailID: '',
    userEmailID: '',
    forgotPassKey: '',
    password: "",
    confirmPassword: "",
    hideForm: true, // Initially hide the form till we check that the link has not expired. 
    passwordShown: false,
    errors: { 
      password: '',
      confirmPassword:'',
      others:''
    },
    arrOfAllSessionIDs: '',
  });

  useEffect(() => {
    let appRelevantDataContextValue = context;

    // To get Info from LS which have persisted even after logout 
    // (for eg. Language and Last Loggedin UserID).
    appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage(true);

    getForgotPasskeyOnComponentMount(); 
  },[]);

  const getForgotPasskeyOnComponentMount = (e) => {

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let modifiedState = state;
    modifiedState.forgotPassKey = '';

    // getting unique key as forgotPassKey from url of reset passowrd page.
    modifiedState.forgotPassKey = location.pathname.split('/').pop()
    

    const recivedForgotPassKeyData = {
      ForgotPassKey: modifiedState.forgotPassKey,
    }

    modifiedState.maskEmailID = '';
    modifiedState.userEmailID = '';

    axios.post( `${getAPIHostURL()}/wclient/getUserIdOfForgotPasskey`, recivedForgotPassKeyData )
    .then(response => {
      if(response.data.code == 'SUCCESS') {

        const resData = response.data;
        modifiedState.maskEmailID = resData.maskUserID;
        modifiedState.userEmailID = resData.UserID;
        modifiedState.hideForm = false; // Now show the form as the key has not expired yet

        setState({...modifiedState});
  
      } else {

        if(response.data.code == 'REQ_PARAMS_MISSING') {
          modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);

        } else if ( response.data.code == 'SQL_ERROR' ) {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);

          modifiedState.hideForm = false;

        } else if( response.data.code == 'INVALID_FORGOTPASSKEY' ) {

          modifiedState.hideForm = true;
          alert(t(IDS_BackToFrgtPg));
          navigate('/')

        } else {
          console.log('Should not reach here');
          modifiedState.errors.others = t(IDS_LoginServerIssue);
          modifiedState.hideForm = false;

        }
        // Display the specific error message for Reset Password failure
        setState({...modifiedState});
      }
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError);
      setState({...modifiedState});
    }); 
  }
  

  const onResetPasswordSubmit = (e) => {

    e.preventDefault();

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let modifiedState = state;

    if(modifiedState.password !== modifiedState.confirmPassword) {

      modifiedState.errors.confirmPassword = t(IDS_RegistPasswordError);
      setState({...modifiedState})
    } else {

      const loginData = {
        Password: modifiedState.password,
        ForgotPassKey: modifiedState.forgotPassKey
      }
  
      axios.post( `${getAPIHostURL()}/wclient/resetUserPassword`, loginData )
      .then(response => {
        if(response.data.code == "SUCCESS" ) {

          // Here, On ChangePassword, End date the current session of Logged in user.
          let jsonParams = {
            LoggedInUserID: modifiedState.userEmailID,
          }

          axios.post(`${getAPIHostURL()}/wclient/bringAllSessIDsOfLoggedInUser`, jsonParams)
          .then(response =>{
      
              if(response.data.code == 'SUCCESS') {

                if(response.data.allSessionIDsOfLoggedInUser == null || response.data.allSessionIDsOfLoggedInUser.length <= 0 )
                {
                    console.log("Should not reach here.");
                } else {
                    let allSessionIDsOfLoggedInUser = response.data.allSessionIDsOfLoggedInUser;
                    let arrayOfSessionIDs = [];

                    for(let i=0; i<allSessionIDsOfLoggedInUser.length; i++){
                      arrayOfSessionIDs.push(allSessionIDsOfLoggedInUser[i]["SessionID"]);
                    }

                    modifiedState.arrOfAllSessionIDs = arrayOfSessionIDs.join(",");

                }
                
                onLogOut(modifiedState);

              } else {
                // this.props.history.replace('/');
                if(response.data.code == 'SQL_ERROR') {
                    // modifiedState.errors = 'Server experiencing issues.\n Try again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log("Should not reach here");
                    // modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                setState({...modifiedState});

            }
          })
          .catch(err => {
              console.log("Network error");
              console.log(err);
              if (axios.isCancel(err)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
              } else {
                // modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                console.log(modifiedState.errors.others);
                setState({...modifiedState});
              }
          }) 

          alert(t(IDS_FrgtPswdUpdateSuccess));
          navigate('/');

          modifiedState.password = '';
          modifiedState.confirmPassword = '';
          modifiedState.errors.password = '';
          modifiedState.errors.confirmPassword = '';

          setState({...modifiedState});

        } else if (response.data.code == 'SQL_ERROR') {

          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else if( response.data.code == 'INVALID_FORGOTPASSKEY' ) {

          alert( t(IDS_BackToFrgtPg) );
          navigate('/');

        } else {

          console.log('Should not reach here');
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
        // Display the specific error message for Reset Password failure
        setState({...modifiedState});
  
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        setState({...modifiedState});
      }); 
    }
  }

  const onLogOut = (inModifiedState = null) => {

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = this.state;
    } else {
      modifiedState = inModifiedState;
    }

    let LoggedInUserID = modifiedState.userEmailID;
    let fcmRegID = null;

    saveDeviceRegIdIntoDB(LoggedInUserID, fcmRegID, "null", 'ResetPassword', null, modifiedState).then((response) => {

      let responseCode = response[0];
      let updatedState = response[1];

      if(responseCode == "SUCCESS") {

        endDateSesJankari(updatedState);          

      } else {
        setState({...updatedState});

      }

    })
    .catch((error) => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError)
      setState({...modifiedState});

    });
      // Here, On Log out End date the current session of Logged in user.
  }

  const endDateSesJankari = (inModifiedState = null) => {

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = this.state;
    } else {
      modifiedState = inModifiedState;
    }

    let LoggedInUserID = modifiedState.userEmailID;
    let sessID = modifiedState.arrOfAllSessionIDs;

    let jsonParams = {
      LoggedInUserID: LoggedInUserID,
      // strChalan: strChalan,
      sessID: sessID
    }
    
    axios.post(`${getAPIHostURL()}/wclient/endDateSesJankari`, jsonParams)
    .then(response => {

        if(response.data.code == 'SUCCESS') {

          // User wants to Log Out
          // Clear the AppRelevantDataContext that has been stored in the LocalStorage,
          // (Except UserID and Language Selection and a few other default values).
          App.clearAppRelevantDataContextFromLocalStorage();
          
          setState({...modifiedState});

        } else {
          // this.props.history.replace('/');
          if(response.data.code == 'SQL_ERROR') {
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
              console.log("Should not reach here");
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
          setState({...modifiedState});

      }
    })
    .catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
          console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
        } else {
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          setState({...modifiedState});
        }
    })

  }

  // To send notifications, we need FCM (Firebase Cloud Messaging) registration Id of particular user
  // Depending on the registration id, the notifications will be sent to the users whereever they are having active sessions.
  // The function below will save the FCM registration id of user into the database.
  // We call this function in following cases: Login, Logout, Language change and Token Refresh
  const saveDeviceRegIdIntoDB = async (userEmailId, currToken, newToken, action, sessionID, inModifiedState = null) => {

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = this.state;
    } else {
      modifiedState = inModifiedState;
    }

    let sessID = "";

    sessID = sessionID != null ? sessionID : null;
    let languageToViewIn = "en";

    var requestObject = {
      "UserID": userEmailId,
      "CurrentToken": currToken,
      "NewToken": newToken,
      "UserClientLanguage": languageToViewIn,
      "UserClientMode": "WEB",
      "Action": action,
      "SessionID": sessID
    };

    return await axios.post( `${getAPIHostURL()}/wclient/UpdateDeviceNotificationRegID`, requestObject )
    .then(response => {

      if(response.data.code == 'SUCCESS') {
        // console.log("Successfully Updated Notification ID");
      } else {
        if(response.data.code == 'REQ_PARAMS_MISSING'){
          modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);

        } else if (response.data.code == 'SQL_ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          console.log("Should not reach here.")
          modifiedState.errors.others = t(IDS_LoginServerIssue);

        }
      }
      return [response.data.code, modifiedState];
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError)
      setState({...modifiedState});
      return;
    }); 
  }
    
  const handleChange = (event) => {
    event.preventDefault();
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    const { name, value } = event.target;
    let errors = state.errors;
  
    switch (name) {
      case 'password': 
        errors.password = 
          value.length < 8
            ? t(IDS_RegistPasswordLengthError)
            : '';
        break;
      default:
        break;
    }
  
    setState({
      ...state,
      errors, 
      [name]: value,
      [event.target.name]:event.target.value,
      
      })
  }

  const onTogglePassword = () => {
    let modifiedState = state;
    modifiedState.passwordShown = !modifiedState.passwordShown;  
    setState({...modifiedState});
  }
	
  const {errors} = state;

  let appRelevantDataContextValue = context;
  let t = appRelevantDataContextValue.t;

  const color = {
    color : "var(--errorColor)",
    fontSize:"13px"
  }

  return (
    <div className="container" style={{display: (state.hideForm) ? "none" : "block"}}>
      <div className="row">
        <div className="container col-lg-4 col-lg-offset-4 centered
                                  col-md-6 col-md-offset-3">
          <div className="modal-body p-4 login" >
              <div className="form-group logform">					
              <img src={logoviliso}/>
              </div>
              <div style={{color:"var(--secondaryColor)", fontSize:"1.3rem", fontWeight:"700"}}>{t(IDS_ResetPassword)}</div>
              <form onSubmit={onResetPasswordSubmit} >
                <div className="form-group logform">
                  <div className="inputgroupCustom">
                    <label className="log-form-label" style={{fontSize:"1rem"}}>{t(IDS_EmailID)}:</label>
                    <input type='Email' name='Email' className=" login-input" style={{fontweight: "bold", color:"grey"}} required="required"   
                                      value={state.maskEmailID} disabled />

                  </div>
                </div>
                <div className="form-group logform">
                  <div className="inputgroupCustom">
                    <label className="log-form-label" style={{fontSize:"1rem"}}>{t(IDS_Password)}:</label>
                    <input autoComplete="off" type={state.passwordShown == true ? "text" : "password"} name='password' className=" login-input" required="required" 
                                      value={state.password}
                                      onChange={handleChange} noValidate />
                                      {errors.password.length > 0 && 
                                    <p style={color} className='error'>{errors.password}</p>}
                  </div>
                </div>
                <div className="form-group logform" style = {{marginBottom:"0rem"}}>
                  <div className="inputgroupCustom">      
                    <label className="log-form-label" style={{fontSize:"1rem"}}>{t(IDS_ConfirmPassword)}:</label>
                    <input autoComplete="off" type={state.passwordShown == true ? "text" : "password"} name='confirmPassword' className=" login-input" required="required" 
                                      value={state.confirmPassword}
                                      onChange={handleChange} noValidate />
                      {errors.confirmPassword.length > 0 && 
                    <p style={color} className='error'>{errors.confirmPassword}</p>}
                  </div> 
                </div>
                <div style = {{marginTop:"0rem", textAlign: "start"}}>
                  <input type='checkbox' 
                                className="userProfileFormLabel" 
                                onClick= {onTogglePassword}
                                value = {state.passwordShown} 
                          />        
                  <label style={{display: "inline", marginLeft: "0.5rem" }} className="reg-form-label">{t(IDS_ShowPassword)}</label>
                </div>
                <div className="logform">
                  <button type="submit" className="btn-lg logbtn" >{t(IDS_SUBMIT)}</button>
                  <div className= "buttonErrorMessage">
                      {errors.others.length > 0 && 
                        <p style={color} className='error'>{errors.others}</p>}
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  )
  
}

export default VcResetPassword;