import React, { Component } from 'react'
import "../CSS/Removeuser.css"
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import { convertUTCDateToStrYYYYMMDDHH24MMSS } from '../../vtUtil';
import { AppRelevantDataContext } from '../../AppContext';
import { getAPIHostURL } from '../../ClientConfig';
import { IDS_AUUserEmail, IDS_AUEndView, IDS_RmvUsrAccess, IDS_Close, IDS_Remove, IDS_RmvUsrDeviceNotAdd,
        IDS_RmvUsrEnsureDevice, IDS_RegistNetworkError, IDS_LoginServerIssue, IDS_RmvUsrServerErrorForUsrID,
        IDS_AUSrvrIssueReqParamsNotSent, IDS_RmvUsrServerIssue, IDS_DeviceRmvAccess, IDS_DeviceNotVisible,
        IDS_RmvUsrEndDtLessCurDt, IDS_NoDevcForUsr, IDS_DeviceRmvSuccesForUsr, IDS_DeviceRmvSuccessfully,
        IDS_RemoveUserEmailFirst
} from '../../VcLanguage';
        
const RemoveUserFormCheckbox = props => {
  
    return (
      <li key={props.id} data-name={props.name} className="AllDevice"> 
        <input key={props.id} onClick={props.handleCheckedIndividualDevice} className="checkbox-distance" 
            type="checkbox" defaultChecked={props.isChecked} value={props.value} name={props.id}
            /> 
        {props.value}
      </li>
    )
}

export class RemoveUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            notifyOnCloseForm: this.props.onCloseRemoveUserPopup,
            DeviceOwnerEmailID: this.props.LoggedInUserID,
            SelectedDeviceName: this.props.DeviceName,
            SelectedDeviceID: this.props.DeviceID,
            SelectedNodeParentID: this.props.ParentIDOfSelectedNode,
            isCheckedAllowRemoveAll: false,
            RemoveUserEmailID: '',
            EndViewTime: new Date(),
            errors:{
                email: '',
                others: '',
                enterEmailIDFirst: "",
            },      
            deviceData: [
                            // This will be filled when the user clicks on 'Remove View All' based on the information 
                            // that has been filled for 'UserEmailID' etc.
                            // EXAMPLE: {key: 'DeviceID', id: 'DeviceID', value: "Device1", isChecked: true},
            ],
            AdditionalUserEmail: []
        }
      }

      componentDidMount() {

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext

        this.getAdditionalUserInfo(appRelevantDataContextValue);
      }

      getAdditionalUserInfo = (appRelevantDataContextValue) => {
        let t = appRelevantDataContextValue.t;

        let modifiedState = this.state;
        modifiedState.AdditionalUserEmail = [];

            let jsonParams = {
                selectedTreeNodeID: this.state.SelectedDeviceID,
            }        
        axios.post(`${getAPIHostURL()}/wclient/getAdditionalUserInfo`, jsonParams)
        .then(response => {
            if(response.data.code == "SUCCESS") {
                
                let receivedAdditionalUsers = response.data.retreivedAdditionalUsers;

                for(let i=0; i < receivedAdditionalUsers.length; i++){
                    modifiedState.AdditionalUserEmail.push(receivedAdditionalUsers[i].email);

                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
            }
        }); 
    }

      handleAdditionalUserEmailChange = (e) => {
        let emailValue = e.target.value; // Get the modified value from the target
        // this.setState({
        //     RemoveUserEmailID :emailValue,
        //     error: {
        //         enterEmailIDFirst: "",
        //     }
        // })

        this.setState( 
            prevState => {
                let modifiedState = prevState;

                let errors = modifiedState.errors;
                modifiedState.RemoveUserEmailID = emailValue;
                modifiedState.errors.enterEmailIDFirst = "";

                return modifiedState;
            }
        );
    }

    onChangeEndViewTime = updatedTime => {
        let updatedActiveEndTime = updatedTime;
        this.setState( 
            prevState => {
                let modifiedState = prevState;
                modifiedState.EndViewTime = updatedActiveEndTime;
                return modifiedState;
            }
        );
    }

    onEndVwDtTmCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        this.setState( 
            prevState => {
                let modifiedState = prevState;

                let updatedEndViewTime = prevState.EndViewTime;

                // Give a tolerance of -5 minutes to the current date time to prevent unnecessary alert message
                let currentDateTime = new Date();
                currentDateTime.setMinutes(currentDateTime.getMinutes() - 5);

                if(updatedEndViewTime < currentDateTime) {
                    // let strMsg = 'End View Date Time cannot be less than the current date time.' + 
                    // '\nIt will be set to current date time.';
                    let strMsg = t(IDS_RmvUsrEndDtLessCurDt);
                    alert(strMsg);

                    // Calculate End View Time based on the currentDateTime
                    let EndViewTime = new Date(); // Eliminate the 5 minute tolerance added earlier so that we get more accurate End time
                    modifiedState.EndViewTime = EndViewTime;
                }

                return modifiedState;
            }
        );
    }

    handleAllChecked = (event) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        // Get the checked state from the event
        let isCheckedAllowViewAll = event.target.checked;

        let selectedDeviceID = this.state.SelectedDeviceID;

        // First get the full state so that only required elements can be updated
        let modifiedState = this.state;


        if(modifiedState.RemoveUserEmailID == null || modifiedState.RemoveUserEmailID.length <= 0) {
            event.preventDefault();
            modifiedState.errors.enterEmailIDFirst =  t(IDS_RemoveUserEmailFirst);
            this.setState(modifiedState);
            return;
        }  

        modifiedState.isCheckedAllowViewAll = isCheckedAllowViewAll;
        modifiedState.deviceData = []; // Initially set the Devices Data array to empty

        // Get the list of eligible devices for this additional user only if the 'AllowViewAll' checkbox is checked
        if(isCheckedAllowViewAll) {
            // JSON object containing DeviceOwnerEmailID and RemoveUserEmailID to be sent to the API Server
            let jsonParams = {
                RemoveUserEmailID: modifiedState.RemoveUserEmailID,
                DeviceOwnerEmailID: modifiedState.DeviceOwnerEmailID
            }

            axios.post(`${getAPIHostURL()}/wclient/getEligibleOwnedDevicesForRemoveUser`, jsonParams)
            .then(response => {
                if(response.data.code == 'SUCCESS') {

                    // Take the Devices info that were received from the server
                    let receivedDevicesInfo = response.data.retreivedDevicesInfo;

                    if(receivedDevicesInfo.length <= 0) {
                        // No errors
                        // modifiedState.errors.others = 'No Devices found which have been already Added for this User.';
                        modifiedState.errors.others = t(IDS_NoDevcForUsr);

                    } else {
                        // Take each Devices Info that was received from axios and 
                        // add into the State's 'deviceData' array after appropriate modification if required

                        // Also initially set no errors message (which can/may be set to appropriate error in the loop processing if required)
                        modifiedState.errors.others = '';

                        let noOfDevices = receivedDevicesInfo.length;
                        for (let i = 0; i < noOfDevices; i++) {
                            const singleValue = receivedDevicesInfo[i];

                            if( singleValue.DeviceID === selectedDeviceID ) {
                                // Do not show the originally passed DeviceID in the list, so that
                                // the user does not accidentally uncheck the device.
                                continue;
                            } else {
                                let singleDeviceInfo = { 

                                    // EXAMPLE: {key: 'DeviceID', id: 'DeviceID', value: "Device1", isChecked: true},
    
                                    key: singleValue.DeviceID, // DeviceID in the Server is unique so can be used directly as key
                                    id: singleValue.DeviceID, // The DeviceID
                                    value: singleValue.DeviceName,
                                    isChecked: true // Initially all eligible devices will be allowed for additional user
                                };
    
                                modifiedState.deviceData.push(singleDeviceInfo);
                            }
                        
                        } // for: Adding each DeviceInfo into the State's 'deviceData' array
                    }
                } else {
                    if (response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the DeviceOwnerEmailID or RemoveUserEmailID was not received by the Server
                        // modifiedState.errors.others = 'Server experiencing issues (UserID to be removed not received).\nTry again later.';
                        modifiedState.errors.others = t(IDS_RmvUsrServerErrorForUsrID);
                    } else if (response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                        modifiedState.errors.others = t(IDS_LoginServerIssue);
                    } else {
                        console.log('Should not reach here');
                        // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                        modifiedState.errors.others = t(IDS_LoginServerIssue);
                    }
                }

                // Display the Alert Settings received from the Server. Or display any specific error messages.
                this.setState(modifiedState);
            })
            .catch( error => {
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    modifiedState.errors.others = t(IDS_RegistNetworkError);
                    this.setState(modifiedState);
                }
            }); 

        } else {
            this.setState(modifiedState); // Original state with 'AllowViewAll' set to false
        }

    }

    handleCheckedIndividualDevice = (e) => {
        let targetDeviceID = e.target.name;
        let isChecked = e.target.checked;

        this.setState( 
            prevState => {
                let modifiedState = prevState;
                
                let deviceData = modifiedState.deviceData
                deviceData.forEach( (deviceDatasingle, index) => {
                    if (deviceDatasingle.id === targetDeviceID) {
                        modifiedState.deviceData[index].isChecked = isChecked;
                    }
                });
                
                return modifiedState;
            }
        );
    }

    
    removeSelectedDeviceFromUser = (inbIncludeOriginalDevice) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        let modifiedState = this.state; // Used to show errors after call is made to the server. Also to modify EndViewTime if less than current time.

        let strDeviceOwnerEmailID = this.state.DeviceOwnerEmailID;
        let RemoveUserEmailID = this.state.RemoveUserEmailID; 
        let EndViewTime = this.state.EndViewTime;

        //============================================================================================
        // * If end date time is less than current date time, it will be adjusted to be at least equal to the current date time.

        // Give a tolerance of -5 minutes to the current date time to prevent unnecessary alert message
        let currentDateTime = new Date();
        currentDateTime.setMinutes(currentDateTime.getMinutes() - 5);

        if(EndViewTime < currentDateTime) {
            // let strMsg = 'End View Date Time cannot be less than the current date time.' + 
            // '\nIt will be set to current date time.';
            let strMsg = t(IDS_RmvUsrEndDtLessCurDt);
            alert(strMsg);

            // Calculate new minimum required End View Time based on the currentDateTime
            EndViewTime = new Date(); // Eliminate the 5 minute tolerance added earlier for more accurate End time
            modifiedState.EndViewTime = EndViewTime; // Also put this in modified state so that it can be reflected on the screen
        }

        //============================================================================================

        // Convert the input dates to UTC before sending to the Server
        let strEndViewTime = convertUTCDateToStrYYYYMMDDHH24MMSS(EndViewTime);

        let selectedDeviceID = this.state.SelectedDeviceID;
        let SelectedDeviceName = this.state.SelectedDeviceName;

        const jsonUserAndDeviceInfo = {
            DeviceOwnerEmailID: strDeviceOwnerEmailID,
            RemoveUserEmailID: RemoveUserEmailID, 
            EndViewTime: strEndViewTime,
            deviceData: inbIncludeOriginalDevice == true ? 
                [ { DeviceID: selectedDeviceID } ] : // Initially this array will contain only the originally selected DeviceID
                [], // Original device skipped for removal as it may not be eligible
        };

        this.state.deviceData.forEach( (singledevice) => { 
            // Remove only the selected devices into the DeviceData array
            if(singledevice.isChecked === true) {
                let singleDeviceData = { 
                    DeviceID : singledevice.isChecked ? singledevice.id : '',
                };
                // Push singleDeviceData into deviceData array present in jsonUserAndDeviceInfo
                jsonUserAndDeviceInfo.deviceData.push(singleDeviceData);
            }          
        });

        axios.post(`${getAPIHostURL()}/wclient/removeUser`, jsonUserAndDeviceInfo)    
        .then(response => {
            if(response.data.code == 'SUCCESS') { 
                let strSuccessMessage = '';
                // strSuccessMessage = (inbIncludeOriginalDevice && this.state.deviceData.length <= 0) ?
                //     `Selected Device "${SelectedDeviceName}" Removed Successfully for User ${RemoveUserEmailID}` :
                //     `Selected Device(s) Removed Successfully for User ${RemoveUserEmailID}` ;

                strSuccessMessage = (inbIncludeOriginalDevice && this.state.deviceData.length <= 0) ?
                t(IDS_DeviceRmvSuccesForUsr, SelectedDeviceName, RemoveUserEmailID) :
                t(IDS_DeviceRmvSuccessfully, RemoveUserEmailID);

                modifiedState.errors.others = '';
                // this.setState(modifiedState);

                alert(strSuccessMessage); // Remove user successfully.
                this.state.notifyOnCloseForm(); // Notify the parent to close the popup.
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    // modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log('Should not reach here');
                    // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }  
        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }
        }); 
    
    }
    
    onSubmitRemoveUser = (e) => {
        e.preventDefault();
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        let strDeviceOwnerEmailID = this.state.DeviceOwnerEmailID;
        let strRemoveUserEmailID = this.state.RemoveUserEmailID;

        let EndViewTime = this.state.EndViewTime;

        let selectedDeviceID = this.state.SelectedDeviceID;
        let SelectedDeviceName = this.state.SelectedDeviceName;

        // First check if the original device for which this form was invoked is eligible
        // to be added for the specified user

        const jsonUserAndDeviceInfo = {
            DeviceOwnerEmailID: strDeviceOwnerEmailID,
            AdditionalUserEmailID: strRemoveUserEmailID,
            DeviceIDToCheck: selectedDeviceID
        };

        let modifiedState = this.state; // Used to show errors after call is made to the server

        axios.post(`${getAPIHostURL()}/wclient/isDeviceVisibleToAdditionalUser`, jsonUserAndDeviceInfo)    
        .then(response => {

            if(response.data.code == 'SUCCESS') {

                if (response.data.isDeviceVisibleToAdditionalUser) {
                    // Originally selected device is also visible to additional user.
                    // Call server api for removing the same along with any additional devices
                    // that may have been selected by the user.
                    this.removeSelectedDeviceFromUser(true); // Rest of the asynchronous processing will be done in this function
                } else if (this.state.deviceData.length > 0) {
                    // Tell the user that the original selected device is already not visible to
                    // to the additional user, so does not need to be removed. Would he like to remove the additional devices
                    // that he has selected.

                    // let strConfirmationMsg = `Device "${SelectedDeviceName}" is already not visible to User "${strRemoveUserEmailID}", so it does not need to be removed. ` + 
                    // `\nWould you like to Remove Access for User "${strRemoveUserEmailID}" for the other devices that you have selected from the list ? `;

                    let strConfirmationMsg = t(IDS_DeviceRmvAccess, SelectedDeviceName, strRemoveUserEmailID);

                    if ( window.confirm(strConfirmationMsg) ) {
                        // User wants to skip the original device (as it is already not visible to the user) and wants to use only the additionally selected devices.
                        // Call server api for removing only the additional devices.
                        this.removeSelectedDeviceFromUser(false); // Rest of the asynchronous processing will be done in this function
                    } else {
                        // User does not want to skip the original device while removing the additional devices
                        // modifiedState.errors.others = `Device "${SelectedDeviceName}" is already not visible to User "${strRemoveUserEmailID}", so it does not need to be removed. `;
                        modifiedState.errors.others = t(IDS_DeviceNotVisible, SelectedDeviceName, strRemoveUserEmailID);
                        this.setState(modifiedState); // To show red message on the form. Dont show alert here as you already showed Confirm message.
                    }
                } else {
                    // Original device is already not visible to the additional user. Also user has not selected any additional devices from the list.
                    // modifiedState.errors.others = `Device "${SelectedDeviceName}" is already not visible to User "${strRemoveUserEmailID}", so it does not need to be removed. `;
                    modifiedState.errors.others = t(IDS_DeviceNotVisible, SelectedDeviceName, strRemoveUserEmailID);
                    this.setState(modifiedState); // To show red message on the form
                    alert(modifiedState.errors.others);
                }

                return; // No further processing required
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    // modifiedState.errors.others = 'Server experiencing issues (AdditionalUser or DeviceID not specified).\nTry again later.';
                    modifiedState.errors.others = t(IDS_RmvUsrServerIssue);
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                    console.log('Should not reach here');
                    // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }  
        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }
        }); 
    
    }

    onCloseRemoveUserPopup = () => {
        this.state.notifyOnCloseForm();
    }

    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;   
        const{errors}=this.state;

        const color = {
            color: "var(--errorColor)",
            fontSize: "0.8rem",  
        }
       
        return (
            <div className="removeuser-blackfilm">    
                <div className="container">
                    <div className="row">
                        <div className="container col-lg-4 col-lg-offset-4
                                                col-md-6 col-md-offset-3">
                            <div className="mvAddScroll">
                                <div className="removeuser-popup">
                                    <form onSubmit={this.onSubmitRemoveUser}>
                                        <div className="popup-scroll">
                                            <h6 className="deviceName-removeUser"> {this.state.SelectedDeviceName} </h6>
                                            <div className="form-group remove-form">
                                                <div className="inputgroupCustom">
                                                    <label className="removeuser-form-label">{t(IDS_AUUserEmail)}:</label>
                                                    <select className="user-email-box" style={{marginLeft: "1.5rem"}} onChange={this.handleAdditionalUserEmailChange}
                                                    required="required">
                                                        <option></option>
                                                        {this.state.AdditionalUserEmail.map((singlAdditonalUser) => {
                                                            return(
                                                                <option key={singlAdditonalUser}>{singlAdditonalUser}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-group remove-form">
                                                <div className="inputgroupCustom">
                                                    <label className="removeuser-form-label">{t(IDS_AUEndView)}:</label>
                                                    <DateTimePicker
                                                        clearIcon={null}
                                                        onChange={this.onChangeEndViewTime}
                                                        onCalendarClose={this.onEndVwDtTmCalendarOrClockClose}
                                                        onClockClose={this.onEndVwDtTmCalendarOrClockClose}
                                                        className="input-form-datetime"
                                                        format={"yyyy/MM/dd HH:mm:ss"} 
                                                        value={this.state.EndViewTime} 
                                                        name="EndViewTime"
                                                    />
                                                </div>
                                            </div>

                                            <div className="allCheckedCheckbox1">
                                                <input type="checkbox" className="checkbox-space" onClick={this.handleAllChecked} defaultChecked={this.state.isCheckedAllowViewAll}/>
                                                <span className="allow-all-devices" dangerouslySetInnerHTML={{__html: t(IDS_RmvUsrAccess)}}></span>
                                            </div>

                                            <div style={{textAlign: "center"}}>
                                                {errors.enterEmailIDFirst != null && errors.enterEmailIDFirst.length > 0 && <span style={{color: "red", fontSize: "0.8rem"}} className="inlineCSS">{errors.enterEmailIDFirst}</span>}
                                            </div>

                                            { this.state.isCheckedAllowViewAll ? 
                                                <div>
                                                    {
                                                        this.state.deviceData.length <= 0 ?
                                                            this.state.errors.others.length <= 0 && 
                                                                <span style={{color: "green", fontSize: "0.8rem"}}>{t(IDS_RmvUsrDeviceNotAdd)}</span>
                                                            :
                                                            <span style={{color: "green", fontSize: "0.8rem"}}>{t(IDS_RmvUsrEnsureDevice)}</span>
                                                    }
                                                    <div className="user-list-removeuser">
                                                        <ul> { this.state.deviceData.map((deviceDatasingle) => {
                                                                return (<RemoveUserFormCheckbox  handleCheckedIndividualDevice={this.handleCheckedIndividualDevice}  
                                                                    {...deviceDatasingle} />
                                                                )
                                                            })}
                                                        </ul>
                                                    </div> 
                                                </div> 
                                                : null 
                                            }

                                            {errors.others.length > 0 && <span style={{marginLeft: "1.5rem", color: "red", fontSize: "0.8rem"}} className="inlineCSS">{errors.others}</span>}

                                            <div>
                                                <span>
                                                    <button type="submit" title={"Remove Devices for Additional User"} value={"Remove Devices for Additional User"}
                                                    className="removeuserButton btn-lg">{t(IDS_Remove)}</button>
                                                    <button 
                                                        type="button" 
                                                        // title={"Close Form"} 
                                                        // value={"Close Form"} 
                                                        className="removeuserButton btn-lg"
                                                        onClick={this.onCloseRemoveUserPopup}
                                                    >   {t(IDS_Close)}
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

RemoveUser.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default RemoveUser