import React, { Component } from 'react'
import "react-tabs/style/react-tabs.css";
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import "../CSS/AddUserForm.css"

export class VcUpdateNewAppVersion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            platFormTy: [],
            AppNameArr: [],
            AppInformationArr: [],
            selectedAppName: "",
            selectedAppPlatform: "",
            appVersion: "",
            MinReqAppVersion: "",
            OriginalMinReqAppVersion: "",
            CurrentAppVersion: "",
            setNewVerAsMinReqAppVersion: false,

            errors: { 
              others:'',
            },
        }
    }

    componentDidMount = () => {
        this.getMobileAppDetails();
    }

    getMobileAppDetails = () => {
        let modifiedState = this.state;
        modifiedState.errors.others = "";

        axios.post( `${getAPIHostURL()}/wclient/getMobileAppDetails`)
        .then(response => {
    
            if(response.data.code == 'SUCCESS') { 
                modifiedState.AppNameArr = [];
                modifiedState.platFormTy = [];
                modifiedState.AppInformationArr = [];
                modifiedState.AppInformationArr = response.data.retrivedAppInformation;

                if(modifiedState.AppInformationArr != null && modifiedState.AppInformationArr.length > 0) {
                    for(let i=0; i<modifiedState.AppInformationArr.length; i++) {
                        modifiedState.platFormTy.push(modifiedState.AppInformationArr[i]['Platform'])
                    }

                    // unique App Platform 
                    modifiedState.platFormTy = modifiedState.platFormTy.filter((singlePlatform, i, arrAppPlatformName) => arrAppPlatformName.indexOf(singlePlatform) === i);

                } else {
                    modifiedState.errors.others = 'App Details Not Present On Server.';
                }

                this.setState(modifiedState);
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                // modifiedState.errors.others = "Network issues.\nCheck your Internet and Try again later.";
                this.setState(modifiedState);
            }
        });
    }

    onChangePlatFormSelection = (e) => {
        let modifiedState = this.state;
        modifiedState.AppNameArr = [];
        modifiedState.appVersion = "";
        modifiedState.selectedAppName = "";
        modifiedState.MinReqAppVersion = "";
        modifiedState.OriginalMinReqAppVersion = "";
        modifiedState.setNewVerAsMinReqAppVersion = false;
        modifiedState.CurrentAppVersion = "";
        modifiedState.errors.others = "";
        modifiedState.selectedAppPlatform = e.target.value;
        for(let i=0; i< modifiedState.AppInformationArr.length; i++) {
            if(modifiedState.selectedAppPlatform  == modifiedState.AppInformationArr[i]['Platform']
            ) {
                modifiedState.AppNameArr.push(modifiedState.AppInformationArr[i]['AppName']);
            }
        }
        // Unique AppName for Selected App platform
        modifiedState.AppNameArr = modifiedState.AppNameArr.filter((singleAppName, i, arrAppName) => arrAppName.indexOf(singleAppName) === i);

        this.setState(modifiedState);
    }

    onChangeAppVersion = (e) => {
        let modifiedState = this.state;
        modifiedState.appVersion = e.target.value;
        modifiedState.setNewVerAsMinReqAppVersion = false;
        this.setState(modifiedState);
    }

    // For selecting App Name or Defining New App Name.
    onChangeAppNameSelection = (e) => {
        let modifiedState = this.state;
        modifiedState.MinReqAppVersion = "";
        modifiedState.OriginalMinReqAppVersion = "";
        modifiedState.CurrentAppVersion = "";
        modifiedState.appVersion = "";
        modifiedState.setNewVerAsMinReqAppVersion = false;
        modifiedState.selectedAppName = e.target.value;
        for(let i=0; i< modifiedState.AppInformationArr.length; i++) {
            if(modifiedState.selectedAppName == modifiedState.AppInformationArr[i]['AppName'] && 
                modifiedState.selectedAppPlatform  == modifiedState.AppInformationArr[i]['Platform']
            ) {
                modifiedState.MinReqAppVersion = modifiedState.AppInformationArr[i]['MinReqrdAppVersion'];
                modifiedState.OriginalMinReqAppVersion = modifiedState.AppInformationArr[i]['MinReqrdAppVersion'];
                modifiedState.CurrentAppVersion = modifiedState.AppInformationArr[i]['AppVersion'];
            }
        }
        this.setState(modifiedState);
    }

    onClickAddAppInfo = () => {
        let modifiedState = this.state;

        modifiedState.errors.others = "";

        if(modifiedState.appVersion != null && modifiedState.appVersion <= modifiedState.CurrentAppVersion) {
            modifiedState.errors.others = 'Please Enter Valid Version Number which is greater than the Previous Version.';
            this.setState(modifiedState);

        } else {

            let jsonBody = {
                newAppVersion: modifiedState.appVersion,
                platform: modifiedState.selectedAppPlatform,
                appName: modifiedState.selectedAppName,
                minReqAppVersion: modifiedState.MinReqAppVersion
            }
    
    
            axios.post( `${getAPIHostURL()}/wclient/insertOrUpdateAppInformation`, jsonBody)
            .then(response => {
        
                if(response.data.code == 'SUCCESS') { 
                    alert("Successfully stored app information on server.")
                    modifiedState.selectedAppName = "";
                    modifiedState.appVersion = "";
                    modifiedState.selectedAppPlatform = "";
                    modifiedState.AppNameArr = [];
                    modifiedState.MinReqAppVersion = "";
                    modifiedState.OriginalMinReqAppVersion = "";
                    modifiedState.CurrentAppVersion = "";
                    modifiedState.setNewVerAsMinReqAppVersion = false;
    
                    this.setState(modifiedState);
                    this.getMobileAppDetails();
                } else {
                    if(response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                    this.setState(modifiedState);
                }
            })
            .catch( error => {
                console.log("Network error:");
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    this.setState(modifiedState);
                }
            });
        }
    }

    // For setting New App Version as Minimum Required App Version.
    setNewAppVersionAsMinimumRequiredAppVersion = (e) => {
        let modifiedState = this.state;

        modifiedState.setNewVerAsMinReqAppVersion = e.target.checked;

        if(modifiedState.setNewVerAsMinReqAppVersion != null && modifiedState.setNewVerAsMinReqAppVersion == true){
            modifiedState.MinReqAppVersion = modifiedState.appVersion;
        } else {
            modifiedState.MinReqAppVersion = modifiedState.OriginalMinReqAppVersion;
        }

        this.setState(modifiedState);
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-lg-offset-2
                                              col-md-12 col-md-offset-2">
                        <div className="modal-body p-4 box">
                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1rem"}}>
                                Add Or Update New App Version  
                            </div>
                            <div >
                                <select  className="input-form" style={{height:"2.5rem", marginBottom: "1rem"}}
                                        value={this.state.selectedAppPlatform}
                                        onChange={this.onChangePlatFormSelection}
                                >
                                    <option value="" disabled select= "true">Select Platform...</option>
                                    {(this.state.platFormTy).map((singlePlatFormTy,index) => <option key={index} value={singlePlatFormTy}>{singlePlatFormTy}</option>)}
                                </select>
                            </div>
                            <div>
                                <label className="reg-form-label" style={{width:"100%"}}>Select App Name or Define New App:</label>
                                <div>
                                    <input type="list" list="data" className="input-form" required="required" value={this.state.selectedAppName} onChange={this.onChangeAppNameSelection} />
                                    <datalist id="data">
                                    {this.state.AppNameArr.map((singleAppName, index) =>
                                        <option key={index} value={singleAppName.displayValue}>{singleAppName}</option>
                                    )}
                                    </datalist>
                                </div>
                            </div>

                            <div hidden={this.state.MinReqAppVersion == null || this.state.MinReqAppVersion.length <=0}>
                                <label className="reg-form-label" style={{width:"100%"}}>Minimum Required App Version:</label>
                                <div>
                                    <input type="text" className="input-form" required="required" value={this.state.MinReqAppVersion} disabled/>
                                </div>
                            </div>
                            <div hidden={this.state.CurrentAppVersion == null || this.state.CurrentAppVersion.length <=0}>
                                <label className="reg-form-label" style={{width:"100%"}}>Current App Version:</label>
                                <div>
                                    <input type="text" className="input-form" required="required" value={this.state.CurrentAppVersion} disabled/>
                                </div>
                            </div>
                            <div>
                                <label className="reg-form-label" style={{width:"100%"}}>New App Version:</label>
                                <div>
                                    <input type="text" className="input-form" required="required" value={this.state.appVersion} onChange={this.onChangeAppVersion} />
                                </div>
                            </div>

                            <div className="reg-form-label" style={{marginTop: "1rem", width:"100%"}}>
                                <input type="checkbox"
                                        checked={this.state.setNewVerAsMinReqAppVersion}
                                        onChange={this.setNewAppVersionAsMinimumRequiredAppVersion}/> Set this App Version as 'Minimum Required App Version'.
                            </div>
                            
                            <div className="form-group reg-form" style={{marginTop:"1.5rem"}}>
                                <button type="button" className="btn-lg reg-btn" style={{ textTransform: "capitalize"}} onClick={this.onClickAddAppInfo}>Add App Information</button>
                                <div className = "buttonErrorMessage">
                                    {this.state.errors.others.length > 0 && 
                                        <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>}
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

export default VcUpdateNewAppVersion;
    