import React, { useState, useContext, useEffect } from 'react';
import logoviliso from '../IMAGES/vilisoLogo.png';
import '../CSS/Logintest.css';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_SUBMIT, IDS_EmailID} from '../../VcLanguage';
import { getLocalTimezoneOffset } from '../../vtUtil';
import {useNavigate} from 'react-router-dom';

function VcResetDeviceDetails (props) {

  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();
  const [state, setState] = useState({
    MaskEmailID: '',
    DeviceID: '',
    hideForm: true, // Initially hide the form till we check that the link has not expired.
    DevcPinCode: "",
    DevcVicinityType: "",
    DevcName: '',
    CountryInfo: [],
    DevcCountryCode: '',
    DevcToiletID: '',
    DevcSeatsInToilet: '',
    DevcToiletSection: '',
    TankVolume: '',
    TankDistance: '',
    errors: { 
      others:''
    },
  });


  useEffect(() => {
    getForgotPasskeyOnComponentMount();
    getAllCountryCodeInfo();
  }, []);

  const getForgotPasskeyOnComponentMount = (e) => {

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let modifiedState = state;
    modifiedState.DeviceID = '';

    // getting unique key as DeviceID from url of reset DeviceDetails page.
    modifiedState.DeviceID = window.location.pathname.split('/').pop()

    const recivedDeviceID = {
      DeviceId: modifiedState.DeviceID,
    }

    modifiedState.MaskEmailID = '';

    axios.post( `${getAPIHostURL()}/wclient/getDeviceDetailsOfSelectedDevc`, recivedDeviceID )
    .then(response => {
            
      if(response.data.code == 'SUCCESS') {

        const resData = response.data;
        modifiedState.MaskEmailID = (resData.maskUserID != null && resData.maskUserID.length > 0 ) ? resData.maskUserID : "";
        modifiedState.DevcPinCode = (resData.DevcPinCode != null && resData.DevcPinCode.length > 0 ) ? resData.DevcPinCode : "";
        modifiedState.DevcVicinityType = (resData.DevcVicinityType != null && resData.DevcVicinityType.length > 0 ) ? resData.DevcVicinityType : "";
        modifiedState.DevcName = (resData.DevcName != null && resData.DevcName.length > 0) ? resData.DevcName : "";
        modifiedState.DevcOwnerEmailId = (resData.DevcOwnerEmailId != null && resData.DevcOwnerEmailId.length > 0) ? resData.DevcOwnerEmailId : "";
        modifiedState.DevcCountryCode = (resData.CountryCode != null && resData.CountryCode.length > 0) ? resData.CountryCode : null;
        modifiedState.DevcToiletID = (resData.ToiletID != null && resData.ToiletID.length > 0) ? resData.ToiletID : null;
        modifiedState.DevcSeatsInToilet = (resData.SeatsInToilet != null && resData.SeatsInToilet.length > 0) ? resData.SeatsInToilet : null;
        modifiedState.DevcToiletSection = (resData.ToiletSection != null && resData.ToiletSection.length > 0) ? resData.ToiletSection : null;
        modifiedState.TankVolume = (resData.TankVolume != null && resData.TankVolume.length > 0) ? resData.TankVolume : null;
        modifiedState.TankDistance = (resData.TankDistance != null && resData.TankDistance.length > 0) ? resData.TankDistance : null;

        setState({...modifiedState});

        if(modifiedState.MaskEmailID.length <= 0 || modifiedState.DevcPinCode.length <= 0 || modifiedState.DevcVicinityType.length <= 0 || modifiedState.DevcName.length <=0){
          modifiedState.hideForm = true;
          setState({...modifiedState});

          alert(`Looks like your 'validate Device Owner EmailID' link has expired. Taking you to 'Login' page.`);
          navigate('/')
        } else {
          modifiedState.hideForm = false;
          setState({...modifiedState});
        }  
      } else {

        if ( response.data.code == 'SQL_ERROR' ) {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
          modifiedState.hideForm = false;

        } else {
          console.log('Should not reach here');
          modifiedState.errors.others = t(IDS_LoginServerIssue);
          modifiedState.hideForm = false;
        }
        // Display the specific error message for Reset Password failure
        setState({...modifiedState});
      }
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError);
      setState({...modifiedState});
    }); 
  }

  const onResetDevcDetailsSubmit = (e) => {
    e.preventDefault();

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let modifiedState = state;

    // Get the Browser Timezone Offset
    let localTimeZone = (new Date()).getTimezoneOffset();

      const loginData = {
        DeviceId: modifiedState.DeviceID,
        DeviceName: modifiedState.DevcName,
        ownerID: modifiedState.DevcOwnerEmailId,
        Pincode: modifiedState.DevcPinCode,
        installVicinityID: modifiedState.DevcVicinityType,
        LocalTimeZone: getLocalTimezoneOffset( localTimeZone ),
        CountryCode: modifiedState.DevcCountryCode,
        ToiletID: modifiedState.DevcToiletID,
        SeatsInToilet: modifiedState.DevcSeatsInToilet,
        ToiletSection: modifiedState.DevcToiletSection,
        TankVolume: modifiedState.TankVolume,
        TankDistance: modifiedState.TankDistance
      }
  
      axios.post( `${getAPIHostURL()}/vdevice/registerDeviceForAlreadyValidatedOwnerEmailID`, loginData )
      .then(response => {
        if(response.data.code == "SUCCESS" ) {
          alert(`Successfully registered Device with Device Name '${modifiedState.DevcName}' (${modifiedState.DeviceID}).Taking you to 'Login Page'.`);
          navigate('/');

          modifiedState.DeviceID = '';
          modifiedState.DevcName = '';
          modifiedState.MaskEmailID = '';
          modifiedState.DevcPinCode = '';
          modifiedState.errors.others = '';
          modifiedState.DevcOwnerEmailId = '';
          modifiedState.DevcToiletID = ''; 
          modifiedState.DevcSeatsInToilet = '';
          modifiedState.DevcToiletSection = '';
          modifiedState.TankVolume = '';
          modifiedState.TankDistance = '';

          setState({...modifiedState});

        } else if (response.data.code == 'SQL_ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else if( response.data.code == 'USR_NOT_YET_REGISTERED' ) {

          alert(`Successfully registered Device with Device Name '${modifiedState.DevcName}' (${modifiedState.DeviceID}). Please go ahead and create a User for the Registered owner.`);
          navigate('/registration')
        } else if( response.data.code == 'OWNERID_CANNOT_BE_MODIFIED' ) {
          modifiedState.errors.others = `Device with ID '${modifiedState.DeviceID}' already has an existing OwnerID which cannot be modified. Please contact the support team.`;

        } else if( response.data.code == 'NO_REGISTRATION_REQUIRED' ) {
          modifiedState.errors.others = 'Registration is not required.';

        } else if( response.data.code == 'CURR_STATE_IS_NOT_VALID' ) {
          modifiedState.errors.others = t(IDS_LoginServerIssue);

        } else if( response.data.code == 'INVALID_DEVICEID' ) {

          modifiedState.errors.others = 'Invalid Device ID.';
        } else {
          console.log('Should not reach here');
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
        // Display the specific error message for Reset Device details failure
        setState({...modifiedState});
  
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        setState({...modifiedState});
      }); 
  }

  const onChangeDevcName = (e) => {
    let modifiedState = state;

    modifiedState.DevcName = e.target.value;
    setState({...modifiedState});
  }

  const onChangeDevcPinCode = (e) => {
    let modifiedState = state;

    modifiedState.DevcPinCode = e.target.value;
    setState({...modifiedState});
  }

  const onChangeDevcVicinityType = (e) => {
    let modifiedState = state;

    modifiedState.DevcVicinityType = e.target.value;
    setState({...modifiedState});
  }

  const onChangeCountryCode = (e) => {
    let modifiedState = state;
    modifiedState.DevcCountryCode = e.target.value;
    modifiedState.errors.others = "";
    setState({...modifiedState});
  }

  const onChangeToiletID = (e) => {
    let modifiedState = state;
    modifiedState.DevcToiletID = e.target.value;
    modifiedState.errors.others = "";
    setState({...modifiedState});
  }

  const onChangeSeatsInToilet = (e) => {
    let modifiedState = state;
    modifiedState.DevcSeatsInToilet = e.target.value;
    modifiedState.errors.others = "";
    setState({...modifiedState});
  }

  const onChangeToiletSection = (e) => {
    let modifiedState = state;
    modifiedState.DevcToiletSection = e.target.value;
    modifiedState.errors.others = "";
    setState({...modifiedState});
  }

  const onChangeTankVolume = (e) => {
    let modifiedState = this.state;
    modifiedState.TankVolume = e.target.value;
    modifiedState.errors.others = "";
    this.setState(modifiedState);
  }

  const onChangeTankDistance = (e) => {
    let modifiedState = this.state;
    modifiedState.TankDistance = e.target.value;
    modifiedState.errors.others = "";
    this.setState(modifiedState);
  }

  const getAllCountryCodeInfo = () => {

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let modifiedState = state;

    axios.post(`${getAPIHostURL()}/wclient/getAllCountryCodeInformationForReg`)
    .then(response => {
      if(response.data.code == "SUCCESS") {
          modifiedState.CountryInfo = [];
          modifiedState.CountryInfo = response.data.CountryInfo;
      } else {

        if(response.data.code == "SQL_ERROR"){
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          console.log('Should not reach here');
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
      }
      setState({...modifiedState});
    })
    .catch(error => {
      console.log("Network error:");
      console.log(error);
      if (axios.isCancel(error)) {
        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
      } else {
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        setState({...modifiedState});
      } 
    });
  }
	
  const {errors} = state;
  let appRelevantDataContextValue = context;
  let t = appRelevantDataContextValue.t;

  const color = {
    color : "var(--errorColor)",
    fontSize:"13px"
  }

  return (
    <div className="container" style={{display: (state.hideForm) ? "none" : "block"}}>
      <div className="row">
        <div className="container col-lg-4 col-lg-offset-4 centered
                                  col-md-6 col-md-offset-3">
          <div className="modal-body login" >
              <div className="form-group logform">					
              <img src={logoviliso}/>
              </div>
              <div style={{color:"var(--secondaryColor)", fontSize:"1.3rem", fontWeight:"700"}}>Confirm Device Details</div>
              <form onSubmit={onResetDevcDetailsSubmit} >
                  <div className="inputgroupCustom">
                    <label className="log-form-label" style={{fontSize:"1rem"}}>{t(IDS_EmailID)}:</label>
                    <input type='Email' name='Email' className=" login-input" style={{fontweight: "bold", color:"grey"}} required="required"   
                                      value={state.MaskEmailID} disabled />

                  </div>
                  <div className="inputgroupCustom">
                    <label className="log-form-label" style={{fontSize:"1rem"}}>Device Name:</label>
                    <input type='text' name='DeviceName' className="login-input" required="required" 
                                      value={state.DevcName}
                                      onChange={onChangeDevcName} noValidate />
                  </div>
                  <div className="inputgroupCustom">      
                    <label className="log-form-label" style={{fontSize:"1rem"}}>Install Location Pin/Zip Code:</label>
                    <input type='text' name='pincode' className="login-input" required="required" 
                                      value={state.DevcPinCode}
                                      onChange={onChangeDevcPinCode} noValidate />
                  </div>
                  <div className="inputgroupCustom">      
                    <label className="log-form-label" style={{fontSize:"1rem"}}>Room Type:</label>
                    <input type='text' name='vicinityType' className="login-input" required="required" 
                                      value={state.DevcVicinityType}
                                      onChange={onChangeDevcVicinityType} noValidate />
                  </div>
                  <div className="contact-space" hidden={true}>
                    <label className="contact-form-label">Country:</label>
                    <span style={{display:"flex", justifyContent: "space-around"}}>
                    <select className="input-contact" 
                                        value={state.DevcCountryCode}
                                        onChange={onChangeCountryCode}
                                        style={{color: "#505050", marginRight:"0.5rem"}}
                                >
                                <option value={state.DevcCountryCode} select= "true">{state.DevcCountryCode}</option> 
                                {(state.CountryInfo).map((singleCountryCode, index) => 
                                <option key={index} value={singleCountryCode["CountryCode"]}>
                                {singleCountryCode["CountryCode"]}
                                {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                {'\xA0'+singleCountryCode["Country"]}</option>)}
                    </select>
                    </span>
                  </div>
                <div className="logform">
                  <button type="submit" className="btn-lg logbtn" >{t(IDS_SUBMIT)}</button>
                  <div className= "buttonErrorMessage">
                      {errors.others.length > 0 && 
                        <p style={color} className='error'>{errors.others}</p>}
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VcResetDeviceDetails;