import React, { Component } from 'react';
import '../CSS/RegistrationForm.css';
import axios from 'axios';
import App from '../../App'; 
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext } from '../../AppContext';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { IDS_AUSrvrIssueReqParamsNotSent, IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_AlertSrvcIssue} from '../../VcLanguage';
import { convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, trimStringAndRemoveTrailingComma} from '../../vtUtil';
import { RESOLVED_STATUS, IN_PROCESS_STATUS } from '../../VcConstants';

export class VcProvideSolForCustComplaint extends Component {
    constructor(props) {
        super(props);
        this.state = {
          EmailID: '',
          phoneNo: '',
          complaintCategory: "",
          complaintRegistrationDate: "",
          deviceID: [], 
          complaintDetails: "",
          StatusOfComplaint: "",
          solutionDetails: "",
          CustComplaintID: "",
          toShowPreviousSolutionDetailsModalOpen: false,
          solutionGivenBy: "",
          commentDetails:"",
          previousSolutionDetails:"",
          CustComplaintSolID:"",
          PassEmailKey:"",
          solutionState: "",
          solData: [],
          backdrop: 'static',
          provideAnotherSolutionMessage: "",
          hideForm: true, // Initially hide the form till we check that the link has not expired. 
          errors: { 
            others:''
          },
          toShowComplaintSnapshotModalOpen: false,
          ComplaintS3Bucket: "",
          arrFileName: [],
          signedUrl: "",
          singleFileName: "",
        };
    }

    // display all the complaint details on page mount.
    componentDidMount() {  
      this.getComplaintDetailsOnPageMount();
    }

    onLogOut = (inModifiedState = null) => {
      let appRelevantDataContextValue = this.context;
      let t = appRelevantDataContextValue.t; 
  
      let modifiedState;
  
      if(inModifiedState == null) {
        modifiedState = this.state;
      } else {
        modifiedState = inModifiedState;
      }
  
      let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
      let sessID = appRelevantDataContextValue.sessJankari.sessID;
      let fcmRegID = appRelevantDataContextValue.fcmRegDetails.fcmRegID;
  
      this.saveDeviceRegIdIntoDB(LoggedInUserID, fcmRegID, "null", 'Logout', sessID, modifiedState).then((response) => {

        let responseCode = response[0];
        let updatedState = response[1];

        if(responseCode == "SUCCESS") {

          this.endDateSesJankari(updatedState);          

        } else {
          this.setState(updatedState);
        }

      })
      .catch((error) => {
        console.log("Network error:");
        console.log(error);
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError)
        this.setState(modifiedState);
      });
        // Here, On Log out End date the current session of Logged in user.
    }
  
    endDateSesJankari = (inModifiedState = null) => {
  
      let modifiedState;
  
      if(inModifiedState == null) {
        modifiedState = this.state;
      } else {
        modifiedState = inModifiedState;
      }
  
      let appRelevantDataContextValue = this.context;
      let t = appRelevantDataContextValue.t; 
  
      let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
      let sessID = appRelevantDataContextValue.sessJankari.sessID;
  
      let jsonParams = {
        LoggedInUserID: LoggedInUserID,
        // strChalan: strChalan,
        sessID: sessID
      }
      
      axios.post(`${getAPIHostURL()}/wclient/endDateSesJankari`, jsonParams)
      .then(response => {
  
          if(response.data.code == 'SUCCESS') {
  
            // In VcLoginPg component on useEffect we added check if sesstion ID present in context than don't login page, 
            // redirect to default home page as per privilage and only if sesstion ID not present in context than only show login page form.
            // Bellow clearAppRelevantDataContextFromLocalStorage function was taking time to clear and set default empty context.
            // hence page redirect to login component before context get clean and its throws authorization failed message after logout.
            // so we explicitly clean the "onChangeSessJankari" context so after logout we can show login page.
            appRelevantDataContextValue.onChangeSessJankari("", "", "");
  
            // User wants to Log Out
            // Clear the AppRelevantDataContext that has been stored in the LocalStorage,
            // (Except UserID and Language Selection and a few other default values).
            App.clearAppRelevantDataContextFromLocalStorage();
  
            window.location.replace('/');

            this.setState(modifiedState);
  
          } else {
            // this.props.history.replace('/');
            if(response.data.code == 'REQ_PARAMS_MISSING') {
                modifiedState.errors.others = t(IDS_AlertSrvcIssue);
            } else if(response.data.code == 'SQL_ERROR') {
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
                console.log("Should not reach here");
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
            this.setState(modifiedState);
  
        }
      })
      .catch(err => {
          console.log("Network error");
          console.log(err);
          if (axios.isCancel(err)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            this.setState(modifiedState);
          }
      })
  
    }

  // To send notifications, we need FCM (Firebase Cloud Messaging) registration Id of particular user
  // Depending on the registration id, the notifications will be sent to the users whereever they are having active sessions.
  // The function below will save the FCM registration id of user into the database.
  // We call this function in following cases: Login, Logout, Language change and Token Refresh
  saveDeviceRegIdIntoDB = async (userEmailId, currToken, newToken, action, sessionID, inModifiedState = null) => {

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = this.state;
    } else {
      modifiedState = inModifiedState;
    }

    let sessID = "";

    let appRelevantDataContextValue = this.context;
    let t = appRelevantDataContextValue.t; 

    sessID = sessionID != null ? sessionID : null;
    let languageToViewIn = appRelevantDataContextValue.language.languageToViewIn

    var requestObject = {
      "UserID": userEmailId,
      "CurrentToken": currToken,
      "NewToken": newToken,
      "UserClientLanguage": languageToViewIn,
      "UserClientMode": "WEB",
      "Action": action,
      "SessionID": sessID
    };

    return await axios.post( `${getAPIHostURL()}/wclient/UpdateDeviceNotificationRegID`, requestObject )
    .then(response => {

      if(response.data.code == 'SUCCESS') {
        // console.log("Successfully Updated Notification ID");
      } else {
        if(response.data.code == 'REQ_PARAMS_MISSING'){
          modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);

        } else if (response.data.code == 'SQL_ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          console.log("Should not reach here.")
          modifiedState.errors.others = t(IDS_LoginServerIssue);

        }
      }
      return [response.data.code, modifiedState];
    })
    .catch( error => {
      console.log("Network error:");
      console.log(error);
      // Tell the user that there are network issues
      modifiedState.errors.others = t(IDS_RegistNetworkError)
      this.setState(modifiedState);
      return;
    }); 
  }

    getComplaintDetailsOnPageMount = (e) => {

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;
    
        // getting unique key as complaintID from url of provide solution for cust complaint
       let SplittedElementOfArray = window.location.pathname.split("/");

       let trimedCustComplaintID = trimStringAndRemoveTrailingComma(SplittedElementOfArray[2]);

       modifiedState.CustComplaintID = trimedCustComplaintID.replace(/\W*$/gi, '');

       // get logged in user from Context to check if the user has privilege to provide solution.
       let loggedInUserIDToCheck = appRelevantDataContextValue.loggedInUserInfo.userID;

        const recivedDCustComplaintID = {
            CustComplaintID: modifiedState.CustComplaintID,
            LoggedInUserID: loggedInUserIDToCheck
        }
        
        axios.post( `${getAPIHostURL()}/wclient/getCustComplaintDetailsBasedOnCustID`, recivedDCustComplaintID )
        .then(response => {
                
            if(response.data.code == 'SUCCESS') {

              // if the data is empty then the user dont have privilege to provide solution, log out user and take user to smartHHM login page.
              if(response.data["retrievedCustComplaintDetails"] == null || response.data["retrievedCustComplaintDetails"].length <= 0) {
                alert(`You don't have privilege to provide Solution for the Complaint. Logging out from the page and taking you to Smart HHM login page.`);
                this.onLogOut();

              } else {
          
                const resData = response.data["retrievedCustComplaintDetails"];

                let ComplaintDescriptionToShow = (resData[0].ComplaintDescription != null || resData[0].ComplaintDescription > 0) ? resData[0].ComplaintDescription: "";
                
                let DeviceIDsToShow = (resData[0].DeviceID != null || resData[0].DeviceID > 0) ? JSON.parse(resData[0].DeviceID) : "";
                let strCommaSeperatedDevices = [] ;

                // include comma and space after each device ID.
                // for example, end result  will be DA009, DA008 to display.
                if(DeviceIDsToShow != null || DeviceIDsToShow > 0) {
                    strCommaSeperatedDevices = DeviceIDsToShow[0];
                    for(let i=1; i<DeviceIDsToShow.length ; i++) {
                        strCommaSeperatedDevices += "," + " " + DeviceIDsToShow[i];
                    }
                }

                modifiedState.EmailID = (resData[0].EmailID != null && resData[0].EmailID.length > 0 ) ? resData[0].EmailID : "";
                modifiedState.phoneNo = (resData[0].PhoneNo != null && resData[0].PhoneNo.length > 0 ) ? resData[0].PhoneNo : "";
                modifiedState.complaintCategory = (resData[0].ComplaintCategory != null && resData[0].ComplaintCategory.length > 0 ) ? resData[0].ComplaintCategory : "";
                modifiedState.complaintRegistrationDate = (resData[0].ComplaintRegistrationDtTm != null && resData[0].ComplaintRegistrationDtTm.length > 0) ? convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(resData[0].ComplaintRegistrationDtTm) : "";
                modifiedState.deviceID = (resData[0].DeviceID != null || resData[0].DeviceID > 0) ? strCommaSeperatedDevices : DeviceIDsToShow;
                modifiedState.complaintDetails = ComplaintDescriptionToShow;
                modifiedState.StatusOfComplaint = (resData[0].StatusOfComplaint != null && resData[0].StatusOfComplaint.length > 0) ? resData[0].StatusOfComplaint : "";
                modifiedState.commentDetails = (resData[0].Comments != null && resData[0].Comments.length > 0) ? resData[0].Comments : "";
                modifiedState.CustComplaintSolID = (resData[0].CustComplaintSolID != null && resData[0].CustComplaintSolID.length > 0) ? resData[0].CustComplaintSolID : "";
                modifiedState.PassEmailKey  = (resData[0].PassEmailKey != null && resData[0].PassEmailKey.length > 0) ? resData[0].PassEmailKey : "";
                modifiedState.ComplaintS3Bucket  = (resData[0].ComplaintS3Bucket != null && resData[0].ComplaintS3Bucket.length > 0) ? resData[0].ComplaintS3Bucket : "";
                modifiedState.arrFileName  = (resData[0].FileName != null && resData[0].FileName.length > 0) ? JSON.parse(resData[0].FileName) : [];

                // if compliant status is resolved then expire the link and take user to smartHHM login page.
                if(modifiedState.StatusOfComplaint.length > 0 && modifiedState.StatusOfComplaint != null && modifiedState.StatusOfComplaint == RESOLVED_STATUS){
                    modifiedState.hideForm = true;
                    alert(`The particular complaint has been resolved for the Customer Complaint ID ${modifiedState.CustComplaintID}. Logging out from the page and taking you to Smart HHM login page.`);
                    this.onLogOut();
                } else {
                    modifiedState.hideForm = false;
                }  
              }
              this.setState(modifiedState);
            } else {
    
                if ( response.data.code == 'SQL_ERROR' ) {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                    modifiedState.hideForm = false;
                } else if (response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                    modifiedState.hideForm = false;
                }
                // Display the specific error message for Reset Password failure
                this.setState(modifiedState);
            }
        })
        .catch( error => {
          console.log("Network error:");
          console.log(error);
          // Tell the user that there are network issues
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          this.setState(modifiedState);
        }); 
    }

    getPreviousSolutionsToDisplay = () => {

      let modifiedState = this.state;
    
      let appRelevantDataContextValue = this.context;
      let t = appRelevantDataContextValue.t;

      const recivedDCustComplaintID = {
          CustComplaintID: modifiedState.CustComplaintID,
      }
      
      axios.post( `${getAPIHostURL()}/wclient/getPreviousSolutionsToDisplay`, recivedDCustComplaintID )
      .then(response => {
              
          if(response.data.code == 'SUCCESS') {

              modifiedState.solData = response.data.retrivedPreviousSolutions;

              if(modifiedState.solData == null || modifiedState.solData.length <= 0) {
                alert("There are no Solution details available.");
              } else {
                modifiedState.toShowPreviousSolutionDetailsModalOpen = true;
              }

              this.setState(modifiedState);

          } else {
  
              if (response.data.code == 'SQL_ERROR' ) {
                  // Tell the user that Server is experiencing errors
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
              } else if (response.data.code == 'REQ_PARAMS_MISSING'){
                  modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
              } else {
                  console.log('Should not reach here');
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
              }
              // Display the specific error message for Reset Password failure
              this.setState(modifiedState);
          }
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        this.setState(modifiedState);
      }); 
    }
    
    getComplaintSnapshot = (e) => {

      let singleFileName = e.target.getAttribute("data-singlefilename");

      let modifiedState = this.state;

      let appRelevantDataContextValue = this.context;
      let t = appRelevantDataContextValue.t;

      const jsonBody = {
        ID: modifiedState.CustComplaintID,
        S3BucketName: modifiedState.ComplaintS3Bucket,
        FileNameToView: singleFileName
      }

      modifiedState.signedUrl = "";
      modifiedState.singleFileName = singleFileName;
      modifiedState.toShowComplaintSnapshotModalOpen = false;
      
      axios.post( `${getAPIHostURL()}/wclient/getFilesFromS3Bucket`, jsonBody )
      .then(response => {
              
          if(response.data.code == 'SUCCESS') {

            if(response.data.signedUrl == null || response.data.signedUrl.length <= 0) {
              alert("No complaint snapshot found for clicked link.");
            } else {
              modifiedState.toShowComplaintSnapshotModalOpen = true;
              modifiedState.signedUrl = response.data.signedUrl;
            }
            
            this.setState(modifiedState);

          } else {
  
            if (response.data.code == 'SQL_ERROR' ) {
                // Tell the user that Server is experiencing errors
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else if (response.data.code == 'REQ_PARAMS_MISSING'){
                modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
            } else {
                console.log('Should not reach here');
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
            // Display the specific error message for Reset Password failure
            this.setState(modifiedState);
          }
      })
      .catch( error => {
        console.log("Network error:");
        console.log(error);
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        this.setState(modifiedState);
      }); 
    }

    // to display each solution in diffrent box
    showDisplayBoxForEachSolution = (e) => {
      let modifiedState = this.state;
        
      let boxSeperatedSolutions;

      boxSeperatedSolutions = <div style = {{width:"100%", fontSize:"0.8rem"}}> {

        modifiedState.solData.map((singleSolData, i) =>
          <div key = {i}>
            <div style={{border:"1px solid var(--primaryColor)", color: "#505050", backgroundColor: "#F0F0F0", fontSize:"0.8rem", width: "100%", paddingLeft: "0.3rem", paddingRight: "0.3rem", borderRadius:"0.3rem", marginBottom:"0.5rem"}}>
              <div style = {{padding: "0.3rem"}}>
                <div>
                  <b>Solution Submission Date:</b> {convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(singleSolData.ComplaintSolDtTm)}
                  <br/>
                  <b>Solution ID:</b> {singleSolData.CustComplaintSolID}
                  <br/>
                  <b>Solution State:</b> {singleSolData.Active == 1 ? IN_PROCESS_STATUS : "Rejected"}
                  <br/>
                  <br/>
                  <b>Solution:</b> {singleSolData.CustComplaintSol}
                  <br/>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
        
      return boxSeperatedSolutions;
        
    }
    
    handleChange = (e) => {
        let modifiedState = this.state;
        modifiedState.solutionDetails = e.target.value;
        this.setState(modifiedState);

        modifiedState.errors.others = "";
        modifiedState.provideAnotherSolutionMessage = "";

    }

    provideSolutionOnSubmit = (e) => {
        e.preventDefault();
    
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;

        let loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        if(modifiedState.solutionDetails == null || modifiedState.solutionDetails <= 0) {
            modifiedState.errors.others = 'Please provide Solution.';
            this.setState(modifiedState);
            return;
        } else if(modifiedState.solutionDetails != null && modifiedState.solutionDetails.length > 1500) {
          modifiedState.errors.others = 'Solution Details Character limit has exceed. It should be between 1 to 1500 Characters.';
          this.setState(modifiedState);
          return;
        } else {

            let complaintStatusInProcess = IN_PROCESS_STATUS;

            // replace all the single quotes to double quotes and escape character with double escape character while storing into database.
            let SolutionDescription = modifiedState.solutionDetails.replace(/'/g, "''").replace(/\\/g, "\\\\");

            const SolDetails = {
                CustComplaintID: modifiedState.CustComplaintID,
                CustComplaintSol: trimStringAndRemoveTrailingComma(SolutionDescription),
                SolutionGivenBy: loggedInUserID,
                ComplaintStatusToBeUpdated: complaintStatusInProcess, // set status as in process at the time of submiting each and every solution.
            }

            axios.post( `${getAPIHostURL()}/wclient/registerSolForCustComplaintID`, SolDetails )
            .then(response => {

                    if(response.data.code == "SUCCESS" ) {
                        
                        // if the response.data.code == "SUCCESS", then show the alert message and then confirm message 
                        // that if the users wants to log out from the page or stay on the page and provide another solution.
                        alert(`Your Solution has been recorded for Customer Complaint ID ${modifiedState.CustComplaintID}.`)
                        let windowsConfirmMsg = "";
                        windowsConfirmMsg = (`Do you want to Logout?\nPress Ok if you want to Log Out. You will be directed to Smart HHM login page.\nPress Cancel if you want to provide another solution.`);
                        
                        // if the user clicks cancel from the confirm message
                        // then stay on the same page and give user to provide another solution
                        if(!window.confirm(windowsConfirmMsg)) {
                          // clear the Input Solution field and errors
                          modifiedState.solutionDetails = '';
                          modifiedState.errors.others = '';
                          // show this message at the end of the page if the user stays on the same page to provide another solution.
                          modifiedState.provideAnotherSolutionMessage = "You can provide Another Solution if you want from the same page or press Log out button if you want to Log out.";
                          this.setState(modifiedState);
                          return;
                        } else {
                          // if the user selects okay and choose to log out then log user out from the page
                          // and take user to Smart HHM login page.
                          this.onLogOut();
                        }

                        this.setState(modifiedState);
            
                    } else {

                      if(response.data.code == 'ALREADY_RESOLVED_COMPLAINT') {
                        alert(`Complaint with ID ${modifiedState.CustComplaintID} is already resolved. Logging out and taking you to Smart HHM login page.`);
                        this.onLogOut();
                      } else if (response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = t(IDS_LoginServerIssue);
                      } else if (response.data.code == 'REQ_PARAMS_MISSING'){
                          modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                      } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = t(IDS_LoginServerIssue);
                    }
                    this.setState(modifiedState);
                  }
                
            })
            .catch( error => {
                console.log("Network error:");
                console.log(error);
                // Tell the user that there are network issues
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState); 
            }); 
        }
    }

    togglePreviousSolutionDetails = (e) => {
      this.setState(prevState => ({
        toShowPreviousSolutionDetailsModalOpen: !prevState.toShowPreviousSolutionDetailsModalOpen
      }));
    }

    toggleComplaintSnapshotDetails = (e) => {
      this.setState(prevState => ({
        toShowComplaintSnapshotModalOpen: !prevState.toShowComplaintSnapshotModalOpen
      }));
    }
    
    render() {

        const {errors} = this.state;

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        const color = {
        color : "var(--errorColor)",
        fontSize:"0.8rem"
        }

        const ProvideAnotherSolMessagecolor = {
          color : "var(--signalLevel3Color)",
          fontSize:"1.0rem"
          }

        return (
            <div>
              <div className="container" style={{display: (this.state.hideForm) ? "none" : "block"}}>
              <div className="row">
                <div className="container col-lg-6 col-lg-offset-6 centered
                                          col-md-6 col-md-offset-3">
                  <div className="modal-body login" style={{padding:"1rem"}}>
                      <div style={{color:"var(--secondaryColor)", fontSize:"1.3rem", fontWeight:"700", marginBottom:"0.5rem"}}>Complaint Details</div>
                      <form onSubmit={this.provideSolutionOnSubmit}>
                        <div className="form-group logform" style={{marginBottom:"0.5rem"}} hidden={this.state.EmailID == "" && this.state.EmailID.length <= 0}>
                          <div className="inputgroupCustom">
                            <label className="log-form-label" style={{fontSize:"0.9rem", marginBottom:"0.1rem"}}>Customer Email ID:</label>
                            <input type='text' name='Email' className=" login-input" style={{fontweight: "bold", color: "#505050", backgroundColor: "#F0F0F0",fontSize:"0.9rem"}} required="required" 
                                              value={this.state.EmailID} disabled />
      
                          </div>
                        </div>
                        <div className="form-group logform" style={{marginBottom:"0.5rem"}} hidden={this.state.phoneNo == "" && this.state.phoneNo.length <= 0}>
                          <div className="inputgroupCustom">
                            <label className="log-form-label" style={{fontSize:"0.9rem", marginBottom:"0.1rem"}}>Customer Phone No:</label>
                            <input type='text' name='phoneNo' className=" login-input" required="required" style={{fontSize:"0.9rem", color: "#505050", backgroundColor: "#F0F0F0"}}
                                              value={this.state.phoneNo}
                                              disabled/>
                                              
                          </div>
                        </div>
                        <div className="form-group logform" style={{marginBottom:"0.5rem"}}>
                          <div className="inputgroupCustom">      
                            <label className="log-form-label" style={{fontSize:"0.9rem", marginBottom:"0.1rem"}}>Complaint Type:</label>
                            <input type='text' name='complaintType' className=" login-input" required="required" style={{fontSize:"0.9rem", color: "#505050", backgroundColor: "#F0F0F0"}}
                                              value={this.state.complaintCategory}
                                              disabled />
                                            
                          </div>
                        </div>
                        <div className="form-group logform" style={{marginBottom:"0.5rem"}}>
                          <div className="inputgroupCustom">      
                            <label className="log-form-label" style={{fontSize:"0.9rem", marginBottom:"0.1rem"}}> Complaint Registration Date:</label>
                            <input type='text' name='complaintRegistrationDate' className=" login-input" required="required" style={{fontSize:"0.9rem", color: "#505050", backgroundColor: "#F0F0F0"}}
                                              value={this.state.complaintRegistrationDate}
                                              disabled />
                                            
                          </div>
                        </div>
                        <div className="form-group logform" style={{marginBottom:"0.5rem"}} hidden={this.state.deviceID == "" || this.state.deviceID <= 0}>
                          <div className="inputgroupCustom">      
                            <label className="log-form-label" style={{fontSize:"0.9rem", marginBottom:"0.1rem"}}>Device ID(s):</label>
                            <textarea type='message' name='deviceID' className=" login-input" required="required" size="8" 
                                              style={{height:"3.8rem",fontSize:"0.9rem", color: "#505050", backgroundColor: "#F0F0F0"}}
                                              rows="8" 
                                              value={this.state.deviceID}
                                              disabled />
                                            
                          </div>
                        </div>
                        <div className="form-group logform" style={{marginBottom:"0.5rem"}}>
                          <div className="inputgroupCustom">      
                            <label className="log-form-label" style={{fontSize:"0.9rem", marginBottom:"0.1rem"}}>Complaint Details:</label>
                            <textarea type='text' name='complaintDetails' className=" login-input" required="required" 
                                              style={{height:"9.4rem", fontSize:"0.9rem", color: "#505050", backgroundColor: "#F0F0F0"}}
                                              value={this.state.complaintDetails}
                                              disabled />
                                            
                          </div>
                        </div>
                        <div className="form-group logform" style={{display:this.state.arrFileName != null && this.state.arrFileName.length > 0 ? "block" : "none", marginBottom:"0.5rem"}}>
                          <div>
                            <label className="log-form-label" style={{fontSize:"0.9rem", marginBottom:"0.1rem"}}>Complaint Snapshot:</label>
                          </div>
                          {
                            this.state.arrFileName.map(singleFileName => {
                              return (
                                <div style={{textAlign:"left"}}>
                                  <div 
                                    style={{textDecoration:"underline", cursor:"pointer", color:"#007bff"}} 
                                    onClick={this.getComplaintSnapshot} 
                                    data-singlefilename={singleFileName}
                                  >
                                    {singleFileName}
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                        <div className="form-group logform" style={{marginBottom:"0.5rem", display: (this.state.commentDetails != null && this.state.commentDetails.length > 0 ) ? "block" : "none"}} >
                          <div className="inputgroupCustom">      
                            <label className="log-form-label" style={{fontSize:"0.9rem", marginBottom:"0.1rem"}}>Remark:</label>
                            <textarea type='text' name='commentDetails' className=" login-input" required="required" 
                                              style={{height:"5rem", fontSize:"0.9rem", color: "#505050", backgroundColor: "#F0F0F0"}}
                                              value={this.state.commentDetails}
                                              disabled />
                                            
                          </div>
                        </div>
                        <div className="form-group logform" style={{marginBottom:"0.5rem"}}> 
                            <div>
                              <label className="log-form-label" style={{fontSize:"0.9rem", marginBottom:"0.1rem"}}>Previous Solution Details:</label>
                            </div>
                            <button type="button" className="btn-lg logbtn" 
                                    style={{marginTop: "0rem", marginBottom:"0.5rem", width:"70%", fontSize:"0.8rem"}}
                                    onClick={this.getPreviousSolutionsToDisplay}
                            >
                                <b>Show Previous Solution Details</b>  
                            </button>
                        </div>
                        <Modal size="lg" scrollable={true} isOpen={this.state.toShowPreviousSolutionDetailsModalOpen} toggle={this.togglePreviousSolutionDetails} backdrop={this.state.backdrop}>
                          <ModalHeader toggle={this.togglePreviousSolutionDetails}>
                              Previous Solution Details
                          </ModalHeader> 
                          <ModalBody>
                              <div className="form-group logform">
                                <div className="inputgroupCustom">      
                                  {this.showDisplayBoxForEachSolution()}             
                                </div>
                            </div>
                            <div style={{textAlign:"center"}}>
                                <button type="button"
                                        className="addCustSavebtn"
                                        onClick={this.togglePreviousSolutionDetails}
                                > 
                                    Close
                                </button>
                            </div>
                          </ModalBody>
                        </Modal>
                        <Modal size="xl" scrollable={true} isOpen={this.state.toShowComplaintSnapshotModalOpen} toggle={this.toggleComplaintSnapshotDetails} backdrop={this.state.backdrop}>
                          <ModalHeader toggle={this.toggleComplaintSnapshotDetails}>
                              Complaint Snapshot - [{this.state.singleFileName}]
                          </ModalHeader> 
                          <ModalBody>
                            <div>
                              <div style={{textAlign:"center"}}>					
                                <img style={{maxHeight:"50rem", maxWidth:"50rem"}} src={this.state.signedUrl}/>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                        <div className="form-group logform" style={{marginBottom:"0.5rem"}}>
                          <div className="inputgroupCustom">      
                            <label className="log-form-label" style={{fontSize:"0.9rem", marginBottom:"0.1rem"}}>Provide Solution(Max Character limit is 1500 Characters):</label>
                            <textarea type='message' name='SolutionDetails' className=" login-input" 
                                              style={{height:"9.4rem",fontSize:"0.9rem"}}
                                              value={this.state.solutionDetails}
                                              onChange={this.handleChange} 
                                              maxLength = "1500"  
                                            />
                                            
                          </div>
                        </div>
                        <div className="logform">
                          <button type="submit" className="btn-lg logbtn" style={{fontSize:"0.95rem"}}>
                            <b>Submit</b>
                          </button>
                          <div className= "buttonErrorMessage">
                              {errors.others.length > 0 && 
                                <p style={color} className='error'>{errors.others}</p>}
                          </div>
                          <div className= "buttonErrorMessage">
                              {this.state.provideAnotherSolutionMessage.length > 0 && 
                                <p style={ProvideAnotherSolMessagecolor} className='error'>{this.state.provideAnotherSolutionMessage}</p>}
                          </div>
                        </div>
                      </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

VcProvideSolForCustComplaint.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcProvideSolForCustComplaint;
