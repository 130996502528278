import React, { useContext } from 'react'
import { NH3OD } from '../../../VcConstants'
import { IDS_Device, IDS_HygieneMsgGood, IDS_HygieneMsgModerate, IDS_HygieneMsgPoor, IDS_HygieneMsgSatisfactory, IDS_HygieneMsgSevere, IDS_HygieneMsgVPoor } from '../../../VcLanguage'
import { AppRelevantDataContext } from '../../../AppContext'
import { GOOD_L, GOOD_U, SATISFACTORY_L, SATISFACTORY_U, MODERATE_L, MODERATE_U, POOR_L, POOR_U, V_POOR_L, V_POOR_U,
     HIGH_SEVERE_L, HIGH_SEVERE_U,  } from '../../../VcConstants';

const VcInfoBox = (props) => {
    const context = useContext(AppRelevantDataContext)
    let t = context.t;

    let inParamValue = props.SingleDeviceParamValue[NH3OD];
    
    const renderOdourMessage = () => {
        let inParamNameType = NH3OD

        if(inParamValue == "--" || inParamValue == null) {
            return <div/>
        }

        if(inParamNameType == NH3OD) {
            let singleParamAirQualityRange = props.stdAirQualityRanges[inParamNameType];

            if(singleParamAirQualityRange == null) {
                console.log(`Should not happen. Param [${inParamNameType}] was not found in stdAirQualityRange. Might happen during first Render.`);
                // If singleParamAirQualityRange missing.
                return <div/>

            }

            let singleParamAirQualityRangeGoodL = singleParamAirQualityRange[GOOD_L];
            let singleParamAirQualityRangeGoodU = singleParamAirQualityRange[GOOD_U];
            let singleParamAirQualityRangeSatisfactoryL = singleParamAirQualityRange[SATISFACTORY_L];
            let singleParamAirQualityRangeSatisfactoryU = singleParamAirQualityRange[SATISFACTORY_U];
            let singleParamAirQualityRangeModerateL = singleParamAirQualityRange[MODERATE_L];
            let singleParamAirQualityRangeModerateU = singleParamAirQualityRange[MODERATE_U];
            let singleParamAirQualityRangePoorL = singleParamAirQualityRange[POOR_L];
            let singleParamAirQualityRangePoorU = singleParamAirQualityRange[POOR_U];
            let singleParamAirQualityRangeVPoorL = singleParamAirQualityRange[V_POOR_L];
            let singleParamAirQualityRangeVPoorU = singleParamAirQualityRange[V_POOR_U];
            let singleParamAirQualityRangeHighSevereL= singleParamAirQualityRange[HIGH_SEVERE_L];
            let singleParamAirQualityRangeHighSevereU = singleParamAirQualityRange[HIGH_SEVERE_U];

            if(( singleParamAirQualityRangeGoodL == null && inParamValue <= singleParamAirQualityRangeGoodU ) ||
                        ( singleParamAirQualityRangeGoodU == null && inParamValue >= singleParamAirQualityRangeGoodL ) ||
                        ( inParamValue >= singleParamAirQualityRangeGoodL && inParamValue <= singleParamAirQualityRangeGoodU ) )
            {
                // return <span className='w-100 '> {t(IDS_HygieneMsgGood)} </span>
                return <div className='w-100 p-2 rounded px-2 ' style={{backgroundColor:"#50c87850",fontSize: "1.1rem",}}>
                        {t(IDS_HygieneMsgGood, t(IDS_Device))} 
                        </div>

            } else if(( singleParamAirQualityRangeSatisfactoryL == null && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) ||
                        ( singleParamAirQualityRangeSatisfactoryU == null && inParamValue > singleParamAirQualityRangeSatisfactoryL ) ||
                        ( inParamValue > singleParamAirQualityRangeSatisfactoryL && inParamValue <= singleParamAirQualityRangeSatisfactoryU ) )
            {
                return <div className='w-100 p-2 rounded px-2' style={{backgroundColor:"#C7F36C", fontSize: "1.1rem",}}>
                        {t(IDS_HygieneMsgSatisfactory, t(IDS_Device))} 
                        </div>
            } else if(( singleParamAirQualityRangeModerateL == null && inParamValue <= singleParamAirQualityRangeModerateU ) ||
                        ( singleParamAirQualityRangeModerateU == null && inParamValue > singleParamAirQualityRangeModerateL ) ||
                        ( inParamValue > singleParamAirQualityRangeModerateL && inParamValue <= singleParamAirQualityRangeModerateU ) )
            {
                // return <span className='w-100 '> {t(IDS_HygieneMsgModerate)} </span>
                return <div className='w-100 p-2 rounded px-2 py-1' style={{backgroundColor:"#ffff0050", fontSize: "1.1rem",}}>
                            {t(IDS_HygieneMsgModerate, t(IDS_Device))} 
                        </div>
                
            } else if(( singleParamAirQualityRangePoorL == null && inParamValue <= singleParamAirQualityRangePoorU ) ||
                        ( singleParamAirQualityRangePoorU == null && inParamValue > singleParamAirQualityRangePoorL ) ||
                        ( inParamValue > singleParamAirQualityRangePoorL && inParamValue <= singleParamAirQualityRangePoorU ) )
            {
                // return <span className='w-100 '> {t(IDS_HygieneMsgPoor)} </span>
                return <div className='w-100 p-2 rounded px-2 py-1' style={{backgroundColor:"#ffa50050", fontSize: "1.1rem",}}>
                            {t(IDS_HygieneMsgPoor, t(IDS_Device))} 
                        </div>


            } else if(( singleParamAirQualityRangeVPoorL == null && inParamValue <= singleParamAirQualityRangeVPoorU ) ||
                        ( singleParamAirQualityRangeVPoorU == null && inParamValue > singleParamAirQualityRangeVPoorL ) ||
                        (inParamValue > singleParamAirQualityRangeVPoorL && inParamValue <= singleParamAirQualityRangeVPoorU ) )
            {
                // return <span className='w-100 '> {t(IDS_HygieneMsgVPoor)} </span>
                return <div className='w-100 p-2 rounded px-2 py-1' style={{backgroundColor:"#ff000050", fontSize: "1.1rem",}}>
                            {t(IDS_HygieneMsgVPoor, t(IDS_Device))}
                        </div>


            } else if(( singleParamAirQualityRangeHighSevereL == null && inParamValue <= singleParamAirQualityRangeHighSevereU ) ||
                        ( singleParamAirQualityRangeHighSevereU == null && inParamValue > singleParamAirQualityRangeHighSevereL ) ||
                        (inParamValue > singleParamAirQualityRangeHighSevereL && inParamValue <= singleParamAirQualityRangeHighSevereU ) )
            {            
                // return <span className='w-100 '> {t(IDS_HygieneMsgSevere)} </span>
                return  <div className='w-100 p-2 rounded px-2 py-1' style={{backgroundColor:"#c0000060", fontSize: "1.1rem",}}>
                            {t(IDS_HygieneMsgSevere, t(IDS_Device))}
                        </div>
                
            } else {
                if(inParamValue < 0 ) {
                    return <span className='w-100 '> {t(IDS_HygieneMsgGood, t(IDS_Device))} </span>
                } else {
                    return <span className='w-100 '> {t(IDS_HygieneMsgSevere, t(IDS_Device))} </span>
                }
            }
        }    
    }

  return (
    //To be made for general messages/info (summary analysis depending on parameters present), alert notifications
    props.SingleDeviceParamSequence.includes(NH3OD) && inParamValue != null ?
    <div className={`bg-white p-1 text-start rounded w-100 ${props.flag && 'mb-2'}`} >
        {renderOdourMessage()}
    </div>
    :
    <div/>
  )
}

export default VcInfoBox