import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AppRelevantDataContext} from '../../AppContext';
import { getAPIHostURL } from '../../ClientConfig';
import DateTimePicker from 'react-datetime-picker';
import '../CSS/VcStagewiseProdStat.css';
import { ARR_COMPLAINT_STATUS, IN_PROCESS_STATUS, RESOLVED_STATUS, RE_OPENED_STATUS, REGISTERED_STATUS, MAX_DAYS_RANGE, CSV_COLUMN_SEPERATOR, CSV_LINE_SEPERATOR, MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA} from '../../VcConstants';
import { convertUTCDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, 
        convertLocalDateToDisplayToday, convertLocalDateToStrYYYYMMDDHH24MMSS, trimStringAndRemoveTrailingComma } from '../../vtUtil';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters, useRowSelect }  from 'react-table';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { FaSearch } from 'react-icons/fa';
import { IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_TodaySearch } from '../../VcLanguage';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    )
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({columns, data, renderBasedOnTable, getCellProps = defaultPropGetter, passedStateVariables}) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { 
                pageIndex: 0, 
                // set page size based on tables.
                pageSize: renderBasedOnTable == "custComlaintTable" ? 10 :
                renderBasedOnTable == "ComplaintInfoTable" ? 1 : 
                renderBasedOnTable == "selectedComplaintSlnTable" && passedStateVariables[0] == "ResolveComplaint" ? 1 : 5,
                // show and hide columns based on coditions.
                hiddenColumns: passedStateVariables[1] == "ResolveComplaint" ? ["activeSln"] : ["selection"] },
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
        // useRowSelect,
        // hooks => {
        //     if(renderBasedOnTable == "selectedComplaintSlnTable"){

        //         hooks.visibleColumns.push(columns => [
                    
        //             // Let's make a column for selection
        //             {       
        //                 id: 'selection',
        //                 Header:() => <div className="ShowOrderTableHeader">Selected</div>,  
        //                 disableFilters: true,
        //                 Cell: ({ row }) => {
        //                     return (
        //                         // Using radio button to act like a bullets in React table.
        //                         <input
        //                             type = "radio"
        //                             name = "srchRsltTblSelectedRowIndicator"
        //                             id = "indicatingSelectedRow"
        //                             className = "selectedRadioBtn"
        //                             title = "Selected Solution"
        //                             readOnly
        //                             checked={passedStateVariables[2] == row.index ? true : false }
        //                         />
        //                     );
        //                 },
        //                 width: 98,
        //                 style:({
        //                     textAlign: "center",
        //                     paddingLeft: "1rem",
        //                     fontSize: "0.8rem",
        //                 }),
        //             },
        //             ...columns,
        //         ])
        //     }
        // }
    );

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp; {renderBasedOnTable == "custComlaintTable" || 
                        renderBasedOnTable == "selectedComplaintSlnTable" ? "No Data Found." : "Complaint Not Selected"}</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead hidden = {renderBasedOnTable == "ComplaintInfoTable" ? true : false}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {
                                            style: cell.column.style,
                                            },
                                            getCellProps(cell),
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || 
                        //     // when there is no data found 
                        //     <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex" ,justifyContent:"left"}}>{renderBasedOnTable == "custComlaintTable" || 
                        //                 renderBasedOnTable == "selectedComplaintSlnTable" ? "No Data Found." : "Complaint Not Selected"}
                        //             </span>
                        //         </td>
                        //     </tr>
                        }
                        {/* get empty rows */}
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            {renderBasedOnTable != "ComplaintInfoTable" && 
                <div className='w-100 d-flex justify-content-end antdPagination'>
                    <div className='d-flex py-1 justify-content-end align-items-center'>
                        <Pagination showQuickJumper 
                            defaultCurrent={pageIndex + 1} 
                            total={pageOptions.length * 10}
                            // total={10 * 10} 
                            onChange={(page) => gotoPage(page - 1)} 
                            showSizeChanger={false}
                            current={pageIndex + 1} 
                        />

                        <Select
                            style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                            aria-label="rows per page"
                            className="paginationDropdown mySelector"
                            value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                            onChange={e => setPageSize(Number(e))} 
                            options={[
                                {
                                    label: "5 rows",
                                    value: 5
                                },
                                {
                                    label: "10 rows",
                                    value: 10
                                },
                                {
                                    label: "20 rows",
                                    value: 20
                                },
                                {
                                    label: "25 rows",
                                    value: 25
                                },
                                {
                                    label: "100 rows",
                                    value: 100
                                }
                            ]} // Assuming options is the array of options for the Select component
                        />
                    </div>
                </div>
            }

            {/* <div  hidden = {renderBasedOnTable != "ComplaintInfoTable" ? false : true}>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcComplaintDetailsAndResolveComplaint (props) {

    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {

        if (id == "lastModifiedTime" || id == "complaintSolDtTm" || id == "complaintRegistrationDtTm") {
            return rows.filter(row => {

                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]
                        
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            })
        } else {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
                })
        }
    };

    const [state, setState] = useState({
        invokedFrom: props.invokedFrom,
        selectedComplaintStatus: "All",
        selectedCustComplaintID: "",
        selectedSlnComplaintID: "",
        selectedSlnSolutionID: "",
        takeActionBasedOn: "",
        addedComment: "",
        selectedComplaintCategory: "",
        custComlaintTableData: [],
        selectedComplaintSlnTableData: [],
        complaintInfoTableData: [],
        slnTbleSelectedRowIndex: null,
        selectedComplaintResolvedSlnID: "",
        selectedRowIndex: null,
        showModalForSelectedComplaintSolution: false,
        backdrop:'static',
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date(),
        bChngeBtnName: false,
        csvData: [],
        toShowComplaintSnapshotModalOpen: false,
        singleFileName: "",
        signedUrl: "",
        textFile: null,
        errors:{
            others:'',
            requiredFiledErr: '',
            timeRelatedErr: '',
        },

        custComlaintTableColumn: [
            {   
                Header:() => <div className="ShowOrderTableHeader">Customer<br/>Complaint ID</div>,  
                accessor: 'custComplaintID',
                width: 160,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Customer<br/>Email ID</div>,  
                accessor: 'custEmailID',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Customer<br/>Phone No</div>,  
                accessor: 'custPhoneNo',
                width: 140,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Customer<br/>Device ID</div>,  
                accessor: 'custDeviceID',
                width: 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Complaint<br/>Category</div>,  
                accessor: 'complaintCategory',
                width: 140,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Complaint<br/>Current Status</div>,  
                accessor: 'complaintCurrentStatus',
                width: 128,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    'whiteSpace': 'unset',
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Complaint<br/>Description</div>,  
                accessor: 'complaintDescription',
                width: 500,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    'whiteSpace': 'unset',
                    // height: "2.5rem",
                    // textOverflow: "ellipsis", 
                    // overflow: "hidden",
                    // whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Complaint<br/>Images</div>,  
                accessor: 'fileName',
                width: 500,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    'whiteSpace': 'pre',
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Remark</div>,  
                accessor: 'comments',
                width: 450,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Set By<br/>User</div>,  
                accessor: 'setByUser',
                width: 180,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            { 
                Header:() => <div className="ShowOrderTableHeader">Complaint<br/>Registration Date Time</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'complaintRegistrationDtTm',
                filter: filterCaseInsensitive,
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 190,
                style:({
                    textAlign:"left",
                    fontSize: "0.8rem",
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            },
            { 
                Header:() => <div className="ShowOrderTableHeader">Last<br/>Modified Time</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                filter: filterCaseInsensitive,
                accessor: 'lastModifiedTime',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 190,
                style:({
                    textAlign:"left",
                    fontSize: "0.8rem",
                    // height: "2.5rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",

                }),
            }, 
            
        ],

        selectedComplaintSlnTableColumn: [
            {   
                Header:() => <div className="ShowOrderTableHeader">Complaint<br/>Solution ID</div>,  
                accessor: 'custComplaintSolID',
                width: 170,
                style:({
                    textAlign:"left",
                    fontSize: "0.8rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Complaint<br/>Solution</div>,  
                accessor: 'custComplaintSln',
                width: 630,
                style:({
                    textAlign:"left",
                    fontSize: "0.8rem",
                    'whiteSpace': 'unset'
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Solution<br/>State</div>,  
                accessor: 'activeSln',
                show: props.invokedFrom == "ResolveComplaint" ? false : true,
                width: 95,
                style:({
                    textAlign:"left",
                    fontSize: "0.8rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Solution<br/>Given By</div>,  
                accessor: 'soluntionGivenBy',
                width: 140,
                style:({
                    textAlign:"left",
                    fontSize: "0.8rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {       
                Header:() => <div className="ShowOrderTableHeader">Solution<br/>Date Time</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'complaintSolDtTm',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 160,
                style:({
                    textAlign:"left",
                    fontSize: "0.8rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },                     
        ],

        ComplaintInfoTableColumn: [
            {       
                Header:() => <div className="ShowOrderTableHeader">Complaint ID</div>, 
                accessor: 'complaintInfoTbleComplaintID',
                width: 170,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.8rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Customer Email ID</div>,  
                accessor: 'complaintInfoTbleCustEmail',
                width: 150,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.8rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Customer Phone No</div>,  
                accessor: 'complaintInfoTbleCustPhone',
                width: 160,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.8rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Complaint Description</div>,  
                accessor: 'complaintInfoTblDescription',
                width: 550,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    fontSize: "0.8rem",
                    'whiteSpace': 'unset'
                }),
            }, 
            {   
                Header:() => <div className="ShowOrderTableHeader">Complaint<br/>Images</div>,  
                accessor: 'complaintInfoTblUploadedImages',
                width: 500,
                disableFilters: true,
                style:({
                    textAlign: "left",
                    fontSize: "0.8rem",
                    'whiteSpace': 'pre'
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Remark</div>,  
                accessor: 'complaintInfoTblComments',
                width: 600,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    fontSize: "0.8rem",
                    'whiteSpace': 'unset'
                }),
            },                        
        ],
    });

    useEffect(() =>{
        getComplaintDetails();
    }, []);

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        if(inLogTime == null) {
            return inLogTime;
        }

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return t(IDS_TodaySearch) + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    const getComplaintDetails = (inModifiedState = null) => {
        
        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let complaintStatusToSend = modifiedState.invokedFrom == "ResolveComplaint" ? IN_PROCESS_STATUS : modifiedState.selectedComplaintStatus;

        let diffBetweenTwoDates = Math.abs((modifiedState.startDate - modifiedState.endDate)/ (1000*60*60*24) );

        if( diffBetweenTwoDates > MAX_DAYS_RANGE ) {
            modifiedState.errors.others = "Date Range should be within Period of 365 days. Please, choose appropriate date.";
            setState({...modifiedState});
            return;
        } 

        if( diffBetweenTwoDates > MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA ) {
            if(!window.confirm(`Are you sure you want to view the large data for more than 90 days of period?`)){
                return
            }
        }

        let strLoclStartDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.startDate);
        let strLoclEndDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.endDate);

        let strLoclStartDtTm = strLoclStartDate.split(" ")[0] + "T" + "00:00:00";
        let strLoclEndDtTm = strLoclEndDate.split(" ")[0] + "T" + "23:59:59";
  
        let UtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclStartDtTm.valueOf()));
        let UtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclEndDtTm.valueOf()));

        modifiedState.custComlaintTableData = [];

        let jsonBody = {
            complaintStatus: complaintStatusToSend,
            startDate: UtcStartDtTm,
            endDate: UtcEndDtTm,
            invokedFrom : modifiedState.invokedFrom
        }

        axios.post(`${getAPIHostURL()}/wclient/getComplaintDetails`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data["retrivedComplaintDetails"] == null || response.data["retrivedComplaintDetails"].length <=0) {
                    
                    if( modifiedState.invokedFrom != null &&  modifiedState.invokedFrom  == "ResolveComplaint"){
                        modifiedState.errors.others = 'No Complaint Information Present for Resolve and Reopen complaint.';
                    }else {
                        modifiedState.errors.others = 'No Complaint Information are Present.';
                    }
                } else {
                    let retrivedComplaintDetails = response.data["retrivedComplaintDetails"];

                    let stateComplaintDetails = []

                    stateComplaintDetails = [...modifiedState.custComlaintTableData]

                    for(let i = 0; i < retrivedComplaintDetails.length; i++) {

                        const complaintDetails = retrivedComplaintDetails[i];

                        let strComplaintLastModifiedDateTimeLocale = (convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(complaintDetails["LastModifiedUtcDtTm"]));
                        let strComplaintRegistrationDateTimeLocale = (convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(complaintDetails["ComplaintRegstDtTm"]));
                        let strListOfImages = complaintDetails["FileName"] !=null && complaintDetails["FileName"].length > 0 ? complaintDetails["FileName"].replace(/[\[\]"]+/g,"") : "";
                        let arrListOfImages = [];
                        let divSeperatedImages = "" ; 

                        if(strListOfImages != null && strListOfImages.length > 0 && strListOfImages.includes(",")){
                            arrListOfImages = strListOfImages.split(",")

                            divSeperatedImages = 
                                arrListOfImages.map((singleImage, i) => 
                                    <div 
                                        style={{textDecoration:"underline", cursor:"pointer", color:"#313597"}}  
                                        onClick={getComplaintSnapshot} 
                                        key = {i}
                                        data-singleimage = {singleImage != null && singleImage.length > 0 ? singleImage.trim() : ""}
                                        data-compid = {complaintDetails["CustComplaintID"]}
                                        data-bucketname = {complaintDetails["ComplaintS3Bucket"]}
                                    >
                                        {singleImage != null && singleImage.length > 0 ? singleImage.trim() : "" }
                                    </div>
                                );

                        } else if(strListOfImages != null && strListOfImages.length >0 && !strListOfImages.includes(",")){
                            arrListOfImages = strListOfImages;

                            divSeperatedImages = 
                                <div 
                                    style={{textDecoration:"underline", cursor:"pointer", color:"#313597"}}  
                                    onClick={getComplaintSnapshot} 
                                    data-singleimage = {arrListOfImages != null && arrListOfImages.length > 0 ? arrListOfImages.trim() : ""}
                                    data-compid = {complaintDetails["CustComplaintID"]}
                                    data-bucketname = {complaintDetails["ComplaintS3Bucket"]}
                                >
                                    {arrListOfImages != null && arrListOfImages.length > 0 ? arrListOfImages.trim() : "" }
                                </div>
                        } else {
                            divSeperatedImages = <div></div>
                        }

                        let singleInstanceInfo = {
                            custComplaintID: complaintDetails["CustComplaintID"],
                            custEmailID: complaintDetails["CustEmail"] == null ? "" : complaintDetails["CustEmail"],
                            custPhoneNo: complaintDetails["CustPhoneNo"] == null ? "" : complaintDetails["CustPhoneNo"],
                            custDeviceID: complaintDetails["DeviceID"] == null ? "" : complaintDetails["DeviceID"].replace(/[\[\]"]+/g,""),
                            complaintCategory: complaintDetails["ComplaintCategory"] == null ? "" : complaintDetails["ComplaintCategory"],
                            complaintDescription: complaintDetails["ComplaintDescription"] == null ? "" : complaintDetails["ComplaintDescription"],
                            complaintCurrentStatus: complaintDetails["StatusOfComplaint"] == null ? "" : complaintDetails["StatusOfComplaint"],
                            custComplaintResolveSolID: complaintDetails["CustComplaintSolID"] == null ? "" : complaintDetails["CustComplaintSolID"],
                            comments: complaintDetails["Comments"] == null ? "" : complaintDetails["Comments"],
                            setByUser: complaintDetails["SetByUser"] == null ? "" : complaintDetails["SetByUser"],
                            complaintRegistrationDtTm: strComplaintRegistrationDateTimeLocale,
                            lastModifiedTime: strComplaintLastModifiedDateTimeLocale,
                            fileName : complaintDetails["FileName"] == null ? "" : divSeperatedImages,
                            bucketName: complaintDetails["ComplaintS3Bucket"] == null ? "" :  complaintDetails["ComplaintS3Bucket"],
                            unformattedFileName: complaintDetails["FileName"] == null ? "" : complaintDetails["FileName"].replace(/[\[\]"]+/g,""),
                        }
                        stateComplaintDetails.push(singleInstanceInfo);
                    }

                    modifiedState.custComlaintTableData = stateComplaintDetails;
                    modifiedState.errors.others = "";
                }
                
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});

        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        }); 
    }

    // when the image URL is clicked bring the image from s3 bucket.
    const getComplaintSnapshot = (e) => {

        let singleFileName = e.target.getAttribute("data-singleimage");
        let selectedUrlCompId = e.target.getAttribute("data-compid");
        let selectedUrlBucketName = e.target.getAttribute("data-bucketname");
  
        let modifiedState = state;
  
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
  
        const jsonBody = {
          ID: selectedUrlCompId,
          S3BucketName:  selectedUrlBucketName,
          FileNameToView: singleFileName
        }
  
        modifiedState.signedUrl = "";
        modifiedState.singleFileName = singleFileName;
        modifiedState.toShowComplaintSnapshotModalOpen = false;
        
        axios.post( `${getAPIHostURL()}/wclient/getFilesFromS3Bucket`, jsonBody )
        .then(response => {
                
            if(response.data.code == 'SUCCESS') {
  
              if(response.data.signedUrl == null || response.data.signedUrl.length <= 0) {
                alert("No complaint snapshot found for clicked link.");
              } else {
                modifiedState.toShowComplaintSnapshotModalOpen = true;
                modifiedState.signedUrl = response.data.signedUrl;
              }
              
              setState({...modifiedState});
  
            } else {
              if (response.data.code == 'SQL_ERROR' ) {
                  // Tell the user that Server is experiencing errors
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
              } else if (response.data.code == 'REQ_PARAMS_MISSING'){
                  modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
              } else {
                  console.log('Should not reach here');
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
              }
              // Display the specific error message for Reset Password failure
              setState({...modifiedState});
            }
        })
        .catch( error => {
          console.log("Network error:");
          console.log(error);
          // Tell the user that there are network issues
          modifiedState.errors.others = t(IDS_RegistNetworkError);
          setState({...modifiedState});
        }); 
      }

    const getAllSelectedComplaintSolution = (inModifiedState = null) => {
        
        let modifiedState;
        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.selectedComplaintSlnTableData = [];

        let jsonBody = {
            CustComplaintID: modifiedState.selectedCustComplaintID,
            InvokedFrom: modifiedState.invokedFrom,
            SelectedComplaintResolvedSlnID: modifiedState.selectedComplaintResolvedSlnID
        }

        axios.post(`${getAPIHostURL()}/wclient/getAllSelectedComplaintSolution`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data["retrivedComplaintSln"] == null || response.data["retrivedComplaintSln"].length <= 0) {
                    modifiedState.errors.others = 'No Complaint Solutions are Present. Please Try again later.';

                } else {
                    let retrivedComplaintSln = response.data["retrivedComplaintSln"];

                    modifiedState.showModalForSelectedComplaintSolution = true;

                    let stateComplaintSlnDetails = []

                    stateComplaintSlnDetails = [...modifiedState.selectedComplaintSlnTableData]

                    for(let i = 0; i < retrivedComplaintSln.length; i++) {

                        const complaintSlnDetails = retrivedComplaintSln[i];

                        let strComplaintSlnDateTimeLocale = (convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(complaintSlnDetails["ComplaintSolDtTm"]));
                        let strSlnTableLastModifiedDateTimeLocale = (convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(complaintSlnDetails["LastModifiedDtTm"]));

                        let singleInstanceInfo = {
                            custComplaintSolID: complaintSlnDetails["CustComplaintSolID"],
                            slnTblCustComplaintID: complaintSlnDetails["CustComplaintID"],
                            custComplaintSln: complaintSlnDetails["CustComplaintSol"] == null ? "" : complaintSlnDetails["CustComplaintSol"],
                            activeSln: complaintSlnDetails["Active"] == null ? "" : (modifiedState.selectedComplaintResolvedSlnID != null && 
                                modifiedState.selectedComplaintResolvedSlnID.length > 0) ? RESOLVED_STATUS : complaintSlnDetails["Active"] == 1 ? IN_PROCESS_STATUS : "Rejected",
                            soluntionGivenBy: complaintSlnDetails["SolutionGivenBy"] == null ? "" : complaintSlnDetails["SolutionGivenBy"],
                            complaintSolDtTm: strComplaintSlnDateTimeLocale,
                            lastModifiedTime: strSlnTableLastModifiedDateTimeLocale,
                        }

                        stateComplaintSlnDetails.push(singleInstanceInfo);
                    }

                    modifiedState.selectedComplaintSlnTableData = stateComplaintSlnDetails;
                    modifiedState.errors.others = "";
                }
                
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});

        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        }); 
    }

    const rejectReopenOrResolveComplaintSolution = (inModifiedState = null) => {
        
        let modifiedState;
        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;
        let loggedinUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
        
        if(modifiedState.selectedSlnComplaintID == null || modifiedState.selectedSlnComplaintID.length <= 0 || 
            modifiedState.selectedSlnSolutionID == null || modifiedState.selectedSlnSolutionID.length <= 0
        ) {
            modifiedState.errors.requiredFiledErr = 'Please select the particular Solution by clicking on the Solution row.';
            setState({...modifiedState});
            return;

        } else if(modifiedState.takeActionBasedOn == null || modifiedState.takeActionBasedOn.length <= 0 ) {
            modifiedState.errors.requiredFiledErr = state.selectedComplaintSlnTableData != null && state.selectedComplaintSlnTableData.length > 1 ? 
                'Please select any one option from Reject the Selected Solution and Resolve Complaint.' :
                `Please select any one option from Re Open Complaint and Resolve Complaint.`;
                setState({...modifiedState});
                return;

        } else if((modifiedState.takeActionBasedOn == "ResolveComplaint" || modifiedState.takeActionBasedOn == "ReopenComplaint") &&
            (modifiedState.addedComment == null || modifiedState.addedComment.length <= 0)
        ) {
            modifiedState.errors.requiredFiledErr = 'Please add the Remark.';
            setState({...modifiedState});
            return;

        } else {
            let windowsConfirmMsg = "";
            if(modifiedState.takeActionBasedOn == "ResolveComplaint") {
                windowsConfirmMsg = `Are you sure you want to Resolve the selected Complaint with Complaint ID ${modifiedState.selectedSlnComplaintID}.`;
            } else if(modifiedState.takeActionBasedOn == "ReopenComplaint") {
                windowsConfirmMsg = `Are you sure you want to Reject the selected solution and Re Open the selected Complaint with Complaint ID ${modifiedState.selectedSlnComplaintID}.\nThe Email will be sent with Re opened Complaint details to the respective person.`;
            } else {
                windowsConfirmMsg = `Are you sure you want to Reject the selected Solution with solution ID ${modifiedState.selectedSlnSolutionID}`;
            }

            if(!window.confirm(windowsConfirmMsg)) {
                return;
            }

            let commentToAdd = modifiedState.takeActionBasedOn == "RejectSolution" ? "" : (modifiedState.addedComment.replace(/'/g, "''").replace(/\\/g, "\\\\"));

            let constComplaintStatus = modifiedState.takeActionBasedOn == "ResolveComplaint" ? RESOLVED_STATUS :
                 modifiedState.takeActionBasedOn == "ReopenComplaint"  ? RE_OPENED_STATUS : "";

            let jsonBody = {            
                SelectedComplaintID: modifiedState.selectedSlnComplaintID,
                SelectedSolutionID: modifiedState.selectedSlnSolutionID,
                SelectedComplaintCategory: modifiedState.selectedComplaintCategory,
                Comment: trimStringAndRemoveTrailingComma(commentToAdd),
                ConstComplaintStatus: constComplaintStatus,
                TakeActionBasedOn: modifiedState.takeActionBasedOn,
                LoggedInUserID: loggedinUserID

            }

            if(modifiedState.takeActionBasedOn != null  && modifiedState.takeActionBasedOn == "ReopenComplaint"){
                // Show spinner to restrict the user to perform any actions.
                appRelevantDataContextValue.onChangeProcessingReq(true);
            }

            axios.post(`${getAPIHostURL()}/wclient/rejectReopenOrResolveComplaintSolution`, jsonBody)
            .then(response => {

                // Remove spinner and allow user to perform action.
                appRelevantDataContextValue.onChangeProcessingReq(false); 

                if(response.data.code == 'SUCCESS') {

                    modifiedState.errors.others = "";
                    modifiedState.errors.requiredFiledErr = "";
                    modifiedState.addedComment = "";
                    modifiedState.slnTbleSelectedRowIndex = null;

                    if(modifiedState.takeActionBasedOn == "ResolveComplaint") {
                        alert(`Successfully Resolved Complaint with Complaint ID ${modifiedState.selectedSlnComplaintID}.`);
                        modifiedState.selectedSlnComplaintID = "";
                        modifiedState.selectedSlnSolutionID = "";
                        modifiedState.takeActionBasedOn = "";
                        modifiedState.showModalForSelectedComplaintSolution = false;
                        getComplaintDetails(modifiedState);

                    } else if(modifiedState.takeActionBasedOn == "ReopenComplaint") {
                        alert(`Successfully Re Opened Complaint with Complaint ID ${modifiedState.selectedSlnComplaintID}.`);
                        modifiedState.selectedSlnComplaintID = "";
                        modifiedState.selectedSlnSolutionID = "";
                        modifiedState.takeActionBasedOn = "";
                        modifiedState.showModalForSelectedComplaintSolution = false;
                        getComplaintDetails(modifiedState);

                    } else {
                        alert(`Successfully Rejected Solution with Solution ID: ${modifiedState.selectedSlnSolutionID}.`);
                        modifiedState.selectedSlnComplaintID = "";
                        modifiedState.selectedSlnSolutionID = "";
                        modifiedState.takeActionBasedOn = "";
                        getAllSelectedComplaintSolution(modifiedState);

                    }
                } else {
                    if(response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                    else if(response.data.code == 'REQ_PARAMS_MISSING') {
                        modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                    }
                    else if(response.data.code == 'CUST_DETAILS_MISSING') { 
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.'
                    }
                    else if(response.data.code == 'PRIVILEGES_NAME_MISSING') { 
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.'
                    }
                    else if(response.data.code == 'EMAIL_ID_MISSING'){
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.'
                    }
                    else if(response.data.code == 'SUPPORT_DETAILS_MISSING') {
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.'
                    }
                    else if(response.data.code == 'ERROR') {
                        modifiedState.errors.others = 'Server experiencing issues while sending Email. Try again later.'
                    }
                    else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                    setState({...modifiedState});
                }
            })
            .catch( error => {

                // Remove spinner and allow user to perform action.
                appRelevantDataContextValue.onChangeProcessingReq(false); 

                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});
                }
            }); 
        }
    }

    const onComplaintStatusChange = (e) => {
        let modifiedState = state;
        modifiedState.selectedComplaintStatus = e.target.value;

        modifiedState.custComlaintTableData = [];
        modifiedState.selectedRowIndex = null;

        setState({...modifiedState});

    }

    const onClickProceed = () => {

        let modifiedState = state;
        modifiedState.bChngeBtnName = true;

        getComplaintDetails(modifiedState);

    }

    const toggleShowModalForSelectedComplaintSolution = () => {

        let modifiedState = state;
        modifiedState.showModalForSelectedComplaintSolution = !modifiedState.showModalForSelectedComplaintSolution;
        modifiedState.errors.others = "";
        modifiedState.errors.requiredFiledErr = "";
        modifiedState.addedComment = "";
        modifiedState.slnTbleSelectedRowIndex = null;
        modifiedState.takeActionBasedOn = "";
        modifiedState.selectedSlnComplaintID = "";
        modifiedState.selectedSlnSolutionID = "";

        setState({...modifiedState});

    }

    // For entering detailed decription for Resolved or Reopen Complaint.
    const onEnteredNewComment = (e) => {
        let modifiedState = state;
        modifiedState.addedComment = e.target.value;
        modifiedState.errors.requiredFiledErr = "";

        setState({...modifiedState});
    }

    // Radio Button to Switch between Search by User Info and search by Device ID.
    // on switch we also Empty the TextBoxes and Array.
    const onTakeActionBasedOnRadioBtnChange = (e) => {
        
        let modifiedState = state;

        modifiedState.takeActionBasedOn = e.target.value;
        modifiedState.errors.requiredFiledErr = "";
        modifiedState.errors.others = "";
        modifiedState.addedComment = "";

        setState({...modifiedState});
    }

    const onChangeStartDate = (updatedStartDt) => {
        let modifiedState = state;
        let currentDate = new Date();

        if(updatedStartDt > currentDate) {
            modifiedState.errors.timeRelatedErr = "Start Date can not be greater than Current Date. It will be set to Current Date.";
            modifiedState.startDate = currentDate;
            modifiedState.endDate = currentDate;

        } else if(updatedStartDt > modifiedState.endDate) {
            modifiedState.errors.timeRelatedErr = "End Date can not be less than Start Date. It will be set same as to Start Date.";
            modifiedState.startDate = updatedStartDt;
            modifiedState.endDate = updatedStartDt;

        } else {
            modifiedState.errors.timeRelatedErr = "";
            modifiedState.startDate = updatedStartDt;
        }
        modifiedState.custComlaintTableData = [];
        modifiedState.selectedRowIndex = null;

        setState({...modifiedState});
    }

    const onChangeEndDate = (updatedEndDt) => {
        let modifiedState = state;
        let currentDate = new Date();

        if(updatedEndDt > currentDate) {
            modifiedState.errors.timeRelatedErr = "End Date can not be greater than Current Date. It will be set to Current Date.";
            modifiedState.endDate = currentDate;

        } else if(updatedEndDt < modifiedState.startDate) {
            modifiedState.errors.timeRelatedErr = "Start Date can not be greater than End Date. It will be set as same to End Date.";
            modifiedState.startDate = updatedEndDt;
            modifiedState.endDate = updatedEndDt;

        } else {
            modifiedState.errors.timeRelatedErr = "";
            modifiedState.endDate = updatedEndDt;
        }

        modifiedState.custComlaintTableData = [];
        // modifiedState.data = [];
        modifiedState.selectedRowIndex = null;
        // modifiedState.selectedRowProdName = "";

        setState({...modifiedState});
    }

    const onClickDownloadDataAsCsvFile = () => {
        let modifiedState = state;

        if(modifiedState.custComlaintTableData.length > 0) {
            DownloadCsv(modifiedState.custComlaintTableData);
        } else {
            modifiedState.errors.others = "There are no data present to Downloaded.";
            setState({...modifiedState});
        }
    }


    const DownloadCsv = (inCsvData) => {      

        let modifiedState = state;

        let arrColumnKeys = [ "Customer Email ID", "Customer Phone No", "Customer Device ID", "Complaint Category", "Complaint Description", "Complaint Images", "Complaint Current Status", "Remark", 
                                 "Set By User", "Complaint Registration Date Time", "Last Modified Time"];

        let strCsvHeaderLine = "Customer Complaint ID";
        let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
        let strSingleCsvDataLine = "";
        let strColumnName = "";
        modifiedState.csvData = [];

        // Create rest of the columns in the header
        let noOfParams = arrColumnKeys.length;
        for (let j=0; j<noOfParams; j++) {
            strColumnName = arrColumnKeys[j];
            strCsvHeaderLine += (CSV_COLUMN_SEPERATOR + strColumnName);
        }

        let receivedComplaintDetails = inCsvData;
        let allInstanceInfo = [];

        // First push the header CSV line
        arrCsvAllLines.push(strCsvHeaderLine);

        for(let i = 0; i < inCsvData.length; i++) {

            strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                ((receivedComplaintDetails[i]["custComplaintID"] == null) ? '' : receivedComplaintDetails[i]["custComplaintID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedComplaintDetails[i]["custEmailID"] == null) ? '' : receivedComplaintDetails[i]["custEmailID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedComplaintDetails[i]["custPhoneNo"] == null) ? '' : receivedComplaintDetails[i]["custPhoneNo"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedComplaintDetails[i]["custDeviceID"] == null) ? '' : receivedComplaintDetails[i]["custDeviceID"].replace(/\n|\r|,/g, "") ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedComplaintDetails[i]["complaintCategory"] == null) ? '' : receivedComplaintDetails[i]["complaintCategory"] ) +
                CSV_COLUMN_SEPERATOR +
                ((receivedComplaintDetails[i]["complaintDescription"] == null) ? '' : receivedComplaintDetails[i]["complaintDescription"].replace(/\n|\r|,/g, "") ) +
                CSV_COLUMN_SEPERATOR +
                ((receivedComplaintDetails[i]["unformattedFileName"] == null) ? '' : receivedComplaintDetails[i]["unformattedFileName"].replace(/\n|\r|,/g, " ") ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedComplaintDetails[i]["complaintCurrentStatus"] == null) ? '' : receivedComplaintDetails[i]["complaintCurrentStatus"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedComplaintDetails[i]["comments"] == null) ? '' : receivedComplaintDetails[i]["comments"].replace(/\n|\r|,/g, "") ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedComplaintDetails[i]["setByUser"] == null) ? '' : receivedComplaintDetails[i]["setByUser"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedComplaintDetails[i]["complaintRegistrationDtTm"] == null) ? '' : receivedComplaintDetails[i]["complaintRegistrationDtTm"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedComplaintDetails[i]["lastModifiedTime"] == null) ? '' : receivedComplaintDetails[i]["lastModifiedTime"] );

            arrCsvAllLines.push(strSingleCsvDataLine);
        }

        // modifiedState.csvData = allInstanceInfo;
        // Create CSV File and fill it
        let bFileCreationStatus = createAndFillCsvFile(arrCsvAllLines);

        alert(`Complaint Details Data download has started. Check your Browser's status bar or Downloads Folder.`);

        if (bFileCreationStatus) {
            // CSV file creation successful.
            // Close the date selection modal.
            // modifiedState.enableStagewiseProdStat = true;

            // props.toggleAllOrderDetailsInfoModal(); // Notify the parent to close the modal.
            // modal closed, no need to do any further processing
            // return;
        } else {
            modifiedState.errors = 'Downloaded data could not be saved to file.';
        }

        setState({...modifiedState});
    } 

    const createAndFillCsvFile = (inarrCsvAllLines) => {

        let retVal = true;

        let foramttedStartDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.startDate);
        let foramttedEndDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.endDate);
        let fileName = "Complaint Details Data From " + foramttedStartDateTime + " To " + foramttedEndDateTime + ".csv";

        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = makeTextFile(inarrCsvAllLines);
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            let event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });

        // Return true to indicate that operation performed successfully (in future any failures in 
        // the previous calls can be checked to return false)
        retVal = true;
        return retVal;
    }

    const makeTextFile = (arrText) =>  {

        let data = new Blob(arrText, {type: 'text/plain'});
        let modifiedState = state;

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (modifiedState.textFile !== null) {
            window.URL.revokeObjectURL(modifiedState.textFile);
        }

        modifiedState.textFile = window.URL.createObjectURL(data);

        // Return a URL you can use as an href
        return modifiedState.textFile;
    }


    const onComplaintTblRowClick = (cellInfo) => {

        if (cellInfo.row != undefined && cellInfo.column != undefined && cellInfo.column["id"] != "fileName") {
            let modifiedState = state;
            return {
                onClick: (e, handleOriginal) => {

                    modifiedState.selectedRowIndex = cellInfo.row.index;

                    let stateComplaintInfoTableData = [];
                    modifiedState.complaintInfoTableData = [];

                    if(cellInfo.row["original"]["complaintCurrentStatus"] != null && cellInfo.row["original"]["complaintCurrentStatus"].length > 0 
                        && cellInfo.row["original"]["complaintCurrentStatus"] == REGISTERED_STATUS
                    ) {
                        setState({...modifiedState});
                        return;
                    } else {

                        stateComplaintInfoTableData = [...modifiedState.complaintInfoTableData]

                        let singleComplaintInfoData = {
                            complaintInfoTbleComplaintID: cellInfo.row["original"]["custComplaintID"],
                            complaintInfoTbleCustEmail: cellInfo.row["original"]["custEmailID"],
                            complaintInfoTbleCustPhone: cellInfo.row["original"]["custPhoneNo"],
                            complaintInfoTblDescription: cellInfo.row["original"]["complaintDescription"],
                            complaintInfoTblComments: cellInfo.row["original"]["comments"],
                            complaintInfoTblUploadedImages: cellInfo.row["original"]["fileName"],

                        };

                        stateComplaintInfoTableData.push(singleComplaintInfoData);
                        modifiedState.complaintInfoTableData = stateComplaintInfoTableData;

                        modifiedState.selectedComplaintCategory = cellInfo.row["original"]["complaintCategory"];
                        modifiedState.selectedCustComplaintID = cellInfo.row["original"]["custComplaintID"];
                        let selectedCurrentComplaintStatus = cellInfo.row["original"]["complaintCurrentStatus"];
                        
                        if(selectedCurrentComplaintStatus == RESOLVED_STATUS) {
                            modifiedState.selectedComplaintResolvedSlnID = cellInfo.row["original"]["custComplaintResolveSolID"] != null && 
                            cellInfo.row["original"]["custComplaintResolveSolID"].length > 0 ? 
                            cellInfo.row["original"]["custComplaintResolveSolID"] : "";
                        } else {
                            modifiedState.selectedComplaintResolvedSlnID = "";
                        }

                        getAllSelectedComplaintSolution(modifiedState);
                    } 
                                    
                },

                style:(cellInfo.row["original"]["complaintCurrentStatus"] != null && cellInfo.row["original"]["complaintCurrentStatus"].length > 0 
                    && cellInfo.row["original"]["complaintCurrentStatus"] != REGISTERED_STATUS) && 
                {
                    cursor: 'pointer',
                    background: cellInfo.row.index == modifiedState.selectedRowIndex ? '#6c95ba' : '',
                    color: cellInfo.row.index == modifiedState.selectedRowIndex ? 'white' : 'black',
                    alignItems: "center"
                }
            }
            
        } else if(cellInfo.row != undefined && cellInfo.column != undefined && cellInfo.column["id"] == "fileName") {
            let modifiedState = state;
            return {
                style:(cellInfo.row["original"]["complaintCurrentStatus"] != null && cellInfo.row["original"]["complaintCurrentStatus"].length > 0 
                    && cellInfo.row["original"]["complaintCurrentStatus"] != REGISTERED_STATUS) && 
                {
                    background: cellInfo.row.index == modifiedState.selectedRowIndex ? '#6c95ba' : '',
                    color: cellInfo.row.index == modifiedState.selectedRowIndex ? 'white' : 'black',
                    alignItems: "center"
                }
            }
        } else {
            return {}
        }
    }

    // Function will highlight the selected row of React Table and also set selected row index no in State to further use.
    const onSlnTableRowClick = (cellInfo) => {

        if (cellInfo.row != undefined && cellInfo.column != undefined) {
            let modifiedState = state;
            if( modifiedState.invokedFrom == "ResolveComplaint") {
                return {
                    onClick: (e, handleOriginal) => {

                        modifiedState.slnTbleSelectedRowIndex = cellInfo.row.index;

                        modifiedState.selectedSlnComplaintID = cellInfo.row.original.slnTblCustComplaintID;
                        modifiedState.selectedSlnSolutionID = cellInfo.row.original.custComplaintSolID;
                        // modifiedState.selectedComplaintCategory = rowInfo.original.custComplaintSolID;
                        modifiedState.errors.requiredFiledErr = "";

                        setState({...modifiedState});
                                        
                    },
                    style: {
                        cursor: 'pointer',
                        background: cellInfo.row.index == state.slnTbleSelectedRowIndex ? '#6c95ba' : '',
                        color: cellInfo.row.index == state.slnTbleSelectedRowIndex ? 'white' : 'black',
                        alignItems: "center"
                    }
                }
            } else {
                return {}
            }
            
        } else {
            return {}
        }
    }

    const toggleComplaintSnapshotDetails = (e) => {
        setState({...state,
          toShowComplaintSnapshotModalOpen: !state.toShowComplaintSnapshotModalOpen
        });
      }

    return (
        <div>
            <div className="row justify-content-center" >
                <div className="col-lg-12 col-md-12 col-sm-12" style={{margin: "1rem"}}>
                    <div className="modal-body box" style={{paddingTop: "1.3rem", paddingBottom: "1.5rem", margin: "0rem", paddingLeft: "1.5rem", paddingRight: "1.5rem"}}>
                        <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "0rem"}}>
                            {(state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == "ResolveComplaint") ?
                                "Resolve/Reopen Complaint" :
                                "Complaint Details"
                            }
                        </div>
                        <div style={{display: state.invokedFrom == "ResolveComplaint" ? "none" : "block", marginBottom:"1rem", marginTop: "0.2rem"}}>
                            <div className="startAndEnddate" style = {{justifyContent: "space-evenly"}}>
                                <div>
                                    <label style={{fontSize:"0.9rem"}}>
                                        Start Date:
                                    </label>
                                    <DateTimePicker
                                        clearIcon={null}
                                        onChange={onChangeStartDate}
                                        format={"yyyy/MM/dd"} 
                                        value={state.startDate} 
                                        name="startDateTime"
                                        timeFormat={false}
                                    />                                
                                </div>
                                <div>
                                    <label style={{fontSize:"0.9rem"}}>
                                        &nbsp;End Date:
                                    </label>
                                    <DateTimePicker
                                        clearIcon={null}
                                        onChange={onChangeEndDate}
                                        format={"yyyy/MM/dd"} 
                                        value={state.endDate} 
                                        name="endDateTime"
                                        timeFormat={false}
                                    />           
                                </div>
                            </div>
                            {state.errors.timeRelatedErr != null && state.errors.timeRelatedErr.length > 0 && 
                                <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center"}} className='error'>{state.errors.timeRelatedErr}</p> 
                            }       

                            <div style = {{ display: state.invokedFrom == "ResolveComplaint" ? "none" : "block"}}>
                                <div style={{marginTop: "0.5rem", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "0.5rem"}}>
                                    <div className="selectedModelLable"
                                    >
                                        Complaint Status:
                                    </div>
                                    <div style={{marginLeft:"1%"}}>
                                        <select className="ProdInfoInput"
                                                style={{width:"100%", background:"white", height:"2rem"}} 
                                                required
                                                value={state.selectedComplaintStatus}
                                                onChange={onComplaintStatusChange}
                                        >
                                            <option value="" disabled >Select Complaint Status...</option> 
                                            {(ARR_COMPLAINT_STATUS).map((singleComplaintStatus, index) => <option key={index} value={singleComplaintStatus}>{singleComplaintStatus}</option>)}

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{display:"flex"}}>
                                    <button type="button"
                                            onClick = {onClickProceed}
                                            style={{borderRadius: "5px", width:"30%", marginTop:"1rem",
                                                    backgroundColor: "var(--primaryColor)", color:"white",
                                                    marginLeft:"15%", 
                                                    fontWeight:"700", fontSize: "0.9rem", padding: "0.2rem"
                                                }}
                                            className="btn-sm"
                                    >
                                        {/* {state.bChngeBtnName? "Refresh" : "View"} */}
                                        Refresh
                                    </button>
                                    <button type="button"
                                            name = "download"
                                            onClick = {onClickDownloadDataAsCsvFile}
                                            style={{
                                                    borderRadius: "5px", width:"35%", marginTop:"1rem",
                                                    backgroundColor: "var(--primaryColor)", color:"white",
                                                    marginLeft:"12%", fontWeight:"700", fontSize: "0.9rem", padding: "0.2rem"
                                                }}
                                            className="btn-sm"
                                    >
                                        Download Summary
                                    </button>
                                </div>
                                {state.errors.others.length > 0 && 
                                    <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center", marginTop: "0.2rem"}} className='error'>{state.errors.others}</p> 
                                }
                            </div>
                        </div>

                        {state.custComlaintTableData != null && state.custComlaintTableData.length > 0 ?
                            // <div style={{border: "1px solid black", textAlign:"center"}}>
                            <div className="border border-3 ReactTableStyle">
                                <ReactTable
                                    data={state.custComlaintTableData}
                                    columns={state.custComlaintTableColumn}
                                    renderBasedOnTable = {"custComlaintTable"}
                                    getCellProps = {onComplaintTblRowClick}
                                    // as React Table is outsdde the main function, state varaibles cant be accessed there 
                                    // so passing all the state variables on which react table is dependent through passedStateVariable
                                    passedStateVariables = {[state.invokedFrom, props.invokedFrom, state.slnTbleSelectedRowIndex]}
                                /> 
                            </div>
                        :
                            null
                        }

                        {state.errors.others != null && state.errors.others.length > 0 && 
                            state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == "ResolveComplaint" &&
                            <p className='addCustError' style={{textAlign: "center"}}>{state.errors.others}</p>
                        }

                        <Modal size="xl" isOpen={state.showModalForSelectedComplaintSolution} toggle={toggleShowModalForSelectedComplaintSolution} backdrop={state.backdrop}>
                            <ModalHeader toggle={toggleShowModalForSelectedComplaintSolution}> 
                                {state.invokedFrom != null && state.invokedFrom.length > 0 && props.invokedFrom == "ResolveComplaint" ?
                                    (state.selectedComplaintSlnTableData != null && state.selectedComplaintSlnTableData.length > 1) ?
                                    `Reject Solution or Resolve Complaint Based on Provided Complaint Solutions.` :
                                    `Re Open or Resolve Complaint Based on Provided Complaint Solutions.` :
                                    `Provided Solutions for Selected Complaint.`}
                            </ModalHeader>
                            <ModalBody>
                                {/* <div style = {{border:" 1px solid rgba(0,0,0,.1)"}} > */}
                                <div className="border border-3 ReactTableStyle">
                                    <ReactTable
                                        data={state.complaintInfoTableData}
                                        columns={state.ComplaintInfoTableColumn}
                                        renderBasedOnTable = {"ComplaintInfoTable"}
                                        getCellProps = {(cellInfo) => ({})}
                                        // as React Table is outsdde the main function, state varaibles cant be accessed there 
                                        // so passing all the state variables on which react table is dependent through passedStateVariable
                                        passedStateVariables = {[state.invokedFrom, props.invokedFrom, state.slnTbleSelectedRowIndex]}
                                    /> 
                                </div>

                                { state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == "ComplaintDetails" ?
                                    // <div style={{color:"green", marginTop:"0.7rem", fontSize:"0.8rem"}}>
                                    <div style={{border:"1px solid var(--primaryColor)", color:"green", fontSize:"0.8rem", paddingLeft: "0.5rem", paddingRight: "0.5rem", backgroundColor:"rgb(168,204,168,0.3)", borderRadius:"0.3rem", marginTop:"0.7rem"}}>
                                        {(state.selectedComplaintResolvedSlnID != null && 
                                            state.selectedComplaintResolvedSlnID.length > 0 ) ?
                                            <div>
                                                <b>Note:</b> 
                                                <ul style = {{marginBottom: "0.2rem"}}>
                                                    <li>
                                                    If the solution state is "Resolved", it indicates that the particular solution is used for resolving the complaint.
                                                    </li>
                                                </ul>
                                            </div>
                                        :
                                            <div>
                                                <b>Note:</b> 
                                                <ul style = {{marginBottom: "0.2rem"}}>
                                                    <li>
                                                        If the solution state is "Rejected", 
                                                        it indicates that the particular solution is rejected and is no longer useful for resolving the complaint.
                                                    </li>
                                                    <li>
                                                        If the solution state is "In Process", 
                                                        it indicates that the particular solution is under consideration and it can be used to resolve the complaint or the solution can be rejected.
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                :
                                    null}

                                {/* <div style={{marginTop:"0.7rem", border: "1px solid black", textAlign:"center"}}> */}
                                <div className="border border-3 ReactTableStyle" style ={{marginTop:"2rem"}}>
                                     <ReactTable
                                        data={state.selectedComplaintSlnTableData}
                                        columns={state.selectedComplaintSlnTableColumn}
                                        renderBasedOnTable = {"selectedComplaintSlnTable"}
                                        getCellProps = {onSlnTableRowClick}
                                        // as React Table is outsdde the main function, state varaibles cant be accessed there 
                                        // so passing all the state variables on which react table is dependent through passedStateVariable
                                        passedStateVariables = {[state.invokedFrom, props.invokedFrom, state.slnTbleSelectedRowIndex]}
                                    /> 
                                </div>

                                {state.errors.others != null && state.errors.others.length > 0 && 
                                    state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == "ComplaintDetails" &&
                                    <p className='addCustError' style={{textAlign: "center"}}>{state.errors.others}</p>
                                }

                                {(state.invokedFrom != null && state.invokedFrom.length > 0 && state.invokedFrom == "ResolveComplaint") ?
                                    <div>
                                        <div className="trackRadioDiv" style={{marginTop: "1rem", marginBottom: "0.3rem"}}>
                                            <div className = "trackradioBox" style={{padding: "0.5rem"}}>
                                                
                                                {state.selectedComplaintSlnTableData != null && state.selectedComplaintSlnTableData.length > 1 ? 
                                                    <div>
                                                        <input type='radio' name='actionTakenBy'
                                                            id = "RejectSolution"
                                                            value= "RejectSolution"
                                                            checked = {state.takeActionBasedOn == "RejectSolution"}
                                                            onChange={onTakeActionBasedOnRadioBtnChange} noValidate 
                                                        /> 
                                                        <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Reject The Selected Solution</span>
                                                    </div>
                                                :
                                                    <div>
                                                        <input type='radio' name='actionTakenBy'  
                                                            id = "ReopenComplaint" 
                                                            value= "ReopenComplaint"
                                                            checked = {state.takeActionBasedOn == "ReopenComplaint"}
                                                            onChange={onTakeActionBasedOnRadioBtnChange} noValidate 
                                                        />
                                                        <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Re Open Complaint</span>
                                                    </div>
                                                }

                                                <div>
                                                    <input type='radio' name='actionTakenBy'  
                                                        id = "ResolveComplaint" 
                                                        value= "ResolveComplaint"
                                                        checked = {state.takeActionBasedOn == "ResolveComplaint"}
                                                        onChange={onTakeActionBasedOnRadioBtnChange} noValidate 
                                                    />
                                                    <span style={{marginLeft: "0.3rem"}}>Resolve Complaint</span>
                                                </div>
                                            </div>
                                        </div>

                                        {state.takeActionBasedOn != null && state.takeActionBasedOn.length > 0 && 
                                            (state.takeActionBasedOn == "ResolveComplaint" || 
                                            state.takeActionBasedOn == "ReopenComplaint")     
                                        ?
                                            <div className="inputgroupCustom">
                                                <label className="reg-form-label">Add Remark:</label>
                                                <textarea type='text' className="LabelComment" value={state.addedComment} 
                                                onChange={onEnteredNewComment}/>  
                                            </div>
                                        : 
                                        null
                                        }

                                        <div style={{marginTop: "1.5rem"}}>
                                            {state.errors.requiredFiledErr != null  && state.errors.requiredFiledErr.length > 0 && 
                                                <p className='addCustError' style={{textAlign: "center"}}>{state.errors.requiredFiledErr}</p>}

                                            {state.errors.others != null && state.errors.others.length > 0 && 
                                                <p className='addCustError' style={{textAlign: "center"}}>{state.errors.others}</p>}

                                            <div style={{display: "flex", justifyContent: "center"}}>
                                            <button type="button"
                                                    onClick = {() => rejectReopenOrResolveComplaintSolution()}
                                                    style={{borderRadius: "5px", width:"20%", fontWeight:"700",
                                                            backgroundColor: "var(--primaryColor)", color:"white",
                                                        }}
                                                    className="btn-sm"
                                            >
                                                Submit
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                : 
                                    null
                                }

                            </ModalBody>
                        </Modal>
                        <Modal size="xl" scrollable={true} isOpen={state.toShowComplaintSnapshotModalOpen} toggle={toggleComplaintSnapshotDetails} backdrop={state.backdrop}>
                            <ModalHeader toggle={toggleComplaintSnapshotDetails}>
                                Complaint Snapshot - [{state.singleFileName}]
                            </ModalHeader> 
                            <ModalBody>
                                <div>
                                <div style={{textAlign:"center"}}>					
                                    <img style={{maxHeight:"50rem", maxWidth:"50rem"}} src={state.signedUrl}/>
                                </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VcComplaintDetailsAndResolveComplaint;
