import { createSlice } from "@reduxjs/toolkit";

const treeNodeList = createSlice({
    name: "Tree Node List",
    initialState: {
        nodeList: []
    },
    reducers: {
        appendTreeNodes: (state, action) => {
            state.nodeList.push(action.payload)
        },

        clearTreeNodeList: (state) => {
            state.nodeList = []
        }
    }
});

export const { appendTreeNodes, clearTreeNodeList } = treeNodeList.actions;

export default treeNodeList.reducer;
