import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel';
import { AppRelevantDataContext } from '../../AppContext';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { ITM_STATE_READY_FOR_SHIPMENT, ITM_STATE_FAULTY, ITM_STATE_DEVC_UPGRADE, ITM_STATE_MAPPED_TO_OWNER, ITM_STATE_MAPPED_AS_REPLACEMENT, DEVICE_RP_USER_ID} from '../../VcConstants';
import { convertUTCDateToStrYYYYMMDDHH24MMSS, trimStringAndRemoveTrailingComma } from '../../vtUtil';
// import {QrReader} from '@otterscan/react-qr-reader'
import {QrScanner} from '@yudiel/react-qr-scanner';
import { FaQrcode } from 'react-icons/fa'
import '../CSS/VcDevcReplacement.css';

export class VcDevcReplacement extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            LoggedInUserID: "",
            originalDeviceID: "",
            replacementDeviceID:"",
            replacementComment: "",
            // showArrows: true,
            selectedPosition: 0,
            orgDevcQRCodeFlag: false,
            rplcDevcQRCodeFlag: false,
            isReplacementDeviceID: false,
            rsonToRplcDevc: ITM_STATE_FAULTY,
            ArrrsonToRplcDevc: [ITM_STATE_FAULTY, ITM_STATE_DEVC_UPGRADE],
            errors: {
                others:"",
            }
        }
    }

    onChangeOrgDevcID = (e) => {
        let modifiedState = this.state;
        modifiedState.originalDeviceID = e.target.value;
        modifiedState.selectedPosition = 1;
        // modifiedState.errors.others = "";
        this.setState(modifiedState);
    }

    onChangeRplcDevcID = (e) => {
        let modifiedState = this.state;
        modifiedState.replacementDeviceID = e.target.value;
        modifiedState.selectedPosition = 1;
        // modifiedState.errors.others = "";
        this.setState(modifiedState);
    }

    onChangersonToRplcDevc = (e) => {
        let modifiedState = this.state;
        modifiedState.rsonToRplcDevc = e.target.value;
        modifiedState.selectedPosition = 1;
        modifiedState.errors.others = "";
        this.setState(modifiedState);
    }

    openQRCameraForOrgDevc = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 

        this.setState(prevState => {
            let modifiedState = prevState;

            modifiedState.orgDevcQRCodeFlag = !modifiedState.orgDevcQRCodeFlag;
            modifiedState.rplcDevcQRCodeFlag = false;
            modifiedState.originalDeviceID = "";
            modifiedState.errors.others = "";
            modifiedState.selectedPosition = 1;
            return modifiedState
        })
    }

    openQRCameraForRplcDevc = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 

        this.setState(prevState => {
            let modifiedState = prevState;

            modifiedState.rplcDevcQRCodeFlag = !modifiedState.rplcDevcQRCodeFlag;
            modifiedState.orgDevcQRCodeFlag = false;
            modifiedState.replacementDeviceID = "";
            modifiedState.errors.others = "";
            modifiedState.selectedPosition = 1;
            return modifiedState
        })
    }

    handleScanResultOfOrgDevc = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.originalDeviceID = data;
            if((modifiedState.originalDeviceID != null && modifiedState.originalDeviceID.length > 0)) {
                modifiedState.orgDevcQRCodeFlag = false;
            } 
            modifiedState.isReplacementDeviceID = false;
            this.verifyEnteredDeviceID(modifiedState);

            // else {
            //     modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
            //     this.setState(modifiedState);
            // }
        }
      }

    handleScanResultOfRplcDevc = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.replacementDeviceID = data;
            if((modifiedState.replacementDeviceID != null && modifiedState.replacementDeviceID.length > 0)) {
                modifiedState.rplcDevcQRCodeFlag = false;
            } 
            modifiedState.isReplacementDeviceID = true;
            this.verifyEnteredDeviceID(modifiedState);

            // else {
            //     modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
            //     this.setState(modifiedState);
            // }
        }
    }

    handleKeyDownOrgDevcID = (e) => {
        if (e.key == 'Enter') {
            let modifiedState = this.state;
            modifiedState.isReplacementDeviceID = false;
            this.verifyEnteredDeviceID(modifiedState);
        }
    }
    
    handleKeyDownRplcDevcID = (e) => {
        if (e.key == 'Enter') {
            let modifiedState = this.state;
            modifiedState.isReplacementDeviceID = true;
            this.verifyEnteredDeviceID(modifiedState);
        }
    }

    verifyEnteredDeviceID = ( inModifiedState = null ) => {
        
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        if( ( modifiedState.isReplacementDeviceID == false &&  
              (modifiedState.originalDeviceID == null || modifiedState.originalDeviceID.length <=0) 
            ) ||
            ( modifiedState.isReplacementDeviceID == true &&  
                (modifiedState.replacementDeviceID == null || modifiedState.replacementDeviceID.length <=0) 
            )
        ) {
            modifiedState.selectedPosition = 1;
            // modifiedState.showArrows = false;
            this.setState(modifiedState);
            return;
        }

        let jsonParams = {
            EnteredDeviceID: modifiedState.isReplacementDeviceID == true ? modifiedState.replacementDeviceID : modifiedState.originalDeviceID
        }

        axios.post(`${getAPIHostURL()}/wclient/checkDeviceIdExistence`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data.isDeviceIDExist == null) {
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                    modifiedState.selectedPosition = 1;
                    // modifiedState.showArrows = false;
                    this.setState(modifiedState);

                } else {
                    if(response.data.isDeviceIDExist == 0) {
                        modifiedState.errors.others = "Invalid Device ID.";
                        modifiedState.selectedPosition = 1;
                        // modifiedState.showArrows = false;
                        this.setState(modifiedState);                     
                    } else {
                        if(modifiedState.isReplacementDeviceID == false) {

                            if(modifiedState.originalDeviceID.length > 0 && modifiedState.replacementDeviceID.length > 0 && modifiedState.rsonToRplcDevc.length > 0) {
                                // modifiedState.selectedPosition = 2;
                                // modifiedState.showArrows = true;
                                modifiedState.errors.others = "";

                            } else {
                                modifiedState.selectedPosition = 1;
                                // modifiedState.showArrows = false;
                            }

                            this.setState(modifiedState);
                        } else {
                            this.checkCurrentStOfProdItem(modifiedState);
                        }
                    }
                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
                this.setState(modifiedState);
            }

        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    checkCurrentStOfProdItem = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let jsonParams = {
            DeviceID: modifiedState.replacementDeviceID
        }

        axios.post(`${getAPIHostURL()}/wclient/checkProdItemCurrentSt`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if(response.data.currentStInfo == null || response.data.currentStInfo.length <=0 ) {
                    modifiedState.errors.others = "Product item current state information is missing.";
                    modifiedState.selectedPosition = 1;
                    // modifiedState.showArrows = false;
                } else {
                    
                    if(response.data.currentStInfo[0]["State"] == ITM_STATE_READY_FOR_SHIPMENT) {

                        if(modifiedState.originalDeviceID.length > 0 && modifiedState.replacementDeviceID.length > 0 && modifiedState.rsonToRplcDevc.length > 0) {
                            modifiedState.selectedPosition = 2;
                            // modifiedState.showArrows = true;
                            modifiedState.errors.others = "";

                        } else {
                            modifiedState.selectedPosition = 1;
                            // modifiedState.showArrows = false;
                        }

                    } else if((response.data.currentStInfo[0]["State"] == ITM_STATE_MAPPED_TO_OWNER || response.data.currentStInfo[0]["State"] == ITM_STATE_MAPPED_AS_REPLACEMENT) && 
                        response.data.currentStInfo[0]["DeviceOwnerEmailID"] == DEVICE_RP_USER_ID) {
                        
                        if(modifiedState.originalDeviceID.length > 0 && modifiedState.replacementDeviceID.length > 0 && modifiedState.rsonToRplcDevc.length > 0) {
                            modifiedState.selectedPosition = 2;
                            // modifiedState.showArrows = true;
                            modifiedState.errors.others = "";

                        } else {
                            modifiedState.selectedPosition = 1;
                            // modifiedState.showArrows = false;
                        }
                    } else {
                        modifiedState.errors.others = `The scanned Product Item cannot be used to replace the original Device. 
                                                        \nThe current state of the item is '${response.data.currentStInfo[0]["State"]}' whereas it needs to be in 'Ready For Shipment' state or 
                                                        it must be a combination of "Mapped To Owner" state or "Mapped as Replacement" state and device owner should be "device.rp@viliso.in".`;
                        
                        modifiedState.selectedPosition = 1;
                        // modifiedState.showArrows = false;
                    }
                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);

        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    replaceDevc = () => {

        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;
        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let replacementComment = trimStringAndRemoveTrailingComma(modifiedState.replacementComment)

        if(modifiedState.originalDeviceID.length <=0 ||
            modifiedState.replacementDeviceID.length <=0
        ) {
            alert(`Please enter DeviceID`);
            modifiedState.selectedPosition = 1;
            this.setState(modifiedState);
            return; 
        } else if(replacementComment.length <= 0) {
            alert(`Please enter Remark`);
            modifiedState.selectedPosition = 1;
            this.setState(modifiedState);
            return; 
        }

        if(modifiedState.errors.others.length > 0 ) {
            return;
        }

        let jsonParams = {  
            OrgDevcID: modifiedState.originalDeviceID,
            RplcDevcID: modifiedState.replacementDeviceID,
            RplcDtTm: convertUTCDateToStrYYYYMMDDHH24MMSS(new Date()),
            LoggedInUserID: modifiedState.LoggedInUserID,
            RsonToRplcDevc: modifiedState.rsonToRplcDevc,
            Comment: trimStringAndRemoveTrailingComma(modifiedState.replacementComment)
        }

        axios.post(`${getAPIHostURL()}/wclient/replaceDevice`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                modifiedState.selectedPosition = 0;
                modifiedState.originalDeviceID = "";
                modifiedState.replacementDeviceID = "";
                modifiedState.replacementComment = "";
                modifiedState.errors.others = "";

                alert(`Successfully replaced device.`);

            } else {
                modifiedState.selectedPosition = 4;
                modifiedState.errors.others = "";

                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }  else if (response.data.code == 'ERR_ONE_OF_DEVC_ID_IS_INVALID_OR_ELSE_BOTH') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Invalid DeviceID.';
                    alert(modifiedState.errors.others);
                    modifiedState.selectedPosition = 1;
                }  else if (response.data.code == 'ERR_ONE_OF_DEVC_IS_NOT_YET_REGISTERED_OR_ELSE_BOTH') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Device is not yet registered.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    onChangeComment = (e) => {
        let modifiedState = this.state;
        modifiedState.replacementComment = e.target.value;
        modifiedState.selectedPosition = 1;
        this.setState(modifiedState);
    }

    handleError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    onChangeCarousel = () => {
        
        let modifiedState = this.state;
        // console.log(modifiedState.selectedPosition);
        // if(this.state.selectedPosition == 1 && (this.state.replacementDeviceID == null || this.state.replacementDeviceID.length <= 0 || this.state.originalDeviceID == null || this.state.originalDeviceID.length <= 0)) {
        //     console.log(modifiedState.selectedPosition);

        //     this.setState( 
        //         prevState => {
        //             let modifiedState = prevState;
    
        //             let errors = modifiedState.errors;
        //             errors.others = "Please Enter DeviceID.";
        //             modifiedState.selectedPosition = 1;
    
        //             return modifiedState;
        //         }
        //     );
        //     return;

        // }

        // modifiedState.orgDevcQRCodeFlag = false;
        // modifiedState.rplcDevcQRCodeFlag = false;

        if(modifiedState.selectedPosition == 1 && modifiedState.replacementDeviceID != null && modifiedState.replacementDeviceID.length > 0 
            && modifiedState.originalDeviceID != null && modifiedState.originalDeviceID.length > 0) 
        {
            this.checkCurrentStOfProdItem();
        }

        // this.setState(modifiedState);
    }
    
    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        return (
            <div>
                <div className = "customerTableHeading" style={{marginTop: "0.2rem"}}>Device Replacement</div> 
                <Carousel 
                        // showArrows={this.state.showArrows}
                          showThumbs={false}
                          selectedItem={this.state.selectedPosition}
                          swipeable= {false}
                          showIndicators= {this.state.showArrows}
                          onChange={this.onChangeCarousel} 
                        //   onClickItem={this.onClickItem} 
                        //   onClickThumb={this.onClickThumb}
                >
                    <div className="drFirstmodalslide" key={0}>
                        <div className="container centered">
                            <p style={{fontSize: "1.2rem", color: "white"}}>Power off the current Device which is being replaced and remove it from its current position</p>
                        </div>
                    </div>
                    <div className="drFourthmodalslide" key={1} style={{overflow:"auto"}}>
                        <div className="container" >
                            <div>
                                <label className="DeviceIdLabel">Reason To Replace Device:<span className="addCustRequiredMarkStar">*</span></label>
                                <select 
                                        className="DeviceIdInput"
                                        value={this.state.rsonToRplcDevc}
                                        onChange={this.onChangersonToRplcDevc}
                            >
                                    <option value="" disabled select= "true">Select...</option>
                                    {(this.state.ArrrsonToRplcDevc).map((singleReason,index) => <option key={index} value={singleReason}>{singleReason}</option>)}
                                </select>
                            </div>
                            <div>
                                <label className="DeviceIdLabel">Remark:<span className="addCustRequiredMarkStar">*</span></label>
                                <textarea className="DeviceIdInput"
                                            value={this.state.replacementComment}
                                            onChange={this.onChangeComment}
                                            required
                                />
                            </div>
                            <div>
                                <label className="DeviceIdLabel">Scan the Old Device ID:<span className="addCustRequiredMarkStar">*</span></label>
                                <input className="DeviceIdInput"
                                        value={this.state.originalDeviceID}
                                        onChange={this.onChangeOrgDevcID}
                                        onKeyDown={this.handleKeyDownOrgDevcID}
                                />
                                <label id="child-element" onClick={this.openQRCameraForOrgDevc} className = "qrcodeTextBtn">
                                    <FaQrcode className = "qrcodeTextBtnIcon"/>
                                </label>
                                
                                <div>
                                    {(this.state.orgDevcQRCodeFlag == true)
                                    ?
                                        // <div style={{display: "flex", justifyContent: "center"}}>
                                        //     <QrReader
                                        //         scanDelay={300}
                                        //         onResult={(result, error) => {
                                        //             if (!!result) {
                                        //                 console.log("result = ",result);
                                        //                 this.handleScanResultOfOrgDevc(result?.text);
                                        //             }
                                        //         }}
                                        //         className= "QRCodeCamBox"
                                        //     />
                                        // </div>
                                            <div style={{display: "flex", justifyContent: "center", width: "30%", height: "80%",
                                                        marginTop:"1rem", marginBottom: "1rem", marginLeft: "auto",
                                                        marginRight: "auto"}}>                                           
                                            <QrScanner
                                                scanDelay={300}
                                                onResult={(result, error) => {
                                                    if (!!result) {
                                                        this.handleScanResultOfOrgDevc(result?.text);
                                                    }
                                                }}
                                                className= "QRCodeCamBox"
                                            />
                                        </div>
                                    :
                                        <div/>
                                    }
                                </div>
                            </div>
                            <div>
                                <label className="DeviceIdLabel">Scan the New Device ID:<span className="addCustRequiredMarkStar">*</span></label>
                                <input className="DeviceIdInput" 
                                        value={this.state.replacementDeviceID}
                                        onChange={this.onChangeRplcDevcID}
                                        onKeyDown={this.handleKeyDownRplcDevcID}
                                />
                                    <label onClick= {this.openQRCameraForRplcDevc} className = "qrcodeTextBtn">
                                    <FaQrcode className = "qrcodeTextBtnIcon"/>
                                </label>
                                <div>
                                    {(this.state.rplcDevcQRCodeFlag == true)
                                    ?
                                        // <div style={{display: "flex", justifyContent: "center"}}>
                                        //     <QrReader
                                        //         scanDelay={300}
                                        //         onResult={(result, error) => {
                                        //             if (!!result) {
                                        //                 this.handleScanResultOfRplcDevc(result?.text);
                                        //             }
                                        //         }}
                                        //         className = "QRCodeCamBox"
                                        //     />
                                        // </div>
                                        <div style={{display: "flex", justifyContent: "center", width: "30%", height: "80%",
                                                        marginTop:"1rem", marginBottom: "1rem", marginLeft: "auto",
                                                        marginRight: "auto"}}>                                            
                                            <QrScanner
                                                scanDelay={300}
                                                onResult={(result, error) => {
                                                    if (!!result) {
                                                        this.handleScanResultOfRplcDevc(result?.text);
                                                    }
                                                }}
                                                className = "QRCodeCamBox"
                                            />
                                        </div>
                                    :
                                        <div/>
                                    }
                                </div>

                            </div>
                            
                            {this.state.errors.others.length > 0 && 
                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", marginTop:"0.4rem"}} className='error'>{this.state.errors.others}</p>}  
                        </div>
                    </div>
                    <div className="drSecondmodalslide" key={2}>
                        <div className="container centered">
                            <p>
                            <ul>
                                <li style={{fontSize: "1.2rem", color: "white"}}>Turn <b>ON</b> your Mobile Phone Or Laptop's <b>Wi-Fi</b> and connect To <b>Wi-Fi Network</b> named <b>'HHM_xxxxxxx'</b>.</li>
                                {/* <li style={{fontSize: "1.2rem", color: "white", textAlign: "start"}}>Enter WiFi Password: <b>00000000</b></li> */}
                                <li style={{fontSize: "1.2rem", color: "white", textAlign: "start"}}>Ensure that the selected network is connected.</li>
                            </ul>
                            </p>
                        </div>
                    </div>
                    <div className="drThirdmodalslide" key={3}>
                        <div className="container centered">
                            <p className="thirdSliderHeader">Click the link below and follow the given instructions.</p>
                            <p className="thirdSlideList">
                                <ul>
                                <li className="thirdSliderSubComp"><b>Select Wi-Fi</b>: Device will Search for available <b>Wi-Fi's</b>. Please select desired <b>Wi-Fi Name</b> from the List appearing after <br></br>clicking on Drop Down arrow.</li>
                                <li className="thirdSliderSubComp"><b>Wi-Fi Password</b>: Enter <b>Password</b> of selected Wi-Fi.</li>
                                <li className="thirdSliderSubComp"><b>Device Name</b>: <b>'Replacement'</b></li>
                                <li className="thirdSliderSubComp"><b>Email ID</b>: <b>'device.rp@viliso.in'</b></li>
                                <li className="thirdSliderSubComp"><b>Re-enter Email Id</b>: Enter exact same Email Id as above.</li>
                                <li className="thirdSliderSubComp"><b>Pincode</b>: 123456</li>
                                <li className="thirdSliderSubComp"><b>Select Room Type</b>: 'Hall'</li>
                                <li className="thirdSliderSubComp">Click on <b>SAVE</b> button.</li>
                                </ul>
                            </p>
                            <p style={{textAlign:"center"}}><a href="http://192.168.4.1" className = "textDecoNone" target="_blank">Click here to setup your Replacement Device.</a></p>
                        </div>
                    </div>
                    <div className="drFourthmodalslide" key={4}>
                        <div className="container">
                            <p className="thirdSliderHeader">Make sure Device WiFi has been setup (Indicative LED for Replacement Device turns Green).</p>
                            <div>
                                <label className="DeviceIdLabel">Original Device ID:</label>
                                <input className="DeviceIdInput"
                                        style={{background:"lightGray"}}
                                        value={this.state.originalDeviceID}
                                        readOnly
                                />
                            </div>
                            <div>
                                <label className="DeviceIdLabel">Replacement Device ID:</label>
                                <input className="DeviceIdInput"
                                        style={{background:"lightGray"}}
                                        readOnly
                                        value={this.state.replacementDeviceID}
                                />
                            </div>
                            <div>
                                <button type="button"
                                        className="btn-sm replacementPage"
                                        onClick = {this.replaceDevc}
                                >
                                    Save New Device as Replacement for Old Device
                                </button>
                            </div>
                            {this.state.errors.others.length > 0 && 
                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", marginTop:"0.4rem"}} className='error'>{this.state.errors.others}</p>}  
                        </div>
                    </div>
                </Carousel>
            </div>
        )
    }
}

VcDevcReplacement.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods
export default VcDevcReplacement;
