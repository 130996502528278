import React, { useState, useContext, useEffect } from 'react';
import { AppRelevantDataContext } from '../../AppContext';
import App from '../../App';
import '@studysync/react-splitter-layout/lib/index.css';
import VcNotificationSetting from './VcNotificationSetting';
import { IDS_InfoMsgForTreeNodeWhileChildDevicesMissingInSettingPg, IDS_InfoMsgForTreeNodeWhileChildNodesMissingInSettingPg, IDS_SettingSelectDevc } from '../../VcLanguage';
import {useNavigate} from 'react-router-dom';

function VcSettings (props) {

  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();

  useEffect(() => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.
      // Try to get this value if present in LocalStorage and update the app state (so that
      // re-render will happen). If app context data not present in LocalStorage then
      // show the Login Page
      if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
        // App Relevant Data Context not present in LocalStorage as well.
        navigate('/', { replace: true });
      } else {
        // App Relevant Data Context loaded from LocalStorage at AppLeval.
        // Re-render will happen automatically (no action required).
      }

    }
  }, []);
        
  let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext
  let t = appRelevantDataContextValue.t;


  if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.

      // Next re-render will happen automatically when the AppState is updated again 
      // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
      return App.renderLoadingPage();
  }

  let HasSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.hasDevc;
  let IsSelectedNodeDevices = appRelevantDataContextValue.selectedNodeInfo.isDevc;
  let isContainsChildNode = appRelevantDataContextValue.selectedNodeInfo.containsChildNode;
  let selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;

  return (
    !IsSelectedNodeDevices && (!isContainsChildNode || isContainsChildNode)) ?
         <div className=" sddSingleParam" >
          <div className="parameterOuterDiv ">
              {t(IDS_SettingSelectDevc)} 
          </div>
        </div>
        : 
        <div className=' flex-center'>
          <div className='w-100'>
          <VcNotificationSetting fullPath={props.fullPath}/>
          </div>
        </div>
      
}

export default VcSettings;

