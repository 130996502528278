import React, { Component } from 'react'
import VcFAQContent from './VcFAQContent';
import { AppRelevantDataContext } from '../../AppContext';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { IDS_LoginServerIssue, IDS_RegistNetworkError } from '../../VcLanguage';

class VcFAQ extends Component {
    state = {
      LoggedInUserID: this.props.LoggedInUserID,
      UserFirstName: this.props.UserFirstName,
      UserLastName: this.props.UserLastName,
        FaqArray: [
          // {no:1, question: 'How to add Institution Name to my Devices?', answer:'Go on Devices page, Click on the button next to Device Name.'},
        ],
        errors: {
          others: ''
        },
        showDrawer: false,
      }

      onItemClick = id => {
        this.setState(prev => {
          const { FaqArray } = prev;
          const nexItems = FaqArray.map(item => {
            if (item.id !== id) return item;
            return {
              ...item,
              active: !item.active
            }
          });
          return { ...prev, FaqArray: nexItems };
        });
      }

      componentDidMount() {
        this.getFaq();
      }

      getFaq() {
        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;

        let modifiedState = this.state;
        modifiedState.FaqArray = [];

        axios.post(`${getAPIHostURL()}/wclient/getFaq`)
        .then(response => {
          // console.log(response)

          if(response.data.code == 'SUCCESS') {
            let receivedFaq = response.data.retrivedFaq;

            for(let i=0; i<receivedFaq.length; i++) {
              let faqObject = {};

              faqObject = { 
                id: receivedFaq[i].FaqID,
                no: receivedFaq[i].SeqNo,   
                question: receivedFaq[i].Title,
                answer: receivedFaq[i].Description
              }

              modifiedState.FaqArray.push(faqObject);
            }

          } else {

            if(response.data.code == 'SQL_ERROR') {
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
          }

          this.setState(modifiedState);
        })
        .catch( error => {
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            modifiedState.errors.others = t(IDS_RegistNetworkError);
          }

       })
      }

    render() {
       const { FaqArray, errors } = this.state;

       return   <div className="container">
       <div className="row">
           <div className="container col-lg-10 ">
               <div className="faqbox-main">
                 {/* <h1 className="main-h1">FAQ's</h1> */}
           
                   {
                     FaqArray.map(item => (
                         <VcFAQContent key={item.id} {...item} onClick={this.onItemClick} />
                     ))
                   }
               </div>
           </div>
       </div>
   </div>

       
    }
}

VcFAQ.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcFAQ

