import React, { Component } from 'react'
import '../CSS/Support.css';
import { FaEnvelope, FaBuilding, FaSadCry, FaPhone, FaPhoneAlt} from 'react-icons/fa';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_Contact, IDS_Email, IDS_IndiaLable, IDS_AddressIndia, IDS_ContactNo, IDS_Name, IDS_PhoneNumberErrMsg,
        IDS_SupportForm, IDS_Message, IDS_SUBMIT, IDS_EmailError, IDS_RegistNetworkError, 
        IDS_LoginServerIssue, IDS_AUSrvrIssueReqParamsNotSent, IDS_SuccessMsgAfterSendingEmail, IDS_Phone} from '../../VcLanguage';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

export class VcSupportForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            phoneno:'',
            name:'',
            message: '',
            CountryInfo: [],
            CountryCode: "+91",
            errors: {
              email: '',
              phoneno:'',
              others: ''
            } 
        };
      }

      onSupportSubmit = (e) =>
      {
        e.preventDefault();
        let modifiedState = this.state;

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let t = appRelevantDataContextValue.t;
        modifiedState.errors.others = "";

        if(validEmailRegex.test(this.state.email) === false) {
            modifiedState.errors.others = t(IDS_EmailError);
        } else if( this.state.phoneno == null || this.state.phoneno.length < 4 || this.state.phoneno.length > 12){
            modifiedState.errors.others = t(IDS_PhoneNumberErrMsg);
        } else {

            // Convert email to lowercase 
            modifiedState.email = (modifiedState.email != null && modifiedState.email.length > 0) ? modifiedState.email.toLowerCase() : "" ;

            let allData = {
                  UserEmailID: modifiedState.email,
                  UserName: modifiedState.name, 
                  UserPhoneNo: modifiedState.CountryCode +" " +modifiedState.phoneno, 
                  UserMessage: modifiedState.message, 
                };

            // Show spinner to restrict other user actions to be perform.
            appRelevantDataContextValue.onChangeProcessingReq(true);
            
            axios.post(`${getAPIHostURL()}/wclient/sendUserMessageEmailToSupportTeam`, allData)
            .then(response => {
               // Remove spinner and allow user to perform action.
               appRelevantDataContextValue.onChangeProcessingReq(false);

              if(response.data.code == "SUCCESS") {
                alert(t(IDS_SuccessMsgAfterSendingEmail));

                // To Reset Form values back to Default.
                this.showDefaultUserDetails(appRelevantDataContextValue); 

              } else {
                if(response.data.code == "SQL_ERROR"){
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else if(response.data.code == "REQ_PARAM_MISSING") {
                  modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
                } else if(response.data.code == "SUPPORT_DETAILS_MISSING") {
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
                } else {
                  console.log('Should not reach here');
                  modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
              }
              this.setState(modifiedState);
            })
            .catch(error => {
               // Remove spinner and allow user to perform action.
               appRelevantDataContextValue.onChangeProcessingReq(false);
               
              console.log("Network error:");
              console.log(error);
              if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
              } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
              } 
            });
        }
        
      }

      getAllCountryCodeInfo = () => {

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
    
        let modifiedState = this.state;
    
        axios.post(`${getAPIHostURL()}/wclient/getAllCountryCodeInformation`)
        .then(response => {
          if(response.data.code == "SUCCESS") {
              modifiedState.CountryInfo = [];
              modifiedState.CountryInfo = response.data.CountryInfo;
          } else {
    
            if(response.data.code == "SQL_ERROR"){
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
              console.log('Should not reach here');
              modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
          }
          this.setState(modifiedState);
        })
        .catch(error => {
          console.log("Network error:");
          console.log(error);
          if (axios.isCancel(error)) {
            console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
          } else {
            // Tell the user that there are network issues
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            this.setState(modifiedState);
          } 
        });
      }

      onChangeCountryCode = (e) => {
        let modifiedState = this.state;
        modifiedState.CountryCode = e.target.value;
        modifiedState.errors.others = "";
        this.setState(modifiedState);
      }

	  handleChange = (event) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;

        const { name, value } = event.target;
        let errors = this.state.errors;
        modifiedState.errors.others = "";
      
        switch (name) {
          case 'email': 
            errors.email = 
              validEmailRegex.test(value)
                ? ''
                : t(IDS_EmailError);
            break;         
         case 'phoneno': 
            errors.phoneno = 
            (value.length < 4 || value.length > 12)
                ? t(IDS_PhoneNumberErrMsg) : '';      
            break;
          default:
            break;
        }
      
        this.setState({
          errors, 
          [name]: value,
          [event.target.name]:event.target.value
          }, ()=> {
        })
      }

      componentDidMount() {        
        // this.showDefaultUserDetails(appRelevantDataContextValue);
        this.showDefaultUserDetails();  
        this.getAllCountryCodeInfo();
      }

      showDefaultUserDetails() {
        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;
 
        let email = appRelevantDataContextValue.loggedInUserInfo.userID;

        modifiedState.phoneno = '';
        modifiedState.message = '';
        modifiedState.email = validEmailRegex.test(email) ?  email : '';
        modifiedState.name = appRelevantDataContextValue.loggedInUserInfo.userFirstName + " "+ appRelevantDataContextValue.loggedInUserInfo.userLastName

        this.setState(modifiedState)

      }

    
    render() {
        const{errors} = this.state;

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
            
            const color = {
                color : "var(--errorColor)",
                fontSize:"13px"
            }
        return (
          <div className="container-fluid" style={{display:"flex"}}>
                    <div className="row">
                        <div className="container col-lg-6">
                            <div className="row">
                                    <div className="container col-lg-10">
                                    <div className="contact-box-left">
                                        <div className="contact-form-left">
                                            <div>
                                                <h1 className="head-left">{t(IDS_Contact)}</h1>
                                            </div>

                                    <div className="contact-space1">
                                        <h3 className="sub-head"> <FaEnvelope /> <span className="sub-text">{t(IDS_Email)}</span></h3>
                                        <h4 className="sub-info"><a href="mailto:contact@viliso.in?Subject=Support%20Request." className = "textDecoNone" target="_top">contact@viliso.in</a></h4>
                                    </div>

                                    <div className="contact-space1">
                                        <h3 className="sub-head"> <FaPhoneAlt /> <span className="sub-text">{t(IDS_Phone)}</span></h3>
                                        <h4 className="sub-info"><a href="tel:+91 9819291480?Subject=Support%20Request." className = "textDecoNone" target="_top">(+91) 9819291480</a></h4>
                                    </div>

                                            <div className="contact-space1">
                                                <h3 className="sub-head"> <FaBuilding /> <span className="sub-text">{t(IDS_IndiaLable)}</span></h3>
                                                <h4 className="sub-info">{t(IDS_AddressIndia)}</h4>
                                            </div>

                                        </div>
                                    </div>

                                    </div>
                            </div>
                        </div>

                        <div className="container col-lg-6">
                            <div className="row">
                                <div className="container col-lg-8">

                                    <div className="contact-box rounded-xl bg-white">
                                        <div className="contact-form" >
                                            <form onSubmit={this.onSupportSubmit}>
                                                <h1 className="head-right">{t(IDS_SupportForm)}</h1>
                                            
                                                    <div className="contact-space">
                                                    <label className="contact-form-label">{t(IDS_Email)}:</label>
                                                    <input type='email' name='email' className="input-contact" required="required" 
                                                      value={this.state.email}
                                                      onChange={this.handleChange} noValidate />
                                                          {errors.email.length > 0 && 
                                                          <span style={color} className='error'>{errors.email}</span>} 
                                                    </div>
                                            
                                            
                                                    <div className="contact-space">
                                                        <label className="contact-form-label">{t(IDS_Name)}:</label>
                                                        <input type="text" className="input-contact" name="name" required="required"
                                                          value={this.state.name}
                                                          onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="contact-space">
                                                        <label className="contact-form-label">{t(IDS_ContactNo)}:</label>
                                                        <span style={{display:"flex", justifyContent: "space-around"}}>
                                                        <select className="input-contact"
                                                                            required
                                                                            readOnly = {this.state.formViewMode == "viewMode" ? true : false}
                                                                            value={this.state.CountryCode}
                                                                            onChange={this.onChangeCountryCode}
                                                                            style={this.state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0", width:"4.3rem", marginRight:"0.5rem"} : {color: "", backgroundColor: "", width:"4.3rem", marginRight:"0.5rem"}}
                                                                    >
                                                                    <option value="+91" select= "true">+91</option> 
                                                                    {(this.state.CountryInfo).map((singleCountryCode, index) => 
                                                                    <option key={index} value={singleCountryCode["CountryCode"]}>
                                                                    {singleCountryCode["CountryCode"]}
                                                                    {singleCountryCode["CountryCode"].length == 2 && '\xA0\xA0\xA0\xA0\xA0\xA0'}
                                                                    {singleCountryCode["CountryCode"].length == 3 && '\xA0\xA0\xA0\xA0'}
                                                                    {singleCountryCode["CountryCode"].length == 4 && '\xA0\xA0'}
                                                                    {'\xA0'+singleCountryCode["Country"]}</option>)}
                                                            </select>

                                                            <input type='number' name='phoneno' className="input-contact" required="required"
                                                                    value={this.state.phoneno}
                                                                    onChange={this.handleChange} noValidate />

                                                        </span>
                                                          {errors.phoneno.length > 0 && 
                                                              <span style={color} className='error'>{errors.phoneno}</span>} 
                                                    </div>
                                            
                                                    <div className="contact-space">
                                                        <label className="contact-form-label">{t(IDS_Message)}:</label>
                                                        <textarea  style={{height:'6rem'}} className="input-contact" name="message" required="required"
                                                          value={this.state.message}
                                                          onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div className="contact-space">
                                                        <button type="submit"  className=" btn-lg contact-btn" >{t(IDS_SUBMIT)}</button>
                                                        {this.state.errors.others.length > 0 && 
                                                          <p style={{color:"red", fontSize:"0.8rem"}} className='error'>{this.state.errors.others}</p>}
                                                    </div>
                                            </form>

                                        </div>

                                    </div>
                              </div>
                          
                          </div>
                        </div>
                    </div>                
          </div>
       
        )
    }
}
VcSupportForm.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcSupportForm
