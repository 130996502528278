import React, { Component } from 'react';
import '../CSS/AboutHHM.css';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_VRIDetailedInfo, IDS_MRIDetailedInfo} from '../../VcLanguage';

import {getSupportedLangShortNameList} from '../../VcLanguage';

export class VcVRIandMRIInfo extends Component {

    constructor(props) {
        super(props)

        this.state = {
            paramToShowDetailedInfo: this.props.paramToShowDetailedInfo,
            receivedParamToShowInfo: ""
        }
    }

    componentDidMount() {

        let appRelevantDataContextValue = this.context; // Get all the relevant data from AppContext
        let modifiedState = this.state;

        let receivedLangAndParamToShowInfo = window.location.pathname.split('/').pop();

        let receivedLangAndParam = receivedLangAndParamToShowInfo.split('-');
        let SelectedLanguage = receivedLangAndParam[0];
        modifiedState.receivedParamToShowInfo = receivedLangAndParam[1];

        if(getSupportedLangShortNameList().includes(SelectedLanguage)) {
            appRelevantDataContextValue.onChangeLanguageToViewIn(SelectedLanguage);
        } else {
            // Do not set language as English as someone might have already selected a different language in the dropdown.
            // (for eg: in case of route '/abouthhm' language short key is not sent in URL.)
            console.log(`Language short key not sent in the url or the short key [${SelectedLanguage}] is invalid. 
                            Use the default language currently present in the context.`);
        }

        this.setState(modifiedState);
    }    

    
    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 

        return (
            <div style={{border:"1px solid var(--primaryColor)", backgroundColor:"rgb(168,204,168,0.3)", borderRadius:"0.3rem", marginTop:"0.5rem", padding:"0.1rem", height: "42rem", overflow: "auto"}}>              
                <div style={{marginLeft: "0.5rem", marginRight: "0.5rem", textAlign: "left"}} 
                     dangerouslySetInnerHTML={{__html: (this.state.paramToShowDetailedInfo == "Virus" || this.state.receivedParamToShowInfo == "VRI") ? t(IDS_VRIDetailedInfo) : 
                                                       (this.state.paramToShowDetailedInfo == "Mould" || this.state.receivedParamToShowInfo == "MRI") ? t(IDS_MRIDetailedInfo) : ""}}>
                </div>
            </div>
        )
    }
}
VcVRIandMRIInfo.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcVRIandMRIInfo
