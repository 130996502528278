    import React, { useState,useContext, useEffect, useRef } from 'react';
    import App from '../../App';
    import { AppRelevantDataContext } from '../../AppContext';
    import {useNavigate} from 'react-router-dom';
    import axios from 'axios';
    import { getAPIHostURL } from '../../ClientConfig';
    import {IDS_AUServerError, IDS_RegistNetworkError, IDS_SanitationSurveyOfPublicandCommunityToilets, IDS_ToiletNameLbl, IDS_SearchToilet,
    IDS_ToiletAddresLbl,IDS_ToiletCityLbl, IDS_ToiletStateLbl, IDS_ToiletPincodeLbl, IDS_EnterName, IDS_EnterAddress, IDS_EnterCity,
    IDS_EnterState, IDS_EnterPincode, IDS_SearchToiletsToProceed, IDS_SanitationAlert1, IDS_EditSanitationForm, IDS_FillSanitationForm,IDS_ViewSanitationForm,
    IDS_SanitationAlert2, IDS_SanitationAlert3} from '../../VcLanguage';
    import VcNavBar from './VcNavbar';
    import { Button, message, theme, Layout, Form, Input, List, Empty, Modal, Row, Col} from 'antd';
    import VcSideNav from './DeviceHome/VcSideNav';
    import { FaArrowAltCircleLeft } from 'react-icons/fa';
    import { GrMenu } from 'react-icons/gr';
    import vilisoLogoTransparent from './../IMAGES/vilisoLogoTransparent.png'
    import { RiMenuUnfoldLine } from "react-icons/ri";

    import { MdClose } from 'react-icons/md';
    const { Content } = Layout;

    function VcSanitationMapping (props) {

    const context = useContext(AppRelevantDataContext);
    let t = context.t;
    const navigate = useNavigate();
    const [state, setState] = useState({
        toiletList: [],
        searchValues: {},
        showDrawer: false,
        errors: {
        others: "",
        },
    });
    const { token, token: { colorBgContainer, colorBgSpotlight, borderRadiusLG }} = theme.useToken();
    const [form] = Form.useForm();
    const [pageSize, setPageSize] = useState(4); // State variable to store the page size
    const [messageApi, contextHolder] = message.useMessage();
    const [lastMessage, setLastMessage] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    // function to show msg
    const showMessage = (msg) => {
        setState({toiletList: ""});

        if (!isMessageShown() || msg !== lastMessage) {
        messageApi.open({
            type: 'warning',
            content: msg,
            duration: 5,
        });
        setLastMessage(msg);
        }
        };

        const isMessageShown = () => {
        // Check if a message is currently displayed
        return document.getElementsByClassName('ant-message-notice').length > 0;
        };

    useEffect(() => {
        let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
        if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
        // User login information is not present in appRelevantDataContext.
        // Try to get this value if present in LocalStorage and update the app state (so that
        // re-render will happen). If app context data not present in LocalStorage then
        // show the Login Page
        if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
            // App Relevant Data Context not present in LocalStorage as well.
            navigate('/', { replace: true });
        } else {
            // App Relevant Data Context loaded from LocalStorage at AppLeval.
            // Re-render will happen automatically (no action required).
        }
        }
    },[]);


    // To switch between layouts
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const savedFormValues = localStorage.getItem('formValues');
        if (savedFormValues) {
        form.setFieldsValue(JSON.parse(savedFormValues));
        }
    }, [form]);

    if( context.loggedInUserInfo.userFirstName.length <= 0 ) {
        // User login information is not present in appRelevantDataContext.

        // Next re-render will happen automatically when the AppState is updated again 
        // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
        return App.renderLoadingPage();
    }

    const onFinish = (values) => {

        let modifiedState = state;
        modifiedState.toiletList = [];
        let hasNonNullValue = Object.values(values).some(value => value !== null && value !== undefined && value !== "");

        if(hasNonNullValue == true){
        let hasValueWithLengthLessThan3Char = Object.values(values).some(value => value !== null && value !== undefined && value !== "" && value.length < 3);

        if(hasValueWithLengthLessThan3Char == false) {

            let jsonBody = {
                            toiletName: values.toiletName !== undefined ? values.toiletName : null, 
                            toiletAddr: values.toiletAddr !== undefined ? values.toiletAddr : null, 
                            toiletPincode: values.toiletPincode !== undefined ? values.toiletPincode : null, 
                            toiletCity: values.toiletCity !== undefined ? values.toiletCity : null, 
                            toiletState: values.toiletState !== undefined ? values.toiletState : null
                            }

            // API to get the list of the searched toilet to fill or edit sanitation mapping form
            axios.post(`${getAPIHostURL()}/wclient/getListOfToiletsForSanitationMapping`, jsonBody)
            .then(response => {
            if(response.data.code == "SUCCESS") {
                if(response.data.recievedToiletData == null || response.data.recievedToiletData.length <= 0)
                {
                    // No sanitation form response found for selected toilet node.
                    showMessage(t(IDS_SanitationAlert2));
                } else {
                    // store response
                    modifiedState.toiletList = response.data.recievedToiletData;
                }
            } else {
                if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = t(IDS_AUServerError);
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = t(IDS_AUServerError);
                }
            }
            setState({...modifiedState});
            })
            .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                setState({...modifiedState});
            }  
            });
        } else {
            // Less than 3 characters in some search input fields
            showMessage(t(IDS_SanitationAlert3));
        }
        } else {
        // No value entered in the search input fields
        showMessage(t(IDS_SanitationAlert1));
        }
    };

    const renderSanitationMapping = () => {
        return <div style={{overflow: 'none'}}>
        {/* <VcNavBar /> */}
        {contextHolder}
        <Layout theme="light">
        <Layout>
            <Content
            style={{
                margin: '10px 10px',
                padding: 10,
                minHeight: "100vh",
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
            }}
            >
            <div className="container flex col-lg-12 justify-content-center align-items-center">
                <h4 style={{color: "var(--primaryColor)", fontWeight: "bold"}}>{t(IDS_SanitationSurveyOfPublicandCommunityToilets)}</h4>
            </div>
            
            <div className="border-0 rounded p-3" style={{ backgroundColor: "#F5F5F5", marginTop: "0.5rem" }}>
                <Form
                    name="horizontal_login"
                    layout="inline"
                    onFinish={onFinish}
                    form={form}
                    onValuesChange={(_, allFields) => {
                        let modifiedState = state;

                        if ((Object.values(allFields).some(value => value !== null && value !== undefined && value !== "")) === false) {
                            modifiedState.toiletList = [];
                        }

                        modifiedState.searchValues = allFields;
                        setState({ ...modifiedState });
                    }}
                >
                    <Col>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                                <Form.Item 
                                    name="toiletName" 
                                    label={t(IDS_ToiletNameLbl)}
                                    labelCol={{ xs: { span: 8 }, sm: { span: 8 }, lg: { span: 10 } }}
                                    wrapperCol={{ xs: { span: 16 }, sm: { span: 16 }, lg: { span: 16 }  }}
                                >
                                    <Input placeholder={t(IDS_EnterName)} allowClear/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                                <Form.Item 
                                    name="toiletAddr" 
                                    label={t(IDS_ToiletAddresLbl)}
                                    labelCol={{ xs: { span: 8 }, sm: { span: 8 }, lg: { span: 10 }  }}
                                    wrapperCol={{ xs: { span: 16 }, sm: { span: 16 }, lg: { span: 16 }  }}
                                >
                                    <Input placeholder={t(IDS_EnterAddress)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                                <Form.Item 
                                    name="toiletCity" 
                                    label={t(IDS_ToiletCityLbl)}
                                    labelCol={{ xs: { span: 8 }, sm: { span: 8 }, lg: { span: 10 }  }}
                                    wrapperCol={{ xs: { span: 16 }, sm: { span: 16 }, lg: { span: 16 }  }}
                                >
                                    <Input placeholder={t(IDS_EnterCity)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                                <Form.Item 
                                    name="toiletState" 
                                    label={t(IDS_ToiletStateLbl)}
                                    labelCol={{ xs: { span: 8 }, sm: { span: 8 }, lg: { span: 10 }  }}
                                    wrapperCol={{ xs: { span: 16 }, sm: { span: 16 }, lg: { span: 16 }  }}
                                >
                                    <Input placeholder={t(IDS_EnterState)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                                <Form.Item 
                                    name="toiletPincode" 
                                    label={t(IDS_ToiletPincodeLbl)}
                                    labelCol={{ xs: { span: 8 }, sm: { span: 8 }, lg: { span: 10 }  }}
                                    wrapperCol={{ xs: { span: 16 }, sm: { span: 16 }, lg: { span: 16 }  }}
                                >
                                    <Input placeholder={t(IDS_EnterPincode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Row justify="center" style={{ width: "100%"}}>
                        <Col xs={{ span: 24, offset: 1 }} sm={{ span: 24, offset: 1 }} md={{ span: 24, offset: 1 }} lg={{ span: 24, offset: 1 }} xl={{ span: 24, offset: 1 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ backgroundColor: "var(--secondaryColor)", fontWeight: "540" }}>
                                    {t(IDS_SearchToilet)}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            {
            state.toiletList.length > 0 
            ?
                <div className="col-lg-12 mx-auto text-center">
                <List
                    itemLayout="horizontal"
                    size="large"
                    pagination={{
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['4', '10', '20', '30'],
                    onShowSizeChange: (current, newSize) => {
                        setPageSize(newSize);
                    },
                    }}
                    dataSource={state.toiletList}
                    renderItem={(item) => (
                    <List.Item key={item.title}>
                        <Row style={{ width: '100%' }} gutter={[16, 16]}>
                        <Col xs={24} sm={16} md={16} lg={16}>
                            <List.Item.Meta
                            style={{ textAlign: 'left' }}
                            title={item.title}
                            description={item.description}
                            />
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} style={{ textAlign: 'right' }}>
                            {item.isSanitationFormFilled == true ? (
                            <Row justify="end" gutter={[8, 8]}>
                                <Col>
                                <Button
                                    type="link"
                                    onClick={() => navigate('/sanitationMappingForm', { state: { invokedTo: 'view', EntityLocnID: item.EntityLocnID, Name: item.title } })}
                                >
                                    {t(IDS_ViewSanitationForm)}
                                </Button>
                                </Col>
                                <Col>
                                <Button
                                    type="link"
                                    onClick={() => navigate('/sanitationMappingForm', { state: { invokedTo: 'edit', EntityLocnID: item.EntityLocnID, Name: item.title } })}
                                >
                                    {t(IDS_EditSanitationForm)}
                                </Button>
                                </Col>
                            </Row>
                            ) : (
                            <Button
                                type="link"
                                onClick={() => navigate('/sanitationMappingForm', { state: { invokedTo: 'fill', EntityLocnID: item.EntityLocnID, Name: item.title } })}
                            >
                                {t(IDS_FillSanitationForm)}
                            </Button>
                            )}
                        </Col>
                        </Row>
                    </List.Item>
                    )}
                />
                </div>
            : 
            <Empty description={<span style={{ color: 'gray' }}>{t(IDS_SearchToiletsToProceed)}</span>}
            style={{marginTop: "2rem"}} />
            }
            </Content>
        </Layout>
        </Layout>
    </div>
    }


    
    return (
        windowWidth <= 570 ?
        <>  
            <div  className={`drawerDiv ${state.showDrawer ? 'drawerDivIn' : 'drawerDivOut'}`}>
                <VcSideNav />
                <FaArrowAltCircleLeft 
                    className="closingArrow top-nav" onClick={()=> setState({...state , showDrawer : false})}
                />
            </div>
            <div className='bg-white shadow flex items-center justify-between px-2 py-1 m-1 ' style={{top:"0", borderRadius:"0.75rem"}}>
                <div className='' style={{width:"60px"}}>
                <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
                </div>
                {state.showDrawer ? 
                <MdClose classname='transition-all' onClick={()=> setState({...state , showDrawer : false})}/>:
                <span onClick={()=> setState({...state , showDrawer : true})}><RiMenuUnfoldLine style={{ fontSize: "1.2rem" }} /></span> 
                }
            </div>
                {renderSanitationMapping()}        
        </>
        :
        <div className='w-100 d-flex relative '>
            <div className='shadow' style={{width:"4%", position:"relative", zIndex:"5"}}>
                <VcSideNav/>
            </div>
            <div style={{width:"96%" }}>
                {renderSanitationMapping()}
            </div>
        </div>
    )
    
    }

    export default VcSanitationMapping;