import React, { useContext, useEffect, useState } from 'react';
import '../CSS/Support.css';
import App from '../../App';
import { AppRelevantDataContext } from '../../AppContext';
import {useNavigate} from 'react-router-dom';
import VcSideNav from './DeviceHome/VcSideNav';
import vilisoLogoTransparent from './../IMAGES/vilisoLogoTransparent.png'
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { clearDeviceList } from './Store/Slices/deviceList';
import { clearTreeNodeList } from './Store/Slices/treeNodeList';
import { RiMenuUnfoldLine } from 'react-icons/ri';
import {IDS_ParamDocumentation, IDS_EverythingAboutParam, IDS_ParamDoc_Aqi, IDS_AQIParamForParamDoc, IDS_VAQIParamForParamDoc, IDS_ParamDoc_Caqi,
    IDS_COParamForParamDoc, IDS_ParamDoc_Co, IDS_CO2ParamForParamDoc, IDS_ParamDoc_Co2, IDS_H2SParamForParamDoc, IDS_ParamDoc_H2s, IDS_HumParamForParamDoc, IDS_ParamDoc_Hum,
    IDS_LPGParamForParamDoc, IDS_ParamDoc_Lpg, IDS_MRIParamForParamDoc, IDS_ParamDoc_Mri, IDS_OdourParamForParamDoc, IDS_ParamDoc_Nh3od, IDS_NO2ParamForParamDoc, IDS_ParamDoc_No2, IDS_O3ParamForParamDoc,  IDS_ParamDoc_O3,
    IDS_DustPm1, IDS_ParamDoc_Pm1, IDS_DustPm10, IDS_ParamDoc_Pm10, IDS_DustPm25, IDS_ParamDoc_Pm25, IDS_ParamDoc_Smoke, IDS_SO2ParamForParamDoc, IDS_ParamDoc_So2,
    IDS_TempParamForParamDoc, IDS_ParamDoc_Temp, IDS_ParamDoc_Voc, IDS_VRIParamForParamDoc, IDS_ParamDoc_Vri, IDS_SMOKEParamForParamDoc, IDS_TVOCParamForParamDoc} from '../../VcLanguage';
import '../CSS/ParamDocumentation.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AQI, CAQI, CO, CO2, H2S, HUM, LPG, MRI, NH3OD, NO2, O3, PM1, PM10, PM25, SMOKE, SO2, TEMP, VOC, VRI} from '../../VcConstants';


function VcParameterDocumentationPg () {

  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();
  let t = context.t; 
  const dispatch = useDispatch();

  const [state, setState] = useState({
    showDrawer: false,
    // this array of objects contains all the title and description of parameters for the 1st page of Parameter Documentation
    paramInfoForDocumentation: [ {MeasuredParam: NH3OD, Title: IDS_OdourParamForParamDoc, Description: IDS_ParamDoc_Nh3od},
      {MeasuredParam: TEMP, Title: IDS_TempParamForParamDoc, Description: IDS_ParamDoc_Temp},
      {MeasuredParam: HUM, Title: IDS_HumParamForParamDoc, Description: IDS_ParamDoc_Hum}, 
      {MeasuredParam: VOC, Title: IDS_TVOCParamForParamDoc, Description: IDS_ParamDoc_Voc},
      {MeasuredParam: AQI, Title: IDS_AQIParamForParamDoc, Description: IDS_ParamDoc_Aqi}, 
      {MeasuredParam: CAQI, Title: IDS_VAQIParamForParamDoc, Description: IDS_ParamDoc_Caqi}, 
      {MeasuredParam: VRI, Title: IDS_VRIParamForParamDoc, Description: IDS_ParamDoc_Vri},
      {MeasuredParam: MRI, Title: IDS_MRIParamForParamDoc, Description: IDS_ParamDoc_Mri},
      {MeasuredParam: CO, Title: IDS_COParamForParamDoc, Description: IDS_ParamDoc_Co}, 
      {MeasuredParam: CO2, Title: IDS_CO2ParamForParamDoc, Description: IDS_ParamDoc_Co2}, 
      {MeasuredParam: H2S, Title: IDS_H2SParamForParamDoc, Description: IDS_ParamDoc_H2s}, 
      {MeasuredParam: NO2, Title: IDS_NO2ParamForParamDoc, Description: IDS_ParamDoc_No2},
      {MeasuredParam: O3, Title: IDS_O3ParamForParamDoc, Description: IDS_ParamDoc_O3},
      {MeasuredParam: PM1, Title: IDS_DustPm1, Description: IDS_ParamDoc_Pm1},
      {MeasuredParam: PM10, Title: IDS_DustPm10, Description: IDS_ParamDoc_Pm10},
      {MeasuredParam: PM25, Title: IDS_DustPm25, Description: IDS_ParamDoc_Pm25},
      {MeasuredParam: SO2, Title: IDS_SO2ParamForParamDoc, Description: IDS_ParamDoc_So2},
      {MeasuredParam: LPG, Title: IDS_LPGParamForParamDoc, Description: IDS_ParamDoc_Lpg},
      {MeasuredParam: SMOKE, Title: IDS_SMOKEParamForParamDoc, Description: IDS_ParamDoc_Smoke}],
  })
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  
  useEffect(() => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
    
    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.
      // Try to get this value if present in LocalStorage and update the app state (so that
      // re-render will happen). If app context data not present in LocalStorage then
      // show the Login Page
      if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() === false ) {
        // App Relevant Data Context not present in LocalStorage as well.
        navigate('/', { replace: true });
      } else {
        // App Relevant Data Context loaded from LocalStorage at AppLeval.
        // Re-render will happen automatically (no action required).
      }
    }

    dispatch(clearTreeNodeList())
    dispatch(clearDeviceList())
    
  }, []);

  useEffect(() => {

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
    window.removeEventListener('resize', handleResize);
    };

}, []);

const renderParamDocPage = () => {

  return <div style = {{position:"sticky"}}>
  <body>
      <div class="page-header theme-bg-dark py-2 text-center position-relative" style = {{backgroundColor:"#C1E1C1"}}>
          <div class="headerThemeRight"></div>
          <div class="headerThemeLeft"></div>
          <div class="container">
              <h1 class="page-heading single-col-max mx-auto" style = {{fontSize: "2rem", fontWeight: "bold"}}>{t(IDS_ParamDocumentation)}</h1>
              <div class="page-intro single-col-max mx-auto" style = {{ fontSize: "1rem"}}>{t(IDS_EverythingAboutParam)}</div>
          </div>
      </div>

      <div class="page-content">
          <div class="container">
              <div class="docs-overview py-4">
                  <div class="row justify-content-center">
                      {
                          state.paramInfoForDocumentation.map((singleParamDetails) => {
                                  let title = singleParamDetails.Title;
                                  let description = singleParamDetails.Description;
                                  let selectedMeasuredParam = singleParamDetails.MeasuredParam;
                              return(
                                  <div class="col-8 col-md-3 py-4">
                                      <div class="card shadow-sm CardForParamFrontPage h-100" style = {{marginBottom: "0"}} onClick={() => navigate('/paramInfo', {state: {title, selectedMeasuredParam}})}>
                                            <div class="card-body">
                                              <h5 class="card-title mb-3">
                                                  <span class="card-title-text" style = {{fontSize: "1.3rem", fontWeight: "bold"}}>{t(title)}</span>
                                              </h5>
                                              <div style = {{fontSize: "1rem"}}>{t(description)}</div>
                                            </div>
                                      </div>
                                  </div>
                              )
                          })
                      }                     
                  </div>
              </div>
          </div> 
       </div> 
  </body>
</div>
}


  let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

  if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.

      // Next re-render will happen automatically when the AppState is updated again 
      // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
      return App.renderLoadingPage();
  }


  if(windowWidth < 570) {
   return  <>  
     <div  className={`drawerDiv ${state.showDrawer ? 'drawerDivIn' : 'drawerDivOut'}`}>
         <VcSideNav />
         <FaArrowAltCircleLeft 
             className="closingArrow top-nav" onClick={()=> setState({...state , showDrawer : false})}
         />
     </div>
     <div className='bg-white shadow flex items-center justify-between px-2 py-1 m-1 ' style={{position:"sticky", top:"0", borderRadius:"0.75rem"}}>
         <div className='' style={{width:"60px"}}>
           <img src={vilisoLogoTransparent} alt="" style={{maxWidth:"100%"}} />
         </div>
         {state.showDrawer ? 
         <MdClose classname='transition-all' onClick={()=> setState({...state , showDrawer : false})}/>:
         <span onClick={()=> setState({...state , showDrawer : true})}><RiMenuUnfoldLine style={{ fontSize: "1.2rem" }} /></span> 
         }
     </div>
      {renderParamDocPage()}        
     </>
  } 
   return (
     <div className='w-100 d-flex relative '>
         <div className='shadow' style={{width:"4%", position:"relative", zIndex:"5"}}>
              <VcSideNav/>
         </div>
         <div style={{width:"96%",overflow: 'none'}}>
          {renderParamDocPage()}        
         </div>
     </div>
   )
}

export default VcParameterDocumentationPg;