import React, { Component } from 'react';
import "../CSS/AddUserForm.css"
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import { convertUTCDateToStrYYYYMMDDHH24MMSS } from '../../vtUtil'
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_Add, IDS_Close, IDS_AUUserEmail, IDS_AUStartView, IDS_AUForever, IDS_AUAllowUser, IDS_ViewTill,
        IDS_AUServerError, IDS_RegistNetworkError, IDS_LoginEmailError, IDS_AUSrvrIssueOwnerAddEmailNotRec,
        IDS_AUSrvrIssueReqParamsNotSent, IDS_AUSrvrIssueDevcOwnAddUsrDevcID, IDS_AUCurentDateTime,
        IDS_AUAdjustCurentDateTime, IDS_IDS_AUViewDateTime, IDS_AUNoDeviceFound, IDS_AUDeviceAddSuccesForUser,
        IDS_AUDeviceAddSuccessfully, IDS_AUDeviceCannotAdded, IDS_AUDeviceCannotAddedForUserEmail, IDS_SelectPvg,
        IDS_AUDeviceCannotAddedMaxUser, IDS_AddUserEmailFirst } from '../../VcLanguage';
import '../CSS/AreaPincodePopupForm.css';
import "../CSS/InputFormDeviceData.css";
import '../CSS/InputFormVtDevice.css';
import '../CSS/InstitutionPopupForm.css';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

const AddUserFormCheckbox = props => {
  
    return (
      <li key={props.id} data-name={props.name} className="DeviceName"> 
        <input key={props.id} onClick={props.handleCheckedIndividualDevice} className="checkbox-space" 
            type="checkbox" defaultChecked={props.isChecked} value={props.value} name={props.id}
            /> 
        {props.value}
      </li>
    )
}

class AddUserForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notifyOnCloseForm: this.props.onCloseAddUserPopup,
            DeviceOwnerEmailID: this.props.LoggedInUserID,
            SelectedDeviceName: this.props.DeviceName,
            SelectedDeviceID: this.props.DeviceID,
            SelectedNodeParentID: this.props.ParentIDOfSelectedNode,
            AdditionalUserEmailID: '',
            isCheckedForever: true,
            StartViewTime: new Date(),
            EndViewTime: null, // new Date(),
            isCheckedAllowViewAll: false,
            ArrPrivileges: [],
            selectedPrivilege: "",
            errors:{
                email: '',
                others: '',
                enterEmailIDFirst: '',
            },      
            deviceData: [
                            // This will be filled when the user clicks on 'Allow View All' based on the information 
                            // that has been filled for 'UserEmailID' etc.
                            // EXAMPLE: {key: 'DeviceID', id: 'DeviceID', value: "Device1", isChecked: true},
                        ],
        }
    }

    handleAdditionalUserEmailChange = (e) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let emailValue = e.target.value.trim(); // Get the modified value from the target
        // Convert UserID to lowercase and remove all whiteSpaces before SetState. it makes comparison easy and valid.
        emailValue = emailValue.toLowerCase();
        this.setState( 
            prevState => {
                let modifiedState = prevState;

                let errors = modifiedState.errors;
                errors.email = validEmailRegex.test(emailValue) ? '' : t(IDS_LoginEmailError);

                modifiedState.errors = errors;
                modifiedState.AdditionalUserEmailID = emailValue;
                modifiedState.isCheckedAllowViewAll = false;
                modifiedState.errors.enterEmailIDFirst = "";

                return modifiedState;
            }
        );
    }

    onIsCheckedForever = (e) => {
        let isChecked = e.target.checked;
        this.setState( 
            prevState => {
                let modifiedState = prevState;
                modifiedState.isCheckedForever = isChecked;

                if(isChecked) {
                    modifiedState.EndViewTime = null;
                } else {
                    // Set the ViewEndDateTime 1 hour beyond the ViewStartDateTime
                    let viewEndDateTime = new Date( modifiedState.StartViewTime.valueOf() );
                    viewEndDateTime.setHours(viewEndDateTime.getHours() + 1);
                    modifiedState.EndViewTime = viewEndDateTime;
                }

                return modifiedState;
            }
        );
    }

    onChangeStartViewTime = updatedTime => {
        let updatedActiveStartTime = updatedTime;

        this.setState( 
            prevState => {
                let modifiedState = prevState;
                // Dont Allow the user to enter null time. If he enters null 
                // time (For ex. Using X Button), then set the Date back to the original value.
                modifiedState.StartViewTime = 
                    updatedActiveStartTime == null ? prevState.StartViewTime : updatedActiveStartTime ;
                return modifiedState;
            }
        );
    }

    onStartVwDtTmCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        this.setState( 
            prevState => {

                let updatedActiveStartTime = prevState.StartViewTime;
                // Based on the currently set StartViewTime, calculate the minimum required EndDateTime (i.e 1 hour greater)
                let minRequiredEndViewTime = new Date( updatedActiveStartTime.valueOf() );
                minRequiredEndViewTime.setHours(minRequiredEndViewTime.getHours() + 1);

                let currentlySetEndViewTime = prevState.EndViewTime;

                // Give a tolerance of -5 minutes to the current date time to avoid unnecessary messages
                let currentDateTime = new Date();
                currentDateTime.setMinutes(currentDateTime.getMinutes() - 5);

                let modifiedState = prevState;

                if(updatedActiveStartTime < currentDateTime) {
                    let strMsg = t(IDS_AUCurentDateTime) + 
                    (currentlySetEndViewTime == null ? '' : 
                        t(IDS_AUAdjustCurentDateTime));

                    alert(strMsg);

                    // Calculate new minimum required End View Time based on the currentDateTime
                    currentDateTime = new Date(); // Remove tolerance so that we get more acurate start time
                    minRequiredEndViewTime = new Date( currentDateTime.valueOf() );
                    minRequiredEndViewTime.setHours(minRequiredEndViewTime.getHours() + 1);

                    modifiedState.StartViewTime = currentDateTime;
                    if( modifiedState.EndViewTime != null && currentlySetEndViewTime < minRequiredEndViewTime ) {
                        modifiedState.EndViewTime = minRequiredEndViewTime;
                    }

                } else if ( currentlySetEndViewTime != null && currentlySetEndViewTime < minRequiredEndViewTime ) {
                    alert(t(IDS_IDS_AUViewDateTime));

                    modifiedState.EndViewTime = minRequiredEndViewTime; 
                } else {
                    // Everything is fine. No actions required.
                }

                return modifiedState;
            }
        );
    }

    onChangeEndViewTime = updatedTime => {
        let updatedActiveEndTime = updatedTime;

        this.setState( 
            prevState => {
                let modifiedState = prevState;
                modifiedState.EndViewTime = updatedActiveEndTime;
                return modifiedState;
            }
        );
    }

    onEndVwDtTmCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        this.setState( 
            prevState => {

                let currentlySetStartViewTime = prevState.StartViewTime;
                // Based on the currently set StartViewTime, calculate the minimum required EndDateTime (i.e 1 hour greater)
                let minRequiredEndViewTime = new Date( currentlySetStartViewTime.valueOf() );
                minRequiredEndViewTime.setHours(minRequiredEndViewTime.getHours() + 1);

                let updatedEndViewTime = prevState.EndViewTime;

                let modifiedState = prevState;

                if ( updatedEndViewTime != null && updatedEndViewTime < minRequiredEndViewTime ) {
                    alert(t(IDS_IDS_AUViewDateTime));

                    modifiedState.EndViewTime = minRequiredEndViewTime; 
                }

                return modifiedState;
            }
        );
    }

    handleAllChecked = (event) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state;

        if(modifiedState.AdditionalUserEmailID == null || modifiedState.AdditionalUserEmailID.length <= 0 || validEmailRegex.test(modifiedState.AdditionalUserEmailID) == false || modifiedState.DeviceOwnerEmailID == null || modifiedState.DeviceOwnerEmailID.length <= 0) {
            event.preventDefault();
            modifiedState.errors.enterEmailIDFirst = t(IDS_AddUserEmailFirst);
            this.setState(modifiedState);
            return;
        }   

        // Get the checked state from the event
        let isCheckedAllowViewAll = event.target.checked;

        // First get the full state so that only required elements can be updated
        modifiedState.isCheckedAllowViewAll = isCheckedAllowViewAll;
        modifiedState.deviceData = []; // Initially set the Devices Data array to empty
        let selectedDeviceId = modifiedState.SelectedDeviceID;

        // Get the list of eligible devices for this additional user only if the 'AllowViewAll' checkbox is checked
        if(isCheckedAllowViewAll) {
            // JSON object containing DeviceOwnerEmailID and AdditionUserEmailID to be sent to the API Server
            let jsonParams = {
                AdditionalUserEmailID: modifiedState.AdditionalUserEmailID,
                DeviceOwnerEmailID: modifiedState.DeviceOwnerEmailID
            }
            
            axios.post(`${getAPIHostURL()}/wclient/getEligibleOwnedDevicesForAdditionalUser`, jsonParams)
            .then(response => {
                if(response.data.code == 'SUCCESS') {

                    // Take the Devices info that were received from the server
                    let receivedDevicesInfo = response.data.retreivedDevicesInfo;

                    if(receivedDevicesInfo.length <= 0) {
                        // No errors
                        modifiedState.errors.others = t(IDS_AUNoDeviceFound);

                    } else {
                        // Take each Devices Info that was received from axios and 
                        // add into the State's 'deviceData' array after appropriate modification if required

                        // Also initially set no errors message (which can/may be set to appropriate error in the loop processing if required)
                        modifiedState.errors.others = '';

                        let noOfDevices = receivedDevicesInfo.length;
                        for (let i = 0; i < noOfDevices; i++) {
                            const singleValue = receivedDevicesInfo[i];

                            if( singleValue.DeviceID === selectedDeviceId ) {
                                // Do not show the originally passed DeviceID in the list, so that
                                // the user does not accidentally uncheck the device.
                                continue;
                            } else {
                                let singleDeviceInfo = { 

                                    // EXAMPLE: {key: 'DeviceID', id: 'DeviceID', value: "Device1", isChecked: true},
    
                                    key: singleValue.DeviceID, // DeviceID in the Server is unique so can be used directly as key
                                    id: singleValue.DeviceID, // The DeviceID
                                    value: singleValue.DeviceName,
                                    isChecked: true // Initially all eligible devices will be allowed for additional user
                                };
    
                                modifiedState.deviceData.push(singleDeviceInfo);
                            }
                        
                        } // for: Adding each DeviceInfo into the State's 'deviceData' array
                    }
                } else {
                    if (response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                        modifiedState.errors.others = t(IDS_AUSrvrIssueOwnerAddEmailNotRec);
                    } else if (response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                        modifiedState.errors.others = t(IDS_AUServerError);
                    } else {
                        console.log('Should not reach here');
                        // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                        modifiedState.errors.others = t(IDS_AUServerError);
                    }
                }

                // Display the Alert Settings received from the Server. Or display any specific error messages.
                this.setState(modifiedState);
            })
            .catch( error => {

                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    modifiedState.errors.others = t(IDS_RegistNetworkError);
                    this.setState(modifiedState);
                }
            }); 

        } else {
            this.setState(modifiedState); // Original state with 'AllowViewAll' set to false
        }

    }

    handleCheckedIndividualDevice = (e) => {
        let targetDeviceID = e.target.name;
        let isChecked = e.target.checked;

        this.setState( 
            prevState => {
                let modifiedState = prevState;
                
                let deviceData = modifiedState.deviceData
                deviceData.forEach( (deviceDatasingle, index) => {
                    if (deviceDatasingle.id === targetDeviceID) {
                        modifiedState.deviceData[index].isChecked = isChecked;
                    }
                });
                
                return modifiedState;
            }
        );
    }

    addSelectedDeviceToUser = (inbIncludeOriginalDevice) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;   

        let modifiedState = this.state; // Used to show errors after call is made to the server. Also to reflect modified Start/EndView times

        let strDeviceOwnerEmailID = this.state.DeviceOwnerEmailID;
        let AdditionalUserEmailID = this.state.AdditionalUserEmailID;

        let  StartViewTime = this.state.StartViewTime; 
        let EndViewTime = this.state.EndViewTime;

        //============================================================================================
        // * If start date time is less than current date time, it will be adjusted to be at least equal to the current date time.
        // * The end date time will be adjusted to be at least 1 hour greater than the start date time.

        // Give a tolerance of -5 minutes to the current date time to prevent unnecessary alert message
        let currentDateTime = new Date();
        currentDateTime.setMinutes(currentDateTime.getMinutes() - 5);

        if(StartViewTime < currentDateTime) {
            let strMsg = t(IDS_AUCurentDateTime) + 
            (EndViewTime == null ? '' : 
            t(IDS_AUAdjustCurentDateTime));

            alert(strMsg);

            // Calculate new minimum required End View Time based on the currentDateTime
            StartViewTime = new Date(); // Eliminate the 5 minute tolerance added earlier for more accurate start time
            modifiedState.StartViewTime = StartViewTime;
            let minRequiredEndViewTime = new Date( StartViewTime.valueOf() );
            minRequiredEndViewTime.setHours(minRequiredEndViewTime.getHours() + 1);

            if( EndViewTime != null && EndViewTime < minRequiredEndViewTime ) {
                EndViewTime = minRequiredEndViewTime;
                modifiedState.EndViewTime = EndViewTime;
            }

        }

        //============================================================================================

        // Convert the input dates to UTC before sending to the Server
        let strStartViewTime = convertUTCDateToStrYYYYMMDDHH24MMSS(StartViewTime);
        // If the 'Forever' checkbox is 'True', then send 'null' as EndViewTime
        let strEndViewTime = this.state.isCheckedForever ? null : convertUTCDateToStrYYYYMMDDHH24MMSS(EndViewTime);

        let selectedDeviceID = this.state.SelectedDeviceID;
        let SelectedDeviceName = this.state.SelectedDeviceName;

        const jsonUserAndDeviceInfo = {
            DeviceOwnerEmailID: strDeviceOwnerEmailID,
            AdditionalUserEmailID: AdditionalUserEmailID,
            StartViewTime: strStartViewTime, 
            EndViewTime: strEndViewTime,
            selectedPrivilege: modifiedState.selectedPrivilege,
            deviceData: inbIncludeOriginalDevice == true ? 
                [ { DeviceID: selectedDeviceID } ] : // Initially this array will contain only the originally selected DeviceID
                [], // Original device skipped for addition as it may not be eligible
        };

        // In addition to the originally selected DeviceID, also include the DeviceIDs that have been 
        // selected from the eligible list.
        this.state.deviceData.forEach( (singledevice) => { 
            // Add only the selected devices into the DeviceData array
            if(singledevice.isChecked === true) {
                let singleDeviceData = { 
                    DeviceID: singledevice.isChecked ? singledevice.id : '',
                };
                // Push singleDeviceData into deviceData array present in jsonUserAndDeviceInfo
                jsonUserAndDeviceInfo.deviceData.push(singleDeviceData);
            }          
        });

        axios.post(`${getAPIHostURL()}/wclient/addUser`, jsonUserAndDeviceInfo)    
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                let strSuccessMessage = '';
                // strSuccessMessage = (jsonUserAndDeviceInfo.deviceData.length > 0) ?
                // strSuccessMessage = (inbIncludeOriginalDevice && this.state.deviceData.length <= 0) ?
                //     `Selected Device "${SelectedDeviceName}" added Successfully for User ${AdditionalUserEmailID}` :
                //     `Selected Device(s) added Successfully for User ${AdditionalUserEmailID}` ;
                strSuccessMessage = (inbIncludeOriginalDevice && this.state.deviceData.length <= 0) ?
                t(IDS_AUDeviceAddSuccesForUser, SelectedDeviceName, AdditionalUserEmailID):
                t(IDS_AUDeviceAddSuccessfully, AdditionalUserEmailID);

                modifiedState.errors.others = ''; // Success
                // this.setState(modifiedState);

                alert(strSuccessMessage); //Add user Successfully.
                this.state.notifyOnCloseForm(); // Notify the parent to close the popup.
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    modifiedState.errors.others = t(IDS_AUServerError);
                } else {
                    console.log('Should not reach here');
                    // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    modifiedState.errors.others = t(IDS_AUServerError);
                }
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }  
        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }
        }); 
    
    }

    onSubmitAddUser = (e) => {
        e.preventDefault();
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;   

        let strDeviceOwnerEmailID = this.state.DeviceOwnerEmailID;
        let AdditionalUserEmailID = validEmailRegex.test(this.state.AdditionalUserEmailID) ? this.state.AdditionalUserEmailID: null;

        if(AdditionalUserEmailID == null){
            // alert("EmailID is not valid");
            alert(t(IDS_LoginEmailError));
            return; // no further processing required.
        }
        
        let  StartViewTime = this.state.StartViewTime; 
        let EndViewTime = this.state.EndViewTime;

        // //============================================================================================
        // // * If start date time is less than current date time, it will be adjusted to be at least equal to the current date time.
        // // * The end date time will be adjusted to be at least 1 hour greater than the start date time.

        // // Give a tolerance of -5 minutes to the current date time
        // let currentDateTime = new Date();
        // currentDateTime.setMinutes(currentDateTime.getMinutes() - 5);

        // if(StartViewTime < currentDateTime) {
        //     let strMsg = 'Start View Date Time cannot be less than the current date time.' + 
        //     '\nIt will be set to current date time.' + 
        //     (EndViewTime == null ? '' : 
        //         '\nAlso End View Date Time will be adjusted in case it is not greater than a minimum of one hour from the start view date time.');

        //     alert(strMsg);

        //     // Calculate new minimum required End View Time based on the currentDateTime
        //     let minRequiredEndViewTime = new Date( currentDateTime.valueOf() );
        //     minRequiredEndViewTime.setHours(minRequiredEndViewTime.getHours() + 1);

        //     StartViewTime = currentDateTime;
        //     if( EndViewTime != null && EndViewTime < minRequiredEndViewTime ) {
        //         EndViewTime = minRequiredEndViewTime;
        //     }

        // }

        // //============================================================================================

        // // Convert the input dates to UTC before sending to the Server
        // let strStartViewTime = convertUTCDateToStrYYYYMMDDHH24MMSS(StartViewTime);
        // // If the 'Forever' checkbox is 'True', then send 'null' as EndViewTime
        // let strEndViewTime = this.state.isCheckedForever ? null : convertUTCDateToStrYYYYMMDDHH24MMSS(EndViewTime);

        let selectedDeviceID = this.state.SelectedDeviceID;
        let SelectedDeviceName = this.state.SelectedDeviceName;

        // First check if the original device for which this form was invoked is eligible
        // to be added for the specified user

        const jsonUserAndDeviceInfo = {
            DeviceOwnerEmailID: strDeviceOwnerEmailID,
            AdditionalUserEmailID: AdditionalUserEmailID,
            DeviceIDToCheck: selectedDeviceID
        };

        let modifiedState = this.state; // Used to show errors after call is made to the server

        axios.post(`${getAPIHostURL()}/wclient/checkDeviceEligibilityForAdditionalUser`, jsonUserAndDeviceInfo)    
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if (response.data.isDeviceEligibleForAdditionalUser) {
                    // Originally selected device is eligible for adding to additional user.
                    // Call server api for adding the same along with any additional devices
                    // that may have been selected by the user
                    this.addSelectedDeviceToUser(true); // Rest of the asynchronous processing will be done in this function
                } else if (this.state.deviceData.length > 0) {
                    // Tell the user that the original selected device is not eligible to
                    // be added for the additional user. Would he like to add the additional devices
                    // that he has selected.

                    // let strConfirmationMsg = `Device "${SelectedDeviceName}" cannot be added to User "${AdditionalUserEmailID}". ` + 
                    // `\nEither because maximum user count have been exceeded for your device "${SelectedDeviceName}". ` +
                    // `\nOr because user "${AdditionalUserEmailID}" already has access to this device. ` +
                    // `Would you like user "${AdditionalUserEmailID}" to get access to the other devices that you have selected from the list ? `;

                    let strConfirmationMsg = t(IDS_AUDeviceCannotAdded, SelectedDeviceName, AdditionalUserEmailID);

                    if ( window.confirm(strConfirmationMsg) ) {
                        // User wants to skip the original device (as it is not eligible) and wants to use only the additionally selected devices.
                        // Call server api for adding only the additional devices.
                        this.addSelectedDeviceToUser(false); // Rest of the asynchronous processing will be done in this function
                    } else {
                        // User does not want to skip the original device while adding the additional devices
                        // modifiedState.errors.others = `Device "${SelectedDeviceName}" cannot be added. Max users exceeded for device or User already has access to the device.`;
                        modifiedState.errors.others = t(IDS_AUDeviceCannotAddedMaxUser, SelectedDeviceName);
                        this.setState(modifiedState); // To show red message on the form
                    }
                } else {
                    // Original device is not eligible. Also user has not selected any additional devices from the list.
                    // modifiedState.errors.others = `Device "${SelectedDeviceName}" cannot be added. Max users exceeded for device or User already has access to the device.`;
                    modifiedState.errors.others = t(IDS_AUDeviceCannotAddedMaxUser, SelectedDeviceName);
                    this.setState(modifiedState); // To show red message on the form
                    // alert(`Device "${SelectedDeviceName}" cannot be added to User "${AdditionalUserEmailID}".` + 
                    //     `\nCheck if maximum user count have been exceeded for your device "${SelectedDeviceName}".` +
                    //     `\nOr check if user "${AdditionalUserEmailID}" already has access to this device.`
                    // );

                    alert(t(IDS_AUDeviceCannotAddedForUserEmail, SelectedDeviceName, AdditionalUserEmailID));
                }

                return; // No further processing required
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    modifiedState.errors.others = t(IDS_AUSrvrIssueDevcOwnAddUsrDevcID);
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    modifiedState.errors.others = t(IDS_AUServerError);
                } else {
                    console.log('Should not reach here');
                    // modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    modifiedState.errors.others = t(IDS_AUServerError);
                }
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }  
        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                // modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }
        }); 
    
    }

    onCloseAddUserPopup = (e) => {
        this.state.notifyOnCloseForm();
    }

    componentDidMount () {
        this.getPrivilegeWhichCanAssignToAddUser();
    }

    getPrivilegeWhichCanAssignToAddUser () {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let modifiedState = this.state; // Used to show errors after call is made to the server

        axios.post(`${getAPIHostURL()}/wclient/getPrivilegeWhichCanAssignToAddUser`)    
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                if (response.data.ArrPrivileges != null && response.data.ArrPrivileges.length > 0) {
                    modifiedState.ArrPrivileges = response.data.ArrPrivileges;
                } else {
                    modifiedState.errors.others = t(IDS_AUServerError);
                }
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = t(IDS_AUServerError);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = t(IDS_AUServerError);
                }
            }  
            this.setState(modifiedState);
        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
            }
        }); 
    }

    onChangePrivilege = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedPrivilege = e.target.value;
        this.setState(modifiedState);
    }

    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;   
        const{errors} = this.state;

        const color = {
            color : "red",
            fontSize:"0.8rem"    
        }
       
        return (
        <div className="adduser-blackfilm">    
            <div className="container">
                <div className="row">
                    <div className="container col-lg-4 col-lg-offset-4
                                              col-md-6 col-md-offset-3">
                        <div className="mvAddScroll">
                        <div className="adduser-popup">
                            <form onSubmit={this.onSubmitAddUser}>
                                <div className="popup-scroll">
                                    <h6 className="deviceDetail"> {this.state.SelectedDeviceName} </h6>
                                    <div className="form-group update-form">
                                        <div className="inputgroupCustom">
                                            <label className="adduser-form-label">{t(IDS_AUUserEmail)}:</label>
                                            <input type="text" className="input-form" name="AdditionalUserEmailID" style={{marginLeft: "1.5rem"}} onChange={this.handleAdditionalUserEmailChange} required="required"/>
                                            {errors.email.length > 0 && <span style={{marginLeft: "1.5rem", color: "red", fontSize: "0.8rem"}} className="inlineCSS">{errors.email}</span>}
                                        </div>
                                    </div>

                                    <div className="form-group update-form">
                                        <div className="inputgroupCustom">
                                            <label className="adduser-form-label">{t(IDS_SelectPvg)}:</label>
                                            <select className="input-form" 
                                                    style={{marginLeft: "1.5rem", padding: "0px 7px"}}
                                                    required
                                                    value={this.state.selectedPrivilege}
                                                    onChange={this.onChangePrivilege}
                                            >
                                            <option value="" select= "true">Select Privilege...</option> 
                                            {(this.state.ArrPrivileges).map((singlePrivilegeInfo, index) => 
                                            <option key={index} value={singlePrivilegeInfo["PrivilegeName"]}>
                                                {singlePrivilegeInfo["PrivilegeName"]}
                                            </option>)}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group update-form">
                                        <div className="inputgroupCustom">
                                            <label className="adduser-form-label">{t(IDS_AUStartView)}:</label>
                                            <DateTimePicker
                                                clearIcon={null}
                                                selected={this.state.StartViewTime}
                                                onChange={this.onChangeStartViewTime}
                                                onCalendarClose={this.onStartVwDtTmCalendarOrClockClose}
                                                onClockClose={this.onStartVwDtTmCalendarOrClockClose}
                                                className="input-form-datetime"
                                                format={"yyyy/MM/dd HH:mm:ss"} 
                                                value={this.state.StartViewTime} 
                                                name="StartViewTime"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group update-form">
                                        <div className="inputgroupCustom">
                                            <span>    
                                                <label className="adduser-form-label">{t(IDS_ViewTill)} :</label>
                                                <input type="checkbox" defaultChecked={this.state.isCheckedForever}
                                                        className="forevercheck" 
                                                        onClick={this.onIsCheckedForever}
                                                />
                                                <label className="checkbox-text"> {t(IDS_AUForever)} </label>
                                            </span>
                                            {this.state.isCheckedForever ?  null : <div> 
                                                                                    <DateTimePicker
                                                                                        clearIcon={null}
                                                                                        onChange={this.onChangeEndViewTime}
                                                                                        onCalendarClose={this.onEndVwDtTmCalendarOrClockClose}
                                                                                        onClockClose={this.onEndVwDtTmCalendarOrClockClose}
                                                                                        className="input-form-datetime"
                                                                                        format={"yyyy/MM/dd HH:mm:ss"} 
                                                                                        value={this.state.EndViewTime} 
                                                                                        name="EndViewTime"
                                                                                    />
                                                                                    </div>
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="allCheckedCheckbox">
                                        <input type="checkbox" onChange={this.handleAllChecked} checked={this.state.isCheckedAllowViewAll} value = {this.state.isCheckedAllowViewAll} className="addisCheckedAllowViewAllbox"/>
                                        <span className="checkbox-text" dangerouslySetInnerHTML={{__html: t(IDS_AUAllowUser)}}></span>
                                    </div>
                                    <div style={{textAlign: "center"}}>
                                        {errors.enterEmailIDFirst != null && errors.enterEmailIDFirst.length > 0 && <span style={{color: "red", fontSize: "0.8rem"}} className="inlineCSS">{errors.enterEmailIDFirst}</span>}
                                    </div>

                                    { this.state.isCheckedAllowViewAll ? 
                                        <div className="user-list-add">
                                            <ul> { this.state.deviceData.map((deviceDatasingle) => {
                                                    return (<AddUserFormCheckbox handleCheckedIndividualDevice={this.handleCheckedIndividualDevice}  
                                                        {...deviceDatasingle} />
                                                    )
                                                })}
                                            </ul>
                                        </div> : null 
                                    }
                                    <div style={{textAlign: "center"}}>
                                        {errors.others.length != null && errors.others.length > 0 && <span style={{color: "red", fontSize: "0.8rem"}} className="inlineCSS">{errors.others}</span>}
                                    </div>
                                    <div>
                                        <span>
                                            <button type="submit" title={"Add Devices to Additional User"} 
                                                    value={"Add Devices to Additional User"} className="adduserButton btn-lg"> 
                                                    {t(IDS_Add)} 
                                            </button>
                                            <button type="button" 
                                                    // title={"Close Add User Popup"} 
                                                    // value={"Close Add User Popup"} 
                                                    className="adduserButton btn-lg"
                                                    onClick={this.onCloseAddUserPopup}
                                            > 
                                                    {t(IDS_Close)} 
                                            </button>
                                        </span>
                                    </div>
                               </div>
                            </form>
                            </div>
                        
                         </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
AddUserForm.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default AddUserForm

 