import React, { useState, useContext, useEffect } from 'react';
import { AppRelevantDataContext } from '../../AppContext';
import '@studysync/react-splitter-layout/lib/index.css';
import { IDS_ToiletHavingNoDevice } from '../../VcLanguage';
import {useNavigate} from 'react-router-dom';
import VcToiletNodeDashboardData from './VcToiletNodeDashboardData';
import { useSelector } from 'react-redux';
import VcLoader from './DeviceHome/Charts/VcLoader';

function VcToilet (props) {

  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();

  const toiletDevicesReduxStore = useSelector((state) => state?.deviceList?.devices)

  const [ state, setState ] = useState({
    devicesAssociatedToiletNode: null
  })

  useEffect(() => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.
      // Try to get this value if present in LocalStorage and update the app state (so that
      // re-render will happen). If app context data not present in LocalStorage then
      // show the Login Page
      if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
        // App Relevant Data Context not present in LocalStorage as well.
        navigate('/', { replace: true });
      } else {
        // App Relevant Data Context loaded from LocalStorage at AppLeval.
        // Re-render will happen automatically (no action required).
      }

    }
  }, []);


  useEffect(() => {

    let deviceMatchedToiletNode = [];
    if(toiletDevicesReduxStore != null && toiletDevicesReduxStore.length > 0) {
        toiletDevicesReduxStore[0]?.map((toiletDevice) => {
            if(toiletDevice.TreeParentID == seletecNodeID) {
                deviceMatchedToiletNode.push(toiletDevice)
            }
        })

        setState(prevState => ({
            ...prevState,
            devicesAssociatedToiletNode: deviceMatchedToiletNode
        }))
    }
  }, [toiletDevicesReduxStore, context.selectedNodeInfo.nodeID])
        
  let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext
  let t = appRelevantDataContextValue.t;

  const seletecNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;

  if(state.devicesAssociatedToiletNode == null) {
    return <div className='sddSingleParam'>
           <VcLoader/>
         </div>
  }
  return (
      <div>
        {
          state.devicesAssociatedToiletNode != null && state.devicesAssociatedToiletNode.length <= 0 ?
            <div className=" sddSingleParam">
              <div className="parameterOuterDiv col-xs-12 col-sm-6 col-md-8 ">
                {t(IDS_ToiletHavingNoDevice)}
              </div>
            </div> :
            <div style={{width: "100%"}}>
                <VcToiletNodeDashboardData fullPath={props.fullPath}/>
            </div>
        }
      </div>
  )
}

export default VcToilet;

