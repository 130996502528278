import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AppRelevantDataContext} from '../../AppContext';
import { getAPIHostURL } from '../../ClientConfig';
import DateTimePicker from 'react-datetime-picker';
import '../CSS/VcStagewiseProdStat.css';
import { INCLUDES_ALL_PRODUCT_NAME, ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED, ITM_STATE_FUNCTIONAL_TESTED, ITM_STATE_DEMO,
         ITM_STATE_READY_FOR_SHIPMENT, ITM_STATE_SOLD, ITM_STATE_RENTAL, ITM_STATE_FAULTY, ITM_STATE_UNDER_REPAIR, ITM_STATE_MAPPED_TO_OWNER, 
         ITM_STATE_MAPPED_AS_REPLACEMENT, ITM_STATE_DECOMMISSIONED_DEVICEID, ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT, 
         ITM_STATE_RECALL_SOLD_DEVICE, ITM_STATE_DEVC_UPGRADE, CSV_COLUMN_SEPERATOR, CSV_LINE_SEPERATOR, MAX_DAYS_RANGE, MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA} from '../../VcConstants';
import { convertUTCDateToStrYYYYMMDDHH24MMSS, getLocalTimezoneOffset, convertLocalDateWithFormatDDMMMYY, 
         convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday, convertLocalDateToStrYYYYMMDDHH24MMSS } from '../../vtUtil';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters }  from 'react-table';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { FaSearch, FaDownload } from 'react-icons/fa';
import { IDS_TodaySearch } from '../../VcLanguage';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

const defaultPropGetter = () => ({});

const ReactTable = ({ columns, data, renderBasedOnTable, getCellProps = defaultPropGetter, passedStateVariables}) => {

    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { 
                pageIndex: 0, 
                // set page size based on tables
                pageSize: passedStateVariables[0] == INCLUDES_ALL_PRODUCT_NAME && renderBasedOnTable == "StagewiseProductStatusTable" ? 5 :
                    passedStateVariables[0] != INCLUDES_ALL_PRODUCT_NAME && renderBasedOnTable == "StagewiseProductStatusTable" ? 1 :
                    passedStateVariables[1].length > 10 && renderBasedOnTable == "DataBasedOnSingleDatesTable" ? 10 :
                    passedStateVariables[1].length <= 10 && renderBasedOnTable == "DataBasedOnSingleDatesTable" ? passedStateVariables[1].length + 1 :
                    passedStateVariables[2].length > 10 && renderBasedOnTable == "SignleDateDetailedDataTable" ? 10 :
                    passedStateVariables[2].length + 1,
                // hide and show columns based on condition
                hiddenColumns: passedStateVariables[3] == true && passedStateVariables[4] == true ? [ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED, ITM_STATE_FUNCTIONAL_TESTED,
                    ITM_STATE_DEMO, ITM_STATE_SOLD, ITM_STATE_RENTAL, ITM_STATE_FAULTY, ITM_STATE_UNDER_REPAIR,
                    ITM_STATE_MAPPED_TO_OWNER, ITM_STATE_MAPPED_AS_REPLACEMENT, ITM_STATE_DECOMMISSIONED_DEVICEID,
                    ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT, ITM_STATE_RECALL_SOLD_DEVICE,
                    ITM_STATE_DEVC_UPGRADE] : passedStateVariables[3] == true && passedStateVariables[4] == false ? [ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED, ITM_STATE_FUNCTIONAL_TESTED,
                    ITM_STATE_DEMO, ITM_STATE_SOLD, ITM_STATE_RENTAL, ITM_STATE_FAULTY, ITM_STATE_UNDER_REPAIR,
                    ITM_STATE_MAPPED_TO_OWNER, ITM_STATE_MAPPED_AS_REPLACEMENT, ITM_STATE_DECOMMISSIONED_DEVICEID,
                    ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT, ITM_STATE_RECALL_SOLD_DEVICE,
                    ITM_STATE_DEVC_UPGRADE, "Download"] : passedStateVariables[3] != true && passedStateVariables[4] == false ? ["Download"] : []},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;{!passedStateVariables[6] ? "No Data Found." : ""}</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* {page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {
                                            style: cell.column.style,
                                            },
                                            getCellProps(cell),
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })
                        //  || <tr style = {{backgroundColor: "white"}}>
                        //     <td>
                        //     <span style={{paddingLeft:"1rem", color: "green", display: "flex" ,justifyContent:"left"}}>{!passedStateVariables[6] ? "No Data Found." : ""}</span>
                        //     </td>
                        //     </tr>
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div  hidden = {passedStateVariables[0] == INCLUDES_ALL_PRODUCT_NAME && renderBasedOnTable == "StagewiseProductStatusTable"
                            ? false : passedStateVariables[0] != INCLUDES_ALL_PRODUCT_NAME && renderBasedOnTable == "StagewiseProductStatusTable"
                            ? true : passedStateVariables[1].length == 1 && renderBasedOnTable == "DataBasedOnSingleDatesTable"
                            ? true : passedStateVariables[1].length == 1 && renderBasedOnTable == "DataBasedOnSingleDatesTable"
                            ? false : passedStateVariables[2].length == 1 && renderBasedOnTable == "SignleDateDetailedDataTable"
                            ? true : false}>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcStagewiseProdStat (props) {

    const searchStringStyle = {fontSize: "0.6rem"};    
    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {

        if(id != "StateDateAndTime") {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
                });
        } else {
            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]
                        
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        }
    };

    const [state, setState] = useState({            
        ShowProductionCount: props.ShowProductionCount,
        startDate: new Date(),
        endDate: new Date(),
        selectedProdName: INCLUDES_ALL_PRODUCT_NAME,
        arrProductsInfo: [],
        arrProductName: [],
        prodCode: '',
        prodNameCode: '',
        prodLine: '',
        prodDescription: '',
        arrStagewiseProdStatus: [],
        textFile: null,
        showDownload: true,
        errors:{
            others:'',
            timeRelatedErr: '',
        },
        selectedRowIndex: null,
        selectedRowProdName: '',
        selectedRowProdCode: '',
        selectedRowProdNameCode: '',
        selectedRowProdLine: '',
        selectedRowProdDescription: '',
        strSelectedCellLocalDate: '',
        selectedCellState: '',
        showIndividualRowInfo: false,
        showSingleModalDateDetailedStatus: false,
        onHoverRowIndex: null,
        onHoverColID: "",
        backdrop:'static',
        data: [],
        dataBasedOnSingleDate: [],
        signleDateDetailedData: [],
        isUniqueProductStateCountChecked: true,
        // Array of all state sequence for stagewise product status
        arrAllStateSequenceForStagewiseProdStat: [ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED, ITM_STATE_FUNCTIONAL_TESTED, ITM_STATE_READY_FOR_SHIPMENT, ITM_STATE_DEMO, ITM_STATE_SOLD, ITM_STATE_RENTAL, ITM_STATE_FAULTY, ITM_STATE_UNDER_REPAIR,  ITM_STATE_MAPPED_TO_OWNER, ITM_STATE_MAPPED_AS_REPLACEMENT, ITM_STATE_DECOMMISSIONED_DEVICEID, ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT, ITM_STATE_RECALL_SOLD_DEVICE, ITM_STATE_DEVC_UPGRADE],
        column: [
            { 
                Header: <div className="ShowOrderTableHeader">Download</div>, 
                accessor: "Download",
                width: 110,   
                style:({
                    textAlign:"center",
                    fontSize:"0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
                disableFilters: true,
                Cell: (props) => <button type = "button" className = "createOrderButton" style={{fontSize:"0.8rem"}}><FaDownload/></button>,                    
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Product<br/>Name</div>,  
                accessor: 'ProductName',
                width: props.ShowProductionCount == true ? 500 : 150,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {   
                Header:() => props.ShowProductionCount == false ?
                                <div className="ShowOrderTableHeader">Date(s)</div> :
                                <div className="ShowOrderTableHeader">Production Date(s)</div>,  
                accessor: 'Dates',
                width: props.ShowProductionCount == true ? 410 : 220,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Manufactured<br/>and<br/>Hardware<br/>Tested</div>,  
                accessor: ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED,
                width: 130,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Functional<br/>Tested</div>,  
                accessor: ITM_STATE_FUNCTIONAL_TESTED,
                width: 105,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => props.ShowProductionCount == false ?
                                <div className="ShowOrderTableHeader">Ready for<br/>Shipment</div> :
                                <div className="ShowOrderTableHeader">Production<br/>Count</div>,  
                accessor: ITM_STATE_READY_FOR_SHIPMENT,
                width: props.ShowProductionCount == true ? 360 : 110,
                disableFilters: true,
                style:({
                    textAlign:props.ShowProductionCount == true ? "center": "center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Demo</div>,  
                accessor: ITM_STATE_DEMO,
                width: 80,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Sold</div>,  
                accessor: ITM_STATE_SOLD,
                width: 80,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Rental</div>,  
                accessor: ITM_STATE_RENTAL,
                width: 90,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Faulty</div>,  
                accessor: ITM_STATE_FAULTY,
                width: 90,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Under<br/>Repair</div>,  
                accessor: ITM_STATE_UNDER_REPAIR,
                width: 90,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Mapped<br/>To<br/>Owner</div>,  
                accessor: ITM_STATE_MAPPED_TO_OWNER,
                width: 105,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Mapped<br/>As<br/>Replacement</div>,  
                accessor: ITM_STATE_MAPPED_AS_REPLACEMENT,
                width: 121,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {   
                Header:() => <div className="ShowOrderTableHeader">Decommissioned<br/>DeviceID</div>,  
                accessor: ITM_STATE_DECOMMISSIONED_DEVICEID,
                width: 143,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {   
                Header:() => <div className="ShowOrderTableHeader">Device<br/>Taken Back For<br/>Replenishment</div>,  
                accessor: ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT,
                width: 140,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {   
                Header:() => <div className="ShowOrderTableHeader">Recall<br/>Sold<br/>Device</div>,  
                accessor: ITM_STATE_RECALL_SOLD_DEVICE,
                width: 90,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {   
                Header:() => <div className="ShowOrderTableHeader">Upgrade</div>,  
                accessor: ITM_STATE_DEVC_UPGRADE,
                width: 95,
                disableFilters: true,
                style:({
                    textAlign:"center",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            
        ],
        detailedProductStatuscolumn: [],
        defaultDetailedProductStatuscolumn: [
            {   
                Header:() => <div className="ShowOrderTableHeader">Device ID</div>,  
                accessor: 'DeviceID',
                width: 350,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },

            {   
                Header:() => <div className="ShowOrderTableHeader">Date and Time</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                filter: filterCaseInsensitive,
                accessor: 'StateDateAndTime',
                ssortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 350,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Selected Product State</div>,  
                accessor: 'SelectedProductState',
                filter: filterCaseInsensitive,
                width: 400,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
        ],
        extraDetailedProductStatuscolumn: [
            {   
                Header:() => <div className="ShowOrderTableHeader">First Name</div>,  
                accessor: 'FirstName',
                filter: filterCaseInsensitive,
                width: 200,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Last Name</div>,  
                accessor: 'LastName',
                filter: filterCaseInsensitive,
                width: 200,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Owner EmailID</div>,  
                accessor: 'OwnerEmailID',
                filter: filterCaseInsensitive,
                width: 200,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
            {   
                Header:() => <div className="ShowOrderTableHeader">Phone No.</div>,  
                accessor: 'PhoneNo',
                filter: filterCaseInsensitive,
                width: 200,
                style:({
                    textAlign:"left",
                    // paddingLeft: "1rem",
                    fontSize: "0.9rem",
                    height: "2.6rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }),
            },
        ],
        // CSV related state
        arrStates: [],
        arrStatesInfo: [],
        bChngeBtnName: false,
    });

    useEffect(() => {
        getRelevantProdInfo();
    }, []);

    const onHoverGetRowIndexAndColID = (rowInfo, column) => {
        let modifiedState = state;
        modifiedState.onHoverRowIndex = rowInfo["index"];
        modifiedState.onHoverColID = column["id"]
        setState({...modifiedState});
    }

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        if(inLogTime == null) {
            return inLogTime;
        }

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return t(IDS_TodaySearch) + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    const getRelevantProdInfo = () => {
        let modifiedState = state;
        modifiedState.arrProductName = [];
        modifiedState.arrProductsInfo = [];
        modifiedState.arrStates = [];
        modifiedState.arrStatesInfo = [];


        axios.post(`${getAPIHostURL()}/wclient/getProductCategoryAndItsRelevantInfo`)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                
                if(response.data["retrievedProdTypeAndItsRelvantInfo"] == null || response.data["retrievedProdTypeAndItsRelvantInfo"].length <=0) {
                    modifiedState.errors.others = 'Product information not present on Server. Please Try again later.'
                } else {
                    modifiedState.arrProductsInfo = response.data["retrievedProdTypeAndItsRelvantInfo"];
                    modifiedState.arrStatesInfo = response.data["retrievedStates"];
                    
                    modifiedState.arrProductName = [...new Set(modifiedState.arrProductsInfo.map(singleProdInfo => singleProdInfo["ProductName"]))];

                    modifiedState.arrProductName.unshift(INCLUDES_ALL_PRODUCT_NAME);

                    for(let i=0; i<modifiedState.arrStatesInfo.length; i++) {
                        modifiedState.arrStates.push(modifiedState.arrStatesInfo[i]["State"]);
                    }

                }
                
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});

        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        }); 
    }

    const onChangeStartDate = (updatedStartDt) => {
        let modifiedState = state;
        let currentDate = new Date();

        if(updatedStartDt > currentDate) {
            modifiedState.errors.timeRelatedErr = "Start Date can not be greater than Current Date. It will be set to Current Date.";
            modifiedState.startDate = currentDate;
            modifiedState.endDate = currentDate;

        } else if(updatedStartDt > modifiedState.endDate) {
            modifiedState.errors.timeRelatedErr = "End Date can not be less than Start Date. It will be set same as to Start Date.";
            modifiedState.startDate = updatedStartDt;
            modifiedState.endDate = updatedStartDt;

        } else {
            modifiedState.errors.timeRelatedErr = "";
            modifiedState.startDate = updatedStartDt;
        }
        modifiedState.arrStagewiseProdStatus = [];
        modifiedState.data = [];
        modifiedState.selectedRowIndex = null;
        modifiedState.selectedRowProdName = "";

        setState({...modifiedState});
    }

    const onChangeEndDate = (updatedEndDt) => {
        let modifiedState = state;
        let currentDate = new Date();

        if(updatedEndDt > currentDate) {
            modifiedState.errors.timeRelatedErr = "End Date can not be greater than Current Date. It will be set to Current Date.";
            modifiedState.endDate = currentDate;

        } else if(updatedEndDt < modifiedState.startDate) {
            modifiedState.errors.timeRelatedErr = "Start Date can not be greater than End Date. It will be set as same to End Date.";
            modifiedState.startDate = updatedEndDt;
            modifiedState.endDate = updatedEndDt;

        } else {
            modifiedState.errors.timeRelatedErr = "";
            modifiedState.endDate = updatedEndDt;
        }
        modifiedState.arrStagewiseProdStatus = [];
        modifiedState.data = [];
        modifiedState.selectedRowIndex = null;
        modifiedState.selectedRowProdName = "";

        setState({...modifiedState});
    }

    const onChangeProdName = (e) => {
        let modifiedState = state;
        modifiedState.selectedProdName = e.target.value;
        modifiedState.arrStagewiseProdStatus = [];
        modifiedState.data = [];
        modifiedState.selectedRowIndex = null;
        modifiedState.selectedRowProdName = "";

        if(modifiedState.selectedProdName == INCLUDES_ALL_PRODUCT_NAME) {
            modifiedState.prodCode = "";
            modifiedState.prodNameCode = "";
            modifiedState.prodLine = "";
            modifiedState.prodDescription = "";

        } else {
            let filteredArrBasedOnProdName = modifiedState.arrProductsInfo.filter((singleProdInfo) => singleProdInfo["ProductName"] == modifiedState.selectedProdName);

            modifiedState.prodCode = filteredArrBasedOnProdName[0]["ProductCode"];
            modifiedState.prodNameCode = filteredArrBasedOnProdName[0]["ProductNameCode"];
            modifiedState.prodLine = filteredArrBasedOnProdName[0]["ProductLine"];
            modifiedState.prodDescription = filteredArrBasedOnProdName[0]["ProductDescription"];
        }
        setState({...modifiedState});
    }

    const OnDownloadSingleRowCSVData = (rowInfo, column) => {

        let modifiedState = state;

        modifiedState.dataBasedOnSingleDate = [];
        
        modifiedState.selectedRowIndex = rowInfo.index;
        setState({...modifiedState});
        modifiedState.selectedRowProdName = rowInfo["original"]["ProductName"];
        modifiedState.selectedRowProdCode = rowInfo["original"]["ProductCode"];
        modifiedState.selectedRowProdNameCode = rowInfo["original"]["ProductNameCode"];
        modifiedState.selectedRowProdLine = rowInfo["original"]["ProductLine"];
        modifiedState.selectedRowProdDescription = rowInfo["original"]["ProductDescription"];
        modifiedState.showIndividualRowInfo = !modifiedState.showIndividualRowInfo;
        modifiedState.showDownload = !modifiedState.showDownload;

        modifiedState = getDataForSingleDate();

        DownloadCsv(modifiedState.dataBasedOnSingleDate);

    }

    const onClickProceed = () => {

        let modifiedState = state;
        modifiedState.bChngeBtnName = true;

        getStagewiseProdStatus(modifiedState);

    }

    const onClickDownload = () => {
        let modifiedState = state;

        if(modifiedState.data.length > 0) {
            DownloadCsv(modifiedState.data);
        } else {
            modifiedState.bChngeBtnName = true;
            getStagewiseProdStatus(modifiedState, true);
        }
    }

    const DownloadCsv = (inCsvData) => {      

        let strCsvHeaderLine = "ProductName" + CSV_COLUMN_SEPERATOR + "Date(s)";
        let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
        let strSingleCsvDataLine = "";

        if(state.ShowProductionCount == true) {
            strCsvHeaderLine += CSV_COLUMN_SEPERATOR + "Production Count";
        } else {
            for(let i=0; i < state.arrAllStateSequenceForStagewiseProdStat.length; i++) {
                strCsvHeaderLine += CSV_COLUMN_SEPERATOR + state.arrAllStateSequenceForStagewiseProdStat[i];
            }
        }

        arrCsvAllLines.push(strCsvHeaderLine);

        for(let i=0; i<inCsvData.length; i++) {

            strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                                    ( (inCsvData[i]["ProductName"] == null) ? '' : inCsvData[i]["ProductName"] ) +
                                   CSV_COLUMN_SEPERATOR +
                                    ( (inCsvData[i]["Dates"] == null) ? '' : inCsvData[i]["Dates"] );

            if(state.ShowProductionCount == true) {
                strSingleCsvDataLine += CSV_COLUMN_SEPERATOR +
                                        ( (inCsvData[i][ITM_STATE_READY_FOR_SHIPMENT] == null) ? '' : inCsvData[i][ITM_STATE_READY_FOR_SHIPMENT] );
            } else {
                strSingleCsvDataLine += CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED] == null) ? '' : inCsvData[i][ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED] ) +
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_FUNCTIONAL_TESTED] == null) ? '' : inCsvData[i][ITM_STATE_FUNCTIONAL_TESTED] ) +
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_READY_FOR_SHIPMENT] == null) ? '' : inCsvData[i][ITM_STATE_READY_FOR_SHIPMENT] ) +
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_DEMO] == null) ? '' : inCsvData[i][ITM_STATE_DEMO] ) +
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_SOLD] == null) ? '' : inCsvData[i][ITM_STATE_SOLD] ) +
                                        CSV_COLUMN_SEPERATOR +
                                        ( (inCsvData[i][ITM_STATE_RENTAL] == null) ? '' : inCsvData[i][ITM_STATE_RENTAL] ) +
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_FAULTY] == null) ? '' : inCsvData[i][ITM_STATE_FAULTY] ) + 
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_UNDER_REPAIR] == null) ? '' : inCsvData[i][ITM_STATE_UNDER_REPAIR] ) +
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_MAPPED_TO_OWNER] == null) ? '' : inCsvData[i][ITM_STATE_MAPPED_TO_OWNER] ) +
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_MAPPED_AS_REPLACEMENT] == null) ? '' : inCsvData[i][ITM_STATE_MAPPED_AS_REPLACEMENT] ) +
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_DECOMMISSIONED_DEVICEID] == null) ? '' : inCsvData[i][ITM_STATE_DECOMMISSIONED_DEVICEID] ) + 
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT] == null) ? '' : inCsvData[i][ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT] ) +
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_RECALL_SOLD_DEVICE] == null) ? '' : inCsvData[i][ITM_STATE_RECALL_SOLD_DEVICE] ) +
                                        CSV_COLUMN_SEPERATOR +
                                            ( (inCsvData[i][ITM_STATE_DEVC_UPGRADE] == null) ? '' : inCsvData[i][ITM_STATE_DEVC_UPGRADE] );
            }
            
            arrCsvAllLines.push(strSingleCsvDataLine);
        }

        // Create CSV File and fill it
        let bFileCreationStatus = createAndFillCsvFile(arrCsvAllLines);

        if(state.ShowProductionCount == true) {
            alert(`Production status count download has started. Check your Browser's status bar or Downloads Folder.`);

        } else {
            alert(`Stagewise product status Data download has started. Check your Browser's status bar or Downloads Folder.`);

        }

    }

    const createAndFillCsvFile = (inarrCsvAllLines) => {

        let retVal = true;

        let foramttedStartDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.startDate);
        let foramttedEndDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.endDate);
        let fileName = "";
        if(state.ShowProductionCount == true) {
            fileName = "ProductionCount From " + foramttedStartDateTime.split(" ")[0] + " To " + foramttedEndDateTime.split(" ")[0] + ".csv";
        } else {
            fileName = "StagewiseProductStatus From " + foramttedStartDateTime.split(" ")[0] + " To " + foramttedEndDateTime.split(" ")[0] + ".csv";
        }

        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = makeTextFile(inarrCsvAllLines);
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            let event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });

        // Return true to indicate that operation performed successfully (in future any failures in 
        // the previous calls can be checked to return false)
        retVal = true;
        return retVal;
    }

    const makeTextFile = (arrText) =>  {

        let data = new Blob(arrText, {type: 'text/plain'});

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (state.textFile !== null) {
            window.URL.revokeObjectURL(state.textFile);
        }

        state.textFile = window.URL.createObjectURL(data);

        // Return a URL you can use as an href
        return state.textFile;
    }

    const getStagewiseProdStatus = (inModifiedState = null, isDownload = false) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let diffBetweenTwoDates = Math.abs((modifiedState.startDate - modifiedState.endDate)/ (1000*60*60*24) );

        if( diffBetweenTwoDates > MAX_DAYS_RANGE ) {
            modifiedState.errors.others = "Date Range should be within Period of 365 days. Please, choose appropriate date.";
            setState({...modifiedState});
            return;
        } 

        if( diffBetweenTwoDates > MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA ) {
            if(!window.confirm(`Are you sure you want to view the large data for more than 90 days of period?`)){
                return
            }
        }

        let strLoclStartDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.startDate);
        let strLoclEndDate = convertLocalDateToStrYYYYMMDDHH24MMSS(modifiedState.endDate);

        let strLoclStartDtTm = strLoclStartDate.split(" ")[0] + "T" + "00:00:00";
        let strLoclEndDtTm = strLoclEndDate.split(" ")[0] + "T" + "23:59:59";
  
        let UtcStartDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclStartDtTm.valueOf()));
        let UtcEndDtTm = convertUTCDateToStrYYYYMMDDHH24MMSS(new Date(strLoclEndDtTm.valueOf()));

        // Get the Browser Timezone Offset
        let localTimeZone = (new Date()).getTimezoneOffset();

        let jsonBody = {
            ProductName: modifiedState.selectedProdName == INCLUDES_ALL_PRODUCT_NAME ? '' : modifiedState.selectedProdName,
            StartDate: UtcStartDtTm,
            EndDate: UtcEndDtTm,
            localTimeZone: getLocalTimezoneOffset(localTimeZone),
            IsUniqueProductStateCountChecked: modifiedState.isUniqueProductStateCountChecked,
        }
        modifiedState.data = [];
        modifiedState.arrStagewiseProdStatus = [];


        axios.post(`${getAPIHostURL()}/wclient/getStagewiseProdStatus`, jsonBody)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                
                if(response.data["retreivedStagewiseProdStat"] == null || response.data["retreivedStagewiseProdStat"].length <=0) {
                    if(state.ShowProductionCount) {
                        modifiedState.errors.others = `Product count is not present for the selected period and filter.`
                    } else {
                        modifiedState.errors.others = `Stagewise Product status information is not present for the selected period and filter.`
                    }
                } else {
                    modifiedState.errors.others = "";
                    modifiedState.arrStagewiseProdStatus = response.data["retreivedStagewiseProdStat"];

                    let sortedArrBasedOnItsProdName = [];
                     // As in these case we dont want to show 'selected' column hence we are hiding it.
                     for(let i=0; i<modifiedState.column.length; i++) {
                        if(modifiedState.column[i]["accessor"] == "") {
                            modifiedState.column[i]["show"] = true;
                            break;
                        }
                    }
                    if(modifiedState.selectedProdName == INCLUDES_ALL_PRODUCT_NAME) {

                        for(let i=0; i<modifiedState.arrProductsInfo.length; i++) {
                            let singleProdInfo = modifiedState.arrProductsInfo[i];
                                
                            let objSingleProdInfo = {
                                ProductCode: singleProdInfo["ProductCode"],
                                ProductNameCode: singleProdInfo["ProductNameCode"],
                                ProductName: singleProdInfo["ProductName"],
                                ProductLine: singleProdInfo["ProductLine"],
                                ProductDescription: singleProdInfo["ProductDescription"],
                                Dates: dateToBeShown(modifiedState.startDate, modifiedState.endDate)
                            }
                            let StateObj = {};

                            for(let j=0; j<modifiedState.arrStatesInfo.length; j++) {
                                let StateID = modifiedState.arrStatesInfo[j]["StateID"];
                                let State = modifiedState.arrStatesInfo[j]["State"];

                                let fileteredArrBasedOnStateID = modifiedState.arrStagewiseProdStatus.filter((singleRow) => singleRow["ProductCode"] == singleProdInfo["ProductCode"] && singleRow["StateID"] == StateID);

                                let lenOfAboveStatID = 0;
                                if(fileteredArrBasedOnStateID.length > 0) {
                                    for(let l=0; l<fileteredArrBasedOnStateID.length; l++) {
                                        lenOfAboveStatID = Math.abs(lenOfAboveStatID) + Math.abs(fileteredArrBasedOnStateID[l]["count"]);
                                    }
                                } else {
                                    lenOfAboveStatID = 0;
                                }
                                let singleStateObj = {
                                    [State]: lenOfAboveStatID
                                }
                                StateObj = {...StateObj, ...singleStateObj};
                            }

                            let objIncludsBothStateAndProdInfo = {...objSingleProdInfo, ...StateObj}

                            sortedArrBasedOnItsProdName.push(objIncludsBothStateAndProdInfo);
                        }
                    } else {
                        let singleProdInfo = modifiedState.arrProductsInfo.filter((singleProdInfo) => singleProdInfo["ProductName"] == modifiedState.selectedProdName);

                        singleProdInfo = singleProdInfo[0];
                            
                        let objSingleProdInfo = {
                            ProductCode: singleProdInfo["ProductCode"],
                            ProductNameCode: singleProdInfo["ProductNameCode"],
                            ProductName: singleProdInfo["ProductName"],
                            ProductLine: singleProdInfo["ProductLine"],
                            ProductDescription: singleProdInfo["ProductDescription"],
                            Dates: dateToBeShown(modifiedState.startDate, modifiedState.endDate)
                        }
                        let StateObj = {};

                        for(let j=0; j<modifiedState.arrStatesInfo.length; j++) {
                            let StateID = modifiedState.arrStatesInfo[j]["StateID"];
                            let State = modifiedState.arrStatesInfo[j]["State"];

                            let fileteredArrBasedOnStateID = modifiedState.arrStagewiseProdStatus.filter((singleRow) => singleRow["ProductCode"] == singleProdInfo["ProductCode"] && singleRow["StateID"] == StateID);

                            let lenOfAboveStatID = 0;
                            if(fileteredArrBasedOnStateID.length > 0) {
                                for(let l=0; l<fileteredArrBasedOnStateID.length; l++) {
                                    lenOfAboveStatID = Math.abs(lenOfAboveStatID) + Math.abs(fileteredArrBasedOnStateID[l]["count"]);
                                }
                            } else {
                                lenOfAboveStatID = 0;
                            }                    
                            
                            let singleStateObj = {
                                [State]: lenOfAboveStatID
                            }
                            StateObj = {...StateObj, ...singleStateObj};
                        }

                        let objIncludsBothStateAndProdInfo = {...objSingleProdInfo, ...StateObj}

                        sortedArrBasedOnItsProdName.push(objIncludsBothStateAndProdInfo);
                    }
                    modifiedState.data = sortedArrBasedOnItsProdName;

                    // As we have to make to show 'Download'.
                    for(let i=0; i<modifiedState.column.length; i++) {
                        if(modifiedState.column[i]["accessor"] == "Download") {
                            modifiedState.column[i]["show"] = true;
                            break;
                        }
                    }

                    if(isDownload) {
                        DownloadCsv(modifiedState.data);
                    }
                }
                
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (Request Params not received).\n Try again later.';
        
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        });
    }

    const dateToBeShown = (startDate, endDate) => {
        let strDateTimeLocale;

        if( new Date(startDate).getFullYear() == new Date(endDate).getFullYear() && 
            new Date(startDate).getDate() == new Date(endDate).getDate() && 
            new Date(startDate).getMonth() == new Date(endDate).getMonth()
        ) {
            strDateTimeLocale =  convertLocalDateWithFormatDDMMMYY(startDate);
        } else {
            strDateTimeLocale = convertLocalDateWithFormatDDMMMYY(startDate) + " ~ " + convertLocalDateWithFormatDDMMMYY(endDate);
        }
        return strDateTimeLocale;
    }

    const toggleshowIndividualRowInfo = () => {
        let modifiedState = state;

        modifiedState.showIndividualRowInfo = !modifiedState.showIndividualRowInfo;
        modifiedState.showDownload = !modifiedState.showDownload;
        modifiedState.selectedRowIndex = null;
        modifiedState.selectedRowProdName = '';
        modifiedState.selectedRowProdCode = '';
        modifiedState.selectedRowProdNameCode = '';
        modifiedState.selectedRowProdLine = '';
        modifiedState.selectedRowProdDescription = '';
        setState({...modifiedState});
    }

    const toggleShowDetailedSingleDateProdStatus = () => {
        let modifiedState = state;

        modifiedState.showSingleModalDateDetailedStatus = !modifiedState.showSingleModalDateDetailedStatus;
        modifiedState.strSelectedCellLocalDate = '';
        modifiedState.selectedCellState = '';
        setState({...modifiedState});
    }

    const getDataForSingleDate = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }
        
        let sortedArrBasedOnItsProdName = [];

        // As in these case we dont want to show 'Show' column hence we are hiding it.
        for(let i=0; i<modifiedState.column.length; i++) {
            modifiedState.showDownload = false;
            if(modifiedState.column[i]["accessor"] == "" || modifiedState.column[i]["accessor"] == "Download") {
                modifiedState.column[i]["show"] = false;
            }
        }
        let singleProdInfo = modifiedState.arrProductsInfo.filter((singleProdInfo) => singleProdInfo["ProductName"] == modifiedState.selectedRowProdName);

        singleProdInfo = singleProdInfo[0];
        let filteredArrBasedOnProdName 
            = modifiedState.arrStagewiseProdStatus.filter((singlerow) =>
                singlerow["ProductCode"] == singleProdInfo["ProductCode"]
            )
            
        let start = new Date(modifiedState.startDate);
        let end = new Date(modifiedState.endDate);
        
        let loop = new Date(start).setDate(start.getDate() - 1 );
        loop = new Date(loop);
        while(loop < end) {
            
            let dateToBeCompared = loop.setDate(loop.getDate() + 1);
           
            let objSingleProdInfo = {
                ProductCode: singleProdInfo["ProductCode"],
                ProductNameCode: singleProdInfo["ProductNameCode"],
                ProductName: singleProdInfo["ProductName"],
                ProductLine: singleProdInfo["ProductLine"],
                ProductDescription: singleProdInfo["ProductDescription"],
                Dates: convertLocalDateWithFormatDDMMMYY(dateToBeCompared)
            }
            let StateObj = {};

            for(let j=0; j<modifiedState.arrStatesInfo.length; j++) {
                let StateID = modifiedState.arrStatesInfo[j]["StateID"];
                let State = modifiedState.arrStatesInfo[j]["State"];

                let filteredArrayBasedOnDateAndID = filteredArrBasedOnProdName.filter(singleProdStInfo => 
                                                            new Date(singleProdStInfo["StateDt"]).getFullYear() == new Date(dateToBeCompared).getFullYear() && 
                                                            new Date(singleProdStInfo["StateDt"]).getDate() == new Date(dateToBeCompared).getDate() && 
                                                            new Date(singleProdStInfo["StateDt"]).getMonth() == new Date(dateToBeCompared).getMonth() &&
                                                            singleProdStInfo["StateID"] == StateID
                                                    );

                let lenOfAboveStatID = 0;
                if(filteredArrayBasedOnDateAndID.length > 0) {
                    lenOfAboveStatID = filteredArrayBasedOnDateAndID[0]["count"];
                } else {
                    lenOfAboveStatID = 0;
                }           
                let singleStateObj = {
                    [State]: lenOfAboveStatID
                }
                StateObj = {...StateObj, ...singleStateObj};
            }

            let objIncludsBothStateAndProdInfo = {...objSingleProdInfo, ...StateObj}
            sortedArrBasedOnItsProdName.unshift(objIncludsBothStateAndProdInfo);
            loop = new Date(dateToBeCompared);
        }

        modifiedState.dataBasedOnSingleDate = sortedArrBasedOnItsProdName;

        return modifiedState;
    }

    const onRowClick = (rowInfo) => {

        let modifiedState = state
       
        modifiedState.dataBasedOnSingleDate = [];
        modifiedState.selectedRowIndex = rowInfo.index;
        modifiedState.selectedRowProdName = rowInfo["original"]["ProductName"];
        modifiedState.selectedRowProdCode = rowInfo["original"]["ProductCode"];
        modifiedState.selectedRowProdNameCode = rowInfo["original"]["ProductNameCode"];
        modifiedState.selectedRowProdLine = rowInfo["original"]["ProductLine"];
        modifiedState.selectedRowProdDescription = rowInfo["original"]["ProductDescription"];
        modifiedState.showIndividualRowInfo = !modifiedState.showIndividualRowInfo;
        modifiedState.showDownload = !modifiedState.showDownload;

        modifiedState = getDataForSingleDate(modifiedState);
                        
        setState({...modifiedState});
      
    }

    const getDetailsBasedOnSelectedItemState = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.signleDateDetailedData = [];
     
        if( modifiedState.selectedCellState == null || modifiedState.selectedCellState.length <= 0 ||
            modifiedState.selectedRowProdCode == null || modifiedState.selectedRowProdCode.length <= 0 ||
            modifiedState.strSelectedCellLocalDate == null || modifiedState.strSelectedCellLocalDate.length <= 0) {
            setState({...modifiedState});
            return
        } 

        let localTimeZone = (new Date()).getTimezoneOffset();

        // Adding Time '00:00:00' to '23:59:59' with local Date String to make a selected date to exact 24hrs local time window.
        let searchLocalStartDtTm = modifiedState.strSelectedCellLocalDate + " 00:00:00";
        let searchLocalEndDtTm = modifiedState.strSelectedCellLocalDate + " 23:59:59";
        let invokeFrom = "stagewiseProductStatus"
    
        let jsonBody = {
            SelectedCellState: modifiedState.selectedCellState,
            SearchLocalStartDtTm: searchLocalStartDtTm,
            SearchLocalEndDtTm: searchLocalEndDtTm,
            SelectedRowProdCode: modifiedState.selectedRowProdCode,
            InvokedFrom: invokeFrom,
            localTimeZone: getLocalTimezoneOffset(localTimeZone),
            IsUniqueProductStateCountChecked: modifiedState.isUniqueProductStateCountChecked,
        };

        axios.post(`${getAPIHostURL()}/wclient/getDetailsBasedOnSelectedItemState`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {

                if(response.data.retrieveDetailsBasedOnSelectedItemState == null || response.data.retrieveDetailsBasedOnSelectedItemState.length <= 0) {
                    // retrieveDetailsBasedOnSelectedItemState Data not found on server.
                } else {
                    let stateArrDataBasedOnSingleDate = [];

                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing product line Details data.
                    stateArrDataBasedOnSingleDate = [...modifiedState.signleDateDetailedData]

                    if(modifiedState.selectedCellState != null && modifiedState.selectedCellState.length > 0 && 
                        (modifiedState.selectedCellState == ITM_STATE_MAPPED_TO_OWNER || modifiedState.selectedCellState == ITM_STATE_MAPPED_AS_REPLACEMENT)
                    ) {
                        modifiedState.detailedProductStatuscolumn = [...modifiedState.defaultDetailedProductStatuscolumn, ...modifiedState.extraDetailedProductStatuscolumn];
                    } else {
                        modifiedState.detailedProductStatuscolumn = modifiedState.defaultDetailedProductStatuscolumn;
                    }
                                            
                    const retrieveDetailsBasedOnSelectedItemState = response.data.retrieveDetailsBasedOnSelectedItemState;

                    for(let i = 0; i < retrieveDetailsBasedOnSelectedItemState.length; i++) {
                        const SingleDateProdStatusDetails = retrieveDetailsBasedOnSelectedItemState[i];
                        // let StateLocaleDateTime = SingleDateProdStatusDetails.StateDtTm == null ? "-" : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(SingleDateProdStatusDetails.StateDtTm);
                        let StateLocaleDateTime = SingleDateProdStatusDetails.StateDtTm == null ? "-" : SingleDateProdStatusDetails.StateDtTm;

                        let singleDateProdStatusData = {
                            DeviceID: SingleDateProdStatusDetails.ItemID == null || SingleDateProdStatusDetails.ItemID.length <= 0  ? "-" : SingleDateProdStatusDetails.ItemID,
                            StateDateAndTime: StateLocaleDateTime,
                            SelectedProductState: modifiedState.selectedCellState == null || modifiedState.selectedCellState.length <= 0 ? "-" : modifiedState.selectedCellState,
                            OwnerEmailID: SingleDateProdStatusDetails.DeviceOwnerEmailID == null  || SingleDateProdStatusDetails.DeviceOwnerEmailID.length <=0 ? null : SingleDateProdStatusDetails.DeviceOwnerEmailID,
                            FirstName: SingleDateProdStatusDetails.FirstName == null  || SingleDateProdStatusDetails.FirstName.length <=0 ? null : SingleDateProdStatusDetails.FirstName,
                            LastName: SingleDateProdStatusDetails.LastName == null  || SingleDateProdStatusDetails.LastName.length <=0 ? null : SingleDateProdStatusDetails.LastName,
                            PhoneNo: SingleDateProdStatusDetails.PhoneNo == null  || SingleDateProdStatusDetails.PhoneNo.length <=0 ? null : SingleDateProdStatusDetails.PhoneNo,
                        };
                        stateArrDataBasedOnSingleDate.push(singleDateProdStatusData);
                    } 
                    
                    modifiedState.signleDateDetailedData = stateArrDataBasedOnSingleDate;
                    modifiedState.showSingleModalDateDetailedStatus = !modifiedState.showSingleModalDateDetailedStatus;

                }
            } else {
                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    modifiedState.errors.others = 'Server experiencing issues (Request Params not received).\n Try again later.';
        
                } else if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        })
    }

    const onCellClick = (cellInfo) => {
        let modifiedState = state;
        if (cellInfo.row != undefined && cellInfo.column != undefined) {
            return {

                onMouseOver: ((cellInfo.column.id == ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED ||
                    cellInfo.column.id == ITM_STATE_FUNCTIONAL_TESTED ||
                    cellInfo.column.id == ITM_STATE_READY_FOR_SHIPMENT ||
                    cellInfo.column.id == ITM_STATE_DEMO ||
                    cellInfo.column.id == ITM_STATE_SOLD ||
                    cellInfo.column.id == ITM_STATE_RENTAL ||
                    cellInfo.column.id == ITM_STATE_FAULTY ||
                    cellInfo.column.id == ITM_STATE_UNDER_REPAIR ||
                    cellInfo.column.id == ITM_STATE_MAPPED_TO_OWNER ||
                    cellInfo.column.id == ITM_STATE_MAPPED_AS_REPLACEMENT ||
                    cellInfo.column.id == ITM_STATE_DECOMMISSIONED_DEVICEID ||
                    cellInfo.column.id == ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT ||
                    cellInfo.column.id == ITM_STATE_RECALL_SOLD_DEVICE ||
                    cellInfo.column.id == ITM_STATE_DEVC_UPGRADE
                    ) && state.showIndividualRowInfo == true && state.dataBasedOnSingleDate.length > 0 
                ) 
                ?
                () => {
                    onHoverGetRowIndexAndColID(cellInfo.row, cellInfo.column);
                }
                : 
                () => {},  

                style: (
                    (cellInfo.column.id === ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED && state.onHoverColID == ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_READY_FOR_SHIPMENT && state.onHoverColID == ITM_STATE_READY_FOR_SHIPMENT && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_READY_FOR_SHIPMENT] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_FUNCTIONAL_TESTED && state.onHoverColID == ITM_STATE_FUNCTIONAL_TESTED && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_FUNCTIONAL_TESTED] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_DEMO && state.onHoverColID == ITM_STATE_DEMO && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_DEMO] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_SOLD && state.onHoverColID == ITM_STATE_SOLD && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_SOLD] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_RENTAL && state.onHoverColID == ITM_STATE_RENTAL && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_RENTAL] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_FAULTY && state.onHoverColID == ITM_STATE_FAULTY && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_FAULTY] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_UNDER_REPAIR && state.onHoverColID == ITM_STATE_UNDER_REPAIR && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_UNDER_REPAIR] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_MAPPED_TO_OWNER && state.onHoverColID == ITM_STATE_MAPPED_TO_OWNER && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_MAPPED_TO_OWNER] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_MAPPED_AS_REPLACEMENT && state.onHoverColID == ITM_STATE_MAPPED_AS_REPLACEMENT && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_MAPPED_AS_REPLACEMENT] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_DECOMMISSIONED_DEVICEID && state.onHoverColID == ITM_STATE_DECOMMISSIONED_DEVICEID && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_DECOMMISSIONED_DEVICEID] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT && state.onHoverColID == ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_RECALL_SOLD_DEVICE && state.onHoverColID == ITM_STATE_RECALL_SOLD_DEVICE && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_RECALL_SOLD_DEVICE] != 0 && props.ShowProductionCount == false) ||
                    (cellInfo.column.id === ITM_STATE_DEVC_UPGRADE && state.onHoverColID == ITM_STATE_DEVC_UPGRADE && cellInfo.row.index == state.onHoverRowIndex &&
                        cellInfo.row.original[ITM_STATE_DEVC_UPGRADE] != 0 && props.ShowProductionCount == false)
                ) ? 
                {
                    border: "1px solid #1c1c1c",
                    cursor: "pointer",
                    background: '#6c95ba',
                    color: 'white',
                } 
                :
                {
                }, 

                onClick: (e, handleOriginal) => {

                    modifiedState.selectedRowIndex = cellInfo.row.index;
                    modifiedState.selectedRowProdName = cellInfo.row["original"]["ProductName"];
                    modifiedState.selectedRowProdCode = cellInfo.row["original"]["ProductCode"];
                    modifiedState.selectedRowProdNameCode = cellInfo.row["original"]["ProductNameCode"];
                    modifiedState.selectedRowProdLine = cellInfo.row["original"]["ProductLine"];
                    modifiedState.selectedRowProdDescription = cellInfo.row["original"]["ProductDescription"];
                    modifiedState.strSelectedCellLocalDate = cellInfo.row["original"]["Dates"];
                    let selectedCellCoumnHeading = cellInfo.column["id"]

                    if(selectedCellCoumnHeading == "Mapped to Owner and Mapped as Replacement"){
                        modifiedState.selectedCellState = "Mapped to Owner and Mapped as Replacement";
                    } else {
                        // This is used to prevent Click on any cell other than Product Item States and cell having value 0.  
                        for(let i = 0; i < modifiedState.arrStates.length; i++) {
                            if(modifiedState.arrStates[i] == selectedCellCoumnHeading && cellInfo.row["original"][selectedCellCoumnHeading] != 0) {
                                modifiedState.selectedCellState = modifiedState.arrStates[i]
                                break;
                            } else {
                                modifiedState.selectedCellState = "";
                            } 
                        }
                    }
                    getDetailsBasedOnSelectedItemState(modifiedState);              
                },
            }
        } else {
            return {}
        }
    }

    const onModalDownloadClickForIndividualRowData = () => {

        DownloadCsv(state.dataBasedOnSingleDate);
    }

     // For Chageing Stagewise product state count data to distinct data.
    const onCheckProductStateUniqueCount = (e) => {
        let modifiedState = state;

        let uniqueProdStateCountCheckBoxIsChecked = e.target.checked;
        let confirmBoxMsgToDiplaywhenUniqProdStateCountCheckboxChange = "";

        if(uniqueProdStateCountCheckBoxIsChecked == true) {
            confirmBoxMsgToDiplaywhenUniqProdStateCountCheckboxChange = `When this option is checked, you will see only the most recent Date for a State for a particular Product Item, ` + 
                `even if the Item has gone through the same state more than once during the given Date Range. ` +
                `Do you want to enable viewing Unique State for Product Item in the Selected Date Range ?`;
        } else {
            confirmBoxMsgToDiplaywhenUniqProdStateCountCheckboxChange = `When this option is unchecked, you will see every Date on which the particular Product Item went into a ` +
                `particular State during the given Date Range. Do you want to disable viewing Unique State for Product Item ` +
                `in the Selected Date Range ?`;
        }

        if(!window.confirm(confirmBoxMsgToDiplaywhenUniqProdStateCountCheckboxChange)){
            return
        } else {
            modifiedState.isUniqueProductStateCountChecked = uniqueProdStateCountCheckBoxIsChecked;
            modifiedState.arrStagewiseProdStatus = [];
            modifiedState.data = [];
            modifiedState.selectedRowIndex = null;
            modifiedState.selectedRowProdName = "";
            modifiedState.showIndividualRowInfo = false;
            setState({...modifiedState});
        }   
    }
    
    return (
        <div>
            <div className="row justify-content-center">
                <div className="container col-lg-12 col-md-12">
                    <div className="modal-body box" style={{padding: "1rem"}}>
                        <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "0.5rem"}}>
                            {state.ShowProductionCount == true ? "Report - Production Count" : "Report - Stagewise Product Status"}
                        </div>
                        <div style={{border:"1px solid black", paddingLeft: "0.3rem", paddingRight: "0.3rem", borderRadius:"0.3rem", marginBottom:"1rem"}}>
                            <div className="selectedModelLable" style={{marginBottom: "0.5rem", marginTop: "0.5rem", textAlign: "center"}}> 
                                <input type="checkbox" key="ProductStateUniqueCount" onChange={onCheckProductStateUniqueCount} 
                                    className="checkbox-space" 
                                    // defaultChecked={state.isUniqueProductStateCountChecked} 
                                    checked = {state.isUniqueProductStateCountChecked}
                                /> 
                                Show Only Unique State for a Product Item in the Selected Date Range
                            </div>
                            <div style={{border:"1px solid var(--primaryColor)", color:"green", fontSize:"0.8rem", alignItems: "left", textAlign: "left", paddingLeft: "0.2rem", paddingRight: "0.2rem", backgroundColor:"rgb(168,204,168,0.3)", borderRadius:"0.3rem", marginBottom:"0.5rem"}}>
                                <b>Note :</b>
                                <ul style={{marginBottom : "0.2rem"}}>
                                    <li>When this option is checked, you will see only the most recent Date for a State for a particular Product Item, 
                                        even if the Item has gone through the same state more than once during the given Date Range.</li>
                                    <li>When this option is unchecked, you will see every Date on which the particular Product Item went into a 
                                        particular State during the given Date Range.</li>
                                </ul> 
                            </div>
                        </div>
                        <div className="startAndEnddate">
                            <div style={{flex:"1"}}>
                                <label style={{fontSize:"0.9rem"}}>
                                    Start Date:
                                </label>
                                <DateTimePicker
                                    clearIcon={null}
                                    onChange={onChangeStartDate}
                                    format={"yyyy/MM/dd"} 
                                    value={state.startDate} 
                                    name="startDateTime"
                                    timeFormat={false}
                                />                                
                            </div>
                            <div style={{flex:"1"}}>
                                <label style={{fontSize:"0.9rem"}}>
                                    &nbsp;End Date:
                                </label>
                                <DateTimePicker
                                    clearIcon={null}
                                    onChange={onChangeEndDate}
                                    format={"yyyy/MM/dd"} 
                                    value={state.endDate} 
                                    name="endDateTime"
                                    timeFormat={false}
                                />           
                            </div>
                        </div>
                        {state.errors.timeRelatedErr.length > 0 && 
                            <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center"}} className='error'>{state.errors.timeRelatedErr}</p> 
                        }       

                        <div className="dropDownOfModelInfo">
                            {/* <div style={{flex:"1"}}/> */}
                            <div className="selectedModelLable"
                            >
                                Select Product Name:
                            </div>
                            <div style={{marginLeft:"1%"}}>
                                <select className="ProdInfoInput"
                                        style={{width:"100%", background:"white", height:"2rem"}} 
                                        required
                                        value={state.selectedProdName}
                                        onChange={onChangeProdName}
                                >
                                    <option value="" select= "true" disabled>Select ProductName...</option> 
                                    {(state.arrProductName).map((singleProdName, index) => <option key={index} value={singleProdName}>{singleProdName}</option>)}

                                </select>
                            </div>
                        </div>
                        <div style={{display:state.selectedProdName == INCLUDES_ALL_PRODUCT_NAME ? "none" : "block"}}>
                            <div className="ProdInfo">
                                <div className="subProdInfoFirst">
                                    <label className="ProdInfolabel" >Product Code:</label>
                                    <input value={state.prodCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                </div>
                                <div className="subProdInfoSecond">
                                    <label className="ProdInfolabel">Product Name Code:</label>
                                    <input value={state.prodNameCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                </div>
                            </div>
                            <div className="ProdInfo">
                                <div className="subProdInfoFirst">
                                    <label className="ProdInfolabel">Product Line:</label>
                                    <input value={state.prodLine} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                </div>
                                <div className="subProdInfoSecond">
                                    <div className="ProdInfolabelDiscription" style={{textAlign:"left"}}>Product Description:</div>
                                    <textarea value={state.prodDescription} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>  
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{display:"flex"}}>
                                <button type="button"
                                        onClick = {onClickProceed}
                                        style={{borderRadius: "5px", width:"30%", marginTop:"1rem",
                                                backgroundColor: "var(--primaryColor)", color:"white",
                                                marginLeft:"15%", 
                                                fontWeight:"700",
                                            }}
                                        className="btn-sm"
                                >
                                    {state.bChngeBtnName? "Refresh" : "View"}
                                </button>
                                <button type="button"
                                        name = "download"
                                        onClick = {onClickDownload}
                                        style={{
                                                borderRadius: "5px", width:"35%", marginTop:"1rem",
                                                backgroundColor: "var(--primaryColor)", color:"white",
                                                marginLeft:"12%", fontWeight:"700",
                                            }}
                                        className="btn-sm"
                                >
                                    Download Summary
                                </button>
                            </div>
                            {state.errors.others.length > 0 && 
                                <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center"}} className='error'>{state.errors.others}</p> 
                            }
                        </div>
                        {state.arrStagewiseProdStatus.length > 0 ?
                            // <div style={{marginTop:"1rem", border: "1px solid black", textAlign:"center"}}>
                            <div className="border border-3 ReactTableStyle" style = {{marginTop:"1rem"}}>
                                <ReactTable 
                                    data={state.data}
                                    columns={state.column}
                                    renderBasedOnTable = {"StagewiseProductStatusTable"}
                                    getCellProps={(cellInfo) => {
                                        if (cellInfo.row != undefined && cellInfo.column != undefined) {
                                            return {
                                                onClick: () => {

                                                    if(cellInfo.column.id == "Download") {
                                                        OnDownloadSingleRowCSVData(cellInfo.row, cellInfo.column)     
                                                    }  else {
                                                        onRowClick(cellInfo.row);
                                                    }
                                                },

                                                style: {
                                                    cursor: 'pointer',
                                                    background: cellInfo.row.index == state.selectedRowIndex ? '#6c95ba' : '',
                                                    color: cellInfo.row.index == state.selectedRowIndex ? 'white' : 'black',
                                                    alignItems: "center"
                                                }
                                            }
                                        } else {
                                            return {}
                                        }}
                                    }
                                    // as React Table is outsdde the main function, state varaibles cant be accessed there 
                                    // so passing all the state variables on which react table is dependent through passedStateVariable
                                    passedStateVariables = {[state.selectedProdName, state.dataBasedOnSingleDate, state.signleDateDetailedData,
                                        props.ShowProductionCount, state.showDownload, state.selectedRowIndex, state.loading]}
                                 />
                            </div>
                        :
                            null
                        }

                        <Modal size="xl" isOpen={state.showIndividualRowInfo} toggle={toggleshowIndividualRowInfo} backdrop={state.backdrop}>
                            <ModalHeader toggle={toggleshowIndividualRowInfo}> 
                                {state.ShowProductionCount == false ? "Stagewise Product Status Per Date" : "Production Count" }
                            </ModalHeader>
                            <ModalBody>
                                
                                <div>    
                                    <div className="ProdInfo" style={{marginTop:"0rem"}}>
                                        <div style={{flex:"3"}}/>
                                        <button type="button"
                                            onClick = {onModalDownloadClickForIndividualRowData}
                                            style={{borderRadius: "5px", 
                                                    backgroundColor: "var(--primaryColor)", color:"white",
                                                    fontWeight:"700",
                                                }}
                                            className="btn-sm"
                                        >
                                            Download
                                        </button>
                                    </div>                                    
                                    <div className="ProdInfo">
                                        <div className="subProdInfoFirst">
                                            <label className="ProdInfolabel" >Product Code:</label>
                                            <input value={state.selectedRowProdCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                        </div>
                                        <div className="subProdInfoSecond">
                                            <label className="ProdInfolabel">Product Name Code:</label>
                                            <input value={state.selectedRowProdNameCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                        </div>
                                    </div>
                                    <div className="ProdInfo">
                                        <div className="subProdInfoFirst">
                                            <label className="ProdInfolabel">Product Line:</label>
                                            <input value={state.selectedRowProdLine} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                        </div>
                                        <div className="subProdInfoSecond">
                                            <div className="ProdInfolabelDiscription">Product Description:</div>
                                            <textarea value={state.selectedRowProdDescription} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>  
                                        </div>
                                    </div>
                                    {state.errors.others.length > 0 && 
                                        <p style={{color:"Red", fontSize:"0.8rem", textAlign:"center"}} className='error'>{state.errors.others}</p> 
                                    }
                                </div>
                                {state.arrStagewiseProdStatus.length > 0 ?
                                    // <div style={{marginTop:"1rem", border: "1px solid black", textAlign:"center"}}>
                                    <div className="border border-3 ReactTableStyle" style = {{marginTop:"1rem"}}>
                                        <ReactTable 
                                            data={state.dataBasedOnSingleDate}
                                            columns={state.column}
                                            renderBasedOnTable = {"DataBasedOnSingleDatesTable"}
                                            getCellProps={onCellClick}
                                            // as React Table is outsdde the main function, state varaibles cant be accessed there 
                                            // so passing all the state variables on which react table is dependent through passedStateVariable
                                            passedStateVariables = {[state.selectedProdName, state.dataBasedOnSingleDate, state.signleDateDetailedData,
                                                props.ShowProductionCount, state.showDownload, state.selectedRowIndex, state.loading]}
                                        />
                                    </div>
                                :
                                    null
                                }
                            </ModalBody>
                        </Modal>


                        <Modal size="xl" isOpen={state.showSingleModalDateDetailedStatus} toggle={toggleShowDetailedSingleDateProdStatus} backdrop={state.backdrop}>
                            <ModalHeader toggle={toggleShowDetailedSingleDateProdStatus}> 
                                {`Details on selected date for product state '${state.selectedCellState}'.`}
                            </ModalHeader>
                            <ModalBody>
                                <div>                                      
                                    <div className="ProdInfo">
                                        <div className="subProdInfoFirst">
                                            <label className="ProdInfolabel" >Product Code:</label>
                                            <input value={state.selectedRowProdCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                        </div>
                                        <div className="subProdInfoSecond">
                                            <label className="ProdInfolabel">Product Name Code:</label>
                                            <input value={state.selectedRowProdNameCode} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                        </div>
                                    </div>
                                    <div className="ProdInfo">
                                        <div className="subProdInfoFirst">
                                            <label className="ProdInfolabel">Product Line:</label>
                                            <input value={state.selectedRowProdLine} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>

                                        </div>
                                        <div className="subProdInfoSecond">
                                            <div className="ProdInfolabelDiscription">Product Description:</div>
                                            <textarea value={state.selectedRowProdDescription} className="ProdInfoInput" style={{background:"#F5F5F5"}} readOnly/>  
                                        </div>
                                    </div>
                                </div>

                                {state.signleDateDetailedData.length > 0 ?
                                    // <div style={{marginTop:"1rem", border: "1px solid black", textAlign:"center"}}>
                                    <div className="border border-3 ReactTableStyle" style = {{marginTop:"1rem"}}>
                                        <ReactTable
                                            data={state.signleDateDetailedData}
                                            columns={state.detailedProductStatuscolumn}
                                            renderBasedOnTable = {"SignleDateDetailedDataTable"}
                                            getCellProps = {(cellInfo) => ({})}
                                            // as React Table is outsdde the main function, state varaibles cant be accessed there 
                                            // so passing all the state variables on which react table is dependent through passedStateVariable
                                            passedStateVariables = {[state.selectedProdName, state.dataBasedOnSingleDate, state.signleDateDetailedData,
                                                props.ShowProductionCount, state.showDownload, state.selectedRowIndex, state.loading]}
                                        />
                                    </div>
                                :
                                    null
                                }

                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VcStagewiseProdStat;
