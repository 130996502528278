import React, { useState, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext } from '../../AppContext';
import { convertUTCDateToStrYYYYMMDDHH24MMSS, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS} from '../../vtUtil';
import { IDS_RDEndDtGrtCurDt, IDS_RDEndDTAdjust, IDS_RDStartDTAdjust, IDS_RDStartDTAdjustNotMaxRng, IDS_RDStartDTSetAcrodEndDT, IDS_RDStartDTAdjustMinPossible,
        IDS_RDEndDTNotInRng, IDS_RDEndDTSetAcrodStartDT } from '../../VcLanguage';
import { FaSearch} from 'react-icons/fa';
import { CSV_COLUMN_SEPERATOR, CSV_LINE_SEPERATOR, MAX_DAYS_RANGE, MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA } from '../../VcConstants';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"0.8rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.8rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    )
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data }) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // set page size based on tables
            initialState: {pageSize: 10},
            filterTypes,
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp; No Data Found</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr"  
                                    style={{
                                        cursor: '',
                                        background: '',
                                        color: 'black',
                                        alignItems: "center",
                                    }}  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style},
                                        ]
                                    )} className="td">{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || 
                        //     // when there is no data found 
                        //     <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex" ,justifyContent:"left"}}>{"No Data Found."}</span>
                        //         </td>
                        //     </tr>                            
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcAllTakenBackRentalDevices (props) {
    const searchStringStyle = {fontSize: "0.8rem", width:"85%"};
    const csvLink = React.createRef();
    let textFile = null;
    const context = useContext(AppRelevantDataContext);

    const filterCaseInsensitive = (rows, id, filterValue) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;


        if (id == "LastModifiedTime" || id == "DtTmWhenDvcTknBack"){

            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]                        
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            })
        } else {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
            })
        }
    };

    const [state, setState] = useState({            
        StartDateTime: new Date(new Date().setHours(new Date().getHours() - 24)),
        EndDateTime: new Date(),
        csvData : [],
        AllTakenBackRentalDevicesForSelectedTimePeriod: [],
        backdrop: 'static',
        errors: {
            others: "",
            timeRelatedErrors:""
        },
        tknBackRentalDvcCol:[
            {       
                Header:() => <div className="AlertLogTableHeader">Device ID</div>, 
                accessor: 'DeviceID',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Order<br/>Number</div>, 
                accessor: 'OrderNumber',
                filter: filterCaseInsensitive,
                width: 110,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Owner<br/>Email ID</div>,  
                accessor: 'OwnerEmailID',
                filter: filterCaseInsensitive,
                width: 120,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Date Time when Device<br/>Taken Back</div>, 
                accessor: 'DtTmWhenDvcTknBack',
                filter: filterCaseInsensitive,
                width: 210,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Returned Security<br/>Deposit (INR)</div>, 
                accessor: 'ReturnedSecurityDepositINR',
                filter: filterCaseInsensitive,
                width: 200,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Returned Rental Period<br/>Amount (INR)</div>, 
                accessor: 'ReturnedRentalPeriodAmountINR',
                filter: filterCaseInsensitive,
                width: 210,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Total Amount<br/>Returned (INR)</div>, 
                accessor: 'TotalAmountReturnedINR',
                filter: filterCaseInsensitive,
                width: 180,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Owner<br/>Name</div>,  
                accessor: 'OwnerName',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Owner<br/>Phone No</div>,  
                accessor: 'OwnerPhoneNo',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Sales Rep<br/>Email ID</div>, 
                accessor: 'SalesRepEmailID',
                filter: filterCaseInsensitive,
                width: 140,
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">LastModifiedTime</div>,  
                accessor: 'LastModifiedTime',
                filter: filterCaseInsensitive,
                width: 200,
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = firstRow.original[columnId] == null ? "" : new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = secondRow.original[columnId] == null ? "" : new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                style:({
                    textAlign:"right",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
        ]
    });

    const onChangeStartDateTime = (updatedTime) => {

        let modifiedState = state;
        modifiedState.errors.others = '';

        let updatedActiveStartDateTime = updatedTime;

        modifiedState.StartDateTime = updatedActiveStartDateTime;
        setState({...modifiedState});
    }

    const onChangeEndDateTime = (updatedTime) => {
        
        let modifiedState = state; 
        modifiedState.errors.others = "";

        let updatedEndDateTime = updatedTime;
        modifiedState.EndDateTime = updatedEndDateTime;

        setState({...modifiedState});
    }

    const onStartDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;    
        let strMsg = '';

        let modifiedState = state;

        let currentlySetEndDateTime = modifiedState.EndDateTime;

        // Incase time is greater than currentdatetime.
        let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
        minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

        
        let currentlySetStartDateTime = modifiedState.StartDateTime;

        // Incase of startDateTime greater than EndDateTime. Set EndDateTime According to StartDateTime.
        let minRequiredEndDateTime = new Date(currentlySetStartDateTime.valueOf());
        minRequiredEndDateTime.setHours(minRequiredEndDateTime.getHours() + 24);

        // required to compare Range between Start and End Date Time.
        let minRequiredDateRange = new Date(currentlySetEndDateTime.valueOf());
        minRequiredDateRange.setDate(minRequiredDateRange.getDate() - MAX_DAYS_RANGE);

        let currentDateTime = new Date();

        // Give a tolerance of -1 minutes to the current date time to avoid unnecessary messages
        // currentDateTime.setMinutes(currentDateTime.getMinutes() - 1)                

        let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
        let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );
        
        if(currentlySetStartDateTime != null && currentlySetStartDateTime >= currentDateTime) {

            // strMsg = `Start Date Time cannot be greater than the current date time. It will be set according to End date time.`;
            strMsg = t(IDS_RDStartDTSetAcrodEndDT);
            
            modifiedState.errors.timeRelatedErrors = strMsg;
            modifiedState.StartDateTime = minRequiredStartDateTime;

        } else if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {

            // strMsg = `Start Date Time will be adjusted because it is not within a Range. The time will be adjusted to minimum possible for the day.`;
            strMsg = t(IDS_RDStartDTAdjustMinPossible);
            
            modifiedState.errors.timeRelatedErrors = strMsg;

            modifiedState.StartDateTime = new Date(currentlySetEndDateTime.valueOf());
            modifiedState.StartDateTime.setHours(0);
            modifiedState.StartDateTime.setMinutes(0);
            modifiedState.StartDateTime.setSeconds(0);

        } else if(currentlySetStartDateTime >= currentlySetEndDateTime) {

            // strMsg = `End Date Time has been adjusted because it is not within a Range.`;
            strMsg = t(IDS_RDEndDTNotInRng);
            
            modifiedState.errors.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = minRequiredEndDateTime;

            if(modifiedState.EndDateTime >= currentDateTime) {
                modifiedState.EndDateTime = new Date();
            }

        } else if(currentlySetStartDateTime < minRequiredDateRange) {

            // strMsg = `End Date Time has been adjusted because it is not within a Maximum Range according to StartDateTime.`;
            strMsg = t(IDS_RDEndDTSetAcrodStartDT);
            
            modifiedState.errors.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = minRequiredEndDateTime;

        } else {
            strMsg = "";
            modifiedState.errors.timeRelatedErrors = strMsg;
        }

        setState({...modifiedState});

    }

    const onEndDateTimeCalendarOrClockClose = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  
        let strMsg ="";

        let modifiedState = state;

        let currentlySetEndDateTime = modifiedState.EndDateTime;

        let minRequiredStartDateTime = new Date(currentlySetEndDateTime.valueOf());
        minRequiredStartDateTime.setHours(minRequiredStartDateTime.getHours() - 24);

        let currentlySetStartDateTime = modifiedState.StartDateTime;

        let minRequiredDateRange = new Date(currentlySetStartDateTime.valueOf());
        minRequiredDateRange.setDate(minRequiredDateRange.getDate() + 31);

        let currentDateTime = new Date();

        if(currentlySetEndDateTime > currentDateTime) {
            // strMsg = 'End Date Time cannot be greater than the current date time. It will be set to current date time.';
            strMsg = t(IDS_RDEndDtGrtCurDt);

            modifiedState.errors.timeRelatedErrors = strMsg;
            modifiedState.EndDateTime = currentDateTime
        } else if(currentlySetEndDateTime <= currentlySetStartDateTime) {

            let strCurrentlySetStartDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetStartDateTime) );
            let strCurrentlySetEndDateTime =  convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(currentlySetEndDateTime) );

            if(strCurrentlySetStartDateTime == strCurrentlySetEndDateTime) {
                // strMsg = `End Date Time will be adjusted because it is not within a Range. The time will be adjusted to max possible for the day.`;
                strMsg = t(IDS_RDEndDTAdjust);
                
                modifiedState.errors.timeRelatedErrors = strMsg;
                modifiedState.EndDateTime = new Date(currentlySetStartDateTime.valueOf());
                modifiedState.EndDateTime.setHours(23);
                modifiedState.EndDateTime.setMinutes(59);
                modifiedState.EndDateTime.setSeconds(59);
            } else {

                // strMsg = `Start Date Time will be adjusted because it is not within a Range. It will be set according to End Date Time.`;
                strMsg = t(IDS_RDStartDTAdjust);
                
                modifiedState.errors.timeRelatedErrors = strMsg;

                modifiedState.StartDateTime = minRequiredStartDateTime
            }
            
            if(modifiedState.EndDateTime > currentDateTime) {
                modifiedState.errors.timeRelatedErrors = strMsg;

                modifiedState.EndDateTime = currentDateTime
            }

        } else if(currentlySetEndDateTime > minRequiredDateRange) {

            // strMsg = `Start Date Time has been adjusted because it is not within a Maximum Range according to EndDateTime.`;
            strMsg = t(IDS_RDStartDTAdjustNotMaxRng);
            
            modifiedState.errors.timeRelatedErrors = strMsg;
            modifiedState.StartDateTime = minRequiredStartDateTime;

        } else {
            strMsg = "";
            modifiedState.errors.timeRelatedErrors = strMsg;
        }
        
        setState({...modifiedState});

    }

    const OnClickViewButton = () => {
        let modifiedState = state;
        getAllTakenBackRentalDeviceInfoForSelectedDate(modifiedState,);
    }

    const OnClickDownloadAsCsvButton = () => {
        let bDownloadCsvOfAllTknBckRentalDvcInfo = true;
        let modifiedState = state;
        getAllTakenBackRentalDeviceInfoForSelectedDate(modifiedState, bDownloadCsvOfAllTknBckRentalDvcInfo)
    }

    const OnClickDownloadDataAsCsvButtonInView = () => {
        let modifiedState = state;

        if(modifiedState.AllTakenBackRentalDevicesForSelectedTimePeriod.length > 0) {
            DownloadCsv(modifiedState.AllTakenBackRentalDevicesForSelectedTimePeriod);
        } else {
            console.error("Downloaded data could not be saved to file.")
        }
    }

    const getAllTakenBackRentalDeviceInfoForSelectedDate = (inModifiedState = null, bDownloadCsvOfAllTknBckRentalDvcInfo= false) => {

        let modifiedState;
        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        let StartDateTime = modifiedState.StartDateTime;
        let EndDateTime = modifiedState.EndDateTime;
        modifiedState.timeRelatedErrors = '';
        modifiedState.AllTakenBackRentalDevicesForSelectedTimePeriod = [];
        let strStartDateTime = null;
        let strEndDateTime = null;

        if((props.invokedFrom == null || props.invokedFrom != "AllTakenBackRentalDevices")) {
            strStartDateTime = null;
            strEndDateTime = null;
        } else {

            let diffBetweenTwoDates = Math.abs((StartDateTime - EndDateTime)/ (1000*60*60*24) );

            if( diffBetweenTwoDates > MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA ) {
                if(!window.confirm(`Are you sure you want to view the large data for more than 90 days of period?`)){
                    return
                }
            }

            strStartDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(StartDateTime) );
            strEndDateTime = convertUTCDateToStrYYYYMMDDHH24MMSS( new Date(EndDateTime) );

        }

        const jsonDeviceInfo = {
            StartDateTime : strStartDateTime,
            EndDateTime : strEndDateTime,
        }

        axios.post(`${getAPIHostURL()}/wclient/getAllTakenBackRentalDeviceInfo`, jsonDeviceInfo)    
        .then( response => {
            if(response.data.code == 'SUCCESS') {
                let receivedAllTknBckRentalDvcInfo = response.data.allTakenBackRentalDeviceInfo;

                if( receivedAllTknBckRentalDvcInfo == null || receivedAllTknBckRentalDvcInfo.length <= 0 ) {
                    modifiedState.errors.others = "For Selected Time Period does not contain any taken back rental device Data.";
                } else {
                    let receivedAllTknBckRentalDvcInfoLen = receivedAllTknBckRentalDvcInfo.length;

                    for(let i = 0; i < receivedAllTknBckRentalDvcInfoLen; i++) {

                        let OwnerFullName = receivedAllTknBckRentalDvcInfo[i]["FirstName"] + " " + receivedAllTknBckRentalDvcInfo[i]["LastName"]
                        let strLastModifiedDateTimeLocale = (receivedAllTknBckRentalDvcInfo[i]["LastModifiedTime"] == null) ? null :convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedAllTknBckRentalDvcInfo[i]["LastModifiedTime"]);

                        let singleInstanceInfo = {
                            DeviceID: receivedAllTknBckRentalDvcInfo[i]["ItemID"] == null ? '-': receivedAllTknBckRentalDvcInfo[i]["ItemID"],
                            OrderNumber: receivedAllTknBckRentalDvcInfo[i]["OrderNumber"] == null ? '-': receivedAllTknBckRentalDvcInfo[i]["OrderNumber"],
                            OwnerEmailID: receivedAllTknBckRentalDvcInfo[i]["OwnerEmailID"] == null ? '-': receivedAllTknBckRentalDvcInfo[i]["OwnerEmailID"],
                            DtTmWhenDvcTknBack: receivedAllTknBckRentalDvcInfo[i]["DtTmWhenDvcTknBack"] == null ? '-': convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(receivedAllTknBckRentalDvcInfo[i]["DtTmWhenDvcTknBack"]),
                            ReturnedSecurityDepositINR: receivedAllTknBckRentalDvcInfo[i]["SecurityDepositToReturn"] == null ? '-': receivedAllTknBckRentalDvcInfo[i]["SecurityDepositToReturn"],
                            ReturnedRentalPeriodAmountINR: receivedAllTknBckRentalDvcInfo[i]["SubscriptionAmtToReturn"] == null ? '-': receivedAllTknBckRentalDvcInfo[i]["SubscriptionAmtToReturn"],
                            TotalAmountReturnedINR: receivedAllTknBckRentalDvcInfo[i]["TotalAmountReturned"] == null ? '-': receivedAllTknBckRentalDvcInfo[i]["TotalAmountReturned"],
                            OwnerPhoneNo: receivedAllTknBckRentalDvcInfo[i]["OwnerMobileNo"] == null ? '-': receivedAllTknBckRentalDvcInfo[i]["OwnerMobileNo"],
                            OwnerName: OwnerFullName == null ? '-': OwnerFullName,
                            SalesRepEmailID: receivedAllTknBckRentalDvcInfo[i]["SalesRepEmailID"] == null ? '-': receivedAllTknBckRentalDvcInfo[i]["SalesRepEmailID"],
                            LastModifiedTime: strLastModifiedDateTimeLocale == null ? '-': strLastModifiedDateTimeLocale,
                        }
                    
                        modifiedState.AllTakenBackRentalDevicesForSelectedTimePeriod.push(singleInstanceInfo);
                    }

                    if(bDownloadCsvOfAllTknBckRentalDvcInfo == true) {
                        DownloadCsv(modifiedState.AllTakenBackRentalDevicesForSelectedTimePeriod)
                    }
                    modifiedState.errors.others = "";
                    modifiedState.timeRelatedErrors = "";
                }
            } else {

                if(response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the Required parameters were not sent to the Server
                    modifiedState.errors = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                    
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});

        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }
        });
    }

     const DownloadCsv = (inCsvData) => {      

        let modifiedState = state;

        let arrColumnKeys = [ "Order Number", "Owner Email ID", "Date Time When Device Taken Back", "Returned Security Deposit (INR)", "Returned Rental Period Amount (INR)", "Total Amount Returned (INR)",
                            "Owner Phone No", "Owner Name", "Sale Rep Email ID", "LastModifiedTime"];
        let strCsvHeaderLine = "Device ID";
        let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
        let strSingleCsvDataLine = "";
        let strColumnName = "";
        modifiedState.csvData = [];

        // Create rest of the columns in the header
        let noOfParams = arrColumnKeys.length;
        for (let j=0; j<noOfParams; j++) {
            strColumnName = arrColumnKeys[j];
            strCsvHeaderLine += (CSV_COLUMN_SEPERATOR + strColumnName);
        }

        let receivedAllTknBckRentalDvcInfo = inCsvData;
        let receivedAllTknBckRentalDvcInfoLen = inCsvData.length;
        let allInstanceInfo = [];

        // First push the header CSV line
        arrCsvAllLines.push(strCsvHeaderLine);

        for(let i = 0; i < receivedAllTknBckRentalDvcInfoLen; i++) {

            let singleInstanceInfo = {
                DeviceID: receivedAllTknBckRentalDvcInfo[i]["DeviceID"],
                OrderNumber: receivedAllTknBckRentalDvcInfo[i]["OrderNumber"],
                OwnerEmailID: receivedAllTknBckRentalDvcInfo[i]["OwnerEmailID"],
                DtTmWhenDvcTknBack: receivedAllTknBckRentalDvcInfo[i]["DtTmWhenDvcTknBack"],
                ReturnedSecurityDepositINR: receivedAllTknBckRentalDvcInfo[i]["ReturnedSecurityDepositINR"],
                ReturnedRentalPeriodAmountINR: receivedAllTknBckRentalDvcInfo[i]["ReturnedRentalPeriodAmountINR"],
                TotalAmountReturnedINR: receivedAllTknBckRentalDvcInfo[i]["TotalAmountReturnedINR"],
                OwnerPhoneNo: receivedAllTknBckRentalDvcInfo[i]["OwnerPhoneNo"],
                OwnerName: receivedAllTknBckRentalDvcInfo[i]["OwnerName"],
                SalesRepUserID: receivedAllTknBckRentalDvcInfo[i]["SalesRepEmailID"],
                LastModifiedTime: receivedAllTknBckRentalDvcInfo[i]["LastModifiedTime"],
            }

            allInstanceInfo.push(singleInstanceInfo);

            strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                ((receivedAllTknBckRentalDvcInfo[i]["DeviceID"] == null) ? '-' : receivedAllTknBckRentalDvcInfo[i]["DeviceID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllTknBckRentalDvcInfo[i]["OrderNumber"] == null) ? '-' : receivedAllTknBckRentalDvcInfo[i]["OrderNumber"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllTknBckRentalDvcInfo[i]["OwnerEmailID"] == null) ? '-' : receivedAllTknBckRentalDvcInfo[i]["OwnerEmailID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllTknBckRentalDvcInfo[i]["DtTmWhenDvcTknBack"] == null) ? '-' : receivedAllTknBckRentalDvcInfo[i]["DtTmWhenDvcTknBack"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllTknBckRentalDvcInfo[i]["ReturnedSecurityDepositINR"] == null) ? '-' : receivedAllTknBckRentalDvcInfo[i]["ReturnedSecurityDepositINR"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllTknBckRentalDvcInfo[i]["ReturnedRentalPeriodAmountINR"]== null) ? '-' : receivedAllTknBckRentalDvcInfo[i]["ReturnedRentalPeriodAmountINR"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllTknBckRentalDvcInfo[i]["TotalAmountReturnedINR"]== null) ? '-' : receivedAllTknBckRentalDvcInfo[i]["TotalAmountReturnedINR"] ) +
                CSV_COLUMN_SEPERATOR +
                ((receivedAllTknBckRentalDvcInfo[i]["OwnerPhoneNo"] == null) ? '-' : receivedAllTknBckRentalDvcInfo[i]["OwnerPhoneNo"] ) +
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllTknBckRentalDvcInfo[i]["OwnerName"] == null) ? '-' : receivedAllTknBckRentalDvcInfo[i]["OwnerName"] ) +                
                CSV_COLUMN_SEPERATOR +
                ( (receivedAllTknBckRentalDvcInfo[i]["SalesRepEmailID"] == null) ? '-' : receivedAllTknBckRentalDvcInfo[i]["SalesRepEmailID"] ) +
                CSV_COLUMN_SEPERATOR +
                ( receivedAllTknBckRentalDvcInfo[i]["LastModifiedTime"] == null ? '-' : receivedAllTknBckRentalDvcInfo[i]["LastModifiedTime"] );

            arrCsvAllLines.push(strSingleCsvDataLine);
        }

        modifiedState.csvData = allInstanceInfo;
        // Create CSV File and fill it
        let bFileCreationStatus = createAndFillCsvFile(arrCsvAllLines);

        alert(`All Taken Back Rental Devices Data download has started. Check your Browser's status bar or Downloads Folder.`);

        if (bFileCreationStatus) {
            // CSV file creation successful.
            // Close the date selection modal.

        } else {
            modifiedState.errors = 'Downloaded data could not be saved to file.';
        }

        setState({...modifiedState});
    } 

    const makeTextFile = (arrText) =>  {

        let data = new Blob(arrText, {type: 'text/plain'});

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (textFile !== null) {
            window.URL.revokeObjectURL(textFile);
        }

        textFile = window.URL.createObjectURL(data);

        // Return a URL you can use as an href
        return textFile;
    }

    const createAndFillCsvFile = (inarrCsvAllLines) => {

        let retVal = true;

        let foramttedStartDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.StartDateTime);
        let foramttedEndDateTime = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(state.EndDateTime);
        let fileName = "All Taken Back Rental Devices " + foramttedStartDateTime + " To " + foramttedEndDateTime + ".csv";

        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = makeTextFile(inarrCsvAllLines);
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            let event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });

        // Return true to indicate that operation performed successfully (in future any failures in 
        // the previous calls can be checked to return false)
        retVal = true;
        return retVal;
    }

    return(
        <div>
            <div className="row justify-content-center">
                <div className="container col-lg-8 col-md-8">
                    <div className="modal-body box" style={{padding: "1rem"}}>
                        <div className = "headingForComponentsOfCrmPage" 
                            style={{marginBottom: "1.2rem", display: "block", alignSelf: "center"
                            }}
                        >
                            Report - All Taken Back Rental Devices
                        </div>
                        <div style={{display:"flex", justifyContent:"space-evenly"}} className="startAndEnddate">
                            <div className="form-group update-form">
                                <div className="inputgroupCustom">
                                    <label className="adduser-form-label">Start Date Time:</label>
                                    <DateTimePicker
                                        clearIcon={null}
                                        onChange={onChangeStartDateTime}
                                        onCalendarClose={onStartDateTimeCalendarOrClockClose}
                                        onClockClose={onStartDateTimeCalendarOrClockClose}
                                        className="input-form-datetime"
                                        format={"yyyy/MM/dd HH:mm:ss"} 
                                        value={state.StartDateTime}    
                                        name="StartDateTime"
                                    />
                                </div>
                            </div>

                            <div className="form-group update-form">
                                <div className="inputgroupCustom">
                                    <label className="adduser-form-label">End Date Time:</label>
                                    <DateTimePicker
                                        clearIcon={null}
                                        selected={state.EndDateTime}
                                        onChange={onChangeEndDateTime}
                                        onCalendarClose={onEndDateTimeCalendarOrClockClose}
                                        onClockClose={onEndDateTimeCalendarOrClockClose}
                                        className="input-form-datetime"
                                        format={"yyyy/MM/dd HH:mm:ss"} 
                                        value={state.EndDateTime} 
                                        name="EndDateTime"
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{fontSize: "0.8rem", color: "green", textAlign:"center"}}>{state.errors.timeRelatedErrors}</div>
                        <div style={{display:"flex", justifyContent:"center"}} >
                            <div>
                                <button type="button"
                                        onClick={OnClickDownloadAsCsvButton} 
                                        className="btn-sm reg-btn"
                                        style={{textTransform: "capitalize", color:"white", fontWeight:"bold", fontSize: "0.9rem"}}
                                >
                                    Download As CSV 
                                </button>
                            </div>
                            <div>
                                <button type="button"
                                        className="btn-sm reg-btn"
                                        style={{textTransform: "capitalize", color:"white", marginLeft:"2rem", fontWeight:"bold", width:"100%", fontSize: "0.9rem"}} 
                                        onClick={OnClickViewButton}>View
                                </button>
                            </div>
                        </div>
                        <div style={{fontSize: "0.8rem", color: "var(--errorColor)", textAlign:"center"}}>{state.errors.others}</div>
                    </div>
                </div>
            </div>
            {(state.AllTakenBackRentalDevicesForSelectedTimePeriod != null && state.AllTakenBackRentalDevicesForSelectedTimePeriod.length > 0)
            &&
                <div className="row justify-content-center">
                    <div className="container col-lg-12 col-md-12">
                        <div className="modal-body box" style={{padding: "1rem"}}>
                            <div style = {{display: "flex", justifyContent: "space-between", padding: "0.2rem" }}>
                                <div>
                                    <button type = "button" 
                                        className = "btn-md addCustBtn" 
                                        style = {{background: "transparent", pointerEvents: "none", border: "none", 
                                                    color:"transparent"}} 
                                    >
                                        Download As CSV
                                    </button>  
                                </div>
                                <div className = "headingForComponentsOfCrmPage" style={{alignSelf:"center"}}>
                                    All Taken Back Rental Devices
                                </div>
                                <div>
                                    <button style={{backgroundColor:"var(--primaryColor)", border:"1px solid var(--primaryColor)", borderRadius: "0.3rem", color: "white", fontWeight:"bold"}} onClick={OnClickDownloadDataAsCsvButtonInView}>Download As CSV</button>
                                </div>
                            </div>
                            <div className="border border-3 ReactTableStyle">
                                <ReactTable 
                                    columns={state.tknBackRentalDvcCol} 
                                    data={state.AllTakenBackRentalDevicesForSelectedTimePeriod}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default VcAllTakenBackRentalDevices;