import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Legend,
  Filler // 1. Import Filler plugin
} from "chart.js";
import { RNFL } from "../../../../VcConstants";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler 
);

function VcSimpleBarChart({paramData,paramTimes, paramName, getParamValueTextAndBoxStyleBasedOnRange, getUnits}) {
    
    const getColor = (val) => {
        if(paramName == RNFL) {
            return '#0958d9';
        }
        try {
            let { styleValueText, styleValueBox } = getParamValueTextAndBoxStyleBasedOnRange(val, paramName);

            if(styleValueText == 'ParamsValueRangeGood') {
                return '#50c878';
            } else if (styleValueText == 'ParamsValueRangeSatisfactory') {
                return '#9acd32';
            } else if (styleValueText == 'ParamsValueRangeModerate') {
                return '#ffff00';
            } else if (styleValueText == 'ParamsValueRangePoor') {
                return '#ffa500';
            } else if (styleValueText == 'ParamsValueRangeLowVPoor') {
                return '#ff2929';
            } else if (styleValueText == 'ParamsValueRangeVeryPoor') {
                return '#ff0000';
            } else if (styleValueText == 'ParamsValueRangeSevere') {
                return '#c00000'
            } else if (styleValueText == 'ParamsValueMissingOrWithoutRange') {
                return '#00000069'
            }
        } catch(err) {
            console.log(err);
            return '#00000069'
        }
        return 'gray'
        
    }

    let x  = [1, 2, 3, 4, 5, 6]
    let j = 0;
    if(paramTimes) {
        paramTimes.forEach(time => {
            x[j] = `${time} min ago`;
            j++;
        })
    } else {
    }
   
    const labels = x

    let i = 0;
    let fdata = [null, null, null, null, null, null];
    if(paramData) {
        paramData.forEach(data => {
            fdata[i] = data;
            i++;
        })
    }
 

    const data = {
    labels: labels,
    datasets: [{
        label: paramName,
        data: fdata,
        type:'bar',
        borderColor: fdata.map(val => getColor(val)),
        backgroundColor: fdata.map(val => { return getColor(val)+'60'}),
        borderWidth: 1,
    },
    {
        label: paramName,
        data: fdata,
        type: 'line',
        showLine:false,
        pointHoverRadius: fdata.map(val => val==0 ? 7: 0),
        pointBackgroundColor: fdata.map(val => val==0 ? getColor(val): 'transparent'),
        pointRadius: fdata.map(val => val==0 ? 4: 0),
    }
   
    ],

    };
    const barconfig = {
    data: data,
    
    options: {
        responsive: true,
        barThickness: 13,
        plugins: {
            legend: {
                display: false // Hide legend
            },
            focus: true,       
            tooltip: {
                filter: function (tooltipItems) {
                    if(tooltipItems.datasetIndex == 0) {
                        return tooltipItems.raw != null && tooltipItems.raw != 0;
                    } else {
                        return tooltipItems.raw == 0;
                    }
                  },
                callbacks: {
                  label: function(context) {
                    let label = paramName + ": " + context.formattedValue + ' ' + getUnits(paramName);
                    return label;
                  },
                  
                },
            }
        },

        scales: {
            y: {
                ticks: {
                    display: false,
                },
                beginAtZero: true,
                display: false,
                // type: 'linear',
                grid: {
                  display: false,
                },
                                            
            },
            x: {
                ticks: {
                    display: false,
                },
                labels:labels,
                beginAtZero: true,
                display: true,
                grid: {
                  display: false,
                },                         
            }                      
        },
    },
    };

  return <Bar options={barconfig.options}   data={barconfig.data} />;

}

export default VcSimpleBarChart;