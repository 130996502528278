import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import { Provider } from "react-redux";
import { appStore } from './Component/JS/Store/appStore';

import * as serviceWorker from './serviceWorker';

const root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={appStore}>
        <App />
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
