import { IDS_RangeAlertLevel0LD, IDS_RangeAlertLevel1LD, IDS_RangeAlertLevel2LD, IDS_RangeAlertLevel3LD,
        IDS_RangeAlertLevel4LD, IDS_RangeAlertLevel5LD, IDS_RangeAlertLevel0SD, IDS_RangeAlertLevel1SD,
        IDS_RangeAlertLevel2SD, IDS_RangeAlertLevel3SD, IDS_RangeAlertLevel4SD, IDS_RangeAlertLevel5SD, 
        IDS_NH3ODRangeAlertLevel1SD, IDS_NH3ODRangeAlertLevel2SD, IDS_NH3ODRangeAlertLevel3SD, 
        IDS_NH3ODRangeAlertLevel4SD, IDS_NH3ODRangeAlertLevel5SD, IDS_RangeAlertLevel6SD, IDS_NH3ODRangeAlertLevel6SD, IDS_RangeAlertLevel6LD} from '../src/VcLanguage';

//User Privileges
export const PVG_MAP_TOILET = 'MapToilet';
export const PVG_MAP_TOILETS_AND_DEVICE = 'MapToiletsAndDevice';
export const PVG_ADMIN = 'Admin';
export const PVG_GENERAL_USER = 'GeneralUser';
export const PVG_DEVC_OWNER = 'DeviceOwner';
export const PVG_DEVC_VIEWER = 'DeviceViewer';
export const PVG_DEVC_VIEWER_WITH_SETTING = 'DeviceViewerWithSettingsPvlg';
export const PVG_FWMR_ADMIN = 'FwmrAdmin';
export const PVG_SALES_EXECUTIVE = 'SalesExecutive';
export const PVG_SALES_SUPERVISOR = 'SalesSupervisor';
export const PVG_PRODUCTION_SUPERVISOR = 'ProductionSupervisor';
export const PVG_PRODUCTION_WORKER = 'ProductionWorker';
export const PVG_BACK_OFFICE_EXECUTIVE = 'BackOfficeExecutive';
export const PVG_PRODUCT_OWNER = 'ProductOwner';
export const PVG_FIELD_ENGINEER = 'FieldEngineer';
export const PVG_INST_PRIMARY_OWNER = 'InstitutionalPrimaryOwner';
export const PVG_INST_ADDITIONAL_VIEWER = 'InstitutionalAdditionalViewer';
export const PVG_INST_ADDITIONAL_VIEWER_WITH_SETTING = 'InstitutionalAdditionalViewerWithSettingsPvlg';
export const PVG_FWMR_FLASHING_ADMIN = 'FwmrFlashingAdmin';
export const PVG_TOILET_ADMIN = 'ToiletAdmin';
export const PVG_SW_DEV_ADMIN = 'SwDevAdmin';
export const PVG_TREE_DEFINITION_ADMIN = 'TreeDefinitionAdmin';
export const PVG_TREE_NODE_VIEWER = 'TreeNodeViewer';
export const PVG_EXTERNAL_API_USER = 'HHMApiUser';

// Toilet type
export const ALL_TOILET = 'All';
export const BRICK_N_MORTAR_TOILET = 'Brick n Mortar';
export const E_TOILET = 'eToilet';
export const SHE_TOILET = 'She Toilet';
export const CONTAINER_TOILET = 'Container Toilet';
export const GANDHVEDH_TOILETS = 'Gandhvedh Toilets';
export const HYGIENIC_TOILETS  = "Hygienic Toilets";
//Gandhvedh-R GPS toilet tracking (Please remove this code when code for Gandhvedh-R  is removed)
export const WHEEL = 'Toilets on Wheel';

// Toilet Sections
export const MEN_TOILET_SECTION = 'Men';
export const WOMEN_TOILET_SECTION = 'Women';

export const NO_DEVC_FOUND_FOR_LOGGED_IN_USER = 'NO_DEVC_FOUND_FOR_LOGGED_IN_USER';

export const TOO_MANY_SERVER_REQUESTS = 'TOO_MANY_SERVER_REQUESTS';
export const STD_DIFF_BW_TWO_AXIOS_CALLS = 5;
export const MAX_AXIOS_REQ = 100;
export const AXIOS_STOPPING_COUNTDOWN = 60;


export const AQI = 'AQI';
export const CAQI = 'CAQI';
export const CO = 'CO';
export const O3 = 'O3';
export const CH4 = 'CH4';
export const LPG = 'LPG';
export const CO2 = 'CO2';
export const HUM = 'HUM';
export const NO2 = 'NO2';
export const VOC = 'VOC';
export const PM10 = 'PM10';
export const PM25 = 'PM25';
export const TEMP = 'TEMP';
export const FIRE = 'FIRE';
export const LAT = 'LAT';
export const LON = 'LON';
export const PM1 = 'PM1';
export const NH3 = 'NH3';
export const NH4 = 'NH4';
export const H2S = 'H2S';
export const ALCO = 'ALCO';
export const SO2 = 'SO2';
export const NH3OD = 'NH3OD';
export const SMOKE = 'SMOKE';
export const VRI = "VRI";
export const MRI = "MRI";
export const PFC = "PFC";
export const BH = "BH";
export const LBH = "LBH";
export const MXC = "MXC";
export const NH3OD_RS = 'NH3OD_RS';
export const LOC = 'LOC';
export const TTS = 'TTS';
export const LAS = 'LAS';
export const SOA = 'SOA';
export const WLI = 'WLI';
export const WLIP = 'WLIP';
export const TCWL = 'TCWL';
export const VOL = 'VOL';
export const AWL = 'AWL';
export const AWC = 'AWC';
export const SLIP = 'SLIP';
export const STLI = 'STLI';

export const HCHO = 'HCHO';
export const O2 = 'O2';
export const PM100 = 'PM100';
export const NO = 'NO';
export const CL = 'CL';
export const CH3SH = 'CH3SH';
export const NOISE = 'NOISE';
export const LUX = 'LUX';
export const UV = 'UV';
export const RADON = 'RADON';
export const AP = 'AP';
export const WS = 'WS';
export const WD = 'WD';
export const DLV = 'DLV';
export const RNFL = 'RNFL';


// Range values
export const LOW = 'Low';
export const LOW_SEVERE_L = 'LowSevereL';
export const LOW_SEVERE_U = 'LowSevereU';
export const LOW_V_POOR_L = 'LowVPoorL';
export const LOW_V_POOR_U = 'LowVPoorU';
export const GOOD_L = 'GoodL';
export const GOOD_U = 'GoodU';
export const SATISFACTORY_L = 'SatisfactoryL';
export const SATISFACTORY_U = 'SatisfactoryU';
export const MODERATE_L = 'ModerateL';
export const MODERATE_U = 'ModerateU';
export const POOR_L = 'PoorL';
export const POOR_U = 'PoorU';
export const V_POOR_L = 'VPoorL';
export const V_POOR_U = 'VPoorU';
export const HIGH_SEVERE_L = 'HighSevereL';
export const HIGH_SEVERE_U = 'HighSevereU';
export const MAX = 'Max';

export const LOW_SEVERE = 'LowSevere';
export const LOW_V_POOR = 'LowVPoor';
export const GOOD = 'Good';
export const SATISFACTORY = 'Satisfactory';
export const MODERATE = 'Moderate';
export const POOR = 'Poor';
export const V_POOR = 'VPoor';
export const HIGH_SEVERE = 'HighSevere';

export const LOW_SEVERE_WHEN = 'LowSevereWhen';
export const LOW_V_POOR_WHEN = 'LowVPoorWhen';
export const GOOD_WHEN = 'GoodWhen';
export const SATISFACTORY_WHEN = 'SatisfactoryWhen';
export const MODERATE_WHEN = 'ModerateWhen';
export const POOR_WHEN = 'PoorWhen';
export const V_POOR_WHEN = 'VPoorWhen';
export const HIGH_SEVERE_WHEN = 'HighSevereWhen';

// Additional Range Constants
export const INCL_LOW_SEVERE_L = 'IncludeLowSevereL';
export const EXCL_LOW_SEVERE_U = 'ExcludeLowSevereU';
export const INCL_Low_V_POOR_L = 'IncludeLowVeryPoorL';
export const EXCL_LOW_V_POOR_U = 'ExcludeLowVeryPoorU';
export const INCL_GOOD_L = 'IncludeGoodL';
export const EXCL_GOOD_U = 'ExcludeGoodU';
export const INCL_SATISFACTORY_L = 'IncludeSatisfactoryL';
export const EXCL_SATISFACTORY_U = 'ExcludeSatisfactoryU';
export const INCL_MODERATE_L = 'IncludeModerateL';
export const EXCL_MODERATE_U = 'ExcludeModerateU';
export const INCL_POOR_L = 'IncludePoorL';
export const EXCL_POOR_U = 'ExcludePoorU';
export const INCL_V_POOR_L = 'IncludeVeryPoorL';
export const EXCL_V_POOR_U = 'ExcludeVeryPoorU';
export const INCL_HIGH_SEVERE_L = 'IncludeHighSevereL';
export const EXCL_HIGH_SEVERE_U = 'ExcludeHighSevereU';

export const PARAM_VALUE_MISSING = 'ParamValueMissing';
export const PARAM_STD_QUALITY_MISSING = 'ParamStdQualityMissing';
export const UNABLE_TO_CALCULATE_RANGE_FOR_PARAMVALUE = 'ParamUnableToCalculateRange';
export const PARAM_VALUE_LESS_THAN_LOWER_RANGE = 'ParamValueLessThanLowerRange';
export const PARAM_VALUE_GREATER_THAN_UPPER_RANGE = 'ParamValueGreaterThanUpperRange';
export const PARAM_MODEL_INFO_MISSING = 'ParamModelInfoMissing';


// Level based params
export const ARR_LEVEL_BASED_PARAMS = [NH3OD];
// Default Setting Levels for Level based params (in future, define different levels 
// for params which cannot use the default setting levels - for example HUM and TEMP)
export const ARR_DEFAULT_SETTING_LEVELS = ['L1', 'L2', 'L3', 'L4', 'L5', 'L6'];
// Setting long description displayed when a particular level is selected.
export const MAP_DEFAULT_LEVEL_SETTING_LONG_DESCRIPTION = {
    "L0": IDS_RangeAlertLevel0LD,
    "L1": IDS_RangeAlertLevel1LD,
    "L2": IDS_RangeAlertLevel2LD,
    "L3": IDS_RangeAlertLevel3LD,
    "L4": IDS_RangeAlertLevel4LD,
    "L5": IDS_RangeAlertLevel5LD,
    "L6": IDS_RangeAlertLevel6LD,
};
// Setting short description displayed when a particular level is selected.
export const MAP_DEFAULT_LEVEL_SETTING_SHORT_DESCRIPTION = {
    "L0": IDS_RangeAlertLevel0SD,
    "L1": IDS_RangeAlertLevel1SD,
    "L2": IDS_RangeAlertLevel2SD,
    "L3": IDS_RangeAlertLevel3SD,
    "L4": IDS_RangeAlertLevel4SD,
    "L5": IDS_RangeAlertLevel5SD,
    "L6": IDS_RangeAlertLevel6SD,
};
// Setting short description displayed when a particular level is selected for NH3OD.
export const MAP_NH3OD_LEVEL_SETTING_SHORT_DESCRIPTION = {
    "L1": IDS_NH3ODRangeAlertLevel1SD,
    "L2": IDS_NH3ODRangeAlertLevel2SD,
    "L3": IDS_NH3ODRangeAlertLevel3SD,
    "L4": IDS_NH3ODRangeAlertLevel4SD,
    "L5": IDS_NH3ODRangeAlertLevel5SD,
    "L6": IDS_NH3ODRangeAlertLevel6SD,
};

export const EARTH_RADIUS = 6371;
export const RADIUS_KM = 5;
export const IS_MILES = false;

// Minimum gap time after which if device does not send data, then it will be
// considered as inactive.
export const ACTIVE_STATUS_MINIMUM_TIME = 300; // 300 seconds equals 5 min.

// -------------------------- CRM: start --------------------------

export const ARR_SENSORS_WITH_SCANNED_VALUES = ["CO", "H2S", "SO2", "O3"];
export const SENSOR_SERIAL_NO = "SerialNo";
export const SENSOR_SENSITIVITY_CODE = "SensitivityCode";
export const SENSOR_MANUFACTURING_DATE = "ManufacturingDate";
export const PCB_ID = "PcbID";
export const SPEC_SENSOR_EXPECTED_LENGTH = "5";
export const FIGA_SENSOR_EXPECTED_LENGTH = "28";

// Order states.
export const ORDER_STATE_PLACED = "Placed";
export const ORDER_STATE_PROCESSING = "Processing";
export const ORDER_STATE_MARKED_FOR_SHIPPING = "MarkedForShipping";

// Order type.
export const ORDER_TYPE_DEMO = "Demo";
export const ORDER_TYPE_SALE = "Sale";
export const ORDER_TYPE_RENTAL = "Rental";
export const ORDER_TYPE_API = "API";

export const INCLUDES_ALL_PRODUCT_NAME = "All";

// Item states.
export const ITM_STATE_MANUFACTURED_AND_HARDWARE_TESTED = "Manufactured and Hardware Tested";
export const ITM_STATE_FUNCTIONAL_TESTED = "Functional Tested";
export const ITM_STATE_READY_FOR_SHIPMENT = "Ready for Shipment";
export const ITM_STATE_DEMO = "Demo";
export const ITM_STATE_SOLD = "Sold";
export const ITM_STATE_FAULTY = "Faulty";
export const ITM_STATE_UNDER_REPAIR = "Under Repair";
export const ITM_STATE_MAPPED_TO_OWNER = "Mapped to Owner";
export const ITM_STATE_MAPPED_AS_REPLACEMENT = "Mapped as Replacement";
export const ITM_STATE_DECOMMISSIONED_DEVICEID = "Decommissioned DeviceID";
export const ITM_STATE_DEVC_UPGRADE = "Upgrade";
export const ITM_STATE_DEVICE_TAKEN_BACK_FOR_REPLENISHMENT = "Device Taken Back For Replenishment";
export const ITM_STATE_RECALL_SOLD_DEVICE = "Recall Sold Device";
export const ITM_STATE_RENTAL = "Rental";

export const ITM_STATE_MAPPED_TO_OWNER_AND_MAPPED_AS_REPLACEMENT = "Mapped to Owner and Mapped as Replacement";
export const CURRENT_FIELD_DEVICES = "Current Field Devices";

export const ALERT_BASED = "AlertBased";
export const NO_CALIB = "NoCalib";
export const POSITION_PRIORITY = "PosPriority";

// CSV 
export const CSV_COLUMN_SEPERATOR = ',';
export const CSV_LINE_SEPERATOR = '\r\n';

// Date Range to view data for stagewise prod status.
export const MAX_DAYS_RANGE = '365';
export const MAX_DAYS_RANGE_TO_ASK_CONFIRMATION_TO_VIEW_DATA = '90';

// Track Devices in Production 
export const PROD_DEVC_OWNER_USER_ID = 'prod.devc.owner@viliso.in';

// Replaced Device Intial owner
export const DEVICE_RP_USER_ID = 'device.rp@viliso.in';

// Data Send Interval Time
export const MIN_DATA_SEND_INTERVAL_TIME_FOR_PROD_DVC_OWNER = 1;
export const MIN_DATA_SEND_INTERVAL_TIME = 5;
export const MAX_DATA_SEND_INTERVAL_TIME = 60;

// Single Device Data's Range of Sensors
export const ONWARDS = "Onwards";
export const DISP_LOW_SEVERE_RANGE = "DispLSevereRng";
export const DISP_LOW_VERY_POOR_RANGE = "DispLVPoorRng";
export const DISP_GOOD_RANGE = "DispGoodRng";
export const DISP_SATISFACTORY_RANGE = "DispSatisfactoryRng";
export const DISP_MODERATE_RANGE = "DispModRng";
export const DISP_POOR_RANGE = "DispPoorRng";
export const DISP_VERY_POOR_RANGE = "DispVPoorRng";
export const DISP_HIGH_SEVERE_RANGE = "DispHSevereRng";
export const UPTO = "Upto";


export const NO_CHART_PARAMS = [WD, LUX];

// CRM page Routes Based On User Pvg
export const PRODUCT_OWNER_ROUTES = [
                                    "/crm/productAction/productLine", "/crm/productAction/productDetails", "/crm/productAction/productSubscription"
                                    , "/crm/productAction/rentalProductDetails", "/crm/report/allStatesDashboard", "/crm/more/calibAppLinks", "/crm/more/hhmAppLinks",
                                    ];
export const SW_DEV_ADMIN_ROUTES = ["/crm/action/productItemState", "/crm/action/updateNewAppVersion"];
export const BACK_OFFICE_EXECUTIVE_ROUTES = ["/crm/report/deviceHistory", "/crm/cms/complaintDetails", "/crm/cms/registerComplaint", "/crm/cms/resolveComplaint",
                                             "/crm/track/trackDevices", "/crm/track/trackAppVersion", "/crm/track/trackRtcIssues", "/crm/report/inactiveFieldDevices", "/crm/report/allStatesDashboard"];
export const SALES_SUPERVISOR_AND_EXECUTIVE_ROUTES = ["/crm/report/allOrderDetails", , "/crm/report/currentStateInventory", "/crm/report/salesDashboard", "/crm/report/allTakenBackRentalDevices",
                                                     "/crm/customerOrder/customer","/crm/customerOrder/createOrder", "/crm/customerOrder/fulfillOrder","/crm/customerOrder/renewSubscription", "/crm/customerOrder/renewVilisoApiPkg",
                                                     "/crm/track/trackDevices", "/crm/track/trackAppVersion", 
                                                     "/crm/track/trackRtcIssues", "/crm/customerOrder/organization", "/crm/report/allSubscriptionOrRentalDetails",
                                                     "/crm/deviceAction/blockDeviceData", "/crm/deviceAction/unblockDeviceData", "/crm/customerOrder/CancelSubscription",
                                                     "/crm/report/allStatesDashboard", "/crm/report/rawDataAnalysis"];

export const FIELD_ENG_ROUTES = ["/crm/deviceAction/deviceReplacementUpgrade", "/crm/deviceAction/takeBackDemoDevice", "/crm/deviceAction/recallSoldDevice", 
                                "/crm/report/deviceHistory", "/crm/track/trackDevices", "/crm/track/trackAppVersion", "/crm/track/trackRtcIssues", "/crm/deviceAction/replaceFulfilledFaultyDevice", 
                                "/crm/more/rawDataParamsAction/addRawDataParams", "/crm/more/rawDataParamsAction/removeRawDataParams", "/crm/deviceAction/takeBackRentalDevice",
                                "/crm/customerOrder/CancelSubscription", "/crm/report/allStatesDashboard", "/crm/more/calibAppLinks", "/crm/more/hhmAppLinks",
                                "/sanitationMapping", "/sanitationMappingForm",
                            ];

export const PRODUCTION_SUPERVISOR_AND_WORKER_ROUTES = ["/crm/report/stagewiseProductStatus", "/crm/report/productionCount", "/crm/report/currentStateInventory", "/crm/report/deviceHistory", 
                                                        "/crm/report/allOrderDetails", 
                                                        "/crm/more/productPartAction/definePcbTypeAndVersion",
                                                        "/crm/more/productPartAction/productItemImpParts",
                                                        "/crm/action/productItemState", 
                                                        "/crm/track/trackDevices", "/crm/track/trackAppVersion", "/crm/track/trackRtcIssues", 
                                                        "/crm/customerOrder/fulfillOrder", 
                                                        "/crm/deviceAction/readyForShipment", "/crm/deviceAction/replaceFulfilledFaultyDevice", "/crm/deviceAction/moveDevcInDecommisionSt", "/crm/deviceAction/moveDevcInUnderRepairSt",
                                                        "/crm/customerOrder/organization", 
                                                        "/crm/more/rawDataParamsAction/addRawDataParams", "/crm/more/rawDataParamsAction/removeRawDataParams", 
                                                        "/crm/deviceAction/changeModel", "/crm/report/allStatesDashboard", "/crm/report/rawDataAnalysis", "/crm/more/calibAppLinks", "/crm/more/hhmAppLinks",];
                                                        
export const TOILET_ADMIN_ROUTES = ["/crm/report/viewToiletAndItsRatingInfo", "/crm/report/viewPublicFootContInfo", "/sanitationMappingForm"];

export const TREE_DEFINATION_ADMIN_ROUTES = ["/crm/more/treeDefinition", "/crm/customerOrder/organization", "/crm/report/allStatesDashboard"];

export const DEVICE_MENU_ROUTES = ["/device/dashboard", "/device/settings", "/device/charts", "/device/alerts", "/device/allDevices", "/device/addNode"];

// Challan refresh is the first API called in case the Challan DecryptKey is missing.
// Till that time, additional APIs get called and will fail. This will leave the UI in 
// an unpredictable state in case of Refresh. So, we retry the same API till the token 
// is refreshed. But, to prevent infinite loop, we will retry the single API only thrice.
export const MAX_API_RETRIES_FOR_SINGLE_API_TILL_CHALLAN_REFRESH = 3;

export const CLIENT_TYPE = "Web";

export const IV_KEY_GEN_VAL = "x3y2z1";
export const INVOKED_FROM_TRACK_DEVC = "InvokedFromTrackDevc";

export const MAX_NO_OF_DAY_FOR_VIEWING_DATA_AFTER_SUBS_EXPIRY = 7;

export const DAYS_IN_MONTH = 30;

// Related to subscription                                                        
export const ONE_MONTH_SUB_RENEWAL_PERIOD_IN_DAYS = 30;
export const DEFAULT_SUB_GRACE_PERIOD_IN_DAYS = 15;
export const DEFAULT_SUB_WARNING_PERIOD_IN_DAYS = 15;

export const SUBS_RENT_START = "Subscription/Rental Start";
export const SUBS_RENT_END = "Subscription/Rental End";
export const SUBS_RENT_CANCELLED = "Subscription/Rental Cancelled";

export const DEFAULT_RATING_LEVEL_STARS = "Stars";
export const DEFAULT_RATING_LEVEL_SIGNAL = "Signal";

export const ARR_DEFAULT_RATING_LEVEL = ["Stars", "Signal"];

export const SET_BY_USER_INVOKED_FROM_DEVICE = "InvokedFromDevice";

export const DEVICE_TYPE_PFC = "PFC";
export const DEVICE_TYPE_WLI = "WLI";
export const DEVICE_TYPE_STLI = "STLI";
export const PEOPLE_COUNTER = "People-Counter";
export const WATER_LEVEL_INDICATOR = "Water-Level-Indicator";
export const SEPTIC_TANK_LEVEL_INDICATOR = "Septic-Tank-Level-Indicator";
export const GANDHVEDH = "Gandhvedh";

// Related to HHM APIS
export const HHM_API_DURATION_ARRAY = ["1 Month", "3 Month", "6 Month", "1 Year"]
export const HHM_API_HITS_ARRAY = ["10000", "30000", "60000", "120000"]
export const HHM_API_RENEWADDON = "RenewAddOn";
export const HHM_API_RENEWPACKAGE = "RenewPackage";

// For adding and checking logged in user Email Id authentication type.
export const GOOGLE_AUTH = "Google";
export const APPLE_AUTH = "Apple";
export const SMART_HHM_AUTH = "smartHHM";

// Model Name
export const MODEL_LPG = "Vayuvedh-LPG";

export const MODEL_CODE_GANDHVEDH = "HHM-GV-Hw";
export const MODEL_CODE_VAYUVEDH_TOI = "HHM-VV-TOI-Hw";

// Current Field Devices in these Time Frames 
export const TIMEFRAME_15MINTO30MIN = "15 Mins to 30 Mins";
export const TIMEFRAME_30MINTO1HOUR = "30 Mins to 1 Hour";
export const TIMEFRAME_1HOURTO8HOURS = "1 Hour to 8 Hours";
export const TIMEFRAME_8HOURSTO24HOURS = "8 Hours to 24 Hours";
export const TIMEFRAME_24HOURSTOALL = "24 Hours to All";
export const TIMEFRAME_ALL = "All";

//Complaint Status
export const REGISTERED_STATUS = "Registered";
export const IN_PROCESS_STATUS = "In Process";
export const RESOLVED_STATUS = "Resolved";
export const RE_OPENED_STATUS = "Re Opened";
export const ARR_COMPLAINT_STATUS = ["All", "Registered", "In Process", "Resolved", "Re Opened"];

// Sanitation Mapping
// export const SANITATION_BUCKET_NAME = "hhmsanitationbucket";
export const SANITATION_BUCKET_NAME = "hhmcomplaintbucket";

//Param Doc
export const PARAM_FOR_SHOWING_IMPACTS = ["NH3OD", "TEMP", "HUM", "VOC", "AQI", "CAQI", "CO", "CO2", "H2S", "LPG", "MRI", "NO2", "O3", "PM1", "PM10", "PM25", "SMOKE", "SO2", "VRI"];
