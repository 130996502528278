
import React, { useContext, useEffect, useState } from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import { BsArrowDownRight, BsArrowUpRight } from 'react-icons/bs'
import { FaCalendar, FaClock, FaCross, FaNodeJs, FaToilet, FaUsers, FaWalking } from 'react-icons/fa'
import { IDS_AvailableSeats, IDS_BusiestDay, IDS_LeastBusyHour, IDS_OccupiedSeats, IDS_BusiestHour, IDS_PeopleCount, IDS_TotalSeats, IDS_MxPeopleCount, IDS_SeatAverage, IDS_PFC, IDS_Yesterday_s, IDS_TodayPeopleCount, IDS_LiveAvailableSeats, IDS_TotalNoOfToiletSeats, IDS_LiveOccupiedSeatsPercent, IDS_TotalOccupancyAvgPerDay, IDS_People} from '../../../VcLanguage'
import { AppRelevantDataContext } from '../../../AppContext'
import { BH, LAS, LBH, LOC, MXC, PFC, SOA, TTS } from '../../../VcConstants'
import { useDispatch, useSelector } from 'react-redux'
import { TbAlarmAverage } from 'react-icons/tb'
import { SlPeople } from "react-icons/sl";
import { PiPlaceholder } from "react-icons/pi";
import { MdAirlineSeatReclineNormal } from 'react-icons/md'
import { convertLocalDateWithFormatDDMMMYY } from '../../../vtUtil'



const VcPFC = ({PfcParamModelInfo, pfcData}) => {

      const getPercentage = (value, total = 30) => {
        return ((value / total ) * 100).toFixed(2)
      }
    //   const [fixedUIparamArr, setfixedUIparamArr] = useState(second)
      let fixedUIparamArr = [PFC, MXC, LOC, TTS, BH, LBH, TTS, LAS, SOA]
      const [toBeMappedParams, settoBeMappedParams] = useState([])
      const {minUsageHour, maxUsageHour, maxUsageCount, minUsageCount} = useSelector((s)=> s.variables.pfcData)
      const context = useContext(AppRelevantDataContext)
      let t = context.t;

      useEffect(() => {
        let otherParams = []
        if(PfcParamModelInfo && PfcParamModelInfo.length > 0) {
            otherParams = PfcParamModelInfo.filter(param => !fixedUIparamArr.includes(param))
        }
        settoBeMappedParams(otherParams)
      }, [])

    const renderPFCIconBasedOnParam = (inParamName) => {
        switch (inParamName) {   
            case TTS :
            case LAS :
                    return (
                        <span style={{width:"50px" , height:"50px"}} className='flex-center bg-primary text-white rounded-circle'>
                            <FaToilet/>
                        </span>
                    )
            case SOA:
                return (
                    <span style={{width:"50px" , height:"50px"}} className='flex-center bg-primary text-white rounded-circle'>
                        <MdAirlineSeatReclineNormal/>
                    </span>
                )
            default:
                return (
                    <span style={{width:"50px" , height:"50px"}} className='flex-center bg-secondary font-white rounded-circle'>
                        <PiPlaceholder/>
                    </span>
                )
        }
    }


      const renderPFCParamSymbolBasedOnType = (inSymbolType) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  
 
        switch(inSymbolType) {
            case PFC:
                return t(IDS_PFC)
            case TTS:
                return t(IDS_TotalSeats)
            case LOC:
                return t(IDS_OccupiedSeats)
            case LAS:
                return t(IDS_AvailableSeats)
            case SOA:
                return t(IDS_TotalOccupancyAvgPerDay)
                return t(IDS_SeatAverage)
                return t(IDS_Yesterday_s) + " " + t(IDS_SeatAverage)
            default:
                // console.error(`Unable to get Symbol. Unknown Param Type: ${inSymbolType}`);
                return inSymbolType // Return empty span
        }
    }
      
      const renderParamDiv = (inParamName) => {
        // if(inParamName == SOA || inParamName == LAS) return null
        if (inParamName == SOA) {
            return <div className='flex-col flex-center pfcBoxes'>
            <div className='text-muted  border-b-2 text-center border-bottom border-success w-100'>{renderPFCParamSymbolBasedOnType(inParamName)}
                <small className='text-black ps-2' style={{fontSize:"0.9rem"}}>
                    ({convertLocalDateWithFormatDDMMMYY(new Date().setDate(new Date().getDate() - 1))})
                </small>
            </div>
            <div className='flex gap-2 fs-5 items-center p-2'>
                {renderPFCIconBasedOnParam(inParamName)}
                <span classname='fs-2'>
                    {(PFCCountOf24Hours == 0 || PFCCountOf24Hours == null) && (TotalToiletSeatsOfSingleDevc == 0 || TotalToiletSeatsOfSingleDevc == null) || 
                                        (PFCCountOf24Hours != null && (TotalToiletSeatsOfSingleDevc == 0 || TotalToiletSeatsOfSingleDevc == null))
                                        ? "--" : Math.round(Number(PFCCountOf24Hours/TotalToiletSeatsOfSingleDevc))}
                </span>
            </div>
        </div>
        } else {
            return <div className='flex-col flex-center pfcBoxes'>
            <div className='text-muted  border-b-2 text-center border-bottom border-success w-100'>{renderPFCParamSymbolBasedOnType(inParamName)}</div>
            <div className='flex gap-2 fs-5 items-center p-2'>
                {renderPFCIconBasedOnParam(inParamName)}
                <span classname='fs-2'>
                    {pfcData.inParamName == null ? "--" : pfcData.inParamName}
                    {PFCCountOf24Hours}
                </span>
            </div>
        </div>
        }
        
      }


      let InOutCountOfSingleDevc = pfcData != null && pfcData.hasOwnProperty("InOutCount") ? pfcData["InOutCount"] : 0;
      let SeatOccupancyCountOfSingleDevc = pfcData != null && pfcData.hasOwnProperty("SeatOccupancyCount") ? pfcData["SeatOccupancyCount"] : 0;
      let TotalToiletSeatsOfSingleDevc = pfcData != null && pfcData.hasOwnProperty("TotalToiletSeats") ? pfcData["TotalToiletSeats"] : 0;
      let PFCCountOf24Hours = pfcData != null && pfcData.hasOwnProperty("PFCCountPerDay") ? pfcData["PFCCountPerDay"] : 0;
      let valueToBeUsedForColor = ((SeatOccupancyCountOfSingleDevc == 0 || SeatOccupancyCountOfSingleDevc == null) && (TotalToiletSeatsOfSingleDevc == 0 || TotalToiletSeatsOfSingleDevc == null)) || 
      (SeatOccupancyCountOfSingleDevc != null && (TotalToiletSeatsOfSingleDevc == 0 || TotalToiletSeatsOfSingleDevc == null))
      ? 0
      : parseFloat(getPercentage(SeatOccupancyCountOfSingleDevc, TotalToiletSeatsOfSingleDevc))

  return (
    <div className='flex-center bg-white p-2 py-2 py-md-3 rounded flex-col'>
        <div className='flex flex-md-row flex-column w-100 p-2 gap-4 gap-md-4'>
            {(PfcParamModelInfo.includes(PFC) || PfcParamModelInfo.includes(SOA)) && <div className='w-100 w-md-25'>
                <div className={`flex flex-column  gap-2 h-100
                ${(PfcParamModelInfo.includes(PFC) && PfcParamModelInfo.includes(SOA)) ? 'justify-between' : 'justify-center'} `}>

                    {PfcParamModelInfo.includes(PFC) && <div className='flex-col flex-center w-100'>
                        <div className='text-muted  border-b-2 border-bottom border-success w-100'>{t(IDS_TodayPeopleCount)}</div>
                        <div className='flex gap-2 fs-5 items-center p-2'>
                            <span style={{width:"50px" , height:"50px"}} className='flex-center bg-primary text-white rounded-circle'>
                                <FaWalking/>
                            </span>
                            <span classname='fs-2'>
                                {/* {pfcData.CrossCount == null ? "--" : pfcData?.CrossCount} */}
                                {InOutCountOfSingleDevc}
                            </span>
                        </div>
                    </div>}

                   {/* {PfcParamModelInfo.includes(MXC) && <div className='flex-col flex-center w-100'>
                    
                        <div className='text-muted  border-b-2 border-bottom border-success w-100'>{t(IDS_MxPeopleCount)}</div>
                       
                        <div className='flex gap-2 fs-5 items-center p-2'>
                            <span style={{width:"50px" , height:"50px"}} className='flex-center bg-warning text-white rounded-circle'>
                                <FaUsers/>
                            </span>
                            <span classname='fs-2'>
                            {maxUsageCount}
                            </span>
                        </div>
                    </div>} */}

                    {PfcParamModelInfo.includes(SOA) && 
                        <div className='flex-col flex-center w-100'>
                             <div className='text-muted  border-b-2 text-center border-bottom border-success w-100'>{renderPFCParamSymbolBasedOnType(SOA)}
                                <small className='text-muted ps-2' style={{fontSize:"0.7rem"}}>
                                    ({convertLocalDateWithFormatDDMMMYY(new Date().setDate(new Date().getDate() - 1))})
                                </small>
                            </div>
                            <div className='flex gap-2 fs-5 items-center p-2'>
                                {renderPFCIconBasedOnParam(SOA)}
                                <span classname='fs-2'>
                                {(PFCCountOf24Hours == 0 || PFCCountOf24Hours == null) && (TotalToiletSeatsOfSingleDevc == 0 || TotalToiletSeatsOfSingleDevc == null) || 
                                        (PFCCountOf24Hours != null && (TotalToiletSeatsOfSingleDevc == 0 || TotalToiletSeatsOfSingleDevc == null))
                                        ? "--" : Math.round(Number(PFCCountOf24Hours/TotalToiletSeatsOfSingleDevc))}</span>
                            </div>
                        </div>
                    }

                </div>
                
            </div>}
            {(PfcParamModelInfo.includes(LOC) || PfcParamModelInfo.includes(TTS)) && <div className='w-100 w-md-50'>
                    <div className='text-muted  border-b-2 border-bottom border-success w-100'>
                        {t(IDS_LiveOccupiedSeatsPercent)}
                    </div>
                    <div className='flex flex-col align-items-center justify-center  w-100'>
                        
                            <div className="pt-2" style={{height:"100px", width: "100px"}} onScroll={(e => console.log(e))}>
                                <CircularProgressbarWithChildren 
                                    // value={parseFloat(getPercentage(pfcData?.SeatOccupancyCount, pfcData?.TotalToiletSeats))}  
                                    value={((SeatOccupancyCountOfSingleDevc == 0 || SeatOccupancyCountOfSingleDevc == null) && (TotalToiletSeatsOfSingleDevc == 0 || TotalToiletSeatsOfSingleDevc == null)) || 
                                        (SeatOccupancyCountOfSingleDevc != null && (TotalToiletSeatsOfSingleDevc == 0 || TotalToiletSeatsOfSingleDevc == null))
                                        ? 0
                                        : parseFloat(getPercentage(SeatOccupancyCountOfSingleDevc, TotalToiletSeatsOfSingleDevc))}  
                                    styles= {buildStyles({
                                        pathColor: valueToBeUsedForColor > 80 ? '#FF0000' : "#0d6efd",
                                        pathTransitionDuration: 0.5,
                                        trailColor: '#d6d6d6',
                                        backgroundColor: '#3e98c7',
                                    })}
                                >
                                    <div className='fs-6 font-semibold text-primary'>{((SeatOccupancyCountOfSingleDevc == 0 || SeatOccupancyCountOfSingleDevc == null) && (TotalToiletSeatsOfSingleDevc == 0 || TotalToiletSeatsOfSingleDevc == null)) || 
                                                (SeatOccupancyCountOfSingleDevc != null && (TotalToiletSeatsOfSingleDevc == 0 || TotalToiletSeatsOfSingleDevc == null))
                                                ? "--" 
                                                : parseFloat(getPercentage(SeatOccupancyCountOfSingleDevc, TotalToiletSeatsOfSingleDevc))} %</div>
                                    {/* <div className='fs-6 font-semibold'>{parseFloat(getPercentage(pfcData?.SeatOccupancyCount, pfcData?.TotalToiletSeats))}%</div> */}
                                </CircularProgressbarWithChildren>

                            </div>
                            {PfcParamModelInfo.includes(TTS) && <>
                                <div className='flex pt-4 items-center justify-content-center'>
                                    <span className='text-secondary'>{t(IDS_LiveAvailableSeats)}:&nbsp;</span>
                                    <span className='fs-5 text-primary'>{TotalToiletSeatsOfSingleDevc - SeatOccupancyCountOfSingleDevc}</span>
                                </div>
                                <div className='flex  items-center justify-content-center'>
                                    <span className='text-secondary'>{t(IDS_TotalSeats)}:&nbsp;</span>
                                    <span className='fs-5 text-primary'>{TotalToiletSeatsOfSingleDevc}</span>
                                </div>
                            </>}
                    </div>
                </div>}
            {(PfcParamModelInfo.includes(BH) || PfcParamModelInfo.includes(LBH)) &&<div className='w-100 w-md-25'>
                <div className={`flex flex-column  gap-2 h-100
                ${(PfcParamModelInfo.includes(BH) && PfcParamModelInfo.includes(LBH)) ? 'justify-between' : 'justify-center'}`}>
                    {PfcParamModelInfo.includes(BH) && <div className='flex-col flex-center w-100'>
                        <div className='text-muted  border-b-2 border-bottom border-success w-100'>{t(IDS_BusiestHour)}</div>
                        <div className='flex gap-2 fs-5 items-center p-2'>
                            <span style={{width:"50px" , height:"50px"}} className=' flex items-center justify-content-center bg-danger text-white rounded-circle'>
                                <BsArrowUpRight/>
                            </span>
                            <span classname='fs-2'>
                                {/* {getTime(maxUsageHour)} */}
                                {maxUsageHour}
                                <small className='text-black ps-2' style={{fontSize:"0.9rem"}}>
                                    ({maxUsageCount} {t(IDS_People)})                            
                                </small>
                            </span>
                        </div>
                    </div>}
                    {PfcParamModelInfo.includes(LBH) && <div className='flex-col flex-center w-100'>

                        <div className='text-muted  border-b-2 border-bottom border-success w-100'>{t(IDS_LeastBusyHour)}</div>
                        <div className='flex gap-2 fs-5 items-center p-2'>
                            <span style={{width:"50px" , height:"50px"}} className=' flex items-center justify-content-center bg-success text-white rounded-circle'>
                                <BsArrowDownRight/>
                            </span>
                            <span classname='fs-2'>
                                {minUsageHour} 
                                <small className='text-black ps-2' style={{fontSize:"0.9rem"}}>
                                    ({minUsageCount} {t(IDS_People)})                                 
                                </small>
                            </span>
                        </div>
                    </div>}

                </div>
            </div>}
        </div>
        {toBeMappedParams && toBeMappedParams.length > 0 && <div className='w-100 flex flex-col flex-md-row p-2 flex-wrap mt-2 align-items-start ' style={{gap:"2%"}}>
                {toBeMappedParams?.map(paramName => {
                    return renderParamDiv(paramName)
                })}
            </div>}

        

    </div>
  )
}

export default VcPFC