import { createSlice } from "@reduxjs/toolkit";

const variables = createSlice({
    name: "variables",
    initialState: {
        pfcData : {
            minUsageHour : "--",
            maxUsageHour: "--",
            maxUsageCount: "--",
            minUsageCount: "--",
        },
        wliData : {
            minUsageHour : "--",
            maxUsageHour: "--",
            currentHourUsage: "--",
        },
        selectedNode : '',
        searchedDeviceToiletNode: '',
        propToOpenSubmenues: false,
        toSearchDeviceIDOnMap : '', 
        treeDefinitionFlag: false,
    },
    reducers: {

        setMinUsageHour: (state, action) => {
            state.pfcData.minUsageHour = action.payload;
        },

        setMaxUsageHour: (state, action) => {
            state.pfcData.maxUsageHour = action.payload;
        },

        setMaxUsage: (state, action) => {
            state.pfcData.maxUsageCount = action.payload;
        },

        setMinUsage: (state, action) => {
            state.pfcData.minUsageCount = action.payload;
        },

        setMinUsageHourWLI: (state, action) => {
            state.wliData.minUsageHour = action.payload;
        },

        setMaxUsageHourWLI: (state, action) => {
            state.wliData.maxUsageHour = action.payload;
        },

        setCurrentHourUsagesWLI: (state, action) => {
            state.wliData.currentHourUsage = action.payload;
        },

        setToSearchDeviceIDOnMap : (state, action) => {
            state.toSearchDeviceIDOnMap = action.payload;
        },
        setTreeDefinitionFlag: (state, action) => {
            state.treeDefinitionFlag = action.payload
        }
    }
})


export const { setMinUsageHour, setMaxUsageHour, setMaxUsage, setMinUsage, setToSearchDeviceIDOnMap, setMinUsageHourWLI, setMaxUsageHourWLI, setCurrentHourUsagesWLI, setTreeDefinitionFlag } = variables.actions;

export default variables.reducer;