import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_AUSrvrIssueReqParamsNotSent } from '../../VcLanguage';
import { AppRelevantDataContext } from '../../AppContext';
import App from '../../App';
import { FaArrowCircleRight} from 'react-icons/fa';
import { CircularProgressbar, buildStyles} from "react-circular-progressbar";
import {  convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateWithFormatDDMMMYY, convertUTCDateStringToLocaleString} from '../../vtUtil';
import '../CSS/VcHhmAPIs.css'
import "react-circular-progressbar/dist/styles.css";
import {UserOutlined, LaptopOutlined, NotificationOutlined,  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined } from '@ant-design/icons';
import { Layout, Select, Button , Space, Input, message, Modal, Row, Col, Table, Typography, List} from 'antd';
import VcNavBar from "./VcNavbar";
import VcSideNav from './DeviceHome/VcSideNav';

const { Title } = Typography;
const { Header, Content, Footer, Sider } = Layout;

function VcVilisoAPIs (props) {

  const context = useContext(AppRelevantDataContext);
  const { confirm } = Modal;
  const [state, setState] = useState({
    loggedInUserID: "",
    loggedInUserName: "",
    selectedOrdLineNo: "",
    apiKeyOfselectedOrdLineNo: "",
    apiStatus: "",
    isAPIActive: "",
    authInfoIdOfApiKey: "",
    pkgDurationOfselectedOrdLineNo: "",
    apiHitsInPkgOfselectedOrdLineNo: "",
    PkgStartDtOfselectedOrdLineNo: "",
    PkgEndDtTmOfselectedOrdLineNo: "",
    apiConsumedPercentageOfselectedOrdLineNo: "",
    apiStatusWarning: "",
    showGenerateKeyBtn: true,
    showActDeactKeyBtn: false,
    arrAllOrdLineNoDetails: [],
    progressBarpercentage: "0",
    windowWidth: window.innerWidth,
    errors:{
      others: "",
      apiStatusWarningErr: "",
      error: ""
    },
    isOpen: true,
    value: "",
    copied: false,  
    activeBtnStatus: "",
    getStartedDropDownOpen: false,
    jsonArray: [
      { "apiKey": "U2FsdGVkX19px1Un+42f64w6SyRXFRmrCSv2v1RXAjYSEIrCss/+NhMdrvhYSyUC"},
      { 
        "code": "SUCCESS",
        "successmessage": "Success while fetching Access Token.",
        "accessToken": "42f64w6SyRXFRmrCSv2v1RXAjYSEIrCss" 
      },
      { 
        "code": "SUCCESS",
        "successmessage": "Success while fetching list of registered devices.",
        "registeredDeviceList": [
            {
                "deviceId": "DEVICE1",
                "deviceName": "HHM_DEVICE1",
                "modelName": "Gandhvedh"
            },
            {
                "deviceId": "DEVICE2",
                "deviceName": "HHM_DEVICE2",
                "modelName": "Vayuvedh-MV"
            },
            {
                "deviceId": "DEVICE3",
                "deviceName": "HHM_DEVICE3",
                "modelName": "Vayuvedh-MV"
            }
        ]
      },
      { "deviceId" : "DEVICE1" },
      {
        "code": "SUCCESS",
        "successmessage": "Success while fetching Device Data for the DeviceID 'SAMPLEDEVICE1'.",
        "deviceData": {
            "deviceId": "DEVICE1",
            "deviceName": "HHM_DEVICE1",
            "deviceData": {
                "HUM": 76.75,
                "TEMP": 29.74,
                "NH3OD": 0
            },
            "deviceDataLogTimeInUTC": "04-Oct-2024 16:30:00",
            "deviceStatus": "Offline"
        }
      },
      {
        "deviceId": "DEVICE1",
        "startDateTime": "2024-08-15 05:40:15",
        "endDateTime": "2024-09-14 05:40:15",
        "periodToView": "Monthly",
        "selectedParam": "NH3OD",
        "timeZoneOffset": "+05:30"
      },
      {
        "code": "SUCCESS",
        "successmessage": "Success while fetching Historical Data for DeviceID 'DEVICE1'.",
        "historicalDeviceData": [
            {
                "durationLabel": "Sep-02-24",
                "value": 14.700000000000003
            },
            {
                "durationLabel": "Sep-03-24",
                "value": 14.033333333333337
            }
        ]
      },
      { "deviceId" : "DEVICE1" },
      {
        "code": "SUCCESS",
        "successmessage": "Success while fetching Device Details for the DeviceID 'DEVICE1'.",
        "deviceDetails": [
          {
              "deviceId": "DEVICE1",
              "deviceName": "HHM_DEVICE1",
              "firstName": "viliso",
              "lastName": "user",
              "deviceOwnerEmailID": "vilisouser@gmail.com",
              "modelName": "Gandhvedh",
              "premise": "Hall",
              "firmwareName": "HHM-GV-Fw",
              "firmwareVersion": "V1.70.00",
              "dataSendIntervalTimeInMin": 3

          }
        ]
      },
      {
        "modelName": "Model1"
      },
      {
        "code": "SUCCESS",
        "successmessage": "Success while fetching Range Values for the Model 'Model1'.",
        "parameterRangeValues": [
            {
                "parameter": "MODEL1PARAM",
                "rangeValues": {
                    "low": 0,
                    "max": 100,
                    "dispRngSeq": [
                        {
                            "DispLSevereRng": "0 ~ 10"
                        },
                        {
                            "DispGoodRng": "10 ~ 20"
                        },
                        {
                            "DispPoorRng": "20 ~ 30"
                        },
                        {
                            "DispHSevereRng": "30 ~ Onwards"
                        }
                    ]
                }
            }
          ],
          "parameterList": [
              "MODEL1PARAM"
          ]
        },
        // { "DeviceID" : "08EA91A2CFF4" },
        // {
        //   "DeviceSettings": [
        //     {
        //       "DeviceID": "08EA91A2CFF4",
        //       "Settings": {
        //         "HUM": {
        //           "Low": 30,
        //           "High": 55,
        //           "Notify": false
        //         },
        //         "VOC": {
        //           "Low": null,
        //           "High": 250,
        //           "Notify": true
        //         },
        //         "TEMP": {
        //           "Low": 10,
        //           "High": 35,
        //           "Notify": true
        //         },
        //         "NH3OD": {
        //           "Low": null,
        //           "High": 1.2,
        //           "Notify": true
        //         },
        //         "EnableAllNotifications": true
        //       }
        //     }
        //   ]
        // },
        // {
        //   "DeviceID" : "08EA91A2CFF4",
        //   "Parameter": "HUM",
        //   "Range": "Low",
        //   "Value": 20
        // },
        // {
        //   "code": "SUCCESS",
        //   "successmessage": "Successful while updating Device Settings."
        // },
        // {
        //   "DeviceID" : "08EA91A2CFF4",
        //   "Parameter" : "HUM",
        //   "NotifyStatus" : "false"
        // },
        // {
        //   "code": "SUCCESS",
        //   "successmessage": "Successful while updating Notification Settings."
        // }
    ]
  });
  const [activeMenu, setActiveMenu] = useState('1'); // Track active section

  const userGuidelinesData = [
    `<strong>Authentication:</strong> Ensure you include the correct authentication headers with your requests.`,
    `<strong>Data Format:</strong> Make sure to send and receive data in the expected formats, typically JSON.`,
    `<strong>Error Handling:</strong> Implement proper error handling in your application to gracefully manage API errors.`,
    `<strong>Versioning:</strong> Use the correct version of the API for your application to ensure compatibility.`,
    `<strong>Testing:</strong> Test your API integration thoroughly before deploying your application.`,
    `<strong>Documentation:</strong> Always refer to the API documentation for detailed usage instructions and examples.`,
    `<strong>Contact Support:</strong> If you encounter issues, contact our support team for assistance.`
  ];

  const handleResize = () => {
    if ((window.innerWidth <= 570 && state.windowWidth > 570) || (window.innerWidth > 570 && state.windowWidth <= 570)) {
        setState(prevState => ({
            ...prevState,
            windowWidth: window.innerWidth
        }))
    } 
  }
    
  // Function will be called on click on "Generate API Key" button
  const OnClickGenerateApiKeyBtn = () => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t;

    let modifiedState = state;
   
    let jsonParams = {
      userID: modifiedState.loggedInUserID,
      apiKey: modifiedState.apiKeyOfselectedOrdLineNo,
      authInfoId: modifiedState.authInfoIdOfApiKey,
      apiStatus: modifiedState.apiStatus,
      isAPIActive: modifiedState.isAPIActive,
      orderlineNumber: modifiedState.selectedOrdLineNo,
    }

    axios.post(`${getAPIHostURL()}/wclient/generateApiKey`, jsonParams)
    .then(response =>{

      if(response.data.code == 'SUCCESS') {

        if (response.data.GeneratedApiKey != null && response.data.GeneratedApiKey.length > 0) {
            modifiedState.apiKeyOfselectedOrdLineNo = response.data.GeneratedApiKey;
            modifiedState.showActDeactKeyBtn = true;
            modifiedState.errors.apiStatusWarningErr = "";
        } else {
            modifiedState.errors.others = "OrderLineNumber details not available.";
        }          
      } else {
          if(response.data.code == 'REQ_PARAMS_MISSING') {
              modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
          } else if(response.data.code == 'SQL_ERROR') {
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
              console.log("Should not reach here");
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
      }
      setState({...modifiedState});

    }).catch(err => {
        if (axios.isCancel(err)) {
            console.log('Axios request cancelled because of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
            modifiedState.errors.others = t(IDS_RegistNetworkError);
        }
        setState({...modifiedState});
    })
    setState({...modifiedState});
  }

  // Function called to show confirmation box before generating API Key.
  const showConfirm = () => {
    confirm({
      title: 'Regenerate API Key',
      content: (
        <div>
          <p>The API key is active and already present. Are you sure you want to regenerate the API key for the selected order?</p>
          <p style={{ marginTop: '10px', fontStyle: 'italic' }}>
            NOTE: Please regenerate the API key only for important reasons, such as security concerns, compromised credentials, or when the existing key is no longer functional.
          </p>
        </div>
      ),
      okText: 'Yes, Regenerate',
      okType: 'default',
      cancelText: 'Cancel',
      onOk() {
        // Call the API only if confirmed
        OnClickGenerateApiKeyBtn();
      },
      onCancel() {
        console.log('Cancelled');
      },
    });
  };

  // Function get called on clicking on "Activate API Key" or "Deactivate API Key"
  const OnClickActiveDeactiveBtn = () => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t;

    let modifiedState = state;
    let apiStatusToUpdate = modifiedState.activeBtnStatus;

    modifiedState.activeBtnStatus = modifiedState.activeBtnStatus == "Active" ? "Deactive" : "Active";
    modifiedState.apiStatus = modifiedState.activeBtnStatus == "Active" ? "Deactive" : "Active";
    modifiedState.errors.apiStatusWarningErr = modifiedState.activeBtnStatus == "Active" ? 
    "The API key for the selected order is currently deactivated. To activate it, please click the 'Activate API Key' button."
    : "";

    let jsonParams = {
      apiKey: modifiedState.apiKeyOfselectedOrdLineNo,
      apiStatus: apiStatusToUpdate
    }

    axios.post(`${getAPIHostURL()}/wclient/updateAPIStatus`, jsonParams)
    .then(response =>{
        if(response.data.code == 'SUCCESS') {
          apiStatusToUpdate == "Active" ?
            message.success(`API key is successfully activated for order no. ${modifiedState.selectedOrdLineNo}.`):
            message.success(`API key is successfully deactivated for order no. ${modifiedState.selectedOrdLineNo}.`);
        
        } else {
            if(response.data.code == 'REQ_PARAMS_MISSING'){
                modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
            } else if(response.data.code == 'SQL_ERROR') {
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            } else {
                console.log("Should not reach here");
                modifiedState.errors.others = t(IDS_LoginServerIssue);
            }
        }
        setState({...modifiedState});

    }).catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
            console.log('Axios request cancelled because of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
            modifiedState.errors.others = t(IDS_RegistNetworkError);
        }
        setState({...modifiedState});
    })

    setState({...modifiedState});
  }

  // Function get called on page mount
  const getOrdLineNoWithHhmApiDetailsBasedOnUserID = () => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t;
    let modifiedState = state;

    modifiedState.loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;
    modifiedState.loggedInUserName = appRelevantDataContextValue.loggedInUserInfo.userFirstName;

    if(modifiedState.selectedOrdLineNo.length <= 0){
      modifiedState.apiStatusWarning = "";
      modifiedState.errors.apiStatusWarningErr = "";
    }

    let jsonParams = {
      userID: modifiedState.loggedInUserID,
      invokedFrom: "HhmApiDoc"
    }

    axios.post(`${getAPIHostURL()}/wclient/getOrdLineNoWithHhmApiDetailsBasedOnUserID`, jsonParams)
    .then(response =>{
      if(response.data.code == 'SUCCESS') {

        if (response.data != null && response.data.ordLineNoForHhmApi != null && response.data.ordLineNoForHhmApi.length > 0) {
          let allOrdLineNoDetailsRecieved = [];
          modifiedState.arrAllOrdLineNoDetails = [];

          allOrdLineNoDetailsRecieved = response.data.ordLineNoForHhmApi;
          
          for(let i=0; i < allOrdLineNoDetailsRecieved.length; i++) {
            modifiedState.arrAllOrdLineNoDetails.push(allOrdLineNoDetailsRecieved[i]);
          }            

          if(modifiedState.selectedOrdLineNo.length > 0 && modifiedState.authInfoIdOfApiKey.length > 0){
            displayPkgEndDateAndApiHitsConsumedPercntOfSelectedOrdLineNo(modifiedState);
          } else {
            setState({...modifiedState});
          }

        } else {
            modifiedState.errors.others = "OrderLineNumber details not available.";
        }          
      } else {
        if(response.data.code == 'REQ_PARAMS_MISSING'){
            modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
        } else if(response.data.code == 'SQL_ERROR') {
            modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
            console.log("Should not reach here");
            modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
      }
      // Function to display json payloads
      displayJsons();

    })
    .catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
            console.log('Axios request cancelled because of too many requests being sent to the Server.');
        } else {
            modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
            modifiedState.errors.others = t(IDS_RegistNetworkError);
        }
        setState({...modifiedState});
    })
  }

  // function to display request and response payloads
  const displayJsons = () => {
    let modifiedState = state;

    for(let i=0; i < modifiedState.jsonArray.length; i++){
      document.getElementById(i).textContent = JSON.stringify(modifiedState.jsonArray[i], undefined, 2);
    }
  }

  // function get called on selecting order line no. from dropdown
  const onSelectOrderLineNumber = (value) => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t;
    let modifiedState = state;

    modifiedState.apiKeyOfselectedOrdLineNo = "";
    modifiedState.apiStatusWarning = "";
    modifiedState.errors.apiStatusWarningErr = "";
    modifiedState.activeBtnStatus = "Deactive";

    let orderlineNumber = value;
    modifiedState.selectedOrdLineNo = orderlineNumber;

    for(let i=0; i < modifiedState.arrAllOrdLineNoDetails.length; i++){

      if(modifiedState.arrAllOrdLineNoDetails[i].OrderLineNumber == orderlineNumber){

        modifiedState.apiKeyOfselectedOrdLineNo = modifiedState.arrAllOrdLineNoDetails[i].ApiKey == null || modifiedState.arrAllOrdLineNoDetails[i].ApiKey.length <= 0 
          ? "" : modifiedState.arrAllOrdLineNoDetails[i].ApiKey;

        modifiedState.apiStatus = modifiedState.arrAllOrdLineNoDetails[i].CurrAPIStatus == null || modifiedState.arrAllOrdLineNoDetails[i].CurrAPIStatus.length <= 0 
          ? "" : modifiedState.arrAllOrdLineNoDetails[i].CurrAPIStatus;

        modifiedState.isAPIActive = modifiedState.arrAllOrdLineNoDetails[i].Active == null
          ? "" : modifiedState.arrAllOrdLineNoDetails[i].Active;

        modifiedState.authInfoIdOfApiKey = modifiedState.arrAllOrdLineNoDetails[i].AuthInfoId == null || modifiedState.arrAllOrdLineNoDetails[i].AuthInfoId.length <= 0 
          ? "" : modifiedState.arrAllOrdLineNoDetails[i].AuthInfoId;

      }
    }

    if(modifiedState.isAPIActive == 0) {

      // switch for displaying and hiding errors, warnings and status
      switch(modifiedState.apiStatus) {
        case "Active":
          modifiedState.apiStatusWarning = "";
          modifiedState.activeBtnStatus = "Deactive"
          modifiedState.showGenerateKeyBtn = true;  
          modifiedState.showActDeactKeyBtn = true;
          break;
        case "Deactive":
          modifiedState.errors.apiStatusWarningErr = "The API key for the selected order is currently deactivated. To activate it, please click the 'Activate API Key' button.";
          modifiedState.activeBtnStatus = "Active";
          modifiedState.apiStatusWarning = "";
          modifiedState.showGenerateKeyBtn = true;
          modifiedState.showActDeactKeyBtn = true;
          break;
        case "MaxHitsOver":
          modifiedState.errors.apiStatusWarningErr = "The maximum hits for the current order have been reached. Please renew your order to continue accessing Viliso APIs.";
          modifiedState.apiStatusWarning = "Viliso Api Max Hits are Over";
          modifiedState.showGenerateKeyBtn = false;
          modifiedState.showActDeactKeyBtn = false;
          break;
        case "PkgExpired":
          modifiedState.errors.apiStatusWarningErr = "The duration of the current order has expired. Please renew your order to continue accessing Viliso APIs.";
          modifiedState.apiStatusWarning = "Viliso Api Access Duration Expired";
          modifiedState.showGenerateKeyBtn = false;
          modifiedState.showActDeactKeyBtn = false;
          break;
        default:
          modifiedState.errors.apiStatusWarningErr = "Please generate a new API key by clicking the 'Generate API Key' button to access Viliso APIs.";
          modifiedState.showGenerateKeyBtn = true;
          modifiedState.showActDeactKeyBtn = false;
          break;
      }

    } else {

      modifiedState.errors.apiStatusWarningErr = "Please create a new order to access Viliso APIs.";
      modifiedState.apiStatusWarning = "Access to Viliso Api is Blocked";
      modifiedState.showGenerateKeyBtn = false;
      modifiedState.showActDeactKeyBtn = false;
    }

    if(modifiedState.authInfoIdOfApiKey.length > 0 ){
      displayPkgEndDateAndApiHitsConsumedPercntOfSelectedOrdLineNo(modifiedState);
    } else {
      setState({...modifiedState});
    }
  }

  const displayPkgEndDateAndApiHitsConsumedPercntOfSelectedOrdLineNo = (inModifiedState = null) => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
    let t = appRelevantDataContextValue.t;

    let modifiedState;

    if(inModifiedState == null) {
      modifiedState = state;
    } else {
      modifiedState = inModifiedState;
    }

    modifiedState.progressBarpercentage = "";
    modifiedState.PkgEndDtTmOfselectedOrdLineNo = "";

    let jsonParams = {
      OrderLineNumber: modifiedState.selectedOrdLineNo,
      AuthInfoId: modifiedState.authInfoIdOfApiKey
    }

    axios.post(`${getAPIHostURL()}/wclient/getApiHitsConsumedPercntAndPkgEndDate`, jsonParams)
    .then(response =>{
      
      if(response.data.code == 'SUCCESS') {

        let resultRecieved = response.data.result;

        modifiedState.progressBarpercentage = resultRecieved != null && resultRecieved.apiHitsConsumedPercentage != null && resultRecieved.apiHitsConsumedPercentage.length > 0
          ? resultRecieved.apiHitsConsumedPercentage >= 1 ? parseFloat(resultRecieved.apiHitsConsumedPercentage).toFixed(0)
          : resultRecieved.apiHitsConsumedPercentage > 0 && resultRecieved.apiHitsConsumedPercentage < 1 ? parseFloat(resultRecieved.apiHitsConsumedPercentage).toFixed(2)
          : "0" : "0";

        modifiedState.PkgEndDtTmOfselectedOrdLineNo = resultRecieved != null && resultRecieved.PkgEndDt != null && resultRecieved.PkgEndDt.length > 0
          ? resultRecieved.PkgEndDt
          : "NA";

      } else {
          if(response.data.code == 'REQ_PARAMS_MISSING'){
              modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent);
          } else if(response.data.code == 'SQL_ERROR') {
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          } else {
              console.log("Should not reach here");
              modifiedState.errors.others = t(IDS_LoginServerIssue);
          }
      }
      setState({...modifiedState});

    }).catch(err => {
        console.log("Network error");
        console.log(err);
        if (axios.isCancel(err)) {
          console.log('Axios request cancelled because of too many requests being sent to the Server.');
        } else {
          modifiedState.errors.others = 'Network issues. \n Check your Internet and Try again later.';
          modifiedState.errors.others = t(IDS_RegistNetworkError);
        }
        setState({...modifiedState});
    })
  
  }
          
  useEffect(() => {
    
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 || appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0) {
      // User login information is not present in appRelevantDataContext.
      // Try to get this value if present in LocalStorage and update the app state (so that
      // re-render will happen). If app context data not present in LocalStorage then
      // show the Login Page
      if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
        // App Relevant Data Context not present in LocalStorage as well.
        props.history.replace('/');
      } else {
        // App Relevant Data Context loaded from LocalStorage at AppLeval.
        // Re-render will happen automatically (no action required).
      }

    } else {
      getOrdLineNoWithHhmApiDetailsBasedOnUserID();
    }

  },[]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.section');
      let currentSection = '1';

      sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        if (sectionTop <= 100) {
          currentSection = section.id; // Set the section ID as the active menu item
        }
      });

      setActiveMenu(currentSection);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
      getOrdLineNoWithHhmApiDetailsBasedOnUserID();
  }, [context, state.selectedOrdLineNo, state.apiStatus ])

  useEffect(() => {
    

    if(state.errors.others.length > 0){
      message.success(`${state.errors.others}.`)
    }

  }, [state.errors.others]);
  // }, [state.errors]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
        window.removeEventListener('resize', handleResize());
    }
}, [state.windowWidth <= 570])

  if( context.loggedInUserInfo.userFirstName.length <= 0 ) {
    // User login information is not present in appRelevantDataContext.

    // Next re-render will happen automatically when the AppState is updated again 
    // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
    return App.renderLoadingPage();
  }

  const pathParamsColumns = [
    {
      title: 'Parameter',
      dataIndex: 'parameter',
      key: 'parameter',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],  // Responsive breakpoints

      render: (text) => (
        <p style={{ fontWeight: 600, marginBottom: 0 }}>
          {text} <b style={{ color: 'red' }}>*</b>
        </p>
      ),
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      responsive: ['xs','sm', 'md', 'lg', 'xl'],  // Will be hidden on extra-small devices (xs)
      render: (text) => (
        <p>
          <i>{text.type}</i>
          <br />
          {text.description}
        </p>
      ),
    },
  ];

  const pathParamsData = [
    {
      key: '1',
      parameter: 'accessToken',
      details: { description: `The Access Token must be set in headers under "authorization" of the https request. The token must be accompanied in "Bearer + Access Token" format respectively.` },
    },
  ];

  const queryParamsColumns = [
    {
      title: 'Parameter',
      dataIndex: 'parameter',
      key: 'parameter',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],  // Responsive breakpoints

      render: (text, record) => (
        <p style={{ fontWeight: 600, marginBottom: 0 }}>
          {text} {record.details.required && <b style={{ color: 'red' }}>*</b>}
        </p>
      ),
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      responsive: ['xs','sm', 'md', 'lg', 'xl'],  // Will be hidden on extra-small devices (xs)
      render: (text) => (
        <p>
          <i>{text.type}</i>
          <br />
          {text.description}
        </p>
      ),
    },
  ];

  // <td><p className="para">DeviceID <b style={{color:"Red"}}>*</b></p></td>
  //                           <td><p className="para"><i>String</i><br/>DeviceID of the Device.</p></td>

  const queryParamsGetAccessToken = [
    {
      key: '1',
      parameter: 'apiKey',
      details: { type: 'String', description: 'Generated API Key.', required: true },
    },

  ];

  const queryParamsDataDvcData = [
    {
      key: '1',
      parameter: 'deviceId',
      details: { type: 'String', description: 'DeviceID of the Device.', required: true },
    },
  ];

  const queryParamsDataParamRanges = [
    {
      key: '1',
      parameter: 'modelName',
      details: { type: 'String', description: 'ModelName of the Device.', required: true },
    },
  ];

  const queryParamsDataDataHist = [
  {
    key: '1',
    parameter: 'deviceId',
    details: { type: 'String', description: 'DeviceID of the Device.', required: true },
  },
  {
    key: '2',
    parameter: 'periodToView',
    details: { type: 'String', description: 'Period to view data in range of "Daily" / "Weekly" / "Monthly"', required: true },
  },
  {
    key: '3',
    parameter: 'selectedParam',
    details: { type: 'String', description: 'Selected parameter.', required: true },
  },
  {
    key: '4',
    parameter: 'timeZoneOffset',
    details: { type: 'String', description: 'Time zone offset. Local time zone offset in format ±HH:MM ranging HH from -11: to +11 & MM from 00 to 59', required: true },
  },
  {
    key: '5',
    parameter: 'startDateTime',
    details: { type: 'String', description: 'Start date and time.', required: false },
  },
  {
    key: '6',
    parameter: 'endDateTime',
    details: { type: 'String', description: 'End date and time.', required: false },
  },
];


  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(state.apiKeyOfselectedOrdLineNo).then(() => {
      message.success('Copied API key to clipboard!');
    }).catch(() => {
      message.error('Failed to copy API key.');
    });
  };

  return (
    (state.windowWidth <= 570) ? 
      <div className="d-flex">
        <div className="treeAndContentCRM">
          <VcNavBar />

          <div>
            <div className="row" >
              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-2 col-xxl-3" style={{backgroundColor: "", height: "70vh",overflow: "none"}}>
                <div>
                  <div style={{display: "none"}}><FaArrowCircleRight /></div>
                </div>
                <div class="pb-0" >
                  <div className="sidebar-header">
                    <h4><b>Viliso APIs</b></h4>
                  </div>
                  <div className="menuNavbar side-menu">
                    <nav id="menu-list" className="navbar navbar-light flex-column align-items-stretch p-3">
                      <nav className="nav nav-pills flex-column">
                        <a className="apiNavlink" activeClassName="apiNavlinkActive" href="#section1">Overview</a>
                        <a className="apiNavlink" href="#section2">Get Started</a>
                        <a className="apiNavlink ms-2 my-1" href="#section2.1">Before You Start With Your First Request</a>
                        <a className="apiNavlink ms-2 my-1" href="#section2.2">Your First Request</a>
                        <a className="apiNavlink" href="#section3">API Documentation</a>
                        <a className="apiNavlink ms-2 my-1" href="#section3.1">Device List</a>
                        <a className="apiNavlink ms-2 my-1" href="#section3.2">Device Data</a>
                        <a className="apiNavlink ms-2 my-1" href="#section3.3">Device Data History</a>
                        <a className="apiNavlink ms-2 my-1" href="#section3.4">Device Profile</a>
                        <a className="apiNavlink ms-2 my-1" href="#section3.5">Air Quality Ranges</a>
                        {/* <a className="apiNavlink ms-2 my-1" href="#section3.6">Device Settings</a> */}
                        {/* <a className="apiNavlink ms-2 my-1" href="#section3.7">Update Device Settings</a> */}
                        {/* <a className="apiNavlink ms-2 my-1" href="#section3.8">On/Off Alert Notification Settings</a> */}
                        <a className="apiNavlink" href="#section4">Developer Guidelines</a>
                      </nav>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-9 p-1" data-coreui-spy="scroll" data-coreui-target="#menu-list" data-coreui-offset="0" tabindex="0" style={{overflow:"auto", overflowY: "scroll"}} >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  sddSingleParam" style={{height:"88vh", display:"flex", justifyContent:"left", alignItems:"left"}}>
                  <div style={{height:"88vh", width: "100%", marginLeft: "1rem", marginRight: "1rem"}}>
                    <div id="section1"><div className="form-group logform pb-0" >
                      <div className="inputgroupCustom">
                        <label className="log-form-label MainMenuContentLabels"><h2>Overview</h2></label>
                        <p className="para">
                          The Viliso-APIs provide a robust interface for developers to access data related to our smart HHM products. 
                          With the Viliso-APIs, you can manage and maintain data across all of our devices, including key products like Gandhvedh and Vayuvedh. 
                          Our APIs offer the tools you need to seamlessly interact with the smartHHM ecosystem.
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr></hr>
                  <div className="form-group logform pt-0" id="section2">
                    <div className="inputgroupCustom">
                      <label className="log-form-label MainMenuContentLabels"><h2>Get Started</h2></label>
                      <p className="para">This section outlines the steps to begin receiving data from smartHHM using the Viliso-APIs.</p><br/><br/>
                      <div class="pt-0" id="section2.1">
                        <label className="log-form-label SubMenuContentLabels" ><h5><b>Before You Start With Your First Request</b></h5></label>
                        <div className="inputgroupCustom">

                          <label className="log-form-label" ><h6>Step 1: Select Order Line Number</h6></label>
                          <div><p className="para">From the dropdown, choose the 'Order Line Number' for which you'd like to generate an API Key.</p>
                          </div><br/>

                          <div style={{ width: "50%", clear: "both",marginLeft:"1rem", textAlign: "left" }}>
                            <Select
                              defaultValue="Select Order Line Number..."
                              style={{
                                width: "100%",
                              }}
                              onChange={onSelectOrderLineNumber}
                            >
                              {(state.arrAllOrdLineNoDetails.map((singleOrdLineNo, index) => 
                              <options key={index} value={singleOrdLineNo.OrderLineNumber}>{"ApiOrder" + state.loggedInUserName + singleOrdLineNo.OrderLineNumber}</options>))} 
                            </Select><br/>
                          </div>

                          {state.apiStatusWarning != null && state.apiStatusWarning.length > 0 &&
                          <div style={{display: "inline-block"}}>
                            {/* <span btn-sm style={{marginLeft: "3rem", backgroundColor: "red", height:"2rem", border:"0rem", borderRadius: "5px", color:"#fff"}}>{state.apiStatusWarning}</span> */}

                            <span style={{marginLeft: "3rem",backgroundColor: 'red', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                            {state.apiStatusWarning}
                                  </span>

                            <br/></div>
                          } 

                          {state.selectedOrdLineNo != null && state.selectedOrdLineNo != "" && state.selectedOrdLineNo.length >= 0 &&
                          state.isAPIActive != null && state.isAPIActive == "0" &&
                          <div className='container' style={{marginTop:"1rem"}}>
                            <p className='para'>Package Expiry Date: {state.PkgEndDtTmOfselectedOrdLineNo}</p>
                            <p className='para'>Viliso API Hits Consumed: </p>
                            <div style={{width:"10%", marginLeft:"1rem"}}>

                              <CircularProgressbar 
                                value={state.progressBarpercentage} 
                                text={`${state.progressBarpercentage}%`} 
                                styles= {buildStyles({
                                pathColor: state.progressBarpercentage <= 25 ? "green" : 
                                            state.progressBarpercentage > 25 && state.progressBarpercentage <= 50 ? "gold" :
                                            state.progressBarpercentage > 50 && state.progressBarpercentage <= 75 ? "orange" :
                                            "red"
                              })}
                              />
                            </div>
                          </div>
                          }

                        </div><br/>
                        <div className="inputgroupCustom">
                          <label className="log-form-label"><h6>Step 2: Generate API Key</h6></label>
                          <p className="para">Click on <b>Generate API Key</b> button.</p><br></br>
                          <p className="para"><b>API Key</b> is required to authorize the developer or user. After authorization, you can generate an <b>Access Token</b> using the Viliso-APIs, which will authenticate subsequent requests.</p><br/>                        
                          <br></br>
                    
                          {state.showGenerateKeyBtn == true &&
                            <Button 
                              onClick={(state.apiStatus == "Deactive" || state.apiStatus == "ApiKeyExpired" || state.apiStatus == "PkgExpired" || state.apiStatus == "MaxHitsOver") ? null : state.apiKeyOfselectedOrdLineNo.length <= 0 ? OnClickGenerateApiKeyBtn : showConfirm} 
                              disabled={(state.apiStatus == "Deactive" || state.apiStatus == "ApiKeyExpired" || state.apiStatus == "PkgExpired" || state.apiStatus == "MaxHitsOver" || state.selectedOrdLineNo.length <= 0) ? true : false}

                              style={{position: "relative", marginRight: "0.5rem", backgroundColor: "var(--primaryColor)", color:"white", marginLeft:"1rem"}}
                            >
                              {state.apiKeyOfselectedOrdLineNo.length > 0 ? "Re-Generate API Key" : "Generate API Key"}
                            </Button>
                          } 
                          <br/>

                          {/* <p className="para"></p> */}
                          <br/>
                          {state.showActDeactKeyBtn == true &&
                          <Button onClick={OnClickActiveDeactiveBtn} 
                            style={{position: "relative", backgroundColor: "var(--secondaryColor)"}}>
                            {state.activeBtnStatus == "Deactive" ? "Deactivate API Key" : "Activate API Key"}
                          </Button>
                          }
                          <br/>

                          {(state.selectedOrdLineNo != null && state.selectedOrdLineNo != "" && state.selectedOrdLineNo.length >= 0 ) &&
                          (state.apiKeyOfselectedOrdLineNo != null && state.apiKeyOfselectedOrdLineNo != "" && state.apiKeyOfselectedOrdLineNo.length >= 0)
                          &&
                          
                          <div style={{ position: "relative", width: "100%", paddingBottom: "1.3rem", marginTop: "0rem" }}>
                            {state.errors.apiStatusWarningErr.length > 0 && 
                              <div style={{ width: '100%', marginLeft:"1rem" }}>
                                <p style={{ color: "Green", fontSize: "1rem", textAlign: "left", paddingTop: "0.3rem", marginBottom: "1rem" }} className='errors'>
                                  {state.errors.apiStatusWarningErr}
                                </p>
                              </div>
                            }

                            <div style={{ width: '70%', marginLeft:"1rem" }}> {/* Inner div set to 70% width */}
                              <label className='log-form-label' style={{ textAlign: "left", fontSize: "medium" }}>API Key of selected order: </label>
                              <Space.Compact style={{ width: '100%' }}>
                                <Input readOnly style={{ userSelect: 'none' }} value={state.apiKeyOfselectedOrdLineNo} />
                                <Button style={{ backgroundColor: "var(--secondaryColor)" }} onClick={copyToClipboard}>Copy</Button>
                              </Space.Compact>
                            </div>
                          </div>
                          }

                        </div>
                      </div><br/>
                      <div className="inputgroupCustom pt-1" id="section2.2">
                        <label className="log-form-label SubMenuContentLabels"><h5><b>Your First Request</b></h5></label>
                        <label className="log-form-label"><h6><b>Generate Access Token</b></h6></label>
                        <p className="para">To make your first request, you'll need to generate an Access Token using your API Key. 
                              This token serves as a secure way to authenticate your requests to the Viliso-APIs.</p><br/>

                        <div className="apiDocContent" style={{ marginTop: '0rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                          <Row gutter={[16, 16]}>
                            {/* Second Column: Request - Response Samples Section */}
                            <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                              <div style={{ marginBottom: '1rem' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                  <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                    POST
                                  </span>
                                </div>
                                <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                                  URL:  https://smarthhm/vilisoapi/getAccessToken
                                </span>
                                <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                                  Content type: application/json
                                </span>
                              </div>

                              {/* <Title
                                level={5}
                                style={{
                                  borderBottom: '2px solid #28a745',
                                  paddingBottom: '0.3rem',
                                  color: '#28a745',
                                }}
                              >
                                Request Header
                              </Title>
                              <Table
                                columns={pathParamsColumns}
                                dataSource={pathParamsData}
                                pagination={false}
                                bordered
                                size="small"
                                style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                              /> */}
                              <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', marginTop: '1rem', color: '#28a745' }}>
                                Request Payload
                              </Title>
                              <Table
                                columns={queryParamsColumns}
                                dataSource={queryParamsGetAccessToken}
                                pagination={false}
                                bordered
                                size="small"
                              >
                              </Table>
                            </Col>

                            {/* Second Column: Request - Response Samples Section */}
                            <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                              <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                                Sample Request
                              </Title>
                              <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                                <p className="para">
                                  <pre className="jsonPre" id="0"></pre>
                                </p>
                              </div>

                              <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                                Sample Response
                              </Title>
                              <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                                <p className="para">
                                  <pre className="jsonPre" id="1"></pre>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="form-group logform " id="section3">
                    <label className="log-form-label MainMenuContentLabels"><h2>API Documentation</h2></label>
                    <div className="inputgroupCustom" id="section3.1">
                      <label className="log-form-label SubMenuContentLabels"><h4><b>Device List</b></h4></label>
                      <p className="para">Get device list of the user.</p><br/>

                      <div className="apiDocContent" style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                        <Row gutter={[16, 16]}>
                          {/* First Column: request header Section */}
                          <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                            <div style={{ marginBottom: '1rem' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                  POST
                                </span>
                              </div>
                              <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                                URL:  https://smarthhm/vilisoapi/getListOfRegisteredDevices
                              </span>
                              <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                                Content type: application/json
                              </span>
                            </div>

                            <Title
                              level={5}
                              style={{
                                borderBottom: '2px solid #28a745',
                                paddingBottom: '0.3rem',
                                color: '#28a745',
                              }}
                            >
                              Request Header
                            </Title>
                            <Table
                              columns={pathParamsColumns}
                              dataSource={pathParamsData}
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            />
                          </Col>

                          {/* Second Column: Request and Response samples Section */}
                          <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Sample Response
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="2"></pre>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                    </div>
                    <br/>
                    <div className="inputgroupCustom" id="section3.2">
                      <label className="log-form-label SubMenuContentLabels"><h4><b>Device Data</b></h4></label>
                      <p className="para">Get device data of the deviceID provided.</p><br/>

                      <div className="apiDocContent" style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                        <Row gutter={[16, 16]}>
                          {/* First Column: Path & Query Parameters */}
                          <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                            <div style={{ marginBottom: '1rem' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                  POST
                                </span>
                              </div>
                              <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                                URL:  https://smarthhm/vilisoapi/getCurrentDeviceData
                              </span>
                              <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                                Content type: application/json
                              </span>
                            </div>

                            {/* Path Parameters Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Request Header
                            </Title>
                            <Table
                              columns={pathParamsColumns}
                              dataSource={pathParamsData}
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            >
                            </Table>

                            {/* Query Parameters Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', marginTop: '1rem', color: '#28a745' }}>
                              Request Payload
                            </Title>
                            <Table
                              columns={queryParamsColumns}
                              dataSource={queryParamsDataDvcData}
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            >
                            </Table>
                          </Col>

                          {/* Second Column: Request & Response Samples */}
                          <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                            {/* Request Samples Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Request Sample
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="3"></pre>
                              </p>
                            </div>

                            {/* Response Samples Section */}
                            <Title level={5} style={{ marginTop: '1rem', borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Sample Response
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="4"></pre>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                    </div>
                    <br/>
                    <div className="inputgroupCustom" id="section3.3">
                      <label className="log-form-label SubMenuContentLabels"><h4><b>Device Data History</b></h4></label>
                      <p className="para">Get device data history of the deviceID provided.</p><br/>

                      <div className="apiDocContent" style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                        <Row gutter={[16, 16]}>
                          {/* First Column: Path & Query Parameters */}
                          <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                            <div style={{ marginBottom: '1rem' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                  POST
                                </span>
                              </div>
                              <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                                URL:  https://smarthhm/vilisoapi/getDeviceDataHistory
                              </span>
                              <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                                Content type: application/json
                              </span>
                            </div>

                            {/* Path Parameters Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Request Header
                            </Title>
                            <Table
                              columns={pathParamsColumns}
                              dataSource={pathParamsData}
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            >
                            </Table>

                            {/* Query Parameters Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', marginTop: '1rem', color: '#28a745' }}>
                              Request Payload
                            </Title>
                            <Table
                              columns={queryParamsColumns}
                              dataSource={queryParamsDataDataHist}
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            >
                              
                            </Table>
                          </Col>

                          {/* Second Column: Request & Response Samples */}
                          <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                            {/* Request Samples Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Sample Request
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="5"></pre>
                              </p>
                            </div>

                            {/* Response Samples Section */}
                            <Title level={5} style={{ marginTop: '1rem', borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Sample Response
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="6"></pre>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                    </div>
                    <br/>
                    <div className="inputgroupCustom" id="section3.4">
                      <label className="log-form-label SubMenuContentLabels"><h4><b>Device Profile</b></h4></label>
                      <p className="para">Get device profile of the deviceID provided.</p><br/>

                      <div className="apiDocContent" style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                        <Row gutter={[16, 16]}>
                          {/* First Column: Path & Query Parameters */}
                          <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                            <div style={{ marginBottom: '1rem' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                  POST
                                </span>
                              </div>
                              <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                                URL:  https://smarthhm/vilisoapi/getDeviceProfileInfo
                              </span>
                              <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                                Content type: application/json
                              </span>
                            </div>

                            {/* Path Parameters Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Request Header
                            </Title>
                            <Table
                              columns={pathParamsColumns}
                              dataSource={pathParamsData}
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            >
                              <tbody>
                                <tr>
                                  <td><span style={{ fontWeight: '600' }}>Access Token <b style={{ color: 'red' }}>*</b></span></td>
                                  <td>
                                    <span style={{ fontStyle: 'italic' }}>String</span><br />
                                    Generated Access Token.
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                            {/* Query Parameters Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', marginTop: '1rem', color: '#28a745' }}>
                              Request Payload
                            </Title>
                            <Table
                              columns={queryParamsColumns}
                              dataSource={queryParamsDataDvcData}
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            >
                              <tbody>
                                <tr>
                                  <td><span style={{ fontWeight: '600' }}>DeviceID <b style={{ color: 'red' }}>*</b></span></td>
                                  <td>
                                    <span style={{ fontStyle: 'italic' }}>String</span><br />
                                    DeviceID of the Device.
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>

                          {/* Second Column: Request & Response Samples */}
                          <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                            {/* Request Samples Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Sample Request
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="7"></pre>
                              </p>
                            </div>

                            {/* Response Samples Section */}
                            <Title level={5} style={{ marginTop: '1rem', borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Sample Response
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="8"></pre>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                    </div>
                    <br/>
                    <div className="inputgroupCustom" id="section3.5">
                      <label className="log-form-label SubMenuContentLabels"><h4><b>Parameters and Range Values</b></h4></label>
                      <p className="para">Get parameter details and ranges of specified device model.</p><br/>

                      <div className="apiDocContent" style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                        <Row gutter={[16, 16]}>
                          {/* First Column: Path & Query Parameters */}
                          <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                            <div style={{ marginBottom: '1rem' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                  POST
                                </span>
                              </div>
                              <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                                URL:  https://smarthhm/vilisoapi/getParametersWithRangeValues
                              </span>
                              <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                                Content type: application/json
                              </span>
                            </div>

                            {/* Path Parameters Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Request Header
                            </Title>
                            <Table
                              columns={pathParamsColumns}
                              dataSource={pathParamsData}
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            >
                              <tbody>
                                <tr>
                                  <td><span style={{ fontWeight: '600' }}>Access Token <b style={{ color: 'red' }}>*</b></span></td>
                                  <td>
                                    <span style={{ fontStyle: 'italic' }}>String</span><br />
                                    Generated Access Token.
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                            {/* Query Parameters Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', marginTop: '1rem', color: '#28a745' }}>
                              Request Payload
                            </Title>
                            <Table
                              columns={queryParamsColumns}
                              dataSource={queryParamsDataParamRanges}
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            >
                              <tbody>
                                <tr>
                                  <td><span style={{ fontWeight: '600' }}>MeasuredParams <b style={{ color: 'red' }}>*</b></span></td>
                                  <td>
                                    <span style={{ fontStyle: 'italic' }}>Array of Strings</span><br />
                                    Array of Measured Parameters.
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>

                          {/* Second Column: Request & Response Samples */}
                          <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                            {/* Request Samples Section */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Sample Request
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="9"></pre>
                              </p>
                            </div>

                            {/* Response Samples Section */}
                            <Title level={5} style={{ marginTop: '1rem', borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Sample Response
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="10"></pre>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                    </div>
                    <br/>
                    {/* <div className="inputgroupCustom" id="section3.6">
                      <label className="log-form-label SubMenuContentLabels"><h4><b>Device Settings</b></h4></label>
                      <p className="para">Get Device Settings of the DeviceID provided.</p><br/> */}

                      {/* <Container className="apiDocContent">
                        <Row >
                          <Col style={{padding:"0.5rem"}}>
                            <p className="para"><button className="btnPost"> POST </button> Content type: application/json</p>
                            <label className="log-form-label innerDocLabels"><h6>Request Header</h6></label>
                            <Table>
                              <tbody>
                                <tr>
                                  <td><p className="para">Access Token <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>String</i><br/>Generated Access Token.</p></td>
                                </tr>
                              </tbody>
                            </Table>
                            <label className="log-form-label innerDocLabels"><h6>Request Payload</h6></label>
                            <Table>
                              <tbody>
                                <tr>
                                  <td><p className="para">DeviceID <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>String</i><br/>DeviceID of the Device.</p></td>
                                </tr>
                              </tbody>
                            </Table>

                          </Col>
                          <Col style={{backgroundColor: "#a8cca8", padding:"0.5rem"}}>
                            <label className="log-form-label innerDocLabels"><h6>Sample Request</h6></label>
                            <div className="apiJson">
                              <p className="para"><pre className="jsonPre" id="11"></pre></p>
                            </div>
                            
                            <label className="log-form-label innerDocLabels"><h6>Sample Response</h6></label>
                            <div className="apiJson">
                              <p className="para"><pre className="jsonPre" id="12"></pre></p>
                            </div>
                          </Col>
                        </Row>
                      </Container> */}
                      {/* <div className="apiDocContent" style={{ padding: '1.5rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <Row gutter={[16, 16]}>
                          <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                            <p className="para">
                              <button className="btnPost" style={{ backgroundColor: '#007bff', border: 'none', padding: '0.5rem 1rem', color: '#fff', borderRadius: '4px' }}>
                                POST
                              </button> 
                              <span style={{ marginLeft: '0.5rem', fontWeight: 'bold' }}>Content type: application/json</span>
                            </p>
                            
                            <label className="log-form-label innerDocLabels">
                              <h6 style={{ color: '#007bff', borderBottom: '2px solid #28a745', paddingBottom: '0.5rem' }}>Request Header</h6>
                            </label>
                            <Table
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px', marginBottom: '1rem' }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <p className="para">Access Token <b style={{ color: 'red' }}>*</b></p>
                                  </td>
                                  <td>
                                    <p className="para"><i>String</i><br />Generated Access Token.</p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                            <label className="log-form-label innerDocLabels">
                              <h6 style={{ color: '#28a745', borderBottom: '2px solid #28a745', paddingBottom: '0.5rem' }}>Request Payload</h6>
                            </label>
                            <Table
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <p className="para">DeviceID <b style={{ color: 'red' }}>*</b></p>
                                  </td>
                                  <td>
                                    <p className="para"><i>String</i><br />DeviceID of the Device.</p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>

                          <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                            <label className="log-form-label innerDocLabels">
                              <h6 style={{ color: '#28a745', borderBottom: '2px solid #28a745', paddingBottom: '0.5rem' }}>Sample Request</h6>
                            </label>
                            <div className="apiJson" style={{ backgroundColor: '#f8f9fa', padding: '1rem', borderRadius: '5px', border: '1px solid #ccc', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="11"></pre>
                              </p>
                            </div>

                            <label className="log-form-label innerDocLabels" style={{ marginTop: '1rem' }}>
                              <h6 style={{ color: '#28a745', borderBottom: '2px solid #28a745', paddingBottom: '0.5rem' }}>Sample Response</h6>
                            </label>
                            <div className="apiJson" style={{ backgroundColor: '#f8f9fa', padding: '1rem', borderRadius: '5px', border: '1px solid #ccc', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="12"></pre>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div> */}
                    <br/>
                    {/* <div className="inputgroupCustom" id="section3.7">
                      <label className="log-form-label SubMenuContentLabels"><h4><b>Update Device Settings</b></h4></label>
                      <p className="para">Update Device Settings of the DeviceID provided.</p><br/>

                      <Container className="apiDocContent">
                        <Row >
                          <Col style={{padding:"0.5rem"}}>
                            <p className="para"><button className="btnPost"> POST </button> Content type: application/json</p>
                            <label className="log-form-label innerDocLabels"><h6>Request Header</h6></label>
                            <Table>
                              <tbody>
                                <tr>
                                  <td><p className="para">Access Token <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>String</i><br/>Generated Access Token.</p></td>
                                </tr>
                              </tbody>
                            </Table>
                            <label className="log-form-label innerDocLabels"><h6>Request Payload</h6></label>
                            <Table>
                              <tbody>
                                <tr>
                                  <td><p className="para">DeviceID <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>String</i><br/>DeviceID of the Device.</p></td>
                                </tr>
                                <tr>
                                  <td><p className="para">Parameter <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>String</i><br/>Measured Parameter of the Device.</p></td>
                                </tr>
                                <tr>
                                  <td><p className="para">Range <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>String</i><br/>Range of the Measured Parameter.</p></td>
                                </tr>
                                <tr>
                                  <td><p className="para">Value <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>Integer</i><br/>Value of the Range.</p></td>
                                </tr>
                              </tbody>
                            </Table>

                          </Col>
                          <Col style={{backgroundColor: "#a8cca8", padding:"0.5rem"}}>
                            <label className="log-form-label innerDocLabels"><h6>Sample Request</h6></label>
                            <div className="apiJson">
                              <p className="para"><pre className="jsonPre" id="13"></pre></p>
                            </div>
                            
                            <label className="log-form-label innerDocLabels"><h6>Sample Response</h6></label>
                            <div className="apiJson">
                              <p className="para"><pre className="jsonPre" id="14"></pre></p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    <br/>
                    <div className="inputgroupCustom" id="section3.8">
                      <label className="log-form-label SubMenuContentLabels"><h4><b>On Off Alert Notification Settings</b></h4></label>
                      <p className="para">Update Alert Notification Settings of the specified DeviceID.</p><br/>

                      <Container className="apiDocContent">
                        <Row >
                          <Col style={{padding:"0.5rem"}}>
                            <p className="para"><button className="btnPost"> POST </button> Content type: application/json</p>
                            <label className="log-form-label innerDocLabels"><h6>Request Header</h6></label>
                            <Table>
                              <tbody>
                                <tr>
                                  <td><p className="para">Access Token <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>String</i><br/>Generated Access Token.</p></td>
                                </tr>
                              </tbody>
                            </Table>
                            <label className="log-form-label innerDocLabels"><h6>Request Payload</h6></label>
                            <Table>
                              <tbody>
                                <tr>
                                  <td><p className="para">DeviceID <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>String</i><br/>DeviceID of the Device.</p></td>
                                </tr>
                                <tr>
                                  <td><p className="para">Parameter <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>String</i><br/>Measured Parameter of the Device.</p></td>
                                </tr>
                                <tr>
                                  <td><p className="para">NotifyStatus <b style={{color:"Red"}}>*</b></p></td>
                                  <td><p className="para"><i>Boolean</i><br/>Notification status of the specified measured parameter.</p></td>
                                </tr>
                              </tbody>
                            </Table>

                          </Col>
                          <Col style={{backgroundColor: "#a8cca8", padding:"0.5rem"}}>
                            <label className="log-form-label innerDocLabels"><h6>Sample Request</h6></label>
                            <div className="apiJson">
                              <p className="para"><pre className="jsonPre" id="15"></pre></p>
                            </div>
                            
                            <label className="log-form-label innerDocLabels"><h6>Sample Response</h6></label>
                            <div className="apiJson">
                              <p className="para"><pre className="jsonPre" id="16"></pre></p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div> */}
                  </div>
                  <br/>
                  <hr></hr>
                  <div className="form-group logform pb-5" id="section4">
                    <div className="inputgroupCustom">
                      <label className="log-form-label MainMenuContentLabels"><h2>Developer Guidelines</h2></label>
                      <List
                        style={{ width: '100%' }}
                        bordered
                        dataSource={userGuidelinesData}
                        renderItem={(item) => (
                          <List.Item style={{ textAlign: 'left', width: '100%' }}>
                            <span
                              dangerouslySetInnerHTML={{ __html: item }}
                              style={{ textAlign: 'left', display: 'block' }}
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                  </div>               
                </div>
              </div>    
            </div>  
          </div>
        </div>
      </div>
    :
      <div className="d-flex">
        <div className='d-flex' style={{ width: "4%" }}>
          <div className="shadow sideNav" style={{ width: "100%" }}>
            <VcSideNav />
          </div>
        </div>        

        <div className="treeAndContentCRM">
          <VcNavBar />

          <div className="row" >
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-2 col-xxl-3" style={{backgroundColor: "", height: "70vh",overflow: "none"}}>
              <div>
                <div style={{display: "none"}}><FaArrowCircleRight /></div>
              </div>
              <div class="pb-0" >
                <div className="sidebar-header">
                  <h4><b>Viliso APIs</b></h4>
                </div>
                <div className="menuNavbar side-menu">
                  <nav id="menu-list" className="navbar navbar-light flex-column align-items-stretch p-3">
                    <nav className="nav nav-pills flex-column">
                      <a className="apiNavlink" activeClassName="apiNavlinkActive" href="#section1">Overview</a>
                      <a className="apiNavlink" href="#section2">Get Started</a>
                      <a className="apiNavlink ms-2 my-1" href="#section2.1">Before You Start With Your First Request</a>
                      <a className="apiNavlink ms-2 my-1" href="#section2.2">Your First Request</a>
                      <a className="apiNavlink" href="#section3">API Documentation</a>
                      <a className="apiNavlink ms-2 my-1" href="#section3.1">Device List</a>
                      <a className="apiNavlink ms-2 my-1" href="#section3.2">Device Data</a>
                      <a className="apiNavlink ms-2 my-1" href="#section3.3">Device Data History</a>
                      <a className="apiNavlink ms-2 my-1" href="#section3.4">Device Profile</a>
                      <a className="apiNavlink ms-2 my-1" href="#section3.5">Air Quality Ranges</a>
                      {/* <a className="apiNavlink ms-2 my-1" href="#section3.6">Device Settings</a> */}
                      {/* <a className="apiNavlink ms-2 my-1" href="#section3.7">Update Device Settings</a> */}
                      {/* <a className="apiNavlink ms-2 my-1" href="#section3.8">On/Off Alert Notification Settings</a> */}
                      <a className="apiNavlink" href="#section4">Developer Guidelines</a>
                    </nav>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-9 p-1" data-coreui-spy="scroll" data-coreui-target="#menu-list" data-coreui-offset="0" tabindex="0" style={{overflow:"auto", overflowY: "scroll"}} >
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  sddSingleParam" style={{height:"88vh", display:"flex", justifyContent:"left", alignItems:"left"}}>
                <div style={{height:"88vh", width: "100%", marginLeft: "1rem", marginRight: "1rem"}}>
                  <div id="section1"><div className="form-group logform pb-0" >
                    <div className="inputgroupCustom">
                      <label className="log-form-label MainMenuContentLabels"><h2>Overview</h2></label>
                      <p className="para">
                        The Viliso-APIs provide a robust interface for developers to access data related to our smart HHM products. 
                        With the Viliso-APIs, you can manage and maintain data across all of our devices, including key products like Gandhvedh and Vayuvedh. 
                        Our APIs offer the tools you need to seamlessly interact with the smartHHM ecosystem.
                      </p>
                    </div>
                  </div>
                </div>

                <hr></hr>
                <div className="form-group logform pt-0" id="section2">
                  <div className="inputgroupCustom">
                    <label className="log-form-label MainMenuContentLabels"><h2>Get Started</h2></label>
                    <p className="para">This section outlines the steps to begin receiving data from smartHHM using the Viliso-APIs.</p><br/><br/>
                    <div class="pt-0" id="section2.1">
                      <label className="log-form-label SubMenuContentLabels" ><h5><b>Before You Start With Your First Request</b></h5></label>
                      <div className="inputgroupCustom">

                        <label className="log-form-label" ><h6>Step 1: Select Order Line Number</h6></label>
                        <div><p className="para">From the dropdown, choose the 'Order Line Number' for which you'd like to generate an API Key.</p>
                        </div><br/>

                        <div style={{ width: "50%", clear: "both",marginLeft:"1rem", textAlign: "left" }}>
                          <Select
                            defaultValue="Select Order Line Number..."
                            style={{
                              width: "100%",
                            }}
                            onChange={onSelectOrderLineNumber}
                          >
                            {(state.arrAllOrdLineNoDetails.map((singleOrdLineNo, index) => 
                            <options key={index} value={singleOrdLineNo.OrderLineNumber}>{"ApiOrder" + state.loggedInUserName + singleOrdLineNo.OrderLineNumber}</options>))} 
                          </Select><br/>
                        </div>

                        {state.apiStatusWarning != null && state.apiStatusWarning.length > 0 &&
                        <div style={{display: "inline-block"}}>
                          {/* <span btn-sm style={{marginLeft: "3rem", backgroundColor: "red", height:"2rem", border:"0rem", borderRadius: "5px", color:"#fff"}}>{state.apiStatusWarning}</span> */}

                          <span style={{marginLeft: "3rem",backgroundColor: 'red', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                          {state.apiStatusWarning}
                                </span>

                          <br/></div>
                        } 

                        {state.selectedOrdLineNo != null && state.selectedOrdLineNo != "" && state.selectedOrdLineNo.length >= 0 &&
                        state.isAPIActive != null && state.isAPIActive == "0" &&
                        <div className='container' style={{marginTop:"1rem"}}>
                          <p className='para'>Package Expiry Date: {state.PkgEndDtTmOfselectedOrdLineNo}</p>
                          <p className='para'>Viliso API Hits Consumed: </p>
                          <div style={{width:"10%", marginLeft:"1rem"}}>

                            <CircularProgressbar 
                              value={state.progressBarpercentage} 
                              text={`${state.progressBarpercentage}%`} 
                              styles= {buildStyles({
                              pathColor: state.progressBarpercentage <= 25 ? "green" : 
                                          state.progressBarpercentage > 25 && state.progressBarpercentage <= 50 ? "gold" :
                                          state.progressBarpercentage > 50 && state.progressBarpercentage <= 75 ? "orange" :
                                          "red"
                            })}
                            />
                          </div>
                        </div>
                        }

                      </div><br/>
                      <div className="inputgroupCustom">
                        <label className="log-form-label"><h6>Step 2: Generate API Key</h6></label>
                        <p className="para">Click on <b>Generate API Key</b> button.</p><br></br>
                        <p className="para"><b>API Key</b> is required to authorize the developer or user. After authorization, you can generate a <b>Access Token</b> using the Viliso-APIs, which will authenticate subsequent requests.</p><br/>                        
                        <br></br>
                  
                        {state.showGenerateKeyBtn == true &&
                          <Button 
                            onClick={(state.apiStatus == "Deactive" || state.apiStatus == "ApiKeyExpired" || state.apiStatus == "PkgExpired" || state.apiStatus == "MaxHitsOver") ? null : state.apiKeyOfselectedOrdLineNo.length <= 0 ? OnClickGenerateApiKeyBtn : showConfirm} 
                            disabled={(state.apiStatus == "Deactive" || state.apiStatus == "ApiKeyExpired" || state.apiStatus == "PkgExpired" || state.apiStatus == "MaxHitsOver" || state.selectedOrdLineNo.length <= 0) ? true : false}

                            style={{position: "relative", marginRight: "0.5rem", backgroundColor: "var(--primaryColor)", color:"white", marginLeft:"1rem"}}
                          >
                            {state.apiKeyOfselectedOrdLineNo.length > 0 ? "Re-Generate API Key" : "Generate API Key"}
                          </Button>
                        } 
                        <br/>

                        {/* <p className="para"></p> */}
                        <br/>
                        {state.showActDeactKeyBtn == true &&
                        <Button onClick={OnClickActiveDeactiveBtn} 
                          style={{position: "relative", backgroundColor: "var(--secondaryColor)"}}>
                          {state.activeBtnStatus == "Deactive" ? "Deactivate API Key" : "Activate API Key"}
                        </Button>
                        }
                        <br/>

                        {(state.selectedOrdLineNo != null && state.selectedOrdLineNo != "" && state.selectedOrdLineNo.length >= 0 ) &&
                        (state.apiKeyOfselectedOrdLineNo != null && state.apiKeyOfselectedOrdLineNo != "" && state.apiKeyOfselectedOrdLineNo.length >= 0)
                        &&
                        
                        <div style={{ position: "relative", width: "100%", paddingBottom: "1.3rem", marginTop: "0rem" }}>
                          {state.errors.apiStatusWarningErr.length > 0 && 
                            <div style={{ width: '100%', marginLeft:"1rem" }}>
                              <p style={{ color: "Green", fontSize: "1rem", textAlign: "left", paddingTop: "0.3rem", marginBottom: "1rem" }} className='errors'>
                                {state.errors.apiStatusWarningErr}
                              </p>
                            </div>
                          }

                          <div style={{ width: '70%', marginLeft:"1rem" }}> {/* Inner div set to 70% width */}
                            <label className='log-form-label' style={{ textAlign: "left", fontSize: "medium" }}>API Key of selected order: </label>
                            <Space.Compact style={{ width: '100%' }}>
                              <Input readOnly style={{ userSelect: 'none' }} value={state.apiKeyOfselectedOrdLineNo} />
                              <Button style={{ backgroundColor: "var(--secondaryColor)" }} onClick={copyToClipboard}>Copy</Button>
                            </Space.Compact>
                          </div>
                        </div>
                        }

                      </div>
                    </div><br/>
                    <div className="inputgroupCustom pt-1" id="section2.2">
                      <label className="log-form-label SubMenuContentLabels"><h5><b>Your First Request</b></h5></label>
                      <label className="log-form-label"><h6><b>Generate Access Token</b></h6></label>
                      <p className="para">To make your first request, you'll need to generate an Access Token using your API Key. 
                            This token serves as a secure way to authenticate your requests to the Viliso-APIs.</p><br/>

                      <div className="apiDocContent" style={{ marginTop: '0rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                        <Row gutter={[16, 16]}>
                          {/* Second Column: Request - Response Samples Section */}
                          <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                            <div style={{ marginBottom: '1rem' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                  POST
                                </span>
                              </div>
                              <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                                URL:  https://smarthhm/vilisoapi/getAccessToken
                              </span>
                              <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                                Content type: application/json
                              </span>
                            </div>

                            {/* <Title
                              level={5}
                              style={{
                                borderBottom: '2px solid #28a745',
                                paddingBottom: '0.3rem',
                                color: '#28a745',
                              }}
                            >
                              Request Header
                            </Title>
                            <Table
                              columns={pathParamsColumns}
                              dataSource={pathParamsData}
                              pagination={false}
                              bordered
                              size="small"
                              style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                            /> */}
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', marginTop: '1rem', color: '#28a745' }}>
                              Request Payload
                            </Title>
                            <Table
                              columns={queryParamsColumns}
                              dataSource={queryParamsGetAccessToken}
                              pagination={false}
                              bordered
                              size="small"
                            >
                            </Table>
                          </Col>

                          {/* Second Column: Request - Response Samples Section */}
                          <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Sample Request
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="0"></pre>
                              </p>
                            </div>

                            <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                              Sample Response
                            </Title>
                            <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                              <p className="para">
                                <pre className="jsonPre" id="1"></pre>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="form-group logform " id="section3">
                  <label className="log-form-label MainMenuContentLabels"><h2>API Documentation</h2></label>
                  <div className="inputgroupCustom" id="section3.1">
                    <label className="log-form-label SubMenuContentLabels"><h4><b>Device List</b></h4></label>
                    <p className="para">Get device list of the user.</p><br/>

                    <div className="apiDocContent" style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                      <Row gutter={[16, 16]}>
                        {/* First Column: request header Section */}
                        <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                          <div style={{ marginBottom: '1rem' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                POST
                              </span>
                            </div>
                            <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                              URL:  https://smarthhm/vilisoapi/getListOfRegisteredDevices
                            </span>
                            <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                              Content type: application/json
                            </span>
                          </div>

                          <Title
                            level={5}
                            style={{
                              borderBottom: '2px solid #28a745',
                              paddingBottom: '0.3rem',
                              color: '#28a745',
                            }}
                          >
                            Request Header
                          </Title>
                          <Table
                            columns={pathParamsColumns}
                            dataSource={pathParamsData}
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                          />
                        </Col>

                        {/* Second Column: Request and Response samples Section */}
                        <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Sample Response
                          </Title>
                          <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="2"></pre>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>

                  </div>
                  <br/>
                  <div className="inputgroupCustom" id="section3.2">
                    <label className="log-form-label SubMenuContentLabels"><h4><b>Device Data</b></h4></label>
                    <p className="para">Get device data of the deviceID provided.</p><br/>

                    <div className="apiDocContent" style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                      <Row gutter={[16, 16]}>
                        {/* First Column: Path & Query Parameters */}
                        <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                          <div style={{ marginBottom: '1rem' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                POST
                              </span>
                            </div>
                            <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                              URL:  https://smarthhm/vilisoapi/getCurrentDeviceData
                            </span>
                            <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                              Content type: application/json
                            </span>
                          </div>

                          {/* Path Parameters Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Request Header
                          </Title>
                          <Table
                            columns={pathParamsColumns}
                            dataSource={pathParamsData}
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                          >
                          </Table>

                          {/* Query Parameters Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', marginTop: '1rem', color: '#28a745' }}>
                            Request Payload
                          </Title>
                          <Table
                            columns={queryParamsColumns}
                            dataSource={queryParamsDataDvcData}
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                          >
                          </Table>
                        </Col>

                        {/* Second Column: Request & Response Samples */}
                        <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                          {/* Request Samples Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Request Sample
                          </Title>
                          <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="3"></pre>
                            </p>
                          </div>

                          {/* Response Samples Section */}
                          <Title level={5} style={{ marginTop: '1rem', borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Sample Response
                          </Title>
                          <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="4"></pre>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>

                  </div>
                  <br/>
                  <div className="inputgroupCustom" id="section3.3">
                    <label className="log-form-label SubMenuContentLabels"><h4><b>Device Data History</b></h4></label>
                    <p className="para">Get device data history of the deviceID provided.</p><br/>

                    <div className="apiDocContent" style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                      <Row gutter={[16, 16]}>
                        {/* First Column: Path & Query Parameters */}
                        <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                          <div style={{ marginBottom: '1rem' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                POST
                              </span>
                            </div>
                            <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                              URL:  https://smarthhm/vilisoapi/getDeviceDataHistory
                            </span>
                            <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                              Content type: application/json
                            </span>
                          </div>

                          {/* Path Parameters Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Request Header
                          </Title>
                          <Table
                            columns={pathParamsColumns}
                            dataSource={pathParamsData}
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                          >
                          </Table>

                          {/* Query Parameters Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', marginTop: '1rem', color: '#28a745' }}>
                            Request Payload
                          </Title>
                          <Table
                            columns={queryParamsColumns}
                            dataSource={queryParamsDataDataHist}
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                          >
                            
                          </Table>
                        </Col>

                        {/* Second Column: Request & Response Samples */}
                        <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                          {/* Request Samples Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Sample Request
                          </Title>
                          <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="5"></pre>
                            </p>
                          </div>

                          {/* Response Samples Section */}
                          <Title level={5} style={{ marginTop: '1rem', borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Sample Response
                          </Title>
                          <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="6"></pre>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>

                  </div>
                  <br/>
                  <div className="inputgroupCustom" id="section3.4">
                    <label className="log-form-label SubMenuContentLabels"><h4><b>Device Profile</b></h4></label>
                    <p className="para">Get device profile of the deviceID provided.</p><br/>

                    <div className="apiDocContent" style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                      <Row gutter={[16, 16]}>
                        {/* First Column: Path & Query Parameters */}
                        <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                          <div style={{ marginBottom: '1rem' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                POST
                              </span>
                            </div>
                            <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                              URL:  https://smarthhm/vilisoapi/getDeviceProfileInfo
                            </span>
                            <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                              Content type: application/json
                            </span>
                          </div>

                          {/* Path Parameters Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Request Header
                          </Title>
                          <Table
                            columns={pathParamsColumns}
                            dataSource={pathParamsData}
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                          >
                            <tbody>
                              <tr>
                                <td><span style={{ fontWeight: '600' }}>Access Token <b style={{ color: 'red' }}>*</b></span></td>
                                <td>
                                  <span style={{ fontStyle: 'italic' }}>String</span><br />
                                  Generated Access Token.
                                </td>
                              </tr>
                            </tbody>
                          </Table>

                          {/* Query Parameters Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', marginTop: '1rem', color: '#28a745' }}>
                            Request Payload
                          </Title>
                          <Table
                            columns={queryParamsColumns}
                            dataSource={queryParamsDataDvcData}
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                          >
                            <tbody>
                              <tr>
                                <td><span style={{ fontWeight: '600' }}>DeviceID <b style={{ color: 'red' }}>*</b></span></td>
                                <td>
                                  <span style={{ fontStyle: 'italic' }}>String</span><br />
                                  DeviceID of the Device.
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>

                        {/* Second Column: Request & Response Samples */}
                        <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                          {/* Request Samples Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Sample Request
                          </Title>
                          <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="7"></pre>
                            </p>
                          </div>

                          {/* Response Samples Section */}
                          <Title level={5} style={{ marginTop: '1rem', borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Sample Response
                          </Title>
                          <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="8"></pre>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>

                  </div>
                  <br/>
                  <div className="inputgroupCustom" id="section3.5">
                    <label className="log-form-label SubMenuContentLabels"><h4><b>Parameters and Range Values</b></h4></label>
                    <p className="para">Get parameter details and ranges of specified device model.</p><br/>

                    <div className="apiDocContent" style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%' }}>
                      <Row gutter={[16, 16]}>
                        {/* First Column: Path & Query Parameters */}
                        <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                          <div style={{ marginBottom: '1rem' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <span style={{backgroundColor: '#28a745', color: '#fff', padding: '4px 8px', borderRadius: '4px', fontWeight: 'bold', marginBottom: '0.5rem'}}>
                                POST
                              </span>
                            </div>
                            <span style={{ fontWeight: '600', wordWrap: 'break-word', whiteSpace: 'normal', width: '100%', textAlign: 'left', display: 'block'}}>
                              URL:  https://smarthhm/vilisoapi/getParametersWithRangeValues
                            </span>
                            <span style={{ marginTop: '0.5rem', display: 'block', fontWeight: '600', textAlign: 'left'}}>
                              Content type: application/json
                            </span>
                          </div>

                          {/* Path Parameters Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Request Header
                          </Title>
                          <Table
                            columns={pathParamsColumns}
                            dataSource={pathParamsData}
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                          >
                            <tbody>
                              <tr>
                                <td><span style={{ fontWeight: '600' }}>Access Token <b style={{ color: 'red' }}>*</b></span></td>
                                <td>
                                  <span style={{ fontStyle: 'italic' }}>String</span><br />
                                  Generated Access Token.
                                </td>
                              </tr>
                            </tbody>
                          </Table>

                          {/* Query Parameters Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', marginTop: '1rem', color: '#28a745' }}>
                            Request Payload
                          </Title>
                          <Table
                            columns={queryParamsColumns}
                            dataSource={queryParamsDataParamRanges}
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                          >
                            <tbody>
                              <tr>
                                <td><span style={{ fontWeight: '600' }}>MeasuredParams <b style={{ color: 'red' }}>*</b></span></td>
                                <td>
                                  <span style={{ fontStyle: 'italic' }}>Array of Strings</span><br />
                                  Array of Measured Parameters.
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>

                        {/* Second Column: Request & Response Samples */}
                        <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                          {/* Request Samples Section */}
                          <Title level={5} style={{ borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Sample Request
                          </Title>
                          <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="9"></pre>
                            </p>
                          </div>

                          {/* Response Samples Section */}
                          <Title level={5} style={{ marginTop: '1rem', borderBottom: '2px solid #28a745', paddingBottom: '0.3rem', color: '#28a745' }}>
                            Sample Response
                          </Title>
                          <div style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '4px', border: '1px solid #dee2e6', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="10"></pre>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>

                  </div>
                  <br/>
                  {/* <div className="inputgroupCustom" id="section3.6">
                    <label className="log-form-label SubMenuContentLabels"><h4><b>Device Settings</b></h4></label>
                    <p className="para">Get Device Settings of the DeviceID provided.</p><br/> */}

                    {/* <Container className="apiDocContent">
                      <Row >
                        <Col style={{padding:"0.5rem"}}>
                          <p className="para"><button className="btnPost"> POST </button> Content type: application/json</p>
                          <label className="log-form-label innerDocLabels"><h6>Request Header</h6></label>
                          <Table>
                            <tbody>
                              <tr>
                                <td><p className="para">Access Token <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>String</i><br/>Generated Access Token.</p></td>
                              </tr>
                            </tbody>
                          </Table>
                          <label className="log-form-label innerDocLabels"><h6>Request Payload</h6></label>
                          <Table>
                            <tbody>
                              <tr>
                                <td><p className="para">DeviceID <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>String</i><br/>DeviceID of the Device.</p></td>
                              </tr>
                            </tbody>
                          </Table>

                        </Col>
                        <Col style={{backgroundColor: "#a8cca8", padding:"0.5rem"}}>
                          <label className="log-form-label innerDocLabels"><h6>Sample Request</h6></label>
                          <div className="apiJson">
                            <p className="para"><pre className="jsonPre" id="11"></pre></p>
                          </div>
                          
                          <label className="log-form-label innerDocLabels"><h6>Sample Response</h6></label>
                          <div className="apiJson">
                            <p className="para"><pre className="jsonPre" id="12"></pre></p>
                          </div>
                        </Col>
                      </Row>
                    </Container> */}
                    {/* <div className="apiDocContent" style={{ padding: '1.5rem', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={12} style={{ padding: '0.5rem' }}>
                          <p className="para">
                            <button className="btnPost" style={{ backgroundColor: '#007bff', border: 'none', padding: '0.5rem 1rem', color: '#fff', borderRadius: '4px' }}>
                              POST
                            </button> 
                            <span style={{ marginLeft: '0.5rem', fontWeight: 'bold' }}>Content type: application/json</span>
                          </p>
                          
                          <label className="log-form-label innerDocLabels">
                            <h6 style={{ color: '#007bff', borderBottom: '2px solid #28a745', paddingBottom: '0.5rem' }}>Request Header</h6>
                          </label>
                          <Table
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px', marginBottom: '1rem' }}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <p className="para">Access Token <b style={{ color: 'red' }}>*</b></p>
                                </td>
                                <td>
                                  <p className="para"><i>String</i><br />Generated Access Token.</p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>

                          <label className="log-form-label innerDocLabels">
                            <h6 style={{ color: '#28a745', borderBottom: '2px solid #28a745', paddingBottom: '0.5rem' }}>Request Payload</h6>
                          </label>
                          <Table
                            pagination={false}
                            bordered
                            size="small"
                            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <p className="para">DeviceID <b style={{ color: 'red' }}>*</b></p>
                                </td>
                                <td>
                                  <p className="para"><i>String</i><br />DeviceID of the Device.</p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>

                        <Col xs={24} sm={12} md={12} style={{ backgroundColor: '#e9f5e9', padding: '0.5rem', borderRadius: '8px' }}>
                          <label className="log-form-label innerDocLabels">
                            <h6 style={{ color: '#28a745', borderBottom: '2px solid #28a745', paddingBottom: '0.5rem' }}>Sample Request</h6>
                          </label>
                          <div className="apiJson" style={{ backgroundColor: '#f8f9fa', padding: '1rem', borderRadius: '5px', border: '1px solid #ccc', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="11"></pre>
                            </p>
                          </div>

                          <label className="log-form-label innerDocLabels" style={{ marginTop: '1rem' }}>
                            <h6 style={{ color: '#28a745', borderBottom: '2px solid #28a745', paddingBottom: '0.5rem' }}>Sample Response</h6>
                          </label>
                          <div className="apiJson" style={{ backgroundColor: '#f8f9fa', padding: '1rem', borderRadius: '5px', border: '1px solid #ccc', minHeight: '100px' }}>
                            <p className="para">
                              <pre className="jsonPre" id="12"></pre>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div> */}
                  <br/>
                  {/* <div className="inputgroupCustom" id="section3.7">
                    <label className="log-form-label SubMenuContentLabels"><h4><b>Update Device Settings</b></h4></label>
                    <p className="para">Update Device Settings of the DeviceID provided.</p><br/>

                    <Container className="apiDocContent">
                      <Row >
                        <Col style={{padding:"0.5rem"}}>
                          <p className="para"><button className="btnPost"> POST </button> Content type: application/json</p>
                          <label className="log-form-label innerDocLabels"><h6>Request Header</h6></label>
                          <Table>
                            <tbody>
                              <tr>
                                <td><p className="para">Access Token <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>String</i><br/>Generated Access Token.</p></td>
                              </tr>
                            </tbody>
                          </Table>
                          <label className="log-form-label innerDocLabels"><h6>Request Payload</h6></label>
                          <Table>
                            <tbody>
                              <tr>
                                <td><p className="para">DeviceID <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>String</i><br/>DeviceID of the Device.</p></td>
                              </tr>
                              <tr>
                                <td><p className="para">Parameter <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>String</i><br/>Measured Parameter of the Device.</p></td>
                              </tr>
                              <tr>
                                <td><p className="para">Range <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>String</i><br/>Range of the Measured Parameter.</p></td>
                              </tr>
                              <tr>
                                <td><p className="para">Value <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>Integer</i><br/>Value of the Range.</p></td>
                              </tr>
                            </tbody>
                          </Table>

                        </Col>
                        <Col style={{backgroundColor: "#a8cca8", padding:"0.5rem"}}>
                          <label className="log-form-label innerDocLabels"><h6>Sample Request</h6></label>
                          <div className="apiJson">
                            <p className="para"><pre className="jsonPre" id="13"></pre></p>
                          </div>
                          
                          <label className="log-form-label innerDocLabels"><h6>Sample Response</h6></label>
                          <div className="apiJson">
                            <p className="para"><pre className="jsonPre" id="14"></pre></p>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <br/>
                  <div className="inputgroupCustom" id="section3.8">
                    <label className="log-form-label SubMenuContentLabels"><h4><b>On Off Alert Notification Settings</b></h4></label>
                    <p className="para">Update Alert Notification Settings of the specified DeviceID.</p><br/>

                    <Container className="apiDocContent">
                      <Row >
                        <Col style={{padding:"0.5rem"}}>
                          <p className="para"><button className="btnPost"> POST </button> Content type: application/json</p>
                          <label className="log-form-label innerDocLabels"><h6>Request Header</h6></label>
                          <Table>
                            <tbody>
                              <tr>
                                <td><p className="para">Access Token <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>String</i><br/>Generated Access Token.</p></td>
                              </tr>
                            </tbody>
                          </Table>
                          <label className="log-form-label innerDocLabels"><h6>Request Payload</h6></label>
                          <Table>
                            <tbody>
                              <tr>
                                <td><p className="para">DeviceID <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>String</i><br/>DeviceID of the Device.</p></td>
                              </tr>
                              <tr>
                                <td><p className="para">Parameter <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>String</i><br/>Measured Parameter of the Device.</p></td>
                              </tr>
                              <tr>
                                <td><p className="para">NotifyStatus <b style={{color:"Red"}}>*</b></p></td>
                                <td><p className="para"><i>Boolean</i><br/>Notification status of the specified measured parameter.</p></td>
                              </tr>
                            </tbody>
                          </Table>

                        </Col>
                        <Col style={{backgroundColor: "#a8cca8", padding:"0.5rem"}}>
                          <label className="log-form-label innerDocLabels"><h6>Sample Request</h6></label>
                          <div className="apiJson">
                            <p className="para"><pre className="jsonPre" id="15"></pre></p>
                          </div>
                          
                          <label className="log-form-label innerDocLabels"><h6>Sample Response</h6></label>
                          <div className="apiJson">
                            <p className="para"><pre className="jsonPre" id="16"></pre></p>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div> */}
                </div>
                <br/>
                <hr></hr>
                <div className="form-group logform pb-5" id="section4">
                  <div className="inputgroupCustom">
                    <label className="log-form-label MainMenuContentLabels"><h2>Developer Guidelines</h2></label>
                    <List
                      style={{ width: '100%' }}
                      bordered
                      dataSource={userGuidelinesData}
                      renderItem={(item) => (
                        <List.Item style={{ textAlign: 'left', width: '100%' }}>
                          <span
                            dangerouslySetInnerHTML={{ __html: item }}
                            style={{ textAlign: 'left', display: 'block' }}
                          />
                        </List.Item>
                      )}
                    />
                  </div>
                </div>
                </div>               
              </div>
            </div>    
          </div>
        </div>
      </div>  
  )
}

export default VcVilisoAPIs;