import React, { Component } from 'react'
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import "react-tabs/style/react-tabs.css";
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';

export class VcCalibAppLinks extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 'HHTPSCalibApp',
            HTTPSProdCurrentVersion: "",
            HTTPSDevCurrentVersion: "",
            MQTTDevCurrentVersion: "",
            MQTTProdCurrentVersion: "",
            AppInformationArr: [],
            errors: {
                others: ""
            }
        }
    }

    componentDidMount() {
        this.getCalibAppVersions();
    }

    toggle(tab) {
        let modifiedState = this.state;
        if (modifiedState.activeTab !== tab) {
            modifiedState.activeTab = tab;
        } else {
            modifiedState.activeTab = modifiedState.activeTab;
        }
        
        this.setState(modifiedState);
    }
    
    getCalibAppVersions = () => {

        let modifiedState = this.state;

        modifiedState.HTTPSDevCurrentVersion = "";
        modifiedState.HTTPSProdCurrentVersion = "";
        modifiedState.MQTTDevCurrentVersion = "";
        modifiedState.MQTTProdCurrentVersion = "";
        modifiedState.errors.others = "";

        axios.post( `${getAPIHostURL()}/wclient/getMobileAppDetails`)
        .then(response => {
            if(response.data.code == 'SUCCESS') {    
                modifiedState.AppInformationArr = [];
                modifiedState.AppInformationArr = response.data.retrivedAppInformation;
                if(modifiedState.AppInformationArr != null && modifiedState.AppInformationArr.length > 0) {
                    for(let i = 0; i<modifiedState.AppInformationArr.length; i++) {
                        if(modifiedState.AppInformationArr[i]["AppName"] == "CalibApp" && modifiedState.AppInformationArr[i]["Platform"] == "HTTPS_DEV") {
                            modifiedState.HTTPSDevCurrentVersion = modifiedState.AppInformationArr[i]["AppVersion"]
                        } else if(modifiedState.AppInformationArr[i]["AppName"] == "CalibApp" && modifiedState.AppInformationArr[i]["Platform"] == "HTTPS_PROD"){
                            modifiedState.HTTPSProdCurrentVersion = modifiedState.AppInformationArr[i]["AppVersion"]
                        } else if(modifiedState.AppInformationArr[i]["AppName"] == "CalibApp" && modifiedState.AppInformationArr[i]["Platform"] == "MQTT_DEV"){
                            modifiedState.MQTTDevCurrentVersion = modifiedState.AppInformationArr[i]["AppVersion"]
                        } else if(modifiedState.AppInformationArr[i]["AppName"] == "CalibApp" && modifiedState.AppInformationArr[i]["Platform"] == "MQTT_PROD"){
                            modifiedState.MQTTProdCurrentVersion = modifiedState.AppInformationArr[i]["AppVersion"]
                        } else {
                            // Do Nothing
                        }
                    }
                } else {
                    modifiedState.errors.others = 'App Details Not Present On Server.';
                }   
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        });
    }

    onClickGetHHTPSCalibDevApp = (e) => {
        window.open("https://drive.google.com/file/d/1sbUbJQ-3OM8p-jVPN5A07z16kZxfC0Vt/view?usp=sharing", "_blank");
    }

    onClickGetHHTPSCalibProdApp = (e) => {
        window.open("https://drive.google.com/file/d/1MQO0Bxqyhj_84joprKGSoLteP-JzNgSs/view?usp=sharing", "_blank");
    }

    onClickGetMQTTSCalibDevApp = (e) => {
        window.open("https://drive.google.com/file/d/1ORgxxNBVJtikecpUd0FSHn_EJrp6S7_4/view?usp=sharing", "_blank");
    }

    onClickGetMQTTSCalibProdApp = (e) => {
        window.open("https://drive.google.com/file/d/1MO-Yp1F8NCL01kCBXaBMppT0CmVWoPGq/view?usp=sharing", "_blank");
    }

    render() {
        return (
            <div className="container">
                <div className="row align-items-center"  style={{height: "70vh"}}>
                    <div style={{marginBottom: "1rem", marginTop: "1rem", border:"1px solid black", paddingLeft: "0.3rem", paddingRight: "0.3rem", borderRadius:"0.5rem", paddingBottom: "0.4rem"}}>
                        <div style={{border:"1px solid var(--primaryColor)", marginTop: "1rem", color:"green", fontSize:"0.8rem", alignItems: "left", 
                            textAlign: "left", paddingLeft: "0.2rem", paddingRight: "0.2rem", backgroundColor:"rgb(168,204,168,0.3)", borderRadius:"0.5rem", marginBottom:"0.5rem"}}>
                            <div style = {{padding: "0.5rem"}}>
                                <b>Note:</b>
                                <ul style={{marginBottom: "0.2rem"}}>
                                    <li>
                                        There are sections for HTTPS Calibrartion App and MQTT Calibrartion App for Development and Production and 
                                        their respective current versions are mentioned.
                                    </li>
                                    <li>                                
                                        By clicking on the respective button you will be redirected to the Drive link from which you can download the current App Apk.
                                    </li>                                
                                    <li>
                                        Please uninstall the previous App before downloading the new app apk.
                                    </li>
                                </ul> 
                            </div>
                        </div>
                    </div>
                    <div className="container col-lg-8 col-lg-offset-3 centered col-md-6 col-md-offset-3 p-1">
                        <div className="modal-body box" style = {{padding:"1rem"}}>
                            <form>
                                <Nav tabs className="tabular">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab == 'HHTPSCalibApp' })}
                                            onClick={() => { this.toggle('HHTPSCalibApp'); }}>
                                            <b>HTTPS Calibration App</b>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: this.state.activeTab == 'MQTTCalibApp' })}
                                        onClick={() => { this.toggle('MQTTCalibApp'); }}>
                                            <b>MQTT Calibration App</b>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="HHTPSCalibApp" className="tabular2">
                                        <Row>
                                            <Col sm="12">
                                                <div className="form-group reg-form">
                                                    <div className="inputgroupCustom" style={{paddingTop:"1rem"}}>
                                                        <label className="reg-form-label">Calibrartion Dev App Current Version - {this.state.HTTPSDevCurrentVersion}</label>
                                                        <button type="button" className="btn-lg logbtn" 
                                                            style={{padding:"0.4rem", borderRadius:"5px", width:"100%", marginTop:"0.2rem", textTransform: "none",
                                                                backgroundColor :"var(--primaryColor)", borderColor: "var(--primaryColor)", color: "white", borderRadius: "0.5rem"
                                                            }} 
                                                            onClick={this.onClickGetHHTPSCalibDevApp}> 
                                                            HTTPS Calibraration App for Development
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group reg-form">
                                                    <div className="inputgroupCustom" style={{paddingTop:"1rem"}}>
                                                        <label className="reg-form-label">Calibrartion Prod App Current Version - {this.state.HTTPSProdCurrentVersion}</label>
                                                        <button type="button" className="btn-lg logbtn" 
                                                            style={{padding:"0.4rem", borderRadius:"5px", width:"100%", marginTop:"0.2rem", textTransform: "none",
                                                                backgroundColor :"var(--primaryColor)", borderColor: "var(--primaryColor)", color: "white", borderRadius: "0.5rem"
                                                            }} 
                                                            onClick={this.onClickGetHHTPSCalibProdApp}
                                                            > 
                                                            HTTPS Calibraration App for Production
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col> 
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="MQTTCalibApp" className="tabular2">
                                        <Row>
                                            <Col sm="12">
                                            <div className="form-group reg-form">
                                                    <div className="inputgroupCustom" style={{paddingTop:"1rem"}}>
                                                        <label className="reg-form-label">Calibrartion Dev App Current Version - {this.state.MQTTDevCurrentVersion}</label>
                                                        <button type="button" className="btn-lg logbtn" 
                                                            style={{padding:"0.4rem", borderRadius:"5px", width:"100%", marginTop:"0.2rem", textTransform: "none",
                                                                backgroundColor :"var(--primaryColor)", borderColor: "var(--primaryColor)", color: "white", borderRadius: "0.5rem"
                                                            }} 
                                                            onClick={this.onClickGetMQTTSCalibDevApp}
                                                            > 
                                                            MQTT Calibraration App for Development
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="form-group reg-form">
                                                    <div className="inputgroupCustom" style={{paddingTop:"1rem"}}>
                                                        <label className="reg-form-label">Calibrartion Prod App Current Version - {this.state.MQTTProdCurrentVersion}</label>
                                                        <button type="button" className="btn-lg logbtn" 
                                                            style={{padding:"0.4rem", borderRadius:"5px", width:"100%", marginTop:"0.2rem", textTransform: "none",
                                                                backgroundColor :"var(--primaryColor)", borderColor: "var(--primaryColor)", color: "white", borderRadius: "0.5rem"
                                                            }} 
                                                            onClick={this.onClickGetMQTTSCalibProdApp}
                                                            > 
                                                            MQTT Calibraration App for Production
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent> 
                                <div style={{textAlign: "center"}}>
                                    {this.state.errors.others.length != null && this.state.errors.others.length > 0 && <span style={{color: "red", fontSize: "0.8rem"}} className="inlineCSS">{this.state.errors.others}</span>}
                                </div>
                            </form>  
                        </div>                    
                    </div>
                </div>
            </div>  
        )
    }
}

export default VcCalibAppLinks;