import React, { useState, useContext, useEffect } from 'react';
import logoviliso from '../IMAGES/vilisoLogo.png';
import '../CSS/Logintest.css';
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_Email, IDS_LoginServerIssue, IDS_RegistNetworkError, IDS_LoginEmailError, IDS_FrgtWaitSendingResetDetails,
         IDS_ResetPassDetailSend, IDS_GoToLoginPg, IDS_FrgtPswdResetDetailsSentToEmail, IDS_FrgtRestPswdLinkSent, IDS_SUBMIT,
         IDS_FrgtNotValidUser, IDS_ForgotPassword} from '../../VcLanguage';
import {useNavigate} from 'react-router-dom';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

function VcForgotPassword (){

  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    redirect: false,
    errors: {
      email: '',
      others: ''
    },
    ResetPasswordEmailSendingMsg: ''        
  });

  const onResetPasswordEmailSubmit = (e) => {

    e.preventDefault();

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    // First get the full state
    let modifiedState = state;

    // Convert UserEmailID to lowercase and remove all whiteSpaces before sending to Server as it makes comparison easy and valid.
    let UserEmailID = modifiedState.email.trim();
    UserEmailID = UserEmailID.toLowerCase();
    
    const changePasswordData = {
        UserEmailID: UserEmailID,
    }

    axios.post( `${getAPIHostURL()}/wclient/sendResetPasswordEmailToUser`,  changePasswordData )
    .then(response => {
      if(response.data.code == 'SUCCESS') {
        modifiedState.ResetPasswordEmailSendingMsg = '';
        alert(t(IDS_FrgtRestPswdLinkSent));
        modifiedState.redirect = true;

        setState({...modifiedState});

      } else {
        modifiedState.ResetPasswordEmailSendingMsg = '';

         if (response.data.code == 'INVALID_EMAILID') {
          // Tell the user that his EmailID is invalid
          modifiedState.errors.others = t(IDS_FrgtNotValidUser);
        } else if (response.data.code == 'ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else if (response.data.code == 'SUPPORT_DETAILS_MISSING') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else if (response.data.code == 'SQL_ERROR') {
          // Tell the user that Server is experiencing errors
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        } else {
          // Tell the user that Server is experiencing errors
          console.log('Should not reach here');
          modifiedState.errors.others = t(IDS_LoginServerIssue);
        }
        setState({...modifiedState});
      }
    })
    .catch( error => {
      modifiedState.ResetPasswordEmailSendingMsg = '';

      console.log("Network error:");
      console.log(error);
      if (axios.isCancel(error)) {
        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
      } else {
        // Tell the user that there are network issues
        modifiedState.errors.others = t(IDS_RegistNetworkError);
        setState({...modifiedState});
      }
    }); 
  }

  const onSubButtonclick = (e) => {
    let modifiedState = state;
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    if(state.redirect == true) {
      navigate('/')

    } else {
      navigate('/forgotpassword')
    }

    modifiedState.errors.others = '';
    if(modifiedState.email != null && modifiedState.email.length > 0 && validEmailRegex.test(modifiedState.email)) {
      modifiedState.ResetPasswordEmailSendingMsg = t(IDS_FrgtWaitSendingResetDetails);
    } else {
      modifiedState.ResetPasswordEmailSendingMsg = "";  
    }
    setState({...modifiedState});
  }


  useEffect(() =>{
    let appRelevantDataContextValue = context;

    // To get Info from LS which have persisted even after logout 
    // (for eg. Language and Last Loggedin UserID).
    appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage(true);
  }, []);
    
  const handleChange = (event) => {
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;
    let modifiedState = state;
    modifiedState.errors.others = ""; 

    event.preventDefault();
    const { name, value } = event.target;

    let errors = modifiedState.errors;
  
    switch (name) {
      case 'email': 
        errors.email = 
            value.length > 0 ?
            (
                (validEmailRegex.test(value)) ? 
                '' :  
                "EmailID is not valid!"
            )
            : '';
        modifiedState.email = value;
        break;
      default:
        break;
    }

    setState({...modifiedState});
  }
	
    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    const {errors, ResetPasswordEmailSendingMsg} = state;

    const color = {
      color : "var(--errorColor)",
      fontSize:"13px"
    }

    return (
      <div className="container">
        <div className="row">
          <div className="container col-lg-4 col-lg-offset-4 centered col-md-6 col-md-offset-3">
            <div className="modal-body p-4 login" >
              <div className="form-group logform">					
                  <img src={logoviliso}/>
              </div>
              <div style={{color:"var(--secondaryColor)", fontSize:"1.3rem", fontWeight:"700"}}>{t(IDS_ForgotPassword)}</div>
              <form onSubmit={onResetPasswordEmailSubmit} >
                <div className="form-group logform">
                  <div className="inputgroupCustom">
                    <label className="log-form-label" style={{fontSize:"1rem"}}> {t(IDS_Email)}:</label>
                    <input type='email' name='email' value={state.email} className="rounded-pill form-control login-input" required="required" onChange={handleChange} disabled={state.redirect}/>
                                          {errors.email.length > 0 && 
                                          <span style={color} className='error'>{errors.email}</span>} 
                  </div>
                  <div style={{fontSize:"0.9rem"}}>{state.redirect == false ? t(IDS_FrgtPswdResetDetailsSentToEmail)
                  : t(IDS_ResetPassDetailSend)}</div>  
                </div>
                <div className="logform">
                  <button type="submit" onClick= {onSubButtonclick } className="loginMapBtn btn-lg rounded-pill btn-block btn" style={{marginTop: "1rem", width: "100%", backgroundColor:"#303597", color: "white", opacity: "0.9"}}>
                    {state.redirect == false ? t(IDS_SUBMIT) : t(IDS_GoToLoginPg)}</button>
                  <div className= "buttonErrorMessage">
                    {errors.others.length > 0 && 
                        <p style={color} className='error'>{errors.others}</p>}
                    {ResetPasswordEmailSendingMsg.length > 0 &&
                        <p style={{color:'green'}} className='error'>{ResetPasswordEmailSendingMsg}</p>}    
                  </div>
                </div>
              </form>
            </div>  
          </div>  
        </div>  
      </div>  
    );
}

export default VcForgotPassword;