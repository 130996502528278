import React, { Component } from 'react'
import { getAPIHostURL } from '../../ClientConfig';
import axios from 'axios';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_AUSrvrIssueReqParamsNotSent, IDS_RegistNetworkError} from '../../VcLanguage';

export class VcDefinePcbTypeAndVersion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {
                others: ""
            },
            loggedInUserID: "",
            PcbNameAndVersionArr: [],
            PcbNameArr: [],
            // PcbVersionArr: [],
            selectedPcbName: "",
            selectedVersion: "",
            newPcbDescription: "",
        }
    }

    componentDidMount () {
        let appRelevantDataContextValue = this.context;

        // To get Info from LS which have persisted even after logout 
        // (for eg. Language and Last Loggedin UserID).
        appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage(true);

        // To select PCB name and  PCB Version.
        this.DefineNewPcb();
    }

    DefineNewPcb = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        let modifiedState = this.state; 
        
        // To avoid appending of array values.
        modifiedState.PcbNameAndVersionArr = [];
        modifiedState.PcbNameArr = [];
        // modifiedState.PcbVersionArr = [];

        // To get PCB name and PCB Version from database.
        axios.post(`${getAPIHostURL()}/wclient/defineNewPcb`)
        .then(response => {
            if(response.data.code == "SUCCESS") {
                // IF response gets inadequate data.
                if(response.data.retrievedPcbInfo == null || response.data.retrievedPcbInfo.length <= 0){
                    modifiedState.errors.others = "PCB Information is not present on Server.";
                } else {
                    modifiedState.PcbNameAndVersionArr = response.data.retrievedPcbInfo;
                    // For showing only one value in dropdown if value gets repeat.
                    modifiedState.PcbNameArr = [...new Set(modifiedState.PcbNameAndVersionArr.map(singleHWandFWVersion => singleHWandFWVersion.PcbName))];
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }  
        });
    }

    // For selecting values in dropdown of SELECT PCB Version as per selection of dropdown of SELECT PCB name.
    onChangePcbNameSelection = (e) => {

        let modifiedState = this.state;
        modifiedState.selectedPcbName = e.target.value;

        // modifiedState.PcbVersionArr = [];

        modifiedState.errors.others = "";

        // for(let i=0; i< modifiedState.PcbNameAndVersionArr.length; i++) {
        //     if(modifiedState.PcbNameAndVersionArr[i]["PcbName"] == modifiedState.selectedPcbName) {
        //         // Assigning values to Second dropdown based on selection of First dropdown.
        //         modifiedState.PcbVersionArr.push(modifiedState.PcbNameAndVersionArr[i]["PcbVersion"]);
        //     }
        // }

        this.setState(modifiedState);
    }

    // For selecting PCB Version or Defining New PCB Version.
    onChangePcbVersion = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedVersion = e.target.value;
        this.setState(modifiedState);
    }

    // For entering detailed decription for newly added version of PCB.
    onEnteredNewVersionDescription = (e) => {
        let modifiedState = this.state;

        modifiedState.newPcbDescription = e.target.value;
        this.setState(modifiedState);  
    }


    // For saving all information to database.
    onSubmit = (e) => {
        e.preventDefault();

        let modifiedState = this.state;

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        modifiedState.loggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;


        if( modifiedState.selectedPcbName == null || modifiedState.selectedPcbName.length <= 0 ||
            modifiedState.selectedVersion == null || modifiedState.selectedVersion.length <= 0
        ) {
            modifiedState.errors.others = "Please select PCB Name or PCB Version.";
            this.setState(modifiedState);
            return; // Dont process any further
        }


        let JsonBody = {
            PCBName: modifiedState.selectedPcbName,
            PCBVersion: modifiedState.selectedVersion,
            PCBDescription: modifiedState.newPcbDescription,
            loggedInUserID: modifiedState.loggedInUserID 
        }
               
        // For saving/defining new PCB's Information.
        axios.post(`${getAPIHostURL()}/wclient/savePCBDetails`, JsonBody)
        .then(response => {
            if(response.data.code == "SUCCESS") {

                alert(`Successfully Saved New PCB Information.`);

                // Clear all fields in the form when user successfully defines new PCB or PCB Version.
                modifiedState.errors.others = "";
                modifiedState.selectedPcbName = "";
                modifiedState.selectedVersion = "";
                modifiedState.newPcbDescription = "";
                modifiedState.loggedInUserID = '';

            } else {
                if(response.data.code == 'SQL_ERROR' || response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";

                } else if(response.data.code == 'REQ_PARAMS_MISSING'){
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent)
                    
                } else if(response.data.code == 'ER_DUP_ENTRY'){
                    modifiedState.errors.others = 'PCB Information Already Exists.'
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);

        })
        .catch(error => {
            console.log(error);
            modifiedState.errors.others = t(IDS_RegistNetworkError);
            this.setState(modifiedState);  
        });
    }

    render() {

        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-6 col-lg-offset-3
                                            col-md-8 col-md-offset-2">
                        <div className="modal-body p-4 box">
                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1.2rem"}}>Define New PCB Name or Version</div> 
                            <form onSubmit={this.onSubmit}>
                                <div style={{marginBottom: "0.8rem"}}>
                                    <label className="reg-form-label" style={{width:"100%"}}>Select PCB Name or Define New PCB Name:</label>
                                    <div>
                                        <input type="list" list="data" className="input-form" required="required" value={this.state.selectedPcbName} onChange={this.onChangePcbNameSelection} />
                                        <datalist id="data">
                                        {this.state.PcbNameArr.map((singlePcbName, index) =>
                                            <option key={index} value={singlePcbName.displayValue}>{singlePcbName}</option>
                                        )}
                                        </datalist>
                                    </div>
                                </div>

                                <div>
                                    <label className="reg-form-label" style={{width:"100%"}}>Select PCB Version or Define New PCB Version:</label>
                                    <div>
                                        <input type="list" list="data1" className="input-form" required="required" value={this.state.selectedVersion} onChange={this.onChangePcbVersion} />
                                        {/* <datalist id="data1">
                                        {this.state.PcbVersionArr.map((singlePcbVersion, index) =>
                                            <option key={index} value={singlePcbVersion.displayValue}>{singlePcbVersion}</option>
                                        )}
                                        </datalist> */}
                                    </div>
                                </div>


                                <div className="inputgroupCustom" style={{paddingTop:"0.8rem", paddingBottom:"0.8rem"}}>
                                    <label className="reg-form-label">Enter Description for New PCB:</label>
                                    <textarea type='text' className="input-form" style={{minHeight:"5rem", overflowY:"auto"}} required="required" value={this.state.newPcbDescription} onChange={this.onEnteredNewVersionDescription}/>  
                                </div>


                                <div className="form-group reg-form" style={{marginTop:"1.5rem"}}>
                                    <button type="submit" className="btn-lg reg-btn">SAVE</button>
                                    <div className = "buttonErrorMessage">
                                        {this.state.errors.others.length > 0 && 
                                            <p style={{color: 'var(--errorColor)', fontSize: '0.8rem'}} className='error'>{this.state.errors.others}</p>}
                                    </div> 
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
VcDefinePcbTypeAndVersion.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcDefinePcbTypeAndVersion
