import React, { Component } from 'react';
import "../CSS/AddUserForm.css"
import '../CSS/VcAddRemoveUserToTheNode.css'
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_Close, IDS_AUServerError, IDS_RegistNetworkError, IDS_AUSrvrIssueReqParamsNotSent } from '../../VcLanguage';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { convertUTCDateToStrYYYYMMDDHH24MMSS, trimStringAndRemoveTrailingComma } from '../../vtUtil';
import DateTimePicker from 'react-datetime-picker';
import { IDS_AUCurentDateTime, IDS_AUAdjustCurentDateTime, IDS_IDS_AUViewDateTime} from '../../VcLanguage';

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 
const RemoveUserFormCheckbox = props => {
  
    return (
      <li key={props.id} data-name={props.name} className="AllDevice"> 
        <input key={props.id} onClick={props.handleCheckedIndividualDeviceRmvUser} className="checkbox-distance" 
            type="checkbox" defaultChecked={props.isChecked} value={props.value} name={props.id}
            /> 
        {props.value}
      </li>
    )
}

const AddUserFormCheckbox = props => {
  
    return (
        <li key={props.id} data-name={props.name} className="DeviceName"> 
            <input key={props.id} onClick={props.handleCheckedIndividualDeviceForSendingNotiToAdduser} className="checkbox-space" 
                type="checkbox" defaultChecked={props.isChecked} value={props.name} name={props.id}
                /> 
            {props.name}
        </li>
    )
}
class VcAddRemoveUserToTheNode extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            notifyOnCloseForm: this.props.OnCloseAddRemoveUserPopup,
            SelectedTreeNodeTitle: this.props.TreeNodeTitle,
            SelectedNodeID: this.props.TreeNodeID,
            EnteredUserEmailID: '',
            isProceedBtnClicked: false,
            receivedUsrEmailIdHavingAssignedOrgs: [],
            receivedOrganizationInfo: [],
            associatedTreeNodeIds: [],

            activeTab: 'selectByAddUser',
            // arrOfUserEmailIdsAssignedToTreeNode: [],
            selectedUserIdToRemove: "",
            arrUserIdToBeRemoved: [],
            displayBy: "addUserEmailId",
            enteredKeyToSearchOrg: "",
            arrOfNamesOfSearchedOrgs: [],
            arrOrgsToBeAdded: [],
            selectedOrg: "",
            isOrganisationToSearchAndSelect: false,
            retreivedSearchResultOfOrgs: [],
            arrOfOrgsToBeSaved: [],
            bEnteredUserAlreadyAdded: false,
            bInsistUserToSearch: false,

            bExistingUserSearched: false,
            selectedExistingUser: "",
            receivedResultOfSerchedExistingUser: [],
            arrOfEmailIdsOfSearchedExistingUsers: [],
            selectedOrgToRemove: "",
            arrOrgToBeRemoved: [],
            bIsOrgsToBeRemoved: false,
            actionOnOrg: "AddOrg",
            bTakeActionOnOrg: false,
            searchUserEmailID: "",
            searchFirstName: "",
            searchLastName: "",
            isAllowUserToAccessDevicesOfAllOrgs: false,
            isRemoveLastOrgFromUser: false,
            isNewUserAddedSuccessfully: false,
            isRemoveUserDueToRemovalOfLastOrg: false,
            bDontRemoveLastOrgFromUser: false,
            selectedExistingUserToProceed: "",
            bIsExistingUserFound: false,
            errors:{
                email: '',
                others: '',
                errUserToRemove: "",
                errOrgToRemove: "",
                requiredField: "",
                errOrgsToAdd: "",
                errSelectOrgToAdd: "",
                errSelectExistingUserToAdd: "",
                errNoOrgAddedToThisUser: "",
                errEnterOrgNameToSearch: "",
                noUserAdded: "",
                errSelectOrg: "",
                errNoDevcFound: "",
                errRmvUsr: "",
            },          
            arrOfOrganizationNames: [],
            SelectedNodeHasDevices: this.props.NodeHasDevices,

            // SendNotificationToUser
            isAllowUserToSendDevicesNotifications: false,
            ArrAssignedDevicesToUser: [],
            ArrSelectedAllOrganisation: [],
            isCheckedAllowViewAll: false,
            isCheckedForever: true,
            StartViewTime: new Date(),
            EndViewTime: null, // new Date(),
            
            // StopNotificationForUser
            RemvUsrEndViewTime: new Date(),
            isCheckedAllowViewAllRemvUsr: false,
            deviceDataForRemvUser: [],
        }
    }

    toggle(tab) {
        let modifiedState = this.state;

        modifiedState.bExistingUserSearched = false;
        modifiedState.bTakeActionOnOrg = false;

        modifiedState.searchUserEmailID = "";
        modifiedState.searchFirstName = "";
        modifiedState.searchLastName = "";
        modifiedState.errors.errNoOrgAddedToThisUser = "";
        modifiedState.errors.errEnterOrgNameToSearch = "";
        modifiedState.errors.others = "";
        modifiedState.errors.requiredField = "";

        modifiedState.arrOfOrganizationNames = [];
        modifiedState.arrOrgToBeRemoved = [];

        modifiedState.arrUserIdToBeRemoved = [];
        modifiedState.selectedExistingUserToProceed = "";

        modifiedState.bIsOrgsToBeRemoved = false;

        if(modifiedState.bInsistUserToSearch == true){
            modifiedState.arrOfEmailIdsOfSearchedExistingUsers = [];
            // modifiedState.arrOfUserEmailIdsAssignedToTreeNode = [];
            modifiedState.arrUserIdToBeRemoved = [];
        }

        if(modifiedState.receivedUsrEmailIdHavingAssignedOrgs.length == 0){
            modifiedState.errors.noUserAdded = "No User(s) Added on this Node.";
        }

        if (modifiedState.activeTab !== tab) {
            modifiedState.activeTab = tab;
        } else {
            modifiedState.activeTab = modifiedState.activeTab;
        }
        
        this.setState(modifiedState);
    }

    // When new user has to be added then convert User Email ID to lower case and check its Email Validation.
    handleUserEmailChange = (e) => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;
        let emailValue = e.target.value.trim(); // Get the modified value from the target
        // Convert UserID to lowercase and remove all whiteSpaces before SetState. it makes comparison easy and valid.
        emailValue = emailValue.toLowerCase();
        this.setState( 
            prevState => {
                let modifiedState = prevState;

                modifiedState.isProceedBtnClicked = false;
                modifiedState.bEnteredUserAlreadyAdded = false;
                modifiedState.errors.others = "";
                modifiedState.arrOfOrganizationNames = [];

                let errors = modifiedState.errors;
                errors.email = validEmailRegex.test(emailValue) ? '' : 'EmailID is not valid.';

                modifiedState.errors = errors;
                modifiedState.EnteredUserEmailID = emailValue;

                return modifiedState;
            }
        );
    }

    componentDidMount () {
        // To Get Organisation Information.
        this.getAllOrgInfo();

        // To Get UserEmailIDs which are having assigned Organisations against them.
        this.getUserEmailIdsHavingAssignedOrgs();
    }

    // To Get UserEmailIDs which are having assigned Organisations against them.
    getUserEmailIdsHavingAssignedOrgs = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null){
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 

        modifiedState.receivedUsrEmailIdHavingAssignedOrgs = [];
        modifiedState.arrOfOrgsToBeSaved = [];

        const jsonBody = {
            selectedTreeNodeID: modifiedState.SelectedNodeID
        };

        // To get information related to UserEmailIds having Organisations assigned to them from Database.
        axios.post(`${getAPIHostURL()}/wclient/getUserEmailIdsHavingAssignedOrgs`, jsonBody)
        .then(response => {
            if(response.data.code == "SUCCESS") { 

                modifiedState.isProceedBtnClicked = (modifiedState.EnteredUserEmailID == null || 
                                                     modifiedState.EnteredUserEmailID.length <= 0) ? false : true;

                modifiedState.receivedUsrEmailIdHavingAssignedOrgs = response.data.receivedUsrEmailIdHavingAssignedOrgs;

                // If number of users added to the node are upto 100 then show such users in dropdown or provide a search feature.
                if(modifiedState.receivedUsrEmailIdHavingAssignedOrgs.length == 0){
                    modifiedState.errors.noUserAdded = "No User(s) Added on this Node.";
                } else if(modifiedState.receivedUsrEmailIdHavingAssignedOrgs.length <= 100){
                    modifiedState.bInsistUserToSearch = false;
                } else {
                    modifiedState.bInsistUserToSearch = true;
                }

                for(let i = 0; i<modifiedState.receivedUsrEmailIdHavingAssignedOrgs.length; i++){

                    let associatedTreeNodeIdsToCheck = JSON.parse(modifiedState.receivedUsrEmailIdHavingAssignedOrgs[i]["AdditionalInfo"]);
                    let isAssociatedTreeNodeIdPresent = associatedTreeNodeIdsToCheck["AssociatedTreeNodeIds"];
                    let isAssociatedTreeNodeAddUsrIdPresent = associatedTreeNodeIdsToCheck["AssocTreeNodeIdsAsDevcAddnlUser"];

                    if(modifiedState.bInsistUserToSearch == false){
                        if(((isAssociatedTreeNodeIdPresent != null && isAssociatedTreeNodeIdPresent.includes(modifiedState.SelectedNodeID) == true) )
                            // || (isAssociatedTreeNodeAddUsrIdPresent != null && isAssociatedTreeNodeAddUsrIdPresent.includes(modifiedState.SelectedNodeID) == true))
                            && modifiedState.arrOfEmailIdsOfSearchedExistingUsers.indexOf(modifiedState.receivedUsrEmailIdHavingAssignedOrgs[i]['EmailID']) === -1){
                            
                                modifiedState.arrOfEmailIdsOfSearchedExistingUsers.push(modifiedState.receivedUsrEmailIdHavingAssignedOrgs[i]['EmailID']);
                        }
                        // if(isAssociatedTreeNodeAddUsrIdPresent != null && isAssociatedTreeNodeAddUsrIdPresent.includes(modifiedState.SelectedNodeID) == true &&
                        //     modifiedState.arrOfUserEmailIdsAssignedToTreeNode.indexOf(modifiedState.receivedUsrEmailIdHavingAssignedOrgs[i]['EmailID']) === -1){

                        //     modifiedState.arrOfUserEmailIdsAssignedToTreeNode.push(modifiedState.receivedUsrEmailIdHavingAssignedOrgs[i]['EmailID']);
                        // }
                    }

                    modifiedState.arrOfEmailIdsOfSearchedExistingUsers = [...new Set(modifiedState.arrOfEmailIdsOfSearchedExistingUsers)];
                    // modifiedState.arrOfUserEmailIdsAssignedToTreeNode = [...new Set(modifiedState.arrOfUserEmailIdsAssignedToTreeNode)];

                    if(modifiedState.receivedUsrEmailIdHavingAssignedOrgs[i]['EmailID'] == modifiedState.EnteredUserEmailID){

                        let additionalInfo = JSON.parse(modifiedState.receivedUsrEmailIdHavingAssignedOrgs[i]['AdditionalInfo']);
                        let associatedTreeNodeIds = [];

                        if(additionalInfo != null){
                            associatedTreeNodeIds = additionalInfo["AssociatedTreeNodeIds"];
                            modifiedState.associatedTreeNodeIds = (additionalInfo["AssociatedTreeNodeIds"] != null && 
                                                                   additionalInfo["AssociatedTreeNodeIds"].length > 0) ?
                                                                   additionalInfo["AssociatedTreeNodeIds"] : [];

                            if(associatedTreeNodeIds != null && associatedTreeNodeIds.length > 0){
                                for(let j=0; j<associatedTreeNodeIds.length; j++){
                                    if(associatedTreeNodeIds[j] == modifiedState.SelectedNodeID){
                                        alert("Entered user is already added to this Tree Node. Please enter new Email Id.");
                                        modifiedState.isProceedBtnClicked = false;
                                        modifiedState.EnteredUserEmailID = "";
                                        modifiedState.arrOfOrganizationNames = [];
                                    }
                                }
                            }
                        }
                    }
                }

            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that server is experiencing issues (required params are not found).
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent); // TODO: Define generic language error for this
                }
                else if(response.data.code == "SQL_ERROR") {
                    modifiedState.errors.others = t(IDS_AUServerError); 

                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = t(IDS_AUServerError); // TODO: Define generic language error for this
                }
            }
            this.setState(modifiedState);
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);  
            }
        });
    }

    getAllOrgInfo = () => {

        let modifiedState = this.state

        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t; 

        // To get information related to Organisations from Database.
        axios.post(`${getAPIHostURL()}/wclient/getAllOrgInfo`)
        .then(response => {
            if(response.data.code == "SUCCESS") { 

                modifiedState.receivedOrganizationInfo = response.data.receivedOrgInfo;

            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Tell the user that server is experiencing issues (required params are not found).
                    modifiedState.errors.others = t(IDS_AUSrvrIssueReqParamsNotSent); // TODO: Define generic language error for this
                }
                else if(response.data.code == "SQL_ERROR") {
                    modifiedState.errors.others = t(IDS_AUServerError); 

                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = t(IDS_AUServerError); // TODO: Define generic language error for this
                }
            }
            this.setState(modifiedState);
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);  
            }
        });
    }
    
    // For saving User(Add/Remove) as well as Organisation(Add/Remove) data to the database.
    onSubmitUserAndOrgInfo = (e) => {
        e.preventDefault();
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;   

        let modifiedState = this.state;

        let OrganisationIds = [];
        let orgIdsOfSearchedExistingUser = [];

        // If user to be added is new for the current node, but the user is present in the database then bring its previous 
        // Additional Info, so that we can add newly assigned Organisation Ids to the Additional Info of such user.
        if(modifiedState.displayBy == "addUserEmailId"){
            for(let i=0; i<modifiedState.receivedResultOfSerchedExistingUser.length; i++){
                if(modifiedState.receivedResultOfSerchedExistingUser[i]["EmailID"] == modifiedState.EnteredUserEmailID){
                    let additionalInfoOfSearchedExistingUser = JSON.parse(modifiedState.receivedResultOfSerchedExistingUser[i]["AdditionalInfo"]);
                    orgIdsOfSearchedExistingUser = (additionalInfoOfSearchedExistingUser != null && 
                                                    additionalInfoOfSearchedExistingUser.length > 0) ? 
                                                    additionalInfoOfSearchedExistingUser["OrganisationIds"] : [];
                }
            }
        }

        // If user to be added is new for the current node, but the user is present in the database then bring its previous 
        // Additional Info, get Organisation Names of already added Organisations for this new user to the node.
        if(modifiedState.displayBy == "addUserEmailId"){
            if(modifiedState.receivedOrganizationInfo != null && modifiedState.receivedOrganizationInfo.length > 0){
                for(let j = 0; j<modifiedState.receivedOrganizationInfo.length; j++){
                    if(orgIdsOfSearchedExistingUser != null && orgIdsOfSearchedExistingUser.length > 0){
                        for(let k = 0; k<orgIdsOfSearchedExistingUser.length; k++){
                            if(modifiedState.receivedOrganizationInfo[j]["OrgID"] == orgIdsOfSearchedExistingUser[k]){
                                modifiedState.arrOfOrganizationNames.push(modifiedState.receivedOrganizationInfo[j]["OrgName"]);
                            }
                        }
                    } 
                }
            }
        }

        // Store already added Organisation names to the finalised Array.
        if(modifiedState.arrOfOrganizationNames != null && modifiedState.arrOfOrganizationNames.length > 0){
            for(let p=0; p<modifiedState.arrOfOrganizationNames.length; p++){
                OrganisationIds.push(modifiedState.arrOfOrganizationNames[p]);
            }
        }
    
        // Store newly added Organisation names to the finalised Array.
        if(modifiedState.arrOrgsToBeAdded != null && modifiedState.arrOrgsToBeAdded.length > 0){
            for(let q=0; q<modifiedState.arrOrgsToBeAdded.length; q++){
                OrganisationIds.push(modifiedState.arrOrgsToBeAdded[q]);
            }
        }

        // If we want to "Remove Only Specifically Viewable Organisations" from the user then remove such Organisations from the finalised array.
        if(modifiedState.actionOnOrg == "RemoveOrg"){
            if(OrganisationIds != null && OrganisationIds.length > 0){
                for(let x=0; x<OrganisationIds.length; x++){
                    if(this.state.arrOrgToBeRemoved != null && this.state.arrOrgToBeRemoved.length > 0){
                        for(let y=0; y<this.state.arrOrgToBeRemoved.length; y++){                    
                            if ( OrganisationIds[x] === this.state.arrOrgToBeRemoved[y]){ 
                                OrganisationIds.splice(x, 1); 
                                x--; 
                            }
                        }
                    }
                }
            }
        }

        if((modifiedState.receivedOrganizationInfo != null && modifiedState.receivedOrganizationInfo.length > 0) &&
            (OrganisationIds != null && OrganisationIds.length > 0))
        {
            for(let i=0; i<modifiedState.receivedOrganizationInfo.length; i++){
                for(let j=0; j<OrganisationIds.length; j++){
                    if(modifiedState.receivedOrganizationInfo[i]['OrgName'] == OrganisationIds[j]){
                        modifiedState.arrOfOrgsToBeSaved.push(modifiedState.receivedOrganizationInfo[i]['OrgID']);
                    }
                }
            }
        }

        // Getting Organisation Ids from the Organisation Names from the Finalised Array in case of "Remove Only Specifically Viewable Organisations".
        if(modifiedState.actionOnOrg == "RemoveOrg"){
            if(modifiedState.receivedOrganizationInfo != null && 
               modifiedState.receivedOrganizationInfo.length > 0 &&
               OrganisationIds != null && OrganisationIds.length > 0)
            {
                for(let i=0; i<modifiedState.receivedOrganizationInfo.length; i++){
                    for(let j=0; j<OrganisationIds.length; j++){
                        if(modifiedState.receivedOrganizationInfo[i]['OrgName'] == OrganisationIds[j]){
                            modifiedState.arrOfOrgsToBeSaved.push(modifiedState.receivedOrganizationInfo[i]['OrgID']);
                        }
                    }
                }
            }

        }

        // Storing Selected Tree Node ID to the array of Associated Tree Node IDs array.
        modifiedState.associatedTreeNodeIds.push(modifiedState.SelectedNodeID);

        // Before sending to the API, removing duplicate entries from the Finalised Array of Organisation IDs.
        let finalArrOfOrgsToSave = [...new Set(modifiedState.arrOfOrgsToBeSaved)];

        // Before sending to the API, removing duplicate entries from the Finalised Array of Associated Tree Node IDs.
        let finalArrOfTreeNodeIdsToSave = [...new Set(modifiedState.associatedTreeNodeIds)];

        // Convert the input dates to UTC before sending to the Server
        let strStartViewTime = convertUTCDateToStrYYYYMMDDHH24MMSS(modifiedState.StartViewTime);
        // If the 'Forever' checkbox is 'True', then send 'null' as EndViewTime
        let strEndViewTime = this.state.isCheckedForever ? null : convertUTCDateToStrYYYYMMDDHH24MMSS(modifiedState.EndViewTime);

        let retDeviceDataForRemvUser = [];

        modifiedState.deviceDataForRemvUser.forEach( (singledevice) => { 
            // Remove only the selected devices into the DeviceData array
            if(singledevice.isChecked === true) {
                let singleDeviceData = { 
                    DeviceID : singledevice.isChecked ? singledevice.id : '',
                };
                // Push singleDeviceData into deviceData array present in jsonUserAndDeviceInfo
                retDeviceDataForRemvUser.push(singleDeviceData);
            }          
        });

        let RemvUsrEndViewTimeFromState = modifiedState.RemvUsrEndViewTime;

        //============================================================================================
        // * If end date time is less than current date time, it will be adjusted to be at least equal to the current date time.

        // Give a tolerance of -5 minutes to the current date time to prevent unnecessary alert message
        let currentDateTime = new Date();
        currentDateTime.setMinutes(currentDateTime.getMinutes() - 5);

        if(RemvUsrEndViewTimeFromState < currentDateTime) {
            let strMsg = 'End View Date Time cannot be less than the current date time.' + 
            '\nIt will be set to current date time.';
            alert(strMsg);

            // Calculate new minimum required End View Time based on the currentDateTime
            RemvUsrEndViewTimeFromState = new Date(); // Eliminate the 5 minute tolerance added earlier for more accurate End time
            modifiedState.RemvUsrEndViewTime = RemvUsrEndViewTimeFromState; // Also put this in modified state so that it can be reflected on the screen
        }

        //============================================================================================

        // Convert the input dates to UTC before sending to the Server
        let strRemvUsrEndViewTime = convertUTCDateToStrYYYYMMDDHH24MMSS(RemvUsrEndViewTimeFromState);
        let loggedInUserId = appRelevantDataContextValue.loggedInUserInfo.userID;

        const jsonUserAndOrgInfo = {
            EnteredUserEmailID: modifiedState.EnteredUserEmailID,
            OrganisationIds: this.state.isAllowUserToAccessDevicesOfAllOrgs == false ? finalArrOfOrgsToSave : [],
            AssociatedTreeNodeIds: finalArrOfTreeNodeIdsToSave,
            isAddNewUser: modifiedState.displayBy == "addUserEmailId" ? true : false,
            deviceDataForRemvUser: retDeviceDataForRemvUser,
            SelectedNodeID: modifiedState.SelectedNodeID,
            RemvUsrEndViewTime: strRemvUsrEndViewTime,
            StartViewTime: strStartViewTime,
            EndViewTime: strEndViewTime, 
            ArrAssignedDevicesToUser: modifiedState.ArrAssignedDevicesToUser,
            invokedFrom: modifiedState.actionOnOrg,
            LoggedInUserEmailID: loggedInUserId
        };

        axios.post(`${getAPIHostURL()}/wclient/submitUserIdWithNodeAndOrgInfo`, jsonUserAndOrgInfo)    
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                alert("User's data has been saved successfully.");
                modifiedState.isProceedBtnClicked = false;
                modifiedState.arrOrgsToBeAdded = [];
                modifiedState.isAllowUserToAccessDevicesOfAllOrgs = false;
                modifiedState.isNewUserAddedSuccessfully = true;
                this.setState(modifiedState);

                // If the user is going to remove last organisation of the selected user then the user shall be removed from the selected node.
                if(modifiedState.isRemoveUserDueToRemovalOfLastOrg == true){
                    this.onClickRemoveSelectedUsers();
                }

                this.onCloseAddUserPopup();
                return; // No further processing required
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = t(IDS_AUServerError);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = t(IDS_AUServerError);
                }
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }  
        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }
        }); 
    
    }

    // For Closing the main Popup Window.
    onCloseAddUserPopup = (e) => {
        if(this.state.displayBy == "addUserEmailId" && this.state.activeTab != 'selectByRemoveUser' && this.state.isAllowUserToAccessDevicesOfAllOrgs == false &&
           (this.state.arrOrgsToBeAdded == null || this.state.arrOrgsToBeAdded.length <= 0))
        {
            if(this.state.isNewUserAddedSuccessfully == false){
                let strMsg = `User not added.\nDo you want to close without adding ?`;

                let retVal = window.confirm(strMsg);

                if(retVal == true){
                    this.state.notifyOnCloseForm();
                } 
                else {
                    return;
                }
            } 
            else {
                this.state.notifyOnCloseForm();
            }
            
        } else {
            this.state.notifyOnCloseForm();
        }
    }

    // When the new user has to be added to the node then check if the user is already present to the current node.
    onClickProceed = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;   

        let modifiedState = this.state;

        modifiedState.enteredKeyToSearchOrg = "";
        modifiedState.selectedOrg = "";
        modifiedState.arrOrgsToBeAdded = [];
        modifiedState.arrOfNamesOfSearchedOrgs = [];
        modifiedState.errors.errOrgsToAdd = "";
        modifiedState.arrOfEmailIdsOfSearchedExistingUsers = [];

        if(modifiedState.EnteredUserEmailID == null || modifiedState.EnteredUserEmailID.length <= 0){
            modifiedState.errors.others = "Please enter User Email Id.";
            this.setState(modifiedState);
            return;
        }

        if(modifiedState.errors.email == null || modifiedState.errors.email.length <= 0){
            // To check if the entered Email ID is already assigned to the selected node.
            this.onClickSearchExistingUser(null, "invokedFromProceed", modifiedState);
        }
    }

    // When we select users for removal, store their UserEmailIDs to the array for removal.
    onClickRemoveUser = () => {
        let modifiedState =  this.state;

        let SelectedUserEmailId = modifiedState.selectedUserIdToRemove;

        modifiedState.errors.others = "";
        modifiedState.errors.errUserToRemove = "";

        if(SelectedUserEmailId == null || SelectedUserEmailId.length <= 0 || 
            !modifiedState.arrOfEmailIdsOfSearchedExistingUsers.includes(SelectedUserEmailId)) {
        //    !modifiedState.arrOfUserEmailIdsAssignedToTreeNode.includes(SelectedUserEmailId)){
            modifiedState.errors.errUserToRemove = 'Please Enter Valid User Email Id.';
            this.setState(modifiedState);
            return;
        } 

        // If the user selected from the dropdown has already been selected for removal then it will show error message.
        if(modifiedState.arrUserIdToBeRemoved.findIndex(singleUserEmailIdToBeRemoved => SelectedUserEmailId.toLowerCase() == singleUserEmailIdToBeRemoved.toLowerCase()) >= 0) {
            modifiedState.errors.errUserToRemove = 'Entered User Email Id already exist.';
            this.setState(modifiedState);

        } else {

            modifiedState.errors.others = "";
            modifiedState.errors.errUserToRemove = "";
            modifiedState.selectedUserIdToRemove = "";
            // Just wanted to show last inserted UserEmailId at the top of DisplayBox.
            modifiedState.arrUserIdToBeRemoved.unshift(SelectedUserEmailId);
        }
        this.setState(modifiedState);
    }

    // When we select Organisation for removal, store such OrganisationIds to the array for removal.
    onClickRemoveOrg = () => {
        let modifiedState =  this.state;

        let SelectedOrgToRemove = modifiedState.selectedOrgToRemove;

        modifiedState.errors.others = "";
        modifiedState.errors.errUserToRemove = "";

        let differenceBetnArrOfOrgsAndOrgsToBeRemoved = 0;

        if(modifiedState.arrOfOrganizationNames != null && modifiedState.arrOrgToBeRemoved != null){
            differenceBetnArrOfOrgsAndOrgsToBeRemoved = modifiedState.arrOfOrganizationNames.length - modifiedState.arrOrgToBeRemoved.length;
        }
        
        // If the Organisation selected from the dropdown has already been selected for removal then it will show error message.
        if(modifiedState.arrOrgToBeRemoved.findIndex
            (
                singleUserEmailIdToBeRemoved => SelectedOrgToRemove.toLowerCase() == singleUserEmailIdToBeRemoved.toLowerCase()
            ) >= 0  
        ) {
            modifiedState.errors.errOrgToRemove = 'Organisation already selected.';
            this.setState(modifiedState);

        } else {

            // If user is going to remove last organisation of the selected user then the user will also be removed from the selected node.
            if(this.state.actionOnOrg == "RemoveOrg" && differenceBetnArrOfOrgsAndOrgsToBeRemoved == 1){
            
                let strMsg = `Removing this last organisation will remove this user from this node.\nIf you are going to remove organisation of the User then you will stop receiving notification for those devices which are under the Organisation which you are going to remove.\nIf required, you can add this user to this node again later.\nClick "OK" if you want to remove this last organisation from this user (as well as this user from the node).\nClick "Cancel" if you don't want to remove this organisation from the user. `;
    
                let retVal = window.confirm(strMsg);
    
                if(retVal == true){
                    modifiedState.isRemoveUserDueToRemovalOfLastOrg = true;
                    modifiedState.bDontRemoveLastOrgFromUser = false;
                    modifiedState.arrUserIdToBeRemoved.push(modifiedState.selectedExistingUserToProceed);

                    modifiedState.errors.others = "";
                    modifiedState.errors.errOrgToRemove = "";
                    modifiedState.selectedOrgToRemove = "";
                    modifiedState.arrOrgToBeRemoved.unshift(SelectedOrgToRemove);
                } else {
                    modifiedState.bDontRemoveLastOrgFromUser = true;
                }
            } else {
                modifiedState.errors.others = "";
                modifiedState.errors.errOrgToRemove = "";
                modifiedState.selectedOrgToRemove = "";
                modifiedState.arrOrgToBeRemoved.unshift(SelectedOrgToRemove);
            }
        }  
        this.setState(modifiedState);
    }

    // On change hadler of Email ID while removing user.
    onChangeRemoveUserEmailID = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedUserIdToRemove = e.target.value;

        modifiedState.errors.others = "";
        modifiedState.errors.errUserToRemove = "";
        
        this.setState(modifiedState);
    }

    // On change hadler of Email ID while removing organisation.
    onChangeRemoveAddedOrg = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedOrgToRemove = e.target.value;

        modifiedState.errors.others = "";
        modifiedState.errors.errOrgToRemove = "";
        
        this.setState(modifiedState);
    }

    // For removal of Users from the selected node.
    onClickRemoveSelectedUsers = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;   

        let modifiedState = this.state;

        const jsonBody = {
            UserIdsToBeRemoved: modifiedState.arrUserIdToBeRemoved,
            SelectedTreeNodeID: modifiedState.SelectedNodeID,
            NodeHasDevices: modifiedState.SelectedNodeHasDevices
        };

        axios.post(`${getAPIHostURL()}/wclient/removeSelectedUserEmailIds`, jsonBody)    
        .then(response => {
            if(response.data.code == 'SUCCESS') {


                if(response.data.arrUnremovedUsrBcozOfActiveDevc != null && response.data.arrUnremovedUsrBcozOfActiveDevc.length > 0) {
                    let arrRcvdUnremovedUsrBcozOfActiveDevc = [];
                    try {
                        arrRcvdUnremovedUsrBcozOfActiveDevc = JSON.parse(response.data.arrUnremovedUsrBcozOfActiveDevc);
                    } catch(e) {
                        arrRcvdUnremovedUsrBcozOfActiveDevc = [];
                        console.log("Invalid JSON format.")
                    }

                    if(arrRcvdUnremovedUsrBcozOfActiveDevc != null && arrRcvdUnremovedUsrBcozOfActiveDevc.length >0) {
                        let strUnRemovedUserId = "";

                        for(let i=0; i<arrRcvdUnremovedUsrBcozOfActiveDevc.length; i++) {
                            strUnRemovedUserId += (strUnRemovedUserId.length  > 0 ? ", " : '');
                            strUnRemovedUserId += `'${arrRcvdUnremovedUsrBcozOfActiveDevc[i]}'`;
                        }

                        let strMsg = ``;

                        if(arrRcvdUnremovedUsrBcozOfActiveDevc.length == modifiedState.arrUserIdToBeRemoved.length) {
                            strMsg = `User(s) with User ID ${strUnRemovedUserId} cannot be removed because they are having devices assigned with them which are still receiving notifications. Please disable those devices from receiving notifications and then try to remove these users.`
                        } else {
                            strMsg = `User(s) removed successfully from the Selected Tree Node Except these ${strUnRemovedUserId} User(s). These users cannot be removed because they are having devices assigned with them which are still receiving notifications. Please disable those devices from receiving notifications and then try to remove these users.`
                        }
    
                        alert(strMsg);
                        this.onCloseAddUserPopup();
                    } else {

                        alert("User(s) removed successfully from the Selected Tree Node.");
                        // this.setState(modifiedState);
                        this.onCloseAddUserPopup();
                        return; // No further processing required
                    }
                } 
                // Making input fields and arays empty.
                modifiedState.arrUserIdToBeRemoved = [];
                modifiedState.selectedUserIdToRemove = "";
                modifiedState.arrOfUserEmailIdsAssignedToTreeNode = [];
                modifiedState.searchUserEmailID = "";
                modifiedState.searchFirstName = "";
                modifiedState.searchLastName = "";
                
            } else {
                if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = t(IDS_AUServerError);
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = t(IDS_AUServerError);
                }
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }  
        })
        .catch( error => {
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                this.setState(modifiedState);
                alert(modifiedState.errors.others);
            }
        }); 
    
    }

    // Radio Button to Switch between "Add a new User" and "Search an Existing User".
    // on switching radio buttons we also Empty the TextBoxes and Array.
    onSearchByRadioBtnChange = (e) => {
        
        let modifiedState = this.state;

        modifiedState.errors.others = "";
        modifiedState.errors.email = "";
        modifiedState.errors.requiredField = "";
        modifiedState.EnteredUserEmailID = "";
        modifiedState.enteredKeyToSearchOrg = "";
        modifiedState.arrOrgsToBeAdded = [];
        modifiedState.errors.errNoOrgAddedToThisUser = "";
        modifiedState.errors.errEnterOrgNameToSearch = "";
        modifiedState.arrOfOrganizationNames = [];
        modifiedState.arrOrgToBeRemoved = [];

        modifiedState.searchFirstName = "";
        modifiedState.searchLastName = "";
        modifiedState.searchUserEmailID = "";

        modifiedState.arrOrgToBeRemoved = [];
        modifiedState.selectedExistingUserToProceed = "";
        modifiedState.bIsOrgsToBeRemoved = false;
        modifiedState.selectedExistingUser = "";
        modifiedState.isAllowUserToSendDevicesNotifications = false;
        modifiedState.errors.errSelectOrg = "";
        modifiedState.errors.errNoDevcFound = "";
        modifiedState.isCheckedAllowViewAllRemvUsr = false;
        modifiedState.deviceDataForRemvUser = [];

        if(modifiedState.bInsistUserToSearch == true){
            modifiedState.arrOfEmailIdsOfSearchedExistingUsers = [];
        }

        if(modifiedState.receivedUsrEmailIdHavingAssignedOrgs.length == 0){
            modifiedState.errors.noUserAdded = "No User(s) Added on this Node.";
        } 

        modifiedState.isProceedBtnClicked = false;
        modifiedState.bEnteredUserAlreadyAdded = false;
        modifiedState.bTakeActionOnOrg = false;
        modifiedState.isAllowUserToAccessDevicesOfAllOrgs = false;
        
        modifiedState.displayBy = e.target.value;
        
        this.setState(modifiedState);  
    }

    // Radio Button to Switch between "Add Only Specific Organisations to View" and "Remove Only Specifically Viewable Organisations".
    onAddOrRemoveOrgRadioBtnChange = (e) => {
        
        let modifiedState = this.state;
        
        modifiedState.actionOnOrg = e.target.value;
        modifiedState.errors.errEnterOrgNameToSearch = "";
        modifiedState.arrOrgsToBeAdded = [];
        modifiedState.arrOrgToBeRemoved = [];
        modifiedState.isRemoveUserDueToRemovalOfLastOrg = false;
        modifiedState.isAllowUserToSendDevicesNotifications = false;
        modifiedState.errors.errSelectOrg = "";
        modifiedState.errors.errNoDevcFound = "";

        if(modifiedState.actionOnOrg == "RemoveOrg") {
            let strMsg = `If you are going to remove any organisation from "Already Added Organisation" of the User then you will stop receiving notification for those devices which are under Organisation which you are going to remove.`;
            alert(strMsg);    
        }
        this.setState(modifiedState);  
    }

    // For searching Organisation from the Database which are assigned to the selected user and which are matched to the entered keyword.
    onClickSearchOrg = () => {
        let modifiedState = this.state;
        modifiedState.errors.errSelectOrg = "";
        modifiedState.errors.errNoDevcFound = "";
        modifiedState.isAllowUserToSendDevicesNotifications = false;

        modifiedState.errors.errEnterOrgNameToSearch = (modifiedState.enteredKeyToSearchOrg == null || 
                                                        modifiedState.enteredKeyToSearchOrg == '' || 
                                                        modifiedState.enteredKeyToSearchOrg.length < 0)
        ? "Please enter Organisation Name to Search." 
        : "";

        if(modifiedState.enteredKeyToSearchOrg != null && modifiedState.enteredKeyToSearchOrg.length > 0){
            modifiedState.isOrganisationToSearchAndSelect = true;
            this.setState(modifiedState);
        }

        modifiedState.arrOfNamesOfSearchedOrgs = [];
        modifiedState.retreivedSearchResultOfOrgs = [];

        // if required fields are not entered by user then return the function and show error message.
        if(modifiedState.errors.errEnterOrgNameToSearch.length > 0) {
            this.setState(modifiedState);
            return
        }
        else {
            const jsonBody = {
                OrgName: trimStringAndRemoveTrailingComma(modifiedState.enteredKeyToSearchOrg)
            };

            axios.post(`${getAPIHostURL()}/wclient/searchOrgInfo`, jsonBody)
            .then(response => {
                
                if(response.data.code == 'SUCCESS') {

                    if(response.data.retreivedSearchResultOfOrgs == null || response.data.retreivedSearchResultOfOrgs.length <= 0) {
                        modifiedState.required = "No Search Result Found for Organisation.";
                        alert("No matched search result with entered keywords.")

                    } else {
                        modifiedState.retreivedSearchResultOfOrgs = response.data.retreivedSearchResultOfOrgs;
                        for(let i=0; i<response.data.retreivedSearchResultOfOrgs.length; i++){
                            modifiedState.arrOfNamesOfSearchedOrgs.push(response.data.retreivedSearchResultOfOrgs[i]["OrgName"]);
                        }
                    }
                } else {
                    if(response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the Required parameters were not sent to the Server
                        modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                    } else if(response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                this.setState(modifiedState);
            })
            .catch(error => {
                console.log(error);
                console.log("Network error:");
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    this.setState(modifiedState);
                }
            })
        }
    }

    // If the user is already present in the database then bring its Addtional Info.
    // If this fucntion is invoked from various places then its setState will be done through variations.
    onClickSearchExistingUser = (event = null, invokedFrom = null, inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null){
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        // While adding a new user to the node, but if the same user is already present in the database then,
        // for bringing its Addiitonal Info we are passing EnteredUserEmailID as searchUserEmailID to the API.
        if((modifiedState.displayBy == "addUserEmailId" && modifiedState.activeTab != 'selectByRemoveUser') ||
           (modifiedState.displayBy == "searchUserEmailId")) {

            if(modifiedState.bInsistUserToSearch == false){
                if(modifiedState.displayBy == "addUserEmailId"){
                    modifiedState.searchUserEmailID = modifiedState.EnteredUserEmailID;
                } else {
                    modifiedState.searchUserEmailID = modifiedState.selectedExistingUser;
                }

            } else {
                if(modifiedState.displayBy == "addUserEmailId"){
                    modifiedState.searchUserEmailID = modifiedState.EnteredUserEmailID;
                } else {
                    modifiedState.searchUserEmailID = modifiedState.searchUserEmailID;
                }

            }
        }

        // Ensuring that the user has entered atleast one field for Searching.
        modifiedState.errors.requiredField = ( (modifiedState.searchUserEmailID == null || modifiedState.searchUserEmailID == '' || modifiedState.searchUserEmailID.length < 0) &&
                                               (modifiedState.searchFirstName == null || modifiedState.searchFirstName == '' || modifiedState.searchFirstName.length < 0) &&
                                               (modifiedState.searchLastName == null || modifiedState.searchLastName == '' || modifiedState.searchLastName.length < 0) &&
                                               (this.state.arrOfEmailIdsOfSearchedExistingUsers == null || this.state.arrOfEmailIdsOfSearchedExistingUsers.length <= 0)
                                             )
        ? "Please enter atleast any one field to Search." 
        : "";

        // if any one of the required fields are not entered by user then return the function and show error message.
        if(modifiedState.errors.requiredField.length > 0) {            
            this.setState(modifiedState);
            return
        }
        else {
            const jsonBody = {
                FirstName: trimStringAndRemoveTrailingComma(modifiedState.searchFirstName), 
                LastName: trimStringAndRemoveTrailingComma(modifiedState.searchLastName),
                EmailID: trimStringAndRemoveTrailingComma(modifiedState.searchUserEmailID),
                selectedTreeNodeID: modifiedState.SelectedNodeID,
                bInvokedFromAddUserEmailId: (modifiedState.displayBy == "addUserEmailId" && 
                                             modifiedState.activeTab == 'selectByRemoveUser') ? false : 
                                             modifiedState.displayBy == "addUserEmailId" ? true : false
            };

            axios.post(`${getAPIHostURL()}/wclient/searchExistingUserAddedToSelectedNode`, jsonBody)
            .then(response => {
                
                if(response.data.code == 'SUCCESS') {

                    if(response.data.retreivedSearchResultOfExistingUsers == null || response.data.retreivedSearchResultOfExistingUsers.length <= 0) {
                        modifiedState.required = "No Search Result Found";

                        if(modifiedState.displayBy == "searchUserEmailId" || modifiedState.activeTab == "selectByRemoveUser"){
                            alert("No matched search result with entered keywords.");
                        }
                        
                        modifiedState.searchUserEmailID = "";
                        modifiedState.searchFirstName = "";
                        modifiedState.searchLastName = "";

                    } else {
                        modifiedState.receivedResultOfSerchedExistingUser = response.data.retreivedSearchResultOfExistingUsers;

                        modifiedState.bExistingUserSearched = true;

                        let additionalInfoOfMatchedUsers = {};
                        let associatedTreeNodeIdsOfMatchedUsers = [];
                        for(let i=0; i<modifiedState.receivedResultOfSerchedExistingUser.length; i++){

                            modifiedState.bIsExistingUserFound = true;

                            // Fetching Additional Info from the Retreieved result.
                            additionalInfoOfMatchedUsers = JSON.parse(modifiedState.receivedResultOfSerchedExistingUser[i]["AdditionalInfo"]);

                            if(additionalInfoOfMatchedUsers != null && Object.keys(additionalInfoOfMatchedUsers).length > 0){
                                // Fetching AssociatedTreeNodeIds from the Retreieved result.
                                associatedTreeNodeIdsOfMatchedUsers = additionalInfoOfMatchedUsers["AssociatedTreeNodeIds"];

                                modifiedState.associatedTreeNodeIds = (additionalInfoOfMatchedUsers["AssociatedTreeNodeIds"] != null &&
                                                                       additionalInfoOfMatchedUsers["AssociatedTreeNodeIds"].length > 0) ?
                                                                       additionalInfoOfMatchedUsers["AssociatedTreeNodeIds"] : [];

                                let orgIdsOfSearchedExistingUser = [];

                                orgIdsOfSearchedExistingUser = (additionalInfoOfMatchedUsers["OrganisationIds"] != null &&
                                                                additionalInfoOfMatchedUsers["OrganisationIds"].length > 0) ?
                                                                additionalInfoOfMatchedUsers["OrganisationIds"] : [];

                                if((orgIdsOfSearchedExistingUser == null || orgIdsOfSearchedExistingUser.length <= 0) &&
                                   (modifiedState.associatedTreeNodeIds != null && modifiedState.associatedTreeNodeIds.length > 0)
                                ){
                                    modifiedState.isAllowUserToAccessDevicesOfAllOrgs = true;
                                }

                                // Getting Organisation Names of already added organisations for showing them in a dropdown of Organisation Removal.
                                for(let j = 0; j<modifiedState.receivedOrganizationInfo.length; j++){
                                    for(let k = 0; k<orgIdsOfSearchedExistingUser.length; k++){
                                        if(modifiedState.receivedOrganizationInfo[j]["OrgID"] ==  orgIdsOfSearchedExistingUser[k]){
                                            modifiedState.arrOfOrganizationNames.push(modifiedState.receivedOrganizationInfo[j]["OrgName"]);
                                        }
                                    }
                                }

                                if(associatedTreeNodeIdsOfMatchedUsers != null && associatedTreeNodeIdsOfMatchedUsers.length > 0){
                                    for(let j=0; j<associatedTreeNodeIdsOfMatchedUsers.length; j++){
                                        if(associatedTreeNodeIdsOfMatchedUsers[j] == modifiedState.SelectedNodeID){
                                            modifiedState.arrOfEmailIdsOfSearchedExistingUsers.push(modifiedState.receivedResultOfSerchedExistingUser[i]["EmailID"]);
                                        }
                                    }
                                }
                                
                            }
                        }

                        modifiedState.arrOfOrganizationNames = [...new Set(modifiedState.arrOfOrganizationNames)];
                        modifiedState.arrOfEmailIdsOfSearchedExistingUsers = [...new Set(modifiedState.arrOfEmailIdsOfSearchedExistingUsers)];
                        modifiedState.arrOfUserEmailIdsAssignedToTreeNode = [...new Set(modifiedState.arrOfUserEmailIdsAssignedToTreeNode)];

                        if(modifiedState.displayBy != "addUserEmailId" && 
                           (modifiedState.arrOfEmailIdsOfSearchedExistingUsers == null || 
                            modifiedState.arrOfEmailIdsOfSearchedExistingUsers.length <= 0)
                          )
                        {
                            modifiedState.errors.others = "No existing user found added with an entered keyword to this selected node."
                        }
                    } 
                    
                    if(invokedFrom != null && invokedFrom.length > 0 && invokedFrom == "invokedFromProceed"){
                        // If the new user is already present in the database and it is new only for the current node 
                        // then bring its Additional Info (Previously Added Organisation Info).

                        this.getUserEmailIdsHavingAssignedOrgs(modifiedState);
                    } else if(invokedFrom != null && invokedFrom.length > 0 && invokedFrom == "invokedFromProceedSearchExistingUser"){
                        this.fillArrayOfOrgNamesAndOther(modifiedState);
                    } else {
                        this.setState(modifiedState);
                    }

                } else {
                    if(response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the Required parameters were not sent to the Server
                        modifiedState.errors.others = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                    } else if(response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                    this.setState(modifiedState);
                }

            })
            .catch(error => {
                console.log(error);
                console.log("Network error:");
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    this.setState(modifiedState);
                }
            })
        }
    }

    // Handle Change of Search Fields.
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let modifiedState = this.state;

        modifiedState.errors.others = "";
        modifiedState.arrOfNamesOfSearchedOrgs = [];
        modifiedState.arrOfEmailIdsOfSearchedExistingUsers = [];
        modifiedState.errors.errNoOrgAddedToThisUser = "";
        modifiedState.bTakeActionOnOrg = false;
        modifiedState.isAllowUserToAccessDevicesOfAllOrgs = false;
        modifiedState.bIsOrgsToBeRemoved = false;
        modifiedState.arrOfOrganizationNames = [];
        modifiedState.errors.errEnterOrgNameToSearch = "";
        modifiedState.arrOrgsToBeAdded = [];
        modifiedState.enteredKeyToSearchOrg = "";

        let errors = this.state.errors;
    
        switch (name) {

            case 'orgName': 
                errors.requiredField = ""       
            break;

            case 'searchFirstName': 
                errors.requiredField = ""       
            break;

            case 'searchLastName': 
                errors.requiredField = ""       
            break;

            case 'searchUserEmailID': 
                errors.requiredField = ""  
            break;

            default:
            break;
        }

        this.setState({
            errors, 
            [name]: value,
            modifiedState
             
            // [event.target.name]: event.target.value,   
        }, ()=> {
        })
    }

    // Hamdle Change of Organisation Search.
    handleChangeOrgSearch = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let modifiedState = this.state;

        modifiedState.errors.others = "";
        modifiedState.errors.requiredField = "";
        modifiedState.errors.errEnterOrgNameToSearch = "";
        modifiedState.errors.errOrgsToAdd = "";
        modifiedState.enteredKeyToSearchOrg = event.target.value;
        modifiedState.arrOfNamesOfSearchedOrgs = [];

        this.setState(modifiedState);
    
    }

    // On Change Handler while Organisation Selection Dropdown.
    onChangeOrgSelection = (e) => {
        let modifiedState = this.state;
        modifiedState.errors.errSelectOrg = "";
        modifiedState.errors.errSelectOrgToAdd = "";
        modifiedState.errors.errOrgsToAdd = "";
        modifiedState.errors.errNoDevcFound = "";
        modifiedState.isAllowUserToSendDevicesNotifications = false;
        modifiedState.selectedOrg = e.target.value
        this.setState(modifiedState);
    }

    // On Change Handler of searched existing users which are added to the node..    
    onChangeExistingUserSelection = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedExistingUser = e.target.value;
        modifiedState.errors.errSelectExistingUserToAdd = "";
        modifiedState.errors.errNoOrgAddedToThisUser = "";
        modifiedState.errors.errOrgsToAdd = "";
        modifiedState.isCheckedAllowViewAllRemvUsr = false;
        modifiedState.deviceDataForRemvUser = [];
        modifiedState.selectedExistingUserToProceed = "";
        modifiedState.errors.errNoDevcFound = "";
        modifiedState.errors.errOrgsToAdd = "";
        modifiedState.isAllowUserToSendDevicesNotifications = false;
        this.setState(modifiedState);
    }

    // For adding selected Organisation to the array of displaying selected Organisation.
    onClickAddSelectedOrg = () => {
        let modifiedState = this.state;

        let selectedOrg = modifiedState.selectedOrg;

        modifiedState.errors.errSelectOrgToAdd = (modifiedState.selectedOrg == null || modifiedState.selectedOrg == "" || modifiedState.selectedOrg.length <= 0)
        ? "Please Select Organisation to add."
        : "";

        if(selectedOrg != null && modifiedState.selectedOrg.length > 0){
            if(modifiedState.arrOrgsToBeAdded.findIndex(singleSelectedOrg => selectedOrg.toLowerCase() == singleSelectedOrg.toLowerCase()) >= 0) {
                modifiedState.errors.errOrgsToAdd = 'Organisation has been already selected.';
                this.setState(modifiedState);
            } else {
                modifiedState.selectedOrg = "";
                modifiedState.errors.others = "";
                modifiedState.errors.errOrgsToAdd = "";
                // Just wanted to show last inserted Organisation at the top of DisplayBox.
                modifiedState.arrOrgsToBeAdded.unshift(selectedOrg);
            }
        }
        
        this.setState(modifiedState);
    }

    // Further procedure, after selecting user from searched existing user.
    onClickProceedSearchedExistingUser = () => {
        let modifiedState = this.state;

        modifiedState.errors.errSelectExistingUserToAdd = (modifiedState.selectedExistingUser == null || modifiedState.selectedExistingUser == "" || modifiedState.selectedExistingUser.length <= 0)
        ? "Please Select a user."
        : "";

        // To check if the selected Email ID is already assigned to the selected node.
        this.onClickSearchExistingUser(null, "invokedFromProceedSearchExistingUser");

        this.setState(modifiedState);
    }

    fillArrayOfOrgNamesAndOther = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null){
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.selectedExistingUserToProceed = modifiedState.selectedExistingUser;
        let additionalInfoOfSearchedExistingUser = {};
        let orgIdsOfSearchedExistingUser = [];
        modifiedState.arrOfOrganizationNames = [];
        modifiedState.selectedExistingUser = "";

        if(modifiedState.displayBy != "addUserEmailId"){
            modifiedState.EnteredUserEmailID = modifiedState.selectedExistingUserToProceed;
            if(modifiedState.selectedExistingUserToProceed != null && modifiedState.selectedExistingUserToProceed.length > 0){
                modifiedState.bTakeActionOnOrg = true; 
            }
        }

        // Storing Already added OrganisationIds of the searched user to the array for further use.
        for(let i=0; i<modifiedState.receivedResultOfSerchedExistingUser.length; i++){
            if(modifiedState.receivedResultOfSerchedExistingUser[i]["EmailID"] == modifiedState.selectedExistingUserToProceed){
                additionalInfoOfSearchedExistingUser = JSON.parse(modifiedState.receivedResultOfSerchedExistingUser[i]["AdditionalInfo"]);
                orgIdsOfSearchedExistingUser = additionalInfoOfSearchedExistingUser["OrganisationIds"];
            }
        }

        let arrOfOrgs = [];

        // Getting Organisation Names of already added organisations for showing them in a dropdown of Organisation Removal.
        if(modifiedState.receivedOrganizationInfo != null && modifiedState.receivedOrganizationInfo.length > 0){
            if(orgIdsOfSearchedExistingUser != null && orgIdsOfSearchedExistingUser.length > 0){
                for(let j = 0; j<modifiedState.receivedOrganizationInfo.length; j++){
                    for(let k = 0; k<orgIdsOfSearchedExistingUser.length; k++){
                        if(modifiedState.receivedOrganizationInfo[j]["OrgID"] ==  orgIdsOfSearchedExistingUser[k]){
                            arrOfOrgs.push(modifiedState.receivedOrganizationInfo[j]["OrgName"]);
                        }
                    }
                }
            }
        }

        modifiedState.arrOfOrganizationNames = arrOfOrgs;
     
        if(modifiedState.arrOfOrganizationNames == null || modifiedState.arrOfOrganizationNames.length <= 0){
            modifiedState.errors.errNoOrgAddedToThisUser = "Note: This User is able to View Devices of All Organisations.";
            modifiedState.isAllowUserToAccessDevicesOfAllOrgs = true;
        } else {
            modifiedState.bIsOrgsToBeRemoved = true;
        }

        this.setState(modifiedState);
    }

    // For allow entered/selected User to Access devices of All Organisations.
    allowUserToAccessDevicesOfAllOrgs = (e) => {
        let modifiedState = this.state;
        modifiedState.errors.errSelectOrg = "";
        modifiedState.errors.errNoDevcFound = "";
        modifiedState.isAllowUserToSendDevicesNotifications = false;
        modifiedState.ArrAssignedDevicesToUser = [];

        if(e.target.checked == true){

            let strMsg = `Turning on this option will allow this user to view Devices of All Organisations.\nClick "OK" if you want to allow this User to view All Organisations.\nClick "Cancel" if you don't want this User to view All Organisations.`;

            let retVal = window.confirm(strMsg);
            
            if(retVal == true){
                modifiedState.isAllowUserToAccessDevicesOfAllOrgs = e.target.checked;
            } else {
                modifiedState.isAllowUserToAccessDevicesOfAllOrgs = false;
                this.setState(modifiedState);
            }

        } else {
            modifiedState.isAllowUserToAccessDevicesOfAllOrgs = e.target.checked;
        }
        
        this.setState(modifiedState);
    }

    handleCheckedIndividualDeviceForSendingNotiToAdduser = (e) => {
        let targetDeviceID = e.target.name;
        let isChecked = e.target.checked;

        this.setState( 
            prevState => {
                let modifiedState = prevState;
                
                let deviceData = modifiedState.ArrAssignedDevicesToUser
                deviceData.forEach( (deviceDatasingle, index) => {
                    if (deviceDatasingle.id === targetDeviceID) {
                        modifiedState.ArrAssignedDevicesToUser[index].isChecked = isChecked;
                    }
                });
                return modifiedState;
            }
        );
    }

    allowUserToSendDevicesNotification = (e) => {
        let modifiedState = this.state;
        modifiedState.ArrAssignedDevicesToUser = [];
        modifiedState.ArrSelectedAllOrganisation = [];
        modifiedState.errors.errSelectOrg = "";
        modifiedState.errors.errNoDevcFound = "";

        modifiedState.ArrSelectedAllOrganisation = [...modifiedState.arrOfOrganizationNames, ...modifiedState.arrOrgsToBeAdded];

        if((modifiedState.ArrSelectedAllOrganisation != null && modifiedState.ArrSelectedAllOrganisation.length > 0) || modifiedState.isAllowUserToAccessDevicesOfAllOrgs) {
            modifiedState.isAllowUserToSendDevicesNotifications = e.target.checked;
        } else {
            modifiedState.errors.errSelectOrg = 'Please select an Organisation first.';
            this.setState(modifiedState);
            return;
        }

        if(modifiedState.isAllowUserToSendDevicesNotifications){
            this.getDeviceInfoOfSelectedNodeAndUser();
        } else {
          // No Need to process.  
        }
        this.setState(modifiedState);
    }

    getDeviceInfoOfSelectedNodeAndUser = () => {

        let modifiedState = this.state;

        modifiedState.errors.others = "";

        let jsonBody = {
            NodeID: modifiedState.SelectedNodeID,
            AddUserEmailID: modifiedState.EnteredUserEmailID,
            ArrOrganisationIds: modifiedState.ArrSelectedAllOrganisation,
            isAllOrganisationAllow: modifiedState.isAllowUserToAccessDevicesOfAllOrgs
        }

        axios.post( `${getAPIHostURL()}/wclient/getDeviceInfoOfSelectedNodeAndUser`, jsonBody)
        .then(response => {    
            if(response.data.code == 'SUCCESS') {
                modifiedState.isCheckedAllowViewAll = true;
                modifiedState.ArrAssignedDevicesToUser = [];

                if(response.data.ArrAssignedDevicesToUser != null && response.data.ArrAssignedDevicesToUser.length > 0) {
                    for(let i=0; i<response.data.ArrAssignedDevicesToUser.length; i++) {
                        let singleValue = response.data.ArrAssignedDevicesToUser[i];
    
                        let oSingleDeviceStructure = {
                            key: singleValue.DeviceID,
                            DeviceOwnerEmailID: singleValue.DeviceOwnerEmailID,
                            name: singleValue.DeviceName, // Pass each device name to the child node 'title'
                            id: singleValue.DeviceID, // Device ID is unique in the backend
                            isChecked: true  
                        };
                        modifiedState.ArrAssignedDevicesToUser.push(oSingleDeviceStructure);                
                    }

                } else {
                    modifiedState.errors.errNoDevcFound = "There are no Device(s) for which we can send Notifications to the selected User."
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    console.log('Server experiencing issues.\nTry again later.');    
                } else if(response.data.code == 'REQ_PARAMS_MISSING') {
                    console.log('Server experiencing issues.\nTry again later.');
                } else {
                    console.log('Should not reach here');
                    console.log('Server experiencing issues.\nTry again later.');    
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {
            console.log("Network error:");
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                console.log('Network issues.\nCheck your Internet and Try again later.');
                this.setState(modifiedState);
            }
        }); 
    }

    onChangeStartViewTime = updatedTime => {
        let updatedActiveStartTime = updatedTime;

        this.setState( 
            prevState => {
                let modifiedState = prevState;
                // Dont Allow the user to enter null time. If he enters null 
                // time (For ex. Using X Button), then set the Date back to the original value.
                modifiedState.StartViewTime = 
                    updatedActiveStartTime == null ? prevState.StartViewTime : updatedActiveStartTime ;
                return modifiedState;
            }
        );
    }

    onIsCheckedForever = (e) => {
        let isChecked = e.target.checked;
        this.setState( 
            prevState => {
                let modifiedState = prevState;
                modifiedState.isCheckedForever = isChecked;

                if(isChecked) {
                    modifiedState.EndViewTime = null;
                } else {
                    // Set the ViewEndDateTime 1 hour beyond the ViewStartDateTime
                    let viewEndDateTime = new Date( modifiedState.StartViewTime.valueOf() );
                    viewEndDateTime.setHours(viewEndDateTime.getHours() + 1);
                    modifiedState.EndViewTime = viewEndDateTime;
                }

                return modifiedState;
            }
        );
    }

    onEndVwDtTmCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        this.setState( 
            prevState => {

                let currentlySetStartViewTime = prevState.StartViewTime;
                // Based on the currently set StartViewTime, calculate the minimum required EndDateTime (i.e 1 hour greater)
                let minRequiredEndViewTime = new Date( currentlySetStartViewTime.valueOf() );
                minRequiredEndViewTime.setHours(minRequiredEndViewTime.getHours() + 1);

                let updatedEndViewTime = prevState.EndViewTime;

                let modifiedState = prevState;

                if ( updatedEndViewTime != null && updatedEndViewTime < minRequiredEndViewTime ) {
                    alert(t(IDS_IDS_AUViewDateTime));

                    modifiedState.EndViewTime = minRequiredEndViewTime; 
                }

                return modifiedState;
            }
        );
    }

    onChangeEndViewTime = updatedTime => {
        let updatedActiveEndTime = updatedTime;

        this.setState( 
            prevState => {
                let modifiedState = prevState;
                modifiedState.EndViewTime = updatedActiveEndTime;
                return modifiedState;
            }
        );
    }

    onStartVwDtTmCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        this.setState( 
            prevState => {

                let updatedActiveStartTime = prevState.StartViewTime;
                // Based on the currently set StartViewTime, calculate the minimum required EndDateTime (i.e 1 hour greater)
                let minRequiredEndViewTime = new Date( updatedActiveStartTime.valueOf() );
                minRequiredEndViewTime.setHours(minRequiredEndViewTime.getHours() + 1);

                let currentlySetEndViewTime = prevState.EndViewTime;

                // Give a tolerance of -5 minutes to the current date time to avoid unnecessary messages
                let currentDateTime = new Date();
                currentDateTime.setMinutes(currentDateTime.getMinutes() - 5);

                let modifiedState = prevState;

                if(updatedActiveStartTime < currentDateTime) {
                    let strMsg = t(IDS_AUCurentDateTime) + 
                    (currentlySetEndViewTime == null ? '' : 
                        t(IDS_AUAdjustCurentDateTime));

                    alert(strMsg);

                    // Calculate new minimum required End View Time based on the currentDateTime
                    currentDateTime = new Date(); // Remove tolerance so that we get more acurate start time
                    minRequiredEndViewTime = new Date( currentDateTime.valueOf() );
                    minRequiredEndViewTime.setHours(minRequiredEndViewTime.getHours() + 1);

                    modifiedState.StartViewTime = currentDateTime;
                    if( modifiedState.EndViewTime != null && currentlySetEndViewTime < minRequiredEndViewTime ) {
                        modifiedState.EndViewTime = minRequiredEndViewTime;
                    }

                } else if ( currentlySetEndViewTime != null && currentlySetEndViewTime < minRequiredEndViewTime ) {
                    alert(t(IDS_IDS_AUViewDateTime));

                    modifiedState.EndViewTime = minRequiredEndViewTime; 
                } else {
                    // Everything is fine. No actions required.
                }

                return modifiedState;
            }
        );
    }

    onChangeRemvUsrEndViewTime = updatedTime => {
        let updatedActiveEndTime = updatedTime;
        this.setState( 
            prevState => {
                let modifiedState = prevState;
                modifiedState.RemvUsrEndViewTime = updatedActiveEndTime;
                return modifiedState;
            }
        );
    }

    onRemvUsrEndVwDtTmCalendarOrClockClose = () => {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        this.setState( 
            prevState => {
                let modifiedState = prevState;

                let updatedEndViewTime = prevState.RemvUsrEndViewTime;

                // Give a tolerance of -5 minutes to the current date time to prevent unnecessary alert message
                let currentDateTime = new Date();
                currentDateTime.setMinutes(currentDateTime.getMinutes() - 5);

                if(updatedEndViewTime < currentDateTime) {
                    let strMsg = 'End View Date Time cannot be less than the current date time.' + 
                    '\nIt will be set to current date time.';
                    alert(strMsg);

                    // Calculate End View Time based on the currentDateTime
                    let RemvUsrEndViewTime = new Date(); // Eliminate the 5 minute tolerance added earlier so that we get more accurate End time
                    modifiedState.RemvUsrEndViewTime = RemvUsrEndViewTime;
                }

                return modifiedState;
            }
        );
    }

    handleCheckedIndividualDeviceRmvUser = (e) => {
        let targetDeviceID = e.target.name;
        let isChecked = e.target.checked;

        this.setState( 
            prevState => {
                let modifiedState = prevState;
                
                let deviceData = modifiedState.deviceDataForRemvUser
                deviceData.forEach( (deviceDatasingle, index) => {
                    if (deviceDatasingle.id === targetDeviceID) {
                        modifiedState.deviceDataForRemvUser[index].isChecked = isChecked;
                    }
                });
                
                return modifiedState;
            }
        );
    }

    RemvUsrHandleAllChecked = (event) => {

        // Get the checked state from the event
        let isCheckedAllowViewAllRemvUsr = event.target.checked;

        // First get the full state so that only required elements can be updated
        let modifiedState = this.state;

        modifiedState.isCheckedAllowViewAllRemvUsr = isCheckedAllowViewAllRemvUsr;
        modifiedState.deviceDataForRemvUser = []; // Initially set the Devices Data array to empty

        // Get the list of eligible devices for this additional user only if the 'AllowViewAll' checkbox is checked
        if(isCheckedAllowViewAllRemvUsr) {
            // JSON object containing DeviceOwnerEmailID and RemoveUserEmailID to be sent to the API Server
            let jsonParams = {
                ExistingUserEmailID: modifiedState.selectedExistingUserToProceed,
                SelectedNodeID: modifiedState.SelectedNodeID
            }

            axios.post(`${getAPIHostURL()}/wclient/getEligibleOwnedDevicesBasedOnTreeNodeIdForRemoveUser`, jsonParams)
            .then(response => {
                if(response.data.code == 'SUCCESS') {

                    // Take the Devices info that were received from the server
                    let receivedDevicesInfo = response.data.retreivedDevicesInfo;

                    if(receivedDevicesInfo.length <= 0) {
                        // No errors
                        console.log('No Devices found which have been already Added for this User.');

                    } else {
                        // Take each Devices Info that was received from axios and 
                        // add into the State's 'deviceDataForRemvUser' array after appropriate modification if required

                        // Also initially set no errors message (which can/may be set to appropriate error in the loop processing if required)
                        modifiedState.errors.errRmvUsr = '';

                        let noOfDevices = receivedDevicesInfo.length;

                        for (let i = 0; i < noOfDevices; i++) {
                            const singleValue = receivedDevicesInfo[i];

                            let singleDeviceInfo = { 

                                // EXAMPLE: {key: 'DeviceID', id: 'DeviceID', value: "Device1", isChecked: true},

                                key: singleValue.DeviceID, // DeviceID in the Server is unique so can be used directly as key
                                id: singleValue.DeviceID, // The DeviceID
                                value: singleValue.DeviceName,
                                isChecked: true // Initially all eligible devices will be allowed for additional user
                            };

                            modifiedState.deviceDataForRemvUser.push(singleDeviceInfo);
                        
                        } // for: Adding each DeviceInfo into the State's 'deviceDataForRemvUser' array
                    }
                } else {
                    if (response.data.code == 'REQ_PARAMS_MISSING') {
                        modifiedState.errors.errRmvUsr = 'Server experiencing issues (required parameters are not sent).\nTry again later.';
                    } else if (response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.errRmvUsr = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.errRmvUsr = 'Server experiencing issues.\nTry again later.';
                    }
                }

                this.setState(modifiedState);
            })
            .catch( error => {
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    modifiedState.errors.errRmvUsr = 'Network issues.\nCheck your Internet and Try again later.';
                    this.setState(modifiedState);
                }
            }); 

        } else {
            this.setState(modifiedState); // Original state with 'AllowViewAll' set to false
        }
    }

    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;   
        const{errors} = this.state;

        return (
            <div className="deviceProfileBlackfilm" style={{overflowX: "scroll"}}>    
            <div className="container">
                <div className="row">
                    <div className="container col-lg-12,col-md-12 col-sm-12 ">
                        <div className="mvAddScroll">
                            <div className="adduser-popup">
                                <div className="popup-scroll">
                                    <div className = "container col-lg-12" style = {{display: "flex", justifyContent: "space-between", padding: "0.2rem" }}>
                                        <div>
                                            <button type = "button" 
                                                className = "btn-md addCustBtn" 
                                                style = {{background: "transparent", pointerEvents: "none", border: "none", color:"transparent"}} 
                                            >
                                                X
                                            </button>  
                                        </div>   
                                        <div className = "customerTableHeading" style={{fontSize: "1.7rem"}}>Add/Remove User For Node</div> 
                                        <div>
                                            <button type = "button"
                                                className="btnClearInputField"
                                                onClick = {this.onCloseAddUserPopup}>X
                                            </button>  
                                        </div>
                                    </div>

                                    <h4 className="deviceDetailToShowInAddRemoveUserToTheNode"> {this.state.SelectedTreeNodeTitle} </h4>
                                    <div className="container col-lg-10 col-lg-offset-2 col-md-12 col-md-offset-2">
                                        <div className="boxForRangesForSensors">    
                                            <Nav tabs className="tabular" style={{marginBottom: "0.3rem"}}>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab == 'selectByAddUser' })}
                                                        onClick={() => { this.toggle('selectByAddUser'); }}
                                                    >
                                                        <b>Add User to the node</b>
                                                    </NavLink>
                                                </NavItem>

                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab == 'selectByRemoveUser' })}
                                                        onClick={() => { this.toggle('selectByRemoveUser'); }}
                                                    >
                                                        <b>Remove User from the node</b>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>

                                            <TabContent activeTab={this.state.activeTab}>
                                                <TabPane tabId="selectByAddUser" className="tabular2">
                                                <Row>
                                                    <Col sm="12">
                                                    <div className="trackRadioDiv" style={{marginTop: "1rem"}}>
                                                        <div className = "trackradioBox" style={{padding: "0.4rem", borderRadius:"5px"}}>
                                                            <div>
                                                                <input type='radio' 
                                                                       name='searchBy'
                                                                       id = "addUserEmailId"
                                                                       value= "addUserEmailId"
                                                                       onChange={this.onSearchByRadioBtnChange} noValidate 
                                                                       defaultChecked={true}
                                                                /> 
                                                                <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Add a New User Email Id</span>
                                                            </div>

                                                            <div>
                                                                <input type='radio' 
                                                                       name='searchBy'  
                                                                       id = "searchUserEmailId" 
                                                                       value= "searchUserEmailId"
                                                                       onChange={this.onSearchByRadioBtnChange} noValidate
                                                                />
                                                                <span style={{marginLeft: "0.3rem"}}>Search for an Existing User Email Id</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", 
                                                                     marginTop:"0.6rem", marginBottom:"0.6rem", background:"#fafafa",
                                                                     display: this.state.displayBy == "addUserEmailId" ? "block" : "none"}}
                                                        >
                                                            <div className="inputgroupCustom">
                                                                <label className="addCustFormLabelWithRequiredFiled" style={{marginTop:"0.4rem"}}>
                                                                    Enter User Email Id to Add:
                                                                </label>
                                                                <div className="addRemoveUserInputField">
                                                                    <input type="text"
                                                                        className="AddRemoveUserInputForm"
                                                                        value={this.state.EnteredUserEmailID}
                                                                        onChange={this.handleUserEmailChange}
                                                                    />
                                                                    <div style={{display: "flex", justifyContent: "center"}}>
                                                                        <button type="button" 
                                                                                className="btnAddRemoveUserToTheNode"
                                                                                onClick={this.onClickProceed}
                                                                        >
                                                                            Proceed
                                                                        </button> 
                                                                    </div>
                                                                </div>     
                                                            </div>
                                                            <div>
                                                                {this.state.errors.others.length > 0 &&
                                                                    <div className='addRemoveUserToNodeError'>{this.state.errors.others}</div>}
                                                                    
                                                                {this.state.errors.email.length > 0 &&
                                                                    <div className='addRemoveUserToNodeError'>{this.state.errors.email}</div>}
                                                            </div>
                                                        </div>

                                                        <div style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", marginTop:"0.6rem", marginBottom:"0.6rem", background:"#fafafa",
                                                                     display: ((this.state.displayBy == "searchUserEmailId" || this.state.activeTab == "selectByRemoveUser") &&
                                                                                this.state.bInsistUserToSearch == true) ? "block" : "none"}}
                                                        >
                                                            <div className="form-group trackFrmrow">
                                                                <div className="trackFrmLblAndInputDiv" >
                                                                    <div className="inputgroupCustom" style={{marginLeft: "0.5rem"}}>
                                                                        <label className="AddRemoveUserWithOrgLabel">User Email Id:</label>
                                                                        <div className="trackFrmInput">
                                                                            <input type='text' name='searchUserEmailID' className="AddRemoveUserWithOrgInputForm"
                                                                                   value={this.state.searchUserEmailID}
                                                                                   onChange={this.handleChange} noValidate    
                                                                            />
                                                                        </div>     
                                                                    </div>
                                                                </div>

                                                                <div className="trackFrmLblAndInputDiv" >
                                                                    <div className="inputgroupCustom" style={{marginLeft: "0.5rem"}}>
                                                                        <label className="AddRemoveUserWithOrgLabel">First Name:</label>
                                                                        <div className="trackFrmInput">
                                                                            <input type='text' name='searchFirstName' className=" AddRemoveUserWithOrgInputForm"
                                                                                   value={this.state.searchFirstName}
                                                                                   onChange={this.handleChange} noValidate    
                                                                            />
                                                                        </div>     
                                                                    </div>
                                                                </div>

                                                                <div className="trackFrmLblAndInputDiv" >
                                                                    <div className="inputgroupCustom" style={{marginLeft: "0.5rem"}}>
                                                                        <label className="AddRemoveUserWithOrgLabel">Last Name:</label>
                                                                        <div className="trackFrmInput">
                                                                            <input type='text' name='searchLastName' className=" AddRemoveUserWithOrgInputForm"
                                                                                   value={this.state.searchLastName}
                                                                                   onChange={this.handleChange} noValidate    
                                                                            />
                                                                        </div>     
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                                <button type="button" 
                                                                        className="btnAddRemoveUserToTheNode"
                                                                        onClick={this.onClickSearchExistingUser}> Search 
                                                                </button>
                                                            </div>
                                                            <div>
                                                                {errors.requiredField.length > 0 &&
                                                                    <div className='addRemoveUserToNodeError'>{errors.requiredField}</div>}
                                                                {errors.others.length > 0 &&
                                                                    <div className='addRemoveUserToNodeError'>{errors.others}</div>}
                                                            </div>
                                                        </div>

                                                        <div className="inputgroupCustom" 
                                                             style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", marginTop:"0.6rem", marginBottom:"0.6rem", background:"#fafafa", 
                                                                     display: (this.state.displayBy != "addUserEmailId" &&
                                                                               this.state.arrOfEmailIdsOfSearchedExistingUsers != null && 
                                                                               this.state.arrOfEmailIdsOfSearchedExistingUsers.length > 0) ? "block" : "none"}}
                                                        >
                                                            <div className="inputgroupCustom">
                                                                <label className="addCustFormLabelWithRequiredFiled" style={{marginTop:"0.4rem"}}>
                                                                    Users Found:
                                                                </label>
                                                                <div className="addRemoveUserInputField">
                                                                    <select className="AddRemoveUserInputForm"
                                                                            value={this.state.selectedExistingUser}
                                                                            onChange={this.onChangeExistingUserSelection}
                                                                    >
                                                                        <option value="" disabled select="true">Select User to Proceed...</option>
                                                                        {(this.state.arrOfEmailIdsOfSearchedExistingUsers).map((singleUser,index) => <option key={index} value={singleUser}>{singleUser}</option>)}
                                                                        
                                                                    </select>

                                                                    <div style={{display: "flex", justifyContent: "center"}}>
                                                                        <button type="button" 
                                                                                className="btnAddRemoveUserToTheNode"
                                                                                onClick={this.onClickProceedSearchedExistingUser}> Proceed 
                                                                        </button> 
                                                                    </div>
                                                                </div>     
                                                            </div>

                                                            <div className="inputgroupCustom"
                                                                 style={{marginTop: "0.5rem", 
                                                                        display: (this.state.selectedExistingUserToProceed != null && 
                                                                                  this.state.selectedExistingUserToProceed.length > 0) ? "block" : "none"}}
                                                            >
                                                                <div className="inputgroupCustom">
                                                                    <label className="addCustFormLabelWithRequiredFiled" style={{marginTop:"0.4rem"}}>
                                                                        Selected User to Proceed:
                                                                    </label>
                                                                    <div className="addRemoveUserInputField">
                                                                        <input type="text"
                                                                            name="orgName"
                                                                            className="AddRemoveUserInputForm"
                                                                            style={{width:"100%"}}
                                                                            value={this.state.selectedExistingUserToProceed}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                {this.state.errors.errSelectExistingUserToAdd.length > 0 &&
                                                                    <div className='addRemoveUserToNodeError'>{this.state.errors.errSelectExistingUserToAdd}</div>}
                                                            </div>
                                                        </div>    

                                                        <div className="trackRadioDiv" style={{marginTop: "1rem", display: this.state.bTakeActionOnOrg == true ? "block" : "none"}}>
                                                            <div className = "trackradioBox" style={{padding: "0.4rem", borderRadius:"5px"}}>
                                                                <div>
                                                                    <input type='radio' name='actionOnOrg'
                                                                           id = "AddOrg"
                                                                           value= "AddOrg"
                                                                           onChange={this.onAddOrRemoveOrgRadioBtnChange} noValidate 
                                                                           defaultChecked={true}
                                                                    /> 
                                                                    <span style={{marginLeft: "0.3rem", marginRight: "1rem"}}>Add Only Specific Organisations to View</span>
                                                                </div>

                                                                <div>
                                                                    <input type='radio' name='actionOnOrg'  
                                                                           id = "RemoveOrg" 
                                                                           value= "RemoveOrg"
                                                                           onChange={this.onAddOrRemoveOrgRadioBtnChange} noValidate 
                                                                    />
                                                                    <span style={{marginLeft: "0.3rem"}}>Remove Only Specifically Viewable Organisations</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", marginTop:"0.6rem", marginBottom:"0.6rem", background:"#fafafa",
                                                                     display: (this.state.displayBy != "addUserEmailId" && 
                                                                               this.state.actionOnOrg == "RemoveOrg" && 
                                                                               this.state.bIsOrgsToBeRemoved == true) ? "block" : "none"}}>
                                                            <div className="inputgroupCustom">
                                                                <div className="inputgroupCustom" style={{marginBottom: "0.5rem"}}>
                                                                    <label className="addCustFormLabelWithRequiredFiled" style={{marginTop:"0.4rem"}}>
                                                                        Select Organisation to Remove:
                                                                    </label>
                                                                    <div className="addRemoveUserInputField">
                                                                        <select className="AddRemoveUserInputForm"
                                                                                value={this.state.selectedOrgToRemove}
                                                                                onChange={this.onChangeRemoveAddedOrg}
                                                                        >
                                                                            <option value="" disabled select="true">Select Organisation to Remove...</option>
                                                                            {(this.state.arrOfOrganizationNames).map((singleOrg,index) => <option key={index} value={singleOrg}>{singleOrg}</option>)}
                                                                            
                                                                        </select>
                                                                        
                                                                        <div style={{display: "flex", justifyContent: "center"}}>
                                                                            <button type="button"
                                                                                    className="btnAddRemoveUserToTheNode"
                                                                                    onClick={this.onClickRemoveOrg}> Remove 
                                                                            </button>
                                                                        </div>
                                                                    </div>     
                                                                </div>
                                                                <div>
                                                                    {errors.requiredField.length > 0 &&
                                                                        <div className='addRemoveUserToNodeError'>{errors.requiredField}</div>}
                                                                </div>
                                                            </div>
                                                            <div className="inputgroupCustom">
                                                                <div className="inputgroupCustom">
                                                                    <label className="addCustFormLabelWithRequiredFiled">
                                                                        Organisations To Be Removed:
                                                                    </label>
                                                                    <div className="addRemoveUserInputField">
                                                                        <select size="3" 
                                                                                style={{width:"100%", borderRadius:"5px", 
                                                                                        border:"1px solid var(--primaryColor)", color:"black" 
                                                                                }}
                                                                        >
                                                                            {(this.state.arrOrgToBeRemoved).map((singleOrg,index) => <option key={index} value={singleOrg}>{singleOrg}</option>)}
                                                                        </select>
                                                                    </div>     
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{display: (this.state.displayBy != "addUserEmailId" && 
                                                                               this.state.actionOnOrg == "RemoveOrg") ? "block" : "none"}}>
                                                            {this.state.errors.errOrgToRemove.length > 0 &&
                                                                <div className='addRemoveUserToNodeError'>{this.state.errors.errOrgToRemove}</div>}
                                                            {this.state.errors.errNoOrgAddedToThisUser.length > 0 &&
                                                                <div className='addRemoveUserToNodeError'>{this.state.errors.errNoOrgAddedToThisUser}</div>}
                                                        </div>
                                                        <div style={{ display: (this.state.displayBy == "searchUserEmailId" || this.state.activeTab == 'selectByRemoveUser') ? "block" : "none"}}>
                                                            {this.state.errors.noUserAdded.length > 0 &&
                                                                <div className='addRemoveUserToNodeError'>{this.state.errors.noUserAdded}</div>}
                                                        </div>

                                                        <div style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", marginTop:"0.6rem", marginBottom:"0.6rem", background:"#fafafa",
                                                                    display: ((this.state.displayBy == "searchUserEmailId" && this.state.actionOnOrg == "AddOrg" && this.state.bTakeActionOnOrg == true) || 
                                                                             (this.state.displayBy == "addUserEmailId" && this.state.isProceedBtnClicked == true)) &&
                                                                             (this.state.arrOfOrganizationNames != null && this.state.arrOfOrganizationNames.length > 0) ? "block" : "none"}}
                                                        >
                                                            <div className="inputgroupCustom">
                                                                <div className="inputgroupCustom">
                                                                    <label className="addCustFormLabelWithRequiredFiled">
                                                                        Already Added Organisations:
                                                                    </label>
                                                                    <div className="addRemoveUserInputField">
                                                                        <select size="3" 
                                                                                style={{width:"100%", borderRadius:"5px", 
                                                                                        border:"1px solid var(--primaryColor)", color:"black" 
                                                                                }}
                                                                        >
                                                                            {(this.state.arrOfOrganizationNames).map((selectedOrg,index) => <option key={index} value={selectedOrg}>{selectedOrg}</option>)}
                                                                        </select>
                                                                    </div>     
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="inputgroupCustom" style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", marginTop:"0.6rem", 
                                                                     marginBottom:"0.6rem", background:"#fafafa",
                                                                    display: ((this.state.isProceedBtnClicked == true && this.state.bEnteredUserAlreadyAdded == false) ||
                                                                              (this.state.bTakeActionOnOrg == true && this.state.actionOnOrg == "AddOrg")) ? "block" : "none"}}
                                                        >
                                                            <div align="left" style={{display:"flex", flexDirection:"row"}}>
                                                                <input type="checkbox"
                                                                        style={{marginTop: "0.3rem"}}
                                                                        checked={this.state.isAllowUserToAccessDevicesOfAllOrgs}
                                                                        onChange={this.allowUserToAccessDevicesOfAllOrgs}
                                                                /> 

                                                                <label style={{fontSize: "0.9rem", marginLeft: "1rem"}}>
                                                                    Allow this User to access Devices of All Organisations.
                                                                </label>        
                                                            </div>

                                                            <div align="left">
                                                                <label style={{fontSize: "0.8rem", color: "red",
                                                                               display: this.state.isAllowUserToAccessDevicesOfAllOrgs == true ? "block" : "none"}}>
                                                                    Note: Currently this User is able to View Devices of All Organisations.
                                                                </label>
                                                            </div>
                                                                
                                                            <div align="left">
                                                                <label style={{fontSize:"0.8rem"}}>
                                                                    <b>Note:</b> When this option is OFF, you should add at least one organisation. User will receive notifications for only those devices which are under the organisation which you are going to add.
                                                                </label>
                                                            </div> 
                                                            
                                                            <div style={{marginTop:"1rem", 
                                                                        display: ((this.state.isProceedBtnClicked == true && this.state.bEnteredUserAlreadyAdded == false && this.state.isAllowUserToAccessDevicesOfAllOrgs == false) ||
                                                                                  (this.state.bTakeActionOnOrg == true && this.state.actionOnOrg == "AddOrg" && this.state.isAllowUserToAccessDevicesOfAllOrgs == false)) ? "block" : "none"}}
                                                            >
                                                                <div className="inputgroupCustom">
                                                                    <div className="inputgroupCustom">
                                                                        <label className="addCustFormLabelWithRequiredFiled" style={{marginTop:"0.4rem"}}>
                                                                            Search Organisation to Add:
                                                                        </label>
                                                                        <div className="addRemoveUserInputField">
                                                                            <input type="text"
                                                                                name="orgName"
                                                                                className="AddRemoveUserInputForm"
                                                                                value={this.state.enteredKeyToSearchOrg}
                                                                                onChange={this.handleChangeOrgSearch} noValidate
                                                                            />

                                                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                                                <button type="button"
                                                                                        className="btnAddRemoveUserToTheNode"
                                                                                        onClick={this.onClickSearchOrg}> Search 
                                                                                </button> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {this.state.errors.errEnterOrgNameToSearch.length > 0 &&
                                                                        <div className='addRemoveUserToNodeError'>{this.state.errors.errEnterOrgNameToSearch}</div>}
                                                                </div>

                                                                <div className="inputgroupCustom" 
                                                                    style={{display: (this.state.arrOfNamesOfSearchedOrgs != true && this.state.arrOfNamesOfSearchedOrgs.length > 0) ? "block" : "none"}}>
                                                                    <div className="inputgroupCustom" style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
                                                                        <label className="addCustFormLabelWithRequiredFiled" style={{marginTop:"0.4rem"}}>
                                                                            Organisations Found:
                                                                        </label>
                                                                        <div className="addRemoveUserInputField">
                                                                            <select className="AddRemoveUserInputForm"
                                                                                    value={this.state.selectedOrg}
                                                                                    onChange={this.onChangeOrgSelection}
                                                                            >
                                                                                <option value="" disabled select="true">Select Organisation to Add...</option>
                                                                                {(this.state.arrOfNamesOfSearchedOrgs).map((singleOrg,index) => <option key={index} value={singleOrg}>{singleOrg}</option>)}
                                                                                
                                                                            </select>

                                                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                                                <button type="button"
                                                                                        className="btnAddRemoveUserToTheNode"
                                                                                        onClick={this.onClickAddSelectedOrg}> Add 
                                                                                </button> 
                                                                            </div>
                                                                        </div>     
                                                                    </div>
                                                                    <div>
                                                                        {this.state.errors.errSelectOrgToAdd.length > 0 &&
                                                                            <div className='addRemoveUserToNodeError'>{this.state.errors.errSelectOrgToAdd}</div>}
                                                                    </div>
                                                                </div>

                                                                <div className="inputgroupCustom" 
                                                                    style={{display: (this.state.arrOfNamesOfSearchedOrgs != true && this.state.arrOfNamesOfSearchedOrgs.length > 0) ? "block" : "none"}}>
                                                                    <div className="inputgroupCustom">
                                                                        <label className="addCustFormLabelWithRequiredFiled">
                                                                            Organisations To Be Added:
                                                                        </label>
                                                                        <div className="addRemoveUserInputField">
                                                                            <select size="3" 
                                                                                    style={{width:"100%", borderRadius:"5px", 
                                                                                            border:"1px solid var(--primaryColor)", color:"black" 
                                                                                    }}
                                                                            >
                                                                                {(this.state.arrOrgsToBeAdded).map((selectedOrg,index) => <option key={index} value={selectedOrg}>{selectedOrg}</option>)}
                                                                            </select>
                                                                        </div>     
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {this.state.errors.errOrgsToAdd.length > 0 &&
                                                                        <div className='addRemoveUserToNodeError'>{this.state.errors.errOrgsToAdd}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="inputgroupCustom" style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", marginTop:"0.6rem", 
                                                                     marginBottom:"0.6rem", background:"#fafafa",
                                                                     display: ((this.state.isProceedBtnClicked == true && this.state.bEnteredUserAlreadyAdded == false && this.state.SelectedNodeHasDevices == true) ||
                                                                     (this.state.selectedExistingUserToProceed != null && this.state.selectedExistingUserToProceed.length > 0 && this.state.bTakeActionOnOrg == true && this.state.actionOnOrg == "AddOrg" && this.state.SelectedNodeHasDevices == true)) ? "block" : "none"}}
                                                        >
                                                            <div align="left">
                                                                <label style={{fontSize:"0.8rem"}}>
                                                                    <b>Note:</b> User will receive notifications for the devices which are under the selected tree node, associated with a selected organisation and<br/>which are in 'Mapped to Owner/ Mapped as Replacement' State.
                                                                </label>
                                                            </div> 
                                                            <div className="form-group trackFrmrow">
                                                                <div align="left" style={{display:"flex", flexDirection:"row"}}>
                                                                    <input type="checkbox"
                                                                            style={{marginTop: "0.3rem"}}
                                                                            checked={this.state.isAllowUserToSendDevicesNotifications}
                                                                            onChange={this.allowUserToSendDevicesNotification}
                                                                    /> 

                                                                    <label style={{fontSize: "0.9rem", marginLeft: "1rem"}}>
                                                                        Send Device Notifications to this User
                                                                    </label>        
                                                                </div>
                                                            </div>
                                                            <div className="form-group trackFrmrow" hidden={!this.state.isAllowUserToSendDevicesNotifications}>
                                                                <div className="inputgroupCustom">
                                                                    <label className="adduser-form-label">Start Receiving From:</label>
                                                                    <DateTimePicker
                                                                        clearIcon={null}
                                                                        selected={this.state.StartViewTime}
                                                                        onChange={this.onChangeStartViewTime}
                                                                        onCalendarClose={this.onStartVwDtTmCalendarOrClockClose}
                                                                        onClockClose={this.onStartVwDtTmCalendarOrClockClose}
                                                                        className="input-form-datetime"
                                                                        format={"yyyy/MM/dd HH:mm:ss"} 
                                                                        value={this.state.StartViewTime} 
                                                                        name="StartViewTime"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group trackFrmrow" hidden={!this.state.isAllowUserToSendDevicesNotifications}>
                                                                <div className="inputgroupCustom">
                                                                    <span>    
                                                                        <label className="adduser-form-label">Receive Till :</label>
                                                                        <input type="checkbox" defaultChecked={this.state.isCheckedForever}
                                                                                className="forevercheck" 
                                                                                onClick={this.onIsCheckedForever}
                                                                        />
                                                                        <label className="checkbox-text">Forever</label>
                                                                    </span>
                                                                    {this.state.isCheckedForever ?  null : <div> 
                                                                                                            <DateTimePicker
                                                                                                                clearIcon={null}
                                                                                                                onChange={this.onChangeEndViewTime}
                                                                                                                onCalendarClose={this.onEndVwDtTmCalendarOrClockClose}
                                                                                                                onClockClose={this.onEndVwDtTmCalendarOrClockClose}
                                                                                                                className="input-form-datetime"
                                                                                                                format={"yyyy/MM/dd HH:mm:ss"} 
                                                                                                                value={this.state.EndViewTime} 
                                                                                                                name="EndViewTime"
                                                                                                            />
                                                                                                            </div>
                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                            { (this.state.isCheckedAllowViewAll) ? 
                                                                <div className="user-list-add" hidden={!this.state.isAllowUserToSendDevicesNotifications || this.state.ArrAssignedDevicesToUser == null || this.state.ArrAssignedDevicesToUser.length<= 0}>
                                                                    <div>Select the Device(s) you want to send notifications on the User Email Id</div>
                                                                    <ul> { this.state.ArrAssignedDevicesToUser.map((deviceDatasingle) => {
                                                                            return (<AddUserFormCheckbox handleCheckedIndividualDeviceForSendingNotiToAdduser={this.handleCheckedIndividualDeviceForSendingNotiToAdduser}  
                                                                                {...deviceDatasingle} />
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div> : null 
                                                            }
                                                            <div style={{color:"red", fontSize:"0.8rem"}}>
                                                                {this.state.errors.errSelectOrg}
                                                            </div>
                                                            <div style={{color:"green", fontSize:"0.8rem"}}>
                                                                {this.state.errors.errNoDevcFound}
                                                            </div>
                                                        </div>

                                                        {/* Stop Sending Notifications */}
                                                        <div>
                                                            <div style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", 
                                                                        marginTop:"0.6rem", marginBottom:"0.6rem", background:"#fafafa",
                                                                        display: (this.state.SelectedNodeHasDevices && this.state.selectedExistingUserToProceed != null && this.state.selectedExistingUserToProceed.length > 0 && this.state.bTakeActionOnOrg == true && this.state.actionOnOrg == "AddOrg") ? "block" : "none"}}
                                                            >
                                                                <div align="left">
                                                                    <label style={{fontSize:"0.8rem"}}>
                                                                        <b>Note:</b>  User will stop receiving notifications for the devices which are under the selected tree node.
                                                                    </label>
                                                                </div> 
                                                                <div className="form-group trackFrmrow">
                                                                    <div align="left" style={{display:"flex", flexDirection:"row"}}>
                                                                        <input type="checkbox"
                                                                                style={{marginTop: "0.3rem"}}
                                                                                checked={this.state.isCheckedAllowViewAllRemvUsr}
                                                                                onChange={this.RemvUsrHandleAllChecked}
                                                                        /> 

                                                                        <label style={{fontSize: "0.9rem", marginLeft: "1rem"}}>
                                                                            Stop sending Notification to this User for all Devices under this Tree Node
                                                                        </label>        
                                                                    </div>
                                                                </div>

                                                                { this.state.isCheckedAllowViewAllRemvUsr ? 
                                                                    <div>
                                                                        <div className="form-group remove-form">
                                                                            <div className="inputgroupCustom">
                                                                                <label className="removeuser-form-label">End Receiving Notification From:</label>
                                                                                <DateTimePicker
                                                                                    clearIcon={null}
                                                                                    onChange={this.onChangeRemvUsrEndViewTime}
                                                                                    onCalendarClose={this.onRemvUsrEndVwDtTmCalendarOrClockClose}
                                                                                    onClockClose={this.onRemvUsrEndVwDtTmCalendarOrClockClose}
                                                                                    className="input-form-datetime1"
                                                                                    format={"yyyy/MM/dd HH:mm:ss"} 
                                                                                    value={this.state.RemvUsrEndViewTime} 
                                                                                    name="RemvUsrEndViewTime"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            this.state.deviceDataForRemvUser.length <= 0 ?
                                                                                <span style={{color: "green", fontSize: "0.8rem"}}>No other devices currently sending notifications to the specified user.</span>
                                                                                :
                                                                                <span style={{color: "green", fontSize: "0.8rem"}}>
                                                                                    Please select a device for which you want to stop receiving Notification for the selected User.
                                                                                </span>
                                                                        }
                                                                        {
                                                                            this.state.errors.errRmvUsr.length > 0 ?
                                                                                <span style={{color: "red", fontSize: "0.8rem"}}>{this.state.errors.errRmvUsr}</span>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            this.state.deviceDataForRemvUser.length > 0 ?
                                                                            <div className="user-list-removeuser">
                                                                                <ul> { this.state.deviceDataForRemvUser.map((deviceDatasingle) => {
                                                                                        return (<RemoveUserFormCheckbox  handleCheckedIndividualDeviceRmvUser={this.handleCheckedIndividualDeviceRmvUser}  
                                                                                            {...deviceDatasingle} />
                                                                                        )
                                                                                    })}
                                                                                </ul>
                                                                            </div> 
                                                                            :
                                                                            null
                                                                        }
                                                                    </div> 
                                                                    : null 
                                                                }
                                                            </div> 
                                                        </div>

                                                        <div style={{display: (this.state.isProceedBtnClicked == true && this.state.bEnteredUserAlreadyAdded == false && 
                                                                               this.state.arrOrgsToBeAdded != null && this.state.arrOrgsToBeAdded.length > 0) ||
                                                                               (this.state.isCheckedAllowViewAllRemvUsr) ||
                                                                               (this.state.isAllowUserToSendDevicesNotifications == true && this.state.ArrSelectedAllOrganisation != null && this.state.ArrSelectedAllOrganisation.length > 0) ||
                                                                              (this.state.bTakeActionOnOrg == true && this.state.arrOrgsToBeAdded != null && 
                                                                               this.state.arrOrgsToBeAdded.length > 0) ||
                                                                              (this.state.arrOrgToBeRemoved != null && this.state.arrOrgToBeRemoved.length > 0) ||
                                                                              (this.state.isProceedBtnClicked == true && this.state.isAllowUserToAccessDevicesOfAllOrgs == true) ||
                                                                              (this.state.bTakeActionOnOrg == true && this.state.actionOnOrg != "RemoveOrg" && this.state.isAllowUserToAccessDevicesOfAllOrgs == true) ? "block" : "none"}}
                                                        >
                                                            <button type="button"
                                                                    className="addCustSavebtn"
                                                                    style={{width:"8rem", marginTop:"0rem", marginBottom:"0rem"}}
                                                                    onClick={this.onSubmitUserAndOrgInfo}
                                                            > 
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                    </Col> 
                                                </Row>
                                                </TabPane>
                                                <TabPane tabId="selectByRemoveUser" className="tabular2">
                                                <Row>
                                                    <Col sm="12">
                                                        <div>
                                                            <div style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", 
                                                                         marginTop:"0.6rem", marginBottom:"0.6rem", background:"#fafafa",
                                                                         display: ((this.state.displayBy == "searchUserEmailId" || this.state.activeTab == "selectByRemoveUser") &&
                                                                                    this.state.bInsistUserToSearch == true) ? "block" : "none"}}
                                                            >
                                                                <div className="form-group trackFrmrow">
                                                                    <div className="trackFrmLblAndInputDiv" >
                                                                        <div className="inputgroupCustom" style={{marginLeft: "0.5rem"}}>
                                                                            <label className="AddRemoveUserWithOrgLabel">User Email Id:</label>
                                                                            <div className="trackFrmInput">
                                                                                <input type='text' name='searchUserEmailID' 
                                                                                       className="AddRemoveUserWithOrgInputForm"
                                                                                       value={this.state.searchUserEmailID}
                                                                                       onChange={this.handleChange} noValidate    
                                                                                />
                                                                            </div>     
                                                                        </div>
                                                                    </div>

                                                                    <div className="trackFrmLblAndInputDiv" >
                                                                        <div className="inputgroupCustom" style={{marginLeft: "0.5rem"}}>
                                                                            <label className="AddRemoveUserWithOrgLabel">First Name:</label>
                                                                            <div className="trackFrmInput">
                                                                                <input type='text' name='searchFirstName' 
                                                                                       className=" AddRemoveUserWithOrgInputForm"
                                                                                       value={this.state.searchFirstName}
                                                                                       onChange={this.handleChange} noValidate    
                                                                                />
                                                                            </div>     
                                                                        </div>
                                                                    </div>

                                                                    <div className="trackFrmLblAndInputDiv" >
                                                                        <div className="inputgroupCustom" style={{marginLeft: "0.5rem"}}>
                                                                            <label className="AddRemoveUserWithOrgLabel">Last Name:</label>
                                                                            <div className="trackFrmInput">
                                                                                <input type='text' name='searchLastName' 
                                                                                       className=" AddRemoveUserWithOrgInputForm"
                                                                                       value={this.state.searchLastName}
                                                                                       onChange={this.handleChange} noValidate    
                                                                                />
                                                                            </div>     
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div style={{display: "flex", justifyContent: "center"}}>
                                                                    <button type="button" 
                                                                            style={{padding:"0.2rem", borderRadius:"5px",
                                                                                    background:"var(--primaryColor)", color:"white", 
                                                                                    border:"tranparent", fontWeight:"600", width:"5rem"
                                                                            }} 
                                                                            onClick={this.onClickSearchExistingUser}> Search 
                                                                    </button>
                                                                </div>
                                                                <div>
                                                                    {errors.requiredField.length > 0 &&
                                                                        <div className='addRemoveUserToNodeError'>{errors.requiredField}</div>}
                                                                    {errors.others.length > 0 &&
                                                                        <div className='addRemoveUserToNodeError' style={{textAlign: "center"}}>{errors.others}</div>}
                                                                </div>
                                                            </div>

                                                            <div className="form-group reg-form"
                                                                // style={{display: (this.state.arrOfUserEmailIdsAssignedToTreeNode != null && 
                                                                //     this.state.arrOfUserEmailIdsAssignedToTreeNode.length > 0) ? "block" : "none"}}
                                                                style={{display: (this.state.arrOfEmailIdsOfSearchedExistingUsers != null && 
                                                                    this.state.arrOfEmailIdsOfSearchedExistingUsers.length > 0) ? "block" : "none"}}
                                                            >
                                                                <div style={{border:"1px solid var(--primaryColor)", padding:"0.5rem", borderRadius:"5px", 
                                                                            marginBottom:"0.6rem", background:"#fafafa"}}>
                                                                    <div className="inputgroupCustom">
                                                                        <div className="inputgroupCustom" style={{marginBottom: "0.5rem"}}>
                                                                            <label className="addCustFormLabelWithRequiredFiled" style={{marginTop:"0.4rem"}}>
                                                                                Select User to Remove:
                                                                            </label>
                                                                            <div className="addRemoveUserInputField">

                                                                                <select className="AddRemoveUserInputForm"
                                                                                        value={this.state.selectedUserIdToRemove}
                                                                                        onChange={this.onChangeRemoveUserEmailID}
                                                                                >
                                                                                    <option value="" disabled select="true">Select User To Remove...</option>
                                                                                    {/* {(this.state.arrOfUserEmailIdsAssignedToTreeNode).map((singleUser,index) => <option key={index} value={singleUser}>{singleUser}</option>)} */}
                                                                                    {(this.state.arrOfEmailIdsOfSearchedExistingUsers).map((singleUser,index) => <option key={index} value={singleUser}>{singleUser}</option>)}
                                                                                </select>
                                                                                
                                                                                <div style={{display: "flex", justifyContent: "center"}}>
                                                                                    <button type="button"
                                                                                            className="btnAddRemoveUserToTheNode"
                                                                                            onClick={this.onClickRemoveUser}> Remove 
                                                                                    </button> 
                                                                                </div>
                                                                            </div>     
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {this.state.errors.errUserToRemove.length > 0 &&
                                                                            <div className='addRemoveUserToNodeError'>{this.state.errors.errUserToRemove}</div>}
                                                                    </div>
                                                                    <div className="inputgroupCustom">
                                                                        <div className="inputgroupCustom">
                                                                            <label className="addCustFormLabelWithRequiredFiled">
                                                                                Users To Be Removed:
                                                                            </label>
                                                                            <div className="addRemoveUserInputField">
                                                                                <select size="3" 
                                                                                        style={{width:"100%", borderRadius:"5px", 
                                                                                                border:"1px solid var(--primaryColor)", color:"black" 
                                                                                        }}
                                                                                >
                                                                                    {(this.state.arrUserIdToBeRemoved).map((addedPvg,index) => <option key={index} value={addedPvg}>{addedPvg}</option>)}
                                                                                </select>
                                                                            </div>     
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div style={{display: "flex", justifyContent: "center"}}>
                                                                    <button type="button" 
                                                                            className="addCustSavebtn"
                                                                            style={{marginTop:"0rem", marginBottom:"0rem", 
                                                                                    display: (this.state.arrUserIdToBeRemoved != null && this.state.arrUserIdToBeRemoved.length > 0) ? "block" : "none"}} 
                                                                            onClick={this.onClickRemoveSelectedUsers}
                                                                    >
                                                                        Remove Selected Users
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: (this.state.displayBy == "searchUserEmailId" || this.state.activeTab == 'selectByRemoveUser') ? "block" : "none"}}>
                                                            {this.state.errors.noUserAdded.length > 0 &&
                                                                <div className='addRemoveUserToNodeError'>{this.state.errors.noUserAdded}</div>}
                                                        </div>
                                                    </Col> 
                                                </Row>
                                                </TabPane>
                                            </TabContent>
                                        </div>    
                                    </div>

                                    <div>
                                        <span>
                                            <button type="button" 
                                                    className="addCustSavebtn"
                                                    onClick={this.onCloseAddUserPopup}
                                                    style={{width:"8rem", marginTop:"0rem"}}
                                            > 
                                                    {t(IDS_Close)} 
                                            </button>
                                        </span>
                                    </div>
                               </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
VcAddRemoveUserToTheNode.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcAddRemoveUserToTheNode


// User will receive notifications for the devices which are under the selected tree node and associated with a selected organisation.
// User will stop receiving notifications for the devices which are under the selected tree node.
