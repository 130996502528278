import React, { Component } from 'react';
import { Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import { AppRelevantDataContext} from '../../AppContext';
import { getAPIHostURL } from '../../ClientConfig';
// import {QrReader} from '@otterscan/react-qr-reader';
import {QrScanner} from '@yudiel/react-qr-scanner';
import { FaQrcode } from 'react-icons/fa';
import {ARR_SENSORS_WITH_SCANNED_VALUES, SENSOR_SERIAL_NO, SENSOR_SENSITIVITY_CODE, SENSOR_MANUFACTURING_DATE, PCB_ID,
        SPEC_SENSOR_EXPECTED_LENGTH, FIGA_SENSOR_EXPECTED_LENGTH} from '../../VcConstants';
//     101915010903  100105 H2S 1510 2.78
// 101915010904      123104   SO2 2010 07.78
// 101915010905  145102 CO 2010 34.78
// 101915010906  147103 O3 1902 12.78
// 101915010907  140105 H2S 1506 02.00
// xxxxYYMMDDnnnnnnnnnnnnnnnnnn
//      4.56200205A20099009399399999     
// 9.03YYMMDDA200990093993938236
// 2.34190330A200990093993312321

export class VcProductItemImpParts extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            LoggedInUserID: "",
            DeviceID:"",
            selectedPcbName:"",
            selectedPcbVersion:"",
            selectedPcbDiscription:"",
            modal: false,
            backdrop: 'static',
            ArrPcbNameAndVer: [],
            ArrPcbName:[],
            ArrPcbVer:[],
            enableEditPcbInfo: false,
            alreadyAssembledPcbID: null,
            alreadyAssembledPcbName: "",
            alreadyAssembledPcbVers: "",
            alreadyAssembledPcbDescription: "",
            arrScannedProductItemAndImpParts: [],
            singleScannedSensorVal:"",
            selectedSensor:"",
            scannedSensor:"",
            selectedSensorSerialNo: "",
            selectedSensorSensitivityCode: "",
            selectedSensorManufacturedDate: "",
            error: "",
            sensorScanningErr:"",
            deviceExistenceErr:"",
            alreadyStoredSensorInfo: [],
            enableSettingProductImpPart: false,
            DevcIDQRCodeFlag: false,
            ScanSensorQRCodeFlag: false,
                    
        }
    }

    onChangeDeviceID = (e) => {
        let modifiedState = this.state;
        modifiedState.DeviceID = e.target.value;

        // set all fields to blank as it will not give a wrong values if user change deviceID.
        modifiedState.deviceExistenceErr = "";
        modifiedState.error = "";
        modifiedState.enableSettingProductImpPart = false;
        modifiedState.selectedPcbName ="";
        modifiedState.selectedPcbVersion ="";
        modifiedState.selectedPcbDiscription = "";
        modifiedState.alreadyAssembledPcbName = "";
        modifiedState.enableEditPcbInfo = false;
        modifiedState.alreadyAssembledPcbID = null;
        modifiedState.alreadyAssembledPcbName = "";
        modifiedState.alreadyAssembledPcbDescription = "";
        modifiedState.alreadyAssembledPcbVers = "";
        modifiedState.ArrPcbNameAndVer= [];
        modifiedState.ArrPcbName= [];
        modifiedState.ArrPcbVer= [];
        modifiedState.arrScannedProductItemAndImpParts = [];
        modifiedState.DevcIDQRCodeFlag = false;

        this.setState(modifiedState);
    }

    getProductItemImpParts = (inModifiedState = null) => {

        let modifiedState;

        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }
        modifiedState.ArrPcbNameAndVer = [];
        modifiedState.ArrPcbName = [];
        modifiedState.arrScannedProductItemAndImpParts = [];
        modifiedState.alreadyStoredSensorInfo = [];
        
        let jsonParams = {
            DeviceID: modifiedState.DeviceID
        }

        axios.post(`${getAPIHostURL()}/wclient/getProductItemImpParts`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {
                if( response.data["modelInfo"] == null || response.data["modelInfo"].length <=0 ||
                    response.data["ArrPcbNameAndVer"] == null || response.data["ArrPcbNameAndVer"].length <=0
                ) {
                    modifiedState.error = 'Device Model or PCB Information is not present on Server.'

                } else {
                    // Store Pcb info.
                    modifiedState.ArrPcbNameAndVer = response.data["ArrPcbNameAndVer"];

                    // seperate out distinct PCB name.
                    for(let i=0; i<modifiedState.ArrPcbNameAndVer.length; i++) {
                        if(!modifiedState.ArrPcbName.includes(modifiedState.ArrPcbNameAndVer[i]["PcbName"])) { 
                            modifiedState.ArrPcbName.push(modifiedState.ArrPcbNameAndVer[i]["PcbName"]);
                        }
                    }

                    let receivedScannedProductItemAndImpParts = response.data["ScannedProductItemAndImpParts"];
                    let receivedModelInfo = response.data["modelInfo"];

                    try {
                        // scanned Device model's sensor seq.
                        let receivedModelInfoMeasuredParams = JSON.parse(receivedModelInfo[0]["MeasuredParams"]);
                        let receivedModelInfoSensorSeq = receivedModelInfoMeasuredParams["Seq"];

                        let sensorPartsAndInfo = 
                                receivedScannedProductItemAndImpParts.length <=0 ? [] : JSON.parse(receivedScannedProductItemAndImpParts[0]["parts"]);

                        // Check where scanned deviceID has sensor to scan.
                        let sensorHavingScannigInfo = receivedModelInfoSensorSeq.filter(sensor=> ARR_SENSORS_WITH_SCANNED_VALUES.includes(sensor));

                        // This is the case where first device is used and found to be faulty hence sensor sacnned info will be present.
                        modifiedState.alreadyStoredSensorInfo = sensorPartsAndInfo;

                        // Crearting json object for the sensor who has scannned values.
                        if(sensorHavingScannigInfo.length > 0) {

                            for(let i=0; i<sensorHavingScannigInfo.length; i++) {

                                // Both cases where sensor having scanning info is present or not.
                                let storedSensorInfo = sensorPartsAndInfo.hasOwnProperty([sensorHavingScannigInfo[i]]) == true ?
                                                        sensorPartsAndInfo[sensorHavingScannigInfo[i]]: "";

                                let objScanningParams = {
                                    [SENSOR_SERIAL_NO]: storedSensorInfo.length <=0 ? "" : storedSensorInfo[SENSOR_SERIAL_NO],
                                    [SENSOR_SENSITIVITY_CODE]: storedSensorInfo.length <=0 ? "" : storedSensorInfo[SENSOR_SENSITIVITY_CODE],
                                    [SENSOR_MANUFACTURING_DATE]: storedSensorInfo.length <=0 ? "" : storedSensorInfo[SENSOR_MANUFACTURING_DATE],
                                }

                                console.log("innnerObj: ", objScanningParams);

                                let singleSensorStoredInfo = {
                                    [sensorHavingScannigInfo[i]]: objScanningParams
                                }

                                modifiedState.arrScannedProductItemAndImpParts.push(singleSensorStoredInfo);
                            }
                        }

                        // Checking where Device is already having pcb info.
                        // if device has pcb info show him edit button to edit pcb.
                        if( sensorPartsAndInfo.hasOwnProperty(PCB_ID) &&
                            sensorPartsAndInfo[PCB_ID] != null
                        ) {
                            // A case where Pcb Infoe is alredy stored.
                            // Extract that pcb id and get it corresponding PCB info.
                            let assembledPcbID = sensorPartsAndInfo[PCB_ID];
                            let assembledPcbInfo = modifiedState.ArrPcbNameAndVer.filter((singlePcbInfo) => singlePcbInfo[PCB_ID] == assembledPcbID);

                            modifiedState.alreadyAssembledPcbID = assembledPcbID;
                            modifiedState.alreadyAssembledPcbName = assembledPcbInfo[0]["PcbName"];
                            modifiedState.alreadyAssembledPcbVers = assembledPcbInfo[0]["PcbVersion"];
                            modifiedState.alreadyAssembledPcbDescription = assembledPcbInfo[0]["PcbDescription"];
                            modifiedState.enableEditPcbInfo = false;
                        } else {
                            // case where device is newly made where we have to assemble pcb for it.
                            // hence show a user pcb info dropdown.
                            modifiedState.enableEditPcbInfo = true;
                        }
                    } catch(e) {
                        console.error(`Should not happen. The data obtained from server for DeviceID ${modifiedState.DeviceID} is in invalid JSON format.`)
                    }
                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                }
            }

            this.setState(modifiedState);
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    onChangePcbName = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedPcbName = e.target.value;
        modifiedState.ArrPcbVer = [];
        modifiedState.selectedPcbVersion = "";
        modifiedState.selectedPcbDiscription = "";

        // Filtering those elemets from array which having selected software name.
        let filteredArrVersForSelectedPcbName = modifiedState.ArrPcbNameAndVer.filter((singlePcb) => singlePcb["PcbName"] == modifiedState.selectedPcbName);

        for(let i=0; i<filteredArrVersForSelectedPcbName.length; i++) {
            let versionAndPcbIDObj = {
                PcbVersion: filteredArrVersForSelectedPcbName[i]["PcbVersion"],
                PcbID: filteredArrVersForSelectedPcbName[i]["PcbID"],
            }
            modifiedState.ArrPcbVer.push(versionAndPcbIDObj);
        }

        // Sort the array of object in Descending order as we want to show latest version on the top.
        for (let i = 0; i < modifiedState.ArrPcbVer.length; i++) {     
            for (let j = i+1; j < modifiedState.ArrPcbVer.length; j++) {  
                if(modifiedState.ArrPcbVer[i]["PcbID"] < modifiedState.ArrPcbVer[j]["PcbID"]) {    
                    let temp = modifiedState.ArrPcbVer[i];    
                    modifiedState.ArrPcbVer[i] = modifiedState.ArrPcbVer[j];    
                    modifiedState.ArrPcbVer[j] = temp;    
                }     
            }     
        }    
        this.setState(modifiedState);
    }

    onChangePcbVersion = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedPcbVersion = e.target.value;

        // Filtering those elemets from array which having selected Pcb Version and name.
        let filteredArrPcbNameVers =
                modifiedState.ArrPcbNameAndVer.filter((singlePcb) => 
                                                        singlePcb["PcbName"] == modifiedState.selectedPcbName &&
                                                        singlePcb["PcbVersion"] == modifiedState.selectedPcbVersion 
                                                    );

        modifiedState.selectedPcbDiscription = filteredArrPcbNameVers[0]["PcbDescription"];

        this.setState(modifiedState);

    }

    onEditSensorInfo = (e) => {

        let modifiedState = this.state;

        // set explicilty all sensor sccenned values to blank.
        modifiedState.selectedSensorSerialNo = "";
        modifiedState.selectedSensorSensitivityCode = "";
        modifiedState.selectedSensorManufacturedDate = "";
        modifiedState.scannedSensor = "";
        modifiedState.singleScannedSensorVal = "";
        modifiedState.sensorScanningErr = "";
        modifiedState.modal = true;
        modifiedState.selectedSensor = e.target.getAttribute('clicked-sensor');
        this.setState(modifiedState);
    }
    
    toggle = () => {
        let modifiedState = this.state;
        modifiedState.modal = false;
        this.setState(modifiedState);

    }

    onClickEditOption = (e) => {

        let modifiedState = this.state;
        let selectedSensor = modifiedState.selectedSensor;
        console.log(modifiedState.singleScannedSensorVal);

        if(modifiedState.singleScannedSensorVal.length <= 0) {
            modifiedState.sensorScanningErr = "Please Scan a Selected scanner."

            // this.setState(modifiedState);
        } else {

            // this is the case where scanned sensor having its param name.
            if(modifiedState.scannedSensor.length > 0 && modifiedState.scannedSensor != modifiedState.selectedSensor) {
                modifiedState.sensorScanningErr = `The sensor scanned by you is '${modifiedState.scannedSensor}' which is not same as selected sensor '${modifiedState.selectedSensor}'.`;
                // this.setState(modifiedState);
            } else {

                if(modifiedState.selectedSensorSerialNo == null || modifiedState.selectedSensorSerialNo.length <= 0 || 
                    modifiedState.selectedSensorSensitivityCode == null || modifiedState.selectedSensorSensitivityCode.length <= 0 ||
                    modifiedState.selectedSensorManufacturedDate == null || modifiedState.selectedSensorManufacturedDate.length <= 0 
                ) {
                    modifiedState.sensorScanningErr = "Sensor scanning information is missing.";
                    this.setState(modifiedState);
                    return;
                } 

                let strMsg = `Are you sure you want to update sensor information for sensor '${modifiedState.selectedSensor}'?`;
                
                if(window.confirm(strMsg)) {

                    // fill Json object values with its corresponding scanned values.
                    for(let i =0; i<modifiedState.arrScannedProductItemAndImpParts.length; i++) {
                        if(Object.keys(modifiedState.arrScannedProductItemAndImpParts[i]) == selectedSensor) {

                            modifiedState.arrScannedProductItemAndImpParts[i][selectedSensor][SENSOR_SERIAL_NO] = modifiedState.selectedSensorSerialNo;
                            modifiedState.arrScannedProductItemAndImpParts[i][selectedSensor][SENSOR_SENSITIVITY_CODE] = modifiedState.selectedSensorSensitivityCode;
                            modifiedState.arrScannedProductItemAndImpParts[i][selectedSensor][SENSOR_MANUFACTURING_DATE] = modifiedState.selectedSensorManufacturedDate;
                            break;
                        }
                    }  

                    // modifiedState.selectedSensorSerialNo = "";
                    // modifiedState.selectedSensorSensitivityCode = "";
                    // modifiedState.selectedSensorManufacturedDate = "";
                    // modifiedState.scannedSensor = "";
                    modifiedState.modal = false;
                } 
            }
        }

        modifiedState.selectedSensorSerialNo = "";
        modifiedState.selectedSensorSensitivityCode = "";
        modifiedState.selectedSensorManufacturedDate = "";
        modifiedState.scannedSensor = "";

        this.setState(modifiedState);

    }

    handleKeyDown = (e) => {
        if (e.key == 'Enter') {
            // This is just to prevent form submission on pressing "enter".
            e.preventDefault();
            return;
        }
    }

    onEnterDeviceSensor = (e) =>{
        let modifiedState = this.state
        modifiedState.singleScannedSensorVal = e.target.value;
        modifiedState.ScanSensorQRCodeFlag = false;
        this.onScanningDeviceSensor(modifiedState)
    }

    onScanningDeviceSensor = (inModifiedState = null) => {

        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = this.state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.sensorScanningErr = "";

        modifiedState.selectedSensorSensitivityCode = "";
        modifiedState.selectedSensorManufacturedDate = "";
        modifiedState.selectedSensorSerialNo = "";
        modifiedState.scannedSensor = "";
        let arrScannedSensorValues;

        // remove extra white spaces.
        modifiedState.singleScannedSensorVal = modifiedState.singleScannedSensorVal.trim();

        // split it on whitespaces.
        arrScannedSensorValues = modifiedState.singleScannedSensorVal.split(/(\s+)/).filter( word => word.trim().length > 0);

        console.log(arrScannedSensorValues);

        if(arrScannedSensorValues != null && arrScannedSensorValues.length > 1) {
            
            // a SPEC sensor as it retuns 5 values after scanning. 
            if(arrScannedSensorValues.length == SPEC_SENSOR_EXPECTED_LENGTH && arrScannedSensorValues[3].length >= 4) {
                modifiedState.selectedSensorSerialNo = arrScannedSensorValues[0] + "-" + arrScannedSensorValues[1];
                modifiedState.selectedSensorSensitivityCode = arrScannedSensorValues[4];
                modifiedState.selectedSensorManufacturedDate = (arrScannedSensorValues[3].length == 4) ? (arrScannedSensorValues[3] + "01") : arrScannedSensorValues[3];
                modifiedState.scannedSensor = arrScannedSensorValues[2];
            } else {
                modifiedState.sensorScanningErr = "Scanning Information is not in appropriate format for SPEC sensor.";
            }

        } else {

            // Assumed that Figa sensor has only one string.
            if( arrScannedSensorValues != null && 
                arrScannedSensorValues.length > 0 && 
                arrScannedSensorValues[0].length == FIGA_SENSOR_EXPECTED_LENGTH
            ) {
                // a FIGA sensor as it returns 3 values after scanning.
                modifiedState.selectedSensorSensitivityCode = modifiedState.singleScannedSensorVal.slice(0,4);
                modifiedState.selectedSensorManufacturedDate = modifiedState.singleScannedSensorVal.slice(4,10);
                modifiedState.selectedSensorSerialNo = modifiedState.singleScannedSensorVal.slice(10);
            } else {
                modifiedState.sensorScanningErr = "Scanning Information is not in appropriate format for FIGA sensor.";
            }
          
        }
        this.setState(modifiedState);
    }

    openQRCameraForDevcIDOfPrdItmImpPrts = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 

        this.setState(prevState => {
            let modifiedState = prevState;

            modifiedState.DevcIDQRCodeFlag = !modifiedState.DevcIDQRCodeFlag;

            // set all fields to blank as it will not give a wrong values if user change deviceID.
            modifiedState.DeviceID = "";
            modifiedState.deviceExistenceErr = "";
            modifiedState.error = "";
            modifiedState.enableSettingProductImpPart = false;
            modifiedState.selectedPcbName ="";
            modifiedState.selectedPcbVersion ="";
            modifiedState.selectedPcbDiscription = "";
            modifiedState.alreadyAssembledPcbName = "";
            modifiedState.enableEditPcbInfo = false;
            modifiedState.alreadyAssembledPcbID = null;
            modifiedState.alreadyAssembledPcbName = "";
            modifiedState.alreadyAssembledPcbDescription = "";
            modifiedState.alreadyAssembledPcbVers = "";
            modifiedState.ArrPcbNameAndVer= [];
            modifiedState.ArrPcbName= [];
            modifiedState.ArrPcbVer= [];
            modifiedState.arrScannedProductItemAndImpParts = [];
            return modifiedState
        })
    } 

    handleScanResultOfPrdItmImpPrtsDevcID = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.DeviceID = data;
            if((modifiedState.DeviceID != null && modifiedState.DeviceID.length > 0)) {
                modifiedState.DevcIDQRCodeFlag = false;
                this.setState(modifiedState);
                // this.verifyEnteredDeviceID(modifiedState);
            } else {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
                this.setState(modifiedState);
            }
        }
    }

    openQRCameraForSensorOfPrdItmImpPrts = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 
        
        this.setState(prevState => {
            let modifiedState = prevState;

            modifiedState.ScanSensorQRCodeFlag = !modifiedState.ScanSensorQRCodeFlag;
            modifiedState.singleScannedSensorVal = "";
            // modifiedState.errors.others = "";
            // modifiedState.selectedPosition = 1;
            return modifiedState
        })
    } 

    handleScanResultOfPrdItmImpPrtsSensor = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.singleScannedSensorVal = data;
            if((modifiedState.singleScannedSensorVal != null && modifiedState.singleScannedSensorVal.length > 0)) {
                modifiedState.ScanSensorQRCodeFlag = false;
                this.onScanningDeviceSensor(modifiedState);
            } else {
                modifiedState.errors.others = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
                this.setState(modifiedState);
            }
        }
    }

    handleQRCodeError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        let modifiedState = this.state;
        let appRelevantDataContextValue = this.context;

        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let strScannedProductItemAndImpParts = "";

        try { 

            console.log(modifiedState.arrScannedProductItemAndImpParts.length)

            // if a sensor scanning info is present.
            if(modifiedState.arrScannedProductItemAndImpParts.length > 0 ) {
                console.log(modifiedState.arrScannedProductItemAndImpParts[0]);

                let sensorName = Object.keys(modifiedState.arrScannedProductItemAndImpParts[0]);

                // check whether all sensor having scanning information is scanned properly.
                if(modifiedState.arrScannedProductItemAndImpParts[0][sensorName][SENSOR_SERIAL_NO].length <= 0 ||
                    modifiedState.arrScannedProductItemAndImpParts[0][sensorName][SENSOR_SERIAL_NO].length <= 0 ||
                    modifiedState.arrScannedProductItemAndImpParts[0][sensorName][SENSOR_SERIAL_NO].length <= 0 
                ) {
                    // console.log('sensor Scanning Information is missing for sensor ');
                    modifiedState.error = `Sensor Scanning Information is missing for sensor '${sensorName}'.`
                    strScannedProductItemAndImpParts = "";
                    this.setState(modifiedState);
                    return;
                } 
            
                // construct JSON string.
                strScannedProductItemAndImpParts = '"' + sensorName + '"' + ":" + JSON.stringify(modifiedState.arrScannedProductItemAndImpParts[0][sensorName]);
    
                for(let i =1; i<modifiedState.arrScannedProductItemAndImpParts.length; i++) {
                    let sensorName = Object.keys(modifiedState.arrScannedProductItemAndImpParts[i]);

                    // check whether all sensor having scanning information is scanned properly.
                    if(modifiedState.arrScannedProductItemAndImpParts[i][sensorName][SENSOR_SERIAL_NO].length <= 0 ||
                        modifiedState.arrScannedProductItemAndImpParts[i][sensorName][SENSOR_SERIAL_NO].length <= 0 ||
                        modifiedState.arrScannedProductItemAndImpParts[i][sensorName][SENSOR_SERIAL_NO].length <= 0 
                    ) {
                        // console.log('sensor Scanning Information is missing for sensor ');
                        modifiedState.error = `Sensor Scanning Information is missing for sensor '${sensorName}'.`
                        strScannedProductItemAndImpParts = "";
                        this.setState(modifiedState);
                        return;
                    } 

                    strScannedProductItemAndImpParts = strScannedProductItemAndImpParts +  "," + '"' + sensorName + '"'  + ":" + JSON.stringify(modifiedState.arrScannedProductItemAndImpParts[i][sensorName]);                
                }
            }

            // Add PcbIfo too in same json.
            let PcbIDAgainstSelectedPcbInfo;
            
            if(modifiedState.enableEditPcbInfo == true && 
                modifiedState.selectedPcbName.length > 0 && 
                modifiedState.selectedPcbVersion.length > 0
            ) {

                // If a user change the pcb board for faluty device or else this is the 1st tym of assembling PCB for newly device.
                let filteredPcbinfoAgainstSelectedPcbInfo =
                    modifiedState.ArrPcbNameAndVer.filter((singlePcbInfo) => singlePcbInfo["PcbName"] == modifiedState.selectedPcbName &&   
                                                                             singlePcbInfo["PcbVersion"] == modifiedState.selectedPcbVersion  
                                                        )
                PcbIDAgainstSelectedPcbInfo = filteredPcbinfoAgainstSelectedPcbInfo[0]["PcbID"];
            } else {

                // case where user want to use previously assembled board.
                PcbIDAgainstSelectedPcbInfo = modifiedState.alreadyStoredSensorInfo["PcbID"];
            }

            // both cases where sensor scanning info is present or not  if present construct it with comma.
            strScannedProductItemAndImpParts = strScannedProductItemAndImpParts +  
                                                    (modifiedState.arrScannedProductItemAndImpParts.length > 0 ? ",": "")
                                                        + '"' + "PcbID" + '"' + ":" + PcbIDAgainstSelectedPcbInfo;

            strScannedProductItemAndImpParts = "{" + strScannedProductItemAndImpParts + "}";
            console.log("strScannedProductItemAndImpParts", strScannedProductItemAndImpParts);


        } catch(e) {
            console.log("Should not happen. Scanned sensor info is in invalid Json format.");
        }

        let jsonParams = {
            DeviceID: modifiedState.DeviceID,
            LoggedInUserID: modifiedState.LoggedInUserID,
            strScannedProductItemAndImpParts: strScannedProductItemAndImpParts
        }

        axios.post(`${getAPIHostURL()}/wclient/setProductItemImpParts`, jsonParams)
        .then(response => {
            if(response.data.code == 'SUCCESS') {

                alert("Scanned sensor information is stored successfully.");

                modifiedState.DeviceID = "";
                modifiedState.error = "";
                modifiedState.selectedPcbName ="";
                modifiedState.selectedPcbVersion ="";
                modifiedState.selectedPcbDiscription = "";
                modifiedState.alreadyAssembledPcbName = "";
                modifiedState.enableEditPcbInfo = false;
                modifiedState.enableSettingProductImpPart = false;
                modifiedState.alreadyAssembledPcbID = null;
                modifiedState.alreadyAssembledPcbName = "";
                modifiedState.alreadyAssembledPcbDescription = "";
                modifiedState.alreadyAssembledPcbVers = "";
                modifiedState.ArrPcbNameAndVer= [];
                modifiedState.ArrPcbName= [];
                modifiedState.ArrPcbVer= [];
                modifiedState.arrScannedProductItemAndImpParts = [];
                // this.setState(modifiedState);

                
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                } else if(response.data.code == 'SERVER_EXPERIENCING_ISSUES') {
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                }
            }
            this.setState(modifiedState);
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.errors = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
    }

    onClickEditPcbInfo = () => {
        // let modifiedState = this.state;

        this.setState(prevState => ({
            enableEditPcbInfo: !prevState.enableEditPcbInfo
        }));
       
        // modifiedState.enableEditPcbInfo = !modifiedState.enableEditPcbInfo;
        // this.setState(modifiedState);
    }

    verifyEnteredDeviceID = () => {

        let modifiedState = this.state;

        if(modifiedState.DeviceID == null || modifiedState.DeviceID.length <=0 ) {
            modifiedState.deviceExistenceErr = 'Please enter DeviceID.';
            this.setState(modifiedState);
            return;
        }

        let jsonParams = {
            EnteredDeviceID: modifiedState.DeviceID
        }

        axios.post(`${getAPIHostURL()}/wclient/checkDeviceIdExistence`, jsonParams)
        .then(response => {
            console.log(response)
            if(response.data.code == 'SUCCESS') {

                if(response.data.isDeviceIDExist == null) {
                    modifiedState.deviceExistenceErr = "Server experiencing issues.\nTry again later.";
                    this.setState(modifiedState);

                } else {
                    if(response.data.isDeviceIDExist == 0) {
                        modifiedState.deviceExistenceErr = 'Invalid Device ID.';
                        console.log(modifiedState.deviceExistenceErr);
                        this.setState(modifiedState);

                    } else {
                        modifiedState.deviceExistenceErr = "";
                        modifiedState.enableSettingProductImpPart = true;

                        this.getProductItemImpParts(modifiedState);
                    }
                }
            } else {
                if (response.data.code == 'REQ_PARAMS_MISSING') {
                    // Let the user know that the DeviceOwnerEmailID or AdditionalUserEmailID was not received by the Server
                    modifiedState.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                } else if (response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.deviceExistenceErr = 'Server experiencing issues.\nTry again later.';
                    
                }
                this.setState(modifiedState);

            }
        })
        .catch( error => {

            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                // Tell the user that there are network issues
                modifiedState.deviceExistenceErr = 'Network issues.\nCheck your Internet and Try again later.';
                this.setState(modifiedState);
            }
        }); 
        
    }
    
    
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-lg-offset-2
                                              col-md-12">
                        <div className="modal-body p-4 box">
                            <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1rem"}}>Note Product Item Important Parts</div> 
                            <form onSubmit={this.onFormSubmit}>
                                <div>
                                    <label className="reg-form-label" 
                                        style={{width:"100%"}}
                                    >
                                        Enter Serial No / DeviceID of Product Or Scan the QR Code:
                                    </label>
                                    <input style={{width:"100%"}}
                                           className="input-form"
                                           value= {this.state.DeviceID}
                                           onKeyDown={this.handleKeyDown}
                                           onChange = {this.onChangeDeviceID}
                                           required
                                    />
                                    <label onClick = {this.openQRCameraForDevcIDOfPrdItmImpPrts} className = "qrcodeTextBtn">
                                        <FaQrcode className = "qrcodeTextBtnIcon"/>
                                    </label>
                                    <div>
                                        {(this.state.DevcIDQRCodeFlag == true)
                                        ?
                                            // <div style={{display: "flex", justifyContent: "center"}}>
                                            //     <QrReader
                                            //         scanDelay={300}
                                            //         onResult={(result, error) => {
                                            //             if (!!result) {
                                            //                 console.log("result = ",result);
                                            //                 this.handleScanResultOfPrdItmImpPrtsDevcID(result?.text);
                                            //             }
                                            //         }}
                                            //         className = "QRCodeCamBoxForModalAndContainer"
                                            //     />
                                            // </div>
                                            <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                        marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                        marginRight: "auto"}}>                                            
                                            <QrScanner
                                                scanDelay={300}
                                                onResult={(result, error) => {
                                                    if (!!result) {
                                                        console.log("result = ",result);
                                                        this.handleScanResultOfPrdItmImpPrtsDevcID(result?.text);
                                                    }
                                                }}
                                                className = "QRCodeCamBoxForModalAndContainer"
                                            />
                                        </div>
                                        :
                                            <div/>
                                        }
                                    </div>

                                    {this.state.enableSettingProductImpPart == false &&
                                        <div style={{display:"flex", justifyContent: "center", flexDirection:"column"}}>
                                            <button type={"button"}
                                                    style={{borderRadius: "5px", 
                                                            width:'40%', 
                                                            marginLeft:'30%',
                                                            marginTop:"0.5rem",
                                                            backgroundColor: "var(--primaryColor)", color:"white",
                                                            fontSize: "1.2rem", padding: "0.4rem"}}
                                                    className="btn-lg"
                                                    onClick={this.verifyEnteredDeviceID} 
                                            >
                                                Proceed
                                            </button>
                                            {this.state.deviceExistenceErr.length > 0 && 
                                                <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.deviceExistenceErr}</p>}  
                                        </div>
                                    }                          
                                </div>
                                {(this.state.enableSettingProductImpPart == true) && 
                                <div>
                                    <div>
                                        <label className="reg-form-label" 
                                            style={{width:"100%", marginTop:"1rem"}}
                                        >
                                            Scan Device Parts:
                                        </label>
                                        <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginBottom:"1rem", background:"white"}}>
                                            <div style={{boxSizing:"border-box", border: "1px solid #ABADB3", borderRadius:"0.5rem" , paddingTop:"0.5rem", paddingBottom:"0.5rem", paddingLeft:"1rem", paddingRight:"1rem", textAlign:"left", marginBottom:"1rem", background:"#F5F5F5"}}>
                                                {/* As of this is not required as a user can not change asembled pcb. */}
                                                {/* {this.state.alreadyAssembledPcbID != null && 
                                                    <button type={"button"}
                                                            style={{borderRadius: "5px", 
                                                                    width: this.state.enableEditPcbInfo ? '60%' : '40%', 
                                                                    marginLeft: this.state.enableEditPcbInfo ? '20%' : '30%',
                                                                    backgroundColor: "var(--primaryColor)", color:"white"}}
                                                            className="btn-md"
                                                            onClick={this.onClickEditPcbInfo} 
                                                    >
                                                        {this.state.enableEditPcbInfo ? 'Set it back to Default PCB Info' : 'Edit PCB Info'}
                                                        
                                                    </button> 
                                                }                                            */}
                                                
                                                <div>
                                                    <label className="reg-form-label" 
                                                            style={{width:"100%"}}
                                                    >
                                                        Select PCB Name:
                                                    </label>
                                                
                                                {(this.state.enableEditPcbInfo == true) ?
                                                        <select className="input-form" 
                                                                style={{width:"100%", background:"white"}} 
                                                                required
                                                                value={this.state.selectedPcbName}
                                                                onChange={this.onChangePcbName}

                                                        >
                                                            <option value="" select= "true" disabled>Select PCB Name...</option> 
                                                            {(this.state.ArrPcbName).map((singlePcbName, index) => <option key={index} value={singlePcbName}>{singlePcbName}</option>)}

                                                        </select> 
                                                        :
                                                        <input placeholder={"PCB Name..."}
                                                                value={this.state.alreadyAssembledPcbName}
                                                                className="input-form" 
                                                                style={{background:"#F5F5F5", width:"100%"}}
                                                                readOnly
                                                        />
                                                    }

                                                    <label className="reg-form-label" 
                                                            style={{width:"100%"}}
                                                    >
                                                        Select PCB Version:
                                                    </label>
                                                    {(this.state.enableEditPcbInfo == true) ?

                                                        <select className="input-form" 
                                                                style={{width:"100%", background:"white"}} 
                                                                required
                                                                value={this.state.selectedPcbVersion}
                                                                onChange={this.onChangePcbVersion}
                                                        >
                                                            <option value="" select= "true" disabled>Select PCB Version...</option> 
                                                            {(this.state.ArrPcbVer).map((singlePcbVers, index) => <option key={index} value={singlePcbVers["PcbVersion"]}>{singlePcbVers["PcbVersion"]}</option>)}

                                                        </select>
                                                        :
                                                            <input placeholder={"PCB Version..."}
                                                                    value={this.state.alreadyAssembledPcbVers}
                                                                    className="input-form" 
                                                                    style={{background:"#F5F5F5", width:"100%"}}
                                                                    readOnly
                                                            />
                                                    }
                                                    <label className="reg-form-label" 
                                                            style={{width:"100%"}}
                                                    >
                                                        PCB Description:
                                                    </label>
                                                    {(this.state.enableEditPcbInfo == true) ?
                                                        <input placeholder={"PCB Version..."}
                                                                value={this.state.selectedPcbDiscription}
                                                                className="input-form" 
                                                                style={{background:"#F5F5F5", width:"100%"}}
                                                                readOnly
                                                        />
                                                        :
                                                            <input placeholder={"PCB Version..."}
                                                                    value={this.state.alreadyAssembledPcbDescription}
                                                                    className="input-form" 
                                                                    style={{background:"#F5F5F5", width:"100%"}}
                                                                    readOnly
                                                            />
                                                        }

                                                </div>
                                            </div>
                                            {this.state.arrScannedProductItemAndImpParts.length > 0 &&
                                                <div style={{marginTop:"1rem", overflow:"scroll"}}>
                                                    <Table bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Parameter Sensed</th>
                                                                <th>Sensor Serial No</th>
                                                                <th>Sensitivity Code</th>
                                                                <th>Manufacturing Date (YYMMDD)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.arrScannedProductItemAndImpParts.map((singleSensor, index) => {
                                                            return (
                                                                <tr key={Object.keys(singleSensor)}>
                                                                    <td> 
                                                                        <div style={{display:"flex"}}>
                                                                            <div style={{flex:"5"}}>
                                                                                {Object.keys(singleSensor)} 
                                                                            </div>
                                                                            <div style={{flex:"1"}}>
                                                                                <button type={"button"}
                                                                                    onClick={this.onEditSensorInfo}
                                                                                    className="deviceTreeRefreshButton"
                                                                                    clicked-sensor={Object.keys(singleSensor)}
                                                                                >
                                                                                    <i className="fa fa-edit" onClick={this.onEditSensorInfo} clicked-sensor={Object.keys(singleSensor)}></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>                                                               
                                                                    </td>
                                                                    <td>{singleSensor[Object.keys(singleSensor)][SENSOR_SERIAL_NO]}</td>
                                                                    <td>{singleSensor[Object.keys(singleSensor)][SENSOR_SENSITIVITY_CODE]}</td>
                                                                    <td>{singleSensor[Object.keys(singleSensor)][SENSOR_MANUFACTURING_DATE]}</td>
                                                                </tr>
                                                            )
                                                        })}         

                                                        <Modal size="md" isOpen={this.state.modal} toggle={this.toggle} backdrop={this.state.backdrop}>
                                                            <ModalHeader toggle={this.toggle}>Scan '{this.state.selectedSensor}' Sensor on the Board to get its Details:</ModalHeader>
                                                            <ModalBody>   
                                                                    <div>
                                                                        <label style={{width:"100%"}}
                                                                            className="reg-form-label" 
                                                                        >
                                                                            Scan Sensor
                                                                        </label>
                                                                        <input style={{width:"100%", fontSize:"1.2rem", borderRadius:"0.3rem", border:"1px solid var(--primaryColor)"}}
                                                                                onChange= {this.onEnterDeviceSensor}
                                                                                onKeyDown={this.handleKeyDown}
                                                                                value = {this.state.singleScannedSensorVal}
                                                                                required
                                                                        />
                                                                        <label onClick= {this.openQRCameraForSensorOfPrdItmImpPrts} className = "qrcodeTextBtn">
                                                                            <FaQrcode className = "qrcodeTextBtnIcon"/>
                                                                        </label>
                                                                        <div>
                                                                            {(this.state.ScanSensorQRCodeFlag == true)
                                                                            ?
                                                                                // <div style={{display: "flex", justifyContent: "center"}}>
                                                                                //     <QrReader
                                                                                //         scanDelay={300}
                                                                                //         onResult={(result, error) => {
                                                                                //             if (!!result) {
                                                                                //                 console.log("result = ",result);
                                                                                //                 this.handleScanResultOfPrdItmImpPrtsSensor(result?.text);
                                                                                //             }
                                                                                //         }}
                                                                                //         className= "QRCodeCamBoxForModalAndContainer"
                                                                                //     />
                                                                                // </div>

                                                                                <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                                                    marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                                                    marginRight: "auto"}}>                                                                                    
                                                                                    <QrScanner
                                                                                        scanDelay={300}
                                                                                        onResult={(result, error) => {
                                                                                            if (!!result) {
                                                                                                console.log("result = ",result);
                                                                                                this.handleScanResultOfPrdItmImpPrtsSensor(result?.text);
                                                                                            }
                                                                                        }}
                                                                                        className= "QRCodeCamBoxForModalAndContainer"
                                                                                    />
                                                                                </div>
                                                                            :
                                                                                <div/>
                                                                            }
                                                                        </div>
                                                                        <label style={{width:"100%"}}
                                                                            className="reg-form-label" 
                                                                        >
                                                                            Sensor Serial No
                                                                        </label>
                                                                        <input style={{width:"100%", fontSize:"1.4rem", borderRadius:"0.3rem", border:"1px solid var(--primaryColor)", background:"#F5F5F5"}}
                                                                                value={this.state.selectedSensorSerialNo}
                                                                                readOnly
                                                                        />
                                                                        <label style={{width:"100%"}}
                                                                            className="reg-form-label" 
                                                                        >
                                                                            Sensitivity Code
                                                                        </label>
                                                                        <input style={{width:"100%", fontSize:"1.4rem", borderRadius:"0.3rem", border:"1px solid var(--primaryColor)", background:"#F5F5F5"}}
                                                                                value={this.state.selectedSensorSensitivityCode}
                                                                                readOnly
                                                                        />
                                                                        <label style={{width:"100%"}}
                                                                            className="reg-form-label" 
                                                                        >
                                                                            Manufacturing Date (YYMMDD):
                                                                        </label>
                                                                        <input style={{width:"100%", fontSize:"1.4rem", borderRadius:"0.3rem", border:"1px solid var(--primaryColor)", background:"#F5F5F5"}}
                                                                                value = {this.state.selectedSensorManufacturedDate}
                                                                                readOnly
                                                                        />
                                                                        <div style={{display:"flex", justifyContent: "center" ,flexDirection:"column"}}>
                                                                        <button
                                                                                onClick = {this.onClickEditOption}
                                                                                style={{borderRadius: "5px", width:"30%", marginTop:"1rem",
                                                                                        backgroundColor: "var(--primaryColor)", color:"white",
                                                                                        marginLeft:"35%"
                                                                                    }}
                                                                                className="btn-lg"
                                                                        >
                                                                            Save
                                                                        </button>
                                                                        {this.state.sensorScanningErr.length > 0 && 
                                                                            <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center", marginTop:"0.5rem"}} className='error'>{this.state.sensorScanningErr}</p>}
                                                                        </div>
                                                                    </div>
                                                            </ModalBody>
                                                        </Modal>    
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                        <button style={{borderRadius: "5px", width:"30%", marginTop:"1rem",
                                                        marginLeft:"35%",
                                                        backgroundColor: "var(--primaryColor)", color:"white",
                                                    }}
                                                className="btn-lg"
                                        >
                                            Save
                                        </button>
                                        {this.state.error.length > 0 && 
                                            <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.error}</p>} 
                                    </div>                           
                                </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}
VcProductItemImpParts.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcProductItemImpParts;
