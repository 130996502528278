import React, { Component } from 'react'
import { Spinner } from 'reactstrap';
import { AppRelevantDataContext } from '../../AppContext';import { IDS_Close, IDS_Too_Many_Axios_Req } from '../../VcLanguage';
import { AXIOS_STOPPING_COUNTDOWN } from '../../VcConstants';

export class VcPopUpToBeShownOnExceedingAMaxAxiosReq extends Component {
    
    constructor(props) {
        super(props);

    this.state = { 
        notifyOnCloseForm: this.props.OnClosePopupToBeShownOnExceedingAMaxAxiosReq,
        time: {}, 
        breakRemainingSeconds: AXIOS_STOPPING_COUNTDOWN,
        showButton:false,
    };
        
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    // Let's make some sense of JS date and time It can get a little bit tricky sometimes.
    // So, what we're doing here is taking the values and converting it in hours minutes, seconds. 
    // In the example below we are using minutes and seconds, but just in case we got hours in there too :)

    createTime(secs){
      let hours = Math.floor(secs / (60 * 60));
      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);
      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);

        let timeObject = {
        "h": hours,
        "m": minutes,
        "s": seconds
        };
        return timeObject;
    }

    componentDidMount() {
        // Taking the starting point  -> breakRemainingSeconds <-
        // Passing it as the parameter and setting the state's time object to it.
        let timeLeft = this.createTime(this.state.breakRemainingSeconds);
        this.setState({ time: timeLeft });
        this.startTimer();
    }

    // Check the current state and potentially (if != 0) start our main function 
    startTimer() {
        if (this.timer == 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.breakRemainingSeconds - 1;
        this.setState({
            time: this.createTime(seconds),
            breakRemainingSeconds: seconds
        });
        
        // Check if we're at zero, and if so, clear the Interval
        if (seconds == 0) { 
            this.setState({
                showButton: true,
            })
            // Clearing interval.
            clearInterval(this.timer);
        }
    }

    onClosePopup = () => {
      this.state.notifyOnCloseForm();
    }

    render() {
        let appRelevantDataContextValue = this.context;
        let t = appRelevantDataContextValue.t;

        return (
            <div className="showPopUpForMaximumAxios">    
                <div className="container">
                    <div className="row">
                        <div className="container col-lg-8 col-lg-offset-2
                                                    col-md-6 col-md-offset-3">
                            <div className="mvAddScroll">
                            <div className="adduser-popup" style={{ marginLeft: "-4rem", marginRight: "-4rem", backgroundColor: "whitesmoke" }}>

                                {/* <div className="col-lg-12 col-lg-8 col-lg-8 sddSingleParam" style={{paddingTop:"1rem"}}> */}
                                <div className="col-lg-12 col-lg-8 col-lg-8 tooManyRequests" style={{paddingTop:"2rem"}}>
                                    <div className="parameterOuterDiv col-lg-10 col-lg-3 col-md-6">
                                        <div>
                                            <span className="ParamsValue" 
                                                  style={{fontWeight:"400", color:'var(--secondaryColor)'}}
                                            >
                                                {t(IDS_Too_Many_Axios_Req)}
                                            </span>
                                        </div>                  
                                    </div>
                                </div>
                                <div style={{ display:"flex", justifyContent: "center", alignItems: "center", paddingTop:"2rem" }}>
                                    <label style={{ paddingRight:"0.6rem", fontWeight: "bold" }}>Seconds:</label>
                                    <label style={{ fontWeight: "bold" }}>{this.state.time.s}</label>
                                </div>
                                {this.state.showButton == true?
                                    <button type="button" 
                                        style={{ marginBottom: "2rem" }}
                                        className="adduserButton btn-lg"
                                        onClick={this.onClosePopup}
                                    > 
                                    {t(IDS_Close)}
                                    </button>
                                    :
                                    <Spinner color="success" style={{marginTop: "0.8rem", marginBottom: "1.3rem", width: '3rem', height: '3rem'}}/>
                                }                                 

                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) 
    }
}

VcPopUpToBeShownOnExceedingAMaxAxiosReq.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcPopUpToBeShownOnExceedingAMaxAxiosReq;


