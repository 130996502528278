import React, { useState, useContext } from 'react';
import {useTable,  useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters, useExpanded}  from 'react-table';
import { AppRelevantDataContext} from '../../AppContext';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FaSearch} from 'react-icons/fa';
import { getAPIHostURL} from '../../ClientConfig';
import { convertLocalDateToDisplayToday, convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS} from '../../vtUtil';
import { IDS_TodaySearch} from '../../VcLanguage';
import { ITM_STATE_MAPPED_TO_OWNER, ITM_STATE_SOLD, ITM_STATE_DEMO, SET_BY_USER_INVOKED_FROM_DEVICE, ITM_STATE_RENTAL } from '../../VcConstants';
import { FaQrcode } from 'react-icons/fa'
// import {QrReader} from '@otterscan/react-qr-reader'
import {QrScanner} from '@yudiel/react-qr-scanner';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

const CSV_COLUMN_SEPERATOR = ',';
const CSV_LINE_SEPERATOR = '\r\n';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {  
    return (
        <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                 value={filterValue || ''}
                     onChange={e => {
                       setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                     }}
                placeholder= "Search"
                style={{fontSize:"0.9rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    )
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data, renderBasedOnTable, defaultPageSize, getCellProps = defaultPropGetter, passedStateVariables, onExpendedViewClick}) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
          text: (rows, id, filterValue) => {
            return rows.filter(row => {
              const rowValue = row.values[id]
              return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
          },
        }),
        []
    )

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        visibleColumns,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: {
                // set page size based on tables
                pageSize: renderBasedOnTable == "calibHistExpandColTable" ? 
                    defaultPageSize : renderBasedOnTable  == "DeviceStateInfoColumnsTable" ? 
                    5 : (renderBasedOnTable  == "calibDetailsColumnsTable" || renderBasedOnTable == "CalibHistColTable") ? 
                    10 : 0
                },
            filterTypes,
            // whichever function called from react table which is inside main function
            // should be passed to react table and added in useTable
            onExpendedViewClick
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
    );

     //uses the table header group props for the empty rows so resizing and flex layout still works
     const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp; 
                        {renderBasedOnTable == "calibHistExpandColTable" && !passedStateVariables ? "No Data Found" :
                        renderBasedOnTable == "DeviceStateInfoColumnsTable" && !passedStateVariables ? "No Device State Information Found." : 
                        renderBasedOnTable == "calibDetailsColumnsTable" && !passedStateVariables ? "No Parameter Calibrated for this Device." : 
                        renderBasedOnTable == "CalibHistColTable" && !passedStateVariables ? "No Calibration History found for selected parameter" : 
                        ""}
                    </span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table  {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trforHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header' style = {{color: renderBasedOnTable == "calibHistExpandColTable" ? "darkorange" : ""}}>
                                    {column.render('Header') }
                                    <div className='fa' >
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FaArrowDownLong />
                                            : <FaArrowUpLong />
                                        : ''}
                                    </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead hidden = {renderBasedOnTable == "calibHistExpandColTable" ? true : false}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <React.Fragment {...row.getRowProps()}>
                                    <tr {...row.getRowProps(getRowProps(row))}
                                        className = "tr"
                                    >
                                        {row.cells.map(cell => {
                                        return <td {...cell.getCellProps(
                                                [
                                                    {
                                                    style: cell.column.style,
                                                    },
                                                    getCellProps(cell),
                                                ]
                                            )} className="td"
                                            >{cell.render("Cell")}                                      
                                            </td>
                                        
                                        })}
                                    </tr>
                                    {row.isExpanded ? (
                                        <tr style ={{backgroundColor: "white"}}>
                                            <td colSpan={visibleColumns.length}>
                                                {onExpendedViewClick(row)}
                                            </td>
                                        </tr>
                                    ) : null}
                                </React.Fragment>
                            );
                        }) 
                        // ||
                        //     // when there is no data found 
                        //     <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex" ,justifyContent:"left"}}>
                        //                 {renderBasedOnTable == "calibHistExpandColTable" && !passedStateVariables ? "No Data Found" :
                        //                     renderBasedOnTable == "DeviceStateInfoColumnsTable" && !passedStateVariables ? "No Device State Information Found." : 
                        //                     renderBasedOnTable == "calibDetailsColumnsTable" && !passedStateVariables ? "No Parameter Calibrated for this Device." : 
                        //                     renderBasedOnTable == "CalibHistColTable" && !passedStateVariables ? "No Calibration History found for selected parameter" : 
                        //                     ""}
                        //             </span>
                        //         </td>
                        //     </tr>                             
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div hidden = {renderBasedOnTable == "calibHistExpandColTable" ? true : false}>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

function VcDeviceStateHistory (props) {
    const csvLink = React.createRef();
    // const textFile = null;

    const filterCaseInsensitive = (rows, id, filterValue) => {

        if (id == "DateTime" || id == "calibDateTime") {

            return rows.filter(row => {
                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id];                    
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        } else {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
            });
        }
    };
    const context = useContext(AppRelevantDataContext);
    const [state, setState] = useState({
        backdrop: 'static',
        DeviceHistoryTableModal: false,
        calibDetailsModal: false,
        calibParamHistModal: false,
        EnterDeviceID: '',
        DeviceIDForCsvFileName: '',
        DeviceStateHistoryInfo: [],
        calibDetailsTableData: [],
        csvData: [],
        rplcDevcQRCodeFlag: false,
        bDeviceStateHistoryViewDataModalOpen: false,
        showCalibDetailsInvokedByDevcMsg: false,
        showCalibHistInvokedByDevcMsg: false,
        paramCalibHistDataArr: [],
        expendedViewHistDataLength: [],
        calibDetailsSelectedRowIndex: "",
        calibDetailSelectedRowMessuredParam: "",
        textFile: null,
        DeviceStateInfoColumns:[
            {   
                Header:() => <div className="AlertLogTableHeader">Device ID</div>,  
                accessor: 'DeviceID',
                filter: filterCaseInsensitive,
                width: 200,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Device State</div>, 
                accessor: 'DeviceState',
                width: 250,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Date Time</div>, 
                accessor: 'DateTime',
                Cell: (props) => getCustomizedTodaysDate(props.value),
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 202,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Additional Info</div>,  
                accessor: 'AdditionalInfo',
                filter: filterCaseInsensitive,
                width: 260,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Remark</div>,  
                accessor: 'Comment',
                filter: filterCaseInsensitive,
                width: 260,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Executive EmailID</div>,  
                accessor: 'ExecutiveEmailID',
                filter: filterCaseInsensitive,
                width: 350,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",  
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"      
                }),
            },
        ],

        calibDetailsColumns:[
            {   
                Header:() => <div className="AlertLogTableHeader">Device ID</div>,  
                accessor: 'deviceID',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem" 
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Measured Param</div>, 
                accessor: 'measuredParam',
                width: 200,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem" 
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Parameter Calibration Done</div>,  
                accessor: 'finalParamCalibStatus',
                filter: filterCaseInsensitive,
                width: 250,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem" 
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Calibration Done By</div>,  
                accessor: 'calibrationDoneBy',
                filter: filterCaseInsensitive,
                width: 200,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem", 
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"        
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Calib Date Time</div>, 
                accessor: 'calibDateTime',
                Cell: (props) => getCustomizedTodaysDate(props.value),
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 300,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem" 
                }),
            },
        ],

        CalibHistCol:[
            {
                // Make an expander cell
                Header: () => null, // No header
                id: 'expander', // It needs an ID
                width: 30,
                Cell: ({ row }) => (
                    // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? '▼' : '►'}
                    </span>
                ),
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {   
                Header:() => <div className="AlertLogTableHeader">Device ID</div>,  
                accessor: 'calibHistDeviceID',
                filter: filterCaseInsensitive,
                width: 150,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Measured<br/>Param</div>, 
                accessor: 'calibHistMeasuredParam',
                width: 180,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Parameter<br/>Calibration Done</div>,  
                accessor: 'calibHistFinalParamCalibStatus',
                filter: filterCaseInsensitive,
                width: 180,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Calibration Value</div>,  
                accessor: 'calibHistCalibValues',
                filter: filterCaseInsensitive,
                width: 250,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"        
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Calibration Status</div>,  
                accessor: 'calibHistCalibStatus',
                filter: filterCaseInsensitive,
                width: 250,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Calibration Done By</div>,  
                accessor: 'calibHistCalibrationDoneBy',
                filter: filterCaseInsensitive,
                width: 200,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",  
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"      
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Calib Date Time</div>, 
                accessor: 'calibHistCalibDateTime',
                // Cell: props => getCustomizedTodaysDate(props.value),
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width: 200,
                filter: filterCaseInsensitive,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },
        ],

        calibHistExpandCol:[
            {       
                Header:() => <div className="AlertLogTableHeader">Calibration Value</div>,  
                accessor: 'expendedCalibValue',
                filter: filterCaseInsensitive,
                width: 660,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    borderRight: '0.1rem solid black',
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    // height: "2.6rem"
                }),
            },
            {       
                Header:() => <div></div>,  
                accessor: 'TempColAsDevider',
                width: 10,
                disableFilters: true,
                style:({
                    textAlign:"left",
                    padding: "1.5rem",
                    background:'white',
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    // height: "2.6rem"
                }),
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Calibration Status</div>,  
                accessor: 'expendedCalibStatus',
                filter: filterCaseInsensitive,
                disableFilters: true,
                width: 680,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    borderLeft: '0.1rem solid black',
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    // height: "2.6rem"
                }),
            },
        ],

        errors: ""
    });

    const toggleDeviceStateHistoryTable = (e) => {

        let modifiedState = state;
        modifiedState.DeviceHistoryTableModal =  !modifiedState.DeviceHistoryTableModal;
        modifiedState.DeviceStateHistoryInfo = [];
        modifiedState.DeviceIDForCsvFileName = '';
        modifiedState.EnterDeviceID = "";
        modifiedState.errors = '';

        setState({...modifiedState});
    }

    const toggleCalibDetailsModal = (e) => {

        let modifiedState = state;
        modifiedState.calibDetailsModal = !modifiedState.calibDetailsModal;
        setState({...modifiedState});

    }

    const toggleCalibParamHistModal = (e) => {
        
        let modifiedState = state;
        modifiedState.calibParamHistModal = !modifiedState.calibParamHistModal;
        modifiedState.errors = '';
        setState({...modifiedState});

    }

    const onEnterDeviceID = (e) => {
        let modifiedState = state;
        modifiedState.EnterDeviceID = e.target.value;
        modifiedState.DeviceIDForCsvFileName = e.target.value;
        modifiedState.errors = '';
        modifiedState.DeviceHistoryTableModal = false;
        setState({...modifiedState});
    }

    const handleKeyDown = (e) => {
       
        if (e.key == 'Enter') {
            // This is just to prevent form submission on pressing "enter".
            e.preventDefault();
            return;
        }
    }

    const makeTextFile = (arrText) =>  {

        let modifiedState = state;
        let data = new Blob(arrText, {type: 'text/plain'});

        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (modifiedState.textFile !== null) {
            window.URL.revokeObjectURL(modifiedState.textFile);
        }

        modifiedState.textFile = window.URL.createObjectURL(data);

        // Return a URL you can use as an href
        return modifiedState.textFile;
    }

    const createAndFillCsvFile = (inarrCsvAllLines) => {

        let retVal = true;

        let fileName = "DeviceStateHistory_" +  state.DeviceIDForCsvFileName + ".csv";

        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.href = makeTextFile(inarrCsvAllLines);
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            let event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });

        // Return true to indicate that operation performed successfully (in future any failures in 
        // the previous calls can be checked to return false)
        retVal = true;
        return retVal;
    }

    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return t(IDS_TodaySearch) + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    const handleError = err => {
        console.error(err);
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    const openQRCameraForGetDevc = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 

        let modifiedState = state;

        modifiedState.rplcDevcQRCodeFlag = !modifiedState.rplcDevcQRCodeFlag;
        modifiedState.errors = "";
        setState({...modifiedState});

    }

    const handleScanResultOfDevc = (data) => {
        if(data) {
            let modifiedState = state;
            modifiedState.EnterDeviceID = data;
            modifiedState.DeviceIDForCsvFileName = data;
            modifiedState.rplcDevcQRCodeFlag = false;
            // if((modifiedState.EnterDeviceID != null && modifiedState.EnterDeviceID.length > 0)) {
            //     GetDeviceStateHistory(modifiedState);
            // } else {
            //     modifiedState.errors = "No QR code found. Please make sure the QR code is within the camera's frame and try again.";
            //     setState({...modifiedState});
            // }
            modifiedState.DeviceHistoryTableModal = false;
            setState({...modifiedState});
        }
    }

    const getCalibrationDetails = () => {

        let modifiedState = state;
        modifiedState.errors = '';
        modifiedState.showCalibDetailsInvokedByDevcMsg = false;

        if(modifiedState.EnterDeviceID == null || modifiedState.EnterDeviceID.length <= 0) {
            modifiedState.errors = 'Please Enter DeviceID.';
            setState({...modifiedState});
            return;
        } else {

            const jsonBody = {
                DeviceIDs: modifiedState.EnterDeviceID, 
            };
    
            // To get selected Device ID Calibration Details Done By details.
            axios.post(`${getAPIHostURL()}/wclient/getCalibrationDetails`, jsonBody)
            .then(response => {
                if(response.data.code == "SUCCESS") {
                    if(response.data.retrievedMessuredParam == null || response.data.retrievedMessuredParam.length <= 0) {
                        modifiedState.errors = "Device Calibration Details not present.";
                    } else {
                        let stateCalibTableDetails = [];
                        let DeviceAllParamInfo = JSON.parse(response.data.retrievedMessuredParam[0]["MeasuredParams"]);
                        // let seq = DeviceAllParamInfo["Seq"];
                        let deviceParamSeq = [];
                        deviceParamSeq.push("RTC");

                        for (let key in DeviceAllParamInfo) {
                            if(key != null && key != "Seq" && DeviceAllParamInfo[key].hasOwnProperty("NoCalib") == false) {
                                deviceParamSeq.push(key);
                            }
                        }

                        // for(let i=0; i<seq.length; i++){

                        //     let singleParam = seq[i];

                        //     if(DeviceAllParamInfo[singleParam].hasOwnProperty("NoCalib") == false) {
                        //         deviceParamSeq.push(seq[i]);
                        //     } else {
                        //         continue;
                        //     }
                        // }

                        let DeviceCalibDetails = response.data.arrRetrievedCalibDetails;
                        let deviceIdToShowOnTable = (DeviceCalibDetails != null && DeviceCalibDetails.length > 0) ? 
                            DeviceCalibDetails[0]["DeviceID"] : modifiedState.EnterDeviceID;

                        let signleDeviceCalibDetails = {};
    
                        for(let i = 0; i < deviceParamSeq.length; i++) {

                            let signleCalibParam = "";

                            for(let j = 0; j < DeviceCalibDetails.length; j++) {
                                
                                if(deviceParamSeq[i] == DeviceCalibDetails[j].MeasuredParam) {
                                    signleDeviceCalibDetails = DeviceCalibDetails[j];
                                    signleCalibParam = signleDeviceCalibDetails.MeasuredParam;
                                    break;
                                } else {
                                    signleCalibParam = "";
                                    continue
                                }
                            }

                            if(deviceParamSeq.includes(signleCalibParam)) {

                                if(signleDeviceCalibDetails["SetByUser"] == SET_BY_USER_INVOKED_FROM_DEVICE) {
                                    modifiedState.showCalibDetailsInvokedByDevcMsg = true;
                                }
        
                                let strCalibDateTimeLocale = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(signleDeviceCalibDetails.CalibDateTime);
            
                                let singleDeviceStateDetails = {
                                    deviceID: deviceIdToShowOnTable,
                                    measuredParam: (signleDeviceCalibDetails["MeasuredParam"] != null && signleDeviceCalibDetails["MeasuredParam"] == "TEMP") ?
                                        "TEMPR" : (signleDeviceCalibDetails["MeasuredParam"] != null && signleDeviceCalibDetails["MeasuredParam"] == "NH3OD") ? 
                                        "ODOUR" : signleDeviceCalibDetails["MeasuredParam"],
                                    finalParamCalibStatus: (signleDeviceCalibDetails["FinalParamCalibStatus"] != null && signleDeviceCalibDetails["FinalParamCalibStatus"] == 1) ? "Yes" : "No",
                                    calibrationDoneBy: signleDeviceCalibDetails["SetByUser"],
                                    invokedBy: signleDeviceCalibDetails["InvokedBy"],
                                    calibDateTime: strCalibDateTimeLocale,
                                };
                                stateCalibTableDetails.push(singleDeviceStateDetails);
                            } else {
                                let singleDeviceStateDetails = {
                                    deviceID: deviceIdToShowOnTable,
                                    measuredParam: (deviceParamSeq[i] != null && deviceParamSeq[i] == "TEMP") ? "TEMPR" : 
                                        (deviceParamSeq[i] != null && deviceParamSeq[i] == "NH3OD") ? "ODOUR" : deviceParamSeq[i],
                                    finalParamCalibStatus: "-",
                                    calibrationDoneBy: "-",
                                    invokedBy: "-",
                                    calibDateTime: "-",
                                };
                                stateCalibTableDetails.push(singleDeviceStateDetails);
                            }
                        }

                        modifiedState.calibDetailsTableData = stateCalibTableDetails;
                    }
                    modifiedState.calibDetailsModal = true;
                    setState({...modifiedState});

                } else {
                    if(response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the Required parameters were not sent to the Server
                        modifiedState.errors = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                        
                    } else if (response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                    }
                    setState({...modifiedState});
                }
            })
            .catch(error => {
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors= 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});
                }  
            });  
        }
    }

    const getSelectedParamCalibHist = (inModifiedState = null) => {

        let modifiedState;
        if(inModifiedState == null ) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.errors = '';

        if(modifiedState.EnterDeviceID == null || modifiedState.EnterDeviceID.length <= 0 
            || modifiedState.calibDetailSelectedRowMessuredParam == null || modifiedState.calibDetailSelectedRowMessuredParam.length <= 0
        ) {
            modifiedState.errors = 'Please Enter DeviceID.';
            setState({...modifiedState});
            return;
        } else {

            const jsonBody = {
                DeviceID: modifiedState.EnterDeviceID, 
                SelectedMesuredParam: (modifiedState.calibDetailSelectedRowMessuredParam == "TEMPR") ? "TEMP" : 
                                        (modifiedState.calibDetailSelectedRowMessuredParam == "ODOUR") ? "NH3OD" : modifiedState.calibDetailSelectedRowMessuredParam,
            };
    
            // To get selected Device ID Calibration Details Done By details.
            axios.post(`${getAPIHostURL()}/wclient/getSelectedParamCalibHist`, jsonBody)
            .then(response => {
                if(response.data.code == "SUCCESS") {
                    if(response.data.retrievedSelectedParamCalibHist == null || response.data.retrievedSelectedParamCalibHist.length <= 0) {
                        modifiedState.errors = "Selected Param Calibration History Details not present.";
                    } else {

                        let selectedParamCalibHistDetailsArr = response.data.retrievedSelectedParamCalibHist;
                        modifiedState.paramCalibHistDataArr = [];
    
                        for(let i = 0; i < selectedParamCalibHistDetailsArr.length; i++) {
    
                            let signleParamCalibHistDetails = selectedParamCalibHistDetailsArr[i];

                            if(signleParamCalibHistDetails["SetByUser"] == SET_BY_USER_INVOKED_FROM_DEVICE) {
                                modifiedState.showCalibHistInvokedByDevcMsg = true;
                            } else {
                                modifiedState.showCalibHistInvokedByDevcMsg = false;
                            }

                            let strCalibHistDateTimeLocale = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(signleParamCalibHistDetails.CalibDateTime);
            
                            let singleDeviceStateDetails = {
                                calibHistDeviceID: signleParamCalibHistDetails["DeviceID"],
                                calibHistMeasuredParam: (signleParamCalibHistDetails["MeasuredParam"] != null && signleParamCalibHistDetails["MeasuredParam"] == "TEMP") ?
                                                            "TEMPR" : (signleParamCalibHistDetails["MeasuredParam"] != null && signleParamCalibHistDetails["MeasuredParam"] == "NH3OD") ? 
                                                            "ODOUR" : signleParamCalibHistDetails["MeasuredParam"],
                                calibHistFinalParamCalibStatus: (signleParamCalibHistDetails["FinalParamCalibStatus"] != null && signleParamCalibHistDetails["FinalParamCalibStatus"] == 1) ? "Yes" : "No",
                                calibHistCalibValues: signleParamCalibHistDetails["CalibValues"],
                                calibHistCalibStatus: signleParamCalibHistDetails["Status"],
                                calibHistCalibrationDoneBy: signleParamCalibHistDetails["SetByUser"],
                                calibHistInvokedBy: signleParamCalibHistDetails["InvokedBy"],
                                calibHistCalibDateTime: strCalibHistDateTimeLocale,
                            };
                            modifiedState.paramCalibHistDataArr.push(singleDeviceStateDetails);
                            modifiedState.calibParamHistModal = true;

                        }

                    }
                    setState({...modifiedState});

                } else {
                    if(response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the Required parameters were not sent to the Server
                        modifiedState.errors = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                        
                    } else if (response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                    }
                    setState({...modifiedState});
                }

            })
            .catch(error => {
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors= 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});
                }  
            });  
        }
    }

    const GetDeviceStateHistory = (bDeviceStateHistorydataForCsv= false) => {

        let modifiedState = state;
        modifiedState.errors = '';

        if(modifiedState.EnterDeviceID == null || modifiedState.EnterDeviceID.length <= 0) {
            modifiedState.errors = 'Please Enter DeviceID.';
            setState({...modifiedState});
        } else {

            const jsonBody = {
                EnterDeviceID: modifiedState.EnterDeviceID, 
            };
    
            // To get selected Customer Orders information from database.
            axios.post(`${getAPIHostURL()}/wclient/getDeviceStateHistory`, jsonBody)
            .then(response => {
                if(response.data.code == "SUCCESS") {
                    if(response.data.retrivedDeviceStateHistoryData == null || response.data.retrivedDeviceStateHistoryData.length <= 0) {
                        modifiedState.errors = "Device state history Detail Information is not present.";
                    } else {

                        modifiedState.DeviceStateHistoryInfo = [];
                        if(modifiedState.bDeviceStateHistoryViewDataModalOpen == true) {
                            modifiedState.DeviceHistoryTableModal = true;
                        }

                        let DeviceStateDetails = response.data.retrivedDeviceStateHistoryData;
    
                        for(let i = 0; i < DeviceStateDetails.length; i++) {
    
                            let selectedDeviceStateInfo = DeviceStateDetails[i];
        
                            let strDateTimeLocale = convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(selectedDeviceStateInfo.StateDateTime);
        
                            let singleDeviceStateDetails = {
                                DeviceID: selectedDeviceStateInfo["ItemID"],
                                DeviceState: selectedDeviceStateInfo["State"],
                                DateTime: strDateTimeLocale,
                                AdditionalInfo: (selectedDeviceStateInfo["State"] == ITM_STATE_SOLD || selectedDeviceStateInfo["State"] == ITM_STATE_DEMO || selectedDeviceStateInfo["State"] == ITM_STATE_RENTAL) ? 'Customer:' + " " + (selectedDeviceStateInfo["CustomerId"]== null ? "" : selectedDeviceStateInfo["CustomerId"]) : (selectedDeviceStateInfo["State"] == ITM_STATE_MAPPED_TO_OWNER) ? 'Owner:' + " " + (selectedDeviceStateInfo["DeviceOwnerId"] == null ? "" : selectedDeviceStateInfo["DeviceOwnerId"])  : null,
                                ExecutiveEmailID: (selectedDeviceStateInfo["State"] == ITM_STATE_MAPPED_TO_OWNER && selectedDeviceStateInfo["UserIdOfSalesRep"] == selectedDeviceStateInfo["DeviceOwnerId"]) ? null : selectedDeviceStateInfo["UserIdOfSalesRep"],
                                Comment: selectedDeviceStateInfo["Comment"] == null ? "" : selectedDeviceStateInfo["Comment"],
                            };
                            modifiedState.DeviceStateHistoryInfo.push(singleDeviceStateDetails);

                        }
                        if( bDeviceStateHistorydataForCsv == true) {
                            modifiedState.DeviceHistoryTableModal = false;
                            DownloadCsv(modifiedState.DeviceStateHistoryInfo)
                        }
                    }
                    setState({...modifiedState});

                } else {
                    if(response.data.code == 'REQ_PARAMS_MISSING') {
                        // Let the user know that the Required parameters were not sent to the Server
                        modifiedState.errors = 'Server experiencing issues (required parameters not sent).\nTry again later.';
                        
                    } else if (response.data.code == 'SQL_ERROR') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors = 'Server experiencing issues.\nTry again later.';
                    }
                    setState({...modifiedState});
                }

            })
            .catch(error => {
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    modifiedState.errors= 'Network issues.\nCheck your Internet and Try again later.';
                    setState({...modifiedState});
                }  
            });  
        }
    }

    const OnClickViewButton = () => {
        let modifiedState = state;
        modifiedState.bDeviceStateHistoryViewDataModalOpen = true;

        setState({...modifiedState});
        GetDeviceStateHistory();

    }

    const OnClickDownloadAsCsvButton =() => {

        let bDeviceStateHistorydataForCsv = true

        GetDeviceStateHistory(bDeviceStateHistorydataForCsv);
    }

    const OnClickDownloadDataAsCsvButtonInView = () => {
        let modifiedState = state;

        if(modifiedState.DeviceStateHistoryInfo.length > 0) {
            DownloadCsv(modifiedState.DeviceStateHistoryInfo);
        } else {
            console.error("Downloaded data could not be saved to file.")
        }
    }

    const DownloadCsv = (inCsvData) => {      

        let modifiedState = state;
        let DeviceStateDetails = [];
        DeviceStateDetails = inCsvData;
        let noOfLogs = inCsvData.length;
        let arrColumnKeys = [];
        modifiedState.csvData = [];

        // create csv data.
        arrColumnKeys = ["Device State", "Date Time", "Additional Info", "Comment","Executive Email ID"];

        let strCsvHeaderLine = "Device ID";
        let arrCsvAllLines = []; // All data lines having a "CRLF" at the beginning
        let strSingleCsvDataLine = "";
        let strColumnName = "";

        // Create rest of the columns in the header
        let noOfParams = arrColumnKeys.length;
        for (let j=0; j<noOfParams; j++) {
            strColumnName = arrColumnKeys[j];
            strCsvHeaderLine += (CSV_COLUMN_SEPERATOR + strColumnName);
        }

        let allInstanceInfo = [];

        // First push the header CSV line
        arrCsvAllLines.push(strCsvHeaderLine);

        for(let i = 0; i < noOfLogs; i++) {

            let selectedDeviceStateInfo = DeviceStateDetails[i];

            let singleDeviceStateDetailsForCsv = {
                DeviceID: selectedDeviceStateInfo.DeviceID,
                DeviceState: selectedDeviceStateInfo["DeviceState"],
                DateTime: selectedDeviceStateInfo["DateTime"],
                AdditionalInfo: selectedDeviceStateInfo["AdditionalInfo"],
                Comment: selectedDeviceStateInfo["Comment"],
                ExecutiveEmailID: selectedDeviceStateInfo["ExecutiveEmailID"],
            };
            allInstanceInfo.push(singleDeviceStateDetailsForCsv);

            strSingleCsvDataLine = CSV_LINE_SEPERATOR +
                                    ((selectedDeviceStateInfo["DeviceID"] == null) ? '' : selectedDeviceStateInfo["DeviceID"] ) +
                                    CSV_COLUMN_SEPERATOR +
                                    ( (selectedDeviceStateInfo["DeviceState"] == null) ? '' : selectedDeviceStateInfo["DeviceState"] ) +
                                    CSV_COLUMN_SEPERATOR +
                                    ( selectedDeviceStateInfo["DateTime"] == null ? '' : selectedDeviceStateInfo["DateTime"] ) +
                                    CSV_COLUMN_SEPERATOR +
                                    ((selectedDeviceStateInfo["AdditionalInfo"] == null ) ? '' : selectedDeviceStateInfo["AdditionalInfo"]) +
                                    CSV_COLUMN_SEPERATOR +
                                    ((selectedDeviceStateInfo["Comment"] == null ) ? '' : selectedDeviceStateInfo["Comment"]) +
                                    CSV_COLUMN_SEPERATOR +
                                    ( (selectedDeviceStateInfo["ExecutiveEmailID"] == null ) ? '' : selectedDeviceStateInfo["ExecutiveEmailID"]);

            arrCsvAllLines.push(strSingleCsvDataLine);
        }
        modifiedState.csvData = allInstanceInfo;
        // Create CSV File and fill it
        let bFileCreationStatus = createAndFillCsvFile(arrCsvAllLines);

        alert(`Device state history data download has started. Check your Browser's status bar or Downloads Folder.`);

        if (bFileCreationStatus) {
            // CSV file creation successful.
            // Close the date selection modal.
            modifiedState.DeviceHistoryTableModal = true;
            // props.toggleInventoryReportsDevcHistory(); // Notify the parent to close the modal.
            // modal closed, no need to do any further processing
            return;
        } else {
            modifiedState.errors = 'Downloaded data could not be saved to file.';
        }
    }  
    
    const onRowClick = (rowInfo) => {

        if (rowInfo.row != undefined && rowInfo.column != undefined) {
            let modifiedState = state;
            return {
                onClick: (e, handleOriginal) => {

                    modifiedState.calibDetailsSelectedRowIndex = rowInfo.row.index;

                    if(rowInfo.row["original"]["finalParamCalibStatus"] != null && rowInfo.row["original"]["finalParamCalibStatus"].length > 0 
                        && rowInfo.row["original"]["finalParamCalibStatus"] != "-") {

                        let selectedMessuredaram = rowInfo.row["original"]["measuredParam"];
                        modifiedState.calibDetailSelectedRowMessuredParam = selectedMessuredaram;
                        getSelectedParamCalibHist(modifiedState);
                    } else {
                        modifiedState.calibDetailSelectedRowMessuredParam = "";
                        setState({...modifiedState});
                    }               
                },
                style:
                    (rowInfo.row["original"]["finalParamCalibStatus"] != null && rowInfo.row["original"]["finalParamCalibStatus"].length > 0 
                        && rowInfo.row["original"]["finalParamCalibStatus"] != "-") &&
                    {
                        cursor: 'pointer',
                        background: rowInfo.row.index == modifiedState.calibDetailsSelectedRowIndex ? '#6c95ba' : '',
                        color: rowInfo.row.index == modifiedState.calibDetailsSelectedRowIndex ? 'white' : 'black',
                        alignItems: "center"
                    }
            }
            
        } else {
            return {}
        }
    }

    const onExpendedViewClick = (row) => {
        let modifiedState = state;

        let expendedRowCalibHistValue = row.original.calibHistCalibValues;
        let expendedRowCalibHistStatus = row.original.calibHistCalibStatus;

        try {
            let expendedViewHistData = [];
            let parseParamCalibHistValues = JSON.parse(expendedRowCalibHistValue);
            let strParamCalibHistValues = JSON.stringify(parseParamCalibHistValues);
            let parseParamCalibHistStatus = JSON.parse(expendedRowCalibHistStatus);
            let strParamCalibHistStatus = JSON.stringify(parseParamCalibHistStatus);

            let strRevoedParamCalibHistValuesBracket = strParamCalibHistValues.replace("{","").replace("}","");
            let paramCalibValuesArr = (strRevoedParamCalibHistValuesBracket).split(",");

            let strRevoedParamCalibHistStatusBracket = strParamCalibHistStatus.replace("{","").replace("}","");
            let paramCalibStatusArr = (strRevoedParamCalibHistStatusBracket).split(",");

            let expendedTableLength =  (paramCalibValuesArr.length > paramCalibStatusArr.length) ? 
                paramCalibValuesArr.length : paramCalibStatusArr.length;

            for(let i = 0; i < expendedTableLength; i++) {
                let singleParamHistValuesAndStatusDetails = {
                    expendedCalibValue: [i] <= paramCalibValuesArr.length &&  paramCalibValuesArr[i] != null && paramCalibValuesArr[i].length > 0 ? paramCalibValuesArr[i] : "-",
                    expendedCalibStatus: [i] <= paramCalibStatusArr.length && paramCalibStatusArr[i] != null && paramCalibStatusArr[i].length > 0 ? paramCalibStatusArr[i] : "-",
                };
                expendedViewHistData.push(singleParamHistValuesAndStatusDetails);
            }

            return (
                // <div style={{margin:"1rem", border: "1px solid black", textAlign:"center", width: "96%"}}>
                <div style={{margin:"1rem", textAlign:"center", width: "96%"}}>
                {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", borderColor: "lightgray"}}> */}
                <div className="border border-3 ReactTableStyle">
                    <ReactTable columns={state.calibHistExpandCol} 
                        data={expendedViewHistData} 
                        renderBasedOnTable = {"calibHistExpandColTable"}
                        defaultPageSize = {expendedViewHistData.length}
                        getCellProps = {(cellInfo) => ({})}
                        // as React Table is outside the main function, state varaibles cant be accessed there 
                        // so passing all the state variables on which react table is dependent through passedStateVariable
                        passedStateVariables = {state.loading}
                        passedFuntion = {""}
                    />
                </div>
                </div>
            )

        } catch {
            console.log(`Should not happen. expendedRowCalibHistValue and expendedRowCalibHistStatus is invalid JSON format.`);
            return <div/>; // Return empty Div if there is JSON conversion error
        }

    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="container col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2">
                    <div className="modal-body p-4 box">
                        <div className = "headingForComponentsOfCrmPage" style={{marginBottom: "1.2rem"}}>
                            Report - Device History
                        </div>
                        <form >
                            <div style={{paddingBottom:"0.8rem"}}>
                                <label className="reg-form-label">Scan or Enter Device ID:</label>
                                <input type='text' 
                                        className="input-form"
                                        required="required" 
                                        value={state.EnterDeviceID} 
                                        onChange={onEnterDeviceID}
                                        onKeyDown={handleKeyDown}
                                />
                                <label onClick= {openQRCameraForGetDevc} className = "qrcodeTextBtn">
                                    <FaQrcode className = "qrcodeTextBtnIcon"/>
                                </label>
                                <div>
                                    {(state.rplcDevcQRCodeFlag == true)
                                    ?
                                        // <div style={{display: "flex", justifyContent: "center"}}>
                                        //     <QrReader
                                        //         scanDelay={300}
                                        //         onResult={(result, error) => {
                                        //             if (!!result) {
                                        //                 handleScanResultOfDevc(result?.text);
                                        //             }
                                        //         }}
                                        //         className = "QRCodeCamBoxForModalAndContainer"
                                        //     />
                                        // </div>
                                            <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                        marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                        marginRight: "auto"}}>                                           
                                            <QrScanner
                                                scanDelay={300}
                                                onResult={(result, error) => {
                                                    if (!!result) {
                                                        handleScanResultOfDevc(result?.text);
                                                    }
                                                }}
                                                className = "QRCodeCamBoxForModalAndContainer"
                                            />
                                        </div>
                                    :
                                        <div/>
                                    }
                                </div>
                            </div>
                            <div style={{display:"flex", justifyContent:"space-evenly"}} className="startAndEnddate">
                                <div>
                                    <button type="button"
                                            onClick={OnClickDownloadAsCsvButton}
                                            style={{textTransform:"capitalize", fontWeight:"bold", fontSize: "0.9rem"}}
                                            className="btn-sm reg-btn"
                                    >
                                        Download As CSV 
                                    </button>
                                </div>
                                <div className="DownloadAndViewButton">
                                    <button type="button"
                                            style={{textTransform:"capitalize", fontWeight:"bold", fontSize: "0.9rem"}}
                                            className="btn-sm reg-btn"
                                            onClick={OnClickViewButton}>
                                            View
                                    </button>
                                </div>
                            </div>

                            <div className = "buttonErrorMessage" style={{textAlign: "center"}}>
                                {state.errors.length > 0 && 
                                    <p style={{color: 'var(--errorColor)', fontSize: '0.8rem'}} className='error'>{state.errors}</p>}
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
            {state.DeviceHistoryTableModal == true &&
                <div>
                    <div className="row justify-content-center">
                        <div className="container col-lg-12 col-md-12 col-sm-12">
                            <div className="modal-body box" style = {{padding: "1rem"}}>
                                <div style={{display: "flex", justifyContent: "space-between", marginBottom:"1rem"}} className="startAndEnddate">
                                    <div>
                                        <button type="button"
                                            style={{textTransform:"capitalize", fontWeight:"bold", fontSize: "0.9rem"}}
                                            className="btn-sm reg-btn"
                                            onClick={getCalibrationDetails}
                                        >
                                            Get Calibration Details
                                        </button>
                                    </div>
                                    <div className="DownloadAndViewButton">
                                        <button type="button"
                                                style={{textTransform:"capitalize", fontWeight:"bold", fontSize: "0.9rem"}}
                                                className="btn-sm reg-btn"
                                                onClick={OnClickDownloadDataAsCsvButtonInView}
                                        >
                                            Download As CSV
                                        </button>
                                    </div>
                                </div>
                                {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", borderColor: "lightgray"}}> */}
                                <div className="border border-3 ReactTableStyle">
                                    <ReactTable columns={state.DeviceStateInfoColumns} 
                                        data={state.DeviceStateHistoryInfo}
                                        renderBasedOnTable = {"DeviceStateInfoColumnsTable"}
                                        defaultPageSize = {0}
                                        getCellProps = {(cellInfo) => ({})}
                                        // as React Table is outside the main function, state varaibles cant be accessed there 
                                        // so passing all the state variables on which react table is dependent through passedStateVariable
                                        passedStateVariables = {state.loading}
                                        passedFuntion = {""}
                                    />
                                </div>
                                <div style={{textAlign:"center"}}>
                                    <button type="button" className="btn-sm" style={{fontWeight:"bold", marginTop:"1rem", background:"var(--primaryColor)", color:"white", alignItems:"centre",
                                    borderRadius: "5px", padding: "0.4rem", fontSize: "0.9rem"}} onClick={toggleDeviceStateHistoryTable}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div>
                <Modal size="xl" isOpen={state.calibDetailsModal} toggle={toggleCalibDetailsModal} backdrop={state.backdrop}>
                    <ModalHeader toggle={toggleCalibDetailsModal}>Calibration Details Table</ModalHeader>
                        <ModalBody>
                            {(state.showCalibDetailsInvokedByDevcMsg == true) &&
                                <div style={{border:"1px solid var(--primaryColor)", color:"green", fontSize:"0.8rem", paddingLeft: "0.3rem", paddingRight: "0.3rem", backgroundColor:"rgb(168,204,168,0.3)", borderRadius:"0.3rem", marginBottom:"0.5rem"}}>
                                    <b>Note:</b> If the "Calibration Done By" column shows "InvokedByDevice", 
                                    it means that the Device has directly used the information that was stored inside it during an earlier calibration. 
                                    In that case please check the Calibration History for that individual parameter to see which user had calibrated the same.
                                </div>
                            }
                            {state.errors.length > 0 && 
                                <p style={{color: 'var(--errorColor)', fontSize: '0.8rem'}} className='error'>{state.errors}</p>}
                            {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", borderColor: "lightgray"}}> */}
                            <div className="border border-3 ReactTableStyle">
                                <ReactTable columns={state.calibDetailsColumns} 
                                    data={state.calibDetailsTableData}
                                    renderBasedOnTable = {"calibDetailsColumnsTable"}
                                    defaultPageSize = {0}
                                    getCellProps = {onRowClick}
                                    passedStateVariables = {state.loading}
                                    passedFuntion = {""}
                                />
                            </div>          
                            <div style={{textAlign:"center"}}>
                                <button type="button" className="btn-sm" 
                                    style={{fontWeight:"bold", marginTop:"1rem", background:"var(--primaryColor)", color:"white", alignItems:"centre",
                                    borderRadius: "5px", padding: "0.4rem", fontSize: "0.9rem"}} 
                                    onClick={toggleCalibDetailsModal}
                                >
                                    Close
                                </button>
                            </div>
                        </ModalBody>
                </Modal>
            </div>
            <div>
                <Modal size="xl" isOpen={state.calibParamHistModal} toggle={toggleCalibParamHistModal} backdrop={state.backdrop}>
                    <ModalHeader toggle={toggleCalibParamHistModal}>Calibration History for Selected Parameter - {state.calibDetailSelectedRowMessuredParam}</ModalHeader>
                        <ModalBody>
                            {(state.showCalibHistInvokedByDevcMsg == true) &&
                                <div style={{border:"1px solid var(--primaryColor)", color:"green", fontSize:"0.8rem", backgroundColor:"rgb(168,204,168,0.3)", borderRadius:"0.3rem", marginBottom:"0.5rem"}}>
                                    Note: If the "Calibration Done By" column shows "InvokedByDevice", 
                                    it means that the Device has directly used the information that was stored inside it during an earlier calibration.
                                </div>
                            }
                            {/* <div style={{ borderStyle: "solid", marginTop: "0rem", borderWidth: "1px", borderColor: "lightgray"}}> */}
                            <div className="border border-3 ReactTableStyle">
                                <ReactTable columns={state.CalibHistCol} 
                                    data={state.paramCalibHistDataArr}  
                                    renderBasedOnTable = {"CalibHistColTable"}
                                    defaultPageSize = {0}
                                    getCellProps = {(cellInfo) => ({})}
                                    // as React Table is outside the main function, state varaibles cant be accessed there 
                                    // so passing all the state variables on which react table is dependent through passedStateVariable
                                    passedStateVariables = {state.loading}
                                    onExpendedViewClick = {onExpendedViewClick}
                                />
                            </div>     
                            <div style={{textAlign:"center"}}>
                                <button type="button" className="btn-sm reg-btn" 
                                    style={{fontWeight:"bold", marginTop:"1rem", background:"var(--primaryColor)", color:"white", alignItems:"centre",
                                    borderRadius: "5px", padding: "0.4rem", fontSize: "1.2rem"}} 
                                    onClick={toggleCalibParamHistModal}
                                >
                                    Close
                                </button>
                            </div>
                        </ModalBody>
                </Modal>
            </div>
        </div>
    );
}

export default VcDeviceStateHistory;

