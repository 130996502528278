import React, { useState, useContext, useEffect } from 'react'
import { AppRelevantDataContext } from '../../AppContext';
import App from "../../App";
import '@studysync/react-splitter-layout/lib/index.css';
import VcAlertLog from './VcAlertLog';
import { IDS_AlertsSelectDevc } from '../../VcLanguage';
import {useNavigate} from 'react-router-dom';
import '../CSS/AlertPeriodSelectionForm.css';
import '../CSS/InputFormVtAlertLog.css';

function VcAlerts (props) {
  const context = useContext(AppRelevantDataContext);
  const navigate = useNavigate();

  useEffect(() => {
    let appRelevantDataContextValue = context; // Get all the relevant data from AppContext

    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
      // User login information is not present in appRelevantDataContext.
      // Try to get this value if present in LocalStorage and update the app state (so that
      // re-render will happen). If app context data not present in LocalStorage then
      // show the Login Page
      if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
        // App Relevant Data Context not present in LocalStorage as well.
        navigate('/', { replace: true });
      } else {
        // App Relevant Data Context loaded from LocalStorage at AppLeval.
        // Re-render will happen automatically (no action required).
      }
    }
    
  }, []);

  let appRelevantDataContextValue = context;
  let t = appRelevantDataContextValue.t;

  if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
    // User login information is not present in appRelevantDataContext.
    // Next re-render will happen automatically when the AppState is updated again 
    // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
    return App.renderLoadingPage();
  }
  let isRootSelected = appRelevantDataContextValue.selectedNodeInfo.isRoot;
  let selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle


  return (
    <>
      {/* <div className='py-2 w-100 flex items-center justify-between '>
          <span className='text-left flex align-items-center gap-1'>
            <small>{props.fullPath}</small>
            <small className='selectedNodeTitle' style={{fontWeight:"600"}}>
                {selectedTreeNodeTitle}
            </small>
          </span>
          <button className='refreshButtonStyle'
              onClick={onRefreshBtnClicked} 
              title={t(IDS_RefreshData)}
            >
              <i className="fa fa-refresh flex-center" 
              style={{ paddingLeft: "0", fontSize: "20px"}} 
              aria-hidden="true"></i>
          </button>
      </div> */}
      <div>
      { isRootSelected ?
        <div className=" sddSingleParam">
          <div className="parameterOuterDiv  col-xs-12 col-sm-6 col-md-8">
            {t(IDS_AlertsSelectDevc)}     
          </div>
      </div>
      :                    
        <VcAlertLog fullPath={props.fullPath}/>
      }
    </div>
    
    </>
    
  )
}

export default VcAlerts;
