import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AppRelevantDataContext } from '../../AppContext';
import { IDS_NeedHelpToSetupDevice, IDS_WSwitchON, IDS_WOnMobileNWifi, IDS_WEnterWifiPassword, IDS_WClickBelow, IDS_WSelectWifi,
    IDS_WSelectedWifiPassword, IDS_WEnterDesiredName, IDS_WEnterEmail, IDS_WReEnterEmail, IDS_WEnterPincode, 
    IDS_WSelectRoomType, IDS_WClickSave, IDS_WClickToSetup, IDS_LoginDevcSetup, IDS_NeedHelpToSetupDeviceAndWifi} from '../../VcLanguage';

export class VcSetupDevice extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            modal: false,
            backdrop: 'static',
            selectedPosition: 0,
      
        }
    }

    toggle = (e) => {
        let modifiedState = this.state;
        modifiedState.modal = !this.state.modal;
        this.setState(modifiedState);
        if(this.props.invokedFrom != null && this.props.invokedFrom.length > 0 && this.props.invokedFrom == "UserProfilePopOver") {
          this.props.toggleRootCloseValueBasedOnSetupDeviceModal();
        }
    }
    
    setSelectedPage = index => {
      this.setState({
        selectedPosition: index,
      });
    };
    
    render() {
      let appRelevantDataContextValue = this.context;
      let t = appRelevantDataContextValue.t;

      return (
          <div>
              <a href="#"
                onClick={this.toggle}
                style={{color:"#043e73", backgroundColor: "transparent", border:"none"}} 
              >
              </a>  
              <Modal size="xl" isOpen={this.state.modal} toggle={this.toggle} backdrop={this.state.backdrop}>
                  <ModalHeader toggle={this.toggle}>{t(IDS_LoginDevcSetup)}</ModalHeader>
                  <ModalBody>     
                  <Carousel showThumbs={false}
                            selectedItem={this.state.selectedPosition}
                            onChange={this.setSelectedPage}
                            className="p-4"
                  >
                    <div className="row">
                      <div className="container centered">
                        <div className="firstmodalslide">
                            <p style={{fontSize: "1.2rem", color: "white"}} dangerouslySetInnerHTML={{__html: t(IDS_WSwitchON)}}></p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="container centered">
                        <div className="secondmodalslide">
                            <span>
                              <ul>
                                <li style={{fontSize: "1.2rem", color: "white"}} dangerouslySetInnerHTML={{__html: t(IDS_WOnMobileNWifi)}}></li>
                                <li style={{fontSize: "1.2rem", color: "white", textAlign: "start"}} dangerouslySetInnerHTML={{__html: t(IDS_WEnterWifiPassword)}}></li>
                              </ul>
                            </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="container centered">
                        <div className="thirdmodalslide">
                            <p className="thirdSliderHeader" dangerouslySetInnerHTML={{__html: t(IDS_WClickBelow)}}></p>
                            <span className="thirdSlideList">
                                <ul>
                                  <li className="thirdSliderSubComp" dangerouslySetInnerHTML={{__html: t(IDS_WSelectWifi)}}></li>
                                  <li className="thirdSliderSubComp" dangerouslySetInnerHTML={{__html: t(IDS_WSelectedWifiPassword)}}></li>
                                  <li className="thirdSliderSubComp" dangerouslySetInnerHTML={{__html: t(IDS_WEnterDesiredName)}}></li>
                                  <li className="thirdSliderSubComp" dangerouslySetInnerHTML={{__html: t(IDS_WEnterEmail)}}></li>
                                  <li className="thirdSliderSubComp" dangerouslySetInnerHTML={{__html: t(IDS_WReEnterEmail)}}></li>
                                  <li className="thirdSliderSubComp" dangerouslySetInnerHTML={{__html: t(IDS_WEnterPincode)}}></li>
                                  <li className="thirdSliderSubComp" dangerouslySetInnerHTML={{__html: t(IDS_WSelectRoomType)}}></li>
                                  <li className="thirdSliderSubComp" dangerouslySetInnerHTML={{__html: t(IDS_WClickSave)}}></li>
                                </ul>
                            </span>
                            <p style={{textAlign:"center"}}><a href="http://192.168.4.1" target="_blank" className = "textDecoNone" dangerouslySetInnerHTML={{__html: t(IDS_WClickToSetup)}}></a></p>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </ModalBody>
              </Modal>
          </div>
      )
    }
}

VcSetupDevice.contextType = AppRelevantDataContext; // Default context from which this component will get provider values in required lifecycle methods

export default VcSetupDevice;
