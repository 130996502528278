import React, { Component } from 'react'
import "react-tabs/style/react-tabs.css";
import axios from 'axios';
import { getAPIHostURL } from '../../ClientConfig';
import { FaQrcode } from 'react-icons/fa'
// import {QrReader} from '@otterscan/react-qr-reader'
import {QrScanner} from '@yudiel/react-qr-scanner';
import "../CSS/AddUserForm.css"

// const validEmailRegex = RegExp(/^(([a-z0-9]+(\.[a-z0-9]+)*))@((([a-z0-9]+(\-*[[a-z0-9]+)*))+(\.[a-z]{2,})+)$/i); 
const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/); 

export class VcResetDevcOwnerEmailID extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDeviceID: "",
            manuallyAddedDeviceIDArr: [],
            rplcDevcQRCodeFlag: false,
            email: '',
            password: '',
            OpenForm: false,
            ForceFullyResetOwner: false,

            errors: { 
              others:'',
              invalidDevcID: '',
              ownerNotCleared: '',
              email: '',
              password: '',
              loginIssue: '',
            },
        }
    }

    onChangeDeviceID = (e) => {
        let modifiedState = this.state;
        modifiedState.selectedDeviceID = (e.target.value); 
        modifiedState.errors.invalidDevcID = "";
        modifiedState.errors.ownerNotCleared = "";
        modifiedState.errors.others = "";

        this.setState(modifiedState);
    }

    onClickAddDataList = (e) => {
        let modifiedState = this.state;

        let EnteredDeviceID = modifiedState.selectedDeviceID;
        modifiedState.errors.others = "";


        if(modifiedState.manuallyAddedDeviceIDArr.includes(EnteredDeviceID)) {
            modifiedState.errors.invalidDevcID = 'DeviceID already exist.';
            this.setState(modifiedState);

        } else {
            if(EnteredDeviceID == null || EnteredDeviceID.length <= 0) {
                modifiedState.errors.invalidDevcID = 'Please Enter Device ID.';
                this.setState(modifiedState);
                return;
            }
            let jsonBody = {
                EnteredDeviceID: EnteredDeviceID,
            }

            axios.post( `${getAPIHostURL()}/wclient/checkDeviceIdExistence`, jsonBody)
            .then(response => {
        
                if(response.data.code == 'SUCCESS') {    
    
                    if(response.data.isDeviceIDExist == null) {
                        modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                    } else {
                        if(response.data.isDeviceIDExist == 0) {
                            modifiedState.errors.invalidDevcID = 'Invalid Device ID.'
                        } else {
                            modifiedState.selectedDeviceID = "";
                            modifiedState.errors.invalidDevcID = "";
                            // Just wanted to show last inserted DeviceID at the top of DisplayBox.
                            modifiedState.manuallyAddedDeviceIDArr.unshift(EnteredDeviceID);
                        }
                    }
                } else {
                    if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                }
                this.setState(modifiedState);
            })
            .catch( error => {
                console.log("Network error:");
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    this.setState(modifiedState);
                }
            });   
        }
    }

    onLoginSumbit = (e) => {
        let modifiedState = this.state;
        if(modifiedState.ForceFullyResetOwner == false && modifiedState.email == "") {
            modifiedState.errors.email = "Please enter Email ID."
            this.setState(modifiedState); 
            return;
        } else if(modifiedState.ForceFullyResetOwner == false && (modifiedState.password == null || modifiedState.password.length < 8)) {
            modifiedState.errors.password = "Please enter password."
            this.setState(modifiedState); 
            return;
        } else {
            modifiedState.OpenForm = false;
            modifiedState.ForceFullyResetOwner = true;
            this.setState(modifiedState); 
            this.onSubmitResetDeviceOwnerEmailID();
        }
    }

    onSubmitResetDeviceOwnerEmailID = () => {
        let modifiedState = this.state;
        modifiedState.errors.ownerNotCleared = "";
        modifiedState.errors.others = "";

        if(modifiedState.manuallyAddedDeviceIDArr != null && modifiedState.manuallyAddedDeviceIDArr.length > 0) {

            // Convert UserID to lowercase and remove all whiteSpaces before sending to Server as it makes comparison easy and valid.
            let UserID =  modifiedState.email.trim();
            UserID = UserID.toLowerCase();

            let jsonBody = {
                    DeviceIDArr: modifiedState.manuallyAddedDeviceIDArr,
                    ForceFullyResetOwner: modifiedState.ForceFullyResetOwner,
                    UserEmailID: UserID,
                    UserPassword: modifiedState.password
                }

            axios.post( `${getAPIHostURL()}/wclient/resetDevcOwnerEmailID`, jsonBody)
            .then(response => {
        
                if(response.data.code == 'SUCCESS') { 
                    modifiedState.manuallyAddedDeviceIDArr = []; 
                    modifiedState.email = '';
                    modifiedState.password = '';    
                    var NotClearedDeviceIDs = [];
                    NotClearedDeviceIDs = response.data.NotClearedDeviceIDs;
                    if(NotClearedDeviceIDs != null && NotClearedDeviceIDs.length > 0) {
                        modifiedState.errors.ownerNotCleared = "Above DeviceID's Owner Not Cleared."

                        for(let i=0; i < NotClearedDeviceIDs.length; i++) {
                            modifiedState.manuallyAddedDeviceIDArr.push(NotClearedDeviceIDs[i]["DeviceID"])
                        }
                    }

                    if(NotClearedDeviceIDs != null && NotClearedDeviceIDs.length > 0 && modifiedState.ForceFullyResetOwner == false) {

                        let strClearanceMsg = `Owner could not get cleared for following DeviceID(s):` + `\n` +
                                                `${modifiedState.manuallyAddedDeviceIDArr}` + `\n` +
                                                `Reason being the Device is in 'Sold', 'Demo', 'Mapped to Owner' or 'Mapped as Replacement' state.` + `\n` +
                                                `Do you still want to go ahead and clear the Device Owner ?` + `\n` +
                                                `If 'Yes', click 'OK' and you will be required to enter an Admin credential.` + `\n` +
                                                `Otherwise click 'Cancel'.`;

                        if(window.confirm(strClearanceMsg)){
                            modifiedState.OpenForm = true;

                        } else {
                            // no need to process any thing.
                        }
                    } else if(NotClearedDeviceIDs != null && NotClearedDeviceIDs.length > 0 && modifiedState.ForceFullyResetOwner == true) {
                        modifiedState.ForceFullyResetOwner = false;  
                        alert ("The Login Credentials are Invalid or the User does not have required Privileges for Clearing the Device Owner");

                    } else{
                        modifiedState.ForceFullyResetOwner = false;  
                        alert ("Successfully Cleared Device Owner.");
                    }
                    this.setState(modifiedState);
                } else {
                    if(response.data.code == 'SQL_ERROR' || response.data.code == 'REQ_PARAMS_MISSING') {
                        // Tell the user that Server is experiencing errors
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    } else if(response.data.code == 'USER_PASSWORD_INVALID'){
                        modifiedState.ForceFullyResetOwner = false;  
                        modifiedState.errors.others = 'Password Invalid';
                    } else {
                        console.log('Should not reach here');
                        modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                    }
                    this.setState(modifiedState);
                }
            })
            .catch( error => {
                console.log("Network error:");
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                } else {
                    // Tell the user that there are network issues
                    modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    // modifiedState.errors.others = "Network issues.\nCheck your Internet and Try again later.";
                    this.setState(modifiedState);
                }
            });
        }       
    }

    handleError = err => {
        alert("There is some problem while loading QRCode Scanner. Please try again later.");
    }

    onCloseLoginPopup = (e) => {
        let modifiedState = this.state;
        modifiedState.OpenForm = false;
        modifiedState.email = '';
        modifiedState.password = '';
        this.setState(modifiedState)
    }

    openQRCameraForGetDevc = async () => {

        let result = await navigator.permissions.query({name:'camera'}).then(function(result) {
            // Will return ['granted', 'prompt', 'denied']
            return result;
        })

        if(result.state == "denied") {
            alert("Camera permission is blocked for SmartHHM. Please enable Camera permission in browser.");
            return;
        } 
        
        this.setState(prevState => {
            let modifiedState = prevState;
            modifiedState.rplcDevcQRCodeFlag = !modifiedState.rplcDevcQRCodeFlag;
            modifiedState.errors.others = "";
            return modifiedState
        })
    }

        handleScanResultOfDevc = (data) => {
        if(data) {
            let modifiedState = this.state;
            modifiedState.selectedDeviceID = data;
            modifiedState.rplcDevcQRCodeFlag = false;
            this.setState(modifiedState)
        }
    }

    handleChange = (event) => {
        let modifiedState = this.state;    
        const { name, value } = event.target;
      
        switch (name) {
          case 'email': 
            modifiedState.errors.email = validEmailRegex.test(value) ? '' : "EmailID is not valid!";
            modifiedState.email = value;
            break;
          case 'password': 
            modifiedState.errors.password = value.length < 8 ? "password must be 8 characters long!" : '';
            modifiedState.password = value;
            break;
          default:
            break;
        }  
        this.setState(modifiedState);
    }

    render() {
        const color = {
          color : "var(--errorColor)",
          fontSize:"13px",
          marginLeft: "2rem",
        }
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="container col-lg-8 col-lg-offset-2
                                              col-md-12 col-md-offset-2">
                        <div className="modal-body box">
                            <div className="" id="ShowAddedManualDeviceID">
                                <div>
                                    <label className="reg-form-label">Scan or Enter Device ID:</label>
                                    <div style={{display:"flex", className:""}}>
                                        <span style={{width:"", flex:"8"}} >
                                            <input type="text"
                                                className="input-form" 
                                                value={this.state.selectedDeviceID}  
                                                onChange={this.onChangeDeviceID} 
                                            />
                                            <label onClick= {this.openQRCameraForGetDevc} className = "qrcodeTextBtn">
                                                <FaQrcode className = "qrcodeTextBtnIcon"/>
                                            </label>
                                            <div>
                                                {(this.state.rplcDevcQRCodeFlag == true)
                                                ?
                                                    // <div style={{display: "flex", justifyContent: "center", marginTop:"1rem"}}>
                                                    //     <QrReader
                                                    //         scanDelay={300}
                                                    //         onResult={(result, error) => {
                                                    //             if (!!result) {
                                                    //                 this.handleScanResultOfDevc(result?.text);
                                                    //             }
                                                    //         }}
                                                    //         className = "QRCodeCamBoxForModalAndContainer"
                                                    //     />
                                                    // </div>
                                                    
                                                    <div style={{display: "flex", justifyContent: "center", width: "50%",
                                                        marginTop:"2rem", marginBottom: "2rem", display: "block", marginLeft: "auto",
                                                        marginRight: "auto"}}>
                                                        <QrScanner
                                                            scanDelay={300}
                                                            onResult={(result, error) => {
                                                                if (!!result) {
                                                                    this.handleScanResultOfDevc(result?.text);
                                                                }
                                                            }}
                                                            className = "QRCodeCamBoxForModalAndContainer"
                                                        />
                                                    </div>
                                                :
                                                    <div/>
                                                }
                                            </div>
                                        </span>
                                        <span style={{marginLeft:"1rem", flex:"1"}}>
                                            <button type="button" 
                                                    style={{padding:"0.4rem", borderRadius:"5px" }} 
                                                    className=" reg-btn" 
                                                    onClick={this.onClickAddDataList}> ADD 
                                            </button>
                                        </span>
                                    </div>
                                    <div>
                                        {this.state.errors.invalidDevcID.length > 0 && 
                                        <p style={{color:"Red", fontSize:"0.8rem", textAlign:"left"}} className='error'>{this.state.errors.invalidDevcID}</p>}
                                    </div>
                                </div>
                                <div className="inputgroupCustom" style={{marginTop:"1.2rem"}}>
                                    <label className="reg-form-label">Displaying Selected DeviceIDs:</label>
                                    <select  className="input-form" size="5" style={{height:"5%" }}>
                                        {(this.state.manuallyAddedDeviceIDArr).map((addedDevcID,index) => <option key={index} value={addedDevcID}>{addedDevcID}</option>)}
                                    </select>
                                    <div className = "buttonErrorMessage">
                                        {this.state.errors.ownerNotCleared.length > 0 && 
                                            <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.ownerNotCleared}</p>}
                                    </div>
                                </div>                                    
                                </div>
                                <div className="form-group reg-form" style={{marginTop:"1.5rem"}}>
                                    <button type="button" className="btn-lg reg-btn" style={{ textTransform: "capitalize"}} onClick={this.onSubmitResetDeviceOwnerEmailID}>Clear Device Owner</button>
                                    <div className = "buttonErrorMessage">
                                        {this.state.errors.others.length > 0 && 
                                            <p style={{color:"Red", fontSize:"0.9rem", textAlign:"center"}} className='error'>{this.state.errors.others}</p>}
                                    </div>
                                </div>
                                <div className="adduser-blackfilm" hidden={this.state.OpenForm == false}>    
                                <div className="container">
                                    <div className="row">
                                        <div className="container col-lg-4 col-lg-offset-4
                                                                col-md-6 col-md-offset-3">
                                            <div className="mvAddScroll">
                                            <div className="adduser-popup">
                                                <form>
                                                    <div className="popup-scroll">
                                                        <h6 className="deviceDetail">Login</h6>
                                                        <div className="form-group update-form">
                                                            <div className="inputgroupCustom">
                                                                <label className="adduser-form-label">Email ID:</label>
                                                                <input type='email' name='email' 
                                                                        className="input-form" 
                                                                        required="required" 
                                                                        value = {this.state.email}
                                                                        onChange={this.handleChange}
                                                                        noValidate 
                                                                />
                                                                    {this.state.errors.email.length > 0 && 
                                                                    <span style={color} className='error'>{this.state.errors.email}</span>} 
                                                            </div>
                                                        </div>

                                                        <div className="form-group update-form">
                                                            <div className="inputgroupCustom">
                                                                <label className="adduser-form-label">Password:</label>
                                                                <input type='password' name='password' className="input-form" required="required" value = {this.state.password} onChange={this.handleChange} noValidate/>
                                                                {this.state.errors.password.length > 0 && 
                                                                <p style={color} className='error'>{this.state.errors.password}</p>}
                                      
                                                            </div>
                                                        </div>
                                                        <div style={{display:"flex", justifyContent:"center", alignItems: "center"}}>
                                                            <button type="button" 
                                                                    className="adduserButton btn-lg"
                                                                    style={{fontSize:"1rem", fontWeight: "bold"}}
                                                                    onClick={this.onLoginSumbit}> 
                                                                    PROCEED
                                                            </button>
                                                            <button type="button" 
                                                                    className="adduserButton btn-lg"
                                                                    style={{fontSize:"1rem", fontWeight: "bold"}}
                                                                    onClick={this.onCloseLoginPopup}> 
                                                                    CANCEL
                                                            </button>
                                                        </div>
                                                        {this.state.errors.loginIssue.length > 0 && 
                                                            <p style={color} className='error'>{this.state.errors.loginIssue}</p>}

                                                    </div>
                                                </form>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}

export default VcResetDevcOwnerEmailID;
    