import React, { useState, useContext, useEffect } from 'react';
import {Navigate} from 'react-router-dom';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import App from '../../App';
import VcAdminEnrollFieldEngineer from './VcAdminEnrollFieldEngineer';
import {IDS_AdminEnrollFiledEng, IDS_LoginServerIssue, IDS_RegistNetworkError} from '../../VcLanguage';
import { AppRelevantDataContext} from '../../AppContext';
import VcDefineNewFirmware from './VcDefineNewFirmware';
import VcDeployViaOTA from './VcDeployViaOTA';
import axios from 'axios';
import { getAPIHostURL} from '../../ClientConfig';
import { PVG_FWMR_ADMIN, PVG_ADMIN } from '../../VcConstants';
import VcCrmUsersAndPrivileges from './VcCrmUsersAndPrivileges';
import {useNavigate} from 'react-router-dom';
// var CryptoJS = require("crypto-js");
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import VcResetDevcOwnerEmailID from './VcResetDevcOwnerEmailID';
import VcUpdateNewAppVersion from './VcUpdateNewAppVersion';
import VcSideNav from './DeviceHome/VcSideNav';
import VcNavBar from "./VcNavbar";

function VcAdminHome (props) {
    const context = useContext(AppRelevantDataContext);
    const navigate = useNavigate();
    
    const [state, setState] = useState({
        modal: false,
        backdrop: 'static',
        CrmUsersAndPrivilegesModal: false,
        uploadFWModal:false,
        deployFWModal: false,
        resetOwnerEmailID: false,
        // resetAppVersion: false,
        PrivilegeEncKey: "",
        objPrivilege:{},
        EnctyptedPrivilege: "",
        windowWidth: window.innerWidth,
        errors: {
            others:""
        }
    });

    const handleResize = () => {
        if ((window.innerWidth <= 570 && state.windowWidth > 570) || (window.innerWidth > 570 && state.windowWidth <= 570)) {
            setState(prevState => ({
                ...prevState,
                windowWidth: window.innerWidth
            }))
        } 
    }

    const toggle = (e) => {
        let modifiedState = state;

        modifiedState.modal = !modifiedState.modal;
        setState({...modifiedState});
    }

    const toggleCrmUsersAndPrivileges = (e) => {
        let modifiedState = state;
        modifiedState.CrmUsersAndPrivilegesModal = !modifiedState.CrmUsersAndPrivilegesModal;
        setState({...modifiedState});
    }

    const toggleUploadFW = (e) => {
        let modifiedState = state;
        modifiedState.uploadFWModal = !modifiedState.uploadFWModal;
        setState({...modifiedState});
    }

    const toggleDeployFW = (e) => {
        let modifiedState = state;
        modifiedState.deployFWModal = !modifiedState.deployFWModal;
        setState({...modifiedState});
    }

    const toggleResetDeviceOwnerEmailId = (e) => {
        let modifiedState = state;
        modifiedState.resetOwnerEmailID = !modifiedState.resetOwnerEmailID;
        setState({...modifiedState});
    }
    
    // const toggleAddOrUpdateAppVersion = (e) => {
    //     let modifiedState = state;
    //     modifiedState.resetAppVersion = !modifiedState.resetAppVersion;
    //     setState({...modifiedState});
    // }

    useEffect(() => {
        let appRelevantDataContextValue = context; // Get all the relevant data from AppContext
    
        if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
          // User login information is not present in appRelevantDataContext.
          // Try to get this value if present in LocalStorage and update the app state (so that
          // re-render will happen). If app context data not present in LocalStorage then
          // show the Login Page
          if ( appRelevantDataContextValue.updateAppRelevantDataContextFromLocalStorage() == false ) {
            // App Relevant Data Context not present in LocalStorage as well.
            navigate('/', { replace: true });
          } else {
            // App Relevant Data Context loaded from LocalStorage at AppLeval.
            // Re-render will happen automatically (no action required).
          }
        } else {
            checkPrivilegeOfLoggedInUser();
        }

    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize());
        }
    }, [state.windowWidth <= 570])

    useEffect(() => {
        let currentState = state;
        let appRelevantDataContextValue = context;  // Get all the relevant data from AppContext
    
        if( appRelevantDataContextValue == null ||
            ("loggedInUserInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.loggedInUserInfo == null ||
            ("userID" in appRelevantDataContextValue.loggedInUserInfo) == false ||
            appRelevantDataContextValue.loggedInUserInfo.userID == null ||
            appRelevantDataContextValue.loggedInUserInfo.userID.length <= 0 ||
            ("selectedNodeInfo" in appRelevantDataContextValue) == false ||
            appRelevantDataContextValue.selectedNodeInfo == null ||
            ("nodeID" in appRelevantDataContextValue.selectedNodeInfo) == false ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID == null ||
            appRelevantDataContextValue.selectedNodeInfo.nodeID.length <= 0
        ) {
            console.log("VcAdminHome:componentDidUpdate - First time render. AppRelevantDataContext does not have LoggedIn User information or Information about the Node to be Selected.");
            return; // Do not process further
        }
    
        if(appRelevantDataContextValue.loggedInUserPrivilege.Privilege != currentState.EncryptedPvg) {
          // on Refresh values stored in LocalStorage doesnot refelect into appcontext hence 
          // we need to call componenentDidUpdate which again take privilege from context and store into state.
          checkPrivilegeOfLoggedInUser();
        }
    }, [context]);

    const checkPrivilegeOfLoggedInUser = () => {
        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;  

        let encryptedPrivileges = appRelevantDataContextValue.loggedInUserPrivilege.Privilege;
        modifiedState.EncryptedPvg = encryptedPrivileges;
        modifiedState.selectedTreeNodeID = appRelevantDataContextValue.selectedNodeInfo.nodeID;
        modifiedState.selectedTreeNodeTitle = appRelevantDataContextValue.selectedNodeInfo.nodeTitle;  
        modifiedState.LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        axios.post(`${getAPIHostURL()}/wclient/getEncChaabi`)
        .then(response =>{
            if(response.data.code == 'SUCCESS') {
               if(response.data.retrievedEncChaabi == null || response.data.retrievedEncChaabi.length <= 0) {
                    modifiedState.errors.others = `Unable to get encryption key.`;
               } else {
                   modifiedState.PrivilegeEncKey = response.data["retrievedEncChaabi"][0]["PassKey"];

                //    let bytes  = CryptoJS.AES.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);
                   let bytes  = aes.decrypt(encryptedPrivileges.toString(), modifiedState.PrivilegeEncKey);

                //    let strPrivilege = bytes.toString(CryptoJS.enc.Utf8);
                   let strPrivilege = bytes.toString(enc);

                   try {
                        modifiedState.objPrivilege = JSON.parse(strPrivilege);
                   } catch(e) {
                        console.log(`Should not happen. The Privilege obtained from Context is in invalid JSON format.`);
                   }
               }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    modifiedState.errors.others = 'Server experiencing issues.\n Try again later.';
                } else {
                    console.log("Should not reach here");
                    modifiedState.errors.others = t(IDS_LoginServerIssue);
                }
            }
            setState({...modifiedState});
        })
        .catch(err => {
            console.log("Network error");
            console.log(err);
            if (axios.isCancel(err)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = t(IDS_RegistNetworkError);
                console.log(modifiedState.errors.others);
                setState({...modifiedState});
            }
        }) 
    }

    const renderRedirect = () => {
        return<Navigate to="/VcAdminEnrollFieldEngineer"/>    
    }    

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    if( appRelevantDataContextValue.loggedInUserInfo.userFirstName.length <= 0 ) {
        // User login information is not present in appRelevantDataContext.

        // Next re-render will happen automatically when the AppState is updated again 
        // from LS in ComponentDidMount, (meanwhile 'Loading Page' shown)
        return App.renderLoadingPage();
    }

    return (
        (state.windowWidth <= 570) ? 
            <div className="d-flex">
                <div className="treeAndContentCRM">
                    <VcNavBar />

                    <div className="container">
                        <div className="row align-items-center"  style={{height: "100vh"}}>
                            <div className="container col-lg-6 col-lg-offset-3 centered col-md-6 col-md-offset-3">
                                <div className="modal-body p-4 login" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <form>                            
                                        <div className="logform">
                                            {state.objPrivilege != null &&
                                                state.objPrivilege.hasOwnProperty(PVG_ADMIN) &&
                                                state.objPrivilege[PVG_ADMIN] == true 
                                            ?
                                                <div>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggle}>Enroll Field Engineer
                                                                {/* {t(IDS_AdminEnrollFiledEng)} */}
                                                    </button>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleCrmUsersAndPrivileges}>CRM Users And Privileges
                                                    </button>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleUploadFW}>Upload New Firmware or Firmware Version
                                                    </button>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleDeployFW}>Deploy Firmware Updates via OTA
                                                    </button>
                                                    {/* <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleResetDeviceOwnerEmailId}>Clear Device Owner
                                                    </button> */}
                                                    {/* <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleAddOrUpdateAppVersion}>Add Or Update New App Version
                                                    </button> */}
                                                </div>
                                            : (state.objPrivilege != null &&
                                                state.objPrivilege.hasOwnProperty(PVG_FWMR_ADMIN) &&
                                                state.objPrivilege[PVG_FWMR_ADMIN] == true)
                                            ? 
                                                <div>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleUploadFW}>Upload New Firmware or Firmware Version
                                                    </button>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleDeployFW}>Deploy Firmware Updates via OTA
                                                    </button>

                                                </div>
                                            :   null    
                                            }
                                        </div>
                                    </form>
                                    <Modal size="xl" isOpen={state.modal} toggle={toggle} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggle}>Enroll Field Engineer</ModalHeader>
                                        {/* <ModalHeader toggle={toggle}>{t(IDS_AdminEnrollFiledEng)}</ModalHeader> */}
                                        <ModalBody>     
                                            <VcAdminEnrollFieldEngineer/>
                                        </ModalBody>
                                    </Modal>

                                    <Modal size="xl" isOpen={state.CrmUsersAndPrivilegesModal} toggle={toggleCrmUsersAndPrivileges} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggleCrmUsersAndPrivileges}>CRM Users And Privileges</ModalHeader>
                                        <ModalBody>     
                                            <VcCrmUsersAndPrivileges/>
                                        </ModalBody>
                                    </Modal>

                                    <Modal size="xl" isOpen={state.uploadFWModal} toggle={toggleUploadFW} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggleUploadFW}>Upload New Firmware or Firmware Version</ModalHeader>
                                        <ModalBody>     
                                            <VcDefineNewFirmware/>
                                        </ModalBody>
                                    </Modal>

                                    <Modal size="xl" isOpen={state.deployFWModal} toggle={toggleDeployFW} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggleDeployFW}>Deploy Firmware Updates via OTA</ModalHeader>
                                        <ModalBody>   
                                            <VcDeployViaOTA/>
                                        </ModalBody>
                                    </Modal>

                                    {/* <Modal size="xl" isOpen={state.resetOwnerEmailID} toggle={toggleResetDeviceOwnerEmailId} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggleResetDeviceOwnerEmailId}>Clear Device Owner</ModalHeader>
                                        <ModalBody>   
                                            <VcResetDevcOwnerEmailID/>
                                        </ModalBody>
                                    </Modal> */}

                                    {/* <Modal size="xl" isOpen={state.resetAppVersion} toggle={toggleAddOrUpdateAppVersion} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggleAddOrUpdateAppVersion}>Add Or Update New App Version</ModalHeader>
                                        <ModalBody>   
                                            <VcUpdateNewAppVersion/>
                                        </ModalBody>
                                    </Modal> */}

                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        :
            <div className="d-flex">
                <div className='d-flex' style={{ width: "4%" }}>
                    <div className="shadow sideNav" style={{ width: "100%" }}>
                        <VcSideNav />
                    </div>
                </div>

                <div className="treeAndContentCRM">
                    <VcNavBar />

                    <div className="container">
                        <div className="row align-items-center"  style={{height: "100vh"}}>
                            <div className="container col-lg-6 col-lg-offset-3 centered col-md-6 col-md-offset-3">
                                <div className="modal-body p-4 login" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <form>                            
                                        <div className="logform">
                                            {state.objPrivilege != null &&
                                                state.objPrivilege.hasOwnProperty(PVG_ADMIN) &&
                                                state.objPrivilege[PVG_ADMIN] == true 
                                            ?
                                                <div>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggle}>Enroll Field Engineer
                                                                {/* {t(IDS_AdminEnrollFiledEng)} */}
                                                    </button>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleCrmUsersAndPrivileges}>CRM Users And Privileges
                                                    </button>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleUploadFW}>Upload New Firmware or Firmware Version
                                                    </button>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleDeployFW}>Deploy Firmware Updates via OTA
                                                    </button>
                                                    {/* <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleResetDeviceOwnerEmailId}>Clear Device Owner
                                                    </button> */}
                                                    {/* <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleAddOrUpdateAppVersion}>Add Or Update New App Version
                                                    </button> */}
                                                </div>
                                            : (state.objPrivilege != null &&
                                                state.objPrivilege.hasOwnProperty(PVG_FWMR_ADMIN) &&
                                                state.objPrivilege[PVG_FWMR_ADMIN] == true)
                                            ? 
                                                <div>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleUploadFW}>Upload New Firmware or Firmware Version
                                                    </button>
                                                    <button type="button" 
                                                            className="btn-lg logbtn" 
                                                            width = "100px" 
                                                            style={{ textTransform: "capitalize"}} 
                                                            onClick={toggleDeployFW}>Deploy Firmware Updates via OTA
                                                    </button>

                                                </div>
                                            :   null    
                                            }
                                        </div>
                                    </form>
                                    <Modal size="xl" isOpen={state.modal} toggle={toggle} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggle}>Enroll Field Engineer</ModalHeader>
                                        {/* <ModalHeader toggle={toggle}>{t(IDS_AdminEnrollFiledEng)}</ModalHeader> */}
                                        <ModalBody>     
                                            <VcAdminEnrollFieldEngineer/>
                                        </ModalBody>
                                    </Modal>

                                    <Modal size="xl" isOpen={state.CrmUsersAndPrivilegesModal} toggle={toggleCrmUsersAndPrivileges} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggleCrmUsersAndPrivileges}>CRM Users And Privileges</ModalHeader>
                                        <ModalBody>     
                                            <VcCrmUsersAndPrivileges/>
                                        </ModalBody>
                                    </Modal>

                                    <Modal size="xl" isOpen={state.uploadFWModal} toggle={toggleUploadFW} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggleUploadFW}>Upload New Firmware or Firmware Version</ModalHeader>
                                        <ModalBody>     
                                            <VcDefineNewFirmware/>
                                        </ModalBody>
                                    </Modal>

                                    <Modal size="xl" isOpen={state.deployFWModal} toggle={toggleDeployFW} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggleDeployFW}>Deploy Firmware Updates via OTA</ModalHeader>
                                        <ModalBody>   
                                            <VcDeployViaOTA/>
                                        </ModalBody>
                                    </Modal>

                                    {/* <Modal size="xl" isOpen={state.resetOwnerEmailID} toggle={toggleResetDeviceOwnerEmailId} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggleResetDeviceOwnerEmailId}>Clear Device Owner</ModalHeader>
                                        <ModalBody>   
                                            <VcResetDevcOwnerEmailID/>
                                        </ModalBody>
                                    </Modal> */}

                                    {/* <Modal size="xl" isOpen={state.resetAppVersion} toggle={toggleAddOrUpdateAppVersion} backdrop={state.backdrop}>
                                        <ModalHeader toggle={toggleAddOrUpdateAppVersion}>Add Or Update New App Version</ModalHeader>
                                        <ModalBody>   
                                            <VcUpdateNewAppVersion/>
                                        </ModalBody>
                                    </Modal> */}

                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
    )
}

export default VcAdminHome;