import React, {useState, useEffect, useContext} from 'react';
import {useTable, useBlockLayout, useResizeColumns, usePagination, useSortBy, useFilters}  from 'react-table';
import { getAPIHostURL } from '../../ClientConfig';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { IDS_RegistEmailError, IDS_PrevBtn, IDS_NxtBtn, IDS_Search, IDS_TodaySearch, IDS_AUServerError} from '../../VcLanguage';
import { convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS, convertLocalDateToDisplayToday} from '../../vtUtil';
import { FaSearch, FaEdit, FaIoMdPersonAdd, FaEye} from 'react-icons/fa';
import { IoMdAddCircleOutline} from 'react-icons/io';
import { AppRelevantDataContext } from '../../AppContext';
import { trimStringAndRemoveTrailingComma } from '../../vtUtil';
import '../CSS/VcCustomer.css'
import { DEFAULT_SUB_GRACE_PERIOD_IN_DAYS, DEFAULT_SUB_WARNING_PERIOD_IN_DAYS } from '../../VcConstants';
import '../CSS/ReactTable.css';
import { Pagination, Select } from 'antd';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
        <div style={{textOverflow: "ellipsis",whiteSpace: "nowrap", paddingLeft: "0.1rem", paddingRight: "0.3rem"}}>
            <FaSearch style={{marginRight:"0.3rem",color:"var(--secondaryColor)", fontSize:"1rem"}}/>
            <input
                value={filterValue || ''}
                 onChange={e => {
                   setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                 }}
                placeholder={"Search"}
                style={{fontSize:"0.8rem",width: "85%", height:"100%", padding: "0.3rem", border:"1px solid rgba(0,0,0,.1)"}}
            />
        </div>
    );
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const ReactTable = ({ columns, data, passedStateVariable, getCellProps = defaultPropGetter }) => {

    // Allows overriding or adding additional filter types for columns to use
    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
            },
        }),
        []
    );

    // This is particularly useful for adding global column properties.
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 450,
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        prepareRow,
        state: { pageIndex, pageSize},
        getRowProps = defaultPropGetter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // set page size based on tables
            initialState: {pageSize: 10 },
            filterTypes,
            // whichever function called from react table which is inside main function
            // should be passed to react table and added in useTable
            // onEditProductDetails,
            // onViewProductDetails
        },
        useBlockLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
    );

    // if the state.goToPage1 is true and if new data is inserted
    // go to page 1
    React.useEffect(() =>{
        if(passedStateVariable == true){
            gotoPage(0)
        }

    },[passedStateVariable]);

    //uses the table header group props for the empty rows so resizing and flex layout still works
    const createEmptyRow = (NoData=false) => {
        return(
            <tr className = "tr"
                style= {{
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }}
            >
                <td className = "td"
                >
                    {NoData == true?
                    <div><span>&nbsp;No Product Details Found.</span></div>
                    :
                    <div><span>&nbsp;</span></div>
                    }
                </td>
            </tr>
                
        )
    };

    //creating empty padding cells
    const getEmptyRow = () => {
        let emptyRows = [];

        if(page.length%pageSize !== 0 && !canNextPage){
            for (let i = 0; i < (pageSize - page.length%pageSize); i++)
                emptyRows.push(createEmptyRow(false));

                // if(i == 0){
                //     emptyRows.push(createEmptyRow(true));
                // } else{
                // emptyRows.push(createEmptyRow(false));
                // }
        }

        if(data.length === 0 || page.length === 0){
            for (let i = 0; i < pageSize; i++){
                // emptyRows.push(createEmptyRow());
                if(i == 0){
                    emptyRows.push(createEmptyRow(true));
                } else{
                emptyRows.push(createEmptyRow(false));
                }
            }
        }

        return emptyRows
    };
    
    return (
        <div>
            <div className='tableWrap'> 
                <table {...getTableProps()}  style={{overflow:'auto'}} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="trForHeader" >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tdForHeader">
                                <div className='Header'>
                                    {column.render('Header') }
                                <div className='fa' >
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <FaArrowDownLong />
                                        : <FaArrowUpLong />
                                    : ''}
                                </div>
                                </div>
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                    // to stop other clicking events when resizing
                                    onClick={(event)=> event.stopPropagation()}
                                />
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>

                    <thead >
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}  className="trforSearchField">
                                {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className="tdForSearchField">
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                        {/* {page.length > 0 &&  */}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}
                                    className = "tr" 
                                    style={{
                                        cursor: '',
                                        background: '',
                                        color: 'black',
                                        alignItems: "center",
                                    }}  
                                >
                                    {row.cells.map(cell => {
                                    return <td {...cell.getCellProps(
                                        [
                                            {style: cell.column.style},
                                            getCellProps(cell),
                                        ]
                                    )} className="td" 
                                    // onClick = {() =>{ 
                                    //                 if(cell.column.id == 'view'){
                                    //                     onViewProductDetails(row, cell.column);
                                    //                 } else if(cell.column.id == 'edit'){
                                    //                     onEditProductDetails(row, cell.column);
                                    //                 }
                                    //             }}
                                    >{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }) 
                        // || 
                        //     // when there is no data found 
                        //     <tr style = {{backgroundColor: "white"}}>
                        //         <td>
                        //             <span  style={{paddingLeft:"1rem", color: "green", display: "flex" ,justifyContent:"left"}}>{"No Product Details Found."}</span>
                        //         </td>
                        //     </tr> 
                        }
                        {getEmptyRow()}
                    </tbody>
                </table>
            </div>

            <div className='w-100 d-flex justify-content-end antdPagination'>
                <div className='d-flex py-1 justify-content-end align-items-center'>
                    <Pagination showQuickJumper 
                        defaultCurrent={pageIndex + 1} 
                        total={pageOptions.length * 10}
                        // total={10 * 10} 
                        onChange={(page) => gotoPage(page - 1)} 
                        showSizeChanger={false}
                        current={pageIndex + 1} 
                    />

                    <Select
                        style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
                        aria-label="rows per page"
                        className="paginationDropdown mySelector"
                        value={pageSize} // Assuming quickTrackParam is the state variable holding the selected value
                        onChange={e => setPageSize(Number(e))} 
                        options={[
                            {
                                label: "5 rows",
                                value: 5
                            },
                            {
                                label: "10 rows",
                                value: 10
                            },
                            {
                                label: "20 rows",
                                value: 20
                            },
                            {
                                label: "25 rows",
                                value: 25
                            },
                            {
                                label: "100 rows",
                                value: 100
                            }
                        ]} // Assuming options is the array of options for the Select component
                    />
                </div>
            </div>

            {/* <div>
                <div className="-pagination">
                    <div className="-previous">
                        <button onClick={() => previousPage()} type="button" disabled={!canPreviousPage} className="-btn">Prev</button>
                    </div>
                    <div className="-center">
                        <span className="-pageInfo">
                            Page {" "}    
                            <div className="-pageJump">
                                <input 
                                    value={pageIndex + 1} 
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    aria-label="jump to page" type="number"
                                />
                            </div>
                            of {" "}  
                            <span className="-totalPages">{pageOptions.length}</span>
                        </span>
                        <span className="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page"
                                style={{padding:"0.2rem"}}
                                value={pageSize}
                                onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                                {[5, 10, 20, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                    {pageSize} rows
                                    </option>
                                ))}
                            </select>
                        </span>  
                    </div>
                    <div className="-next">
                        <button onClick={() => nextPage()} type="button" disabled={!canNextPage} className="-btn">Next</button>
                    </div>
                </div>
            </div>         */}
        </div>
    );
};

function VcProductSubscription (props) {
    const searchStringStyle = {fontSize: "0.8rem", width:"85%"};
    const context = useContext(AppRelevantDataContext);
    
    const filterCaseInsensitive = (rows, id, filterValue) => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        if (id == "SubscriptionLastModifiedTime"){
            return rows.filter(row => {

                let splittedStr = row.values[id].split(" ");
                const rowValue = row.values[id]
                        
                if(String("Today".toString().toLowerCase()).includes(filterValue.toLowerCase()) &&
                            new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {

                    // Only for the case where the string entered in the filter box is present in cell
                    // then show all the rows which includes the input string in the final result (by returning true)
                    // It will return only those rows which contains a current date.                
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                            new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                            new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase() &&
                            String(splittedStr[1].toString().toLowerCase()).includes(filterValue.toString().toLowerCase()) ) {
                    // only for A "Today" case where Searching will always happens in a Time String.
                    return true;
                } else if(new Date(rowValue).getDate().toString().toLowerCase() == new Date().getDate().toString().toLowerCase() &&
                        new Date(rowValue).getMonth().toString().toLowerCase() == new Date().getMonth().toString().toLowerCase() &&
                        new Date(rowValue).getFullYear().toString().toLowerCase() == new Date().getFullYear().toString().toLowerCase()
                ) {
                    // It will return only those rows which should not contains a current date.
                    return false;
                }
                else {
                    return String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                }
            });
        } else {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue.toString().toLowerCase()).includes(filterValue.toLowerCase())
                    : false
            });
        }
    };

    const [state, setState] = useState({
        modal: false,
        showModal: false,
        backdrop: 'static',

        ProductLineArr: [],
        BasicUnitArr: [],
        AllProductsInformation: [],
        filteredProductInfoBasedOnProductLine: [],
        ProdDescription: "",
        subNameCode: "",
        subBasicUnit: "",
        subPricePerUnit: "",
        subTaxPrcntPerUnit: "",
        subGracePeriod: DEFAULT_SUB_GRACE_PERIOD_IN_DAYS,
        subWarningPeriod: DEFAULT_SUB_WARNING_PERIOD_IN_DAYS,
        selectedProductLine: "",
        selectedProdNameCode: "",
        selectedProductName: "",
        showModal: false,
        goToPage1: false,
        formViewMode: "",
        LastModifiedUtcDtTmOfReceivedSubData: null,
        subscriptionTableData: [],
        noDataFound: "",
        ProductCode: "",
        subscriptionID: "",

        originalSubNameCode: "",
        originalSubBasicUnit: "",
        originalSubPricePerUnit: "",
        originalSubTaxPrcntPerUnit: "",
        originalSubGracePeriod: "",
        originalSubWarningPeriod: "",
        errors: {
            others: "",
            ProductLine: "",
            ProdName: "",
            subNameCode: "",
            subBasicUnit: "",
            subPricePerUnit: "",
            subTaxPrcntPerUnit: "",
            subGracePeriod: "",
            subWarningPeriod: "",
        },
        columns: [
            {       
            Header:() => <div className="AlertLogTableHeader">View</div>,
                accessor: 'view',
                width: 80,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
                Cell: (props) =><span className='deviceNameCell'>{props.value}</span>
            }, 
            {   
                Header:() => <div className="AlertLogTableHeader">Edit</div>, 
                accessor: "edit",
                width: 80,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
                disableFilters: true,
                Cell: (props) => <span className='deviceNameCell'>{props.value}</span>
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Product<br/>Code</div>,  
                accessor: 'ProductCode',
                width: 110,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Product<br/>Name Code</div>,
                accessor: 'selectedProdNameCode',
                width: 155,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Product<br/>Name</div>,
                accessor: 'selectedProductName',
                width: 210,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },    
            {       
                Header:() => <div className="AlertLogTableHeader">Product<br/>Description</div>,
                accessor: 'ProdDescription',
                width: 260,
                style:({
                    textAlign: "left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Subscription<br/>ID</div>,  
                accessor: 'SubscriptionID',
                width: 138,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Subscription<br/>Name<br/>Code</div>,  
                accessor: 'SubscriptionNameCode',
                width: 138,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Subscription<br/>Period<br/>Basic Unit</div>,  
                accessor: 'SubscriptionBasicUnit',
                width: 138,
                style:({
                    textAlign: "center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            {       
                Header:() => <div className="AlertLogTableHeader">Subscription<br/>Price Per Unit<br/>Period (INR)</div>,
                accessor: 'SubscriptionPricePerUnitINR',
                width: 145,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },   
            {       
                Header:() => <div className="AlertLogTableHeader"> Subscription<br/>Tax Per Unit<br/>Period (%)</div>,
                accessor: 'SubscriptionTaxPrcntPerUnit',
                width: 138,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },

            {       
                Header:() => <div className="AlertLogTableHeader"> Subscription<br/>Warning<br/>Period (Days)</div>,
                accessor: 'SubscriptionWarningPeriod',
                width: 142,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },

            {       
                Header:() => <div className="AlertLogTableHeader"> Subscription<br/>Grace<br/>Period (Days)</div>,
                accessor: 'SubscriptionGracePeriod',
                width: 142,
                style:({
                    textAlign:"center",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
                filter: filterCaseInsensitive,
            },
            
            { 
                Header:() => <div className="AlertLogTableHeader">Subscription<br/>Last<br/>Modified Time</div>, 
                Cell: (props) => getCustomizedTodaysDate(props.value),
                accessor: 'SubscriptionLastModifiedTime',
                sortType: (firstRow, secondRow, columnId) => {
                    const rowFirst = new Date(firstRow.original[columnId].toLowerCase()).getTime();
                    const rowSecond = new Date(secondRow.original[columnId].toLowerCase()).getTime();
                    return rowFirst > rowSecond ? 1 : -1
                },
                width:190,
                filter: filterCaseInsensitive,
                style:({
                    textAlign:"left",
                    paddingLeft: "1rem",
                    textOverflow: "ellipsis", 
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "2.6rem"
                }),
            },  
        ],
    });
    
    const getCustomizedTodaysDate = (inLogTime) => {

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t; 

        let strLocaleDateTimeToBeDisplayed = convertLocalDateToDisplayToday(inLogTime);
        let splittedDate = strLocaleDateTimeToBeDisplayed.split(" ");

        if(splittedDate[0].toLowerCase() == "Today,".toLowerCase()) {
            return t(IDS_TodaySearch) + splittedDate[1];
        } else {
            return strLocaleDateTimeToBeDisplayed;
        }
    }

    // function will get called on cliking of eye icon, setting the flag formViewMode to viewMode to display form in readonly only.
    // fetching the value from react table selected Product details row and set it in the state.
    // setting modal value to true to display Form view only mode.
    const onViewProductDetails = (rowInfo, column) => {

        let modifiedState = state;
        modifiedState.formViewMode = "viewMode";
        modifiedState.ProductCode = rowInfo.original.ProductCode;
        modifiedState.subscriptionID = rowInfo.original.SubscriptionID;
        modifiedState.selectedProdNameCode = rowInfo.original.selectedProdNameCode;
        modifiedState.selectedProductName = rowInfo.original.selectedProductName;
        modifiedState.selectedProductLine = rowInfo.original.selectedProductLine;
        modifiedState.ProdDescription = rowInfo.original.ProdDescription;
        modifiedState.subNameCode = rowInfo.original.SubscriptionNameCode;
        modifiedState.subBasicUnit = rowInfo.original.SubscriptionBasicUnit;
        modifiedState.subPricePerUnit = rowInfo.original.SubscriptionPricePerUnitINR;
        modifiedState.subTaxPrcntPerUnit = rowInfo.original.SubscriptionTaxPrcntPerUnit;
        modifiedState.subGracePeriod = rowInfo.original.SubscriptionGracePeriod;
        modifiedState.subWarningPeriod = rowInfo.original.SubscriptionGracePeriod;
        
        modifiedState.modal = true;
        setState({...modifiedState});
    }

    // function will get called on clicking of edit icon, setting the flag formViewMode to editMode to display form in Editable screen.
    // fetching the value from react table to allow edting the exsiting details of selected product.
    // setting modal value to true to display Form in Edit mode.
    // also setting product original details(before any changes) to state to compaire it latter on with value of changed fields
    // to propmpt confirmation while saving edited fields.
    const onEditProductDetails = (rowInfo, column) => {
        let modifiedState = state;
        modifiedState.formViewMode = "editMode";
        modifiedState.ProductCode = rowInfo.original.ProductCode;
        modifiedState.subscriptionID = rowInfo.original.SubscriptionID;
        modifiedState.selectedProdNameCode = rowInfo.original.selectedProdNameCode;
        modifiedState.selectedProductName = rowInfo.original.selectedProductName;
        modifiedState.selectedProductLine = rowInfo.original.selectedProductLine;
        modifiedState.ProdDescription = rowInfo.original.ProdDescription;
        modifiedState.subNameCode = rowInfo.original.SubscriptionNameCode;
        modifiedState.subBasicUnit = rowInfo.original.SubscriptionBasicUnit;
        modifiedState.subPricePerUnit = rowInfo.original.SubscriptionPricePerUnitINR;
        modifiedState.subTaxPrcntPerUnit = rowInfo.original.SubscriptionTaxPrcntPerUnit;
        modifiedState.subGracePeriod = rowInfo.original.SubscriptionGracePeriod;
        modifiedState.subWarningPeriod = rowInfo.original.SubscriptionGracePeriod;

        modifiedState.originalSubNameCode = rowInfo.original.SubscriptionNameCode;
        modifiedState.originalSubBasicUnit = rowInfo.original.SubscriptionBasicUnit;
        modifiedState.originalSubPricePerUnit = rowInfo.original.SubscriptionPricePerUnitINR;
        modifiedState.originalSubTaxPrcntPerUnit = rowInfo.original.SubscriptionsubTaxPrcntPerUnit;
        modifiedState.originalSubGracePeriod = rowInfo.original.SubscriptionGracePeriod;
        modifiedState.originalSubWarningPeriod = rowInfo.original.SubscriptionGracePeriod;

        modifiedState.modal = true;
        setState({...modifiedState});
    }

    //putting the flag formViewMode to Insert Mode(Create new Product)
    //toggling the Product create form. 
    const toggle = () => {
        let modifiedState = state;

        modifiedState.modal = !modifiedState.modal
        modifiedState.filteredProductInfoBasedOnProductLine = [];
        modifiedState.ProductLineArr = [];
        modifiedState.AllProductsInformation = [];
        modifiedState.BasicUnitArr = [];
        modifiedState.subscriptionID = "";
        modifiedState.ProductCode = "";
        modifiedState.selectedProdNameCode = "";
        modifiedState.selectedProductName = "";
        modifiedState.selectedProductLine = "";
        modifiedState.ProdDescription = "";
        modifiedState.formViewMode = "";

        modifiedState.subNameCode = "";
        modifiedState.subBasicUnit = "";
        modifiedState.subPricePerUnit = "";
        modifiedState.subTaxPrcntPerUnit = "";
        modifiedState.subGracePeriod = DEFAULT_SUB_GRACE_PERIOD_IN_DAYS;
        modifiedState.subWarningPeriod = DEFAULT_SUB_WARNING_PERIOD_IN_DAYS;

        modifiedState.errors.subNameCode = "";
        modifiedState.errors.subBasicUnit = "";
        modifiedState.errors.subPricePerUnit = "";
        modifiedState.errors.subTaxPrcntPerUnit = "";
        modifiedState.errors.subGracePeriod = "";
        modifiedState.errors.subWarningPeriod = "";

        setState({...modifiedState});


    }

    useEffect(() =>{
        getLatestSubscriptionInfo();
    }, []);

    //when first time page will load we send LastModifiedUtcDtTmForInsertOrUpdateSubDetails empty, 
    //and retrieved all the Customer data from database and filled out in the Customer table.
    //In case of create new customer and edit existing customer details, we send latest LastModifiedUtcDtTmForInsertOrUpdateSubDetails
    //received from customer table, and axios will retrieved the Customer details next to LastModifiedUtcDtTmForInsertOrUpdateSubDetails.
    //we put latest retrived data on top of the table.
    const getLatestSubscriptionInfo = (inModifiedState = null) => {
        
        let modifiedState;
        if(inModifiedState == null) {
            modifiedState = state;
        } else {
            modifiedState = inModifiedState;
        }

        modifiedState.goToPage1 = false;

        let LastModifiedUtcDtTmForInsertOrUpdateSubDetails;
        //In case of create new customer(flag formViewMode == InsertMode) and edit existing customer(flag formViewMode == "editMode") details, 
        //we send latest LastModifiedUtcDtTmForInsertOrUpdateSubDetails recvied from Customer Table
        //or send empty LastModifiedUtcDtTmForInsertOrUpdateSubDetails.
        if(modifiedState.formViewMode == "editMode" || modifiedState.formViewMode == "insertMode"){
            LastModifiedUtcDtTmForInsertOrUpdateSubDetails = modifiedState.LastModifiedUtcDtTmOfReceivedSubData;
        } else {
            LastModifiedUtcDtTmForInsertOrUpdateSubDetails = null;
        }

        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        const jsonBody = {
            lastModifiedTime: LastModifiedUtcDtTmForInsertOrUpdateSubDetails, 
        };
        axios.post(`${getAPIHostURL()}/wclient/getLatestSubscriptionInfo`, jsonBody)
        .then(response => {
            
            if(response.data.code == 'SUCCESS') {    

                if(response.data.retrievedSubscriptionData == null || response.data.retrievedSubscriptionData.length <= 0 ||
                    response.data.retreivedSubLastModifiedUtcDateTime == null || response.data.retreivedSubLastModifiedUtcDateTime.length <= 0) {
                    // modifiedState.errors.others = "No Product Data Found.";
                } else {
                    let stateSubscriptionDetailsArr = [];
                    let stateProductLineDetailsArrForFechingData = []
                                            
                    // React table checks using referential integrity. So if you do not create a
                    // new array (but just add to the existing array), the change detection will not trigger.
                    // So create a brand new array from existing Subscription Details data.
                    stateSubscriptionDetailsArr = [...modifiedState.subscriptionTableData]

                    const receivedSubscritptionData = response.data.retrievedSubscriptionData;
                    let viewEyeIcon = <FaEye className="viewAndEditIcon" title="View"/>;
                    let editIcon = <FaEdit className="viewAndEditIcon" title="Edit"/>
                    
                    for(let i = 0; i < receivedSubscritptionData.length; i++) {
                        const SubscriptionDetails = receivedSubscritptionData[i];
                        let singleSubscriptionDetails = {
                            view: viewEyeIcon,  
                            edit: editIcon,
                            ProductCode: SubscriptionDetails.ProductCode == null ? "" : SubscriptionDetails.ProductCode,
                            selectedProdNameCode: SubscriptionDetails.ProductNameCode == null ? "" : SubscriptionDetails.ProductNameCode,
                            selectedProductName: SubscriptionDetails.ProductName == null ? "" : SubscriptionDetails.ProductName,
                            selectedProductLine: SubscriptionDetails.ProductLine == null ? "" : SubscriptionDetails.ProductLine,
                            ProdDescription: SubscriptionDetails.ProductName == null ? "" : SubscriptionDetails.ProductDescription,
                            SubscriptionID: SubscriptionDetails.SubscriptionID == null || SubscriptionDetails.SubscriptionID.length <= 0 ? "" : SubscriptionDetails.SubscriptionID,
                            SubscriptionNameCode: SubscriptionDetails.SubscriptionNameCode == null || SubscriptionDetails.SubscriptionNameCode.length <= 0 ? "" : SubscriptionDetails.SubscriptionNameCode,
                            SubscriptionBasicUnit: SubscriptionDetails.SubBasicUnit == null || SubscriptionDetails.SubBasicUnit.length <= 0 ? "" : SubscriptionDetails.SubBasicUnit,
                            SubscriptionPricePerUnitINR: SubscriptionDetails.SubPricePerUnitINR == null || SubscriptionDetails.SubPricePerUnitINR.length <= 0 ? "" : SubscriptionDetails.SubPricePerUnitINR,
                            SubscriptionTaxPrcntPerUnit: SubscriptionDetails.SubTaxPrcntPerUnit == null || SubscriptionDetails.SubTaxPrcntPerUnit.length <= 0 ? "" : SubscriptionDetails.SubTaxPrcntPerUnit,
                            SubscriptionGracePeriod: SubscriptionDetails.GracePeriod == null || SubscriptionDetails.GracePeriod.length <= 0 ? "" : SubscriptionDetails.GracePeriod,
                            SubscriptionWarningPeriod: SubscriptionDetails.WarningPeriod == null || SubscriptionDetails.WarningPeriod.length <= 0 ? "" : SubscriptionDetails.WarningPeriod,
                            SubscriptionLastModifiedTime: SubscriptionDetails.SubscriptionNameCode == null || SubscriptionDetails.SubscriptionNameCode.length <= 0 ? "" : convertUTCDateStringToLocalDateWithFormatDDMMMYYHH24MISS(SubscriptionDetails.SubLastModifiedTime)
                        };

                        //if form is open in insertMode(create new Product) then just add new retrieved data(new added Product details) at top of the Product table.
                        if(modifiedState.formViewMode == "insertMode"){
                            stateSubscriptionDetailsArr.unshift(singleSubscriptionDetails);
                            modifiedState.goToPage1 = true;
                        }
                        //if form is open in editMode(edit existing Product details) then compare all ProductCode in Product table
                        //with retrieved ProductCode after editing Product details. If both custId get match then remove the selected Product data
                        //from Product table, and add new retrived Product data at top of the Product table.
                        else if(modifiedState.formViewMode == "editMode"){
                            for(let j = 0; j <stateSubscriptionDetailsArr.length; j++){
                                if(stateSubscriptionDetailsArr[j].SubscriptionID == singleSubscriptionDetails.SubscriptionID){
                                    stateSubscriptionDetailsArr.splice(j, 1);
                                }
                            }
                            stateSubscriptionDetailsArr.unshift(singleSubscriptionDetails);
                            modifiedState.goToPage1 = false;
                        }    
                        //on first time page load its will retrieved all Product details and add it in Product table,
                        // in order of latest data on top of the Product table.
                        else { 
                            stateProductLineDetailsArrForFechingData.push(singleSubscriptionDetails);
                            modifiedState.goToPage1 = false;
                        }
                    } 

                    if(modifiedState.formViewMode == "insertMode"){
                        // stored latest received UTC Date Time of Product table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedSubData = response.data.retreivedSubLastModifiedUtcDateTime[0].SubscriptionLastModifiedUtcDtTm;
                        modifiedState.subscriptionTableData = stateSubscriptionDetailsArr;
                    } else if(modifiedState.formViewMode == "editMode"){
                        // stored latest received UTC Date Time of Product table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedSubData = response.data.retreivedSubLastModifiedUtcDateTime[0].SubscriptionLastModifiedUtcDtTm;
                        modifiedState.subscriptionTableData = stateSubscriptionDetailsArr;
                    } else {
                        // stored latest received UTC Date Time of Product table in State.
                        modifiedState.LastModifiedUtcDtTmOfReceivedSubData = response.data.retreivedSubLastModifiedUtcDateTime[0].SubscriptionLastModifiedUtcDtTm;
                        modifiedState.subscriptionTableData = stateProductLineDetailsArrForFechingData;
                    }
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                } else {
                    console.log('Should not reach here');
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            console.log("Network error:");
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                // modifiedState.errors.others = t(IDS_RegistNetworkError);
                setState({...modifiedState});
            }
        })
    }

    const getProductsInfoAndBasicUnits = () => {
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        let modifiedState = state; 
        
        // To avoid appending of array values.
        modifiedState.AllProductsInformation = [];
        modifiedState.filteredProductInfoBasedOnProductLine = [];
        modifiedState.ProductLineArr = [];
        modifiedState.BasicUnitArr = [];
        modifiedState.formViewMode = "insertMode";
        modifiedState.modal = true;

        // To get Products information from database.
        axios.post(`${getAPIHostURL()}/wclient/getProductsInfoAndBasicUnits`)
        .then(response => {
            if(response.data.code == "SUCCESS") {
                // IF response gets inadequate data.
                if(response.data.retrievedProductInfo == null || response.data.retrievedProductInfo.length <= 0 ||
                    response.data.retrievedBasicUnit == null || response.data.retrievedBasicUnit.length <= 0){
                    modifiedState.errors.others = "Products or Basic Unit Information is not present on Server.";
                    
                } else {
                    modifiedState.AllProductsInformation = response.data.retrievedProductInfo;
                    modifiedState.AllProductsInformation = response.data.retrievedProductInfo;
                    modifiedState.BasicUnitArr = [...new Set(response.data.retrievedBasicUnit.map(singleBasicUnit => singleBasicUnit.Unit))];
                    modifiedState.ProductLineArr = [...new Set(modifiedState.AllProductsInformation.map(singleProductInfo => singleProductInfo.ProductLine))];
                    modifiedState.subGracePeriod = DEFAULT_SUB_GRACE_PERIOD_IN_DAYS;
                    modifiedState.subWarningPeriod = DEFAULT_SUB_WARNING_PERIOD_IN_DAYS;
                }
            } else {
                if(response.data.code == 'SQL_ERROR') {
                    // Tell the user that Server is experiencing errors
                    modifiedState.errors.others = "Server experiencing issues.\nTry again later.";
                } else {
                    console.log("Should not reach here.")
                    modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                }
            }
            setState({...modifiedState});
        })
        .catch(error => {
            console.log(error);
            if (axios.isCancel(error)) {
                console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
            } else {
                modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                setState({...modifiedState});
            }  
        });
    }

    // For selecting values in datalist of "Select or Define Product Name Code and Product Name" as per selection of dropdown of "Select Product Line".
    const onChangeProductLineSelection = (e) => {

        let modifiedState = state;
        modifiedState.selectedProductLine = e.target.value;
        modifiedState.filteredProductInfoBasedOnProductLine = [];
        modifiedState.selectedProdNameCode = "";
        modifiedState.ProdDescription = "";
        modifiedState.ProductCode = "";
        modifiedState.selectedProductName = "";

        for(let i=0; i< modifiedState.AllProductsInformation.length; i++) {
            if(modifiedState.AllProductsInformation[i].ProductLine == modifiedState.selectedProductLine) {
                modifiedState.filteredProductInfoBasedOnProductLine.push(modifiedState.AllProductsInformation[i])
            }
        }
        modifiedState.errors.ProductLine = "";

        setState({...modifiedState});
    }

    const OnChangeProductNameSelection = (e) => {

        let modifiedState = state;
        modifiedState.errors.ProdName = "";
        modifiedState.selectedProductName = e.target.value;

        for(let i=0; i< modifiedState.filteredProductInfoBasedOnProductLine.length; i++) {
            if(modifiedState.filteredProductInfoBasedOnProductLine[i].ProductName == modifiedState.selectedProductName) {
                modifiedState.selectedProdNameCode = modifiedState.filteredProductInfoBasedOnProductLine[i].ProductNameCode;
                modifiedState.ProdDescription = modifiedState.filteredProductInfoBasedOnProductLine[i].ProductDescription;
                modifiedState.ProductCode = modifiedState.filteredProductInfoBasedOnProductLine[i].ProductCode;
                modifiedState.errors.ProdName = "";
            }
        }
        
        setState({...modifiedState});
    }

    const handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        let errors = state.errors;

        switch (name) {

            case 'subNameCode': 
                errors.others= "";
                errors.subNameCode = "";
            break;

            case 'subPricePerUnit': 
                value = value < 0 ? "" : value;
                errors.others= "";
                errors.subPricePerUnit = "";     
            break;

            case 'subTaxPrcntPerUnit': 
                value = value < 0 ? "" : value;
                errors.others= "";
                errors.subTaxPrcntPerUnit = "";
            break;

            case 'subGracePeriod': 
                value = value < 0 ? "" : value;
                errors.others= "";
                errors.subGracePeriod = "";
            break;

            case 'subWarningPeriod': 
                value = value < 0 ? "" : value;
                errors.others= "";
                errors.subWarningPeriod = "";
            break;

            default:
            break;
        }

        setState({
            ...state,
            errors, 
            [name]: value,
            // [event.target.name]: event.target.value,   
        });
    }

    const onChangeSubscriptionBasicUnitSelection = (e) => {

        let modifiedState = state;
        modifiedState.errors.subBasicUnit = "";
        modifiedState.subBasicUnit = e.target.value;
        setState({...modifiedState});
    }

    // For saving all information to database.
    const onSubmit = (e) => {
        e.preventDefault();
        let modifiedState = state;
        let appRelevantDataContextValue = context;
        let t = appRelevantDataContextValue.t;

        let LoggedInUserID = appRelevantDataContextValue.loggedInUserInfo.userID;

        let confirmationForSubFormEditFileds = "";
        let subscriptionIDForEdit;

        // if flag formviewMode is set "editMode" (edit Product details) then check which fields are user has edited,
        // and ask a confirmation for that edited fields of Product form.
        // if there is no change if form return a function. 
        // aslo add Product code of selected Product which user want to edit.
        if(modifiedState.formViewMode == "editMode")
        {   
            subscriptionIDForEdit = modifiedState.subscriptionID;

            if(modifiedState.originalSubNameCode != modifiedState.subNameCode){
                confirmationForSubFormEditFileds = "Subscription Name Code,";
            }
            if(modifiedState.originalSubBasicUnit != modifiedState.subBasicUnit){
                confirmationForSubFormEditFileds = confirmationForSubFormEditFileds + "Subscription Basic Unit,";

            }
            if(modifiedState.originalSubPricePerUnit != modifiedState.subPricePerUnit){
                confirmationForSubFormEditFileds = confirmationForSubFormEditFileds + "Subscription Price Per Unit,";

            }
            if(modifiedState.originalSubTaxPrcntPerUnit != modifiedState.subTaxPrcntPerUnit){
                confirmationForSubFormEditFileds = confirmationForSubFormEditFileds + "Subscription Tax Per Unit,";
            }

            if(modifiedState.originalSubGracePeriod != modifiedState.subGracePeriod){
                confirmationForSubFormEditFileds = confirmationForSubFormEditFileds + "Subscription Grace Period,";

            }
            if(modifiedState.originalSubWarningPeriod != modifiedState.subWarningPeriod){
                confirmationForSubFormEditFileds = confirmationForSubFormEditFileds + "Subscription Warning Period,";
            }

            if(confirmationForSubFormEditFileds != null && confirmationForSubFormEditFileds.length > 0 ){
                if(!window.confirm(`Are you sure you want to change the ${confirmationForSubFormEditFileds} subscription details for this product?`)){
                    return
                }
            } else {
                alert("There are no changes in Subscription Details")
                return
            }
        } 
        // if flag formViewMode is not set as EditMode(Edit existing Product Details) then ProductCode will send null.
        // in case of create new Product we send ProductCode =  Null
        else {
            subscriptionIDForEdit = null;    
        }

        modifiedState.errors.ProductLine = (modifiedState.selectedProductLine == null || modifiedState.selectedProductLine.length <= 0)
            ? "Please Select ProductLine." 
            : "";

        modifiedState.errors.ProdName = (modifiedState.selectedProductName == null || modifiedState.selectedProductName.length <= 0)
            ? "Please Select Product Name." 
            : "";
        
        modifiedState.errors.subNameCode = (modifiedState.subNameCode == null || modifiedState.subNameCode.length <= 0)
            ? "Please Define Subscription Name Code." 
            : "";

        modifiedState.errors.subBasicUnit = (modifiedState.subBasicUnit == null || modifiedState.subBasicUnit.length <= 0)
            ? "Please Select or Enter Subscription Basic Unit."
            : "";

        modifiedState.errors.subPricePerUnit = (modifiedState.subPricePerUnit == null || modifiedState.subPricePerUnit.length <= 0)
            ? "Please Enter Subscription Price Per Unit." 
            : "";

        modifiedState.errors.subTaxPrcntPerUnit = (modifiedState.subTaxPrcntPerUnit == null || modifiedState.subTaxPrcntPerUnit.length <= 0)
            ? "Please Enter Subscription Tax Per Unit." 
            : "";

        modifiedState.errors.subGracePeriod = (modifiedState.subGracePeriod == null || modifiedState.subGracePeriod.length <= 0)
            ? "Please Enter Subscription Grace Period." 
            : "";

        modifiedState.errors.subWarningPeriod = (modifiedState.subWarningPeriod == null || modifiedState.subWarningPeriod.length <= 0)
            ? "Please Enter Subscription Warning Period." 
            : "";

        // if any one of the required fields is not entered by user then return the function and show error message.
        if(modifiedState.errors.ProductLine.length > 0 || modifiedState.errors.ProdName.length > 0 ||
           modifiedState.errors.subNameCode.length > 0 || modifiedState.errors.subBasicUnit.length > 0 ||
           modifiedState.errors.subPricePerUnit.length > 0 || modifiedState.errors.subTaxPrcntPerUnit.length > 0 ||
           modifiedState.errors.subGracePeriod.length > 0 || modifiedState.errors.subWarningPeriod.length > 0) {
            
            setState({...modifiedState});
            return
        } else {
                let JsonBody = {
                    SubscriptionID: subscriptionIDForEdit,
                    ProductCode: modifiedState.ProductCode,
                    SubNameCode: trimStringAndRemoveTrailingComma(modifiedState.subNameCode),
                    SubBasicUnit: trimStringAndRemoveTrailingComma(modifiedState.subBasicUnit),
                    SubPricePerUnit: modifiedState.subPricePerUnit,
                    SubTaxPrcntPerUnit: modifiedState.subTaxPrcntPerUnit,
                    SubGracePeriod: modifiedState.subGracePeriod,
                    SubWarningPeriod: modifiedState.subWarningPeriod,
                    UserID: LoggedInUserID,
                }

                // For saving/defining new Product's Information.
                axios.post(`${getAPIHostURL()}/wclient/insertOrUpdateSubscriptionInfo`, JsonBody)
                .then(response => {
                    if(response.data.code == "SUCCESS") {
                        if(modifiedState.formViewMode == "editMode") {
                                alert(`Successfully 'updated' Subscription Details for Selected Product.`);
                        } else{
                                alert(`Successfully Added Subscription Details for Selected Product.`); 
                        }
 
                        modifiedState.modal = false;
                        modifiedState.errors.others = "";
                        modifiedState.selectedProductLine = "";
                        modifiedState.selectedProdNameCode = "";
                        modifiedState.selectedProductName = "";
                        modifiedState.ProdDescription = "";
                        modifiedState.subNameCode = "";
                        modifiedState.SubBasicUnit = "";
                        modifiedState.subPricePerUnit = "";
                        modifiedState.subTaxPrcntPerUnit = "";
                        modifiedState.subGracePeriod = "";
                        modifiedState.subWarningPeriod = "";

                        getLatestSubscriptionInfo(modifiedState)


                    } else {
                        if(response.data.code == 'SUBSCRIPTION_DETAILS_ALREADY_EXIST'){
                            alert("Subscription Details already exists for selected Product.");
                        } else if(response.data.code == 'REQ_PARAMS_MISSING'){
                            modifiedState.errors.others = "Server experiencing issues (required parameters not sent). Try again later.";
                        } else if(response.data.code == 'SQL_ERROR') {
                            // Tell the user that Server is experiencing errors
                            modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                        } else {
                            console.log('Should not reach here');
                            modifiedState.errors.others = 'Server experiencing issues.\nTry again later.';
                        }
                    }
                    setState({...modifiedState});
                })
                .catch(error => {
                    console.log(error);
                    if (axios.isCancel(error)) {
                        console.log('Axios request cancelled beacuse of too many requests being sent to the Server.');
                    } else {
                        modifiedState.errors.others = 'Network issues.\nCheck your Internet and Try again later.';
                    //   modifiedState.errors.others = t(IDS_RegistNetworkError);
                        setState({...modifiedState});
                } 
                });
        }
    }

    let appRelevantDataContextValue = context;
    let t = appRelevantDataContextValue.t;

    return (
        <div>
            <div className = "container col-lg-12" style = {{display: "flex", justifyContent: "space-between", padding: "0.2rem"}}>
                <div>
                    <button type = "button" 
                        className = "btn-md addCustBtn" 
                        style = {{background: "transparent", pointerEvents: "none", border: "none", color:"transparent"}} 
                    >
                        Add New Subscription Details
                    </button>  
                    <IoMdAddCircleOutline className = "addProductIcon"
                        style = {{background: "transparent", pointerEvents: "none", border: "none", color:"transparent"}} />   
                </div>
                <div className = "customerTableHeading">
                    Add, View or Edit Subscription Details
                </div> 
                <div>
                    <button type = "button" 
                        className = "btn-md addCustBtn" 
                        onClick = {getProductsInfoAndBasicUnits}
                        style = {{textTransform: 'capitalize'}}
                        >   
                        Add New Subscription Details
                    </button>
                    <IoMdAddCircleOutline className = "addProductIcon" onClick = {getProductsInfoAndBasicUnits} title="Add New Subscription Details"/>    
                </div> 
            </div>
            {/* <div style={{borderStyle: "solid", borderWidth: "1px"}}> */}
            <div className="border border-3 ReactTableStyle">
                <ReactTable columns={state.columns} 
                    data={state.subscriptionTableData}
                    passedStateVariable = {state.goToPage1}
                    getCellProps={(cellInfo) => {
                        let modifiedState = state;
                        return {
                            onClick: () => {
                                modifiedState.selectedRowIndex = cellInfo.row.index;
                                setState({...modifiedState});

                                if(cellInfo.column.id == "view") {
                                    onEditProductDetails(cellInfo.row, cellInfo.column); 
                                }  else if(cellInfo.column.id == "edit"){
                                    onEditProductDetails(cellInfo.row, cellInfo.column);
                                }             
                            }, 
                            style: {
                                cursor: '',
                                background: cellInfo.row.index === modifiedState.selectedRowIndex ? '#6c95ba' : '',
                                color: cellInfo.row.index === modifiedState.selectedRowIndex ? 'white' : 'black',
                                alignItems: "center",
                            }
                        }
                    }}
                    // onEditProductDetails = {onEditProductDetails}
                    // onViewProductDetails = {onViewProductDetails}
                />
            </div>

            <Modal size="lg" isOpen={state.modal} backdrop={state.backdrop}>
                <ModalHeader toggle={toggle} style={{textAlign: "center"}}>
                    {state.formViewMode == "viewMode" ? <span> Subscription Details View Only </span> : 
                    state.formViewMode == "editMode" ? <span> Edit Subscription Details </span> 
                    : <span>Add New Subscription Details</span>}
                </ModalHeader>
                <ModalBody>  
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="container col-lg-8 col-lg-offset-3
                                                    col-md-8 col-md-offset-2">
                                <div className="modal-body box">
                                    <form onSubmit={onSubmit}>
                                        <div>
                                            {(state.formViewMode == "viewMode" || state.formViewMode == "editMode") ?
                                                <div>
                                                    <label className="reg-form-label">Product Line : </label>
                                                    <input type='text' className="input-form" 
                                                                value= {state.selectedProductLine} 
                                                                onChange={onChangeProductLineSelection}
                                                                readOnly
                                                                style={{color: "#505050", backgroundColor: "#F0F0F0"}}       
                                                    />
                                                </div>
                                            :
                                                <div>
                                                    <label className="reg-form-label">Select Product Line : <span className="validationRequiredStarMark">*</span></label>
                                                    <select className="input-form" style={{height:"2.5rem"}}
                                                                                value={state.selectedProductLine}
                                                                                onChange={onChangeProductLineSelection}
                                                    >
                                                        <option value="" disabled select= "true">Select Product Line...</option>
                                                        {(state.ProductLineArr).map((singleProdLine,index) => <option key={index} value={singleProdLine}>{singleProdLine}</option>)}
                                                        
                                                    </select>
                                                    {state.errors.ProductLine.length > 0 && 
                                                                        <span  className='validationErrorLabel'>{state.errors.ProductLine}</span>} 
                                                </div>
                                            }   
                                        </div>

                                        <div style={{marginTop: "0.5rem"}}>
                                            {(state.formViewMode == "viewMode" || state.formViewMode == "editMode") ?
                                                <div>
                                                    <label className="reg-form-label">Product Name: </label>
                                                    <input type='text' className="input-form" 
                                                                value= {state.selectedProductName} 
                                                                readOnly
                                                                style={{color: "#505050", backgroundColor: "#F0F0F0"}}
                                                    />
                                                </div>
                                            :         
                                                <div>
                                                    <label className="reg-form-label">Select Product Name: <span className="validationRequiredStarMark">*</span></label>
                                                    <select className="input-form" style={{height:"2.5rem"}}
                                                                                value={state.selectedProductName}
                                                                                onChange={OnChangeProductNameSelection}
                                                    >
                                                        <option value="" disabled select= "true">Select Product Name Code...</option>
                                                        {(state.filteredProductInfoBasedOnProductLine).map((singleProdInfo,index) => <option key={index} value={singleProdInfo.ProductName}>{singleProdInfo.ProductName}</option>)}
                                                        
                                                    </select>
                                                    {state.errors.ProdName.length > 0 && 
                                                                        <span  className='validationErrorLabel'>{state.errors.ProdName}</span>} 
                                                </div>
                                            }
                                        </div>

                                        <div className="inputgroupCustom" style={{marginTop: "0.5rem"}}>
                                            <label className="reg-form-label">Product Name Code: </label>
                                            <input type='text' className="input-form" 
                                                value= {state.selectedProdNameCode} 
                                                readOnly
                                                style={{color: "#505050", backgroundColor: "#F0F0F0"}}
                                            />
                                        </div>
                                            
                                        <div className="inputgroupCustom" style={{marginTop: "0.5rem"}}>
                                            <label className="reg-form-label">Product Description: </label>   
                                            <textarea className=" input-form" name="comments"   
                                                value={state.ProdDescription}
                                                readOnly
                                                style={{color: "#505050", backgroundColor: "#F0F0F0"}} 
                                            /> 
                                        </div>

                                        <div style={{marginTop: "0.5rem"}}>
                                            <label className="reg-form-label">
                                                {(state.formViewMode == "viewMode" || (state.formViewMode == "editMode" && state.originalSubBasicUnit != null && state.originalSubBasicUnit.length > 0)) ? 
                                                "Subscription Name Code:" :
                                                <div>Define Subscription Name Code: <span className="validationRequiredStarMark">*</span></div>
                                                }
                                            </label>
                                            <input type='text' className="input-form" name = "subNameCode"
                                                value= {state.subNameCode} 
                                                onChange={handleChange}
                                                readOnly = {(state.formViewMode == "viewMode" || (state.formViewMode == "editMode" && state.originalSubBasicUnit != null && state.originalSubBasicUnit.length > 0)) ?  true : false}
                                                style = {(state.formViewMode == "viewMode" || (state.formViewMode == "editMode" && state.originalSubBasicUnit != null && state.originalSubBasicUnit.length > 0)) ?  {color: "#505050", backgroundColor: "#F0F0F0"} : {color: "", backgroundColor: ""}}
                                            />
                                            {state.errors.subNameCode.length > 0 && 
                                                <span  className='validationErrorLabel'>{state.errors.subNameCode}</span>} 
                                        </div>
                                            
                                        <div style={{marginTop: "0.5rem"}}>
                                            {(state.formViewMode == "viewMode" || (state.formViewMode == "editMode" && state.originalSubBasicUnit != null && state.originalSubBasicUnit.length > 0)) ? 
                                                <div>
                                                    <label className="reg-form-label">Subscription Period Basic Unit: </label>
                                                    <input type="list" list="data" className="input-form" 
                                                        value={state.subBasicUnit} 
                                                        readOnly
                                                        style={{color: "#505050", backgroundColor: "#F0F0F0"}}
                                                    />   
                                                </div> 
                                            : 
                                                <div>
                                                    <label className="reg-form-label">Select or Define Subscription Period Basic Unit: <span className="validationRequiredStarMark">*</span></label>
                                                    <div>
                                                        <input type="list" list="dataListSubBasicUnit" className="input-form" 
                                                            value={state.subBasicUnit} onChange={onChangeSubscriptionBasicUnitSelection}
                                                        />
                                                        <datalist id="dataListSubBasicUnit">
                                                            {state.BasicUnitArr.map((singleBasicUnit, index) =>
                                                                <option key={index} value={singleBasicUnit.displayValue}>{singleBasicUnit}</option>
                                                            )}
                                                        </datalist>
                                                        {state.errors.subBasicUnit.length > 0 && 
                                                            <span  className='validationErrorLabel'>{state.errors.subBasicUnit}</span>} 
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="inputgroupCustom" style={{marginTop:"0.5rem"}}>
                                            <label className="reg-form-label">
                                            {state.formViewMode == "viewMode"  ? 
                                                <span>Subscription Price Per Unit Period (INR):</span> :
                                                <span>Enter Subscription Price Per Unit Period (INR): <span className="validationRequiredStarMark">*</span></span>
                                            }
                                            </label>
                                            <input type='number' className="input-form" name= "subPricePerUnit"
                                                value= {state.subPricePerUnit} 
                                                onChange={handleChange}
                                                readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                style = {state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0"} : {color: "", backgroundColor: ""}}
                                            />  
                                            {state.errors.subPricePerUnit.length > 0 && 
                                                    <span  className='validationErrorLabel'>{state.errors.subPricePerUnit}</span>}
                                        </div>

                                        <div className="inputgroupCustom" style={{marginTop:"0.5rem"}}>
                                            <label className="reg-form-label">
                                                {state.formViewMode == "viewMode"  ? 
                                                    <span>Subscription Tax Per Unit Period (%):</span> :
                                                    <span>Enter Subscription Tax Per Unit Period (%): <span className="validationRequiredStarMark">*</span></span>
                                                }
                                            </label>
                                            <input type='number' className="input-form" name="subTaxPrcntPerUnit"
                                                value= {state.subTaxPrcntPerUnit} 
                                                onChange={handleChange}
                                                readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                style = {state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0"} : {color: "", backgroundColor: ""}}

                                            />  
                                            {state.errors.subTaxPrcntPerUnit.length > 0 && 
                                                <span  className='validationErrorLabel'>{state.errors.subTaxPrcntPerUnit}</span>} 
                                        </div>

                                        <div className="inputgroupCustom" style={{marginTop:"0.5rem"}}>
                                            <label className="reg-form-label">
                                                {state.formViewMode == "viewMode"  ? 
                                                    <span>Subscription Warning Period (Days):</span> :
                                                    <span>Enter Subscription Warning Period (Days): <span className="validationRequiredStarMark">*</span></span>
                                                }
                                            </label>
                                            <input type='number' className="input-form" name="subWarningPeriod"
                                                value= {state.subWarningPeriod} 
                                                onChange={handleChange}
                                                readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                style = {state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0"} : {color: "", backgroundColor: ""}}

                                            />  
                                            {state.errors.subWarningPeriod.length > 0 && 
                                                <span  className='validationErrorLabel'>{state.errors.subWarningPeriod}</span>} 
                                        </div>

                                        <div className="inputgroupCustom" style={{marginTop:"0.5rem"}}>
                                            <label className="reg-form-label">
                                                {state.formViewMode == "viewMode"  ? 
                                                    <span>Subscription Grace Period (Days):</span> :
                                                    <span>Enter Subscription Grace Period (Days): <span className="validationRequiredStarMark">*</span></span>
                                                }
                                            </label>
                                            <input type='number' className="input-form" name="subGracePeriod"
                                                value= {state.subGracePeriod} 
                                                onChange={handleChange}
                                                readOnly = {state.formViewMode == "viewMode" ? true : false}
                                                style = {state.formViewMode == "viewMode" ? {color: "#505050", backgroundColor: "#F0F0F0"} : {color: "", backgroundColor: ""}}

                                            />  
                                            {state.errors.subGracePeriod.length > 0 && 
                                                <span  className='validationErrorLabel'>{state.errors.subGracePeriod}</span>} 
                                        </div>

                                        <div style={{marginTop: "1rem", display: "flex", justifyContent: "space-evenly"}}>
                                            <div>
                                                <button type="button" className="addCustSavebtn" 
                                                    onClick={toggle} name="Back" 
                                                    style={{pointerEvents: "auto"}}
                                                > 
                                                Back</button>
                                            </div >
                                            <div style={{ display: `${state.formViewMode == "viewMode" ? "none" : "block"}` }}>
                                                <button type="submit" className="addCustSavebtn"  name="Save">
                                                Save</button>
                                            </div>
                                        </div>

                                        <div className = "buttonErrorMessage">
                                            {state.errors.others.length > 0 && 
                                                <p style={{color: 'var(--errorColor)', fontSize: '0.8rem'}} className='error'>{state.errors.others}</p>}
                                        </div> 
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default VcProductSubscription;
